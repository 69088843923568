﻿
import { HardnessTestingResultsTableRow } from './models/HardnessTestingResultsTableRow';
import { ImpactTestingResultsTableRow } from './models/ImpactTestingResultsTableRow';
import { TensileTestingResultsTableRow } from './models/TensileTestingResultsTableRow';
import { TransverseTensileTestingResultsTableRow } from './models/TransverseTensileTestingResultsTableRow';
import { BendTestingResultsTableRow } from './models/BendTestingResultsTableRow';
import { MacroTestingResultsTableRow } from './models/MacroTestingResultsTableRow';
import { MicroTestingResultsTableRow } from './models/MicroTestingResultsTableRow';
import { AppThunkAction } from './index';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { BendTestingResults } from './models/BendTestingResults';
import { HardnessTestingResults } from './models/HardnessTestingResults';
import { ImpactTestingResults } from './models/ImpactTestingResults';
import { GetTestResultsExaminationResponse } from './models/responses/GetTestResultsExaminationResponse';
import { TensileTestingResults } from './models/TensileTestingResults';
import { TransverseTensileTestingResults } from './models/TransverseTensileTestingResults';
import { MacroTestingResults } from './models/MacroTestingResults';
import { MicroTestingResults } from './models/MicroTestingResults';
import { OtherDetailsTestingResults } from './models/OtherDetailsTestingResults';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TestResultsExaminationState {
    bendTestingResults: BendTestingResults;
    hardnessTestingResults: HardnessTestingResults;
    impactTestingResults: ImpactTestingResults;
    macroTestingResults: MacroTestingResults;
    microTestingResults: MicroTestingResults;
    magneticTest: string;
    penetrantTest: string;
    radiographicExamination: string;
    longitudinalTensileTestingResults: TensileTestingResults;
    transverseTensileTestingResults: TransverseTensileTestingResults;
    ultrasonicExamination: string;
    visualExamination: string;
    otherDetailsTestingResults: OtherDetailsTestingResults;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface InitBendTestingResultsAction {
    type: 'INIT_BEND_TESTING_RESULTS';
    bendTestingResults: BendTestingResults;
}

export interface InitHardnessTestingResultsAction {
    type: 'INIT_HARDNESS_TESTING_RESULTS';
    hardnessTestingResults: HardnessTestingResults;
}

export interface InitImpactTestingResultsAction {
    type: 'INIT_IMPACT_TESTING_RESULTS';
    impactTestingResults: ImpactTestingResults;
}

export interface InitLongitudinalTensileTestingResultsAction {
    type: 'INIT_LONGITUDINAL_TENSILE_TESTING_RESULTS';
    longitudinalTensileTestingResults: TensileTestingResults;
}

export interface InitMacroTestingResultsAction {
    type: 'INIT_MACRO_TESTING_RESULTS';
    macroTestingResults: MacroTestingResults;
}

export interface InitMicroTestingResultsAction {
    type: 'INIT_MICRO_TESTING_RESULTS';
    microTestingResults: MicroTestingResults;
}

export interface InitTransverseTensileTestingResultsAction {
    type: 'INIT_TRANSVERSE_TENSILE_TESTING_RESULTS';
    transverseTensileTestingResults: TransverseTensileTestingResults;
}

export interface SetBendTestingResultsAction {
    type: 'SET_BEND_TESTING_RESULTS';
    bendTestingResults: BendTestingResults;
}

export interface SetHardnessTestingResultsAction {
    type: 'SET_HARDNESS_TESTING_RESULTS';
    hardnessTestingResults: HardnessTestingResults;
}

export interface SetImpactTestingResultsAction {
    type: 'SET_IMPACT_TESTING_RESULTS1';
    impactTestingResults: ImpactTestingResults;
}

export interface SetLongitudinalTensileTestingResultsAction {
    type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS';
    longitudinalTensileTestingResults: TensileTestingResults;
}

export interface SetMacroTestingResultsAction {
    type: 'SET_MACRO_TEST_RESULTS';
    macroTestingResults: MacroTestingResults;
}

export interface SetMicroTestingResultsAction {
    type: 'SET_MICRO_TESTING_RESULTS';
    microTestingResults: MicroTestingResults;
}

export interface SetMagneticTestAction {
    type: 'SET_MAGNETIC_TEST';
    magneticTest: string;
}

export interface SetPenetrantTestAction {
    type: 'SET_PENETRANT_TEST';
    penetrantTest: string;
}

export interface SetRadiographicExaminationAction {
    type: 'SET_RADIOGRAPHIC_EXAMINATION';
    radiographicExamination: string;
}

export interface SetTransverseTensileTestingResultsAction {
    type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS';
    transverseTensileTestingResults: TransverseTensileTestingResults;
}

export interface SetUltrasonicExaminationAction {
    type: 'SET_ULTRASONIC_EXAMINATION';
    ultrasonicExamination: string;
}

export interface SetVisualExaminationAction {
    type: 'SET_VISUAL_EXAMINATION';
    visualExamination: string;
}
export interface SetOtherDetailsTestingResultAction {
    type: 'SET_OTHER_DETAILS_TESTING_RESULTS';
    otherDetailsTestingResults: OtherDetailsTestingResults;
}
export interface ResetStateAction {
    type: 'RESET_STATE_ACTION';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    InitBendTestingResultsAction
    | InitHardnessTestingResultsAction
    | InitImpactTestingResultsAction
    | InitLongitudinalTensileTestingResultsAction
    | InitMacroTestingResultsAction
    | InitMicroTestingResultsAction
    | InitTransverseTensileTestingResultsAction
    | SetBendTestingResultsAction
    | SetHardnessTestingResultsAction
    | SetImpactTestingResultsAction
    | SetLongitudinalTensileTestingResultsAction
    | SetMacroTestingResultsAction
    | SetMicroTestingResultsAction
    | SetMagneticTestAction
    | SetPenetrantTestAction
    | SetRadiographicExaminationAction
    | SetTransverseTensileTestingResultsAction
    | SetUltrasonicExaminationAction
    | SetVisualExaminationAction
    | SetOtherDetailsTestingResultAction
    | ResetStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    initTestResultsExamination: (testResultsExaminationResponse: GetTestResultsExaminationResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let magneticTest: string = appState.testResultsExamination.magneticTest;
            magneticTest = testResultsExaminationResponse.testResultsExaminationValuesResponse.magneticTest;
            dispatch({ type: 'SET_MAGNETIC_TEST', magneticTest: magneticTest });

            let penetrantTest: string = appState.testResultsExamination.penetrantTest;
            penetrantTest = testResultsExaminationResponse.testResultsExaminationValuesResponse.penetrantTest;
            dispatch({ type: 'SET_PENETRANT_TEST', penetrantTest: penetrantTest });

            let radiographicExamination: string = appState.testResultsExamination.radiographicExamination;
            radiographicExamination = testResultsExaminationResponse.testResultsExaminationValuesResponse.radiographicExamination;
            dispatch({ type: 'SET_RADIOGRAPHIC_EXAMINATION', radiographicExamination: radiographicExamination });

            let ultrasonicExamination: string = appState.testResultsExamination.ultrasonicExamination;
            ultrasonicExamination = testResultsExaminationResponse.testResultsExaminationValuesResponse.ultrasonicExamination;
            dispatch({ type: 'SET_ULTRASONIC_EXAMINATION', ultrasonicExamination: ultrasonicExamination });

            let visualExamination: string = appState.testResultsExamination.visualExamination;
            visualExamination = testResultsExaminationResponse.testResultsExaminationValuesResponse.visualExamination;
            dispatch({ type: 'SET_VISUAL_EXAMINATION', visualExamination: visualExamination });

            let macroTestingResults: MacroTestingResults = { ...appState.testResultsExamination.macroTestingResults };
            for (let i = 0; i < macroTestingResults.macroTestingResultsTableRows.length; i++) {
                if (testResultsExaminationResponse.testResultsExaminationValuesResponse.macroTestingResultsTableRows.length > 0) {
                    macroTestingResults.macroTestingResultsTableRows[i].acceptance = testResultsExaminationResponse.testResultsExaminationValuesResponse.macroTestingResultsTableRows[i].acceptance;
                    macroTestingResults.macroTestingResultsTableRows[i].testSpecimenNumber = testResultsExaminationResponse.testResultsExaminationValuesResponse.macroTestingResultsTableRows[i].testSpecimenNumber
                }
            }
            dispatch({ type: 'INIT_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });

            let microTestingResults: MicroTestingResults = { ...appState.testResultsExamination.microTestingResults };
            for (let i = 0; i < microTestingResults.microTestingResultsTableRows.length; i++) {
                if (testResultsExaminationResponse.testResultsExaminationValuesResponse.microTestingResultsTableRows.length > 0) {
                    microTestingResults.microTestingResultsTableRows[i].acceptance = testResultsExaminationResponse.testResultsExaminationValuesResponse.microTestingResultsTableRows[i].acceptance;
                    microTestingResults.microTestingResultsTableRows[i].testSpecimenNumber = testResultsExaminationResponse.testResultsExaminationValuesResponse.microTestingResultsTableRows[i].testSpecimenNumber
                }
            }
            dispatch({ type: 'INIT_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });

            if (testResultsExaminationResponse.getBendTestingResultsResponse.bendTestingResultsTableRows.length > 0) {
                let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
                bendTestingResults.comments = testResultsExaminationResponse.getBendTestingResultsResponse.comments;
                bendTestingResults = { ...testResultsExaminationResponse.getBendTestingResultsResponse };
                dispatch({ type: 'INIT_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
            }

            if (testResultsExaminationResponse.getImpactTestingResultsResponse.weldImpactTestingResultsTableRows.length > 0) {
                let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
                let getImpactTestingResults = { ...testResultsExaminationResponse.getImpactTestingResultsResponse };
                impactTestingResults = getImpactTestingResults;
                dispatch({ type: 'INIT_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
            }

            if (testResultsExaminationResponse.getTransverseTensileTestingResultsResponse.transverseTensileTestingResultsTabelRows.length > 0) {
                let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
                transverseTensileTestingResults = { ...testResultsExaminationResponse.getTransverseTensileTestingResultsResponse };
                dispatch({ type: 'INIT_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
            }

            if (testResultsExaminationResponse.longitudinalTensileTestingResultsResponse.tensileTestingResultsTableRows.length > 0) {
                let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
                longitudinalTensileTestingResults = { ...testResultsExaminationResponse.longitudinalTensileTestingResultsResponse };
                dispatch({ type: 'INIT_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
            }

            if (testResultsExaminationResponse.getHardnessTestingResultsResponse.hardnessTestingResultsTable1Rows.length > 0) {
                let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
                hardnessTestingResults = { ...testResultsExaminationResponse.getHardnessTestingResultsResponse };
                dispatch({ type: 'INIT_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
            }

            let otherDetailsTestingResults: OtherDetailsTestingResults = { ...appState.testResultsExamination.otherDetailsTestingResults };
            otherDetailsTestingResults = { ...testResultsExaminationResponse.testResultsExaminationValuesResponse};
            dispatch({ type: 'SET_OTHER_DETAILS_TESTING_RESULTS', otherDetailsTestingResults: otherDetailsTestingResults });
        }
    },
    initBendTestingResults: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows.splice(0, bendTestingResults.bendTestingResultsTableRows.length);
            for (let i = 0; i < 4; i++) {
                bendTestingResults.bendTestingResultsTableRows.splice(bendTestingResults.bendTestingResultsTableRows.length, 0, new BendTestingResultsTableRow('', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    initHardnessTestingResults: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            // Table 1.
            hardnessTestingResults.hardnessTestingResultsTable1Rows.splice(0, hardnessTestingResults.hardnessTestingResultsTable1Rows.length);
            for (let i = 0; i < 1; i++) {
                hardnessTestingResults.hardnessTestingResultsTable1Rows.splice(
                    hardnessTestingResults.hardnessTestingResultsTable1Rows.length,
                    0,
                    new HardnessTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''));
            }
            // Table 2.
            hardnessTestingResults.hardnessTestingResultsTable2Rows.splice(0, hardnessTestingResults.hardnessTestingResultsTable2Rows.length);
            for (let i = 0; i < 1; i++) {
                hardnessTestingResults.hardnessTestingResultsTable2Rows.splice(
                    hardnessTestingResults.hardnessTestingResultsTable2Rows.length,
                    0,
                    new HardnessTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''));
            }
            // Table 3.
            hardnessTestingResults.hardnessTestingResultsTable3Rows.splice(0, hardnessTestingResults.hardnessTestingResultsTable3Rows.length);
            for (let i = 0; i < 1; i++) {
                hardnessTestingResults.hardnessTestingResultsTable3Rows.splice(
                    hardnessTestingResults.hardnessTestingResultsTable3Rows.length,
                    0,
                    new HardnessTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    initImpactTestingResults: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            // Weld.
            impactTestingResults.weldImpactTestingResultsTableRows.splice(0, impactTestingResults.weldImpactTestingResultsTableRows.length);
            for (let i = 0; i < 5; i++) {
                impactTestingResults.weldImpactTestingResultsTableRows.splice(impactTestingResults.weldImpactTestingResultsTableRows.length, 0, new ImpactTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    initLongitudinalTensileTestingResults: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows.splice(0, longitudinalTensileTestingResults.tensileTestingResultsTableRows.length);
            for (let i = 0; i < 2; i++) {
                longitudinalTensileTestingResults.tensileTestingResultsTableRows.splice(longitudinalTensileTestingResults.tensileTestingResultsTableRows.length, 0, new TensileTestingResultsTableRow('', '', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    clearState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_STATE_ACTION' });
    },
    initMacroTestingResults: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let macroTestingResults: MacroTestingResults = { ...appState.testResultsExamination.macroTestingResults };
            macroTestingResults.macroTestingResultsTableRows.splice(0, macroTestingResults.macroTestingResultsTableRows.length);
            for (let i = 0; i < 1; i++) {
                macroTestingResults.macroTestingResultsTableRows.splice(macroTestingResults.macroTestingResultsTableRows.length, 0, new MacroTestingResultsTableRow('', '', ''));
            }
            dispatch({ type: 'INIT_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    initMicroTestingResults: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let microTestingResults: MicroTestingResults = { ...appState.testResultsExamination.microTestingResults };
            microTestingResults.microTestingResultsTableRows.splice(0, microTestingResults.microTestingResultsTableRows.length);
            for (let i = 0; i < 1; i++) {
                microTestingResults.microTestingResultsTableRows.splice(microTestingResults.microTestingResultsTableRows.length, 0, new MicroTestingResultsTableRow('', '', ''));
            }
            dispatch({ type: 'INIT_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    initTransverseTensileTestingResults: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.splice(0, transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.length);
            for (let i = 0; i < 2; i++) {
                transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.splice(transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.length, 0, new TransverseTensileTestingResultsTableRow('', '', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setBendTestingResultsComments: (commentsValues: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.comments = commentsValues;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsAcceptableValues: (acceptableValues: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.acceptableValues = acceptableValues;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsAcceptanceResult: (acceptanceResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.acceptanceResult = acceptanceResult;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsBendingAngle: (rowNumber: number, bendingAngle: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].bendingAngle = bendingAngle;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsDiameterOfRoller: (rowNumber: number, diameterOfRoller: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].diameterOfRoller = diameterOfRoller;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsDistanceBetweenRollers: (rowNumber: number, distanceBetweenRollers: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].distanceBetweenRollers = distanceBetweenRollers;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsSurfaceCondition: (rowNumber: number, surfaceCondition: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].surfaceCondition = surfaceCondition;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsTestSpecimenDimensions: (rowNumber: number, testSpecimenDimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions = testSpecimenDimensions;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsTestSpecimenType: (rowNumber: number, testSpecimenType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let bendTestingResults: BendTestingResults = { ...appState.testResultsExamination.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenType = testSpecimenType;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setHardnessTestingResultsAcceptableValues: (acceptableValues: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.acceptableValues = acceptableValues;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsAcceptanceResult: (acceptanceResults: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.acceptanceResults = acceptanceResults;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.comments = comments;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ4: (rowNumber: number, haz4: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz4 = haz4;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ5: (rowNumber: number, haz5: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz5 = haz5;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ6: (rowNumber: number, haz6: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz6 = haz6;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ16: (rowNumber: number, haz16: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz16 = haz16;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ17: (rowNumber: number, haz17: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz17 = haz17;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ10: (rowNumber: number, haz10: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz10 = haz10;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ18: (rowNumber: number, haz18: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz18 = haz18;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ19: (rowNumber: number, haz19: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz19 = haz19;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ11: (rowNumber: number, haz11: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz11 = haz11;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ12: (rowNumber: number, haz12: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz12 = haz12;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent1: (rowNumber: number, parent1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent1 = parent1;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent2: (rowNumber: number, parent2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent2 = parent2;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent3: (rowNumber: number, parent3: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent3 = parent3;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent13: (rowNumber: number, parent13: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent13 = parent13;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent14: (rowNumber: number, parent14: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent14 = parent14;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent15: (rowNumber: number, parent15: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent15 = parent15;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Weld7: (rowNumber: number, weld7: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld7 = weld7;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Weld8: (rowNumber: number, weld8: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld8 = weld8;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Weld9: (rowNumber: number, weld9: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld9 = weld9;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ4: (rowNumber: number, haz4: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz4 = haz4;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ5: (rowNumber: number, haz5: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz5 = haz5;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ6: (rowNumber: number, haz6: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz6 = haz6;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ16: (rowNumber: number, haz16: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz16 = haz16;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ17: (rowNumber: number, haz17: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz17 = haz17;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ10: (rowNumber: number, haz10: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz10 = haz10;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ18: (rowNumber: number, haz18: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz18 = haz18;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ19: (rowNumber: number, haz19: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz19 = haz19;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ11: (rowNumber: number, haz11: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz11 = haz11;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ12: (rowNumber: number, haz12: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz12 = haz12;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent1: (rowNumber: number, parent1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent1 = parent1;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent2: (rowNumber: number, parent2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent2 = parent2;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent3: (rowNumber: number, parent3: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent3 = parent3;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent13: (rowNumber: number, parent13: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent13 = parent13;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent14: (rowNumber: number, parent14: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent14 = parent14;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent15: (rowNumber: number, parent15: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent15 = parent15;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Weld7: (rowNumber: number, weld7: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld7 = weld7;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Weld8: (rowNumber: number, weld8: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld8 = weld8;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Weld9: (rowNumber: number, weld9: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld9 = weld9;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    // Table 3
    setHardnessTestingResultsTable3HAZ4: (rowNumber: number, haz4: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz4 = haz4;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3HAZ5: (rowNumber: number, haz5: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz5 = haz5;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3HAZ6: (rowNumber: number, haz6: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz6 = haz6;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3HAZ16: (rowNumber: number, haz16: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz16 = haz16;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3HAZ17: (rowNumber: number, haz17: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz17 = haz17;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3HAZ10: (rowNumber: number, haz10: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz10 = haz10;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3HAZ18: (rowNumber: number, haz18: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz18 = haz18;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3HAZ19: (rowNumber: number, haz19: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz19 = haz19;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3HAZ11: (rowNumber: number, haz11: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz11 = haz11;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3HAZ12: (rowNumber: number, haz12: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz12 = haz12;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3Parent1: (rowNumber: number, parent1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent1 = parent1;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3Parent2: (rowNumber: number, parent2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent2 = parent2;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3Parent3: (rowNumber: number, parent3: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent3 = parent3;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3Parent13: (rowNumber: number, parent13: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent13 = parent13;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3Parent14: (rowNumber: number, parent14: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent14 = parent14;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3Parent15: (rowNumber: number, parent15: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent15 = parent15;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3Weld7: (rowNumber: number, weld7: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].weld7 = weld7;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3Weld8: (rowNumber: number, weld8: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].weld8 = weld8;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable3Weld9: (rowNumber: number, weld9: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].weld9 = weld9;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsWayOfMeasurementOfHardness1: (wayOfMeasurementOfHardness1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.wayOfMeasurementOfHardness1 = wayOfMeasurementOfHardness1;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsWayOfMeasurementOfHardness2: (wayOfMeasurementOfHardness2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.wayOfMeasurementOfHardness2 = wayOfMeasurementOfHardness2;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsWayOfMeasurementOfHardness3: (wayOfMeasurementOfHardness3: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.testResultsExamination.hardnessTestingResults };
            hardnessTestingResults.wayOfMeasurementOfHardness3 = wayOfMeasurementOfHardness3;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setImpactTestingResultsAcceptableValues: (acceptableValues: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.acceptableValues = acceptableValues;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsType: (typeValues: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.type = typeValues;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAbsorbedEnergy: (rowNumber: number, absorbedEnergy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy = absorbedEnergy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAbsorbedEnergy1: (rowNumber: number, absorbedEnergy1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy1 = absorbedEnergy1;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAbsorbedEnergy2: (rowNumber: number, absorbedEnergy2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy2 = absorbedEnergy2;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsAverage: (rowNumber: number, average: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].average = average;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAcceptanceResult: (weldAcceptanceResults: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.weldAcceptanceResults = weldAcceptanceResults;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldComments: (weldComments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.weldComments = weldComments;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldTestSpecimenDimensions: (rowNumber: number, testSpecimenDimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions = testSpecimenDimensions;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldTestSpecimenType: (rowNumber: number, testSpecimenType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let impactTestingResults: ImpactTestingResults = { ...appState.testResultsExamination.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenType = testSpecimenType;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS1', impactTestingResults: impactTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsA: (rowNumber: number, a: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].a = a;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsAcceptableValuesUA: (acceptableValuesUA: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.acceptableValuesUA = acceptableValuesUA;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsAcceptableValuesUR: (acceptableValuesUR: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.acceptableValuesUR = acceptableValuesUR;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsAcceptableValuesURm: (acceptableValuesURm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.acceptableValuesURm = acceptableValuesURm;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsAcceptableValuesUFm: (acceptableValuesUFm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.acceptableValuesUFm = acceptableValuesUFm;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsAcceptableValuesUZ: (acceptableValuesUZ: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.acceptableValuesUZ = acceptableValuesUZ;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsAcceptanceResult: (acceptanceResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.acceptanceResult = acceptanceResult;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.comments = comments;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsFractureSurfaceCondition: (rowNumber: number, fractureSurfaceCondition: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].fractureSurfaceCondition = fractureSurfaceCondition;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsMeasurementUncertaintyUA: (measurementUncertaintyUA: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.measurementUncertaintyUA = measurementUncertaintyUA;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsMeasurementUncertaintyUR: (measurementUncertaintyUR: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.measurementUncertaintyUR = measurementUncertaintyUR;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsMeasurementUncertaintyURm: (measurementUncertaintyURm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.measurementUncertaintyURm = measurementUncertaintyURm;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsMeasurementUncertaintyUFm: (measurementUncertaintyUFm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.measurementUncertaintyUFm = measurementUncertaintyUFm;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsMeasurementUncertaintyUZ: (measurementUncertaintyUZ: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.measurementUncertaintyUZ = measurementUncertaintyUZ;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsR: (rowNumber: number, r: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].r = r;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsRp: (rowNumber: number, rp: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].rp = rp;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsRm: (rowNumber: number, rm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].rm = rm;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsTestSpecimenDimensions: (rowNumber: number, testSpecimenDimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions = testSpecimenDimensions;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setLongitudinalTensileTestingResultsZ: (rowNumber: number, z: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let longitudinalTensileTestingResults: TensileTestingResults = { ...appState.testResultsExamination.longitudinalTensileTestingResults };
            longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].z = z;
            dispatch({ type: 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS', longitudinalTensileTestingResults: longitudinalTensileTestingResults });
        }
    },
    setMacroTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let macroTestingResults: MacroTestingResults = { ...appState.testResultsExamination.macroTestingResults };
            macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_MACRO_TEST_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingResultsExaminationResult: (rowNumber: number, examinationResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let macroTestingResults: MacroTestingResults = { ...appState.testResultsExamination.macroTestingResults };
            macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].examinationResult = examinationResult;
            dispatch({ type: 'SET_MACRO_TEST_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let macroTestingResults: MacroTestingResults = { ...appState.testResultsExamination.macroTestingResults };
            macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_MACRO_TEST_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMagneticTest: (magneticTest: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_MAGNETIC_TEST', magneticTest: magneticTest });
    },
    setMicroTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let microTestingResults: MicroTestingResults = { ...appState.testResultsExamination.microTestingResults };
            microTestingResults.microTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsExaminationResult: (rowNumber: number, examinationResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let microTestingResults: MicroTestingResults = { ...appState.testResultsExamination.microTestingResults };
            microTestingResults.microTestingResultsTableRows[rowNumber - 1].examinationResult = examinationResult;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let microTestingResults: MicroTestingResults = { ...appState.testResultsExamination.microTestingResults };
            microTestingResults.microTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setPenetrantTest: (penetrantTest: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_PENETRANT_TEST', penetrantTest: penetrantTest });
    },
    setRadiographicExamination: (radiographicExamination: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_RADIOGRAPHIC_EXAMINATION', radiographicExamination: radiographicExamination });
    },
    setTransverseTensileTestingResultsA: (rowNumber: number, a: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].a = a;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptableValuesUA: (acceptableValuesUA: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptableValuesUA = acceptableValuesUA;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptableValuesUA1: (acceptableValuesUA1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptableValuesUA1 = acceptableValuesUA1;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptableValuesUR: (acceptableValuesUR: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptableValuesUR = acceptableValuesUR;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptableValuesUR1: (acceptableValuesUR1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptableValuesUR1 = acceptableValuesUR1;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptableValuesURm: (acceptableValuesURm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptableValuesURm = acceptableValuesURm;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptableValuesURm1: (acceptableValuesURm1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptableValuesURm1 = acceptableValuesURm1;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptableValuesUFm: (acceptableValuesUFm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptableValuesUFm = acceptableValuesUFm;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptableValuesUFm1: (acceptableValuesUFm1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptableValuesUFm1 = acceptableValuesUFm1;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptanceResult: (acceptanceResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptanceResult = acceptanceResult;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.comments = comments;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsMeasurementUncertaintyUA: (measurementUncertaintyUA: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.measurementUncertaintyUA = measurementUncertaintyUA;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsMeasurementUncertaintyUR: (measurementUncertaintyUR: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.measurementUncertaintyUR = measurementUncertaintyUR;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsMeasurementUncertaintyURm: (measurementUncertaintyURm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.measurementUncertaintyURm = measurementUncertaintyURm;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsMeasurementUncertaintyUFm: (measurementUncertaintyUFm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.measurementUncertaintyUFm = measurementUncertaintyUFm;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsPlaceOfBreak: (rowNumber: number, placeOfBreak: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].placeOfBreak = placeOfBreak;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsR: (rowNumber: number, r: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].r = r;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsRm: (rowNumber: number, rm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].rm = rm;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsRp: (rowNumber: number, rp: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].rp = rp;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.testResultsExamination.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setUltrasonicExamination: (ultrasonicExamination: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_ULTRASONIC_EXAMINATION', ultrasonicExamination: ultrasonicExamination });
    },
    setVisualExamination: (visualExamination: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_VISUAL_EXAMINATION', visualExamination: visualExamination });
    },
    setOtherDetailsTestingResultsOtherTesting: (otherTesting: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let otherDetailsTestingResults: OtherDetailsTestingResults = { ...appState.testResultsExamination.otherDetailsTestingResults };
            otherDetailsTestingResults.otherTesting = otherTesting;
            dispatch({ type: 'SET_OTHER_DETAILS_TESTING_RESULTS', otherDetailsTestingResults: otherDetailsTestingResults });
        }
    },
    setOtherTesting: (otherTesting: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let otherDetailsTestingResults: OtherDetailsTestingResults = { ...appState.testResultsExamination.otherDetailsTestingResults };
            otherDetailsTestingResults.otherTesting = otherDetailsTestingResults.otherTesting === "" ? otherTesting : otherDetailsTestingResults.otherTesting + "\n\n" + otherTesting;
            dispatch({ type: 'SET_OTHER_DETAILS_TESTING_RESULTS', otherDetailsTestingResults: otherDetailsTestingResults });
        }
    },
    setRemarks: (remarks: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let otherDetailsTestingResults: OtherDetailsTestingResults = { ...appState.testResultsExamination.otherDetailsTestingResults };
            otherDetailsTestingResults.remarks = otherDetailsTestingResults.remarks === "" ? remarks : otherDetailsTestingResults.remarks + "\n\n" + remarks;
            dispatch({ type: 'SET_OTHER_DETAILS_TESTING_RESULTS', otherDetailsTestingResults: otherDetailsTestingResults });
        }
    },
    setOtherDetailsTestingResultsRemarks: (remarks: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let otherDetailsTestingResults: OtherDetailsTestingResults = { ...appState.testResultsExamination.otherDetailsTestingResults };
            otherDetailsTestingResults.remarks = remarks;
            dispatch({ type: 'SET_OTHER_DETAILS_TESTING_RESULTS', otherDetailsTestingResults: otherDetailsTestingResults });
        }
    },
    setOtherDetailsTestingResultsTestsPerformedWithAcceptableResult: (testsPerformedWithAcceptableResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let otherDetailsTestingResults: OtherDetailsTestingResults = { ...appState.testResultsExamination.otherDetailsTestingResults };
            otherDetailsTestingResults.testsPerformedWithAcceptableResult = testsPerformedWithAcceptableResult;
            dispatch({ type: 'SET_OTHER_DETAILS_TESTING_RESULTS', otherDetailsTestingResults: otherDetailsTestingResults });
        }
    },
    setOtherDetailsTestingResultsLaboratoryReportReferenceNo: (laboratoryReportReferenceNo: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let otherDetailsTestingResults: OtherDetailsTestingResults = { ...appState.testResultsExamination.otherDetailsTestingResults };
            otherDetailsTestingResults.laboratoryReportReferenceNo = laboratoryReportReferenceNo;
            dispatch({ type: 'SET_OTHER_DETAILS_TESTING_RESULTS', otherDetailsTestingResults: otherDetailsTestingResults });
        }
    },
    setOtherDetailsTestingResultsTestCarriedOutInThePresenceOf: (testCarriedOutInThePresenceOf: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let otherDetailsTestingResults: OtherDetailsTestingResults = { ...appState.testResultsExamination.otherDetailsTestingResults };
            otherDetailsTestingResults.testCarriedOutInThePresenceOf = testCarriedOutInThePresenceOf;
            dispatch({ type: 'SET_OTHER_DETAILS_TESTING_RESULTS', otherDetailsTestingResults: otherDetailsTestingResults });
        }
    },
    setTestCarriedOutInThePresenceOf: (testCarriedOutInThePresenceOf: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testResultsExamination) {
            let otherDetailsTestingResults: OtherDetailsTestingResults = { ...appState.testResultsExamination.otherDetailsTestingResults };
            otherDetailsTestingResults.testCarriedOutInThePresenceOf = otherDetailsTestingResults.testCarriedOutInThePresenceOf === "" ? testCarriedOutInThePresenceOf : otherDetailsTestingResults.testCarriedOutInThePresenceOf + "\n\n" + testCarriedOutInThePresenceOf;
            dispatch({ type: 'SET_OTHER_DETAILS_TESTING_RESULTS', otherDetailsTestingResults: otherDetailsTestingResults });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TestResultsExaminationState = {
    magneticTest: '',
    penetrantTest: '',
    radiographicExamination: '',
    ultrasonicExamination: '',
    visualExamination: '',
    macroTestingResults: {
        macroTestingResultsTableRows: [],
        comments: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    microTestingResults: {
        microTestingResultsTableRows: [],
        coverageFactor: '',
        distribution: '',
        comments: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    longitudinalTensileTestingResults: {
        acceptanceNormsStandard: '',
        testingTemperature: '',
        deviationFromStandardsOrAgreement: '',
        tensileTestingResultsTableRows: [],
        acceptableValuesUA: '',
        acceptableValuesUFm: '',
        acceptableValuesUR: '',
        acceptableValuesURm: '',
        acceptableValuesUZ: '',
        measurementUncertaintyUFm: '',
        measurementUncertaintyURm: '',
        measurementUncertaintyUR: '',
        measurementUncertaintyUA: '',
        measurementUncertaintyUZ: '',
        testingPerformedBy: '',
        certificationNumber: '',
        comments: '',
        acceptanceResult: ''
    },
    transverseTensileTestingResults: {
        acceptanceNormsStandard: '',
        testingTemperature: '',
        deviationFromStandardsOrAgreement: '',
        transverseTensileTestingResultsTabelRows: [],
        acceptableValuesUA: '',
        acceptableValuesUFm: '',
        acceptableValuesUR: '',
        acceptableValuesURm: '',
        measurementUncertaintyUFm: '',
        measurementUncertaintyURm: '',
        measurementUncertaintyUR: '',
        measurementUncertaintyUA: '',
        testingPerformedBy: '',
        certificationNumber: '',
        comments: '',
        acceptanceResult: '',
        acceptableValuesUA1: '',
        acceptableValuesUFm1: '',
        acceptableValuesUR1: '',
        acceptableValuesURm1: '',
    },
    bendTestingResults: {
        bendTestingResultsTableRows: [],
        comments: '',
        acceptanceNormsStandard: '',
        acceptableValues: '',
        testingTemperature: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: '',
        acceptanceResult: ''
    },
    impactTestingResults: {
        acceptanceNormsStandard: '',
        acceptableValues: '',
        testingTemperature: '',
        certificationNumber: '',
        comments: '',
        deviationFromStandardsOrAgreement: '',
        weldImpactTestingResultsTableRows: [],
        weldAverageAbsorbedEnergy: '',
        weldAverageLateralExpansion: '',
        weldAverageShear: '',
        weldAverageAcceptance: '',
        weldMeasurementUncertaintyAbsorbedEnergy: '',
        weldMeasurementUncertaintyLateralExpansion: '',
        weldMeasurementUncertaintyShear: '',
        weldMeasurementUncertaintyAcceptance: '',
        weldComments: '',
        weldAcceptanceResults: '',
        testingPerformedBy: '',
        hazAcceptanceResults: '',
        hazAverageAbsorbedEnergy: '',
        hazAverageLateralExpansion: '',
        hazAverageShear: '',
        hazAverageAcceptance: '',
        hazComments: '',
        hazImpactTestingResultsTableRows: [],
        hazMeasurementUncertaintyAbsorbedEnergy: '',
        hazMeasurementUncertaintyLateralExpansion: '',
        hazMeasurementUncertaintyShear: '',
        hazMeasurementUncertaintyAcceptance: '',
        type: '',
    },
    hardnessTestingResults: {
        acceptanceNormsStandard: '',
        acceptableValues: '',
        testingTemperature: '',
        deviationFromStandardsOrAgreement: '',
        wayOfMeasurementOfHardness1: '',
        hardnessTestingResultsTable1Rows: [],
        wayOfMeasurementOfHardness2: '',
        hardnessTestingResultsTable2Rows: [],
        wayOfMeasurementOfHardness3: '',
        hardnessTestingResultsTable3Rows: [],
        testingPerformedBy: '',
        certificationNumber: '',
        measurementUncertainty: '',
        acceptance: '',
        comments: '',
        acceptanceResults: ''
    },
    otherDetailsTestingResults: {
        otherTesting: '',
        remarks: '',
        testsPerformedWithAcceptableResult: '',
        laboratoryReportReferenceNo: '',
        testCarriedOutInThePresenceOf: '',
    }
    
};

export const reducer: Reducer<TestResultsExaminationState> = (state: TestResultsExaminationState | undefined, incomingAction: Action): TestResultsExaminationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'INIT_BEND_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: action.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'INIT_HARDNESS_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: action.hardnessTestingResults
            }
        case 'INIT_IMPACT_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: action.impactTestingResults,
                otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'INIT_LONGITUDINAL_TENSILE_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: action.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'INIT_MACRO_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: action.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'INIT_MICRO_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: action.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'INIT_TRANSVERSE_TENSILE_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: action.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_BEND_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: action.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_HARDNESS_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: action.hardnessTestingResults
            }
        case 'SET_IMPACT_TESTING_RESULTS1':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                otherDetailsTestingResults: state.otherDetailsTestingResults,
                impactTestingResults: action.impactTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_LONGITUDINAL_TENSILE_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: action.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_MACRO_TEST_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: action.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_MICRO_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: action.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_MAGNETIC_TEST':
            return {
                magneticTest: action.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_PENETRANT_TEST':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: action.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_RADIOGRAPHIC_EXAMINATION':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: action.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: action.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_ULTRASONIC_EXAMINATION':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: action.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_VISUAL_EXAMINATION':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: action.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,	 	 	 	otherDetailsTestingResults: state.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'SET_OTHER_DETAILS_TESTING_RESULTS':
            return {
                magneticTest: state.magneticTest,
                penetrantTest: state.penetrantTest,
                radiographicExamination: state.radiographicExamination,
                ultrasonicExamination: state.ultrasonicExamination,
                visualExamination: state.visualExamination,
                macroTestingResults: state.macroTestingResults,
                microTestingResults: state.microTestingResults,
                longitudinalTensileTestingResults: state.longitudinalTensileTestingResults,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                bendTestingResults: state.bendTestingResults,
                impactTestingResults: state.impactTestingResults,                otherDetailsTestingResults: action.otherDetailsTestingResults,
                hardnessTestingResults: state.hardnessTestingResults
            }
        case 'RESET_STATE_ACTION':
            return unloadedState;
        default:
            return state;
    }
};
