﻿
import * as React from 'react';
import { Button, Col,Modal, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { t } from '../i18n';

// At runtime, Redux will merge together...
type DeletionConfirmationModalProps =
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    show: boolean;
    setOpen: (open: boolean) => void;
    useUserNotCust: boolean;
    onConfirmDeletion: () => void;
}

class DeletionConfirmationModal extends React.PureComponent<DeletionConfirmationModalProps> {
    public constructor(props: DeletionConfirmationModalProps) {
        super(props);
    }

    public componentDidMount() {

    }

    private confirmModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.props.onConfirmDeletion();
        this.props.setOpen(false);
    }

    private cancelModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.props.setOpen(false);
    }

    private onOpen() {
        
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.show} onOpened={() => this.onOpen()} size="md" centered={true}>
                    <ModalHeader>
                        {t('AreYouSureDelete_')} {this.props.useUserNotCust ? t('theUser') : t('theCustomer')}?
                    </ModalHeader>
                    <ModalFooter>
                        <Row>
                            <Col sm={12}>
                                <Button color="primary" onClick={(event) => this.confirmModal(event)}>{t('OK')}</Button>
                                {' '}
                                <Button color="secondary" onClick={(event) => this.cancelModal(event)}>{t('Cancel')}</Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default DeletionConfirmationModal;
