﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, PopoverBody, PopoverHeader, Row, UncontrolledPopover } from 'reactstrap';
import * as UserListStore from '../store/UserListStore';
import { ApplicationState } from '../store/index';
import { UserListObject } from '../store/UserListObject';
import { t } from '../i18n';
import { CustomerObject } from '../store/CustomerObject';
import { UpdateUserRequest } from '../store/models/requests/UpdateUserRequest';
import { CreateUserRequest } from '../store/models/requests/CreateUserRequest';
//@ts-ignore
const logo = require('./../images/logo.png');

// At runtime, Redux will merge together...
type EditUserObjectModalProps =
    UserListStore.UserListState // ... state we've requested from the Redux store
    & typeof UserListStore.actionCreators  // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    show: boolean;
    toggleShow: () => void;
    chosenUser: UserListObject;
    setChosenUser: any;
    customers: Array<CustomerObject>;
    creatingNewUser: boolean;
}

class DekraListModal extends React.PureComponent<EditUserObjectModalProps> {
    public constructor(props: EditUserObjectModalProps) {
        super(props);
    }

    public componentDidMount() {

    }

    public state = {
        errorList: new Map<string, boolean>([
            ['userNameMissing', false],
            ['usernameTaken', false],
        ])
    };

    public onRolesSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
    }

    public onCompanySelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
    }

    private onClickToggleShowModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.resetErrors();
        this.props.toggleShow();
    }

    private errorsExist() {
        let currentErrorsArray = Array.from<boolean>(this.state.errorList.values());

        if (currentErrorsArray.find(errorBoolean => errorBoolean) !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    private setError(errorName: string, showError: boolean) {
        let currentErrors = this.state.errorList;

        if (currentErrors.has(errorName)) {
            currentErrors.set(errorName, showError)
        }

        this.setState({ currentErrors: currentErrors });
    }

    private resetErrors() {
        let currentErrors = this.state.errorList;

        currentErrors.forEach((isOn, error) => {
            currentErrors.set(error, false);
        });
        this.setState({ currentErrors: currentErrors });
    }

    private confirmModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.resetErrors();

        const username = (document.getElementById('userNameInput') as HTMLInputElement).value;
        const signatureLogo = (document.getElementById('signatureLogo') as HTMLInputElement).src;

        if (!username) {
            this.setError('userNameMissing', true);
        }

        if (this.errorsExist()) {
            let fadeInElements = document.getElementsByClassName('fadeInFromLeftClass');

            //Reset animation
            for (var i = 0; i < fadeInElements.length; i++) {
                let el = (fadeInElements[i] as HTMLElement);

                el.style.animationName = "fadeInFromLeft";
                el.onanimationend = (event) => { el.style.animationName = null }
            }

            this.props.receiveUserList();

            return;
        }

        if (this.props.creatingNewUser) {
            const createUserRequest: CreateUserRequest = {
                email: (document.getElementById('emailInput') as HTMLInputElement).value,
                userName: (document.getElementById('userNameInput') as HTMLInputElement).value,
                phoneNumber: (document.getElementById('phoneInput') as HTMLInputElement).value,
                firstName: (document.getElementById('firstNameInput') as HTMLInputElement).value,
                lastName: (document.getElementById('lastNameInput') as HTMLInputElement).value,
                title: (document.getElementById('titleInput') as HTMLInputElement).value,
                phoneExchangeNumber: "",
                website: "",
                dekraContactId: null,
                //website: (document.getElementById('websiteInput') as HTMLInputElement).value,
                //phoneExchangeNumber: (document.getElementById('phoneExchangeInput') as HTMLInputElement).value,
                //website: (document.getElementById('websiteInput') as HTMLInputElement).value,
                postCity: (document.getElementById('postCityInput') as HTMLInputElement).value,
                postZipcode: (document.getElementById('postZipcodeInput') as HTMLInputElement).value,
                postStreet: (document.getElementById('postStreetInput') as HTMLInputElement).value,
                visitingCity: (document.getElementById('cityInput') as HTMLInputElement).value,
                visitingZipcode: (document.getElementById('zipcodeInput') as HTMLInputElement).value,
                visitingStreet: (document.getElementById('streetInput') as HTMLInputElement).value,
                roles: (document.getElementById('permissionsInput') as HTMLInputElement).value == '0' ?
                    [] : [(document.getElementById('permissionsInput') as HTMLInputElement).value],
                mobilePhoneNumber: "",
                dekraContact: null,
                customerId: 1,
                signatureLogo: signatureLogo,
            }

            this.props.addNewUser(createUserRequest, () => { this.props.receiveUserList() });
        } else {
            const updateUserRequest: UpdateUserRequest = {
                email: (document.getElementById('emailInput') as HTMLInputElement).value,
                userName: (document.getElementById('userNameInput') as HTMLInputElement).value,
                phoneNumber: (document.getElementById('phoneInput') as HTMLInputElement).value,
                firstName: (document.getElementById('firstNameInput') as HTMLInputElement).value,
                lastName: (document.getElementById('lastNameInput') as HTMLInputElement).value,
                title: (document.getElementById('titleInput') as HTMLInputElement).value,
                phoneExchangeNumber: "",
                website: "",
                //website: (document.getElementById('websiteInput') as HTMLInputElement).value,
                //phoneExchangeNumber: (document.getElementById('phoneExchangeInput') as HTMLInputElement).value,
                //website: (document.getElementById('websiteInput') as HTMLInputElement).value,
                postCity: (document.getElementById('postCityInput') as HTMLInputElement).value,
                postZipcode: (document.getElementById('postZipcodeInput') as HTMLInputElement).value,
                postStreet: (document.getElementById('postStreetInput') as HTMLInputElement).value,
                visitingCity: (document.getElementById('cityInput') as HTMLInputElement).value,
                visitingZipcode: (document.getElementById('zipcodeInput') as HTMLInputElement).value,
                visitingStreet: (document.getElementById('streetInput') as HTMLInputElement).value,
                roles: (document.getElementById('permissionsInput') as HTMLInputElement).value == '0' ?
                    [] : [(document.getElementById('permissionsInput') as HTMLInputElement).value],
                customerId: 1,
                id: this.props.chosenUser.id,
                dekraContact: null,
                dekraContactId: null,
                mobilePhoneNumber: "",
                signatureLogo: signatureLogo,
            }

            this.props.updateUser(updateUserRequest, () => { this.props.receiveUserList() });
        }

        this.props.toggleShow();
    }

    private populateDekraFacilitator(popUser: UserListObject | null) {
        if (!popUser) {
            (document.getElementById('dekraEmploymentNumberInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraFirstNameInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraLastNameInput') as HTMLInputElement).value = "";

            (document.getElementById('dekraTitleInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraEmailInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraPhoneInput') as HTMLInputElement).value = "";

            (document.getElementById('dekraStreetInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraCityInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraZipcodeInput') as HTMLInputElement).value = "";

            (document.getElementById('dekraPostStreetInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraPostCityInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraPostZipcodeInput') as HTMLInputElement).value = "";
        } else {
            (document.getElementById('dekraEmploymentNumberInput') as HTMLInputElement).value = popUser.userName;
            (document.getElementById('dekraFirstNameInput') as HTMLInputElement).value = popUser.firstName;
            (document.getElementById('dekraLastNameInput') as HTMLInputElement).value = popUser.lastName;

            (document.getElementById('dekraTitleInput') as HTMLInputElement).value = popUser.title;
            (document.getElementById('dekraEmailInput') as HTMLInputElement).value = popUser.email;
            (document.getElementById('dekraPhoneInput') as HTMLInputElement).value = popUser.phoneNumber;

            (document.getElementById('dekraStreetInput') as HTMLInputElement).value = popUser.visitingStreet;
            (document.getElementById('dekraCityInput') as HTMLInputElement).value = popUser.visitingCity;
            (document.getElementById('dekraZipcodeInput') as HTMLInputElement).value = popUser.visitingZipcode;

            (document.getElementById('dekraPostStreetInput') as HTMLInputElement).value = popUser.postStreet;
            (document.getElementById('dekraPostCityInput') as HTMLInputElement).value = popUser.postCity;
            (document.getElementById('dekraPostZipcodeInput') as HTMLInputElement).value = popUser.postZipcode;
        }
    }

    public onSelectDekraFacilitatorChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        if (event.target.value) {
            if (event.target.value == "newDekraFacilitator") {
                this.populateDekraFacilitator(null);
                this.enableDekraFacilitatorInputs();
            } else {
                this.populateDekraFacilitator(this.props.users.find(user => user.userName == event.target.value));
                this.disableDekraFacilitatorInputs();
            }
        } else {
            this.populateDekraFacilitator(null);
        }

        //const foundContact = this.props.userList.users.find((user) => user.userName === event.target.value);

        //this.setState({
        //    currentFacilitator: foundContact
        //});
    }

    private enableDekraFacilitatorInputs() {
        const facInputs = document.getElementsByClassName('dekraModalInput');

        for (var i = 0; i < facInputs.length; i++) {
            facInputs[i].removeAttribute('disabled')
        }
    }

    private disableDekraFacilitatorInputs() {
        const facInputs = document.getElementsByClassName('dekraModalInput');

        for (var i = 0; i < facInputs.length; i++) {
            facInputs[i].setAttribute('disabled', '');
        }
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private onOpen() {
        if (this.props.creatingNewUser) {
            const userNameInput = (document.getElementById('userNameInput') as HTMLInputElement);
            userNameInput.focus();
            userNameInput.removeAttribute('disabled');
            userNameInput.value = "";
            (document.getElementById('firstNameInput') as HTMLInputElement).value = "";
            (document.getElementById('lastNameInput') as HTMLInputElement).value = "";
            (document.getElementById('titleInput') as HTMLInputElement).value = "";

            (document.getElementById('emailInput') as HTMLInputElement).value = "";
            (document.getElementById('phoneInput') as HTMLInputElement).value = "";

            (document.getElementById('permissionsInput') as HTMLInputElement).value = '0';
            (document.getElementById('streetInput') as HTMLInputElement).value = "";
            (document.getElementById('cityInput') as HTMLInputElement).value = "";
            (document.getElementById('zipcodeInput') as HTMLInputElement).value = "";

            (document.getElementById('postStreetInput') as HTMLInputElement).value = "";
            (document.getElementById('postCityInput') as HTMLInputElement).value = "";
            (document.getElementById('postZipcodeInput') as HTMLInputElement).value = "";

            let emptyUser: UserListObject = {
                customer: null,
                customerId: '',
                dekraContact: null,
                dekraContactId: '',
                displayName: '',
                email: '',
                firstName: '',
                id: '',
                lastName: '',
                phoneExchangeNumber: '',
                phoneNumber: '',
                postCity: '',
                postStreet: '',
                postZipcode: '',
                roles: [],
                title: '',
                userName: '',
                visitingCity: '',
                visitingStreet: '',
                visitingZipcode: '',
                website: '',
                mobilePhoneNumber: "",
                signatureLogo: '',
            }

            this.props.setChosenUser(emptyUser);
        } else {
            (document.getElementById('userNameInput') as HTMLInputElement).setAttribute('disabled', '');
        }
    }

    private handleChangeUploadSignatureLogo = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    (document.getElementById('signatureLogo') as HTMLInputElement).src = reader.result.toString();
                }
            };
            reader.readAsDataURL(event.target.files[i]);
        }
    };

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.show} onOpened={() => this.onOpen()} size="lg">
                    <ModalHeader>
                        {this.props.creatingNewUser ? t('CreateNewUser') : t('ChangeDekraInformation')}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <h5 style={{ color: "#007D40" }}>{t('GeneralInformation')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Permissions')}</Label>
                                                <Input type="select" bssize="sm" id="permissionsInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.roles.length > 0 ? this.props.chosenUser.roles[0] : 0}
                                                    onChange={(event) => this.onRolesSelectChange(event)}
                                                    placeholder="" >
                                                    <option key={"permission0"} id="permission0" value={0}>{t('NoRolesChosen')}</option>
                                                    {/*<option key={"permission1"} id="permission1" value="Customer">{t('Customer')}</option>*/}
                                                    <option key={"permission2"} id="permission2" value="DEKRA">{t('DekraContact')}</option>
                                                    <option key={"permission3"} id="permission3" value="Admin">{t('Administrator')}</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Username')}</Label>
                                                <Input type="text" bssize="sm" id="userNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.userName}
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        {this.state.errorList.get('userNameMissing') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="userNameMissing" style={{ color: "red" }} for="" size="sm">{t('UserNameMissing_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        {this.state.errorList.get('userNameTaken') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="userNameTaken" style={{ color: "red" }} for="" size="sm">{t('UserNameTaken_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('FirstName')}</Label>
                                                <Input type="text" bssize="sm" id="firstNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.firstName}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('LastName')}</Label>
                                                <Input type="text" bssize="sm" id="lastNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.lastName}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Title')}</Label>
                                                <Input type="text" bssize="sm" id="titleInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.title}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Email')}</Label>
                                                <Input type="text" bssize="sm" id="emailInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.email}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Phone')}</Label>
                                                <Input type="text" bssize="sm" id="phoneInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.phoneNumber}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Button type="button" id="UploadLogo" name="UploadLogo" className="btn btn-sm btn-primary btn-file" >
                                                    {t('Upload')} {t('Signature')}<input type="file" onChange={event => this.handleChangeUploadSignatureLogo(event)} />
                                                </Button>
                                                <Button type="button" color="link" size="sm" id={"LogoInfo"} name={"LogoInfo"} >
                                                    <i className="fas fa-info-circle"></i>
                                                </Button>
                                                <span>&nbsp;</span>
                                                <UncontrolledPopover trigger="focus" placement="bottom" target={"LogoInfo"}>
                                                    <PopoverHeader>{t('Signature')}</PopoverHeader>
                                                    <PopoverBody>{t('InfoLogo')}</PopoverBody>
                                                </UncontrolledPopover>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="modal-distancer" />
                                    <Row form>
                                        <Col sm={12}>
                                            <div className="text-center customer-logo-border">
                                                <img id="signatureLogo" className="signature-logo" src={this.props.chosenUser.signatureLogo === "" || this.props.chosenUser.signatureLogo === null ? logo : this.props.chosenUser.signatureLogo } alt="DEKRA" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <h5 style={{ color: "#007D40" }}>{t('VisitingAddress')} {t('forTheUser')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Street')}</Label>
                                                <Input type="text" bssize="sm" id="streetInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.visitingStreet}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="zipcodeInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.visitingZipcode}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('City')}</Label>
                                                <Input type="text" bssize="sm" id="cityInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.visitingCity}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div style={{ marginBottom: "6px" }} className="modal-distancer" />
                                        <h5 style={{ color: "#007D40" }}>{t('PostAddress')} {t('forTheUser')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('DeliveryAddress')}</Label>
                                                <Input type="text" bssize="sm" id="postStreetInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.postStreet}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="postZipcodeInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.postZipcode}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('PostCity')}</Label>
                                                <Input type="text" bssize="sm" id="postCityInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.postCity}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Row >
                            {this.errorsExist() ?
                                <Col className="no-padding" sm={12}>
                                    <Label className="fadeInFromLeftClass error-text" for="" size="sm">{t('ThereWereErrors_')}</Label>
                                </Col>
                                : null}
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Button color="primary" onClick={(event) => this.confirmModal(event)}>{t('OK')}</Button>
                                {' '}
                                <Button color="secondary" onClick={(event) => this.onClickToggleShowModal(event)}>{t('Cancel')}</Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.userList;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    UserListStore.actionCreators // Selects which action creators are merged into the component's props
)(DekraListModal as any);