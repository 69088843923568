﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { CustomerObject } from './CustomerObject';
import authHeader from './models/auth-header';
import { UpdateCustomerRequest } from './models/requests/UpdateCustomerRequest';
import { CreateCustomerRequest } from './models/requests/CreateCustomerRequest';
import { UserListObject } from './UserListObject';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CustomerListState {
    customers: Array<CustomerObject>;
    newCustomer: CustomerObject;
    searchResult: Array<CustomerObject>;
    searchTerm: string;
    paginationPageIndex: number;
    paginationPageSize: number;
    editModalOpen: boolean;
    currentlyLoading: boolean;
    listOfUsers: Array<UserListObject>;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetLoading {
    type: 'SET_LOADING';
    currentlyLoading: boolean;
}

export interface SetSettings {
    type: 'SET_SETTINGS';
    name: string
}

export interface SetCustomerList {
    type: 'SET_CUSTOMER_LIST';
    customers: Array<CustomerObject>
}

export interface SetUserList {
    type: 'SET_CUSTOMER_USER_LIST';
    listOfUsers: Array<UserListObject>
}

export interface ToggleEditModal {
    type: 'TOGGLE_CUSTOMER_EDIT_MODAL_OPEN';
    editModalOpen: boolean;
}

export interface SetPaginationPageIndexAction {
    type: 'SET_PAGINATION_PAGE_INDEX';
    paginationPageIndex: number;
}

export interface SetSearchTerm {
    type: 'SET_SEARCH_TERM';
    searchTerm: string;
}

export interface GetCustomerSearchResult {
    type: 'SET_CUSTOMER_SEARCH_RESULT';
    searchResult: Array<CustomerObject>;
}

export interface SetNewCustomer {
    type: 'SET_CUSTOMER_NEW';
    newCustomer: CustomerObject;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    SetSettings
    | SetPaginationPageIndexAction
    | SetSearchTerm
    | GetCustomerSearchResult
    | SetCustomerList
    | ToggleEditModal
    | SetNewCustomer
    | SetLoading
    | SetUserList

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    initForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {

        }
    },
    GetUserSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.customerList) {
            let newCustomerList = [];
            let searchTerm = appState.customerList!.searchTerm.toLowerCase();
            let customers: Array<CustomerObject> = appState.customerList!.customers;

            for (let i = 0; i < customers.length; i++) {
                if (searchTerm.length > -1) {
                    if (customers[i].name === "") {
                        continue;
                    }
                    if (
                        (customers[i].name && customers[i].name?.toLowerCase().search(searchTerm) > -1)
                        || (customers[i].number && customers[i].number?.toString().search(searchTerm) > -1)
                        || (customers[i].customerContact && customers[i].customerContact.visitingCity?.toLowerCase().search(searchTerm) > -1)
                        || (customers[i].customerContact && customers[i].customerContact.email?.toLowerCase().search(searchTerm) > -1)
                    ) {
                        newCustomerList.push(customers[i]);
                    }
                }
            }

            dispatch({ type: 'SET_CUSTOMER_SEARCH_RESULT', searchResult: newCustomerList });
        }
    },
    updateCustomer: (incomingCustomer: UpdateCustomerRequest, customerObj: CustomerObject, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/Customer/Update/`, {
                method: 'PUT',
                headers: authHeader.authHeader(),
                body: JSON.stringify(incomingCustomer)
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (callback !== null) {
                        callback();
                    }
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    addNewCustomer: (createCustomerRequest: CreateCustomerRequest, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.customerList) {
            fetch(`/api/Customer/Create/`, {
                method: 'POST',
                headers: authHeader.authHeader(),
                body: JSON.stringify(createCustomerRequest)
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (callback !== null) {
                        callback();
                    }
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });

            //dispatch({ type: 'SET_CUSTOMER_LIST', customers: customers });
        }
    },
    addNewCustomerToState: (customerObject: CustomerObject): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.customerList) {
            let customerList = appState.customerList.customers;

            customerList.push(customerObject);
            dispatch({ type: 'SET_CUSTOMER_LIST', customers: customerList });
        }
    },
    removeCustomer: (customerId: number, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.customerList) {
            fetch(`/api/Customer/DeleteById/${customerId}`, {
                method: 'DELETE',
                headers: authHeader.authHeader(),
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (callback !== null) {
                        callback();
                    }
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    resetSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.customerList) {
            let customers: Array<CustomerObject> = appState.customerList!.customers.filter((customer) => customer.number);

            dispatch({ type: 'SET_CUSTOMER_SEARCH_RESULT', searchResult: customers });
        }
    },
    setSearchTerm: (searchTerm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const newTerm = searchTerm;
        dispatch({ type: 'SET_SEARCH_TERM', searchTerm: newTerm });
    },
    setNewCustomer: (customer: CustomerObject): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_CUSTOMER_NEW', newCustomer: customer });
    },
    toggleEditModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {

            let editModalOpen = appState.customerList?.editModalOpen;
            dispatch({ type: 'TOGGLE_CUSTOMER_EDIT_MODAL_OPEN', editModalOpen: !editModalOpen });
        }
    },
    setCustomerList: (newCustomer: CustomerObject, rowNumber: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const customers = appState.customerList!.customers;
        console.log('newCustomer');
        console.log(newCustomer);
        customers[rowNumber] = newCustomer;
        dispatch({
            type: 'SET_CUSTOMER_LIST',
            customers: customers
        });
    },
    clearEmptyCustomersList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const customers = appState.customerList!.customers.filter((customer) => {
            return customer.number;
        });

        dispatch({
            type: 'SET_CUSTOMER_LIST',
            customers: customers
        });
    },
    clearEmptyCustomersSearch: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const searchresult = appState.customerList!.searchResult.filter((customer) => {
            return customer.number;
        });

        dispatch({ type: 'SET_CUSTOMER_SEARCH_RESULT', searchResult: searchresult });
    },
    setPaginationPageIndex: (pageIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let paginationPageIndex = pageIndex
            dispatch({ type: 'SET_PAGINATION_PAGE_INDEX', paginationPageIndex: paginationPageIndex });
        }
    },
    receiveCustomerList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }
        dispatch({ type: 'SET_LOADING', currentlyLoading: true });

        if (tokenStr) {
            fetch(`/api/Customer/GetAll/`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    dispatch({
                        type: 'SET_CUSTOMER_LIST',
                        customers: res.data
                    });
                    // Update SearchResult
                    const appState = getState();
                    let searchTerm = appState.customerList!.searchTerm.toLowerCase();
                    if (searchTerm.length > -1) {
                        let newCustomerList = [];
                        let customers: Array<CustomerObject> = appState.customerList!.customers;
                        for (let i = 0; i < customers.length; i++) {
                            if (searchTerm.length > -1) {
                                if (customers[i].name === "") {
                                    continue;
                                }
                                if (
                                    (customers[i].name && customers[i].name?.toLowerCase().search(searchTerm) > -1)
                                    || (customers[i].number && customers[i].number?.toString().search(searchTerm) > -1)
                                    || (customers[i].customerContact && customers[i].customerContact.visitingCity?.toLowerCase().search(searchTerm) > -1)
                                    || (customers[i].customerContact && customers[i].customerContact.email?.toLowerCase().search(searchTerm) > -1)
                                ) {
                                    newCustomerList.push(customers[i]);
                                }
                            }
                        }

                        dispatch({ type: 'SET_CUSTOMER_SEARCH_RESULT', searchResult: newCustomerList });
                    }
                    dispatch({ type: 'SET_LOADING', currentlyLoading: false });

                    //dispatch({ type: 'SET_LOADING', currentlyLoading: false });

                    //dispatch({ type: 'SET_USER_LIST', userList: newUsers });
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    receiveUserList: (): AppThunkAction<KnownAction> => (dispatch) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        dispatch({ type: 'SET_LOADING', currentlyLoading: true });

        if (tokenStr) {
            fetch(`/api/User/GetAllOrderBy/firstName`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    dispatch({
                        type: 'SET_CUSTOMER_USER_LIST',
                        listOfUsers: res.data
                    });

                    dispatch({ type: 'SET_LOADING', currentlyLoading: false });

                    //dispatch({ type: 'SET_LOADING', currentlyLoading: false });

                    //dispatch({ type: 'SET_USER_LIST', userList: newUsers });
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    getTokenFromUsername: (username: string): AppThunkAction<KnownAction> => () => {
        fetch(`/api/User/GetToken/${username}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                console.log('getToken');
                console.log(res.data);
                const result = res.data.redirect as String;
                const newToken = result.split('=')[1];
                console.log(newToken);
                sessionStorage.setItem('savedToken', newToken);
                window.location.reload();
                //return res.data;
            }))
            .catch(error => {
                console.log('error');
                console.log(error);
            });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

let value = localStorage.getItem('token');

const unloadedState: CustomerListState =
{
    customers: [],
    listOfUsers: [],
    newCustomer: {
        number: "",
        id: 0,
        name: "",
        customerLogo: "",
        customerContactId: "",
        customerContact: {
            userName: '',
            firstName: '',
            lastName: '',
            displayName: '',

            title: '',
            email: '',

            phoneNumber: '',
            mobilePhoneNumber: '',
            phoneExchangeNumber: '',
            roles: [],

            visitingCity: '',
            visitingZipcode: '',
            visitingStreet: '',

            postCity: '',
            postZipcode: '',
            postStreet: '',

            dekraContact: null,
            dekraContactId: '',

            customer: null,
            customerId: '',
            website: '',
            id: '',
            signatureLogo: '',
        },
    },
    paginationPageIndex: 0,
    paginationPageSize: 0,
    searchResult: [],
    searchTerm: '',
    editModalOpen: false,
    currentlyLoading: false
};

export const reducer: Reducer<CustomerListState> = (state: CustomerListState | undefined, incomingAction: Action): CustomerListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_LOADING':
            return {
                listOfUsers: state.listOfUsers,
                customers: state.customers,
                newCustomer: state.newCustomer,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                currentlyLoading: action.currentlyLoading
            }

        case 'SET_SETTINGS':
            return {
                listOfUsers: state.listOfUsers,
                customers: state.customers,
                newCustomer: state.newCustomer,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                currentlyLoading: state.currentlyLoading
            }

        case 'TOGGLE_CUSTOMER_EDIT_MODAL_OPEN':
            return {
                listOfUsers: state.listOfUsers,
                customers: state.customers,
                newCustomer: state.newCustomer,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: action.editModalOpen,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_CUSTOMER_LIST':
            return {
                listOfUsers: state.listOfUsers,
                customers: action.customers,
                newCustomer: state.newCustomer,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_CUSTOMER_USER_LIST':
            return {
                listOfUsers: action.listOfUsers,
                customers: state.customers,
                newCustomer: state.newCustomer,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_PAGINATION_PAGE_INDEX':
            return {
                listOfUsers: state.listOfUsers,
                customers: state.customers,
                newCustomer: state.newCustomer,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: action.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_SEARCH_TERM':
            return {
                listOfUsers: state.listOfUsers,
                customers: state.customers,
                newCustomer: state.newCustomer,
                searchResult: state.searchResult,
                searchTerm: action.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_CUSTOMER_SEARCH_RESULT':
            return {
                listOfUsers: state.listOfUsers,
                customers: state.customers,
                newCustomer: state.newCustomer,
                searchResult: action.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_CUSTOMER_NEW':
            return {
                listOfUsers: state.listOfUsers,
                customers: state.customers,
                newCustomer: action.newCustomer,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                currentlyLoading: state.currentlyLoading
            }
        default:
            return state;
    }
};
