﻿

export function authHeader()
{
    let tokenStr = null;

    if (sessionStorage.getItem('savedToken')) {
        tokenStr = sessionStorage.getItem('savedToken');
    } else {
        tokenStr = localStorage.getItem("accessToken");
    }


 
    let access_token
   
    if (tokenStr)
    {
        access_token = tokenStr;
    }
    else
    {
        access_token = "";
    }

    const myHeaders = new Headers({
        'Authorization': 'Bearer ' + access_token,
        'Content-Type': 'application/json',
    });

    return myHeaders;
}
export function authHeaderAddAccept() {
    let tokenStr = null;

    if (sessionStorage.getItem('savedToken')) {
        tokenStr = sessionStorage.getItem('savedToken');
    } else {
        tokenStr = localStorage.getItem("accessToken");
    }



    let access_token

    if (tokenStr) {
        access_token = tokenStr;
    }
    else {
        access_token = "";
    }

    const myHeaders = new Headers({
        'Authorization': 'Bearer ' + access_token,
        'Accept': "multipart/form-data"
    });

    return myHeaders;
}
export function NonAuthHeader()
{
   
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
    });

    return myHeaders;
}

export default { authHeader, NonAuthHeader, authHeaderAddAccept }