﻿import { GetMaterialGroupResponse } from "./GetMaterialGroupResponse";
import { GetTechnicalDeliveryConditionResponse } from "./GetTechnicalDeliveryConditionResponse";

export class GetBaseMaterialResponse {
    createdAt: string;
    deletedAt: string;
    description: string;
    id: number;
    materialGroup: GetMaterialGroupResponse;
    name: string;
    number: string;
    technicalDeliveryConditions: Array<GetTechnicalDeliveryConditionResponse>;
    updatedAt: string;

    public constructor() {
        this.createdAt = '';
        this.deletedAt = '';
        this.description = '';
        this.id = 0;
        this.materialGroup = new GetMaterialGroupResponse();
        this.name = '';
        this.number = '';
        this.technicalDeliveryConditions = [];
        this.updatedAt = '';
    }
}