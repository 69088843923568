﻿export class CruciformTensileTestingResultsTableRow {
    acceptance: string;
    fm: string;
    placeOfBreak: string;
    rm: string;
    testSpecimenDimensions: string;
    testSpecimenNumber: string;
    wf: string;

    public constructor(testSpecimenNumber: string, testSpecimenDimensions: string, wf: string, fm: string, rm: string, placeOfBreak: string, acceptance: string) {
        this.testSpecimenNumber = testSpecimenNumber;
        this.testSpecimenDimensions = testSpecimenDimensions;
        this.wf = wf;
        this.fm = fm;
        this.rm = rm;
        this.placeOfBreak = placeOfBreak;
        this.acceptance = acceptance;
    }
}