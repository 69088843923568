﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardText, CardTitle, Col, Form, FormGroup, Input, Label, Row, Table, Button, Container, CardSubtitle, FormText } from 'reactstrap';
import { ApplicationState } from '../store/index';
import { QWBPOrder } from '../store/models/QWBPOrder';
import * as TestingOrderStore from '../store/TestingOrderStore';
import { t } from '../i18n';

// At runtime, Redux will merge together...
type TestingOrderProps =
    TestingOrderStore.TestingOrderState // ... state we've requested from the Redux store
    & typeof TestingOrderStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    pqwbpOrder: QWBPOrder;
}

class TestingOrder extends React.PureComponent<TestingOrderProps> {
    constructor(props: TestingOrderProps) {
        super(props);
        this.props.setTestingOrder(this.props.pqwbpOrder);
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.pqwbpOrder !== this.props.pqwbpOrder) {
            this.props.setTestingOrder(this.props.pqwbpOrder);
        }
    }
    private onChangeCommissionNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCommissionNumber(event.currentTarget.value);
    }

    private onChangeDekraProcedureNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDekraProcedureNumber(event.currentTarget.value);
    }

    private onChangeManufacturerWPQRNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setManufacturerWPQRNumber(event.currentTarget.value);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private onChangeQWBPOrderChargeNumber1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderChargeNumber1(event.currentTarget.value);
    }

    private onChangeQWBPOrderChargeNumber2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderChargeNumber2(event.currentTarget.value);
    }

    private onChangeQWBPOrderConditionsAndDescriptionRemarks(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderConditionsAndDescriptionRemarks(event.currentTarget.value);
    }

    private onChangeQWBPOrderContactPerson(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderContactPerson(event.currentTarget.value);
    }

    private onChangeQWBPOrderCustomer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderCustomer(event.currentTarget.value);
    }

    private onChangeQWBPOrderDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderDate(event.currentTarget.value);
    }

    private onChangeQWBPOrderDestructiveTestingApproved(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderDestructiveTestingApproved(event.currentTarget.value);
    }

    private onChangeQWBPOrderDestructiveTestingDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderDestructiveTestingDate(event.currentTarget.value);
    }

    private onChangeQWBPOrderDestructiveTestingSignature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderDestructiveTestingSignature(event.currentTarget.value);
    }

    private onChangeQWBPOrderDimension1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        //this.props.setQWBPOrderDimension1(event.currentTarget.value);
    }

    private onChangeQWBPOrderDimension2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderDimension2(event.currentTarget.value);
    }

    private onChangeQWBPOrderDirectionOfPlateRolling1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderDirectionOfPlateRolling1(event.currentTarget.value);
    }

    private onChangeQWBPOrderDirectionOfPlateRolling2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderDirectionOfPlateRolling2(event.currentTarget.value);
    }

    private onChangeQWBPOrderFillerMaterial(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        //this.props.setQWBPOrderFillerMaterial(event.currentTarget.value);
    }
    private onChangeQWBPOrderImpactTestTemperature1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderImpactTestTemperature1(event.currentTarget.value);
    }

    private onChangeQWBPOrderImpactTestTemperature2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderImpactTestTemperature2(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO10902(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO10902AdditionalTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902AdditionalTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO10902CruciformTensileTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902CruciformTensileTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO10902ImpactTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902ImpactTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO10902MacroPlusPhoto(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902MacroPlusPhoto(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO10902MicroTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902MicroTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO10902WeldabilityPrimer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902WeldabilityPrimer(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO10902AdditionalTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902AdditionalTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO10902CruciformTensileTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902CruciformTensileTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO10902ImpactTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902ImpactTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO10902MacroPlusPhotoText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902MacroPlusPhotoText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO10902MicroTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902MicroTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO10902WeldabilityPrimerText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO10902WeldabilityPrimerText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO129526Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO129526Testing(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO129526AdditionalTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO129526AdditionalTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO129526ImpactTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO129526ImpactTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO129526LongitudinalTensileTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO129526LongitudinalTensileTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO129526AdditionalTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO129526AdditionalTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO129526ImpactTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO129526ImpactTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO129526LongitudinalTensileTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO129526LongitudinalTensileTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO13134AdditionalTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134AdditionalTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO13134AdditionalTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134AdditionalTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO13134BendTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134BendTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO13134BendTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134BendTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO13134HardnessTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134HardnessTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO13134HardnessTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134HardnessTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO13134PeelTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134PeelTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO13134PeelTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134PeelTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO13134ShearTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134ShearTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO13134ShearTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134ShearTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO13134BrazinProcedureApproval(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134BrazinProcedureApproval(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO13134TensileTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134TensileTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO13134TensileTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO13134TensileTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO134454Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454Testing(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO134454AdditionalTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454AdditionalTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO134454FerriteTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454FerriteTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO134454ImpactTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454ImpactTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO134454LongitudinalTensileTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454LongitudinalTensileTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO134454MicroTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454MicroTesting(event.currentTarget.checked);
    }


    private onChangeQWBPOrderISO134454AdditionalTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454AdditionalTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO134454FerriteTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454FerriteTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO134454ImpactTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454ImpactTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO134454LongitudinalTensileTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454LongitudinalTensileTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO134454MicroTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO134454MicroTestingText(event.currentTarget.value);
    }


    private onChangeQWBPOrderISO14555AdditionalTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555AdditionalTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO14555ApplicationGreaterThan100Degrees(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555ApplicationGreaterThan100Degrees(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO14555ApplicationLessThanOrEqualTo100Degrees(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555ApplicationLessThanOrEqualTo100Degrees(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO14555BendTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555BendTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO14555ArcStudWelding(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555ArcStudWelding(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO14555_3834Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555_3834Testing(event.currentTarget.checked);
    }
    private onChangeQWBPOrderISO14555_783Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555_783Testing(event.currentTarget.checked);
    }
    private onChangeQWBPOrderISO14555_784Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555_784Testing(event.currentTarget.checked);
    }
    private onChangeQWBPOrderISO14555_785Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555_785Testing(event.currentTarget.checked);
    }
    private onChangeQWBPOrderISO14555_786Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555_786Testing(event.currentTarget.checked);
    }
    private onChangeQWBPOrderISO14555_787Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555_787Testing(event.currentTarget.checked);
    }


    private onChangeQWBPOrderISO14555_3834TestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555_3834TestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO14555BendTestingTorqueWrench(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555BendTestingTorqueWrench(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO14555MacroPlusPhoto(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555MacroPlusPhoto(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO14555RTProvning(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555RTProvning(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO14555TensileTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555TensileTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO14555TorqueTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555TorqueTesting(event.currentTarget.checked);
    }


    private onChangeQWBPOrderISO14555AdditionalTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555AdditionalTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO14555ApplicationGreaterThan100DegreesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555ApplicationGreaterThan100DegreesText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO14555ApplicationLessThanOrEqualTo100DegreesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555ApplicationLessThanOrEqualTo100DegreesText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO14555BendTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555BendTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO14555BendTestingTorqueWrenchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555BendTestingTorqueWrenchText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO14555MacroPlusPhotoText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555MacroPlusPhotoText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO14555TensileTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555TensileTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO14555TorqueTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555TorqueTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO14555RTProvningText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO14555RTProvningText(event.currentTarget.value);
    }

    private onChangeQWBPOrderISO156141Edition2004(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO156141Edition2004(event.currentTarget.checked);
    }

    private onChangeQWBPOrderISO156141Edition2017(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderISO156141Edition2017(event.currentTarget.checked);
    }

    private onChangeQWBPOrderManufacturerWPQRBPARNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderManufacturerWPQRBPARNumber(event.currentTarget.value);
    }

    private onChangeQWBPOrderMaterial1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderMaterial1(event.currentTarget.value);
    }

    private onChangeQWBPOrderMaterial2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderMaterial2(event.currentTarget.value);
    }

    private onChangeQWBPOrderMaterialGroup1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderMaterialGroup1(event.currentTarget.value);
    }

    private onChangeQWBPOrderMaterialGroup2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderMaterialGroup2(event.currentTarget.value);
    }

    private onChangeQWBPOrderMaterialSpecifications1Remarks(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderMaterialSpecifications1Remarks(event.currentTarget.value);
    }

    private onChangeQWBPOrderMaterialSpecifications2Remarks(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderMaterialSpecifications2Remarks(event.currentTarget.value);
    }

    private onChangeQWBPOrderMTPT(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderMTPT(event.currentTarget.value);
    }

    private onChangeQWBPOrderNDTApproved(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNDTApproved(event.currentTarget.value);
    }

    private onChangeQWBPOrderNDTDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNDTDate(event.currentTarget.value);
    }

    private onChangeQWBPOrderNDTOther(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNDTOther(event.currentTarget.value);
    }

    private onChangeQWBPOrderNDTRemarks(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNDTRemarks(event.currentTarget.value);
    }

    private onChangeQWBPOrderNDTSignature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNDTSignature(event.currentTarget.value);
    }

    private onChangeQWBPOrderNORSOKAdditionalTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTesting(event.currentTarget.checked);
    }

    private onChangeQWBPOrderNORSOKAdditionalTesting1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTesting1(event.currentTarget.checked);
    }
    private onChangeQWBPOrderNORSOKAdditionalTesting2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTesting2(event.currentTarget.checked);
    }
    private onChangeQWBPOrderNORSOKAdditionalTesting3(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTesting3(event.currentTarget.checked);
    }
    private onChangeQWBPOrderNORSOKAdditionalTesting4(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTesting4(event.currentTarget.checked);
    }
    private onChangeQWBPOrderNORSOKAdditionalTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderNORSOKAdditionalTesting1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTesting1Text(event.currentTarget.value);
    }
    private onChangeQWBPOrderNORSOKAdditionalTesting2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTesting2Text(event.currentTarget.value);
    }
    private onChangeQWBPOrderNORSOKAdditionalTesting3Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTesting3Text(event.currentTarget.value);
    }
    private onChangeQWBPOrderNORSOKAdditionalTesting4Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKAdditionalTesting4Text(event.currentTarget.value);
    }

    private onChangeQWBPOrderNORSOKCorrosionTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKCorrosionTesting(event.currentTarget.checked);
    }
    private onChangeQWBPOrderNORSOK(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOK(event.currentTarget.checked);
    }


    private onChangeQWBPOrderNORSOKCorrosionTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNORSOKCorrosionTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderOrderedByLevel3(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderOrderedByLevel3(event.currentTarget.value);
    }

    private onChangeQWBPOrderOther(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderOther(event.currentTarget.value);
    }

    private onChangeQWBPOrderPhoneNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderPhoneNumber(event.currentTarget.value);
    }

    private onChangeQWBPOrderPWPSPBPS(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderPWPSPBPS(event.currentTarget.value);
        // Update in PWPS TODO
        //this.props.setHeaderPWPSNumber(event.currentTarget.value);
    }

    private onChangeQWBPOrderRT(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderRT(event.currentTarget.value);
    }

    private onChangeQWBPOrderSignature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderSignature(event.currentTarget.value);
    }

    private onChangeQWBPOrderStandardForAcceptance1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderStandardForAcceptance1(event.currentTarget.value);
    }

    private onChangeQWBPOrderStandardForAcceptance2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderStandardForAcceptance2(event.currentTarget.value);
    }
    private onChangeQWBPOrderIso15614TransverseTensilePlusRp0ReH(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614TransverseTensilePlusRp0ReH(event.currentTarget.checked);
    }
    //Iso15614
    private onChangeQWBPOrderPEDAdditionalTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderPEDAdditionalTesting(event.currentTarget.checked);
    }
    private onChangeQWBPOrderIso15614MacroPlusPhoto(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614MacroPlusPhoto(event.currentTarget.checked);
    }
    private onChangeQWBPOrderIso15614HardnessTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614HardnessTesting(event.currentTarget.checked);
    }
    private onChangeQWBPOrderIso15614ImpactTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614ImpactTesting(event.currentTarget.checked);
    }
    private onChangeQWBPOrderIso15614BendTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614BendTesting(event.currentTarget.checked);
    }
    private onChangeQWBPOrderIso15614AdditionalTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614AdditionalTesting(event.currentTarget.checked);
    }
    private onChangeQWBPOrderIso15614TestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614TestingText(event.currentTarget.value);
    }
    private onChangeQWBPOrderIso15614TransverseTensilePlusRp0ReHText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614TransverseTensilePlusRp0ReHText(event.currentTarget.value);
    }
    private onChangeQWBPOrderIso15614MacroPlusPhotoText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614MacroPlusPhotoText(event.currentTarget.value);
    }
    private onChangeQWBPOrderIso15614HardnessTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614HardnessTestingText(event.currentTarget.value);
    }
    private onChangeQWBPOrderIso15614ImpactTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614ImpactTestingText(event.currentTarget.value);
    }
    private onChangeQWBPOrderIso15614BendTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614BendTestingText(event.currentTarget.value);
    }
    private onChangeQWBPOrderIso15614AdditionalTestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderIso15614AdditionalTestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderUT(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderUT(event.currentTarget.value);
    }

    private onChangeQWBPOrderVisual(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderVisual(event.currentTarget.value);
    }

    private onChangeQWBPOrderWelderBrazerFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderWelderBrazerFirstName(event.currentTarget.value);
    }

    private onChangeQWBPOrderWelderBrazerLastName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderWelderBrazerLastName(event.currentTarget.value);
    }

    private onChangeQWBPOrderWeldingBrazingPositions(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderWeldingBrazingPositions(event.currentTarget.value);
    }

    private onChangeQWBPOrderWeldingDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderWeldingDate(event.currentTarget.value);
    }

    private onChangeQWBPOrderDekraProcedureNoTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDekraProcedureNoTesting(event.currentTarget.value);
    }
    private onChangeQWBPOrderInvoiceReferenceName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setInvoiceReferenceName(event.currentTarget.value);
    }
    private onChangeQWBPOrderUppdragsnr(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setUppdragsnr(event.currentTarget.value);
    }
    private onChangeQWBPOrderTestmarking(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTestmarking(event.currentTarget.value);
    }

    private onChangeQWBPOrderWeldingProcess(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        //this.props.setQWBPOrderWeldingProcess(event.currentTarget.value);
    }

    private onChangeQWBPOrderNACE(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNACE(event.currentTarget.checked);
    }

    private onChangeQWBPOrdernaceMr0103Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrdernaceMr0103Testing(event.currentTarget.checked);
    }

    private onChangeQWBPOrderNaceMr0175Testing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNaceMr0175Testing(event.currentTarget.checked);
    }

    private onChangeQWBPOrderNACEAdditionalTesting1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNACEAdditionalTesting1(event.currentTarget.checked);
    }


    private onChangeQWBPOrderNACEAdditionalTesting2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNACEAdditionalTesting2(event.currentTarget.checked);
    }

    private onChangeQWBPOrderNaceMr0103TestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNaceMr0103TestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderNaceMr0175TestingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNaceMr0175TestingText(event.currentTarget.value);
    }

    private onChangeQWBPOrderNACEAdditionalTesting1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNACEAdditionalTesting1Text(event.currentTarget.value);
    }

    private onChangeQWBPOrderNACEAdditionalTesting2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setQWBPOrderNACEAdditionalTesting2Text(event.currentTarget.value);
    }

    public render() {
        return (
            <React.Fragment>
                <div>
                    <Row form>
                        <Col sm={12}>
                            {this.renderQWBPOrder()}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
    private renderQWBPOrder() {
        return (
            <React.Fragment>
                <Form>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h4>{t('OrderQualificationOfWeldAndSolderingProcedure')}</h4>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderCustomer" size="sm">{t('Customer')} ({t('Manufacturer')})</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderCustomer" id="qwbpOrderCustomer"
                                                        value={this.props.qwbpOrder.customer}
                                                        onChange={(event) => this.onChangeQWBPOrderCustomer(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderContactPerson" size="sm">{t('Facilitator')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderContactPerson" id="qwbpOrderContactPerson"
                                                        value={this.props.qwbpOrder.contactPerson}
                                                        onChange={(event) => this.onChangeQWBPOrderContactPerson(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderWeldingDate" size="sm">{t('WeldingDate')}</Label>
                                                    <Input type="date" max="9999-12-30" bsSize="sm" name="qwbpOrderWeldingDate" id="qwbpOrderWeldingDate"
                                                        value={this.props.qwbpOrder.weldingDate} onChange={(event) => this.onChangeQWBPOrderWeldingDate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderPhoneNumber" size="sm">{t('PhoneNumber')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderPhoneNumber" id="qwbpOrderPhoneNumber"
                                                        value={this.props.qwbpOrder.phoneNumber}
                                                        onChange={(event) => this.onChangeQWBPOrderPhoneNumber(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderWelderBrazerFirstName" size="sm">{t('FirstName')} {t('Welder')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderWelderBrazerFirstName" id="qwbpOrderWelderBrazerFirstName"
                                                        value={this.props.qwbpOrder.welderBrazerFirstName}
                                                        onChange={(event) => this.onChangeQWBPOrderWelderBrazerFirstName(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderWelderBrazerLastName" size="sm">{t('LastName')} {t('Welder')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderWelderBrazerLastName" id="qwbpOrderWelderBrazerLastName"
                                                        value={this.props.qwbpOrder.welderBrazerLastName}
                                                        onChange={(event) => this.onChangeQWBPOrderWelderBrazerLastName(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderDekraProcedureNoTesting" size="sm">{t('DekraProcedureNo')} ({t('RefNo')})</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderDekraProcedureNoTesting" id="qwbpOrderDekraProcedureNoTesting"
                                                        value={this.props.qwbpOrder.dekraProcedureNoTesting}
                                                        onChange={(event) => this.onChangeQWBPOrderDekraProcedureNoTesting(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderInvoiceReferenceName" size="sm">{t('InvoiceReferenceName')} ({t('Name')})</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderInvoiceReferenceName" id="qwbpOrderInvoiceReferenceName"
                                                        value={this.props.qwbpOrder.invoiceReferenceName}
                                                        onChange={(event) => this.onChangeQWBPOrderInvoiceReferenceName(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderUppdragsnr" size="sm">{t('TaskNumber')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderUppdragsnr" id="qwbpOrderUppdragsnr"
                                                        value={this.props.qwbpOrder.uppdragsnr}
                                                        onChange={(event) => this.onChangeQWBPOrderUppdragsnr(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderTestmarking" size="sm">{t('TestMarking')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderTestmarking" id="qwbpOrderTestmarking"
                                                        value={this.props.qwbpOrder.testmarking}
                                                        onChange={(event) => this.onChangeQWBPOrderTestmarking(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('WeldingSolderingTerms')}</h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderPWPSPBPS" size="sm">pWPS/pBPS</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderPWPSPBPS" id="qwbpOrderPWPSPBPS"
                                                        value={this.props.qwbpOrder.pWPSPBPS}
                                                        onChange={(event) => this.onChangeQWBPOrderPWPSPBPS(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={5}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderManufacturerWPQRBPARNumber" size="sm">{t('Manufacturers')} WPQR/BPAR {t('Number')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderManufacturerWPQRBPARNumber" id="qwbpOrderManufacturerWPQRBPARNumber"
                                                        value={this.props.qwbpOrder.manufacturerWPQRBPARNumber}
                                                        onChange={(event) => this.onChangeQWBPOrderManufacturerWPQRBPARNumber(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderWeldingBrazingPositions" size="sm">{t('WeldingBrazingPositions')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderWeldingBrazingPositions" id="qwbpOrderWeldingBrazingPositions"
                                                        value={this.props.qwbpOrder.weldingBrazingPositions}
                                                        onChange={(event) => this.onChangeQWBPOrderWeldingBrazingPositions(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderWeldingProcess" size="sm">{t('WeldingMethod')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderWeldingProcess" id="qwbpOrderWeldingProcess"
                                                        value={this.props.qwbpOrder.weldingProcess}
                                                        onChange={(event) => this.onChangeQWBPOrderWeldingProcess(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderFillerMaterial" size="sm">{t('AdditivesPowder')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderFillerMaterial" id="qwbpOrderFillerMaterial"
                                                        value={this.props.qwbpOrder.fillerMaterial}
                                                        onChange={(event) => this.onChangeQWBPOrderFillerMaterial(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderConditionsAndDescriptionRemarks" size="sm">{t('Remark')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="qwbpOrderConditionsAndDescriptionRemarks" id="qwbpOrderConditionsAndDescriptionRemarks"
                                                        value={this.props.qwbpOrder.conditionsAndDescriptionRemarks} onChange={(event) => this.onChangeQWBPOrderConditionsAndDescriptionRemarks(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('FollowingOFPShouldBeDone')}</h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderVisual" size="sm">{t('Visual')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderVisual" id="qwbpOrderVisual"
                                                        value={this.props.qwbpOrder.visual}
                                                        onChange={(event) => this.onChangeQWBPOrderVisual(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderMTPT" size="sm">MT/PT</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderMTPT" id="qwbpOrderMTPT"
                                                        value={this.props.qwbpOrder.mtPT}
                                                        onChange={(event) => this.onChangeQWBPOrderMTPT(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderRT" size="sm">RT</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderRT" id="qwbpOrderRT"
                                                        value={this.props.qwbpOrder.rt}
                                                        onChange={(event) => this.onChangeQWBPOrderRT(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderUT" size="sm">UT</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderUT" id="qwbpOrderUT"
                                                        value={this.props.qwbpOrder.ut}
                                                        onChange={(event) => this.onChangeQWBPOrderUT(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderNDTOther" size="sm">{t('Other')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="qwbpOrderNDTOther" id="qwbpOrderNDTOther"
                                                        value={this.props.qwbpOrder.ndtOther} onChange={(event) => this.onChangeQWBPOrderNDTOther(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderNDTRemarks" size="sm">{t('Remark')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="qwbpOrderNDTRemarks" id="qwbpOrderNDTRemarks"
                                                        value={this.props.qwbpOrder.ndtRemarks} onChange={(event) => this.onChangeQWBPOrderNDTRemarks(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('MaterialSpecifications')}</h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderMaterial1" size="sm">{t('Material')} 1</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderMaterial1" id="qwbpOrderMaterial1"
                                                        value={this.props.qwbpOrder.material1}
                                                        onChange={(event) => this.onChangeQWBPOrderMaterial1(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderMaterialGroup1" size="sm">{t('MaterialGroup')} CR/ISO 15608 1</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderMaterialGroup1" id="qwbpOrderMaterialGroup1"
                                                        value={this.props.qwbpOrder.materialGroup1}
                                                        onChange={(event) => this.onChangeQWBPOrderMaterialGroup1(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderDimension1" size="sm">{t('Dimension')} Dxt (mm) 1</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderDimension1" id="qwbpOrderDimension1"
                                                        value={this.props.qwbpOrder.dimension1}
                                                        onChange={(event) => this.onChangeQWBPOrderDimension1(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderStandardForAcceptance1" size="sm">{t('StandardForAcceptance')} 1</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderStandardForAcceptance1" id="qwbpOrderStandardForAcceptance1"
                                                        value={this.props.qwbpOrder.standardForAcceptance1}
                                                        onChange={(event) => this.onChangeQWBPOrderStandardForAcceptance1(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderChargeNumber1" size="sm">{t('ChargeNumber')} 1</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderChargeNumber1" id="qwbpOrderChargeNumber1"
                                                        value={this.props.qwbpOrder.chargeNumber1}
                                                        onChange={(event) => this.onChangeQWBPOrderChargeNumber1(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderImpactTestTemperature1" size="sm">{t('ImpactTestTemperature')} 1</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderImpactTestTemperature1" id="qwbpOrderImpactTestTemperature1"
                                                        value={this.props.qwbpOrder.impactTestTemperature1}
                                                        onChange={(event) => this.onChangeQWBPOrderImpactTestTemperature1(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderDirectionOfPlateRolling1" size="sm">{t('DirectionOfPlateRolling')} 1</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderDirectionOfPlateRolling1" id="qwbpOrderDirectionOfPlateRolling1"
                                                        value={this.props.qwbpOrder.directionOfPlateRolling1}
                                                        onChange={(event) => this.onChangeQWBPOrderDirectionOfPlateRolling1(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderMaterialSpecifications1Remarks" size="sm">{t('OtherRemarks')} 1</Label>
                                                    <Input type="textarea" bsSize="sm" name="qwbpOrderMaterialSpecifications1Remarks" id="qwbpOrderMaterialSpecifications1Remarks"
                                                        value={this.props.qwbpOrder.materialSpecifications1Remarks} onChange={(event) => this.onChangeQWBPOrderMaterialSpecifications1Remarks(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderMaterial2" size="sm">{t('Material')} 2</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderMaterial2" id="qwbpOrderMaterial2"
                                                        value={this.props.qwbpOrder.material2}
                                                        onChange={(event) => this.onChangeQWBPOrderMaterial2(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderMaterialGroup2" size="sm">{t('MaterialGroup')} CR/ISO 15608 2</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderMaterialGroup2" id="qwbpOrderMaterialGroup2"
                                                        value={this.props.qwbpOrder.materialGroup2}
                                                        onChange={(event) => this.onChangeQWBPOrderMaterialGroup2(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderDimension2" size="sm">{t('Dimension')} Dxt (mm) 2</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderDimension2" id="qwbpOrderDimension2"
                                                        value={this.props.qwbpOrder.dimension2}
                                                        onChange={(event) => this.onChangeQWBPOrderDimension2(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderStandardForAcceptance2" size="sm">{t('StandardForAcceptance')} 2</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderStandardForAcceptance2" id="qwbpOrderStandardForAcceptance2"
                                                        value={this.props.qwbpOrder.standardForAcceptance2}
                                                        onChange={(event) => this.onChangeQWBPOrderStandardForAcceptance2(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderChargeNumber2" size="sm">{t('ChargeNumber')} 2</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderChargeNumber2" id="qwbpOrderChargeNumber2"
                                                        value={this.props.qwbpOrder.chargeNumber2}
                                                        onChange={(event) => this.onChangeQWBPOrderChargeNumber2(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderImpactTestTemperature2" size="sm">{t('ImpactTestTemperature')} 2</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderImpactTestTemperature2" id="qwbpOrderImpactTestTemperature2"
                                                        value={this.props.qwbpOrder.impactTestTemperature2}
                                                        onChange={(event) => this.onChangeQWBPOrderImpactTestTemperature2(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderDirectionOfPlateRolling2" size="sm">{t('DirectionOfPlateRolling')} 2</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderDirectionOfPlateRolling2" id="qwbpOrderDirectionOfPlateRolling2"
                                                        value={this.props.qwbpOrder.directionOfPlateRolling2}
                                                        onChange={(event) => this.onChangeQWBPOrderDirectionOfPlateRolling2(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderMaterialSpecifications2Remarks" size="sm">{t('OtherRemarks')} 2</Label>
                                                    <Input type="textarea" bsSize="sm" name="qwbpOrderMaterialSpecifications2Remarks" id="qwbpOrderMaterialSpecifications2Remarks"
                                                        value={this.props.qwbpOrder.materialSpecifications2Remarks} onChange={(event) => this.onChangeQWBPOrderMaterialSpecifications2Remarks(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <a color="muted">
                                                        {t('FollowingMechanicalTesting...')}
                                                    </a>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <Row form>
                                            <Col sm={3}>
                                                <h5>
                                                    <Form inline>
                                                        SS-EN ISO 15614-1
                                                    </Form>
                                                </h5>
                                            </Col>
                                            <Col sm={3}>
                                                <h5>
                                                    <Form inline>
                                                        {t('Edition')}
                                                        <span>&nbsp;</span>
                                                        <FormGroup check>
                                                            <Label check size="sm">
                                                                <Input type="checkbox" bssize="sm" name="qwbpOrderISO156141Edition2004" id="qwbpOrderISO156141Edition2004"
                                                                    checked={this.props.qwbpOrder.iso156141Edition2004}
                                                                    onChange={(event) => this.onChangeQWBPOrderISO156141Edition2004(event)}
                                                                    placeholder="" />{' '} 2004
                                                            </Label>
                                                        </FormGroup>
                                                        <span>&nbsp;</span>
                                                        <FormGroup check>
                                                            <Label check size="sm">
                                                                <Input type="checkbox" bssize="sm" name="qwbpOrderISO156141Edition2017" id="qwbpOrderISO156141Edition2017"
                                                                    checked={this.props.qwbpOrder.iso156141Edition2017}
                                                                    onChange={(event) => this.onChangeQWBPOrderISO156141Edition2017(event)}
                                                                    placeholder="" />{' '} 2017
                                                            </Label>
                                                        </FormGroup>
                                                    </Form>
                                                </h5>
                                            </Col>
                                            <Col sm={6}>
                                                <h5>
                                                    <Form inline>
                                                        {t('OtherPartOf')} EN ISO 15614
                                                        <span>&nbsp;</span>
                                                        <FormGroup>
                                                            <Input type="text" bssize="sm" name="qwbpOrderiso15614TestingText" id="qwbpOrderiso15614TestingText"
                                                                value={this.props.qwbpOrder.iso15614TestingText}
                                                                onChange={(event) => this.onChangeQWBPOrderIso15614TestingText(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup>
                                                    </Form>
                                                </h5>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderTransverseTensilePlusRp0ReH" id="qwbpOrderTransverseTensilePlusRp0ReH"
                                                            checked={this.props.qwbpOrder.transverseTensilePlusRp0ReH}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614TransverseTensilePlusRp0ReH(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('TransverseTensileTesting')} + Rp02/ReH</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderTransverseTensilePlusRp0TestingText" id="qwbpOrderTransverseTensilePlusRp0TestingText"
                                                            value={this.props.qwbpOrder.iso15614transverseTensilePlusRp0ReHText}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614TransverseTensilePlusRp0ReHText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderMacroPlusPhoto" id="qwbpOrderMacroPlusPhoto"
                                                            checked={this.props.qwbpOrder.macroPlusPhoto}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614MacroPlusPhoto(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('Macro+Photo')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderMacroPlusTestingText" id="qwbpOrderMacroPlusTestingText"
                                                            value={this.props.qwbpOrder.iso15614macroPlusPhotoText}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614MacroPlusPhotoText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderHardnessTesting" id="qwbpOrderHardnessTesting"
                                                            checked={this.props.qwbpOrder.hardnessTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614HardnessTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('HardnessTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderHardnessTestingText" id="qwbpOrderHardnessTestingText"
                                                            value={this.props.qwbpOrder.iso15614hardnessTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614HardnessTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderImpactTesting" id="qwbpOrderImpactTesting"
                                                            checked={this.props.qwbpOrder.impactTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614ImpactTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('ImpactTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderImpactTestingText" id="qwbpOrderImpactTestingText"
                                                            value={this.props.qwbpOrder.iso15614impactTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614ImpactTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderBendTesting" id="qwbpOrderBendTesting"
                                                            checked={this.props.qwbpOrder.bendTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614BendTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('BendTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderBendTestingText" id="qwbpOrderBendTestingText"
                                                            value={this.props.qwbpOrder.iso15614bendTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614BendTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderAdditionalTesting" id="qwbpOrderAdditionalTesting"
                                                            checked={this.props.qwbpOrder.additionalTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614AdditionalTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderAdditionalTestingText" id="qwbpOrderAdditionalTestingText"
                                                            value={this.props.qwbpOrder.iso15614additionalTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderIso15614AdditionalTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Row form>
                                            <Col sm={12}>
                                                <h5 className="h5_margin-bottom_0">
                                                    <Form inline>
                                                        <FormGroup check>
                                                            <Input type="checkbox" bssize="sm" name="qwbpOrderPEDTesting" id="qwbpOrderPEDTesting"
                                                                checked={this.props.qwbpOrder.pedAdditionalTesting}
                                                                onChange={(event) => this.onChangeQWBPOrderPEDAdditionalTesting(event)}
                                                                placeholder="" />
                                                        </FormGroup>
                                                        PED 2014/68/EU {t('AdditionalTesting')}
                                                    </Form>
                                                </h5>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>
                                            <Form inline>
                                                <FormGroup check>
                                                    <Input type="checkbox" bssize="sm" name="qwbpOrderISO134454Testing" id="qwbpOrderISO134454Testing"
                                                        checked={this.props.qwbpOrder.iso134454Testing}
                                                        onChange={(event) => this.onChangeQWBPOrderISO134454Testing(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                                SS-EN 13445-4 {t('PressureVessel')} ({t('unaffectedByFire')})-{t('Part')} 4: {t('Manufacturing')}
                                            </Form>
                                        </h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO134454ImpactTesting" id="qwbpOrderISO134454ImpactTesting"
                                                            checked={this.props.qwbpOrder.iso134454ImpactTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454ImpactTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('ImpactTesting')} (t &gt; 5 mm)</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO134454ImpactTestingText" id="qwbpOrderISO134454ImpactTestingText"
                                                            value={this.props.qwbpOrder.iso134454ImpactTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454ImpactTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO134454MicroTesting" id="qwbpOrderISO134454MicroTesting"
                                                            checked={this.props.qwbpOrder.iso134454MicroTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454MicroTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('Microtesting')} ({t('MaterialGroup')} 8.2 & 10)</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO134454MicroTestingText" id="qwbpOrderISO134454MicroTestingText"
                                                            value={this.props.qwbpOrder.iso134454MicroTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454MicroTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO134454FerriteTesting" id="qwbpOrderISO134454FerriteTesting"
                                                            checked={this.props.qwbpOrder.iso134454FerriteTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454FerriteTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('FerriteTesting')} ({t('MaterialGroup')} 10)</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO134454FerriteTestingText" id="qwbpOrderISO134454FerriteTestingText"
                                                            value={this.props.qwbpOrder.iso134454FerriteTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454FerriteTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO134454LongitudinalTensileTesting" id="qwbpOrderISO134454LongitudinalTensileTesting"
                                                            checked={this.props.qwbpOrder.iso134454LongitudinalTensileTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454LongitudinalTensileTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('LongitudinalTensileTesting')} (t &gt; 20 mm)</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO134454LongitudinalTensileTestingText" id="qwbpOrderISO134454LongitudinalTensileTestingText"
                                                            value={this.props.qwbpOrder.iso134454LongitudinalTensileTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454LongitudinalTensileTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO134454AdditionalTesting" id="qwbpOrderISO134454AdditionalTesting"
                                                            checked={this.props.qwbpOrder.iso134454AdditionalTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454AdditionalTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO134454AdditionalTestingText" id="qwbpOrderISO134454AdditionalTestingText"
                                                            value={this.props.qwbpOrder.iso134454AdditionalTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO134454AdditionalTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>
                                            <Form inline>
                                                <FormGroup check>
                                                    <Input type="checkbox" bssize="sm" name="qwbpOrderNORSOK" id="qwbpOrderNORSOK"
                                                        checked={this.props.qwbpOrder.iso129526Testing}
                                                        onChange={(event) => this.onChangeQWBPOrderISO129526Testing(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                                SS-EN 12952-6 {t('WaterPipeBoiler')} {t('and')} {t('HelpInstallations')}-{t('part')} 6: {t('ManufacturingControl')}
                                            </Form>
                                        </h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO129526LongitudinalTensileTesting" id="qwbpOrderISO129526LongitudinalTensileTesting"
                                                            checked={this.props.qwbpOrder.iso129526LongitudinalTensileTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO129526LongitudinalTensileTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('LongitudinalTensileTesting')} (t &gt; 20 mm) ({t('MaterialGroup')} 1.1, 1.2, 2.1, 4, 5)</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO129526LongitudinalTensileTestingText" id="qwbpOrderISO129526LongitudinalTensileTestingText"
                                                            value={this.props.qwbpOrder.iso129526LongitudinalTensileTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO129526LongitudinalTensileTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO129526ImpactTesting" id="qwbpOrderISO129526ImpactTesting"
                                                            checked={this.props.qwbpOrder.iso129526ImpactTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO129526ImpactTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('ImpactTesting')} (T = 20°)</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO129526ImpactTestingText" id="qwbpOrderISO129526ImpactTestingText"
                                                            value={this.props.qwbpOrder.iso129526ImpactTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO129526ImpactTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO129526AdditionalTesting" id="qwbpOrderISO129526AdditionalTesting"
                                                            checked={this.props.qwbpOrder.iso129526AdditionalTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO129526AdditionalTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO129526AdditionalTestingText" id="qwbpOrderISO129526AdditionalTestingText"
                                                            value={this.props.qwbpOrder.iso129526AdditionalTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO129526AdditionalTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>
                                            <Form inline>
                                                <FormGroup check>
                                                    <Input type="checkbox" bssize="sm" name="qwbpOrderIso10902" id="qwbpOrderIso10902"
                                                        checked={this.props.qwbpOrder.iso10902}
                                                        onChange={(event) => this.onChangeQWBPOrderISO10902(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                                SS-EN 1090-2 {t('ExecutionOfSteelAndAluminumConstructions')}-{t('Part')} 2: {t('SteelConstructions')}
                                            </Form>
                                        </h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO10902CruciformTensileTesting" id="qwbpOrderISO10902CruciformTensileTesting"
                                                            checked={this.props.qwbpOrder.iso10902CruciformTensileTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902CruciformTensileTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('CruciformTensileTesting')} (≥ S460)</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO10902CruciformTensileTestingText" id="qwbpOrderISO10902CruciformTensileTestingText"
                                                            value={this.props.qwbpOrder.iso10902CruciformTensileTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902CruciformTensileTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO10902MacroPlusPhoto" id="qwbpOrderISO10902MacroPlusPhoto"
                                                            checked={this.props.qwbpOrder.iso10902MacroPlusPhoto}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902MacroPlusPhoto(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('Macro+Photo')} (S {t('measure')})</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO10902MacroPlusPhotoText" id="qwbpOrderISO10902MacroPlusPhotoText"
                                                            value={this.props.qwbpOrder.iso10902MacroPlusPhotoText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902MacroPlusPhotoText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO10902WeldabilityPrimer" id="qwbpOrderISO10902WeldabilityPrimer"
                                                            checked={this.props.qwbpOrder.iso10902WeldabilityPrimer}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902WeldabilityPrimer(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('WeldabilityPrimer')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO10902WeldabilityPrimerText" id="qwbpOrderISO10902WeldabilityPrimerText"
                                                            value={this.props.qwbpOrder.iso10902WeldabilityPrimerText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902WeldabilityPrimerText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO10902ImpactTesting" id="qwbpOrderISO10902ImpactTesting"
                                                            checked={this.props.qwbpOrder.iso10902ImpactTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902ImpactTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('ImpactTesting')} (min temp.)</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO10902ImpactTestingText" id="qwbpOrderISO10902ImpactTestingText"
                                                            value={this.props.qwbpOrder.iso10902ImpactTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902ImpactTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO10902MicroTesting" id="qwbpOrderISO10902MicroTesting"
                                                            checked={this.props.qwbpOrder.iso10902MicroTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902MicroTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('Microtesting')} ({t('Material')} EN 10025-6)</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO10902MicroTestingText" id="qwbpOrderISO10902MicroTestingText"
                                                            value={this.props.qwbpOrder.iso10902MicroTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902MicroTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO10902AdditionalTesting" id="qwbpOrderISO10902AdditionalTesting"
                                                            checked={this.props.qwbpOrder.iso10902AdditionalTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902AdditionalTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO10902AdditionalTestingText" id="qwbpOrderISO10902AdditionalTestingText"
                                                            value={this.props.qwbpOrder.iso10902AdditionalTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO10902AdditionalTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>
                                            <Form inline>
                                                <FormGroup check>
                                                    <Input type="checkbox" bssize="sm" name="qwbpOrderNORSOK" id="qwbpOrderNORSOK"
                                                        checked={this.props.qwbpOrder.norsok}
                                                        onChange={(event) => this.onChangeQWBPOrderNORSOK(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                                NORSOK
                                            </Form>
                                        </h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNORSOKCorrosionTesting" id="qwbpOrderNORSOKCorrosionTesting"
                                                            checked={this.props.qwbpOrder.norsokCorrosionTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKCorrosionTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('CorrosionTesting')} NORSOK M-601</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNORSOKCorrosionTestingText" id="qwbpOrderNORSOKCorrosionTestingText"
                                                            value={this.props.qwbpOrder.norsokCorrosionTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKCorrosionTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNORSOKAdditionalTesting" id="qwbpOrderNORSOKAdditionalTesting"
                                                            checked={this.props.qwbpOrder.norsokAdditionalTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')} NORSOK M-101</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNORSOKAdditionalTestingText" id="qwbpOrderNORSOKAdditionalTestingText"
                                                            value={this.props.qwbpOrder.norsokAdditionalTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNORSOKAdditionalTesting1" id="qwbpOrderNORSOKAdditionalTesting1"
                                                            checked={this.props.qwbpOrder.norsokAdditionalTesting1}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTesting1(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')} NORSOK M-603</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNORSOKAdditionalTesting1Text" id="qwbpOrderNORSOKAdditionalTesting1Text"
                                                            value={this.props.qwbpOrder.norsokAdditionalTesting1Text}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTesting1Text(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNORSOKAdditionalTesting2" id="qwbpOrderNORSOKAdditionalTesting2"
                                                            checked={this.props.qwbpOrder.norsokAdditionalTesting2}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTesting2(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')} 1</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNORSOKAdditionalTesting2Text" id="qwbpOrderNORSOKAdditionalTesting2Text"
                                                            value={this.props.qwbpOrder.norsokAdditionalTesting2Text}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTesting2Text(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNORSOKAdditionalTesting3" id="qwbpOrderNORSOKAdditionalTesting3"
                                                            checked={this.props.qwbpOrder.norsokAdditionalTesting3}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTesting3(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')} 2</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNORSOKAdditionalTesting3Text" id="qwbpOrderNORSOKAdditionalTesting3Text"
                                                            value={this.props.qwbpOrder.norsokAdditionalTesting3Text}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTesting3Text(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNORSOKAdditionalTesting4" id="qwbpOrderNORSOKAdditionalTesting4"
                                                            checked={this.props.qwbpOrder.norsokAdditionalTesting4}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTesting4(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')} 3</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNORSOKAdditionalTesting4Text" id="qwbpOrderNORSOKAdditionalTesting4Text"
                                                            value={this.props.qwbpOrder.norsokAdditionalTesting4Text}
                                                            onChange={(event) => this.onChangeQWBPOrderNORSOKAdditionalTesting4Text(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>
                                            <Form inline>
                                                <FormGroup check>
                                                    <Input type="checkbox" bssize="sm" name="qwbpOrderNACE" id="qwbpOrderNACE"
                                                        checked={this.props.qwbpOrder.nace}
                                                        onChange={(event) => this.onChangeQWBPOrderNACE(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                                NACE
                                            </Form>
                                        </h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNACEMr0103" id="qwbpOrderNACEMr0103"
                                                            checked={this.props.qwbpOrder.naceMr0103Testing}
                                                            onChange={(event) => this.onChangeQWBPOrdernaceMr0103Testing(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} NACE MR0103/ISO 17945</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNaceMr0103TestingText" id="qwbpOrderNaceMr0103TestingText"
                                                            value={this.props.qwbpOrder.naceMr0103TestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderNaceMr0103TestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNACEMr0175" id="qwbpOrderNACEMr0175"
                                                            checked={this.props.qwbpOrder.naceMr0175Testing}
                                                            onChange={(event) => this.onChangeQWBPOrderNaceMr0175Testing(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} NACE MR0175/ISO 15156</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNACEMr0175TestingText" id="qwbpOrderNACEMr0175TestingText"
                                                            value={this.props.qwbpOrder.naceMr0175TestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderNaceMr0175TestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNACEAdditionalTesting1" id="qwbpOrderNACEAdditionalTesting1"
                                                            checked={this.props.qwbpOrder.naceAdditionalTesting1}
                                                            onChange={(event) => this.onChangeQWBPOrderNACEAdditionalTesting1(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')} 1</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNACEAdditionalTesting1Text" id="qwbpOrderNACEAdditionalTesting1Text"
                                                            value={this.props.qwbpOrder.naceAdditionalTesting1Text}
                                                            onChange={(event) => this.onChangeQWBPOrderNACEAdditionalTesting1Text(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderNACEAdditionalTesting2" id="qwbpOrderNACEAdditionalTesting2"
                                                            checked={this.props.qwbpOrder.naceAdditionalTesting2}
                                                            onChange={(event) => this.onChangeQWBPOrderNACEAdditionalTesting2(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')} 2</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderNACEAdditionalTesting2Text" id="qwbpOrderNACEAdditionalTesting2Text"
                                                            value={this.props.qwbpOrder.naceAdditionalTesting2Text}
                                                            onChange={(event) => this.onChangeQWBPOrderNACEAdditionalTesting2Text(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                            </Col>
                                            <Col sm={2}>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>
                                            <Form inline>
                                                <FormGroup check>
                                                    <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555ArcStudWelding" id="qwbpOrderISO14555ArcStudWelding"
                                                        checked={this.props.qwbpOrder.iso14555ArcStudWelding}
                                                        onChange={(event) => this.onChangeQWBPOrderISO14555ArcStudWelding(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                                SS-EN ISO 14555 {t('WeldingArcStudWeldingOfMetallicMaterial')}
                                            </Form>
                                        </h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup check>
                                                    <Label check size="sm">
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555ApplicationGreaterThan100Degrees" id="qwbpOrderISO14555ApplicationGreaterThan100Degrees"
                                                            checked={this.props.qwbpOrder.iso14555ApplicationGreaterThan100Degrees}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555ApplicationGreaterThan100Degrees(event)}
                                                            placeholder="" />{' '} Application &gt; 100 °C
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={10}>
                                                <FormGroup check>
                                                    <Label check size="sm">
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555ApplicationLessThanOrEqualTo100Degrees" id="qwbpOrderISO14555ApplicationLessThanOrEqualTo100Degrees"
                                                            checked={this.props.qwbpOrder.iso14555ApplicationLessThanOrEqualTo100Degrees}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555ApplicationLessThanOrEqualTo100Degrees(event)}
                                                            placeholder="" />{' '} Application ≤ 100 °C
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555_783Testing" id="qwbpOrderISO14555_783Testing"
                                                            checked={this.props.qwbpOrder.iso14555_783Testing}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555_783Testing(event)}
                                                            placeholder="" />
                                                        <Label check className="text-muted" size="sm">{' '} 783: {t('DrawnArcStudWeldingWithCeramicFeruleorShieldingGas')}</Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555_784Testing" id="qwbpOrderISO14555_784Testing"
                                                            checked={this.props.qwbpOrder.iso14555_784Testing}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555_784Testing(event)}
                                                            placeholder="" />
                                                        <Label className="text-muted" check size="sm">{' '} 784: {t('ShortCycleDrawnArcStudWeldingWithDW')} ≤ 12 mm</Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555_785Testing" id="qwbpOrderISO14555_785Testing"
                                                            checked={this.props.qwbpOrder.iso14555_785Testing}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555_785Testing(event)}
                                                            placeholder="" />
                                                        <Label check className="text-muted" size="sm">{' '} 785: {t('CapacitorDischargeDrawnArcStudWelding')}</Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555_786Testing" id="qwbpOrderISO14555_786Testing"
                                                            checked={this.props.qwbpOrder.iso14555_786Testing}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555_786Testing(event)}
                                                            placeholder="" />
                                                        <Label className="text-muted" check size="sm">{' '} 786: {t('CapacitorDischargeStudWeldingWithTipIgnition')} </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555_787Testing" id="qwbpOrderISO14555_787Testing"
                                                            checked={this.props.qwbpOrder.iso14555_787Testing}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555_787Testing(event)}
                                                            placeholder="" />
                                                        <Label check className="text-muted" size="sm">{' '} 787: {t('DrawnArcStudWeldingWithFusibleCollar')}</Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row form>
                                            <Col sm={4}>
                                                <Form inline>
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555_3834Testing" id="qwbpOrderISO14555_3834Testing"
                                                            checked={this.props.qwbpOrder.iso14555_3834Testing}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555_3834Testing(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} ISO 3834</Label>
                                                        <span>&nbsp;</span>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO14555_3834TestingText" id="qwbpOrderISO14555_3834TestingText"
                                                            value={this.props.qwbpOrder.iso14555_3834TestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555_3834TestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                            <Col sm={2}>
                                            </Col>
                                            <Col sm={6}>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555BendTesting" id="qwbpOrderISO14555BendTesting"
                                                            checked={this.props.qwbpOrder.iso14555BendTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555BendTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('BendTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO14555BendTestingText" id="qwbpOrderISO14555BendTestingText"
                                                            value={this.props.qwbpOrder.iso14555BendTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555BendTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555BendTestingTorqueWrench" id="qwbpOrderISO14555BendTestingTorqueWrench"
                                                            checked={this.props.qwbpOrder.iso14555BendTestingTorqueWrench}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555BendTestingTorqueWrench(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('BendTesting')} {t('torque')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO14555BendTestingTorqueWrenchText" id="qwbpOrderISO14555BendTestingTorqueWrenchText"
                                                            value={this.props.qwbpOrder.iso14555BendTestingTorqueWrenchText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555BendTestingTorqueWrenchText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555TensileTesting" id="qwbpOrderISO14555TensileTesting"
                                                            checked={this.props.qwbpOrder.iso14555TensileTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555TensileTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('TensileTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO14555TensileTestingText" id="qwbpOrderISO14555TensileTestingText"
                                                            value={this.props.qwbpOrder.iso14555TensileTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555TensileTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555MacroPlusPhoto" id="qwbpOrderISO14555MacroPlusPhoto"
                                                            checked={this.props.qwbpOrder.iso14555MacroPlusPhoto}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555MacroPlusPhoto(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('Macro+Photo')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO14555MacroPlusPhotoText" id="qwbpOrderISO14555MacroPlusPhotoText"
                                                            value={this.props.qwbpOrder.iso14555MacroPlusPhotoText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555MacroPlusPhotoText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555RTProvning" id="qwbpOrderISO14555RTProvning"
                                                            checked={this.props.qwbpOrder.iso14555RTProvning}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555RTProvning(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('RTTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO14555RTProvningText" id="qwbpOrderISO14555RTProvningText"
                                                            value={this.props.qwbpOrder.iso14555RTProvningText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555RTProvningText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555TorqueTesting" id="qwbpOrderISO14555TorqueTesting"
                                                            checked={this.props.qwbpOrder.iso14555TorqueTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555TorqueTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('TorqueTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO14555TorqueTestingText" id="qwbpOrderISO14555TorqueTestingText"
                                                            value={this.props.qwbpOrder.iso14555TorqueTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555TorqueTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO14555AdditionalTesting" id="qwbpOrderISO14555AdditionalTesting"
                                                            checked={this.props.qwbpOrder.iso14555AdditionalTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555AdditionalTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO14555AdditionalTestingText" id="qwbpOrderISO14555AdditionalTestingText"
                                                            value={this.props.qwbpOrder.iso14555AdditionalTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO14555AdditionalTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>
                                            <Form inline>
                                                <FormGroup check>
                                                    <Input type="checkbox" bssize="sm" name="qwbpOrderBrazinProcedureApproval" id="qwbpOrderBrazinProcedureApproval"
                                                        checked={this.props.qwbpOrder.iso13134BrazinProcedureApproval}
                                                        onChange={(event) => this.onChangeQWBPOrderISO13134BrazinProcedureApproval(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                                SS-EN ISO 13134 {t('AcceptanceOfSolderingProcedure')}
                                            </Form>
                                        </h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO13134TensileTesting" id="qwbpOrderISO13134TensileTesting"
                                                            checked={this.props.qwbpOrder.iso13134TensileTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134TensileTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('TensileTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO13134TensileTestingText" id="qwbpOrderISO13134TensileTestingText"
                                                            value={this.props.qwbpOrder.iso13134TensileTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134TensileTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO13134BendTesting" id="qwbpOrderISO13134BendTesting"
                                                            checked={this.props.qwbpOrder.iso13134BendTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134BendTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('BendTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO13134BendTestingText" id="qwbpOrderISO13134BendTestingText"
                                                            value={this.props.qwbpOrder.iso13134BendTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134BendTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO13134HardnessTesting" id="qwbpOrderISO13134HardnessTesting"
                                                            checked={this.props.qwbpOrder.iso13134HardnessTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134HardnessTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('HardnessTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO13134HardnessTestingText" id="qwbpOrderISO13134HardnessTestingText"
                                                            value={this.props.qwbpOrder.iso13134HardnessTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134HardnessTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO13134ShearTesting" id="qwbpOrderISO13134ShearTesting"
                                                            checked={this.props.qwbpOrder.iso13134ShearTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134ShearTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('ShearTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO13134ShearTestingText" id="qwbpOrderISO13134ShearTestingText"
                                                            value={this.props.qwbpOrder.iso13134ShearTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134ShearTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO13134PeelTesting" id="qwbpOrderISO13134PeelTesting"
                                                            checked={this.props.qwbpOrder.iso13134PeelTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134PeelTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('PeelTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO13134PeelTestingText" id="qwbpOrderISO13134PeelTestingText"
                                                            value={this.props.qwbpOrder.iso13134PeelTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134PeelTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Input type="checkbox" bssize="sm" name="qwbpOrderISO13134AdditionalTesting" id="qwbpOrderISO13134AdditionalTesting"
                                                            checked={this.props.qwbpOrder.iso13134AdditionalTesting}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134AdditionalTesting(event)}
                                                            placeholder="" />
                                                        <Label check size="sm">{' '} {t('AdditionalTesting')}</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="text" bssize="sm" name="qwbpOrderISO13134AdditionalTestingText" id="qwbpOrderISO13134AdditionalTestingText"
                                                            value={this.props.qwbpOrder.iso13134AdditionalTestingText}
                                                            onChange={(event) => this.onChangeQWBPOrderISO13134AdditionalTestingText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderOther" size="sm">{t('Other')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="qwbpOrderOther" id="qwbpOrderOther"
                                                        value={this.props.qwbpOrder.other} onChange={(event) => this.onChangeQWBPOrderOther(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <a className="text-muted">
                                                        {t('AllDocumentsAndReports...')}
                                                    </a>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>OFP</h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label size="sm">{t('Approved')}</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <Form inline>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="qwbpOrderNDTApproved"
                                                                value="yes"
                                                                checked={this.props.qwbpOrder.ndtApproved === 'yes'}
                                                                onChange={(event) => this.onChangeQWBPOrderNDTApproved(event)}
                                                                placeholder="" />{' '} {t('Yes')}
                                                        </Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="qwbpOrderNDTApproved"
                                                                value="no"
                                                                checked={this.props.qwbpOrder.ndtApproved === 'no'}
                                                                onChange={(event) => this.onChangeQWBPOrderNDTApproved(event)}
                                                                placeholder="" />{' '} {t('No')}
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderNDTSignature" size="sm">{t('Signature')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderNDTSignature" id="qwbpOrderNDTSignature"
                                                        value={this.props.qwbpOrder.ndtSignature}
                                                        onChange={(event) => this.onChangeQWBPOrderNDTSignature(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderNDTDate" size="sm">{t('Date')}</Label>
                                                    <Input type="date" max="9999-12-30" bsSize="sm" name="qwbpOrderNDTDate" id="qwbpOrderNDTDate"
                                                        value={this.props.qwbpOrder.ndtDate} onChange={(event) => this.onChangeQWBPOrderNDTDate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('MechanicalTesting')}</h5>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label size="sm">{t('Approved')}</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <Form inline>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="qwbpOrderDestructiveTestingApproved"
                                                                value="yes"
                                                                checked={this.props.qwbpOrder.destructiveTestingApproved === 'yes'}
                                                                onChange={(event) => this.onChangeQWBPOrderDestructiveTestingApproved(event)}
                                                                placeholder="" />{' '} {t('Yes')}
                                                        </Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="qwbpOrderDestructiveTestingApproved"
                                                                value="no"
                                                                checked={this.props.qwbpOrder.destructiveTestingApproved === 'no'}
                                                                onChange={(event) => this.onChangeQWBPOrderDestructiveTestingApproved(event)}
                                                                placeholder="" />{' '} {t('No')}
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderDestructiveTestingSignature" size="sm">{t('Signature')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderDestructiveTestingSignature" id="qwbpOrderDestructiveTestingSignature"
                                                        value={this.props.qwbpOrder.destructiveTestingSignature}
                                                        onChange={(event) => this.onChangeQWBPOrderDestructiveTestingSignature(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderDestructiveTestingDate" size="sm">{t('Date')}</Label>
                                                    <Input type="date" max="9999-12-30" bsSize="sm" name="qwbpOrderDestructiveTestingDate" id="qwbpOrderDestructiveTestingDate"
                                                        value={this.props.qwbpOrder.destructiveTestingDate} onChange={(event) => this.onChangeQWBPOrderDestructiveTestingDate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderOrderedByLevel3" size="sm">{t('OrderedByLevel')} III</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderOrderedByLevel3" id="qwbpOrderOrderedByLevel3"
                                                        value={this.props.qwbpOrder.orderedByLevel3}
                                                        onChange={(event) => this.onChangeQWBPOrderOrderedByLevel3(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderDate" size="sm">{t('Date')}</Label>
                                                    <Input type="date" max="9999-12-30" bsSize="sm" name="qwbpOrderDate" id="qwbpOrderDate"
                                                        value={this.props.qwbpOrder.date} onChange={(event) => this.onChangeQWBPOrderDate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="qwbpOrderSignature" size="sm">{t('Signature')}</Label>
                                                    <Input type="text" bsSize="sm" name="qwbpOrderSignature" id="qwbpOrderSignature"
                                                        value={this.props.qwbpOrder.signature}
                                                        onChange={(event) => this.onChangeQWBPOrderSignature(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.testingOrder;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    TestingOrderStore.actionCreators // Selects which action creators are merged into the component's props
)(TestingOrder as any);
