﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as RegisterUserStore from '../store/RegisterUserStore';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col, Container, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Nav, NavItem, NavLink, Row, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

// At runtime, Redux will merge together...
type RegisterUserProps =
    RegisterUserStore.RegisterUserState // ... state we've requested from the Redux store
    & typeof RegisterUserStore.actionCreators // ... plus action creators we've requested

class RegisterUser extends React.PureComponent<RegisterUserProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {

    }

    private onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setPassword(event.currentTarget.value);
    }

    private onChangeUserName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setUserName(event.currentTarget.value);
    }

    private onClickRegisterButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm={3}>
                                        </Col>
                                        <Col sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <Row form>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="firstName" size="sm">Förnamn</Label>
                                                                <Input type="text" bsSize="sm" name="firstName" id="firstName"
                                                                    value={this.props.firstName} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeUserName(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="lastName" size="sm">Efternamn</Label>
                                                                <Input type="text" bsSize="sm" name="lastName" id="lastName"
                                                                    value={this.props.lastName} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeUserName(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="userName" size="sm">Användarnamn</Label>
                                                                <Input type="text" bsSize="sm" name="userName" id="userName"
                                                                    value={this.props.userName} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeUserName(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="password" size="sm">Lösenord</Label>
                                                                <Input type="text" bsSize="sm" name="password" id="password"
                                                                    value={this.props.password} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangePassword(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="repeatPassword" size="sm">Upprepa lösenord</Label>
                                                                <Input type="text" bsSize="sm" name="repeatPassword" id="repeatPassword"
                                                                    value={this.props.repeatPassword} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangePassword(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col sm={12}>
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={(event) => this.onClickRegisterButton(event)}>Registrera</Button>
                                                                <Link to={`/login`}>Avbryt</Link>
                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.login, // Selects which state properties are merged into the component's props
    RegisterUserStore.actionCreators // Selects which action creators are merged into the component's props
)(RegisterUser as any);
