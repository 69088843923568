import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from "./translations/en/translation.json";
import translation_se from "./translations/se/translation.json";

let lang = localStorage.getItem('lang');
if (lang === null) {
    localStorage.setItem('lang', 'se');
    lang = 'se';
}

let translate: any;

i18n.use(initReactI18next).init(
    {
        resources: {
            en: {
                translation: translation_en
            },
            se: {
                translation: translation_se
            }
        }, lng: lang, //
        fallbackLng: 'se',
        debug: false,

        interpolation: {
            escapeValue: false // not needed for react!!
        },

        react: {
            bindI18n: 'languageChanged loaded'
        }
    },
    (err, t) => {
        translate = t;
    }
);

export { translate as t, i18n };