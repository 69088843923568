﻿import { GetArcTypeResponse } from "./GetArcTypeResponse";
import { GetMakeResponse } from "./GetMakeResponse";
import { GetShieldingGasResponse } from "./GetShieldingGasResponse";
import { GetWeldingMethodResponse } from "./GetWeldingMethodResponse";

export class GetWeldingProcessResponse {
    arcType1: GetArcTypeResponse;
    arcType2: GetArcTypeResponse;
    createdAt: string;
    deletedAt: string;
    id: number;
    make1: GetMakeResponse;
    make2: GetMakeResponse;
    shieldingGas1: GetShieldingGasResponse;
    shieldingGas2: GetShieldingGasResponse;
    updatedAt: string;
    weldingMethod1: GetWeldingMethodResponse;
    weldingMethod2: GetWeldingMethodResponse;
    enableMakeAText: boolean;
    makeAText: string;
    designationAText: string;
    fmGroupAText: string;
    enableMakeBText: boolean;
    makeBText: string;
    designationBText: string;
    fmGroupBText: string;
    typeOfBackingText: string;

    public constructor() {
        this.arcType1 = new GetArcTypeResponse();
        this.arcType2 = new GetArcTypeResponse();
        this.createdAt = '';
        this.deletedAt = '';
        this.id = 0;
        this.make1 = new GetMakeResponse();
        this.make2 = new GetMakeResponse();
        this.shieldingGas1 = new GetShieldingGasResponse();
        this.shieldingGas2 = new GetShieldingGasResponse();
        this.updatedAt = '';
        this.weldingMethod1 = new GetWeldingMethodResponse();
        this.weldingMethod2 = new GetWeldingMethodResponse();
        this.enableMakeAText = false;
        this.makeAText = '';
        this.designationAText = '';
        this.fmGroupAText = '';
        this.enableMakeBText = false;
        this.makeBText = '';
        this.designationBText = '';
        this.fmGroupBText = '';
        this.typeOfBackingText = '';
    }
}