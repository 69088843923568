﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import authHeader from './models/auth-header';
import { News } from './models/News';
import { UserListObject } from './UserListObject';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface NewsState {
    newsList: Array<News>;
    searchResult: Array<News>;
    chosenNews: News;
    searchTerm: string;
    editModalOpen: boolean;
    currentlyLoading: boolean;
    selectedLanguage: string;
    selectedCustomerId: number;
    currentUser: UserListObject;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetLoading {
    type: 'SET_LOADING';
    currentlyLoading: boolean;
}

export interface SetSettings {
    type: 'SET_SETTINGS';
    name: string;
}

export interface SetNews {
    type: 'SET_NEWS_LIST';
    newsList: Array<News>
}

export interface ToggleEditModal {
    type: 'TOGGLE_NEWS_EDIT_MODAL_OPEN';
    editModalOpen: boolean;
}

export interface SetPaginationPageIndexAction {
    type: 'SET_PAGINATION_PAGE_INDEX';
    paginationPageIndex: number;
}

export interface SetSearchTerm {
    type: 'SET_SEARCH_TERM';
    searchTerm: string;
}

export interface SetSelectedLanguage {
    type: 'SET_SELECTED_LANGUAGE';
    selectedLanguage: string;
}

export interface SetSelectedCustomerId {
    type: 'SET_SELECTED_CUSTOMER_ID';
    selectedCustomerId: number;
}

export interface SetChosenNews {
    type: 'SET_CHOSEN_NEWS';
    chosenNews: News;
}

export interface GetNewsSearchResult {
    type: 'SET_NEWS_SEARCH_RESULT';
    searchResult: Array<News>;
}

export interface ReceiveCurrentUser {
    type: 'RECEIVE_CURRENT_USER';
    currentUser: UserListObject;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    SetSettings
    | SetPaginationPageIndexAction
    | SetSearchTerm
    | GetNewsSearchResult
    | SetNews
    | ToggleEditModal
    | SetChosenNews
    | SetLoading
    | SetSelectedLanguage
    | SetSelectedCustomerId
    | ReceiveCurrentUser

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    currentNews: (): AppThunkAction<KnownAction> => () => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/News/current/`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('news');
                        localStorage.removeItem('newsId');
                        localStorage.removeItem('customerId');
                    }
                }))
                .catch(error => {

                });
        }
    },
    GetNewsSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.news) {
            let newNews = [];
            let searchTerm = appState.news!.searchTerm.toLowerCase();
            let newss: Array<News> = appState.news!.newsList;

            for (let i = 0; i < newss.length; i++) {
                if (searchTerm.length > -1) {
                    if (
                        newss[i].title?.toLowerCase().search(searchTerm) > -1
                        || newss[i].content?.toLowerCase().search(searchTerm) > -1
                    ) {
                        newNews.push(newss[i]);
                    }
                }
            }

            dispatch({ type: 'SET_NEWS_SEARCH_RESULT', searchResult: newNews });
        }
    },
    removeNews: (newsId: number, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.news) {
            dispatch({ type: 'SET_LOADING', currentlyLoading: true });
            fetch(`/api/News/DeleteById/${newsId}`, {
                method: 'DELETE',
                headers: authHeader.authHeader(),
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (callback !== null) {
                        callback();
                    }
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    setSelectedLanguage: (language: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.news) {
            dispatch({ type: 'SET_SELECTED_LANGUAGE', selectedLanguage: language });
        }
    },
    setSelectedCustomerId: (customerId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.news) {
            dispatch({ type: 'SET_SELECTED_CUSTOMER_ID', selectedCustomerId: customerId });
        }
    },
    setChosenNews: (news: News): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_CHOSEN_NEWS', chosenNews: news });
    },
    setSearchTerm: (searchTerm: string): AppThunkAction<KnownAction> => (dispatch) => {
        const newTerm = searchTerm;
        dispatch({ type: 'SET_SEARCH_TERM', searchTerm: newTerm });
    },
    toggleEditModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.news) {
            let editModalOpen = appState.news?.editModalOpen;
            dispatch({ type: 'TOGGLE_NEWS_EDIT_MODAL_OPEN', editModalOpen: !editModalOpen });
        }
    },
    receiveNews: (language: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: 'SET_LOADING', currentlyLoading: true });

        fetch(`/api/News/GetAllByLanguage/${language}`, {
            method: 'GET',
            headers: authHeader.authHeader(),

        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                dispatch({ type: 'SET_NEWS_LIST', newsList: res.data });
                dispatch({ type: 'SET_LOADING', currentlyLoading: false });
            }))
            .catch(error => {
                console.log('error');
                console.log(error);
            });
    },
    receiveNewsUnsorted: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        const appState = getState();

        if (appState && appState.news) {
            let language: string = appState.news!.selectedLanguage;

            
                fetch(`/api/News/GetAllByLanguage/${language}`, {
                    method: 'GET',
                    headers: authHeader.authHeader(),

                }).then(response =>
                    response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                    ).then(res => {
                        dispatch({ type: 'SET_NEWS_LIST', newsList: res.data });
                        dispatch({ type: 'SET_LOADING', currentlyLoading: false });
                    }))
                    .catch(error => {
                        console.log('error');
                        console.log(error);
                    });
        }
    },
    updateNews: (incomingNews: News, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            dispatch({ type: 'SET_LOADING', currentlyLoading: true });
            fetch(`/api/News/Update/`, {
                method: 'PUT',
                headers: authHeader.authHeader(),
                body: JSON.stringify(incomingNews)
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (callback !== null) {
                        callback();
                    }
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    addNewNews: (incomingNews: News, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        const appState = getState();

        if (appState && appState.news) {

            let language: string = appState.news!.selectedLanguage;
            incomingNews.language = language;

            if (tokenStr) {
                dispatch({ type: 'SET_LOADING', currentlyLoading: true });
                fetch(`/api/News/Create/`, {
                    method: 'POST',
                    headers: authHeader.authHeader(),
                    body: JSON.stringify(incomingNews)
                }).then(response =>
                    response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                    ).then(res => {
                        if (callback !== null) {
                            callback();
                        }
                    }))
                    .catch(error => {
                        console.log('error');
                        console.log(error);
                    });
            }
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

let value = localStorage.getItem('token');

const unloadedState: NewsState =
{
    newsList: [],
    searchResult: [],
    searchTerm: '',
    editModalOpen: false,
    chosenNews: {
        title: '',
        content: '',
        language: '',
        id: 0,
    },
    currentlyLoading: false,
    selectedLanguage: '',
    selectedCustomerId: 0,
    currentUser: null,
};

export const reducer: Reducer<NewsState> = (state: NewsState | undefined, incomingAction: Action): NewsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_LOADING':
            return {
                newsList: state.newsList,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                editModalOpen: state.editModalOpen,
                chosenNews: state.chosenNews,
                currentlyLoading: action.currentlyLoading,
                selectedLanguage: state.selectedLanguage,
                selectedCustomerId: state.selectedCustomerId,
                currentUser: state.currentUser,
            }
        case 'TOGGLE_NEWS_EDIT_MODAL_OPEN':
            return {
                newsList: state.newsList,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                editModalOpen: action.editModalOpen,
                chosenNews: state.chosenNews,
                currentlyLoading: state.currentlyLoading,
                selectedLanguage: state.selectedLanguage,
                selectedCustomerId: state.selectedCustomerId,
                currentUser: state.currentUser,
            }
        case 'SET_NEWS_LIST':
            return {
                newsList: action.newsList,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                editModalOpen: state.editModalOpen,
                chosenNews: state.chosenNews,
                currentlyLoading: state.currentlyLoading,
                selectedLanguage: state.selectedLanguage,
                selectedCustomerId: state.selectedCustomerId,
                currentUser: state.currentUser,
            }
        case 'SET_SEARCH_TERM':
            return {
                newsList: state.newsList,
                searchResult: state.searchResult,
                searchTerm: action.searchTerm,
                editModalOpen: state.editModalOpen,
                chosenNews: state.chosenNews,
                currentlyLoading: state.currentlyLoading,
                selectedLanguage: state.selectedLanguage,
                selectedCustomerId: state.selectedCustomerId,
                currentUser: state.currentUser,
            }
        case 'SET_NEWS_SEARCH_RESULT':
            return {
                newsList: state.newsList,
                searchResult: action.searchResult,
                searchTerm: state.searchTerm,
                editModalOpen: state.editModalOpen,
                chosenNews: state.chosenNews,
                currentlyLoading: state.currentlyLoading,
                selectedLanguage: state.selectedLanguage,
                selectedCustomerId: state.selectedCustomerId,
                currentUser: state.currentUser,
            }
        case 'SET_CHOSEN_NEWS':
            return {
                newsList: state.newsList,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                editModalOpen: state.editModalOpen,
                chosenNews: action.chosenNews,
                currentlyLoading: state.currentlyLoading,
                selectedLanguage: state.selectedLanguage,
                selectedCustomerId: state.selectedCustomerId,
                currentUser: state.currentUser,
            }
        case 'SET_SELECTED_LANGUAGE':
            return {
                newsList: state.newsList,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                editModalOpen: state.editModalOpen,
                chosenNews: state.chosenNews,
                currentlyLoading: state.currentlyLoading,
                selectedLanguage: action.selectedLanguage,
                selectedCustomerId: state.selectedCustomerId,
                currentUser: state.currentUser,
            }
        case 'SET_SELECTED_CUSTOMER_ID':
            return {
                newsList: state.newsList,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                editModalOpen: state.editModalOpen,
                chosenNews: state.chosenNews,
                currentlyLoading: state.currentlyLoading,
                selectedLanguage: state.selectedLanguage,
                selectedCustomerId: action.selectedCustomerId,
                currentUser: state.currentUser,
            }
        case 'SET_SELECTED_CUSTOMER_ID':
            return {
                newsList: state.newsList,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                editModalOpen: state.editModalOpen,
                chosenNews: state.chosenNews,
                currentlyLoading: state.currentlyLoading,
                selectedLanguage: state.selectedLanguage,
                selectedCustomerId: action.selectedCustomerId,
                currentUser: state.currentUser,
            }
        case 'RECEIVE_CURRENT_USER':
            return {
                newsList: state.newsList,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                editModalOpen: state.editModalOpen,
                chosenNews: state.chosenNews,
                currentlyLoading: state.currentlyLoading,
                selectedLanguage: state.selectedLanguage,
                selectedCustomerId: state.selectedCustomerId,
                currentUser: action.currentUser,
            };
        default:
            return state;
    }
};
