﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardText, CardTitle, Col, Form, FormGroup, Input, InputGroup, Label, Row, Table } from 'reactstrap';
import { ApplicationState } from '../store/index';
import { GetTestResultsExaminationResponse } from '../store/models/responses/GetTestResultsExaminationResponse';
import { RevisionActionType } from '../store/models/RevisionActionType';
import * as TestResultsExaminationStore from '../store/TestResultsExaminationStore';
import WPQRPageHeader from './WPQRPageHeader';
import { t } from '../i18n';
import DropdownLists from './otherComponents/DropdownLists';

// At runtime, Redux will merge together...
type TestResultsExaminationProps =
    TestResultsExaminationStore.TestResultsExaminationState // ... state we've requested from the Redux store
    & typeof TestResultsExaminationStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    saveWPQR: (enable: boolean) => void;
    cloneWPQR: (enable: boolean) => void;
    pDekraProcedureNumber: string;
    pTestResultsExaminationId?: number;
    pTestResultsExaminationResponse: GetTestResultsExaminationResponse;
    pRevisionWpqrAction: string;
    pUpdateTestResultsExamination: boolean;
}

class TestResultsExamination extends React.PureComponent<TestResultsExaminationProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.startTestResultsExamination();
    }
    private startTestResultsExamination() {
        this.props.clearState()
        this.props.initMacroTestingResults();
        this.props.initMicroTestingResults();
        this.props.initLongitudinalTensileTestingResults();
        this.props.initTransverseTensileTestingResults();
        this.props.initBendTestingResults();
        this.props.initImpactTestingResults();
        this.props.initHardnessTestingResults();
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.pTestResultsExaminationResponse !== this.props.pTestResultsExaminationResponse) {
            this.props.initTestResultsExamination(this.props.pTestResultsExaminationResponse);
        }
        if (prevProps.pUpdateTestResultsExamination !== this.props.pUpdateTestResultsExamination) {
            this.startTestResultsExamination();
        }
    }

    private onChangeBendTestingResultsAcceptableValues(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsAcceptableValues(event.currentTarget.value);
    }

    private onChangeBendTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeBendTestingResultsAcceptanceResult(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsAcceptanceResult(event.currentTarget.value);
    }

    private onChangeBendTestingResultsBendingAngle(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsBendingAngle(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsDiameterOfRoller(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsDiameterOfRoller(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsDistanceBetweenRollers(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsDistanceBetweenRollers(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsSurfaceCondition(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsSurfaceCondition(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsTestingTemperature(event.currentTarget.value);
    }

    private onChangeBendTestingResultsTestSpecimenDimensions(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsTestSpecimenDimensions(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsTestSpecimenType(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsTestSpecimenType(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsAcceptableValues(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsAcceptableValues(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsAcceptanceResult(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsAcceptanceResult(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsComments(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ4(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ4(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ5(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ5(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ6(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ6(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable1HAZ16(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ16(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable1HAZ17(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ17(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ10(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ10(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable1HAZ18(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ18(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable1HAZ19(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ19(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ11(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ11(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ12(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ12(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent1(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent1(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent2(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent2(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent3(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent3(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent13(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent13(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent14(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent14(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent15(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent15(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Weld7(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Weld7(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Weld8(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Weld8(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Weld9(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Weld9(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ4(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ4(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ5(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ5(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ6(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ6(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable2HAZ16(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ16(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable2HAZ17(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ17(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ10(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ10(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable2HAZ18(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ18(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable2HAZ19(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ19(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ11(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ11(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ12(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ12(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent1(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent1(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent2(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent2(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent3(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent3(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent13(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent13(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent14(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent14(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent15(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent15(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Weld7(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Weld7(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Weld8(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Weld8(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Weld9(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Weld9(rowNumber, event.currentTarget.value);
    }
    // Table3
    private onChangeHardnessTestingResultsTable3HAZ4(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ4(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3HAZ5(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ5(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3HAZ6(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ6(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable3HAZ16(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ16(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable3HAZ17(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ17(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3HAZ10(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ10(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable3HAZ18(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ18(rowNumber, event.currentTarget.value);
    }
    private onChangeHardnessTestingResultsTable3HAZ19(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ19(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3HAZ11(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ11(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3HAZ12(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3HAZ12(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3Parent1(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3Parent1(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3Parent2(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3Parent2(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3Parent3(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3Parent3(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3Parent13(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3Parent13(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3Parent14(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3Parent14(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3Parent15(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3Parent15(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3Weld7(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3Weld7(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3Weld8(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3Weld8(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable3Weld9(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable3Weld9(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTestingTemperature(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsWayOfMeasurementOfHardness1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsWayOfMeasurementOfHardness1(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsWayOfMeasurementOfHardness2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsWayOfMeasurementOfHardness2(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsWayOfMeasurementOfHardness3(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsWayOfMeasurementOfHardness3(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsAcceptableValues(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsAcceptableValues(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsType(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsType(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsTestingTemperature(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAbsorbedEnergy(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAbsorbedEnergy(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAbsorbedEnergy1(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAbsorbedEnergy1(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAbsorbedEnergy2(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAbsorbedEnergy2(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsAverage(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsAverage(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAcceptanceResult(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAcceptanceResult(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldComments(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldTestSpecimenDimensions(rowNumber: number,event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldTestSpecimenDimensions(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldTestSpecimenType(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldTestSpecimenType(rowNumber, event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsA(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsA(rowNumber, event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsAcceptableValuesUA(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsAcceptableValuesUA(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsAcceptableValuesUR(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsAcceptableValuesUR(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsAcceptableValuesURm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsAcceptableValuesURm(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsAcceptableValuesUFm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsAcceptableValuesUFm(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsAcceptableValuesUZ(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsAcceptableValuesUZ(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsAcceptanceResult(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsAcceptanceResult(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsComments(event.currentTarget.value);
    }

    private onChangeBendTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsComments(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsFractureSurfaceCondition(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsFractureSurfaceCondition(rowNumber, event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyUA(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsMeasurementUncertaintyUA(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyUR(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsMeasurementUncertaintyUR(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyURm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsMeasurementUncertaintyURm(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyUFm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsMeasurementUncertaintyUFm(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyUZ(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsMeasurementUncertaintyUZ(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsR(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsR(rowNumber, event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsRp(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsRp(rowNumber, event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsRm(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsRm(rowNumber, event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsTestingTemperature(event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsTestSpecimenDimensions(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsTestSpecimenDimensions(rowNumber, event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeLongitudinalTensileTestingResultsZ(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLongitudinalTensileTestingResultsZ(rowNumber, event.currentTarget.value);
    }

    private onChangeMacroTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeMacroTestingResultsExaminationResult(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsExaminationResult(rowNumber, event.currentTarget.value);
    }

    private onChangeMacroTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeMagneticTest(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMagneticTest(event.currentTarget.value);
    }

    private onChangeMicroTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeMicroTestingResultsExaminationResult(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsExaminationResult(rowNumber, event.currentTarget.value);
    }

    private onChangeMicroTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangePenetrantTest(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setPenetrantTest(event.currentTarget.value);
    }

    private onChangeRadiographicExamination(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setRadiographicExamination(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsA(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsA(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptableValuesUA(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptableValuesUA(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptableValuesUA1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptableValuesUA1(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptableValuesUR(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptableValuesUR(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptableValuesUR1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptableValuesUR1(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptableValuesURm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptableValuesURm(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptableValuesURm1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptableValuesURm1(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptableValuesUFm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptableValuesUFm(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptableValuesUFm1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptableValuesUFm1(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptanceResult(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptanceResult(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsComments(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsMeasurementUncertaintyUA(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsMeasurementUncertaintyUA(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsMeasurementUncertaintyUR(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsMeasurementUncertaintyUR(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsMeasurementUncertaintyURm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsMeasurementUncertaintyURm(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsMeasurementUncertaintyUFm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsMeasurementUncertaintyUFm(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsPlaceOfBreak(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsPlaceOfBreak(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsR(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsR(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsRm(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsRm(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsRp(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsRp(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsTestingTemperature(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeUltrasonicExamination(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setUltrasonicExamination(event.currentTarget.value);
    }

    private onChangeVisualExamination(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setVisualExamination(event.currentTarget.value);
    }

    private onClickSaveWpqr(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.props.saveWPQR(true);
    }
    private onClickCloneWpqr(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.props.cloneWPQR(true);
    }
    private onChangeOtherDetailsTestingResultsOtherTesting(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherDetailsTestingResultsOtherTesting(event.currentTarget.value);
    }
    private onChangeOtherDetailsTestingResultsRemarks(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherDetailsTestingResultsRemarks(event.currentTarget.value);
    }
    private onChangeTestingResultsTestsPerformedWithAcceptableResult(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherDetailsTestingResultsTestsPerformedWithAcceptableResult(event.currentTarget.value);
    }
    private onChangeLaboratoryReportReferenceNo(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherDetailsTestingResultsLaboratoryReportReferenceNo(event.currentTarget.value);
    }
    private onChangeOtherDetailsTestingResultsTestCarriedOutInThePresenceOf(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherDetailsTestingResultsTestCarriedOutInThePresenceOf(event.currentTarget.value);
    }

    private onClickSetTextWithRow = (rowN: number, event: string) => {
    }

    private onClickSetText = (event: string) => {
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Form>
                    <WPQRPageHeader pCommissionNumber="" pManufacturerWPQRNumber="" pDekraProcedureNumber="" pageNumber={'4/4'} />
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('TestResultNondestructiveExamination')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationVisualExamination" size="sm">{t('VisualExamination')}</Label>
                                                    <InputGroup>
                                                        <Input type="text" bsSize="sm" name="testResultsExaminationVisualExamination" id="testResultsExaminationVisualExamination"
                                                            value={this.props.visualExamination} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeVisualExamination(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={3} dropDownListsCallback={this.props.setVisualExamination} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationMagneticTest" size="sm">{t('MagneticPowderTest')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationMagneticTest" id="testResultsExaminationMagneticTest"
                                                        value={this.props.magneticTest} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMagneticTest(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={3} dropDownListsCallback={this.props.setMagneticTest} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationPenetrantTest" size="sm">{t('PenetrantTest')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationPenetrantTest" id="testResultsExaminationPenetrantTest"
                                                        value={this.props.penetrantTest} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangePenetrantTest(event)}
                                                        placeholder="" />
                                                        <DropdownLists listNumber={3} dropDownListsCallback={this.props.setPenetrantTest} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationRadiographicExamination" size="sm">{t('RadiographicExamination')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationRadiographicExamination" id="testResultsExaminationRadiographicExamination"
                                                        value={this.props.radiographicExamination} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeRadiographicExamination(event)}
                                                    placeholder="" />
                                                        <DropdownLists listNumber={3} dropDownListsCallback={this.props.setRadiographicExamination} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationUltrasonicExamination" size="sm">{t('UltrasonicExamination')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationUltrasonicExamination" id="testResultsExaminationUltrasonicExamination"
                                                        value={this.props.ultrasonicExamination} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeUltrasonicExamination(event)}
                                                        placeholder="" />
                                                        <DropdownLists listNumber={3} dropDownListsCallback={this.props.setUltrasonicExamination} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('TestResultDestructiveExamination')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <h6>{t('MacroStructureExamination')}</h6>
                                            </Col>
                                            <Col sm={6}>
                                                <h6>{t('MicroStructureExamination')}</h6>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                {this.renderMacroTestingResultsTable()}
                                            </Col>
                                            <Col sm={6}>
                                                {this.renderMicroTestingResultsTable()}
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('TransverseTensileTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={8}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationTransverseTensileTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceStandard')}</Label>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptanceNormsStandard" id="testResultsExaminationTransverseTensileTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.transverseTensileTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationTransverseTensileTestingResultsTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <InputGroup>
                                                        <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsTestingTemperature" id="testResultsExaminationTransverseTensileTestingResultsTestingTemperature"
                                                            value={this.props.transverseTensileTestingResults.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsTestingTemperature(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={4} dropDownListsCallback={this.props.setTransverseTensileTestingResultsTestingTemperature} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderTransverseTensileTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationTransverseTensileTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsComments" id="testResultsExaminationTransverseTensileTestingResultsComments"
                                                        value={this.props.transverseTensileTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label size="sm">{t('Acceptance')}</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={10}>
                                                <Form inline>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptanceResult"
                                                                value="yes"
                                                                checked={this.props.transverseTensileTestingResults.acceptanceResult === 'yes'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('Yes')}
                                                        </Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptanceResult"
                                                                value="no"
                                                                checked={this.props.transverseTensileTestingResults.acceptanceResult === 'no'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('No')}
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('LongitudinalTensileTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={8}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationLongitudinalTensileTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceStandard')}</Label>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsAcceptanceNormsStandard" id="testResultsExaminationLongitudinalTensileTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.longitudinalTensileTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationLongitudinalTensileTestingResultsTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsTestingTemperature" id="testResultsExaminationLongitudinalTensileTestingResultsTestingTemperature"
                                                        value={this.props.longitudinalTensileTestingResults.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsTestingTemperature(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={4} dropDownListsCallback={this.props.setLongitudinalTensileTestingResultsTestingTemperature} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderLongitudinalTensileTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationLongitudinalTensileTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsComments" id="testResultsExaminationLongitudinalTensileTestingResultsComments"
                                                        value={this.props.longitudinalTensileTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label size="sm">{t('Accepance')}</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={10}>
                                                <Form inline>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsAcceptanceResult"
                                                                value="yes"
                                                                checked={this.props.longitudinalTensileTestingResults.acceptanceResult === 'yes'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('Yes')}
                                                        </Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsAcceptanceResult"
                                                                value="no"
                                                                checked={this.props.longitudinalTensileTestingResults.acceptanceResult === 'no'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('No')}
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('BendTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationBendTestingResultsAcceptanceNormsStandard" size="sm">{t('TestingStandard')}</Label>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationBendTestingResultsAcceptanceNormsStandard" id="testResultsExaminationBendTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.bendTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeBendTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationBendTestingResultsAcceptableValues" size="sm">{t('DiameterOfRoller')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationBendTestingResultsAcceptableValues" id="testResultsExaminationBendTestingResultsAcceptableValues"
                                                        value={this.props.bendTestingResults.acceptableValues} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeBendTestingResultsAcceptableValues(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={7} dropDownListsCallback={this.props.setBendTestingResultsAcceptableValues} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col hidden={true} >
                                                <FormGroup>
                                                    <Label for="testResultsExaminationBendTestingResultsTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationBendTestingResultsTestingTemperature" id="testResultsExaminationBendTestingResultsTestingTemperature"
                                                        value={this.props.bendTestingResults.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeBendTestingResultsTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <br/>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderBendTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationBendTestingResultsTestingComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="testResultsExaminationBendTestingResultsTestingComments" id="testResultsExaminationBendTestingResultsTestingComments"
                                                        value={this.props.bendTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeBendTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label size="sm">{t('Acceptance')}</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={10}>
                                                <Form inline>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationBendTestingResultsAcceptanceResult"
                                                                value="yes"
                                                                checked={this.props.bendTestingResults.acceptanceResult === 'yes'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeBendTestingResultsAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('Yes')}
                                                        </Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationBendTestingResultsAcceptanceResult"
                                                                value="no"
                                                                checked={this.props.bendTestingResults.acceptanceResult === 'no'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeBendTestingResultsAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('No')}
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('ImpactTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationImpactTestingResultsAcceptanceNormsStandard" size="sm">{t('TestingStandard')}</Label>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationImpactTestingResultsAcceptanceNormsStandard" id="testResultsExaminationImpactTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.impactTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationImpactTestingResultsAcceptableValues" size="sm">{t('AcceptableValues')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationImpactTestingResultsAcceptableValues" id="testResultsExaminationImpactTestingResultsAcceptableValues"
                                                        value={this.props.impactTestingResults.acceptableValues} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsAcceptableValues(event)}
                                                        placeholder="" />
                                                    <DropdownLists listNumber={10} dropDownListsCallback={this.props.setImpactTestingResultsAcceptableValues} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationImpactTestingResultsTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationImpactTestingResultsTestingTemperature" id="testResultsExaminationImpactTestingResultsTestingTemperature"
                                                        value={this.props.impactTestingResults.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsTestingTemperature(event)}
                                                        placeholder="" />
                                                        <DropdownLists listNumber={14} dropDownListsCallback={this.props.setImpactTestingResultsTestingTemperature} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationImpactTestingResultsType" size="sm">{t('Type')}</Label>
                                                    <InputGroup>
                                                        <Input type="text" bsSize="sm" name="testResultsExaminationImpactTestingResultsType" id="testResultsExaminationImpactTestingResultsType"
                                                            value={this.props.impactTestingResults.type} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsType(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={13} dropDownListsCallback={this.props.setImpactTestingResultsType} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderImpactTestingResultsWeldTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationImpactTestingResultsWeldComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="testResultsExaminationImpactTestingResultsWeldComments" id="testResultsExaminationImpactTestingResultsWeldComments"
                                                        value={this.props.impactTestingResults.weldComments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label size="sm">{t('Acceptance')}</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={10}>
                                                <Form inline>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationImpactTestingResultsWeldAcceptanceResult"
                                                                value="yes"
                                                                checked={this.props.impactTestingResults.weldAcceptanceResults === 'yes'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('Yes')}
                                                        </Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationImpactTestingResultsWeldAcceptanceResult"
                                                                value="no"
                                                                checked={this.props.impactTestingResults.weldAcceptanceResults === 'no'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('No')}
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('HardnessTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationHardnessTestingResultsAcceptanceNormsStandard" size="sm">{t('TestingStandard')}</Label>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsAcceptanceNormsStandard" id="testResultsExaminationHardnessTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.hardnessTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationHardnessTestingResultsAcceptableValues" size="sm">{t('AcceptableValues')}</Label>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsAcceptableValues" id="testResultsExaminationHardnessTestingResultsAcceptableValues"
                                                        value={this.props.hardnessTestingResults.acceptableValues} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsAcceptableValues(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col hidden={true}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationHardnessTestingResultsTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTestingTemperature" id="testResultsExaminationHardnessTestingResultsTestingTemperature"
                                                        value={this.props.hardnessTestingResults.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationHardnessTestingResultsWayOfMeasurementOfHardness1" size="sm">{t('PositionOfHardnessIndentation')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsWayOfMeasurementOfHardness1" id="testResultsExaminationHardnessTestingResultsWayOfMeasurementOfHardness1"
                                                        value={this.props.hardnessTestingResults.wayOfMeasurementOfHardness1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsWayOfMeasurementOfHardness1(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={16} dropDownListsCallback={this.props.setHardnessTestingResultsWayOfMeasurementOfHardness1} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderHardnessTestingResultsTable1()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationHardnessTestingResultsWayOfMeasurementOfHardness2" size="sm">{t('PositionOfHardnessIndentation')}</Label>
                                                    <InputGroup>
                                                    <Input type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsWayOfMeasurementOfHardness2" id="testResultsExaminationHardnessTestingResultsWayOfMeasurementOfHardness2"
                                                        value={this.props.hardnessTestingResults.wayOfMeasurementOfHardness2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsWayOfMeasurementOfHardness2(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={16} dropDownListsCallback={this.props.setHardnessTestingResultsWayOfMeasurementOfHardness2} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderHardnessTestingResultsTable2()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationHardnessTestingResultsWayOfMeasurementOfHardness3" size="sm">{t('PositionOfHardnessIndentation')}</Label>
                                                    <InputGroup>
                                                        <Input type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsWayOfMeasurementOfHardness3" id="testResultsExaminationHardnessTestingResultsWayOfMeasurementOfHardness3"
                                                            value={this.props.hardnessTestingResults.wayOfMeasurementOfHardness3} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsWayOfMeasurementOfHardness3(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={16} dropDownListsCallback={this.props.setHardnessTestingResultsWayOfMeasurementOfHardness3} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderHardnessTestingResultsTable3()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="testResultsExaminationHardnessTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="testResultsExaminationHardnessTestingResultsComments" id="testResultsExaminationHardnessTestingResultsComments"
                                                        value={this.props.hardnessTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label size="sm">{t('Acceptance')}</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={10}>
                                                <Form inline>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationHardnessTestingResultsAcceptanceResult"
                                                                value="yes"
                                                                checked={this.props.hardnessTestingResults.acceptanceResults === 'yes'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('Yes')}
                                                        </Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input type="radio" bssize="sm" name="testResultsExaminationHardnessTestingResultsAcceptanceResult"
                                                                value="no"
                                                                checked={this.props.hardnessTestingResults.acceptanceResults === 'no'}
                                                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsAcceptanceResult(event)}
                                                                placeholder="" />{' '} {t('No')}
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('OtherDetailsTestResult')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm>
                                                <FormGroup>
                                                    <Form inline>
                                                    <Label for="testResultsOtherTesting" size="sm">{t('OtherTesting')}</Label>
                                                    <span>&nbsp;</span>
                                                        <DropdownLists listNumber={23} dropDownListsCallback={this.props.setOtherTesting} rowNumber={0} ></DropdownLists>
                                                    </Form>
                                                    <Input type="textarea" rows={this.props.otherDetailsTestingResults.otherTesting.split("\n").length} bsSize="sm" name="testResultsOtherTesting" id="testResultsOtherTesting"
                                                        value={this.props.otherDetailsTestingResults.otherTesting} onChange={(event) => this.onChangeOtherDetailsTestingResultsOtherTesting(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm>
                                                <FormGroup>
                                                    <Form inline>
                                                        <Label for="testResultsRemarks" size="sm">{t('Remarks')}</Label>
                                                        <span>&nbsp;</span>
                                                        <DropdownLists listNumber={24} dropDownListsCallback={this.props.setRemarks} rowNumber={0} ></DropdownLists>
                                                    </Form>
                                                    <Input type="textarea" rows={this.props.otherDetailsTestingResults.remarks.split("\n").length} bsSize="sm" name="testResultsRemarks" id="testResultsRemarks"
                                                        value={this.props.otherDetailsTestingResults.remarks} onChange={(event) => this.onChangeOtherDetailsTestingResultsRemarks(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm>
                                                <FormGroup>
                                                    <Label for="testResultsLaboratoryReportReferenceNo" size="sm">{t('LabReportRefNo')}.</Label>
                                                    <Input type="text" bsSize="sm" name="testResultsLaboratoryReportReferenceNo" id="testResultsLaboratoryReportReferenceNo"
                                                        value={this.props.otherDetailsTestingResults.laboratoryReportReferenceNo} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLaboratoryReportReferenceNo(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm>
                                                <FormGroup>
                                                    <Form inline>
                                                        <Label for="testResultsTestCarriedOutInThePresenceOf" size="sm">{t('TestCarriedOut_')}</Label>
                                                        <span>&nbsp;</span>
                                                        <DropdownLists listNumber={25} dropDownListsCallback={this.props.setTestCarriedOutInThePresenceOf} rowNumber={0} ></DropdownLists>
                                                    </Form>
                                                    <Input type="textarea" rows={this.props.otherDetailsTestingResults.testCarriedOutInThePresenceOf.split("\n").length} bsSize="sm" name="testResultsTestCarriedOutInThePresenceOf" id="testResultsTestCarriedOutInThePresenceOf"
                                                        value={this.props.otherDetailsTestingResults.testCarriedOutInThePresenceOf}  onChange={(event) => this.onChangeOtherDetailsTestingResultsTestCarriedOutInThePresenceOf(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="testResultTestsPerformedWithAcceptableResult" size="sm">{t('TestPerformedWith_')}</Label>
                                                    <Input type="text" bsSize="sm" name="testResultTestsPerformedWithAcceptableResult" id="testResultTestsPerformedWithAcceptableResult"
                                                        value={this.props.otherDetailsTestingResults.testsPerformedWithAcceptableResult} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTestingResultsTestsPerformedWithAcceptableResult(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    {this.props.pRevisionWpqrAction === RevisionActionType.View ? "" :
                        <Row form>
                            <Col sm={6}>
                                {this.props.pRevisionWpqrAction === RevisionActionType.Clone ?
                                    <FormGroup >
                                        <Button type="button" color="primary" size="lg" id="generateclone" name="generateClone"
                                            onClick={(event) => this.onClickCloneWpqr(event)}>
                                            <i className="fas fa-clone"></i>
                                        </Button>
                                    </FormGroup>
                                    : <br />}
                            </Col>
                            <Col sm={6}>
                                <FormGroup className="float-right">
                                    <Button type="button" color="primary" size="lg" id="generatePdf" name="generatePdf"
                                        onClick={(event) => this.onClickSaveWpqr(event)}
                                        disabled={this.props.pRevisionWpqrAction === RevisionActionType.Clone ? true : false}>
                                        Save WPQR
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                </Form>
            </React.Fragment>
        );
    }

    private renderBendTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderBendTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.bendTestingResults.bendTestingResultsTableRows.map((row, index) => (
                            this.renderBendTestingResultsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderBendTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimenType')}.</th>
                    <th className="text-nowrap">{t('BendingAngle')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderBendTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment key={rowNumber}>
                <tr>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsTestSpecimenType-" + rowNumber} id={"bendTestingResultsTestSpecimenType-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenType}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeBendTestingResultsTestSpecimenType(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={9} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setBendTestingResultsTestSpecimenType} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsBendingAngle-" + rowNumber} id={"bendTestingResultsBendingAngle-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].bendingAngle}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeBendTestingResultsBendingAngle(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={8} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setBendTestingResultsBendingAngle} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsAcceptance-" + rowNumber} id={"bendTestingResultsAcceptance-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeBendTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={3} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setBendTestingResultsAcceptance} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsNumberOfGaugingTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <td className="text-nowrap">{t('NumberOfIndentations')}</td>
                    <td className="text-nowrap">1</td>
                    <td className="text-nowrap">2</td>
                    <td className="text-nowrap">3</td>
                    <td className="text-nowrap">4</td>
                    <td className="text-nowrap">5</td>
                    <td className="text-nowrap">6</td>
                    <td className="text-nowrap">7</td>
                    <td className="text-nowrap">8</td>
                    <td className="text-nowrap">9</td>
                    <td className="text-nowrap">10</td>
                    <td className="text-nowrap">11</td>
                    <td className="text-nowrap">12</td>
                    <td className="text-nowrap">13</td>
                    <td className="text-nowrap">14</td>
                    <td className="text-nowrap">15</td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsPlaceOfGaugingTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <td className="text-nowrap">{t('PlaceOfIndentation')}</td>
                    <td className="text-nowrap">BM</td>
                    <td className="text-nowrap">BM</td>
                    <td className="text-nowrap">BM</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">weld</td>
                    <td className="text-nowrap">weld</td>
                    <td className="text-nowrap">weld</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">BM</td>
                    <td className="text-nowrap">BM</td>
                    <td className="text-nowrap">BM</td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsTable1() {
        return (
            <React.Fragment>
                <Table size="sm" responsive className="table-text-align-center">
                    <tbody>
                        {this.renderHardnessTestingResultsNumberOfGaugingTableRow()}
                        {this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows.map((row, index) => (
                            this.renderHardnessTestingResultsValueOfHardnessTable1Row(index + 1)
                        ))}
                        {this.renderHardnessTestingResultsPlaceOfGaugingTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsTable2() {
        return (
            <React.Fragment>
                <Table size="sm" responsive className="table-text-align-center">
                    <tbody>
                        {this.renderHardnessTestingResultsNumberOfGaugingTableRow()}
                        {this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows.map((row, index) => (
                            this.renderHardnessTestingResultsValueOfHardnessTable2Row(index + 1)
                        ))}
                        {this.renderHardnessTestingResultsPlaceOfGaugingTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsTable3() {
        return (
            <React.Fragment>
                <Table size="sm" responsive className="table-text-align-center">
                    <tbody>
                        {this.renderHardnessTestingResultsNumberOfGaugingTableRow()}
                        {this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows.map((row, index) => (
                            this.renderHardnessTestingResultsValueOfHardnessTable3Row(index + 1)
                        ))}
                        {this.renderHardnessTestingResultsPlaceOfGaugingTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderHardnessTestingResultsValueOfHardnessTable1Row(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <td>{t('ValueOfHardness')}</td>
                    <td>
                        <Input className="padding-form-control "type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1Parent1" id="testResultsExaminationHardnessTestingResultsTable1Parent1"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent1(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1Parent2" id="testResultsExaminationHardnessTestingResultsTable1Parent2"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent2(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1Parent3" id="testResultsExaminationHardnessTestingResultsTable1Parent3"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent3} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent3(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ4" id="testResultsExaminationHardnessTestingResultsTable1HAZ4"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz4} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ4(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ5" id="testResultsExaminationHardnessTestingResultsTable1HAZ5"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz5} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ5(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ6" id="testResultsExaminationHardnessTestingResultsTable1HAZ6"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz6} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ6(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ16" id="testResultsExaminationHardnessTestingResultsTable1HAZ16"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz16} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ16(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ17" id="testResultsExaminationHardnessTestingResultsTable1HAZ17"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz17} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ17(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1Weld7" id="testResultsExaminationHardnessTestingResultsTable1Weld7"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld7} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Weld7(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1Weld8" id="testResultsExaminationHardnessTestingResultsTable1Weld8"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld8} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Weld8(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1Weld9" id="testResultsExaminationHardnessTestingResultsTable1Weld9"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld9} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Weld9(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ10" id="testResultsExaminationHardnessTestingResultsTable1HAZ10"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz10} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ10(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ18" id="testResultsExaminationHardnessTestingResultsTable1HAZ18"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz18} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ18(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ19" id="testResultsExaminationHardnessTestingResultsTable1HAZ19"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz19} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ19(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ11" id="testResultsExaminationHardnessTestingResultsTable1HAZ11"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz11} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ11(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ12" id="testResultsExaminationHardnessTestingResultsTable1HAZ12"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz12} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ12(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1Parent13" id="testResultsExaminationHardnessTestingResultsTable1Parent13"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent13} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent13(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1Parent14" id="testResultsExaminationHardnessTestingResultsTable1Parent14"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent14} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent14(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1Parent15" id="testResultsExaminationHardnessTestingResultsTable1Parent15"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent15} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent15(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsValueOfHardnessTable2Row(rowNumber: number) {
        return (
            <React.Fragment key={rowNumber}>
                <tr>
                    <td>{t('ValueOfHardness')}</td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2Parent1" id="testResultsExaminationHardnessTestingResultsTable2Parent1"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent1(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2Parent2" id="testResultsExaminationHardnessTestingResultsTable2Parent2"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent2(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2Parent3" id="testResultsExaminationHardnessTestingResultsTable2Parent3"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent3} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent3(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2HAZ4" id="testResultsExaminationHardnessTestingResultsTable2HAZ4"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz4} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ4(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2HAZ5" id="testResultsExaminationHardnessTestingResultsTable2HAZ5"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz5} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ5(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2HAZ6" id="testResultsExaminationHardnessTestingResultsTable2HAZ6"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz6} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ6(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ16" id="testResultsExaminationHardnessTestingResultsTable1HAZ16"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz16} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ16(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ17" id="testResultsExaminationHardnessTestingResultsTable1HAZ17"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz17} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ17(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2Weld7" id="testResultsExaminationHardnessTestingResultsTable2Weld7"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld7} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Weld7(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2Weld8" id="testResultsExaminationHardnessTestingResultsTable2Weld8"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld8} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Weld8(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2Weld9" id="testResultsExaminationHardnessTestingResultsTable2Weld9"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld9} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Weld9(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2HAZ10" id="testResultsExaminationHardnessTestingResultsTable2HAZ10"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz10} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ10(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ18" id="testResultsExaminationHardnessTestingResultsTable1HAZ18"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz18} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ18(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable1HAZ19" id="testResultsExaminationHardnessTestingResultsTable1HAZ19"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz19} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ19(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2HAZ11" id="testResultsExaminationHardnessTestingResultsTable2HAZ11"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz11} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ11(rowNumber, event)}
                            placeholder="" />
                        
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2HAZ12" id="testResultsExaminationHardnessTestingResultsTable2HAZ12"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz12} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ12(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2Parent13" id="testResultsExaminationHardnessTestingResultsTable2Parent13"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent13} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent13(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2Parent14" id="testResultsExaminationHardnessTestingResultsTable2Parent14"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent14} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent14(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable2Parent15" id="testResultsExaminationHardnessTestingResultsTable2Parent15"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent15} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent15(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsValueOfHardnessTable3Row(rowNumber: number) {
        return (
            <React.Fragment key={rowNumber}>
                <tr>
                    <td>{t('ValueOfHardness')}</td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3Parent1" id="testResultsExaminationHardnessTestingResultsTable3Parent1"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3Parent1(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3Parent2" id="testResultsExaminationHardnessTestingResultsTable3Parent2"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3Parent2(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3Parent3" id="testResultsExaminationHardnessTestingResultsTable3Parent3"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent3} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3Parent3(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ4" id="testResultsExaminationHardnessTestingResultsTable3HAZ4"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz4} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ4(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ5" id="testResultsExaminationHardnessTestingResultsTable3HAZ5"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz5} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ5(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ6" id="testResultsExaminationHardnessTestingResultsTable3HAZ6"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz6} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ6(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ16" id="testResultsExaminationHardnessTestingResultsTable3HAZ16"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz16} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ16(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ17" id="testResultsExaminationHardnessTestingResultsTable3HAZ17"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz17} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ17(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3Weld7" id="testResultsExaminationHardnessTestingResultsTable3Weld7"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].weld7} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3Weld7(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3Weld8" id="testResultsExaminationHardnessTestingResultsTable3Weld8"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].weld8} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3Weld8(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3Weld9" id="testResultsExaminationHardnessTestingResultsTable3Weld9"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].weld9} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3Weld9(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ10" id="testResultsExaminationHardnessTestingResultsTable3HAZ10"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz10} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ10(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ18" id="testResultsExaminationHardnessTestingResultsTable3HAZ18"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz18} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ18(rowNumber, event)}
                            placeholder="" />
                        <Input className="padding-form-control margin-bottom" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ19" id="testResultsExaminationHardnessTestingResultsTable3HAZ19"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz19} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ19(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ11" id="testResultsExaminationHardnessTestingResultsTable3HAZ11"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz11} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ11(rowNumber, event)}
                            placeholder="" />
                        
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3HAZ12" id="testResultsExaminationHardnessTestingResultsTable3HAZ12"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].haz12} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3HAZ12(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3Parent13" id="testResultsExaminationHardnessTestingResultsTable3Parent13"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent13} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3Parent13(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3Parent14" id="testResultsExaminationHardnessTestingResultsTable3Parent14"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent14} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3Parent14(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input className="padding-form-control" type="text" bsSize="sm" name="testResultsExaminationHardnessTestingResultsTable3Parent15" id="testResultsExaminationHardnessTestingResultsTable3Parent15"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable3Rows[rowNumber - 1].parent15} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable3Parent15(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
    private renderImpactTestingResultsWeldTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderImpactTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.impactTestingResults.weldImpactTestingResultsTableRows.map((row, index) => (
                            this.renderImpactTestingResultsWeldTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsWeldTableRow(rowNumber: number) {
        return (
            <React.Fragment key={rowNumber}>
                <tr>
                    <td className="impact-testing-td" hidden={!(rowNumber === 1)} colSpan={8}>{t('ImpactTestingResultsInWeld')}</td>
                    <td className="impact-testing-td" hidden={!(rowNumber === 2)} colSpan={8}>{t('ImpactTestingResultsInHAZ')}</td>
                    <td className="impact-testing-td" hidden={!(rowNumber === 3)} colSpan={8}>{t('ImpactTestingResultsInHAZ') + "2"}</td>
                    <td className="impact-testing-td" hidden={!(rowNumber === 4)} colSpan={8}>{t('ImpactTestingResults')}</td>
                    <td className="impact-testing-td" hidden={!(rowNumber === 5)} colSpan={8}>{t('ImpactTestingResults')}</td>
                </tr>
                <tr>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationImpactTestingResultsWeldTestSpecimenType-" + rowNumber} id={"testResultsExaminationImpactTestingResultsWeldTestSpecimenType-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenType}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldTestSpecimenType(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={11} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setImpactTestingResultsWeldTestSpecimenType} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationImpactTestingResultsWeldTestSpecimenDimensions-" + rowNumber} id={"testResultsExaminationImpactTestingResultsWeldTestSpecimenDimensions-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldTestSpecimenDimensions(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={12} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setImpactTestingResultsWeldTestSpecimenDimensions} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationImpactTestingResultsWeldAbsorbedEnergy-" + rowNumber} id={"testResultsExaminationImpactTestingResultsWeldAbsorbedEnergy-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAbsorbedEnergy(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationImpactTestingResultsWeldAbsorbedEnergy-" + rowNumber} id={"testResultsExaminationImpactTestingResultsWeldAbsorbedEnergy-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy1}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAbsorbedEnergy1(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationImpactTestingResultsWeldAbsorbedEnergy-" + rowNumber} id={"testResultsExaminationImpactTestingResultsWeldAbsorbedEnergy-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy2}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAbsorbedEnergy2(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationImpactTestingResultsWeldAbsorbedEnergy-" + rowNumber} id={"testResultsExaminationImpactTestingResultsWeldAbsorbedEnergy-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].average}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsAverage(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationImpactTestingResultsWeldAcceptance-" + rowNumber} id={"testResultsExaminationImpactTestingResultsWeldAcceptance-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAcceptance(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={15} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setImpactTestingResultsWeldAcceptance} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('NotchLocation')}.</th>
                    <th className="text-nowrap">{t('TestSpecimenDimensions')}. (mm)</th>
                    <th className="text-nowrap">{t('AbsorbedEnergy')} KV (J)</th>
                    <th className="text-nowrap">{t('AbsorbedEnergy')} KV (J)</th>
                    <th className="text-nowrap">{t('AbsorbedEnergy')} KV (J)</th>
                    <th className="text-nowrap">{t('Average')}</th>
                    <th className="text-nowrap">{t('Remarks')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderLongitudinalTensileTestingResultsAcceptablesValuesTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <td scope="row" colSpan={1}>
                        <Label size="sm" className="float-right">{t('AcceptableValues')}</Label>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesURm" id="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesURm"
                            value={this.props.longitudinalTensileTestingResults.acceptableValuesURm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsAcceptableValuesURm(event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setLongitudinalTensileTestingResultsAcceptableValuesURm} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesURm" id="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesURm"
                                value={this.props.longitudinalTensileTestingResults.acceptableValuesUFm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsAcceptableValuesUFm(event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setLongitudinalTensileTestingResultsAcceptableValuesUFm} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesUR" id="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesUR"
                            value={this.props.longitudinalTensileTestingResults.acceptableValuesUR} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsAcceptableValuesUR(event)}
                            placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setLongitudinalTensileTestingResultsAcceptableValuesUR} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesUA" id="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesUA"
                            value={this.props.longitudinalTensileTestingResults.acceptableValuesUA} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsAcceptableValuesUA(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesUZ" id="testResultsExaminationLongitudinalTensileTestingResultsAcceptableValuesUZ"
                            value={this.props.longitudinalTensileTestingResults.acceptableValuesUZ} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsAcceptableValuesUZ(event)}
                            placeholder="" />
                    </td>
                    <td colSpan={2}>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderLongitudinalTensileTestingResultsMeasurementUncertaintyTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th colSpan={1}></th>
                    <th className="text-nowrap">U Rm</th>
                    <th className="text-nowrap">Rp 0,2 N/mm</th>
                    <th className="text-nowrap">U ReH</th>
                    <th className="text-nowrap">U A</th>
                    <th className="text-nowrap">U Z</th>
                    <th colSpan={2}></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderLongitudinalTensileTestingResultsMeasurementUncertaintyTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row" colSpan={1}>
                        <Label size="sm" className="float-right">{t('MeasurementUncertainty')}</Label>
                    </th>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyURm" id="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyURm"
                            value={this.props.longitudinalTensileTestingResults.measurementUncertaintyURm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyURm(event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setLongitudinalTensileTestingResultsMeasurementUncertaintyURm} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyURp" id="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyURp"
                                value={this.props.longitudinalTensileTestingResults.measurementUncertaintyUFm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyUFm(event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setLongitudinalTensileTestingResultsMeasurementUncertaintyUFm} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyUR" id="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyUR"
                            value={this.props.longitudinalTensileTestingResults.measurementUncertaintyUR} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyUR(event)}
                            placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setLongitudinalTensileTestingResultsMeasurementUncertaintyUR} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyUA" id="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyUA"
                            value={this.props.longitudinalTensileTestingResults.measurementUncertaintyUA} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyUA(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyUZ" id="testResultsExaminationLongitudinalTensileTestingResultsMeasurementUncertaintyUZ"
                            value={this.props.longitudinalTensileTestingResults.measurementUncertaintyUZ} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsMeasurementUncertaintyUZ(event)}
                            placeholder="" />
                    </td>
                    <th colSpan={2}>
                    </th>
                </tr>
            </React.Fragment>
        );
    }

    private renderLongitudinalTensileTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderLongitudinalTensileTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.renderLongitudinalTensileTestingResultsAcceptablesValuesTableRow()}
                        {this.props.longitudinalTensileTestingResults.tensileTestingResultsTableRows.map((row, index) => (
                            this.renderLongitudinalTensileTestingResultsTableRow(index + 1)
                        ))}
                        {this.renderLongitudinalTensileTestingResultsMeasurementUncertaintyTableHeaderRow()}
                        {this.renderLongitudinalTensileTestingResultsMeasurementUncertaintyTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderLongitudinalTensileTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimenNo')}.</th>
                    <th className="text-nowrap">Rm (MPa)</th>
                    <th className="text-nowrap">Rp 0,2 N/mm.</th>
                    <th className="text-nowrap">ReH (MPa)</th>
                    <th className="text-nowrap">A (%)</th>
                    <th className="text-nowrap">Z (%)</th>
                    <th className="text-nowrap">{t('PlaceOfFracture')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderLongitudinalTensileTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment key={rowNumber}>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"testResultsExaminationLongitudinalTensileTestingResultsTestSpecimenNumber-" + rowNumber} id={"testResultsExaminationLongitudinalTensileTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationLongitudinalTensileTestingResultsRm-" + rowNumber} id={"testResultsExaminationLongitudinalTensileTestingResultsRm-" + rowNumber}
                            value={this.props.longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].rm}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsRm(rowNumber, event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setLongitudinalTensileTestingResultsRm} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name={"testResultsExaminationLongitudinalTensileTestingResultsRp-" + rowNumber} id={"testResultsExaminationLongitudinalTensileTestingResultsRp-" + rowNumber}
                                value={this.props.longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].rp}
                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsRp(rowNumber, event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setLongitudinalTensileTestingResultsRp} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationLongitudinalTensileTestingResultsR-" + rowNumber} id={"testResultsExaminationLongitudinalTensileTestingResultsR-" + rowNumber}
                            value={this.props.longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].r}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsR(rowNumber, event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setLongitudinalTensileTestingResultsR} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationLongitudinalTensileTestingResultsA-" + rowNumber} id={"testResultsExaminationLongitudinalTensileTestingResultsA-" + rowNumber}
                            value={this.props.longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].a}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsA(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationLongitudinalTensileTestingResultsZ-" + rowNumber} id={"testResultsExaminationLongitudinalTensileTestingResultsZ-" + rowNumber}
                            value={this.props.longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].z}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsZ(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationLongitudinalTensileTestingResultsFractureSurfaceCondition-" + rowNumber} id={"testResultsExaminationLongitudinalTensileTestingResultsFractureSurfaceCondition-" + rowNumber}
                            value={this.props.longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].fractureSurfaceCondition}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsFractureSurfaceCondition(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={6} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setLongitudinalTensileTestingResultsFractureSurfaceCondition} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationLongitudinalTensileTestingResultsAcceptance-" + rowNumber} id={"testResultsExaminationLongitudinalTensileTestingResultsAcceptance-" + rowNumber}
                            value={this.props.longitudinalTensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLongitudinalTensileTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                        <DropdownLists listNumber={3} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setLongitudinalTensileTestingResultsAcceptance} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderMacroTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderMacroTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.macroTestingResults.macroTestingResultsTableRows.map((row, index) => (
                            this.renderMacroTestingResultsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderMacroTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                    <th className="text-nowrap">{t('Comments')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderMacroTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment key={rowNumber}>
                <tr>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name={"testResultsExaminationMacroTestingResultsAcceptance-" + rowNumber} id={"testResultsExaminationMacroTestingResultsAcceptance-" + rowNumber}
                                value={this.props.macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].acceptance}
                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsAcceptance(rowNumber, event)}
                                placeholder="" />
                            <DropdownLists listNumber={3} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setMacroTestingResultsAcceptance} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td scope="row">
                        <Input type="text" bsSize="sm" name={"testResultsExaminationMacroTestingResultsTestSpecimenNumber-" + rowNumber} id={"testResultsExaminationMacroTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderMicroTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderMicroTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.microTestingResults.microTestingResultsTableRows.map((row, index) => (
                            this.renderMicroTestingResultsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderMicroTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                    <th className="text-nowrap">{t('Comments')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderMicroTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment key={rowNumber}>
                <tr>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name={"testResultsExaminationMicroTestingResultsAcceptance-" + rowNumber} id={"testResultsExaminationMicroTestingResultsAcceptance-" + rowNumber}
                                value={this.props.microTestingResults.microTestingResultsTableRows[rowNumber - 1].acceptance}
                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsAcceptance(rowNumber, event)}
                                placeholder="" />
                            <DropdownLists listNumber={3} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setMicroTestingResultsAcceptance} rowNumber={rowNumber}></DropdownLists>
                        </InputGroup>
                    </td>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"testResultsExaminationMicroTestingResultsTestSpecimenNumber-" + rowNumber} id={"testResultsExaminationMicroTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.microTestingResults.microTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                </tr>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsAcceptablesValuesTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <td scope="row" colSpan={1}>
                        <Label size="sm" className="float-right">{t('AcceptableValues')}</Label>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesURm" id="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesURm"
                            value={this.props.transverseTensileTestingResults.acceptableValuesURm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptableValuesURm(event)}
                            placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setTransverseTensileTestingResultsAcceptableValuesURm} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesURp" id="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesURp"
                                value={this.props.transverseTensileTestingResults.acceptableValuesUFm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptableValuesUFm(event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setTransverseTensileTestingResultsAcceptableValuesUFm} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesUR" id="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesUR"
                            value={this.props.transverseTensileTestingResults.acceptableValuesUR} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptableValuesUR(event)}
                            placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setTransverseTensileTestingResultsAcceptableValuesUR} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesUA" id="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesUA"
                            value={this.props.transverseTensileTestingResults.acceptableValuesUA} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptableValuesUA(event)}
                            placeholder="" />
                    </td>
                    <td colSpan={2}></td>
                </tr>
                <tr>
                    <td scope="row" colSpan={1}>
                        <Label size="sm" className="float-right">{t('AcceptableValues')}</Label>
                    </td>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesURm1" id="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesURm1"
                                value={this.props.transverseTensileTestingResults.acceptableValuesURm1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptableValuesURm1(event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setTransverseTensileTestingResultsAcceptableValuesURm1} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesURp1" id="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesURp1"
                                value={this.props.transverseTensileTestingResults.acceptableValuesUFm1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptableValuesUFm1(event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setTransverseTensileTestingResultsAcceptableValuesUFm1} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesUR1" id="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesUR1"
                                value={this.props.transverseTensileTestingResults.acceptableValuesUR1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptableValuesUR1(event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setTransverseTensileTestingResultsAcceptableValuesUR1} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesUA1" id="testResultsExaminationTransverseTensileTestingResultsAcceptableValuesUA1"
                            value={this.props.transverseTensileTestingResults.acceptableValuesUA1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptableValuesUA1(event)}
                            placeholder="" />
                    </td>
                    <td colSpan={2}></td>
                </tr>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsMeasurementUncertaintyTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th colSpan={1}></th>
                    <th className="text-nowrap">U Rm</th>
                    <th className="text-nowrap">Rp 0,2 N/mm</th>
                    <th className="text-nowrap">U ReH</th>
                    <th className="text-nowrap">U A</th>
                    <td colSpan={2}></td>
                </tr>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsMeasurementUncertaintyTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row" colSpan={1}>
                        <Label size="sm" className="float-right">{t('MeasurementUncertainty')}</Label>
                    </th>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsMeasurementUncertaintyURm" id="testResultsExaminationTransverseTensileTestingResultsMeasurementUncertaintyURm"
                            value={this.props.transverseTensileTestingResults.measurementUncertaintyURm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsMeasurementUncertaintyURm(event)}
                            placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setTransverseTensileTestingResultsMeasurementUncertaintyURm} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsMeasurementUncertaintyURp" id="testResultsExaminationTransverseTensileTestingResultsMeasurementUncertaintyURp"
                                value={this.props.transverseTensileTestingResults.measurementUncertaintyUFm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsMeasurementUncertaintyUFm(event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setTransverseTensileTestingResultsMeasurementUncertaintyUFm} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsMeasurementUncertaintyUR" id="testResultsExaminationTransverseTensileTestingResultsMeasurementUncertaintyUR"
                            value={this.props.transverseTensileTestingResults.measurementUncertaintyUR} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsMeasurementUncertaintyUR(event)}
                            placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.props.setTransverseTensileTestingResultsMeasurementUncertaintyUR} dropDownListsCallbackRow={this.onClickSetTextWithRow} rowNumber={0} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="testResultsExaminationTransverseTensileTestingResultsMeasurementUncertaintyUA" id="testResultsExaminationTransverseTensileTestingResultsMeasurementUncertaintyUA"
                            value={this.props.transverseTensileTestingResults.measurementUncertaintyUA} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsMeasurementUncertaintyUA(event)}
                            placeholder="" />
                    </td>
                    <td colSpan={2}></td>
                </tr>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderTransverseTensileTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.renderTransverseTensileTestingResultsAcceptablesValuesTableRow()}
                        {this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.map((row, index) => (
                            this.renderTransverseTensileTestingResultsTableRow(index + 1)
                        ))}
                        {this.renderTransverseTensileTestingResultsMeasurementUncertaintyTableHeaderRow()}
                        {this.renderTransverseTensileTestingResultsMeasurementUncertaintyTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimenNo')}.</th>
                    <th className="text-nowrap">Rm (MPa)</th>
                    <th className="text-nowrap">Rp 0,2 N/mm</th>
                    <th className="text-nowrap">ReH (MPa)</th>
                    <th className="text-nowrap">A (%)</th>
                    <th className="text-nowrap">{t('PlaceOfFracture')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"testResultsExaminationTransverseTensileTestingResultsTestSpecimenNumber-" + rowNumber} id={"testResultsExaminationTransverseTensileTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationTransverseTensileTestingResultsRm-" + rowNumber} id={"testResultsExaminationTransverseTensileTestingResultsRm-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].rm}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsRm(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setTransverseTensileTestingResultsRm} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <Input type="text" bsSize="sm" name={"testResultsExaminationTransverseTensileTestingResultsRp-" + rowNumber} id={"testResultsExaminationTransverseTensileTestingResultsRp-" + rowNumber}
                                value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].rp}
                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsRp(rowNumber, event)}
                                placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setTransverseTensileTestingResultsRp} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationTransverseTensileTestingResultsR-" + rowNumber} id={"testResultsExaminationTransverseTensileTestingResultsR-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].r}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsR(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={5} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setTransverseTensileTestingResultsR} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationTransverseTensileTestingResultsA-" + rowNumber} id={"testResultsExaminationTransverseTensileTestingResultsA-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].a}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsA(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationTransverseTensileTestingResultsPlaceOfBreak-" + rowNumber} id={"testResultsExaminationTransverseTensileTestingResultsPlaceOfBreak-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].placeOfBreak}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsPlaceOfBreak(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={6} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setTransverseTensileTestingResultsPlaceOfBreak} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                        <Input type="text" bsSize="sm" name={"testResultsExaminationTransverseTensileTestingResultsAcceptance-" + rowNumber} id={"testResultsExaminationTransverseTensileTestingResultsAcceptance-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                            <DropdownLists listNumber={3} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setTransverseTensileTestingResultsAcceptance} rowNumber={rowNumber} ></DropdownLists>
                        </InputGroup>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.testResultsExamination;
}
export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    TestResultsExaminationStore.actionCreators // Selects which action creators are merged into the component's props
)(TestResultsExamination as any);
