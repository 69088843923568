﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import authHeader from './models/auth-header';
import { UpdateMyPageRequest } from './models/requests/UpdateMyPageRequest';
import { UserListObject } from './UserListObject';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MyPageState {
    editModalOpen: boolean
    settings: {
        timeIntervalForEmailNotification: string
    }
    information: UserListObject,
    contactInformationForDekra: UserListObject
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoginFailureResponseAction {
    type: 'LOGIN_FAILURE_RESPONSE';
}

export interface LoginRequestAction {
    type: 'LOGIN_REQUEST';
}

export interface LoginSuccessResponseAction {
    type: 'LOGIN_SUCCESS_RESPONSE';
    accessToken: string;
}

export interface LogoutAction {
    type: 'LOGOUT';
}

export interface CheckTokenAction {
    type: 'CHECK_TOKEN_ACTION';
}

export interface SetPasswordAction {
    type: 'SET_PASSWORD';
    password: string;
}

export interface SetUserInformation {
    type: 'SET_USER_INFORMATION';
    user: UserListObject;
}

export interface SetUserDekraInformation {
    type: 'SET_USER_DEKRA_INFORMATION';
    user: UserListObject;
}

export interface ToggleEditModal {
    type: 'TOGGLE_EDIT_MODAL';
    editModalOpen: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = LoginFailureResponseAction
    | LoginRequestAction
    | LoginSuccessResponseAction
    | LogoutAction
    | SetPasswordAction
    | SetUserInformation
    | SetUserDekraInformation
    | CheckTokenAction
    | ToggleEditModal


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    login: (userName: string, password: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'LOGIN_REQUEST' });

        fetch('', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userName, password })
        })
            .then(response => response.json())
            .then(data => {
                // localStorage.setItem('user', JSON.stringify(data));
                //  dispatch({ type: 'LOGIN_SUCCESS_RESPONSE', user: data });
            })
            .catch(error => {
                dispatch({ type: 'LOGIN_FAILURE_RESPONSE' });
            });
    },
    initForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab && appState.drawJointDesign) {
            
        }
    },
    adlogin: (token: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'LOGIN_REQUEST' });

        const myHeaders = new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });

        fetch(`/api/User/current/`, {
            method: 'GET',
            headers: myHeaders,

        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    return dispatch({ type: 'LOGIN_FAILURE_RESPONSE' });
                }

                //localStorage.setItem('accessToken', token);

                return dispatch({ type: 'LOGIN_SUCCESS_RESPONSE', accessToken: token })
            }))
            .catch(error => {

            });
    },
    currentUser: (): AppThunkAction<KnownAction> => (dispatch) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/current/`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('user');
                        localStorage.removeItem('userId');
                        localStorage.removeItem('customerId');

                        return dispatch({ type: 'LOGOUT' });
                    }

                    //return dispatch({ type: 'CHECK_TOKEN_ACTION' });
                }))
                .catch(error => {

                });
        }
        else {
            //return dispatch({ type: 'LOGOUT' });
        }
    },
    logout: (): AppThunkAction<KnownAction> => (dispatch) => {

        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        localStorage.removeItem('userId');
        localStorage.removeItem('customerId');

        return dispatch({ type: 'LOGOUT' });
    },
    CheckToken: (): AppThunkAction<KnownAction> => (dispatch) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/current/`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('user');
                        localStorage.removeItem('userId');
                        localStorage.removeItem('customerId');

                        return dispatch({ type: 'LOGOUT' });
                    }

                    return dispatch({ type: 'CHECK_TOKEN_ACTION' });
                }))
                .catch(error => {

                });
        }
        else {
            return dispatch({ type: 'LOGOUT' });
        }
    },
    recieveUserInformation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/current/`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    dispatch({ type: 'SET_USER_INFORMATION', user: res.data });
                }))
                .catch(error => {
                    console.log(error);
                });
        }
    },
    updateUserInformation: (userUpdate: UpdateMyPageRequest, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const currentMyPage = getState().myPage;
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/UpdateMyPage/`, {
                method: 'PUT',
                headers: authHeader.authHeader(),
                body: JSON.stringify(userUpdate)
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (callback !== null) {
                        callback();
                    }
                }))
                .catch(error => {
                    console.log(error);
                });
        }
    },
    setUserInformation: (user: UserListObject): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_USER_INFORMATION', user: user });
    },
    setUserDekraInformation: (user: UserListObject): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_USER_DEKRA_INFORMATION', user: user });
    },
    toggleEditModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {

            let editModalOpen = appState.myPage?.editModalOpen;
            dispatch({ type: 'TOGGLE_EDIT_MODAL', editModalOpen: !editModalOpen });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

let value = localStorage.getItem('token');

const unloadedState: MyPageState =
    {
        editModalOpen: false,
        settings: {
            timeIntervalForEmailNotification: ""
        },
        information: {
            userName: "",
            firstName: "",
            lastName: "",
            displayName: "",
            title: "",
            phoneNumber: "",
            mobilePhoneNumber: "",
            phoneExchangeNumber: "",
            email: "",
            roles: [""],
            visitingCity: "",
            visitingZipcode: "",
            visitingStreet: "",
            postCity: "",
            postZipcode: "",
            postStreet: "",
            website: "",
            customer: null,
            customerId: "",
            id: "0",
            dekraContact: null,
            dekraContactId: "",
            signatureLogo: "",
        },
        contactInformationForDekra: {
            userName: "",
            firstName: "",
            lastName: "",
            displayName: "",
            title: "",
            phoneNumber: "",
            mobilePhoneNumber: "",
            phoneExchangeNumber: "",
            email: "",
            roles: [""],
            visitingCity: "",
            visitingZipcode: "",
            visitingStreet: "",
            postCity: "",
            postZipcode: "",
            postStreet: "",
            website: "",
            customer: null,
            customerId: "",
            id: "0",
            dekraContact: null,
            dekraContactId: "",
            signatureLogo: "",
        }
    };

export const reducer: Reducer<MyPageState> = (state: MyPageState | undefined, incomingAction: Action): MyPageState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'TOGGLE_EDIT_MODAL':
            return {
                editModalOpen: action.editModalOpen,
                settings: {
                    timeIntervalForEmailNotification: state.settings.timeIntervalForEmailNotification
                },
                information: state.information,
                contactInformationForDekra: state.contactInformationForDekra
            }
        case 'SET_USER_INFORMATION':
            return {
                editModalOpen: state.editModalOpen,
                settings: {
                    timeIntervalForEmailNotification: state.settings.timeIntervalForEmailNotification
                },
                information: action.user,
                contactInformationForDekra: state.contactInformationForDekra
            }
        case 'SET_USER_DEKRA_INFORMATION':
            return {
                editModalOpen: state.editModalOpen,
                settings: {
                    timeIntervalForEmailNotification: state.settings.timeIntervalForEmailNotification
                },
                information: state.information,
                contactInformationForDekra: action.user
            }
        default:
            return state;
    }
};
