﻿
import { GetOtherInformationRangeOfQualificationResponse } from './models/responses/GetOtherInformationRangeOfQualificationResponse';
import { RangeOfQualificationInfo } from './models/RangeOfQualificationInfo';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import HelpFunctions from './models/HelpFunctions';
import { GetRangeOfQualificationExaminationResponse } from './models/responses/GetRangeOfQualificationExaminationResponse';
import { GetWeldingPositionResponse } from './models/responses/GetWeldingPositionResponse';
import  authHeader  from './models/auth-header';
import { GetStandardResponse } from './models/responses/GetStandardResponse';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface RangeOfQualificationState {
    otherInformations: Array<GetOtherInformationRangeOfQualificationResponse>;
    selectedOtherInformation: GetOtherInformationRangeOfQualificationResponse;
    weldingPositionResult: Array<GetWeldingPositionResponse>;
    rangeOfQualificationInfo: RangeOfQualificationInfo;
    standards: Array<GetStandardResponse>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface GetOtherInformationRangeOfQualificationsReceiveAction {
    type: 'GET_OTHER_INFORMATION_RECEIVE';
    otherInformations: Array<GetOtherInformationRangeOfQualificationResponse>;
}

interface GetOtherInformationRangeOfQualificationsRequestAction {
    type: 'GET_OTHER_INFORMATION_REQUEST';
}

interface SetRangeOfQualificationInfoAction {
    type: 'SET_RANGE_OF_QUALIFICATION_INFO';
    rangeOfQualificationInfo: RangeOfQualificationInfo;
}

interface SetSelectedOtherInformationAction {
    type: 'SET_SELECTED_OTHER_INFORMATION';
    selectedOtherInformation: GetOtherInformationRangeOfQualificationResponse;
}

interface GetRangeOfQualificationWeldingPositionReceiveAction {
    type: 'SET_RANGE_OF_QUALIFICATION_WELDING_POSITION_RECEIVE';
    weldingPositionResult: Array<GetWeldingPositionResponse>;
}

interface GetRangeOfQualificationWeldingPositionRequestAction {
    type: 'GET_RANGE_OF_QUALIFICATION_WELDING_POSITION_REQUEST';
}

export interface GetRangeOfQualificationStandardsReceiveAction {
    type: 'GET_RANGE_OF_QUALIFICATION_STANDARDS_RECEIVE';
    standards: Array<GetStandardResponse>;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction =
    | GetOtherInformationRangeOfQualificationsReceiveAction
    | GetOtherInformationRangeOfQualificationsRequestAction
    | SetSelectedOtherInformationAction
    | SetRangeOfQualificationInfoAction
    | GetRangeOfQualificationWeldingPositionReceiveAction
    | GetRangeOfQualificationWeldingPositionRequestAction
    | GetRangeOfQualificationStandardsReceiveAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getOtherInformationRangeOfQualificationsAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/OtherInformationRangeOfQualifications/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<Array<GetOtherInformationRangeOfQualificationResponse>>)
            .then(data => {
                console.info(data);
                dispatch({ type: 'GET_OTHER_INFORMATION_RECEIVE', otherInformations: data });
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_OTHER_INFORMATION_REQUEST' });
    },
    getWeldingPositionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingPositions/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<Array<GetWeldingPositionResponse>>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.rangeOfQualification) {
                    let getWeldingPositionResponse: Array<GetWeldingPositionResponse> = { ...appState.rangeOfQualification.weldingPositionResult };
                    getWeldingPositionResponse =[...data];
                    dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_WELDING_POSITION_RECEIVE', weldingPositionResult: getWeldingPositionResponse });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_RANGE_OF_QUALIFICATION_WELDING_POSITION_REQUEST' });
    },
    setAddress: (address: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.address = address;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setDateOfIssue: (dateOfIssue: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.dateOfIssue = dateOfIssue;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setDateOfWelding: (dateOfWelding: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.dateOfWelding = dateOfWelding;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setJointType: (jointType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableJointTypeText) {
                rangeOfQualificationInfo.jointType = jointType;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setMetalThickness: (metalThickness: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableWeldingMetalThicknessText) {
                rangeOfQualificationInfo.weldingMetalThicknessText = metalThickness;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setThroatThickness: (throatThickness: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableThroatThicknessText) {
                rangeOfQualificationInfo.throatThicknessText = throatThickness;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setRunType: (runType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableRunTypeText) {
                rangeOfQualificationInfo.runTypeText = runType;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setDiameter: (diameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableDiameterText) {
                rangeOfQualificationInfo.diameterText = diameter;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setRootProtectionGas: (rootProtectionGas: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableRootProtectionGasText) {
                rangeOfQualificationInfo.rootProtectionGasText = rootProtectionGas;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setPreheatTemperature: (preheatTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enablePreheatTemperatureText) {
                rangeOfQualificationInfo.preheatTemperatureText = preheatTemperature;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setInterpassTemperature: (interpassTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableInterpassTemperatureText) {
                rangeOfQualificationInfo.interpassTemperatureText = interpassTemperature;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setPostWeldHeatTreatment: (postWeldHeatTreatment: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enablePostWeldHeatTreatmentText) {
                rangeOfQualificationInfo.postWeldHeatTreatmentText = postWeldHeatTreatment;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setMaterialGroup: (materialGroup: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableMaterialGroupText) {
                rangeOfQualificationInfo.materialGroupText = materialGroup;
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setLocation: (location: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.location = location;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setManufacturer: (manufacturer: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.manufacturer = manufacturer;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setSelectedOtherInformation: (otherInformationId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let selectedOtherInformation: GetOtherInformationRangeOfQualificationResponse = new GetOtherInformationRangeOfQualificationResponse();
            let otherInformationRangeOfQualifications: Array<GetOtherInformationRangeOfQualificationResponse> = { ...appState.rangeOfQualification.otherInformations };
            for (let i = 0; i < otherInformationRangeOfQualifications.length; i++) {
                if (otherInformationId === otherInformationRangeOfQualifications[i].id) {
                    selectedOtherInformation = { ...otherInformationRangeOfQualifications[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_SELECTED_OTHER_INFORMATION', selectedOtherInformation: selectedOtherInformation });
        }
    },
    setTestingStandards: (standardId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let standards: Array<GetStandardResponse> = [...appState.rangeOfQualification.standards];
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (standardId === 0) {
                rangeOfQualificationInfo.testingStandardId = standardId;
            } else {
                for (let i = 0; i < standards.length; i++) {
                    if (standardId === standards[i].id) {
                        rangeOfQualificationInfo.testingStandardId = standards[i].id;
                        break;
                    }
                }
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setStandardText: (standardText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.standardText = standardText
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableWeldingMetalThicknessText: (enableWeldingMetalThicknessText: boolean, weldingMetalThickness: string ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (enableWeldingMetalThicknessText) {
                rangeOfQualificationInfo.weldingMetalThicknessText = weldingMetalThickness
            }
            rangeOfQualificationInfo.enableWeldingMetalThicknessText = enableWeldingMetalThicknessText
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableMaterialThicknessesText: (enableMaterialThicknessesText: boolean, materialThicknesses: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.materialThicknesses = HelpFunctions.arrayJoinToString(materialThicknesses);
            rangeOfQualificationInfo.enableMaterialThicknessesText = enableMaterialThicknessesText;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableMaterialGroupText: (enableMaterialGroupText: boolean, materialGroup: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.materialGroupText = materialGroup;
            rangeOfQualificationInfo.enableMaterialGroupText = enableMaterialGroupText;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableWeldingProcessText: (enable: boolean, textArrray: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.weldingProcess= HelpFunctions.arrayJoinToString(textArrray);;
            rangeOfQualificationInfo.enableWeldingProcessText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableJointTypeText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.jointType = text;
                rangeOfQualificationInfo.enableJointTypeText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableThroatThicknessText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.throatThicknessText = text;
            rangeOfQualificationInfo.enableThroatThicknessText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableStandardText: (enable: boolean, id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            let standards: Array<GetStandardResponse> = [...appState.rangeOfQualification.standards];
            for (let i = 0; i < standards.length; i++) {
                if (id === standards[i].id) {
                    rangeOfQualificationInfo.standardText = standards[i].name;
                    break;
                }
            }
            rangeOfQualificationInfo.enableStandardText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    
    setEnableRunTypeText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.runTypeText = text;
            rangeOfQualificationInfo.enableRunTypeText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableDiameterText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.diameterText = text;
                rangeOfQualificationInfo.enableDiameterText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableMakesText: (enable: boolean, text: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.makes = HelpFunctions.arrayJoinToString(text);
            rangeOfQualificationInfo.enableMakesText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableShieldingGasesText: (enable: boolean, text: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.shieldingGases = HelpFunctions.arrayJoinToString(text);
            rangeOfQualificationInfo.enableShieldingGasesText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableRootProtectionGasText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.rootProtectionGasText = text;
                rangeOfQualificationInfo.enableRootProtectionGasText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableCurrentTypesText: (enable: boolean, text: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.currentTypes = HelpFunctions.arrayJoinToString(text);
            rangeOfQualificationInfo.enableCurrentTypesText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableDropletTransitionsText: (enable: boolean, text: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.dropletTransitions = HelpFunctions.arrayJoinToString(text);
            rangeOfQualificationInfo.enableDropletTransitionsText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableHeatInputText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.heatInputText = text;
            rangeOfQualificationInfo.enableHeatInputText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableArcEnergyText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.arcEnergyText = text;
            rangeOfQualificationInfo.enableArcEnergyText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnablePreheatTemperatureText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.preheatTemperatureText = text;
            rangeOfQualificationInfo.enablePreheatTemperatureText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableInterpassTemperatureText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.interpassTemperatureText = text;
            rangeOfQualificationInfo.enableInterpassTemperatureText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnablePostWeldHeatTreatmentText: (enable: boolean, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
                rangeOfQualificationInfo.postWeldHeatTreatmentText = text;
            rangeOfQualificationInfo.enablePostWeldHeatTreatmentText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setEnableDesignationsText: (enable: boolean, text: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.designations = HelpFunctions.arrayJoinToString(text);
            rangeOfQualificationInfo.enableDesignationsText = enable;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setWeldingMetalThicknessText: (weldingMetalThicknessText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.weldingMetalThicknessText = weldingMetalThicknessText
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setMaterialThicknessesText: (materialThicknesses: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.materialThicknesses = materialThicknesses;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setMaterialGroupText: (materialGroupText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.materialGroupText = materialGroupText;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setWeldingProcessText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.weldingProcess = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setJointTypeText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.jointType = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setThroatThicknessText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.throatThicknessText = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setRunTypesText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.runTypeText = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setDiameterText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.diameterText = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setDesignationsText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.designations = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setMakesText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.makes = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setShieldingGasesText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.shieldingGases = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setRootProtectionGasText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.rootProtectionGasText = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setDropletTransitionsText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.dropletTransitions = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setHeatInputText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.heatInputText = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setArcEnergyText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.arcEnergyText = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setInterpassTemperatureText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.interpassTemperatureText = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setPreheatTemperatureText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.preheatTemperatureText = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setPostWeldHeatTreatmentText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.postWeldHeatTreatmentText = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setCurrentTypesText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.currentTypes = text;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setOnChangeWeldingPositionText: (weldingPositionText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.weldingPositionsText = weldingPositionText
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setOnChangeOtherInformationsText: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.otherInformations = text
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    clearWeldingPositionText: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.weldingPositionsText = ""
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    clearOtherInformationsText: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.otherInformations = ""
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setWeldingPositionText: (weldingPositionText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.weldingPositionsText = weldingPositionText;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setOtherInformations: (otherInformations: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.otherInformations = rangeOfQualificationInfo.otherInformations === "" ? otherInformations : rangeOfQualificationInfo.otherInformations + "\n\n" + otherInformations;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setWeldingProcesses: (weldingMethods: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableWeldingProcessText) {
                rangeOfQualificationInfo.weldingProcess = HelpFunctions.arrayJoinToString(weldingMethods);
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setMaterialThicknessesTemp: (materialThicknesses: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableMaterialThicknessesText) {
                rangeOfQualificationInfo.materialThicknesses = HelpFunctions.arrayJoinToString(materialThicknesses);
            }
            
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setInitForRangeOfQualification: (weldinDate: string, companyAddress: string, companyPostcode: string, companyCity: string, companyName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.dateOfWelding = weldinDate;
            if (companyPostcode!) {
                companyPostcode = companyPostcode + " ";
            }
            rangeOfQualificationInfo.address = companyAddress + ", " + companyPostcode  + companyCity;
            rangeOfQualificationInfo.manufacturer = companyName;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setStandardId: (standard: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.testingStandardId = standard;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setRangeOfQualificationInfo: (updateRangeOfQualificationInfo: RangeOfQualificationInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo = updateRangeOfQualificationInfo;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setDesignations: (designations: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableDesignationsText) {
                rangeOfQualificationInfo.designations = HelpFunctions.arrayJoinToString(designations);
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setShieldingGases: (shieldingGases: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableShieldingGasesText) {
                rangeOfQualificationInfo.shieldingGases = HelpFunctions.arrayJoinToString(shieldingGases);
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setCurrentTypes: (currentTypes: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableCurrentTypesText) {
                rangeOfQualificationInfo.currentTypes = HelpFunctions.arrayJoinToString(currentTypes);
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setDropletTransitions: (dropletTransitions: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableDropletTransitionsText) {
                rangeOfQualificationInfo.dropletTransitions = HelpFunctions.arrayJoinToString(dropletTransitions);
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setMakes: (makes: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            if (!rangeOfQualificationInfo.enableMakesText) {
                rangeOfQualificationInfo.makes = HelpFunctions.arrayJoinToString(makes);
            }
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    setRangeOfQualificationExaminationResponse: (RangeOfQualificationExaminationResponse: GetRangeOfQualificationExaminationResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.address = RangeOfQualificationExaminationResponse.address ? rangeOfQualificationInfo.address : RangeOfQualificationExaminationResponse.address;
            rangeOfQualificationInfo.testingStandardId = RangeOfQualificationExaminationResponse.testingStandardId === 0 ? rangeOfQualificationInfo.testingStandardId : RangeOfQualificationExaminationResponse.testingStandardId;
            rangeOfQualificationInfo.enableStandardText = RangeOfQualificationExaminationResponse.enableStandardText;
            rangeOfQualificationInfo.standardText = RangeOfQualificationExaminationResponse.standardText;
            rangeOfQualificationInfo.dateOfWelding = RangeOfQualificationExaminationResponse.dateOfWelding === "" ? rangeOfQualificationInfo.dateOfWelding : RangeOfQualificationExaminationResponse.dateOfWelding;
            rangeOfQualificationInfo.location = RangeOfQualificationExaminationResponse.location === "" ? rangeOfQualificationInfo.location : RangeOfQualificationExaminationResponse.location;
            rangeOfQualificationInfo.dateOfIssue = RangeOfQualificationExaminationResponse.dateOfIssue === "" ? rangeOfQualificationInfo.dateOfIssue : RangeOfQualificationExaminationResponse.dateOfIssue;
            rangeOfQualificationInfo.weldingMetalThicknessText = RangeOfQualificationExaminationResponse.weldingMetalThicknessText === "" ? rangeOfQualificationInfo.weldingMetalThicknessText : RangeOfQualificationExaminationResponse.weldingMetalThicknessText;
            rangeOfQualificationInfo.enableWeldingMetalThicknessText = RangeOfQualificationExaminationResponse.enableWeldingMetalThicknessText;
            rangeOfQualificationInfo.materialGroupText = RangeOfQualificationExaminationResponse.materialGroupText === "" ? rangeOfQualificationInfo.materialGroupText : RangeOfQualificationExaminationResponse.materialGroupText;
            rangeOfQualificationInfo.enableMaterialGroupText = RangeOfQualificationExaminationResponse.enableMaterialGroupText;
            rangeOfQualificationInfo.enableMaterialThicknessesText = RangeOfQualificationExaminationResponse.enableMaterialThicknessesText;
            rangeOfQualificationInfo.weldingPositionsText = RangeOfQualificationExaminationResponse.weldingPositionsText === "" ? rangeOfQualificationInfo.weldingPositionsText : RangeOfQualificationExaminationResponse.weldingPositionsText;
            rangeOfQualificationInfo.enableWeldingProcessText = RangeOfQualificationExaminationResponse.enableWeldingProcessText;
            rangeOfQualificationInfo.enableJointTypeText = RangeOfQualificationExaminationResponse.enableJointTypeText;
            rangeOfQualificationInfo.enableThroatThicknessText = RangeOfQualificationExaminationResponse.enableThroatThicknessText;
            rangeOfQualificationInfo.enableRunTypeText = RangeOfQualificationExaminationResponse.enableRunTypeText;
            rangeOfQualificationInfo.enableDiameterText = RangeOfQualificationExaminationResponse.enableDiameterText;
            rangeOfQualificationInfo.enableMakesText = RangeOfQualificationExaminationResponse.enableMakesText;
            rangeOfQualificationInfo.enableShieldingGasesText = RangeOfQualificationExaminationResponse.enableShieldingGasesText;
            rangeOfQualificationInfo.enableRootProtectionGasText = RangeOfQualificationExaminationResponse.enableRootProtectionGasText;
            rangeOfQualificationInfo.enableCurrentTypesText = RangeOfQualificationExaminationResponse.enableCurrentTypesText;
            rangeOfQualificationInfo.enableDropletTransitionsText = RangeOfQualificationExaminationResponse.enableDropletTransitionsText;
            rangeOfQualificationInfo.enableHeatInputText = RangeOfQualificationExaminationResponse.enableHeatInputText;
            rangeOfQualificationInfo.enableArcEnergyText = RangeOfQualificationExaminationResponse.enableArcEnergyText;
            rangeOfQualificationInfo.enableDesignationsText = RangeOfQualificationExaminationResponse.enableDesignationsText;
            rangeOfQualificationInfo.enablePreheatTemperatureText = RangeOfQualificationExaminationResponse.enablePreheatTemperatureText;
            rangeOfQualificationInfo.enableInterpassTemperatureText = RangeOfQualificationExaminationResponse.enableInterpassTemperatureText;
            rangeOfQualificationInfo.enablePostWeldHeatTreatmentText = RangeOfQualificationExaminationResponse.enablePostWeldHeatTreatmentText;
            rangeOfQualificationInfo.throatThicknessText = RangeOfQualificationExaminationResponse.throatThicknessText === "" ? rangeOfQualificationInfo.throatThicknessText : RangeOfQualificationExaminationResponse.throatThicknessText;
            rangeOfQualificationInfo.runTypeText = RangeOfQualificationExaminationResponse.runTypeText === "" ? rangeOfQualificationInfo.runTypeText : RangeOfQualificationExaminationResponse.runTypeText;
            rangeOfQualificationInfo.diameterText = RangeOfQualificationExaminationResponse.diameterText === "" ? rangeOfQualificationInfo.diameterText : RangeOfQualificationExaminationResponse.diameterText;
            rangeOfQualificationInfo.designations = RangeOfQualificationExaminationResponse.designations === "" ? rangeOfQualificationInfo.designations : RangeOfQualificationExaminationResponse.designations;
            rangeOfQualificationInfo.makes = RangeOfQualificationExaminationResponse.makes === "" ? rangeOfQualificationInfo.makes : RangeOfQualificationExaminationResponse.makes;
            rangeOfQualificationInfo.shieldingGases = RangeOfQualificationExaminationResponse.shieldingGases === "" ? rangeOfQualificationInfo.shieldingGases : RangeOfQualificationExaminationResponse.shieldingGases;
            rangeOfQualificationInfo.rootProtectionGasText = RangeOfQualificationExaminationResponse.rootProtectionGasText === "" ? rangeOfQualificationInfo.rootProtectionGasText : RangeOfQualificationExaminationResponse.rootProtectionGasText;
            rangeOfQualificationInfo.currentTypes = RangeOfQualificationExaminationResponse.currentTypes === "" ? rangeOfQualificationInfo.currentTypes : RangeOfQualificationExaminationResponse.currentTypes;
            rangeOfQualificationInfo.dropletTransitions = RangeOfQualificationExaminationResponse.dropletTransitions === "" ? rangeOfQualificationInfo.dropletTransitions : RangeOfQualificationExaminationResponse.dropletTransitions;
            rangeOfQualificationInfo.heatInputText = RangeOfQualificationExaminationResponse.heatInputText === "" ? rangeOfQualificationInfo.heatInputText : RangeOfQualificationExaminationResponse.heatInputText;
            rangeOfQualificationInfo.arcEnergyText = RangeOfQualificationExaminationResponse.arcEnergyText === "" ? rangeOfQualificationInfo.arcEnergyText : RangeOfQualificationExaminationResponse.arcEnergyText;
            rangeOfQualificationInfo.postWeldHeatTreatmentText = RangeOfQualificationExaminationResponse.postWeldHeatTreatmentText === "" ? rangeOfQualificationInfo.postWeldHeatTreatmentText : RangeOfQualificationExaminationResponse.postWeldHeatTreatmentText;
            rangeOfQualificationInfo.interpassTemperatureText = RangeOfQualificationExaminationResponse.interpassTemperatureText === "" ? rangeOfQualificationInfo.interpassTemperatureText : RangeOfQualificationExaminationResponse.interpassTemperatureText;
            rangeOfQualificationInfo.preheatTemperatureText = RangeOfQualificationExaminationResponse.preheatTemperatureText === "" ? rangeOfQualificationInfo.preheatTemperatureText : RangeOfQualificationExaminationResponse.preheatTemperatureText;
            rangeOfQualificationInfo.otherInformations = RangeOfQualificationExaminationResponse.otherInformations === "" ? rangeOfQualificationInfo.otherInformations : RangeOfQualificationExaminationResponse.otherInformations;
            rangeOfQualificationInfo.jointType = RangeOfQualificationExaminationResponse.jointType === "" ? rangeOfQualificationInfo.jointType : RangeOfQualificationExaminationResponse.jointType;
            rangeOfQualificationInfo.weldingProcess = RangeOfQualificationExaminationResponse.weldingProcess === "" ? rangeOfQualificationInfo.weldingProcess : RangeOfQualificationExaminationResponse.weldingProcess;
            rangeOfQualificationInfo.materialThicknesses = RangeOfQualificationExaminationResponse.materialThicknesses === "" ? rangeOfQualificationInfo.materialThicknesses : RangeOfQualificationExaminationResponse.materialThicknesses;
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    },
    getStandardsAll: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Standards/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<Array<GetStandardResponse>>)
            .then(data => {
                console.info(data);
                dispatch({ type: 'GET_RANGE_OF_QUALIFICATION_STANDARDS_RECEIVE', standards: data });
            })
            .catch(error => {
                console.error(error);
            });
    },
    clearPartOfRangeOfQualificationExamination: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.rangeOfQualification) {
            let rangeOfQualificationInfo: RangeOfQualificationInfo = { ...appState.rangeOfQualification.rangeOfQualificationInfo };
            rangeOfQualificationInfo.address = '';
            rangeOfQualificationInfo.testingStandardId = 0;
            rangeOfQualificationInfo.enableStandardText = false;
            rangeOfQualificationInfo.standardText = '';
            rangeOfQualificationInfo.dateOfWelding = '';
            rangeOfQualificationInfo.location = '';
            rangeOfQualificationInfo.dateOfIssue = '';
            rangeOfQualificationInfo.weldingMetalThicknessText = '';
            rangeOfQualificationInfo.enableWeldingMetalThicknessText = false;
            rangeOfQualificationInfo.materialGroupText = '';
            rangeOfQualificationInfo.enableMaterialGroupText = false;
            rangeOfQualificationInfo.enableMaterialThicknessesText = false;
            rangeOfQualificationInfo.weldingPositionsText= '';
            rangeOfQualificationInfo.enableWeldingProcessText= false;
            rangeOfQualificationInfo.enableJointTypeText= false;
            rangeOfQualificationInfo.enableThroatThicknessText= false;
            rangeOfQualificationInfo.enableRunTypeText= false;
            rangeOfQualificationInfo.enableDiameterText= false;
            rangeOfQualificationInfo.enableMakesText= false;
            rangeOfQualificationInfo.enableShieldingGasesText= false;
            rangeOfQualificationInfo.enableRootProtectionGasText= false;
            rangeOfQualificationInfo.enableCurrentTypesText= false;
            rangeOfQualificationInfo.enableDropletTransitionsText= false;
            rangeOfQualificationInfo.enableHeatInputText= false;
            rangeOfQualificationInfo.enableArcEnergyText= false;
            rangeOfQualificationInfo.enableDesignationsText= false;
            rangeOfQualificationInfo.enablePreheatTemperatureText= false;
            rangeOfQualificationInfo.enableInterpassTemperatureText= false;
            rangeOfQualificationInfo.enablePostWeldHeatTreatmentText= false;
            rangeOfQualificationInfo.throatThicknessText= '';
            rangeOfQualificationInfo.runTypeText= '';
            rangeOfQualificationInfo.diameterText= '';
            rangeOfQualificationInfo.rootProtectionGasText= '';
            rangeOfQualificationInfo.heatInputText= '';
            rangeOfQualificationInfo.arcEnergyText= '';
            rangeOfQualificationInfo.postWeldHeatTreatmentText= '';
            rangeOfQualificationInfo.interpassTemperatureText= '';
            rangeOfQualificationInfo.preheatTemperatureText= '';
            dispatch({ type: 'SET_RANGE_OF_QUALIFICATION_INFO', rangeOfQualificationInfo: rangeOfQualificationInfo });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: RangeOfQualificationState = {
    rangeOfQualificationInfo: {
        manufacturer: '',
        address: '',
        testingStandardId: 0,
        enableStandardText: false,
        standardText: '',
        dateOfWelding: '',
        location: '',
        dateOfIssue: '',
        weldingProcess: '',
        jointType: '',
        materialThicknesses: '',
        designations: '',
        shieldingGases: '',
        currentTypes: '',
        dropletTransitions: '', 
        makes: '',
        otherInformations: '',
        enableWeldingMetalThicknessText: false,
        weldingMetalThicknessText: '',
        enableMaterialGroupText: false,
        enableMaterialThicknessesText: false,
        materialGroupText: '',
        weldingPositionsText: '',
        enableWeldingProcessText: false,
        enableJointTypeText: false,
        enableThroatThicknessText: false,
        enableRunTypeText: false,
        enableDiameterText: false,
        enableMakesText: false,
        enableShieldingGasesText: false,
        enableRootProtectionGasText: false,
        enableCurrentTypesText: false,
        enableDropletTransitionsText: false,
        enableHeatInputText: false,
        enableArcEnergyText: false,
        enableDesignationsText: false,
        enablePreheatTemperatureText: false,
        enableInterpassTemperatureText: false,
        enablePostWeldHeatTreatmentText: false,
        throatThicknessText: '',
        runTypeText: '',
        diameterText: '',
        rootProtectionGasText: '',
        heatInputText: '',
        arcEnergyText: '',
        postWeldHeatTreatmentText: '',
        interpassTemperatureText: '',
        preheatTemperatureText: '',
    },
    otherInformations: [],
    selectedOtherInformation: {
        id: 0,
        rangeOfQualification: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    weldingPositionResult: [],
    standards: [],
};

export const reducer: Reducer<RangeOfQualificationState> = (state: RangeOfQualificationState | undefined, incomingAction: Action): RangeOfQualificationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_OTHER_INFORMATION_RECEIVE':
            return {
                rangeOfQualificationInfo: state.rangeOfQualificationInfo,
                otherInformations: action.otherInformations,
                selectedOtherInformation: state.selectedOtherInformation,
                standards: state.standards,
                weldingPositionResult: state.weldingPositionResult
            }
        case 'SET_RANGE_OF_QUALIFICATION_INFO':
            return {
                rangeOfQualificationInfo: action.rangeOfQualificationInfo,
                otherInformations: state.otherInformations,
                selectedOtherInformation: state.selectedOtherInformation,
                standards: state.standards,
                weldingPositionResult: state.weldingPositionResult
            }
        case 'SET_SELECTED_OTHER_INFORMATION':
            return {
                rangeOfQualificationInfo: state.rangeOfQualificationInfo,
                otherInformations: state.otherInformations,
                selectedOtherInformation: action.selectedOtherInformation,
                standards: state.standards,
                weldingPositionResult: state.weldingPositionResult
            }
        case 'SET_RANGE_OF_QUALIFICATION_WELDING_POSITION_RECEIVE':
            return {
                rangeOfQualificationInfo: state.rangeOfQualificationInfo,
                otherInformations: state.otherInformations,
                selectedOtherInformation: state.selectedOtherInformation,
                standards: state.standards,
                weldingPositionResult: action.weldingPositionResult
            }
        case 'GET_RANGE_OF_QUALIFICATION_STANDARDS_RECEIVE':
            return {
                rangeOfQualificationInfo: state.rangeOfQualificationInfo,
                otherInformations: state.otherInformations,
                selectedOtherInformation: state.selectedOtherInformation,
                standards: action.standards,
                weldingPositionResult: state.weldingPositionResult
            }
        default:
            return state;
    }
};
