﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Table } from 'reactstrap';
import { ApplicationState } from '../../store/index';
import * as WPSTabStore from '../../store/WPSTabStore';
import { t } from '../../i18n';
import HelpRangeOfQualificationExamination from '../../store/models/HelpRangeOfQualificationExamination';
/*import { UpdatePwpsListModalRequest } from '../../store/models/requests/UpdatePwpsListModalRequest';*/

// At runtime, Redux will merge together...
type PwpsListModalProps =
    WPSTabStore.WPSTabStoreState // ... state we've requested from the Redux store
    & typeof WPSTabStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    showPwpsList?: boolean;
    initSelectedPwps?: (id: number) => void;
}
class PwpsListModal extends React.PureComponent<PwpsListModalProps> {
    constructor(props: PwpsListModalProps) {
        super(props);
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        // Get Pwpslist
        const customerId = JSON.parse(localStorage.getItem('customerId')!);
        this.props.getWPSs(customerId);
    }

    componentDidUpdate(prevProps: any) {
    }

    public state = {
        showPwpsListModal: false,
        selectedPwpsId: 0,
    };

    private toggleShowPwpsListModalModal = () => {
        this.setState({
            showPwpsListModal: !this.state.showPwpsListModal
        });
    }

    private onClickShowPwpsModal(id: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.toggleShowPwpsListModalModal();
    }
    
    private onClickModalCancel = (event: React.MouseEvent) => {
        this.toggleShowPwpsListModalModal();
    }

    private onClickConfirmModal = (event: React.MouseEvent) => {
        this.toggleShowPwpsListModalModal();
        this.props.initSelectedPwps(this.state.selectedPwpsId);
    }

    private onChangeSelectedPwps(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.setState({
            selectedPwpsId: parseInt(event.currentTarget.value)
        });
    }
    public render() {
        return (
            <React.Fragment>
                {this.props.showPwpsList ?
                    <div>
                        { this.rendePwpsListModalModal() }
                        < FormGroup className="float-right">
                            <Button type="button" color="primary" id="history" name="history" className="btn-sm" style={{ marginLeft: "3px" }}
                                onClick={(event) => this.onClickShowPwpsModal(JSON.parse(localStorage.getItem('customerId')!), event)}>
                                {t('pWPS_WPS')}{" "}<i className="fas fa-file-import" ></i>
                            </Button>
                        </FormGroup>
                    </div>: null
                }
            </React.Fragment>
        );
    }

    private rendePwpsListModalModal() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.showPwpsListModal} size="lg">
                    <ModalHeader>
                        {t('ImportFrom')}{" "}{t('pWPS_WPS')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                {this.renderPwpsListModalTable()}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickConfirmModal(event)} disabled={this.state.selectedPwpsId === 0 ? true : false}>{t('OK')}</Button>
                        {' '}
                        <Button color="secondary" onClick={(event) => this.onClickModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

    private renderPwpsListModalTable() {
        return (
            <React.Fragment key="PwpsListModal">
                <Table size="lg" responsive className="cert-table">
                    <thead >
                        {this.renderPwpsListModalHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.wpssSearchResult.map((row, index) => (
                            this.renderPwpsListModalTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderPwpsListModalHeaderRow() {
        return (
            <React.Fragment key="Wparameters141">
                <tr>
                    <th><a></a></th>
                    <th><a>{t('pWPS_WPS')}</a></th>
                    <th><a>{t('ManufacturersWPSNo')}</a></th>
                    <th><a>{t('ManufacturersWPQRNo')}</a></th>
                    <th><a>{t('DekraProcedureNo')}</a></th>
                    <th><a>{t('QualificationStandard')}</a></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderPwpsListModalTableRow(rowNumber: number) {
        return (
            <React.Fragment key={"PwpsListModalTableRow" + rowNumber}>
                <tr>
                    <td>
                        <FormGroup check>
                            <Label check size="sm">
                                <Input type="radio" bssize="sm" id={"selectedFile" + rowNumber} name={"selectedFile" + rowNumber}
                                    value={this.props.wpssSearchResult[rowNumber - 1].id}
                                    checked={this.state.selectedPwpsId === this.props.wpssSearchResult[rowNumber - 1].id}
                                    onChange={(event) => this.onChangeSelectedPwps(event)}
                                    placeholder="" />{' '}  {""}
                                <span>&nbsp;</span>
                            </Label>
                        </FormGroup>
                    </td>
                    <td>
                        <Label className="label-sm-form-size" for={"standard" + rowNumber} size="sm">{HelpRangeOfQualificationExamination.SetWpsType(this.props.wpssSearchResult[rowNumber - 1].wpsType)}</Label>
                    </td>
                    <td>
                        <Label className="label-sm-form-size" for={"wpqrNumber" + rowNumber} size="sm">{this.props.wpssSearchResult[rowNumber - 1].pwpsNumber}</Label>
                    </td>
                    <td>
                        <Label className="label-sm-form-size" for={"createdAt" + rowNumber} size="sm">{this.props.wpssSearchResult[rowNumber - 1].wpqrNumber}</Label>
                    </td>
                    <td>
                        <Label className="label-sm-form-size" for={"createdAt" + rowNumber} size="sm">{this.props.wpssSearchResult[rowNumber - 1].dekraNumber}</Label>
                    </td>
                    <td>
                        <Label className="label-sm-form-size" for={"createdAt" + rowNumber} size="sm">{this.props.wpssSearchResult[rowNumber - 1].standardName}</Label>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.wpsTab
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    WPSTabStore.actionCreators // Selects which action creators are merged into the component's props
)(PwpsListModal as any);
