﻿

import { WPQR } from './WPQR';
import { GetWeldingMethodResponse } from './models/responses/GetWeldingMethodResponse';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { WPQRSearchProperties } from './models/WPQRSearchProperties';
import { WPQRCompleteSettingColumns } from './models/WPQRCompleteSettingColumns';
import { GetProductTypeResponse } from './models/responses/GetProductTypeResponse';
import { GetJointTypeResponse } from './models/responses/GetJointTypeResponse';
import { GetMaterialGroupResponse } from './models/responses/GetMaterialGroupResponse';
import { GetRunTypeRangeOfQualificationResponse } from './models/responses/GetRunTypeRangeOfQualificationResponse';
import { GetStandardResponse } from './models/responses/GetStandardResponse';
import { WPQRRevision } from './models/WPQRRevision';
import authHeader from './models/auth-header';
import EventBus from './eventBus';
//import { WPQRRevision } from './models/WPQRRevision';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WPQRCompleteStoreState {
    wpqrs: Array<WPQR>;
    wpqrsSearchResult: Array<WPQR>;
    searchProperties: WPQRSearchProperties;
    showTabSettingModal: boolean;
    wpqrCompleteSettingColumns: WPQRCompleteSettingColumns;
    wpqrPaginationPageIndex: number;
    wpqrPaginationPageSize: number;
    wpqrWeldingMethodsSearch: Array<GetWeldingMethodResponse>;
    wpqrRevision: WPQRRevision;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetWPQRsAction {
    type: 'GET_WPQRS';
    wpqrs: Array<WPQR>;
}
interface GetWPQRsRequestAction {
    type: 'GET_WPQRS_REQUEST';
}
interface SetWPQRsSearchResultAction {
    type: 'SET_WPQRS_SEARCH_RESULT';
    wpqrsSearchResult: Array<WPQR>;
}

interface SetSearchProperties {
    type: 'SET_SEARCH_PROPERTIES';
    searchProperties: WPQRSearchProperties;
}
interface GetWeldingMethondsSearchAction {
    type: 'SET_WELDING_METHODS_SEARCH';
    wpqrWeldingMethodsSearch: Array<GetWeldingMethodResponse>;
}

export interface SetShowTabSettingModalAction {
    type: 'SET_SHOW_TAB_SETTING_MODAL';
    showTabSettingModal: boolean;
}

export interface SetTabSettingColumnsAction {
    type: 'SET_TAB_SETTING_COLUMNS';
    wpqrCompleteSettingColumns: WPQRCompleteSettingColumns;
}

export interface SetPaginationPageIndexAction {
    type: 'SET_PAGINATION_PAGE_INDEX';
    wpqrPaginationPageIndex: number;
}

export interface GetWeldingMethondsSearchRequestAction {
    type: 'GET_WELDING_METHODS_SEARCH_REQUEST';
}
interface GetProductTypesRequestAction {
    type: 'GET_PRODUCT_TYPES_REQUEST';
}
interface GetJointTypesRequestAction {
    type: 'GET_JOINT_TYPES_REQUEST';
}

interface GetMaterialGroupRequestAction {
    type: 'GET_MATERIAL_GROUPS_REQUEST';
}

interface GetRunTypeRangeQualificationAction {
    type: 'GET_RUN_TYPE_RANGE_QUALIFICATION_REQUEST';
}

interface GetStandardsAction {
    type: 'GET_STANDARDS_REQUEST';
}

interface SetWpsRevisionAction {
    type: 'WPQR_SET_REVISION_RESULT';
    wpqrRevision: WPQRRevision;
}

interface GetWpsRevisionAction {
    type: 'WPQR_GET_REVISION_REQUEST';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetWPQRsAction
    | GetWPQRsRequestAction
    | SetSearchProperties
    | SetWPQRsSearchResultAction
    | SetShowTabSettingModalAction
    | SetTabSettingColumnsAction
    | SetPaginationPageIndexAction
    | GetWeldingMethondsSearchAction
    | GetWeldingMethondsSearchRequestAction
    | GetProductTypesRequestAction
    | GetJointTypesRequestAction
    | GetMaterialGroupRequestAction
    | GetRunTypeRangeQualificationAction
    | GetStandardsAction
    | SetWpsRevisionAction
    | GetWpsRevisionAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getWPQRs: (customerId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingProtocols/GetAllWpqrInclude/${customerId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<WPQR>;

                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrComplete) {
                        let wpqrs: Array<WPQR> = { ...appState.wpqrComplete.wpqrs };
                        wpqrs = [...data];
                        dispatch({ type: 'GET_WPQRS', wpqrs: wpqrs });

                        let wpqrsSearchResult: Array<WPQR> = { ...appState.wpqrComplete.wpqrsSearchResult };
                        wpqrsSearchResult = [...data];
                        dispatch({ type: 'SET_WPQRS_SEARCH_RESULT', wpqrsSearchResult: wpqrsSearchResult });
                    }
                }))
                .catch(error => {
                    console.error(error);
                });
            dispatch({ type: 'GET_WPQRS_REQUEST' });
    },
    getWeldingMethodsSearch: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingMethods/GetByDistinctWeldingMethodDescription`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingMethodResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrComplete) {
                        let wpqrWeldingMethodsSearch: Array<GetWeldingMethodResponse> = { ...appState.wpqrComplete.wpqrWeldingMethodsSearch };
                        wpqrWeldingMethodsSearch = [...data];
                        dispatch({ type: 'SET_WELDING_METHODS_SEARCH', wpqrWeldingMethodsSearch: wpqrWeldingMethodsSearch });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_WELDING_METHODS_SEARCH_REQUEST' });
    },
    getWpqrRevisions: (wpqrId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Wpqrs/GetByWpqrIdRevisions/${wpqrId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<WPQR>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrComplete) {
                        let wpqrRevision: WPQRRevision = { ...appState.wpqrComplete.wpqrRevision };
                        wpqrRevision.revisions = [...data];
                        dispatch({ type: 'WPQR_SET_REVISION_RESULT', wpqrRevision: wpqrRevision });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPQR_GET_REVISION_REQUEST' });
    },
    getProductTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ProductTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetProductTypeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrComplete) {
                        let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
                        searchProperties.productTypes = [...data];;
                        dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_PRODUCT_TYPES_REQUEST' });
    },
    getJointTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/JointTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetJointTypeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrComplete) {
                        let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
                        searchProperties.jointTypes = [...data];;
                        dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_JOINT_TYPES_REQUEST' });
    },
    getMaterialGroupAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/MaterialGroups/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetMaterialGroupResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrComplete) {
                        let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
                        searchProperties.materialGroups = [...data];;
                        dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MATERIAL_GROUPS_REQUEST' });
    },
    getRuntypeRangeOfQualification: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RunTypeRangeOfQualifications/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetRunTypeRangeOfQualificationResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrComplete) {
                        let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
                        let newRunTypeRangeOfQualifications = []
                        for (let i = 0; i < [...data].length; i++) {
                            //exclude all hardnessTestingOrImpactTesting with false
                            if ([...data][i].hardnessTestingOrImpactTesting) {
                                newRunTypeRangeOfQualifications.push([...data][i])
                            }
                        }
                        searchProperties.runTypeRangeOfQualifications = [...newRunTypeRangeOfQualifications];
                        dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_RUN_TYPE_RANGE_QUALIFICATION_REQUEST' });
    },
    getStandardsAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Standards/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetStandardResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrComplete) {
                        let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
                        searchProperties.standards = [...data];;
                        dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_STANDARDS_REQUEST' });
    },
    getWPQRsSearchResult: (wpqrsList: Array<WPQR>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let newWpqrsList = []
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            let wpqrs: Array<WPQR> = { ...appState.wpqrComplete.wpqrs };
            for (let i = 0; i < wpqrsList.length; i++) {

                if ((searchProperties.freeSearch === "" ? true : ((wpqrsList[i].dekraNumber.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpqrsList[i].wpqrNumber.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpqrsList[i].pwpsNumber.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpqrsList[i].make1Name.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpqrsList[i].make2DesignationName.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)))
                    && (searchProperties.selectedWeldingMethod.name === "" ? true : (wpqrsList[i].weldingMethod1Name.search(searchProperties.selectedWeldingMethod.name.split(",", 1)[0]) > -1) || (wpqrsList[i].weldingMethod2Name.search(searchProperties.selectedWeldingMethod.name.split(",", 1)[0]) > -1))
                    && (searchProperties.selectedProductType.name === "" ? true : (wpqrsList[i].productType1Name.search(searchProperties.selectedProductType.name.split(",", 1)[0]) > -1) || (wpqrsList[i].productType2Name.search(searchProperties.selectedProductType.name.split(",", 1)[0]) > -1))
                    && (searchProperties.materialThickness === "" ? true : compareValueBetweenMinAndMax(wpqrsList[i].materialThickness1RangeOfQualification, searchProperties.materialThickness))
                    && (searchProperties.outerDiameter === "" ? true : compareOuterDiameter(wpqrsList[i].diameter1Text, searchProperties.outerDiameter))
                    && (searchProperties.selectedJointType.name === "" ? true : compareJointType(wpqrsList[i].jointTypeName, searchProperties.selectedJointType.name))
                    && (searchProperties.selectedMaterialGroup.name === "" ? true : compareMaterialGroup(wpqrsList[i].materialGroupRangeOfQualification, searchProperties.selectedMaterialGroup.name))
                    && (searchProperties.selectedRunTypeRangeOfQualification.rangeOfQualification === "" ? true : compareRunTypeRangeOfQualification(wpqrsList[i].runTypeRangeOfQualification, searchProperties.selectedRunTypeRangeOfQualification.rangeOfQualification))
                    && (searchProperties.selectedStandard.name === "" ? true : compareStandard(wpqrsList[i].standardName, searchProperties.selectedStandard.name))) { 
                    newWpqrsList.push(wpqrsList[i]);
                }
            }
                wpqrs = newWpqrsList;
            
            dispatch({ type: 'SET_WPQRS_SEARCH_RESULT', wpqrsSearchResult: wpqrs });
        }

        function compareStandard(standardText: string, selectedStandard: string) {
            let returnValue: Boolean = false;

            if (standardText.indexOf(selectedStandard) > -1) {
                returnValue = true;
            }
            return returnValue;
        }

        function compareRunTypeRangeOfQualification(runTypeRangeOfQualificationText: string, selectedRangeOfQualification: string) {
            let returnValue: Boolean = false;

            if (runTypeRangeOfQualificationText.indexOf(selectedRangeOfQualification) > -1) {
                returnValue = true;
            }
            return returnValue;
        }

        function compareMaterialGroup(materialGroupRangeOfQualification: string, compareMaterialGroupParameter: string) {
            let returnValue: Boolean = false;
            let firtPart = compareMaterialGroupParameter.split(".");

            if (materialGroupRangeOfQualification.indexOf(compareMaterialGroupParameter) > -1) {
                returnValue = true;
            }

            if ((returnValue === false) && ((materialGroupRangeOfQualification.indexOf(" " + firtPart[0] + ",") > -1) || (materialGroupRangeOfQualification.indexOf(firtPart[0] + "*") > -1))) {
                returnValue = true;
            }

            if (returnValue === false) {
                let lastTwo = materialGroupRangeOfQualification.substr(materialGroupRangeOfQualification.length - 2);
                let comma = lastTwo.split(".")
                let chartA = lastTwo.split("a")
                if (comma.length === 1 && chartA.length === 1) {
                    if (lastTwo.indexOf(firtPart[0]) > -1) {
                        returnValue = true
                    }
                }
            }
            return returnValue;
        }

        function compareJointType(jointTypeText: string, materialGroupRangeOfQualification: string) {
            let returnValue: Boolean = false;

            if ((materialGroupRangeOfQualification.indexOf("(") > -1) && (materialGroupRangeOfQualification.indexOf(")") > -1)) {
                let jointType = materialGroupRangeOfQualification.substring(materialGroupRangeOfQualification.indexOf("(") + 1, materialGroupRangeOfQualification.indexOf(")"))
                if (jointTypeText.indexOf(jointType) > -1) {
                    returnValue = true;
                }
            }
            return returnValue;
        }

        function compareOuterDiameter(diameter1Text: string, compareDiameterParameter: string) { //Changes in table [DiameterRangeOfQualification], can make so we need to change here
            let returnValue: Boolean = false;
            let compareValueNumber = !isNaN(parseFloat(compareDiameterParameter.replace(',', '.'))) ? parseFloat(compareDiameterParameter.replace(',', '.')) : 0.0;
            let diameter1TextArray = diameter1Text.split(" ");

            if (diameter1TextArray.length > 1) {
                let compareValue = !isNaN(parseFloat(diameter1TextArray[1].replace(',', '.'))) ? parseFloat(diameter1TextArray[1].replace(',', '.')) : 0.0;
                let conditions = diameter1TextArray[0];
                switch (conditions) {
                    case "≥":
                        if (compareValueNumber >= compareValue ) {
                            returnValue = true;
                        }
                        break;
                    case "˃":
                        if (compareValueNumber > compareValue) {
                            returnValue = true;
                        }
                        break;
                    default:
                        break;
                }
            }
            return returnValue;
        }

        function compareValueBetweenMinAndMax(materialThicknessRangeOfQualification: string, compareValue: string) {//Changes in table [MaterialThicknessRangeOfQualification], can make so we need to change here
            let returnValue: Boolean = false;
            let compareValueNumber = !isNaN(parseFloat(compareValue.replace(',', '.'))) ? parseFloat(compareValue.replace(',', '.')) : 0.0;
            
            let BW = materialThicknessRangeOfQualification.substring(materialThicknessRangeOfQualification.indexOf("BW") + 4, materialThicknessRangeOfQualification.indexOf("FW") - 1).split(" ").join("");
            if (materialThicknessRangeOfQualification.indexOf("BW") > -1) {
                let BWMin = Number(BW.split("-")[0].replace(',', '.'));
                let BWMax = Number(BW.split("-")[1].replace(',', '.'));
                if ((BWMin <= compareValueNumber) && compareValueNumber <= BWMax ) {
                    returnValue = true;
                }
                    
            }

            let FW = materialThicknessRangeOfQualification.substring(materialThicknessRangeOfQualification.indexOf("FW") + 4, materialThicknessRangeOfQualification.length).split(" ").join("");
            if (materialThicknessRangeOfQualification.indexOf("FW") > -1 && returnValue === false) {
                let conditions = FW.split("-")[0];
                switch (conditions) {
                    case "≥5":
                        if (compareValueNumber  >= 5) {
                            returnValue = true;
                        }
                        break;
                    default:
                        let FWMin = Number(FW.split("-")[0].replace(',', '.'));
                        let FWMax = Number(FW.split("-")[1].replace(',', '.'));
                        if ((FWMin <= compareValueNumber) && (compareValueNumber <= FWMax)) {
                            returnValue = true;
                        }
                        break;
                }

            }
            return returnValue;
        }
    },
    setFreeSearch: (freeSearch: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            searchProperties.freeSearch = freeSearch;
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setMaterialThickness: (materialThickness: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            searchProperties.materialThickness = materialThickness;
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setOuterDiameter: (outerDiameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            searchProperties.outerDiameter = outerDiameter;
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setWeldingMethodForSearch: (weldingMethodId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let weldingMethods: Array<GetWeldingMethodResponse> = [...appState.wpqrComplete.wpqrWeldingMethodsSearch];

            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };

            if (weldingMethodId !== 0) {
                for (let i = 0; i < weldingMethods.length; i++) {
                    if (weldingMethodId === weldingMethods[i].id) {
                        searchProperties.selectedWeldingMethod = { ...weldingMethods[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedWeldingMethod = {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setStandardForSearch: (StandardId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let standars: Array<GetStandardResponse> = [...appState.wpqrComplete.searchProperties.standards];
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            if (StandardId !== 0) {
                for (let i = 0; i < standars.length; i++) {
                    if (StandardId === standars[i].id) {
                        searchProperties.selectedStandard = { ...standars[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedStandard = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedProductType: (productTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            if (productTypeId !== 0) {
                for (let i = 0; i < searchProperties.productTypes.length; i++) {
                    if (productTypeId === searchProperties.productTypes[i].id) {
                        searchProperties.selectedProductType = { ...searchProperties.productTypes[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedProductType = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedJointType: (jointTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            if (jointTypeId !== 0) {
                for (var i = 0; i < searchProperties.jointTypes.length; i++) {
                    if (jointTypeId === searchProperties.jointTypes[i].id) {
                        searchProperties.selectedJointType = { ...searchProperties.jointTypes[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedJointType = {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedMaterialGroup: (materialGroupId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            if (materialGroupId !== 0) {
                for (var i = 0; i < searchProperties.materialGroups.length; i++) {
                    if (materialGroupId === searchProperties.materialGroups[i].id) {
                        searchProperties.selectedMaterialGroup = { ...searchProperties.materialGroups[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedMaterialGroup = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedRunTypeRangeOfQualification: (runTypeRangeOfQualificationId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            if (runTypeRangeOfQualificationId !== 0) {
                for (var i = 0; i < searchProperties.runTypeRangeOfQualifications.length; i++) {
                    if (runTypeRangeOfQualificationId === searchProperties.runTypeRangeOfQualifications[i].id) {
                        searchProperties.selectedRunTypeRangeOfQualification = { ...searchProperties.runTypeRangeOfQualifications[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedRunTypeRangeOfQualification = {
                    id: 0,
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                    rangeOfQualification: '',
                    runType: 1,
                    hardnessTestingOrImpactTesting: false,
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    clearSearchProperties: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrComplete.searchProperties };
            searchProperties = {
                freeSearch: '',
                welder: '',
                selectedWeldingMethod: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                productTypes: [],
                selectedProductType: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                materialThickness: '',
                outerDiameter: '',
                jointTypes: [],
                selectedJointType: {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                materialGroups: [],
                selectedMaterialGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                runTypeRangeOfQualifications: [],
                selectedRunTypeRangeOfQualification: {
                    id: 0,
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                    rangeOfQualification: '',
                    runType: 1,
                    hardnessTestingOrImpactTesting: false,

                },
                standards: [],
                selectedStandard: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setShowTabSettingModal: (setShowTabSettingModal: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SHOW_TAB_SETTING_MODAL', showTabSettingModal: setShowTabSettingModal });
    },
    setTabSettingColumnDekraProcedureNumber: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.dekraProcedureNumber = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnmanufacturerWPQRNumber: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.manufacturerWPQRNumber = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnPwpsName: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.pwpsName = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnQualificationStandard: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.qualificationStandard = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnWeldingMethod: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.weldingMethod = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnJointTypes: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.jointTypes = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnProductType: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.productType = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnMaterialThickness: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.materialThickness = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnOutsideDiameter: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.outsideDiameter = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnMaterialGroup: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.materialGroup = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnSingleMultiString: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.singleMultiString = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnMakeAdditives: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.makeAdditives = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnMakeDesignation: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.makeDesignation = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setTabSettingColumnRemark: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrCompleteSettingColumns: WPQRCompleteSettingColumns = { ...appState.wpqrComplete.wpqrCompleteSettingColumns };
            wpqrCompleteSettingColumns.remark = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrCompleteSettingColumns: wpqrCompleteSettingColumns });
        }
    },
    setPaginationPageIndex: (pageIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrPaginationPageIndex = pageIndex
            dispatch({ type: 'SET_PAGINATION_PAGE_INDEX', wpqrPaginationPageIndex: wpqrPaginationPageIndex });
        }
    },
    setSelectedRevision: (revisionId: number, index: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrRevision = { ...appState.wpqrComplete.wpqrRevision };
            let selectedRevision: WPQR = { ...appState.wpqrComplete.wpqrRevision.selectedRevision };
            for (let i = 0; i < wpqrRevision.revisions.length; i++) {
                if (wpqrRevision.revisions[i].id === revisionId) {
                    selectedRevision = { ...wpqrRevision.revisions[i] };
                    selectedRevision.revisionNumber = index;
                    break;
                }
            }
            wpqrRevision.selectedRevision = selectedRevision;
            dispatch({ type: 'WPQR_SET_REVISION_RESULT', wpqrRevision: wpqrRevision });
        }
    },
    cleanSelectedRevision: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrRevision = { ...appState.wpqrComplete.wpqrRevision };
            let selectedRevision: WPQR = { ...appState.wpqrComplete.wpqrRevision.selectedRevision };
            selectedRevision = {
                dekraNumber: '',
                wpqrNumber: '',
                pwpsNumber: '',
                qualificationStandard: '',
                weldingMethod1Name: '',
                weldingMethod2Name: '',
                weldingMethod: '',
                jointTypeId: '',
                jointTypeName: '',
                productType: '',
                productType1Name: '',
                productType2Name: '',
                materialThickness1RangeOfQualification: '',
                diameter1Text: '',
                materialGroupRangeOfQualification: '',
                runTypeRangeOfQualification: '',
                makeAdditives: '',
                make1Name: '',
                make1DesignationName: '',
                make2Name: '',
                make2DesignationName: '',
                standardName: '',
                applicationId: 0,
                remarks: '',
                id: 0,
                wpqrId: 0,
                revisionWpqrAction: '',
                revisionWpqrId: undefined,
                createdAt: '',
                wpsId: 0,
                employee: {
                    id: 0,
                    firstName: '',
                    lastName: ''
                },
                revisionNumber: 0,
                selectedFile: {
                    fileName: '',
                    fileBase64: '',
                    filePath: '',
                    fileDescription: '',
                    selectedFile: false
                }
            };
            wpqrRevision.selectedRevision = selectedRevision;
            dispatch({ type: 'WPQR_SET_REVISION_RESULT', wpqrRevision: wpqrRevision });
        }
    },
    closeWpsRevisionModal: (showChooseWpsRevisionModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrRevision: WPQRRevision = { ...appState.wpqrComplete.wpqrRevision };
            if (showChooseWpsRevisionModal) {
                wpqrRevision.selectedWpsRevisionModalOk = true;
                wpqrRevision.selectedRevisionAction = wpqrRevision.selectedRevision.revisionWpqrAction;
            }
            wpqrRevision.showChooseWpsRevisionModal = false;
            dispatch({ type: 'WPQR_SET_REVISION_RESULT', wpqrRevision: wpqrRevision });
        }
    },
    setShowChooseWpsRevisionModal: (showChooseWpsRevisionModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let wpqrRevision: WPQRRevision = { ...appState.wpqrComplete.wpqrRevision };
            wpqrRevision.showChooseWpsRevisionModal = showChooseWpsRevisionModal;
            dispatch({ type: 'WPQR_SET_REVISION_RESULT', wpqrRevision: wpqrRevision });
        }
    },
    removeWpqr: (wpqrId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.customerList) {
            fetch(`/api/Wpqrs/DeleteByWpqrId/${wpqrId}`, {
                method: 'DELETE',
                headers: authHeader.authHeader(),
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    EventBus.dispatch("refreshWpqrList", "");
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WPQRCompleteStoreState = {
    wpqrs: [],
    wpqrsSearchResult: [],
    searchProperties: {
        freeSearch: '',
        welder: '',
        selectedWeldingMethod: {
            id: 0,
            name: '',
            efficiencyFactor: 0.0,
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        productTypes: [],
        selectedProductType: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        materialThickness: '',
        outerDiameter: '',
        jointTypes: [],
        selectedJointType: {
            id: 0,
            name: '',
            description: '',
            jointShapes: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        materialGroups: [],
        selectedMaterialGroup: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        runTypeRangeOfQualifications: [],
        selectedRunTypeRangeOfQualification: {
            id: 0,
            createdAt: '',
            deletedAt: '',
            updatedAt: '',
            rangeOfQualification: '',
            runType: 1,
            hardnessTestingOrImpactTesting: false,

        },
        standards: [],
        selectedStandard: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
    },
    showTabSettingModal: false,
    wpqrCompleteSettingColumns: {
        dekraProcedureNumber: true,
        manufacturerWPQRNumber: true,
        pwpsName: true,
        qualificationStandard: true,
        weldingMethod: true,
        jointTypes: true,
        productType: true,
        materialThickness: true,
        outsideDiameter: true,
        materialGroup: true,
        singleMultiString: true,
        makeAdditives: true,
        makeDesignation: true,
        remark: true,
    },
    wpqrPaginationPageIndex: 0,
    wpqrPaginationPageSize: 20,
    wpqrWeldingMethodsSearch: [],
    wpqrRevision: {
        selectedRevisionAction: "",
        revisions: [],
        selectedRevision: {
            dekraNumber: '',
            wpqrNumber: '',
            pwpsNumber: '',
            qualificationStandard: '',
            weldingMethod1Name: '',
            weldingMethod2Name: '',
            weldingMethod: '',
            jointTypeId: '',
            jointTypeName: '',
            productType: '',
            productType1Name: '',
            productType2Name: '',
            materialThickness1RangeOfQualification: '',
            diameter1Text: '',
            materialGroupRangeOfQualification: '',
            runTypeRangeOfQualification: '',
            makeAdditives: '',
            make1Name: '',
            make1DesignationName: '',
            make2Name: '',
            make2DesignationName: '',
            standardName: '',
            applicationId: 0,
            remarks: '',
            id: 0,
            wpqrId: 0,
            revisionWpqrAction: '',
            revisionWpqrId: undefined,
            createdAt: '',
            wpsId: 0,
            employee: {
                id: 0,
                firstName: '',
                lastName: ''
            },
            revisionNumber: 0,
            selectedFile: {
                fileName: '',
                fileBase64: '',
                filePath: '',
                fileDescription: '',
                selectedFile: false
            }
        },
        showChooseWpsRevisionModal: false,
        selectedWpsRevisionModalOk: false
    }
};

export const reducer: Reducer<WPQRCompleteStoreState> = (state: WPQRCompleteStoreState | undefined, incomingAction: Action): WPQRCompleteStoreState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_WPQRS':
            return {
                wpqrs: action.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpqrCompleteSettingColumns: state.wpqrCompleteSettingColumns,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                wpqrRevision: state.wpqrRevision
            }; 
        case 'GET_WPQRS_REQUEST':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpqrCompleteSettingColumns: state.wpqrCompleteSettingColumns,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                wpqrRevision: state.wpqrRevision
            };
        case 'SET_WPQRS_SEARCH_RESULT':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: action.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpqrCompleteSettingColumns: state.wpqrCompleteSettingColumns,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                wpqrRevision: state.wpqrRevision
            };
        case 'SET_SEARCH_PROPERTIES':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: action.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpqrCompleteSettingColumns: state.wpqrCompleteSettingColumns,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                wpqrRevision: state.wpqrRevision
            };
        case 'SET_SHOW_TAB_SETTING_MODAL':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrCompleteSettingColumns: state.wpqrCompleteSettingColumns,
                showTabSettingModal: action.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                wpqrRevision: state.wpqrRevision
            };
        case 'SET_TAB_SETTING_COLUMNS':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrCompleteSettingColumns: action.wpqrCompleteSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                wpqrRevision: state.wpqrRevision
            };
        case 'SET_PAGINATION_PAGE_INDEX':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrCompleteSettingColumns: state.wpqrCompleteSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: action.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                wpqrRevision: state.wpqrRevision
            };
        case 'SET_WELDING_METHODS_SEARCH':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrCompleteSettingColumns: state.wpqrCompleteSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: action.wpqrWeldingMethodsSearch,
                wpqrRevision: state.wpqrRevision
            };
        case 'GET_WELDING_METHODS_SEARCH_REQUEST':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrCompleteSettingColumns: state.wpqrCompleteSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                wpqrRevision: state.wpqrRevision
            };
        case 'WPQR_SET_REVISION_RESULT':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrCompleteSettingColumns: state.wpqrCompleteSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                wpqrRevision: action.wpqrRevision
            };
        default:
            return state;
    }
};
