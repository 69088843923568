﻿
import { Action, Reducer } from '../../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './../index';
import { GetHistoryProgressResponse } from '../models/responses/GetHistoryProgressResponse';
import authHeader from '../models/auth-header';
import EventBus from '../eventBus';
import { UpdateHistoryProgressRequest } from '../models/requests/UpdateHistoryProgressRequest';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface HistoryProgressState {
    historiesProgress: Array<GetHistoryProgressResponse>; 
    historyProgress: GetHistoryProgressResponse; 
    activeCustomerId: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
interface GetHistoriesProgressReceiveAction {
    type: 'GET_HISTORIES_PROGRESS_RECEIVE';
    historiesProgress: Array<GetHistoryProgressResponse>;
}

interface GetHistoryProgressRequestAction {
    type: 'GET_HISTORIES_PROGRESS_REQUEST';
}

interface SetHistoryProgressReceiveAction {
    type: 'SET_HISTORY_PROGRESS_RECEIVE';
    historyProgress: GetHistoryProgressResponse;
}

export interface ResetStateHistoryProgressAction {
    type: 'RESET_STATE_HISTORY_PROGRESS_ACTION';
}
export interface SetHistoryProgressCustomerIdAction {
    type: 'SET_HISTORY_PROGRESS_CUSTOMER_ID';
    activeCustomerId: number;
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = GetHistoriesProgressReceiveAction
    | SetHistoryProgressReceiveAction
    | GetHistoryProgressRequestAction
    | ResetStateHistoryProgressAction
    | SetHistoryProgressCustomerIdAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearState: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'RESET_STATE_HISTORY_PROGRESS_ACTION' });
    },
    setCustomerId: (customerId: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_HISTORY_PROGRESS_CUSTOMER_ID', activeCustomerId: customerId });
    },
    updateHistoryProgress: (updateHistoryProgressRequest: UpdateHistoryProgressRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/HistoriesProgress/UpdateHistoryProgress`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateHistoryProgressRequest)
        })
            .then(response => response.json() as Promise<GetHistoryProgressResponse>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.historyProgress) {
                    let getHistoryProgressResponse: GetHistoryProgressResponse = { ...appState.historyProgress.historyProgress };
                    getHistoryProgressResponse = { ...data };
                    dispatch({ type: 'SET_HISTORY_PROGRESS_RECEIVE', historyProgress: getHistoryProgressResponse });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_HISTORIES_PROGRESS_REQUEST' });
    },
    getHistoriesProgress: (customerId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/HistoriesProgress/GetAllHistoryProgressByCustomerId/${customerId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetHistoryProgressResponse>;
                console.info(data);
                const appState = getState();
                if (appState && appState.historyProgress) {
                    let historiesProgress: Array<GetHistoryProgressResponse> = { ...appState.historyProgress.historiesProgress };
                    historiesProgress = [...data];
                    dispatch({ type: 'GET_HISTORIES_PROGRESS_RECEIVE', historiesProgress: historiesProgress });
                }
            }))
            .catch(error => {
                console.error(error);
            });
    },
    getByApplicationId: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/HistoriesProgress/GetByApplicationId/${id}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as GetHistoryProgressResponse;
                console.info(data);
                const appState = getState();
                if (appState && appState.historyProgress) {
                    let historyProgress: GetHistoryProgressResponse = { ...appState.historyProgress.historyProgress };
                    historyProgress = { ...data };
                    dispatch({ type: 'SET_HISTORY_PROGRESS_RECEIVE', historyProgress: historyProgress });
                }
            }))
            .catch(error => {
                console.error(error);
            });
    },

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: HistoryProgressState = {
    historiesProgress: [],
    historyProgress: {
        id: 0,
        applicationId: 0,
        wpsName: '',
        pwpsName: '',
        standard: '',
        wpqrNumber: '',
        progressId1: '',
        progressId2: '',
        progressId3: '',
        progressId4: '',
        customerId: 0,
        createdAt: '',
    },
    activeCustomerId: 0
};

export const reducer: Reducer<HistoryProgressState> = (state: HistoryProgressState | undefined, incomingAction: Action): HistoryProgressState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_HISTORIES_PROGRESS_RECEIVE':
            return {
                historiesProgress: action.historiesProgress,
                historyProgress: state.historyProgress,
                activeCustomerId: state.activeCustomerId,
            };
        case 'SET_HISTORY_PROGRESS_RECEIVE':
            return {
                historiesProgress: state.historiesProgress,
                historyProgress: action.historyProgress,
                activeCustomerId: state.activeCustomerId,
            };
        case 'SET_HISTORY_PROGRESS_CUSTOMER_ID':
            return {
                historiesProgress: state.historiesProgress,
                historyProgress: state.historyProgress,
                activeCustomerId: action.activeCustomerId,
            };
        case 'RESET_STATE_HISTORY_PROGRESS_ACTION':
            return unloadedState;
        default:
            return state;
    }
};
