﻿
export class TestingOrderResponse {
    additionalTesting: boolean;
    bendTesting: boolean;
    chargeNumber1: string;
    chargeNumber2: string;
    conditionsAndDescriptionRemarks: string;
    contactPerson: string;
    customer: string;
    date: string;
    destructiveTestingApproved: string;
    destructiveTestingDate: string;
    destructiveTestingSignature: string;
    dimension1: string;
    dimension2: string;
    directionOfPlateRolling1: string;
    directionOfPlateRolling2: string;
    fillerMaterial: string;
    hardnessTesting: boolean;
    impactTesting: boolean;
    impactTestTemperature1: string;
    impactTestTemperature2: string;
    iso10902: boolean;
    iso10902AdditionalTesting: boolean;
    iso10902CruciformTensileTesting: boolean;
    iso10902ImpactTesting: boolean;
    iso10902MacroPlusPhoto: boolean;
    iso10902MicroTesting: boolean;
    iso10902WeldabilityPrimer: boolean;
    iso10902AdditionalTestingText: string;
    iso10902CruciformTensileTestingText: string;
    iso10902ImpactTestingText: string;
    iso10902MacroPlusPhotoText: string;
    iso10902MicroTestingText: string;
    iso10902WeldabilityPrimerText: string;
    iso129526AdditionalTesting: boolean;
    iso129526ImpactTesting: boolean;
    iso129526LongitudinalTensileTesting: boolean;
    iso129526AdditionalTestingText: string;
    iso129526ImpactTestingText: string;
    iso129526LongitudinalTensileTestingText: string;
    iso129526Testing: boolean;
    iso13134AdditionalTesting: boolean;
    iso13134BendTesting: boolean;
    iso13134HardnessTesting: boolean;
    iso13134PeelTesting: boolean;
    iso13134ShearTesting: boolean;
    iso13134TensileTesting: boolean;
    iso13134BrazinProcedureApproval: boolean;
    iso13134AdditionalTestingText: string;
    iso13134BendTestingText: string;
    iso13134HardnessTestingText: string;
    iso13134PeelTestingText: string;
    iso13134ShearTestingText: string;
    iso13134TensileTestingText: string;
    iso134454Testing: boolean;
    iso134454AdditionalTesting: boolean;
    iso134454FerriteTesting: boolean;
    iso134454ImpactTesting: boolean;
    iso134454LongitudinalTensileTesting: boolean;
    iso134454MicroTesting: boolean;
    iso134454AdditionalTestingText: string;
    iso134454FerriteTestingText: string;
    iso134454ImpactTestingText: string;
    iso134454LongitudinalTensileTestingText: string;
    iso134454MicroTestingText: string;
    iso14555AdditionalTesting: boolean;
    iso14555ApplicationGreaterThan100Degrees: boolean;
    iso14555ApplicationLessThanOrEqualTo100Degrees: boolean;
    iso14555BendTesting: boolean;
    iso14555ArcStudWelding: boolean;
    iso14555_3834Testing: boolean;
    iso14555_783Testing: boolean;
    iso14555_784Testing: boolean;
    iso14555_785Testing: boolean;
    iso14555_786Testing: boolean;
    iso14555_787Testing: boolean;
    iso14555BendTestingTorqueWrench: boolean;
    iso14555MacroPlusPhoto: boolean;
    iso14555TensileTesting: boolean;
    iso14555TorqueTesting: boolean;
    iso14555RTProvning: boolean;
    iso14555AdditionalTestingText: string;
    iso14555ApplicationGreaterThan100DegreesText: string;
    iso14555ApplicationLessThanOrEqualTo100DegreesText: string;
    iso14555BendTestingText: string;
    iso14555BendTestingTorqueWrenchText: string;
    iso14555MacroPlusPhotoText: string;
    iso14555TensileTestingText: string;
    iso14555TorqueTestingText: string;
    iso14555RTProvningText: string;
    iso14555_3834TestingText: string;
    iso156141Edition2004: boolean;
    iso156141Edition2017: boolean;
    macroPlusPhoto: boolean;
    manufacturerWPQRBPARNumber: string;
    material1: string;
    material2: string;
    materialGroup1: string;
    materialGroup2: string;
    materialSpecifications1Remarks: string;
    materialSpecifications2Remarks: string;
    mtPT: string;
    ndtApproved: string;
    ndtDate: string;
    ndtSignature: string;
    ndtOther: string;
    norsokAdditionalTesting: boolean;
    norsokAdditionalTesting1: boolean;
    norsokAdditionalTesting2: boolean;
    norsokAdditionalTesting3: boolean;
    norsokAdditionalTesting4: boolean;
    norsokAdditionalTestingText: string;
    norsokAdditionalTesting1Text: string;
    norsokAdditionalTesting2Text: string;
    norsokAdditionalTesting3Text: string;
    norsokAdditionalTesting4Text: string;
    norsokCorrosionTestingText: string;
    norsokCorrosionTesting: boolean;
    norsok: boolean;
    phoneNumber: string;
    pWPSPBPS: string;
    orderedByLevel3: string;
    other: string;
    ndtRemarks: string;
    rt: string;
    signature: string;
    standardForAcceptance1: string;
    standardForAcceptance2: string;
    transverseTensilePlusRp0ReH: boolean;
    ut: string;
    visual: string;
    weldingDate: string;
    weldingBrazingPositions: string;
    weldingProcess: string;
    welderBrazerFirstName: string;
    welderBrazerLastName: string;
    iso15614transverseTensilePlusRp0ReHText: string;
    iso15614macroPlusPhotoText: string;
    iso15614hardnessTestingText: string;
    iso15614impactTestingText: string;
    iso15614bendTestingText: string;
    iso15614additionalTestingText: string;
    pedAdditionalTesting: boolean;
    iso15614TestingText: string;
    dekraProcedureNoTesting: string;
    invoiceReferenceName: string;
    uppdragsnr: string;
    testmarking: string;
    nace: boolean;
    naceMr0103Testing: boolean;
    naceMr0103TestingText: string;
    naceMr0175Testing: boolean;
    naceMr0175TestingText: string;
    naceAdditionalTesting1: boolean;
    naceAdditionalTesting1Text: string;
    naceAdditionalTesting2: boolean;
    naceAdditionalTesting2Text: string;

    public constructor() {
        this.additionalTesting = false;
        this.bendTesting = false;
        this.chargeNumber1 = '';
        this.chargeNumber2 = '';
        this.conditionsAndDescriptionRemarks = '';
        this.contactPerson = '';
        this.customer = '';
        this.date = '';
        this.destructiveTestingApproved = '';
        this.destructiveTestingDate = '';
        this.destructiveTestingSignature = '';
        this.dimension1 = '';
        this.dimension2 = '';
        this.directionOfPlateRolling1 = '';
        this.directionOfPlateRolling2 = '';
        this.fillerMaterial = '';
        this.hardnessTesting = false;
        this.impactTesting = false;
        this.impactTestTemperature1 = '';
        this.impactTestTemperature2 = '';
        this.iso10902 = false;
        this.iso10902AdditionalTesting = false;
        this.iso10902CruciformTensileTesting = false;
        this.iso10902ImpactTesting = false;
        this.iso10902MacroPlusPhoto = false;
        this.iso10902MicroTesting = false;
        this.iso10902WeldabilityPrimer = false;
        this.iso10902AdditionalTestingText = '';
        this.iso10902CruciformTensileTestingText = '';
        this.iso10902ImpactTestingText = '';
        this.iso10902MacroPlusPhotoText = '';
        this.iso10902MicroTestingText = '';
        this.iso10902WeldabilityPrimerText = '';
        this.iso129526LongitudinalTensileTesting = false;
        this.iso129526ImpactTesting = false;
        this.iso129526AdditionalTesting = false;
        this.iso129526LongitudinalTensileTestingText = '';
        this.iso129526ImpactTestingText = '';
        this.iso129526AdditionalTestingText = '';
        this.iso129526Testing = false;
        this.iso13134AdditionalTesting = false;
        this.iso13134BendTesting = false;
        this.iso13134HardnessTesting = false;
        this.iso13134PeelTesting = false;
        this.iso13134ShearTesting = false;
        this.iso13134TensileTesting = false;
        this.iso13134BrazinProcedureApproval = false;
        this.iso13134AdditionalTestingText = '';
        this.iso13134BendTestingText = '';
        this.iso13134HardnessTestingText = '';
        this.iso13134PeelTestingText = '';
        this.iso13134ShearTestingText = '';
        this.iso13134TensileTestingText = '';
        this.iso134454Testing = false;
        this.iso134454AdditionalTesting = false;
        this.iso134454FerriteTesting = false;
        this.iso134454ImpactTesting = false;
        this.iso134454LongitudinalTensileTesting = false;
        this.iso134454MicroTesting = false;
        this.iso134454AdditionalTestingText = '';
        this.iso134454FerriteTestingText = '';
        this.iso134454ImpactTestingText = '';
        this.iso134454LongitudinalTensileTestingText = '';
        this.iso134454MicroTestingText = '';
        this.iso14555AdditionalTesting = false;
        this.iso14555ApplicationGreaterThan100Degrees = false;
        this.iso14555ApplicationLessThanOrEqualTo100Degrees = false;
        this.iso14555BendTesting = false;
        this.iso14555ArcStudWelding = false;
        this.iso14555BendTestingTorqueWrench = false;
        this.iso14555MacroPlusPhoto = false;
        this.iso14555TensileTesting = false;
        this.iso14555TorqueTesting = false;
        this.iso14555RTProvning = false;
        this.iso14555_3834Testing = false;
        this.iso14555_783Testing = false;
        this.iso14555_784Testing = false;
        this.iso14555_785Testing = false;
        this.iso14555_786Testing = false;
        this.iso14555_787Testing = false;
        this.iso14555AdditionalTestingText = '';
        this.iso14555ApplicationGreaterThan100DegreesText = '';
        this.iso14555ApplicationLessThanOrEqualTo100DegreesText = '';
        this.iso14555BendTestingText = '';
        this.iso14555BendTestingTorqueWrenchText = '';
        this.iso14555MacroPlusPhotoText = '';
        this.iso14555TensileTestingText = '';
        this.iso14555TorqueTestingText = '';
        this.iso14555RTProvningText = '';
        this.iso14555_3834TestingText = '';
        this.iso156141Edition2004 = false;
        this.iso156141Edition2017 = false;
        this.macroPlusPhoto = false;
        this.manufacturerWPQRBPARNumber = '';
        this.material1 = '';
        this.material2 = '';
        this.materialGroup1 = '';
        this.materialGroup2 = '';
        this.materialSpecifications1Remarks = '';
        this.materialSpecifications2Remarks = '';
        this.mtPT = '';
        this.ndtApproved = '';
        this.ndtDate = '';
        this.ndtRemarks = '';
        this.ndtSignature = '';
        this.ndtOther = '';
        this.norsokAdditionalTesting = false;
        this.norsokAdditionalTesting1 = false;
        this.norsokAdditionalTesting2 = false;
        this.norsokAdditionalTesting3 = false;
        this.norsokAdditionalTesting4 = false;
        this.norsokAdditionalTestingText = '';
        this.norsokAdditionalTesting1Text = '';
        this.norsokAdditionalTesting2Text = '';
        this.norsokAdditionalTesting3Text = '';
        this.norsokAdditionalTesting4Text = '';
        this.norsokCorrosionTesting = false;
        this.norsok = false;
        this.norsokCorrosionTestingText = '';
        this.phoneNumber = '';
        this.pWPSPBPS = '';
        this.orderedByLevel3 = '';
        this.other = '';
        this.rt = '';
        this.signature = '';
        this.standardForAcceptance1 = '';
        this.standardForAcceptance2 = '';
        this.transverseTensilePlusRp0ReH = false;
        this.ut = '';
        this.visual = '';
        this.weldingDate = '';
        this.weldingBrazingPositions = '';
        this.weldingProcess = '';
        this.welderBrazerFirstName = '';
        this.welderBrazerLastName = '';
        this.iso15614transverseTensilePlusRp0ReHText = '';
        this.iso15614macroPlusPhotoText = '';
        this.iso15614hardnessTestingText = '';
        this.iso15614impactTestingText = '';
        this.iso15614bendTestingText = '';
        this.iso15614additionalTestingText = '';
        this.iso15614TestingText = '';
        this.pedAdditionalTesting = false;
        this.dekraProcedureNoTesting = '';
        this.invoiceReferenceName = '';
        this.uppdragsnr = '';
        this.testmarking = '';
        this.nace = false;
        this.naceMr0103Testing = false;
        this.naceMr0103TestingText = '';
        this.naceMr0175Testing = false;
        this.naceMr0175TestingText = '';
        this.naceAdditionalTesting1 = false;
        this.naceAdditionalTesting1Text = '';
        this.naceAdditionalTesting2 = false;
        this.naceAdditionalTesting2Text = '';
    }
}