﻿export class CorrosionTestingResultsTableRow {
    acceptance: string;
    testingResult: string;
    testSpecimenNumber: string;

    public constructor(testSpecimenNumber: string, testingResult: string, acceptance: string) {
        this.testSpecimenNumber = testSpecimenNumber;
        this.testingResult = testingResult;
        this.acceptance = acceptance;
    }
}