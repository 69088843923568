import * as React from 'react';
import { connect } from 'react-redux';
const logo = require('./../images/logo.png');

class LogoImage extends React.PureComponent<{customerLogo: string}> {
    public render() {
        return (<img alt="Dekra" src={this.props.customerLogo ? this.props.customerLogo : logo} />);
    }
};

export default connect()(LogoImage);
