import * as React from 'react';
import { NavItem, NavLink, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//@ts-ignore
const welDBaseLogo = require('../images/DEKRAwelDBase-scale-alpha.png');
import './NavMenu.css';
import '../dekra-style.css';
import '../customized.css';
import { t } from '../i18n';
import { CSSProperties } from "react";
import { LanguageSelect } from './LanguageSelect';
import * as LoginStore from '../store/LoginStore';
import EventBus from '../store/eventBus';
import { UserListObject } from '../store/UserListObject';

type LoginProps =
    LoginStore.LoginState // ... state we've requested from the Redux store
    & typeof LoginStore.actionCreators // ... plus action creators we've requested
    & OwnProps

export interface OwnProps {
    showMyPageDropdown: boolean;
    isLoggedIn: boolean;
    currentUser: UserListObject
    currentRoles: Array<string>
}

const dekraWrapperDivHead: CSSProperties = {
    minHeight: '90px'
}

const navbarHead: CSSProperties = {
    padding: '15px 0 0 0',
    margin: '0 0 0 0',
    minHeight: '50px',
    height: '50px',
    borderRadius: '0px',
    borderBottom: '1px solid #E5E5E5'
}

const navbarHeaderHead: CSSProperties = {
    padding: '0 0 0 0',
    margin: '0 0 0 0',
}

const navbarBrandHead: CSSProperties = {
    border: '0px solid red',
    padding: '20px 30px 10px 18px',
    margin: '0 0 0 0',
    backgroundColor: 'white',
    borderTopRightRadius: '30px',
}

const navTabsHead: CSSProperties = {
    borderBottom: 'none',
    margin: '5px 0px 0px 0px',
    paddingLeft: '0px',
    borderTop: '1px solid white',
}

class NavMenu extends React.PureComponent<LoginProps, {
    isOpen: boolean, tabId: String, changingLanguage: boolean, showMyPageDropdown: boolean, showPermissionWarning: boolean, permissionWarningText: string, savedToken: string
}> {
    constructor(props: LoginProps) {
        super(props);

        window.addEventListener('click', (e) => {
            if (!(e.srcElement as HTMLElement).classList.contains('mypage-button')) {
                this.setDropdown(false);
            }
        });
    }

    public state = {
        isOpen: false,
        tabId: '',
        changingLanguage: false,
        showCustomerlist: false,
        showUserList: false,
        showPermissionWarning: false,
        showMyPageDropdown: false,
        permissionWarningText: "",
        savedToken: ""
    };

    public componentDidMount() {
        EventBus.on("tokenSet", () => {
            this.props.receiveCurrentRoles();
            this.props.receiveRoles();
            this.props.receiveCurrentUser();
        });

        EventBus.on("showPermissionWarning", (permissionWarning) => {
            this.setState({
                showPermissionWarning: true,
                permissionWarningText: permissionWarning
            });
        });

        if (sessionStorage.getItem('savedToken')) {
            console.log(['savedToken', sessionStorage.getItem('savedToken')]);
            this.setState({ savedToken: sessionStorage.getItem('savedToken') });    
        }
    }

    private setDropdown = (show) => {
        this.setState({
            showMyPageDropdown: show
        });
    }

    private toDekraButtonClick() {
        console.log('test');
        sessionStorage.removeItem('savedToken');
        window.location.reload();
    }

    public setChangingLanguage = (changing: boolean) => {
        this.setState({
            changingLanguage: changing
        });
    }

    public componentDidUpdate() {
        this.setChangingLanguage(false);
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    private setTabId = (p: String) => {
        this.setState({
            tabId: p
        });
        this.props.setActiveTabId();
    }

    private logOutClick = () => {
        this.props.logout();
    }

    private checkPermission(allowedP: Array<string>, userP: Array<string>) {
        if (userP == undefined) {
            return false;
        }

        for (var i = 0; i < userP.length; i++) {
            for (var j = 0; j < allowedP.length; j++) {
                if (userP[i] === allowedP[j]) {
                    return true;
                }
            }
        }

        return false;
    }

    public render() {
        return (
            <header className="container-fluid">
                <div className="dekra-wrapper" ng-show="isLoggedIn" style={dekraWrapperDivHead}>
                    <nav className="navbar gray-gradient-bg" role="navigation" style={navbarHead}>
                        <div className="navbar-header" style={navbarHeaderHead}>
                            <div className="d-block d-sm-none">
                                <span className="glyphicon glyphicon-chevron-down" data-toggle="dropdown" aria-expanded="false" style={{ padding: '0 0 0 0', margin: '0 0 0 0', position: 'absolute', right: '15px', top: '7.5px' }}>uuuu</span>
                                <Dropdown isOpen={this.state.isOpen} style={{ padding: '0 0 0 0', margin: '0 0 0 0', position: 'absolute', right: '15px', top: '7.5px' }} toggle={() => { this.setState({ isOpen: !this.state.isOpen }) }}>
                                    <DropdownToggle variant="success" id="dropdown-basic" className="btn-secondary-dekra">
                                        <i className={"fas fa-chevron-down"}></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {/*this.props.currentRoles.indexOf('Customer', 0) > -1 ? <NavItem className="dropdown-item" active={this.state.tabId === 'application'} onClick={() => this.setTabId('application')}>
                                            <NavLink className="" tag={Link} to="/application">{t('APPLY')}</NavLink>
                                        </NavItem> : null*/}
                                        <NavItem className="dropdown-item" active={this.state.tabId === 'wpqr'} onClick={() => this.setTabId('wpqr')}>
                                            <NavLink className="" tag={Link} to="/wpqr">WPQR</NavLink>
                                        </NavItem>
                                        <NavItem className="dropdown-item" active={this.state.tabId === 'wps'} onClick={() => this.setTabId('wps')}>
                                            <NavLink tag={Link} className="" to="/wps">WPS/BPS</NavLink>
                                        </NavItem>
                                        {/*<NavItem className="dropdown-item" active={this.state.tabId === 'login'} onClick={() => this.setTabId('login')}>
                                            <NavLink tag={Link} className="" to="/login">{t('PROJECT')}</NavLink>
                                        </NavItem>*/}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <a className="navbar-brand" href="/" style={navbarBrandHead}>
                                <img src={welDBaseLogo} alt="DEKRA" height="52px"></img>
                            </a>
                        </div>
                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <ul className="nav navbar-nav navbar-right dekra-green-active">
                                {this.state.showPermissionWarning ?
                                    <li>
                                        <span className='topText'>{t(this.state.permissionWarningText)}</span>
                                    </li>
                                    : null
                                }
                                <li>
                                    <LanguageSelect changingLanguage={this.state.changingLanguage} setTabId={this.setTabId} setChangingLanguage={this.setChangingLanguage} />
                                </li>
                                <li className={this.state.tabId === 'Home' ? 'active' : ''} onClick={() => this.setTabId('Home')}>
                                    <NavLink tag={Link} style={{ padding: '10px 10px 12px 10px' }} className="" to="/">{t('HOME')}</NavLink>
                                </li>
                                <li className={this.state.tabId === 'Pages' ? 'active' : ''} onClick={() => this.setTabId('Pages')} style={this.state.showMyPageDropdown ? { backgroundColor: "#017c40", color: "white" } : {}}>
                                    <div style={!this.props.loggedIn ? { display: "none" } : null} className='mypage-button' onClick={() => this.setDropdown(!this.state.showMyPageDropdown)}>{t('MyPages')}</div>
                                </li>
                                {this.state.showMyPageDropdown ?
                                    <div className="mypage-dropdown">
                                        <NavLink tag={Link} style={{ padding: "0" }} to="/mypage">
                                            <div className="mypage-dropdown-button">
                                                <div className="mypage-dropdown-icon-wrapper"><i className="fas fa-tools mypage-dropdown-icon"></i></div>
                                                <div><span>{t('SETTINGS')}</span></div>
                                            </div>
                                        </NavLink>
                                        {this.state.savedToken ?  < NavLink onClick={() => this.toDekraButtonClick()} tag={Link} style={{ padding: "0" }} to="/">
                                            <div className="mypage-dropdown-button">
                                                <div className="mypage-dropdown-icon-wrapper"><i className="fas fa-crown mypage-dropdown-icon"></i></div>
                                                <span>{t('TODEKRA')}</span>
                                            </div>
                                        </NavLink> : null}
                                        <NavLink tag={Link} onClick={() => this.logOutClick()} style={{ padding: "0" }} to="/login">
                                            <div className="mypage-dropdown-button">
                                                <div className="mypage-dropdown-icon-wrapper"><i className="fas fa-sign-out-alt mypage-dropdown-icon"></i></div>
                                                <span>{t('LOGOUT')}</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                    : null}
                            </ul>
                        </div>
                    </nav>
                    {this.props && this.props.currentRoles.length > 0 ?
                        <div className="d-none d-sm-block">
                            <ul className="nav nav-tabs flex-grow dekra-nav-tabs" style={navTabsHead}>
                                <React.Fragment>
                                    {this.checkPermission(["Customer"], this.props.currentRoles) ?
                                        <NavItem active={this.state.tabId === 'application'} onClick={() => this.setTabId('application')}>
                                            <NavLink className="" tag={Link} to="/application">{t('APPLY')}</NavLink>
                                        </NavItem> : null
                                    }

                                    {this.checkPermission(["Customer"], this.props.currentRoles) ?
                                        <NavItem active={this.state.tabId === 'wpqr' && (!this.props.activeTabId)} onClick={() => this.setTabId('wpqr')}>
                                            <NavLink className="" tag={Link} to="/wpqr">WPQR</NavLink>
                                        </NavItem> : null
                                    }

                                    {this.checkPermission(["Customer"], this.props.currentRoles) ?
                                        <NavItem active={this.state.tabId === 'wps' || this.props.activeTabId} onClick={() => this.setTabId('wps')}>
                                            <NavLink tag={Link} className="" to="/wps">WPS/BPS</NavLink>
                                        </NavItem> : null
                                    }

                                    {this.checkPermission(["Customer"], this.props.currentRoles) ?
                                        <NavItem active={this.state.tabId === 'wPlan'} onClick={() => this.setTabId('wPlan')}>
                                            <NavLink tag={Link} className="" to="/weldingplan">{t('WPLAN')}</NavLink>
                                        </NavItem> : null
                                    }

                                    {this.checkPermission(["Admin", "Dekra"], this.props.currentRoles) ?
                                        <NavItem active={this.state.tabId === 'WPQRDekra'} onClick={() => this.setTabId('WPQRDekra')}>
                                            <NavLink tag={Link} className="" to="/wpqrdekra">WPQR DEKRA</NavLink>
                                        </NavItem> : null
                                    }

                                    {this.checkPermission(["Admin"], this.props.currentRoles) ?
                                        <NavItem active={this.state.tabId === 'customerlist'} onClick={() => this.setTabId('customerlist')}>
                                            <NavLink tag={Link} className="" to="/customerlist">{t('CUSTOMERLIST')}</NavLink>
                                        </NavItem> : null
                                    }

                                    {this.checkPermission(["Admin"], this.props.currentRoles) ?
                                        <NavItem active={this.state.tabId === 'userlist'} onClick={() => this.setTabId('userlist')}>
                                            <NavLink tag={Link} className="" to="/userlist">{t('USERLIST')}</NavLink>
                                        </NavItem> : null
                                    }

                                    {this.checkPermission(["Admin"], this.props.currentRoles) ?
                                        <NavItem active={this.state.tabId === 'dekralist'} onClick={() => this.setTabId('dekralist')}>
                                            <NavLink tag={Link} className="" to="/dekralist">{t('DEKRALIST')}</NavLink>
                                        </NavItem> : null
                                    }

                                    {this.checkPermission(["Admin"], this.props.currentRoles) ?
                                        <NavItem active={this.state.tabId === 'newslist'} onClick={() => this.setTabId('newslist')}>
                                            <NavLink tag={Link} className="" to="/newslist">{t('NEWSLIST')}</NavLink>
                                        </NavItem> : null
                                    }

                                    {/* //Uncomment to test ProtectedRoutes
                    <NavItem active={this.state.tabId === 'application'} onClick={() => this.setTabId('application')}>
                        <NavLink className="" tag={Link} to="/application">{t('APPLY')}</NavLink>
                    </NavItem> 
                
                    <NavItem active={this.state.tabId === 'wpqr'} onClick={() => this.setTabId('wpqr')}>
                        <NavLink className="" tag={Link} to="/wpqr">WPQR</NavLink>
                    </NavItem> 
                
                    <NavItem active={this.state.tabId === 'wps'} onClick={() => this.setTabId('wps')}>
                        <NavLink tag={Link} className="" to="/wps">WPS/BPS</NavLink>
                    </NavItem> 
                
                    <NavItem active={this.state.tabId === 'login'} onClick={() => this.setTabId('login')}>
                        <NavLink tag={Link} className="" to="/login">{t('LOGIN')}</NavLink>
                    </NavItem> 
                
                    <NavItem active={this.state.tabId === 'WPQRDekra'} onClick={() => this.setTabId('WPQRDekra')}>
                        <NavLink tag={Link} className="" to="/wpqrdekra">WPQR DEKRA</NavLink>
                    </NavItem> 
                
                    <NavItem active={this.state.tabId === 'customerlist'} onClick={() => this.setTabId('customerlist')}>
                        <NavLink tag={Link} className="" to="/customerlist">{t('CUSTOMERLIST')}</NavLink>
                    </NavItem> 
                
                    <NavItem active={this.state.tabId === 'userlist'} onClick={() => this.setTabId('userlist')}>
                        <NavLink tag={Link} className="" to="/userlist">{t('USERLIST')}</NavLink>
                    </NavItem> */}

                                </React.Fragment>

                            </ul>
                        </div> :
                        this.props.loggedIn ? null :
                            <div className="d-none d-sm-block">
                                <ul className="nav nav-tabs flex-grow dekra-nav-tabs" style={navTabsHead}>
                                    <React.Fragment>
                                        {this.props.loggedIn ? null
                                            : <NavItem active={this.state.tabId === 'login'} onClick={() => this.setTabId('login')}>
                                                <NavLink tag={Link} className="" to="/login">{t('LogIn')}</NavLink>
                                            </NavItem>
                                        }
                                    </React.Fragment>
                                </ul>
                            </div>
                    }
                    <hr className="dekra-line-break"></hr>
                </div>
            </header>
        );
    }
}

/*const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.myPage;
}*/

const mapStateToProps = (state: any) => ({
    currentRoles: state.login.currentRoles,
    loggedIn: state.login.loggedIn,
    currentUser: state.login.currentUser,
    activeTabId: state.login.activeTabId,
    
})

export default connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    LoginStore.actionCreators // Selects which action creators are merged into the component's props
)(NavMenu as any);

/*export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    LoginStore.actionCreators // Selects which action creators are merged into the component's props
)(NavMenu as any);*/