﻿export class ImpactTestingResultsTableRow {
    acceptance: string;
    absorbedEnergy?: string;
    absorbedEnergy1?: string;
    absorbedEnergy2?: string;
    average: string;
    lateralExpansion?: string;
    nominalEnergy?: string; // Todo delete
    shear?: string;
    testSpecimenDimensions: string;
    testSpecimenNumber: string;
    testSpecimenType: string;

    public constructor(testSpecimenNumber: string, testSpecimenType: string, testSpecimenDimensions: string, nominalEnergy: string, absorbedEnergy: string, lateralExpansion: string, shear: string, absorbedEnergy1: string, absorbedEnergy2: string, acceptance: string, average: string) {
        this.testSpecimenNumber = testSpecimenNumber;
        this.testSpecimenType = testSpecimenType;
        this.testSpecimenDimensions = testSpecimenDimensions;
        this.nominalEnergy = nominalEnergy;
        this.absorbedEnergy = absorbedEnergy;
        this.absorbedEnergy1 = absorbedEnergy1;
        this.absorbedEnergy2 = absorbedEnergy2;
        this.lateralExpansion = lateralExpansion;
        this.shear = shear;
        this.acceptance = acceptance;
        this.average = average;
    }
}