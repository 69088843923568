﻿export class MicroTestingResultsTableRow {
    acceptance: string;
    examinationResult: string;
    testSpecimenNumber: string;

    public constructor(testSpecimenNumber: string, examinationResult: string, acceptance: string) {
        this.testSpecimenNumber = testSpecimenNumber;
        this.examinationResult = examinationResult;
        this.acceptance = acceptance;
    }
}