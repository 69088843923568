﻿import { AppThunkAction } from './index';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { ApplicationStatus } from './models/ApplicationStatus';
import { BendTesting } from './models/BendTesting';
import { BendTestingResults } from './models/BendTestingResults';
import { BendTestingResultsTableRow } from './models/BendTestingResultsTableRow';
import { ChemicalTesting } from './models/ChemicalTesting';
import { ChemicalTestingResults } from './models/ChemicalTestingResults';
import { ChemicalTestingResultsTableRow } from './models/ChemicalTestingResultsTableRow';
import { CorrosionTesting } from './models/CorrosionTesting';
import { CorrosionTestingResults } from './models/CorrosionTestingResults';
import { CorrosionTestingResultsTableRow } from './models/CorrosionTestingResultsTableRow';
import { CruciformTensileTesting } from './models/CruciformTensileTesting';
import { CruciformTensileTestingResults } from './models/CruciformTensileTestingResults';
import { CruciformTensileTestingResultsTableRow } from './models/CruciformTensileTestingResultsTableRow';
import { FerriteTesting } from './models/FerriteTesting';
import { FerriteTestingResults } from './models/FerriteTestingResults';
import { FerriteTestingResultsTableRow } from './models/FerriteTestingResultsTableRow';
import { FractureTesting } from './models/FractureTesting';
import { FractureTestingResults } from './models/FractureTestingResults';
import { FractureTestingResultsTableRow } from './models/FractureTestingResultsTableRow';
import { HardnessTesting } from './models/HardnessTesting';
import { HardnessTestingResults } from './models/HardnessTestingResults';
import { HardnessTestingResultsTableRow } from './models/HardnessTestingResultsTableRow';
import { ImpactTesting } from './models/ImpactTesting';
import { ImpactTestingResults } from './models/ImpactTestingResults';
import { ImpactTestingResultsTableRow } from './models/ImpactTestingResultsTableRow';
import { MacroTesting } from './models/MacroTesting';
import { MacroTestingResults } from './models/MacroTestingResults';
import { MacroTestingResultsTableRow } from './models/MacroTestingResultsTableRow';
import { MicroTesting } from './models/MicroTesting';
import { MicroTestingResults } from './models/MicroTestingResults';
import { MicroTestingResultsTableRow } from './models/MicroTestingResultsTableRow';
import { ProductType } from './models/ProductType';
import { RangeOfQualifications } from './models/RangeOfQualifications';
import { UpdateApplicationStatusRequest } from './models/requests/UpdateApplicationStatusRequest';
import { GetApplicationResponse } from './models/responses/GetApplicationResponse';
import { GetByIdWpqrResponse } from './models/responses/GetByIdWpqrResponse';
import { GetArcTypeResponse } from './models/responses/GetArcTypeResponse';
import { GetBaseMaterialResponse } from './models/responses/GetBaseMaterialResponse';
import { GetCurrentTypeRangeOfQualificationResponse } from './models/responses/GetCurrentTypeRangeOfQualificationResponse';
import { GetCurrentTypeResponse } from './models/responses/GetCurrentTypeResponse';
import { GetDesignationRangeOfQualificationResponse } from './models/responses/GetDesignationRangeOfQualificationResponse';
import { GetDiameterRangeOfQualificationResponse } from './models/responses/GetDiameterRangeOfQualificationResponse';
import { GetDropletTransitionRangeOfQualificationResponse } from './models/responses/GetDropletTransitionRangeOfQualificationResponse';
import { GetInterpassTemperatureRangeOfQualificationResponse } from './models/responses/GetInterpassTemperatureRangeOfQualificationResponse';
import { GetJointTypeRangeOfQualificationResponse } from './models/responses/GetJointTypeRangeOfQualificationResponse';
import { GetJointTypeResponse } from './models/responses/GetJointTypeResponse';
import { GetMakeRangeOfQualificationResponse } from './models/responses/GetMakeRangeOfQualificationResponse';
import { GetMakeResponse } from './models/responses/GetMakeResponse';
import { GetMaterialGroupRangeOfQualificationResponse } from './models/responses/GetMaterialGroupRangeOfQualificationResponse';
import { GetMaterialThicknessRangeOfQualificationResponse } from './models/responses/GetMaterialThicknessRangeOfQualificationResponse';
import { GetPreheatTemperatureRangeOfQualificationResponse } from './models/responses/GetPreheatTemperatureRangeOfQualificationResponse';
import { GetRootProtectionGasRangeOfQualificationResponse } from './models/responses/GetRootProtectionGasRangeOfQualificationResponse';
import { GetRootProtectionGasResponse } from './models/responses/GetRootProtectionGasResponse';
import { GetRootSupportDetailsResponse } from './models/responses/GetRootSupportDetailsResponse';
import { GetRunTypeRangeOfQualificationResponse } from './models/responses/GetRunTypeRangeOfQualificationResponse';
import { GetShieldingGasRangeOfQualificationResponse } from './models/responses/GetShieldingGasRangeOfQualificationResponse';
import { GetShieldingGasResponse } from './models/responses/GetShieldingGasResponse';
import { GetThroatThicknessRangeOfQualificationResponse } from './models/responses/GetThroatThicknessRangeOfQualificationResponse';
import { GetWeldingMetalThicknessRangeOfQualificationResponse } from './models/responses/GetWeldingMetalThicknessRangeOfQualificationResponse';
import { GetWeldingMethodRangeOfQualificationResponse } from './models/responses/GetWeldingMethodRangeOfQualificationResponse';
import { GetWeldingMethodResponse } from './models/responses/GetWeldingMethodResponse';
import { GetWeldingPositionResponse } from './models/responses/GetWeldingPositionResponse';
import { UpdateApplicationStatusResponse } from './models/responses/UpdateApplicationStatusResponse';
import { RunType } from './models/RunType';
import { SortOrder } from './models/SortOrder';
import HelpFunctions from './models/HelpFunctions';
import { TensileTesting } from './models/TensileTesting';
import { TensileTestingResults } from './models/TensileTestingResults';
import { TensileTestingResultsTableRow } from './models/TensileTestingResultsTableRow';
import { TransverseTensileTesting } from './models/TransverseTensileTesting';
import { TransverseTensileTestingResults } from './models/TransverseTensileTestingResults';
import { TransverseTensileTestingResultsTableRow } from './models/TransverseTensileTestingResultsTableRow';
import { WeldingParameters } from './models/WeldingParameters';
import { WeldingProtocol } from './models/WeldingProtocol';
import { WeldingProtocolSection1 } from './models/WeldingProtocolSection1';
import { WeldingProtocolSection2 } from './models/WeldingProtocolSection2';
import { WeldingProtocolSection3 } from './models/WeldingProtocolSection3';
import { WeldingProtocolSection4 } from './models/WeldingProtocolSection4';
import { WeldingProtocolSection6 } from './models/WeldingProtocolSection6';
import { WeldingProtocolSection7 } from './models/WeldingProtocolSection7';
import { WeldingTestingDetails1 } from './models/WeldingTestingDetails1';
import { WPQTReport } from './models/WPQTReport';
import { WPQTReportPerformedTestsTableRows } from './models/WPQTReportPerformedTestsTableRow';
import { GetMessageResponse } from './models/responses/GetMessageResponse';
import { CreateWeldingParametersRequest } from './models/requests/CreateWeldingParametersRequest';
import { WpqrRequest } from './models/requests/WpqrRequest';
import { CreateTestingResultsTableRowRequest } from './models/requests/CreateTestingResultsTableRowRequest';
import { CreateHardnessTestingResultsTableRowRequest } from './models/requests/CreateHardnessTestingResultsTableRowRequest';
import { WpqrResponse } from './models/responses/WpqrResponse';
import { QWBPOrder } from './models/QWBPOrder';
import authHeader from './models/auth-header';
import EventBus from './eventBus';
import { UpdateFilesUploadRequest } from './models/requests/UpdateFilesUploadRequest';
import { UpdateFilesRequest } from './models/requests/UpdateFilesRequest';
import { UpdateFilesResponse } from './models/responses/UpdateFilesResponse';
import { GetMaterialGroupResponse } from './models/responses/GetMaterialGroupResponse';
import { SendToCustomerRequest } from './models/requests/SendToCustomerRequest';
import { UserListObject } from './UserListObject';
import { GetStandardResponse } from './models/responses/GetStandardResponse';
import { GetTestingDetails1ExaminationResponse } from './models/responses/GetTestingDetails1ExaminationResponse';
import { ExistingWpqrDataExtra } from './models/ExistingWpqrDataExtra';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WPQRDEKRATabStoreState {
    applications: Array<GetApplicationResponse>;
    applicationsIsOpen: boolean;
    bendTesting: BendTesting;
    bendTestingResults: BendTestingResults;
    chemicalTesting: ChemicalTesting;
    chemicalTestingResults: ChemicalTestingResults;
    corrosionTesting: CorrosionTesting;
    corrosionTestingResults: CorrosionTestingResults;
    cruciformTensileTesting: CruciformTensileTesting;
    cruciformTensileTestingResults: CruciformTensileTestingResults;
    ferriteTesting: FerriteTesting;
    ferriteTestingResults: FerriteTestingResults;
    fractureTesting: FractureTesting;
    fractureTestingResults: FractureTestingResults;
    hardnessTesting: HardnessTesting;
    hardnessTestingResults: HardnessTestingResults;
    impactTesting: ImpactTesting;
    impactTestingResults: ImpactTestingResults;
    loadingApplication: boolean;
    loadingWpqr:boolean
    macroTesting: MacroTesting;
    macroTestingResults: MacroTestingResults;
    microTesting: MicroTesting;
    microTestingResults: MicroTestingResults;
    rangeOfQualifications: RangeOfQualifications;
    selectedApplication: GetApplicationResponse;
    selectedWpqr: GetByIdWpqrResponse; 
    selectedExaminationWPQRTabId: string;
    selectedOngoingWPQRTabId: string;
    selectedTestResultsTabId: string;
    selectedWPQRTabId: string;
    tensileTesting: TensileTesting;
    tensileTestingResults: TensileTestingResults;
    transverseTensileTesting: TransverseTensileTesting;
    transverseTensileTestingResults: TransverseTensileTestingResults;
    weldingProtocol: WeldingProtocol;
    weldingTestingDetails1: WeldingTestingDetails1;
    wpqtReport: WPQTReport;

    weldingMethodRangeOfQualifications: Array<string>;
    jointTypeRangeOfQualification: string;
    materialGroupRangeOfQualification: string;
    materialThicknessRangeOfQualifications: Array<string>;
    weldingMetalThicknessRangeOfQualification: string;
    throatThicknessRangeOfQualification: string;
    runTypeRangeOfQualification: string;
    diameterRangeOfQualification: string;
    designationRangeOfQualifications: Array<string>;
    makeRangeOfQualifications: Array<string>;
    shieldingGasRangeOfQualifications: Array<string>;
    rootProtectionGasRangeOfQualification: string;
    currentTypeRangeOfQualifications: Array<string>;
    dropletTransitionRangeOfQualifications: Array<string>;
    postWeldHeatTreatmentRangeOfQualification: string;
    preheatTemperatureRangeOfQualification: string;
    interpassTemperatureRangeOfQualification: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface AddBendTestingResultsTableRow {
    type: 'ADD_BEND_TESTING_RESULTS_TABLE_ROW';
    bendTestingResults: BendTestingResults;
}

interface GetApplicationRequestAction {
    type: 'GET_APPLICATION_REQUEST';
}

interface GetApplicationsReceiveAction {
    type: 'GET_APPLICATIONS_RECEIVE';
    applications: Array<GetApplicationResponse>;
}

interface GetApplicationsRequestAction {
    type: 'GET_APPLICATIONS_REQUEST';
}

interface GetMessagesRequestAction {
    type: 'GET_MESSAGES_REQUEST';
}

interface GetBaseMaterial1SearchResultRequestAction {
    type: 'GET_BASE_MATERIAL_1_SEARCH_RESULT_REQUEST';
}

interface GetBaseMaterial2SearchResultRequestAction {
    type: 'GET_BASE_MATERIAL_2_SEARCH_RESULT_REQUEST';
}

interface GetCurrentTypeReceiveAction {
    type: 'GET_CURRENT_TYPES_RECEIVE';
    currentTypes: Array<GetCurrentTypeResponse>;
}

interface GetCurrentTypeRequestAction {
    type: 'GET_CURRENT_TYPES_REQUEST';
}

interface GetDropletTransitionsAllRequestAction {
    type: 'GET_DROPLET_TRANSITIONS_REQUEST';
}

interface GetJointTypesRequestAction {
    type: 'GET_JOINT_TYPES_REQUEST';
}

interface GetMakeASearchResultRequestAction {
    type: 'GET_MAKE_A_SEARCH_RESULT_REQUEST';
}

interface GetMakeBSearchResultRequestAction {
    type: 'GET_MAKE_B_SEARCH_RESULT_REQUEST';
}

interface GetProductTypesRequestAction {
    type: 'GET_PRODUCT_TYPES_REQUEST';
}

interface GetRootProtectionGasSearchResultRequestAction {
    type: 'GET_ROOT_PROTECTION_GAS_SEARCH_RESULT_REQUEST';
}

interface GetRootSupportDetailsSearchResultRequestAction {
    type: 'GET_ROOT_SUPPORT_DETAILS_SEARCH_RESULT_REQUEST';
}

interface GetShieldingGas1SearchResultRequestAction {
    type: 'GET_SHIELDING_GAS_1_SEARCH_RESULT_REQUEST';
}

interface GetShieldingGas2SearchResultRequestAction {
    type: 'GET_SHIELDING_GAS_2_SEARCH_RESULT_REQUEST';
}

interface GetWeldingMethodASearchResultRequestAction {
    type: 'GET_WELDING_METHOD_A_SEARCH_RESULT_REQUEST';
}

interface GetWeldingMethodBSearchResultRequestAction {
    type: 'GET_WELDING_METHOD_B_SEARCH_RESULT_REQUEST';
}

interface GetWeldingPositionSearchResultRequestAction {
    type: 'GET_WELDING_POSITION_SEARCH_RESULT_REQUEST';
}

interface InitAction {
    type: 'INIT_DEKRA';
}

interface InitBendTestingAction {
    type: 'INIT_BEND_TESTING';
    bendTesting: BendTesting;
    bendTestingResults: BendTestingResults;
}

interface InitChemicalTestingAction {
    type: 'INIT_CHEMICAL_TESTING';
    chemicalTesting: ChemicalTesting;
    chemicalTestingResults: ChemicalTestingResults;
}

interface InitCorrosionTestingAction {
    type: 'INIT_CORROSION_TESTING';
    corrosionTesting: CorrosionTesting;
    corrosionTestingResults: CorrosionTestingResults;
}

interface InitCruciformTensileTestingAction {
    type: 'INIT_CRUCIFORM_TENSILE_TESTING';
    cruciformTensileTesting: CruciformTensileTesting;
    cruciformTensileTestingResults: CruciformTensileTestingResults;
}

interface InitFerriteTestingAction {
    type: 'INIT_FERRITE_TESTING';
    ferriteTesting: FerriteTesting;
    ferriteTestingResults: FerriteTestingResults;
}

interface InitFractureTestingAction {
    type: 'INIT_FRACTURE_TESTING';
    fractureTesting: FractureTesting;
    fractureTestingResults: FractureTestingResults;
}

interface InitHardnessTestingAction {
    type: 'INIT_HARDNESS_TESTING';
    hardnessTesting: HardnessTesting;
    hardnessTestingResults: HardnessTestingResults;
}

interface InitImpactTestingAction {
    type: 'INIT_IMPACT_TESTING';
    impactTesting: ImpactTesting;
    impactTestingResults: ImpactTestingResults;
}

interface InitMacroTestingAction {
    type: 'INIT_MACRO_TESTING';
    macroTesting: MacroTesting;
    macroTestingResults: MacroTestingResults;
}

interface InitMicroTestingAction {
    type: 'INIT_MICRO_TESTING';
    microTesting: MicroTesting;
    microTestingResults: MicroTestingResults;
}

interface InitRangeOfQualificationAction {
    type: 'INIT_RANGE_OF_QUALIFICATION';
    rangeOfQualifications: RangeOfQualifications;
}

interface InitTensileTestingAction {
    type: 'INIT_TENSILE_TESTING';
    tensileTesting: TensileTesting;
    tensileTestingResults: TensileTestingResults;
}

interface InitTransverseTensileTestingAction {
    type: 'INIT_TRANSVERSE_TENSILE_TESTING';
    transverseTensileTesting: TransverseTensileTesting;
    transverseTensileTestingResults: TransverseTensileTestingResults;
}

interface InitWeldingProtocolAction {
    type: 'INIT_WELDING_PROTOCOL';
    selectedApplication: GetApplicationResponse;
    applicationsIsOpen: boolean;
    weldingProtocol: WeldingProtocol;
}

interface InitWeldingTestingDetails1Action {
    type: 'INIT_WELDING_TESTING_DETAILS1';
    weldingTestingDetails1: WeldingTestingDetails1;
}

interface InitWPQTReportAction {
    type: 'INIT_WPQT_REPORT';
    wpqtReport: WPQTReport;
}

interface SetApplicationsIsOpenAction {
    type: 'SET_APPLICATIONS_IS_OPEN';
    applicationsIsOpen: boolean;
}

interface SetBendTestingAction {
    type: 'SET_BEND_TESTING';
    bendTesting: BendTesting;
}

interface SetBendTestingResultsAction {
    type: 'SET_BEND_TESTING_RESULTS';
    bendTestingResults: BendTestingResults;
}

interface SetChemicalTestingAction {
    type: 'SET_CHEMICAL_TESTING';
    chemicalTesting: ChemicalTesting;
}

interface SetChemicalTestingResultsAction {
    type: 'SET_CHEMICAL_TESTING_RESULTS';
    chemicalTestingResults: ChemicalTestingResults;
}

interface SetCorrosionTestingAction {
    type: 'SET_CORROSION_TESTING';
    corrosionTesting: CorrosionTesting;
}

interface SetCorrosionTestingResultsAction {
    type: 'SET_CORROSION_TESTING_RESULTS';
    corrosionTestingResults: CorrosionTestingResults;
}

interface SetCurrentTypeRangeOfQualificationAction {
    type: 'SET_CURRENT_TYPE_RANGE_OF_QUALIFICATION';
    currentTypeRangeOfQualifications: Array<string>;
}

interface SetCruciformTensileTestingAction {
    type: 'SET_CRUCIFORM_TENSILE_TESTING';
    cruciformTensileTesting: CruciformTensileTesting;
}

interface SetCruciformTensileTestingResultsAction {
    type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS';
    cruciformTensileTestingResults: CruciformTensileTestingResults;
}

interface SetDesignationRangeOfQualificationAction {
    type: 'SET_DESIGNATION_RANGE_OF_QUALIFICATION';
    designationRangeOfQualifications: Array<string>;
}

interface SetDiameterRangeOfQualificationAction {
    type: 'SET_DIAMETER_RANGE_OF_QUALIFICATION';
    diameterRangeOfQualification: string;
}

interface SetDropletTransitionRangeOfQualificationAction {
    type: 'SET_DROPLET_TRANSITION_RANGE_OF_QUALIFICATION';
    dropletTransitionRangeOfQualifications: Array<string>;
}

interface SetFerriteTestingAction {
    type: 'SET_FERRITE_TESTING';
    ferriteTesting: FerriteTesting;
}

interface SetFerriteTestingResultsAction {
    type: 'SET_FERRITE_TESTING_RESULTS';
    ferriteTestingResults: FerriteTestingResults;
}

interface SetFractureTestingAction {
    type: 'SET_FRACTURE_TESTING';
    fractureTesting: FractureTesting;
}

interface SetFractureTestingResultsAction {
    type: 'SET_FRACTURE_TESTING_RESULTS';
    fractureTestingResults: FractureTestingResults;
}

interface SetHardnessTestingAction {
    type: 'SET_HARDNESS_TESTING';
    hardnessTesting: HardnessTesting;
}

interface SetHardnessTestingResultsAction {
    type: 'SET_HARDNESS_TESTING_RESULTS';
    hardnessTestingResults: HardnessTestingResults;
}

interface SetImpactTestingAction {
    type: 'SET_IMPACT_TESTING';
    impactTesting: ImpactTesting;
}

interface SetImpactTestingResultsAction {
    type: 'SET_IMPACT_TESTING_RESULTS';
    impactTestingResults: ImpactTestingResults;
}

interface SetInterpassTemperatureRangeOfQualificationAction {
    type: 'SET_INTERPASS_TEMPERATURE_RANGE_OF_QUALIFICATION';
    interpassTemperatureRangeOfQualification: string;
}

interface SetJointTypeRangeOfQualificationAction {
    type: 'SET_JOINT_TYPE_RANGE_OF_QUALIFICATION';
    jointTypeRangeOfQualification: string;
}

interface SetMacroTestingAction {
    type: 'SET_MACRO_TESTING';
    macroTesting: MacroTesting;
}

interface SetMacroTestingResultsAction {
    type: 'SET_MACRO_TESTING_RESULTS';
    macroTestingResults: MacroTestingResults;
}

interface SetMakeRangeOfQualificationAction {
    type: 'SET_MAKE_RANGE_OF_QUALIFICATION';
    makeRangeOfQualifications: Array<string>;
}


interface SetMaterialGroupRangeOfQualificationAction {
    type: 'SET_MATERIAL_GROUP_RANGE_OF_QUALIFICATION';
    materialGroupRangeOfQualification: string;
}

interface SetMaterialThicknessRangeOfQualificationsAction {
    type: 'SET_MATERIAL_THICKNESS_RANGE_OF_QUALIFICATIONS';
    materialThicknessRangeOfQualifications: Array<string>;
}

interface SetMicroTestingAction {
    type: 'SET_MICRO_TESTING';
    microTesting: MicroTesting;
}

interface SetMicroTestingResultsAction {
    type: 'SET_MICRO_TESTING_RESULTS';
    microTestingResults: MicroTestingResults;
}

interface SetPreheatTemperatureRangeOfQualificationAction {
    type: 'SET_PREHEAT_TEMPERATURE_RANGE_OF_QUALIFICATION';
    preheatTemperatureRangeOfQualification: string;
}

interface SetRootProtectionGasRangeOfQualificationAction {
    type: 'SET_ROOT_PROTECTION_GAS_RANGE_OF_QUALIFICATION';
    rootProtectionGasRangeOfQualification: string;
}

interface SetRunTypeRangeOfQualificationAction {
    type: 'SET_RUN_TYPE_RANGE_OF_QUALIFICATION';
    runTypeRangeOfQualification: string;
}

interface SetSelectedExaminationWPQRTabIdAction {
    type: 'SET_SELECTED_EXAMINATION_WPQR_TAB_ID';
    selectedExaminationWPQRTabId: string;
}

interface SetSelectedOngoingWPQRTabIdAction {
    type: 'SET_SELECTED_ONGOING_WPQR_TAB_ID';
    selectedOngoingWPQRTabId: string;
}

interface SetSelectedTestResultsTabIdAction {
    type: 'SET_SELECTED_TEST_RESULTS_TAB_ID';
    selectedTestResultsTabId: string;
}

interface SetSelectedWPQRTabIdAction {
    type: 'SET_SELECTED_WPQR_TAB_ID';
    selectedWPQRTabId: string;
}

interface SetShieldingGasRangeOfQualificationsAction {
    type: 'SET_SHIELDING_GAS_RANGE_OF_QUALIFICATIONS';
    shieldingGasRangeOfQualifications: Array<string>;
}

interface SetPostWeldHeatTreatmentRangeOfQualificationAction {
    type: 'SET_POST_WELD_HEAT_TREATMENT_RANGE_OF_QUALIFICATION';
    postWeldHeatTreatmentRangeOfQualification: string;
}

interface SetTensileTestingAction {
    type: 'SET_TENSILE_TESTING';
    tensileTesting: TensileTesting;
}

interface SetTensileTestingResultsAction {
    type: 'SET_TENSILE_TESTING_RESULTS';
    tensileTestingResults: TensileTestingResults;
}

interface SetThroatThicknessRangeOfQualificationAction {
    type: 'SET_THROAT_THICKNESS_RANGE_OF_QUALIFICATION';
    throatThicknessRangeOfQualification: string;
}

interface SetTransverseTensileTestingAction {
    type: 'SET_TRANSVERSE_TENSILE_TESTING';
    transverseTensileTesting: TransverseTensileTesting;
}

interface SetTransverseTensileTestingResultsAction {
    type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS';
    transverseTensileTestingResults: TransverseTensileTestingResults;
}

interface SetWeldingMetalthicknessRangeOfQualificationAction {
    type: 'SET_WELDING_METAL_THICKNESS_RANGE_OF_QUALIFICATION';
    weldingMetalThicknessRangeOfQualification: string;
}

interface SetWeldingMethodRangeOfQualificationsAction {
    type: 'SET_WELDING_METHOD_RANGE_OF_QUALIFICATIONS';
    weldingMethodRangeOfQualifications: Array<string>;
}

interface SetWeldingProtocolAction {
    type: 'SET_WELDING_PROTOCOL';
    weldingProtocol: WeldingProtocol;
}

interface SetWpqrAction {
    type: 'SET_WPQR';
    selectedWpqr: GetByIdWpqrResponse;
}

interface SetWeldingProtocolAction {
    type: 'SET_WELDING_PROTOCOL';
    weldingProtocol: WeldingProtocol;
}

interface SetWeldingProtocolSection2Action {
    type: 'SET_WELDING_PROTOCOL_SECTION_2';
    weldingProtocolSection2: WeldingProtocolSection2;
}

interface SetWeldingProtocolSection4Action {
    type: 'SET_WELDING_PROTOCOL_SECTION_4';
    weldingProtocolSection4: WeldingProtocolSection4;
}

interface SetWeldingTestingDetails1Action {
    type: 'SET_WELDING_TESTING_DETAILS1';
    weldingTestingDetails1: WeldingTestingDetails1;
}

interface SetWPQTReportAction {
    type: 'SET_WPQT_REPORT';
    wpqtReport: WPQTReport;
}

interface UpdateApplicationsReceiveAction {
    type: 'UPDATE_APPLICATIONS_RECEIVE';
    applications: Array<GetApplicationResponse>;
}

interface UpdateApplicationsRequestAction {
    type: 'UPDATE_APPLICATIONS_REQUEST';
}

export interface CreateUpdateWeldingProtocolReceiveAction {
    type: "CREATE_UPDATE_WELDING_PROTOCOL_RECEIVE";
    applicationId: number;
}

export interface CreateUpdateWeldingProtocolRequestAction {
    type: "CREATE_UPDATE_WELDING_PROTOCOL_REQUEST";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = AddBendTestingResultsTableRow
    | GetApplicationRequestAction
    | GetApplicationsReceiveAction
    | GetApplicationsRequestAction
    | GetMessagesRequestAction
    | GetBaseMaterial1SearchResultRequestAction
    | GetBaseMaterial2SearchResultRequestAction
    | GetCurrentTypeReceiveAction
    | GetCurrentTypeRequestAction
    | GetDropletTransitionsAllRequestAction
    | GetJointTypesRequestAction
    | GetMakeASearchResultRequestAction
    | GetMakeBSearchResultRequestAction
    | GetProductTypesRequestAction
    | GetRootProtectionGasSearchResultRequestAction
    | GetRootSupportDetailsSearchResultRequestAction
    | GetShieldingGas1SearchResultRequestAction
    | GetShieldingGas2SearchResultRequestAction
    | GetWeldingMethodASearchResultRequestAction
    | GetWeldingMethodBSearchResultRequestAction
    | GetWeldingPositionSearchResultRequestAction
    | InitAction
    | InitBendTestingAction
    | InitChemicalTestingAction
    | InitCorrosionTestingAction
    | InitCruciformTensileTestingAction
    | InitFerriteTestingAction
    | InitFractureTestingAction
    | InitHardnessTestingAction
    | InitImpactTestingAction
    | InitMacroTestingAction
    | InitMicroTestingAction
    | InitRangeOfQualificationAction
    | InitTensileTestingAction
    | InitTransverseTensileTestingAction
    | InitWeldingProtocolAction
    | InitWeldingTestingDetails1Action
    | InitWPQTReportAction
    | SetApplicationsIsOpenAction
    | SetBendTestingAction
    | SetBendTestingResultsAction
    | SetChemicalTestingAction
    | SetChemicalTestingResultsAction
    | SetCorrosionTestingAction
    | SetCorrosionTestingResultsAction
    | SetCurrentTypeRangeOfQualificationAction
    | SetCruciformTensileTestingAction
    | SetCruciformTensileTestingResultsAction
    | SetDesignationRangeOfQualificationAction
    | SetDiameterRangeOfQualificationAction
    | SetDropletTransitionRangeOfQualificationAction
    | SetFerriteTestingAction
    | SetFerriteTestingResultsAction
    | SetFractureTestingAction
    | SetFractureTestingResultsAction
    | SetHardnessTestingAction
    | SetHardnessTestingResultsAction
    | SetImpactTestingAction
    | SetImpactTestingResultsAction
    | SetInterpassTemperatureRangeOfQualificationAction
    | SetJointTypeRangeOfQualificationAction
    | SetMacroTestingAction
    | SetMacroTestingResultsAction
    | SetMakeRangeOfQualificationAction
    | SetMaterialGroupRangeOfQualificationAction
    | SetMaterialThicknessRangeOfQualificationsAction
    | SetMicroTestingAction
    | SetMicroTestingResultsAction
    | SetPreheatTemperatureRangeOfQualificationAction
    | SetRootProtectionGasRangeOfQualificationAction
    | SetRunTypeRangeOfQualificationAction
    | SetSelectedExaminationWPQRTabIdAction
    | SetSelectedOngoingWPQRTabIdAction
    | SetSelectedTestResultsTabIdAction
    | SetSelectedWPQRTabIdAction
    | SetShieldingGasRangeOfQualificationsAction
    | SetPostWeldHeatTreatmentRangeOfQualificationAction
    | SetTensileTestingAction
    | SetTensileTestingResultsAction
    | SetThroatThicknessRangeOfQualificationAction
    | SetTransverseTensileTestingAction
    | SetTransverseTensileTestingResultsAction
    | SetWeldingMetalthicknessRangeOfQualificationAction
    | SetWeldingMethodRangeOfQualificationsAction
    | SetWeldingProtocolAction
    | SetWeldingProtocolSection2Action
    | SetWeldingProtocolSection4Action
    | SetWeldingTestingDetails1Action
    | SetWPQTReportAction
    | UpdateApplicationsReceiveAction
    | UpdateApplicationsRequestAction
    | CreateUpdateWeldingProtocolReceiveAction
    | CreateUpdateWeldingProtocolRequestAction
    | SetWpqrAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    addBendTestingResultsTableRow: (acceptance: string, bendingAngle: string, diameterOfRoller: string, distanceBetweenRollers: string, surfaceCondition: string, testSpecimenDimensions: string, testSpecimenNumber: string, testSpecimenType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            let bendTestingResultsTableRow = new BendTestingResultsTableRow(acceptance, bendingAngle, diameterOfRoller, distanceBetweenRollers, surfaceCondition, testSpecimenDimensions, testSpecimenNumber, testSpecimenType);
            bendTestingResults.bendTestingResultsTableRows.splice(bendTestingResults.bendTestingResultsTableRows.length, 0, bendTestingResultsTableRow);
            dispatch({ type: 'ADD_BEND_TESTING_RESULTS_TABLE_ROW', bendTestingResults: bendTestingResults });
        }
    },
    clearWeldingProtocolBaseMaterial1SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.baseMaterial1SearchResult = [];
            // Reset selected Base Material 1.
            weldingProtocolSection3.selectedBaseMaterial1.id = 0;
            weldingProtocolSection3.selectedBaseMaterial1.name = '';
            weldingProtocolSection3.selectedBaseMaterial1.number = '';
            weldingProtocolSection3.selectedBaseMaterial1.description = '';
            weldingProtocolSection3.selectedBaseMaterial1.materialGroup = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            weldingProtocolSection3.selectedBaseMaterial1.technicalDeliveryConditions = [];
            weldingProtocolSection3.selectedBaseMaterial1.createdAt = '';
            weldingProtocolSection3.selectedBaseMaterial1.deletedAt = '';
            weldingProtocolSection3.selectedBaseMaterial1.updatedAt = '';
            // Reset selected Material Group part 1.
            weldingProtocolSection3.selectedMaterialGroup1.id = 0;
            weldingProtocolSection3.selectedMaterialGroup1.name = '';
            weldingProtocolSection3.selectedMaterialGroup1.description = '';
            weldingProtocolSection3.selectedMaterialGroup1.createdAt = '';
            weldingProtocolSection3.selectedMaterialGroup1.deletedAt = '';
            weldingProtocolSection3.selectedMaterialGroup1.updatedAt = '';
            // Reset selected TechnicalDeliveryCondition part 1.
            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.id = 0;
            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.name = '';
            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.description = '';
            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.createdAt = '';
            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.deletedAt = '';
            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.updatedAt = '';
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    clearWeldingProtocolBaseMaterial2SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.baseMaterial2SearchResult = [];
            // Reset selected Base Material 2.
            weldingProtocolSection3.selectedBaseMaterial2.id = 0;
            weldingProtocolSection3.selectedBaseMaterial2.name = '';
            weldingProtocolSection3.selectedBaseMaterial2.number = '';
            weldingProtocolSection3.selectedBaseMaterial2.description = '';
            weldingProtocolSection3.selectedBaseMaterial2.materialGroup = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            weldingProtocolSection3.selectedBaseMaterial2.technicalDeliveryConditions = [];
            weldingProtocolSection3.selectedBaseMaterial2.createdAt = '';
            weldingProtocolSection3.selectedBaseMaterial2.deletedAt = '';
            weldingProtocolSection3.selectedBaseMaterial2.updatedAt = '';
            // Reset selected Material Group part 2.
            weldingProtocolSection3.selectedMaterialGroup2.id = 0;
            weldingProtocolSection3.selectedMaterialGroup2.name = '';
            weldingProtocolSection3.selectedMaterialGroup2.description = '';
            weldingProtocolSection3.selectedMaterialGroup2.createdAt = '';
            weldingProtocolSection3.selectedMaterialGroup2.deletedAt = '';
            weldingProtocolSection3.selectedMaterialGroup2.updatedAt = '';
            // Reset selected TechnicalDeliveryCondition part 2.
            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.id = 0;
            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.name = '';
            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.description = '';
            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.createdAt = '';
            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.deletedAt = '';
            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.updatedAt = '';
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setParentMaterialTechnicalDeliveryCondition2Text: (technicalDeliveryCondition2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.technicalDeliveryCondition2Text = technicalDeliveryCondition2Text;
            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.name = technicalDeliveryCondition2Text;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setParentMaterialTechnicalDeliveryCondition1Text: (technicalDeliveryCondition1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.technicalDeliveryCondition1Text = technicalDeliveryCondition1Text;
            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.name = technicalDeliveryCondition1Text;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setParentMaterialBaseMaterial2Text: (baseMaterial2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.baseMaterial2Text = baseMaterial2Text;
            weldingProtocolSection3.baseMaterial2SearchText = baseMaterial2Text;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setParentMaterialBaseMaterial1Text: (baseMaterial1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.baseMaterial1Text = baseMaterial1Text;
            weldingProtocolSection3.baseMaterial1SearchText = baseMaterial1Text;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setEnableBaseMaterial2Text: (enableBaseMaterial2Text: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.enableBaseMaterial2Text = enableBaseMaterial2Text;
            weldingProtocolSection3.baseMaterial2Text = weldingProtocolSection3.baseMaterial2SearchText;
            weldingProtocolSection3.materialGroup2Text = weldingProtocolSection3.selectedMaterialGroup2.name;
            weldingProtocolSection3.technicalDeliveryCondition2Text = weldingProtocolSection3.selectedTechnicalDeliveryCondition2.name;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setEnableBaseMaterial1Text: (enableBaseMaterial1Text: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.enableBaseMaterial1Text = enableBaseMaterial1Text;
            weldingProtocolSection3.baseMaterial1Text = weldingProtocolSection3.baseMaterial1SearchText;
            weldingProtocolSection3.materialGroup1Text = weldingProtocolSection3.selectedMaterialGroup1.name;
            weldingProtocolSection3.technicalDeliveryCondition1Text = weldingProtocolSection3.selectedTechnicalDeliveryCondition1.name;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setParentMaterialMaterialGroup2Text: (materialGroup2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.materialGroup2Text = materialGroup2Text;
            let materialGroupExist: Boolean = false;
            for (var i = 0; i < weldingProtocolSection3.materialGroups.length; i++) {
                if (materialGroup2Text === weldingProtocolSection3.materialGroups[i].name) {
                    weldingProtocolSection3.selectedMaterialGroup2 = { ...weldingProtocolSection3.materialGroups[i] };
                    materialGroupExist = true;
                    break;
                }
            }
            if (!materialGroupExist) {
                weldingProtocolSection3.selectedMaterialGroup2 = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setParentMaterialMaterialGroup1Text: (materialGroup1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.materialGroup1Text = materialGroup1Text;
            let materialGroupExist: Boolean = false;
            for (var i = 0; i < weldingProtocolSection3.materialGroups.length; i++) {
                if (materialGroup1Text === weldingProtocolSection3.materialGroups[i].name) {
                    weldingProtocolSection3.selectedMaterialGroup1 = { ...weldingProtocolSection3.materialGroups[i] };
                    materialGroupExist = true;
                    break;
                }
            }
            if (!materialGroupExist) {
                weldingProtocolSection3.selectedMaterialGroup1 = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    clearWeldingProtocolMakeASearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.make1SearchResult = [];
            // Reset selected Designation A.
            weldingProtocolSection2.selectedDesignation1.id = 0;
            weldingProtocolSection2.selectedDesignation1.name = '';
            weldingProtocolSection2.selectedDesignation1.description = '';

            let selectedMake1: GetMakeResponse = new GetMakeResponse();
            weldingProtocolSection2.selectedMake1 = { ...selectedMake1 };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    clearWeldingProtocolMakeBSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.make2SearchResult = [];
            // Reset selected Designation B.
            weldingProtocolSection2.selectedDesignation2.id = 0;
            weldingProtocolSection2.selectedDesignation2.name = '';
            weldingProtocolSection2.selectedDesignation2.description = '';

            let selectedMake2: GetMakeResponse = new GetMakeResponse();
            weldingProtocolSection2.selectedMake2 = { ...selectedMake2 };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },//TOD
    addMessageInWeldingProtocolMessages: (applicationId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Messages/GetAllByApplicationId/${applicationId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<Array<GetMessageResponse>>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.wpqrDekraTab) {
                    let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                    weldingProtocol.weldingProtocolMessages = [...data];
                    dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MESSAGES_REQUEST' });
    },
    initMessagesInWeldingProtocolResult: (messageActive: GetMessageResponse, action: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            if (action === "Add") {
                weldingProtocol.weldingProtocolMessages.push(messageActive);
            } else {
                //remove
                let removeIndex = weldingProtocol.weldingProtocolMessages.findIndex(weldingMethod1 => weldingMethod1.messageCode === messageActive.messageCode);
                if (removeIndex !== -1) {
                    weldingProtocol.weldingProtocolMessages.splice(removeIndex, 1);
                }
            }
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    initWeldingProtocolRevisionWpqrAction: (RevisionWpqrAction: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.revisionWpqrAction = RevisionWpqrAction;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    clearWeldingProtocolRootProtectionGasSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.rootProtectionGasSearchResult = [];
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    clearWeldingProtocolShieldingGas1SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.shieldingGas1SearchResult = [];
            weldingProtocolSection4.selectedShieldingGas1 = {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    clearWeldingProtocolShieldingGas2SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.shieldingGas2SearchResult = [];
            weldingProtocolSection4.selectedShieldingGas2 = {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    clearWeldingProtocolWeldingMethod1SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.weldingMethod1SearchResult = [];
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    clearWeldingProtocolWeldingMethod2SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.weldingMethod2SearchResult = [];
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    removeApplication: (applicationId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let applications: Array<GetApplicationResponse> = [...appState.wpqrDekraTab.applications];
            for (let i = 0; i < applications.length; i++) {
                if (applicationId === applications[i].id) {
                    applications.splice(i, 1);
                    break;
                }
            }
            dispatch({ type: 'UPDATE_APPLICATIONS_RECEIVE', applications: applications });
        }
    },
    deleteApplication: (applicationId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let updateApplicationStatusRequest: UpdateApplicationStatusRequest = {
            id: applicationId,
            applicationStatus: ApplicationStatus.Deleted
        };
        fetch(`api/Applications/UpdateApplicationStatus`, {
            method: 'PUT',
            headers: authHeader.authHeader(),
            body: JSON.stringify(updateApplicationStatusRequest)
        })
            .then(response => response.json() as Promise<UpdateApplicationStatusResponse>)
            .then(data => {
                console.info(data);
                const appState = getState();
                // Just update state from the request object instead of re-getting from db.
                if (appState && appState.wpqrDekraTab) {
                    let applications: Array<GetApplicationResponse> = [...appState.wpqrDekraTab.applications];
                    for (let i = 0; i < applications.length; i++) {
                        if (applicationId === applications[i].id) {
                            applications.splice(i, 1);
                            break;
                        }
                    }
                    dispatch({ type: 'UPDATE_APPLICATIONS_RECEIVE', applications: applications });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'UPDATE_APPLICATIONS_REQUEST' });
    },
    getApplicationsByApplicationStatus: ( applicationStatus: number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Applications/GetByApplicationStatusInclude/${applicationStatus}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<Array<GetApplicationResponse>>)
            .then(data => {
                console.info(data);
                dispatch({ type: 'GET_APPLICATIONS_RECEIVE', applications: data });
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_APPLICATIONS_REQUEST' });
    },
    getBaseMaterial1SearchResult: (nameOrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/BaseMaterials/GetByStartsWithCaseInsensitiveNameOrNumberInclude/${nameOrNumber}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetBaseMaterialResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
                        weldingProtocolSection3.baseMaterial1SearchResult = [...data];
                        if (data.length === 0) {
                            // Reset selected Base Material 1.
                            weldingProtocolSection3.selectedBaseMaterial1.id = 0;
                            weldingProtocolSection3.selectedBaseMaterial1.name = '';
                            weldingProtocolSection3.selectedBaseMaterial1.number = '';
                            weldingProtocolSection3.selectedBaseMaterial1.description = '';
                            weldingProtocolSection3.selectedBaseMaterial1.materialGroup = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            weldingProtocolSection3.selectedBaseMaterial1.technicalDeliveryConditions = [];
                            weldingProtocolSection3.selectedBaseMaterial1.createdAt = '';
                            weldingProtocolSection3.selectedBaseMaterial1.deletedAt = '';
                            weldingProtocolSection3.selectedBaseMaterial1.updatedAt = '';
                            // Reset selected Material Group part 1.
                            weldingProtocolSection3.selectedMaterialGroup1.id = 0;
                            weldingProtocolSection3.selectedMaterialGroup1.name = '';
                            weldingProtocolSection3.selectedMaterialGroup1.description = '';
                            weldingProtocolSection3.selectedMaterialGroup1.createdAt = '';
                            weldingProtocolSection3.selectedMaterialGroup1.deletedAt = '';
                            weldingProtocolSection3.selectedMaterialGroup1.updatedAt = '';
                            // Reset selected TechnicalDeliveryCondition part 1.
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.id = 0;
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.name = '';
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.description = '';
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.createdAt = '';
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.deletedAt = '';
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition1.updatedAt = '';
                        }
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_BASE_MATERIAL_1_SEARCH_RESULT_REQUEST' });
    },
    getBaseMaterial2SearchResult: (nameOrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/BaseMaterials/GetByStartsWithCaseInsensitiveNameOrNumberInclude/${nameOrNumber}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetBaseMaterialResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
                        weldingProtocolSection3.baseMaterial2SearchResult = [...data];
                        if (data.length === 0) {
                            // Reset selected Base Material 2.
                            weldingProtocolSection3.selectedBaseMaterial2.id = 0;
                            weldingProtocolSection3.selectedBaseMaterial2.name = '';
                            weldingProtocolSection3.selectedBaseMaterial2.number = '';
                            weldingProtocolSection3.selectedBaseMaterial2.description = '';
                            weldingProtocolSection3.selectedBaseMaterial2.materialGroup = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            weldingProtocolSection3.selectedBaseMaterial2.technicalDeliveryConditions = [];
                            weldingProtocolSection3.selectedBaseMaterial2.createdAt = '';
                            weldingProtocolSection3.selectedBaseMaterial2.deletedAt = '';
                            weldingProtocolSection3.selectedBaseMaterial2.updatedAt = '';
                            // Reset selected Material Group part 2.
                            weldingProtocolSection3.selectedMaterialGroup2.id = 0;
                            weldingProtocolSection3.selectedMaterialGroup2.name = '';
                            weldingProtocolSection3.selectedMaterialGroup2.description = '';
                            weldingProtocolSection3.selectedMaterialGroup2.createdAt = '';
                            weldingProtocolSection3.selectedMaterialGroup2.deletedAt = '';
                            weldingProtocolSection3.selectedMaterialGroup2.updatedAt = '';
                            // Reset selected TechnicalDeliveryCondition part 2.
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.id = 0;
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.name = '';
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.description = '';
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.createdAt = '';
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.deletedAt = '';
                            weldingProtocolSection3.selectedTechnicalDeliveryCondition2.updatedAt = '';
                        }
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_BASE_MATERIAL_1_SEARCH_RESULT_REQUEST' });
    },
    getStandardsAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Standards/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<Array<GetStandardResponse>>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.wpqrDekraTab) {
                    let standards: Array<GetStandardResponse> = { ...appState.wpqrDekraTab.weldingProtocol.standards };
                    standards = [...data];
                    let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                    weldingProtocol.standards = standards;
                    dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                }
            })
            .catch(error => {
                console.error(error);
            });
    },
    receiveUserList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
            fetch(`/api/User/GetAllOrderBy/firstname`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
                        weldingTestingDetails1.users = res.data;
                        dispatch({ type: 'INIT_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
                    }
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
    },
    getCurrentTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/CurrentTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetCurrentTypeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
                        let currentTypes = [...data];
                        let currentTypeEmpty = new GetCurrentTypeResponse()
                        currentTypes.splice(currentTypes.length, 0, currentTypeEmpty);
                        currentTypes.sort((a, b) => a.id - b.id);
                        weldingProtocolSection6.currentTypes = currentTypes;
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_CURRENT_TYPES_REQUEST' });
    },
    getDropletTransitionsAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ArcTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetArcTypeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
                        weldingProtocolSection6.dropletTransitions = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_DROPLET_TRANSITIONS_REQUEST' });
    },
    getJointTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/JointTypes/GetAllInclude`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetJointTypeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
                        weldingProtocolSection2.jointTypes = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))

            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_JOINT_TYPES_REQUEST' });
    },
    getMakeASearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Makes/GetByStartsWithCaseInsensitiveNameInclude/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetMakeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
                        weldingProtocolSection2.make1SearchResult = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MAKE_A_SEARCH_RESULT_REQUEST' });
    },
    getMakeBSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Makes/GetByStartsWithCaseInsensitiveNameInclude/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetMakeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
                        weldingProtocolSection2.make2SearchResult = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MAKE_B_SEARCH_RESULT_REQUEST' });
    },
    getProductTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ProductTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetJointTypeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
                        weldingProtocolSection3.productTypes = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_PRODUCT_TYPES_REQUEST' });
    },
    getMaterialGroupAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/MaterialGroups/GetAll`)
            .then(response => response.json() as Promise<Array<GetMaterialGroupResponse>>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.wpqrDekraTab) {
                    let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
                    weldingProtocolSection3.materialGroups = [...data];
                    let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                    weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
                    dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_PRODUCT_TYPES_REQUEST' });
    },
    getWeldingProtocolRootProtectionGasSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RootProtectionGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetRootProtectionGasResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
                        weldingProtocolSection4.rootProtectionGasSearchResult = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_ROOT_PROTECTION_GAS_SEARCH_RESULT_REQUEST' });
    },
    getRootSupportDetailsSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RootSupportDetails/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetRootSupportDetailsResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
                        weldingProtocolSection4.rootSupportDetailsSearchResult = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_ROOT_SUPPORT_DETAILS_SEARCH_RESULT_REQUEST' });
    },
    getWeldingProtocolShieldingGas1SearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ShieldingGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetShieldingGasResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
                        weldingProtocolSection4.shieldingGas1SearchResult = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_SHIELDING_GAS_1_SEARCH_RESULT_REQUEST' });
    },
    getWeldingProtocolShieldingGas2SearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ShieldingGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetShieldingGasResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
                        weldingProtocolSection4.shieldingGas2SearchResult = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_SHIELDING_GAS_2_SEARCH_RESULT_REQUEST' });
    },
    getWeldingMethod1SearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingMethods/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingMethodResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
                        weldingProtocolSection2.weldingMethod1SearchResult = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_WELDING_METHOD_A_SEARCH_RESULT_REQUEST' });
    },
    getWeldingMethod2SearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingMethods/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingMethodResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
                        weldingProtocolSection2.weldingMethod2SearchResult = [...data];
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_WELDING_METHOD_B_SEARCH_RESULT_REQUEST' });
    },
    getWeldingPositionSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingPositions/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingPositionResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
                        weldingProtocolSection2.weldingPositionSearchResult = [];
                        for (let i = 0; i < [...data].length; i++) {
                            if ([...data][i].description === "") {
                                weldingProtocolSection2.weldingPositionSearchResult.push([...data][i]);
                            }
                        }
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_WELDING_POSITION_SEARCH_RESULT_REQUEST' });
    },
    init: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'INIT_DEKRA' });
    },
    initBendTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTesting: BendTesting = { ...appState.wpqrDekraTab.bendTesting };
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows.splice(0, bendTestingResults.bendTestingResultsTableRows.length);
            for (let i = 0; i < 4; i++) {
                bendTestingResults.bendTestingResultsTableRows.splice(bendTestingResults.bendTestingResultsTableRows.length, 0, new BendTestingResultsTableRow('', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_BEND_TESTING', bendTesting: bendTesting, bendTestingResults: bendTestingResults });
        }
    },
    initChemicalTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            // Table 1.
            chemicalTestingResults.chemicalTestingResultsTable1Rows.splice(0, chemicalTestingResults.chemicalTestingResultsTable1Rows.length);
            for (let i = 0; i < 1; i++) {
                chemicalTestingResults.chemicalTestingResultsTable1Rows.splice(chemicalTestingResults.chemicalTestingResultsTable1Rows.length, 0, new ChemicalTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''));
            }
            // Table 2.
            chemicalTestingResults.chemicalTestingResultsTable2Rows.splice(0, chemicalTestingResults.chemicalTestingResultsTable2Rows.length);
            for (let i = 0; i < 1; i++) {
                chemicalTestingResults.chemicalTestingResultsTable2Rows.splice(chemicalTestingResults.chemicalTestingResultsTable2Rows.length, 0, new ChemicalTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_CHEMICAL_TESTING', chemicalTesting: chemicalTesting, chemicalTestingResults: chemicalTestingResults });
        }
    },
    initCorrosionTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTesting: CorrosionTesting = { ...appState.wpqrDekraTab.corrosionTesting };
            let corrosionTestingResults: CorrosionTestingResults = { ...appState.wpqrDekraTab.corrosionTestingResults };
            corrosionTestingResults.corrosionTestingResultsTableRows.splice(0, corrosionTestingResults.corrosionTestingResultsTableRows.length);
            for (let i = 0; i < 2; i++) {
                corrosionTestingResults.corrosionTestingResultsTableRows.splice(corrosionTestingResults.corrosionTestingResultsTableRows.length, 0, new CorrosionTestingResultsTableRow('', '', ''));
            }
            dispatch({ type: 'INIT_CORROSION_TESTING', corrosionTesting: corrosionTesting, corrosionTestingResults: corrosionTestingResults });
        }
    },
    initCruciformTensileTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow.splice(0, cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow.length);
            for (let i = 0; i < 3; i++) {
                cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow.splice(cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow.length, 0, new CruciformTensileTestingResultsTableRow('', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting, cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    initFerriteTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.ferriteTestingResultsTableRows.splice(0, ferriteTestingResults.ferriteTestingResultsTableRows.length);
            for (let i = 0; i < 6; i++) {
                ferriteTestingResults.ferriteTestingResultsTableRows.splice(ferriteTestingResults.ferriteTestingResultsTableRows.length, 0, new FerriteTestingResultsTableRow('', '', '', ''));
            }
            dispatch({ type: 'INIT_FERRITE_TESTING', ferriteTesting: ferriteTesting, ferriteTestingResults: ferriteTestingResults });
        }
    },
    initFractureTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            let fractureTestingResults: FractureTestingResults = { ...appState.wpqrDekraTab.fractureTestingResults };
            fractureTestingResults.fractureTestingResultsTableRows.splice(0, fractureTestingResults.fractureTestingResultsTableRows.length);
            for (let i = 0; i < 2; i++) {
                fractureTestingResults.fractureTestingResultsTableRows.splice(fractureTestingResults.fractureTestingResultsTableRows.length, 0, new FractureTestingResultsTableRow('', '', ''));
            }
            dispatch({ type: 'INIT_FRACTURE_TESTING', fractureTesting: fractureTesting, fractureTestingResults: fractureTestingResults });
        }
    },
    initHardnessTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTesting: HardnessTesting = { ...appState.wpqrDekraTab.hardnessTesting };
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            // Table 1.
            hardnessTestingResults.hardnessTestingResultsTable1Rows.splice(0, hardnessTestingResults.hardnessTestingResultsTable1Rows.length);
            for (let i = 0; i < 1; i++) {
                hardnessTestingResults.hardnessTestingResultsTable1Rows.splice(
                    hardnessTestingResults.hardnessTestingResultsTable1Rows.length,
                    0,
                    new HardnessTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''));
            }
            // Table 2.
            hardnessTestingResults.hardnessTestingResultsTable2Rows.splice(0, hardnessTestingResults.hardnessTestingResultsTable2Rows.length);
            for (let i = 0; i < 1; i++) {
                hardnessTestingResults.hardnessTestingResultsTable2Rows.splice(
                    hardnessTestingResults.hardnessTestingResultsTable2Rows.length,
                    0,
                    new HardnessTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''));
            }
            // Table 3.
            hardnessTestingResults.hardnessTestingResultsTable3Rows.splice(0, hardnessTestingResults.hardnessTestingResultsTable3Rows.length);
            for (let i = 0; i < 1; i++) {
                hardnessTestingResults.hardnessTestingResultsTable3Rows.splice(
                    hardnessTestingResults.hardnessTestingResultsTable3Rows.length,
                    0,
                    new HardnessTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_HARDNESS_TESTING', hardnessTesting: hardnessTesting, hardnessTestingResults: hardnessTestingResults });
        }
    },
    initImpactTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTesting: ImpactTesting = { ...appState.wpqrDekraTab.impactTesting };
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            // Weld.
            impactTestingResults.weldImpactTestingResultsTableRows.splice(0, impactTestingResults.weldImpactTestingResultsTableRows.length);
            for (let i = 0; i < 3; i++) {
                impactTestingResults.weldImpactTestingResultsTableRows.splice(impactTestingResults.weldImpactTestingResultsTableRows.length, 0, new ImpactTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', ''));
            }
            // HAZ.
            impactTestingResults.hazImpactTestingResultsTableRows.splice(0, impactTestingResults.hazImpactTestingResultsTableRows.length);
            for (let i = 0; i < 3; i++) {
                impactTestingResults.hazImpactTestingResultsTableRows.splice(impactTestingResults.hazImpactTestingResultsTableRows.length, 0, new ImpactTestingResultsTableRow('', '', '', '', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_IMPACT_TESTING', impactTesting: impactTesting, impactTestingResults: impactTestingResults });
        }
    },
    initMacroTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTesting: MacroTesting = { ...appState.wpqrDekraTab.macroTesting };
            let macroTestingResults: MacroTestingResults = { ...appState.wpqrDekraTab.macroTestingResults };
            macroTestingResults.macroTestingResultsTableRows.splice(0, macroTestingResults.macroTestingResultsTableRows.length);
            for (let i = 0; i < 2; i++) {
                macroTestingResults.macroTestingResultsTableRows.splice(macroTestingResults.macroTestingResultsTableRows.length, 0, new MacroTestingResultsTableRow('', '', ''));
            }
            dispatch({ type: 'INIT_MACRO_TESTING', macroTesting: macroTesting, macroTestingResults: macroTestingResults });
        }
    },
    initMicroTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTesting: MicroTesting = { ...appState.wpqrDekraTab.microTesting };
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.microTestingResultsTableRows.splice(0, microTestingResults.microTestingResultsTableRows.length);
            for (let i = 0; i < 1; i++) {
                microTestingResults.microTestingResultsTableRows.splice(microTestingResults.microTestingResultsTableRows.length, 0, new MicroTestingResultsTableRow('', '', ''));
            }
            dispatch({ type: 'INIT_MICRO_TESTING', microTesting: microTesting, microTestingResults: microTestingResults });
        }
    },
    initTestingOrder: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab && appState.testingOrder) {
            let testingOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.qwbpOrder = testingOrder;
            weldingProtocol.qwbpOrder.material1 = "";
            weldingProtocol.qwbpOrder.material2 = "";
            weldingProtocol.qwbpOrder.standardForAcceptance1 = "";
            weldingProtocol.qwbpOrder.standardForAcceptance2 = "";
            weldingProtocol.qwbpOrder.fillerMaterial = "";
            weldingProtocol.qwbpOrder.weldingProcess = "";
            //qwbpOrder.dimension2 = parentMaterial.materialThickness1 + "/" + parentMaterial.diameter1;
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocol.qwbpOrder.material1 = weldingProtocolSection3.baseMaterial1SearchText;
            weldingProtocol.qwbpOrder.materialGroup1 = weldingProtocolSection3.selectedMaterialGroup1.name;
            weldingProtocol.qwbpOrder.chargeNumber1 = weldingProtocolSection3.chargeAltHeatNumber1;
            weldingProtocol.qwbpOrder.materialSpecifications1Remarks = weldingProtocolSection3.otherInformation1;
            weldingProtocol.qwbpOrder.material2 = weldingProtocolSection3.baseMaterial2SearchText;
            weldingProtocol.qwbpOrder.materialGroup2 = weldingProtocolSection3.selectedMaterialGroup2.name;
            weldingProtocol.qwbpOrder.chargeNumber2 = weldingProtocolSection3.chargeAltHeatNumber2;
            weldingProtocol.qwbpOrder.materialSpecifications2Remarks = weldingProtocolSection3.otherInformation2;
            weldingProtocol.qwbpOrder.dimension1 = HelpFunctions.JoinDiameterAndMaterialThicknes(weldingProtocolSection3.diameter1, weldingProtocolSection3.materialThickness1);
            weldingProtocol.qwbpOrder.dimension2 = HelpFunctions.JoinDiameterAndMaterialThicknes(weldingProtocolSection3.diameter2, weldingProtocolSection3.materialThickness2);
            weldingProtocol.qwbpOrder.standardForAcceptance1 = weldingProtocolSection3.selectedTechnicalDeliveryCondition1.name;
            weldingProtocol.qwbpOrder.standardForAcceptance2 = weldingProtocolSection3.selectedTechnicalDeliveryCondition2.name;
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocol.qwbpOrder.fillerMaterial = HelpFunctions.JoinTwoString(weldingProtocolSection2.make1SearchText, weldingProtocolSection2.make2SearchText);
            weldingProtocol.qwbpOrder.weldingBrazingPositions = HelpFunctions.JoinTwoString(weldingProtocolSection2.selectedWeldingPosition.name, weldingProtocolSection2.selectedWeldingPosition2.name);
            weldingProtocol.qwbpOrder.weldingProcess = HelpFunctions.JoinTwoString(weldingProtocolSection2.weldingMethod1SearchText, weldingProtocolSection2.weldingMethod2SearchText);
            weldingProtocol.qwbpOrder.testmarking = weldingProtocol.markingOfTheTest;
            weldingProtocol.qwbpOrder.uppdragsnr = weldingProtocol.assignmentNumber ;
            weldingProtocol.qwbpOrder.welderBrazerFirstName = weldingProtocolSection1.welder != null ? weldingProtocolSection1.welder.firstName : "";
            weldingProtocol.qwbpOrder.welderBrazerLastName = weldingProtocolSection1.welder != null ? weldingProtocolSection1.welder.lastName : "";
            weldingProtocol.qwbpOrder.weldingDate = weldingProtocol.weldingDate;
            weldingProtocol.qwbpOrder.dekraProcedureNoTesting = weldingProtocol.dekraProcedureNumberDisplay;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setTestingDetail1Component: (existingWpqrweldingTestingDetails1: WeldingTestingDetails1): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1 = existingWpqrweldingTestingDetails1;
            dispatch({ type: 'INIT_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    initSelectedApplicationWeldingTestingDetails1: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol};
            let weldingProtocolSection7: WeldingProtocolSection7 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection7 };
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingTestingDetails1.manufacturer = weldingProtocolSection1.company2.name;
            weldingTestingDetails1.location = weldingProtocolSection1.company2.city;
            weldingTestingDetails1.welderName = weldingProtocolSection1.welder.firstName + " " + weldingProtocolSection1.welder.lastName;
            weldingTestingDetails1.manufacturerPWPSNumber = weldingProtocol.pWPSNumber;

            weldingTestingDetails1.weldingPosition = weldingProtocolSection2.selectedWeldingPosition.name;
            weldingTestingDetails1.weldingPosition2 = weldingProtocolSection2.selectedWeldingPosition2.name;
            weldingTestingDetails1.jointType = weldingProtocolSection2.selectedJointType.description;
            weldingTestingDetails1.weldingProcess= HelpFunctions.addRowsAndNumbers(weldingProtocolSection2.weldingMethod1SearchText, weldingProtocolSection2.weldingMethod2SearchText);
            weldingTestingDetails1.fillerMaterialTradeName = HelpFunctions.addRowsAndNumbers(weldingProtocolSection2.make1SearchText, weldingProtocolSection2.make2SearchText);
            weldingTestingDetails1.fillerMaterialDesignation = HelpFunctions.addRowsAndNumbers(weldingProtocolSection2.selectedDesignation1.name, weldingProtocolSection2.selectedDesignation2.name);
            weldingTestingDetails1.chargeNumberLotNumberSerialNumber = HelpFunctions.addRowsAndNumbers(weldingProtocolSection2.lotNumberOrHeatNumberA, weldingProtocolSection2.lotNumberOrHeatNumberB);
            weldingTestingDetails1.arcType = HelpFunctions.addRowsAndNumbers(weldingProtocolSection2.selectedArcTypeA.name, weldingProtocolSection2.selectedArcTypeB.name);

            weldingTestingDetails1.parentMaterialNameOrNumber = HelpFunctions.addRowsAndNumbers(weldingProtocolSection3.baseMaterial1SearchText, weldingProtocolSection3.baseMaterial2SearchText);
            weldingTestingDetails1.materialThickness = HelpFunctions.addRowsAndNumbers(weldingProtocolSection3.materialThickness1, weldingProtocolSection3.materialThickness2);
            weldingTestingDetails1.outsideDiameter = HelpFunctions.addRowsAndNumbers(weldingProtocolSection3.diameter1, weldingProtocolSection3.diameter2);
            weldingTestingDetails1.technicalDeliveryStandard = HelpFunctions.addRowsAndNumbers(weldingProtocolSection3.selectedTechnicalDeliveryCondition1.name, weldingProtocolSection3.selectedTechnicalDeliveryCondition2.name);
            weldingTestingDetails1.parentMaterialGroup = HelpFunctions.addRowsAndNumbers(weldingProtocolSection3.selectedMaterialGroup1.name, weldingProtocolSection3.selectedMaterialGroup2.name);
            weldingTestingDetails1.chargeNumberHeatNumber = HelpFunctions.addRowsAndNumbers(weldingProtocolSection3.chargeAltHeatNumber1, weldingProtocolSection3.chargeAltHeatNumber2);
            weldingTestingDetails1.otherInformation = HelpFunctions.addRowsAndNumbers(weldingProtocolSection3.otherInformation1, weldingProtocolSection3.otherInformation2);
            weldingTestingDetails1.shieldingGasFlux = HelpFunctions.addRowsAndNumbers(weldingProtocolSection4.shieldingGas1SearchText,weldingProtocolSection4.shieldingGas2SearchText);
            weldingTestingDetails1.backingGasFlux = weldingProtocolSection4.rootProtectionGasSearchText;
            weldingTestingDetails1.plasmaGasName = weldingProtocolSection4.selectedPlasmaGas ? weldingProtocolSection4.selectedPlasmaGas.content : '';
            weldingTestingDetails1.electrodeTorchAngle111 = weldingProtocolSection4.electrodeTorchAngle111;
            weldingTestingDetails1.plasmaflowrate = weldingProtocolSection4.plasmaflowrate;
            weldingTestingDetails1.shieldingGasFlowRate = HelpFunctions.addRowsAndNumbers(weldingProtocolSection4.gasFlowMethod1TopSide, weldingProtocolSection4.gasFlowMethod2TopSide);
            weldingTestingDetails1.tungstenElectrode = weldingProtocolSection4.tungstenElectrode;
            weldingTestingDetails1.detailsOfGougingBacking = weldingProtocolSection4.selectedRootSupportDetails.name;
            weldingTestingDetails1.nozzleDiameter = weldingProtocolSection4.nozzleDiameter;
            weldingTestingDetails1.standOffDistance = weldingProtocolSection4.nozzleDistance;
            weldingTestingDetails1.preheatTemperature = weldingProtocolSection4.minElevatedWorkingTemperature;
            weldingTestingDetails1.postWeldHeatTreatmentAndOrAgeing = weldingProtocolSection4.postWeldHeatTreatment;
            weldingTestingDetails1.heatingAndCoolingRates = weldingProtocolSection4.heatingAndCoolingRate;
            weldingTestingDetails1.methodOfPreparationAndCleaning = weldingProtocolSection4.jointPreparation;
            weldingTestingDetails1.manufacturerOfPowerSource = weldingProtocolSection4.powerSourceManufacturer;
            weldingTestingDetails1.interpassTemperature = weldingProtocolSection4.maxInterpassTemperature;
            weldingTestingDetails1.backingGasFlowRate = weldingProtocolSection4.gasFlowMethod1RootSide;
            weldingTestingDetails1.torchAngle = weldingProtocolSection4.gunAngle;
            weldingTestingDetails1.manufacturer2 = weldingProtocolSection1.company2.name;
            weldingTestingDetails1.weaving = weldingProtocolSection4.oscillationMaxRunWidth;

            weldingTestingDetails1.tackWeldText = weldingProtocolSection7.tackWeldText;
            weldingTestingDetails1.lengthTackWelds = weldingProtocolSection7.lengthTackWelds;
            
            dispatch({ type: 'INIT_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    initRangeOfQualification: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab && appState.wParameters && appState.testingOrder) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            //let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            let weldingProtocolSection7: WeldingProtocolSection7 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection7 };
            //let runType: RunType = (weldingProtocolSection6.weldingParameters.length > 1) ? RunType.MultiRun : RunType.SingleRun;
            let runType: RunType = (weldingParameters.length > 1) ? RunType.MultiRun : RunType.SingleRun;
            let hardnessTestingOrImpactTesting: boolean = true;

            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            let impactTesting: boolean = qwbpOrder.impactTesting;

            let currentType1: GetCurrentTypeResponse = new GetCurrentTypeResponse();
            let currentType2: GetCurrentTypeResponse = new GetCurrentTypeResponse();
            // Get currentType1

            if (weldingProtocolSection2.selectedWeldingMethod1.id !== 0) {
                for (let i = 0; i < weldingParameters.length; i++) {
                    if (weldingParameters[i].weldingMethod.id === weldingProtocolSection2.selectedWeldingMethod1.id) {
                        currentType1 = { ...weldingParameters[i].currentType };
                        break;
                    }
                }
            }
            // Get currentType2
            if (weldingProtocolSection2.selectedWeldingMethod2.id !== 0) {
                for (let i = 0; i < weldingParameters.length; i++) {
                    if (weldingParameters[i].weldingMethod.id === weldingProtocolSection2.selectedWeldingMethod2.id) {
                        currentType2 = { ...weldingParameters[i].currentType };
                        break;
                    }
                }
            }
            // WeldingMethodRangeOfQualification.
            fetch(`api/WeldingMethodRangeOfQualifications/GetById/${weldingProtocolSection2.selectedWeldingMethod1.id}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            }).then(response =>
                    response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                    ).then(res => {
                        if (res.status === 401) {
                            EventBus.dispatch("logout", "");
                        }
                        let data1 = res.data as GetWeldingMethodRangeOfQualificationResponse;
                        console.info(data1);
                        if (weldingProtocolSection2.selectedWeldingMethod2.id !== 0) {
                            fetch(`api/WeldingMethodRangeOfQualifications/GetById/${weldingProtocolSection2.selectedWeldingMethod2.id}`, {
                                method: 'GET',
                                headers: authHeader.authHeader(),
                                })
                                .then(response => response.json() as Promise<GetWeldingMethodRangeOfQualificationResponse>)
                                .then(data2 => {
                                    console.info(data2);
                                    let weldingMethodRangeOfQualifications: Array<string> = [];
                                    weldingMethodRangeOfQualifications.splice(weldingMethodRangeOfQualifications.length, 0, data1.weldingMethod1TwoWeldingMethods);
                                    weldingMethodRangeOfQualifications.splice(weldingMethodRangeOfQualifications.length, 0, data2.weldingMethod2TwoWeldingMethods);
                                    dispatch({ type: 'SET_WELDING_METHOD_RANGE_OF_QUALIFICATIONS', weldingMethodRangeOfQualifications: weldingMethodRangeOfQualifications });
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                        }
                        else {
                            let weldingMethodRangeOfQualifications: Array<string> = [];
                            weldingMethodRangeOfQualifications.splice(weldingMethodRangeOfQualifications.length, 0, data1.weldingMethod1OneWeldingMethod);
                            dispatch({ type: 'SET_WELDING_METHOD_RANGE_OF_QUALIFICATIONS', weldingMethodRangeOfQualifications: weldingMethodRangeOfQualifications });
                        }
                    }))
                .catch(error => {
                    console.error(error);
                });
            // JointTypeRangeOfQualification
            fetch(`api/JointTypeRangeOfQualifications/GetByJointTypeIdAndRootSupportDetailsId/${weldingProtocolSection2.selectedJointType.id}/${weldingProtocolSection4.selectedRootSupportDetails.id}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetJointTypeRangeOfQualificationResponse>)
                .then(data => {
                    console.info(data);
                    let tempRangeOfQualification = data.rangeOfQualification.split("_//_");// Several welding methods split with _//_
                    if (weldingProtocolSection2.weldingMethod2SearchText !== "" && tempRangeOfQualification.length > 1) {
                        data.rangeOfQualification = tempRangeOfQualification[1].replace('[svetsmetod1]', weldingProtocolSection2.selectedWeldingMethod1.name.split(',')[0]).replace('[svetsmetod2]', weldingProtocolSection2.selectedWeldingMethod2.name.split(',')[0])
                    } else {
                        data.rangeOfQualification = tempRangeOfQualification[0];
                    }
                    dispatch({ type: 'SET_JOINT_TYPE_RANGE_OF_QUALIFICATION', jointTypeRangeOfQualification: data.rangeOfQualification });
                })
                .catch(error => {
                    console.error(error);
                });
            // MaterialGroupRangeOfQualification.
            fetch(`api/MaterialGroupRangeOfQualifications/GetByMaterialGroup1IdAndMaterialGroup2Id/${weldingProtocolSection3.selectedMaterialGroup1.id}/${weldingProtocolSection3.selectedMaterialGroup2.id}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetMaterialGroupRangeOfQualificationResponse>)
                .then(data => {
                    console.info(data);
                    if (data.rangeOfQualification === null) {
                        dispatch({ type: 'SET_MATERIAL_GROUP_RANGE_OF_QUALIFICATION', materialGroupRangeOfQualification: "" });
                    } else {
                        dispatch({ type: 'SET_MATERIAL_GROUP_RANGE_OF_QUALIFICATION', materialGroupRangeOfQualification: data.rangeOfQualification });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            // MaterialThicknessRangeOfQualification.
            // Clear the materialThicknessRangeOfQualifications value
            let materialThicknessRangeOfQualificationsClear: Array<string> = [];
            dispatch({ type: 'SET_MATERIAL_THICKNESS_RANGE_OF_QUALIFICATIONS', materialThicknessRangeOfQualifications: materialThicknessRangeOfQualificationsClear });

            fetch(`api/MaterialThicknessRangeOfQualifications/GetByJointTypeIdAndRunTypeAndMaterialThicknessInclude/${weldingProtocolSection2.selectedJointType.id}/${runType}/${weldingProtocolSection3.materialThickness1.replace(',', '.')}/${weldingProtocolSection3.materialThickness2.replace(',', '.')}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetMaterialThicknessRangeOfQualificationResponse>)
                .then(data => {
                    console.info(data);
                    let materialThicknessRangeOfQualifications: Array<string> = [];
                    if (data.rangeOfQualification != null) {
                        if ((weldingProtocolSection3.selectedMaterialGroup1.id === weldingProtocolSection3.selectedMaterialGroup2.id)
                            && (weldingProtocolSection3.selectedProductType1.id === weldingProtocolSection3.selectedProductType2.id
                                && weldingProtocolSection3.materialThickness1 === weldingProtocolSection3.materialThickness2)) {
                            data.rangeOfQualification = data.rangeOfQualification.replace('{ProductType}', weldingProtocolSection3.selectedProductType1.name).replace('{MaterialGroup}', weldingProtocolSection3.selectedMaterialGroup1.name);
                            materialThicknessRangeOfQualifications.splice(materialThicknessRangeOfQualifications.length, 0, data.rangeOfQualification);
                        }
                        else {
                            data.rangeOfQualification1 = data.rangeOfQualification1.replace('{ProductType}', weldingProtocolSection3.selectedProductType1.name).replace('{MaterialGroup}', weldingProtocolSection3.selectedMaterialGroup1.name);
                            data.rangeOfQualification2 = data.rangeOfQualification2.replace('{ProductType}', weldingProtocolSection3.selectedProductType2.name).replace('{MaterialGroup}', weldingProtocolSection3.selectedMaterialGroup2.name);
                            materialThicknessRangeOfQualifications.splice(materialThicknessRangeOfQualifications.length, 0, data.rangeOfQualification1);
                            materialThicknessRangeOfQualifications.splice(materialThicknessRangeOfQualifications.length, 0, data.rangeOfQualification2);
                        }
                    }
                    dispatch({ type: 'SET_MATERIAL_THICKNESS_RANGE_OF_QUALIFICATIONS', materialThicknessRangeOfQualifications: materialThicknessRangeOfQualifications });

                    // WeldingMetalThicknessRangeOfQualification.
                    fetch(`api/WeldingMetalThicknessRangeOfQualifications/GetByJointTypeIdAndWeldingMethod1IdAndWeldingMethod2Id/${weldingProtocolSection2.selectedJointType.id}/${weldingProtocolSection2.selectedWeldingMethod1.id}/${weldingProtocolSection2.selectedWeldingMethod2.id}`, {
                        method: 'GET',
                        headers: authHeader.authHeader(),
                    })
                        .then(response => response.json() as Promise<GetWeldingMetalThicknessRangeOfQualificationResponse>)
                        .then(data => {
                            console.info(data);
                            let result = "XX,X";
                            if (materialThicknessRangeOfQualifications.length === 1 && materialThicknessRangeOfQualifications[0] !== "") {
                                let mt = materialThicknessRangeOfQualifications[0].split(" FW");
                                if (mt.length > 1) {
                                    let r = mt[0].split("- ");
                                    if (r.length > 1) {
                                        result = r[1];
                                    }
                                }
                            }
                            dispatch({ type: 'SET_WELDING_METAL_THICKNESS_RANGE_OF_QUALIFICATION', weldingMetalThicknessRangeOfQualification: data.rangeOfQualification.replace("XX,X", result) });
                        })
                        .catch(error => {
                            console.error(error);
                        });
                })
                .catch(error => {
                    console.error(error);
                });
            // WeldingMetalThicknessRangeOfQualification.
            //fetch(`api/WeldingMetalThicknessRangeOfQualifications/GetByJointTypeIdAndWeldingMethod1IdAndWeldingMethod2Id/${weldingProtocolSection2.selectedJointType.id}/${weldingProtocolSection2.selectedWeldingMethod1.id}/${weldingProtocolSection2.selectedWeldingMethod2.id}`, {
            //    method: 'GET',
            //    headers: authHeader.authHeader(),
            //})
            //    .then(response => response.json() as Promise<GetWeldingMetalThicknessRangeOfQualificationResponse>)
            //    .then(data => {
            //        console.info(data);
            //        dispatch({ type: 'SET_WELDING_METAL_THICKNESS_RANGE_OF_QUALIFICATION', weldingMetalThicknessRangeOfQualification: data.rangeOfQualification });
            //    })
            //    .catch(error => {
            //        console.error(error);designationRangeOfQualifications
            //    });
            // ThroatThicknessRangeOfQualification.
            fetch(`api/ThroatThicknessRangeOfQualifications/GetByJointTypeIdAndRunTypeAndMaterialThicknessAndThroatThicknessInclude/${weldingProtocolSection2.selectedJointType.id}/${runType}/${weldingProtocolSection3.materialThickness1.replace(',', '.')}/${!isNaN(parseFloat(weldingProtocolSection7.throatThickness.replace(',', '.'))) ? parseFloat(weldingProtocolSection7.throatThickness.replace(',', '.')) : 0.0}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetThroatThicknessRangeOfQualificationResponse>)
                .then(data => {
                    console.info(data);
                    dispatch({ type: 'SET_THROAT_THICKNESS_RANGE_OF_QUALIFICATION', throatThicknessRangeOfQualification: data.rangeOfQualification });
                })
                .catch(error => {
                    console.error(error);
                });
            // RunTypeRangeOfQualification.
            fetch(`api/RunTypeRangeOfQualifications/GetByRunTypeAndHardnessTestingOrImpactTesting/${runType}/${hardnessTestingOrImpactTesting}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetRunTypeRangeOfQualificationResponse>)
                .then(data => {
                    console.info(data);
                    dispatch({ type: 'SET_RUN_TYPE_RANGE_OF_QUALIFICATION', runTypeRangeOfQualification: data.rangeOfQualification });
                })
                .catch(error => {
                    console.error(error);
                });
            // DiameterRangeOfQualification.
            let diameter1 = !isNaN(parseFloat(weldingProtocolSection3.diameter1.replace(',', '.'))) ? parseFloat(weldingProtocolSection3.diameter1.replace(',', '.')) : 0.0;
            fetch(`api/DiameterRangeOfQualifications/GetByDiameter/${diameter1}`, {
                    method: 'GET',
                    headers: authHeader.authHeader(),
                })
                .then(response => response.json() as Promise<GetDiameterRangeOfQualificationResponse>)
                .then(data => {
                    console.info(data);
                    dispatch({ type: 'SET_DIAMETER_RANGE_OF_QUALIFICATION', diameterRangeOfQualification: data.rangeOfQualification });
                })
                .catch(error => {
                    console.error(error);
                });
            // DesignationRangeOfQualification.
            fetch(`api/DesignationRangeOfQualifications/GetByDesignationId/${weldingProtocolSection2.selectedDesignation1.id}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetDesignationRangeOfQualificationResponse>)
                .then(data1 => {
                    console.info(data1);
                    let designationRangeOfQualifications: Array<string> = [];
                    // Check if Designation2 exists.
                    if ((weldingProtocolSection2.selectedDesignation2.id !== 0 || weldingProtocolSection2.enableMakeBText) && weldingProtocolSection2.selectedWeldingMethod2.id !== 0) {
                        fetch(`api/DesignationRangeOfQualifications/GetByDesignationId/${weldingProtocolSection2.selectedDesignation2.id}`, {
                            method: 'GET',
                            headers: authHeader.authHeader(),
                        })
                            .then(response => response.json() as Promise<GetDesignationRangeOfQualificationResponse>)
                            .then(data2 => {
                                console.info(data2);
                                designationRangeOfQualifications.splice(designationRangeOfQualifications.length,
                                    0,
                                    weldingProtocolSection2.selectedWeldingMethod1.name.split(',')[0] + ': ' + (weldingProtocolSection2.enableMakeAText ? weldingProtocolSection2.designationAText : data1.rangeOfQualification));
                                designationRangeOfQualifications.splice(designationRangeOfQualifications.length,
                                    0,
                                    weldingProtocolSection2.selectedWeldingMethod2.name.split(',')[0] + ': ' + (weldingProtocolSection2.enableMakeBText ? weldingProtocolSection2.designationBText : data2.rangeOfQualification));
                                dispatch({ type: 'SET_DESIGNATION_RANGE_OF_QUALIFICATION', designationRangeOfQualifications: designationRangeOfQualifications });
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                    else {
                        designationRangeOfQualifications.splice(designationRangeOfQualifications.length, 0, (weldingProtocolSection2.enableMakeAText ? weldingProtocolSection2.designationAText : data1.rangeOfQualification));
                        dispatch({ type: 'SET_DESIGNATION_RANGE_OF_QUALIFICATION', designationRangeOfQualifications: designationRangeOfQualifications });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            // MakeRangeOfQualification.
            fetch(`api/MakeRangeOfQualifications/GetByMakeIdAndWeldingMethodIdAndImpactTesting/${weldingProtocolSection2.selectedMake1.id}/${weldingProtocolSection2.selectedWeldingMethod1.id}/${impactTesting}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetMakeRangeOfQualificationResponse>)
                .then(data1 => {
                    console.info(data1);
                    let makeRangeOfQualifications: Array<string> = [];
                    // Check if Make2 exists.
                    if (weldingProtocolSection2.selectedMake2.id !== 0 || weldingProtocolSection2.enableMakeBText) {
                        fetch(`api/MakeRangeOfQualifications/GetByMakeIdAndWeldingMethodIdAndImpactTesting/${weldingProtocolSection2.selectedMake2.id}/${weldingProtocolSection2.selectedWeldingMethod2.id}/${impactTesting}`, {
                            method: 'GET',
                            headers: authHeader.authHeader(),
                        })
                            .then(response => response.json() as Promise<GetMakeRangeOfQualificationResponse>)
                            .then(data2 => {
                                console.info(data2);
                                //if (impactTesting) {
                                    makeRangeOfQualifications.splice(makeRangeOfQualifications.length,
                                        0,
                                        weldingProtocolSection2.selectedWeldingMethod1.name.split(',')[0] + ': ' + (weldingProtocolSection2.enableMakeAText ? weldingProtocolSection2.makeAText : data1.rangeOfQualification));
                                    makeRangeOfQualifications.splice(makeRangeOfQualifications.length,
                                        0,
                                        weldingProtocolSection2.selectedWeldingMethod2.name.split(',')[0] + ': ' + (weldingProtocolSection2.enableMakeBText ? weldingProtocolSection2.makeBText : data2.rangeOfQualification));
                                //}
                                //else {
                                //    makeRangeOfQualifications.splice(makeRangeOfQualifications.length, 0, (weldingProtocolSection2.enableMakeAText ? weldingProtocolSection2.makeAText : data1.rangeOfQualification));
                                //}
                                dispatch({ type: 'SET_MAKE_RANGE_OF_QUALIFICATION', makeRangeOfQualifications: makeRangeOfQualifications });
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                    else {
                        makeRangeOfQualifications.splice(makeRangeOfQualifications.length, 0, (weldingProtocolSection2.enableMakeAText ? weldingProtocolSection2.makeAText : data1.rangeOfQualification));
                        dispatch({ type: 'SET_MAKE_RANGE_OF_QUALIFICATION', makeRangeOfQualifications: makeRangeOfQualifications });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            // ShieldingGasRangeOfQualification.
            fetch(`api/ShieldingGasRangeOfQualifications/GetByShieldingGasId/${weldingProtocolSection4.selectedShieldingGas1.id}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetShieldingGasRangeOfQualificationResponse>)
                .then(data1 => {
                    console.info(data1);
                    let shieldingGasRangeOfQualifications: Array<string> = [];
                    // Check if ShieldingGas2 exists.
                    if (weldingProtocolSection4.selectedShieldingGas2.id !== 0 && weldingProtocolSection2.selectedWeldingMethod2.id !== 0) {
                        fetch(`api/ShieldingGasRangeOfQualifications/GetByShieldingGasId/${weldingProtocolSection4.selectedShieldingGas2.id}`, {
                            method: 'GET',
                            headers: authHeader.authHeader(),
                        })
                            .then(response => response.json() as Promise<GetShieldingGasRangeOfQualificationResponse>)
                            .then(data2 => {
                                console.info(data2);
                                if (data1.rangeOfQualification != "") {
                                    shieldingGasRangeOfQualifications.splice(shieldingGasRangeOfQualifications.length,
                                        0,
                                        weldingProtocolSection2.selectedWeldingMethod1.name.split(',')[0] + ': ' + data1.rangeOfQualification);
                                }
                                if (data2.rangeOfQualification != "") {
                                    shieldingGasRangeOfQualifications.splice(shieldingGasRangeOfQualifications.length,
                                        0,
                                        weldingProtocolSection2.selectedWeldingMethod2.name.split(',')[0] + ': ' + data2.rangeOfQualification);
                                    dispatch({ type: 'SET_SHIELDING_GAS_RANGE_OF_QUALIFICATIONS', shieldingGasRangeOfQualifications: shieldingGasRangeOfQualifications });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                    else {
                        shieldingGasRangeOfQualifications.splice(shieldingGasRangeOfQualifications.length, 0, data1.rangeOfQualification);
                        dispatch({ type: 'SET_SHIELDING_GAS_RANGE_OF_QUALIFICATIONS', shieldingGasRangeOfQualifications: shieldingGasRangeOfQualifications });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            // RootProtectionGasRangeOfQualification. see Empty in API to
            var selectedMaterialGroup1 = weldingProtocolSection3.selectedMaterialGroup1.name == "" ? "Empty" : weldingProtocolSection3.selectedMaterialGroup1.name;
            var selectedMaterialGroup2 = weldingProtocolSection3.selectedMaterialGroup2.name == "" ? "Empty" : weldingProtocolSection3.selectedMaterialGroup2.name; 
            fetch(`api/RootProtectionGasRangeOfQualifications/GetByRootProtectionGasRangeOfQualification/${selectedMaterialGroup1}/${selectedMaterialGroup2}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetRootProtectionGasRangeOfQualificationResponse>)
                .then(data1 => {
                    console.info(data1);
                    if (weldingProtocolSection2.selectedJointType.name.search("BW") > -1) {
                        dispatch({ type: 'SET_ROOT_PROTECTION_GAS_RANGE_OF_QUALIFICATION', rootProtectionGasRangeOfQualification: data1.rangeOfQualification });
                    }
                    else {
                        dispatch({ type: 'SET_ROOT_PROTECTION_GAS_RANGE_OF_QUALIFICATION', rootProtectionGasRangeOfQualification: "" });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            // CurrentTypeRangeOfQualification.
            fetch(`api/CurrentTypeRangeOfQualifications/GetByCurrentTypeIdAndWeldingMethodIdAndImpactTesting/${currentType1.id}/${weldingProtocolSection2.selectedWeldingMethod1.id}/${impactTesting}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            })
                .then(response => response.json() as Promise<GetCurrentTypeRangeOfQualificationResponse>)
                .then(data1 => {
                    console.info(data1);
                    let currentTypeRangeOfQualifications: Array<string> = [];
                    // Check if CurrentType2 exists.
                    if (currentType2.id !== 0 && weldingProtocolSection2.selectedWeldingMethod2.id !== 0) {
                        fetch(`api/CurrentTypeRangeOfQualifications/GetByCurrentTypeIdAndWeldingMethodIdAndImpactTesting/${currentType2.id}/${weldingProtocolSection2.selectedWeldingMethod2.id}/${impactTesting}`, {
                            method: 'GET',
                            headers: authHeader.authHeader(),
                        })
                            .then(response => response.json() as Promise<GetMakeRangeOfQualificationResponse>)
                            .then(data2 => {
                                console.info(data2);
                                let rangeOfQualification1 = data1.rangeOfQualification === null ? "" : data1.rangeOfQualification;
                                let rangeOfQualification2 = data2.rangeOfQualification === null ? "" : data2.rangeOfQualification;
                                currentTypeRangeOfQualifications.splice(currentTypeRangeOfQualifications.length,
                                    0,
                                    weldingProtocolSection2.selectedWeldingMethod1.name.split(',')[0] + ': ' + rangeOfQualification1);
                                currentTypeRangeOfQualifications.splice(currentTypeRangeOfQualifications.length,
                                    0,
                                    weldingProtocolSection2.selectedWeldingMethod2.name.split(',')[0] + ': ' + rangeOfQualification2);
                                dispatch({ type: 'SET_CURRENT_TYPE_RANGE_OF_QUALIFICATION', currentTypeRangeOfQualifications: currentTypeRangeOfQualifications });
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                    else {
                        currentTypeRangeOfQualifications.splice(currentTypeRangeOfQualifications.length, 0, data1.rangeOfQualification === null ? "" : data1.rangeOfQualification);
                        dispatch({ type: 'SET_CURRENT_TYPE_RANGE_OF_QUALIFICATION', currentTypeRangeOfQualifications: currentTypeRangeOfQualifications });
                    }
                })
                .catch(error => {
                        console.error(error);
                });
            // DropletTransitionRangeOfQualification.
            fetch(`api/DropletTransitionRangeOfQualifications/GetByDropletTransitionIdAndWeldingMethodId/${weldingProtocolSection2.selectedArcTypeA.id}/${weldingProtocolSection2.selectedWeldingMethod1.id}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            }).then(response => response.json() as Promise<GetDropletTransitionRangeOfQualificationResponse>)
                .then(data1 => {
                    console.info(data1);
                    let dropletTransitionRangeOfQualifications: Array<string> = [];
                    // Check if DropletTransition2 exists.
                    if (weldingProtocolSection2.selectedArcTypeB.id !== 0 && weldingProtocolSection2.selectedWeldingMethod2.id !== 0) {
                        fetch(`api/DropletTransitionRangeOfQualifications/GetByDropletTransitionIdAndWeldingMethodId/${weldingProtocolSection2.selectedArcTypeB.id}/${weldingProtocolSection2.selectedWeldingMethod2.id}`, {
                            method: 'GET',
                            headers: authHeader.authHeader(),
                            })
                            .then(response => response.json() as Promise<GetDropletTransitionRangeOfQualificationResponse>)
                            .then(data2 => {
                                console.info(data2);
                                if (data1.rangeOfQualification !== "") {
                                    dropletTransitionRangeOfQualifications.splice(dropletTransitionRangeOfQualifications.length,
                                        0,
                                        weldingProtocolSection2.selectedWeldingMethod1.name.split(',')[0] + ': ' + data1.rangeOfQualification);
                                }
                                if (data2.rangeOfQualification !== "") {
                                    dropletTransitionRangeOfQualifications.splice(dropletTransitionRangeOfQualifications.length,
                                        0,
                                        weldingProtocolSection2.selectedWeldingMethod2.name.split(',')[0] + ': ' + data2.rangeOfQualification);
                                }
                                dispatch({ type: 'SET_DROPLET_TRANSITION_RANGE_OF_QUALIFICATION', dropletTransitionRangeOfQualifications: dropletTransitionRangeOfQualifications });
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                    else {
                        dropletTransitionRangeOfQualifications.splice(dropletTransitionRangeOfQualifications.length, 0, data1.rangeOfQualification);
                        dispatch({ type: 'SET_DROPLET_TRANSITION_RANGE_OF_QUALIFICATION', dropletTransitionRangeOfQualifications: dropletTransitionRangeOfQualifications });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            // PostWeldHeatTreatmentRangeOfQualification.
            let postWeldHeatTreatmentRangeOfQualification: string = weldingProtocolSection4.postWeldHeatTreatment;
            dispatch({ type: 'SET_POST_WELD_HEAT_TREATMENT_RANGE_OF_QUALIFICATION', postWeldHeatTreatmentRangeOfQualification: postWeldHeatTreatmentRangeOfQualification });
            // PreheatTemperatureRangeOfQualification.
            fetch(`api/PreheatTemperatureRangeOfQualifications/GetByPreheatTemperature/${weldingProtocolSection4.minElevatedWorkingTemperature.replace(',', '.')}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            }).then(response =>
                    response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                    ).then(res => {
                        if (res.status === 401) {
                            EventBus.dispatch("logout", "");
                        }
                        let data = res.data as GetPreheatTemperatureRangeOfQualificationResponse;
                        console.info(data);
                        dispatch({ type: 'SET_PREHEAT_TEMPERATURE_RANGE_OF_QUALIFICATION', preheatTemperatureRangeOfQualification: data.rangeOfQualification });
                    }))
                .catch(error => {
                    console.error(error);
                });
            // InterpassTemperatureRangeOfQualification.
            fetch(`api/InterpassTemperatureRangeOfQualifications/GetByMaterialGroupIdAndInterpassTemperature/${weldingProtocolSection3.selectedMaterialGroup1.id}/${weldingProtocolSection4.maxInterpassTemperature.replace(',', '.')}`, {
                method: 'GET',
                headers: authHeader.authHeader(),
            }).then(response =>
                    response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                    ).then(res => {
                        if (res.status === 401) {
                            EventBus.dispatch("logout", "");
                        }
                        let data = res.data as GetInterpassTemperatureRangeOfQualificationResponse;
                        console.info(data);
                        dispatch({ type: 'SET_INTERPASS_TEMPERATURE_RANGE_OF_QUALIFICATION', interpassTemperatureRangeOfQualification: data.rangeOfQualification });
                    }))
                .catch(error => {
                    console.error(error);
                });
        }
    },
    initTensileTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTesting: TensileTesting = { ...appState.wpqrDekraTab.tensileTesting };
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows.splice(0, tensileTestingResults.tensileTestingResultsTableRows.length);
            for (let i = 0; i < 2; i++) {
                tensileTestingResults.tensileTestingResultsTableRows.splice(tensileTestingResults.tensileTestingResultsTableRows.length, 0, new TensileTestingResultsTableRow('', '', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_TENSILE_TESTING', tensileTesting: tensileTesting, tensileTestingResults: tensileTestingResults });
        }
    },
    initTransverseTensileTesting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTesting: TransverseTensileTesting = { ...appState.wpqrDekraTab.transverseTensileTesting };
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.splice(0, transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.length);
            for (let i = 0; i < 2; i++) {
                transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.splice(transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.length, 0, new TransverseTensileTestingResultsTableRow('', '', '', '', '', '', '', '', ''));
            }
            dispatch({ type: 'INIT_TRANSVERSE_TENSILE_TESTING', transverseTensileTesting: transverseTensileTesting, transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    createWpqr: (createWpqrRequest: WpqrRequest, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab && appState.wParameters && appState.testResultsExamination && appState.rangeOfQualification &&
            appState.testingOrder &&
            appState.drawJointDesign &&
            appState.fileUpload &&
            appState.weldSequence &&
            appState.typeOfBacking &&
            appState.login) {
            // Get the TypeOfBackings values.
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection4Request.typeOfBackingText = appState.typeOfBacking.typeOfBackingData.typeOfBackingText;
            // CurrentUser
            let currentUser: UserListObject = { ...appState.login.currentUser };
            createWpqrRequest.createWeldingProtocolRequest.employeeDisplayName = (currentUser.firstName ? currentUser.firstName + " " : "") + (currentUser.lastName ? currentUser.lastName : "")

            // Get the WeldingParameters values.
            let createWeldingParametersRequests: Array<CreateWeldingParametersRequest> = [];
            for (let i = 0; i < appState.wParameters.weldingParameters.length; i++) {
                let updateWeldingParametersRequest: CreateWeldingParametersRequest = {
                    run: !isNaN(parseInt(appState.wParameters.weldingParameters[i].run)) ? parseInt(appState.wParameters.weldingParameters[i].run) : 0,
                    runText: appState.wParameters.weldingParameters[i].runText,
                    indexId: (appState.wParameters.weldingParameters[i].index.id !== 0) ? appState.wParameters.weldingParameters[i].index.id : undefined,
                    diameter: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].diameter.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].diameter.replace(',', '.')) : 0.0,
                    voltage: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltage.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltage.replace(',', '.')) : 0.0,
                    voltageMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageMin.replace(',', '.')) : 0.0,
                    voltageMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageMax.replace(',', '.')) : 0.0,
                    current: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].current.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].current.replace(',', '.')) : 0.0,
                    currentMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentMin.replace(',', '.')) : 0.0,
                    currentMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentMax.replace(',', '.')) : 0.0,
                    travelSpeed: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeed.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeed.replace(',', '.')) : 0.0,
                    travelSpeedMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMin.replace(',', '.')) : 0.0,
                    travelSpeedMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMax.replace(',', '.')) : 0.0,
                    heatInput: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInput.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInput.replace(',', '.')) : 0.0,
                    heatInputMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInputMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInputMin.replace(',', '.')) : 0.0,
                    heatInputMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInputMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInputMax.replace(',', '.')) : 0.0,
                    flowRateTop: setFlowRateTop(i),
                    flowRateRoot: setFlowRoot(),
                    wireFeed: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].wireFeed.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].wireFeed.replace(',', '.')) : 0.0,
                    currentTypeId: (appState.wParameters.weldingParameters[i].currentType.id !== 0) ? appState.wParameters.weldingParameters[i].currentType.id : undefined,
                    weldingMethodId: (appState.wParameters.weldingParameters[i].weldingMethod.id !== 0) ? appState.wParameters.weldingParameters[i].weldingMethod.id : undefined,
                    runLength: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].runLength.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].runLength.replace(',', '.')) : 0.0,
                    time: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].time.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].time.replace(',', '.')) : 0.0,
                    interpassTemperature: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].interpassTemperature.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].interpassTemperature.replace(',', '.')) : 0.0,
                    runWidth: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].runWidth.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].runWidth.replace(',', '.')) : 0.0,
                    pulseCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].pulseCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].pulseCurrent.replace(',', '.')) : 0.0,
                    pulseTime: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].pulseTime.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].pulseTime.replace(',', '.')) : 0.0,
                    basicCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].basicCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].basicCurrent.replace(',', '.')) : 0.0,
                    timeForBasicCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].timeForBasicCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].timeForBasicCurrent.replace(',', '.')) : 0.0,
                    frequency: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].frequency.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].frequency.replace(',', '.')) : 0.0,
                    current1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].current1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].current1.replace(',', '.')) : 0.0,
                    time1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].time1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].time1.replace(',', '.')) : 0.0,
                    heatInput1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInput1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInput1.replace(',', '.')) : 0.0,
                    arcEnergyIE: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIE.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIE.replace(',', '.')) : 0.0,
                    arcEnergyIP: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIP.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIP.replace(',', '.')) : 0.0,
                    arcIP: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcIP.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcIP.replace(',', '.')) : 0.0,
                    arcIE: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcIE.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcIE.replace(',', '.')) : 0.0,
                    additiveMaterial: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].additiveMaterial.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].additiveMaterial.replace(',', '.')) : 0.0,
                    weldingTecknique: appState.wParameters.weldingParameters[i].weldingTecknique,
                    fuelGasPressure: appState.wParameters.weldingParameters[i].fuelGasPressure,
                    oxygenPressure: appState.wParameters.weldingParameters[i].oxygenPressure,
                    typeOfFlame: appState.wParameters.weldingParameters[i].typeOfFlame,
                };
                createWeldingParametersRequests.push(updateWeldingParametersRequest);
            };
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection6Request.createWeldingParametersRequests = createWeldingParametersRequests;

            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.materialThickness1 = !isNaN(parseFloat(appState.drawJointDesign.materialThickness1.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.materialThickness1.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.materialThickness2 = !isNaN(parseFloat(appState.drawJointDesign.materialThickness2.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.materialThickness2.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.jointTypeId = (appState.drawJointDesign.selectedJointType.id !== 0) ? appState.drawJointDesign.selectedJointType.id : undefined;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.jointShapeId = (appState.drawJointDesign.selectedJointShape.id !== 0) ? appState.drawJointDesign.selectedJointShape.id : undefined;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.jointAngleAlpha = !isNaN(parseFloat(appState.drawJointDesign.jointAngleAlpha.toString().toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.jointAngleAlpha.toString().toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.gapOpening = !isNaN(parseFloat(appState.drawJointDesign.gapOpening.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.gapOpening.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.straightEdge = !isNaN(parseFloat(appState.drawJointDesign.straightEdge.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.straightEdge.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.phaseAngle1 = !isNaN(parseFloat(appState.drawJointDesign.phaseAngle1.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.phaseAngle1.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.phaseAngle2 = !isNaN(parseFloat(appState.drawJointDesign.phaseAngle2.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.phaseAngle2.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.jointAngleBeta = !isNaN(parseFloat(appState.drawJointDesign.jointAngleBeta.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.jointAngleBeta.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.throatThickness = !isNaN(parseFloat(appState.drawJointDesign.throatThickness.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.throatThickness.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.rootSupportId = (appState.drawJointDesign.selectedRootSupport.id !== 0) ? appState.drawJointDesign.selectedRootSupport.id : undefined;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.depthOfPreparation = !isNaN(parseFloat(appState.drawJointDesign.depthOfPreparation.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.depthOfPreparation.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.radius = !isNaN(parseFloat(appState.drawJointDesign.radius.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.radius.toString().replace(',', '.')) : 0.0;
            createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.note = appState.drawJointDesign.note;
            //createWpqrRequest.createWeldingProtocolRequest.createWeldingProtocolSection7_8Request.createJointDesignRequest.jointDesignHtmlImage = appState.drawJointDesign.jointDesignHtmlImage;
            // Get the TensileTestingResultsTableRow values.
            let createTensileTestingResultsTableRows: Array<CreateTestingResultsTableRowRequest> = [];
            for (let i = 0; i < appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows.length; i++) {
                let createTensileTestingResultsTableRow: CreateTestingResultsTableRowRequest = {
                    a: appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows[i].a,
                    acceptance: appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows[i].acceptance,
                    rp: appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows[i].rp,
                    fractureSurfaceCondition: appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows[i].fractureSurfaceCondition,
                    r: appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows[i].r,
                    rm: appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows[i].rm,
                    testSpecimenDimensions: appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows[i].testSpecimenDimensions,
                    testSpecimenNumber: appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows[i].testSpecimenNumber,
                    z: appState.testResultsExamination.longitudinalTensileTestingResults.tensileTestingResultsTableRows[i].z,
                    placeOfBreak: '',
                    bendingAngle: '',
                    diameterOfRoller: '',
                    distanceBetweenRollers: '',
                    surfaceCondition: '',
                    testSpecimenType: '',

                };
                createTensileTestingResultsTableRows.push(createTensileTestingResultsTableRow);
            };
            
            createWpqrRequest.createTestResultsExaminationRequest.createLongitudinalTensileTestingResultsRequest = {
                acceptableValuesUA: appState.testResultsExamination.longitudinalTensileTestingResults.acceptableValuesUA,
                acceptableValuesUFm: appState.testResultsExamination.longitudinalTensileTestingResults.acceptableValuesUFm,
                acceptableValuesUR: appState.testResultsExamination.longitudinalTensileTestingResults.acceptableValuesUR,
                acceptableValuesURm: appState.testResultsExamination.longitudinalTensileTestingResults.acceptableValuesURm,
                acceptableValuesUZ: appState.testResultsExamination.longitudinalTensileTestingResults.acceptableValuesUZ,
                acceptanceNormsStandard: appState.testResultsExamination.longitudinalTensileTestingResults.acceptanceNormsStandard,
                acceptanceResult: appState.testResultsExamination.longitudinalTensileTestingResults.acceptanceResult,
                certificationNumber: appState.testResultsExamination.longitudinalTensileTestingResults.certificationNumber,
                comments: appState.testResultsExamination.longitudinalTensileTestingResults.comments,
                deviationFromStandardsOrAgreement: appState.testResultsExamination.longitudinalTensileTestingResults.deviationFromStandardsOrAgreement,
                measurementUncertaintyUA: appState.testResultsExamination.longitudinalTensileTestingResults.measurementUncertaintyUA,
                measurementUncertaintyUFm: appState.testResultsExamination.longitudinalTensileTestingResults.measurementUncertaintyUFm,
                measurementUncertaintyUR: appState.testResultsExamination.longitudinalTensileTestingResults.measurementUncertaintyUR,
                measurementUncertaintyURm: appState.testResultsExamination.longitudinalTensileTestingResults.measurementUncertaintyURm,
                measurementUncertaintyUZ: appState.testResultsExamination.longitudinalTensileTestingResults.measurementUncertaintyUZ,
                tensileTestingResultsTableRows: createTensileTestingResultsTableRows,
                testingPerformedBy: appState.testResultsExamination.longitudinalTensileTestingResults.testingPerformedBy,
                testingTemperature: appState.testResultsExamination.longitudinalTensileTestingResults.testingTemperature,
            }

            // Get the TransverseTensileTestingResults values.
            let createTransverseTensileTestingResultsTabelRows: Array<CreateTestingResultsTableRowRequest> = [];
            for (let i = 0; i < appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.length; i++) {
                let createTestingResultsTableRow: CreateTestingResultsTableRowRequest = {
                    a: appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[i].a,
                    acceptance: appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[i].acceptance,
                    rp: appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[i].rp,
                    fractureSurfaceCondition: appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[i].fractureSurfaceCondition,
                    r: appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[i].r,
                    rm: appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[i].rm,
                    testSpecimenDimensions: appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[i].testSpecimenDimensions,
                    testSpecimenNumber: appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[i].testSpecimenNumber,
                    z: '',
                    placeOfBreak: appState.testResultsExamination.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[i].placeOfBreak,
                    bendingAngle: '',
                    diameterOfRoller: '',
                    distanceBetweenRollers: '',
                    surfaceCondition: '',
                    testSpecimenType: '',
                };
                createTransverseTensileTestingResultsTabelRows.push(createTestingResultsTableRow);
            };

            createWpqrRequest.createTestResultsExaminationRequest.createTransverseTensileTestingResultsRequest = {
                acceptableValuesUA: appState.testResultsExamination.transverseTensileTestingResults.acceptableValuesUA,
                acceptableValuesUFm: appState.testResultsExamination.transverseTensileTestingResults.acceptableValuesUFm,
                acceptableValuesUR: appState.testResultsExamination.transverseTensileTestingResults.acceptableValuesUR,
                acceptableValuesURm: appState.testResultsExamination.transverseTensileTestingResults.acceptableValuesURm,
                acceptanceNormsStandard: appState.testResultsExamination.transverseTensileTestingResults.acceptanceNormsStandard,
                acceptanceResult: appState.testResultsExamination.transverseTensileTestingResults.acceptanceResult,
                certificationNumber: appState.testResultsExamination.transverseTensileTestingResults.certificationNumber,
                comments: appState.testResultsExamination.transverseTensileTestingResults.comments,
                deviationFromStandardsOrAgreement: appState.testResultsExamination.transverseTensileTestingResults.deviationFromStandardsOrAgreement,
                measurementUncertaintyUA: appState.testResultsExamination.transverseTensileTestingResults.measurementUncertaintyUA,
                measurementUncertaintyUFm: appState.testResultsExamination.transverseTensileTestingResults.measurementUncertaintyUFm,
                measurementUncertaintyUR: appState.testResultsExamination.transverseTensileTestingResults.measurementUncertaintyUR,
                measurementUncertaintyURm: appState.testResultsExamination.transverseTensileTestingResults.measurementUncertaintyURm,
                testingPerformedBy: appState.testResultsExamination.transverseTensileTestingResults.testingPerformedBy,
                testingTemperature: appState.testResultsExamination.transverseTensileTestingResults.testingTemperature,
                transverseTensileTestingResultsTabelRows: createTransverseTensileTestingResultsTabelRows,
                acceptableValuesUA1: appState.testResultsExamination.transverseTensileTestingResults.acceptableValuesUA1,
                acceptableValuesUFm1: appState.testResultsExamination.transverseTensileTestingResults.acceptableValuesUFm1,
                acceptableValuesUR1: appState.testResultsExamination.transverseTensileTestingResults.acceptableValuesUR1,
                acceptableValuesURm1: appState.testResultsExamination.transverseTensileTestingResults.acceptableValuesURm1,
            }

            // Get the createBendTestingResultsRequest values.
            let createBendTestingResultsTableRows: Array<CreateTestingResultsTableRowRequest> = [];
            for (let i = 0; i < appState.testResultsExamination.bendTestingResults.bendTestingResultsTableRows.length; i++) {
                let createTestingResultsTableRow: CreateTestingResultsTableRowRequest = {
                    a: '',
                    acceptance: appState.testResultsExamination.bendTestingResults.bendTestingResultsTableRows[i].acceptance,
                    fractureSurfaceCondition: '',
                    r: '',
                    rm: '',
                    testSpecimenDimensions: appState.testResultsExamination.bendTestingResults.bendTestingResultsTableRows[i].testSpecimenDimensions,
                    testSpecimenNumber: appState.testResultsExamination.bendTestingResults.bendTestingResultsTableRows[i].testSpecimenNumber,
                    z: '',
                    bendingAngle: appState.testResultsExamination.bendTestingResults.bendTestingResultsTableRows[i].bendingAngle,
                    diameterOfRoller: appState.testResultsExamination.bendTestingResults.bendTestingResultsTableRows[i].diameterOfRoller,
                    distanceBetweenRollers: appState.testResultsExamination.bendTestingResults.bendTestingResultsTableRows[i].distanceBetweenRollers,
                    surfaceCondition: appState.testResultsExamination.bendTestingResults.bendTestingResultsTableRows[i].surfaceCondition,
                    testSpecimenType: appState.testResultsExamination.bendTestingResults.bendTestingResultsTableRows[i].testSpecimenType,
                    placeOfBreak: ''
                };
                createBendTestingResultsTableRows.push(createTestingResultsTableRow);
            };

            createWpqrRequest.createTestResultsExaminationRequest.createBendTestingResultsRequest = {
                acceptanceNormsStandard: appState.testResultsExamination.bendTestingResults.acceptanceNormsStandard,
                acceptanceResult: appState.testResultsExamination.bendTestingResults.acceptanceResult,
                certificationNumber: appState.testResultsExamination.bendTestingResults.certificationNumber,
                comments: appState.testResultsExamination.bendTestingResults.comments,
                deviationFromStandardsOrAgreement: appState.testResultsExamination.bendTestingResults.deviationFromStandardsOrAgreement,
                testingTemperature: appState.testResultsExamination.bendTestingResults.testingTemperature,
                acceptableValues: appState.testResultsExamination.bendTestingResults.acceptableValues,
                testingPerformedBy: appState.testResultsExamination.bendTestingResults.testingPerformedBy,
                bendTestingResultsTableRows: createBendTestingResultsTableRows,
            }

            // Get the impactTestingResults values.
            let CreateWeldImpactTestingResultsRequest: Array<CreateTestingResultsTableRowRequest> = [];
            for (let i = 0; i < appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows.length; i++) {
                let createTestingResultsTableRow: CreateTestingResultsTableRowRequest = {
                    a: '',
                    acceptance: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].acceptance,
                    fractureSurfaceCondition: '',
                    r: '',
                    rm: '',
                    testSpecimenDimensions: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].testSpecimenDimensions,
                    testSpecimenNumber: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].testSpecimenNumber,
                    z: '',
                    bendingAngle: '',
                    diameterOfRoller: '',
                    distanceBetweenRollers: '',
                    surfaceCondition: '',
                    testSpecimenType: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].testSpecimenType,
                    placeOfBreak: '',
                    absorbedEnergy: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].absorbedEnergy,
                    lateralExpansion: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].lateralExpansion,
                    nominalEnergy: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].nominalEnergy,
                    shear: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].shear,
                    average: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].average,
                    absorbedEnergy1: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].absorbedEnergy1,
                    absorbedEnergy2: appState.testResultsExamination.impactTestingResults.weldImpactTestingResultsTableRows[i].absorbedEnergy2,
                };
                CreateWeldImpactTestingResultsRequest.push(createTestingResultsTableRow);
            };


            createWpqrRequest.createTestResultsExaminationRequest.createImpactTestingResultsRequest = {
                acceptableValues: appState.testResultsExamination.impactTestingResults.acceptableValues,
                acceptanceNormsStandard: appState.testResultsExamination.impactTestingResults.acceptanceNormsStandard,
                certificationNumber: appState.testResultsExamination.impactTestingResults.certificationNumber,
                comments: appState.testResultsExamination.impactTestingResults.comments,
                deviationFromStandardsOrAgreement: appState.testResultsExamination.impactTestingResults.deviationFromStandardsOrAgreement,
                testingPerformedBy: appState.testResultsExamination.impactTestingResults.testingPerformedBy,
                testingTemperature: appState.testResultsExamination.impactTestingResults.testingTemperature,
                weldAcceptanceResults: appState.testResultsExamination.impactTestingResults.weldAcceptanceResults,
                weldAverageAbsorbedEnergy: appState.testResultsExamination.impactTestingResults.weldAverageAbsorbedEnergy,
                weldAverageLateralExpansion: appState.testResultsExamination.impactTestingResults.weldAverageLateralExpansion,
                weldAverageShear: appState.testResultsExamination.impactTestingResults.weldAverageShear,
                weldAverageAcceptance: appState.testResultsExamination.impactTestingResults.weldAverageAcceptance,
                weldComments: appState.testResultsExamination.impactTestingResults.weldComments,
                weldImpactTestingResultsTableRows: CreateWeldImpactTestingResultsRequest,
                weldMeasurementUncertaintyAbsorbedEnergy: appState.testResultsExamination.impactTestingResults.weldMeasurementUncertaintyAbsorbedEnergy,
                weldMeasurementUncertaintyLateralExpansion: appState.testResultsExamination.impactTestingResults.weldMeasurementUncertaintyLateralExpansion,
                weldMeasurementUncertaintyShear: appState.testResultsExamination.impactTestingResults.weldMeasurementUncertaintyShear,
                weldMeasurementUncertaintyAcceptance: appState.testResultsExamination.impactTestingResults.weldMeasurementUncertaintyAcceptance,
                type: appState.testResultsExamination.impactTestingResults.type,
            }

            // Get the impactTestingResults values.
            let CreateHardnessTestingResultsTable1Request: Array<CreateHardnessTestingResultsTableRowRequest> = [];
            for (let i = 0; i < appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows.length; i++) {
                let createHardnessTestingResultsTable1Row: CreateHardnessTestingResultsTableRowRequest = {
                    parent1: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].parent1,
                    parent2: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].parent2,
                    parent3: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].parent3,
                    haz4: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz4,
                    haz5: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz5,
                    haz6: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz6,
                    weld7: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].weld7,
                    weld8: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].weld8,
                    weld9: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].weld9,
                    haz10: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz10,
                    haz11: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz11,
                    haz12: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz12,
                    parent13: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].parent13,
                    parent14: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].parent14,
                    parent15: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].parent15,
                    haz16: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz16,
                    haz17: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz17,
                    haz18: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz18,
                    haz19: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable1Rows[i].haz19,
                };
                CreateHardnessTestingResultsTable1Request.push(createHardnessTestingResultsTable1Row);
            };

            let CreateHardnessTestingResultsTable2Request: Array<CreateHardnessTestingResultsTableRowRequest> = [];
            for (let i = 0; i < appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows.length; i++) {
                let createHardnessTestingResultsTable2Row: CreateHardnessTestingResultsTableRowRequest = {
                    parent1: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].parent1,
                    parent2: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].parent2,
                    parent3: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].parent3,
                    haz4: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz4,
                    haz5: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz5,
                    haz6: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz6,
                    weld7: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].weld7,
                    weld8: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].weld8,
                    weld9: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].weld9,
                    haz10: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz10,
                    haz11: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz11,
                    haz12: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz12,
                    parent13: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].parent13,
                    parent14: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].parent14,
                    parent15: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].parent15,
                    haz16: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz16,
                    haz17: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz17,
                    haz18: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz18,
                    haz19: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable2Rows[i].haz19,
                };
                CreateHardnessTestingResultsTable2Request.push(createHardnessTestingResultsTable2Row);
            };

            let CreateHardnessTestingResultsTable3Request: Array<CreateHardnessTestingResultsTableRowRequest> = [];
            for (let i = 0; i < appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows.length; i++) {
                let createHardnessTestingResultsTable3Row: CreateHardnessTestingResultsTableRowRequest = {
                    parent1: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].parent1,
                    parent2: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].parent2,
                    parent3: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].parent3,
                    haz4: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz4,
                    haz5: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz5,
                    haz6: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz6,
                    weld7: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].weld7,
                    weld8: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].weld8,
                    weld9: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].weld9,
                    haz10: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz10,
                    haz11: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz11,
                    haz12: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz12,
                    parent13: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].parent13,
                    parent14: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].parent14,
                    parent15: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].parent15,
                    haz16: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz16,
                    haz17: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz17,
                    haz18: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz18,
                    haz19: appState.testResultsExamination.hardnessTestingResults.hardnessTestingResultsTable3Rows[i].haz19,
                };
                CreateHardnessTestingResultsTable3Request.push(createHardnessTestingResultsTable3Row);
            };

            createWpqrRequest.createTestResultsExaminationRequest.createHardnessTestingResultsRequest = {
                acceptableValues: appState.testResultsExamination.hardnessTestingResults.acceptableValues,
                acceptance: appState.testResultsExamination.hardnessTestingResults.acceptance,
                acceptanceNormsStandard: appState.testResultsExamination.hardnessTestingResults.acceptanceNormsStandard,
                acceptanceResults: appState.testResultsExamination.hardnessTestingResults.acceptanceResults,
                certificationNumber: appState.testResultsExamination.hardnessTestingResults.certificationNumber,
                comments: appState.testResultsExamination.hardnessTestingResults.comments,
                deviationFromStandardsOrAgreement: appState.testResultsExamination.hardnessTestingResults.deviationFromStandardsOrAgreement,
                hardnessTestingResultsTable1Rows: CreateHardnessTestingResultsTable1Request,
                hardnessTestingResultsTable2Rows: CreateHardnessTestingResultsTable2Request,
                hardnessTestingResultsTable3Rows: CreateHardnessTestingResultsTable3Request,
                measurementUncertainty: appState.testResultsExamination.hardnessTestingResults.measurementUncertainty,
                testingPerformedBy: appState.testResultsExamination.hardnessTestingResults.testingPerformedBy,
                testingTemperature: appState.testResultsExamination.hardnessTestingResults.testingTemperature,
                wayOfMeasurementOfHardness1: appState.testResultsExamination.hardnessTestingResults.wayOfMeasurementOfHardness1,
                wayOfMeasurementOfHardness2: appState.testResultsExamination.hardnessTestingResults.wayOfMeasurementOfHardness2,
                wayOfMeasurementOfHardness3: appState.testResultsExamination.hardnessTestingResults.wayOfMeasurementOfHardness3,
            }

            let CreateMacroTestingResultsRequest: Array<CreateTestingResultsTableRowRequest> = [];
            for (let i = 0; i < appState.testResultsExamination.macroTestingResults.macroTestingResultsTableRows.length; i++) {
                let createMacroTestingResultsTableRow: CreateTestingResultsTableRowRequest = {
                    a: '',
                    acceptance: appState.testResultsExamination.macroTestingResults.macroTestingResultsTableRows[i].acceptance,
                    fractureSurfaceCondition: '',
                    r: '',
                    rm: '',
                    testSpecimenDimensions: '',
                    testSpecimenNumber: appState.testResultsExamination.macroTestingResults.macroTestingResultsTableRows[i].testSpecimenNumber,
                    z: '',
                    bendingAngle: '',
                    diameterOfRoller: '',
                    distanceBetweenRollers: '',
                    surfaceCondition: '',
                    testSpecimenType: '',
                    placeOfBreak: '',
                    absorbedEnergy: '',
                    lateralExpansion: '',
                    nominalEnergy: '',
                    shear: '',
                    examinationResult: appState.testResultsExamination.macroTestingResults.macroTestingResultsTableRows[i].examinationResult
                };
                CreateMacroTestingResultsRequest.push(createMacroTestingResultsTableRow);
            };

            let CreateMicroTestingResultsRequest: Array<CreateTestingResultsTableRowRequest> = [];
            for (let i = 0; i < appState.testResultsExamination.microTestingResults.microTestingResultsTableRows.length; i++) {
                let createMicroTestingResultsTableRow: CreateTestingResultsTableRowRequest = {
                    a: '',
                    acceptance: appState.testResultsExamination.microTestingResults.microTestingResultsTableRows[i].acceptance,
                    fractureSurfaceCondition: '',
                    r: '',
                    rm: '',
                    testSpecimenDimensions: '',
                    testSpecimenNumber: appState.testResultsExamination.microTestingResults.microTestingResultsTableRows[i].testSpecimenNumber,
                    z: '',
                    bendingAngle: '',
                    diameterOfRoller: '',
                    distanceBetweenRollers: '',
                    surfaceCondition: '',
                    testSpecimenType: '',
                    placeOfBreak: '',
                    absorbedEnergy: '',
                    lateralExpansion: '',
                    nominalEnergy: '',
                    shear: '',
                    examinationResult: appState.testResultsExamination.microTestingResults.microTestingResultsTableRows[i].examinationResult
                };
                CreateMicroTestingResultsRequest.push(createMicroTestingResultsTableRow);
            };
            // Set the createTestResultsExaminationValuesRequest.
            createWpqrRequest.createTestResultsExaminationRequest.createTestResultsExaminationValuesRequest = {
                macroTestingResultsTableRows: CreateMacroTestingResultsRequest,
                microTestingResultsTableRows: CreateMicroTestingResultsRequest,
                magneticTest: appState.testResultsExamination.magneticTest,
                penetrantTest: appState.testResultsExamination.penetrantTest,
                radiographicExamination: appState.testResultsExamination.radiographicExamination,
                ultrasonicExamination: appState.testResultsExamination.ultrasonicExamination,
                visualExamination: appState.testResultsExamination.visualExamination,

                otherTesting: appState.testResultsExamination.otherDetailsTestingResults.otherTesting,
                remarks: appState.testResultsExamination.otherDetailsTestingResults.remarks,
                testsPerformedWithAcceptableResult: appState.testResultsExamination.otherDetailsTestingResults.testsPerformedWithAcceptableResult,
                laboratoryReportReferenceNo: appState.testResultsExamination.otherDetailsTestingResults.laboratoryReportReferenceNo,
                testCarriedOutInThePresenceOf: appState.testResultsExamination.otherDetailsTestingResults.testCarriedOutInThePresenceOf,
            }
            // Set the createTestingDetails1ExaminationRequest
                createWpqrRequest.createTestingDetails1ExaminationRequest.manufacturer2 = appState.wpqrDekraTab.weldingTestingDetails1.manufacturer;
                createWpqrRequest.createTestingDetails1ExaminationRequest.manufacturer2Signature = appState.wpqrDekraTab.weldingTestingDetails1.manufacturer2Signature;
                createWpqrRequest.createTestingDetails1ExaminationRequest.manufacturer2Name = appState.wpqrDekraTab.weldingTestingDetails1.manufacturer2Name;
                createWpqrRequest.createTestingDetails1ExaminationRequest.manufacturer2Date = appState.wpqrDekraTab.weldingTestingDetails1.manufacturer2Date;
                createWpqrRequest.createTestingDetails1ExaminationRequest.examiner = appState.wpqrDekraTab.weldingTestingDetails1.examiner;
                createWpqrRequest.createTestingDetails1ExaminationRequest.examinerSignature = appState.wpqrDekraTab.weldingTestingDetails1.examinerSignature;
                createWpqrRequest.createTestingDetails1ExaminationRequest.examinerName = appState.wpqrDekraTab.weldingTestingDetails1.examinerName;
                createWpqrRequest.createTestingDetails1ExaminationRequest.examinerDate = appState.wpqrDekraTab.weldingTestingDetails1.examinerDate;
                createWpqrRequest.createTestingDetails1ExaminationRequest.commentInformation = appState.wpqrDekraTab.weldingTestingDetails1.commentInformation;

            // Set the createRangeOfQualificationExaminationRequest
            createWpqrRequest.createRangeOfQualificationExaminationRequest = {
                address: appState.rangeOfQualification.rangeOfQualificationInfo.address,
                dateOfIssue: appState.rangeOfQualification.rangeOfQualificationInfo.dateOfIssue,
                dateOfWelding: appState.rangeOfQualification.rangeOfQualificationInfo.dateOfWelding,
                location: appState.rangeOfQualification.rangeOfQualificationInfo.location,
                manufacturer: appState.rangeOfQualification.rangeOfQualificationInfo.manufacturer,
                testingStandardId: appState.rangeOfQualification.rangeOfQualificationInfo.testingStandardId,
                enableStandardText: appState.rangeOfQualification.rangeOfQualificationInfo.enableStandardText,
                standardText: appState.rangeOfQualification.rangeOfQualificationInfo.standardText,
                weldingProcess: appState.rangeOfQualification.rangeOfQualificationInfo.enableWeldingProcessText ? appState.rangeOfQualification.rangeOfQualificationInfo.weldingProcess : HelpFunctions.arrayJoinToString(appState.wpqrDekraTab.weldingMethodRangeOfQualifications),
                jointType: appState.rangeOfQualification.rangeOfQualificationInfo.enableJointTypeText ? appState.rangeOfQualification.rangeOfQualificationInfo.jointType : appState.wpqrDekraTab.jointTypeRangeOfQualification,
                materialThicknesses: appState.rangeOfQualification.rangeOfQualificationInfo.enableMaterialThicknessesText ? appState.rangeOfQualification.rangeOfQualificationInfo.materialThicknesses : HelpFunctions.arrayJoinToString(appState.wpqrDekraTab.materialThicknessRangeOfQualifications),
                designations: appState.rangeOfQualification.rangeOfQualificationInfo.designations,
                shieldingGases: appState.rangeOfQualification.rangeOfQualificationInfo.enableShieldingGasesText ? appState.rangeOfQualification.rangeOfQualificationInfo.shieldingGases : HelpFunctions.arrayJoinToString(appState.wpqrDekraTab.shieldingGasRangeOfQualifications),
                currentTypes: appState.rangeOfQualification.rangeOfQualificationInfo.enableCurrentTypesText ? appState.rangeOfQualification.rangeOfQualificationInfo.currentTypes : HelpFunctions.arrayJoinToString(appState.wpqrDekraTab.currentTypeRangeOfQualifications),
                dropletTransitions: appState.rangeOfQualification.rangeOfQualificationInfo.enableDropletTransitionsText ? appState.rangeOfQualification.rangeOfQualificationInfo.dropletTransitions : HelpFunctions.arrayJoinToString(appState.wpqrDekraTab.dropletTransitionRangeOfQualifications),
                makes: appState.rangeOfQualification.rangeOfQualificationInfo.enableMakesText ? appState.rangeOfQualification.rangeOfQualificationInfo.makes : HelpFunctions.arrayJoinToString(appState.wpqrDekraTab.makeRangeOfQualifications),
                otherInformations: appState.rangeOfQualification.rangeOfQualificationInfo.otherInformations,
                weldingMetalThicknessText: appState.rangeOfQualification.rangeOfQualificationInfo.enableWeldingMetalThicknessText ? appState.rangeOfQualification.rangeOfQualificationInfo.weldingMetalThicknessText : appState.wpqrDekraTab.weldingMetalThicknessRangeOfQualification,
                enableWeldingMetalThicknessText: appState.rangeOfQualification.rangeOfQualificationInfo.enableWeldingMetalThicknessText,
                materialGroupText: appState.rangeOfQualification.rangeOfQualificationInfo.enableMaterialGroupText ? appState.rangeOfQualification.rangeOfQualificationInfo.materialGroupText : appState.wpqrDekraTab.materialGroupRangeOfQualification,
                enableMaterialGroupText: appState.rangeOfQualification.rangeOfQualificationInfo.enableMaterialGroupText,
                enableMaterialThicknessesText: appState.rangeOfQualification.rangeOfQualificationInfo.enableMaterialThicknessesText,
                weldingPositionsText: appState.rangeOfQualification.rangeOfQualificationInfo.weldingPositionsText.toString(),
                enableWeldingProcessText: appState.rangeOfQualification.rangeOfQualificationInfo.enableWeldingProcessText,
                enableJointTypeText: appState.rangeOfQualification.rangeOfQualificationInfo.enableJointTypeText,
                enableThroatThicknessText: appState.rangeOfQualification.rangeOfQualificationInfo.enableThroatThicknessText,
                enableRunTypeText: appState.rangeOfQualification.rangeOfQualificationInfo.enableRunTypeText,
                enableDiameterText: appState.rangeOfQualification.rangeOfQualificationInfo.enableDiameterText,
                enableMakesText: appState.rangeOfQualification.rangeOfQualificationInfo.enableMakesText,
                enableShieldingGasesText: appState.rangeOfQualification.rangeOfQualificationInfo.enableShieldingGasesText,
                enableRootProtectionGasText: appState.rangeOfQualification.rangeOfQualificationInfo.enableRootProtectionGasText,
                enableCurrentTypesText: appState.rangeOfQualification.rangeOfQualificationInfo.enableCurrentTypesText,
                enableDropletTransitionsText: appState.rangeOfQualification.rangeOfQualificationInfo.enableDropletTransitionsText,
                enableHeatInputText: appState.rangeOfQualification.rangeOfQualificationInfo.enableHeatInputText,
                enableArcEnergyText: appState.rangeOfQualification.rangeOfQualificationInfo.enableArcEnergyText,
                enableDesignationsText: appState.rangeOfQualification.rangeOfQualificationInfo.enableDesignationsText,
                enablePreheatTemperatureText: appState.rangeOfQualification.rangeOfQualificationInfo.enablePreheatTemperatureText,
                enableInterpassTemperatureText: appState.rangeOfQualification.rangeOfQualificationInfo.enableInterpassTemperatureText,
                enablePostWeldHeatTreatmentText: appState.rangeOfQualification.rangeOfQualificationInfo.enablePostWeldHeatTreatmentText,
                throatThicknessText: appState.rangeOfQualification.rangeOfQualificationInfo.throatThicknessText,
                runTypeText: appState.rangeOfQualification.rangeOfQualificationInfo.enableRunTypeText ? appState.rangeOfQualification.rangeOfQualificationInfo.runTypeText : appState.wpqrDekraTab.runTypeRangeOfQualification,
                diameterText: appState.rangeOfQualification.rangeOfQualificationInfo.enableDiameterText ? appState.rangeOfQualification.rangeOfQualificationInfo.diameterText : appState.wpqrDekraTab.diameterRangeOfQualification,
                rootProtectionGasText: appState.rangeOfQualification.rangeOfQualificationInfo.enableRootProtectionGasText ? appState.rangeOfQualification.rangeOfQualificationInfo.rootProtectionGasText : appState.wpqrDekraTab.rootProtectionGasRangeOfQualification,
                heatInputText: appState.rangeOfQualification.rangeOfQualificationInfo.heatInputText,
                arcEnergyText: appState.rangeOfQualification.rangeOfQualificationInfo.arcEnergyText,
                postWeldHeatTreatmentText: appState.rangeOfQualification.rangeOfQualificationInfo.enablePostWeldHeatTreatmentText ? appState.rangeOfQualification.rangeOfQualificationInfo.postWeldHeatTreatmentText : appState.wpqrDekraTab.postWeldHeatTreatmentRangeOfQualification,
                interpassTemperatureText: appState.rangeOfQualification.rangeOfQualificationInfo.enableInterpassTemperatureText ? appState.rangeOfQualification.rangeOfQualificationInfo.interpassTemperatureText : appState.wpqrDekraTab.interpassTemperatureRangeOfQualification,
                preheatTemperatureText: appState.rangeOfQualification.rangeOfQualificationInfo.enablePreheatTemperatureText ? appState.rangeOfQualification.rangeOfQualificationInfo.preheatTemperatureText : appState.wpqrDekraTab.preheatTemperatureRangeOfQualification,
            }
            // Set the createRangeOfQualificationExaminationRequest
            createWpqrRequest.createTestingOrderRequest = {
                additionalTesting: appState.testingOrder.qwbpOrder.additionalTesting,
                bendTesting: appState.testingOrder.qwbpOrder.bendTesting,
                chargeNumber1: appState.testingOrder.qwbpOrder.chargeNumber1,
                chargeNumber2: appState.testingOrder.qwbpOrder.chargeNumber2,
                conditionsAndDescriptionRemarks: appState.testingOrder.qwbpOrder.conditionsAndDescriptionRemarks,
                contactPerson: appState.testingOrder.qwbpOrder.contactPerson,
                customer: appState.testingOrder.qwbpOrder.customer,
                date: appState.testingOrder.qwbpOrder.date,
                destructiveTestingApproved: appState.testingOrder.qwbpOrder.destructiveTestingApproved,
                destructiveTestingDate: appState.testingOrder.qwbpOrder.destructiveTestingDate,
                destructiveTestingSignature: appState.testingOrder.qwbpOrder.destructiveTestingSignature,
                dimension1: appState.testingOrder.qwbpOrder.dimension1,
                dimension2: appState.testingOrder.qwbpOrder.dimension2,
                directionOfPlateRolling1: appState.testingOrder.qwbpOrder.directionOfPlateRolling1,
                directionOfPlateRolling2: appState.testingOrder.qwbpOrder.directionOfPlateRolling2,
                fillerMaterial: appState.testingOrder.qwbpOrder.fillerMaterial,
                hardnessTesting: appState.testingOrder.qwbpOrder.hardnessTesting,
                impactTesting: appState.testingOrder.qwbpOrder.impactTesting,
                impactTestTemperature1: appState.testingOrder.qwbpOrder.impactTestTemperature1,
                impactTestTemperature2: appState.testingOrder.qwbpOrder.impactTestTemperature2,
                iso10902: appState.testingOrder.qwbpOrder.iso10902,
                iso10902AdditionalTesting: appState.testingOrder.qwbpOrder.iso10902AdditionalTesting,
                iso10902CruciformTensileTesting: appState.testingOrder.qwbpOrder.iso10902CruciformTensileTesting,
                iso10902ImpactTesting: appState.testingOrder.qwbpOrder.iso10902ImpactTesting,
                iso10902MacroPlusPhoto: appState.testingOrder.qwbpOrder.iso10902MacroPlusPhoto,
                iso10902MicroTesting: appState.testingOrder.qwbpOrder.iso10902MicroTesting,
                iso10902WeldabilityPrimer: appState.testingOrder.qwbpOrder.iso10902WeldabilityPrimer,
                iso10902AdditionalTestingText: appState.testingOrder.qwbpOrder.iso10902AdditionalTestingText,
                iso10902CruciformTensileTestingText: appState.testingOrder.qwbpOrder.iso10902CruciformTensileTestingText,
                iso10902ImpactTestingText: appState.testingOrder.qwbpOrder.iso10902ImpactTestingText,
                iso10902MacroPlusPhotoText: appState.testingOrder.qwbpOrder.iso10902MacroPlusPhotoText,
                iso10902MicroTestingText: appState.testingOrder.qwbpOrder.iso10902MicroTestingText,
                iso10902WeldabilityPrimerText: appState.testingOrder.qwbpOrder.iso10902WeldabilityPrimerText,
                iso129526AdditionalTesting: appState.testingOrder.qwbpOrder.iso129526AdditionalTesting,
                iso129526ImpactTesting: appState.testingOrder.qwbpOrder.iso129526ImpactTesting,
                iso129526LongitudinalTensileTesting: appState.testingOrder.qwbpOrder.iso129526LongitudinalTensileTesting,
                iso129526AdditionalTestingText: appState.testingOrder.qwbpOrder.iso129526AdditionalTestingText,
                iso129526ImpactTestingText: appState.testingOrder.qwbpOrder.iso129526ImpactTestingText,
                iso129526LongitudinalTensileTestingText: appState.testingOrder.qwbpOrder.iso129526LongitudinalTensileTestingText,
                iso129526Testing: appState.testingOrder.qwbpOrder.iso129526Testing,
                iso13134AdditionalTesting: appState.testingOrder.qwbpOrder.iso13134AdditionalTesting,
                iso13134BendTesting: appState.testingOrder.qwbpOrder.iso13134BendTesting,
                iso13134HardnessTesting: appState.testingOrder.qwbpOrder.iso13134HardnessTesting,
                iso13134PeelTesting: appState.testingOrder.qwbpOrder.iso13134PeelTesting,
                iso13134ShearTesting: appState.testingOrder.qwbpOrder.iso13134ShearTesting,
                iso13134TensileTesting: appState.testingOrder.qwbpOrder.iso13134TensileTesting,
                iso13134BrazinProcedureApproval: appState.testingOrder.qwbpOrder.iso13134BrazinProcedureApproval,
                iso13134AdditionalTestingText: appState.testingOrder.qwbpOrder.iso13134AdditionalTestingText,
                iso13134BendTestingText: appState.testingOrder.qwbpOrder.iso13134BendTestingText,
                iso13134HardnessTestingText: appState.testingOrder.qwbpOrder.iso13134HardnessTestingText,
                iso13134PeelTestingText: appState.testingOrder.qwbpOrder.iso13134PeelTestingText,
                iso13134ShearTestingText: appState.testingOrder.qwbpOrder.iso13134ShearTestingText,
                iso13134TensileTestingText: appState.testingOrder.qwbpOrder.iso13134TensileTestingText,
                iso134454Testing: appState.testingOrder.qwbpOrder.iso134454Testing,
                iso134454AdditionalTesting: appState.testingOrder.qwbpOrder.iso134454AdditionalTesting,
                iso134454FerriteTesting: appState.testingOrder.qwbpOrder.iso134454FerriteTesting,
                iso134454ImpactTesting: appState.testingOrder.qwbpOrder.iso134454ImpactTesting,
                iso134454LongitudinalTensileTesting: appState.testingOrder.qwbpOrder.iso134454LongitudinalTensileTesting,
                iso134454MicroTesting: appState.testingOrder.qwbpOrder.iso134454MicroTesting,
                iso134454AdditionalTestingText: appState.testingOrder.qwbpOrder.iso134454AdditionalTestingText,
                iso134454FerriteTestingText: appState.testingOrder.qwbpOrder.iso134454FerriteTestingText,
                iso134454ImpactTestingText: appState.testingOrder.qwbpOrder.iso134454ImpactTestingText,
                iso134454LongitudinalTensileTestingText: appState.testingOrder.qwbpOrder.iso134454LongitudinalTensileTestingText,
                iso134454MicroTestingText: appState.testingOrder.qwbpOrder.iso134454MicroTestingText,
                iso14555AdditionalTesting: appState.testingOrder.qwbpOrder.iso14555AdditionalTesting,
                iso14555ApplicationGreaterThan100Degrees: appState.testingOrder.qwbpOrder.iso14555ApplicationGreaterThan100Degrees,
                iso14555ApplicationLessThanOrEqualTo100Degrees: appState.testingOrder.qwbpOrder.iso14555ApplicationLessThanOrEqualTo100Degrees,
                iso14555BendTesting: appState.testingOrder.qwbpOrder.iso14555BendTesting,
                iso14555ArcStudWelding: appState.testingOrder.qwbpOrder.iso14555ArcStudWelding,
                iso14555_3834Testing: appState.testingOrder.qwbpOrder.iso14555_3834Testing,
                iso14555_783Testing: appState.testingOrder.qwbpOrder.iso14555_783Testing,
                iso14555_784Testing: appState.testingOrder.qwbpOrder.iso14555_784Testing,
                iso14555_785Testing: appState.testingOrder.qwbpOrder.iso14555_785Testing,
                iso14555_786Testing: appState.testingOrder.qwbpOrder.iso14555_786Testing,
                iso14555_787Testing: appState.testingOrder.qwbpOrder.iso14555_787Testing,
                iso14555BendTestingTorqueWrench: appState.testingOrder.qwbpOrder.iso14555BendTestingTorqueWrench,
                iso14555MacroPlusPhoto: appState.testingOrder.qwbpOrder.iso14555MacroPlusPhoto,
                iso14555TensileTesting: appState.testingOrder.qwbpOrder.iso14555TensileTesting,
                iso14555TorqueTesting: appState.testingOrder.qwbpOrder.iso14555TorqueTesting,
                iso14555RTProvning: appState.testingOrder.qwbpOrder.iso14555RTProvning,
                iso14555AdditionalTestingText: appState.testingOrder.qwbpOrder.iso14555AdditionalTestingText,
                iso14555ApplicationGreaterThan100DegreesText: appState.testingOrder.qwbpOrder.iso14555ApplicationGreaterThan100DegreesText,
                iso14555ApplicationLessThanOrEqualTo100DegreesText: appState.testingOrder.qwbpOrder.iso14555ApplicationLessThanOrEqualTo100DegreesText,
                iso14555BendTestingText: appState.testingOrder.qwbpOrder.iso14555BendTestingText,
                iso14555BendTestingTorqueWrenchText: appState.testingOrder.qwbpOrder.iso14555BendTestingTorqueWrenchText,
                iso14555MacroPlusPhotoText: appState.testingOrder.qwbpOrder.iso14555MacroPlusPhotoText,
                iso14555TensileTestingText: appState.testingOrder.qwbpOrder.iso14555TensileTestingText,
                iso14555TorqueTestingText: appState.testingOrder.qwbpOrder.iso14555TorqueTestingText,
                iso14555RTProvningText: appState.testingOrder.qwbpOrder.iso14555RTProvningText,
                iso14555_3834TestingText: appState.testingOrder.qwbpOrder.iso14555_3834TestingText,
                iso156141Edition2004: appState.testingOrder.qwbpOrder.iso156141Edition2004,
                iso156141Edition2017: appState.testingOrder.qwbpOrder.iso156141Edition2017,
                macroPlusPhoto: appState.testingOrder.qwbpOrder.macroPlusPhoto,
                manufacturerWPQRBPARNumber: appState.testingOrder.qwbpOrder.manufacturerWPQRBPARNumber,
                material1: appState.testingOrder.qwbpOrder.material1,
                material2: appState.testingOrder.qwbpOrder.material2,
                materialGroup1: appState.testingOrder.qwbpOrder.materialGroup1,
                materialGroup2: appState.testingOrder.qwbpOrder.materialGroup2,
                materialSpecifications1Remarks: appState.testingOrder.qwbpOrder.materialSpecifications1Remarks,
                materialSpecifications2Remarks: appState.testingOrder.qwbpOrder.materialSpecifications2Remarks,
                mtPT: appState.testingOrder.qwbpOrder.mtPT,
                ndtApproved: appState.testingOrder.qwbpOrder.ndtApproved,
                ndtDate: appState.testingOrder.qwbpOrder.ndtDate,
                ndtSignature: appState.testingOrder.qwbpOrder.ndtSignature,
                ndtOther: appState.testingOrder.qwbpOrder.ndtOther,
                norsokAdditionalTesting: appState.testingOrder.qwbpOrder.norsokAdditionalTesting,
                norsokAdditionalTesting1: appState.testingOrder.qwbpOrder.norsokAdditionalTesting1,
                norsokAdditionalTesting2: appState.testingOrder.qwbpOrder.norsokAdditionalTesting2,
                norsokAdditionalTesting3: appState.testingOrder.qwbpOrder.norsokAdditionalTesting3,
                norsokAdditionalTesting4: appState.testingOrder.qwbpOrder.norsokAdditionalTesting4,
                norsokAdditionalTestingText: appState.testingOrder.qwbpOrder.norsokAdditionalTestingText,
                norsokAdditionalTesting1Text: appState.testingOrder.qwbpOrder.norsokAdditionalTesting1Text,
                norsokAdditionalTesting2Text: appState.testingOrder.qwbpOrder.norsokAdditionalTesting2Text,
                norsokAdditionalTesting3Text: appState.testingOrder.qwbpOrder.norsokAdditionalTesting3Text,
                norsokAdditionalTesting4Text: appState.testingOrder.qwbpOrder.norsokAdditionalTesting4Text,
                norsokCorrosionTestingText: appState.testingOrder.qwbpOrder.norsokCorrosionTestingText,
                norsokCorrosionTesting: appState.testingOrder.qwbpOrder.norsokCorrosionTesting,
                norsok: appState.testingOrder.qwbpOrder.norsok,
                phoneNumber: appState.testingOrder.qwbpOrder.phoneNumber,
                pWPSPBPS: appState.testingOrder.qwbpOrder.pWPSPBPS,
                orderedByLevel3: appState.testingOrder.qwbpOrder.orderedByLevel3,
                other: appState.testingOrder.qwbpOrder.other,
                ndtRemarks: appState.testingOrder.qwbpOrder.ndtRemarks,
                rt: appState.testingOrder.qwbpOrder.rt,
                signature: appState.testingOrder.qwbpOrder.signature,
                standardForAcceptance1: appState.testingOrder.qwbpOrder.standardForAcceptance1,
                standardForAcceptance2: appState.testingOrder.qwbpOrder.standardForAcceptance2,
                transverseTensilePlusRp0ReH: appState.testingOrder.qwbpOrder.transverseTensilePlusRp0ReH,
                ut: appState.testingOrder.qwbpOrder.ut,
                visual: appState.testingOrder.qwbpOrder.visual,
                weldingDate: appState.testingOrder.qwbpOrder.weldingDate,
                weldingBrazingPositions: appState.testingOrder.qwbpOrder.weldingBrazingPositions,
                weldingProcess: appState.testingOrder.qwbpOrder.weldingProcess,
                welderBrazerFirstName: appState.testingOrder.qwbpOrder.welderBrazerFirstName,
                welderBrazerLastName: appState.testingOrder.qwbpOrder.welderBrazerLastName,
                iso15614transverseTensilePlusRp0ReHText: appState.testingOrder.qwbpOrder.iso15614transverseTensilePlusRp0ReHText,
                iso15614macroPlusPhotoText: appState.testingOrder.qwbpOrder.iso15614macroPlusPhotoText,
                iso15614hardnessTestingText: appState.testingOrder.qwbpOrder.iso15614hardnessTestingText,
                iso15614impactTestingText: appState.testingOrder.qwbpOrder.iso15614impactTestingText,
                iso15614bendTestingText: appState.testingOrder.qwbpOrder.iso15614bendTestingText,
                iso15614additionalTestingText: appState.testingOrder.qwbpOrder.iso15614additionalTestingText,
                pedAdditionalTesting: appState.testingOrder.qwbpOrder.pedAdditionalTesting,
                iso15614TestingText: appState.testingOrder.qwbpOrder.iso15614TestingText,
                dekraProcedureNoTesting: appState.testingOrder.qwbpOrder.dekraProcedureNoTesting,
                invoiceReferenceName: appState.testingOrder.qwbpOrder.invoiceReferenceName,
                uppdragsnr: appState.testingOrder.qwbpOrder.uppdragsnr,
                testmarking: appState.testingOrder.qwbpOrder.testmarking,
                nace: appState.testingOrder.qwbpOrder.nace,
                naceMr0103Testing: appState.testingOrder.qwbpOrder.naceMr0103Testing,
                naceMr0103TestingText: appState.testingOrder.qwbpOrder.naceMr0103TestingText,
                naceMr0175Testing: appState.testingOrder.qwbpOrder.naceMr0175Testing,
                naceMr0175TestingText: appState.testingOrder.qwbpOrder.naceMr0175TestingText,
                naceAdditionalTesting1: appState.testingOrder.qwbpOrder.naceAdditionalTesting1,
                naceAdditionalTesting1Text: appState.testingOrder.qwbpOrder.naceAdditionalTesting1Text,
                naceAdditionalTesting2: appState.testingOrder.qwbpOrder.naceAdditionalTesting2,
                naceAdditionalTesting2Text: appState.testingOrder.qwbpOrder.naceAdditionalTesting2Text,
            }
            // Get the FilesUpload values.
            let updateFilesUploadRequest: Array<UpdateFilesUploadRequest> = [];
            for (let i = 0; i < appState.fileUpload.filesUpload.length; i++) {
                let updateFileUploadRequest: UpdateFilesUploadRequest = {
                    fileName: appState.fileUpload.filesUpload[i].fileName,
                    fileBase64: appState.fileUpload.filesUpload[i].fileBase64,
                    filePath: appState.fileUpload.filesUpload[i].filePath,
                    fileDescription: appState.fileUpload.filesUpload[i].fileDescription,
                    fileGuid: '',
                    selectedFile: appState.fileUpload.filesUpload[i].selectedFile
                };
                updateFilesUploadRequest.push(updateFileUploadRequest);
            }
            //createWpqrRequest.updateFilesUploadRequest = updateFilesUploadRequest;

            let updateFilesJointDesignHtmlImageRequest: UpdateFilesRequest = {
                applicationId: 0,
                weldingProtocolId: 0,
                wpsId: 0,
                enableCreateWps: false,
                wpqrEnableEdit: createWpqrRequest.wpqrEnableEdit,
                jointDesignHtmlImage: appState.drawJointDesign.jointDesignHtmlImage,
                updateWeldSequenceRequest: {
                    imageId: appState.weldSequence.sequences.imageId,
                    imageData: "",
                    sequenceNumber1: "",
                    sequenceNumber2: "",
                    sequenceNumber3: "",
                    sequenceNumber4: "",
                    sequenceNumber5: "",
                    sequenceNumber6: "",
                    sequenceNumber7: "",
                    sequenceNumber8: "",
                    sequenceNumber9: '',
                    sequenceNumber10: '',
                    sequenceNumber11: '',
                    sequenceNumber12: '',
                    sequenceNumber13: '',
                    sequenceNumber14: '',
                    sequenceNumber15: '',
                },
                updateFilesUploadRequest: updateFilesUploadRequest
            };

            let updateFilesRequest: UpdateFilesRequest = {
                applicationId: 0,
                weldingProtocolId: 0,
                wpsId: 0,
                enableCreateWps: false,
                wpqrEnableEdit: createWpqrRequest.wpqrEnableEdit,
                jointDesignHtmlImage: "",
                updateWeldSequenceRequest: {
                    imageId: appState.weldSequence.sequences.imageId,
                    imageData: appState.weldSequence.sequences.imageData,
                    sequenceNumber1: appState.weldSequence.sequences.sequenceNumber1,
                    sequenceNumber2: appState.weldSequence.sequences.sequenceNumber2,
                    sequenceNumber3: appState.weldSequence.sequences.sequenceNumber3,
                    sequenceNumber4: appState.weldSequence.sequences.sequenceNumber4,
                    sequenceNumber5: appState.weldSequence.sequences.sequenceNumber5,
                    sequenceNumber6: appState.weldSequence.sequences.sequenceNumber6,
                    sequenceNumber7: appState.weldSequence.sequences.sequenceNumber7,
                    sequenceNumber8: appState.weldSequence.sequences.sequenceNumber8,
                    sequenceNumber9: appState.weldSequence.sequences.sequenceNumber9,
                    sequenceNumber10: appState.weldSequence.sequences.sequenceNumber10,
                    sequenceNumber11: appState.weldSequence.sequences.sequenceNumber11,
                    sequenceNumber12: appState.weldSequence.sequences.sequenceNumber12,
                    sequenceNumber13: appState.weldSequence.sequences.sequenceNumber13,
                    sequenceNumber14: appState.weldSequence.sequences.sequenceNumber14,
                    sequenceNumber15: appState.weldSequence.sequences.sequenceNumber15,
                },
                updateFilesUploadRequest: updateFilesUploadRequest

            };

            if (createWpqrRequest.wpqrEnableEdit) {
                fetch(`api/Wpqrs/Update`, {
                    method: 'POST',
                    headers: authHeader.authHeader(),
                    body: JSON.stringify(createWpqrRequest)
                })
                    .then(response => response.json() as Promise<WpqrResponse>)
                    .then(data => {
                        let wpqrResponse: WpqrResponse = { ...data };
                        // Update JointDesign and Files path
                        updateFilesJointDesignHtmlImageRequest.weldingProtocolId = data.id;
                        fetch(`api/File/UpdateJointDesign`, {
                            method: 'PUT',
                            headers: authHeader.authHeader(),
                            body: JSON.stringify(updateFilesJointDesignHtmlImageRequest)
                        })
                            .then(response => response.json() as Promise<UpdateFilesResponse>)
                            .then(data => {
                                console.info(data);
                            })
                            .catch(error => {
                                console.error(error);
                            });

                        // Update WeldSequence and Files path
                        updateFilesRequest.weldingProtocolId = data.id;
                        // TODO The document dont updates yet
                        //if (createWpqrRequest.selectedWpqrId !== 0) {
                        //    updateFilesRequest.updateFilesUploadRequest = [];
                        //}
                        fetch(`api/File/Create`, {
                            method: 'POST',
                            headers: authHeader.authHeader(),
                            body: JSON.stringify(updateFilesRequest)
                        })
                            .then(response => response.json() as Promise<UpdateFilesResponse>)
                            .then(data => {
                                console.info(data);
                                const appState = getState();
                                if (appState && appState.wpqrDekraTab) {
                                    let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                                    if (data.id !== undefined) {
                                        weldingProtocol.wpqrId = data.id;
                                        weldingProtocol.weldingProtocolId = wpqrResponse.weldingProtocolId;
                                    }
                                    dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });

                        if (callback !== null) {
                            callback();
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
            else {

                fetch(`api/Wpqrs/Create`, {
                    method: 'POST',
                    headers: authHeader.authHeader(),
                    body: JSON.stringify(createWpqrRequest)
                })
                    .then(response => response.json() as Promise<WpqrResponse>)
                    .then(data => {
                        let wpqrResponse: WpqrResponse = { ...data };
                        // Update JointDesign and Files path
                        updateFilesJointDesignHtmlImageRequest.weldingProtocolId = data.id;
                        fetch(`api/File/UpdateJointDesign`, {
                            method: 'PUT',
                            headers: authHeader.authHeader(),
                            body: JSON.stringify(updateFilesJointDesignHtmlImageRequest)
                        })
                            .then(response => response.json() as Promise<UpdateFilesResponse>)
                            .then(data => {
                                console.info(data);
                            })
                            .catch(error => {
                                console.error(error);
                            });

                        // Update WeldSequence and Files path
                        updateFilesRequest.weldingProtocolId = data.id;
                        fetch(`api/File/Create`, {
                            method: 'POST',
                            headers: authHeader.authHeader(),
                            body: JSON.stringify(updateFilesRequest)
                        })
                            .then(response => response.json() as Promise<UpdateFilesResponse>)
                            .then(data => {
                                console.info(data);
                                const appState = getState();
                                if (appState && appState.wpqrDekraTab) {
                                    let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                                    if (data.id !== undefined) {
                                        weldingProtocol.wpqrId = data.id;
                                        weldingProtocol.weldingProtocolId = wpqrResponse.weldingProtocolId;
                                    }
                                    dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
            dispatch({ type: 'CREATE_UPDATE_WELDING_PROTOCOL_REQUEST' });
        }

        function setFlowRateTop(counteArrayIndex: number) {
            let returnValue: number = 0;
            if (appState && appState.wpqrDekraTab) {
                let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
                if (counteArrayIndex === 0) {
                    returnValue = !isNaN(parseFloat(weldingProtocolSection4.gasFlowMethod1TopSide.replace(',', '.'))) ? parseFloat(weldingProtocolSection4.gasFlowMethod1TopSide.replace(',', '.')) : 0.0;
                } else {
                    returnValue = !isNaN(parseFloat(weldingProtocolSection4.gasFlowMethod2TopSide.replace(',', '.'))) ? parseFloat(weldingProtocolSection4.gasFlowMethod2TopSide.replace(',', '.')) : 0.0;
                }
            }
            return returnValue;
        }

        function setFlowRoot() {
            let returnValue: number = 0;
            if (appState && appState.wpqrDekraTab) {
                let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
                returnValue = !isNaN(parseFloat(weldingProtocolSection4.gasFlowMethod1RootSide.replace(',', '.'))) ? parseFloat(weldingProtocolSection4.gasFlowMethod1RootSide.replace(',', '.')) : 0.0;
            }
            return returnValue;
        }
    },
    initSelectedWpqr: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Wpqrs/GetByIdInclude/${id}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as GetByIdWpqrResponse;
                console.info(data);
                let selectedWpqrResponse: GetByIdWpqrResponse = { ...data };
                const appState = getState();
                if (appState && appState.wpqrDekraTab) {
                    let selectedWpqr: GetByIdWpqrResponse = { ...appState.wpqrDekraTab.selectedWpqr };
                    selectedWpqr = { ...selectedWpqrResponse }
                    dispatch({ type: 'SET_WPQR', selectedWpqr: selectedWpqr });

                    let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
                    weldingTestingDetails1.manufacturer2 = selectedWpqrResponse.testingDetails1ExaminationResponse.manufacturer2;
                    weldingTestingDetails1.manufacturer2Signature = selectedWpqrResponse.testingDetails1ExaminationResponse.manufacturer2Signature;
                    weldingTestingDetails1.manufacturer2Name = selectedWpqrResponse.testingDetails1ExaminationResponse.manufacturer2Name;
                    weldingTestingDetails1.manufacturer2Date = selectedWpqrResponse.testingDetails1ExaminationResponse.manufacturer2Date;
                    weldingTestingDetails1.examiner = selectedWpqrResponse.testingDetails1ExaminationResponse.examiner == "" ? "DEKRA Industrial AB" : selectedWpqrResponse.testingDetails1ExaminationResponse.examiner;
                    weldingTestingDetails1.examinerSignature = selectedWpqrResponse.testingDetails1ExaminationResponse.examinerSignature;
                    weldingTestingDetails1.examinerName = selectedWpqrResponse.testingDetails1ExaminationResponse.examinerName;
                    weldingTestingDetails1.examinerDate = selectedWpqrResponse.testingDetails1ExaminationResponse.examinerDate;
                    weldingTestingDetails1.commentInformation = selectedWpqrResponse.testingDetails1ExaminationResponse.commentInformation;
                    dispatch({ type: 'INIT_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
                }
            }))
            .catch(error => {
                console.error(error);
            });
    },
    sendToCustomer: (sendToCustomerRequest: SendToCustomerRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        fetch(`api/wpqrs/SendToCustomer`, {
            method: 'PUT',
            headers: authHeader.authHeader(),
            body: JSON.stringify(sendToCustomerRequest)
        })
            .then(response => response.json() as Promise<UpdateApplicationStatusResponse>)
            .then(data => {
                console.info(data);
                if (appState && appState.wpqrDekraTab) {
                    let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                    weldingProtocol.weldingProtocolId = 0;
                    dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                }

            })
            .catch(error => {
                console.error(error);
            });
    },
    clearPartOfWeldingTestingDetails1: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        //Todo for clear TestResultExamination and start with the init values.
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.manufacturer2 = '';
            weldingTestingDetails1.manufacturer2Signature = '';
            weldingTestingDetails1.manufacturer2Name = '';
            weldingTestingDetails1.manufacturer2Date = '';
            weldingTestingDetails1.examiner = '';
            weldingTestingDetails1.examinerSignature = '';
            weldingTestingDetails1.examinerName = '';
            weldingTestingDetails1.examiner = '';
            weldingTestingDetails1.commentInformation = '';
            dispatch({ type: 'INIT_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    initWeldingProtocol: (applicationId: number, wpqrId: number, actionType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Applications/GetByIdInclude/${applicationId}/${wpqrId}/${actionType}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<GetApplicationResponse>)
            .then(data => {
                let selectedApplication: GetApplicationResponse = { ...data };
                const appState = getState();
                if (appState && appState.wpqrDekraTab && appState.login) {
                    let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                    // Initialize WeldingProtocol
                    weldingProtocol.dekraProcedureNumber = selectedApplication.dekraNumber === null ? '' : selectedApplication.dekraNumber;
                    weldingProtocol.dekraProcedureNumberDisplay = selectedApplication.weldingProtocolSectionsResponse.dekraNumberDisplay === null ? selectedApplication.dekraNumber : selectedApplication.weldingProtocolSectionsResponse.dekraNumberDisplay;
                    //weldingProtocol.revisionWpqrAction = selectedApplication.revisionWpqrAction === null ? '' : selectedApplication.revisionWpqrAction;
                    weldingProtocol.weldingProtocolId = selectedApplication.weldingProtocolId;
                    weldingProtocol.wpqrId = selectedApplication.wpqrId;
                    weldingProtocol.weldingProtocolApplicationId = selectedApplication.weldingProtocolApplicationId;
                    weldingProtocol.manufacturerWPQRNumber = selectedApplication.pwps.wpqrNumber === null ? '' : selectedApplication.pwps.wpqrNumber;
                    weldingProtocol.pWPSNumber = selectedApplication.pwps.pwpsNumber === null ? '' : selectedApplication.pwps.pwpsNumber;
                    weldingProtocol.assignmentNumber = selectedApplication.weldingProtocolSectionsResponse.assignmentNumber === null ? selectedApplication.testingOrderResponse.uppdragsnr : selectedApplication.weldingProtocolSectionsResponse.assignmentNumber;
                    weldingProtocol.markingOfTheTest = selectedApplication.weldingProtocolSectionsResponse.markingOfTheTest === null ? selectedApplication.testingOrderResponse.testmarking : selectedApplication.weldingProtocolSectionsResponse.markingOfTheTest;
                    weldingProtocol.standardId = selectedApplication.pwps.byStandard === null ? 0 : selectedApplication.pwps.byStandard.id;
                    // Initialize WeldingProtocolSection1.
                    ///////////////////////////////////////////////////////////////////////////////////7////////////
                    let weldingProtocolSection1: WeldingProtocolSection1 = weldingProtocol.weldingProtocolSection1;
                    // Company.
                    weldingProtocolSection1.company = { ...selectedApplication.company };
                    weldingProtocolSection1.company2 = { ...selectedApplication.company2 };
                    // Welder.Todo its dont done yet
                    weldingProtocolSection1.welder = {
                        id: 0,
                        firstName: selectedApplication.weldingProtocolSectionsResponse.firstName != null ? selectedApplication.weldingProtocolSectionsResponse.firstName : selectedApplication.testingOrderResponse.welderBrazerFirstName,
                        lastName: selectedApplication.weldingProtocolSectionsResponse.lastName != null ? selectedApplication.weldingProtocolSectionsResponse.lastName : selectedApplication.testingOrderResponse.welderBrazerLastName,
                        welderId: '',
                        dateOfBirth: selectedApplication.weldingProtocolSectionsResponse.dateOfBirth === null ? '' : selectedApplication.weldingProtocolSectionsResponse.dateOfBirth,
                        placeOfBirth: selectedApplication.weldingProtocolSectionsResponse.placeOfBirth === null ? '' : selectedApplication.weldingProtocolSectionsResponse.placeOfBirth,
                        department: '',
                        email: '',
                        phone: '',
                        note: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    }
                    // Initialize WeldingProtocolSection2.
                    ////////////////////////////////////////////////////////////////////////////////////////////////
                    let weldingProtocolSection2: WeldingProtocolSection2 = weldingProtocol.weldingProtocolSection2;
                    weldingProtocolSection2.lotNumberOrHeatNumberA = selectedApplication.weldingProtocolSectionsResponse.heatNumber1 === null ? '' : selectedApplication.weldingProtocolSectionsResponse.heatNumber1;
                    weldingProtocolSection2.lotNumberOrHeatNumberB = selectedApplication.weldingProtocolSectionsResponse.heatNumber2 === null ? '' : selectedApplication.weldingProtocolSectionsResponse.heatNumber2;
                    // JointType.
                    if (selectedApplication.pwps.weldingData.jointType && selectedApplication.pwps.weldingData.jointType.id !== 0) {
                        weldingProtocolSection2.selectedJointType = { ...selectedApplication.pwps.weldingData.jointType };
                    }
                    else {
                        weldingProtocolSection2.selectedJointType = {
                            id: 0,
                            name: '',
                            description: '',
                            jointShapes: [],
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // WeldingMethod1.
                    if (selectedApplication.pwps.weldingProcess.weldingMethod1 && selectedApplication.pwps.weldingProcess.weldingMethod1.id !== 0) {
                        weldingProtocolSection2.weldingMethod1SearchText = selectedApplication.pwps.weldingProcess.weldingMethod1.name;
                        weldingProtocolSection2.weldingMethod1SearchResult = [];
                        weldingProtocolSection2.selectedWeldingMethod1 = { ...selectedApplication.pwps.weldingProcess.weldingMethod1 };
                    }
                    else {
                        weldingProtocolSection2.weldingMethod1SearchText = '';
                        weldingProtocolSection2.weldingMethod1SearchResult = [];
                        weldingProtocolSection2.selectedWeldingMethod1 = {
                            id: 0,
                            name: '',
                            efficiencyFactor: 0.0,
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // Make1.
                    if (selectedApplication.pwps.weldingProcess.make1 && selectedApplication.pwps.weldingProcess.make1.id !== 0) {
                        weldingProtocolSection2.make1SearchText = selectedApplication.pwps.weldingProcess.make1.name;
                        weldingProtocolSection2.make1SearchResult = [];
                        weldingProtocolSection2.selectedMake1 = { ...selectedApplication.pwps.weldingProcess.make1 };
                        // Designation1.
                        console.log('selectedApplication.pwps.weldingProcess.make1.designation');
                        console.log(selectedApplication.pwps.weldingProcess.make1.designation);
                        weldingProtocolSection2.selectedDesignation1 = { ...selectedApplication.pwps.weldingProcess.make1.designation };
                    }
                    else {
                        weldingProtocolSection2.make1SearchText = '';
                        weldingProtocolSection2.make1SearchResult = [];
                        weldingProtocolSection2.selectedMake1 = {
                            id: 0,
                            name: '',
                            description: '',
                            designation: {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            },
                            fmGroup: {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            },
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                        weldingProtocolSection2.selectedDesignation1 = {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }

                    // arcType1.
                    if (selectedApplication.pwps.weldingProcess.arcType1 && selectedApplication.pwps.weldingProcess.arcType1.id !== 0) {
                        weldingProtocolSection2.selectedArcTypeA = { ...selectedApplication.pwps.weldingProcess.arcType1 };
                    }
                    else {
                        weldingProtocolSection2.selectedArcTypeA = {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // WeldingPosition.
                    if (selectedApplication.pwps.weldingData.weldingPosition && selectedApplication.pwps.weldingData.weldingPosition.id !== 0) {
                        weldingProtocolSection2.selectedWeldingPosition = { ...selectedApplication.pwps.weldingData.weldingPosition };
                    }
                    else {
                        weldingProtocolSection2.selectedWeldingPosition = {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // WeldingPosition 2.
                    if (selectedApplication.pwps.weldingData.weldingPosition2 && selectedApplication.pwps.weldingData.weldingPosition2.id !== 0) {
                        weldingProtocolSection2.selectedWeldingPosition2 = { ...selectedApplication.pwps.weldingData.weldingPosition2 };
                    }
                    else {
                        weldingProtocolSection2.selectedWeldingPosition2 = {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // WeldingMethod2.
                    if (selectedApplication.pwps.weldingProcess.weldingMethod2 && selectedApplication.pwps.weldingProcess.weldingMethod2.id !== 0) {
                        console.log('selectedApplication.pwps.weldingProcess.weldingMethod2');
                        console.log(selectedApplication.pwps.weldingProcess.weldingMethod2);
                        weldingProtocolSection2.weldingMethod2SearchText = selectedApplication.pwps.weldingProcess.weldingMethod2.name;
                        weldingProtocolSection2.weldingMethod2SearchResult = [];
                        weldingProtocolSection2.selectedWeldingMethod2 = { ...selectedApplication.pwps.weldingProcess.weldingMethod2 };
                    }
                    else {
                        weldingProtocolSection2.weldingMethod2SearchText = '';
                        weldingProtocolSection2.weldingMethod2SearchResult = [];
                        weldingProtocolSection2.selectedWeldingMethod2 = {
                            id: 0,
                            name: '',
                            efficiencyFactor: 0.0,
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // Make2.
                    if (selectedApplication.pwps.weldingProcess.make2 && selectedApplication.pwps.weldingProcess.make2.id !== 0) {
                        weldingProtocolSection2.make2SearchText = selectedApplication.pwps.weldingProcess.make2.name;
                        weldingProtocolSection2.make2SearchResult = [];
                        weldingProtocolSection2.selectedMake2 = { ...selectedApplication.pwps.weldingProcess.make2 };
                        // Designation2.
                        weldingProtocolSection2.selectedDesignation2 = { ...selectedApplication.pwps.weldingProcess.make2.designation };
                    }
                    else {
                        weldingProtocolSection2.make2SearchText = '';
                        weldingProtocolSection2.make2SearchResult = [];
                        weldingProtocolSection2.selectedMake2 = {
                            id: 0,
                            name: '',
                            description: '',
                            designation: {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            },
                            fmGroup: {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            },
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                        weldingProtocolSection2.selectedDesignation2 = {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // arcType2.
                    if (selectedApplication.pwps.weldingProcess.arcType2 && selectedApplication.pwps.weldingProcess.arcType2.id !== 0) {
                        weldingProtocolSection2.selectedArcTypeB = { ...selectedApplication.pwps.weldingProcess.arcType2 };
                    }
                    else {
                        weldingProtocolSection2.selectedArcTypeB = {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    weldingProtocolSection2.enableMakeAText = selectedApplication.pwps.weldingProcess.enableMakeAText;
                    weldingProtocolSection2.makeAText = selectedApplication.pwps.weldingProcess.makeAText;
                    weldingProtocolSection2.designationAText = selectedApplication.pwps.weldingProcess.designationAText;
                    weldingProtocolSection2.fmGroupAText = selectedApplication.pwps.weldingProcess.fmGroupAText;
                    weldingProtocolSection2.enableMakeBText = selectedApplication.pwps.weldingProcess.enableMakeBText;
                    weldingProtocolSection2.makeBText = selectedApplication.pwps.weldingProcess.makeBText;
                    weldingProtocolSection2.designationBText = selectedApplication.pwps.weldingProcess.designationBText;
                    weldingProtocolSection2.fmGroupBText = selectedApplication.pwps.weldingProcess.fmGroupBText;

                    if (selectedApplication.pwps.weldingProcess.enableMakeAText) {
                        weldingProtocolSection2.make1SearchText = selectedApplication.pwps.weldingProcess.makeAText;
                        weldingProtocolSection2.selectedDesignation1.name = selectedApplication.pwps.weldingProcess.designationAText;
                    }
                    if (selectedApplication.pwps.weldingProcess.enableMakeBText) {
                        weldingProtocolSection2.make2SearchText = selectedApplication.pwps.weldingProcess.makeBText;
                        weldingProtocolSection2.selectedDesignation2.name = selectedApplication.pwps.weldingProcess.designationBText;
                    }
                    // Initialize WeldingProtocolSection3.
                    ///////////////////////////////////////////////////////////////////////////////////////
                    let weldingProtocolSection3: WeldingProtocolSection3 = weldingProtocol.weldingProtocolSection3;
                    weldingProtocolSection3.chargeAltHeatNumber1 = selectedApplication.weldingProtocolSectionsResponse.chargeAltHeatNumber1 != null ? selectedApplication.weldingProtocolSectionsResponse.chargeAltHeatNumber1 : selectedApplication.testingOrderResponse.chargeNumber1;
                    weldingProtocolSection3.chargeAltHeatNumber2 = selectedApplication.weldingProtocolSectionsResponse.chargeAltHeatNumber2 != null ? selectedApplication.weldingProtocolSectionsResponse.chargeAltHeatNumber2 : selectedApplication.testingOrderResponse.chargeNumber2;
                    weldingProtocolSection3.diameter1 = '';
                    weldingProtocolSection3.diameter2 = '';
                    weldingProtocolSection3.materialThickness1 = '';
                    weldingProtocolSection3.materialThickness2 = '';
                    weldingProtocolSection3.otherInformation1 = selectedApplication.weldingProtocolSectionsResponse.otherInformation1 != null ? selectedApplication.weldingProtocolSectionsResponse.otherInformation1 : selectedApplication.testingOrderResponse.materialSpecifications1Remarks;
                    weldingProtocolSection3.otherInformation2 = selectedApplication.weldingProtocolSectionsResponse.otherInformation2 != null ? selectedApplication.weldingProtocolSectionsResponse.otherInformation2 : selectedApplication.testingOrderResponse.materialSpecifications2Remarks;
                    // BaseMaterial1.
                    if (selectedApplication.pwps.parentMaterial.baseMaterial1 && selectedApplication.pwps.parentMaterial.baseMaterial1.id !== 0) {
                        weldingProtocolSection3.baseMaterial1SearchText =
                            selectedApplication.pwps.parentMaterial.baseMaterial1.name
                            + '/'
                            + selectedApplication.pwps.parentMaterial.baseMaterial1.number;
                        weldingProtocolSection3.baseMaterial1SearchResult = [];
                        weldingProtocolSection3.selectedBaseMaterial1 = { ...selectedApplication.pwps.parentMaterial.baseMaterial1 };
                        // MaterialGroup1.
                        if (selectedApplication.pwps.parentMaterial.baseMaterial1.materialGroup && selectedApplication.pwps.parentMaterial.baseMaterial1.materialGroup.id !== 0) {
                            weldingProtocolSection3.selectedMaterialGroup1 = { ...selectedApplication.pwps.parentMaterial.baseMaterial1.materialGroup };
                        }
                        else {
                            weldingProtocolSection3.selectedMaterialGroup1 = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        // TechnicalDeliveryCondition1.
                        weldingProtocolSection3.selectedTechnicalDeliveryCondition1 = { ...selectedApplication.pwps.parentMaterial.technicalDeliveryCondition1 };
                    }
                    else {
                        weldingProtocolSection3.baseMaterial1SearchText = '';
                        weldingProtocolSection3.baseMaterial1SearchResult = [];
                        weldingProtocolSection3.selectedBaseMaterial1 = {
                            id: 0,
                            name: '',
                            number: '',
                            description: '',
                            materialGroup: {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            },
                            technicalDeliveryConditions: [],
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // ProductType1.
                    if (selectedApplication.weldingProtocolSectionsResponse.productType1Id !== undefined && selectedApplication.weldingProtocolSectionsResponse.productType1Id > 0) {
                        for (let i = 0; i < weldingProtocolSection3.productTypes.length; i++) {
                            if (weldingProtocolSection3.productTypes[i].id === selectedApplication.weldingProtocolSectionsResponse.productType1Id) {
                                weldingProtocolSection3.selectedProductType1 = { ...weldingProtocolSection3.productTypes[i] };
                                break;
                            }
                        }
                    }
                    else if (selectedApplication.pwps.parentMaterial.diameter1 !== 0) {
                        for (let i = 0; i < weldingProtocolSection3.productTypes.length; i++) {
                            if (weldingProtocolSection3.productTypes[i].id === ProductType.Pipe) {
                                weldingProtocolSection3.selectedProductType1 = { ...weldingProtocolSection3.productTypes[i] };
                                break;
                            }
                        }
                    }
                    else {
                        for (let i = 0; i < weldingProtocolSection3.productTypes.length; i++) {
                            if (weldingProtocolSection3.productTypes[i].id === ProductType.Plate) {
                                weldingProtocolSection3.selectedProductType1 = { ...weldingProtocolSection3.productTypes[i] };
                                break;
                            }
                        }
                    }
                    // MaterialThickness1.
                    weldingProtocolSection3.materialThickness1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.parentMaterial.materialThickness1);
                    // Diameter1.
                    weldingProtocolSection3.diameter1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.parentMaterial.diameter1);
                    // BaseMaterial2.
                    if (selectedApplication.pwps.parentMaterial.baseMaterial2 && selectedApplication.pwps.parentMaterial.baseMaterial2.id !== 0) {
                        weldingProtocolSection3.baseMaterial2SearchText =
                            selectedApplication.pwps.parentMaterial.baseMaterial2.name
                            + '/'
                            + selectedApplication.pwps.parentMaterial.baseMaterial2.number;
                        weldingProtocolSection3.baseMaterial2SearchResult = [];
                        weldingProtocolSection3.selectedBaseMaterial2 = { ...selectedApplication.pwps.parentMaterial.baseMaterial2 };
                        // MaterialGroup2.
                        if (selectedApplication.pwps.parentMaterial.baseMaterial2.materialGroup && selectedApplication.pwps.parentMaterial.baseMaterial2.materialGroup.id !== 0) {
                            weldingProtocolSection3.selectedMaterialGroup2 = { ...selectedApplication.pwps.parentMaterial.baseMaterial2.materialGroup };
                        }
                        else {
                            weldingProtocolSection3.selectedMaterialGroup2 = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        // TechnicalDeliveryCondition2.
                        weldingProtocolSection3.selectedTechnicalDeliveryCondition2 = { ...selectedApplication.pwps.parentMaterial.technicalDeliveryCondition2 };
                    }
                    else {
                        weldingProtocolSection3.baseMaterial2SearchText = '';
                        weldingProtocolSection3.baseMaterial2SearchResult = [];
                        weldingProtocolSection3.selectedBaseMaterial2 = {
                            id: 0,
                            name: '',
                            number: '',
                            description: '',
                            materialGroup: {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            },
                            technicalDeliveryConditions: [],
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // ProductType2.
                    if (selectedApplication.weldingProtocolSectionsResponse.productType2Id !== undefined && selectedApplication.weldingProtocolSectionsResponse.productType2Id > 0) {
                        for (let i = 0; i < weldingProtocolSection3.productTypes.length; i++) {
                            if (weldingProtocolSection3.productTypes[i].id === selectedApplication.weldingProtocolSectionsResponse.productType2Id) {
                                weldingProtocolSection3.selectedProductType2 = { ...weldingProtocolSection3.productTypes[i] };
                                break;
                            }
                        }
                    }
                    else if (selectedApplication.pwps.parentMaterial.diameter2 !== 0) {
                        for (let i = 0; i < weldingProtocolSection3.productTypes.length; i++) {
                            if (weldingProtocolSection3.productTypes[i].id === ProductType.Pipe) {
                                weldingProtocolSection3.selectedProductType2 = { ...weldingProtocolSection3.productTypes[i] };
                                break;
                            }
                        }
                    }
                    else {
                        for (let i = 0; i < weldingProtocolSection3.productTypes.length; i++) {
                            if (weldingProtocolSection3.productTypes[i].id === ProductType.Plate) {
                                weldingProtocolSection3.selectedProductType2 = { ...weldingProtocolSection3.productTypes[i] };
                                break;
                            }
                        }
                    }
                    // MaterialThickness2.
                    weldingProtocolSection3.materialThickness2 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.parentMaterial.materialThickness2);
                    // Diameter2.
                    weldingProtocolSection3.diameter2 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.parentMaterial.diameter2);
                    weldingProtocolSection3.enableBaseMaterial1Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial1Text;
                    weldingProtocolSection3.enableBaseMaterial2Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial2Text;
                    weldingProtocolSection3.baseMaterial1Text = selectedApplication.pwps.parentMaterial.baseMaterial1Text;
                    weldingProtocolSection3.baseMaterial2Text = selectedApplication.pwps.parentMaterial.baseMaterial2Text;
                    weldingProtocolSection3.materialGroup1Text = selectedApplication.pwps.parentMaterial.materialGroup1Text;
                    weldingProtocolSection3.materialGroup2Text = selectedApplication.pwps.parentMaterial.materialGroup2Text;
                    weldingProtocolSection3.technicalDeliveryCondition1Text = selectedApplication.pwps.parentMaterial.technicalDeliveryCondition1Text;
                    weldingProtocolSection3.technicalDeliveryCondition2Text = selectedApplication.pwps.parentMaterial.technicalDeliveryCondition2Text;
                    // SelectedMaterialGroup1
                    if (selectedApplication.pwps.parentMaterial.enableBaseMaterial1Text && selectedApplication.pwps.parentMaterial.materialGroup1Text !== "") {
                        for (let i = 0; i < weldingProtocolSection3.materialGroups.length; i++) {
                            if (weldingProtocolSection3.materialGroups[i].name === selectedApplication.pwps.parentMaterial.materialGroup1Text) {
                                weldingProtocolSection3.selectedMaterialGroup1 = { ...weldingProtocolSection3.materialGroups[i] };
                                break;
                            }
                        }
                    }
                    // SelectedMaterialGroup2
                    if (selectedApplication.pwps.parentMaterial.enableBaseMaterial2Text && selectedApplication.pwps.parentMaterial.materialGroup2Text !== "") {
                        for (let i = 0; i < weldingProtocolSection3.materialGroups.length; i++) {
                            if (weldingProtocolSection3.materialGroups[i].name === selectedApplication.pwps.parentMaterial.materialGroup2Text) {
                                weldingProtocolSection3.selectedMaterialGroup2 = { ...weldingProtocolSection3.materialGroups[i] };
                                break;
                            }
                        }
                    }

                    if (selectedApplication.pwps.parentMaterial.enableBaseMaterial1Text) {
                        weldingProtocolSection3.baseMaterial1SearchText = selectedApplication.pwps.parentMaterial.baseMaterial1Text;
                        weldingProtocolSection3.selectedTechnicalDeliveryCondition1.name = selectedApplication.pwps.parentMaterial.technicalDeliveryCondition1Text;
                    }

                    if (selectedApplication.pwps.parentMaterial.enableBaseMaterial2Text) {
                        weldingProtocolSection3.baseMaterial2SearchText = selectedApplication.pwps.parentMaterial.baseMaterial2Text;
                        weldingProtocolSection3.selectedTechnicalDeliveryCondition2.name = selectedApplication.pwps.parentMaterial.technicalDeliveryCondition2Text;
                    }

                    // Initialize WeldingProtocolSection4.
                    ///////////////////////////////////////////////////////////////////////////////////////
                    let weldingProtocolSection4: WeldingProtocolSection4 = weldingProtocol.weldingProtocolSection4;
                    weldingProtocolSection4.jointPreparation = selectedApplication.pwps.weldingData.jointPreparationSearchText != null ? selectedApplication.pwps.weldingData.jointPreparationSearchText : '';
                    weldingProtocolSection4.nozzleDistance = selectedApplication.pwps.weldingData.stickOut != null ? selectedApplication.pwps.weldingData.stickOut : '';
                    weldingProtocolSection4.gasFlowMethod1RootSide = (selectedApplication.pwps.weldingParameters.length > 0) ? setStringEmptyIfZero(selectedApplication.pwps.weldingParameters[0].flowRateRoot) : '';
                    weldingProtocolSection4.gasFlowMethod1TopSide = (selectedApplication.pwps.weldingParameters.length > 0) ? setStringEmptyIfZero(selectedApplication.pwps.weldingParameters[0].flowRateTop) : '';
                    weldingProtocolSection4.gasFlowMethod2TopSide = (selectedApplication.pwps.weldingParameters.length >= 2 && (weldingProtocolSection2.selectedWeldingMethod2.id != 0)) ? setStringEmptyIfZero(selectedApplication.pwps.weldingParameters[1].flowRateTop) : '';
                    weldingProtocolSection4.gunAngle = selectedApplication.weldingProtocolSectionsResponse.gunAngle != null ? selectedApplication.weldingProtocolSectionsResponse.gunAngle : selectedApplication.pwps.weldingData.electrodeTorchAngle;
                    weldingProtocolSection4.heatingAndCoolingRate = selectedApplication.pwps.afterWelding.coolingRate != null ? selectedApplication.pwps.afterWelding.coolingRate : '';
                    weldingProtocolSection4.maxInterpassTemperature = selectedApplication.pwps.temperature.maxInterpassTemperature !== 0 ? selectedApplication.pwps.temperature.maxInterpassTemperature.toString().replace('.', ',') : '';;
                    weldingProtocolSection4.minElevatedWorkingTemperature = selectedApplication.pwps.temperature.preheatTemperature !== 0 ? selectedApplication.pwps.temperature.preheatTemperature.toString().replace('.', ',') : '';
                    weldingProtocolSection4.nozzleDiameter = selectedApplication.pwps.weldingData.nozzleDiameter;
                    weldingProtocolSection4.oscillationMaxRunWidth = selectedApplication.pwps.weldingData.maxWidthOfRun;
                    weldingProtocolSection4.typeOfBackingText = selectedApplication.pwps.weldingProcess.typeOfBackingText;
                    weldingProtocolSection4.electrodeTorchAngle111 = selectedApplication.pwps.weldingData.electrodeTorchAngle111;
                    weldingProtocolSection4.plasmaflowrate = selectedApplication.pwps.weldingData.plasmaflowrate;
                    //TODO 
                    if (selectedApplication.weldingProtocolSectionsResponse.postWeldHeatTreatment != null) {
                        weldingProtocolSection4.postWeldHeatTreatment = selectedApplication.weldingProtocolSectionsResponse.postWeldHeatTreatment;
                    }
                    else {
                        let postWeldHeatTreatmentAll = selectedApplication.pwps.afterWelding.heatingRate.toString()
                        weldingProtocolSection4.postWeldHeatTreatment = postWeldHeatTreatmentAll;
                    }
                    
                    weldingProtocolSection4.powerSourceManufacturer = selectedApplication.weldingProtocolSectionsResponse.powerSourceManufacturer;
                    weldingProtocolSection4.tungstenElectrode = selectedApplication.pwps.weldingData.tungstenElectrode;
                    // ShieldingGas1.
                    if (selectedApplication.pwps.weldingProcess.shieldingGas1 && selectedApplication.pwps.weldingProcess.shieldingGas1.id !== 0) {
                        weldingProtocolSection4.shieldingGas1SearchText = selectedApplication.pwps.weldingProcess.shieldingGas1.content;
                        weldingProtocolSection4.shieldingGas1SearchResult = [];
                        weldingProtocolSection4.selectedShieldingGas1 = { ...selectedApplication.pwps.weldingProcess.shieldingGas1 };
                    }
                    else {
                        weldingProtocolSection4.shieldingGas1SearchText = '';
                        weldingProtocolSection4.shieldingGas1SearchResult = [];
                        weldingProtocolSection4.selectedShieldingGas1 = {
                            id: 0,
                            name: '',
                            content: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // RootProtectionGas.
                    if (selectedApplication.pwps.weldingData.rootProtectionGas && selectedApplication.pwps.weldingData.rootProtectionGas.id !== 0) {
                        weldingProtocolSection4.rootProtectionGasSearchText = selectedApplication.pwps.weldingData.rootProtectionGas.content;
                        weldingProtocolSection4.rootProtectionGasSearchResult = [];
                        weldingProtocolSection4.selectedRootProtectionGas = { ...selectedApplication.pwps.weldingData.rootProtectionGas };
                    }
                    else {
                        weldingProtocolSection4.rootProtectionGasSearchText = '';
                        weldingProtocolSection4.rootProtectionGasSearchResult = [];
                        weldingProtocolSection4.selectedRootProtectionGas = {
                            id: 0,
                            name: '',
                            content: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // PlasmaGas.
                    if (selectedApplication.pwps.weldingData.plasmaGas && selectedApplication.pwps.weldingData.plasmaGas.id !== 0) {
                        weldingProtocolSection4.selectedPlasmaGas = { ...selectedApplication.pwps.weldingData.plasmaGas };
                    }
                    else {
                        weldingProtocolSection4.selectedPlasmaGas = {
                            id: 0,
                            name: '',
                            content: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // RootSupportDetails.
                    if (selectedApplication.pwps.weldingData.rootSupportDetails && selectedApplication.pwps.weldingData.rootSupportDetails.id !== 0) {
                        weldingProtocolSection4.selectedRootSupportDetails = { ...selectedApplication.pwps.weldingData.rootSupportDetails };
                    }
                    else {
                        weldingProtocolSection4.selectedRootSupportDetails = {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    // ShieldingGas2.
                    if (selectedApplication.pwps.weldingProcess.shieldingGas2 && selectedApplication.pwps.weldingProcess.shieldingGas2.id !== 0) {
                        weldingProtocolSection4.shieldingGas2SearchText = selectedApplication.pwps.weldingProcess.shieldingGas2.content;
                        //if (selectedApplication.pwps.weldingProcess.shieldingGas2.content) {
                        //    weldingProtocolSection4.shieldingGas2SearchText +=
                        //        " (" + selectedApplication.pwps.weldingProcess.shieldingGas2.content + ")";
                        //}
                        weldingProtocolSection4.shieldingGas2SearchResult = [];
                        weldingProtocolSection4.selectedShieldingGas2 = { ...selectedApplication.pwps.weldingProcess.shieldingGas2 };
                    }
                    else {
                        weldingProtocolSection4.shieldingGas2SearchText = '';
                        weldingProtocolSection4.shieldingGas2SearchResult = [];
                        weldingProtocolSection4.selectedShieldingGas2 = {
                            id: 0,
                            name: '',
                            content: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                    }
                    weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
                    // Initialize WeldingProtocolSection6.
                    /////////////////////////////////////////////////////////////////////////////////////
                    let weldingProtocolSection6: WeldingProtocolSection6 = weldingProtocol.weldingProtocolSection6;
                    weldingProtocolSection6.weldingMethods = [];
                    let weldingMethodEmpty = {
                        id: 0,
                        name: '',
                        efficiencyFactor: 0.0,
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    weldingProtocolSection6.weldingMethods.splice(weldingProtocolSection6.weldingMethods.length, 0, weldingMethodEmpty);
                    weldingProtocolSection6.weldingMethods.splice(weldingProtocolSection6.weldingMethods.length, 0, selectedApplication.pwps.weldingProcess.weldingMethod1);
                    weldingProtocolSection6.weldingMethods.splice(weldingProtocolSection6.weldingMethods.length, 0, selectedApplication.pwps.weldingProcess.weldingMethod2);
                    weldingProtocolSection6.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
                        if (weldingMethod1.name < weldingMethod2.name) {
                            return -1;
                        }
                        else if (weldingMethod1.name > weldingMethod2.name) {
                            return 1;
                        }
                        return 0;
                    });
                    weldingProtocolSection6.weldingParameters = [];
                    for (let i = 0; i < selectedApplication.pwps.weldingParameters.length; i++) {
                        //let weldingMethod: GetWeldingMethodResponse;
                        //if (selectedApplication.pwps.weldingParameters[i].run === 1) {
                        //    weldingMethod = { ...selectedApplication.pwps.weldingProcess.weldingMethod1 };
                        //}
                        //else {
                        //    weldingMethod = { ...selectedApplication.pwps.weldingProcess.weldingMethod2 };
                        //}
                        let weldingParameters: WeldingParameters = new WeldingParameters();
                        weldingParameters.id = selectedApplication.pwps.weldingParameters[i].id;
                        weldingParameters.run = selectedApplication.pwps.weldingParameters[i].run.toString();
                        weldingParameters.runText = selectedApplication.pwps.weldingParameters[i].runText;
                        weldingParameters.index = { ...selectedApplication.pwps.weldingParameters[i].index };
                        weldingParameters.weldingMethod = { ...selectedApplication.pwps.weldingParameters[i].weldingMethod };
                        weldingParameters.diameter = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].diameter);
                        weldingParameters.current = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].current);
                        weldingParameters.voltage = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].voltage);
                        weldingParameters.currentType = { ...selectedApplication.pwps.weldingParameters[i].currentType };
                        weldingParameters.runLength = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].runLength);
                        weldingParameters.time = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].time);
                        weldingParameters.interpassTemperature = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].interpassTemperature);
                        weldingParameters.wireFeed = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].wireFeed);
                        weldingParameters.runWidth = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].runWidth);
                        weldingParameters.travelSpeed = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].travelSpeed);
                        weldingParameters.heatInput = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInput);
                        weldingParameters.createdAt = selectedApplication.pwps.weldingParameters[i].createdAt;
                        weldingParameters.deletedAt = selectedApplication.pwps.weldingParameters[i].deletedAt;
                        weldingParameters.updatedAt = selectedApplication.pwps.weldingParameters[i].updatedAt;
                        weldingParameters.flowRateTop = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].flowRateTop);
                        weldingParameters.flowRateRoot = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].flowRateRoot);
                        weldingParameters.pulseCurrent = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].pulseCurrent);
                        weldingParameters.pulseTime = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].pulseTime);
                        weldingParameters.basicCurrent = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].basicCurrent);
                        weldingParameters.timeForBasicCurrent = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].timeForBasicCurrent);
                        weldingParameters.frequency = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].frequency);
                        weldingParameters.current1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].current1);
                        weldingParameters.time1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].time1);
                        weldingParameters.heatInput1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInput1);
                        weldingParameters.arcEnergyIE = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcEnergyIE);
                        weldingParameters.arcEnergyIP = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcEnergyIP);
                        weldingParameters.arcIP = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcIP);
                        weldingParameters.arcIE = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcIE);
                        weldingParameters.additiveMaterial = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].additiveMaterial);
                        weldingParameters.weldingTecknique = selectedApplication.pwps.weldingParameters[i].weldingTecknique;
                        weldingParameters.fuelGasPressure = selectedApplication.pwps.weldingParameters[i].fuelGasPressure;
                        weldingParameters.oxygenPressure = selectedApplication.pwps.weldingParameters[i].oxygenPressure;
                        weldingParameters.typeOfFlame = selectedApplication.pwps.weldingParameters[i].typeOfFlame;
                        weldingProtocolSection6.weldingParameters.splice(weldingProtocolSection6.weldingParameters.length, 0, weldingParameters);
                    };
                    // Initialize WeldingProtocolSection7.
                    /////////////////////////////////////////////////////////////////////////////////////
                    let weldingProtocolSection7: WeldingProtocolSection7 = weldingProtocol.weldingProtocolSection7;
                    weldingProtocolSection7.remarks = selectedApplication.pwps.otherInformation === null ? "" : selectedApplication.pwps.otherInformation;
                    weldingProtocolSection7.throatThickness = selectedApplication.weldingProtocolSectionsResponse.throatThickness === null ? setStringEmptyIfZero(selectedApplication.pwps.jointDesign.throatThickness) : selectedApplication.weldingProtocolSectionsResponse.throatThickness;
                    weldingProtocolSection7.lengthTackWelds = selectedApplication.pwps.weldingData.lengthTackWelds != null ? selectedApplication.pwps.weldingData.lengthTackWelds : '';
                    //JointDesign
                    weldingProtocolSection7.jointDesign = { ...selectedApplication.pwps.jointDesign };
                    //WeldSequence
                    weldingProtocolSection7.weldSequence = { ...selectedApplication.pwps.weldSequence };
                    // TackWeld.
                    weldingProtocolSection7.tackWeldText = selectedApplication.pwps.weldingData.tackWeldText;

                    weldingProtocol.weldingProtocolSection7 = weldingProtocolSection7;
                    // Initialize WeldingProtocolSection8.
                    /////////////////////////////////////////////////////////////////////////////////////
                    weldingProtocol.placeForMonitoring = selectedApplication.weldingProtocolSectionsResponse.placeForMonitoring != null ? selectedApplication.weldingProtocolSectionsResponse.placeForMonitoring : '';
                    weldingProtocol.weldingDate = selectedApplication.weldingProtocolSectionsResponse.weldingDate != null ? selectedApplication.weldingProtocolSectionsResponse.weldingDate : selectedApplication.testingOrderResponse.weldingDate;
                    let currentUser: UserListObject = { ...appState.login.currentUser };
                    //Set CurrentUser when the signature and employeeNumber is empty
                    weldingProtocol.signature = selectedApplication.weldingProtocolSectionsResponse.signature ? selectedApplication.weldingProtocolSectionsResponse.signature : (currentUser && currentUser.displayName ? currentUser.displayName : '');
                    weldingProtocol.employeeNumber = selectedApplication.weldingProtocolSectionsResponse.employeeNumber ? selectedApplication.weldingProtocolSectionsResponse.employeeNumber : (currentUser && currentUser.userName ? currentUser.userName : '');

                    // Initialize TestingOrder.
                    /////////////////////////////////////////////////////////////////////////////////////
                    weldingProtocol.qwbpOrder = {
                        additionalTesting: selectedApplication.testingOrderResponse.additionalTesting,
                        bendTesting: selectedApplication.testingOrderResponse.bendTesting,
                        chargeNumber1: selectedApplication.testingOrderResponse.chargeNumber1,
                        chargeNumber2: selectedApplication.testingOrderResponse.chargeNumber2,
                        conditionsAndDescriptionRemarks: selectedApplication.testingOrderResponse.conditionsAndDescriptionRemarks,
                        contactPerson: selectedApplication.testingOrderResponse.contactPerson,
                        customer: selectedApplication.testingOrderResponse.customer,
                        date: selectedApplication.testingOrderResponse.date,
                        destructiveTestingApproved: selectedApplication.testingOrderResponse.destructiveTestingApproved,
                        destructiveTestingDate: selectedApplication.testingOrderResponse.destructiveTestingDate,
                        destructiveTestingSignature: selectedApplication.testingOrderResponse.destructiveTestingSignature,
                        dimension1: selectedApplication.testingOrderResponse.dimension1,
                        dimension2: selectedApplication.testingOrderResponse.dimension2,
                        directionOfPlateRolling1: selectedApplication.testingOrderResponse.directionOfPlateRolling1,
                        directionOfPlateRolling2: selectedApplication.testingOrderResponse.directionOfPlateRolling2,
                        fillerMaterial: selectedApplication.testingOrderResponse.fillerMaterial,
                        hardnessTesting: selectedApplication.testingOrderResponse.hardnessTesting,
                        impactTesting: selectedApplication.testingOrderResponse.impactTesting,
                        impactTestTemperature1: selectedApplication.testingOrderResponse.impactTestTemperature1,
                        impactTestTemperature2: selectedApplication.testingOrderResponse.impactTestTemperature2,
                        iso10902: selectedApplication.testingOrderResponse.iso10902,
                        iso10902AdditionalTesting: selectedApplication.testingOrderResponse.iso10902AdditionalTesting,
                        iso10902CruciformTensileTesting: selectedApplication.testingOrderResponse.iso10902CruciformTensileTesting,
                        iso10902ImpactTesting: selectedApplication.testingOrderResponse.iso10902ImpactTesting,
                        iso10902MacroPlusPhoto: selectedApplication.testingOrderResponse.iso10902MacroPlusPhoto,
                        iso10902MicroTesting: selectedApplication.testingOrderResponse.iso10902MicroTesting,
                        iso10902WeldabilityPrimer: selectedApplication.testingOrderResponse.iso10902WeldabilityPrimer,
                        iso10902AdditionalTestingText: selectedApplication.testingOrderResponse.iso10902AdditionalTestingText,
                        iso10902CruciformTensileTestingText: selectedApplication.testingOrderResponse.iso10902CruciformTensileTestingText,
                        iso10902ImpactTestingText: selectedApplication.testingOrderResponse.iso10902ImpactTestingText,
                        iso10902MacroPlusPhotoText: selectedApplication.testingOrderResponse.iso10902MacroPlusPhotoText,
                        iso10902MicroTestingText: selectedApplication.testingOrderResponse.iso10902MicroTestingText,
                        iso10902WeldabilityPrimerText: selectedApplication.testingOrderResponse.iso10902WeldabilityPrimerText,
                        iso129526AdditionalTesting: selectedApplication.testingOrderResponse.iso129526AdditionalTesting,
                        iso129526ImpactTesting: selectedApplication.testingOrderResponse.iso129526ImpactTesting,
                        iso129526LongitudinalTensileTesting: selectedApplication.testingOrderResponse.iso129526LongitudinalTensileTesting,
                        iso129526AdditionalTestingText: selectedApplication.testingOrderResponse.iso129526AdditionalTestingText,
                        iso129526ImpactTestingText: selectedApplication.testingOrderResponse.iso129526ImpactTestingText,
                        iso129526LongitudinalTensileTestingText: selectedApplication.testingOrderResponse.iso129526LongitudinalTensileTestingText,
                        iso129526Testing: selectedApplication.testingOrderResponse.iso129526Testing,
                        iso13134AdditionalTesting: selectedApplication.testingOrderResponse.iso13134AdditionalTesting,
                        iso13134BendTesting: selectedApplication.testingOrderResponse.iso13134BendTesting,
                        iso13134HardnessTesting: selectedApplication.testingOrderResponse.iso13134HardnessTesting,
                        iso13134PeelTesting: selectedApplication.testingOrderResponse.iso13134PeelTesting,
                        iso13134ShearTesting: selectedApplication.testingOrderResponse.iso13134ShearTesting,
                        iso13134TensileTesting: selectedApplication.testingOrderResponse.iso13134TensileTesting,
                        iso13134BrazinProcedureApproval: selectedApplication.testingOrderResponse.iso13134BrazinProcedureApproval,
                        iso13134AdditionalTestingText: selectedApplication.testingOrderResponse.iso13134AdditionalTestingText,
                        iso13134BendTestingText: selectedApplication.testingOrderResponse.iso13134BendTestingText,
                        iso13134HardnessTestingText: selectedApplication.testingOrderResponse.iso13134HardnessTestingText,
                        iso13134PeelTestingText: selectedApplication.testingOrderResponse.iso13134PeelTestingText,
                        iso13134ShearTestingText: selectedApplication.testingOrderResponse.iso13134ShearTestingText,
                        iso13134TensileTestingText: selectedApplication.testingOrderResponse.iso13134TensileTestingText,
                        iso134454Testing: selectedApplication.testingOrderResponse.iso134454Testing,
                        iso134454AdditionalTesting: selectedApplication.testingOrderResponse.iso134454AdditionalTesting,
                        iso134454FerriteTesting: selectedApplication.testingOrderResponse.iso134454FerriteTesting,
                        iso134454ImpactTesting: selectedApplication.testingOrderResponse.iso134454ImpactTesting,
                        iso134454LongitudinalTensileTesting: selectedApplication.testingOrderResponse.iso134454LongitudinalTensileTesting,
                        iso134454MicroTesting: selectedApplication.testingOrderResponse.iso134454MicroTesting,
                        iso134454AdditionalTestingText: selectedApplication.testingOrderResponse.iso134454AdditionalTestingText,
                        iso134454FerriteTestingText: selectedApplication.testingOrderResponse.iso134454FerriteTestingText,
                        iso134454ImpactTestingText: selectedApplication.testingOrderResponse.iso134454ImpactTestingText,
                        iso134454LongitudinalTensileTestingText: selectedApplication.testingOrderResponse.iso134454LongitudinalTensileTestingText,
                        iso134454MicroTestingText: selectedApplication.testingOrderResponse.iso134454MicroTestingText,
                        iso14555AdditionalTesting: selectedApplication.testingOrderResponse.iso14555AdditionalTesting,
                        iso14555ApplicationGreaterThan100Degrees: selectedApplication.testingOrderResponse.iso14555ApplicationGreaterThan100Degrees,
                        iso14555ApplicationLessThanOrEqualTo100Degrees: selectedApplication.testingOrderResponse.iso14555ApplicationLessThanOrEqualTo100Degrees,
                        iso14555BendTesting: selectedApplication.testingOrderResponse.iso14555BendTesting,
                        iso14555ArcStudWelding: selectedApplication.testingOrderResponse.iso14555ArcStudWelding,
                        iso14555_3834Testing: selectedApplication.testingOrderResponse.iso14555_3834Testing,
                        iso14555_783Testing: selectedApplication.testingOrderResponse.iso14555_783Testing,
                        iso14555_784Testing: selectedApplication.testingOrderResponse.iso14555_784Testing,
                        iso14555_785Testing: selectedApplication.testingOrderResponse.iso14555_785Testing,
                        iso14555_786Testing: selectedApplication.testingOrderResponse.iso14555_786Testing,
                        iso14555_787Testing: selectedApplication.testingOrderResponse.iso14555_787Testing,
                        iso14555BendTestingTorqueWrench: selectedApplication.testingOrderResponse.iso14555BendTestingTorqueWrench,
                        iso14555MacroPlusPhoto: selectedApplication.testingOrderResponse.iso14555MacroPlusPhoto,
                        iso14555TensileTesting: selectedApplication.testingOrderResponse.iso14555TensileTesting,
                        iso14555TorqueTesting: selectedApplication.testingOrderResponse.iso14555TorqueTesting,
                        iso14555RTProvning: selectedApplication.testingOrderResponse.iso14555RTProvning,
                        iso14555AdditionalTestingText: selectedApplication.testingOrderResponse.iso14555AdditionalTestingText,
                        iso14555ApplicationGreaterThan100DegreesText: selectedApplication.testingOrderResponse.iso14555ApplicationGreaterThan100DegreesText,
                        iso14555ApplicationLessThanOrEqualTo100DegreesText: selectedApplication.testingOrderResponse.iso14555ApplicationLessThanOrEqualTo100DegreesText,
                        iso14555BendTestingText: selectedApplication.testingOrderResponse.iso14555BendTestingText,
                        iso14555BendTestingTorqueWrenchText: selectedApplication.testingOrderResponse.iso14555BendTestingTorqueWrenchText,
                        iso14555MacroPlusPhotoText: selectedApplication.testingOrderResponse.iso14555MacroPlusPhotoText,
                        iso14555TensileTestingText: selectedApplication.testingOrderResponse.iso14555TensileTestingText,
                        iso14555TorqueTestingText: selectedApplication.testingOrderResponse.iso14555TorqueTestingText,
                        iso14555RTProvningText: selectedApplication.testingOrderResponse.iso14555RTProvningText,
                        iso14555_3834TestingText: selectedApplication.testingOrderResponse.iso14555_3834TestingText,
                        iso156141Edition2004: selectedApplication.testingOrderResponse.iso156141Edition2004,
                        iso156141Edition2017: selectedApplication.testingOrderResponse.iso156141Edition2017,
                        macroPlusPhoto: selectedApplication.testingOrderResponse.macroPlusPhoto,
                        manufacturerWPQRBPARNumber: selectedApplication.testingOrderResponse.manufacturerWPQRBPARNumber,
                        material1: selectedApplication.testingOrderResponse.material1,
                        material2: selectedApplication.testingOrderResponse.material2,
                        materialGroup1: selectedApplication.testingOrderResponse.materialGroup1,
                        materialGroup2: selectedApplication.testingOrderResponse.materialGroup2,
                        materialSpecifications1Remarks: selectedApplication.testingOrderResponse.materialSpecifications1Remarks,
                        materialSpecifications2Remarks: selectedApplication.testingOrderResponse.materialSpecifications2Remarks,
                        mtPT: selectedApplication.testingOrderResponse.mtPT,
                        ndtApproved: selectedApplication.testingOrderResponse.ndtApproved,
                        ndtDate: selectedApplication.testingOrderResponse.ndtDate,
                        ndtSignature: selectedApplication.testingOrderResponse.ndtSignature,
                        ndtOther: selectedApplication.testingOrderResponse.ndtOther,
                        norsokAdditionalTesting: selectedApplication.testingOrderResponse.norsokAdditionalTesting,
                        norsokAdditionalTesting1: selectedApplication.testingOrderResponse.norsokAdditionalTesting1,
                        norsokAdditionalTesting2: selectedApplication.testingOrderResponse.norsokAdditionalTesting2,
                        norsokAdditionalTesting3: selectedApplication.testingOrderResponse.norsokAdditionalTesting3,
                        norsokAdditionalTesting4: selectedApplication.testingOrderResponse.norsokAdditionalTesting4,
                        norsokAdditionalTestingText: selectedApplication.testingOrderResponse.norsokAdditionalTestingText,
                        norsokAdditionalTesting1Text: selectedApplication.testingOrderResponse.norsokAdditionalTesting1Text,
                        norsokAdditionalTesting2Text: selectedApplication.testingOrderResponse.norsokAdditionalTesting2Text,
                        norsokAdditionalTesting3Text: selectedApplication.testingOrderResponse.norsokAdditionalTesting3Text,
                        norsokAdditionalTesting4Text: selectedApplication.testingOrderResponse.norsokAdditionalTesting4Text,
                        norsokCorrosionTestingText: selectedApplication.testingOrderResponse.norsokCorrosionTestingText,
                        norsokCorrosionTesting: selectedApplication.testingOrderResponse.norsokCorrosionTesting,
                        norsok: selectedApplication.testingOrderResponse.norsok,
                        phoneNumber: selectedApplication.testingOrderResponse.phoneNumber,
                        pWPSPBPS: selectedApplication.testingOrderResponse.pWPSPBPS,
                        orderedByLevel3: selectedApplication.testingOrderResponse.orderedByLevel3,
                        other: selectedApplication.testingOrderResponse.other,
                        ndtRemarks: selectedApplication.testingOrderResponse.ndtRemarks,
                        rt: selectedApplication.testingOrderResponse.rt,
                        signature: selectedApplication.testingOrderResponse.signature,
                        standardForAcceptance1: selectedApplication.testingOrderResponse.standardForAcceptance1,
                        standardForAcceptance2: selectedApplication.testingOrderResponse.standardForAcceptance2,
                        transverseTensilePlusRp0ReH: selectedApplication.testingOrderResponse.transverseTensilePlusRp0ReH,
                        ut: selectedApplication.testingOrderResponse.ut,
                        visual: selectedApplication.testingOrderResponse.visual,
                        weldingDate: selectedApplication.testingOrderResponse.weldingDate,
                        weldingBrazingPositions: selectedApplication.testingOrderResponse.weldingBrazingPositions,
                        weldingProcess: selectedApplication.testingOrderResponse.weldingProcess,
                        welderBrazerFirstName: selectedApplication.testingOrderResponse.welderBrazerFirstName,
                        welderBrazerLastName: selectedApplication.testingOrderResponse.welderBrazerLastName,
                        iso15614transverseTensilePlusRp0ReHText: selectedApplication.testingOrderResponse.iso15614transverseTensilePlusRp0ReHText,
                        iso15614macroPlusPhotoText: selectedApplication.testingOrderResponse.iso15614macroPlusPhotoText,
                        iso15614hardnessTestingText: selectedApplication.testingOrderResponse.iso15614hardnessTestingText,
                        iso15614impactTestingText: selectedApplication.testingOrderResponse.iso15614impactTestingText,
                        iso15614bendTestingText: selectedApplication.testingOrderResponse.iso15614bendTestingText,
                        iso15614additionalTestingText: selectedApplication.testingOrderResponse.iso15614additionalTestingText,
                        pedAdditionalTesting: selectedApplication.testingOrderResponse.pedAdditionalTesting,
                        iso15614TestingText: selectedApplication.testingOrderResponse.iso15614TestingText,
                        dekraProcedureNoTesting: selectedApplication.testingOrderResponse.dekraProcedureNoTesting,
                        invoiceReferenceName: selectedApplication.testingOrderResponse.invoiceReferenceName,
                        uppdragsnr: selectedApplication.testingOrderResponse.uppdragsnr,
                        testmarking: selectedApplication.testingOrderResponse.testmarking,
                        nace: selectedApplication.testingOrderResponse.nace,
                        naceMr0103Testing: selectedApplication.testingOrderResponse.naceMr0103Testing,
                        naceMr0103TestingText: selectedApplication.testingOrderResponse.naceMr0103TestingText,
                        naceMr0175Testing: selectedApplication.testingOrderResponse.naceMr0175Testing,
                        naceMr0175TestingText: selectedApplication.testingOrderResponse.naceMr0175TestingText,
                        naceAdditionalTesting1: selectedApplication.testingOrderResponse.naceAdditionalTesting1,
                        naceAdditionalTesting1Text: selectedApplication.testingOrderResponse.naceAdditionalTesting1Text,
                        naceAdditionalTesting2: selectedApplication.testingOrderResponse.naceAdditionalTesting2,
                        naceAdditionalTesting2Text: selectedApplication.testingOrderResponse.naceAdditionalTesting2Text,
                    }
                    // Initialize FilesUpload.
                    /////////////////////////////////////////////////////////////////////////////////////

                    weldingProtocol.filesUpload = [ ...selectedApplication.pwps.filesUpload ];

                    dispatch({ type: 'INIT_WELDING_PROTOCOL', selectedApplication: selectedApplication, applicationsIsOpen: false, weldingProtocol: weldingProtocol })
                }
                function setStringEmptyIfZero(numberValue: number) {
                    let returnValue: string = '';

                    if (numberValue !== 0) {
                        returnValue = numberValue.toString().replace(".",",");
                    }
                    return returnValue;
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_APPLICATION_REQUEST' });
    },
    closeWeldingProtocol: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let selectedApplication: GetApplicationResponse = { ...appState.wpqrDekraTab.selectedApplication };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            selectedApplication.id = 0;
            dispatch({ type: 'INIT_WELDING_PROTOCOL', selectedApplication: selectedApplication, applicationsIsOpen: false, weldingProtocol: weldingProtocol })
        }
    },
    updateWeldingProtocolId: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolId = 0;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    addAndRemoveFilesToFilesUpload: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab && appState.fileUpload) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            let updateFilesUploadRequest: Array<UpdateFilesUploadRequest> = [];
            for (let i = 0; i < appState.fileUpload.filesUpload.length; i++) {
                let updateFileUploadRequest: UpdateFilesUploadRequest = {
                    fileName: appState.fileUpload.filesUpload[i].fileName,
                    fileBase64: appState.fileUpload.filesUpload[i].fileBase64,
                    filePath: appState.fileUpload.filesUpload[i].filePath,
                    fileDescription: appState.fileUpload.filesUpload[i].fileDescription,
                    fileGuid: '',
                    selectedFile: appState.fileUpload.filesUpload[i].selectedFile
                };
                // Delete
                if ((appState.fileUpload.filesUpload[i].filePath === weldingProtocol.wpqrId.toString()) && (appState.fileUpload.filesUpload[i].fileDescription != "wps")) {
                } else {
                    updateFilesUploadRequest.push(updateFileUploadRequest);
                }
            }
            
            // Add weldingprotocol pdf
            let addweldingprotocolFileUploadRequest: UpdateFilesUploadRequest = {
                fileName: weldingProtocol.dekraProcedureNumber + "_WP",
                fileBase64: "",
                filePath: weldingProtocol.wpqrId.toString(),
                fileDescription: "wp",
                fileGuid: '',
                selectedFile: false
            }
            updateFilesUploadRequest.push(addweldingprotocolFileUploadRequest);
            // Add Wpqr pdf
            let addWpqrFileUploadRequestPdf: UpdateFilesUploadRequest = {
                fileName: weldingProtocol.dekraProcedureNumber + "_WPQR.pdf",
                fileBase64: "",
                filePath: weldingProtocol.wpqrId.toString(),
                fileDescription: "Wpqr",
                fileGuid: '',
                selectedFile: false
            }
            updateFilesUploadRequest.push(addWpqrFileUploadRequestPdf);
            // Add Wpqr word (docx)
            let addWpqrFileUploadRequestDocx: UpdateFilesUploadRequest = {
                fileName: weldingProtocol.dekraProcedureNumber + "_WPQR.docx",
                fileBase64: "",
                filePath: weldingProtocol.wpqrId.toString(),
                fileDescription: "Wpqr",
                fileGuid: '',
                selectedFile: false
            }
            updateFilesUploadRequest.push(addWpqrFileUploadRequestDocx);

            weldingProtocol.filesUpload = [...updateFilesUploadRequest];
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    initWeldingTestingDetails1: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            dispatch({ type: 'INIT_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    initWPQTReport: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.wpqtReportPerformedTestsTableRows.splice(0, wpqtReport.wpqtReportPerformedTestsTableRows.length);
            for (let i = 0; i < 14; i++) {
                wpqtReport.wpqtReportPerformedTestsTableRows.splice(
                    wpqtReport.wpqtReportPerformedTestsTableRows.length,
                    0,
                    new WPQTReportPerformedTestsTableRows('', false, '', '', ''));
            }
            dispatch({ type: 'INIT_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setApplicationsIsOpen: (applicationsIsOpen: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_APPLICATIONS_IS_OPEN', applicationsIsOpen: applicationsIsOpen });
    },
    setApplicationSupervisor: (applicationId: number, supervisor: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let applications: Array<GetApplicationResponse> = [...appState.wpqrDekraTab.applications];
            for (let i = 0; i < applications.length; i++) {
                if (applicationId === applications[i].id) {
                    applications[i].supervisor = supervisor;
                    console.log('supervisor');
                    console.log(supervisor);
                    break;
                }
            }
            dispatch({ type: 'UPDATE_APPLICATIONS_RECEIVE', applications: applications });
        }
    },
    setBendTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTesting: BendTesting = { ...appState.wpqrDekraTab.bendTesting };
            bendTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_BEND_TESTING', bendTesting: bendTesting });
        }
    },
    setBendTestingMeasuringInstrument: (measuringInstrument: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTesting: BendTesting = { ...appState.wpqrDekraTab.bendTesting };
            bendTesting.measuringInstrument = measuringInstrument;
            dispatch({ type: 'SET_BEND_TESTING', bendTesting: bendTesting });
        }
    },
    setBendTestingMeasuringInstrumentSerialNumber: (measuringInstrumentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTesting: BendTesting = { ...appState.wpqrDekraTab.bendTesting };
            bendTesting.measuringInstrumentSerialNumber = measuringInstrumentSerialNumber;
            dispatch({ type: 'SET_BEND_TESTING', bendTesting: bendTesting });
        }
    },
    setBendTestingTestingMachine: (testingMachine: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTesting: BendTesting = { ...appState.wpqrDekraTab.bendTesting };
            bendTesting.testingMachine = testingMachine;
            dispatch({ type: 'SET_BEND_TESTING', bendTesting: bendTesting });
        }
    },
    setBendTestingTestingMachineSerialNumber: (testingMachineSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTesting: BendTesting = { ...appState.wpqrDekraTab.bendTesting };
            bendTesting.testingMachineSerialNumber = testingMachineSerialNumber;
            dispatch({ type: 'SET_BEND_TESTING', bendTesting: bendTesting });
        }
    },
    setBendTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTesting: BendTesting = { ...appState.wpqrDekraTab.bendTesting };
            bendTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_BEND_TESTING', bendTesting: bendTesting });
        }
    },
    setBendTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsBendingAngle: (rowNumber: number, bendingAngle: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].bendingAngle = bendingAngle;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.comments = comments;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsDiameterOfRoller: (rowNumber: number, diameterOfRoller: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].diameterOfRoller = diameterOfRoller;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsDistanceBetweenRollers: (rowNumber: number, distanceBetweenRollers: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].distanceBetweenRollers = distanceBetweenRollers;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsSurfaceCondition: (rowNumber: number, surfaceCondition: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].surfaceCondition = surfaceCondition;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsTestSpecimenDimensions: (rowNumber: number, testSpecimenDimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions = testSpecimenDimensions;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingResultsTestSpecimenType: (rowNumber: number, testSpecimenType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTestingResults: BendTestingResults = { ...appState.wpqrDekraTab.bendTestingResults };
            bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenType = testSpecimenType;
            dispatch({ type: 'SET_BEND_TESTING_RESULTS', bendTestingResults: bendTestingResults });
        }
    },
    setBendTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTesting: BendTesting = { ...appState.wpqrDekraTab.bendTesting };
            bendTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_BEND_TESTING', bendTesting: bendTesting });
        }
    },
    setBendTestingTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let bendTesting: BendTesting = { ...appState.wpqrDekraTab.bendTesting };
            bendTesting.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_BEND_TESTING', bendTesting: bendTesting });
        }
    },
    setChemicalTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setChemcialTestingMeasuringInstrument: (measuringInstrument: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.measuringInstrument = measuringInstrument;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setChemcialTestingMeasuringInstrumentSerialNumber: (measuringInstrumentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.measuringInstrumentSerialNumber = measuringInstrumentSerialNumber;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setChemicalTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsInterpretationsConclusions1: (interpretationsConclusions1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.interpretationsConclusions1 = interpretationsConclusions1;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsInterpretationsConclusions2: (interpretationsConclusions2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.interpretationsConclusions2 = interpretationsConclusions2;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1Grade: (rowNumber: number, grade: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].grade = grade;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements11: (rowNumber: number, massPercentPartOfElements11: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements11 = massPercentPartOfElements11;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements12: (rowNumber: number, massPercentPartOfElements12: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements12 = massPercentPartOfElements12;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements13: (rowNumber: number, massPercentPartOfElements13: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements13 = massPercentPartOfElements13;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements14: (rowNumber: number, massPercentPartOfElements14: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements14 = massPercentPartOfElements14;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements15: (rowNumber: number, massPercentPartOfElements15: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements15 = massPercentPartOfElements15;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements16: (rowNumber: number, massPercentPartOfElements16: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements16 = massPercentPartOfElements16;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements17: (rowNumber: number, massPercentPartOfElements17: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements17 = massPercentPartOfElements17;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements18: (rowNumber: number, massPercentPartOfElements18: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements18 = massPercentPartOfElements18;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements19: (rowNumber: number, massPercentPartOfElements19: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements19 = massPercentPartOfElements19;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements21: (rowNumber: number, massPercentPartOfElements21: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements21 = massPercentPartOfElements21;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements22: (rowNumber: number, massPercentPartOfElements22: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements22 = massPercentPartOfElements22;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements23: (rowNumber: number, massPercentPartOfElements23: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements23 = massPercentPartOfElements23;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements24: (rowNumber: number, massPercentPartOfElements24: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements24 = massPercentPartOfElements24;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements25: (rowNumber: number, massPercentPartOfElements25: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements25 = massPercentPartOfElements25;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements26: (rowNumber: number, massPercentPartOfElements26: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements26 = massPercentPartOfElements26;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements27: (rowNumber: number, massPercentPartOfElements27: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements27 = massPercentPartOfElements27;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements28: (rowNumber: number, massPercentPartOfElements28: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements28 = massPercentPartOfElements28;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1MassPercentPartOfElements29: (rowNumber: number, massPercentPartOfElements29: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements29 = massPercentPartOfElements29;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable1TestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2Grade: (rowNumber: number, grade: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].grade = grade;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements11: (rowNumber: number, massPercentPartOfElements11: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements11 = massPercentPartOfElements11;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements12: (rowNumber: number, massPercentPartOfElements12: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements12 = massPercentPartOfElements12;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements13: (rowNumber: number, massPercentPartOfElements13: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements13 = massPercentPartOfElements13;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements14: (rowNumber: number, massPercentPartOfElements14: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements14 = massPercentPartOfElements14;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements15: (rowNumber: number, massPercentPartOfElements15: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements15 = massPercentPartOfElements15;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements16: (rowNumber: number, massPercentPartOfElements16: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements16 = massPercentPartOfElements16;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements17: (rowNumber: number, massPercentPartOfElements17: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements17 = massPercentPartOfElements17;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements18: (rowNumber: number, massPercentPartOfElements18: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements18 = massPercentPartOfElements18;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements19: (rowNumber: number, massPercentPartOfElements19: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements19 = massPercentPartOfElements19;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements21: (rowNumber: number, massPercentPartOfElements21: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements21 = massPercentPartOfElements21;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements22: (rowNumber: number, massPercentPartOfElements22: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements22 = massPercentPartOfElements22;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements23: (rowNumber: number, massPercentPartOfElements23: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements23 = massPercentPartOfElements23;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements24: (rowNumber: number, massPercentPartOfElements24: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements24 = massPercentPartOfElements24;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements25: (rowNumber: number, massPercentPartOfElements25: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements25 = massPercentPartOfElements25;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements26: (rowNumber: number, massPercentPartOfElements26: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements26 = massPercentPartOfElements26;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements27: (rowNumber: number, massPercentPartOfElements27: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements27 = massPercentPartOfElements27;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements28: (rowNumber: number, massPercentPartOfElements28: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements28 = massPercentPartOfElements28;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2MassPercentPartOfElements29: (rowNumber: number, massPercentPartOfElements29: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements29 = massPercentPartOfElements29;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingResultsTable2TestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTestingResults: ChemicalTestingResults = { ...appState.wpqrDekraTab.chemicalTestingResults };
            chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_CHEMICAL_TESTING_RESULTS', chemicalTestingResults: chemicalTestingResults });
        }
    },
    setChemicalTestingSpecimensQuantity: (specimensQuantity: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.specimensQuantity = specimensQuantity;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setChemcialTestingTestingEquipment: (testingEquipment: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.testingEquipment = testingEquipment;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setChemcialTestingTestingEquipmentSerialNumber: (testingEquipmentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.testingEquipmentSerialNumber = testingEquipmentSerialNumber;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setChemicalTestingTestingMethod: (testingMethod: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.testingMethod = testingMethod;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setChemicalTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setChemicalTestingTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setChemicalTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let chemicalTesting: ChemicalTesting = { ...appState.wpqrDekraTab.chemicalTesting };
            chemicalTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_CHEMICAL_TESTING', chemicalTesting: chemicalTesting });
        }
    },
    setCorrosionTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTesting: CorrosionTesting = { ...appState.wpqrDekraTab.corrosionTesting };
            corrosionTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_CORROSION_TESTING', corrosionTesting: corrosionTesting });
        }
    },
    setCorrosionTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTestingResults: CorrosionTestingResults = { ...appState.wpqrDekraTab.corrosionTestingResults };
            corrosionTestingResults.corrosionTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_CORROSION_TESTING_RESULTS', corrosionTestingResults: corrosionTestingResults });
        }
    },
    setCorrosionTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTestingResults: CorrosionTestingResults = { ...appState.wpqrDekraTab.corrosionTestingResults };
            corrosionTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_CORROSION_TESTING_RESULTS', corrosionTestingResults: corrosionTestingResults });
        }
    },
    setCorrosionTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTestingResults: CorrosionTestingResults = { ...appState.wpqrDekraTab.corrosionTestingResults };
            corrosionTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_CORROSION_TESTING_RESULTS', corrosionTestingResults: corrosionTestingResults });
        }
    },
    setCorrosionTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTestingResults: CorrosionTestingResults = { ...appState.wpqrDekraTab.corrosionTestingResults };
            corrosionTestingResults.comments = comments;
            dispatch({ type: 'SET_CORROSION_TESTING_RESULTS', corrosionTestingResults: corrosionTestingResults });
        }
    },
    setCorrosionTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTestingResults: CorrosionTestingResults = { ...appState.wpqrDekraTab.corrosionTestingResults };
            corrosionTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_CORROSION_TESTING_RESULTS', corrosionTestingResults: corrosionTestingResults });
        }
    },
    setCorrosionTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTestingResults: CorrosionTestingResults = { ...appState.wpqrDekraTab.corrosionTestingResults };
            corrosionTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_CORROSION_TESTING_RESULTS', corrosionTestingResults: corrosionTestingResults });
        }
    },
    setCorrosionTestingResultsTestingResult: (rowNumber: number, testingResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTestingResults: CorrosionTestingResults = { ...appState.wpqrDekraTab.corrosionTestingResults };
            corrosionTestingResults.corrosionTestingResultsTableRows[rowNumber - 1].testingResult = testingResult;
            dispatch({ type: 'SET_CORROSION_TESTING_RESULTS', corrosionTestingResults: corrosionTestingResults });
        }
    },
    setCorrosionTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTestingResults: CorrosionTestingResults = { ...appState.wpqrDekraTab.corrosionTestingResults };
            corrosionTestingResults.corrosionTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_CORROSION_TESTING_RESULTS', corrosionTestingResults: corrosionTestingResults });
        }
    },
    setCorrosionTestingTestingCondition: (testingCondition: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTesting: CorrosionTesting = { ...appState.wpqrDekraTab.corrosionTesting };
            corrosionTesting.testingCondition = testingCondition;
            dispatch({ type: 'SET_CORROSION_TESTING', corrosionTesting: corrosionTesting });
        }
    },
    setCorrosionTestingTestingMethodDescription: (testingMethodDescription: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTesting: CorrosionTesting = { ...appState.wpqrDekraTab.corrosionTesting };
            corrosionTesting.testingMethodDescription = testingMethodDescription;
            dispatch({ type: 'SET_CORROSION_TESTING', corrosionTesting: corrosionTesting });
        }
    },
    setCorrosionTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTesting: CorrosionTesting = { ...appState.wpqrDekraTab.corrosionTesting };
            corrosionTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_CORROSION_TESTING', corrosionTesting: corrosionTesting });
        }
    },
    setCorrosionTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let corrosionTesting: CorrosionTesting = { ...appState.wpqrDekraTab.corrosionTesting };
            corrosionTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_CORROSION_TESTING', corrosionTesting: corrosionTesting });
        }
    },
    setCruciformTensileTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            cruciformTensileTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting });
        }
    },
    setCruciformTensileTestingMeasuringInstrument: (measuringInstrument: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            cruciformTensileTesting.measuringInstrument = measuringInstrument;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting });
        }
    },
    setCruciformTensileTestingMeasuringInstrumentSerialNumber: (measuringInstrumentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            cruciformTensileTesting.measuringInstrumentSerialNumber = measuringInstrumentSerialNumber;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting });
        }
    },
    setCruciformTensileTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.comments = comments;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsFm: (rowNumber: number, fm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].fm = fm;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsMeasurementUncertaintyUFm: (measurementUncertaintyUFm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.measurementUncertaintyUFm = measurementUncertaintyUFm;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsMeasurementUncertaintyURm: (measurementUncertaintyURm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.measurementUncertaintyURm = measurementUncertaintyURm;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsMeasurementUncertaintyUWf: (measurementUncertaintyUWf: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.measurementUncertaintyUWf = measurementUncertaintyUWf;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsPlaceOfBreak: (rowNumber: number, placeOfBreak: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].placeOfBreak = placeOfBreak;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsRm: (rowNumber: number, rm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].rm = rm;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsTestSpecimenDimensions: (rowNumber: number, testSpecimenDimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].testSpecimenDimensions = testSpecimenDimensions;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingResultsWf: (rowNumber: number, wf: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTestingResults: CruciformTensileTestingResults = { ...appState.wpqrDekraTab.cruciformTensileTestingResults };
            cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].wf = wf;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS', cruciformTensileTestingResults: cruciformTensileTestingResults });
        }
    },
    setCruciformTensileTestingSampleQuantity: (sampleQuantity: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            cruciformTensileTesting.sampleQuantity = sampleQuantity;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting });
        }
    },
    setCruciformTensileTestingTestingMachine: (testingMachine: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            cruciformTensileTesting.testingMachine = testingMachine;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting });
        }
    },
    setCruciformTensileTestingTestingMachineSerialNumber: (testingMachineSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            cruciformTensileTesting.testingMachineSerialNumber = testingMachineSerialNumber;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting });
        }
    },
    setCruciformTensileTestingTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            cruciformTensileTesting.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting });
        }
    },
    setCruciformTensileTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            cruciformTensileTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting });
        }
    },
    setCruciformTensileTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let cruciformTensileTesting: CruciformTensileTesting = { ...appState.wpqrDekraTab.cruciformTensileTesting };
            cruciformTensileTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_CRUCIFORM_TENSILE_TESTING', cruciformTensileTesting: cruciformTensileTesting });
        }
    },
    setFerriteTestingFerriteMeter: (ferriteMeter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.ferriteMeter = ferriteMeter;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingFerriteMeterSerialNumber: (ferriteMeterSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.ferriteMeterSerialNumber = ferriteMeterSerialNumber;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingMeasuringInstrument: (measuringInstrument: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.measuringInstrument = measuringInstrument;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingMeasuringInstrumentSerialNumber: (measuringInstrumentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.measuringInstrumentSerialNumber = measuringInstrumentSerialNumber;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.ferriteTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_FERRITE_TESTING_RESULTS', ferriteTestingResults: ferriteTestingResults });
        }
    },
    setFerriteTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_FERRITE_TESTING_RESULTS', ferriteTestingResults: ferriteTestingResults });
        }
    },
    setFerriteTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_FERRITE_TESTING_RESULTS', ferriteTestingResults: ferriteTestingResults });
        }
    },
    setFerriteTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.comments = comments;
            dispatch({ type: 'SET_FERRITE_TESTING_RESULTS', ferriteTestingResults: ferriteTestingResults });
        }
    },
    setFerriteTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_FERRITE_TESTING_RESULTS', ferriteTestingResults: ferriteTestingResults });
        }
    },
    setFerriteTestingResultsFerriteContent: (rowNumber: number, ferriteContent: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.ferriteTestingResultsTableRows[rowNumber - 1].ferriteContent = ferriteContent;
            dispatch({ type: 'SET_FERRITE_TESTING_RESULTS', ferriteTestingResults: ferriteTestingResults });
        }
    },
    setFerriteTestingResultsMeasurementUncertainty: (rowNumber: number, measurementUncertainty: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.ferriteTestingResultsTableRows[rowNumber - 1].measurementUncertainty = measurementUncertainty;
            dispatch({ type: 'SET_FERRITE_TESTING_RESULTS', ferriteTestingResults: ferriteTestingResults });
        }
    },
    setFerriteTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_FERRITE_TESTING_RESULTS', ferriteTestingResults: ferriteTestingResults });
        }
    },
    setFerriteTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTestingResults: FerriteTestingResults = { ...appState.wpqrDekraTab.ferriteTestingResults };
            ferriteTestingResults.ferriteTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_FERRITE_TESTING_RESULTS', ferriteTestingResults: ferriteTestingResults });
        }
    },
    setFerriteTestingSpecimensQuantity: (specimensQuantity: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.specimensQuantity = specimensQuantity;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingStandardsSet: (standardsSet: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.standardsSet = standardsSet;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingStandardsSerialNumber: (standardsSetSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.standardsSetSerialNumber = standardsSetSerialNumber;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFerriteTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let ferriteTesting: FerriteTesting = { ...appState.wpqrDekraTab.ferriteTesting };
            ferriteTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_FERRITE_TESTING', ferriteTesting: ferriteTesting });
        }
    },
    setFractureTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setFractureTestingMeasuringInstrument: (measuringInstrument: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.measuringInstrument = measuringInstrument;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setFractureTestingMeasuringInstrumentSerialNumber: (measuringInstrumentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.measuringInstrumentSerialNumber = measuringInstrumentSerialNumber;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setFractureTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTestingResults: FractureTestingResults = { ...appState.wpqrDekraTab.fractureTestingResults };
            fractureTestingResults.fractureTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_FRACTURE_TESTING_RESULTS', fractureTestingResults: fractureTestingResults });
        }
    },
    setFractureTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTestingResults: FractureTestingResults = { ...appState.wpqrDekraTab.fractureTestingResults };
            fractureTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_FRACTURE_TESTING_RESULTS', fractureTestingResults: fractureTestingResults });
        }
    },
    setFractureTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTestingResults: FractureTestingResults = { ...appState.wpqrDekraTab.fractureTestingResults };
            fractureTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_FRACTURE_TESTING_RESULTS', fractureTestingResults: fractureTestingResults });
        }
    },
    setFractureTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTestingResults: FractureTestingResults = { ...appState.wpqrDekraTab.fractureTestingResults };
            fractureTestingResults.comments = comments;
            dispatch({ type: 'SET_FRACTURE_TESTING_RESULTS', fractureTestingResults: fractureTestingResults });
        }
    },
    setFractureTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTestingResults: FractureTestingResults = { ...appState.wpqrDekraTab.fractureTestingResults };
            fractureTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_FRACTURE_TESTING_RESULTS', fractureTestingResults: fractureTestingResults });
        }
    },
    setFractureTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTestingResults: FractureTestingResults = { ...appState.wpqrDekraTab.fractureTestingResults };
            fractureTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_FRACTURE_TESTING_RESULTS', fractureTestingResults: fractureTestingResults });
        }
    },
    setFractureTestingResultsTestResults: (rowNumber: number, testResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTestingResults: FractureTestingResults = { ...appState.wpqrDekraTab.fractureTestingResults };
            fractureTestingResults.fractureTestingResultsTableRows[rowNumber - 1].testResult = testResult;
            dispatch({ type: 'SET_FRACTURE_TESTING_RESULTS', fractureTestingResults: fractureTestingResults });
        }
    },
    setFractureTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTestingResults: FractureTestingResults = { ...appState.wpqrDekraTab.fractureTestingResults };
            fractureTestingResults.fractureTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_FRACTURE_TESTING_RESULTS', fractureTestingResults: fractureTestingResults });
        }
    },
    setFractureTestingTestingMachine: (testingMachine: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.testingMachine = testingMachine;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setFractureTestingTestingMachineSerialNumber: (testingMachineSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.testingMachineSerialNumber = testingMachineSerialNumber;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setFractureTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setFractureTestingTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setFractureTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setFractureTestingTestSpecimensDescription: (testSpecimensDescription: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.testSpecimensDescription = testSpecimensDescription;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setFractureTestingTestSpecimensQuantity: (testSpecimensQuantity: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let fractureTesting: FractureTesting = { ...appState.wpqrDekraTab.fractureTesting };
            fractureTesting.testSpecimensQuantity = testSpecimensQuantity;
            dispatch({ type: 'SET_FRACTURE_TESTING', fractureTesting: fractureTesting });
        }
    },
    setHardnessTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTesting: HardnessTesting = { ...appState.wpqrDekraTab.hardnessTesting };
            hardnessTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_HARDNESS_TESTING', hardnessTesting: hardnessTesting });
        }
    },
    setHardnessTestingMeasuringInstrument: (measuringInstrument: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTesting: HardnessTesting = { ...appState.wpqrDekraTab.hardnessTesting };
            hardnessTesting.measuringInstrument = measuringInstrument;
            dispatch({ type: 'SET_HARDNESS_TESTING', hardnessTesting: hardnessTesting });
        }
    },
    setHardnessTestingMeasuringInstrumentSerialNumber: (measuringInstrumentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTesting: HardnessTesting = { ...appState.wpqrDekraTab.hardnessTesting };
            hardnessTesting.measuringInstrumentSerialNumber = measuringInstrumentSerialNumber;
            dispatch({ type: 'SET_HARDNESS_TESTING', hardnessTesting: hardnessTesting });
        }
    },
    setHardnessTestingResultsAcceptance: (acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.acceptance = acceptance;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.comments = comments;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsMeasurementUncertainty: (measurementUncertainty: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.measurementUncertainty = measurementUncertainty;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ4: (rowNumber: number, haz4: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz4 = haz4;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ5: (rowNumber: number, haz5: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz5 = haz5;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ6: (rowNumber: number, haz6: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz6 = haz6;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ10: (rowNumber: number, haz10: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz10 = haz10;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ11: (rowNumber: number, haz11: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz11 = haz11;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1HAZ12: (rowNumber: number, haz12: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz12 = haz12;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent1: (rowNumber: number, parent1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent1 = parent1;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent2: (rowNumber: number, parent2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent2 = parent2;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent3: (rowNumber: number, parent3: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent3 = parent3;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent13: (rowNumber: number, parent13: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent13 = parent13;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent14: (rowNumber: number, parent14: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent14 = parent14;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Parent15: (rowNumber: number, parent15: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent15 = parent15;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Weld7: (rowNumber: number, weld7: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld7 = weld7;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Weld8: (rowNumber: number, weld8: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld8 = weld8;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable1Weld9: (rowNumber: number, weld9: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld9 = weld9;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ4: (rowNumber: number, haz4: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz4 = haz4;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ5: (rowNumber: number, haz5: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz5 = haz5;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ6: (rowNumber: number, haz6: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz6 = haz6;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ10: (rowNumber: number, haz10: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz10 = haz10;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ11: (rowNumber: number, haz11: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz11 = haz11;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2HAZ12: (rowNumber: number, haz12: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz12 = haz12;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent1: (rowNumber: number, parent1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent1 = parent1;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent2: (rowNumber: number, parent2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent2 = parent2;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent3: (rowNumber: number, parent3: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent3 = parent3;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent13: (rowNumber: number, parent13: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent13 = parent13;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent14: (rowNumber: number, parent14: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent14 = parent14;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Parent15: (rowNumber: number, parent15: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent15 = parent15;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Weld7: (rowNumber: number, weld7: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld7 = weld7;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Weld8: (rowNumber: number, weld8: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld8 = weld8;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTable2Weld9: (rowNumber: number, weld9: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld9 = weld9;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsWayOfMeasurementOfHardness1: (wayOfMeasurementOfHardness1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.wayOfMeasurementOfHardness1 = wayOfMeasurementOfHardness1;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsWayOfMeasurementOfHardness2: (wayOfMeasurementOfHardness2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.wayOfMeasurementOfHardness2 = wayOfMeasurementOfHardness2;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTestingResults: HardnessTestingResults = { ...appState.wpqrDekraTab.hardnessTestingResults };
            hardnessTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_HARDNESS_TESTING_RESULTS', hardnessTestingResults: hardnessTestingResults });
        }
    },
    setHardnessTestingTestingMachine: (testingMachine: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTesting: HardnessTesting = { ...appState.wpqrDekraTab.hardnessTesting };
            hardnessTesting.testingMachine = testingMachine;
            dispatch({ type: 'SET_HARDNESS_TESTING', hardnessTesting: hardnessTesting });
        }
    },
    setHardnessTestingTestingMachineSerialNumber: (testingMachineSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTesting: HardnessTesting = { ...appState.wpqrDekraTab.hardnessTesting };
            hardnessTesting.testingMachineSerialNumber = testingMachineSerialNumber;
            dispatch({ type: 'SET_HARDNESS_TESTING', hardnessTesting: hardnessTesting });
        }
    },
    setHardnessTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTesting: HardnessTesting = { ...appState.wpqrDekraTab.hardnessTesting };
            hardnessTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_HARDNESS_TESTING', hardnessTesting: hardnessTesting });
        }
    },
    setHardnessTestingTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTesting: HardnessTesting = { ...appState.wpqrDekraTab.hardnessTesting };
            hardnessTesting.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_HARDNESS_TESTING', hardnessTesting: hardnessTesting });
        }
    },
    setHardnessTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let hardnessTesting: HardnessTesting = { ...appState.wpqrDekraTab.hardnessTesting };
            hardnessTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_HARDNESS_TESTING', hardnessTesting: hardnessTesting });
        }
    },
    setImpactTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTesting: ImpactTesting = { ...appState.wpqrDekraTab.impactTesting };
            impactTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_IMPACT_TESTING', impactTesting: impactTesting });
        }
    },
    setImpactTestingMeasuringInstrument: (measuringInstrument: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTesting: ImpactTesting = { ...appState.wpqrDekraTab.impactTesting };
            impactTesting.measuringInstrument = measuringInstrument;
            dispatch({ type: 'SET_IMPACT_TESTING', impactTesting: impactTesting });
        }
    },
    setImpactTestingMeasuringInstrumentSerialNumber: (measuringInstrumentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTesting: ImpactTesting = { ...appState.wpqrDekraTab.impactTesting };
            impactTesting.measuringInstrumentSerialNumber = measuringInstrumentSerialNumber;
            dispatch({ type: 'SET_IMPACT_TESTING', impactTesting: impactTesting });
        }
    },
    setImpactTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.comments = comments;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZAbsorbedEnergy: (rowNumber: number, absorbedEnergy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy = absorbedEnergy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZAverageAcceptance: (hazAverageAcceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazAverageAcceptance = hazAverageAcceptance;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZAverageAbsorbedEnergy: (hazAverageAbsorbedEnergy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazAverageAbsorbedEnergy = hazAverageAbsorbedEnergy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZAverageLateralExpansion: (hazAverageLateralExpansion: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazAverageLateralExpansion = hazAverageLateralExpansion;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZAverageShear: (hazAverageShear: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazAverageShear = hazAverageShear;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZLateralExpansion: (rowNumber: number, lateralExpansion: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].lateralExpansion = lateralExpansion;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZMeasurementUncertaintyAbsorbedEnergy: (hazMeasurementUncertaintyAbsorbedEnergy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazMeasurementUncertaintyAbsorbedEnergy = hazMeasurementUncertaintyAbsorbedEnergy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZMeasurementUncertaintyAcceptance: (hazMeasurementUncertaintyAcceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazMeasurementUncertaintyAcceptance = hazMeasurementUncertaintyAcceptance;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZMeasurementUncertaintyLateralExpansion: (hazMeasurementUncertaintyLateralExpansion: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazMeasurementUncertaintyLateralExpansion = hazMeasurementUncertaintyLateralExpansion;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZMeasurementUncertaintyShear: (hazMeasurementUncertaintyShear: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazMeasurementUncertaintyShear = hazMeasurementUncertaintyShear;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZNominalEnergy: (rowNumber: number, nominalEnergy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].nominalEnergy = nominalEnergy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZShear: (rowNumber: number, shear: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].shear = shear;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZTestSpecimenDimensions: (rowNumber: number, testSpecimenDimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions = testSpecimenDimensions;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsHAZTestSpecimenType: (rowNumber: number, testSpecimenType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].testSpecimenType = testSpecimenType;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAbsorbedEnergy: (rowNumber: number, absorbedEnergy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy = absorbedEnergy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAverageAcceptance: (weldAverageAcceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldAverageAcceptance = weldAverageAcceptance;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAverageAbsorbedEnergy: (weldAverageAbsorbedEnergy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldAverageAbsorbedEnergy = weldAverageAbsorbedEnergy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAverageLateralExpansion: (weldAverageLateralExpansion: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldAverageLateralExpansion = weldAverageLateralExpansion;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldAverageShear: (weldAverageShear: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldAverageShear = weldAverageShear;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldLateralExpansion: (rowNumber: number, lateralExpansion: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].lateralExpansion = lateralExpansion;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldMeasurementUncertaintyAcceptance: (weldMeasurementUncertaintyAcceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldMeasurementUncertaintyAcceptance = weldMeasurementUncertaintyAcceptance;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldMeasurementUncertaintyAbsorbedEnergy: (weldMeasurementUncertaintyAbsorbedEnergy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldMeasurementUncertaintyAbsorbedEnergy = weldMeasurementUncertaintyAbsorbedEnergy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldMeasurementUncertaintyLateralExpansion: (weldMeasurementUncertaintyLateralExpansion: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldMeasurementUncertaintyLateralExpansion = weldMeasurementUncertaintyLateralExpansion;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldMeasurementUncertaintyShear: (weldMeasurementUncertaintyShear: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldMeasurementUncertaintyShear = weldMeasurementUncertaintyShear;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldNominalEnergy: (rowNumber: number, nominalEnergy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].nominalEnergy = nominalEnergy;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldShear: (rowNumber: number, shear: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].shear = shear;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldTestSpecimenDimensions: (rowNumber: number, testSpecimenDimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions = testSpecimenDimensions;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingResultsWeldTestSpecimenType: (rowNumber: number, testSpecimenType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTestingResults: ImpactTestingResults = { ...appState.wpqrDekraTab.impactTestingResults };
            impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenType = testSpecimenType;
            dispatch({ type: 'SET_IMPACT_TESTING_RESULTS', impactTestingResults: impactTestingResults });
        }
    },
    setImpactTestingTestingMachine: (testingMachine: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTesting: ImpactTesting = { ...appState.wpqrDekraTab.impactTesting };
            impactTesting.testingMachine = testingMachine;
            dispatch({ type: 'SET_IMPACT_TESTING', impactTesting: impactTesting });
        }
    },
    setImpactTestingTestingMachineSerialNumber: (testingMachineSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTesting: ImpactTesting = { ...appState.wpqrDekraTab.impactTesting };
            impactTesting.testingMachineSerialNumber = testingMachineSerialNumber;
            dispatch({ type: 'SET_IMPACT_TESTING', impactTesting: impactTesting });
        }
    },
    setImpactTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTesting: ImpactTesting = { ...appState.wpqrDekraTab.impactTesting };
            impactTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_IMPACT_TESTING', impactTesting: impactTesting });
        }
    },
    setImpactTestingTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTesting: ImpactTesting = { ...appState.wpqrDekraTab.impactTesting };
            impactTesting.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_IMPACT_TESTING', impactTesting: impactTesting });
        }
    },
    setImpactTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let impactTesting: ImpactTesting = { ...appState.wpqrDekraTab.impactTesting };
            impactTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_IMPACT_TESTING', impactTesting: impactTesting });
        }
    },
    setMacroTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTesting: MacroTesting = { ...appState.wpqrDekraTab.macroTesting };
            macroTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_MACRO_TESTING', macroTesting: macroTesting });
        }
    },
    setMacroTestingExaminationDesignation: (examinationDesignation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTesting: MacroTesting = { ...appState.wpqrDekraTab.macroTesting };
            macroTesting.examinationDesignation = examinationDesignation;
            dispatch({ type: 'SET_MACRO_TESTING', macroTesting: macroTesting });
        }
    },
    setMacroTestingExaminationEquipment: (examinationEquipment: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTesting: MacroTesting = { ...appState.wpqrDekraTab.macroTesting };
            macroTesting.examinationEquipment = examinationEquipment;
            dispatch({ type: 'SET_MACRO_TESTING', macroTesting: macroTesting });
        }
    },
    setMacroTestingExaminationEquipmentSerialNumber: (examinationEquipmentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTesting: MacroTesting = { ...appState.wpqrDekraTab.macroTesting };
            macroTesting.examinationEquipmentSerialNumber = examinationEquipmentSerialNumber;
            dispatch({ type: 'SET_MACRO_TESTING', macroTesting: macroTesting });
        }
    },
    setMacroTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTestingResults: MacroTestingResults = { ...appState.wpqrDekraTab.macroTestingResults };
            macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTestingResults: MacroTestingResults = { ...appState.wpqrDekraTab.macroTestingResults };
            macroTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTestingResults: MacroTestingResults = { ...appState.wpqrDekraTab.macroTestingResults };
            macroTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTestingResults: MacroTestingResults = { ...appState.wpqrDekraTab.macroTestingResults };
            macroTestingResults.comments = comments;
            dispatch({ type: 'SET_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTestingResults: MacroTestingResults = { ...appState.wpqrDekraTab.macroTestingResults };
            macroTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingResultsExaminationResult: (rowNumber: number, examinationResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTestingResults: MacroTestingResults = { ...appState.wpqrDekraTab.macroTestingResults };
            macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].examinationResult = examinationResult;
            dispatch({ type: 'SET_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTestingResults: MacroTestingResults = { ...appState.wpqrDekraTab.macroTestingResults };
            macroTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTestingResults: MacroTestingResults = { ...appState.wpqrDekraTab.macroTestingResults };
            macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_MACRO_TESTING_RESULTS', macroTestingResults: macroTestingResults });
        }
    },
    setMacroTestingSpecimensQuantity: (specimensQuantity: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTesting: MacroTesting = { ...appState.wpqrDekraTab.macroTesting };
            macroTesting.specimensQuantity = specimensQuantity;
            dispatch({ type: 'SET_MACRO_TESTING', macroTesting: macroTesting });
        }
    },
    setMacroTestingTestingCondition: (testingCondition: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTesting: MacroTesting = { ...appState.wpqrDekraTab.macroTesting };
            macroTesting.testingCondition = testingCondition;
            dispatch({ type: 'SET_MACRO_TESTING', macroTesting: macroTesting });
        }
    },
    setMacroTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTesting: MacroTesting = { ...appState.wpqrDekraTab.macroTesting };
            macroTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_MACRO_TESTING', macroTesting: macroTesting });
        }
    },
    setMacroTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let macroTesting: MacroTesting = { ...appState.wpqrDekraTab.macroTesting };
            macroTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_MACRO_TESTING', macroTesting: macroTesting });
        }
    },
    setMicroTestingExaminationDesignation: (examinationDesignation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTesting: MicroTesting = { ...appState.wpqrDekraTab.microTesting };
            microTesting.examinationDesignation = examinationDesignation;
            dispatch({ type: 'SET_MICRO_TESTING', microTesting: microTesting });
        }
    },
    setMicroTestingExaminationEquipment: (examinationEquipment: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTesting: MicroTesting = { ...appState.wpqrDekraTab.microTesting };
            microTesting.examinationEquipment = examinationEquipment;
            dispatch({ type: 'SET_MICRO_TESTING', microTesting: microTesting });
        }
    },
    setMicroTestingExaminationEquipmentSerialNumber: (examinationEquipmentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTesting: MicroTesting = { ...appState.wpqrDekraTab.microTesting };
            microTesting.examinationEquipmentSerialNumber = examinationEquipmentSerialNumber;
            dispatch({ type: 'SET_MICRO_TESTING', microTesting: microTesting });
        }
    },
    setMicroTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTesting: MicroTesting = { ...appState.wpqrDekraTab.microTesting };
            microTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_MICRO_TESTING', microTesting: microTesting });
        }
    },
    setMicroTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.microTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.comments = comments;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsCoverageFactor: (coverageFactor: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.coverageFactor = coverageFactor;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsDistribution: (distribution: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.distribution = distribution;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsExaminationResult: (rowNumber: number, examinationResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.microTestingResultsTableRows[rowNumber - 1].examinationResult = examinationResult;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTestingResults: MicroTestingResults = { ...appState.wpqrDekraTab.microTestingResults };
            microTestingResults.microTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_MICRO_TESTING_RESULTS', microTestingResults: microTestingResults });
        }
    },
    setMicroTestingSpecimensQuantity: (specimensQuantity: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTesting: MicroTesting = { ...appState.wpqrDekraTab.microTesting };
            microTesting.specimensQuantity = specimensQuantity;
            dispatch({ type: 'SET_MICRO_TESTING', microTesting: microTesting });
        }
    },
    setMicroTestingTestingCondition: (testingCondition: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTesting: MicroTesting = { ...appState.wpqrDekraTab.microTesting };
            microTesting.testingCondition = testingCondition;
            dispatch({ type: 'SET_MICRO_TESTING', microTesting: microTesting });
        }
    },
    setMicroTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTesting: MicroTesting = { ...appState.wpqrDekraTab.microTesting };
            microTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_MICRO_TESTING', microTesting: microTesting });
        }
    },
    setMicroTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let microTesting: MicroTesting = { ...appState.wpqrDekraTab.microTesting };
            microTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_MICRO_TESTING', microTesting: microTesting });
        }
    },
    setSelectedExaminationWPQRTabId: (selectedExaminationWPQRTabId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SELECTED_EXAMINATION_WPQR_TAB_ID', selectedExaminationWPQRTabId: selectedExaminationWPQRTabId });
    },
    setSelectedOngoingWPQRTabId: (selectedOngoingWPQRTabId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SELECTED_ONGOING_WPQR_TAB_ID', selectedOngoingWPQRTabId: selectedOngoingWPQRTabId });
    },
    setSelectedTestResultsTabId: (selectedTestResultsTabId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SELECTED_TEST_RESULTS_TAB_ID', selectedTestResultsTabId: selectedTestResultsTabId });
    },
    setSelectedWPQRTabId: (selectedWPQRTabId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SELECTED_WPQR_TAB_ID', selectedWPQRTabId: selectedWPQRTabId });
    },
    setTensileTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTesting: TensileTesting = { ...appState.wpqrDekraTab.tensileTesting };
            tensileTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_TENSILE_TESTING', tensileTesting: tensileTesting });
        }
    },
    setTensileTestingMeasuringInstrument: (measuringInstrument: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTesting: TensileTesting = { ...appState.wpqrDekraTab.tensileTesting };
            tensileTesting.measuringInstrument = measuringInstrument;
            dispatch({ type: 'SET_TENSILE_TESTING', tensileTesting: tensileTesting });
        }
    },
    setTensileTestingMeasuringInstrumentSerialNumber: (measuringInstrumentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTesting: TensileTesting = { ...appState.wpqrDekraTab.tensileTesting };
            tensileTesting.measuringInstrumentSerialNumber = measuringInstrumentSerialNumber;
            dispatch({ type: 'SET_TENSILE_TESTING', tensileTesting: tensileTesting });
        }
    },
    setTensileTestingResultsA: (rowNumber: number, a: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].a = a;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.comments = comments;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsFm: (rowNumber: number, fm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].fm = fm;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsFractureSurfaceCondition: (rowNumber: number, fractureSurfaceCondition: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].fractureSurfaceCondition = fractureSurfaceCondition;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsMeasurementUncertaintyUA: (measurementUncertaintyUA: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.measurementUncertaintyUA = measurementUncertaintyUA;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsMeasurementUncertaintyUFm: (measurementUncertaintyUFm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.measurementUncertaintyUFm = measurementUncertaintyUFm;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsMeasurementUncertaintyURm: (measurementUncertaintyURm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.measurementUncertaintyURm = measurementUncertaintyURm;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsMeasurementUncertaintyUR: (measurementUncertaintyUR: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.measurementUncertaintyUR = measurementUncertaintyUR;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsMeasurementUncertaintyUZ: (measurementUncertaintyUZ: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.measurementUncertaintyUZ = measurementUncertaintyUZ;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsR: (rowNumber: number, r: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].r = r;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsRm: (rowNumber: number, rm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].rm = rm;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsTestSpecimenDimensions: (rowNumber: number, testSpecimenDimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions = testSpecimenDimensions;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingResultsZ: (rowNumber: number, z: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTestingResults: TensileTestingResults = { ...appState.wpqrDekraTab.tensileTestingResults };
            tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].z = z;
            dispatch({ type: 'SET_TENSILE_TESTING_RESULTS', tensileTestingResults: tensileTestingResults });
        }
    },
    setTensileTestingTestingMachine: (testingMachine: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTesting: TensileTesting = { ...appState.wpqrDekraTab.tensileTesting };
            tensileTesting.testingMachine = testingMachine;
            dispatch({ type: 'SET_TENSILE_TESTING', tensileTesting: tensileTesting });
        }
    },
    setTensileTestingTestingMachineSerialNumber: (testingMachineSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTesting: TensileTesting = { ...appState.wpqrDekraTab.tensileTesting };
            tensileTesting.testingMachineSerialNumber = testingMachineSerialNumber;
            dispatch({ type: 'SET_TENSILE_TESTING', tensileTesting: tensileTesting });
        }
    },
    setTensileTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTesting: TensileTesting = { ...appState.wpqrDekraTab.tensileTesting };
            tensileTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_TENSILE_TESTING', tensileTesting: tensileTesting });
        }
    },
    setTensileTestingTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTesting: TensileTesting = { ...appState.wpqrDekraTab.tensileTesting };
            tensileTesting.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_TENSILE_TESTING', tensileTesting: tensileTesting });
        }
    },
    setTensileTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let tensileTesting: TensileTesting = { ...appState.wpqrDekraTab.tensileTesting };
            tensileTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_TENSILE_TESTING', tensileTesting: tensileTesting });
        }
    },
    setTransverseTensileTestingLocationOfTestSpecimens: (locationOfTestSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTesting: TransverseTensileTesting = { ...appState.wpqrDekraTab.transverseTensileTesting };
            transverseTensileTesting.locationOfTestSpecimens = locationOfTestSpecimens;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING', transverseTensileTesting: transverseTensileTesting });
        }
    },
    setTransverseTensileTestingMeasuringInstrument: (measuringInstrument: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTesting: TransverseTensileTesting = { ...appState.wpqrDekraTab.transverseTensileTesting };
            transverseTensileTesting.measuringInstrument = measuringInstrument;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING', transverseTensileTesting: transverseTensileTesting });
        }
    },
    setTransverseTensileTestingMeasuringInstrumentSerialNumber: (measuringInstrumentSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTesting: TransverseTensileTesting = { ...appState.wpqrDekraTab.transverseTensileTesting };
            transverseTensileTesting.measuringInstrumentSerialNumber = measuringInstrumentSerialNumber;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING', transverseTensileTesting: transverseTensileTesting });
        }
    },
    setTransverseTensileTestingTestingMachine: (testingMachine: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTesting: TransverseTensileTesting = { ...appState.wpqrDekraTab.transverseTensileTesting };
            transverseTensileTesting.testingMachine = testingMachine;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING', transverseTensileTesting: transverseTensileTesting });
        }
    },
    setTransverseTensileTestingTestingMachineSerialNumber: (testingMachineSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTesting: TransverseTensileTesting = { ...appState.wpqrDekraTab.transverseTensileTesting };
            transverseTensileTesting.testingMachineSerialNumber = testingMachineSerialNumber;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING', transverseTensileTesting: transverseTensileTesting });
        }
    },
    setTransverseTensileTestingResultsA: (rowNumber: number, a: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].a = a;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsAcceptanceNormsStandard: (acceptanceNormsStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.acceptanceNormsStandard = acceptanceNormsStandard;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsCertificationNumber: (certificationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.certificationNumber = certificationNumber;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsComments: (comments: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.comments = comments;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsDeviationFromStandardsOrAgreement: (deviationFromStandardsOrAgreement: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.deviationFromStandardsOrAgreement = deviationFromStandardsOrAgreement;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsFm: (rowNumber: number, fm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].fm = fm;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsFractureSurfaceCondition: (rowNumber: number, fractureSurfaceCondition: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].fractureSurfaceCondition = fractureSurfaceCondition;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsMeasurementUncertaintyU: (measurementUncertaintyU: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.measurementUncertaintyUA = measurementUncertaintyU;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsMeasurementUncertaintyUFm: (measurementUncertaintyUFm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.measurementUncertaintyUFm = measurementUncertaintyUFm;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsMeasurementUncertaintyUR: (measurementUncertaintyUR: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.measurementUncertaintyUR = measurementUncertaintyUR;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsMeasurementUncertaintyURm: (measurementUncertaintyURm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.measurementUncertaintyURm = measurementUncertaintyURm;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsPlaceOfBreak: (rowNumber: number, placeOfBreak: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].placeOfBreak = placeOfBreak;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsR: (rowNumber: number, r: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].r = r;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsRm: (rowNumber: number, rm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].rm = rm;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsTestingPerformedBy: (testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsTestSpecimenDimensions: (rowNumber: number, testSpecimenDimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].testSpecimenDimensions = testSpecimenDimensions;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingResultsTestSpecimenNumber: (rowNumber: number, testSpecimenNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTestingResults: TransverseTensileTestingResults = { ...appState.wpqrDekraTab.transverseTensileTestingResults };
            transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].testSpecimenNumber = testSpecimenNumber;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS', transverseTensileTestingResults: transverseTensileTestingResults });
        }
    },
    setTransverseTensileTestingTestingStandardProcedure: (testingStandardProcedure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTesting: TransverseTensileTesting = { ...appState.wpqrDekraTab.transverseTensileTesting };
            transverseTensileTesting.testingStandardProcedure = testingStandardProcedure;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING', transverseTensileTesting: transverseTensileTesting });
        }
    },
    setTransverseTensileTestingTestingTemperature: (testingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTesting: TransverseTensileTesting = { ...appState.wpqrDekraTab.transverseTensileTesting };
            transverseTensileTesting.testingTemperature = testingTemperature;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING', transverseTensileTesting: transverseTensileTesting });
        }
    },
    setTransverseTensileTestingTestSpecimens: (testSpecimens: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let transverseTensileTesting: TransverseTensileTesting = { ...appState.wpqrDekraTab.transverseTensileTesting };
            transverseTensileTesting.testSpecimens = testSpecimens;
            dispatch({ type: 'SET_TRANSVERSE_TENSILE_TESTING', transverseTensileTesting: transverseTensileTesting });
        }
    },
    setWeldingProtocolAssignmentNumber: (assignmentNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.assignmentNumber = assignmentNumber;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolBaseMaterial1SearchText: (baseMaterial1SearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.baseMaterial1SearchText = baseMaterial1SearchText;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolBaseMaterial2SearchText: (baseMaterial2SearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.baseMaterial2SearchText = baseMaterial2SearchText;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolJointPreparation: (chamferingAndCleaning: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.jointPreparation = chamferingAndCleaning;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolChargeAltHeatNumber1: (chargeAltHeatNumber1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.chargeAltHeatNumber1 = chargeAltHeatNumber1;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolChargeAltHeatNumber2: (chargeAltHeatNumber2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.chargeAltHeatNumber2 = chargeAltHeatNumber2;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolCompanyBoxAddress: (boxAddress: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.company2.boxAddress = boxAddress;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolCompanyCity: (city: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.company2.city = city;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolCompanyContactPerson: (contactPerson: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.company2.contactPerson = contactPerson;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolCompanyContactPersonEmailAddress: (contactPersonEmail: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.company2.contactPersonEmail = contactPersonEmail;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolCompanyName: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.company2.name = name;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolCompanyPhoneNumber: (phone: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.company2.phone = phone;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolCompanyPostcode: (postcode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.company2.postcode = postcode;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolCompanyStreetAddress: (streetAddress: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.company2.streetAddress = streetAddress;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolDEKRAProcedureNumberDisplay: (dekraProcedureNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.dekraProcedureNumberDisplay = dekraProcedureNumber;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setTestingStandards: (standardId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.standardId = standardId;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolDiameterParentMaterial1: (diameterParentMaterial1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.diameter1 = diameterParentMaterial1;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolDiameterParentMaterial2: (diameterParentMaterial2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.diameter2 = diameterParentMaterial2;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolNozzleDistance: (distanceContactTube: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.nozzleDistance = distanceContactTube;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolEmployeeNumber: (employeeNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.employeeNumber = employeeNumber;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolGasFlowMethod1RootSide: (gasFlowMethod1RootSide: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.gasFlowMethod1RootSide = gasFlowMethod1RootSide;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolGasFlowMethod1TopSide: (gasFlowMethod1TopSide: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.gasFlowMethod1TopSide = gasFlowMethod1TopSide;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolGasFlowMethod2TopSide: (gasFlowMethod2TopSide: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.gasFlowMethod2TopSide = gasFlowMethod2TopSide;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolGunAngle: (gunAngle: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.gunAngle = gunAngle;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolHeatingAndCoolingRate: (heatingAndCoolingRate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.heatingAndCoolingRate = heatingAndCoolingRate;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolLotNumberOrHeatNumberA: (lotAltHeatNumberA: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.lotNumberOrHeatNumberA = lotAltHeatNumberA;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolLotNumberOrHeatNumberB: (lotAltHeatNumberB: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.lotNumberOrHeatNumberB = lotAltHeatNumberB;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    getArcTypeSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ArcTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response => response.json() as Promise<Array<GetArcTypeResponse>>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.wpqrDekraTab) {
                    let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
                    weldingProtocolSection2.arcTypeSearchResult = [...data];
                    let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                    weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
                    dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                }
            })
            .catch(error => {
                console.error(error);
            });
    },
    setArcTypeBSearchText: (arcTypeBId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            let selectedArcTypeB: GetArcTypeResponse = new GetArcTypeResponse();
            for (var i = 0; i < weldingProtocolSection2.arcTypeSearchResult.length; i++) {
                if (arcTypeBId === weldingProtocolSection2.arcTypeSearchResult[i].id) {
                    selectedArcTypeB = { ...weldingProtocolSection2.arcTypeSearchResult[i] };
                    break;
                }
            }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocolSection2.selectedArcTypeB = { ...selectedArcTypeB};
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setArcTypeASearchText: (arcTypeAId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            let selectedArcTypeA: GetArcTypeResponse = new GetArcTypeResponse();
            for (var i = 0; i < weldingProtocolSection2.arcTypeSearchResult.length; i++) {
                if (arcTypeAId === weldingProtocolSection2.arcTypeSearchResult[i].id) {
                    selectedArcTypeA = { ...weldingProtocolSection2.arcTypeSearchResult[i] };
                    break;
                }
            }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocolSection2.selectedArcTypeA = { ...selectedArcTypeA}
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolManufacturerWPQRNumber: (manufacturerWPQRNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.manufacturerWPQRNumber = manufacturerWPQRNumber;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolMaterialThicknessParentMaterial1: (materialThicknessParentMaterial1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.materialThickness1 = materialThicknessParentMaterial1;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolMaterialThicknessParentMaterial2: (materialThicknessParentMaterial2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.materialThickness2 = materialThicknessParentMaterial2;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolMaxInterpassTemperature: (maxInterpassTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.maxInterpassTemperature = maxInterpassTemperature;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolMinElevatedWorkingTemperature: (minWorkingTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.minElevatedWorkingTemperature = minWorkingTemperature;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolNozzleDiameter: (nozzleDiameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.nozzleDiameter = nozzleDiameter;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolOscillationMaxRunWidth: (oscillationMaxStringWidth: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.oscillationMaxRunWidth = oscillationMaxStringWidth;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolOtherInformation1: (otherInformation1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.otherInformation1 = otherInformation1;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolOtherInformation2: (otherInformation2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.otherInformation2 = otherInformation2;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolPlaceForMonitoring: (placeForMonitoring: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.placeForMonitoring = placeForMonitoring;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolPowerSourceManufacturer: (powerSourceManufacturer: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.powerSourceManufacturer = powerSourceManufacturer;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolPWPSNumber: (pWPSNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.pWPSNumber = pWPSNumber;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolMarkingOfTheTest: (markingOfTheTest: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.markingOfTheTest = markingOfTheTest;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolRemarks: (remarks: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection7: WeldingProtocolSection7 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection7 };
            weldingProtocolSection7.remarks = remarks;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection7 = weldingProtocolSection7;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolRootProtectionGasSearchText: (rootProtectionGasSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.rootProtectionGasSearchText = rootProtectionGasSearchText;
            let weldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolRootSupportDetailsSearchText: (rootSupportDetailId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            for (let i = 0; i < weldingProtocolSection4.rootSupportDetailsSearchResult.length; i++) {
                if (rootSupportDetailId === weldingProtocolSection4.rootSupportDetailsSearchResult[i].id) {
                    weldingProtocolSection4.selectedRootSupportDetails = { ...weldingProtocolSection4.rootSupportDetailsSearchResult[i] };
                    break;
                }
            }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedBaseMaterial1: (baseMaterial: GetBaseMaterialResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.baseMaterial1SearchText = HelpFunctions.putNameAndNumberTogether(baseMaterial.name, baseMaterial.number);
            weldingProtocolSection3.selectedBaseMaterial1 = { ...baseMaterial };
            weldingProtocolSection3.selectedMaterialGroup1 = { ...baseMaterial.materialGroup };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedBaseMaterial2: (baseMaterial: GetBaseMaterialResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            weldingProtocolSection3.baseMaterial2SearchText = HelpFunctions.putNameAndNumberTogether(baseMaterial.name, baseMaterial.number);
            weldingProtocolSection3.selectedBaseMaterial2 = { ...baseMaterial };
            weldingProtocolSection3.selectedMaterialGroup2 = { ...baseMaterial.materialGroup };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedJointType: (jointTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            let selectedJointType: GetJointTypeResponse = new GetJointTypeResponse();
            for (let i = 0; i < weldingProtocolSection2.jointTypes.length; i++) {
                if (jointTypeId === weldingProtocolSection2.jointTypes[i].id) {
                    selectedJointType = { ...weldingProtocolSection2.jointTypes[i] };
                    break;
                }
            }
            weldingProtocolSection2.selectedJointType = { ...selectedJointType }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedMakeA: (makeA: GetMakeResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.make1SearchText = makeA.name;
            weldingProtocolSection2.selectedMake1 = { ...makeA };
            weldingProtocolSection2.selectedDesignation1 = { ...weldingProtocolSection2.selectedMake1.designation };
            //weldingProtocolSection2.selectedFMGroupA = { ...weldingProtocolSection2.selectedMakeA.fmGroup };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            console.log('weldingProtocolSection2.selectedDesignationA');
            console.log(weldingProtocolSection2.selectedDesignation1);
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedMakeB: (makeB: GetMakeResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.make2SearchText = makeB.name;
            weldingProtocolSection2.selectedMake2 = { ...makeB };
            weldingProtocolSection2.selectedDesignation2 = { ...weldingProtocolSection2.selectedMake2.designation };
            //weldingProtocolSection2.selectedFMGroupB = { ...weldingProtocolSection2.selectedMakeB.fmGroup };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedProductType1: (productTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            for (let i = 0; i < weldingProtocolSection3.productTypes.length; i++) {
                if (productTypeId === weldingProtocolSection3.productTypes[i].id) {
                    weldingProtocolSection3.selectedProductType1 = { ...weldingProtocolSection3.productTypes[i] };
                    break;
                }
            }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedProductType2: (productTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            for (let i = 0; i < weldingProtocolSection3.productTypes.length; i++) {
                if (productTypeId === weldingProtocolSection3.productTypes[i].id) {
                    weldingProtocolSection3.selectedProductType2 = { ...weldingProtocolSection3.productTypes[i] };
                    break;
                }
            }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedRootProtectionGas: (selectedRootProtectionGas: GetRootProtectionGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.rootProtectionGasSearchText = selectedRootProtectionGas.content;
            //if (selectedRootProtectionGas.content) {
            //    weldingProtocolSection4.rootProtectionGasSearchText += " (" + selectedRootProtectionGas.content + ")";
            //}
            weldingProtocolSection4.selectedRootProtectionGas = { ...selectedRootProtectionGas };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingDataSelectedPlasmaGas: (selectedPlasmasGas: GetRootProtectionGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.selectedPlasmaGas = { ...selectedPlasmasGas };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingDataPlasmaflowrate: (plasmaflowrate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.plasmaflowrate = plasmaflowrate;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingDataElectrodeTorchAngle111: (electrodeTorchAngle111: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.electrodeTorchAngle111 = electrodeTorchAngle111;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedShieldingGas1: (selectedShieldingGas: GetShieldingGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.shieldingGas1SearchText = selectedShieldingGas.content;
            //if (selectedShieldingGas.content) {
            //    weldingProtocolSection4.shieldingGas1SearchText += " (" + selectedShieldingGas.content + ")";
            //}
            weldingProtocolSection4.selectedShieldingGas1 = { ...selectedShieldingGas };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedShieldingGas2: (selectedShieldingGas: GetShieldingGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.shieldingGas2SearchText = selectedShieldingGas.content;
            //if (selectedShieldingGas.content) {
            //    weldingProtocolSection4.shieldingGas2SearchText += " (" + selectedShieldingGas.content + ")";
            //}
            weldingProtocolSection4.selectedShieldingGas2 = { ...selectedShieldingGas };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedTechnicalDeliveryCondition1: (technicalDeliveryConditionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            for (let i = 0; i < weldingProtocolSection3.selectedBaseMaterial1.technicalDeliveryConditions.length; i++) {
                if (technicalDeliveryConditionId === weldingProtocolSection3.selectedBaseMaterial1.technicalDeliveryConditions[i].id) {
                    weldingProtocolSection3.selectedTechnicalDeliveryCondition1 = { ...weldingProtocolSection3.selectedBaseMaterial1.technicalDeliveryConditions[i] };
                    break;
                }
            }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedTechnicalDeliveryCondition2: (technicalDeliveryConditionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection3: WeldingProtocolSection3 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3 };
            for (let i = 0; i < weldingProtocolSection3.selectedBaseMaterial2.technicalDeliveryConditions.length; i++) {
                if (technicalDeliveryConditionId === weldingProtocolSection3.selectedBaseMaterial2.technicalDeliveryConditions[i].id) {
                    weldingProtocolSection3.selectedTechnicalDeliveryCondition2 = { ...weldingProtocolSection3.selectedBaseMaterial2.technicalDeliveryConditions[i] };
                    break;
                }
            }
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection3 = weldingProtocolSection3;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedWeldingMethodA: (weldingMethod: GetWeldingMethodResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
            // Remove the old selected WeldingMethod and add the new selected WeldingMethod in the Dropdown list.
            let removeIndex = weldingProtocolSection6.weldingMethods.findIndex(weldingMethod1 => weldingMethod1.id !== 0 && weldingMethod1.id === weldingProtocolSection2.selectedWeldingMethod1.id);
            if (removeIndex !== -1) {
                weldingProtocolSection6.weldingMethods.splice(removeIndex, 1);
            }
            weldingProtocolSection6.weldingMethods.splice(weldingProtocolSection6.weldingMethods.length, 0, weldingMethod);
            weldingProtocolSection6.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
                if (weldingMethod1.name < weldingMethod2.name) {
                    return -1;
                }
                else if (weldingMethod1.name > weldingMethod2.name) {
                    return 1;
                }
                return 0;
            });
            // Update the selected WeldingMethod.
            weldingProtocolSection2.weldingMethod1SearchText = weldingMethod.name;
            weldingProtocolSection2.selectedWeldingMethod1 = { ...weldingMethod };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSelectedWeldingMethodB: (weldingMethod: GetWeldingMethodResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
            // Remove the old selected WeldingMethod and add the new selected WeldingMethod in the Dropdown list.
            let removeIndex = weldingProtocolSection6.weldingMethods.findIndex(weldingMethod1 => weldingMethod1.id !== 0 && weldingMethod1.id === weldingProtocolSection2.selectedWeldingMethod2.id);
            if (removeIndex !== -1) {
                weldingProtocolSection6.weldingMethods.splice(removeIndex, 1);
            }
            weldingProtocolSection6.weldingMethods.splice(weldingProtocolSection6.weldingMethods.length, 0, weldingMethod);
            weldingProtocolSection6.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
                if (weldingMethod1.name < weldingMethod2.name) {
                    return -1;
                }
                else if (weldingMethod1.name > weldingMethod2.name) {
                    return 1;
                }
                return 0;
            });
            // Update the selected WeldingMethod.
            weldingProtocolSection2.weldingMethod2SearchText = weldingMethod.name;
            weldingProtocolSection2.selectedWeldingMethod2 = { ...weldingMethod };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolSignature: (signature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.signature = signature;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolShieldingGasMethod1RootSide: (shieldingGasMethod1RootSide: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.rootProtectionGasSearchText = shieldingGasMethod1RootSide;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolShieldingGas1SearchText: (shieldingGas1SearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.shieldingGas1SearchText = shieldingGas1SearchText;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolShieldingGas2SearchText: (shieldingGas2SearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.shieldingGas2SearchText = shieldingGas2SearchText;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolPostWeldHeatTreatment: (postWeldHeatTreatment: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.postWeldHeatTreatment = postWeldHeatTreatment;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolTestMarking: (testMarking: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.testMarking = testMarking;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingDataTackWeld: (tackWeldText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection7: WeldingProtocolSection7 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection7 };
            weldingProtocolSection7.tackWeldText = tackWeldText;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection7 = weldingProtocolSection7;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolThroatThickness: (throatThickness: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection7: WeldingProtocolSection7 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection7 };
            weldingProtocolSection7.throatThickness = throatThickness;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection7 = weldingProtocolSection7;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingDataLengthTackWelds: (lengthTackWelds: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection7: WeldingProtocolSection7 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection7 };
            weldingProtocolSection7.lengthTackWelds = lengthTackWelds;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection7 = weldingProtocolSection7;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolMakeASearchText: (makeASearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.make1SearchText = makeASearchText;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolMakeBSearchText: (makeBSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.make2SearchText = makeBSearchText;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolTungstenElectrode: (tungstenElectrode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            weldingProtocolSection4.tungstenElectrode = tungstenElectrode;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWelderDateOfBirth: (dateOfBirth: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.welder.dateOfBirth = dateOfBirth;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWelderFirstName: (firstName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.welder.firstName = firstName;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWelderLastName: (lastName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.welder.lastName = lastName;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWelderPlaceOfBirth: (placeOfBirth: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection1: WeldingProtocolSection1 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection1 };
            weldingProtocolSection1.welder.placeOfBirth = placeOfBirth;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection1 = weldingProtocolSection1;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWeldingDate: (weldingDate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingDate = weldingDate;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    //setWeldingProtocolWeldingParametersCurrent: (rowNumber: number, current: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    const appState = getState();
    //    if (appState && appState.wpqrDekraTab) {
    //        let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
    //        weldingProtocolSection6.weldingParameters[rowNumber - 1].current = current;

    //        let current1: number = parseFloat(weldingProtocolSection6.weldingParameters[rowNumber - 1].current.replace(',', '.'));
    //        let voltage1: number = parseFloat(weldingProtocolSection6.weldingParameters[rowNumber - 1].voltage.replace(',', '.'));
    //        let runLength1: number = parseFloat(weldingProtocolSection6.weldingParameters[rowNumber - 1].runLength.replace(',', '.'));
    //        let time1: number = parseFloat(weldingProtocolSection6.weldingParameters[rowNumber - 1].time.replace(',', '.'));
    //        let travelSpeed1: number = NaN;
    //        let heatInput1: number = NaN;
    //        if (!isNaN(runLength1) && !isNaN(time1)) {
    //            travelSpeed1 = (runLength1 * 60.0) / time1;
    //            weldingProtocolSection6.weldingParameters[rowNumber - 1].travelSpeed = travelSpeed1.toFixed(2);
    //        }
    //        else {
    //            weldingProtocolSection6.weldingParameters[rowNumber - 1].travelSpeed = '';
    //        }
    //        if (!isNaN(current1) && !isNaN(voltage1) && !isNaN(travelSpeed1) && weldingProtocolSection6.weldingParameters[rowNumber - 1].weldingMethod.id !== 0) {
    //            heatInput1 = ((current1 * voltage1 * 60.0) / (travelSpeed1 * 1000)) * weldingProtocolSection6.weldingParameters[rowNumber - 1].weldingMethod.efficiencyFactor;
    //            weldingProtocolSection6.weldingParameters[rowNumber - 1].heatInput = heatInput1.toFixed(2);
    //        }
    //        else {
    //            weldingProtocolSection6.weldingParameters[rowNumber - 1].heatInput = '';
    //        }

    //        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
    //        weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
    //        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
    //    }
    //},
    //setWeldingProtocolWeldingParametersCurrentTypeId: (rowNumber: number, currentTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    const appState = getState();
    //    if (appState && appState.wpqrDekraTab) {
    //        let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
    //        let currentTypes: Array<GetCurrentTypeResponse> = [...weldingProtocolSection6.currentTypes];
    //        for (let i = 0; i < currentTypes.length; i++) {
    //            if (currentTypeId === currentTypes[i].id) {
    //                weldingProtocolSection6.weldingParameters[rowNumber - 1].currentType = { ...currentTypes[i] };
    //                break;
    //            }
    //        }
    //        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
    //        weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
    //        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
    //    }
    //},
    //setWeldingProtocolWeldingParametersDiameter: (rowNumber: number, diameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    const appState = getState();
    //    if (appState && appState.wpqrDekraTab) {
    //        let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
    //        weldingProtocolSection6.weldingParameters[rowNumber - 1].diameter = diameter;
    //        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
    //        weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
    //        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
    //    }
    //}
    setWeldingProtocolWeldingParametersHeatInput: (rowNumber: number, heatInput: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
            weldingProtocolSection6.weldingParameters[rowNumber - 1].heatInput = heatInput;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWeldingParametersInterpassTemperature: (rowNumber: number, interpassTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection4: WeldingProtocolSection4 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection4 };
            let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
            weldingProtocolSection6.weldingParameters[rowNumber - 1].interpassTemperature = interpassTemperature;
            // Calculate MaxInterpassTemperature.
            let maxInterpassTemperature: number = 0.0;
            if (weldingProtocolSection6.weldingParameters.length > 0) {
                maxInterpassTemperature = parseFloat(weldingProtocolSection6.weldingParameters[0].interpassTemperature.replace(',', '.'));
                for (let i = 1; i < weldingProtocolSection6.weldingParameters.length; i++) {
                    if (parseFloat(weldingProtocolSection6.weldingParameters[i].interpassTemperature.replace(',', '.')) > maxInterpassTemperature) {
                        maxInterpassTemperature = parseFloat(weldingProtocolSection6.weldingParameters[i].interpassTemperature.replace(',', '.'));
                    }
                }
            }
            weldingProtocolSection4.maxInterpassTemperature = maxInterpassTemperature.toString();
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
            weldingProtocol.weldingProtocolSection4 = weldingProtocolSection4;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWeldingParametersTravelSpeed: (rowNumber: number, travelSpeed: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
            weldingProtocolSection6.weldingParameters[rowNumber - 1].travelSpeed = travelSpeed;
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWeldingMethod1SearchText: (weldingMethodASearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.weldingMethod1SearchText = weldingMethodASearchText;

            let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
            if (weldingProtocolSection2.weldingMethod1SearchText.length === 0 && weldingProtocolSection2.selectedWeldingMethod1.id !== 0) {
                // Remove the old selected Welding method in the Dropdown list.
                let removeIndex = weldingProtocolSection6.weldingMethods.findIndex(weldingMethod1 => weldingMethod1.id === weldingProtocolSection2.selectedWeldingMethod1.id);
                if (removeIndex !== -1) {
                    weldingProtocolSection6.weldingMethods.splice(removeIndex, 1);
                    // Recalculate all Welding parameter rows that used this Welding method.
                    for (let i = 0; i < weldingProtocolSection6.weldingParameters.length; i++) {
                        if (weldingProtocolSection6.weldingParameters[i].weldingMethod.id === weldingProtocolSection2.selectedWeldingMethod1.id) {
                            weldingProtocolSection6.weldingParameters[i].heatInput = '';
                        }
                    }
                }
                // Remove selected weldingMethod1
                weldingProtocolSection2.selectedWeldingMethod1 = {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                };
            }

            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWeldingMethod2SearchText: (weldingMethodBSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            weldingProtocolSection2.weldingMethod2SearchText = weldingMethodBSearchText;

            let weldingProtocolSection6: WeldingProtocolSection6 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection6 };
            if (weldingProtocolSection2.weldingMethod2SearchText.length === 0 && weldingProtocolSection2.selectedWeldingMethod2.id !== 0) {
                // Remove the old selected WeldingMethod in the Dropdown list.
                let removeIndex = weldingProtocolSection6.weldingMethods.findIndex(weldingMethod1 => weldingMethod1.id === weldingProtocolSection2.selectedWeldingMethod2.id);
                if (removeIndex !== -1) {
                    weldingProtocolSection6.weldingMethods.splice(removeIndex, 1);
                    // Recalculate all Welding parameter rows that used this Welding method.
                    for (let i = 0; i < weldingProtocolSection6.weldingParameters.length; i++) {
                        if (weldingProtocolSection6.weldingParameters[i].weldingMethod.id === weldingProtocolSection2.selectedWeldingMethod2.id) {
                            weldingProtocolSection6.weldingParameters[i].heatInput = '';
                        }
                    }
                }
                // Remove selected weldingMethod2
                weldingProtocolSection2.selectedWeldingMethod2 = {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                };;
            }

            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2 = weldingProtocolSection2;
            weldingProtocol.weldingProtocolSection6 = weldingProtocolSection6;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setWeldingProtocolWeldingPositionSearchText: (weldingPositionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            for (let i = 0; i < weldingProtocolSection2.weldingPositionSearchResult.length; i++) {
                if (weldingPositionId === weldingProtocolSection2.weldingPositionSearchResult[i].id) {
                    weldingProtocol.weldingProtocolSection2.selectedWeldingPosition = { ...weldingProtocolSection2.weldingPositionSearchResult[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol })
        }
    },
    setWeldingProtocolWeldingPosition2SearchText: (weldingPosition2Id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocolSection2: WeldingProtocolSection2 = { ...appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2 };
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            let selectedWeldingPosition2: GetWeldingPositionResponse = new GetWeldingPositionResponse();
            for (let i = 0; i < weldingProtocolSection2.weldingPositionSearchResult.length; i++) {
                if (weldingPosition2Id === weldingProtocolSection2.weldingPositionSearchResult[i].id) {
                    selectedWeldingPosition2 = { ...weldingProtocolSection2.weldingPositionSearchResult[i] };
                    break;
                }
            }
            weldingProtocol.weldingProtocolSection2.selectedWeldingPosition2 = { ...selectedWeldingPosition2 };
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol })
        }
    },
    setWeldingTestingDetails1BackingGasFlowRate: (backingGasFlowRate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.backingGasFlowRate = backingGasFlowRate;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1BackingGasFlux: (backingGasFlux: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.backingGasFlux = backingGasFlux;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ChargeNumberHeatNumber: (chargeNumberHeatNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.chargeNumberHeatNumber = chargeNumberHeatNumber;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ChargeNumberLotNumberSerialNumber: (chargeNumberLotNumberSerialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.chargeNumberLotNumberSerialNumber = chargeNumberLotNumberSerialNumber;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1DetailsOfGougingBacking: (detailsOfGougingBacking: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.detailsOfGougingBacking = detailsOfGougingBacking;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1Examiner: (examiner: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.examiner = examiner;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ExaminerDate: (examinerDate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.examinerDate = examinerDate;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1CommentInformation: (commentInformation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.commentInformation = commentInformation;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setCommentInformation: (commentInformation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.commentInformation = weldingTestingDetails1.commentInformation === "" ? commentInformation : weldingTestingDetails1.commentInformation + "\n\n" + commentInformation;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ExaminerName: (examinerName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.examinerName = examinerName;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ExaminerSignature: (examinerSignature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.examinerSignature = examinerSignature;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1FillerMaterialDesignation: (fillerMaterialDesignation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.fillerMaterialDesignation = fillerMaterialDesignation;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1FillerMaterialTradeName: (fillerMaterialTradeName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.fillerMaterialTradeName = fillerMaterialTradeName;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1HeatingAndCoolingRates: (heatingAndCoolingRates: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.heatingAndCoolingRates = heatingAndCoolingRates;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1InterpassTemperature: (interpassTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.interpassTemperature = interpassTemperature;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1JointType: (jointType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.jointType = jointType;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1Location: (location: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.location = location;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1Manufacturer: (manufacturer: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.manufacturer = manufacturer;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1Manufacturer2: (manufacturer2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.manufacturer2 = manufacturer2;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1Manufacturer2Date: (manufacturer2Date: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.manufacturer2Date = manufacturer2Date;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1Manufacturer2Name: (manufacturer2Name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.manufacturer2Name = manufacturer2Name;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1Manufacturer2Signature: (manufacturer2Signature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.manufacturer2Signature = manufacturer2Signature;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ManufacturerOfPowerSource: (manufacturerOfPowerSource: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.manufacturerOfPowerSource = manufacturerOfPowerSource;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ManufacturerPWPSNumber: (manufacturerPWPSNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.manufacturerPWPSNumber = manufacturerPWPSNumber;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1MaterialThickness: (materialThickness: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.materialThickness = materialThickness;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1MethodOfPreparationAndCleaning: (methodOfPreparationAndCleaning: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.methodOfPreparationAndCleaning = methodOfPreparationAndCleaning;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1NozzleDiameter: (nozzleDiameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.nozzleDiameter = nozzleDiameter;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1OtherInformation: (otherInformation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.otherInformation = otherInformation;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1OutsideDiameter: (outsideDiameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.outsideDiameter = outsideDiameter;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ParentMaterialGroup: (parentMaterialGroup: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.parentMaterialGroup = parentMaterialGroup;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ParentMaterialNameOrNumber: (parentMaterialNameOrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.parentMaterialNameOrNumber = parentMaterialNameOrNumber;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1PostWeldHeatTreatmentAndOrAgeing: (postWeldHeatTreatmentAndOrAgeing: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.postWeldHeatTreatmentAndOrAgeing = postWeldHeatTreatmentAndOrAgeing;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1PreheatTemperature: (preheatTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.preheatTemperature = preheatTemperature;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ShieldingGasFlowRate: (shieldingGasFlowRate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.shieldingGasFlowRate = shieldingGasFlowRate;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ShieldingGasFlux: (shieldingGasFlux: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.shieldingGasFlux = shieldingGasFlux;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1StandOffDistance: (standOffDistance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.standOffDistance = standOffDistance;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1TechnicalDeliveryStandard: (technicalDeliveryStandard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.technicalDeliveryStandard = technicalDeliveryStandard;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1TorchAngle: (torchAngle: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.torchAngle = torchAngle;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1TungstenElectrode: (tungstenElectrode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.tungstenElectrode = tungstenElectrode;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ElectrodeTorchAngle111: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.electrodeTorchAngle111 = value;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1Plasmaflowrate: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.plasmaflowrate = value;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1PlasmaGasNam: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.plasmaGasName = value;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1Weaving: (weaving: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.weaving = weaving;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1TackWeldText: (tackWeldText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.tackWeldText = tackWeldText;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1LengthTackWelds: (lengthTackWelds: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.lengthTackWelds = lengthTackWelds;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1WelderName: (welderName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.welderName = welderName;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1WeldingPosition: (weldingPosition: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.weldingPosition = weldingPosition;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1WeldingPosition2: (weldingPosition2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.weldingPosition2 = weldingPosition2;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1WeldingProcess: (weldingProcess: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.weldingProcess = weldingProcess;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWeldingTestingDetails1ArcType: (arcType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingTestingDetails1: WeldingTestingDetails1 = { ...appState.wpqrDekraTab.weldingTestingDetails1 };
            weldingTestingDetails1.arcType = arcType;
            dispatch({ type: 'SET_WELDING_TESTING_DETAILS1', weldingTestingDetails1: weldingTestingDetails1 });
        }
    },
    setWPQTReportAcceptance: (rowNumber: number, acceptance: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].acceptance = acceptance;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportApplicationNumber: (applicationNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.applicationNumber = applicationNumber;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportCustomer: (customer: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.customer = customer;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportDate: (date: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.date = date;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportDekraProcedureNumber: (dekraProcedureNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.dekraProcedureNumber = dekraProcedureNumber;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportDimensions: (dimensions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.dimensions = dimensions;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportFillerMaterial: (fillerMaterial: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.fillerMaterial = fillerMaterial;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportParentMaterial: (parentMaterial: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.parentMaterial = parentMaterial;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportPerformed: (rowNumber: number, performed: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].performed = performed;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportReportApprovedBy: (reportApprovedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.reportApprovedBy = reportApprovedBy;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportReportNumber: (reportNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.reportNumber = reportNumber;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportTestingLocation: (testingLocation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.testingLocation = testingLocation;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportTestingObject: (testingObject: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.testingObject = testingObject;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportTestingPerformedBy: (rowNumber: number, testingPerformedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].testingPerformedBy = testingPerformedBy;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportTestingResult: (rowNumber: number, testingResult: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].testingResult = testingResult;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportTestingScope: (testingScope: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.testingScope = testingScope;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportTestMarking: (testMarking: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.testMarking = testMarking;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportTypeOfTest: (rowNumber: number, typeOfTest: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].typeOfTest = typeOfTest;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setWPQTReportWeldingAndHeatTreatmentData: (weldingAndHeatTreatmentData: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let wpqtReport: WPQTReport = { ...appState.wpqrDekraTab.wpqtReport };
            wpqtReport.weldingAndHeatTreatmentData = weldingAndHeatTreatmentData;
            dispatch({ type: 'SET_WPQT_REPORT', wpqtReport: wpqtReport });
        }
    },
    setEnableMakeAText: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2.enableMakeAText = value;
            if (value) {
                weldingProtocol.weldingProtocolSection2.makeAText = weldingProtocol.weldingProtocolSection2.make1SearchText;
                weldingProtocol.weldingProtocolSection2.designationAText = weldingProtocol.weldingProtocolSection2.selectedDesignation1.name;
                let selectedMake1: GetMakeResponse = new GetMakeResponse();
                weldingProtocol.weldingProtocolSection2.selectedMake1 = { ...selectedMake1 };
                weldingProtocol.weldingProtocolSection2.selectedDesignation1 = {
                    id: 0,
                    name: weldingProtocol.weldingProtocolSection2.designationAText,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                };
            } else {
                weldingProtocol.weldingProtocolSection2.make1SearchText = "";
                weldingProtocol.weldingProtocolSection2.selectedDesignation1.name = ""
            }
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setEnableMakeBText: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2.enableMakeBText = value;
            if (value) {
                weldingProtocol.weldingProtocolSection2.makeBText = weldingProtocol.weldingProtocolSection2.make2SearchText;
                weldingProtocol.weldingProtocolSection2.designationBText = weldingProtocol.weldingProtocolSection2.selectedDesignation2.name;
                let selectedMake2: GetMakeResponse = new GetMakeResponse();
                weldingProtocol.weldingProtocolSection2.selectedMake2 = { ...selectedMake2 };
                weldingProtocol.weldingProtocolSection2.selectedDesignation2 = {
                    id: 0,
                    name: weldingProtocol.weldingProtocolSection2.designationBText,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                };
            } else {
                weldingProtocol.weldingProtocolSection2.make2SearchText = "";
                weldingProtocol.weldingProtocolSection2.selectedDesignation2.name = ""
            }
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setMakeAText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2.makeAText = value;
            weldingProtocol.weldingProtocolSection2.make1SearchText = value;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setDesignationAText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2.designationAText = value;
            weldingProtocol.weldingProtocolSection2.selectedDesignation1.name = value;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setMakeBText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2.makeBText = value;
            weldingProtocol.weldingProtocolSection2.make2SearchText = value;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    setDesignationBText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            weldingProtocol.weldingProtocolSection2.designationBText = value;
            weldingProtocol.weldingProtocolSection2.selectedDesignation2.name = value;
            dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
        }
    },
    sortApplications: (sortProperty: string, sortOrder: SortOrder): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab) {
            let applications: Array<GetApplicationResponse> = [...appState.wpqrDekraTab.applications];
            // Sort by application.company.name.
            if (sortProperty === 'application.company.name') {
                if (sortOrder === SortOrder.Ascending) {
                    applications.sort((application1, application2) => {
                        if (application1.company.name > application2.company.name) {
                            return 1;
                        }
                        else if (application1.company.name < application2.company.name) {
                            return -1;
                        }
                        return 0;
                    });
                }
                else {
                    applications.sort((application1, application2) => {
                        if (application1.company.name > application2.company.name) {
                            return -1;
                        }
                        else if (application1.company.name < application2.company.name) {
                            return 1;
                        }
                        return 0;
                    });
                }
            }
            // Sort by application.company.contactPerson.
            if (sortProperty === 'application.company.contactPerson') {
                if (sortOrder === SortOrder.Ascending) {
                    applications.sort((application1, application2) => {
                        if (application1.company.contactPerson > application2.company.contactPerson) {
                            return 1;
                        }
                        else if (application1.company.contactPerson < application2.company.contactPerson) {
                            return -1;
                        }
                        return 0;
                    });
                }
                else {
                    applications.sort((application1, application2) => {
                        if (application1.company.contactPerson > application2.company.contactPerson) {
                            return -1;
                        }
                        else if (application1.company.contactPerson < application2.company.contactPerson) {
                            return 1;
                        }
                        return 0;
                    });
                }
            }
            // Sort by application.pwps.pwpsNumber.
            if (sortProperty === 'application.pwps.pwpsNumber') {
                if (sortOrder === SortOrder.Ascending) {
                    applications.sort((application1, application2) => {
                        if (application1.pwps.pwpsNumber > application2.pwps.pwpsNumber) {
                            return 1;
                        }
                        else if (application1.pwps.pwpsNumber < application2.pwps.pwpsNumber) {
                            return -1;
                        }
                        return 0;
                    });
                }
                else {
                    applications.sort((application1, application2) => {
                        if (application1.pwps.pwpsNumber > application2.pwps.pwpsNumber) {
                            return -1;
                        }
                        else if (application1.pwps.pwpsNumber < application2.pwps.pwpsNumber) {
                            return 1;
                        }
                        return 0;
                    });
                }
            }
            // Sort by application.pwps.revisionNumber.
            if (sortProperty === 'application.pwps.revisionNumber') {
                if (sortOrder === SortOrder.Ascending) {
                    applications.sort((application1, application2) => {
                        if (application1.pwps.revisionNumber > application2.pwps.revisionNumber) {
                            return 1;
                        }
                        else if (application1.pwps.revisionNumber < application2.pwps.revisionNumber) {
                            return -1;
                        }
                        return 0;
                    });
                }
                else {
                    applications.sort((application1, application2) => {
                        if (application1.pwps.revisionNumber > application2.pwps.revisionNumber) {
                            return -1;
                        }
                        else if (application1.pwps.revisionNumber < application2.pwps.revisionNumber) {
                            return 1;
                        }
                        return 0;
                    });
                }
            }
            // Sort by application.byStandard.name.
            if (sortProperty === 'application.byStandard.name') {
                if (sortOrder === SortOrder.Ascending) {
                    applications.sort((application1, application2) => {
                        if (application1.pwps.byStandard.name > application2.pwps.byStandard.name) {
                            return 1;
                        }
                        else if (application1.pwps.byStandard.name < application2.pwps.byStandard.name) {
                            return -1;
                        }
                        return 0;
                    });
                }
                else {
                    applications.sort((application1, application2) => {
                        if (application1.pwps.byStandard.name > application2.pwps.byStandard.name) {
                            return -1;
                        }
                        else if (application1.pwps.byStandard.name < application2.pwps.byStandard.name) {
                            return 1;
                        }
                        return 0;
                    });
                }
            }
            // Sort by application.pwps.wpqrNumber.
            if (sortProperty === 'application.pwps.wpqrNumber') {
                if (sortOrder === SortOrder.Ascending) {
                    applications.sort((application1, application2) => {
                        if (application1.pwps.wpqrNumber > application2.pwps.wpqrNumber) {
                            return 1;
                        }
                        else if (application1.pwps.wpqrNumber < application2.pwps.wpqrNumber) {
                            return -1;
                        }
                        return 0;
                    });
                }
                else {
                    applications.sort((application1, application2) => {
                        if (application1.pwps.wpqrNumber > application2.pwps.wpqrNumber) {
                            return -1;
                        }
                        else if (application1.pwps.wpqrNumber < application2.pwps.wpqrNumber) {
                            return 1;
                        }
                        return 0;
                    });
                }
            }
            // Sort by application.confirmedAt.
            if (sortProperty === 'application.confirmedAt') {
                if (sortOrder === SortOrder.Ascending) {
                    applications.sort((application1, application2) => {
                        if (application1.confirmedAt > application2.confirmedAt) {
                            return 1;
                        }
                        else if (application1.confirmedAt < application2.confirmedAt) {
                            return -1;
                        }
                        return 0;
                    });
                }
                else {
                    applications.sort((application1, application2) => {
                        if (application1.confirmedAt > application2.confirmedAt) {
                            return -1;
                        }
                        else if (application1.confirmedAt < application2.confirmedAt) {
                            return 1;
                        }
                        return 0;
                    });
                }
            }
            dispatch({ type: 'UPDATE_APPLICATIONS_RECEIVE', applications: applications });
        }
    },
    createFile: (createFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileValidationResult = HelpFunctions.fileValidation(createFile);
        if (fileValidationResult === "") {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.imageValidationError2 = "";
                        weldingProtocol.imageUpload2 = reader.result.toString();;
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }
            };
            reader.readAsDataURL(createFile);
        }
        else {
            const appState = getState();
            if (appState && appState.wpqrDekraTab) {
                let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                weldingProtocol.imageValidationError2 = fileValidationResult;
                dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
            }
        }
    },
    createImageDrawJointDesign: (createFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileValidationResult = HelpFunctions.fileValidation(createFile);
        if (fileValidationResult === "") {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    const appState = getState();
                    if (appState && appState.wpqrDekraTab) {
                        let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                        weldingProtocol.imageValidationError1 = "";
                        weldingProtocol.imageUpload1 = reader.result.toString();
                        dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
                    }
                }
            };
            reader.readAsDataURL(createFile);
        }
        else {
            const appState = getState();
            if (appState && appState.wpqrDekraTab) {
                let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
                weldingProtocol.imageValidationError1 = fileValidationResult;
                dispatch({ type: 'SET_WELDING_PROTOCOL', weldingProtocol: weldingProtocol });
            }
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WPQRDEKRATabStoreState = {
    loadingApplication: false,
    loadingWpqr: false,
    applications: [],
    applicationsIsOpen: true,
    selectedApplication: {
        id: 0,
        applicationStatus: ApplicationStatus.NA,
        dekraNumber: '',
        
        wpsHeatInputText: '',
        wpsArcEnergyText: '',
        revisionWpqrAction: '',
        weldingProtocolId: 0,
        wpsId: 0,
        wpqrId: 0,
        weldingProtocolApplicationId: 0,
        weldingProtocolSectionsResponse: {
            heatNumber1: '',
            heatNumber2: '',
            chargeAltHeatNumber1: '',
            chargeAltHeatNumber2: '',
            productType1Id: 0,
            productType2Id: 0,
            otherInformation1: '',
            otherInformation2: '',
            gunAngle: '',
            hydrogenBakeOut: 0.0,
            oscillationAmplitudeFrequencyHoldingTime: '',
            postWeldHeatTreatment: '',
            powerSourceManufacturer: '',
            plasmaWeldingDetails: '',
            pulseWeldingDetails: '',
            assignmentNumber: '',
            dekraNumberDisplay: '',
            markingOfTheTest: '',
            throatThickness: '',
            remarks: '',
            placeForMonitoring: '',
            weldingDate: '',
            signature: '',
            employeeNumber: '',
            dateOfBirth: '',
            firstName: '',
            lastName: '',
            placeOfBirth: ''
        },
        rangeOfQualificationExaminationResponse: {
            address: '',
            date: '',
            dateOfIssue: '',
            dateOfWelding: '',
            examinerOrExaminingBody: '',
            location: '',
            manufacturer: '',
            name: '',
            testingStandardId: 0,
            enableStandardText: false,
            standardText: '',
            weldingProcess: '',
            jointType: '',
            materialThicknesses: '',
            designations: '',
            shieldingGases: '',
            currentTypes: '',
            dropletTransitions: '',
            makes: '',
            otherInformations: '',
            enableWeldingMetalThicknessText: false,
            weldingMetalThicknessText: '',
            enableMaterialGroupText: false,
            enableMaterialThicknessesText: false,
            materialGroupText: '',
            weldingPositionsText: '',
            enableWeldingProcessText: false,
            enableJointTypeText: false,
            enableThroatThicknessText: false,
            enableRunTypeText: false,
            enableDiameterText: false,
            enableMakesText: false,
            enableShieldingGasesText: false,
            enableRootProtectionGasText: false,
            enableCurrentTypesText: false,
            enableDropletTransitionsText: false,
            enableHeatInputText: false,
            enableArcEnergyText: false,
            enableDesignationsText: false,
            enablePreheatTemperatureText: false,
            enableInterpassTemperatureText: false,
            enablePostWeldHeatTreatmentText: false,
            weldingProcessText: '',
            jointTypeText: '',
            throatThicknessText: '',
            runTypeText: '',
            diameterText: '',
            designationsTemp: '',
            makesTemp: '',
            shieldingGasesTemp: '',
            rootProtectionGasText: '',
            currentTypesTemp: '',
            dropletTransitionsTemp: '',
            heatInputText: '',
            arcEnergyText: '',
            postWeldHeatTreatmentText: '',
            interpassTemperatureText: '',
            preheatTemperatureText: '',
        },
        welders: [],
        testingOrderResponse: {
            customer: '',
            contactPerson: '',
            weldingDate: '',
            phoneNumber: '',
            welderBrazerFirstName: '',
            welderBrazerLastName:'',
            pWPSPBPS: '',
            manufacturerWPQRBPARNumber: '',
            weldingBrazingPositions: '',
            weldingProcess: '',
            fillerMaterial: '',
            ndtRemarks: '',
            visual: '',
            mtPT: '',
            rt: '',
            ut: '',
            ndtOther: '',
            conditionsAndDescriptionRemarks: '',
            material1: '',
            materialGroup1: '',
            dimension1: '',
            standardForAcceptance1: '',
            chargeNumber1: '',
            impactTestTemperature1: '',
            directionOfPlateRolling1: '',
            materialSpecifications1Remarks: '',
            material2: '',
            materialGroup2: '',
            dimension2: '',
            standardForAcceptance2: '',
            chargeNumber2: '',
            impactTestTemperature2: '',
            directionOfPlateRolling2: '',
            materialSpecifications2Remarks: '',
            iso156141Edition2004: false,
            iso156141Edition2017: false,
            transverseTensilePlusRp0ReH: false,
            macroPlusPhoto: false,
            hardnessTesting: false,
            impactTesting: false,
            bendTesting: false,
            additionalTesting: false,
            iso134454Testing: false,
            iso134454ImpactTesting: false,
            iso134454MicroTesting: false,
            iso134454FerriteTesting: false,
            iso134454LongitudinalTensileTesting: false,
            iso134454AdditionalTesting: false,
            iso134454ImpactTestingText: '',
            iso134454MicroTestingText: '',
            iso134454FerriteTestingText: '',
            iso134454LongitudinalTensileTestingText: '',
            iso134454AdditionalTestingText: '',
            iso129526LongitudinalTensileTesting: false,
            iso129526ImpactTesting: false,
            iso129526AdditionalTesting: false,
            iso129526Testing: false,
            iso129526LongitudinalTensileTestingText: '',
            iso129526ImpactTestingText: '',
            iso129526AdditionalTestingText: '',
            iso10902: false,
            iso10902AdditionalTesting: false,
            iso10902CruciformTensileTesting: false,
            iso10902ImpactTesting: false,
            iso10902MacroPlusPhoto: false,
            iso10902MicroTesting: false,
            iso10902WeldabilityPrimer: false,
            iso10902AdditionalTestingText: '',
            iso10902CruciformTensileTestingText: '',
            iso10902ImpactTestingText: '',
            iso10902MacroPlusPhotoText: '',
            iso10902MicroTestingText: '',
            iso10902WeldabilityPrimerText: '',
            norsokAdditionalTesting1: false,
            norsokAdditionalTesting2: false,
            norsokAdditionalTesting3: false,
            norsokAdditionalTesting4: false,
            norsokAdditionalTestingText: '',
            norsokAdditionalTesting1Text: '',
            norsokAdditionalTesting2Text: '',
            norsokAdditionalTesting3Text: '',
            norsokAdditionalTesting4Text: '',
            norsokCorrosionTestingText: '',
            norsok: false,
            norsokCorrosionTesting: false,
            norsokAdditionalTesting: false,
            iso14555ApplicationGreaterThan100Degrees: false,
            iso14555ApplicationLessThanOrEqualTo100Degrees: false,
            iso14555BendTesting: false,
            iso14555ArcStudWelding: false,
            iso14555BendTestingTorqueWrench: false,
            iso14555TensileTesting: false,
            iso14555MacroPlusPhoto: false,
            iso14555TorqueTesting: false,
            iso14555AdditionalTesting: false,
            iso14555RTProvning: false,
            iso14555_3834Testing: false,
            iso14555_783Testing: false,
            iso14555_784Testing: false,
            iso14555_785Testing: false,
            iso14555_786Testing: false,
            iso14555_787Testing: false,
            iso14555ApplicationGreaterThan100DegreesText: '',
            iso14555ApplicationLessThanOrEqualTo100DegreesText: '',
            iso14555BendTestingText: '',
            iso14555BendTestingTorqueWrenchText: '',
            iso14555TensileTestingText: '',
            iso14555MacroPlusPhotoText: '',
            iso14555TorqueTestingText: '',
            iso14555AdditionalTestingText: '',
            iso14555RTProvningText: '',
            iso14555_3834TestingText: '',
            iso13134TensileTesting: false,
            iso13134BendTesting: false,
            iso13134HardnessTesting: false,
            iso13134ShearTesting: false,
            iso13134PeelTesting: false,
            iso13134AdditionalTesting: false,
            iso13134BrazinProcedureApproval: false,
            iso13134TensileTestingText: '',
            iso13134BendTestingText: '',
            iso13134HardnessTestingText: '',
            iso13134ShearTestingText: '',
            iso13134PeelTestingText: '',
            iso13134AdditionalTestingText: '',
            other: '',
            ndtApproved: '',
            ndtSignature: '',
            ndtDate: '',
            destructiveTestingApproved: '',
            destructiveTestingSignature: '',
            destructiveTestingDate: '',
            orderedByLevel3: '',
            date: '',
            signature: '',
            iso15614transverseTensilePlusRp0ReHText: '',
            iso15614macroPlusPhotoText: '',
            iso15614hardnessTestingText: '',
            iso15614impactTestingText: '',
            iso15614bendTestingText: '',
            iso15614additionalTestingText: '',
            iso15614TestingText: '',
            pedAdditionalTesting: false,
            dekraProcedureNoTesting: '',
            invoiceReferenceName: '',
            uppdragsnr: '',
            testmarking: '',
            nace: false,
            naceMr0103Testing: false,
            naceMr0103TestingText: '',
            naceMr0175Testing: false,
            naceMr0175TestingText: '',
            naceAdditionalTesting1: false,
            naceAdditionalTesting1Text: '',
            naceAdditionalTesting2: false,
            naceAdditionalTesting2Text: '',
        },
        company: {
            id: 0,
            name: '',
            streetAddress: '',
            boxAddress: '',
            postcode: '',
            city: '',
            phone: '',
            contactPerson: '',
            contactPersonEmail: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        company2: {
            id: 0,
            name: '',
            streetAddress: '',
            boxAddress: '',
            postcode: '',
            city: '',
            phone: '',
            contactPerson: '',
            contactPersonEmail: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        additionalRequirements: [],
        otherOptions: [],
        standard: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        numberOfWeldingPositions: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        pwps: {
            id: 0,
            date: '',
            projectNumber: '',
            otherInformation: '',
            examiner: '',
            manufacturer: '',
            confirmedMessage: '',
            otherOptionParametersTableId: 0,
            byStandard: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            standardText: '',
            enableStandardText: false,
            revisionNumber: '',
            pwpsNumber: '',
            wpqrNumber: '',
            parentMaterial: {
                id: 0,
                baseMaterial1: {
                    id: 0,
                    name: '',
                    number: '',
                    description: '',
                    materialGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    technicalDeliveryConditions: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                technicalDeliveryCondition1: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                materialThickness1: 0.0,
                diameter1: 0.0,
                baseMaterial2: {
                    id: 0,
                    name: '',
                    number: '',
                    description: '',
                    materialGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    technicalDeliveryConditions: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                technicalDeliveryCondition2: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                materialThickness2: 0.0,
                diameter2: 0.0,
                enableBaseMaterial1Text: false,
                enableBaseMaterial2Text: false,
                baseMaterial1Text: '',
                baseMaterial2Text: '',
                materialGroup1Text: '',
                materialGroup2Text: '',
                technicalDeliveryCondition1Text: '',
                technicalDeliveryCondition2Text: '',
                enableMaterialThickness: false,
                materialThickness1Text: '',
                materialThickness2Text: '',
                enableDiameter: false,
                diameter1Text: '',
                diameter2Text: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldingData: {
                id: 0,
                jointType: {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                rootSupportDetails: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                weldingPosition: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                weldingPosition2: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                rootProtectionGas: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                createdAt: '',
                deletedAt: '',
                updatedAt: '',
                nozzleDiameter: '',
                stickOut: '',
                jointPreparationSearchText: '',
                tungstenElectrode: '',
                electrodeTorchAngle: '',
                maxWidthOfRun: '',
                lengthTackWelds: '',
                tackWeldText: '',
                electrodeTorchAngle111: '',
                plasmaGas: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                plasmaflowrate: '',
                enableJointTypeText: false,
                jointTypeText: '',
            },
            weldingProcess: {
                id: 0,
                weldingMethod1: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                make1: {
                    id: 0,
                    name: '',
                    description: '',
                    designation: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    fmGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                shieldingGas1: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                arcType1: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                weldingMethod2: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                make2: {
                    id: 0,
                    name: '',
                    description: '',
                    designation: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    fmGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                shieldingGas2: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                arcType2: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                enableMakeAText: false,
                makeAText: '',
                designationAText: '',
                fmGroupAText: '',
                enableMakeBText: false,
                makeBText: '',
                designationBText: '',
                fmGroupBText: '',
                typeOfBackingText: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldingParameters: [],
            filesUpload: [],
            jointDesign: {
                id: 0,
                materialThickness1: 0.0,
                materialThickness2: 0.0,
                jointType: {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                jointShape: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                jointAngleAlpha: 0.0,
                gapOpening: 0.0,
                straightEdge: 0.0,
                phaseAngle1: 0.0,
                phaseAngle2: 0.0,
                jointAngleBeta: 0.0,
                throatThickness: 0.0,
                rootSupport: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                depthOfPreparation: 0.0,
                radius: 0.0,
                note: '',
                jointDesignHtmlImage: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldSequence: {
                createdAt: '',
                deletedAt: '',
                updatedAt: '',
                imageId: 0,
                imageData: '',
                sequenceNumber1: '',
                sequenceNumber2: '',
                sequenceNumber3: '',
                sequenceNumber4: '',
                sequenceNumber5: '',
                sequenceNumber6: '',
                sequenceNumber7: '',
                sequenceNumber8: '',
                sequenceNumber9: '',
                sequenceNumber10: '',
                sequenceNumber11: '',
                sequenceNumber12: '',
                sequenceNumber13: '',
                sequenceNumber14: '',
                sequenceNumber15: ''
            },
            temperature: {
                id: 0,
                preheatTemperature: 0.0,
                maxInterpassTemperature: 0.0,
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            afterWelding: {
                id: 0,
                heatingRate: '',
                coolingRate: '',
                createdAt: '',
                updatedAt: '',
                deletedAt: ''
            },
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        supervisor: '',
        examiner: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: '',
        confirmedAt: ''
    },
    selectedWpqr: {
        id: 0,
        testResultsExaminationId: 0,
        testResultsExaminationResponse: {
            longitudinalTensileTestingResultsResponse: {
                acceptableValuesUA: '',
                acceptableValuesUFm: '',
                acceptableValuesUR: '',
                acceptableValuesURm: '',
                acceptableValuesUZ: '',
                acceptanceNormsStandard: '',
                acceptanceResult: '',
                certificationNumber: '',
                comments: '',
                deviationFromStandardsOrAgreement: '',
                measurementUncertaintyUA: '',
                measurementUncertaintyUFm: '',
                measurementUncertaintyUR: '',
                measurementUncertaintyURm: '',
                measurementUncertaintyUZ: '',
                tensileTestingResultsTableRows: [],
                testingPerformedBy: '',
                testingTemperature: ''
            },
            testResultsExaminationValuesResponse: {
                macroTestingResultsTableRows: [],
                microTestingResultsTableRows: [],
                magneticTest: '',
                penetrantTest: '',
                radiographicExamination: '',
                ultrasonicExamination: '',
                visualExamination: '',
                otherTesting: '',
                remarks: '',
                testsPerformedWithAcceptableResult: '',
                laboratoryReportReferenceNo: '',
                testCarriedOutInThePresenceOf: '',
            },
            getHardnessTestingResultsResponse: {
                acceptableValues: '',
                acceptance: '',
                acceptanceNormsStandard: '',
                acceptanceResults: '',
                certificationNumber: '',
                comments: '',
                deviationFromStandardsOrAgreement: '',
                hardnessTestingResultsTable1Rows: [],
                hardnessTestingResultsTable2Rows: [],
                hardnessTestingResultsTable3Rows: [],
                measurementUncertainty: '',
                testingPerformedBy: '',
                testingTemperature: '',
                wayOfMeasurementOfHardness1: '',
                wayOfMeasurementOfHardness2: '',
                wayOfMeasurementOfHardness3: '',
            },
            getImpactTestingResultsResponse: {
                acceptableValues: '',
                acceptanceNormsStandard: '',
                certificationNumber: '',
                comments: '',
                deviationFromStandardsOrAgreement: '',
                hazAcceptanceResults: '',
                hazAverageAbsorbedEnergy: '',
                hazAverageLateralExpansion: '',
                hazAverageShear: '',
                hazAverageAcceptance: '',
                hazComments: '',
                hazImpactTestingResultsTableRows: [],
                hazMeasurementUncertaintyAbsorbedEnergy: '',
                hazMeasurementUncertaintyLateralExpansion: '',
                hazMeasurementUncertaintyShear: '',
                hazMeasurementUncertaintyAcceptance: '',
                testingPerformedBy: '',
                testingTemperature: '',
                weldAcceptanceResults: '',
                weldAverageAbsorbedEnergy: '',
                weldAverageLateralExpansion: '',
                weldAverageShear: '',
                weldAverageAcceptance: '',
                weldComments: '',
                weldImpactTestingResultsTableRows: [],
                weldMeasurementUncertaintyAbsorbedEnergy: '',
                weldMeasurementUncertaintyLateralExpansion: '',
                weldMeasurementUncertaintyShear: '',
                weldMeasurementUncertaintyAcceptance: '',
                type: '',
            },
            getTransverseTensileTestingResultsResponse: {
                acceptableValuesUA: '',
                acceptableValuesUFm: '',
                acceptableValuesUR: '',
                acceptableValuesURm: '',
                acceptanceNormsStandard: '',
                acceptanceResult: '',
                certificationNumber: '',
                comments: '',
                deviationFromStandardsOrAgreement: '',
                measurementUncertaintyUA: '',
                measurementUncertaintyUFm: '',
                measurementUncertaintyUR: '',
                measurementUncertaintyURm: '',
                testingPerformedBy: '',
                testingTemperature: '',
                transverseTensileTestingResultsTabelRows: [],
                acceptableValuesUA1: '',
                acceptableValuesUFm1: '',
                acceptableValuesUR1: '',
                acceptableValuesURm1: '',
            },
            getBendTestingResultsResponse: {
                acceptableValues: '',
                acceptanceNormsStandard: '',
                acceptanceResult: '',
                certificationNumber: '',
                comments: '',
                deviationFromStandardsOrAgreement: '',
                testingPerformedBy: '',
                testingTemperature: '',
                bendTestingResultsTableRows: [],
            }
        },
        testingDetails1ExaminationResponse: {
            manufacturer2: '',
            manufacturer2Signature: '',
            manufacturer2Name: '',
            manufacturer2Date: '',
            examiner: '',
            examinerSignature: '',
            examinerName: '',
            examinerDate: '',
            commentInformation: '',
            backingGasFlowRate: '',
            backingGasFlux: '',
            chargeNumberHeatNumber: '',
            chargeNumberLotNumberSerialNumber: '',
            detailsOfGougingBacking: '',
            fillerMaterialDesignation: '',
            fillerMaterialMake: '',
            fillerMaterialTradeName: '',
            heatingAndCoolingRates: '',
            interpassTemperature: '',
            jointType: '',
            location: '',
            manufacturer: '',
            manufacturerOfPowerSource: '',
            manufacturerPWPSNumber: '',
            materialThickness: '',
            methodOfPreparationAndCleaning: '',
            nozzleDiameter: '',
            otherInformation: '',
            outsideDiameter: '',
            parentMaterialGroup: '',
            parentMaterialNameOrNumber: '',
            postWeldHeatTreatmentAndOrAgeing: '',
            preheatTemperature: '',
            shieldingGasFlowRate: '',
            shieldingGasFlux: '',
            standOffDistance: '',
            technicalDeliveryStandard: '',
            torchAngle: '',
            tungstenElectrode: '',
            weaving: '',
            welderName: '',
            weldingPosition: '',
            weldingPosition2: '',
            weldingProcess: '',
            tackWeldText: '',
            lengthTackWelds: '',
            arcType: '',
            electrodeTorchAngle111: '',
            plasmaflowrate: '',
            plasmaGasName: '',
            users: [],
        },
        rangeOfQualificationExaminationResponse: {
            address: '',
            date: '',
            dateOfIssue: '',
            dateOfWelding: '',
            examinerOrExaminingBody: '',
            location: '',
            manufacturer: '',
            name: '',
            testingStandardId: 0,
            enableStandardText: false,
            standardText: '',
            weldingProcess: '',
            jointType: '',
            materialThicknesses: '',
            designations: '',
            shieldingGases: '',
            currentTypes: '',
            dropletTransitions: '',
            makes: '',
            otherInformations: '',
            enableWeldingMetalThicknessText: false,
            weldingMetalThicknessText: '',
            enableMaterialGroupText: false,
            enableMaterialThicknessesText: false,
            materialGroupText: '',
            weldingPositionsText: '',
            enableWeldingProcessText: false,
            enableJointTypeText: false,
            enableThroatThicknessText: false,
            enableRunTypeText: false,
            enableDiameterText: false,
            enableMakesText: false,
            enableShieldingGasesText: false,
            enableRootProtectionGasText: false,
            enableCurrentTypesText: false,
            enableDropletTransitionsText: false,
            enableHeatInputText: false,
            enableArcEnergyText: false,
            enableDesignationsText: false,
            enablePreheatTemperatureText: false,
            enableInterpassTemperatureText: false,
            enablePostWeldHeatTreatmentText: false,
            weldingProcessText: '',
            jointTypeText: '',
            throatThicknessText: '',
            runTypeText: '',
            diameterText: '',
            designationsTemp: '',
            makesTemp: '',
            shieldingGasesTemp: '',
            rootProtectionGasText: '',
            currentTypesTemp: '',
            dropletTransitionsTemp: '',
            heatInputText: '',
            arcEnergyText: '',
            postWeldHeatTreatmentText: '',
            interpassTemperatureText: '',
            preheatTemperatureText: '',
        }
    },
    selectedOngoingWPQRTabId: '',
    selectedTestResultsTabId: '',
    selectedWPQRTabId: '',
    selectedExaminationWPQRTabId: '',
    weldingProtocol: {
        weldingProtocolSection1: {
            company: {
                id: 0,
                name: '',
                streetAddress: '',
                boxAddress: '',
                postcode: '',
                city: '',
                contactPerson: '',
                contactPersonEmail: '',
                phone: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            company2: {
                id: 0,
                name: '',
                streetAddress: '',
                boxAddress: '',
                postcode: '',
                city: '',
                contactPerson: '',
                contactPersonEmail: '',
                phone: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            welder: {
                id: 0,
                firstName: '',
                lastName: '',
                welderId: '',
                dateOfBirth: '',
                placeOfBirth: '',
                department: '',
                email: '',
                phone: '',
                note: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            }
        },
        weldingProtocolSection2: {
            jointTypes: [],
            selectedJointType: {
                id: 0,
                name: '',
                description: '',
                jointShapes: [],
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldingMethod1SearchText: '',
            weldingMethod1SearchResult: [],
            arcTypeSearchResult: [],
            selectedWeldingMethod1: {
                id: 0,
                name: '',
                efficiencyFactor: 0.0,
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            make1SearchText: '',
            make1SearchResult: [],
            selectedMake1: {
                id: 0,
                name: '',
                description: '',
                designation: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                fmGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            selectedDesignation1: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            lotNumberOrHeatNumberA: '',
            selectedArcTypeA: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldingPositionSearchResult: [],
            selectedWeldingPosition: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            selectedWeldingPosition2: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldingMethod2SearchText: '',
            weldingMethod2SearchResult: [],
            selectedWeldingMethod2: {
                id: 0,
                name: '',
                efficiencyFactor: 0.0,
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            make2SearchText: '',
            make2SearchResult: [],
            selectedMake2: {
                id: 0,
                name: '',
                description: '',
                designation: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                fmGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            selectedDesignation2: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            lotNumberOrHeatNumberB: '',
            selectedArcTypeB: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            enableMakeAText: false,
            makeAText: '',
            designationAText: '',
            fmGroupAText: '',
            enableMakeBText: false,
            makeBText: '',
            designationBText: '',
            fmGroupBText: '',
        },
        weldingProtocolSection3: {
            productTypes: [],
            materialGroups: [],
            baseMaterial1SearchText: '',
            baseMaterial1SearchResult: [],
            baseMaterial2SearchText: '',
            baseMaterial2SearchResult: [],
            selectedBaseMaterial1: {
                id: 0,
                name: '',
                number: '',
                description: '',
                materialGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                technicalDeliveryConditions: [],
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            selectedMaterialGroup1: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            selectedTechnicalDeliveryCondition1: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            chargeAltHeatNumber1: '',
            selectedProductType1: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            materialThickness1: '',
            diameter1: '',
            otherInformation1: '',
            selectedBaseMaterial2: {
                id: 0,
                name: '',
                number: '',
                description: '',
                materialGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                technicalDeliveryConditions: [],
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            selectedMaterialGroup2: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            selectedTechnicalDeliveryCondition2: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            chargeAltHeatNumber2: '',
            selectedProductType2: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            materialThickness2: '',
            diameter2: '',
            otherInformation2: '',
            enableBaseMaterial1Text: false,
            enableBaseMaterial2Text: false,
            baseMaterial1Text: '',
            baseMaterial2Text: '',
            materialGroup1Text: '',
            materialGroup2Text: '',
            technicalDeliveryCondition1Text: '',
            technicalDeliveryCondition2Text: ''
        },
        weldingProtocolSection4: {
            shieldingGas1SearchText: '',
            shieldingGas1SearchResult: [],
            selectedShieldingGas1: {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            gasFlowMethod1TopSide: '',
            rootProtectionGasSearchText: '',
            rootProtectionGasSearchResult: [],
            selectedRootProtectionGas: {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            selectedPlasmaGas: {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            gasFlowMethod1RootSide: '',
            shieldingGas2SearchText: '',
            shieldingGas2SearchResult: [],
            selectedShieldingGas2: {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            gasFlowMethod2TopSide: '',
            rootSupportDetailsSearchResult: [],
            selectedRootSupportDetails: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            jointPreparation: '',
            nozzleDiameter: '',
            nozzleDistance: '',
            gunAngle: '',
            tungstenElectrode: '',
            oscillationMaxRunWidth: '',
            minElevatedWorkingTemperature: '',
            maxInterpassTemperature: '',
            powerSourceManufacturer: '',
            postWeldHeatTreatment: '',
            heatingAndCoolingRate: '',
            typeOfBackingText: '',
            electrodeTorchAngle111: '',
            plasmaflowrate: '',
        },
        weldingProtocolSection6: {
            currentTypes: [],
            dropletTransitions: [],
            weldingMethods: [],
            weldingParameters: []
        },
        weldingProtocolSection7: {
            throatThickness: '',
            remarks: '',
            lengthTackWelds: '',
            jointDesign: {
                id: 0,
                materialThickness1: 0.0,
                materialThickness2: 0.0,
                jointType: {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                jointShape: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                jointAngleAlpha: 0.0,
                gapOpening: 0.0,
                straightEdge: 0.0,
                phaseAngle1: 0.0,
                phaseAngle2: 0.0,
                jointAngleBeta: 0.0,
                throatThickness: 0.0,
                rootSupport: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                depthOfPreparation: 0.0,
                radius: 0.0,
                note: '',
                jointDesignHtmlImage: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldSequence: {
                createdAt: '',
                deletedAt: '',
                updatedAt: '',
                imageId: 0,
                imageData: '',
                sequenceNumber1: '',
                sequenceNumber2: '',
                sequenceNumber3: '',
                sequenceNumber4: '',
                sequenceNumber5: '',
                sequenceNumber6: '',
                sequenceNumber7: '',
                sequenceNumber8: '',
                sequenceNumber9: '',
                sequenceNumber10: '',
                sequenceNumber11: '',
                sequenceNumber12: '',
                sequenceNumber13: '',
                sequenceNumber14: '',
                sequenceNumber15: ''
            },
            tackWeldText: ''
        },
        weldingProtocolMessages: [],
        qwbpOrder: {
            customer: '',
            contactPerson: '',
            weldingDate: '',
            phoneNumber: '',
            welderBrazerFirstName: '',
            welderBrazerLastName: '',
            pWPSPBPS: '',
            manufacturerWPQRBPARNumber: '',
            weldingBrazingPositions: '',
            weldingProcess: '',
            fillerMaterial: '',
            ndtRemarks: '',
            visual: '',
            mtPT: '',
            rt: '',
            ut: '',
            ndtOther: '',
            conditionsAndDescriptionRemarks: '',
            material1: '',
            materialGroup1: '',
            dimension1: '',
            standardForAcceptance1: '',
            chargeNumber1: '',
            impactTestTemperature1: '',
            directionOfPlateRolling1: '',
            materialSpecifications1Remarks: '',
            material2: '',
            materialGroup2: '',
            dimension2: '',
            standardForAcceptance2: '',
            chargeNumber2: '',
            impactTestTemperature2: '',
            directionOfPlateRolling2: '',
            materialSpecifications2Remarks: '',
            iso156141Edition2004: false,
            iso156141Edition2017: false,
            transverseTensilePlusRp0ReH: false,
            macroPlusPhoto: false,
            hardnessTesting: false,
            impactTesting: false,
            bendTesting: false,
            additionalTesting: false,
            iso134454Testing: false,
            iso134454ImpactTesting: false,
            iso134454MicroTesting: false,
            iso134454FerriteTesting: false,
            iso134454LongitudinalTensileTesting: false,
            iso134454AdditionalTesting: false,
            iso134454ImpactTestingText: '',
            iso134454MicroTestingText: '',
            iso134454FerriteTestingText: '',
            iso134454LongitudinalTensileTestingText: '',
            iso134454AdditionalTestingText: '',
            iso129526LongitudinalTensileTesting: false,
            iso129526ImpactTesting: false,
            iso129526AdditionalTesting: false,
            iso129526Testing: false,
            iso129526LongitudinalTensileTestingText: '',
            iso129526ImpactTestingText: '',
            iso129526AdditionalTestingText: '',
            iso10902: false,
            iso10902AdditionalTesting: false,
            iso10902CruciformTensileTesting: false,
            iso10902ImpactTesting: false,
            iso10902MacroPlusPhoto: false,
            iso10902MicroTesting: false,
            iso10902WeldabilityPrimer: false,
            iso10902AdditionalTestingText: '',
            iso10902CruciformTensileTestingText: '',
            iso10902ImpactTestingText: '',
            iso10902MacroPlusPhotoText: '',
            iso10902MicroTestingText: '',
            iso10902WeldabilityPrimerText: '',
            norsokAdditionalTesting1: false,
            norsokAdditionalTesting2: false,
            norsokAdditionalTesting3: false,
            norsokAdditionalTesting4: false,
            norsokAdditionalTestingText: '',
            norsokAdditionalTesting1Text: '',
            norsokAdditionalTesting2Text: '',
            norsokAdditionalTesting3Text: '',
            norsokAdditionalTesting4Text: '',
            norsokCorrosionTestingText: '',
            norsok: false,
            norsokCorrosionTesting: false,
            norsokAdditionalTesting: false,
            iso14555ApplicationGreaterThan100Degrees: false,
            iso14555ApplicationLessThanOrEqualTo100Degrees: false,
            iso14555BendTesting: false,
            iso14555ArcStudWelding: false,
            iso14555BendTestingTorqueWrench: false,
            iso14555TensileTesting: false,
            iso14555MacroPlusPhoto: false,
            iso14555TorqueTesting: false,
            iso14555AdditionalTesting: false,
            iso14555RTProvning: false,
            iso14555_3834Testing: false,
            iso14555_783Testing: false,
            iso14555_784Testing: false,
            iso14555_785Testing: false,
            iso14555_786Testing: false,
            iso14555_787Testing: false,
            iso14555ApplicationGreaterThan100DegreesText: '',
            iso14555ApplicationLessThanOrEqualTo100DegreesText: '',
            iso14555BendTestingText: '',
            iso14555BendTestingTorqueWrenchText: '',
            iso14555TensileTestingText: '',
            iso14555MacroPlusPhotoText: '',
            iso14555TorqueTestingText: '',
            iso14555AdditionalTestingText: '',
            iso14555RTProvningText: '',
            iso14555_3834TestingText: '',
            iso13134TensileTesting: false,
            iso13134BendTesting: false,
            iso13134HardnessTesting: false,
            iso13134ShearTesting: false,
            iso13134PeelTesting: false,
            iso13134AdditionalTesting: false,
            iso13134BrazinProcedureApproval: false,
            iso13134TensileTestingText: '',
            iso13134BendTestingText: '',
            iso13134HardnessTestingText: '',
            iso13134ShearTestingText: '',
            iso13134PeelTestingText: '',
            iso13134AdditionalTestingText: '',
            other: '',
            ndtApproved: '',
            ndtSignature: '',
            ndtDate: '',
            destructiveTestingApproved: '',
            destructiveTestingSignature: '',
            destructiveTestingDate: '',
            orderedByLevel3: '',
            date: '',
            signature: '',
            iso15614transverseTensilePlusRp0ReHText: '',
            iso15614macroPlusPhotoText: '',
            iso15614hardnessTestingText: '',
            iso15614impactTestingText: '',
            iso15614bendTestingText: '',
            iso15614additionalTestingText: '',
            iso15614TestingText: '',
            pedAdditionalTesting: false,
            dekraProcedureNoTesting: '',
            invoiceReferenceName: '',
            uppdragsnr: '',
            testmarking: '',
            nace: false,
            naceMr0103Testing: false,
            naceMr0103TestingText: '',
            naceMr0175Testing: false,
            naceMr0175TestingText: '',
            naceAdditionalTesting1: false,
            naceAdditionalTesting1Text: '',
            naceAdditionalTesting2: false,
            naceAdditionalTesting2Text: '',
        },
        filesUpload:[],
        dekraProcedureNumber: '',
        dekraProcedureNumberDisplay: '',
        weldingProtocolId: 0,
        wpqrId: 0,
        weldingProtocolApplicationId: 0,
        assignmentNumber: '',
        testMarking: '',
        pWPSNumber: '',
        markingOfTheTest:'',
        manufacturerWPQRNumber: '',
        placeForMonitoring: '',
        weldingDate: '',
        signature: '',
        employeeNumber: '',
        revisionWpqrAction: '',
        imageUpload2: '',
        imageUpload1: '',
        imageValidationError1: '',
        imageValidationError2: '',
        standards: [],
        standardId: 0,
    },
    wpqtReport: {
        reportNumber: '',
        date: '',
        applicationNumber: '',
        dekraProcedureNumber: '',
        testMarking: '',
        customer: '',
        testingScope: '',
        testingLocation: '',
        testingObject: '',
        dimensions: '',
        parentMaterial: '',
        fillerMaterial: '',
        weldingAndHeatTreatmentData: '',
        wpqtReportPerformedTestsTableRows: [],
        reportApprovedBy: ''
    },
    bendTesting: {
        testingStandardProcedure: '',
        testingTemperature: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        testingMachine: '',
        measuringInstrument: '',
        testingMachineSerialNumber: '',
        measuringInstrumentSerialNumber: ''
    },
    bendTestingResults: {
        bendTestingResultsTableRows: [],
        comments: '',
        acceptanceNormsStandard: '',
        acceptableValues: '',
        testingTemperature: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: '',
        acceptanceResult: ''
    },
    tensileTesting: {
        testingStandardProcedure: '',
        testingTemperature: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        testingMachine: '',
        measuringInstrument: '',
        testingMachineSerialNumber: '',
        measuringInstrumentSerialNumber: ''
    },
    tensileTestingResults: {
        tensileTestingResultsTableRows: [],
        comments: '',
        acceptanceResult: '',
        acceptanceNormsStandard: '',
        testingTemperature: '',
        deviationFromStandardsOrAgreement: '',
        acceptableValuesUFm: '',
        acceptableValuesURm: '',
        acceptableValuesUR: '',
        acceptableValuesUA: '',
        acceptableValuesUZ: '',
        measurementUncertaintyUFm: '',
        measurementUncertaintyURm: '',
        measurementUncertaintyUR: '',
        measurementUncertaintyUA: '',
        measurementUncertaintyUZ: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    transverseTensileTesting: {
        testingStandardProcedure: '',
        testingTemperature: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        testingMachine: '',
        measuringInstrument: '',
        testingMachineSerialNumber: '',
        measuringInstrumentSerialNumber: ''
    },
    transverseTensileTestingResults: {
        transverseTensileTestingResultsTabelRows: [],
        comments: '',
        acceptanceResult: '',
        acceptanceNormsStandard: '',
        testingTemperature: '',
        deviationFromStandardsOrAgreement: '',
        acceptableValuesUFm: '',
        acceptableValuesURm: '',
        acceptableValuesUR: '',
        acceptableValuesUA: '',
        measurementUncertaintyUFm: '',
        measurementUncertaintyURm: '',
        measurementUncertaintyUR: '',
        measurementUncertaintyUA: '',
        testingPerformedBy: '',
        certificationNumber: '',
        acceptableValuesUFm1: '',
        acceptableValuesURm1: '',
        acceptableValuesUR1: '',
        acceptableValuesUA1: '',
    },
    cruciformTensileTesting: {
        testingStandardProcedure: '',
        testingTemperature: '',
        sampleQuantity: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        testingMachine: '',
        testingMachineSerialNumber: '',
        measuringInstrument: '',
        measuringInstrumentSerialNumber: ''
    },
    cruciformTensileTestingResults: {
        cruciformTensileTestingResultsTableRow: [],
        measurementUncertaintyUWf: '',
        measurementUncertaintyUFm: '',
        measurementUncertaintyURm: '',
        comments: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    impactTesting: {
        testingStandardProcedure: '',
        testingTemperature: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        testingMachine: '',
        measuringInstrument: '',
        testingMachineSerialNumber: '',
        measuringInstrumentSerialNumber: ''
    },
    impactTestingResults: {
        acceptanceNormsStandard: '',
        acceptableValues: '',
        testingTemperature: '',
        certificationNumber: '',
        comments: '',
        deviationFromStandardsOrAgreement: '',
        weldImpactTestingResultsTableRows: [],
        weldAverageAbsorbedEnergy: '',
        weldAverageLateralExpansion: '',
        weldAverageShear: '',
        weldAverageAcceptance: '',
        weldMeasurementUncertaintyAbsorbedEnergy: '',
        weldMeasurementUncertaintyLateralExpansion: '',
        weldMeasurementUncertaintyShear: '',
        weldMeasurementUncertaintyAcceptance: '',
        weldComments: '',
        weldAcceptanceResults: '',
        hazImpactTestingResultsTableRows: [],
        hazAverageAbsorbedEnergy: '',
        hazAverageLateralExpansion: '',
        hazAverageShear: '',
        hazAverageAcceptance: '',
        hazMeasurementUncertaintyAbsorbedEnergy: '',
        hazMeasurementUncertaintyLateralExpansion: '',
        hazMeasurementUncertaintyShear: '',
        hazMeasurementUncertaintyAcceptance: '',
        hazComments: '',
        hazAcceptanceResults: '',
        testingPerformedBy: '',
        type: '',
    },
    hardnessTesting: {
        testingStandardProcedure: '',
        testingTemperature: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        testingMachine: '',
        measuringInstrument: '',
        testingMachineSerialNumber: '',
        measuringInstrumentSerialNumber: ''
    },
    hardnessTestingResults: {
        acceptableValues: '',
        testingTemperature: '',
        comments: '',
        measurementUncertainty: '',
        acceptance: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        wayOfMeasurementOfHardness1: '',
        hardnessTestingResultsTable1Rows: [],
        wayOfMeasurementOfHardness2: '',
        hardnessTestingResultsTable2Rows: [],
        wayOfMeasurementOfHardness3: '',
        hardnessTestingResultsTable3Rows: [],
        testingPerformedBy: '',
        certificationNumber: '',
        acceptanceResults: ''
    },
    macroTesting: {
        testingStandardProcedure: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        examinationDesignation: '',
        examinationEquipment: '',
        testingCondition: '',
        examinationEquipmentSerialNumber: '',
        specimensQuantity: ''
    },
    macroTestingResults: {
        macroTestingResultsTableRows: [],
        comments: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    microTesting: {
        testingStandardProcedure: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        examinationDesignation: '',
        examinationEquipment: '',
        testingCondition: '',
        examinationEquipmentSerialNumber: '',
        specimensQuantity: ''
    },
    microTestingResults: {
        microTestingResultsTableRows: [],
        coverageFactor: '',
        distribution: '',
        comments: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    ferriteTesting: {
        testingStandardProcedure: '',
        testingTemperature: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        ferriteMeter: '',
        standardsSet: '',
        ferriteMeterSerialNumber: '',
        standardsSetSerialNumber: '',
        specimensQuantity: '',
        measuringInstrument: '',
        measuringInstrumentSerialNumber: ''
    },
    ferriteTestingResults: {
        ferriteTestingResultsTableRows: [],
        comments: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    chemicalTesting: {
        testingStandardProcedure: '',
        testingTemperature: '',
        testingMethod: '',
        specimensQuantity: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        testingEquipment: '',
        testingEquipmentSerialNumber: '',
        measuringInstrument: '',
        measuringInstrumentSerialNumber: ''
    },
    chemicalTestingResults: {
        chemicalTestingResultsTable1Rows: [],
        chemicalTestingResultsTable2Rows: [],
        interpretationsConclusions1: '',
        interpretationsConclusions2: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    fractureTesting: {
        testingStandardProcedure: '',
        testingTemperature: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        testingMachine: '',
        testingMachineSerialNumber: '',
        measuringInstrument: '',
        measuringInstrumentSerialNumber: '',
        testSpecimensDescription: '',
        testSpecimensQuantity: ''
    },
    fractureTestingResults: {
        fractureTestingResultsTableRows: [],
        comments: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    corrosionTesting: {
        testingStandardProcedure: '',
        locationOfTestSpecimens: '',
        testSpecimens: '',
        testingMethodDescription: '',
        testingCondition: ''
    },
    corrosionTestingResults: {
        corrosionTestingResultsTableRows: [],
        comments: '',
        acceptanceNormsStandard: '',
        deviationFromStandardsOrAgreement: '',
        testingPerformedBy: '',
        certificationNumber: ''
    },
    rangeOfQualifications: {
        weldingMethods: [],
        jointType: '',
        parentMaterialGroup: '',
        parentMaterialThickness: '',
        weldingMetalThickness: '',
        throatThickness: '',
        numberOfRuns: '',
        outsidePipeDiameter: '',
        fillerMaterialDesignation: '',
        fillerMaterialMake: '',
        shieldingGasDesignation: '',
        rootProtectionGasDesignation: '',
        typeOfWeldingCurrentAndPolarity: '',
        modeOfMetalTransfer: '',
        heatInput: '',
        arcEnergy: '',
        weldingPositions: '',
        preheatTemperature: '',
        interpassTemperature: '',
        postHeating: '',
        postWeldHeatTreatmentAndOrAgeing: '',
        otherInformation: ''
    },
    weldingTestingDetails1: {
        manufacturer: '',
        location: '',
        welderName: '',
        manufacturerPWPSNumber: '',
        weldingProcess: '',
        jointType: '',
        detailsOfGougingBacking: '',
        weldingPosition: '',
        weldingPosition2: '',
        methodOfPreparationAndCleaning: '',
        parentMaterialNameOrNumber: '',
        technicalDeliveryStandard: '',
        parentMaterialGroup: '',
        chargeNumberHeatNumber: '',
        materialThickness: '',
        outsideDiameter: '',
        fillerMaterialTradeName: '',
        fillerMaterialMake: '',
        fillerMaterialDesignation: '',
        chargeNumberLotNumberSerialNumber: '',
        weaving: '',
        shieldingGasFlux: '',
        shieldingGasFlowRate: '',
        backingGasFlux: '',
        backingGasFlowRate: '',
        nozzleDiameter: '',
        standOffDistance: '',
        torchAngle: '',
        tungstenElectrode: '',
        manufacturerOfPowerSource: '',
        preheatTemperature: '',
        interpassTemperature: '',
        postWeldHeatTreatmentAndOrAgeing: '',
        heatingAndCoolingRates: '',
        otherInformation: '',
        manufacturer2: '',
        manufacturer2Signature: '',
        manufacturer2Name: '',
        manufacturer2Date: '',
        examiner: '',
        examinerSignature: '',
        examinerName: '',
        examinerDate: '',
        tackWeldText: '',
        lengthTackWelds: '',
        arcType: '',
        commentInformation: '',
        plasmaGasName: '',
        plasmaflowrate: '',
        electrodeTorchAngle111: '',
        users: []
    },
    weldingMethodRangeOfQualifications: [],
    jointTypeRangeOfQualification: '',
    materialGroupRangeOfQualification: '',
    materialThicknessRangeOfQualifications: [],
    weldingMetalThicknessRangeOfQualification: '',
    throatThicknessRangeOfQualification: '',
    runTypeRangeOfQualification: '',
    diameterRangeOfQualification: '',
    designationRangeOfQualifications: [],
    makeRangeOfQualifications: [],
    shieldingGasRangeOfQualifications: [],
    rootProtectionGasRangeOfQualification: '',
    currentTypeRangeOfQualifications: [],
    dropletTransitionRangeOfQualifications: [],
    postWeldHeatTreatmentRangeOfQualification: '',
    preheatTemperatureRangeOfQualification: '',
    interpassTemperatureRangeOfQualification: ''
};

export const reducer: Reducer<WPQRDEKRATabStoreState> = (state: WPQRDEKRATabStoreState | undefined, incomingAction: Action): WPQRDEKRATabStoreState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_BEND_TESTING_RESULTS_TABLE_ROW':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
                selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: action.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'GET_APPLICATION_REQUEST':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: true,
                loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'GET_APPLICATIONS_RECEIVE':
            return {
                applications: action.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_DEKRA':
            return {
                applications: state.applications,
                applicationsIsOpen: true,
                selectedApplication: new GetApplicationResponse(),
                selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_BEND_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: action.bendTesting,
                bendTestingResults: action.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_CHEMICAL_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: action.chemicalTesting,
                chemicalTestingResults: action.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_CORROSION_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: action.corrosionTesting,
                corrosionTestingResults: action.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_CRUCIFORM_TENSILE_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: action.cruciformTensileTesting,
                cruciformTensileTestingResults: action.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_FERRITE_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: action.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_FRACTURE_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: action.fractureTesting,
                fractureTestingResults: action.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_HARDNESS_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: action.hardnessTesting,
                hardnessTestingResults: action.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_IMPACT_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: action.impactTesting,
                impactTestingResults: action.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_MACRO_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: action.macroTesting,
                macroTestingResults: action.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_MICRO_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: action.microTesting,
                microTestingResults: action.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: action.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_WELDING_PROTOCOL':
            return {
                applications: state.applications,
                applicationsIsOpen: action.applicationsIsOpen,
                selectedApplication: action.selectedApplication,
                selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: 'pwps',
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: action.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: false,
                loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'INIT_WELDING_TESTING_DETAILS1':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: action.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_APPLICATIONS_IS_OPEN':
            return {
                applications: state.applications,
                applicationsIsOpen: action.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_BEND_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: action.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_BEND_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: action.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_CHEMICAL_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: action.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_CHEMICAL_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: action.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_CORROSION_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: action.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_CORROSION_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: action.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_CRUCIFORM_TENSILE_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: action.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_CRUCIFORM_TENSILE_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: action.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_CURRENT_TYPE_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: action.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_DESIGNATION_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: action.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_DIAMETER_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: action.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_DROPLET_TRANSITION_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: action.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_FERRITE_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: action.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_FERRITE_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: action.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_FRACTURE_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: action.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_FRACTURE_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: action.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_HARDNESS_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: action.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_HARDNESS_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: action.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_IMPACT_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: action.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_IMPACT_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: action.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_INTERPASS_TEMPERATURE_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: action.interpassTemperatureRangeOfQualification
            };
        case 'SET_JOINT_TYPE_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: action.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_MACRO_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: action.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_MACRO_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: action.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_MATERIAL_GROUP_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: action.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_MAKE_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: action.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_MATERIAL_THICKNESS_RANGE_OF_QUALIFICATIONS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: action.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_MICRO_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: action.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_MICRO_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: action.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_ROOT_PROTECTION_GAS_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: action.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_RUN_TYPE_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: action.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_SELECTED_EXAMINATION_WPQR_TAB_ID':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: action.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_SELECTED_ONGOING_WPQR_TAB_ID':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: action.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_SELECTED_TEST_RESULTS_TAB_ID':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: action.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_SELECTED_WPQR_TAB_ID':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: action.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_SHIELDING_GAS_RANGE_OF_QUALIFICATIONS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: action.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_POST_WELD_HEAT_TREATMENT_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: action.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_PREHEAT_TEMPERATURE_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: action.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_TENSILE_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: action.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_TENSILE_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: action.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_THROAT_THICKNESS_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: action.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_TRANSVERSE_TENSILE_TESTING':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: action.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_TRANSVERSE_TENSILE_TESTING_RESULTS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: action.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_WELDING_METAL_THICKNESS_RANGE_OF_QUALIFICATION':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: action.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_WELDING_METHOD_RANGE_OF_QUALIFICATIONS':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: action.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_WELDING_PROTOCOL':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: action.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
                loadingWpqr: false,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_WELDING_TESTING_DETAILS1':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: action.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_WPQT_REPORT':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: action.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'UPDATE_APPLICATIONS_RECEIVE':
            return {
                applications: action.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'CREATE_UPDATE_WELDING_PROTOCOL_REQUEST':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
                loadingWpqr: true,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'CREATE_UPDATE_WELDING_PROTOCOL_RECEIVE':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
	 	 	 	selectedWpqr: state.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        case 'SET_WPQR':
            return {
                applications: state.applications,
                applicationsIsOpen: state.applicationsIsOpen,
                selectedApplication: state.selectedApplication,
                selectedWpqr: action.selectedWpqr,
                selectedExaminationWPQRTabId: state.selectedExaminationWPQRTabId,
                selectedOngoingWPQRTabId: state.selectedOngoingWPQRTabId,
                selectedTestResultsTabId: state.selectedTestResultsTabId,
                selectedWPQRTabId: state.selectedWPQRTabId,
                weldingProtocol: state.weldingProtocol,
                wpqtReport: state.wpqtReport,
                bendTesting: state.bendTesting,
                bendTestingResults: state.bendTestingResults,
                impactTesting: state.impactTesting,
                impactTestingResults: state.impactTestingResults,
                tensileTesting: state.tensileTesting,
                tensileTestingResults: state.tensileTestingResults,
                transverseTensileTesting: state.transverseTensileTesting,
                transverseTensileTestingResults: state.transverseTensileTestingResults,
                cruciformTensileTesting: state.cruciformTensileTesting,
                cruciformTensileTestingResults: state.cruciformTensileTestingResults,
                hardnessTesting: state.hardnessTesting,
                hardnessTestingResults: state.hardnessTestingResults,
                macroTesting: state.macroTesting,
                macroTestingResults: state.macroTestingResults,
                microTesting: state.microTesting,
                microTestingResults: state.microTestingResults,
                ferriteTesting: state.ferriteTesting,
                ferriteTestingResults: state.ferriteTestingResults,
                chemicalTesting: state.chemicalTesting,
                chemicalTestingResults: state.chemicalTestingResults,
                fractureTesting: state.fractureTesting,
                fractureTestingResults: state.fractureTestingResults,
                corrosionTesting: state.corrosionTesting,
                corrosionTestingResults: state.corrosionTestingResults,
                rangeOfQualifications: state.rangeOfQualifications,
                weldingTestingDetails1: state.weldingTestingDetails1,
                weldingMethodRangeOfQualifications: state.weldingMethodRangeOfQualifications,
                jointTypeRangeOfQualification: state.jointTypeRangeOfQualification,
                materialGroupRangeOfQualification: state.materialGroupRangeOfQualification,
                materialThicknessRangeOfQualifications: state.materialThicknessRangeOfQualifications,
                weldingMetalThicknessRangeOfQualification: state.weldingMetalThicknessRangeOfQualification,
                throatThicknessRangeOfQualification: state.throatThicknessRangeOfQualification,
                runTypeRangeOfQualification: state.runTypeRangeOfQualification,
                diameterRangeOfQualification: state.diameterRangeOfQualification,
                loadingApplication: state.loadingApplication,
	 	 	 	loadingWpqr: state.loadingWpqr,
                designationRangeOfQualifications: state.designationRangeOfQualifications,
                makeRangeOfQualifications: state.makeRangeOfQualifications,
                shieldingGasRangeOfQualifications: state.shieldingGasRangeOfQualifications,
                rootProtectionGasRangeOfQualification: state.rootProtectionGasRangeOfQualification,
                currentTypeRangeOfQualifications: state.currentTypeRangeOfQualifications,
                dropletTransitionRangeOfQualifications: state.dropletTransitionRangeOfQualifications,
                postWeldHeatTreatmentRangeOfQualification: state.postWeldHeatTreatmentRangeOfQualification,
                preheatTemperatureRangeOfQualification: state.preheatTemperatureRangeOfQualification,
                interpassTemperatureRangeOfQualification: state.interpassTemperatureRangeOfQualification
            };
        default:
            return state;
    }
};
