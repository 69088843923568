﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { ApplicationState } from '../store/index';
import * as MessageStore from '../store/MessageStore';
import { CreateMessageRequest } from '../store/models/requests/CreateMessageRequest';
import { UpdateMessageRequest } from '../store/models/requests/UpdateMessageRequest';
import { GetMessageResponse } from '../store/models/responses/GetMessageResponse';
import { t } from '../i18n';
// At runtime, Redux will merge together...
type MessageProps =
    MessageStore.MessageState // ... state we've requested from the Redux store
    & typeof MessageStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    selectMessage: boolean ;
    applicationIdStart: number;
    messageCodeStart: string;
    initMessages: (message: GetMessageResponse, action: string) => void;
    modalId: string;
    hiddenMessage?: boolean;
}

class Message extends React.PureComponent<MessageProps> {
    constructor(props: MessageProps) {
        super(props);
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
    }

    private onClickMessageModalOpen(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        if (this.props.applicationIdStart > 0) {
            this.props.getByApplicationIdMessageCode(this.props.applicationIdStart, this.props.messageCodeStart);
        }
        else {
            this.props.setByApplicationIdAndMessageCode(this.props.messageCodeStart)
        }
        this.props.setmodalIdActive(this.props.modalId)
        this.props.setShowMessageModal(true);
    }

    private onClickMessageModalOK(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        if (this.props.message.id !== 0) {
            let updateMessageRequest: UpdateMessageRequest = {
                id: this.props.message.id,
                applicationId: this.props.message.applicationId,
                messageCode: this.props.message.messageCode,
                messageText: this.props.message.messageText,
            }
            if (this.props.message.messageText !== "") {
                this.props.updateMessage(updateMessageRequest);
            } else {
                this.props.initMessages(this.props.message,"Delete");
                this.props.deleteMessageById(this.props.message.id)
            }
            
        }
        else {
            let createMessageRequest: CreateMessageRequest = {
                applicationId: this.props.message.applicationId,
                messageCode: this.props.message.messageCode,
                messageText: this.props.message.messageText,
            }
            if (createMessageRequest.messageText !== "") {
                this.props.createMessage(createMessageRequest);
                this.props.initMessages(this.props.message, "Add");
            }
        }
        this.props.setShowMessageModal(false);
        this.props.setMessage("");
    }

    private onClickMessageModalCancel(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowMessageModal(false);
        this.props.setMessage("");
    }

    private onChangeMessage(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setMessage(event.currentTarget.value);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private renderMessageModal() {
        return (
            <React.Fragment>
                <Button style={{ float: 'right', padding: '0px' }} color="link" size="sm" name={"deleteApplicationButton"} onClick={(event) => this.onClickMessageModalOpen(event)}>
                    <i className={this.props.selectMessage ? "fas fa-comments fa-comments-color" : "fas fa-comment"}></i>
                </Button>
                <Modal centered={true} size="md" isOpen={this.props.showMessageModal && this.props.modalId === this.props.modalIdActive} >
                    <ModalHeader>
                        {t('AddComment')}!
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label for="messageLabel" size="sm">{t('Comment')}:</Label>
                                    <Input type="text" bsSize="sm" name="messageText"
                                        value={this.props.message.messageText} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMessage(event)}
                                        placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickMessageModalOK(event)}>OK</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickMessageModalCancel(event)}>Avbryt</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }

    public render() {
        return (
            <React.Fragment>
                {this.props.hiddenMessage ? "" : this.renderMessageModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.message;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    MessageStore.actionCreators // Selects which action creators are merged into the component's props
)(Message as any);
