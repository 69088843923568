﻿export class ChemicalTestingResultsTableRow {
    grade: string;
    massPercentPartOfElements11: string;
    massPercentPartOfElements12: string;
    massPercentPartOfElements13: string;
    massPercentPartOfElements14: string;
    massPercentPartOfElements15: string;
    massPercentPartOfElements16: string;
    massPercentPartOfElements17: string;
    massPercentPartOfElements18: string;
    massPercentPartOfElements19: string;
    massPercentPartOfElements21: string;
    massPercentPartOfElements22: string;
    massPercentPartOfElements23: string;
    massPercentPartOfElements24: string;
    massPercentPartOfElements25: string;
    massPercentPartOfElements26: string;
    massPercentPartOfElements27: string;
    massPercentPartOfElements28: string;
    massPercentPartOfElements29: string;
    testSpecimenNumber: string;

    public constructor(testSpecimenNumber: string, massPercentPartOfElements11: string, massPercentPartOfElements12: string, massPercentPartOfElements13: string, massPercentPartOfElements14: string, massPercentPartOfElements15: string, massPercentPartOfElements16: string, massPercentPartOfElements17: string, massPercentPartOfElements18: string, massPercentPartOfElements19: string, massPercentPartOfElements21: string, massPercentPartOfElements22: string, massPercentPartOfElements23: string, massPercentPartOfElements24: string, massPercentPartOfElements25: string, massPercentPartOfElements26: string, massPercentPartOfElements27: string, massPercentPartOfElements28: string, massPercentPartOfElements29: string, grade: string) {
        this.testSpecimenNumber = testSpecimenNumber;
        this.massPercentPartOfElements11 = massPercentPartOfElements11;
        this.massPercentPartOfElements12 = massPercentPartOfElements12;
        this.massPercentPartOfElements13 = massPercentPartOfElements13;
        this.massPercentPartOfElements14 = massPercentPartOfElements14;
        this.massPercentPartOfElements15 = massPercentPartOfElements15;
        this.massPercentPartOfElements16 = massPercentPartOfElements16;
        this.massPercentPartOfElements17 = massPercentPartOfElements17;
        this.massPercentPartOfElements18 = massPercentPartOfElements18;
        this.massPercentPartOfElements19 = massPercentPartOfElements19;
        this.massPercentPartOfElements21 = massPercentPartOfElements21;
        this.massPercentPartOfElements22 = massPercentPartOfElements22;
        this.massPercentPartOfElements23 = massPercentPartOfElements23;
        this.massPercentPartOfElements24 = massPercentPartOfElements24;
        this.massPercentPartOfElements25 = massPercentPartOfElements25;
        this.massPercentPartOfElements26 = massPercentPartOfElements26;
        this.massPercentPartOfElements27 = massPercentPartOfElements27;
        this.massPercentPartOfElements28 = massPercentPartOfElements28;
        this.massPercentPartOfElements29 = massPercentPartOfElements29;
        this.grade = grade;
    }
}