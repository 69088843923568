﻿import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../store/index';
import * as WPSTabStore from '../store/WPSTabStore';;
import { Button, Card, CardBody, Pagination, PaginationItem, CardTitle, Col, Form, FormGroup, InputGroup, InputGroupText, Input, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader, NavLink, Row, Table, ButtonGroup, Tooltip, UncontrolledTooltip } from 'reactstrap';
//import '../customized.css';
import HelpFunctions from '../store/models/HelpFunctions';
import { Link } from 'react-router-dom';
import { WPS } from '../store/models/WPS';
import ModalWpsType from './ModalWpsType';
import { t } from '../i18n';
import { WpsTypeEnums, WpsTypeIntEnums} from '../store/models/WpsTypeEnums';
import { PDFRequest } from '../store/models/requests/PDFRequest';
import EventBus from '../store/eventBus';
import DeletionModal from './otherComponents/DeletionModal';
import Loader from './Loader';
import HelpRangeOfQualificationExamination from '../store/models/HelpRangeOfQualificationExamination';

// At runtime, Redux will merge together...
type WPSTabProps =
    WPSTabStore.WPSTabStoreState // ... state we've requested from the Redux store
    & typeof WPSTabStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ applicationId: string }>;

    const SweToEngWeldingMethods = new Map<string, string>();
    const SweToEngJointTypes = new Map<string, string>();
    const SweToEngRuns = new Map<string, string>();
    const SweToEngPipeOrPlate = new Map<string, string>();
    const splitString = ",  ";

class WPSTab extends React.PureComponent<WPSTabProps> {
    constructor(props: WPSTabProps) {
        super(props);
    }

    public state = {
        wpqrCompleteIsOpen: true,
        currentWpsId: 0,
        showDeletionModal: false
    };

    public setDeletionModalOpen(open: boolean) {
        this.setState({ showDeletionModal: open });
    }

    public componentDidMount() {
        const customerId = JSON.parse(localStorage.getItem('customerId')!);
        this.props.getWPSs(customerId);
        EventBus.on("refreshWpsList", () => {
            const customerId = JSON.parse(localStorage.getItem('customerId')!);
            this.props.getWPSs(customerId);
        });
        this.props.getWeldingMethodsSearch();
        this.props.getProductTypesAll();
        this.props.getJointTypesAll();
        this.props.getMaterialGroupAll();
        this.props.getRuntypeRangeOfQualification();
        this.props.getStandardsAll();
        this.props.clearSearchProperties();
        this.props.clearWpsType();
        this.props.clearWpsRevisionModal();

        SweToEngWeldingMethods.set('111 Metallbågsvetsning med belagd elektrod', t('111'));
        SweToEngWeldingMethods.set('114 Metallbågsvetsning med rörelektrod utan gasskydd', t('114'));
        SweToEngWeldingMethods.set('121, helmekaniserad', t('121'));
        SweToEngWeldingMethods.set('122 Pulverbågsvetsning med bandelektrod', t('122'));
        SweToEngWeldingMethods.set('124 Pulverbågsvetsning med metallpulver', t('124'));
        SweToEngWeldingMethods.set('125 Pulverbågsvetsning med rörelektrod', t('125'));
        SweToEngWeldingMethods.set('126 Pulverbågsvetsning med rörformad bandelektrod', t('126'));
        SweToEngWeldingMethods.set('131 MIG-svetsning med trådelektrod (Inert gas)', t('131'));
        SweToEngWeldingMethods.set('132 MIG-svetsning med slaggande rörelektrod', t('132'));
        SweToEngWeldingMethods.set('133 MIG-svetsning med metallpulverfylld rörelektrod', t('133'));
        SweToEngWeldingMethods.set('135 MAG-svetsning med trådelektrod', t('135'));
        SweToEngWeldingMethods.set('136 MAG-svetsning, rörelektrod fluxfylld', t('136'));
        SweToEngWeldingMethods.set('138 MAG-svetsning, metallpulverfylld rörelektrod (M)', t('138'));
        SweToEngWeldingMethods.set('141 TIG-svetsning med trådelektrod', t('141'));
        SweToEngWeldingMethods.set('142 TIG-svetsning utan tillsatsmaterial', t('142'));
        SweToEngWeldingMethods.set('143 TIG-svetsning med rörelektrod', t('143'));
        SweToEngWeldingMethods.set('145 TIG-svetsning med reducerande gas och tråd', t('145'));
        SweToEngWeldingMethods.set('146 TIG-svetsning med reducerande gas och pulverfylld tråd/stav', t('146'));
        SweToEngWeldingMethods.set('147 Gasvolframbågsvetsning med aktiv gas (TAGsvetsning)', t('147'));
        SweToEngWeldingMethods.set('151 Plasma-MIG-svetsning', t('151'));
        SweToEngWeldingMethods.set('152 Plasmapulversvetsning', t('152'));
        SweToEngWeldingMethods.set('153 Plasmasvetsning med överförd ljusbåge (PTA)', t('153'));
        SweToEngWeldingMethods.set('154 Plasmasvetsning med ej överförd ljusbåge', t('154'));
        SweToEngWeldingMethods.set('155 Plasmasvetsning med delvis överförd ljusbåge', t('155'));
        SweToEngWeldingMethods.set('311 Oxy-acetylensvetsning', t('311'));
        SweToEngWeldingMethods.set('312 Oxy-propansvetsning', t('312'));
        SweToEngWeldingMethods.set('313 Oxy-hydrogensvetsning', t('313'));

        SweToEngJointTypes.set('Stumsvets (BW)', t('ButtWeldingBW'));
        SweToEngJointTypes.set('Partiell stumsvets', t('PartialButtWelding'));
        SweToEngJointTypes.set('Kälsvets (FW)', t('KeelWeldingFW'));
        SweToEngJointTypes.set('T-förband (BW)', t('TJointBW'));
        SweToEngJointTypes.set('Rör mot plåt (BW)', t('PipeAgainstPlateBW'));
        SweToEngJointTypes.set('Rör mot plåt (FW)', t('PipeAgainstPlateFW'));
        SweToEngJointTypes.set('Avstickare (BW)', t('StickOutBW'));
        SweToEngJointTypes.set('Avstickare (FW)', t('StickOutFW'));

        SweToEngRuns.set('Enkelsträng / Single run', t('SingleRun'));
        SweToEngRuns.set('Flersträng / Multi run', t('MultiRun'));

        SweToEngPipeOrPlate.set('Plåt', t('Plate'));
        SweToEngPipeOrPlate.set('Rör', t('Pipe'));
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.wpsType.selectedWpstypeModalOk !== this.props.wpsType.selectedWpstypeModalOk && this.props.wpsType.selectedWpstypeModalOk) {
            this.props.history.push('/wpsformedit/' + this.props.wpsType.selectedWpsTypeId + '/' + this.props.wpsType.selectedWpsId);
        }
        if (prevProps.wpsRevision.selectedWpsRevisionModalOk !== this.props.wpsRevision.selectedWpsRevisionModalOk! && this.props.wpsRevision.selectedWpsRevisionModalOk) {
            this.props.history.push('/wpsformedit/' + (this.props.wpsRevision.selectedRevision.wpsType === WpsTypeEnums.PWPS ? WpsTypeIntEnums.PWPS : WpsTypeIntEnums.WPS)   + '/' + this.props.wpsRevision.selectedRevision.id);
        }
    }

    private TranslateBoxes = (word: string, dictionary: Map<string, string>) => {
        if (dictionary.has(word)) {
            console.log("Return1:" + dictionary.get(word));
            return dictionary.get(word);
        } else {
            console.log("Return2: " + word);
            return word;
        }
    }

    private onChangeFreeSearch(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setFreeSearch(event.currentTarget.value);
        this.props.getWPSsSearchResult(this.props.wpss);
    }
    private onChangeMaterialThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setMaterialThickness(event.currentTarget.value);
        this.props.getWPSsSearchResult(this.props.wpss);
    }
    private onChangeouterDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setOuterDiameter(event.currentTarget.value);
        this.props.getWPSsSearchResult(this.props.wpss);
    }
    private onClickTabSettingModalCancel(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowTabSettingModal(false);
    }
    private onClickTabSettingModalOK(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowTabSettingModal(false);
    }

    private onClickTabSettingModalOpen(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowTabSettingModal(true);
    }

    private onChangeColumnDekraProcedureNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnDekraProcedureNumber(event.currentTarget.checked);
    }

    private onChangeColumnManufacturerWPSNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnmanufacturerWPSNumber(event.currentTarget.checked);
    }

    private onChangeColumnPwpsName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnPwpsName(event.currentTarget.checked);
    }

    private onChangeColumnQualificationStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnQualificationStandard(event.currentTarget.checked);
    }

    private onChangeColumnWeldingMethod(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnWeldingMethod(event.currentTarget.checked);
    }

    private onChangeColumnJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnJointTypes(event.currentTarget.checked);
    }

    private onChangeColumnProductType(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnProductType(event.currentTarget.checked);
    }

    private onChangeColumnMaterialThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMaterialThickness(event.currentTarget.checked);
    }

    private onChangeColumnOutsideDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnOutsideDiameter(event.currentTarget.checked);
    }

    private onChangeColumnMaterialGroup(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMaterialGroup(event.currentTarget.checked);
    }

    private onChangeColumnSingleMultiString(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnSingleMultiString(event.currentTarget.checked);
    }

    private onChangeColumnMakeAdditives(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMakeAdditives(event.currentTarget.checked);
    }
    //Pagination
    private handlePrevPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.wpsPaginationPageIndex - 1);
    }

    private handleNextPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.wpsPaginationPageIndex + 1);
    }

    private handlePageClick(event: React.MouseEvent<HTMLElement, MouseEvent>, i: number) {
        event.preventDefault();
        this.props.setPaginationPageIndex(i);
    }
    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
    private onChangeWeldingMethod(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingMethodForSearch(parseInt(event.currentTarget.value));
        this.props.getWPSsSearchResult(this.props.wpss);
    }
    private onChangeStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setStandardForSearch(parseInt(event.currentTarget.value));
        this.props.getWPSsSearchResult(this.props.wpss);
    }
    private onChangeProductTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedProductType(parseInt(event.currentTarget.value));
        this.props.getWPSsSearchResult(this.props.wpss);
    }
    private onChangeJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedJointType(parseInt(event.currentTarget.value));
        this.props.getWPSsSearchResult(this.props.wpss);
    }
    private onChangeMaterialGroups(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedMaterialGroup(parseInt(event.currentTarget.value));
        this.props.getWPSsSearchResult(this.props.wpss);
    }
    private onChangeRunTypeRangeOfQualifications(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedRunTypeRangeOfQualification(parseInt(event.currentTarget.value));
        this.props.getWPSsSearchResult(this.props.wpss);
    }
    private onClickWpsRevisionModalCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.closeWpsRevisionModal(false);
    }

    private onClickWpsRevisionModalOK(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.closeWpsRevisionModal(true);
    }

    private onClickCloneWpsRow(rowNumber: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setselectedWpsId(rowNumber);
        this.props.setShowChooseWpsTypeModal(true);
    }

    private onClickGeneratePdf(wpsId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.getElementById('tabSettingButton') as HTMLButtonElement).focus();// To close UncontrolledTooltip
        let pdfRequest: PDFRequest = {
            wpsId: wpsId,
            wpqrId: 0,
            wpId: 0,
            fileName: "",
            weldingPlanId: 0,
        }
        this.props.createPdf(pdfRequest);
    }
    
    private onClickRevisionWpsRow(rowNumber: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip
        this.props.getWPSRevisions(rowNumber);
        this.props.setSelectedRevisionIdTo0();
        this.props.setShowChooseWpsRevisionModal(true);
    }

    private onChangeSelectedRevision(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedRevision(parseInt(event.currentTarget.value));
    }

    public removeWps() {
        this.props.removeWps(this.state.currentWpsId)
    }

    public onRemoveWpsPress(wpsId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip
        this.setState({ currentWpsId: wpsId });
        this.setDeletionModalOpen(true);
    }

    public render() {
        return (
            <React.Fragment key={"renderWpqrTab"}>
                <Form className="dekra-content-wrapper dekra-wrapper">
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Row form>
                                            <Col sm={3}>
                                                <Row form>
                                                    <Col sm={12}>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={12}>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={12}>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={6}>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <NavLink className="btn btn-primary" tag={Link} to="/wpsformedit/-1">{t('AddWPS')}</NavLink>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <NavLink className="btn btn-primary" tag={Link} to="/wpsformedit/0">{t('AddpWPS')}</NavLink>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                    </Col>
                                                    <Col sm={3}>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={12}>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={12}>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={12}>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={3}>
                                                <Row form>
                                                    <Col sm={12}>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={12}>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={12}>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={3}>
                            <FormGroup>
                                <Card className="card-border-dekra">
                                    <CardBody>
                                        <CardTitle>

                                        </CardTitle>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type="text" bsSize="sm" name="freeSearch"
                                                            value={this.props.searchProperties.freeSearch} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFreeSearch(event)}
                                                            placeholder="Sök" />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="exampleSelect">{t('QualificationStandard')}</Label>
                                                    <Input type="select" bssize="sm" name="ByStandard" id="ByStandard"
                                                        value={this.props.searchProperties.selectedStandard.id}
                                                        onChange={(event) => this.onChangeStandard(event)}
                                                        placeholder="">
                                                        <option key={"byStandardId0"} id="byStandardId0" value="0">{t('--all--')}</option>
                                                        {this.props.searchProperties.standards.map((standard, index) => (
                                                            <option key={"byStandardId" + standard.id} id={"byStandardId" + standard.id} value={standard.id}>{standard.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="exampleSelect">{t('WeldingMethod')}</Label>
                                                    <Input type="select" bssize="sm" name="ByWeldingMethod" id="ByWeldingMethod"
                                                        value={this.props.searchProperties.selectedWeldingMethod.id}
                                                        onChange={(event) => this.onChangeWeldingMethod(event)}
                                                        placeholder="">
                                                        <option key={"byStandardId0"} id="byStandardId0" value="0">{t('--all--')}</option>
                                                        {this.props.wpsWeldingMethodsSearch.map((weldingMethod, index) => (
                                                            <option key={"byWeldingMethodId" + weldingMethod.id} id={"byWeldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{this.TranslateBoxes(weldingMethod.description, SweToEngWeldingMethods)}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="wpsJointTypes" size="sm">{t('JointType')} (BW, FW)</Label>
                                                    <Input type="select" bsSize="sm" name="wpsJointTypes" id="wpsJointTypes"
                                                        value={this.props.searchProperties.selectedJointType.id}
                                                        onChange={(event) => this.onChangeJointTypes(event)}
                                                        placeholder="">
                                                        <option id="jointTypeId0" value="0">{t('--all--')}</option>
                                                        {this.props.searchProperties.jointTypes.map((jointType, index) => (
                                                            <option key={"jointTypeId" + jointType.id} id={"jointTypeId" + jointType.id} value={jointType.id}>{this.TranslateBoxes(jointType.name, SweToEngJointTypes)}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="productTypes" size="sm">{t('ProductType')}: {t('pipe')} (T), {t('plate')} (P)</Label>
                                                    <Input type="select" bsSize="sm" name="productTypes" id="productTypes"
                                                        value={this.props.searchProperties.selectedProductType.id}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeProductTypes(event)}
                                                        placeholder="">
                                                        <option key="productType2Id0" id="productType2Id0" value="0">{t('--all--')}</option>
                                                        {this.props.searchProperties.productTypes.map((productType, index) => (
                                                            <option key={"productType2Id" + productType.id} id={"productType2Id" + productType.id} value={productType.id}>{this.TranslateBoxes(productType.name, SweToEngPipeOrPlate)}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="materialThickness">{t('BaseMaterialThickness')} (mm)</Label>
                                                    <Input type="text" name="materialThickness" id="materialThickness"
                                                        value={this.props.searchProperties.materialThickness} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMaterialThickness(event)}
                                                        placeholder="n,d (mm)" />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="outerDiameter">{t('OutsideDiameter')} (mm)</Label>
                                                    <Input type="text" name="outerDiameter" id="outerDiameter"
                                                        value={this.props.searchProperties.outerDiameter} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeouterDiameter(event)}
                                                        placeholder="n,d (mm)" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="wpsMaterialGroups" size="sm">{t('BaseMaterialGroup')}</Label>
                                                    <Input type="select" bsSize="sm" name="wpsMaterialGroups" id="wpsMaterialGroups"
                                                        value={this.props.searchProperties.selectedMaterialGroup.id}
                                                        onChange={(event) => this.onChangeMaterialGroups(event)}
                                                        placeholder="">
                                                        <option key="materialGroupId0" id="materialGroupId0" value="0">{t('--all--')}</option>
                                                        {this.props.searchProperties.materialGroups.map((materialGroup, index) => (
                                                            <option key={"materialGroupId" + materialGroup.id} id={"materialGroupId" + materialGroup.id} value={materialGroup.id}>{materialGroup.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="wpsRunTypeRangeOfQualifications" size="sm">{t('SingleMultiRun')}</Label>
                                                    <Input type="select" bsSize="sm" name="wpsRunTypeRangeOfQualifications" id="wpsRunTypeRangeOfQualifications"
                                                        value={this.props.searchProperties.selectedRunTypeRangeOfQualification.id}
                                                        onChange={(event) => this.onChangeRunTypeRangeOfQualifications(event)}
                                                        placeholder="">
                                                        <option key="runTypeRangeOfQualificationId0" id="runTypeRangeOfQualificationId0" value="0">{t('--all--')}</option>
                                                        {this.props.searchProperties.runTypeRangeOfQualifications.map((runTypeRangeOfQualification, index) => (
                                                            <option key={"runTypeRangeOfQualificationId" + runTypeRangeOfQualification.id} id={"runTypeRangeOfQualificationId" + runTypeRangeOfQualification.id} value={runTypeRangeOfQualification.id}>{this.TranslateBoxes(runTypeRangeOfQualification.rangeOfQualification, SweToEngRuns)}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                        <Col sm={9}>

                            <FormGroup>
                                <Card>
                                    <Loader show={this.props.enablePdfLoader} title={t('Downloading')} />
                                    <CardTitle>
                                        <Row form>
                                            <Col sm={4}>
                                            </Col>
                                            <Col sm={4}>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Button className={"dekra2-table-settings-btn"} color="link" size="sm" id={"tabSettingButton"} name={"tabSettingButton"} onClick={(event) => this.onClickTabSettingModalOpen(event)}>
                                                        <i className={"fas fa-cog"}></i>
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Table size="sm" className="cert-table table-striped" responsive={true}>
                                                    <thead>
                                                        {this.renderTableHeaderRow()}
                                                    </thead>
                                                    <tbody>
                                                        <DeletionModal
                                                            onConfirmDeletion={() => this.removeWps()}
                                                            show={this.state.showDeletionModal}
                                                            setOpen={(open: boolean) => this.setDeletionModalOpen(open)}
                                                            headerText={t('DeleteWPS')}
                                                        />
                                                        {this.renderTabSettingModal()}
                                                        {this.renderChooseWpsTypeModal()}
                                                        {this.rendeWpsRevisionModal()}
                                                        {this.props.wpssSearchResult && this.props.wpssSearchResult.slice(
                                                            this.props.wpsPaginationPageIndex * this.props.wpsPaginationPageSize,
                                                            this.props.wpsPaginationPageIndex * this.props.wpsPaginationPageSize + this.props.wpsPaginationPageSize
                                                        ).map((wps, index) => (
                                                            this.renderTableRow(wps, index)
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan={14}>
                                                                {this.renderPagination()}
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderPagination() {
        return (
            <React.Fragment key="renderPagination">
                <div className="pagination-wrapper">

                    <Pagination aria-label="Page navigation example">
                        <PaginationItem onClick={event => this.handlePrevPageClick(event)}>
                            <a className={this.props.wpsPaginationPageIndex === 0 ? "btn disabled" : "btn"} aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </PaginationItem>


                        {[...Array(Math.ceil(this.props.wpssSearchResult.length / this.props.wpsPaginationPageSize))].map((page, i) =>
                            <PaginationItem key={i} active={this.props.wpsPaginationPageIndex === i} onClick={(event) => this.handlePageClick(event, i)}>
                                <a className="btn">{i + 1}</a>
                            </PaginationItem>
                        )}

                        <PaginationItem onClick={event => this.handleNextPageClick(event)}>
                            <a className={this.props.wpsPaginationPageIndex === (Math.ceil(this.props.wpssSearchResult.length / this.props.wpsPaginationPageSize) - 1) ? "btn disabled" : "btn"} aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </PaginationItem>
                    </Pagination>
                </div>
            </React.Fragment>
        );
    }
    private rendeWpsRevisionModal() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.wpsRevision.showChooseWpsRevisionModal}>
                    <ModalHeader>
                        {t('ListOfWPSRevisions')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                {this.props.wpsRevision.revisions.map((revision, index) => (
                                    <FormGroup check key={index}>
                                        <Label check size="sm">
                                            <Input type="radio" bssize="sm" key={"revisions" + revision.id} id={"revisions" + revision.id} name="revisions"
                                                value={revision.id}
                                                checked={this.props.wpsRevision.selectedRevision.id === revision.id}
                                                onChange={(event) => this.onChangeSelectedRevision(event)}
                                                placeholder="" />{'Revision(' + (this.props.wpsRevision.revisions.length - 1 - index) + '), ' + revision.createdAt + ', ' + revision.employee.firstName + ' ' + revision.employee.lastName}
                                        </Label>
                                    </FormGroup>
                                ))}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickWpsRevisionModalOK(event)} disabled={this.props.wpsRevision.selectedRevision.id === 0 ? true : false}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickWpsRevisionModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
    private renderChooseWpsTypeModal() {
        return (
            <React.Fragment>
                <ModalWpsType openModal={this.props.wpsType.showChooseWpsTypeModal} parentCallback={this.props.onClickModalOK} calcelModalCallback={this.props.setShowChooseWpsTypeModal}></ModalWpsType>
            </React.Fragment>
        );
    }
    private renderTabSettingModal() {
        return (
            <React.Fragment key="renderTabSettingModal">
                <Modal centered={true} size="lg" isOpen={this.props.showTabSettingModal}>
                    <ModalHeader>
                        {t('TableSettings')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpsTabSettingColumns.dekraProcedureNumber}
                                            onChange={(event) => this.onChangeColumnDekraProcedureNumber(event)}
                                            placeholder="" />{' '} DEKRA procedur-nr.
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPSNumber" id="qwbpColumnmanufacturerWPSNumber"
                                            checked={this.props.wpsTabSettingColumns.weldingMethod}
                                            onChange={(event) => this.onChangeColumnWeldingMethod(event)}
                                            placeholder="" />{' '} {t('WeldingMethod')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpsTabSettingColumns.outsideDiameter}
                                            onChange={(event) => this.onChangeColumnOutsideDiameter(event)}
                                            placeholder="" />{' '} {t('OutsideDiameter')} (mm)
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpsTabSettingColumns.manufacturerWPSNumber}
                                            onChange={(event) => this.onChangeColumnManufacturerWPSNumber(event)}
                                            placeholder="" />{' '} {t('ManufacturersWPSNo')}.
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPSNumber" id="qwbpColumnmanufacturerWPSNumber"
                                            checked={this.props.wpsTabSettingColumns.jointTypes}
                                            onChange={(event) => this.onChangeColumnJointTypes(event)}
                                            placeholder="" />{' '} {t('JointType')} (BW, FW)
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpsTabSettingColumns.materialGroup}
                                            onChange={(event) => this.onChangeColumnMaterialGroup(event)}
                                            placeholder="" />{' '} {t('BaseMaterialGroup')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpsTabSettingColumns.pwpsName}
                                            onChange={(event) => this.onChangeColumnPwpsName(event)}
                                            placeholder="" />{' '} pWPS-nr
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPSNumber" id="qwbpColumnmanufacturerWPSNumber"
                                            checked={this.props.wpsTabSettingColumns.productType}
                                            onChange={(event) => this.onChangeColumnProductType(event)}
                                            placeholder="" />{' '} {t('ProductType')}: {t('pipe')} (T), {t('plate')} (P)
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpsTabSettingColumns.singleMultiString}
                                            onChange={(event) => this.onChangeColumnSingleMultiString(event)}
                                            placeholder="" />{' '} {t('SingleMultiRun')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpsTabSettingColumns.qualificationStandard}
                                            onChange={(event) => this.onChangeColumnQualificationStandard(event)}
                                            placeholder="" />{' '} {t('QualificationStandard')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPSNumber" id="qwbpColumnmanufacturerWPSNumber"
                                            checked={this.props.wpsTabSettingColumns.materialThickness}
                                            onChange={(event) => this.onChangeColumnMaterialThickness(event)}
                                            placeholder="" />{' '} {t('BaseMaterialThickness')} (mm)
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpsTabSettingColumns.makeAdditives}
                                            onChange={(event) => this.onChangeColumnMakeAdditives(event)}
                                            placeholder="" />{' '} {t('MakeAdditives')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickTabSettingModalOK(event)}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickTabSettingModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
    private renderTableHeaderRow() {
        return (
            <React.Fragment key={"renderTableHeaderRow"}>
                <tr>
                    <th><a></a></th>
                    {this.props.wpsTabSettingColumns.pwpsName ? <th>
                        <a>{t('pWPS_WPS')}</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.manufacturerWPSNumber ? <th>
                        <a>{t('ManufacturersWPSNo')}</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.manufacturerWPQRNumber ? <th>
                        <a>{t('ManufacturersWPQRNo')}</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.dekraProcedureNumber ? <th>
                        <a>{t('DekraProcedureNo')}</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.qualificationStandard ? <th>
                        <a>{t('QualificationStandard')}</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.weldingMethod ? <th>
                        <a>Svetsmetod</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.jointTypes ? <th>
                        <a> {t('JointType')} (BW, FW)</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.productType ? <th>
                        <a>{t('ProductType')}: {t('pipe')} (T), {t('plate')} (P)</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.materialThickness ? <th>
                        <a>{t('BaseMaterialThickness')} (mm)</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.outsideDiameter ? <th>
                        <a>{t('OutsideDiameter')} (mm)</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.materialGroup ? <th>
                        <a>{t('BaseMaterialGroup')}</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.singleMultiString ? <th>
                        <a>{t('SingleMultiRun')}</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.makeAdditives ? <th>
                        <a>{t('MakeAdditives')}</a>
                    </th> : undefined}
                    {this.props.wpsTabSettingColumns.makeAdditives ? <th>
                        <a>{t('AdditivesDesignation')}</a>
                    </th> : undefined}
                </tr>
            </React.Fragment>
        );
    }
    private renderTableRow(wps: WPS, index: number) {
        return (
            <React.Fragment key={"renderTableRow" + index}>
                <tr key={wps.dekraNumber}>
                    <td>
                        <ButtonGroup vertical>
                        <Button color="link" size="sm" name={"historyWeldingParametersRow-" + index} id={"historyWeldingParametersRow-" + index}
                            onClick={(event) => this.onClickRevisionWpsRow(wps.id, event)}>
                            <i className="fas fa-history"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"historyWeldingParametersRow-" + index} >
                                {t('Revisions')}!
                            </UncontrolledTooltip>
                            <Button color="link" size="sm" name={"cloneWeldingParametersRow-" + index} id={"cloneWeldingParametersRow-" + index}
                            onClick={(event) => this.onClickCloneWpsRow(wps.id, event)}>
                            <i className="fas fa-clone"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"cloneWeldingParametersRow-" + index}>
                                {t('Copy')}!
                            </UncontrolledTooltip>
                            <Button color="link" size="sm" name={"generatePdfRow-" + index} id={"generatePdfRow-" + index}
                                onClick={(event) => this.onClickGeneratePdf(wps.id, event)}>
                                <i className="fas fa-file-pdf"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"generatePdfRow-" + index}>
                                PDF!
                            </UncontrolledTooltip>
                            <Button color="link" size="sm" name={"deleteRow-" + index} id={"deleteRow-" + index}
                                onClick={(event) => this.onRemoveWpsPress(wps.id, event)}>
                                <i className="fas fa-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"deleteRow-" + index}>
                                {t('Delete')}!
                            </UncontrolledTooltip>
                        </ButtonGroup>
                    </td>
                    {this.props.wpsTabSettingColumns.pwpsName ? <td>
                        {HelpRangeOfQualificationExamination.SetWpsType(wps.wpsType)}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.manufacturerWPSNumber ? <td>
                        {wps.pwpsNumber}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.manufacturerWPQRNumber ? <td>
                        {wps.wpqrNumber}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.dekraProcedureNumber ? <td>
                        {wps.dekraNumber}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.qualificationStandard ? <td>
                        {wps.standardName}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.weldingMethod ? <td>
                        {HelpFunctions.putNameAndNumberTogether(wps.weldingMethod1Name.split(",", 1)[0],  wps.weldingMethod2Name.split(",", 1)[0])}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.jointTypes ? <td>
                        {HelpRangeOfQualificationExamination.GetValueRangeOfQualificationJointType(HelpRangeOfQualificationExamination.setNullToEmpty(wps.jointTypeName))}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.productType ? <td>
                        {HelpFunctions.joinTwoStringIfTheyAreDifferent(wps.productType1Name, wps.productType2Name)}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.materialThickness ? <td>
                        {wps.materialThickness1RangeOfQualification.split(splitString).length === 2 ?
                            <p>{wps.materialThickness1RangeOfQualification.split(splitString)[0]}<br></br>{wps.materialThickness1RangeOfQualification.split(splitString)[1]}</p> : wps.materialThickness1RangeOfQualification
                        }
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.outsideDiameter ? <td>
                        {wps.diameter1Text.split(splitString).length === 2 ?
                            <p>{wps.diameter1Text.split(splitString)[0]}<br></br>{wps.diameter1Text.split(splitString)[1]}</p> :wps.diameter1Text
                         }
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.materialGroup ? <td>
                        {HelpRangeOfQualificationExamination.GetValueRangeOfQualificationMaterialGroup(HelpRangeOfQualificationExamination.setNullToEmpty(wps.materialGroupRangeOfQualification))}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.singleMultiString ? <td>
                        {wps.runTypeRangeOfQualification}
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.makeAdditives ? <td>
                        {wps.make1Name.split(splitString).length === 2 ?
                            <p>{wps.make1Name.split(splitString)[0]}<br></br>{wps.make1Name.split(splitString)[1]}</p> : wps.make1Name
                        }
                    </td> : undefined}
                    {this.props.wpsTabSettingColumns.makeAdditives ? <td>
                        {wps.make1DesignationName.split(splitString).length === 2 ?
                            <p>{wps.make1DesignationName.split(splitString)[0]}<br></br>{wps.make1DesignationName.split(splitString)[1]}</p> : wps.make1DesignationName
                        }
                    </td> : undefined}
                </tr>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.wpsTab, // Selects which state properties are merged into the component's props
    WPSTabStore.actionCreators // Selects which action creators are merged into the component's props
)(WPSTab as any);