﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { ApplicationState } from '../store/index';
import * as MyPageStore from '../store/MyPageStore';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col, Container, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Nav, NavItem, NavLink, Row, UncontrolledTooltip } from 'reactstrap';
import EditMyPageModal from './EditMyPageModal';
import { t } from '../i18n';
import authHeader from '../store/models/auth-header';
import { UserListObject } from '../store/UserListObject';
import EventBus from '../store/eventBus';

interface MyPageInfoProps extends RouteComponentProps<{ token: string }> {

}

// At runtime, Redux will merge together...
type MyPageProps =
    MyPageStore.MyPageState // ... state we've requested from the Redux store
    & typeof MyPageStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ token: string }>;

interface FilteredFacsProps {
    userObject: UserListObject;
}

const RenderUserInformation: React.FunctionComponent<FilteredFacsProps> = ({ userObject }) => {
    const [currentUser, setCurrentUser] = React.useState<UserListObject>({
        userName: '',
        firstName: '',
        lastName: '',
        displayName: '',

        title: '',
        email: '',

        phoneNumber: '',
        mobilePhoneNumber: '',
        phoneExchangeNumber: '',
        roles: [],

        visitingCity: '',
        visitingZipcode: '',
        visitingStreet: '',

        postCity: '',
        postZipcode: '',
        postStreet: '',

        customer: null,
        customerId: '',

        dekraContact: null,
        dekraContactId: '',

        website: '',
        id: '',
        signatureLogo: '',
    });

    React.useEffect(() => {
        setCurrentUser(userObject);
    }, [userObject])

    return (
        <Row form>
            <Col sm={6}>
                <FormGroup>
                    {currentUser.customer ?
                        <React.Fragment><Row form>
                            <Col className="no-padding" sm={3}>
                                <Label for="" size="sm">{t('AssignedCompany')}</Label>
                            </Col>
                        </Row>
                            <Row form>
                                <Col className="no-padding" sm={3}>
                                    <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.customer.name : null}</Label>
                                </Col>
                            </Row>
                        </React.Fragment> : null}

                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('Username')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.userName : null}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('FirstName')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.firstName : null}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('LastName')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.lastName : null}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('Title')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.title : null}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('Email')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.email : null}</Label>
                        </Col>
                    </Row>

                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('Phone')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.phoneNumber : null}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('MobilePhone')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.mobilePhoneNumber : null}</Label>
                        </Col>
                    </Row>

                    {/*<Row form>*/}
                    {/*    <Col className="no-padding" sm={3}>*/}
                    {/*        <Label for="" size="sm">{t('Permissions')}</Label>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row form>*/}
                    {/*    <Col className="no-padding" sm={3}>*/}
                    {/*        <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.roles[0] : null}</Label>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row form>*/}
                    {/*    <Col className="no-padding" sm={3}>*/}
                    {/*        <Label for="" size="sm">{t('Website')}</Label>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row form>*/}
                    {/*    <Col className="no-padding" sm={3}>*/}
                    {/*        <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.website : null}</Label>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </FormGroup>
            </Col>

            <Col sm={6}>
                <FormGroup>
                    <br />
                    <h5 style={{ color: "#007D40" }}>{t('VisitingAddress')}</h5>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('Street')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.visitingStreet : null}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('City')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.visitingCity : null}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('Zipcode')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.visitingZipcode : null}</Label>
                        </Col>
                    </Row>
                    <br />
                    <h5 style={{ color: "#007D40" }}>{t('PostAddress')}</h5>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('Street')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.postStreet : null}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('City')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.postCity : null}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label for="" size="sm">{t('Zipcode')}</Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="no-padding" sm={3}>
                            <Label className="standard-text" for="" size="sm">{currentUser ? currentUser.postZipcode : null}</Label>
                        </Col>
                    </Row>
                </FormGroup>
            </Col>
        </Row>
    )
};

class MyPage extends React.PureComponent<MyPageProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        //let params = this.queryStringParse(this.props.location.search);

        this.props.recieveUserInformation();

        //if (params["Token"] !== undefined) {
        //    this.props.adlogin(params["Token"]);
        //}
    }

    public queryStringParse(url: string) {
        const arr = url.slice(1).split(/&|=/); // remove the "?", "&" and "="
        let queryStringparams: { [key: string]: string } = {
        }
        for (let i = 0; i < arr.length; i += 2) {
            const key = arr[i], value = arr[i + 1];
            queryStringparams[key] = value; // build the object = { limit: "10", page:"1", status:"APPROVED" }
        }

        return queryStringparams;
    }

    private onClickToggleEditModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.toggleEditModal();
    }

    public render() {
        let params = this.queryStringParse(this.props.location.search);

        //if (this.props.loggedIn)
        //{


        //    if (params["Token"] !== undefined)
        //    {
        //       /// window.location.href = '/';
        //    }
        //}

        return (
            <React.Fragment>
                <div className="dekra-content-wrapper dekra-wrapper">
                    <Form>
                        {/*                        <Row form>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <h4 style={{ color: "#007D40"}}>{t('MySettings')}</h4>
                                        </CardTitle>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Row form>
                                                    <Col className="no-padding" sm={3}>
                                                        <Label for="" size="sm">{t('TimeIntervalEmail')}</Label>
                                                    </Col>

                                                    <Col className="no-padding" sm={3}>
                                                        <Input type="select" bssize="sm" name="ByStandard" id="ByStandard"
                                                            value="Aldrig"
                                                            placeholder="">
                                                            <option key={"byStandardId0"} id="byStandardId0" value="0">{"Ingen avisering"}</option>
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>*/}

                        <Row>
                            <Col sm={12}><br /></Col>
                        </Row>

                        <Row form>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <h4 style={{ color: "#007D40" }}>{t('MyLoginInformation')}</h4>
                                        </CardTitle>

                                        <Col sm={6}>
                                            <FormGroup>
                                                {/*<Row form>
                                                    <Col className="no-padding" sm={3}>
                                                        <Label for="" size="sm">{t('TimeOfLogin')}</Label>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col className="no-padding" sm={3}>
                                                        <Label className="standard-text" for="" size="sm">2 timmar, 20 minuter</Label>
                                                    </Col>
                                                </Row>*/}
                                                <Row form>
                                                    <Col className="no-padding" sm={3}>
                                                        <Label for="" size="sm">{t('User')}</Label>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col className="no-padding" sm={5}>
                                                        <Label className="standard-text" for="" size="sm">{this.props.information ? this.props.information.firstName : null} {this.props.information ? this.props.information.lastName : null} ({this.props.information.userName ? this.props.information.userName : null})</Label>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}><br /></Col>
                        </Row>

                        <Row form>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <h4 style={{ color: "#007D40" }}>{t('MyInformation')}</h4>
                                        </CardTitle>

                                        <RenderUserInformation
                                            userObject={this.props.information} />

                                        <Row form>
                                            <Col className="no-padding" sm={3}>
                                                <br />
                                                <Button onClick={(event) => this.onClickToggleEditModal(event)} color="primary">
                                                    {t('Edit')}
                                                </Button>
                                                <EditMyPageModal show={this.props.editModalOpen} toggleShow={this.props.toggleEditModal} />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}><br /></Col>
                        </Row>

                        {this.props.information.roles.findIndex(e => e == "Dekra" || e == "Admin") > -1 ?
                            null :
                            <Row form>
                                <Col sm={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>
                                                <h4 style={{ color: "#007D40" }}>{t('ContactInformationForDekra')}</h4>
                                            </CardTitle>
                                            <Row form>
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <h5 style={{ color: "#007D40" }}>{t('FacilitatorAtDekra')}</h5>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('Name')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.firstName : null} {this.props.information.dekraContact ? this.props.information.dekraContact.lastName : null}</Label>
                                                            </Col>
                                                        </Row>

                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('Title')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.title : null}</Label>
                                                            </Col>
                                                        </Row>

                                                        {/*<Row form>*/}
                                                        {/*    <Col className="no-padding" sm={3}>*/}
                                                        {/*        <Label for="" size="sm">{t('Company')}</Label>*/}
                                                        {/*    </Col>*/}
                                                        {/*</Row>*/}
                                                        {/*<Row form>*/}
                                                        {/*    <Col className="no-padding" sm={3}>*/}
                                                        {/*        <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact.assignedCompany}</Label>*/}
                                                        {/*    </Col>*/}
                                                        {/*</Row>*/}

                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('Email')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.email : null}</Label>
                                                            </Col>
                                                        </Row>

                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('PhoneDirect')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.phoneNumber : null}</Label>
                                                            </Col>
                                                        </Row>

                                                        {/*<Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('PhoneExchange')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.phoneExchangeNumber : null}</Label>
                                                            </Col>
                                                        </Row>
                                                        <br />*/}
                                                        <h4 style={{ color: "#007D40" }}>{t('VisitingAddress')}</h4>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('Street')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.visitingStreet : null}</Label>
                                                            </Col>
                                                        </Row>

                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('CityOfEmployment')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.visitingCity : null}</Label>
                                                            </Col>
                                                        </Row>

                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('Zipcode')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.visitingZipcode : null}</Label>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <h4 style={{ color: "#007D40" }}>{t('PostAddress')}</h4>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('Street')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.postStreet : null}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('MailingCity')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.postCity : null}</Label>
                                                            </Col>
                                                        </Row>

                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('Zipcode')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">{this.props.information.dekraContact ? this.props.information.dekraContact.postZipcode : null}</Label>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <h5 style={{ color: "#007D40" }}>{t('SupportDekraIndustrialAB')}</h5>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('PhoneExchange')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">+46 010-455 10 00</Label>
                                                            </Col>
                                                        </Row>

                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label for="" size="sm">{t('Email')}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col className="no-padding" sm={3}>
                                                                <Label className="standard-text" for="" size="sm">weldbase@dekra.com</Label>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>}
                        <Row>
                            <Col sm={12}><br /></Col>
                        </Row>

                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    loggedIn: state.login.loggedIn,
    editModalOpen: state.myPage.editModalOpen,
    information: state.myPage.information
})

export default connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    MyPageStore.actionCreators // Selects which action creators are merged into the component's props
)(MyPage as any);