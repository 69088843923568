﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { ParentMaterial } from './models/ParentMaterial';
import { GetIndexResponse } from './models/responses/GetIndexResponse';
import { GetJointPreparationResponse } from './models/responses/GetJointPreparationResponse';
import { GetJointTypeResponse } from './models/responses/GetJointTypeResponse';
import { GetMakeResponse } from './models/responses/GetMakeResponse';
import { GetRootProtectionGasResponse } from './models/responses/GetRootProtectionGasResponse';
import { GetRootSupportDetailsResponse } from './models/responses/GetRootSupportDetailsResponse';
import { GetShieldingGasResponse } from './models/responses/GetShieldingGasResponse';
import { GetStandardResponse } from './models/responses/GetStandardResponse';
import { GetWeldingMethodResponse } from './models/responses/GetWeldingMethodResponse';
import { GetWeldingPositionResponse } from './models/responses/GetWeldingPositionResponse';
import { WeldingData } from './models/WeldingData';
import { WeldingParameters } from './models/WeldingParameters';
import { WeldingParametersSettings } from './models/WeldingParametersSettings';
import { WeldingProcess } from './models/WeldingProcess';
import { GetArcTypeResponse } from './models/responses/GetArcTypeResponse';
import { GetBaseMaterialResponse } from './models/responses/GetBaseMaterialResponse';
import { GetCurrentTypeResponse } from './models/responses/GetCurrentTypeResponse';
import { AfterWelding } from './AfterWelding';
import { Temperature } from './models/Temperature';
import { OtherInformation } from './OtherInformation';
import { GetApplicationResponse } from './models/responses/GetApplicationResponse';
import { WpsRequest } from './models/requests/WpsRequest';
import { WpsResponse } from './models/responses/WpsResponse';
import { WpsTypeEnums } from './models/WpsTypeEnums';
import { RevisionActionType } from './models/RevisionActionType';
import { ControllerType } from './models/ControllerType';
import  authHeader  from './models/auth-header';
import EventBus  from './eventBus';
import { UpdateWeldingParametersRequest } from './models/requests/UpdateWeldingParametersRequest';
import { GetJointDesignResponse } from './models/responses/GetJointDesignResponse';
import { GetWeldSequenceResponse } from './models/responses/GetWeldSequenceResponse';
import { UpdateFilesRequest } from './models/requests/UpdateFilesRequest';
import { UpdateFilesResponse } from './models/responses/UpdateFilesResponse';
import HelpFunctions from './models/HelpFunctions';
import { ReceiveCurrentUser } from './NewsStore';
import { UserListObject } from './UserListObject';
import { CreateUserRequest } from './models/requests/CreateUserRequest';
import { GetWpsResponse } from './models/responses/GetWpsResponse';
import HelpRangeOfQualificationExamination from './models/HelpRangeOfQualificationExamination';
import { HeaderWps } from './HeaderWps';
import { GetExistingWpqrResponse } from './models/responses/GetExistingWpqrResponse';
import { ExistingWpqrData } from './models/ExistingWpqrData';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WPSFormEditStoreState {
    wpsId: number;
    selectedWpqrId: number;
    header: HeaderWps;
    standards: Array<GetStandardResponse>;
    parentMaterial: ParentMaterial;
    weldingData: WeldingData;
    selectedStandard: GetStandardResponse;
    weldingProcess: WeldingProcess;
    showJointDesignModal: boolean;
    weldingParametersSettingsSave: WeldingParametersSettings;
    weldingParameters: Array<WeldingParameters>;
    weldingParametersSettings: WeldingParametersSettings;
    showWeldingParametersSettingsModal: boolean;
    currentTypes: Array<GetCurrentTypeResponse>;
    indexes: Array<GetIndexResponse>;
    temperature: Temperature;
    afterWelding: AfterWelding;
    otherInformation: OtherInformation;
    updatingWps: boolean;
    jointDesign: GetJointDesignResponse;
    weldSequence: GetWeldSequenceResponse;
}

export interface WPSFormEditStore {
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetWPSsAction {
    type: 'WPSFORMEDIT_GET_WPSS';
}

interface SetWpqrAction {
    type: 'WPSFORMEDIT_SET_WPQR_ID';
    selectedWpqrId: number;
}

export interface SetHeaderAction {
    type: 'WPSFORMEDIT_SET_HEADER';
    header: HeaderWps;
}

export interface GetStandardsReceiveAction {
    type: 'WPSFORMEDIT_GET_STANDARDS_RECEIVE';
    standards: Array<GetStandardResponse>;
}

export interface GetStandardsRequestAction {
    type: 'WPSFORMEDIT_GET_STANDARDS_REQUEST';
}

export interface SetParentMaterialAction {
    type: 'WPSFORMEDIT_SET_PARENT_MATERIAL';
    parentMaterial: ParentMaterial;
}

export interface GetBaseMaterial2SearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_BASE_MATERIAL_2_SEARCH_RESULT_REQUEST';
}

export interface GetBaseMaterial1SearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_BASE_MATERIAL_1_SEARCH_RESULT_REQUEST';
}

export interface GetRootProtectionGasSearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_ROOT_PROTECTION_GAS_SEARCH_RESULT_REQUEST';
}

export interface SetWeldingDataAction {
    type: 'WPSFORMEDIT_SET_WELDING_DATA';
    weldingData: WeldingData;
}

export interface GetJointPreparationsRequestAction {
    type: 'WPSFORMEDIT_GET_JOINT_PREPARATIONS_REQUEST';
}

export interface GetJointTypesRequestAction {
    type: 'WPSFORMEDIT_GET_JOINT_TYPES_REQUEST';
}

export interface GetRootSupportDetailsSearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_ROOT_SUPPORT_DETAILS_SEARCH_RESULT_REQUEST';
}

export interface GetTackWeldSearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_TACKWELD_SEARCH_RESULT_REQUEST';
}

export interface GetWeldingPositionSearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_WELDING_POSITION_SEARCH_RESULT_REQUEST';
}

export interface SetWeldingProcessAction {
    type: 'WPSFORMEDIT_SET_WELDING_PROCESS';
    weldingProcess: WeldingProcess;
}

export interface GetMakeASearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_MAKE_A_SEARCH_RESULT_REQUEST';
}

export interface GetMakeBSearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_MAKE_B_SEARCH_RESULT_REQUEST';
}

export interface GetShieldingGasSearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_SHIELDING_GAS_SEARCH_RESULT_REQUEST';
}

export interface GetWeldingMethodBSearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_WELDING_METHOD_B_SEARCH_RESULT_REQUEST';
}

export interface GetWeldingMethodASearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_WELDING_METHOD_A_SEARCH_RESULT_REQUEST';
}

export interface GetArcTypeASearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_ARC_TYPE_A_SEARCH_RESULT_REQUEST';
}

export interface GetArcTypeBSearchResultRequestAction {
    type: 'WPSFORMEDIT_GET_ARC_TYPE_B_SEARCH_RESULT_REQUEST';
}

export interface ToggleJointDesignModalAction {
    type: 'WPSFORMEDIT_TOGGLE_JOINT_DESIGN_MODAL';
    showJointDesignModal: boolean;
}

export interface SetWeldingParametersAction {
    type: 'WPSFORMEDIT_SET_WELDING_PARAMETERS';
    weldingParameters: Array<WeldingParameters>;
}

//export interface SetWeldingParametersTestAction {
//    type: 'SET_WELDING_PARAMETERS';
//    weldingParameters: Array<WeldingParameters>;
//}

export interface InitPWPSAction {
    type: 'WPSFORMEDIT_INIT_PWPS';
    header: HeaderWps;
    weldingParameters: Array<WeldingParameters>;
}

export interface GetIndexesReceiveAction {
    type: 'WPSFORMEDIT_GET_INDEXES_RECEIVE';
    indexes: Array<GetIndexResponse>;
}

export interface GetIndexesRequestAction {
    type: 'WPSFORMEDIT_GET_INDEXES_REQUEST';
}

export interface GetCurrentTypeReceiveAction {
    type: 'WPSFORMEDIT_GET_CURRENT_TYPES_RECEIVE';
    currentTypes: Array<GetCurrentTypeResponse>;
}

export interface GetCurrentTypeRequestAction {
    type: 'WPSFORMEDIT_GET_CURRENT_TYPES_REQUEST';
}

export interface SetAfterWeldingAction {
    type: 'WPSFORMEDIT_SET_AFTER_WELDING';
    afterWelding: AfterWelding;
}

export interface SetTemperatureAction {
    type: 'WPSFORMEDIT_SET_TEMPERATURE';
    temperature: Temperature;
}

export interface SetOtherInformationAction {
    type: 'WPSFORMEDIT_SET_OTHER_INFORMATION';
    otherInformation: OtherInformation;
}

export interface SetCreateUpdateWpsReceiveAction {
    type: 'WPSFORMEDIT_SET_CREATE_UPATE_WPS_RECEIVE';
    wpsId: number;
}

export interface SetCreateUpdateWpsRequestAction {
    type: 'WPSFORMEDIT_SET_CREATE_UPATE_WPS_REQUEST';
}

export interface SetJointDesignReceiveAction {
    type: 'WPSFORMEDIT_SET_JOINT_DESIGN_RECEIVE';
    jointDesign: GetJointDesignResponse;
}

export interface SetWeldSequenceReceiveAction {
    type: 'WPSFORMEDIT_SET_WELD_SEQUENCE_RECEIVE';
    weldSequence: GetWeldSequenceResponse;
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetWPSsAction
    | SetWpqrAction
    | SetHeaderAction
    | GetStandardsReceiveAction
    | GetStandardsRequestAction
    | SetParentMaterialAction
    | GetBaseMaterial2SearchResultRequestAction
    | GetBaseMaterial1SearchResultRequestAction
    | SetWeldingDataAction
    | GetRootProtectionGasSearchResultRequestAction
    | GetJointPreparationsRequestAction
    | GetJointTypesRequestAction
    | GetRootSupportDetailsSearchResultRequestAction
    | GetTackWeldSearchResultRequestAction
    | GetWeldingPositionSearchResultRequestAction
    | SetWeldingProcessAction
    | GetMakeASearchResultRequestAction
    | GetMakeBSearchResultRequestAction
    | GetShieldingGasSearchResultRequestAction
    | GetWeldingMethodBSearchResultRequestAction
    | GetWeldingMethodASearchResultRequestAction
    | GetArcTypeASearchResultRequestAction
    | GetArcTypeBSearchResultRequestAction
    | ToggleJointDesignModalAction
    | SetWeldingParametersAction
    | InitPWPSAction
    | GetIndexesRequestAction
    | GetIndexesReceiveAction
    | GetCurrentTypeReceiveAction
    | GetCurrentTypeRequestAction
    | SetAfterWeldingAction
    | SetTemperatureAction
    | SetOtherInformationAction
    | SetCreateUpdateWpsReceiveAction
    | SetCreateUpdateWpsRequestAction
    | SetJointDesignReceiveAction
    | SetWeldSequenceReceiveAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getWPSs: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`wpss`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<Array<WPSFormEditStore>>)
            .then(data => {
                dispatch({ type: 'WPSFORMEDIT_GET_WPSS' });
            });
    },
    setSelectedWpqrId: (wpqrId: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'WPSFORMEDIT_SET_WPQR_ID', selectedWpqrId: wpqrId });
    },
    setHeaderDate: (date: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.date = date;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setHeaderLogotypeFileFormData: (logotypeFileFormData: FormData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            //header.logotypeFileFormData = logotypeFileFormData;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setHeaderLogotypeURL: (logotypeURL: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            //header.logotypeURL = logotypeURL;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setHeaderProject: (project: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.project = project;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setHeaderPWPSNumber: (pWPSNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.pwpsNumber = pWPSNumber;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setHeaderRevisionNumber: (revision: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.revisionNumber = revision;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setHeaderSelectedByStandard: (standardId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let standards: Array<GetStandardResponse> = [...appState.wpsFormEdit.standards];
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            let selectedStandard: GetStandardResponse = new GetStandardResponse();
            for (let i = 0; i < standards.length; i++) {
                if (standardId === standards[i].id) {
                    selectedStandard = { ...standards[i] };
                    break;
                }
            }
            header.selectedByStandard = { ...selectedStandard }
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setHeaderWPQRNumber: (wpqrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.wpqrNumber = wpqrNumber;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setHeaderWpsType: (wpsType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit && appState.login) {
            // Get ContactPerson and CompanyName
            let currentUser: UserListObject = { ...appState.login.currentUser };
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.wpsType = wpsType;
            header.manufacturer = currentUser === null ? "" : (currentUser.customer && currentUser.customer.name ? (currentUser.customer.name + ", ") : "") + (currentUser.firstName ? currentUser.firstName + " " : "") + (currentUser.lastName ? currentUser.lastName : "");
            header.examiner = "";
            header.employeeDisplayName = (currentUser.firstName ? currentUser.firstName + " " : "") + (currentUser.lastName ? currentUser.lastName : "")
            header.customerLogo = currentUser === null ? "" : (currentUser.customer && currentUser.customer.customerLogo ? currentUser.customer.customerLogo : "");
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    getCustomerLogo: (wpsId: number, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Wpss/GetCustomerLogoByWpsId/${wpsId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as GetWpsResponse;
                console.info(data);
                const appState = getState();
                if (appState && appState.wpsFormEdit) {
                    let header: HeaderWps = { ...appState.wpsFormEdit.header };
                    header.wpsType = data.wpsType;
                    header.manufacturer = "";
                    header.customerLogo = data.customerLogo;
                    dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
                    callback();
                }
            }))
            .catch(error => {
                console.error(error);
            });
    },
    setManufacturer: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.manufacturer = value;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setExaminer: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.examiner = value;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setHeaderShowWpsTypeModal: (showChooseWpsTypeModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.showChooseWpsTypeModal = showChooseWpsTypeModal;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setOtherOptionsParametersTableId: (otherOptionsParametersTableId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.otherOptionParametersTableId = otherOptionsParametersTableId;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    onClickModalOK: (isOpenModal: boolean, id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.wpsType = id === 0 ? WpsTypeEnums.PWPS : WpsTypeEnums.WPS
            header.revisionWpsAction = "";
            header.showChooseWpsTypeModal = isOpenModal;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    clearAndSetWpsAction: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let revisionAction = '';
        if (appState && appState.wpsTab) {
            revisionAction = appState.wpsTab.wpsRevision.selectedRevisionAction;
        }
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header = {
                date: '',
                project: '',
                selectedByStandard: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                revisionNumber: '',
                pwpsNumber: '',
                wpqrNumber: '',
                wpsType: '',
                wpsDekraNumber: '',
                wpsHeatInputText: '',
                wpsArcEnergyText: '',
                revisionWpsAction: revisionAction,
                showChooseWpsTypeModal: false,
                selectedWpsId: 0,
                otherOptions: [],
                otherOptionParametersTableId: 0,
                manufacturer: '',
                examiner: '',
                confirmedMessage: '',
                dekraContactEmail: '',
                successStatusCode: false,
                bodyTemplateHtml: '',
                imageUpload1: '',
                imageUpload2: '',
                imageValidationError1: '',
                imageValidationError2: '',
                selectedWeldingPosition2: false,
                customerLogo: '',
                employeeDisplayName: '',
                enableStandardText: false,
                standardText: ''
            }
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    clearAndSetWpsActionToView: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header = {
                date: '',
                project: '',
                selectedByStandard: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                revisionNumber: '',
                pwpsNumber: '',
                wpqrNumber: '',
                wpsType: '',
                wpsDekraNumber: '',
                wpsHeatInputText: '',
                wpsArcEnergyText: '',
                revisionWpsAction: RevisionActionType.View,
                showChooseWpsTypeModal: false,
                selectedWpsId: 0,
                otherOptions: [],
                otherOptionParametersTableId: 0,
                manufacturer: '',
                examiner: '',
                confirmedMessage: '',
                successStatusCode: false,
                dekraContactEmail: '',
                bodyTemplateHtml: '',
                imageUpload1: '',
                imageUpload2: '',
                imageValidationError1: '',
                imageValidationError2: '',
                selectedWeldingPosition2: false,
                customerLogo: '',
                employeeDisplayName: '',
                enableStandardText: false,
                standardText: ''
            }
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    clearWpsId: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let wpsId: number = appState.wpsFormEdit.wpsId;
            wpsId = 0;
            dispatch({ type: 'WPSFORMEDIT_SET_CREATE_UPATE_WPS_RECEIVE', wpsId: wpsId });
        }
    },
    setParentMaterialBaseMaterial1SearchText: (baseMaterial1SearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.baseMaterial1SearchText = baseMaterial1SearchText;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialBaseMaterial2SearchText: (baseMaterial2SearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.baseMaterial2SearchText = baseMaterial2SearchText;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialDiameter1: (diameter1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.diameter1 = diameter1;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialDiameter2: (diameter2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.diameter2 = diameter2;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialMaterialThickness1: (materialThickness1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.materialThickness1 = materialThickness1;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialMaterialThickness2: (materialThickness1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.materialThickness2 = materialThickness1;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialSelectedBaseMaterial1: (baseMaterial: GetBaseMaterialResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.baseMaterial1SearchText = baseMaterial.name + "/" + baseMaterial.number;
            parentMaterial.selectedBaseMaterial1 = { ...baseMaterial };
            parentMaterial.selectedMaterialGroup1 = { ...baseMaterial.materialGroup };
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialSelectedBaseMaterial2: (baseMaterial: GetBaseMaterialResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.baseMaterial2SearchText = baseMaterial.name + "/" + baseMaterial.number;
            parentMaterial.selectedBaseMaterial2 = { ...baseMaterial };
            parentMaterial.selectedMaterialGroup2 = { ...baseMaterial.materialGroup };
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialSelectedTechnicalDeliveryCondition1: (technicalDeliveryConditionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            for (let i = 0; i < parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions.length; i++) {
                if (technicalDeliveryConditionId === parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions[i].id) {
                    parentMaterial.selectedTechnicalDeliveryCondition1 = { ...parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions[i] };
                    break;
                }
            }
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialSelectedTechnicalDeliveryCondition2: (technicalDeliveryConditionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            for (let i = 0; i < parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions.length; i++) {
                if (technicalDeliveryConditionId === parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions[i].id) {
                    parentMaterial.selectedTechnicalDeliveryCondition2 = { ...parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions[i] };
                    break;
                }
            }
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    getBaseMaterial1SearchResult: (nameOrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/BaseMaterials/GetByStartsWithCaseInsensitiveNameOrNumberInclude/${nameOrNumber}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetBaseMaterialResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
                        parentMaterial.baseMaterial1SearchResult = [...data];
                        if (data.length === 0) {
                            // Reset selected Base Material 1.
                            parentMaterial.selectedBaseMaterial1.id = 0;
                            parentMaterial.selectedBaseMaterial1.name = '';
                            parentMaterial.selectedBaseMaterial1.number = '';
                            parentMaterial.selectedBaseMaterial1.description = '';
                            parentMaterial.selectedBaseMaterial1.materialGroup = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions = [];
                            parentMaterial.selectedBaseMaterial1.createdAt = '';
                            parentMaterial.selectedBaseMaterial1.deletedAt = '';
                            parentMaterial.selectedBaseMaterial1.updatedAt = '';
                            // Reset selected Material Group part 1.
                            parentMaterial.selectedMaterialGroup1.id = 0;
                            parentMaterial.selectedMaterialGroup1.name = '';
                            parentMaterial.selectedMaterialGroup1.description = '';
                            parentMaterial.selectedMaterialGroup1.createdAt = '';
                            parentMaterial.selectedMaterialGroup1.deletedAt = '';
                            parentMaterial.selectedMaterialGroup1.updatedAt = '';
                            // Reset selected TechnicalDeliveryCondition part 1.
                            parentMaterial.selectedTechnicalDeliveryCondition1.id = 0;
                            parentMaterial.selectedTechnicalDeliveryCondition1.name = '';
                            parentMaterial.selectedTechnicalDeliveryCondition1.description = '';
                            parentMaterial.selectedTechnicalDeliveryCondition1.createdAt = '';
                            parentMaterial.selectedTechnicalDeliveryCondition1.deletedAt = '';
                            parentMaterial.selectedTechnicalDeliveryCondition1.updatedAt = '';
                        }
                        dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
                    }

                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_BASE_MATERIAL_1_SEARCH_RESULT_REQUEST' });
    },
    getBaseMaterial2SearchResult: (nameOrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/BaseMaterials/GetByStartsWithCaseInsensitiveNameOrNumberInclude/${nameOrNumber}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetBaseMaterialResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
                        parentMaterial.baseMaterial2SearchResult = [...data];
                        if (data.length === 0) {
                            // Reset selected Base Material 2.
                            parentMaterial.selectedBaseMaterial2.id = 0;
                            parentMaterial.selectedBaseMaterial2.name = '';
                            parentMaterial.selectedBaseMaterial2.number = '';
                            parentMaterial.selectedBaseMaterial2.description = '';
                            parentMaterial.selectedBaseMaterial2.materialGroup = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions = [];
                            parentMaterial.selectedBaseMaterial2.createdAt = '';
                            parentMaterial.selectedBaseMaterial2.deletedAt = '';
                            parentMaterial.selectedBaseMaterial2.updatedAt = '';
                            // Reset selected Material Group part 2.
                            parentMaterial.selectedMaterialGroup2.id = 0;
                            parentMaterial.selectedMaterialGroup2.name = '';
                            parentMaterial.selectedMaterialGroup2.description = '';
                            parentMaterial.selectedMaterialGroup2.createdAt = '';
                            parentMaterial.selectedMaterialGroup2.deletedAt = '';
                            parentMaterial.selectedMaterialGroup2.updatedAt = '';
                            // Reset selected TechnicalDeliveryCondition part 2.
                            parentMaterial.selectedTechnicalDeliveryCondition2.id = 0;
                            parentMaterial.selectedTechnicalDeliveryCondition2.name = '';
                            parentMaterial.selectedTechnicalDeliveryCondition2.description = '';
                            parentMaterial.selectedTechnicalDeliveryCondition2.createdAt = '';
                            parentMaterial.selectedTechnicalDeliveryCondition2.deletedAt = '';
                            parentMaterial.selectedTechnicalDeliveryCondition2.updatedAt = '';
                        }
                        dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_BASE_MATERIAL_2_SEARCH_RESULT_REQUEST' });
    },
    clearBaseMaterial1SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.baseMaterial1SearchResult = [];
            // Reset selected Base Material 1.
            parentMaterial.selectedBaseMaterial1.id = 0;
            parentMaterial.selectedBaseMaterial1.name = '';
            parentMaterial.selectedBaseMaterial1.number = '';
            parentMaterial.selectedBaseMaterial1.description = '';
            parentMaterial.selectedBaseMaterial1.materialGroup = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions = [];
            parentMaterial.selectedBaseMaterial1.createdAt = '';
            parentMaterial.selectedBaseMaterial1.deletedAt = '';
            parentMaterial.selectedBaseMaterial1.updatedAt = '';
            // Reset selected Material Group part 1.
            parentMaterial.selectedMaterialGroup1.id = 0;
            parentMaterial.selectedMaterialGroup1.name = '';
            parentMaterial.selectedMaterialGroup1.description = '';
            parentMaterial.selectedMaterialGroup1.createdAt = '';
            parentMaterial.selectedMaterialGroup1.deletedAt = '';
            parentMaterial.selectedMaterialGroup1.updatedAt = '';
            // Reset selected TechnicalDeliveryCondition part 1.
            parentMaterial.selectedTechnicalDeliveryCondition1.id = 0;
            parentMaterial.selectedTechnicalDeliveryCondition1.name = '';
            parentMaterial.selectedTechnicalDeliveryCondition1.description = '';
            parentMaterial.selectedTechnicalDeliveryCondition1.createdAt = '';
            parentMaterial.selectedTechnicalDeliveryCondition1.deletedAt = '';
            parentMaterial.selectedTechnicalDeliveryCondition1.updatedAt = '';
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    clearBaseMaterial2SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.baseMaterial2SearchResult = [];
            // Reset selected Base Material 2.
            parentMaterial.selectedBaseMaterial2.id = 0;
            parentMaterial.selectedBaseMaterial2.name = '';
            parentMaterial.selectedBaseMaterial2.number = '';
            parentMaterial.selectedBaseMaterial2.description = '';
            parentMaterial.selectedBaseMaterial2.materialGroup = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions = [];
            parentMaterial.selectedBaseMaterial2.createdAt = '';
            parentMaterial.selectedBaseMaterial2.deletedAt = '';
            parentMaterial.selectedBaseMaterial2.updatedAt = '';
            // Reset selected Material Group part 2.
            parentMaterial.selectedMaterialGroup2.id = 0;
            parentMaterial.selectedMaterialGroup2.name = '';
            parentMaterial.selectedMaterialGroup2.description = '';
            parentMaterial.selectedMaterialGroup2.createdAt = '';
            parentMaterial.selectedMaterialGroup2.deletedAt = '';
            parentMaterial.selectedMaterialGroup2.updatedAt = '';
            // Reset selected TechnicalDeliveryCondition part 2.
            parentMaterial.selectedTechnicalDeliveryCondition2.id = 0;
            parentMaterial.selectedTechnicalDeliveryCondition2.name = '';
            parentMaterial.selectedTechnicalDeliveryCondition2.description = '';
            parentMaterial.selectedTechnicalDeliveryCondition2.createdAt = '';
            parentMaterial.selectedTechnicalDeliveryCondition2.deletedAt = '';
            parentMaterial.selectedTechnicalDeliveryCondition2.updatedAt = '';
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    clearParentMaterial: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial = {
                baseMaterial1SearchText: '',
                baseMaterial1SearchResult: [],
                selectedBaseMaterial1: {
                    id: 0,
                    name: '',
                    number: '',
                    description: '',
                    materialGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    technicalDeliveryConditions: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                baseMaterial2SearchText: '',
                baseMaterial2SearchResult: [],
                selectedBaseMaterial2: {
                    id: 0,
                    name: '',
                    number: '',
                    description: '',
                    materialGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    technicalDeliveryConditions: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedMaterialGroup1: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedMaterialGroup2: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedTechnicalDeliveryCondition1: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedTechnicalDeliveryCondition2: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                diameter1: '',
                diameter2: '',
                materialThickness1: '',
                materialThickness2: '',
                enableBaseMaterial1Text: false,
                enableBaseMaterial2Text: false,
                baseMaterial1Text: '',
                baseMaterial2Text: '',
                materialGroup1Text: '',
                materialGroup2Text: '',
                technicalDeliveryCondition1Text: '',
                technicalDeliveryCondition2Text: '',
                enableMaterialThickness: false,
                materialThickness1Text: '',
                materialThickness2Text: '',
                enableDiameter: false,
                diameter1Text: '',
                diameter2Text: '',
            };
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setWeldingDataLengthTackWelds: (lengthTackWelds: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.lengthTackWelds = lengthTackWelds;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataElectrodeTorchAngle111: (electrodeTorchAngle111: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.electrodeTorchAngle111 = electrodeTorchAngle111;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedPlasmaGas: (selectedPlasmasGas: GetRootProtectionGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.selectedPlasmaGas = { ...selectedPlasmasGas };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataPlasmaflowrate: (plasmaflowrate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.plasmaflowrate = plasmaflowrate;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataMaxWidthOfRun: (maxWidthOfRun: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.maxWidthOfRun = maxWidthOfRun;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataNozzleDiameter: (nozzleDiameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.nozzleDiameter = nozzleDiameter;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataRootProtectionGasSearchText: (rootProtectionGasSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.rootProtectionGasSearchText = rootProtectionGasSearchText;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedJointType: (jointTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            let selectedJointType: GetJointTypeResponse = new GetJointTypeResponse();
            for (var i = 0; i < weldingData.jointTypes.length; i++) {
                if (jointTypeId === weldingData.jointTypes[i].id) {
                    selectedJointType = { ...weldingData.jointTypes[i] };
                    break;
                }
            }
            weldingData.selectedJointType = { ...selectedJointType }
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedRootProtectionGas: (selectedRootProtectionGas: GetRootProtectionGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.rootProtectionGasSearchText = selectedRootProtectionGas.name;
            weldingData.selectedRootProtectionGas = { ...selectedRootProtectionGas };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedRootSupportDetails: (selectedRootSupportDetailId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            for (var i = 0; i < weldingData.rootSupportDetailsSearchResult.length; i++) {
                if (selectedRootSupportDetailId === weldingData.rootSupportDetailsSearchResult[i].id) {
                    weldingData.selectedRootSupportDetails = { ...weldingData.rootSupportDetailsSearchResult[i] };
                    break;
                }
            }
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedTackWeld: (selectedTackWeldText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.tackWeldText = selectedTackWeldText;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataStickOut: (stickOut: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.stickOut = stickOut;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataTungstenElectrode: (tungstenElectrode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.tungstenElectrode = tungstenElectrode;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataElectrodeTorchAngle: (electrodeTorchAngle: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.electrodeTorchAngle = electrodeTorchAngle;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataWeldingPositionSearchText: (weldPositionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            for (var i = 0; i < weldingData.weldingPositionSearchResult.length; i++) {
                if (weldPositionId === weldingData.weldingPositionSearchResult[i].id) {
                    weldingData.selectedWeldingPosition = { ...weldingData.weldingPositionSearchResult[i] };
                    break;
                }
            }
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setOnchangeJointPreparationText: (SelectJointPreparation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.selectedJointPreparationText = SelectJointPreparation
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    clearJointPreparationText: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.selectedJointPreparationText = ""
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setJointPreparationText: (SelectJointPreparation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.selectedJointPreparationText = weldingData.selectedJointPreparationText === "" ? SelectJointPreparation : weldingData.selectedJointPreparationText + ", " + SelectJointPreparation;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataWeldingPosition2SearchText: (weldPosition2Id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            let selectedWeldingPosition2: GetWeldingPositionResponse = new GetWeldingPositionResponse();
            for (var i = 0; i < weldingData.weldingPositionSearchResult.length; i++) {
                if (weldPosition2Id === weldingData.weldingPositionSearchResult[i].id) {
                    selectedWeldingPosition2 = { ...weldingData.weldingPositionSearchResult[i] };
                    break;
                }
            }
            weldingData.selectedWeldingPosition2 = { ...selectedWeldingPosition2 };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    clearRootProtectionGasSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.rootProtectionGasSearchResult = [];
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    cleaWeldingData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData = {
                jointTypes: [],
                selectedJointType: {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                maxWidthOfRun: '',
                rootSupportDetailsSearchResult: [],
                selectedRootSupportDetails: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                weldingPositionSearchResult: [],
                selectedWeldingPosition: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedWeldingPosition2: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                lengthTackWelds: '',
                tungstenElectrode: '',
                electrodeTorchAngle: '',
                nozzleDiameter: '',
                rootProtectionGasSearchText: '',
                rootProtectionGasSearchResult: [],
                selectedRootProtectionGas: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                stickOut: '',
                jointPreparations: [],
                selectedJointPreparationText: '',
                tackWeldText: '',
                electrodeTorchAngle111: '',
                selectedPlasmaGas: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                },
                plasmaflowrate: '',
                enableJointTypeText: false,
                jointTypeText: '',
            };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingProcessArcTypeASearchText: (arcTypeAId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            let selectedArcTypeA: GetArcTypeResponse = new GetArcTypeResponse();
            for (var i = 0; i < weldingProcess.arcTypeSearchResult.length; i++) {
                if (arcTypeAId === weldingProcess.arcTypeSearchResult[i].id) {
                    selectedArcTypeA = { ...weldingProcess.arcTypeSearchResult[i] };
                    break;
                }
            }
            weldingProcess.selectedArcTypeA = { ...selectedArcTypeA };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessArcTypeBSearchText: (arcTypeBId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            let selectedArcTypeB: GetArcTypeResponse = new GetArcTypeResponse();
            for (var i = 0; i < weldingProcess.arcTypeSearchResult.length; i++) {
                if (arcTypeBId === weldingProcess.arcTypeSearchResult[i].id) {
                    selectedArcTypeB = { ...weldingProcess.arcTypeSearchResult[i] };
                    break;
                }
            }
            weldingProcess.selectedArcTypeB = { ...selectedArcTypeB };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessMakeASearchText: (makeASearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.makeASearchText = makeASearchText;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessMakeBSearchText: (makeBSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.makeBSearchText = makeBSearchText;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedMakeA: (selectedMakeA: GetMakeResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.makeASearchText = selectedMakeA.name;
            weldingProcess.selectedMakeA = { ...selectedMakeA };
            weldingProcess.selectedDesignationA = { ...weldingProcess.selectedMakeA.designation };
            weldingProcess.selectedFMGroupA = { ...weldingProcess.selectedMakeA.fmGroup };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedMakeB: (selectedMakeB: GetMakeResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.makeBSearchText = selectedMakeB.name;
            weldingProcess.selectedMakeB = { ...selectedMakeB };
            weldingProcess.selectedDesignationB = { ...weldingProcess.selectedMakeB.designation };
            weldingProcess.selectedFMGroupB = { ...weldingProcess.selectedMakeB.fmGroup };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedShieldingGasA: (shieldingGas: GetShieldingGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.shieldingGasASearchText = shieldingGas.name;
            weldingProcess.selectedShieldingGasA = { ...shieldingGas };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedShieldingGasB: (shieldingGas: GetShieldingGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.shieldingGasBSearchText = shieldingGas.name;
            weldingProcess.selectedShieldingGasB = { ...shieldingGas };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedWeldingMethodA: (weldingMethod: GetWeldingMethodResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            // Remove the old selected WeldingMethod and add the new selected WeldingMethod in the Dropdown list.
            let removeIndex = weldingProcess.weldingMethods.findIndex(weldingMethod1 => weldingMethod1.id !== 0 && weldingMethod1.id === weldingProcess.selectedWeldingMethodA.id);
            if (removeIndex !== -1) {
                weldingProcess.weldingMethods.splice(removeIndex, 1);
            }
            weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, weldingMethod);
            weldingProcess.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
                if (weldingMethod1.name < weldingMethod2.name) {
                    return -1;
                }
                else if (weldingMethod1.name > weldingMethod2.name) {
                    return 1;
                }
                return 0;
            });
            weldingProcess.weldingMethodASearchText = weldingMethod.name;
            weldingProcess.selectedWeldingMethodA = { ...weldingMethod };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedWeldingMethodB: (weldingMethod: GetWeldingMethodResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            // Remove the old selected WeldingMethod and add the new selected WeldingMethod in the Dropdown list.
            let removeIndex = weldingProcess.weldingMethods.findIndex(weldingMethod2 => weldingMethod2.id !== 0 && weldingMethod2.id === weldingProcess.selectedWeldingMethodB.id);
            if (removeIndex !== -1) {
                weldingProcess.weldingMethods.splice(removeIndex, 1);
            }
            weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, weldingMethod);
            weldingProcess.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
                if (weldingMethod1.name < weldingMethod2.name) {
                    return -1;
                }
                else if (weldingMethod1.name > weldingMethod2.name) {
                    return 1;
                }
                return 0;
            });
            weldingProcess.weldingMethodBSearchText = weldingMethod.name;
            weldingProcess.selectedWeldingMethodB = { ...weldingMethod };
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessShieldingGasASearchText: (shieldingGasASearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.shieldingGasASearchText = shieldingGasASearchText;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessShieldingGasBSearchText: (shieldingGasBSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.shieldingGasBSearchText = shieldingGasBSearchText;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessWeldingMethodASearchText: (weldingMethodASearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.weldingMethodASearchText = weldingMethodASearchText;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessWeldingMethodBSearchText: (weldingMethodBSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.weldingMethodBSearchText = weldingMethodBSearchText;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearMakeASearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.makeASearchResult = [];
            // Reset selected Designation A.
            weldingProcess.selectedDesignationA.id = 0;
            weldingProcess.selectedDesignationA.name = '';
            weldingProcess.selectedDesignationA.description = '';
            // Reset selected FM Group A.
            weldingProcess.selectedFMGroupA.id = 0;
            weldingProcess.selectedFMGroupA.name = '';
            weldingProcess.selectedFMGroupA.description = '';
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearMakeBSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.makeBSearchResult = [];
            // Reset selected Designation B.
            weldingProcess.selectedDesignationB.id = 0;
            weldingProcess.selectedDesignationB.name = '';
            weldingProcess.selectedDesignationB.description = '';
            // Reset selected FM Group B.
            weldingProcess.selectedFMGroupB.id = 0;
            weldingProcess.selectedFMGroupB.name = '';
            weldingProcess.selectedFMGroupB.description = '';
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearShieldingGasASearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.shieldingGasASearchResult = [];
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearShieldingGasBSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.shieldingGasBSearchResult = [];
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearWeldingMethodASearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.weldingMethodASearchResult = [];
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearWeldingMethodBSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.weldingMethodBSearchResult = [];
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearWeldingParameters: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wpsFormEdit.weldingParameters];
            weldingParameters = [];
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    clearTemperature: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let temperature: Temperature = { ...appState.wpsFormEdit.temperature };
            temperature.preheatTemperature = "";
            temperature.maxInterpassTemperature = "";
            dispatch({ type: 'WPSFORMEDIT_SET_TEMPERATURE', temperature: temperature });
        }
    },
    clearAfterWelding: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let afterWelding: AfterWelding = { ...appState.wpsFormEdit.afterWelding };
            afterWelding.heatingRate = "";
            afterWelding.coolingRate = "";
            dispatch({ type: 'WPSFORMEDIT_SET_AFTER_WELDING', afterWelding: afterWelding });
        }
    },
    clearOtherInformation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let otherInformation_: OtherInformation = { ...appState.wpsFormEdit.otherInformation };
            otherInformation_.otherInformation = "";
            dispatch({ type: 'WPSFORMEDIT_SET_OTHER_INFORMATION', otherInformation: otherInformation_ });
        }
    },
    clearWeldingProcess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess = {
                weldingMethods: [],
                weldingMethodASearchText: '',
                weldingMethodASearchResult: [],
                selectedWeldingMethodA: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                makeASearchText: '',
                makeASearchResult: [],
                selectedMakeA: {
                    id: 0,
                    name: '',
                    description: '',
                    designation: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    fmGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedDesignationA: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedFMGroupA: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                shieldingGasASearchText: '',
                shieldingGasASearchResult: [],
                selectedShieldingGasA: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                arcTypeSearchResult: [],
                selectedArcTypeA: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                weldingMethodBSearchText: '',
                weldingMethodBSearchResult: [],
                selectedWeldingMethodB: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                makeBSearchText: '',
                makeBSearchResult: [],
                selectedMakeB: {
                    id: 0,
                    name: '',
                    description: '',
                    designation: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    fmGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedDesignationB: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedFMGroupB: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                shieldingGasBSearchText: '',
                shieldingGasBSearchResult: [],
                selectedShieldingGasB: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                selectedArcTypeB: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                enableMakeAText: false,
                makeAText: '',
                designationAText: '',
                fmGroupAText: '',
                enableMakeBText: false,
                makeBText: '',
                designationBText: '',
                fmGroupBText: '',
                typeOfBackingText: '',
            };
            let weldingMethodEmpty = {
                id: 0,
                name: '',
                efficiencyFactor: 0.0,
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, weldingMethodEmpty);
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    toggleShowJointDesignModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let showJointDesignModal = appState.wpsFormEdit.showJointDesignModal;
            dispatch({ type: 'WPSFORMEDIT_TOGGLE_JOINT_DESIGN_MODAL', showJointDesignModal: !showJointDesignModal });
        }
    },
    setParentMaterialAutoFilling: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.selectedBaseMaterial2 = { ...parentMaterial.selectedBaseMaterial1 };
            parentMaterial.baseMaterial2SearchText = parentMaterial.baseMaterial1SearchText;
            parentMaterial.selectedMaterialGroup2 = { ...parentMaterial.selectedMaterialGroup1 };
            parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions = [...parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions];
            parentMaterial.selectedTechnicalDeliveryCondition2 = { ...parentMaterial.selectedTechnicalDeliveryCondition1 }
            parentMaterial.materialThickness2 = parentMaterial.materialThickness1;
            parentMaterial.diameter2 = parentMaterial.diameter1;
            parentMaterial.enableBaseMaterial2Text = parentMaterial.enableBaseMaterial1Text;
            parentMaterial.baseMaterial2Text = parentMaterial.baseMaterial1Text;
            parentMaterial.materialGroup2Text = parentMaterial.materialGroup1Text;
            parentMaterial.technicalDeliveryCondition2Text = parentMaterial.technicalDeliveryCondition1Text;
            parentMaterial.materialThickness2Text = parentMaterial.materialThickness1Text;
            parentMaterial.diameter2Text = parentMaterial.diameter1Text;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    initPWPS: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            // Initialize HeaderWps.
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.selectedByStandard = { ...appState.wpsFormEdit.selectedStandard };
            // Initialize WeldingParameters.
            let weldingParameters: Array<WeldingParameters> = [...appState.wpsFormEdit.weldingParameters];
            if (weldingParameters.length === 0) {
                let weldingParametersRow = new WeldingParameters();
                weldingParametersRow.run = '1';
                weldingParametersRow.runText = '1';
                weldingParameters.splice(weldingParameters.length, 0, weldingParametersRow);
            }
            dispatch({ type: 'WPSFORMEDIT_INIT_PWPS', header: header, weldingParameters: weldingParameters });
        }
    },
    setAfterWeldingCoolingRate: (coolingRate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let afterWelding: AfterWelding = { ...appState.wpsFormEdit.afterWelding };
            afterWelding.coolingRate = coolingRate;
            dispatch({ type: 'WPSFORMEDIT_SET_AFTER_WELDING', afterWelding: afterWelding });
        }
    },
    setAfterWeldingHeatingRate: (heatingRate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let afterWelding: AfterWelding = { ...appState.wpsFormEdit.afterWelding };
            afterWelding.heatingRate = heatingRate;
            dispatch({ type: 'WPSFORMEDIT_SET_AFTER_WELDING', afterWelding: afterWelding });
        }
    },
    setTemperatureMaxInterpassTemperature: (maxInterpassTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let temperature: Temperature = { ...appState.wpsFormEdit.temperature };
            temperature.maxInterpassTemperature = maxInterpassTemperature;
            dispatch({ type: 'WPSFORMEDIT_SET_TEMPERATURE', temperature: temperature });
        }
    },
    setTemperaturePreheatTemperature: (preheatTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let temperature: Temperature = { ...appState.wpsFormEdit.temperature };
            temperature.preheatTemperature = preheatTemperature;
            dispatch({ type: 'WPSFORMEDIT_SET_TEMPERATURE', temperature: temperature });
        }
    },
    setOtherInformationOtherInformation: (otherInformation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let otherInformation_: OtherInformation = { ...appState.wpsFormEdit.otherInformation };
            otherInformation_.otherInformation = otherInformation;
            dispatch({ type: 'WPSFORMEDIT_SET_OTHER_INFORMATION', otherInformation: otherInformation_ });
        }
    },
    getRootProtectionGasSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RootProtectionGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetRootProtectionGasResponse>;
                console.info(data);
                const appState = getState();
                if (appState && appState.wpsFormEdit) {
                    let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
                    weldingData.rootProtectionGasSearchResult = [...data];
                    dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
                }


            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_ROOT_PROTECTION_GAS_SEARCH_RESULT_REQUEST' });
    },
    getStandardsAll: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Standards/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetStandardResponse>;
                    console.info(data);
                    dispatch({ type: 'WPSFORMEDIT_GET_STANDARDS_RECEIVE', standards: data });


                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_STANDARDS_REQUEST' });
    },
    getJointPreparationsAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/JointPreparations/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetJointPreparationResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
                        weldingData.jointPreparations = [...data];
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_JOINT_PREPARATIONS_REQUEST' });
    },
    getJointTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/JointTypes/GetAllInclude`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetJointTypeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
                        weldingData.jointTypes = [...data];
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
                    }

                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_JOINT_TYPES_REQUEST' });
    },
    getRootSupportDetailsSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RootSupportDetails/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetRootSupportDetailsResponse>;

                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
                        weldingData.rootSupportDetailsSearchResult = [...data];
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_ROOT_SUPPORT_DETAILS_SEARCH_RESULT_REQUEST' });
    },
    getWeldingPositionSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingPositions/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingPositionResponse>;

                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
                        weldingData.weldingPositionSearchResult = [];
                        for (let i = 0; i < [...data].length; i++) {
                            if ([...data][i].description === "") {
                                weldingData.weldingPositionSearchResult.push([...data][i]);
                            }
                        }
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_WELDING_POSITION_SEARCH_RESULT_REQUEST' });
    },
    getMakeASearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Makes/GetByStartsWithCaseInsensitiveNameInclude/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetMakeResponse>;

                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
                        weldingProcess.makeASearchResult = [...data];
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_MAKE_A_SEARCH_RESULT_REQUEST' });
    },
    getMakeBSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Makes/GetByStartsWithCaseInsensitiveNameInclude/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetMakeResponse>;

                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
                        weldingProcess.makeBSearchResult = [...data];
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_MAKE_B_SEARCH_RESULT_REQUEST' });
    },
    getShieldingGasASearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ShieldingGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetShieldingGasResponse>;

                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
                        weldingProcess.shieldingGasASearchResult = [...data];
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_SHIELDING_GAS_SEARCH_RESULT_REQUEST' });
    },
    getShieldingGasBSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ShieldingGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetShieldingGasResponse>;

                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
                        weldingProcess.shieldingGasBSearchResult = [...data];
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_SHIELDING_GAS_SEARCH_RESULT_REQUEST' });
    },
    initExistingWpqr: (existingWpqrId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ExistingWpqrs/GetByIdInclude/${existingWpqrId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                console.log('init existing wpqr');
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as GetExistingWpqrResponse;
                console.info(data);
                let selectedExistingWpqr: GetExistingWpqrResponse = { ...res.data };
                console.log(selectedExistingWpqr);
                const appState = getState();
                if (appState && appState.wpsFormEdit) {
                    let header: HeaderWps = { ...appState.wpsFormEdit.header };
                    header.wpqrNumber = selectedExistingWpqr.existingWpqrData.manufacturerWPQRNumber;
                    header.selectedByStandard.id = 0;
                    header.selectedByStandard.name = "";
                    // Standard and standardText
                    header.enableStandardText = true;
                    header.standardText = selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.standardText;
                    header.wpsDekraNumber = selectedExistingWpqr.existingWpqrData.dekraProcedureNumber;
                    header.wpsHeatInputText = "";
                    header.wpsArcEnergyText = "";
                    header.selectedWpsId = selectedExistingWpqr.id;
                    header.revisionWpsAction = "CreatedFromExistingWpqr";
                    header.pwpsNumber = "";
                    header.otherOptions = [];
                    header.otherOptionParametersTableId = 0;
                    header.date = "";
                    header.project = "";
                    header.revisionNumber = "";
                    header.examiner = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.examinerName;
                    header.manufacturer = HelpFunctions.joinTwoStringWithComma(selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.manufacturer2, selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.manufacturer2Name);
                    dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });

                    // BaseMaterial1.
                    let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
                    parentMaterial.baseMaterial1SearchText = '';
                    parentMaterial.baseMaterial1SearchResult = [];
                    parentMaterial.selectedBaseMaterial1 = {
                        id: 0,
                        name: '',
                        number: '',
                        description: '',
                        materialGroup: {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        },
                        technicalDeliveryConditions: [],
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    parentMaterial.selectedTechnicalDeliveryCondition1 = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    }
                    // MaterialThickness1.
                    //let { result1, result2 } = HelpRangeOfQualificationExamination.GetValueRangeOfQualificationMaterialThickness(selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.materialThicknesses)
                    parentMaterial.materialThickness1 = selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.materialThicknesses;

                    // Diameter1.
                    parentMaterial.diameter1 = selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.diameterText;

                    // BaseMaterial2.
                    parentMaterial.baseMaterial2SearchText = '';
                    parentMaterial.baseMaterial2SearchResult = [];
                    parentMaterial.selectedBaseMaterial2 = {
                        id: 0,
                        name: '',
                        number: '',
                        description: '',
                        materialGroup: {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        },
                        technicalDeliveryConditions: [],
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    parentMaterial.selectedTechnicalDeliveryCondition2 = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    }

                    // MaterialThickness2.
                    parentMaterial.materialThickness2 = selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.materialThicknesses;
                    console.log('material thickness2', parentMaterial.materialThickness2)

                    // Diameter2.
                    parentMaterial.diameter2 = parentMaterial.diameter1;

                    parentMaterial.enableBaseMaterial1Text = true;
                    parentMaterial.enableBaseMaterial2Text =true;

                    parentMaterial.baseMaterial1Text = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.parentMaterialNameOrNumber;
                    parentMaterial.baseMaterial2Text = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.parentMaterialNameOrNumber;

                    let result1MaterialGroup = "";
                    if (selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.materialGroupText) {
                        result1MaterialGroup = HelpRangeOfQualificationExamination.GetValueRangeOfQualificationMaterialGroup(selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.materialGroupText)
                    }
                    parentMaterial.materialGroup1Text = result1MaterialGroup;
                    parentMaterial.materialGroup2Text = result1MaterialGroup;
                    parentMaterial.technicalDeliveryCondition1Text = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.technicalDeliveryStandard;
                    parentMaterial.technicalDeliveryCondition2Text = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.technicalDeliveryStandard;
                    parentMaterial.enableMaterialThickness = true;
                    parentMaterial.enableDiameter = true;

                    dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });

                    let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
                    // SelectedJointPreparation
                    weldingData.selectedJointPreparationText = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.methodOfPreparationAndCleaning;

                    // JointType and jointTypeText
                    weldingData.enableJointTypeText = true;
                    weldingData.jointTypeText = HelpRangeOfQualificationExamination.GetValueRangeOfQualificationJointType(selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.jointType);

                    weldingData.selectedJointType = {
                        id: 0,
                        name: '',
                        description: '',
                        jointShapes: [],
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };

                    weldingData.maxWidthOfRun = "";

                    // RootSupportDetails.
                    weldingData.selectedRootSupportDetails = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };

                    // TackWeld.
                    weldingData.tackWeldText = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.tackWeldText;
                    // WeldingPosition.
                    weldingData.selectedWeldingPosition = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    // WeldingPosition2.
                    weldingData.selectedWeldingPosition2 = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    weldingData.lengthTackWelds = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.lengthTackWelds;
                    weldingData.tungstenElectrode = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.tungstenElectrode;
                    weldingData.electrodeTorchAngle = "";
                    weldingData.nozzleDiameter = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.nozzleDiameter;
                    // RootProtectionGas.
                    weldingData.rootProtectionGasSearchText = '';
                    weldingData.rootProtectionGasSearchResult = [];
                    weldingData.selectedRootProtectionGas = {
                        id: 0,
                        name: '',
                        content: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    // PlasmaGas.
                    weldingData.selectedPlasmaGas = {
                        id: 0,
                        name: '',
                        content: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    //
                    weldingData.electrodeTorchAngle111 = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.electrodeTorchAngle111;
                    weldingData.plasmaflowrate = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.plasmaflowrate;
                    weldingData.stickOut = "";
                    dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });

                    // WeldingMethod1.
                    let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
                    weldingProcess.weldingMethodASearchText = '';
                    weldingProcess.weldingMethodASearchResult = [];
                    weldingProcess.selectedWeldingMethodA = {
                        id: 0,
                        name: '',
                        efficiencyFactor: 0.0,
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };

                    // Make1.
                    weldingProcess.makeASearchText = '';
                    weldingProcess.makeASearchResult = [];
                    weldingProcess.selectedMakeA = {
                        id: 0,
                        name: '',
                        description: '',
                        designation: {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        },
                        fmGroup: {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        },
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    weldingProcess.selectedDesignationA = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    weldingProcess.selectedFMGroupA = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    // shieldingGas1
                    weldingProcess.shieldingGasASearchText = '';
                    weldingProcess.shieldingGasASearchResult = [];
                    weldingProcess.selectedShieldingGasA = {
                        id: 0,
                        name: '',
                        content: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    // arcType1.
                    weldingProcess.selectedArcTypeA = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    // WeldingMethod2.
                    weldingProcess.weldingMethodBSearchText = '';
                    weldingProcess.weldingMethodBSearchResult = [];
                    weldingProcess.selectedWeldingMethodB = {
                        id: 0,
                        name: '',
                        efficiencyFactor: 0.0,
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    // Make2.
                    weldingProcess.makeBSearchText = '';
                    weldingProcess.makeBSearchResult = [];
                    weldingProcess.selectedMakeB = {
                        id: 0,
                        name: '',
                        description: '',
                        designation: {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        },
                        fmGroup: {
                            id: 0,
                            name: '',
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        },
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    weldingProcess.selectedDesignationB = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    weldingProcess.selectedFMGroupB = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    // ShieldingGas2.
                    weldingProcess.shieldingGasBSearchText = '';
                    weldingProcess.shieldingGasBSearchResult = [];
                    weldingProcess.selectedShieldingGasB = {
                        id: 0,
                        name: '',
                        content: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };

                    // arcType2.
                    weldingProcess.selectedArcTypeB = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };

                    let result1Value = ""; let result2Value = "";
                    if (selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.designations !== null) {
                        let { result1, result2 } = HelpRangeOfQualificationExamination.GetValueRangeOfQualification(selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.designations)
                        result1Value = result1;
                        result2Value = result2;
                    }

                    let result1Make = ""; let result2Make = "";
                    if (selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.makes !== null) {
                        let { result1, result2 } = HelpRangeOfQualificationExamination.GetValueRangeOfQualification(selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse.makes)
                        result1Make = result1;
                        result2Make = result2;
                    }

                    weldingProcess.enableMakeAText = true;
                    weldingProcess.makeAText = result1Make;
                    weldingProcess.designationAText = result1Value;
                    weldingProcess.fmGroupAText = "";
                    weldingProcess.enableMakeBText = true
                    weldingProcess.makeBText = result2Make;
                    weldingProcess.designationBText = result2Value;
                    weldingProcess.fmGroupBText = "";
                    weldingProcess.typeOfBackingText = "";

                    // WeldingMethods
                    weldingProcess.weldingMethods = [];
                    let weldingMethodEmpty = {
                        id: 0,
                        name: '',
                        efficiencyFactor: 0.0,
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, weldingMethodEmpty);
                    //weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, selectedApplication.pwps.weldingProcess.weldingMethod1);
                    //weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, selectedApplication.pwps.weldingProcess.weldingMethod2);
                    //weldingProcess.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
                    //    if (weldingMethod1.name < weldingMethod2.name) {
                    //        return -1;
                    //    }
                    //    else if (weldingMethod1.name > weldingMethod2.name) {
                    //        return 1;
                    //    }
                    //    return 0;
                    //});

                    dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });

                    //weldingParameters
                    let weldingParameters: Array<WeldingParameters> = [...appState.wpsFormEdit.weldingParameters];
                    weldingParameters = [];
                    for (let i = 0; i < selectedExistingWpqr.existingWpqrData.weldingParameters.length; i++) {
                        let weldingParametersRow: WeldingParameters = new WeldingParameters();
                        weldingParametersRow.id = selectedExistingWpqr.existingWpqrData.weldingParameters[i].id;
                        weldingParametersRow.runText = selectedExistingWpqr.existingWpqrData.weldingParameters[i].runText;
                        weldingParametersRow.run = selectedExistingWpqr.existingWpqrData.weldingParameters[i].run.toString().replace('.', ',');
                        weldingParametersRow.index = { ...selectedExistingWpqr.existingWpqrData.weldingParameters[i].index };
                        weldingParametersRow.weldingMethod = { ...selectedExistingWpqr.existingWpqrData.weldingParameters[i].weldingMethod };
                        weldingParametersRow.diameter = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].diameter);
                        weldingParametersRow.current = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].current);
                        weldingParametersRow.currentMax = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].currentMax);
                        weldingParametersRow.currentMin = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].currentMin);
                        weldingParametersRow.voltage = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].voltage);
                        weldingParametersRow.voltageMax = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].voltageMax);
                        weldingParametersRow.voltageMin = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].voltageMin);
                        weldingParametersRow.currentType = { ...selectedExistingWpqr.existingWpqrData.weldingParameters[i].currentType };
                        weldingParametersRow.runLength = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].runLength);
                        weldingParametersRow.time = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].time);;
                        weldingParametersRow.interpassTemperature = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].interpassTemperature);;
                        weldingParametersRow.wireFeed = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].wireFeed);
                        weldingParametersRow.runWidth = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].runWidth);
                        weldingParametersRow.flowRateRoot = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].flowRateRoot);
                        weldingParametersRow.flowRateTop = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].flowRateTop);
                        weldingParametersRow.travelSpeed = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].travelSpeed);
                        weldingParametersRow.travelSpeedMax = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].travelSpeedMax);
                        weldingParametersRow.travelSpeedMin = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].travelSpeedMin);
                        weldingParametersRow.heatInput = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].heatInput);
                        weldingParametersRow.heatInputMin = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].heatInputMin);
                        weldingParametersRow.heatInputMax = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].heatInputMax);
                        weldingParametersRow.createdAt = selectedExistingWpqr.existingWpqrData.weldingParameters[i].createdAt;
                        weldingParametersRow.deletedAt = selectedExistingWpqr.existingWpqrData.weldingParameters[i].deletedAt;
                        weldingParametersRow.updatedAt = selectedExistingWpqr.existingWpqrData.weldingParameters[i].updatedAt;
                        weldingParametersRow.pulseCurrent = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].pulseCurrent);
                        weldingParametersRow.pulseTime = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].pulseTime);
                        weldingParametersRow.basicCurrent = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].basicCurrent);
                        weldingParametersRow.timeForBasicCurrent = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].timeForBasicCurrent);
                        weldingParametersRow.frequency = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].frequency);
                        weldingParametersRow.current1 = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].current1)
                        weldingParametersRow.time1 = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].time1);
                        weldingParametersRow.heatInput1 = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].heatInput1);
                        weldingParametersRow.arcEnergyIE = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].arcEnergyIE);
                        weldingParametersRow.arcEnergyIP = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].arcEnergyIP);
                        weldingParametersRow.arcIP = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].arcIP);
                        weldingParametersRow.arcIE = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].arcIE);
                        weldingParametersRow.additiveMaterial = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].additiveMaterial);
                        weldingParametersRow.voltageX = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].voltageX);
                        weldingParametersRow.currentY = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].currentY);
                        weldingParametersRow.travelSpeedZ = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].travelSpeedZ);
                        weldingParametersRow.weldingTecknique = selectedExistingWpqr.existingWpqrData.weldingParameters[i].weldingTecknique;
                        weldingParametersRow.fuelGasPressure = selectedExistingWpqr.existingWpqrData.weldingParameters[i].fuelGasPressure;
                        weldingParametersRow.oxygenPressure = selectedExistingWpqr.existingWpqrData.weldingParameters[i].oxygenPressure;
                        weldingParametersRow.typeOfFlame = selectedExistingWpqr.existingWpqrData.weldingParameters[i].typeOfFlame;
                        weldingParameters.splice(weldingParameters.length, 0, weldingParametersRow);
                    };
                    dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });

                    let temperature: Temperature = { ...appState.wpsFormEdit.temperature };
                    temperature.preheatTemperature = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.preheatTemperature;
                    temperature.maxInterpassTemperature = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.interpassTemperature;
                    dispatch({ type: 'WPSFORMEDIT_SET_TEMPERATURE', temperature: temperature });

                    let afterWelding: AfterWelding = { ...appState.wpsFormEdit.afterWelding };
                    afterWelding.heatingRate = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.heatingAndCoolingRates;
                    afterWelding.coolingRate = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.heatingAndCoolingRates;
                    dispatch({ type: 'WPSFORMEDIT_SET_AFTER_WELDING', afterWelding: afterWelding });

                    let otherInformation: OtherInformation = { ...appState.wpsFormEdit.otherInformation };
                    otherInformation.otherInformation = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.commentInformation;
                    dispatch({ type: 'WPSFORMEDIT_SET_OTHER_INFORMATION', otherInformation: otherInformation });

                    //JointDesign
                    let jointDesignResponse: GetJointDesignResponse = { ...appState.wpsFormEdit.jointDesign };
                    jointDesignResponse = selectedExistingWpqr.existingWpqrData.jointDesign;
                    dispatch({ type: 'WPSFORMEDIT_SET_JOINT_DESIGN_RECEIVE', jointDesign: jointDesignResponse });

                    //WeldSequence
                    let weldSequenceResponse: GetWeldSequenceResponse = { ...appState.wpsFormEdit.weldSequence };
                    weldSequenceResponse = selectedExistingWpqr.existingWpqrData.weldSequence;
                    dispatch({ type: 'WPSFORMEDIT_SET_WELD_SEQUENCE_RECEIVE', weldSequence: weldSequenceResponse });
                }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_WELDING_METHOD_A_SEARCH_RESULT_REQUEST' });
    },
    initWeldingProtocol: (applicationId: number, controller: string, enableRevisionAction: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Choose different url
        console.log('init welding protocol');
        let url = controller == ControllerType.Applications ? `api/${controller}/GetByIdInclude/${0}/${applicationId}/${RevisionActionType.Default}` : `api/${controller}/GetByIdInclude/${applicationId}`;
        fetch(url, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    };
                    let data = res.data as GetApplicationResponse;
                    console.info(data);
                    let selectedApplication: GetApplicationResponse = { ...data };
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let header: HeaderWps = { ...appState.wpsFormEdit.header };
                        header.wpqrNumber = selectedApplication.pwps.wpqrNumber;
                        header.selectedByStandard.id = selectedApplication.pwps.byStandard.id;
                        header.selectedByStandard.name = selectedApplication.pwps.byStandard.name;
                        // Standard and standardText
                        header.enableStandardText = selectedApplication.rangeOfQualificationExaminationResponse.enableStandardText ? selectedApplication.rangeOfQualificationExaminationResponse.enableStandardText : selectedApplication.pwps.enableStandardText;
                        header.standardText = selectedApplication.rangeOfQualificationExaminationResponse.enableStandardText ? selectedApplication.rangeOfQualificationExaminationResponse.standardText : selectedApplication.pwps.standardText;
                        header.wpsDekraNumber = selectedApplication.dekraNumber;
                        header.wpsHeatInputText = selectedApplication.wpsHeatInputText;
                        header.wpsArcEnergyText = selectedApplication.wpsArcEnergyText;
                        header.selectedWpsId = selectedApplication.id;
                        header.revisionWpsAction = enableRevisionAction ? header.revisionWpsAction : RevisionActionType.CreatedFromWPQR;
                        header.pwpsNumber = (header.revisionWpsAction === RevisionActionType.Revision || header.revisionWpsAction === RevisionActionType.View || header.revisionWpsAction === RevisionActionType.CreatedFromWPQR) ? selectedApplication.pwps.pwpsNumber : "";
                        header.otherOptions = selectedApplication.otherOptions;
                        header.otherOptionParametersTableId = selectedApplication.pwps.otherOptionParametersTableId;
                        header.date = selectedApplication.pwps.date;
                        header.project = selectedApplication.pwps.projectNumber;
                        header.revisionNumber = selectedApplication.pwps.revisionNumber;
                        header.examiner = enableRevisionAction ? selectedApplication.pwps.examiner : header.examiner;
                        header.manufacturer = enableRevisionAction ? selectedApplication.pwps.manufacturer : header.manufacturer
                        dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });

                        // BaseMaterial1.
                        let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
                        if (selectedApplication.pwps.parentMaterial.baseMaterial1 && selectedApplication.pwps.parentMaterial.baseMaterial1.id !== 0) {
                            parentMaterial.baseMaterial1SearchText =
                                selectedApplication.pwps.parentMaterial.baseMaterial1.name
                                + '/'
                                + selectedApplication.pwps.parentMaterial.baseMaterial1.number;
                            parentMaterial.baseMaterial1SearchResult = [];
                            parentMaterial.selectedBaseMaterial1 = { ...selectedApplication.pwps.parentMaterial.baseMaterial1 };
                            // MaterialGroup1.
                            if (selectedApplication.pwps.parentMaterial.baseMaterial1.materialGroup && selectedApplication.pwps.parentMaterial.baseMaterial1.materialGroup.id !== 0) {
                                parentMaterial.selectedMaterialGroup1 = { ...selectedApplication.pwps.parentMaterial.baseMaterial1.materialGroup };
                            }
                            else {
                                parentMaterial.selectedMaterialGroup1 = {
                                    id: 0,
                                    name: '',
                                    description: '',
                                    createdAt: '',
                                    deletedAt: '',
                                    updatedAt: ''
                                };
                            }
                            // TechnicalDeliveryCondition1.
                            parentMaterial.selectedTechnicalDeliveryCondition1 = { ...selectedApplication.pwps.parentMaterial.technicalDeliveryCondition1 };
                        } else {
                            parentMaterial.baseMaterial1SearchText = '';
                            parentMaterial.baseMaterial1SearchResult = [];
                            parentMaterial.selectedBaseMaterial1 = {
                                id: 0,
                                name: '',
                                number: '',
                                description: '',
                                materialGroup: {
                                    id: 0,
                                    name: '',
                                    description: '',
                                    createdAt: '',
                                    deletedAt: '',
                                    updatedAt: ''
                                },
                                technicalDeliveryConditions: [],
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            parentMaterial.selectedTechnicalDeliveryCondition1 = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            }
                        }
                        // MaterialThickness1.
                        console.log('enabled and not null: ', selectedApplication.rangeOfQualificationExaminationResponse.enableMaterialThicknessesText || selectedApplication.rangeOfQualificationExaminationResponse.materialThicknesses !== null)
                        if (selectedApplication.rangeOfQualificationExaminationResponse.enableMaterialThicknessesText || selectedApplication.rangeOfQualificationExaminationResponse.materialThicknesses !== null) {
                            let { result1, result2 } = HelpRangeOfQualificationExamination.GetValueRangeOfQualificationMaterialThickness(selectedApplication.rangeOfQualificationExaminationResponse.materialThicknesses)
                            console.log('result 1: ', result1, 'result 2: ', result2);
                            selectedApplication.pwps.parentMaterial.materialThickness1Text = result1;
                            selectedApplication.pwps.parentMaterial.materialThickness2Text = result2 === '' && selectedApplication.pwps.parentMaterial.materialThickness2 !== 0 ? result1 : result2;
                            selectedApplication.pwps.parentMaterial.enableMaterialThickness = true;
                            console.log('parent material: ', selectedApplication.pwps.parentMaterial)
                        }
                        parentMaterial.materialThickness1 = selectedApplication.pwps.parentMaterial.enableMaterialThickness ? selectedApplication.pwps.parentMaterial.materialThickness1Text : HelpFunctions.setZeroToEmpty(selectedApplication.pwps.parentMaterial.materialThickness1);
                        // Diameter1.
                        if (selectedApplication.rangeOfQualificationExaminationResponse.enableDiameterText || selectedApplication.rangeOfQualificationExaminationResponse.diameterText !== null) {
                            selectedApplication.pwps.parentMaterial.diameter1Text = HelpRangeOfQualificationExamination.GetValueRangeOfQualificationDiameter(selectedApplication.rangeOfQualificationExaminationResponse.diameterText);
                            selectedApplication.pwps.parentMaterial.diameter2Text = selectedApplication.pwps.parentMaterial.enableDiameter || selectedApplication.pwps.parentMaterial.diameter2 !== 0 ? selectedApplication.pwps.parentMaterial.diameter1Text : "";
                            selectedApplication.pwps.parentMaterial.enableDiameter = true;
                        }

                        parentMaterial.diameter1 = selectedApplication.pwps.parentMaterial.enableDiameter ? selectedApplication.pwps.parentMaterial.diameter1Text : HelpFunctions.setZeroToEmpty(selectedApplication.pwps.parentMaterial.diameter1);
                        // BaseMaterial2.
                        if (selectedApplication.pwps.parentMaterial.baseMaterial2 && selectedApplication.pwps.parentMaterial.baseMaterial2.id !== 0) {
                            parentMaterial.baseMaterial2SearchText =
                                selectedApplication.pwps.parentMaterial.baseMaterial2.name
                                + '/'
                                + selectedApplication.pwps.parentMaterial.baseMaterial2.number;
                            parentMaterial.baseMaterial2SearchResult = [];
                            parentMaterial.selectedBaseMaterial2 = { ...selectedApplication.pwps.parentMaterial.baseMaterial2 };
                            // MaterialGroup2.
                            if (selectedApplication.pwps.parentMaterial.baseMaterial2.materialGroup && selectedApplication.pwps.parentMaterial.baseMaterial2.materialGroup.id !== 0) {
                                parentMaterial.selectedMaterialGroup2 = { ...selectedApplication.pwps.parentMaterial.baseMaterial2.materialGroup };
                            }
                            else {
                                parentMaterial.selectedMaterialGroup2 = {
                                    id: 0,
                                    name: '',
                                    description: '',
                                    createdAt: '',
                                    deletedAt: '',
                                    updatedAt: ''
                                };
                            }
                            // TechnicalDeliveryCondition2.
                            parentMaterial.selectedTechnicalDeliveryCondition2 = { ...selectedApplication.pwps.parentMaterial.technicalDeliveryCondition2 };
                        }
                        else {
                            parentMaterial.baseMaterial2SearchText = '';
                            parentMaterial.baseMaterial2SearchResult = [];
                            parentMaterial.selectedBaseMaterial2 = {
                                id: 0,
                                name: '',
                                number: '',
                                description: '',
                                materialGroup: {
                                    id: 0,
                                    name: '',
                                    description: '',
                                    createdAt: '',
                                    deletedAt: '',
                                    updatedAt: ''
                                },
                                technicalDeliveryConditions: [],
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            parentMaterial.selectedTechnicalDeliveryCondition2 = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            }
                        }
                        // MaterialThickness2.
                        parentMaterial.materialThickness2 = selectedApplication.pwps.parentMaterial.enableMaterialThickness ? selectedApplication.pwps.parentMaterial.materialThickness2Text : HelpFunctions.setZeroToEmpty(selectedApplication.pwps.parentMaterial.materialThickness2);
                        console.log('parentMaterial: ', parentMaterial.materialThickness2, selectedApplication.pwps.parentMaterial.enableMaterialThickness)
                        // Diameter2.
                        parentMaterial.diameter2 = selectedApplication.pwps.parentMaterial.enableDiameter ? selectedApplication.pwps.parentMaterial.diameter2Text : HelpFunctions.setZeroToEmpty(selectedApplication.pwps.parentMaterial.diameter2);

                        selectedApplication.pwps.parentMaterial.baseMaterial1Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial1Text ? selectedApplication.pwps.parentMaterial.baseMaterial1Text : parentMaterial.baseMaterial1SearchText;
                        selectedApplication.pwps.parentMaterial.technicalDeliveryCondition1Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial1Text ? selectedApplication.pwps.parentMaterial.technicalDeliveryCondition1Text : parentMaterial.selectedTechnicalDeliveryCondition1.name;
                        selectedApplication.pwps.parentMaterial.baseMaterial2Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial2Text ? selectedApplication.pwps.parentMaterial.baseMaterial2Text : parentMaterial.baseMaterial2SearchText;
                        selectedApplication.pwps.parentMaterial.technicalDeliveryCondition2Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial2Text ? selectedApplication.pwps.parentMaterial.technicalDeliveryCondition2Text : parentMaterial.selectedTechnicalDeliveryCondition2.name;

                        parentMaterial.enableBaseMaterial1Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial1Text || selectedApplication.rangeOfQualificationExaminationResponse.materialGroupText ? true : false;
                        parentMaterial.enableBaseMaterial2Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial2Text || selectedApplication.rangeOfQualificationExaminationResponse.materialGroupText ? true : false;
                        
                        parentMaterial.baseMaterial1Text = selectedApplication.pwps.parentMaterial.baseMaterial1Text;
                        parentMaterial.baseMaterial2Text = selectedApplication.pwps.parentMaterial.baseMaterial2Text;

                        let result1MaterialGroup = "";
                        if (selectedApplication.rangeOfQualificationExaminationResponse.materialGroupText) {
                            result1MaterialGroup = HelpRangeOfQualificationExamination.GetValueRangeOfQualificationMaterialGroup(selectedApplication.rangeOfQualificationExaminationResponse.materialGroupText)
                        }
                        parentMaterial.materialGroup1Text = selectedApplication.rangeOfQualificationExaminationResponse.materialGroupText ? result1MaterialGroup : selectedApplication.pwps.parentMaterial.materialGroup1Text;
                        parentMaterial.materialGroup2Text = selectedApplication.rangeOfQualificationExaminationResponse.materialGroupText ? result1MaterialGroup : selectedApplication.pwps.parentMaterial.materialGroup2Text;
                        parentMaterial.technicalDeliveryCondition1Text = selectedApplication.pwps.parentMaterial.technicalDeliveryCondition1Text;
                        parentMaterial.technicalDeliveryCondition2Text = selectedApplication.pwps.parentMaterial.technicalDeliveryCondition2Text;
                        parentMaterial.enableMaterialThickness = selectedApplication.pwps.parentMaterial.enableMaterialThickness;
                        parentMaterial.enableDiameter = selectedApplication.pwps.parentMaterial.enableDiameter;
                        // Initialize weldingProcess.
                        dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });


                        let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
                        // SelectedJointPreparation
                        if (selectedApplication.pwps.weldingData !== undefined && selectedApplication.pwps.weldingData.jointPreparationSearchText !== '') {
                            weldingData.selectedJointPreparationText = selectedApplication.pwps.weldingData.jointPreparationSearchText;          
                        }

                        // JointType and jointTypeText
                        if (selectedApplication.rangeOfQualificationExaminationResponse.enableJointTypeText || selectedApplication.rangeOfQualificationExaminationResponse.jointType !== null) {
                            weldingData.enableJointTypeText = true;
                            weldingData.jointTypeText = HelpRangeOfQualificationExamination.GetValueRangeOfQualificationJointType(selectedApplication.rangeOfQualificationExaminationResponse.jointType);
                        } else {
                            weldingData.enableJointTypeText = selectedApplication.pwps.weldingData.enableJointTypeText;
                            weldingData.jointTypeText = selectedApplication.pwps.weldingData.enableJointTypeText ? selectedApplication.pwps.weldingData.jointTypeText : "";
                        }

                        if (selectedApplication.pwps.weldingData.jointType && selectedApplication.pwps.weldingData.jointType.id !== 0) {
                            weldingData.selectedJointType = { ...selectedApplication.pwps.weldingData.jointType };
                        }
                        else {
                            weldingData.selectedJointType = {
                                id: 0,
                                name: '',
                                description: '',
                                jointShapes: [],
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        weldingData.maxWidthOfRun = selectedApplication.pwps.weldingData.maxWidthOfRun.toString().replace('.', ',');
                        // RootSupportDetails.
                        if (selectedApplication.pwps.weldingData.rootSupportDetails && selectedApplication.pwps.weldingData.rootSupportDetails.id !== 0) {
                            weldingData.selectedRootSupportDetails = { ...selectedApplication.pwps.weldingData.rootSupportDetails };
                        }
                        else {
                            weldingData.selectedRootSupportDetails = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        // TackWeld.
                        weldingData.tackWeldText = selectedApplication.pwps.weldingData.tackWeldText;
                        // WeldingPosition.
                        if (selectedApplication.pwps.weldingData.weldingPosition && selectedApplication.pwps.weldingData.weldingPosition.id !== 0) {
                            weldingData.selectedWeldingPosition = { ...selectedApplication.pwps.weldingData.weldingPosition };
                        }
                        else {
                            weldingData.selectedWeldingPosition = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        // WeldingPosition2.
                        if (selectedApplication.pwps.weldingData.weldingPosition2 && selectedApplication.pwps.weldingData.weldingPosition2.id !== 0) {
                            weldingData.selectedWeldingPosition2 = { ...selectedApplication.pwps.weldingData.weldingPosition2 };
                        }
                        else {
                            weldingData.selectedWeldingPosition2 = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        weldingData.lengthTackWelds = selectedApplication.pwps.weldingData.lengthTackWelds;
                        weldingData.tungstenElectrode = selectedApplication.pwps.weldingData.tungstenElectrode;
                        weldingData.electrodeTorchAngle = selectedApplication.pwps.weldingData.electrodeTorchAngle;
                        weldingData.nozzleDiameter = selectedApplication.pwps.weldingData.nozzleDiameter;
                        // RootProtectionGas.
                        if (selectedApplication.pwps.weldingData.rootProtectionGas && selectedApplication.pwps.weldingData.rootProtectionGas.id !== 0) {
                            weldingData.rootProtectionGasSearchText = selectedApplication.pwps.weldingData.rootProtectionGas.name;
                            weldingData.rootProtectionGasSearchResult = [];
                            weldingData.selectedRootProtectionGas = { ...selectedApplication.pwps.weldingData.rootProtectionGas };
                        }
                        else {
                            weldingData.rootProtectionGasSearchText = '';
                            weldingData.rootProtectionGasSearchResult = [];
                            weldingData.selectedRootProtectionGas = {
                                id: 0,
                                name: '',
                                content: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        // PlasmaGas.
                        if (selectedApplication.pwps.weldingData.plasmaGas && selectedApplication.pwps.weldingData.plasmaGas.id !== 0) {
                            weldingData.selectedPlasmaGas = { ...selectedApplication.pwps.weldingData.plasmaGas };
                        }
                        else {
                            weldingData.selectedPlasmaGas = {
                                id: 0,
                                name: '',
                                content: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        //
                        weldingData.electrodeTorchAngle111 = selectedApplication.pwps.weldingData.electrodeTorchAngle111;
                        weldingData.plasmaflowrate = selectedApplication.pwps.weldingData.plasmaflowrate;
                        weldingData.stickOut = selectedApplication.pwps.weldingData.stickOut; //TODO is not found in welding protocols
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });

                        // WeldingMethod1.
                        let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
                        if (selectedApplication.pwps.weldingProcess.weldingMethod1 && selectedApplication.pwps.weldingProcess.weldingMethod1.id !== 0) {
                            weldingProcess.weldingMethodASearchText = selectedApplication.pwps.weldingProcess.weldingMethod1.name;
                            weldingProcess.weldingMethodASearchResult = [];
                            weldingProcess.selectedWeldingMethodA = { ...selectedApplication.pwps.weldingProcess.weldingMethod1 };
                        }
                        else {
                            weldingProcess.weldingMethodASearchText = '';
                            weldingProcess.weldingMethodASearchResult = [];
                            weldingProcess.selectedWeldingMethodA = {
                                id: 0,
                                name: '',
                                efficiencyFactor: 0.0,
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        // Make1.
                        if (selectedApplication.pwps.weldingProcess.make1 && selectedApplication.pwps.weldingProcess.make1.id !== 0) {
                            weldingProcess.makeASearchText = selectedApplication.pwps.weldingProcess.make1.name;
                            weldingProcess.makeASearchResult = [];
                            weldingProcess.selectedMakeA = { ...selectedApplication.pwps.weldingProcess.make1 };
                            // Designation1.
                            console.log('selectedApplication.pwps.weldingProcess.make1.designation');
                            console.log(selectedApplication.pwps.weldingProcess.make1.designation);
                            weldingProcess.selectedDesignationA = { ...selectedApplication.pwps.weldingProcess.make1.designation };
                            weldingProcess.selectedFMGroupA = { ...selectedApplication.pwps.weldingProcess.make1.fmGroup };
                        }
                        else {
                            weldingProcess.makeASearchText = '';
                            weldingProcess.makeASearchResult = [];
                            weldingProcess.selectedMakeA = {
                                id: 0,
                                name: '',
                                description: '',
                                designation: {
                                    id: 0,
                                    name: '',
                                    description: '',
                                    createdAt: '',
                                    deletedAt: '',
                                    updatedAt: ''
                                },
                                fmGroup: {
                                    id: 0,
                                    name: '',
                                    description: '',
                                    createdAt: '',
                                    deletedAt: '',
                                    updatedAt: ''
                                },
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            weldingProcess.selectedDesignationA = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            weldingProcess.selectedFMGroupA = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        if (selectedApplication.pwps.weldingProcess.shieldingGas1 && selectedApplication.pwps.weldingProcess.shieldingGas1.id !== 0) {
                            weldingProcess.shieldingGasASearchText = selectedApplication.pwps.weldingProcess.shieldingGas1.name;
                            weldingProcess.shieldingGasASearchResult = [];
                            weldingProcess.selectedShieldingGasA = { ...selectedApplication.pwps.weldingProcess.shieldingGas1 };
                        }
                        else {
                            weldingProcess.shieldingGasASearchText = '';
                            weldingProcess.shieldingGasASearchResult = [];
                            weldingProcess.selectedShieldingGasA = {
                                id: 0,
                                name: '',
                                content: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }

                        // arcType1.
                        if (selectedApplication.pwps.weldingProcess.arcType1 && selectedApplication.pwps.weldingProcess.arcType1.id !== 0) {
                            weldingProcess.selectedArcTypeA = { ...selectedApplication.pwps.weldingProcess.arcType1 };
                        }
                        else {
                            weldingProcess.selectedArcTypeA = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        // WeldingMethod2.
                        if (selectedApplication.pwps.weldingProcess.weldingMethod2 && selectedApplication.pwps.weldingProcess.weldingMethod2.id !== 0) {
                            console.log('selectedApplication.pwps.weldingProcess.weldingMethod2');
                            console.log(selectedApplication.pwps.weldingProcess.weldingMethod2);
                            weldingProcess.weldingMethodBSearchText = selectedApplication.pwps.weldingProcess.weldingMethod2.name;
                            weldingProcess.weldingMethodBSearchResult = [];
                            weldingProcess.selectedWeldingMethodB = { ...selectedApplication.pwps.weldingProcess.weldingMethod2 };

                        }
                        else {
                            weldingProcess.weldingMethodBSearchText = '';
                            weldingProcess.weldingMethodBSearchResult = [];
                            weldingProcess.selectedWeldingMethodB = {
                                id: 0,
                                name: '',
                                efficiencyFactor: 0.0,
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        // Make2.
                        if (selectedApplication.pwps.weldingProcess.make2 && selectedApplication.pwps.weldingProcess.make2.id !== 0) {
                            weldingProcess.makeBSearchText = selectedApplication.pwps.weldingProcess.make2.name;
                            weldingProcess.makeBSearchResult = [];
                            weldingProcess.selectedMakeB = { ...selectedApplication.pwps.weldingProcess.make2 };
                            // Designation2.
                            weldingProcess.selectedDesignationB = { ...selectedApplication.pwps.weldingProcess.make2.designation };
                            weldingProcess.selectedFMGroupB = { ...selectedApplication.pwps.weldingProcess.make2.fmGroup };
                        }
                        else {
                            weldingProcess.makeBSearchText = '';
                            weldingProcess.makeBSearchResult = [];
                            weldingProcess.selectedMakeB = {
                                id: 0,
                                name: '',
                                description: '',
                                designation: {
                                    id: 0,
                                    name: '',
                                    description: '',
                                    createdAt: '',
                                    deletedAt: '',
                                    updatedAt: ''
                                },
                                fmGroup: {
                                    id: 0,
                                    name: '',
                                    description: '',
                                    createdAt: '',
                                    deletedAt: '',
                                    updatedAt: ''
                                },
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            weldingProcess.selectedDesignationB = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                            weldingProcess.selectedFMGroupB = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        // ShieldingGas2.
                        if (selectedApplication.pwps.weldingProcess.shieldingGas2 && selectedApplication.pwps.weldingProcess.shieldingGas2.id !== 0) {
                            weldingProcess.shieldingGasBSearchText = selectedApplication.pwps.weldingProcess.shieldingGas2.name;
                            weldingProcess.shieldingGasBSearchResult = [];
                            weldingProcess.selectedShieldingGasB = { ...selectedApplication.pwps.weldingProcess.shieldingGas2 };
                        }
                        else {
                            weldingProcess.shieldingGasBSearchText = '';
                            weldingProcess.shieldingGasBSearchResult = [];
                            weldingProcess.selectedShieldingGasB = {
                                id: 0,
                                name: '',
                                content: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }

                        // arcType2.
                        if (selectedApplication.pwps.weldingProcess.arcType2 && selectedApplication.pwps.weldingProcess.arcType2.id !== 0) {
                            weldingProcess.selectedArcTypeB = { ...selectedApplication.pwps.weldingProcess.arcType2 };
                        }
                        else {
                            weldingProcess.selectedArcTypeB = {
                                id: 0,
                                name: '',
                                description: '',
                                createdAt: '',
                                deletedAt: '',
                                updatedAt: ''
                            };
                        }
                        let result1Value = ""; let result2Value = "";
                        if (selectedApplication.rangeOfQualificationExaminationResponse.enableDesignationsText) {
                            let { result1, result2 } = HelpRangeOfQualificationExamination.GetValueRangeOfQualification(selectedApplication.rangeOfQualificationExaminationResponse.designations)
                            result1Value = result1;
                            result2Value = result2;
                            selectedApplication.pwps.weldingProcess.makeAText = selectedApplication.pwps.weldingProcess.enableMakeAText ? selectedApplication.pwps.weldingProcess.makeAText : weldingProcess.makeASearchText;
                            selectedApplication.pwps.weldingProcess.makeBText = selectedApplication.pwps.weldingProcess.enableMakeBText ? selectedApplication.pwps.weldingProcess.makeBText : weldingProcess.makeBSearchText;
                        }
                        let result1Make = ""; let result2Make = "";
                        if (selectedApplication.rangeOfQualificationExaminationResponse.enableMakesText) {
                            let { result1, result2 } = HelpRangeOfQualificationExamination.GetValueRangeOfQualification(selectedApplication.rangeOfQualificationExaminationResponse.makes)
                            result1Make = result1;
                            result2Make = result2;
                            selectedApplication.pwps.weldingProcess.designationAText = selectedApplication.pwps.weldingProcess.enableMakeAText ? selectedApplication.pwps.weldingProcess.designationAText : weldingProcess.selectedDesignationA.name;
                            selectedApplication.pwps.weldingProcess.designationBText = selectedApplication.pwps.weldingProcess.enableMakeBText ? selectedApplication.pwps.weldingProcess.designationBText : weldingProcess.selectedDesignationB.name;
                        }

                        if (selectedApplication.rangeOfQualificationExaminationResponse.enableMakesText || selectedApplication.rangeOfQualificationExaminationResponse.enableDesignationsText) {
                            selectedApplication.pwps.weldingProcess.fmGroupAText = selectedApplication.pwps.weldingProcess.enableMakeAText ? selectedApplication.pwps.weldingProcess.fmGroupAText : weldingProcess.selectedFMGroupA.name;
                            selectedApplication.pwps.weldingProcess.fmGroupBText = selectedApplication.pwps.weldingProcess.enableMakeBText ? selectedApplication.pwps.weldingProcess.fmGroupBText : weldingProcess.selectedFMGroupB.name;
                        }

                        weldingProcess.enableMakeAText = selectedApplication.rangeOfQualificationExaminationResponse.enableDesignationsText || selectedApplication.rangeOfQualificationExaminationResponse.enableMakesText ? true : selectedApplication.pwps.weldingProcess.enableMakeAText;
                        weldingProcess.makeAText = selectedApplication.rangeOfQualificationExaminationResponse.enableMakesText ? result1Make : selectedApplication.pwps.weldingProcess.makeAText;
                        weldingProcess.designationAText = selectedApplication.rangeOfQualificationExaminationResponse.enableDesignationsText ? result1Value : selectedApplication.pwps.weldingProcess.designationAText;
                        weldingProcess.fmGroupAText = selectedApplication.pwps.weldingProcess.fmGroupAText;
                        weldingProcess.enableMakeBText = selectedApplication.rangeOfQualificationExaminationResponse.enableDesignationsText ? true :selectedApplication.pwps.weldingProcess.enableMakeBText;
                        weldingProcess.makeBText = selectedApplication.rangeOfQualificationExaminationResponse.enableMakesText ? result2Make : selectedApplication.pwps.weldingProcess.makeBText;
                        weldingProcess.designationBText = selectedApplication.rangeOfQualificationExaminationResponse.enableDesignationsText ? result2Value : selectedApplication.pwps.weldingProcess.designationBText;
                        weldingProcess.fmGroupBText = selectedApplication.pwps.weldingProcess.fmGroupBText;
                        weldingProcess.typeOfBackingText = selectedApplication.pwps.weldingProcess.typeOfBackingText;
                        // WeldingMethods
                        weldingProcess.weldingMethods = [];
                        let weldingMethodEmpty = {
                            id: 0,
                            name: '',
                            efficiencyFactor: 0.0,
                            description: '',
                            createdAt: '',
                            deletedAt: '',
                            updatedAt: ''
                        };
                        weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, weldingMethodEmpty);
                        weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, selectedApplication.pwps.weldingProcess.weldingMethod1);
                        weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, selectedApplication.pwps.weldingProcess.weldingMethod2);
                        weldingProcess.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
                            if (weldingMethod1.name < weldingMethod2.name) {
                                return -1;
                            }
                            else if (weldingMethod1.name > weldingMethod2.name) {
                                return 1;
                            }
                            return 0;
                        });

                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });

                        //weldingParameters
                        let weldingParameters: Array<WeldingParameters> = [...appState.wpsFormEdit.weldingParameters];
                        weldingParameters = [];
                        for (let i = 0; i < selectedApplication.pwps.weldingParameters.length; i++) {
                            let weldingParametersRow: WeldingParameters = new WeldingParameters();
                            weldingParametersRow.id = selectedApplication.pwps.weldingParameters[i].id;
                            weldingParametersRow.runText = selectedApplication.pwps.weldingParameters[i].runText;
                            weldingParametersRow.run = selectedApplication.pwps.weldingParameters[i].run.toString().replace('.', ',');
                            weldingParametersRow.index = { ...selectedApplication.pwps.weldingParameters[i].index };
                            weldingParametersRow.weldingMethod = { ...selectedApplication.pwps.weldingParameters[i].weldingMethod };
                            weldingParametersRow.diameter = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].diameter);
                            weldingParametersRow.current = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].current);
                            weldingParametersRow.currentMax = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].currentMax);
                            weldingParametersRow.currentMin = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].currentMin);
                            weldingParametersRow.voltage = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].voltage);
                            weldingParametersRow.voltageMax = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].voltageMax);
                            weldingParametersRow.voltageMin = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].voltageMin);
                            weldingParametersRow.currentType = { ...selectedApplication.pwps.weldingParameters[i].currentType };
                            weldingParametersRow.runLength = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].runLength);
                            weldingParametersRow.time = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].time);;
                            weldingParametersRow.interpassTemperature = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].interpassTemperature);;
                            weldingParametersRow.wireFeed = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].wireFeed);
                            weldingParametersRow.runWidth = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].runWidth);
                            weldingParametersRow.flowRateRoot = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].flowRateRoot);
                            weldingParametersRow.flowRateTop = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].flowRateTop);
                            weldingParametersRow.travelSpeed = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].travelSpeed);
                            weldingParametersRow.travelSpeedMax = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].travelSpeedMax);
                            weldingParametersRow.travelSpeedMin = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].travelSpeedMin);
                            weldingParametersRow.heatInput = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInput);
                            weldingParametersRow.heatInputMin = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInputMin);
                            weldingParametersRow.heatInputMax = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInputMax);
                            weldingParametersRow.createdAt = selectedApplication.pwps.weldingParameters[i].createdAt;
                            weldingParametersRow.deletedAt = selectedApplication.pwps.weldingParameters[i].deletedAt;
                            weldingParametersRow.updatedAt = selectedApplication.pwps.weldingParameters[i].updatedAt;
                            weldingParametersRow.pulseCurrent = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].pulseCurrent);
                            weldingParametersRow.pulseTime = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].pulseTime);
                            weldingParametersRow.basicCurrent = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].basicCurrent);
                            weldingParametersRow.timeForBasicCurrent = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].timeForBasicCurrent);
                            weldingParametersRow.frequency = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].frequency);
                            weldingParametersRow.current1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].current1)
                            weldingParametersRow.time1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].time1);
                            weldingParametersRow.heatInput1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInput1);
                            weldingParametersRow.arcEnergyIE = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcEnergyIE);
                            weldingParametersRow.arcEnergyIP = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcEnergyIP);
                            weldingParametersRow.arcIP = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcIP);
                            weldingParametersRow.arcIE = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcIE);
                            weldingParametersRow.additiveMaterial = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].additiveMaterial);
                            weldingParametersRow.voltageX = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].voltageX);
                            weldingParametersRow.currentY = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].currentY);
                            weldingParametersRow.travelSpeedZ = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].travelSpeedZ);
                            weldingParametersRow.weldingTecknique = selectedApplication.pwps.weldingParameters[i].weldingTecknique;
                            weldingParametersRow.fuelGasPressure = selectedApplication.pwps.weldingParameters[i].fuelGasPressure;
                            weldingParametersRow.oxygenPressure = selectedApplication.pwps.weldingParameters[i].oxygenPressure;
                            weldingParametersRow.typeOfFlame = selectedApplication.pwps.weldingParameters[i].typeOfFlame;
                            weldingParameters.splice(weldingParameters.length, 0, weldingParametersRow);
                        };
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });

                        let temperature: Temperature = { ...appState.wpsFormEdit.temperature };
                        temperature.preheatTemperature = selectedApplication.pwps.temperature.preheatTemperature.toString().replace('.', ',');
                        temperature.maxInterpassTemperature = selectedApplication.pwps.temperature.maxInterpassTemperature.toString().replace('.', ',');
                        dispatch({ type: 'WPSFORMEDIT_SET_TEMPERATURE', temperature: temperature });

                        let afterWelding: AfterWelding = { ...appState.wpsFormEdit.afterWelding };
                        afterWelding.heatingRate = selectedApplication.pwps.afterWelding.heatingRate;
                        afterWelding.coolingRate = selectedApplication.pwps.afterWelding.coolingRate;
                        dispatch({ type: 'WPSFORMEDIT_SET_AFTER_WELDING', afterWelding: afterWelding });

                        let otherInformation: OtherInformation = { ...appState.wpsFormEdit.otherInformation };
                        otherInformation.otherInformation = selectedApplication.pwps.otherInformation;
                        dispatch({ type: 'WPSFORMEDIT_SET_OTHER_INFORMATION', otherInformation: otherInformation });

                        //JointDesign
                        let jointDesignResponse: GetJointDesignResponse = { ...appState.wpsFormEdit.jointDesign };
                        jointDesignResponse = selectedApplication.pwps.jointDesign;
                        dispatch({ type: 'WPSFORMEDIT_SET_JOINT_DESIGN_RECEIVE', jointDesign: jointDesignResponse });

                        //WeldSequence
                        let weldSequenceResponse: GetWeldSequenceResponse = { ...appState.wpsFormEdit.weldSequence };
                        weldSequenceResponse = selectedApplication.pwps.weldSequence;
                        dispatch({ type: 'WPSFORMEDIT_SET_WELD_SEQUENCE_RECEIVE', weldSequence: weldSequenceResponse });
                    }
                }))
                    .catch(error => {
                        console.error(error);
                    });
        dispatch({ type: 'WPSFORMEDIT_GET_WELDING_METHOD_A_SEARCH_RESULT_REQUEST' });
    },
    getWeldingMethodASearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingMethods/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetWeldingMethodResponse>;
                console.info(data);
                const appState = getState();
                if (appState && appState.wpsFormEdit) {
                    let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
                    weldingProcess.weldingMethodASearchResult = [...data];
                    dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                }

            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_WELDING_METHOD_A_SEARCH_RESULT_REQUEST' });
    },
    getWeldingMethodBSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingMethods/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingMethodResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
                        weldingProcess.weldingMethodBSearchResult = [...data];
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_WELDING_METHOD_B_SEARCH_RESULT_REQUEST' });
    },
    getArcTypeSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ArcTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetArcTypeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
                        weldingProcess.arcTypeSearchResult = [...data];
                        dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_ARC_TYPE_A_SEARCH_RESULT_REQUEST' });
    },
    getIndexesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Indexes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetIndexResponse>;
                    console.info(data);
                    dispatch({ type: 'WPSFORMEDIT_GET_INDEXES_RECEIVE', indexes: data });

                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_INDEXES_REQUEST' });
    },
    getCurrentTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/CurrentTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetCurrentTypeResponse>;
                    let currentTypes = [...data];
                    let currentTypeEmpty = new GetCurrentTypeResponse();
                    currentTypes.splice(currentTypes.length, 0, currentTypeEmpty);
                    currentTypes.sort((a, b) => a.id - b.id);
                    console.info(data);
                    dispatch({ type: 'WPSFORMEDIT_GET_CURRENT_TYPES_RECEIVE', currentTypes: currentTypes });

                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPSFORMEDIT_GET_CURRENT_TYPES_REQUEST' });
    },
    setParentMaterialBaseMaterial1Text: (baseMaterial1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.baseMaterial1Text = baseMaterial1Text;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialBaseMaterial2Text: (baseMaterial2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.baseMaterial2Text = baseMaterial2Text;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialMaterialGroup1Text: (materialGroup1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.materialGroup1Text = materialGroup1Text;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialMaterialGroup2Text: (materialGroup2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.materialGroup2Text = materialGroup2Text;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialTechnicalDeliveryCondition1Text: (technicalDeliveryCondition1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.technicalDeliveryCondition1Text = technicalDeliveryCondition1Text;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialTechnicalDeliveryCondition2Text: (technicalDeliveryCondition2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.technicalDeliveryCondition2Text = technicalDeliveryCondition2Text;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setEnableBaseMaterial1Text: (enableBaseMaterial1Text: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.enableBaseMaterial1Text = enableBaseMaterial1Text;
            parentMaterial.baseMaterial1Text = parentMaterial.baseMaterial1SearchText;
            parentMaterial.materialGroup1Text = parentMaterial.selectedMaterialGroup1.name;
            parentMaterial.technicalDeliveryCondition1Text = parentMaterial.selectedTechnicalDeliveryCondition1.name;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setEnableDiameterText: (enableDiameterText: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.enableDiameter = enableDiameterText;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setEnableMaterialThicknessText: (enableMaterialThicknessText: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.enableMaterialThickness = enableMaterialThicknessText;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setEnableJointTypeText: (enableJointTypeText: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.enableJointTypeText = enableJointTypeText;
            weldingData.jointTypeText = weldingData.selectedJointType?.description;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setEnableStandardText: (enableStandardText: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.enableStandardText = enableStandardText;
            header.standardText = header.selectedByStandard?.name;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setEnableBaseMaterial2Text: (enableBaseMaterial2Text: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let parentMaterial: ParentMaterial = { ...appState.wpsFormEdit.parentMaterial };
            parentMaterial.enableBaseMaterial2Text = enableBaseMaterial2Text;
            parentMaterial.baseMaterial2Text = parentMaterial.baseMaterial2SearchText;
            parentMaterial.materialGroup2Text = parentMaterial.selectedMaterialGroup2.name;
            parentMaterial.technicalDeliveryCondition2Text = parentMaterial.selectedTechnicalDeliveryCondition2.name;
            dispatch({ type: 'WPSFORMEDIT_SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setEnableMakeAText: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.enableMakeAText = value;
            weldingProcess.makeAText = weldingProcess.makeASearchText;
            weldingProcess.designationAText = weldingProcess.selectedDesignationA.name;
            weldingProcess.fmGroupAText = weldingProcess.selectedFMGroupA.name;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setEnableMakeBText: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.enableMakeBText = value;
            weldingProcess.makeBText = weldingProcess.makeBSearchText;
            weldingProcess.designationBText = weldingProcess.selectedDesignationB.name;
            weldingProcess.fmGroupBText = weldingProcess.selectedFMGroupB.name;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setMakeAText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.makeAText = value;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setJointTypeText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingData: WeldingData = { ...appState.wpsFormEdit.weldingData };
            weldingData.jointTypeText = value;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setStandardText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let header: HeaderWps = { ...appState.wpsFormEdit.header };
            header.standardText = value;
            dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
        }
    },
    setDesignationAText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.designationAText = value;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setfmGroupAText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.fmGroupAText = value;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setMakeBText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.makeBText = value;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setDesignationBText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.designationBText = value;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setfmGroupBText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsFormEdit) {
            let weldingProcess: WeldingProcess = { ...appState.wpsFormEdit.weldingProcess };
            weldingProcess.fmGroupBText = value;
            dispatch({ type: 'WPSFORMEDIT_SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    createUpdateWps: (wpsRequest: WpsRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {// Get the WeldingParameters values.
        const appState = getState();
        if (appState && appState.wParameters
            && appState.drawJointDesign
            && appState.weldSequence
            && appState.typeOfBacking
            && appState.weldSequence
            && appState.drawJointDesign) {
            // Get the TypeOfBackings values.
            wpsRequest.UpdatePWPSRequest.updateWeldingProcessRequest.typeOfBackingText = appState.typeOfBacking.typeOfBackingData.typeOfBackingText;
            // Get values from Component Wparameters
            let updateWeldingParametersRequests: Array<UpdateWeldingParametersRequest> = [];
            for (let i = 0; i < appState.wParameters.weldingParameters.length; i++) {
                let updateWeldingParametersRequest: UpdateWeldingParametersRequest = {
                    id: 0,
                    runText: appState.wParameters.weldingParameters[i].runText,
                    run: !isNaN(parseInt(appState.wParameters.weldingParameters[i].run)) ? parseInt(appState.wParameters.weldingParameters[i].run) : 0,
                    indexId: (appState.wParameters.weldingParameters[i].index.id !== 0) ? appState.wParameters.weldingParameters[i].index.id : undefined,
                    diameter: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].diameter.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].diameter.replace(',', '.')) : 0.0,
                    voltage: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltage.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltage.replace(',', '.')) : 0.0,
                    voltageMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageMin.replace(',', '.')) : 0.0,
                    voltageMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageMax.replace(',', '.')) : 0.0,
                    voltageX: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageX.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageX.replace(',', '.')) : 0.0,
                    current: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].current.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].current.replace(',', '.')) : 0.0,
                    currentMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentMin.replace(',', '.')) : 0.0,
                    currentMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentMax.replace(',', '.')) : 0.0,
                    currentY: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentY.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentY.replace(',', '.')) : 0.0,
                    travelSpeed: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeed.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeed.replace(',', '.')) : 0.0,
                    travelSpeedMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMin.replace(',', '.')) : 0.0,
                    travelSpeedMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMax.replace(',', '.')) : 0.0,
                    travelSpeedZ: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedZ.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedZ.replace(',', '.')) : 0.0,
                    heatInput: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInput.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInput.replace(',', '.')) : 0.0,
                    heatInputMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInputMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInputMin.replace(',', '.')) : 0.0,
                    heatInputMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInputMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInputMax.replace(',', '.')) : 0.0,
                    flowRateTop: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].flowRateTop.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].flowRateTop.replace(',', '.')) : 0.0,
                    flowRateRoot: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].flowRateRoot.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].flowRateRoot.replace(',', '.')) : 0.0,
                    wireFeed: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].wireFeed.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].wireFeed.replace(',', '.')) : 0.0,
                    currentTypeId: (appState.wParameters.weldingParameters[i].currentType.id !== 0) ? appState.wParameters.weldingParameters[i].currentType.id : undefined,
                    pulseCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].pulseCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].pulseCurrent.replace(',', '.')) : 0.0,
                    pulseTime: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].pulseTime.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].pulseTime.replace(',', '.')) : 0.0,
                    basicCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].basicCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].basicCurrent.replace(',', '.')) : 0.0,
                    timeForBasicCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].timeForBasicCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].timeForBasicCurrent.replace(',', '.')) : 0.0,
                    frequency: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].frequency.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].frequency.replace(',', '.')) : 0.0,
                    current1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].current1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].current1.replace(',', '.')) : 0.0,
                    time1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].time1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].time1.replace(',', '.')) : 0.0,
                    time: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].time.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].time.replace(',', '.')) : 0.0,
                    heatInput1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInput1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInput1.replace(',', '.')) : 0.0,
                    runLength: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].runLength.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].runLength.replace(',', '.')) : 0.0,
                    arcEnergyIE: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIE.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIE.replace(',', '.')) : 0.0,
                    arcEnergyIP: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIP.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIP.replace(',', '.')) : 0.0,
                    arcIP: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcIP.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcIP.replace(',', '.')) : 0.0,
                    arcIE: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcIE.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcIE.replace(',', '.')) : 0.0,
                    additiveMaterial: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].additiveMaterial.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].additiveMaterial.replace(',', '.')) : 0.0,
                    weldingMethodId: (appState.wParameters.weldingParameters[i].weldingMethod.id !== 0) ? appState.wParameters.weldingParameters[i].weldingMethod.id : undefined,
                    weldingTecknique: appState.wParameters.weldingParameters[i].weldingTecknique,
                    fuelGasPressure: appState.wParameters.weldingParameters[i].fuelGasPressure,
                    oxygenPressure: appState.wParameters.weldingParameters[i].oxygenPressure,
                    typeOfFlame: appState.wParameters.weldingParameters[i].typeOfFlame
                };
                updateWeldingParametersRequests.push(updateWeldingParametersRequest);
            }
            wpsRequest.UpdatePWPSRequest.updateWeldingParametersRequests = updateWeldingParametersRequests;

            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.materialThickness1 = !isNaN(parseFloat(appState.drawJointDesign.materialThickness1.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.materialThickness1.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.materialThickness2 = !isNaN(parseFloat(appState.drawJointDesign.materialThickness2.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.materialThickness2.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.jointTypeId = (appState.drawJointDesign.selectedJointType.id !== 0) ? appState.drawJointDesign.selectedJointType.id : undefined;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.jointShapeId = (appState.drawJointDesign.selectedJointShape.id !== 0) ? appState.drawJointDesign.selectedJointShape.id : undefined;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.jointAngleAlpha = !isNaN(parseFloat(appState.drawJointDesign.jointAngleAlpha.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.jointAngleAlpha.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.gapOpening = !isNaN(parseFloat(appState.drawJointDesign.gapOpening.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.gapOpening.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.straightEdge = !isNaN(parseFloat(appState.drawJointDesign.straightEdge.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.straightEdge.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.phaseAngle1 = !isNaN(parseFloat(appState.drawJointDesign.phaseAngle1.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.phaseAngle1.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.phaseAngle2 = !isNaN(parseFloat(appState.drawJointDesign.phaseAngle2.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.phaseAngle2.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.jointAngleBeta = !isNaN(parseFloat(appState.drawJointDesign.jointAngleBeta.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.jointAngleBeta.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.throatThickness = !isNaN(parseFloat(appState.drawJointDesign.throatThickness.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.throatThickness.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.rootSupportId = (appState.drawJointDesign.selectedRootSupport.id !== 0) ? appState.drawJointDesign.selectedRootSupport.id : undefined;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.depthOfPreparation = !isNaN(parseFloat(appState.drawJointDesign.depthOfPreparation.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.depthOfPreparation.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.radius = !isNaN(parseFloat(appState.drawJointDesign.radius.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.radius.toString().replace(',', '.')) : 0.0;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.note = appState.drawJointDesign.note;
            wpsRequest.UpdatePWPSRequest.updateJointDesignRequest.jointDesignHtmlImage = appState.drawJointDesign.jointDesignHtmlImage;

            // Save Files and WeldSequence
            let updateFilesRequest: UpdateFilesRequest = {
                applicationId: 0,
                weldingProtocolId: 0,
                wpsId: 0,
                enableCreateWps: true,
                wpqrEnableEdit: false,
                jointDesignHtmlImage: "",
                updateWeldSequenceRequest: {
                    imageId: appState.weldSequence.sequences.imageId,
                    imageData: appState.weldSequence.sequences.imageData,
                    sequenceNumber1: appState.weldSequence.sequences.sequenceNumber1,
                    sequenceNumber2: appState.weldSequence.sequences.sequenceNumber2,
                    sequenceNumber3: appState.weldSequence.sequences.sequenceNumber3,
                    sequenceNumber4: appState.weldSequence.sequences.sequenceNumber4,
                    sequenceNumber5: appState.weldSequence.sequences.sequenceNumber5,
                    sequenceNumber6: appState.weldSequence.sequences.sequenceNumber6,
                    sequenceNumber7: appState.weldSequence.sequences.sequenceNumber7,
                    sequenceNumber8: appState.weldSequence.sequences.sequenceNumber8,
                    sequenceNumber9: appState.weldSequence.sequences.sequenceNumber9,
                    sequenceNumber10: appState.weldSequence.sequences.sequenceNumber10,
                    sequenceNumber11: appState.weldSequence.sequences.sequenceNumber11,
                    sequenceNumber12: appState.weldSequence.sequences.sequenceNumber12,
                    sequenceNumber13: appState.weldSequence.sequences.sequenceNumber13,
                    sequenceNumber14: appState.weldSequence.sequences.sequenceNumber14,
                    sequenceNumber15: appState.weldSequence.sequences.sequenceNumber15,
                },
                updateFilesUploadRequest: []
            };

            fetch(`api/Wpss/Create`, {
                method: 'POST',
                headers: authHeader.authHeader(),
                body: JSON.stringify(wpsRequest)
            })
                .then(response => response.json() as Promise<WpsResponse>)
                .then(data => {
                    console.info(data);
                    updateFilesRequest.wpsId = data.id;
                    // Create WeldSequence
                    fetch(`api/File/Update`, {
                        method: 'PUT',
                        headers: authHeader.authHeader(),
                        body: JSON.stringify(updateFilesRequest)
                        })
                        .then(response => response.json() as Promise<UpdateFilesResponse>)
                        .then(data => {
                            console.info(data);
                            dispatch({ type: 'WPSFORMEDIT_SET_CREATE_UPATE_WPS_RECEIVE', wpsId: data.id });
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    
                })
                .catch(error => {
                    console.error(error);
                });
            dispatch({ type: 'WPSFORMEDIT_SET_CREATE_UPATE_WPS_REQUEST' });
        }
    },
    createFile: (createFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileValidationResult = HelpFunctions.fileValidation(createFile);
        if (fileValidationResult == "") {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let header: HeaderWps = { ...appState.wpsFormEdit.header };
                        header.imageValidationError2 = "";
                        header.imageUpload2 = reader.result.toString();
                        dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
                    }
                }
            };
            reader.readAsDataURL(createFile);
        }
        else {
            const appState = getState();
            if (appState && appState.wpsFormEdit) {
                let header: HeaderWps = { ...appState.wpsFormEdit.header };
                header.imageValidationError2 = fileValidationResult;
                dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
            }
        }
    },
    createImageDrawJointDesign: (createFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileValidationResult = HelpFunctions.fileValidation(createFile);
        if (fileValidationResult == "") {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {
                        let header: HeaderWps = { ...appState.wpsFormEdit.header };
                        header.imageValidationError1 = "";
                        header.imageUpload1 = reader.result.toString();;
                        dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
                    }
                }
            };
            reader.readAsDataURL(createFile);
        }
        else {
            const appState = getState();
            if (appState && appState.wpsFormEdit) {
                let header: HeaderWps = { ...appState.wpsFormEdit.header };
                header.imageValidationError1 = fileValidationResult;
                dispatch({ type: 'WPSFORMEDIT_SET_HEADER', header: header });
            }
        }
    }
};

// ----------------
// REDUCERddddd - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WPSFormEditStoreState = {
    updatingWps: false,
    wpsId: 0,
    selectedWpqrId: 0,
    header: {
        date: '',
        project: '',
        selectedByStandard: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        revisionNumber: '',
        pwpsNumber: '',
        wpqrNumber: '',
        wpsType: '',
        wpsDekraNumber: '',
        wpsHeatInputText: '',
        wpsArcEnergyText: '',
        revisionWpsAction: '',
        showChooseWpsTypeModal: false,
        selectedWpsId: 0,
        otherOptions: [],
        otherOptionParametersTableId: 0,
        manufacturer: '',
        examiner: '',
        confirmedMessage: '',
        successStatusCode: false,
        dekraContactEmail: '',
        bodyTemplateHtml: '',
        imageUpload1: '',
        imageUpload2: '',
        imageValidationError2: '',
        imageValidationError1: '',
        selectedWeldingPosition2: false,
        customerLogo: '',
        employeeDisplayName: '',
        enableStandardText: false,
        standardText: ''
    },
    standards: [],
    parentMaterial: {
        baseMaterial1SearchText: '',
        baseMaterial1SearchResult: [],
        selectedBaseMaterial1: {
            id: 0,
            name: '',
            number: '',
            description: '',
            materialGroup: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            technicalDeliveryConditions: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        baseMaterial2SearchText: '',
        baseMaterial2SearchResult: [],
        selectedBaseMaterial2: {
            id: 0,
            name: '',
            number: '',
            description: '',
            materialGroup: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            technicalDeliveryConditions: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedMaterialGroup1: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedMaterialGroup2: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedTechnicalDeliveryCondition1: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedTechnicalDeliveryCondition2: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        diameter1: '',
        diameter2: '',
        materialThickness1: '',
        materialThickness2: '',
        enableBaseMaterial1Text: false,
        enableBaseMaterial2Text: false,
        baseMaterial1Text: '',
        baseMaterial2Text: '',
        materialGroup1Text: '',
        materialGroup2Text: '',
        technicalDeliveryCondition1Text: '',
        technicalDeliveryCondition2Text: '',
        enableMaterialThickness: false,
        materialThickness1Text: '',
        materialThickness2Text: '',
        enableDiameter: false,
        diameter1Text: '',
        diameter2Text: '',
    },
    weldingData: {
        jointTypes: [],
        selectedJointType: {
            id: 0,
            name: '',
            description: '',
            jointShapes: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        maxWidthOfRun: '',
        rootSupportDetailsSearchResult: [],
        selectedRootSupportDetails: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        weldingPositionSearchResult: [],
        selectedWeldingPosition: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedWeldingPosition2: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        lengthTackWelds: '',
        tungstenElectrode: '',
        electrodeTorchAngle: '',
        nozzleDiameter: '',
        rootProtectionGasSearchText: '',
        rootProtectionGasSearchResult: [],
        selectedRootProtectionGas: {
            id: 0,
            name: '',
            content: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        stickOut: '',
        jointPreparations: [],
        selectedJointPreparationText: '',
        tackWeldText: '',
        electrodeTorchAngle111: '',
        selectedPlasmaGas: {
            id: 0,
            name: '',
            content: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        plasmaflowrate: '',
        enableJointTypeText: false,
        jointTypeText: '',
    },
    selectedStandard: {
        id: 0,
        name: '',
        description: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    weldingProcess: {
        weldingMethods: [],
        weldingMethodASearchText: '',
        weldingMethodASearchResult: [],
        selectedWeldingMethodA: {
            id: 0,
            name: '',
            efficiencyFactor: 0.0,
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        makeASearchText: '',
        makeASearchResult: [],
        selectedMakeA: {
            id: 0,
            name: '',
            description: '',
            designation: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            fmGroup: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedDesignationA: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedFMGroupA: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        shieldingGasASearchText: '',
        shieldingGasASearchResult: [],
        selectedShieldingGasA: {
            id: 0,
            name: '',
            content: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        arcTypeSearchResult: [],
        selectedArcTypeA: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        weldingMethodBSearchText: '',
        weldingMethodBSearchResult: [],
        selectedWeldingMethodB: {
            id: 0,
            name: '',
            efficiencyFactor: 0.0,
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        makeBSearchText: '',
        makeBSearchResult: [],
        selectedMakeB: {
            id: 0,
            name: '',
            description: '',
            designation: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            fmGroup: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedDesignationB: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedFMGroupB: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        shieldingGasBSearchText: '',
        shieldingGasBSearchResult: [],
        selectedShieldingGasB: {
            id: 0,
            name: '',
            content: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedArcTypeB: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        enableMakeAText: false,
        makeAText: '',
        designationAText: '',
        fmGroupAText: '',
        enableMakeBText: false,
        makeBText: '',
        designationBText: '',
        fmGroupBText: '',
        typeOfBackingText: ''
    },
    showJointDesignModal: false,
    showWeldingParametersSettingsModal: false,
    weldingParameters: [],
    weldingParametersSettings: {
        showRun: true,
        showIndex: true,
        showDiameter: true,
        showVoltage: true,
        showCurrent: true,
        showWireFeed: true,
        showFlowRateTop: true,
        showFlowRateRoot: true,
        showCurrentType: true,
        showTravelSpeed: true,
        showHeatInput: true,
        travelSpeedZ: '',
        voltageX: '',
        currentY: '',
        travelSpeedZView: '',
        voltageXView: '',
        currentYView: ''
    },
    weldingParametersSettingsSave: {
        showRun: true,
        showIndex: true,
        showDiameter: true,
        showVoltage: true,
        showCurrent: true,
        showWireFeed: true,
        showFlowRateTop: true,
        showFlowRateRoot: true,
        showCurrentType: true,
        showTravelSpeed: true,
        showHeatInput: true,
        travelSpeedZ: '',
        voltageX: '',
        currentY: '',
        travelSpeedZView: '',
        voltageXView: '',
        currentYView: ''
    },
    indexes: [],
    currentTypes: [],
    temperature: {
        preheatTemperature: '',
        maxInterpassTemperature: ''
    },
    afterWelding: {
        heatingRate: '',
        coolingRate: ''
    },
    otherInformation: {
        otherInformation: ''
    },
    jointDesign: {
        id: 0,
        materialThickness1: 0.0,
        materialThickness2: 0.0,
        jointType: {
            id: 0,
            name: '',
            description: '',
            jointShapes: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        jointShape: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        jointAngleAlpha: 0.0,
        gapOpening: 0.0,
        straightEdge: 0.0,
        phaseAngle1: 0.0,
        phaseAngle2: 0.0,
        jointAngleBeta: 0.0,
        throatThickness: 0.0,
        rootSupport: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        depthOfPreparation: 0.0,
        radius: 0.0,
        note: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: '',
        jointDesignHtmlImage: ''
    },
    weldSequence: {
        createdAt: '',
        deletedAt: '',
        updatedAt: '',
        imageId: 0,
        imageData: '',
        sequenceNumber1: '',
        sequenceNumber2: '',
        sequenceNumber3: '',
        sequenceNumber4: '',
        sequenceNumber5: '',
        sequenceNumber6: '',
        sequenceNumber7: '',
        sequenceNumber8: '',
        sequenceNumber9: '',
        sequenceNumber10: '',
        sequenceNumber11: '',
        sequenceNumber12: '',
        sequenceNumber13: '',
        sequenceNumber14: '',
        sequenceNumber15: '',
    },
};

export const reducer: Reducer<WPSFormEditStoreState> = (state: WPSFormEditStoreState | undefined, incomingAction: Action): WPSFormEditStoreState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'WPSFORMEDIT_GET_WPSS':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding, 
                otherInformation: state.otherInformation,
                jointDesign: state.jointDesign,
                weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_WPQR_ID':
            return {
                selectedWpqrId: action.selectedWpqrId,
                wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_HEADER':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: action.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_GET_STANDARDS_RECEIVE':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: action.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_PARENT_MATERIAL':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: action.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_WELDING_DATA':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: action.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_WELDING_PROCESS':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: action.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_TOGGLE_JOINT_DESIGN_MODAL':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: action.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_WELDING_PARAMETERS':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: action.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_INIT_PWPS':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: action.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: action.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_GET_INDEXES_RECEIVE':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: action.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_GET_CURRENT_TYPES_RECEIVE':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: action.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_AFTER_WELDING':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: action.afterWelding,
                
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_TEMPERATURE':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: action.temperature,
                afterWelding: state.afterWelding,
	 	 	 	otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_OTHER_INFORMATION':
            return {
                selectedWpqrId: state.selectedWpqrId,
	 	 	 	wpsId: state.wpsId,
	 	 	 	updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
                otherInformation: action.otherInformation,
                jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_CREATE_UPATE_WPS_RECEIVE':
            return {
                selectedWpqrId: state.selectedWpqrId,
                wpsId: action.wpsId,
                updatingWps: false,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
                otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_CREATE_UPATE_WPS_REQUEST':
            return {
                selectedWpqrId: state.selectedWpqrId,
                wpsId: state.wpsId, 	 	 	
                updatingWps: true,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
                otherInformation: state.otherInformation,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_JOINT_DESIGN_RECEIVE':
            return {
                selectedWpqrId: state.selectedWpqrId,
                wpsId: state.wpsId,
                updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
                otherInformation: state.otherInformation,
                jointDesign: action.jointDesign,
                weldSequence: state.weldSequence,
            };
        case 'WPSFORMEDIT_SET_WELD_SEQUENCE_RECEIVE':
            return {
                selectedWpqrId: state.selectedWpqrId,
                wpsId: state.wpsId,
                updatingWps: state.updatingWps,
                header: state.header,
                standards: state.standards,
                parentMaterial: state.parentMaterial,
                weldingData: state.weldingData,
                selectedStandard: state.selectedStandard,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                weldingParameters: state.weldingParameters,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                temperature: state.temperature,
                afterWelding: state.afterWelding,
                otherInformation: state.otherInformation,
                jointDesign: state.jointDesign,
                weldSequence: action.weldSequence,
            };
        default:
            return state;
    }
};