﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Table } from 'reactstrap';
import { ApplicationState } from '../../store/index';
import * as HistoryProgressStore from '../../store/otherStore/HistoryProgressStore';
import { t } from '../../i18n';
import { UpdateHistoryProgressRequest } from '../../store/models/requests/UpdateHistoryProgressRequest';

// At runtime, Redux will merge together...
type HistoryProgressProps =
    HistoryProgressStore.HistoryProgressState // ... state we've requested from the Redux store
    & typeof HistoryProgressStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    applicationId?: number;
    showToSetProgressId2?: boolean;
    showToSetProgressId3?: boolean;
    showHistoriesProgressList?: boolean;
    showHistoriesTable?: boolean;
    customerId?: number;
}

class HistoryProgress extends React.PureComponent<HistoryProgressProps> {
    constructor(props: HistoryProgressProps) {
        super(props);
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        // Clear all store
        this.props.clearState();
        this.props.setCustomerId(JSON.parse(localStorage.getItem('customerId') === null ? 0 : JSON.parse(localStorage.getItem('customerId'))));
        this.props.getHistoriesProgress(JSON.parse(localStorage.getItem('customerId') === null  ? 0 : JSON.parse(localStorage.getItem('customerId'))));
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.applicationId !== this.props.applicationId) {
            this.props.getByApplicationId(this.props.applicationId);
        }
        if (this.props.customerId !== this.props.activeCustomerId) {
            this.props.getHistoriesProgress(this.props.customerId === null ? 0 : this.props.customerId );
            this.props.setCustomerId(this.props.customerId === null ? 0 : this.props.customerId);
        }
    }

    public state = {
        showHistoryProgressModal: false,
    };

    private toggleShowHistoryProgressModal = () => {
        this.setState({
            showHistoryProgressModal: !this.state.showHistoryProgressModal
        });
    }

    private onClickShowModal(id: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.getHistoriesProgress(id);
        this.toggleShowHistoryProgressModal();
    }

    private onClickProgressId2(id: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        let updateHistoryProgressRequest: UpdateHistoryProgressRequest = {
            applicationId: id,
            createdAt: '',
            id: 0,
            wpsName: "",
            pwpsName: "",
            standard: "",
            wpqrNumber: "",
            progressId1: "",
            progressId2: "success",
            progressId3: "warning",
            progressId4: "",
            customerId: 0,
        }
        this.props.updateHistoryProgress(updateHistoryProgressRequest);
    }

    private onClickProgressId3(id: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        let updateHistoryProgressRequest: UpdateHistoryProgressRequest = {
            applicationId: id,
            createdAt: '',
            id: 0,
            wpsName: "",
            pwpsName: "",
            standard: "",
            wpqrNumber: "",
            progressId1: "",
            progressId2: "",
            progressId3: "success",
            progressId4: "warning",
            customerId: 0,
        }
        this.props.updateHistoryProgress(updateHistoryProgressRequest);
    }

    private onClickModalCancel = (event: React.MouseEvent) => {
        this.toggleShowHistoryProgressModal();
    }
    
    public render() {
        return (
            <React.Fragment>
                {this.props.showHistoriesProgressList ?
                    <div>
                        { this.rendeHistoryProgressModal() }
                        < FormGroup className="float-right">
                            <Button type="button" color="primary" id="history" name="history" className="btn-sm" 
                                onClick={(event) => this.onClickShowModal(JSON.parse(localStorage.getItem('customerId')!), event)}>
                                {t('History')}{"  "}
                            </Button>
                        </FormGroup>
                    </div>: null
                }
                {this.props.showToSetProgressId2 ?
                    < FormGroup className="float-right">
                        <Button type="button" disabled={this.props.historyProgress.progressId2 === "success"} color="primary" id="progressId2 " name="progressId2"
                            onClick={(event) => this.onClickProgressId2(this.props.applicationId, event)}>
                            {t('Monitoring')}{" "}{t('Ready')}{"  "}
                            </Button>
                        </FormGroup>: null
                }
                {this.props.showToSetProgressId3 ?
                    < FormGroup className="float-right">
                        <Button type="button" disabled={this.props.historyProgress.progressId3 === "success"} color="primary" id="progressId3" name="progressId3"
                            onClick={(event) => this.onClickProgressId3(this.props.applicationId, event)}>
                            {t('Testing')}{" "}{t('Ready')}{"  "}
                        </Button>
                    </FormGroup> : null
                }
                {this.props.showHistoriesTable ?
                    this.renderHistoryProgressTable() : null
                }
                
            </React.Fragment>
        );
    }

    private rendeHistoryProgressModal() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.showHistoryProgressModal} size="lg">
                    <ModalHeader>
                        {t('History')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                {this.renderHistoryProgressTable()}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={(event) => this.onClickModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

    private renderHistoryProgressTable() {
        return (
            <React.Fragment key="HistoryProgress">
                <Table size="sm" responsive className="cert-table">
                    <thead >
                        {this.renderHistoryProgressHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.historiesProgress.map((row, index) => (
                            this.renderHistoryProgressTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderHistoryProgressHeaderRow() {
        return (
            <React.Fragment key="Wparameters141">
                <tr>
                    <th style={{ textAlign: "left" }}><a>{t('pWPSNumber')}</a></th>
                    <th style={{ textAlign: "left" }}><a>{t('Standard')}</a></th>
                    <th><a>{t('PrelWPQRNumber')}</a></th>
                    <th><a>{t('Created')}</a></th>
                    <th style={{ width: '40%' }}><a>{t('Progress')}</a></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderHistoryProgressTableRow(rowNumber: number) {
        return (
            <React.Fragment key={"HistoryProgressTableRow" + rowNumber}>
                <tr>
                    <td style={{ textAlign: "left" }} >
                        <Label className="label-sm-form-size" for={"wpsName" + rowNumber} size="sm">{this.props.historiesProgress[rowNumber - 1].wpsName}</Label>
                    </td>
                    <td style={{ textAlign: "left" }}>
                        <Label className="label-sm-form-size" for={"standard" + rowNumber} size="sm">{this.props.historiesProgress[rowNumber - 1].standard}</Label>
                    </td>
                    <td>
                        <Label className="label-sm-form-size" for={"wpqrNumber" + rowNumber} size="sm">{this.props.historiesProgress[rowNumber - 1].wpqrNumber}</Label>
                    </td>
                    <td>
                        <Label className="label-sm-form-size" for={"createdAt" + rowNumber} size="sm">{this.props.historiesProgress[rowNumber - 1].createdAt}</Label>
                    </td>
                    <td style={{ width: '40%' }}>
                        <Progress multi>
                            <Progress bar animated color={this.props.historiesProgress[rowNumber - 1].progressId1} value="22">{t('Application')}</Progress>
                            <Progress bar animated color={this.props.historiesProgress[rowNumber - 1].progressId2} value="23">{t('Monitoring')}</Progress>
                            <Progress bar animated color={this.props.historiesProgress[rowNumber - 1].progressId3} value="20">{t('Testing')}</Progress>
                            <Progress bar animated color={this.props.historiesProgress[rowNumber - 1].progressId4} value="35">{t('ExaminationWPQR')}</Progress>
                        </Progress>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.historyProgress;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    HistoryProgressStore.actionCreators // Selects which action creators are merged into the component's props
)(HistoryProgress as any);
