﻿import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { ApplicationState } from '../store/index';
import * as ApplicationsStore from '../store/ApplicationsStore';
import { GetApplicationResponse } from '../store/models/responses/GetApplicationResponse';
import { SortOrder } from '../store/models/SortOrder';
import { t } from '../i18n';

// At runtime, Redux will merge together...
type ApplicationsProps =
    ApplicationsStore.ApplicationsState // ... state we've requested from the Redux store
    & typeof ApplicationsStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    applications: Array<GetApplicationResponse>;
    onDeleteApplication: (applicationId: number) => void;
    onSelectApplication: (applicationId: number, wpsId: number) => void;
    onSelectApplicationWithWpqrId: (applicationId: number, wpsId: number, wpqrId: number) => void;
    onSetSupervisor: (applicationId: number, supervisor: string) => void;
    onSortApplications: (sortProperty: string, sortOrder: SortOrder) => void;
}

class Applications extends React.PureComponent<ApplicationsProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.applications !== prevProps.applications) {
            this.props.GetApplicationsSearchResult(this.props.applications)
        }
    }
    private onChangeSupervisor(event: React.ChangeEvent<HTMLInputElement>, applicationId: number) {
        event.stopPropagation();
        this.props.onSetSupervisor(applicationId, event.currentTarget.value);
    }

    private onClickDeleteApplication(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, selectedApplication: GetApplicationResponse) {
        event.preventDefault();
        this.props.setSelectedApplication(selectedApplication);
        this.props.setShowDeleteApplicationModal(true);
    }

    private onClickDeleteApplicationModalCancel(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowDeleteApplicationModal(false);
    }

    private onClickDeleteApplicationModalOK(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.onDeleteApplication(this.props.selectedApplication.id);
        this.props.setShowDeleteApplicationModal(false);
    }

    private onClickSelectApplication(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, applicationId: number, wpsId: number) {
        event.preventDefault();
        this.props.onSelectApplication(applicationId, wpsId);
    }

    private onClickSelectApplicationWithWpqrId(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, applicationId: number, wpsId: number, wpqrId: number) {
        event.preventDefault();
        this.props.onSelectApplicationWithWpqrId(applicationId,wpsId, wpqrId);
    }
    private onClickTableHeader(event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>, sortProperty: string) {
        event.preventDefault();
        let newSortOrder: SortOrder = this.props.sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
        this.props.onSortApplications(sortProperty, newSortOrder);
        this.props.setSortOrder(sortProperty, newSortOrder);
    }

    private onChangeFreeSearch(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setSearchTerm(event.currentTarget.value);
        this.props.GetApplicationsSearchResult(this.props.applications);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
    public render() {
        return (
            <React.Fragment key={"applications"}>
                <div>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={4}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" bsSize="sm" name="freeSearch"
                                        id="freeSearchField"
                                        style={{ height: "33px" }}
                                        value={this.props.searchTerm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFreeSearch(event)}
                                        placeholder={t('Search')} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Table size="sm" responsive={true} className="cert-table table-striped">
                                <thead>
                                    {this.renderTableHeaderRow()}
                                </thead>
                                <tbody>
                                    {this.renderDeleteApplicationModal()}
                                    {
                                        this.props.searchTerm.length > 0 ?
                                        this.props.applicationsSearchResult.map((application, index) => (
                                            this.renderTableRow(application)
                                        ))
                                        :
                                        this.props.applications.map((application, index) => (
                                            this.renderTableRow(application)
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    private renderDeleteApplicationModal() {
        return (
            <React.Fragment key={"deleteApplicationModal"}>
                <Modal centered={true} size="md" isOpen={this.props.showDeleteApplicationModal}>
                    <ModalHeader>
                        Ta bort ansökan
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                <p>Är du säker på att du vill ta bort ansökan <strong>{this.props.selectedApplication.dekraNumber}</strong>?</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickDeleteApplicationModalOK(event)}>OK</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickDeleteApplicationModalCancel(event)}>Avbryt</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

    private renderTableHeaderRow() {
        return (
            <React.Fragment key={"tableHeaderRow"}>
                <tr className="applications-table-header-row">
                    <th className="text-nowrap">
                    </th>
                    <th className="text-nowrap">
                    </th>
                    <th className="text-nowrap" onClick={(event) => this.onClickTableHeader(event, 'application.company.name')}>
                        {this.props.sortProperty === 'application.company.name' && this.props.sortOrder === SortOrder.Ascending &&
                            <i className="fas fa-sort-down"></i>
                        }
                        {this.props.sortProperty === 'application.company.name' && this.props.sortOrder === SortOrder.Descending &&
                            <i className="fas fa-sort-up"></i>
                        }
                        {this.props.sortProperty !== 'application.company.name' &&
                            <i className="fas fa-sort"></i>
                        }
                        <a>{t('CompanyName')}</a>
                    </th>
                    <th className="text-nowrap" onClick={(event) => this.onClickTableHeader(event, 'application.company.contactPerson')}>
                        {this.props.sortProperty === 'application.company.contactPerson' && this.props.sortOrder === SortOrder.Ascending &&
                            <i className="fas fa-sort-down"></i>
                        }
                        {this.props.sortProperty === 'application.company.contactPerson' && this.props.sortOrder === SortOrder.Descending &&
                            <i className="fas fa-sort-up"></i>
                        }
                        {this.props.sortProperty !== 'application.company.contactPerson' &&
                            <i className="fas fa-sort"></i>
                        }
                        <a>{t('Facilitator')}</a>
                    </th>
                    <th className="text-nowrap" onClick={(event) => this.onClickTableHeader(event, 'application.pwps.pwpsNumber')}>
                        {this.props.sortProperty === 'application.pwps.pwpsNumber' && this.props.sortOrder === SortOrder.Ascending &&
                            <i className="fas fa-sort-down"></i>
                        }
                        {this.props.sortProperty === 'application.pwps.pwpsNumber' && this.props.sortOrder === SortOrder.Descending &&
                            <i className="fas fa-sort-up"></i>
                        }
                        {this.props.sortProperty !== 'application.pwps.pwpsNumber' &&
                            <i className="fas fa-sort"></i>
                        }
                        <a>{t('pWPSNo')}</a>
                    </th>
                    <th className="text-nowrap" onClick={(event) => this.onClickTableHeader(event, 'application.pwps.revisionNumber')}>
                        {this.props.sortProperty === 'application.pwps.revisionNumber' && this.props.sortOrder === SortOrder.Ascending &&
                            <i className="fas fa-sort-down"></i>
                        }
                        {this.props.sortProperty === 'application.pwps.revisionNumber' && this.props.sortOrder === SortOrder.Descending &&
                            <i className="fas fa-sort-up"></i>
                        }
                        {this.props.sortProperty !== 'application.pwps.revisionNumber' &&
                            <i className="fas fa-sort"></i>
                        }
                        <a>{t('pWPSRevisionNo')}</a>
                    </th>
                    <th className="text-nowrap" onClick={(event) => this.onClickTableHeader(event, 'application.byStandard.name')}>
                        {this.props.sortProperty === 'application.byStandard.name' && this.props.sortOrder === SortOrder.Ascending &&
                            <i className="fas fa-sort-down"></i>
                        }
                        {this.props.sortProperty === 'application.byStandard.name' && this.props.sortOrder === SortOrder.Descending &&
                            <i className="fas fa-sort-up"></i>
                        }
                        {this.props.sortProperty !== 'application.byStandard.name' &&
                            <i className="fas fa-sort"></i>
                        }
                        <a>{t('ByStandard')}</a>
                    </th>
                    <th className="text-nowrap">
                        <a>{t('Monitor')}</a>
                    </th>
                    <th className="text-nowrap">
                        <a>{t('Examiner')}</a>
                    </th>
                    <th className="text-nowrap" onClick={(event) => this.onClickTableHeader(event, 'application.pwps.wpqrNumber')}>
                        {this.props.sortProperty === 'application.pwps.wpqrNumber' && this.props.sortOrder === SortOrder.Ascending &&
                            <i className="fas fa-sort-down"></i>
                        }
                        {this.props.sortProperty === 'application.pwps.wpqrNumber' && this.props.sortOrder === SortOrder.Descending &&
                            <i className="fas fa-sort-up"></i>
                        }
                        {this.props.sortProperty !== 'application.pwps.wpqrNumber' &&
                            <i className="fas fa-sort"></i>
                        }
                        <a>{t('WPQRNo')}</a>
                    </th>
                    <th className="text-nowrap" onClick={(event) => this.onClickTableHeader(event, 'application.confirmedAt')}>
                        {this.props.sortProperty === 'application.confirmedAt' && this.props.sortOrder === SortOrder.Ascending &&
                            <i className="fas fa-sort-down"></i>
                        }
                        {this.props.sortProperty === 'application.confirmedAt' && this.props.sortOrder === SortOrder.Descending &&
                            <i className="fas fa-sort-up"></i>
                        }
                        {this.props.sortProperty !== 'application.confirmedAt' &&
                            <i className="fas fa-sort"></i>
                        }
                        <a>{t('Confirmed')}</a>
                    </th>
                </tr>
            </React.Fragment>
        );
    }

    private renderTableRow(application: GetApplicationResponse) {
        return (
            <React.Fragment key={"tableRow" + application.id}>
                <tr>
                    <td className="text-nowrap">
                        {application.wpqrId === 0 ?
                            <Button color="link" size="sm" name={"selectApplicationButton-" + application.id} id={"selectApplicationButton-" + application.id} onClick={(event) => this.onClickSelectApplication(event, application.id, application.wpsId)}>
                                <i className="fas fa-check"></i>
                            </Button> : <Button color="link" size="sm" name={"selectApplicationButton-" + application.id} id={"selectApplicationButton-" + application.id} onClick={(event) => this.onClickSelectApplicationWithWpqrId(event, application.id, application.wpsId, application.wpqrId)}>
                                <i className="fas fa-check"></i>
                            </Button>
                        }
                            <UncontrolledTooltip placement="right" target={"selectApplicationButton-" + application.id}>
                            Öppna!
                            </UncontrolledTooltip>
                    </td>
                    <td className="text-nowrap">
                        <Button color="link" size="sm" name={"deleteApplicationButton-" + application.id} id={"deleteApplicationButton-" + application.id} onClick={(event) => this.onClickDeleteApplication(event, application)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                        <UncontrolledTooltip placement="right" target={"deleteApplicationButton-" + application.id}>
                            Ta bort!
                            </UncontrolledTooltip>
                    </td>
                    <td className="text-nowrap">
                        {application.company.name}
                    </td>
                    <td className="text-nowrap">
                        {application.company.contactPerson}
                    </td>
                    <td className="text-nowrap">
                        {application.pwps.pwpsNumber}
                    </td>
                    <td className="text-nowrap">
                        {application.pwps.revisionNumber}
                    </td>
                    <td className="text-nowrap">
                        {application.pwps.byStandard.name}
                    </td>
                    <td className="text-nowrap">
                        <Input readOnly type="text" bsSize="sm" name={"applicationsSupervisor-" + application.id} id={"applicationsSupervisor-" + application.id}
                            value={application.supervisor}
                            onChange={(event) => this.onChangeSupervisor(event, application.id)}
                            placeholder="" />
                    </td>
                    <td className="text-nowrap">
                        <Input readOnly type="text" bsSize="sm" name={"applicationsExaminer-" + application.id} id={"applicationsExaminer-" + application.id}
                            value={application.examiner}
                            placeholder="" />
                    </td>
                    <td className="text-nowrap">
                        {application.pwps.wpqrNumber}
                    </td>
                    <td className="text-nowrap">
                        {application.confirmedAt}
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.applications;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    ApplicationsStore.actionCreators // Selects which action creators are merged into the component's props
)(Applications as any);
