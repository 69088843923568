﻿import { WPQR } from './WPQR';
import { GetWeldingMethodResponse } from './models/responses/GetWeldingMethodResponse';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { WPQRSearchProperties } from './models/WPQRSearchProperties';
import { WPQRTabSettingColumns } from './models/WPQRTabSettingColumns';
import { GetProductTypeResponse } from './models/responses/GetProductTypeResponse';
import { GetJointTypeResponse } from './models/responses/GetJointTypeResponse';
import { GetMaterialGroupResponse } from './models/responses/GetMaterialGroupResponse';
import { GetRunTypeRangeOfQualificationResponse } from './models/responses/GetRunTypeRangeOfQualificationResponse';
import { GetStandardResponse } from './models/responses/GetStandardResponse';
import * as authHeader from "./models/auth-header";
import EventBus  from './eventBus';
import { ExistingWpqrRequest } from './models/requests/ExistingWpqrRequest';
import { UpdateWeldingParametersRequest } from './models/requests/UpdateWeldingParametersRequest';
import { ExistingWpqrData } from './models/ExistingWpqrData';
import { GetJointDesignResponse } from './models/responses/GetJointDesignResponse';
import { GetWeldSequenceResponse } from './models/responses/GetWeldSequenceResponse';
import HelpFunctions from './models/HelpFunctions';
import { WeldingTestingDetails1 } from './models/WeldingTestingDetails1';
import { RangeOfQualificationInfo } from './models/RangeOfQualificationInfo';
import { WeldingParameters } from './models/WeldingParameters';
import { UpdateFilesUploadRequest } from './models/requests/UpdateFilesUploadRequest';
import { ExistingWpqrResponse } from './models/responses/ExistingWpqrResponse';
import { GetExistingWpqrResponse } from './models/responses/GetExistingWpqrResponse';
import { ExistingWpqrRevision } from './models/ExistingWpqrRevision';
import { GetTestingDetails1ExaminationResponse } from './models/responses/GetTestingDetails1ExaminationResponse';
import { GetRangeOfQualificationExaminationResponse } from './models/responses/GetRangeOfQualificationExaminationResponse';
import { RevisionActionType } from './models/RevisionActionType';
import { GetCurrentTypeResponse } from './models/responses/GetCurrentTypeResponse';
import { ExistingWpqrDataExtra } from './models/ExistingWpqrDataExtra';

// STATE - This defines the type of data maintained in the Redux store.
export interface WPQRTabStoreState {
    wpqrs: Array<WPQR>;
    wpqrsSearchResult: Array<WPQR>;
    searchProperties: WPQRSearchProperties;
    showTabSettingModal: boolean;
    wpqrTabSettingColumns: WPQRTabSettingColumns;
    wpqrPaginationPageIndex: number;
    wpqrPaginationPageSize: number;
    wpqrWeldingMethodsSearch: Array<GetWeldingMethodResponse>;
    activeTabId: boolean;
    existingWpqrData: ExistingWpqrData;
    existingWpqrRevision: ExistingWpqrRevision;
    currentlyLoading: boolean;
    currentTypes: Array<GetCurrentTypeResponse>;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetExistingWpqrDataLoading {
    type: 'SET_EXISTING_WPQR_DATA_LOADING';
    currentlyLoading: boolean;
}
interface GetWPQRsAction {
    type: 'GET_WPQRS';
    wpqrs: Array<WPQR>;
}
interface GetWPQRsRequestAction {
    type: 'GET_WPQRS_REQUEST';
}
interface SetWPQRsSearchResultAction {
    type: 'SET_WPQRS_SEARCH_RESULT';
    wpqrsSearchResult: Array<WPQR>;
}
interface SetEwpqrRevisionAction {
    type: 'EWPQR_SET_REVISION_RESULT';
    existingWpqrRevision: ExistingWpqrRevision;
}
interface SetSearchProperties {
    type: 'SET_SEARCH_PROPERTIES';
    searchProperties: WPQRSearchProperties;
}
interface GetWeldingMethondsSearchAction {
    type: 'SET_WELDING_METHODS_SEARCH';
    wpqrWeldingMethodsSearch: Array<GetWeldingMethodResponse>;
}

export interface SetShowTabSettingModalAction {
    type: 'SET_SHOW_TAB_SETTING_MODAL';
    showTabSettingModal: boolean;
}

export interface SetTabSettingColumnsAction {
    type: 'SET_TAB_SETTING_COLUMNS';
    wpqrTabSettingColumns: WPQRTabSettingColumns;
}

export interface SetPaginationPageIndexAction {
    type: 'SET_PAGINATION_PAGE_INDEX';
    wpqrPaginationPageIndex: number;
}

export interface GetWeldingMethondsSearchRequestAction {
    type: 'GET_WELDING_METHODS_SEARCH_REQUEST';
}
interface GetProductTypesRequestAction {
    type: 'GET_PRODUCT_TYPES_REQUEST';
}
interface GetJointTypesRequestAction {
    type: 'GET_JOINT_TYPES_REQUEST';
}

interface GetMaterialGroupRequestAction {
    type: 'GET_MATERIAL_GROUPS_REQUEST';
}

interface GetRunTypeRangeQualificationAction {
    type: 'GET_RUN_TYPE_RANGE_QUALIFICATION_REQUEST';
}

interface GetStandardsAction {
    type: 'GET_STANDARDS_REQUEST';
}

export interface SetExistingWpqrDataAction {
    type: 'SET_EXISTING_WPQR_DATA_ACTION';
    existingWpqrData: ExistingWpqrData;
}

export interface SetActiveTabId {
    type: 'SET_ACTIVE_TAB_ID';
    activeTabId: boolean;
}

export interface GetCurrentTypeReceiveAction {
    type: 'EXISTING_WPQR_GET_CURRENT_TYPES_RECEIVE';
    currentTypes: Array<GetCurrentTypeResponse>;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetWPQRsAction
    | GetWPQRsRequestAction
    | SetSearchProperties
    | SetWPQRsSearchResultAction
    | SetShowTabSettingModalAction
    | SetTabSettingColumnsAction
    | SetPaginationPageIndexAction
    | GetWeldingMethondsSearchAction
    | GetWeldingMethondsSearchRequestAction
    | GetProductTypesRequestAction
    | GetJointTypesRequestAction
    | GetMaterialGroupRequestAction
    | GetRunTypeRangeQualificationAction
    | GetStandardsAction
    | SetActiveTabId
    | SetExistingWpqrDataAction
    | SetExistingWpqrDataLoading
    | SetEwpqrRevisionAction
    | GetCurrentTypeReceiveAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    /*getWPQRs: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`wpqrs`)
            .then(response => response.json() as Promise<Array<WPQR>>)
            .then(data => {
                dispatch({ type: 'GET_WPQRS', wpqrs: data });
            });
    },*/
    getWPQRs: (customerId: number, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingProtocols/GetAllWpqrInclude/${customerId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
        .then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<WPQR>;
                   //console.info(data);
                const appState = getState();
                if (appState && appState.wpqrTab) {
                    let wpqrs: Array<WPQR> = { ...appState.wpqrTab.wpqrs };
                    wpqrs = [...data];
                    dispatch({ type: 'GET_WPQRS', wpqrs: wpqrs });
                    let wpqrsSearchResult: Array<WPQR> = { ...appState.wpqrTab.wpqrsSearchResult };

                    wpqrsSearchResult = [...data];
                    dispatch({ type: 'SET_WPQRS_SEARCH_RESULT', wpqrsSearchResult: wpqrsSearchResult });
                    callback();
                }
            }))
                .catch(error => {
                    console.error(error);
                });
            dispatch({ type: 'GET_WPQRS_REQUEST' });
    },
    getWeldingMethodsSearch: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingMethods/GetByDistinctWeldingMethodDescription`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingMethodResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpqrTab) {
                        let wpqrWeldingMethodsSearch: Array<GetWeldingMethodResponse> = { ...appState.wpqrTab.wpqrWeldingMethodsSearch };
                        wpqrWeldingMethodsSearch = [...data];
                        dispatch({ type: 'SET_WELDING_METHODS_SEARCH', wpqrWeldingMethodsSearch: wpqrWeldingMethodsSearch });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_WELDING_METHODS_SEARCH_REQUEST' });
    },
    getProductTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ProductTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
        .then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetProductTypeResponse>;
            console.info(data);
            const appState = getState();
            if (appState && appState.wpqrTab) {
                let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
                searchProperties.productTypes = [...data];;
                dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
            }
                
   
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_PRODUCT_TYPES_REQUEST' });
    },
    getJointTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/JointTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
        .then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetJointTypeResponse>;
        console.info(data);
            const appState = getState();
            if (appState && appState.wpqrTab) {
                let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
                searchProperties.jointTypes = [...data];;
                dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
            }

            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_JOINT_TYPES_REQUEST' });
    },
    getMaterialGroupAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/MaterialGroups/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetMaterialGroupResponse>;
                  console.info(data);
                const appState = getState();
                if (appState && appState.wpqrTab) {
                    let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
                    searchProperties.materialGroups = [...data];;
                    dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                }
                    
       
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MATERIAL_GROUPS_REQUEST' });
    },
    getRuntypeRangeOfQualification: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RunTypeRangeOfQualifications/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetRunTypeRangeOfQualificationResponse>;
            console.info(data);
            const appState = getState();
            if (appState && appState.wpqrTab) {
                let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
                let newRunTypeRangeOfQualifications = []
                for (let i = 0; i < [...data].length; i++) {
                    //exclude all hardnessTestingOrImpactTesting with false
                    if ([...data][i].hardnessTestingOrImpactTesting) {
                        newRunTypeRangeOfQualifications.push([...data][i])
                    }
                }
                searchProperties.runTypeRangeOfQualifications = [...newRunTypeRangeOfQualifications];
                dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
            }

            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_RUN_TYPE_RANGE_QUALIFICATION_REQUEST' });
    },
    getStandardsAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Standards/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetStandardResponse>;
            console.info(data);
            const appState = getState();
            if (appState && appState.wpqrTab) {
                let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
                searchProperties.standards = [...data];;
                dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
            }
            
            }))

            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_STANDARDS_REQUEST' });
    },
    getWPQRsSearchResult: (wpqrsList: Array<WPQR>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let newWpqrsList = []
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            let wpqrs: Array<WPQR> = { ...appState.wpqrTab.wpqrs };
            for (let i = 0; i < wpqrsList.length; i++) {

                if ((searchProperties.freeSearch === "" ? true : ((wpqrsList[i].dekraNumber.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpqrsList[i].wpqrNumber.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpqrsList[i].pwpsNumber.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpqrsList[i].make1Name.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpqrsList[i].make2DesignationName.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)))
                    && (searchProperties.selectedWeldingMethod.name === "" ? true : (wpqrsList[i].weldingMethod1Name.search(searchProperties.selectedWeldingMethod.name.split(",", 1)[0]) > -1) || (wpqrsList[i].weldingMethod2Name.search(searchProperties.selectedWeldingMethod.name.split(",", 1)[0]) > -1))
                    && (searchProperties.selectedProductType.name === "" ? true : (wpqrsList[i].productType1Name.search(searchProperties.selectedProductType.name.split(",", 1)[0]) > -1) || (wpqrsList[i].productType2Name.search(searchProperties.selectedProductType.name.split(",", 1)[0]) > -1))
                    && (searchProperties.materialThickness === "" ? true : compareValueBetweenMinAndMax(wpqrsList[i].materialThickness1RangeOfQualification, searchProperties.materialThickness))
                    && (searchProperties.outerDiameter === "" ? true : compareOuterDiameter(wpqrsList[i].diameter1Text, searchProperties.outerDiameter))
                    && (searchProperties.selectedJointType.name === "" ? true : compareJointType(wpqrsList[i].jointTypeName, searchProperties.selectedJointType.name))
                    && (searchProperties.selectedMaterialGroup.name === "" ? true : compareMaterialGroup(wpqrsList[i].materialGroupRangeOfQualification, searchProperties.selectedMaterialGroup.name))
                    && (searchProperties.selectedRunTypeRangeOfQualification.rangeOfQualification === "" ? true : compareRunTypeRangeOfQualification(wpqrsList[i].runTypeRangeOfQualification, searchProperties.selectedRunTypeRangeOfQualification.rangeOfQualification))
                    && (searchProperties.selectedStandard.name === "" ? true : compareStandard(wpqrsList[i].standardName, searchProperties.selectedStandard.name))) { 
                    newWpqrsList.push(wpqrsList[i]);
                }
            }
                wpqrs = newWpqrsList;
            
            dispatch({ type: 'SET_WPQRS_SEARCH_RESULT', wpqrsSearchResult: wpqrs });
        }

        function compareStandard(standardText: string, selectedStandard: string) {
            let returnValue: Boolean = false;

            if (standardText.indexOf(selectedStandard) > -1) {
                returnValue = true;
            }
            return returnValue;
        }

        function compareRunTypeRangeOfQualification(runTypeRangeOfQualificationText: string, selectedRangeOfQualification: string) {
            let returnValue: Boolean = false;

            if (runTypeRangeOfQualificationText.indexOf(selectedRangeOfQualification) > -1) {
                returnValue = true;
            }
            return returnValue;
        }

        function compareMaterialGroup(materialGroupRangeOfQualification: string, compareMaterialGroupParameter: string) {
            let returnValue: Boolean = false;
            let firtPart = compareMaterialGroupParameter.split(".");

            if (materialGroupRangeOfQualification.indexOf(compareMaterialGroupParameter) > -1) {
                returnValue = true;
            }

            if ((returnValue === false) && ((materialGroupRangeOfQualification.indexOf(" " + firtPart[0] + ",") > -1) || (materialGroupRangeOfQualification.indexOf(firtPart[0] + "*") > -1))) {
                returnValue = true;
            }

            if (returnValue === false) {
                let lastTwo = materialGroupRangeOfQualification.substr(materialGroupRangeOfQualification.length - 2);
                let comma = lastTwo.split(".")
                let chartA = lastTwo.split("a")
                if (comma.length === 1 && chartA.length === 1) {
                    if (lastTwo.indexOf(firtPart[0]) > -1) {
                        returnValue = true
                    }
                }
            }
            return returnValue;
        }

        function compareJointType(jointTypeText: string, materialGroupRangeOfQualification: string) {
            let returnValue: Boolean = false;

            if ((materialGroupRangeOfQualification.indexOf("(") > -1) && (materialGroupRangeOfQualification.indexOf(")") > -1)) {
                let jointType = materialGroupRangeOfQualification.substring(materialGroupRangeOfQualification.indexOf("(") + 1, materialGroupRangeOfQualification.indexOf(")"))
                if (jointTypeText.indexOf(jointType) > -1) {
                    returnValue = true;
                }
            }
            return returnValue;
        }

        function compareOuterDiameter(diameter1Text: string, compareDiameterParameter: string) { //Changes in table [DiameterRangeOfQualification], can make so we need to change here
            let returnValue: Boolean = false;
            let compareValueNumber = !isNaN(parseFloat(compareDiameterParameter.replace(',', '.'))) ? parseFloat(compareDiameterParameter.replace(',', '.')) : 0.0;
            let diameter1TextArray = diameter1Text.split(" ");

            if (diameter1TextArray.length > 1) {
                let compareValue = !isNaN(parseFloat(diameter1TextArray[1].replace(',', '.'))) ? parseFloat(diameter1TextArray[1].replace(',', '.')) : 0.0;
                let conditions = diameter1TextArray[0];
                switch (conditions) {
                    case "≥":
                        if (compareValueNumber >= compareValue ) {
                            returnValue = true;
                        }
                        break;
                    case "˃":
                        if (compareValueNumber > compareValue) {
                            returnValue = true;
                        }
                        break;
                    default:
                        break;
                }
            }
            return returnValue;
        }

        function compareValueBetweenMinAndMax(materialThicknessRangeOfQualification: string, compareValue: string) {//Changes in table [MaterialThicknessRangeOfQualification], can make so we need to change here
            let returnValue: Boolean = false;
            let compareValueNumber = !isNaN(parseFloat(compareValue.replace(',', '.'))) ? parseFloat(compareValue.replace(',', '.')) : 0.0;
            
            let BW = materialThicknessRangeOfQualification.substring(materialThicknessRangeOfQualification.indexOf("BW") + 4, materialThicknessRangeOfQualification.indexOf("FW") - 1).split(" ").join("");
            if (materialThicknessRangeOfQualification.indexOf("BW") > -1) {
                let BWMin = Number(BW.split("-")[0].replace(',', '.'));
                let BWMax = Number(BW.split("-")[1].replace(',', '.'));
                if ((BWMin <= compareValueNumber) && compareValueNumber <= BWMax ) {
                    returnValue = true;
                }
                    
            }

            let FW = materialThicknessRangeOfQualification.substring(materialThicknessRangeOfQualification.indexOf("FW") + 4, materialThicknessRangeOfQualification.length).split(" ").join("");
            if (materialThicknessRangeOfQualification.indexOf("FW") > -1 && returnValue === false) {
                let conditions = FW.split("-")[0];
                switch (conditions) {
                    case "≥5":
                        if (compareValueNumber  >= 5) {
                            returnValue = true;
                        }
                        break;
                    default:
                        let FWMin = Number(FW.split("-")[0].replace(',', '.'));
                        let FWMax = Number(FW.split("-")[1].replace(',', '.'));
                        if ((FWMin <= compareValueNumber) && (compareValueNumber <= FWMax)) {
                            returnValue = true;
                        }
                        break;
                }

            }
            return returnValue;
        }
    },
    setFreeSearch: (freeSearch: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            searchProperties.freeSearch = freeSearch;
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setMaterialThickness: (materialThickness: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            searchProperties.materialThickness = materialThickness;
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setOuterDiameter: (outerDiameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            searchProperties.outerDiameter = outerDiameter;
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setWeldingMethodForSearch: (weldingMethodId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let weldingMethods: Array<GetWeldingMethodResponse> = [...appState.wpqrTab.wpqrWeldingMethodsSearch];

            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };

            if (weldingMethodId !== 0) {
                for (let i = 0; i < weldingMethods.length; i++) {
                    if (weldingMethodId === weldingMethods[i].id) {
                        searchProperties.selectedWeldingMethod = { ...weldingMethods[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedWeldingMethod = {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setStandardForSearch: (StandardId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let standars: Array<GetStandardResponse> = [...appState.wpqrTab.searchProperties.standards];
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            if (StandardId !== 0) {
                for (let i = 0; i < standars.length; i++) {
                    if (StandardId === standars[i].id) {
                        searchProperties.selectedStandard = { ...standars[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedStandard = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedProductType: (productTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            if (productTypeId !== 0) {
                for (let i = 0; i < searchProperties.productTypes.length; i++) {
                    if (productTypeId === searchProperties.productTypes[i].id) {
                        searchProperties.selectedProductType = { ...searchProperties.productTypes[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedProductType = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedJointType: (jointTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            if (jointTypeId !== 0) {
                for (var i = 0; i < searchProperties.jointTypes.length; i++) {
                    if (jointTypeId === searchProperties.jointTypes[i].id) {
                        searchProperties.selectedJointType = { ...searchProperties.jointTypes[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedJointType = {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedMaterialGroup: (materialGroupId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            if (materialGroupId !== 0) {
                for (var i = 0; i < searchProperties.materialGroups.length; i++) {
                    if (materialGroupId === searchProperties.materialGroups[i].id) {
                        searchProperties.selectedMaterialGroup = { ...searchProperties.materialGroups[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedMaterialGroup = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedRunTypeRangeOfQualification: (runTypeRangeOfQualificationId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            if (runTypeRangeOfQualificationId !== 0) {
                for (var i = 0; i < searchProperties.runTypeRangeOfQualifications.length; i++) {
                    if (runTypeRangeOfQualificationId === searchProperties.runTypeRangeOfQualifications[i].id) {
                        searchProperties.selectedRunTypeRangeOfQualification = { ...searchProperties.runTypeRangeOfQualifications[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedRunTypeRangeOfQualification = {
                    id: 0,
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                    rangeOfQualification: '',
                    runType: 1,
                    hardnessTestingOrImpactTesting: false,
                }
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    clearSearchProperties: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let searchProperties: WPQRSearchProperties = { ...appState.wpqrTab.searchProperties };
            searchProperties = {
                freeSearch: '',
                welder: '',
                selectedWeldingMethod: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                productTypes: [],
                selectedProductType: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                materialThickness: '',
                outerDiameter: '',
                jointTypes: [],
                selectedJointType: {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                materialGroups: [],
                selectedMaterialGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                runTypeRangeOfQualifications: [],
                selectedRunTypeRangeOfQualification: {
                    id: 0,
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                    rangeOfQualification: '',
                    runType: 1,
                    hardnessTestingOrImpactTesting: false,

                },
                standards: [],
                selectedStandard: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
            }
            dispatch({ type: 'SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setShowTabSettingModal: (setShowTabSettingModal: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SHOW_TAB_SETTING_MODAL', showTabSettingModal: setShowTabSettingModal });
    },
    setActiveTabId: (value: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_ACTIVE_TAB_ID', activeTabId: true });
    },
    setTabSettingColumnDekraProcedureNumber: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.dekraProcedureNumber = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnmanufacturerWPQRNumber: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.manufacturerWPQRNumber = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnPwpsName: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.pwpsName = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnQualificationStandard: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.qualificationStandard = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnWeldingMethod: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.weldingMethod = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnJointTypes: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.jointTypes = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnProductType: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.productType = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnMaterialThickness: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.materialThickness = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnOutsideDiameter: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.outsideDiameter = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnMaterialGroup: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.materialGroup = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnSingleMultiString: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.singleMultiString = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setTabSettingColumnMakeAdditives: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrTabSettingColumns: WPQRTabSettingColumns = { ...appState.wpqrTab.wpqrTabSettingColumns };
            wpqrTabSettingColumns.makeAdditives = setTabSettingColumn;
            dispatch({ type: 'SET_TAB_SETTING_COLUMNS', wpqrTabSettingColumns: wpqrTabSettingColumns });
        }
    },
    setPaginationPageIndex: (pageIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let wpqrPaginationPageIndex = pageIndex
            dispatch({ type: 'SET_PAGINATION_PAGE_INDEX', wpqrPaginationPageIndex: wpqrPaginationPageIndex });
        }
    },
    initExistingWpqr: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
            existingWpqrData = new ExistingWpqrData();
            existingWpqrData.revisionEWpqrAction = RevisionActionType.Revision;

            // Initialize WeldingParameters.
            let weldingParameters: Array<WeldingParameters> = [...existingWpqrData.weldingParameters] ;
            if (weldingParameters.length === 0) {
                let weldingParametersRow = new WeldingParameters();
                weldingParametersRow.run = '1';
                weldingParametersRow.runText = '1';
                weldingParameters.splice(weldingParameters.length, 0, weldingParametersRow);
            }
            existingWpqrData.weldingParameters = [...weldingParameters];

            dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
        }
    },
    setWeldingTestingDetails1CommentInformation: (commentInformation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
            existingWpqrData.details1CommentInformation = commentInformation;
            dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
        }
    },
    setExaminer: (examiner: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
            existingWpqrData.existingWpqrDataExtra.examinerOrExaminingBody = examiner;
            dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
        }
    },
    setExaminerName: (examinerName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
            existingWpqrData.existingWpqrDataExtra.rangeOfName = examinerName;
            dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
        }
    },
    setExaminerDate: (examinerDate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
            existingWpqrData.existingWpqrDataExtra.rangeOfDate = examinerDate;
            dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
        }
    },
    setCommentInformation: (commentInformation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
            existingWpqrData.details1CommentInformation = existingWpqrData.details1CommentInformation === "" ? commentInformation : existingWpqrData.details1CommentInformation + "\n" + commentInformation;
            dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
        }
    },
    setSelectedRevisionIdTo0: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrRevision = { ...appState.wpqrTab.existingWpqrRevision };
            let selectedRevision: GetExistingWpqrResponse = { ...appState.wpqrTab.existingWpqrRevision.selectedRevision };
            selectedRevision.id = 0
            existingWpqrRevision.selectedRevision = selectedRevision;
            dispatch({ type: 'EWPQR_SET_REVISION_RESULT', existingWpqrRevision: existingWpqrRevision });
        }
    },
    setShowChooseExistingWpqrRevisionModal: (showChooseExistingWpqrRevisionModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrRevision: ExistingWpqrRevision = { ...appState.wpqrTab.existingWpqrRevision };
            existingWpqrRevision.showChooseExistingWpqrRevisionModal = showChooseExistingWpqrRevisionModal;
            dispatch({ type: 'EWPQR_SET_REVISION_RESULT', existingWpqrRevision: existingWpqrRevision });
        }
    },
    setSelectedRevision: (revisionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrRevision = { ...appState.wpqrTab.existingWpqrRevision };
            let selectedRevision: GetExistingWpqrResponse = { ...appState.wpqrTab.existingWpqrRevision.selectedRevision };
            for (let i = 0; i < existingWpqrRevision.revisions.length; i++) {
                if (existingWpqrRevision.revisions[i].id === revisionId) {
                    selectedRevision = { ...existingWpqrRevision.revisions[i] };
                    break;
                }
            }
            existingWpqrRevision.selectedRevision = selectedRevision;
            dispatch({ type: 'EWPQR_SET_REVISION_RESULT', existingWpqrRevision: existingWpqrRevision });
        }
    },
    enableExistingWpqrRevisionModal: (showChooseExistingWpqrRevisionModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrRevision: ExistingWpqrRevision = { ...appState.wpqrTab.existingWpqrRevision };
            existingWpqrRevision.showChooseExistingWpqrRevisionModal = showChooseExistingWpqrRevisionModal;
            existingWpqrRevision.selectedExistingWpqrRevisionModalOk = true;//Todo
            dispatch({ type: 'EWPQR_SET_REVISION_RESULT', existingWpqrRevision: existingWpqrRevision });
        }
    },
    setActiveSelectedExistingWpqrId: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            let existingWpqrRevision: ExistingWpqrRevision = { ...appState.wpqrTab.existingWpqrRevision };
            existingWpqrRevision.activeSelectedExistingWpqrId = id;
            dispatch({ type: 'EWPQR_SET_REVISION_RESULT', existingWpqrRevision: existingWpqrRevision });
        }
    },
    setCurrentlyLoading: (currentlyLoadin: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            dispatch({ type: 'SET_EXISTING_WPQR_DATA_LOADING', currentlyLoading: currentlyLoadin });
        }
    },
    getExistingWpqrRevisions: (existingWpqrId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ExistingWpqrs/GetByExistingWpqrIdRevisions/${existingWpqrId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetExistingWpqrResponse>;
                console.info(data);
                const appState = getState();
                if (appState && appState.wpqrTab) {
                    let existingWpqrRevision: ExistingWpqrRevision = { ...appState.wpqrTab.existingWpqrRevision };
                    existingWpqrRevision.revisions = [...data];
                    dispatch({ type: 'EWPQR_SET_REVISION_RESULT', existingWpqrRevision: existingWpqrRevision });
                }
            }))
            .catch(error => {
                console.error(error);
            });
    },
    getCurrentTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/CurrentTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetCurrentTypeResponse>;
                let currentTypes = [...data];
                let currentTypeEmpty = new GetCurrentTypeResponse();
                currentTypes.splice(currentTypes.length, 0, currentTypeEmpty);
                currentTypes.sort((a, b) => a.id - b.id);
                console.info(data);
                dispatch({ type: 'EXISTING_WPQR_GET_CURRENT_TYPES_RECEIVE', currentTypes: currentTypes });

            }))
            .catch(error => {
                console.error(error);
            });
    },
    removeExistingWpqr: (ExistingWpqrId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrTab) {
            fetch(`/api/ExistingWpqrs/DeleteByExistingWpqrId/${ExistingWpqrId}`, {
                method: 'DELETE',
                headers: authHeader.authHeader(),
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    EventBus.dispatch("refreshExistingWpqrList", "");
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
            dispatch({ type: 'SET_EXISTING_WPQR_DATA_LOADING', currentlyLoading: true });
        }
    },
    createImageDrawJointDesign: (createImage: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileValidationResult = HelpFunctions.fileValidation(createImage);
        if (fileValidationResult == "") {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    const appState = getState();
                    if (appState && appState.wpqrTab) {
                        let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
                        existingWpqrData.imageValidationError1 = "";
                        existingWpqrData.imageUpload1 = reader.result.toString();;
                        dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
                    }
                }
            };
            reader.readAsDataURL(createImage);
        }
        else {
            const appState = getState();
            if (appState && appState.wpqrTab) {
                let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
                existingWpqrData.imageValidationError1 = fileValidationResult;
                dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
            }
        }
    },
    createImageWeldSequence: (createImage: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileValidationResult = HelpFunctions.fileValidation(createImage);
        if (fileValidationResult == "") {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    const appState = getState();
                    if (appState && appState.wpqrTab) {
                        let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
                        existingWpqrData.imageValidationError2 = "";
                        existingWpqrData.imageUpload2 = reader.result.toString();;
                        dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
                    }
                }
            };
            reader.readAsDataURL(createImage);
        }
        else {
            const appState = getState();
            if (appState && appState.wpqrTab) {
                let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
                existingWpqrData.imageValidationError2 = fileValidationResult;
                dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
            }
        }
    },
    getByUserIdAndExistingWpqrStatus: (userId: string, existingWpqrStatus: number, callback: Function): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/ExistingWpqrs/GetByUserIdAndExistingWpqrStatus/${userId}/${existingWpqrStatus}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<GetExistingWpqrResponse>)
            .then(data => {
                console.info(data);
                data.id ? callback(data.id) : callback(0)
            })
            .catch(error => {
                console.error(error);
            });
    },
    getExistingWpqr: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ExistingWpqrs/GetByIdInclude/${id}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as GetExistingWpqrResponse;
                console.info(data);
                let selectedExistingWpqr: GetExistingWpqrResponse = { ...res.data };
                console.log(selectedExistingWpqr);
                const appState = getState();
                if (appState && appState.wpqrTab) {
                    let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
                    existingWpqrData.existingWpqrId = selectedExistingWpqr.id;
                    existingWpqrData.revisionEWpqrAction = selectedExistingWpqr.revisionEWpqrAction;
                    existingWpqrData.revisionEWpqrId = selectedExistingWpqr.revisionEWpqrId;

                    existingWpqrData.commissionNumber = selectedExistingWpqr.existingWpqrData.commissionNumber;
                    existingWpqrData.dekraProcedureNumber = selectedExistingWpqr.existingWpqrData.dekraProcedureNumber;
                    existingWpqrData.manufacturerWPQRNumber = selectedExistingWpqr.existingWpqrData.manufacturerWPQRNumber;

                    existingWpqrData.existingWpqrStatus = selectedExistingWpqr.existingWpqrStatus;

                    existingWpqrData.details1CommentInformation = selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse.commentInformation;

                    existingWpqrData.rangeOfQualificationInfo = { ...selectedExistingWpqr.existingWpqrData.rangeOfQualificationExaminationResponse };

                    existingWpqrData.weldingTestingDetails1 = { ...selectedExistingWpqr.existingWpqrData.testingDetails1ExaminationResponse };
                    existingWpqrData.existingWpqrDataExtra.examinerOrExaminingBody = existingWpqrData.weldingTestingDetails1.examiner;
                    existingWpqrData.existingWpqrDataExtra.rangeOfDate = existingWpqrData.weldingTestingDetails1.examinerDate;
                    existingWpqrData.existingWpqrDataExtra.rangeOfName = existingWpqrData.weldingTestingDetails1.examinerName;

                    existingWpqrData.jointDesign = { ...selectedExistingWpqr.existingWpqrData.jointDesign };

                    existingWpqrData.weldSequence = { ...selectedExistingWpqr.existingWpqrData.weldSequence };

                    let weldingParametersList = [];
                    for (let i = 0; i < selectedExistingWpqr.existingWpqrData.weldingParameters.length; i++) {
                        let weldingParameters: WeldingParameters = new WeldingParameters();
                        weldingParameters.id = selectedExistingWpqr.existingWpqrData.weldingParameters[i].id;
                        weldingParameters.run = selectedExistingWpqr.existingWpqrData.weldingParameters[i].run.toString();
                        weldingParameters.runText = selectedExistingWpqr.existingWpqrData.weldingParameters[i].runText;
                        weldingParameters.index = { ...selectedExistingWpqr.existingWpqrData.weldingParameters[i].index };
                        weldingParameters.weldingMethod = { ...selectedExistingWpqr.existingWpqrData.weldingParameters[i].weldingMethod };
                        weldingParameters.diameter = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].diameter);
                        weldingParameters.current = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].current);
                        weldingParameters.voltage = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].voltage);
                        weldingParameters.currentType = { ...selectedExistingWpqr.existingWpqrData.weldingParameters[i].currentType };
                        weldingParameters.runLength = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].runLength);
                        weldingParameters.time = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].time);
                        weldingParameters.interpassTemperature = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].interpassTemperature);
                        weldingParameters.wireFeed = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].wireFeed);
                        weldingParameters.runWidth = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].runWidth);
                        weldingParameters.travelSpeed = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].travelSpeed);
                        weldingParameters.heatInput = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].heatInput);
                        weldingParameters.createdAt = selectedExistingWpqr.existingWpqrData.weldingParameters[i].createdAt;
                        weldingParameters.deletedAt = selectedExistingWpqr.existingWpqrData.weldingParameters[i].deletedAt;
                        weldingParameters.updatedAt = selectedExistingWpqr.existingWpqrData.weldingParameters[i].updatedAt;
                        weldingParameters.flowRateTop = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].flowRateTop);
                        weldingParameters.flowRateRoot = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].flowRateRoot);
                        weldingParameters.pulseCurrent = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].pulseCurrent);
                        weldingParameters.pulseTime = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].pulseTime);
                        weldingParameters.basicCurrent = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].basicCurrent);
                        weldingParameters.timeForBasicCurrent = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].timeForBasicCurrent);
                        weldingParameters.frequency = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].frequency);
                        weldingParameters.current1 = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].current1);
                        weldingParameters.time1 = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].time1);
                        weldingParameters.heatInput1 = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].heatInput1);
                        weldingParameters.arcEnergyIE = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].arcEnergyIE);
                        weldingParameters.arcEnergyIP = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].arcEnergyIP);
                        weldingParameters.arcIP = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].arcIP);
                        weldingParameters.arcIE = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].arcIE);
                        weldingParameters.additiveMaterial = HelpFunctions.setZeroToEmpty(selectedExistingWpqr.existingWpqrData.weldingParameters[i].additiveMaterial);
                        weldingParameters.weldingTecknique = selectedExistingWpqr.existingWpqrData.weldingParameters[i].weldingTecknique;
                        weldingParameters.fuelGasPressure = selectedExistingWpqr.existingWpqrData.weldingParameters[i].fuelGasPressure;
                        weldingParameters.oxygenPressure = selectedExistingWpqr.existingWpqrData.weldingParameters[i].oxygenPressure;
                        weldingParameters.typeOfFlame = selectedExistingWpqr.existingWpqrData.weldingParameters[i].typeOfFlame;
                        weldingParametersList.splice(weldingParametersList.length, 0, weldingParameters);
                    };

                    existingWpqrData.weldingParameters = [...weldingParametersList];

                    existingWpqrData.filesUpload = [...selectedExistingWpqr.existingWpqrData.filesUpload];

                    console.log(selectedExistingWpqr);
                    console.log(existingWpqrData);


                    dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
                }
            }))
            .catch(error => {
                console.error(error);
            });
        //dispatch({ type: 'WPLAN_GET_WELDINGPLAN_REQUEST' });
    },
    createUpdateExistingWpqr: (existingWpqr: ExistingWpqrRequest, enableCreateExistingWpqr: boolean, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState &&
            appState.wpqrTab &&
            appState.wParameters &&
            appState.wpqrDekraTab &&
            appState.rangeOfQualification &&
            appState.wpqrPageHeader) {
            // Get the wpqrPageHeader values.
            existingWpqr.existingWpqrDataRequest.commissionNumber = appState.wpqrPageHeader.commissionNumber;
            existingWpqr.existingWpqrDataRequest.dekraProcedureNumber = appState.wpqrPageHeader.dekraProcedureNumber;
            existingWpqr.existingWpqrDataRequest.manufacturerWPQRNumber = appState.wpqrPageHeader.manufacturerWPQRNumber;

            // Get the WeldingParameters values.
            let updateWeldingParametersRequests: Array<UpdateWeldingParametersRequest> = new Array<UpdateWeldingParametersRequest>();
            for (let i = 0; i < appState.wParameters.weldingParameters.length; i++) {
                let updateWeldingParametersRequest: UpdateWeldingParametersRequest = {
                    id: 0,
                    run: !isNaN(parseInt(appState.wParameters.weldingParameters[i].run)) ? parseInt(appState.wParameters.weldingParameters[i].run) : 0,
                    runText: appState.wParameters.weldingParameters[i].runText,
                    indexId: (appState.wParameters.weldingParameters[i].index.id !== 0) ? appState.wParameters.weldingParameters[i].index.id : undefined,
                    diameter: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].diameter.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].diameter.replace(',', '.')) : 0.0,
                    voltage: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltage.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltage.replace(',', '.')) : 0.0,
                    voltageMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageMin.replace(',', '.')) : 0.0,
                    voltageMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageMax.replace(',', '.')) : 0.0,
                    current: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].current.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].current.replace(',', '.')) : 0.0,
                    currentMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentMin.replace(',', '.')) : 0.0,
                    currentMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentMax.replace(',', '.')) : 0.0,
                    travelSpeed: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeed.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeed.replace(',', '.')) : 0.0,
                    travelSpeedMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMin.replace(',', '.')) : 0.0,
                    travelSpeedMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMax.replace(',', '.')) : 0.0,
                    heatInput: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInput.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInput.replace(',', '.')) : 0.0,
                    heatInputMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInputMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInputMin.replace(',', '.')) : 0.0,
                    heatInputMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInputMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInputMax.replace(',', '.')) : 0.0,
                    flowRateTop: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].flowRateTop.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].flowRateTop.replace(',', '.')) : 0.0,
                    flowRateRoot: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].flowRateRoot.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].flowRateRoot.replace(',', '.')) : 0.0,
                    wireFeed: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].wireFeed.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].wireFeed.replace(',', '.')) : 0.0,
                    currentTypeId: (appState.wParameters.weldingParameters[i].currentType.id !== 0) ? appState.wParameters.weldingParameters[i].currentType.id : undefined,
                    time: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].time.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].time.replace(',', '.')) : 0.0,
                    runLength: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].runLength.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].runLength.replace(',', '.')) : 0.0,
                    pulseCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].pulseCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].pulseCurrent.replace(',', '.')) : 0.0,
                    pulseTime: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].pulseTime.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].pulseTime.replace(',', '.')) : 0.0,
                    basicCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].basicCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].basicCurrent.replace(',', '.')) : 0.0,
                    timeForBasicCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].timeForBasicCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].timeForBasicCurrent.replace(',', '.')) : 0.0,
                    frequency: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].frequency.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].frequency.replace(',', '.')) : 0.0,
                    current1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].current1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].current1.replace(',', '.')) : 0.0,
                    time1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].time1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].time1.replace(',', '.')) : 0.0,
                    heatInput1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInput1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInput1.replace(',', '.')) : 0.0,
                    weldingMethodId: (appState.wParameters.weldingParameters[i].weldingMethod.id !== 0) ? appState.wParameters.weldingParameters[i].weldingMethod.id : undefined,
                    arcEnergyIE: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIE.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIE.replace(',', '.')) : 0.0,
                    arcEnergyIP: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIP.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIP.replace(',', '.')) : 0.0,
                    arcIP: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcIP.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcIP.replace(',', '.')) : 0.0,
                    arcIE: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcIE.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcIE.replace(',', '.')) : 0.0,
                    additiveMaterial: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].additiveMaterial.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].additiveMaterial.replace(',', '.')) : 0.0,
                    currentY: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentY.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentY.replace(',', '.')) : 0.0,
                    voltageX: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageX.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageX.replace(',', '.')) : 0.0,
                    travelSpeedZ: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedZ.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedZ.replace(',', '.')) : 0.0,
                    weldingTecknique: appState.wParameters.weldingParameters[i].weldingTecknique,
                    fuelGasPressure: appState.wParameters.weldingParameters[i].fuelGasPressure,
                    oxygenPressure: appState.wParameters.weldingParameters[i].oxygenPressure,
                    typeOfFlame: appState.wParameters.weldingParameters[i].typeOfFlame
                };
                updateWeldingParametersRequests.push(updateWeldingParametersRequest);
            }
            existingWpqr.existingWpqrDataRequest.createWeldingParametersRequests = updateWeldingParametersRequests;

            // Get the FilesUpload values.
            let updateFilesUploadRequest: Array<UpdateFilesUploadRequest> = [];
            for (let i = 0; i < appState.fileUpload.filesUpload.length; i++) {
                let updateFileUploadRequest: UpdateFilesUploadRequest = {
                    fileName: appState.fileUpload.filesUpload[i].fileName,
                    fileBase64: appState.fileUpload.filesUpload[i].fileBase64,
                    filePath: appState.fileUpload.filesUpload[i].filePath,
                    fileDescription: appState.fileUpload.filesUpload[i].fileDescription,
                    fileGuid: '',
                    selectedFile: appState.fileUpload.filesUpload[i].selectedFile
                };
                updateFilesUploadRequest.push(updateFileUploadRequest);
            }
            existingWpqr.existingWpqrDataRequest.updateFilesUploadRequest = updateFilesUploadRequest;

            // Set the createTestingDetails1ExaminationRequest
            existingWpqr.existingWpqrDataRequest.createTestingDetails1ExaminationRequest = {
                manufacturer2: appState.wpqrDekraTab.weldingTestingDetails1.manufacturer,
                manufacturer2Signature: appState.wpqrDekraTab.weldingTestingDetails1.manufacturer2Signature,
                manufacturer2Name: appState.wpqrDekraTab.weldingTestingDetails1.manufacturer2Name,
                manufacturer2Date: appState.wpqrDekraTab.weldingTestingDetails1.manufacturer2Date,
                examiner: appState.wpqrDekraTab.weldingTestingDetails1.examiner,
                examinerSignature: appState.wpqrDekraTab.weldingTestingDetails1.examinerSignature,
                examinerName: appState.wpqrDekraTab.weldingTestingDetails1.examinerName,
                examinerDate: appState.wpqrDekraTab.weldingTestingDetails1.examinerDate,
                commentInformation: existingWpqr.existingWpqrDataRequest.details1CommentInformation, // See where it comes from details1CommentInformation
                backingGasFlowRate: appState.wpqrDekraTab.weldingTestingDetails1.backingGasFlowRate,
                backingGasFlux: appState.wpqrDekraTab.weldingTestingDetails1.backingGasFlux,
                chargeNumberHeatNumber: appState.wpqrDekraTab.weldingTestingDetails1.chargeNumberHeatNumber,
                chargeNumberLotNumberSerialNumber: appState.wpqrDekraTab.weldingTestingDetails1.chargeNumberLotNumberSerialNumber,
                detailsOfGougingBacking: appState.wpqrDekraTab.weldingTestingDetails1.detailsOfGougingBacking,
                fillerMaterialDesignation: appState.wpqrDekraTab.weldingTestingDetails1.fillerMaterialDesignation,
                fillerMaterialMake: appState.wpqrDekraTab.weldingTestingDetails1.fillerMaterialMake,
                fillerMaterialTradeName: appState.wpqrDekraTab.weldingTestingDetails1.fillerMaterialTradeName,
                heatingAndCoolingRates: appState.wpqrDekraTab.weldingTestingDetails1.heatingAndCoolingRates,
                interpassTemperature: appState.wpqrDekraTab.weldingTestingDetails1.interpassTemperature,
                jointType: appState.wpqrDekraTab.weldingTestingDetails1.jointType,
                location: appState.wpqrDekraTab.weldingTestingDetails1.location,
                manufacturer: appState.wpqrDekraTab.weldingTestingDetails1.manufacturer,
                manufacturerOfPowerSource: appState.wpqrDekraTab.weldingTestingDetails1.manufacturerOfPowerSource,
                manufacturerPWPSNumber: appState.wpqrDekraTab.weldingTestingDetails1.manufacturerPWPSNumber,
                materialThickness: appState.wpqrDekraTab.weldingTestingDetails1.materialThickness,
                methodOfPreparationAndCleaning: appState.wpqrDekraTab.weldingTestingDetails1.methodOfPreparationAndCleaning,
                nozzleDiameter: appState.wpqrDekraTab.weldingTestingDetails1.nozzleDiameter,
                otherInformation: appState.wpqrDekraTab.weldingTestingDetails1.otherInformation,
                outsideDiameter: appState.wpqrDekraTab.weldingTestingDetails1.outsideDiameter,
                parentMaterialGroup: appState.wpqrDekraTab.weldingTestingDetails1.parentMaterialGroup,
                parentMaterialNameOrNumber: appState.wpqrDekraTab.weldingTestingDetails1.parentMaterialNameOrNumber,
                postWeldHeatTreatmentAndOrAgeing: appState.wpqrDekraTab.weldingTestingDetails1.postWeldHeatTreatmentAndOrAgeing,
                preheatTemperature: appState.wpqrDekraTab.weldingTestingDetails1.preheatTemperature,
                shieldingGasFlowRate: appState.wpqrDekraTab.weldingTestingDetails1.shieldingGasFlowRate,
                shieldingGasFlux: appState.wpqrDekraTab.weldingTestingDetails1.shieldingGasFlux,
                standOffDistance: appState.wpqrDekraTab.weldingTestingDetails1.standOffDistance,
                technicalDeliveryStandard: appState.wpqrDekraTab.weldingTestingDetails1.technicalDeliveryStandard,
                torchAngle: appState.wpqrDekraTab.weldingTestingDetails1.torchAngle,
                tungstenElectrode: appState.wpqrDekraTab.weldingTestingDetails1.tungstenElectrode,
                weaving: appState.wpqrDekraTab.weldingTestingDetails1.weaving,
                welderName: appState.wpqrDekraTab.weldingTestingDetails1.welderName,
                weldingPosition: appState.wpqrDekraTab.weldingTestingDetails1.weldingPosition,
                weldingPosition2: appState.wpqrDekraTab.weldingTestingDetails1.weldingPosition2,
                weldingProcess: appState.wpqrDekraTab.weldingTestingDetails1.weldingProcess,
                tackWeldText: appState.wpqrDekraTab.weldingTestingDetails1.tackWeldText,
                lengthTackWelds: appState.wpqrDekraTab.weldingTestingDetails1.lengthTackWelds,
                arcType: appState.wpqrDekraTab.weldingTestingDetails1.arcType,
                electrodeTorchAngle111: appState.wpqrDekraTab.weldingTestingDetails1.electrodeTorchAngle111,
                plasmaflowrate: appState.wpqrDekraTab.weldingTestingDetails1.plasmaflowrate,
                plasmaGasName: appState.wpqrDekraTab.weldingTestingDetails1.plasmaGasName,
                users: [],
            }

            // Set the createRangeOfQualificationExaminationRequest
            existingWpqr.existingWpqrDataRequest.createRangeOfQualificationExaminationRequest = {
                address: appState.rangeOfQualification.rangeOfQualificationInfo.address,
                dateOfIssue: appState.rangeOfQualification.rangeOfQualificationInfo.dateOfIssue,
                dateOfWelding: appState.rangeOfQualification.rangeOfQualificationInfo.dateOfWelding,
                location: appState.rangeOfQualification.rangeOfQualificationInfo.location,
                manufacturer: appState.rangeOfQualification.rangeOfQualificationInfo.manufacturer,
                testingStandardId: appState.rangeOfQualification.rangeOfQualificationInfo.testingStandardId,
                enableStandardText: appState.rangeOfQualification.rangeOfQualificationInfo.enableStandardText,
                standardText: appState.rangeOfQualification.rangeOfQualificationInfo.standardText,
                weldingProcess: appState.rangeOfQualification.rangeOfQualificationInfo.weldingProcess,
                jointType: appState.rangeOfQualification.rangeOfQualificationInfo.jointType,
                materialThicknesses: appState.rangeOfQualification.rangeOfQualificationInfo.materialThicknesses,
                designations: appState.rangeOfQualification.rangeOfQualificationInfo.designations,
                shieldingGases: appState.rangeOfQualification.rangeOfQualificationInfo.shieldingGases,
                currentTypes: appState.rangeOfQualification.rangeOfQualificationInfo.currentTypes,
                dropletTransitions: appState.rangeOfQualification.rangeOfQualificationInfo.dropletTransitions,
                makes: appState.rangeOfQualification.rangeOfQualificationInfo.makes,
                otherInformations: appState.rangeOfQualification.rangeOfQualificationInfo.otherInformations,
                weldingMetalThicknessText: appState.rangeOfQualification.rangeOfQualificationInfo.weldingMetalThicknessText,
                enableWeldingMetalThicknessText: appState.rangeOfQualification.rangeOfQualificationInfo.enableWeldingMetalThicknessText,
                materialGroupText: appState.rangeOfQualification.rangeOfQualificationInfo.materialGroupText,
                enableMaterialGroupText: appState.rangeOfQualification.rangeOfQualificationInfo.enableMaterialGroupText,
                enableMaterialThicknessesText: appState.rangeOfQualification.rangeOfQualificationInfo.enableMaterialThicknessesText,
                weldingPositionsText: appState.rangeOfQualification.rangeOfQualificationInfo.weldingPositionsText.toString(),
                enableWeldingProcessText: appState.rangeOfQualification.rangeOfQualificationInfo.enableWeldingProcessText,
                enableJointTypeText: appState.rangeOfQualification.rangeOfQualificationInfo.enableJointTypeText,
                enableThroatThicknessText: appState.rangeOfQualification.rangeOfQualificationInfo.enableThroatThicknessText,
                enableRunTypeText: appState.rangeOfQualification.rangeOfQualificationInfo.enableRunTypeText,
                enableDiameterText: appState.rangeOfQualification.rangeOfQualificationInfo.enableDiameterText,
                enableMakesText: appState.rangeOfQualification.rangeOfQualificationInfo.enableMakesText,
                enableShieldingGasesText: appState.rangeOfQualification.rangeOfQualificationInfo.enableShieldingGasesText,
                enableRootProtectionGasText: appState.rangeOfQualification.rangeOfQualificationInfo.enableRootProtectionGasText,
                enableCurrentTypesText: appState.rangeOfQualification.rangeOfQualificationInfo.enableCurrentTypesText,
                enableDropletTransitionsText: appState.rangeOfQualification.rangeOfQualificationInfo.enableDropletTransitionsText,
                enableHeatInputText: appState.rangeOfQualification.rangeOfQualificationInfo.enableHeatInputText,
                enableArcEnergyText: appState.rangeOfQualification.rangeOfQualificationInfo.enableArcEnergyText,
                enableDesignationsText: appState.rangeOfQualification.rangeOfQualificationInfo.enableDesignationsText,
                enablePreheatTemperatureText: appState.rangeOfQualification.rangeOfQualificationInfo.enablePreheatTemperatureText,
                enableInterpassTemperatureText: appState.rangeOfQualification.rangeOfQualificationInfo.enableInterpassTemperatureText,
                enablePostWeldHeatTreatmentText: appState.rangeOfQualification.rangeOfQualificationInfo.enablePostWeldHeatTreatmentText,
                throatThicknessText: appState.rangeOfQualification.rangeOfQualificationInfo.throatThicknessText,
                runTypeText: appState.rangeOfQualification.rangeOfQualificationInfo.runTypeText,
                diameterText: appState.rangeOfQualification.rangeOfQualificationInfo.diameterText,
                rootProtectionGasText: appState.rangeOfQualification.rangeOfQualificationInfo.rootProtectionGasText,
                heatInputText: appState.rangeOfQualification.rangeOfQualificationInfo.heatInputText,
                arcEnergyText: appState.rangeOfQualification.rangeOfQualificationInfo.arcEnergyText,
                postWeldHeatTreatmentText: appState.rangeOfQualification.rangeOfQualificationInfo.postWeldHeatTreatmentText,
                interpassTemperatureText: appState.rangeOfQualification.rangeOfQualificationInfo.interpassTemperatureText,
                preheatTemperatureText: appState.rangeOfQualification.rangeOfQualificationInfo.preheatTemperatureText,
            };

            // Get the drawJointDesign values.
            existingWpqr.existingWpqrDataRequest.updateJointDesignRequest = {
                id: 0,
                materialThickness1: !isNaN(parseFloat(appState.drawJointDesign.materialThickness1.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.materialThickness1.toString().replace(',', '.')) : 0.0,
                materialThickness2: !isNaN(parseFloat(appState.drawJointDesign.materialThickness2.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.materialThickness2.toString().replace(',', '.')) : 0.0,
                jointTypeId: (appState.drawJointDesign.selectedJointType.id !== 0) ? appState.drawJointDesign.selectedJointType.id : undefined,
                jointShapeId: (appState.drawJointDesign.selectedJointShape.id !== 0) ? appState.drawJointDesign.selectedJointShape.id : undefined,
                jointAngleAlpha: !isNaN(parseFloat(appState.drawJointDesign.jointAngleAlpha.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.jointAngleAlpha.toString().replace(',', '.')) : 0.0,
                gapOpening: !isNaN(parseFloat(appState.drawJointDesign.gapOpening.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.gapOpening.toString().replace(',', '.')) : 0.0,
                straightEdge: !isNaN(parseFloat(appState.drawJointDesign.straightEdge.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.straightEdge.toString().replace(',', '.')) : 0.0,
                phaseAngle1: !isNaN(parseFloat(appState.drawJointDesign.phaseAngle1.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.phaseAngle1.toString().replace(',', '.')) : 0.0,
                phaseAngle2: !isNaN(parseFloat(appState.drawJointDesign.phaseAngle2.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.phaseAngle2.toString().replace(',', '.')) : 0.0,
                jointAngleBeta: !isNaN(parseFloat(appState.drawJointDesign.jointAngleBeta.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.jointAngleBeta.toString().replace(',', '.')) : 0.0,
                throatThickness: !isNaN(parseFloat(appState.drawJointDesign.throatThickness.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.throatThickness.toString().replace(',', '.')) : 0.0,
                rootSupportId: (appState.drawJointDesign.selectedRootSupport.id !== 0) ? appState.drawJointDesign.selectedRootSupport.id : undefined,
                depthOfPreparation: !isNaN(parseFloat(appState.drawJointDesign.depthOfPreparation.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.depthOfPreparation.toString().replace(',', '.')) : 0.0,
                radius: !isNaN(parseFloat(appState.drawJointDesign.radius.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.radius.toString().replace(',', '.')) : 0.0,
                note: appState.drawJointDesign.note,
                jointDesignHtmlImage: appState.drawJointDesign.jointDesignHtmlImage,

            };

            // Get the WeldSequence values.
            existingWpqr.existingWpqrDataRequest.updateWeldSequenceRequest = {
                imageId: appState.weldSequence.sequences.imageId,
                imageData: appState.weldSequence.sequences.imageData,
                sequenceNumber1: appState.weldSequence.sequences.sequenceNumber1,
                sequenceNumber2: appState.weldSequence.sequences.sequenceNumber2,
                sequenceNumber3: appState.weldSequence.sequences.sequenceNumber3,
                sequenceNumber4: appState.weldSequence.sequences.sequenceNumber4,
                sequenceNumber5: appState.weldSequence.sequences.sequenceNumber5,
                sequenceNumber6: appState.weldSequence.sequences.sequenceNumber6,
                sequenceNumber7: appState.weldSequence.sequences.sequenceNumber7,
                sequenceNumber8: appState.weldSequence.sequences.sequenceNumber8,
                sequenceNumber9: appState.weldSequence.sequences.sequenceNumber9,
                sequenceNumber10: appState.weldSequence.sequences.sequenceNumber10,
                sequenceNumber11: appState.weldSequence.sequences.sequenceNumber11,
                sequenceNumber12: appState.weldSequence.sequences.sequenceNumber12,
                sequenceNumber13: appState.weldSequence.sequences.sequenceNumber13,
                sequenceNumber14: appState.weldSequence.sequences.sequenceNumber14,
                sequenceNumber15: appState.weldSequence.sequences.sequenceNumber15,
            };

            //alert(JSON.stringify(existingWpqr))
            //fetch(`api/ExistingWpqrs/GetAll`, {
            //    method: 'GET',
            //    headers: authHeader.authHeader(),
            //}).then(response =>
            //    response.json().then(data => ({
            //        data: data,
            //        status: response.status
            //    })
            //    ).then(res => {
            //        if (res.status === 401) {
            //            EventBus.dispatch("logout", "");
            //        }
            //        let data = res.data as any;
            //        console.info(data);
            //        const appState = getState();
            //    }))
            //    .catch(error => {
            //        console.error(error);
            //    });
            //dispatch({ type: 'WPLAN_GET_WPLANS_REQUEST' });
            console.log(existingWpqr);
            if (enableCreateExistingWpqr) {
                fetch(`api/ExistingWpqrs/Create`, {
                    method: 'POST',
                    headers: authHeader.authHeader(),
                    body: JSON.stringify(existingWpqr)
                })
                    .then(response => response.json() as Promise<ExistingWpqrResponse>)
                    .then(data => {
                        console.info(data);
                        let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
                        existingWpqrData.existingWpqrId = data.id;
                        dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
                        //dispatch({ type: 'SET_EXISTING_WPQR_DATA_LOADING', currentlyLoading: false });

                        // Set activeSelectedExistingWpqrId
                        let existingWpqrRevision: ExistingWpqrRevision = { ...appState.wpqrTab.existingWpqrRevision };
                        existingWpqrRevision.activeSelectedExistingWpqrId = data.id;
                        dispatch({ type: 'EWPQR_SET_REVISION_RESULT', existingWpqrRevision: existingWpqrRevision });

                        callback();
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                fetch(`api/ExistingWpqrs/Update`, {
                    method: 'POST',
                    headers: authHeader.authHeader(),
                    body: JSON.stringify(existingWpqr)
                })
                    .then(response => response.json() as Promise<ExistingWpqrResponse>)
                    .then(data => {
                        console.info(data);
                        //let existingWpqrData: ExistingWpqrData = { ...appState.wpqrTab.existingWpqrData };
                        //existingWpqrData.existingWpqrId = data.id;
                        //dispatch({ type: 'SET_EXISTING_WPQR_DATA_ACTION', existingWpqrData: existingWpqrData });
                        //dispatch({ type: 'SET_EXISTING_WPQR_DATA_LOADING', currentlyLoading: false });
                        callback();
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
            dispatch({ type: 'SET_EXISTING_WPQR_DATA_LOADING', currentlyLoading: true });
            
        }
    }

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WPQRTabStoreState = {
    wpqrs: [],
    wpqrsSearchResult: [],
    searchProperties: {
        freeSearch: '',
        welder: '',
        selectedWeldingMethod: {
            id: 0,
            name: '',
            efficiencyFactor: 0.0,
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        productTypes: [],
        selectedProductType: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        materialThickness: '',
        outerDiameter: '',
        jointTypes: [],
        selectedJointType: {
            id: 0,
            name: '',
            description: '',
            jointShapes: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        materialGroups: [],
        selectedMaterialGroup: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        runTypeRangeOfQualifications: [],
        selectedRunTypeRangeOfQualification: {
            id: 0,
            createdAt: '',
            deletedAt: '',
            updatedAt: '',
            rangeOfQualification: '',
            runType: 1,
            hardnessTestingOrImpactTesting: false,

        },
        standards: [],
        selectedStandard: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
    },
    showTabSettingModal: false,
    wpqrTabSettingColumns: {
        dekraProcedureNumber: true,
        manufacturerWPQRNumber: true,
        pwpsName: true,
        qualificationStandard: true,
        weldingMethod: true,
        jointTypes: true,
        productType: true,
        materialThickness: true,
        outsideDiameter: true,
        materialGroup: true,
        singleMultiString: true,
        makeAdditives: true,
    },
    wpqrPaginationPageIndex: 0,
    wpqrPaginationPageSize: 20,
    wpqrWeldingMethodsSearch: [],
    activeTabId: false,
    existingWpqrData: {
        details1CommentInformation: '',
        imageUpload1: '',
        imageUpload2: '',
        imageValidationError1: '',
        imageValidationError2: '',
        jointDesign: new GetJointDesignResponse(),
        weldSequence: new GetWeldSequenceResponse(),
        weldingParameters: [],
        filesUpload: [],
        weldingTestingDetails1: new WeldingTestingDetails1(),
        existingWpqrDataExtra: new ExistingWpqrDataExtra(),
        rangeOfQualificationInfo: new RangeOfQualificationInfo(),
        existingWpqrId: 0,
        existingWpqrStatus: 0,
        userId: '',
        customerId: 0,
        revisionEWpqrId: 0,
        revisionEWpqrAction: '',
        commissionNumber: '',
        dekraProcedureNumber: '',
        manufacturerWPQRNumber: '',
    },
    existingWpqrRevision: {
        selectedRevisionAction: "",
        revisions: [],
        selectedRevision: {
            id: 0,
            existingWpqrData: {
                filesUpload: [],
                weldingParameters: [],
                jointDesign: new GetJointDesignResponse(),
                weldSequence: new GetWeldSequenceResponse(),
                testingDetails1ExaminationResponse: new GetTestingDetails1ExaminationResponse(),
                rangeOfQualificationExaminationResponse: new GetRangeOfQualificationExaminationResponse(),
                id: 0,
                commissionNumber: '',
                dekraProcedureNumber: '',
                manufacturerWPQRNumber: '',
                existingWpqrStatus: 0
            },
            customerId: 0,
            revisionEWpqrId: 0,
            revisionEWpqrAction: '',
            existingWpqrStatus: 0
        },
        showChooseExistingWpqrRevisionModal: false,
        selectedExistingWpqrRevisionModalOk: false,
        activeSelectedExistingWpqrId: 0,
    },
    currentlyLoading: false,
    currentTypes: [],
};


export const reducer: Reducer<WPQRTabStoreState> = (state: WPQRTabStoreState | undefined, incomingAction: Action): WPQRTabStoreState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_WPQRS':
            return {
                wpqrs: action.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            }; 
        case 'GET_WPQRS_REQUEST':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'SET_WPQRS_SEARCH_RESULT':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: action.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'SET_SEARCH_PROPERTIES':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: action.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'SET_SHOW_TAB_SETTING_MODAL':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                showTabSettingModal: action.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'SET_TAB_SETTING_COLUMNS':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: action.wpqrTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'SET_PAGINATION_PAGE_INDEX':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: action.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'SET_WELDING_METHODS_SEARCH':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: action.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'GET_WELDING_METHODS_SEARCH_REQUEST':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'SET_ACTIVE_TAB_ID':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: action.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'SET_EXISTING_WPQR_DATA_ACTION':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: action.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'SET_EXISTING_WPQR_DATA_LOADING':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: action.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'EWPQR_SET_REVISION_RESULT':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: action.existingWpqrRevision,
                currentTypes: state.currentTypes,
            };
        case 'EXISTING_WPQR_GET_CURRENT_TYPES_RECEIVE':
            return {
                wpqrs: state.wpqrs,
                wpqrsSearchResult: state.wpqrsSearchResult,
                searchProperties: state.searchProperties,
                wpqrTabSettingColumns: state.wpqrTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpqrPaginationPageIndex: state.wpqrPaginationPageIndex,
                wpqrPaginationPageSize: state.wpqrPaginationPageSize,
                wpqrWeldingMethodsSearch: state.wpqrWeldingMethodsSearch,
                activeTabId: state.activeTabId,
                existingWpqrData: state.existingWpqrData,
                currentlyLoading: state.currentlyLoading,
                existingWpqrRevision: state.existingWpqrRevision,
                currentTypes: action.currentTypes,
            };
        default:
            return state;
    }
};
