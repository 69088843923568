﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, InputGroup, InputGroupButtonDropdown, Label} from 'reactstrap';
import { t } from '../../i18n';
import { ApplicationState } from '../../store/index';
import * as TypeOfBackingStore from '../../store/otherStore/TypeOfBackingStore';
// At runtime, Redux will merge together...
type TypeOfBackingProps =
    TypeOfBackingStore.TypeOfBackingState // ... state we've requested from the Redux store
    & typeof TypeOfBackingStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    pTypeOfBackingText: string;
    enableTypeOfBackingTextView: boolean;
}

class TypeOfBacking extends React.PureComponent<TypeOfBackingProps> {
    constructor(props: TypeOfBackingProps) {
        super(props);
    }
    public state = {
        dropdownOpen: false,
        isAuth: true
    };
    // This method is called when the component is first added to the document
    public componentDidMount() {
        // Clear all store
        //this.props.clearState();
        // Get data from db
        this.props.getTypeOfBackingsAll();
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.pTypeOfBackingText !== this.props.pTypeOfBackingText) {
            if (this.props.enableTypeOfBackingTextView) {
                //TODO this is only to wiew.
                this.props.setTypeOfBackingTextView(this.props.pTypeOfBackingText);
            } else {
                this.props.setTypeOfBackingText(this.props.pTypeOfBackingText);
            }
        }
        if (!this.props.enableTypeOfBackingTextView && this.state.isAuth) {
            this.props.setTypeOfBackingText(this.props.pTypeOfBackingText);
            this.setIsAuth();
        }
    }

    private toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    private setIsAuth = () => {
        this.setState({
            isAuth: !this.state.isAuth
        });
    }

    private onChangeTypeOfBackingText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        if (event.currentTarget.value.length > 0) {
            this.props.setTypeOfBackingText(event.currentTarget.value);
        }
        else {
            this.props.clearTypeOfBackingText();
        }
    }

    private onClickTypeOfBackingText(SelectJointPreparation: string) {
        this.props.setTypeOfBackingText(SelectJointPreparation);;
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for="typeOfBacking" size="sm">{t('TypeOfRootSupports')}</Label>
                    {this.props.enableTypeOfBackingTextView === true ? 
                        <InputGroup>
                            <Input disabled autoComplete="off" type="search" bssize="sm" name="weldingDataTypeOfBackingView" id="weldingDataTypeOfBackingView"
                                value={this.props.typeOfBackingData.typeOfBackingTextView}
                                placeholder="" />
                            <InputGroupButtonDropdown disabled addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-backgroun">
                                </DropdownToggle>
                            </InputGroupButtonDropdown>
                        </InputGroup> :
                        <InputGroup>
                            <Input autoComplete="off" type="search" bssize="sm" name="weldingDataTypeOfBacking" id="weldingDataTypeOfBacking"
                                value={this.props.typeOfBackingData.typeOfBackingText}
                                onChange={(event) => this.onChangeTypeOfBackingText(event)}
                                onKeyPress={(event) => this.onKeyPress(event)}
                                placeholder="" />
                            <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-backgroun">
                                </DropdownToggle>
                                <DropdownMenu>
                                    {this.props.typeOfBackingData.typeOfBackings.map((typeOfBacking) => (
                                        <DropdownItem key={"typeOfBackingId" + typeOfBacking.id} id={"typeOfBackingId" + typeOfBacking.id} onClick={(event) => this.onClickTypeOfBackingText(typeOfBacking.name)}><a>{typeOfBacking.name}</a></DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </InputGroupButtonDropdown>
                        </InputGroup>
                    }
                </FormGroup>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.typeOfBacking;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    TypeOfBackingStore.actionCreators // Selects which action creators are merged into the component's props
)(TypeOfBacking as any);
