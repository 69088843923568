﻿import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../store/index';
import * as WPSFormEditStore from '../store/WPSFormEditStore';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Row, Tooltip, DropdownToggle, InputGroup, DropdownMenu, InputGroupButtonDropdown, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { GetBaseMaterialResponse } from '../store/models/responses/GetBaseMaterialResponse';
import { GetRootProtectionGasResponse } from '../store/models/responses/GetRootProtectionGasResponse';
import { GetMakeResponse } from '../store/models/responses/GetMakeResponse';
import { GetShieldingGasResponse } from '../store/models/responses/GetShieldingGasResponse';
import { GetWeldingMethodResponse } from '../store/models/responses/GetWeldingMethodResponse';
import DrawJointDesign from './DrawJointDesign';
import { WpsRequest } from '../store/models/requests/WpsRequest';
import Loader from './Loader';
import ModalWpsType from './ModalWpsType';
import { WpsTypeEnums } from '../store/models/WpsTypeEnums';
import { ControllerType } from '../store/models/ControllerType';
import { RevisionActionType } from '../store/models/RevisionActionType';
import LogoImage from './LogoImage';
import FormelForHeatInput from './FormelForHeatInput';
import WParameters from './WParameters';
import { OtherOptions } from '../store/models/OtherOptions';
import WeldSequence from './WeldSequence';
import TypeOfBacking from './otherComponents/TypeOfBacking';
import { t } from '../i18n';
import DropdownLists from './otherComponents/DropdownLists';
import Gases from './otherComponents/Gases';

// At runtime, Redux will merge together...
type WPSFormEditProps =
    WPSFormEditStore.WPSFormEditStoreState // ... state we've requested from the Redux store
    & typeof WPSFormEditStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ applicationId: string, wpsId: string }>
    & OwnProps; // .. plus our own props :-);

export interface OwnProps {
    pUpdateWPSFormEdit: number;
    pWpsId: number;
}

const SweToEngJointTypes = new Map<string, string>();
const SweToEngTackWelds = new Map<string, string>();
const SweToEngRootSupport = new Map<string, string>();
const SweToEngArcType = new Map<string, string>();

class WPSFormEdit extends React.PureComponent<WPSFormEditProps> {
    constructor(props: WPSFormEditProps) {
        super(props);
    }

    private TranslateBoxes = (word: string, dictionary: Map<string, string>) => {
        if (dictionary.has(word)) {
            return dictionary.get(word);
        } else {
            return word;
        }
    }

    public state = {
        tooltipOpen: false,
        tabId: '',
        parametersTableId: false,
        dropdownOpen: false,
        showJointDesignModalId: 0,
        showWeldSequenceModal: false
    };

    private toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    private setParametersTableId = () => {
        this.setState({
            parametersTableId: true
        });
    }

    private toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    private toggleShowJointDesignModal1 = (value: number) => {
        this.setState({
            showJointDesignModalId: value
        });
    }

    private toggleWeldSequenceModal = () => {
        this.setState({
            showWeldSequenceModal: !this.state.showWeldSequenceModal
        });
    }

    public componentDidMount() {
        // Get data from db.
        this.getDataFromDB()
        
        if (this.props.match !== undefined) {
            const { applicationId, wpsId } = this.props.match.params //TODO get the value from url
            if (applicationId !== undefined && parseInt(applicationId) > 0) {// WPRQ 
                this.props.setHeaderWpsType(WpsTypeEnums.WPQR);
                this.props.initWeldingProtocol(parseInt(applicationId), ControllerType.Applications, false);

            }
            else if (applicationId !== undefined && parseInt(applicationId) === 0) {//PWPS

                this.props.clearAndSetWpsAction();
                this.clearWps();
                this.props.setHeaderWpsType(WpsTypeEnums.PWPS);

                if (wpsId !== undefined && parseInt(wpsId) > 0) {
                    this.props.initWeldingProtocol(parseInt(wpsId), ControllerType.Wpss, true);
                } else {
                    this.setParametersTableId();
                }

            }
            else if (applicationId !== undefined && parseInt(applicationId) === -1) {//WPS

                this.props.clearAndSetWpsAction();
                this.clearWps();
                this.props.setHeaderWpsType(WpsTypeEnums.WPS);

                if (wpsId !== undefined && parseInt(wpsId) > 0) {
                    this.props.initWeldingProtocol(parseInt(wpsId), ControllerType.Wpss, true);
                } else {
                    this.setParametersTableId();
                }
            }
            else if (applicationId !== undefined && parseInt(applicationId) === -2) {// ExistingWpqr

                this.props.clearAndSetWpsAction();
                this.clearWps();
                this.props.setHeaderWpsType(WpsTypeEnums.WPS);

                if (wpsId !== undefined && parseInt(wpsId) > 0) {
                    this.props.initExistingWpqr(parseInt(wpsId));// wpsId is here existingWpqrId
                } else {
                    this.setParametersTableId();
                }

            }
        }
        //clear
        //this.props.clearWpsId();
        this.props.initPWPS();

        SweToEngRootSupport.set('bs utan mejsling', t('BSWithoutChiselling'));
        SweToEngRootSupport.set('bs med mejsling', t('BSWithChiselling'));

        SweToEngArcType.set('D Kortbåge', t('DShortArc'));
        SweToEngArcType.set('S Spraybåge', t('SSprayArc'));
        SweToEngArcType.set('P Pulsbåge', t('PPulseArc'));
        SweToEngArcType.set('Globulär droppövergång', t('GlobularTransfer'));

        SweToEngTackWelds.set('Häftor slipas bort', t('TacksGetGrindedAway'));
        SweToEngTackWelds.set('Kvarvarande häftor', t('RemainingTacks'));

        SweToEngJointTypes.set('Stumsvets (BW)', t('ButtWeldingBW'));
        SweToEngJointTypes.set('Partiell stumsvets', t('PartialButtWelding'));
        SweToEngJointTypes.set('Kälsvets (FW)', t('KeelWeldingFW'));
        SweToEngJointTypes.set('T-förband (BW)', t('TJointBW'));
        SweToEngJointTypes.set('Rör mot plåt (BW)', t('PipeAgainstPlateBW'));
        SweToEngJointTypes.set('Rör mot plåt (FW)', t('PipeAgainstPlateFW'));
        SweToEngJointTypes.set('Avstickare (BW)', t('StickOutBW'));
        SweToEngJointTypes.set('Avstickare (FW)', t('StickOutFW'));
    }
    componentDidUpdate(prevProps: any) {
        if (this.props.wpsId !== prevProps.wpsId) {
            this.props.history.push('/wps');
        }
        // View the created wps for this application
        if (this.props.pUpdateWPSFormEdit !== prevProps.pUpdateWPSFormEdit) {
            this.clearWps();
            this.getDataFromDB()
            this.props.clearAndSetWpsActionToView();
            this.props.getCustomerLogo(this.props.pUpdateWPSFormEdit, () => {
                console.log("updateCustomer");
                this.props.initWeldingProtocol(this.props.pUpdateWPSFormEdit, ControllerType.Wpss, true);
            });
            //this.props.setHeaderWpsType(WpsTypeEnums.PWPS);
            //this.props.initWeldingProtocol(this.props.pUpdateWPSFormEdit, ControllerType.Wpss, true);
        }
    }
    private getDataFromDB() {
        this.props.getStandardsAll();
        this.props.getJointPreparationsAll();
        this.props.getJointTypesAll();
        this.props.getRootSupportDetailsSearchResult();
        this.props.getWeldingPositionSearchResult();
        this.props.getArcTypeSearchResult();
        this.props.getIndexesAll();
        this.props.getCurrentTypesAll();
    }
    private clearWps() {
        this.props.clearParentMaterial();
        this.props.cleaWeldingData();
        this.props.clearWeldingProcess();
        this.props.clearWeldingParameters();
        this.props.clearTemperature();
        this.props.clearAfterWelding();
        this.props.clearOtherInformation();
    }
    private createNewWps() {
        let wpsRequest: WpsRequest = {
            applicationStatus: 1,
            welderIds: [],
            companyId: 1,
            standardId: undefined,
            additionalRequirementIds: [],
            otherOptionIds: [],
            numberOfWeldingPositionsId: undefined,
            updateEmployeeRequest: {
                displayName: this.props.header.employeeDisplayName
            },
            UpdatePWPSRequest: {
                id: this.props.header.selectedWpsId,
                date: this.props.header.date,
                projectNumber: this.props.header.project,
                byStandardId: (this.props.header.selectedByStandard.id !== 0) ? this.props.header.selectedByStandard.id : undefined,
                standardText: this.props.header.standardText,
                enableStandardText: this.props.header.enableStandardText,
                revisionNumber: this.props.header.revisionNumber,
                pwpsNumber: this.props.header.pwpsNumber,
                wpqrNumber: this.props.header.wpqrNumber,
                wpsType: this.props.header.wpsType,
                wpsDekraNumber: this.props.header.wpsDekraNumber,
                wpsHeatInputText: this.props.header.wpsHeatInputText,
                wpsArcEnergyText: this.props.header.wpsArcEnergyText,
                revisionWpsAction: this.props.header.revisionWpsAction,
                otherOptionParametersTableId: this.props.header.otherOptionParametersTableId,
                manufacturer: this.props.header.manufacturer,
                examiner: this.props.header.examiner,
                confirmedMessage: this.props.header.confirmedMessage,
                customerId: parseInt(JSON.parse(localStorage.getItem('customerId')!)),
                updateParentMaterialRequest: {
                    id: 0,
                    baseMaterialId1: (this.props.parentMaterial.selectedBaseMaterial1.id !== 0) ? this.props.parentMaterial.selectedBaseMaterial1.id : undefined,
                    materialThickness1: !isNaN(parseFloat(this.props.parentMaterial.materialThickness1.replace(',', '.'))) ? parseFloat(this.props.parentMaterial.materialThickness1.replace(',', '.')) : 0.0,
                    diameter1: !isNaN(parseFloat(this.props.parentMaterial.diameter1.replace(',', '.'))) ? parseFloat(this.props.parentMaterial.diameter1.replace(',', '.')) : 0.0,
                    baseMaterialId2: (this.props.parentMaterial.selectedBaseMaterial2.id !== 0) ? this.props.parentMaterial.selectedBaseMaterial2.id : undefined,
                    materialThickness2: !isNaN(parseFloat(this.props.parentMaterial.materialThickness2.replace(',', '.'))) ? parseFloat(this.props.parentMaterial.materialThickness2.replace(',', '.')) : 0.0,
                    diameter2: !isNaN(parseFloat(this.props.parentMaterial.diameter2.replace(',', '.'))) ? parseFloat(this.props.parentMaterial.diameter2.replace(',', '.')) : 0.0,
                    technicalDeliveryConditionId1: (this.props.parentMaterial.selectedTechnicalDeliveryCondition1.id !== 0) ? this.props.parentMaterial.selectedTechnicalDeliveryCondition1.id : undefined,
                    technicalDeliveryConditionId2: (this.props.parentMaterial.selectedTechnicalDeliveryCondition2.id !== 0) ? this.props.parentMaterial.selectedTechnicalDeliveryCondition2.id : undefined,
                    enableBaseMaterial1Text: this.props.parentMaterial.enableBaseMaterial1Text,
                    enableBaseMaterial2Text: this.props.parentMaterial.enableBaseMaterial2Text,
                    baseMaterial1Text: this.props.parentMaterial.baseMaterial1Text,
                    baseMaterial2Text: this.props.parentMaterial.baseMaterial2Text,
                    materialGroup1Text: this.props.parentMaterial.materialGroup1Text,
                    materialGroup2Text: this.props.parentMaterial.materialGroup2Text,
                    technicalDeliveryCondition1Text: this.props.parentMaterial.technicalDeliveryCondition1Text,
                    technicalDeliveryCondition2Text: this.props.parentMaterial.technicalDeliveryCondition2Text,
                    enableMaterialThickness: this.props.parentMaterial.enableMaterialThickness,
                    materialThickness1Text: this.props.parentMaterial.materialThickness1,
                    materialThickness2Text: this.props.parentMaterial.materialThickness2,
                    enableDiameter: this.props.parentMaterial.enableDiameter,
                    diameter1Text: this.props.parentMaterial.diameter1,
                    diameter2Text: this.props.parentMaterial.diameter2,
                },
                updateWeldingDataRequest: {
                    id: 0,
                    jointTypeId: (this.props.weldingData.selectedJointType.id !== 0) ? this.props.weldingData.selectedJointType.id : undefined,
                    enableJointTypeText: this.props.weldingData.enableJointTypeText,
                    jointTypeText: this.props.weldingData.jointTypeText,
                    rootSupportDetailsId: (this.props.weldingData.selectedRootSupportDetails.id !== 0) ? this.props.weldingData.selectedRootSupportDetails.id : undefined,
                    tackWeldText: this.props.weldingData.tackWeldText,
                    weldingPositionId: (this.props.weldingData.selectedWeldingPosition.id !== 0) ? this.props.weldingData.selectedWeldingPosition.id : undefined,
                    weldingPosition2Id: (this.props.weldingData.selectedWeldingPosition2.id !== 0) ? this.props.weldingData.selectedWeldingPosition2.id : undefined,
                    rootProtectionGasId: (this.props.weldingData.selectedRootProtectionGas.id !== 0) ? this.props.weldingData.selectedRootProtectionGas.id : undefined,
                    nozzleDiameter: this.props.weldingData.nozzleDiameter,
                    stickOut: this.props.weldingData.stickOut,
                    lengthTackWelds: this.props.weldingData.lengthTackWelds,
                    jointPreparationSearchText: this.props.weldingData.selectedJointPreparationText,
                    tungstenElectrode: this.props.weldingData.tungstenElectrode,
                    electrodeTorchAngle: this.props.weldingData.electrodeTorchAngle,
                    maxWidthOfRun: this.props.weldingData.maxWidthOfRun,
                    electrodeTorchAngle111: this.props.weldingData.electrodeTorchAngle111,
                    plasmaflowrate: this.props.weldingData.plasmaflowrate,
                    plasmaGasId: (this.props.weldingData.selectedPlasmaGas.id !== 0) ? this.props.weldingData.selectedPlasmaGas.id : undefined,
                },
                updateWeldingProcessRequest: {
                    id: 0,
                    weldingMethodId1: (this.props.weldingProcess.selectedWeldingMethodA.id !== 0) ? this.props.weldingProcess.selectedWeldingMethodA.id : undefined,
                    makeId1: (this.props.weldingProcess.selectedMakeA.id !== 0) ? this.props.weldingProcess.selectedMakeA.id : undefined,
                    shieldingGasId1: (this.props.weldingProcess.selectedShieldingGasA.id !== 0) ? this.props.weldingProcess.selectedShieldingGasA.id : undefined,
                    arcTypeId1: (this.props.weldingProcess.selectedArcTypeA.id !== 0) ? this.props.weldingProcess.selectedArcTypeA.id : undefined,
                    weldingMethodId2: (this.props.weldingProcess.selectedWeldingMethodB.id !== 0) ? this.props.weldingProcess.selectedWeldingMethodB.id : undefined,
                    makeId2: (this.props.weldingProcess.selectedMakeB.id !== 0) ? this.props.weldingProcess.selectedMakeB.id : undefined,
                    shieldingGasId2: (this.props.weldingProcess.selectedShieldingGasB.id !== 0) ? this.props.weldingProcess.selectedShieldingGasB.id : undefined,
                    arcTypeId2: (this.props.weldingProcess.selectedArcTypeB.id !== 0) ? this.props.weldingProcess.selectedArcTypeB.id : undefined,
                    enableMakeAText: this.props.weldingProcess.enableMakeAText,
                    makeAText: this.props.weldingProcess.makeAText,
                    designationAText: this.props.weldingProcess.designationAText,
                    fmGroupAText: this.props.weldingProcess.fmGroupAText,
                    enableMakeBText: this.props.weldingProcess.enableMakeBText,
                    makeBText: this.props.weldingProcess.makeBText,
                    designationBText: this.props.weldingProcess.designationBText,
                    fmGroupBText: this.props.weldingProcess.fmGroupBText,
                    typeOfBackingText: '',
                },
                updateJointDesignRequest: {
                    materialThickness1: 0.0,
                    materialThickness2: 0.0,
                    jointTypeId: undefined,
                    jointShapeId: undefined,
                    jointAngleAlpha: 0.0,
                    gapOpening: 0.0,
                    straightEdge: 0.0,
                    phaseAngle1: 0.0,
                    phaseAngle2: 0.0,
                    jointAngleBeta: 0.0,
                    throatThickness: 0.0,
                    rootSupportId: undefined,
                    depthOfPreparation: 0.0,
                    radius: 0.0,
                    note: '',
                    jointDesignHtmlImage: '',
                    id: 0
                },
                updateWeldSequenceRequest: {
                    imageId: 0,
                    imageData: '',
                    sequenceNumber1: '',
                    sequenceNumber2: '',
                    sequenceNumber3: '',
                    sequenceNumber4: '',
                    sequenceNumber5: '',
                    sequenceNumber6: '',
                    sequenceNumber7: '',
                    sequenceNumber8: '',
                    sequenceNumber9: '',
                    sequenceNumber10: '',
                    sequenceNumber11: '',
                    sequenceNumber12: '',
                    sequenceNumber13: '',
                    sequenceNumber14: '',
                    sequenceNumber15: '',
                },
                updateWeldingParametersRequests: [],
                updateFilesUploadRequest: [],
                updateTemperatureRequest: {
                    id: 0,
                    preheatTemperature: !isNaN(parseFloat(this.props.temperature.preheatTemperature.replace(',', '.'))) ? parseFloat(this.props.temperature.preheatTemperature.replace(',', '.')) : 0.0,
                    maxInterpassTemperature: !isNaN(parseFloat(this.props.temperature.maxInterpassTemperature.replace(',', '.'))) ? parseFloat(this.props.temperature.maxInterpassTemperature.replace(',', '.')) : 0.0
                },
                updateAfterWeldingRequest: {
                    id: 0,
                    heatingRate: this.props.afterWelding.heatingRate,
                    coolingRate: this.props.afterWelding.coolingRate
                },
                otherInformation: this.props.otherInformation.otherInformation
            },
        };
        this.props.createUpdateWps(wpsRequest);
    }

    private onClickSaveButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        (document.getElementById('headerDate') as HTMLButtonElement).focus();// Scroll to the top, headerDate
        // Update/Save the wps to db.
        this.createNewWps();
    }

    private onChangeHeaderDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderDate(event.currentTarget.value);
    }

    private onChangeHeaderProject(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderProject(event.currentTarget.value);
    }

    private onChangeHeaderByStandards(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderSelectedByStandard(parseInt(event.currentTarget.value));
    }

    private onChangeHeaderWPQRNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderWPQRNumber(event.currentTarget.value);
    }

    private onChangeHeaderRevisionNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderRevisionNumber(event.currentTarget.value);
    }

    private onChangeHeaderPWPSNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderPWPSNumber(event.currentTarget.value);
    }

    private onChangeParentMaterialBaseMaterial1SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial1SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getBaseMaterial1SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearBaseMaterial1SearchResult();
        }
    }

    private onClickParentMaterialSelectBaseMaterial1(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, baseMaterial: GetBaseMaterialResponse) {
        event.preventDefault();
        this.props.clearBaseMaterial1SearchResult();
        this.props.setParentMaterialSelectedBaseMaterial1(baseMaterial);
    }

    private onChangeParentMaterialTechnicalDeliveryConditions1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialSelectedTechnicalDeliveryCondition1(parseInt(event.currentTarget.value));
    }

    private onChangeParentMaterialMaterialThickness1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialThickness1(event.currentTarget.value);
    }

    private onChangeParentMaterialDiameter1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialDiameter1(event.currentTarget.value);
    }

    private onChangeParentMaterialBaseMaterial2SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial2SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getBaseMaterial2SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearBaseMaterial2SearchResult();
        }
    }

    private onClickParentMaterialSelectBaseMaterial2(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, baseMaterial: GetBaseMaterialResponse) {
        event.preventDefault();
        this.props.clearBaseMaterial2SearchResult();
        this.props.setParentMaterialSelectedBaseMaterial2(baseMaterial);
    }

    private onChangeParentMaterialTechnicalDeliveryConditions2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialSelectedTechnicalDeliveryCondition2(parseInt(event.currentTarget.value));
    }

    private onChangeParentMaterialMaterialThickness2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialThickness2(event.currentTarget.value);
    }

    private onChangeParentMaterialDiameter2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialDiameter2(event.currentTarget.value);
    }

    private onChangeWeldingDataJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataSelectedJointType(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingDataLengthTackWelds(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataLengthTackWelds(event.currentTarget.value);
    }

    private onChangeWeldingDataPlasmaflowrate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataPlasmaflowrate(event.currentTarget.value);
    }

    private onChangeWeldingDataElectrodeTorchAngle111(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataElectrodeTorchAngle111(event.currentTarget.value);
    }

    private onChangeWeldingDataMaxWidthOfRun(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataMaxWidthOfRun(event.currentTarget.value);
    }

    private onChangeWeldingDataNozzleDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataNozzleDiameter(event.currentTarget.value);
    }

    private onChangeWeldingDataRootProtectionGasSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataRootProtectionGasSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getRootProtectionGasSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearRootProtectionGasSearchResult();
        }
    }

    private onChangeWeldingDataRootSupportDetails(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataSelectedRootSupportDetails(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingDataStickOut(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataStickOut(event.currentTarget.value);
    }

    private onChangeWeldingDataTungstenElectrode(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataTungstenElectrode(event.currentTarget.value);
    }

    private onChangeWeldingDataElectrodeTorchAngle(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataElectrodeTorchAngle(event.currentTarget.value);
    }

    private onChangeWeldingDataWeldingPositionSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataWeldingPositionSearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingDataWeldingPosition2SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataWeldingPosition2SearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingDataTackWeldSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataSelectedTackWeld(event.currentTarget.value);
    }

    private onClickWeldingDataSelectRootProtectionGas(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, rootProtectionGas: GetRootProtectionGasResponse) {
        event.preventDefault();
        this.props.clearRootProtectionGasSearchResult();
        this.props.setWeldingDataSelectedRootProtectionGas(rootProtectionGas);
    }

    private onClickWeldingProcessSelectMakeA(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, make: GetMakeResponse) {
        event.preventDefault();
        this.props.clearMakeASearchResult();
        this.props.setWeldingProcessSelectedMakeA(make);
    }

    private onClickWeldingProcessSelectMakeB(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, make: GetMakeResponse) {
        event.preventDefault();
        this.props.clearMakeBSearchResult();
        this.props.setWeldingProcessSelectedMakeB(make);
    }

    private onClickWeldingProcessSelectShieldingGasA(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, shieldingGas: GetShieldingGasResponse) {
        event.preventDefault();
        this.props.clearShieldingGasASearchResult();
        this.props.setWeldingProcessSelectedShieldingGasA(shieldingGas);
    }

    private onClickWeldingProcessSelectShieldingGasB(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, shieldingGas: GetShieldingGasResponse) {
        event.preventDefault();
        this.props.clearShieldingGasBSearchResult();
        this.props.setWeldingProcessSelectedShieldingGasB(shieldingGas);
    }

    private onClickWeldingProcessSelectWeldingMethodA(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, weldingMethod: GetWeldingMethodResponse) {
        event.preventDefault();
        this.props.clearWeldingMethodASearchResult();
        this.props.setWeldingProcessSelectedWeldingMethodA(weldingMethod);
    }

    private onClickWeldingProcessSelectWeldingMethodB(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, weldingMethod: GetWeldingMethodResponse) {
        event.preventDefault();
        this.props.clearWeldingMethodBSearchResult();
        this.props.setWeldingProcessSelectedWeldingMethodB(weldingMethod);
    }

    private onChangeWeldingProcessArcTypeASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessArcTypeASearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProcessArcTypeBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessArcTypeBSearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProcessMakeASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessMakeASearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getMakeASearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearMakeASearchResult();
        }
    }

    private onChangeWeldingProcessMakeBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessMakeBSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getMakeBSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearMakeBSearchResult();
        }
    }

    private onChangeWeldingProcessShieldingGasASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessShieldingGasASearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getShieldingGasASearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearShieldingGasASearchResult();
        }
    }

    private onChangeWeldingProcessShieldingGasBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessShieldingGasBSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getShieldingGasBSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearShieldingGasBSearchResult();
        }
    }

    private onChangeWeldingProcessWeldingMethodASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessWeldingMethodASearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWeldingMethodASearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingMethodASearchResult();
        }
    }

    private onChangeWeldingProcessWeldingMethodBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessWeldingMethodBSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWeldingMethodBSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingMethodBSearchResult();
        }
    }

    private onClickToggleJointDesignModal() {
        this.toggleShowJointDesignModal1(1);
        this.props.toggleShowJointDesignModal();
    }

    private setToggleShowJointDesignModal = () => {
        this.props.toggleShowJointDesignModal();
    }

    private onClickAutoFilling(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setParentMaterialAutoFilling();
    }

    private onChangeAfterWeldingCoolingRate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setAfterWeldingCoolingRate(event.currentTarget.value);
    }

    private onChangeAfterWeldingHeatingRate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setAfterWeldingHeatingRate(event.currentTarget.value);
    }

    private onChangeTemperatureMaxInterpassTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTemperatureMaxInterpassTemperature(event.currentTarget.value);
    }

    private onChangeTemperaturePreheatTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTemperaturePreheatTemperature(event.currentTarget.value);
    }

    private onChangeOtherInformationOtherInformation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherInformationOtherInformation(event.currentTarget.value);
    }

    private onChangeOtherOptionsParametersTableId(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherOptionsParametersTableId(parseInt(event.currentTarget.value));
    }

    private onChangeManufacturer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setManufacturer(event.currentTarget.value);
    }

    private onChangeExaminer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setExaminer(event.currentTarget.value);
    }

    private onChangeWeldingDataSetJointPreparationText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        if (event.currentTarget.value.length > 0) {
            this.props.setOnchangeJointPreparationText(event.currentTarget.value);
        }
        else {
            this.props.clearJointPreparationText();
        }
    }

    private onClickToggleWeldSequenceModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.toggleWeldSequenceModal();
    }

    private onClickWeldingDataSetJointPreparationText(SelectJointPreparation: string) {
        this.props.setJointPreparationText(SelectJointPreparation);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private onClickGenerateClone(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setHeaderShowWpsTypeModal(true);
    }

    private onChangeParentTechnicalDeliveryCondition1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialTechnicalDeliveryCondition1Text(event.currentTarget.value);
    }

    private onChangeParentTechnicalDeliveryCondition2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialTechnicalDeliveryCondition2Text(event.currentTarget.value);
    }

    private onChangeParentMaterialBaseMaterial1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial1Text(event.currentTarget.value);
    }

    private onChangeParentMaterialBaseMaterial2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial2Text(event.currentTarget.value);
    }

    private onChangeParentMaterialMaterialGroup2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialGroup2Text(event.currentTarget.value);
    }

    private onChangeParentMaterialMaterialGroup1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialGroup1Text(event.currentTarget.value);
    }

    private onChangeEnableBaseMaterial1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableBaseMaterial1Text(event.currentTarget.checked);
    }

    private onChangeEnableDiameterText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableDiameterText(event.currentTarget.checked);
    }
    private onChangeEnableMaterialThicknessText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMaterialThicknessText(event.currentTarget.checked);
    }

    private onChangeEnableBaseMaterial2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableBaseMaterial2Text(event.currentTarget.checked);
    }

    private onChangeEnableMakeAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMakeAText(event.currentTarget.checked);
    }

    private onChangeEnableJointTypeText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableJointTypeText(event.currentTarget.checked);
    }

    private onChangeEnableStandardText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableStandardText(event.currentTarget.checked);
    }

    private onChangeEnableMakeBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMakeBText(event.currentTarget.checked);
    }

    private onChangeMakeAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMakeAText(event.currentTarget.value);
    }

    private onChangeJointTypeText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setJointTypeText(event.currentTarget.value);
    }

    private onChangeStandardText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setStandardText(event.currentTarget.value);
    }

    private onChangeDesignationAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDesignationAText(event.currentTarget.value);
    }

    private onChangefmGroupAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setfmGroupAText(event.currentTarget.value);
    }

    private onChangeMakeBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMakeBText(event.currentTarget.value);
    }

    private onChangeDesignationBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDesignationBText(event.currentTarget.value);
    }

    private onChangefmGroupBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setfmGroupBText(event.currentTarget.value);
    }

    private handleChange = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            this.props.createFile(event.target.files[i])
        }
    };

    private handleChangeDraJointDesign = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            this.props.createImageDrawJointDesign(event.target.files[i])
        }
    };

    public render() {
        return (
            <React.Fragment key="rendeWpsForm">
                <Loader show={this.props.updatingWps} title={t('SavingWPS')} />
                <div className={this.props.header.revisionWpsAction === RevisionActionType.View ? "" : "dekra-content-wrapper dekra-wrapper"}>
                    <Row><Col sm={12}>
                        {this.props.header.revisionWpsAction !== RevisionActionType.View ?
                        <Card>
                            <CardHeader className="card-header-dekra" hidden={this.props.header.revisionWpsAction === RevisionActionType.View}>
                                <Row>
                                    <Col sm={12}>
                                            <h1>{t('WeldingProcedureSpecification')} {(this.props.header.wpsType === WpsTypeEnums.PWPS) ? "(pWPS)" : "(WPS)"}</h1>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.renderWPS()}
                            </CardBody>
                            <CardFooter>
                                    <Row>
                                        <Col sm={12}>
                                            <div className={"float-right"}>
                                                <Button color="primary" onClick={(event) => this.onClickSaveButton(event)} disabled={this.props.header.revisionWpsAction === RevisionActionType.Clone ? true : false}>
                                                    {t('Save')}
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row>
                            </CardFooter>
                            </Card>
                            : this.renderWPS()}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    private renderChooseWpsTypeModal() {
        return (
            <React.Fragment>
                <ModalWpsType openModal={this.props.header.showChooseWpsTypeModal} parentCallback={this.props.onClickModalOK} calcelModalCallback={this.props.setHeaderShowWpsTypeModal}></ModalWpsType>
            </React.Fragment>
        );
    }

    private renderWPS() {
        return (
            <React.Fragment key="rendeWps">
                <Form>
                    <Row id="pWPSForm">
                        <Col sm={6}>
                            {this.props.header.revisionWpsAction === RevisionActionType.Clone ?
                                <FormGroup >
                                    <Button type="button" color="primary" size="lg" id="generateclone" name="generateClone"
                                        onClick={(event) => this.onClickGenerateClone(event)}>
                                        <i className="fas fa-clone"></i>
                                    </Button>
                                </FormGroup>
                                : ""}
                            {this.state.parametersTableId ? this.renderParametersTableId() : ""}
                        </Col>
                        <Col sm={3}>
                        </Col>
                        <Col sm={3}>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                            {this.renderChooseWpsTypeModal()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderHead()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderParentMaterial()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderWeldingData()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderWeldingProcess()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {this.renderJointDesign()}
                        </Col>
                        <Col sm={6}>
                            {this.renderWeldSequence()}
                        </Col>
                    </Row>
                    <Row hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                        <Col sm={12}>
                            <FormelForHeatInput weldingPulseParameters={this.props.header.otherOptionParametersTableId === OtherOptions.PulseWeldingParametersTable}
                                weldingArcParameters={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.ArcWeldingParametersTable}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <WParameters pWeldingMethodASearchText={this.props.weldingProcess.weldingMethodASearchText}
                                pWeldingMethodBSearchText={this.props.weldingProcess.weldingMethodBSearchText}
                                pWeldingMethods={this.props.weldingProcess.weldingMethods}
                                pWeldingParameters={this.props.weldingParameters}
                                currentTypes={this.props.currentTypes}
                                showWeldingPulseParameters={this.props.header.otherOptionParametersTableId === OtherOptions.PulseWeldingParametersTable}
                                showWeldingBaseParameters={this.props.header.otherOptionParametersTableId === OtherOptions.BaseWeldingParametersTable}
                                showWeldingArcParameters={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.ArcWeldingParametersTable}
                                showWeldingGwParameters={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}
                                componentPositionApplication={true}
                                setWParametersView={this.props.header.revisionWpsAction === RevisionActionType.View}
                                enableSettingModal={true}
                                pWpsHeatInputText={this.props.header.wpsType === WpsTypeEnums.PWPS ? "" : this.props.header.wpsHeatInputText}
                                pWpsArcEnergyText={this.props.header.wpsType === WpsTypeEnums.PWPS ? "" : this.props.header.wpsArcEnergyText} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {this.renderTemperature()}
                        </Col>
                        <Col sm={6}>
                            {this.renderAfterWelding()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderOtherInformation()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {this.renderManufacturer()}
                        </Col>
                        <Col sm={6}>
                            {this.renderExaminer()}
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderHead() {
        return (
            <React.Fragment key="rendeWpsFormHeas">
                <Card>
                    <CardBody>
                        <div>
                            <Row form>
                                <Col sm={3}>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerDate" size="sm">{t('Date')}</Label>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="date" max="9999-12-30" bssize="sm" name="headerDate" id="headerDate"
                                                    value={this.props.header.date} onChange={(event) => this.onChangeHeaderDate(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerProject" size="sm">{t('Project')}</Label>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="headerProject" id="headerProject"
                                                    value={this.props.header.project}
                                                    onChange={(event) => this.onChangeHeaderProject(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-left">
                                                <Form inline>
                                                    <Label for="headerByStandards" size="sm">{t('ByStandard')}</Label>
                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    <FormGroup check>
                                                        <Label check size="sm">
                                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="checkbox" bssize="sm" name="wpsHeaderByStandards" id="wpsHeaderByStandards"
                                                                checked={this.props.header.enableStandardText}
                                                                onChange={(event) => this.onChangeEnableStandardText(event)}
                                                                placeholder="" />{''} {t('Revise')}
                                                            <UncontrolledTooltip placement="right" target={"wpsHeaderByStandards"} >
                                                                {t('ReviseOrEnterYourOwnChoice')}!
			                                                </UncontrolledTooltip>
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                                {this.props.header.enableStandardText ?
                                                    <FormGroup>
                                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                            name="wpsStandardText" id="wpsStandardText"
                                                            value={this.props.header.standardText}
                                                            onChange={(event) => this.onChangeStandardText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup> :
                                                    <FormGroup>
                                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="select" bssize="sm" name="headerByStandards" id="headerByStandards"
                                                            value={this.props.header.selectedByStandard.id}
                                                            onChange={(event) => this.onChangeHeaderByStandards(event)}
                                                            placeholder="">
                                                            <option key="byStandardId0" id="byStandardId0" value="0"></option>
                                                            {this.props.standards.map((standard, index) => (
                                                                <option key={"byStandardId" + standard.id} id={"byStandardId" + standard.id} value={standard.id}>{standard.name}</option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-center">
                                                <LogoImage customerLogo={this.props.header.customerLogo} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-center">
                                                {(this.props.header.wpsType === WpsTypeEnums.PWPS) ?
                                                    <h2>{t('PreliminaryWeldingProcedureSpecification')}</h2> : <h2>{t('WeldingProcedureSpecification')}</h2>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-center">
                                                <p className="font-weight-normal lead">
                                                    {(this.props.header.wpsType === WpsTypeEnums.PWPS) ? "(pWPS)" : "(WPS)"} ISO 15609-1
                                                </p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={3}>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerRevisionNumber" size="sm">{t('RevisionNumber')}</Label>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="headerRevisionNumber" id="headerRevisionNumber"
                                                    value={this.props.header.revisionNumber}
                                                    onChange={(event) => this.onChangeHeaderRevisionNumber(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerPWPSNumber" size="sm">{(this.props.header.wpsType === WpsTypeEnums.PWPS) ? t('pWPSNumber') :  t('WPSNumber')}</Label>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="headerPWPSNumber" id="headerPWPSNumber"
                                                    value={this.props.header.pwpsNumber}
                                                    onChange={(event) => this.onChangeHeaderPWPSNumber(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerWPQRNumber" size="sm">{(this.props.header.wpsType === WpsTypeEnums.PWPS) ? t('PrelWPQRNumber') : t('WPQRNumber')}</Label>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="headerWPQRNumber" id="headerWPQRNumber"
                                                    value={this.props.header.wpqrNumber}
                                                    onChange={(event) => this.onChangeHeaderWPQRNumber(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderParentMaterial() {
    return (
        <React.Fragment key="rendeWpsFormParentMaterial">
            <Card>
                <CardBody>
                    <CardTitle>
                        <h5>{t('BaseMaterial')}<span>&nbsp;</span><Button className="btn-sm-padding-right-left" disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="button" color="link" size="sm" id="parentMaterialAutoFilling" onClick={(event) => this.onClickAutoFilling(event)}><i className="fas fa-clone"></i></Button><Label check size="sm">{t('CopyPart1ToPart2')}</Label></h5>
                        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="parentMaterialAutoFilling" toggle={this.toggle}>
                            {t('CopyPart1ToPart2')}
                        </Tooltip>
                    </CardTitle>
                    <div>
                        <Row form>
                            <Col sm>
                                <FormGroup>
                                    <Form inline>
                                        <Label for="parentMaterialBaseMaterials1" size="sm">Grundmaterial namn/nummer</Label>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <FormGroup check>
                                            <Label check size="sm"> 
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="checkbox" bssize="sm" name="applicationEnableBaseMaterial1Text" id="applicationEnableBaseMaterial1Text"
                                                        checked={this.props.parentMaterial.enableBaseMaterial1Text}
                                                        onChange={(event) => this.onChangeEnableBaseMaterial1Text(event)}
                                                    placeholder="" />{''} {t('Revise')}
                                                    <UncontrolledTooltip placement="right" target={"applicationEnableBaseMaterial1Text"} >
                                                        {t('ReviseOrEnterYourOwnChoice')}!
                                                    </UncontrolledTooltip>
                                                </Label>
                                        </FormGroup>
                                        </Form>
                                    {this.props.parentMaterial.enableBaseMaterial1Text ?
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                className="enable-base-material-text"
                                                name="BaseMaterial1Text" id="BaseMaterial1Text"
                                                value={this.props.parentMaterial.baseMaterial1Text}
                                                onChange={(event) => this.onChangeParentMaterialBaseMaterial1Text(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup> :
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bsSize="sm" name="parentMaterialBaseMaterials1" id="parentMaterialBaseMaterials1"
                                                value={this.props.parentMaterial.baseMaterial1SearchText}
                                                onChange={(event) => this.onChangeParentMaterialBaseMaterial1SearchText(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            {this.props.parentMaterial.baseMaterial1SearchResult.length !== 0 &&
                                                <ListGroup className="scroll-vertical">
                                                    {this.props.parentMaterial.baseMaterial1SearchResult.map((baseMaterial, index) => (
                                                        <ListGroupItem key={index} tag="button" action onClick={(event) => this.onClickParentMaterialSelectBaseMaterial1(event, baseMaterial)}>
                                                            {baseMaterial.name + (baseMaterial.number === "" ? "" : "/" + baseMaterial.number)}
                                                        </ListGroupItem>
                                                    ))}
                                                </ListGroup>
                                            }
                                        </FormGroup>
                                    }
                                    <small className="text-muted">Del 1</small>
                                </FormGroup>
                            </Col>
                            <Col sm>
                                <FormGroup>
                                    <Label for="parentMaterialMaterialGroup1" size="sm">{t('MaterialGroup')} (ISO/TR 15608)</Label>
                                    {this.props.parentMaterial.enableBaseMaterial1Text ?
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                className="enable-base-material-text"
                                                name="materialGroup1Text" id="materialGroup1Text"
                                                value={this.props.parentMaterial.materialGroup1Text}
                                                onChange={(event) => this.onChangeParentMaterialMaterialGroup1Text(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup> :
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" readOnly bsSize="sm" name="parentMaterialMaterialGroup1" id="parentMaterialMaterialGroup1"
                                                value={this.props.parentMaterial.selectedMaterialGroup1.name}
                                                placeholder="" />
                                        </FormGroup>
                                    }
                                    <small className="text-muted">Del 1</small>
                                </FormGroup>
                            </Col>
                            <Col sm>
                                <FormGroup>
                                    <Label for="parentMaterialTechnicalDeliveryConditions1" size="sm">{t('TechnicalDeliveryStandard')}</Label>
                                    {this.props.parentMaterial.enableBaseMaterial1Text ?
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                className="enable-base-material-text"
                                                name="technicalDeliveryCondition1Text" id="technicalDeliveryCondition1Text"
                                                value={this.props.parentMaterial.technicalDeliveryCondition1Text}
                                                onChange={(event) => this.onChangeParentTechnicalDeliveryCondition1Text(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup> :
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="select" bsSize="sm" name="parentMaterialTechnicalDeliveryConditions1" id="parentMaterialTechnicalDeliveryConditions1"
                                                value={this.props.parentMaterial.selectedTechnicalDeliveryCondition1.id}
                                                onChange={(event) => this.onChangeParentMaterialTechnicalDeliveryConditions1(event)}
                                                placeholder="">
                                                <option key="technicalDeliveryConditionId01" id="technicalDeliveryConditionId01" value="0"></option>
                                                {this.props.parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions.map((technicalDeliveryCondition, index) => (
                                                    <option key={"technicalDeliveryConditionId" + technicalDeliveryCondition.id + "1"} id={"technicalDeliveryConditionId" + technicalDeliveryCondition.id + "1"} value={technicalDeliveryCondition.id}>{technicalDeliveryCondition.name}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    }
                                    <small className="text-muted">Del 1</small>
                                </FormGroup>
                            </Col>
                            <Col sm>
                                <FormGroup>
                                    <Form inline>
                                        <Label for="parentMaterialEnableMaterialThickness" size="sm">{t('ProductThickness')} (mm)</Label>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <FormGroup check>
                                            <Label check size="sm">
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="checkbox" bssize="sm" name="parentMaterialEnableMaterialThickness" id="parentMaterialEnableMaterialThickness"
                                                    checked={this.props.parentMaterial.enableMaterialThickness}
                                                    onChange={(event) => this.onChangeEnableMaterialThicknessText(event)}
                                                    placeholder="" />{''} {t('Revise')}
                                                <UncontrolledTooltip placement="right" target={"parentMaterialEnableMaterialThickness"} >
                                                    {t('ReviseOrEnterYourOwnChoice')}!
						                        </UncontrolledTooltip>
                                            </Label>
                                        </FormGroup>
                                    </Form>
                                    <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm" name="parentMaterialMaterialThickness1" id="parentMaterialMaterialThickness1"
                                        className={this.props.parentMaterial.enableMaterialThickness ? "enable-materialThickness-text" : ""} 
                                        value={this.props.parentMaterial.materialThickness1}
                                        onChange={(event) => this.onChangeParentMaterialMaterialThickness1(event)}
                                        onKeyPress={(event) => this.onKeyPress(event)}
                                        placeholder="" />
                                    <small className="text-muted">{t('Part')} 1</small>
                                </FormGroup>
                            </Col>
                            <Col sm>
                                <FormGroup>
                                    <Form inline>
                                        <Label for="parentMaterialDiameter1" size="sm">{t('Diameter')} (mm)</Label>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <FormGroup check>
                                            <Label check size="sm">
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="checkbox" bssize="sm" name="applicationEnableDiameterText" id="applicationEnableDiameterText"
                                                    checked={this.props.parentMaterial.enableDiameter}
                                                    onChange={(event) => this.onChangeEnableDiameterText(event)}
                                                    placeholder="" />{''} {t('Revise')}
                                                <UncontrolledTooltip placement="right" target={"applicationEnableDiameterText"} >
                                                    {t('ReviseOrEnterYourOwnChoice')}!
						                        </UncontrolledTooltip>
                                            </Label>
                                        </FormGroup>
                                    </Form>
                                    <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm" name="parentMaterialDiameter1" id="parentMaterialDiameter1"
                                        className={this.props.parentMaterial.enableDiameter ? "enable-diameter-text" : ""} 
                                        value={this.props.parentMaterial.diameter1}
                                        onChange={(event) => this.onChangeParentMaterialDiameter1(event)}
                                        onKeyPress={(event) => this.onKeyPress(event)}
                                        placeholder="" />
                                    <small className="text-muted">{t('Part')} 1</small>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm>
                                <FormGroup>
                                    <Form inline>
                                        <Label for="parentMaterialBaseMaterials1" size="sm">Grundmaterial namn/nummer</Label>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <FormGroup check>
                                            <Label check size="sm">
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="checkbox" bssize="sm" name="applicationEnableBaseMaterial2Text" id="applicationEnableBaseMaterial2Text"
                                                    checked={this.props.parentMaterial.enableBaseMaterial2Text}
                                                    onChange={(event) => this.onChangeEnableBaseMaterial2Text(event)}
                                                    placeholder="" />{''} {t('Revise')}
                                                <UncontrolledTooltip placement="right" target={"applicationEnableBaseMaterial2Text"} >
                                                    {t('ReviseOrEnterYourOwnChoice')}!
                                                    </UncontrolledTooltip>
                                            </Label>
                                        </FormGroup>
                                    </Form>
                                    {this.props.parentMaterial.enableBaseMaterial2Text ?
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm" name="baseMaterial2Text" id="baseMaterial2Text"
                                                className="enable-base-material-text"
                                                value={this.props.parentMaterial.baseMaterial2Text}
                                                onChange={(event) => this.onChangeParentMaterialBaseMaterial2Text(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup> :
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bsSize="sm" name="parentMaterialBaseMaterials2" id="parentMaterialBaseMaterials2"
                                                value={this.props.parentMaterial.baseMaterial2SearchText}
                                                onChange={(event) => this.onChangeParentMaterialBaseMaterial2SearchText(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            {this.props.parentMaterial.baseMaterial2SearchResult.length !== 0 &&
                                                <ListGroup className="scroll-vertical">
                                                    {this.props.parentMaterial.baseMaterial2SearchResult.map((baseMaterial, index) => (
                                                        <ListGroupItem key={index} tag="button" action onClick={(event) => this.onClickParentMaterialSelectBaseMaterial2(event, baseMaterial)}>
                                                            {baseMaterial.name + (baseMaterial.number === "" ? "" : "/" + baseMaterial.number)}
                                                        </ListGroupItem>
                                                    ))}
                                                </ListGroup>
                                            }
                                        </FormGroup>
                                    }
                                    <small className="text-muted">Del 2</small>
                                </FormGroup>
                            </Col>
                            <Col sm>
                                <FormGroup>
                                    <Label for="parentMaterialMaterialGroup2" size="sm">{t('MaterialGroup')} (ISO/TR 15608)</Label>
                                    {this.props.parentMaterial.enableBaseMaterial2Text ?
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                className="enable-base-material-text"
                                                name="materialGroup2Text" id="materialGroup2Text"
                                                value={this.props.parentMaterial.materialGroup2Text}
                                                onChange={(event) => this.onChangeParentMaterialMaterialGroup2Text(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup> :
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" readOnly bsSize="sm" name="parentMaterialMaterialGroup2" id="parentMaterialMaterialGroup2"
                                                value={this.props.parentMaterial.selectedMaterialGroup2.name}
                                                placeholder="" />
                                        </FormGroup>
                                    }
                                    <small className="text-muted">Del 2</small>
                                </FormGroup>
                            </Col>
                            <Col sm>
                                <FormGroup>
                                    <Label for="parentMaterialTechnicalDeliveryConditions2" size="sm">{t('TechnicalDeliveryStandard')}</Label>
                                    {this.props.parentMaterial.enableBaseMaterial2Text ?
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                className="enable-base-material-text"
                                                name="technicalDeliveryCondition2Text" id="technicalDeliveryCondition2Text"
                                                value={this.props.parentMaterial.technicalDeliveryCondition2Text}
                                                onChange={(event) => this.onChangeParentTechnicalDeliveryCondition2Text(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup> :
                                        <FormGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="select" bsSize="sm" name="parentMaterialTechnicalDeliveryConditions2" id="parentMaterialTechnicalDeliveryConditions2"
                                                value={this.props.parentMaterial.selectedTechnicalDeliveryCondition2.id}
                                                onChange={(event) => this.onChangeParentMaterialTechnicalDeliveryConditions2(event)}
                                                placeholder="">
                                                <option key="technicalDeliveryConditionId02" id="technicalDeliveryConditionId02" value="0"></option>
                                                {this.props.parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions.map((technicalDeliveryCondition, index) => (
                                                    <option key={"technicalDeliveryConditionId" + technicalDeliveryCondition.id + "2"} id={"technicalDeliveryConditionId" + technicalDeliveryCondition.id + "2"} value={technicalDeliveryCondition.id}>{technicalDeliveryCondition.name}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    }
                                    <small className="text-muted">Del 2</small>
                                </FormGroup>
                            </Col>
                            <Col sm>
                                <FormGroup>
                                    <Label for="parentMaterialMaterialThickness2" size="sm">{t('ProductThickness')} (mm)</Label>
                                    <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm" name="parentMaterialMaterialThickness2" id="parentMaterialMaterialThickness2"
                                        className={this.props.parentMaterial.enableMaterialThickness ? "enable-materialThickness-text" : ""} 
                                        value={this.props.parentMaterial.materialThickness2}
                                        onChange={(event) => this.onChangeParentMaterialMaterialThickness2(event)}
                                        onKeyPress={(event) => this.onKeyPress(event)}
                                        placeholder="" />
                                    <small className="text-muted">{t('Part')} 2</small>
                                </FormGroup>
                            </Col>
                            <Col sm>
                                <FormGroup>
                                    <Label for="parentMaterialDiameter2" size="sm">{t('Diameter')}  (mm)</Label>
                                    <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm" name="parentMaterialDiameter2" id="parentMaterialDiameter2"
                                        className={this.props.parentMaterial.enableDiameter ? "enable-diameter-text" : ""} 
                                        value={this.props.parentMaterial.diameter2}
                                        onChange={(event) => this.onChangeParentMaterialDiameter2(event)}
                                        onKeyPress={(event) => this.onKeyPress(event)}
                                        placeholder="" />
                                    <small className="text-muted">{t('Part')} 2</small>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
    }

    private renderWeldingData() {
        return (
            <React.Fragment key="rendeWpsFormWeldingData">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldingData')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="weldingDataJointPreparationAndCleaning" size="sm">{t('MethodForPreparationAndCleaning')}</Label>
                                        <InputGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bssize="sm" name="weldingDataJointPreparation" id="weldingDataJointPreparation"
                                                value={this.props.weldingData.selectedJointPreparationText}
                                                onChange={(event) => this.onChangeWeldingDataSetJointPreparationText(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            <InputGroupButtonDropdown disabled={this.props.header.revisionWpsAction === RevisionActionType.View}  addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                                <DropdownToggle caret size="sm" className="btn btn-primary">
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {this.props.weldingData.jointPreparations.map((jointPreparation) => (
                                                        <div className="btn text-left" key={"jointPreparationId" + jointPreparation.id} id={"jointPreparationId" + jointPreparation.id} onClick={(event) => this.onClickWeldingDataSetJointPreparationText(jointPreparation.description)}><div style={{ width: "100%" }}><a>{jointPreparation.description}</a></div></div>
                                                    ))}
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Form inline>
                                            <Label for="weldingProcessMakesA" size="sm">{t('JointTypes')}</Label>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <FormGroup check>
                                                <Label check size="sm">
                                                    <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="checkbox" bssize="sm" name="weldingDataJointTypeText" id="weldingDataJointTypeText"
                                                        checked={this.props.weldingData.enableJointTypeText}
                                                        onChange={(event) => this.onChangeEnableJointTypeText(event)}
                                                        placeholder="" />{''} {t('Revise')}
                                                    <UncontrolledTooltip placement="right" target={"weldingDataJointTypeText"} >
                                                        {t('ReviseOrEnterYourOwnChoice')}!
				                                    </UncontrolledTooltip>
                                                </Label>
                                            </FormGroup>
                                        </Form>
                                        {this.props.weldingData.enableJointTypeText ?
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="weldingProcessJointTypeText" id="weldingProcessJointTypeText"
                                                    value={this.props.weldingData.jointTypeText}
                                                    onChange={(event) => this.onChangeJointTypeText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="select" bsSize="sm" name="weldingDataJointTypes" id="weldingDataJointTypes"
                                                    value={this.props.weldingData.selectedJointType.id}
                                                    onChange={(event) => this.onChangeWeldingDataJointTypes(event)}
                                                    placeholder="">
                                                    <option key="jointTypeId0" id="jointTypeId0" value="0"></option>
                                                    {this.props.weldingData.jointTypes.map((jointType, index) => (
                                                        <option key={"jointTypeId" + jointType.id} id={"jointTypeId" + jointType.id} value={jointType.id}>{jointType.description}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="weldingDataMaxWidthOfRun" size="sm">{t('Oscillation')} ({t('MaxWidthOfRun')} (mm))</Label>
                                        <InputGroup >
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm" name="weldingDataMaxWidthOfRun" id="weldingDataMaxWidthOfRun"
                                                value={this.props.weldingData.maxWidthOfRun} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingDataMaxWidthOfRun(event)}
                                                placeholder="" />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="weldingDataRootSupportDetails" size="sm">{t('RootSupportDetails')}</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="select" bsSize="sm" name="weldingDataRootSupportDetails" id="weldingDataRootSupportDetails"
                                            value={this.props.weldingData.selectedRootSupportDetails.id}
                                            onChange={(event) => this.onChangeWeldingDataRootSupportDetails(event)}
                                            placeholder="">
                                            <option key="rootSupportDetailId0" id="rootSupportDetailId0" value="0"></option>
                                            {this.props.weldingData.rootSupportDetailsSearchResult.map((rootSupportDetails, index) => (
                                                <option key={"rootSupportDetailId" + rootSupportDetails.id} id={"rootSupportDetailId" + rootSupportDetails.id} value={rootSupportDetails.id}>{this.TranslateBoxes(rootSupportDetails.name, SweToEngRootSupport)} </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="weldingDataNozzleDiameter" size="sm">{this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t('NozzleSize') + " (l/min)" : t('NozzleDiameter') + " (mm) [MIG, MAG, TIG, 15X]"}</Label>
                                        <InputGroup >
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="weldingDataNozzleDiameter" id="weldingDataNozzleDiameter"
                                                value={this.props.weldingData.nozzleDiameter}
                                                onChange={(event) => this.onChangeWeldingDataNozzleDiameter(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={28} dropdownDisabled={this.props.header.revisionWpsAction === RevisionActionType.View} dropDownListsCallback={this.props.setWeldingDataNozzleDiameter} rowNumber={0} ></DropdownLists>
                                        </InputGroup >
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    {this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ?
                                        <TypeOfBacking pTypeOfBackingText={this.props.weldingProcess.typeOfBackingText} enableTypeOfBackingTextView={this.props.header.revisionWpsAction === RevisionActionType.View} /> :
                                        <FormGroup>
                                            <Label for="weldingDataStickOut" size="sm">{t('StickOutLengthMuzzleDistance')} (mm) (MIG, MAG, 15X)</Label>
                                            <InputGroup >
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="weldingDataStickOut" id="weldingDataStickOut"
                                                    value={this.props.weldingData.stickOut}
                                                    onChange={(event) => this.onChangeWeldingDataStickOut(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                                <DropdownLists listNumber={28} dropdownDisabled={this.props.header.revisionWpsAction === RevisionActionType.View} dropDownListsCallback={this.props.setWeldingDataStickOut} rowNumber={0} ></DropdownLists>
                                            </InputGroup >
                                        </FormGroup>
                                    }
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 2}>
                                    <FormGroup>
                                        <Label for="weldingDataWeldingPosition" size="sm">{t('WeldingPosition')} 1</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="select" bsSize="sm" name="weldingDataWeldingPosition" id="weldingDataWeldingPosition"
                                            value={this.props.weldingData.selectedWeldingPosition.id}
                                            onChange={(event) => this.onChangeWeldingDataWeldingPositionSearchText(event)}
                                            placeholder="">
                                            <option key="weldingPositionId0"  id="weldingPositionId0" value="0"></option>
                                            {this.props.weldingData.weldingPositionSearchResult.map((weldingPosition) => (
                                                    (<option key={"weldingPositionId" + weldingPosition.id} id={"weldingPositionId" + weldingPosition.id} value={weldingPosition.id}>{weldingPosition.name} </option>)

                                                ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataWeldingPosition2" size="sm">{t('WeldingPosition')} 2</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="select" bsSize="sm" name="weldingDataWeldingPosition2" id="weldingDataWeldingPosition2"
                                            value={this.props.weldingData.selectedWeldingPosition2.id}
                                            onChange={(event) => this.onChangeWeldingDataWeldingPosition2SearchText(event)}
                                            placeholder="">
                                            <option key="weldingPositionId2" id="weldingPositionId2" value="0"></option>
                                            {this.props.weldingData.weldingPositionSearchResult.map((weldingPosition) => (
                                                <option key={"weldingPosition2Id" + weldingPosition.id} id={"weldingPosition2Id" + weldingPosition.id} value={weldingPosition.id}>{weldingPosition.name} </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataTungstenElectrode" size="sm">{t('TungstenElectrode')}, {t('TypeDimension')} (mm) [14X, 15X]</Label>
                                        <InputGroup >
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="weldingDataTungstenElectrode" id="weldingDataTungstenElectrode"
                                            value={this.props.weldingData.tungstenElectrode}
                                            onChange={(event) => this.onChangeWeldingDataTungstenElectrode(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={22} dropDownListsCallback={this.props.setWeldingDataTungstenElectrode} rowNumber={0} ></DropdownLists>
                                        </InputGroup >
                                    </FormGroup>
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataElectrodeTorchAngle111" size="sm">{t('ElectrodeTorchAngle111')}</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="weldingDataElectrodeTorchAngle111" id="weldingDataElectrodeTorchAngle111"
                                            value={this.props.weldingData.electrodeTorchAngle111}
                                            onChange={(event) => this.onChangeWeldingDataElectrodeTorchAngle111(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataElectrodeTorchAngle" size="sm">{t('TorchAngle')} (MIG, MAG, {t('mechanical')})</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="weldingDataElectrodeTorchAngle" id="weldingDataElectrodeTorchAngle"
                                            value={this.props.weldingData.electrodeTorchAngle}
                                            onChange={(event) => this.onChangeWeldingDataElectrodeTorchAngle(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingDataTackWelds" size="sm">{t('ProcessTackWelds')}</Label>
                                        <InputGroup>
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="weldingDataTackWelds" id="weldingDataTackWelds"
                                                value={this.props.weldingData.tackWeldText}
                                                onChange={(event) => this.onChangeWeldingDataTackWeldSearchText(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={27} dropdownDisabled={this.props.header.revisionWpsAction === RevisionActionType.View} dropDownListsCallback={this.props.setWeldingDataSelectedTackWeld} rowNumber={0} ></DropdownLists>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingDataLengthTackWelds" size="sm">{t('LengthTackWelds')} (mm)</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="weldingDataLengthTackWelds" id="weldingDataLengthTackWelds"
                                            value={this.props.weldingData.lengthTackWelds}
                                            onChange={(event) => this.onChangeWeldingDataLengthTackWelds(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="weldingDataRootProtectionGases" size="sm">{t('RootProtectionGas')}</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bsSize="sm" name="weldingDataRootProtectionGases" id="weldingDataRootProtectionGases"
                                            value={this.props.weldingData.rootProtectionGasSearchText}
                                            onChange={(event) => this.onChangeWeldingDataRootProtectionGasSearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingData.rootProtectionGasSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingData.rootProtectionGasSearchResult.map((rootProtectionGas, index) => (
                                                    <ListGroupItem key={index} tag="button" action onClick={(event) => this.onClickWeldingDataSelectRootProtectionGas(event, rootProtectionGas)}>
                                                        {rootProtectionGas.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col sm={3} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <Gases enableGasesTextView={this.props.header.revisionWpsAction === RevisionActionType.View} pSelectedPlasmaGas={this.props.weldingData.selectedPlasmaGas} setSelectedPlasmaGas={this.props.setWeldingDataSelectedPlasmaGas} />
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataPlasmaflowrate" size="sm">{t('Plasmaflowrate')} (l/min)</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="weldingDataPlasmaflowrate" id="weldingDataPlasmaflowrate"
                                            value={this.props.weldingData.plasmaflowrate}
                                            onChange={(event) => this.onChangeWeldingDataPlasmaflowrate(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
    private renderWeldingProcess() {
        return (
            <React.Fragment key="rendeWpsFormWeldingProcess">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldingProcess')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 3 : 2}>
                                    <FormGroup>
                                        <Label for="weldingProcessWeldingMethodA" size="sm">{t('WeldingMethod')}</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bsSize="sm" name="weldingProcessWeldingMethodA" id="weldingProcessWeldingMethodA"
                                            value={this.props.weldingProcess.weldingMethodASearchText}
                                            onChange={(event) => this.onChangeWeldingProcessWeldingMethodASearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingProcess.weldingMethodASearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingProcess.weldingMethodASearchResult.map((weldingMethod, index) => (
                                                    <ListGroupItem key={index} tag="button" action onClick={(event) => this.onClickWeldingProcessSelectWeldingMethodA(event, weldingMethod)}>
                                                        {weldingMethod.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                        <small className="text-muted">{t('Index')} A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Form inline>
                                            <Label for="weldingProcessMakesA" size="sm">{t('AdditivesPowder')} ({t('tradeName')})</Label>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <FormGroup check>
                                                <Label check size="sm">
                                                    <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="checkbox" bssize="sm" name="weldingProcessEnableMakeAText" id="weldingProcessEnableMakeAText"
                                                        checked={this.props.weldingProcess.enableMakeAText}
                                                        onChange={(event) => this.onChangeEnableMakeAText(event)}
                                                        placeholder="" />{''} {t('Revise')}
                                                    <UncontrolledTooltip placement="right" target={"weldingProcessEnableMakeAText"} >
                                                        {t('ReviseOrEnterYourOwnChoice')}!
                                                    </UncontrolledTooltip>
                                                </Label>
                                            </FormGroup>
                                        </Form>
                                        {this.props.weldingProcess.enableMakeAText ?
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="weldingProcessBaseMaterial1Text" id="weldingProcessBaseMaterial1Text"
                                                    value={this.props.weldingProcess.makeAText}
                                                    onChange={(event) => this.onChangeMakeAText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bssize="sm" name="weldingProcessMakesA" id="weldingProcessMakesA"
                                                    value={this.props.weldingProcess.makeASearchText}
                                                    onChange={(event) => this.onChangeWeldingProcessMakeASearchText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                                {this.props.weldingProcess.makeASearchResult.length !== 0 &&
                                                    <ListGroup className="scroll-vertical">
                                                        {this.props.weldingProcess.makeASearchResult.map((make, index) => (
                                                            <ListGroupItem key={index} tag="button" action onClick={(event) => this.onClickWeldingProcessSelectMakeA(event, make)}>
                                                                {make.name}
                                                            </ListGroupItem>
                                                        ))}
                                                    </ListGroup>
                                                }
                                            </FormGroup>
                                        }
                                        <small className="text-muted">Index A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingProcessDesignationA" size="sm">{t('Designation')}</Label>
                                        {this.props.weldingProcess.enableMakeAText ?
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="weldingProcessDesignationAText" id="weldingProcessDesignationAText"
                                                    value={this.props.weldingProcess.designationAText}
                                                    onChange={(event) => this.onChangeDesignationAText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" readOnly bssize="sm" name="weldingProcessDesignationA" id="weldingProcessDesignationA"
                                                    value={this.props.weldingProcess.selectedDesignationA.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">Index A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 2 : 1}>
                                    <FormGroup>
                                        <Label for="weldingProcessFmGroupA" size="sm">{t('FMGroup')}</Label>
                                        {this.props.weldingProcess.enableMakeAText ?
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="weldingProcessfmGroupAText" id="weldingProcessfmGroupAText"
                                                    value={this.props.weldingProcess.fmGroupAText}
                                                    onChange={(event) => this.onChangefmGroupAText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" readOnly bssize="sm" name="weldingProcessFmGroupA" id="weldingProcessFmGroupA"
                                                    value={this.props.weldingProcess.selectedFMGroupA.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">Index A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingProcessShieldingGasesA" size="sm">{this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t('BurnerGasType') : t('ShieldingGas') + ' (' + t('TradeName') + ')'}</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bsSize="sm" name="weldingProcessShieldingGasesA" id="weldingProcessShieldingGasesA"
                                            value={this.props.weldingProcess.shieldingGasASearchText}
                                            onChange={(event) => this.onChangeWeldingProcessShieldingGasASearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingProcess.shieldingGasASearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingProcess.shieldingGasASearchResult.map((shieldingGas, index) => (
                                                    <ListGroupItem key={index} tag="button" action onClick={(event) => this.onClickWeldingProcessSelectShieldingGasA(event, shieldingGas)}>
                                                        {shieldingGas.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                        <small className="text-muted">{t('Index')} A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingProcessArcTypesA" size="sm">{t('ArcType')}</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="select" bsSize="sm" name="weldingProcessArcTypesA" id="weldingProcessArcTypesA"
                                            value={this.props.weldingProcess.selectedArcTypeA.id}
                                            onChange={(event) => this.onChangeWeldingProcessArcTypeASearchText(event)}
                                            placeholder="">
                                            <option key="arcTypeId0" id="arcTypeId0" value="0"></option>
                                            {this.props.weldingProcess.arcTypeSearchResult.map((arcType) => (
                                                <option key={"arcTypeId" + arcType.id} id={"arcTypeId" + arcType.id} value={arcType.id}>{this.TranslateBoxes(arcType.name, SweToEngArcType)} </option>
                                            ))}
                                        </Input>
                                        <small className="text-muted">{t('Index')} A</small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form hidden={false}>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 3 : 2}>
                                    <FormGroup>
                                        <Label for="weldingProcessWeldingMethodB" size="sm">{t('WeldingMethod')}</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bsSize="sm" name="weldingProcessWeldingMethodB" id="weldingProcessWeldingMethodB"
                                            value={this.props.weldingProcess.weldingMethodBSearchText}
                                            onChange={(event) => this.onChangeWeldingProcessWeldingMethodBSearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingProcess.weldingMethodBSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingProcess.weldingMethodBSearchResult.map((weldingMethod, index) => (
                                                    <ListGroupItem key={index} tag="button" action onClick={(event) => this.onClickWeldingProcessSelectWeldingMethodB(event, weldingMethod)}>
                                                        {weldingMethod.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                        <small className="text-muted">{t('Index')} B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Form inline>
                                            <Label for="weldingProcessMakesB" size="sm">{t('AdditivesPowder')} ({t('tradeName')})</Label>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <FormGroup check>
                                                <Label check size="sm">
                                                    <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="checkbox" bssize="sm" name="weldingProcessEnableMakeBText" id="weldingProcessEnableMakeBText"
                                                        checked={this.props.weldingProcess.enableMakeBText}
                                                        onChange={(event) => this.onChangeEnableMakeBText(event)}
                                                        placeholder="" />{''} {t('Revise')}
                                                    <UncontrolledTooltip placement="right" target={"weldingProcessEnableMakeBText"} >
                                                        {t('ReviseOrEnterYourOwnChoice')}!
                                                    </UncontrolledTooltip>
                                                </Label>
                                            </FormGroup>
                                        </Form>
                                        {this.props.weldingProcess.enableMakeBText ?
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="weldingProcessBaseMaterial1Text" id="weldingProcessBaseMaterial1Text"
                                                    value={this.props.weldingProcess.makeBText}
                                                    onChange={(event) => this.onChangeMakeBText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bssize="sm" name="weldingProcessMakesB" id="weldingProcessMakesB"
                                                    value={this.props.weldingProcess.makeBSearchText}
                                                    onChange={(event) => this.onChangeWeldingProcessMakeBSearchText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                                {this.props.weldingProcess.makeBSearchResult.length !== 0 &&
                                                    <ListGroup className="scroll-vertical">
                                                        {this.props.weldingProcess.makeBSearchResult.map((make, index) => (
                                                            <ListGroupItem key={index} tag="button" action onClick={(event) => this.onClickWeldingProcessSelectMakeB(event, make)}>
                                                                {make.name}
                                                            </ListGroupItem>
                                                        ))}
                                                    </ListGroup>
                                                }
                                            </FormGroup>
                                        }
                                        <small className="text-muted">Index B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingProcessDesignationB" size="sm">{t('Designation')}</Label>
                                        {this.props.weldingProcess.enableMakeBText ?
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="weldingProcessDesignationBText" id="weldingProcessDesignationBText"
                                                    value={this.props.weldingProcess.designationBText}
                                                    onChange={(event) => this.onChangeDesignationBText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" readOnly bssize="sm" name="weldingProcessDesignationB" id="weldingProcessDesignationB"
                                                    value={this.props.weldingProcess.selectedDesignationB.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">Index B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 2 : 1}>
                                    <FormGroup>
                                        <Label for="weldingProcessFmGroupB" size="sm">{t('FMGroup')}</Label>
                                        {this.props.weldingProcess.enableMakeBText ?
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="applicationfmGroupBText" id="applicationfmGroupBText"
                                                    value={this.props.weldingProcess.fmGroupBText}
                                                    onChange={(event) => this.onChangefmGroupBText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" readOnly bssize="sm" name="weldingProcessFmGroupB" id="weldingProcessFmGroupB"
                                                    value={this.props.weldingProcess.selectedFMGroupB.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">Index B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingProcessShieldingGasesB" size="sm">{this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t('BurnerGasType') : t('ShieldingGas') + ' (' + t('TradeName') + ')'}</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} autoComplete="off" type="search" bsSize="sm" name="weldingProcessShieldingGasesB" id="weldingProcessShieldingGasesB"
                                            value={this.props.weldingProcess.shieldingGasBSearchText}
                                            onChange={(event) => this.onChangeWeldingProcessShieldingGasBSearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingProcess.shieldingGasBSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingProcess.shieldingGasBSearchResult.map((shieldingGas, index) => (
                                                    <ListGroupItem key={index} tag="button" action onClick={(event) => this.onClickWeldingProcessSelectShieldingGasB(event, shieldingGas)}>
                                                        {shieldingGas.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                        <small className="text-muted">{t('Index')} B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingProcessArcTypesB" size="sm">{t('ArcType')}</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="select" bsSize="sm" name="weldingProcessArcTypesB" id="weldingProcessArcTypesB"
                                            value={this.props.weldingProcess.selectedArcTypeB.id}
                                            onChange={(event) => this.onChangeWeldingProcessArcTypeBSearchText(event)}
                                            placeholder="">
                                            <option key="arcTypeBId0" id="arcTypeBId0" value="0"></option>
                                            {this.props.weldingProcess.arcTypeSearchResult.map((arcType) => (
                                                <option key={"arcTypeId" + arcType.id} id={"arcTypeId" + arcType.id} value={arcType.id}>{this.TranslateBoxes(arcType.name, SweToEngArcType)} </option>
                                            ))}
                                        </Input>
                                        <small className="text-muted">{t('Index')} B</small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
    private renderJointDesign() {
        return (
            <React.Fragment key="rendeWpsFormJointDesign">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('JointDesign')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12} className="text-center">
                                    <DrawJointDesign imageValidationError={this.props.header.imageValidationError1}
                                        imageUpload={this.props.header.imageUpload1}
                                        setDrawJointDesignView={this.props.header.revisionWpsAction === RevisionActionType.View}
                                        modalId={1} showJointDesignModalId={this.state.showJointDesignModalId}
                                        show={this.props.showJointDesignModal}
                                        position={"wpsForm"}
                                        toggleShow={this.setToggleShowJointDesignModal}
                                        jointDesign={this.props.jointDesign} />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12} className="text-center">
                                    <FormGroup>
                                        <Button disabled={this.props.header.revisionWpsAction === RevisionActionType.View} color="primary" onClick={(event) => this.onClickToggleJointDesignModal()}>{t('Draw')}</Button><span>&nbsp;</span>
                                        <Button disabled={this.props.header.revisionWpsAction === RevisionActionType.View} className="btn btn-primary btn-file" >
                                            {t('Upload')}<input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="file" onChange={event => this.handleChangeDraJointDesign(event)} />
                                        </Button>
                                        <Button type="button" color="link" size="sm" id={"UploadInfoDj"} name={"UploadInfoDj"} >
                                            <i className="fas fa-info-circle"></i>
                                        </Button>
                                        <span>&nbsp;</span>
                                        <UncontrolledPopover disabled={this.props.header.revisionWpsAction === RevisionActionType.View} trigger="focus" placement="bottom" target={"UploadInfoDj"}>
                                            <PopoverHeader>{t('Upload')}</PopoverHeader>
                                            <PopoverBody>{t('UploadInfo')}</PopoverBody>
                                        </UncontrolledPopover>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderWeldSequence() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldSequences')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <WeldSequence imageValidationError={this.props.header.imageValidationError2}
                                        imageUpload={this.props.header.imageUpload2}
                                        show={this.state.showWeldSequenceModal}
                                        toggleShow={this.toggleWeldSequenceModal}
                                        pSetWeldSequenceView={this.props.header.revisionWpsAction === RevisionActionType.View} 
                                        weldSequence={this.props.weldSequence} />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12} className="text-center">
                                    <FormGroup>
                                        <Button disabled={this.props.header.revisionWpsAction === RevisionActionType.View} color="primary" onClick={(event) => this.onClickToggleWeldSequenceModal(event)}>{t('Draw')}</Button><span>&nbsp;</span>
                                        <Button disabled={this.props.header.revisionWpsAction === RevisionActionType.View} className="btn btn-primary btn-file" >
                                            {t('Upload')}<input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="file" onChange={event => this.handleChange(event)} />
                                        </Button>
                                        <Button type="button" color="link" size="sm" id={"UploadInfo"} name={"UploadInfo"} >
                                            <i className="fas fa-info-circle"></i>
                                        </Button>
                                        <span>&nbsp;</span>
                                        <UncontrolledPopover disabled={this.props.header.revisionWpsAction === RevisionActionType.View} trigger="focus" placement="bottom" target={"UploadInfo"}>
                                            <PopoverHeader>{t('Upload')}</PopoverHeader>
                                            <PopoverBody>{t('UploadInfo')}</PopoverBody>
                                        </UncontrolledPopover>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderTemperature() {
        return (
            <React.Fragment key="rendeWpsFormTemperature">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('Temperature')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="temperaturePreheatTemperature" size="sm">{t('MinPreHeatTemperature')} (°C)</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="temperaturePreheatTemperature" id="temperaturePreheatTemperature"
                                            value={this.props.temperature.preheatTemperature}
                                            onChange={(event) => this.onChangeTemperaturePreheatTemperature(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="temperatureMaxInterpassTemperature" size="sm">{t('MaxInterpassTemperature')} (°C)</Label>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="text" bssize="sm" name="temperatureMaxInterpassTemperature" id="temperatureMaxInterpassTemperature"
                                            value={this.props.temperature.maxInterpassTemperature}
                                            onChange={(event) => this.onChangeTemperatureMaxInterpassTemperature(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderParametersTableId() {
        return (
            <React.Fragment key="renderParametersTableId">
                <Card>
                    <CardBody>
                        <Row form>
                            <Col sm={3}>
                                    <FormGroup check>
                                        <Label check size="sm">
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="radio" bssize="sm" name="otherOptionsParametersTableId"
                                                value="0"
                                                checked={this.props.header.otherOptionParametersTableId === 0}
                                                onChange={(event) => this.onChangeOtherOptionsParametersTableId(event)}
                                                placeholder="" />{' '} WPS (ISO 15609)
                                        </Label>
                                    </FormGroup>
                            </Col>
                            <Col sm={3}>
                                    <FormGroup check>
                                        <Label check size="sm">
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="radio" bssize="sm" name="otherOptionsParametersTableId"
                                                value="3"
                                                checked={this.props.header.otherOptionParametersTableId === 3}
                                                onChange={(event) => this.onChangeOtherOptionsParametersTableId(event)}
                                                placeholder="" />{' '} WPS ({t('PulsedWelding')})
                                        </Label>
                                    </FormGroup>
                            </Col>
                            <Col sm={3}>
                                    <FormGroup check>
                                        <Label check size="sm">
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="radio" bssize="sm" name="otherOptionsParametersTableId"
                                                value="4"
                                                checked={this.props.header.otherOptionParametersTableId === 4}
                                                onChange={(event) => this.onChangeOtherOptionsParametersTableId(event)}
                                                placeholder="" />{' '} WPS ({t('WaveformControlledWelding')})
                                        </Label>
                                    </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="radio" bssize="sm" name="otherOptionsParametersTableId"
                                            value="5"
                                            checked={this.props.header.otherOptionParametersTableId === 5}
                                            onChange={(event) => this.onChangeOtherOptionsParametersTableId(event)}
                                            placeholder="" />{' '} WPS (Gassvetsning)
                                        </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderAfterWelding() {
        return (
            <React.Fragment key="rendeWpsFormAfterWelding">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('FollowUpHeatTreatmentAndOrAging')} (PWHT)</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="afterWeldingHeatingRate" size="sm">{t('MinAndMaxTemperature')} (°C), {t('HoldTime')} ({t('min')})</Label>
                                        <InputGroup >
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="textarea" bsSize="sm" name="afterWeldingHeatingRate" id="afterWeldingHeatingRate"
                                                value={this.props.afterWelding.heatingRate}
                                                onChange={(event) => this.onChangeAfterWeldingHeatingRate(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={20} dropdownDisabled={this.props.header.revisionWpsAction === RevisionActionType.View} dropDownListsCallback={this.props.setAfterWeldingHeatingRate} rowNumber={0} ></DropdownLists>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="afterWeldingCoolingRate" size="sm">{t('HeatingAndCoolingSpeed')} (°C/{t('hour')})</Label>
                                        <InputGroup >
                                            <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="textarea" bsSize="sm" name="afterWeldingCoolingRate" id="afterWeldingCoolingRate"
                                                value={this.props.afterWelding.coolingRate}
                                                onChange={(event) => this.onChangeAfterWeldingCoolingRate(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={21} dropdownDisabled={this.props.header.revisionWpsAction === RevisionActionType.View} dropDownListsCallback={this.props.setAfterWeldingCoolingRate} rowNumber={0} ></DropdownLists>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderOtherInformation() {
        return (
            <React.Fragment key="rendeWpsFormInformation">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('OtherInformation')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="textarea" bssize="sm" name="otherInformation" id="otherInformation"
                                            value={this.props.otherInformation.otherInformation} onChange={(event) => this.onChangeOtherInformationOtherInformation(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderManufacturer() {
        return (
            <React.Fragment key="rendeWpsFormManufacturer">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('Manufacturer')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="textarea" bssize="sm" name="manufacturer" id="manufacturer"
                                            value={this.props.header.manufacturer} onChange={(event) => this.onChangeManufacturer(event)}
                                            placeholder={t('Company') + ", " + t('Name')}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderExaminer() {
        return (
            <React.Fragment key="rendeWpsFormExaminer">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('Examiner')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Input disabled={this.props.header.revisionWpsAction === RevisionActionType.View} type="textarea" bssize="sm" name="examiner" id="examiner"
                                            value={this.props.header.examiner} onChange={(event) => this.onChangeExaminer(event)}
                                            placeholder={t('Name') + ", " + t('Date') + " XXXX-XX-XX"}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.wpsFormEdit;
}
export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    WPSFormEditStore.actionCreators // Selects which action creators are merged into the component's props
)(WPSFormEdit as any);