﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as WPQRCompleteStore from '../store/WPQRCompleteStore';
import { Button, Card, CardBody, Pagination, PaginationItem, CardTitle, Col, FormGroup, InputGroup, InputGroupText, Input, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, ButtonGroup, UncontrolledTooltip} from 'reactstrap';
import EventBus from '../store/eventBus';
import { WPQR } from '../store/WPQR';
import HelpFunctions from '../store/models/HelpFunctions';
import { t } from '../i18n';
import { RevisionActionType } from '../store/models/RevisionActionType';
import FileUpload from './FileUpload';
import HelpRangeOfQualificationExamination from '../store/models/HelpRangeOfQualificationExamination';
import DeletionModal from './otherComponents/DeletionModal';
import Loader from './Loader';

// At runtime, Redux will merge together...
type WPQRCompleteProps =
    WPQRCompleteStore.WPQRCompleteStoreState // ... state we've requested from the Redux store
    & typeof WPQRCompleteStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    pWeldingProtocolId: number;
    onSelectWpqr: (wpqr: WPQR) => void;
    onSelectWpqrRevision: (wpqr: WPQR) => void;
    onSelectWpqrView: (wpqr: WPQR) => void;
}

class WPQRComplete extends React.PureComponent<WPQRCompleteProps> {
    constructor(props: WPQRCompleteProps) {
        super(props);
    }
    public state = {
        wpqrCompleteIsOpen: true,
        currentWpqrId: 0,
        showDeletionModal: false,
        loader: false,
    };
    public setDeletionModalOpen(open: boolean) {
        this.setState({ showDeletionModal: open });
    }
    public componentDidMount() {
        const customerId = 0;// Get all wpqr
        this.props.getWPQRs(customerId);
        EventBus.on("refreshWpqrList", () => {
            this.props.getWPQRs(customerId);
            this.setState({ loader: false });
        });
        this.props.getWeldingMethodsSearch();
        this.props.getProductTypesAll();
        this.props.getJointTypesAll();
        this.props.getMaterialGroupAll();
        this.props.getRuntypeRangeOfQualification();
        this.props.getStandardsAll();
        this.props.clearSearchProperties();
    }
    componentDidUpdate(prevProps: any) {
        if (this.props.pWeldingProtocolId !== prevProps.pWeldingProtocolId) {
            const customerId = 0;// Get all wpqr
            this.props.getWPQRs(customerId);
        }
    }
    private onChangeFreeSearch(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setFreeSearch(event.currentTarget.value);
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeMaterialThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setMaterialThickness(event.currentTarget.value);
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeouterDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setOuterDiameter(event.currentTarget.value);
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onClickTabSettingModalCancel(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowTabSettingModal(false);
    }
    private onClickTabSettingModalOK(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowTabSettingModal(false);
    }

    private onClickTabSettingModalOpen(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowTabSettingModal(true);
    }

    private onChangeColumnDekraProcedureNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnDekraProcedureNumber(event.currentTarget.checked);
    }

    private onChangeColumnManufacturerWPQRNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnmanufacturerWPQRNumber(event.currentTarget.checked);
    }

    private onChangeColumnPwpsName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnPwpsName(event.currentTarget.checked);
    }

    private onChangeColumnQualificationStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnQualificationStandard(event.currentTarget.checked);
    }

    private onChangeColumnWeldingMethod(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnWeldingMethod(event.currentTarget.checked);
    }

    private onChangeColumnJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnJointTypes(event.currentTarget.checked);
    }

    private onChangeColumnProductType(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnProductType(event.currentTarget.checked);
    }

    private onChangeColumnMaterialThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMaterialThickness(event.currentTarget.checked);
    }

    private onChangeColumnOutsideDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnOutsideDiameter(event.currentTarget.checked);
    }

    private onChangeColumnMaterialGroup(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMaterialGroup(event.currentTarget.checked);
    }

    private onChangeColumnSingleMultiString(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnSingleMultiString(event.currentTarget.checked);
    }

    private onChangeColumnMakeAdditives(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMakeAdditives(event.currentTarget.checked);
    }
    private onChangeColumnMakeDesignation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMakeDesignation(event.currentTarget.checked);
    }
    private onChangeColumnRemark(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnRemark(event.currentTarget.checked);
    }
    //Pagination
    private handlePrevPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.wpqrPaginationPageIndex - 1);
    }

    private handleNextPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.wpqrPaginationPageIndex + 1);
    }

    private handlePageClick(event: React.MouseEvent<HTMLElement, MouseEvent>, i: number ) {
        event.preventDefault();
        this.props.setPaginationPageIndex(i);
    }
    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
    private onChangeWeldingMethod(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingMethodForSearch(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setStandardForSearch(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeProductTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedProductType(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedJointType(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeMaterialGroups(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedMaterialGroup(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeRunTypeRangeOfQualifications(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedRunTypeRangeOfQualification(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }

    private onClickRevisionWpsRow(wpqrId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip
        this.props.getWpqrRevisions(wpqrId);
        this.props.cleanSelectedRevision();
        this.props.setShowChooseWpsRevisionModal(true);
    }
    private onChangeSelectedRevision(event: React.ChangeEvent<HTMLInputElement>, index: number) {
        event.stopPropagation();
        this.props.setSelectedRevision(parseInt(event.currentTarget.value), index);
    }
    private onClickCreateRevision(event: React.MouseEvent<HTMLInputElement, MouseEvent>, createRevision: WPQR, index: number) {
        event.stopPropagation();
        this.props.closeWpsRevisionModal(true);
        createRevision.revisionNumber = index;
        createRevision.revisionWpqrAction = RevisionActionType.CreateRevision;
        this.props.onSelectWpqrRevision(createRevision);
    }

    private onClickWpsRevisionModalOK(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.closeWpsRevisionModal(true);
        this.props.onSelectWpqrRevision(this.props.wpqrRevision.selectedRevision);
    }
    private onClickWpsRevisionModalCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.closeWpsRevisionModal(false);
    }
    private onClickSelectWpqr(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, wpqr: WPQR) {
        event.preventDefault();
        this.props.onSelectWpqr(wpqr);
    }
    private onClickSelectWpqrView(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, wpqr: WPQR) {
        event.preventDefault();
        this.props.onSelectWpqrView(wpqr);
    }
    public removeWpqr() {
        this.setState({ loader: true });
        this.props.removeWpqr(this.state.currentWpqrId)
    }

    public onRemoveWpqrPress(wpqrId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip
        this.setState({ currentWpqrId: wpqrId });
        this.setDeletionModalOpen(true);
    }
    public render() {
        return (
            <React.Fragment key={"renderwpqrComplete"}>
                <Loader show={this.state.loader} title={t('RemovalInProgress')} />
                    <Row form>
                        <Col sm={3}>
                            <FormGroup>
                                <Card className="card-border-dekra">
                                    <CardBody>
                                        <CardTitle>
                                        </CardTitle>
                                            <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type="text" bsSize="sm" name="freeSearch"
                                                            value={this.props.searchProperties.freeSearch} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFreeSearch(event)}
                                                            placeholder={t('Search')} />
                                                    </InputGroup>
                                                </FormGroup>
                                                </Col>
                                            </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="exampleSelect">{t('QualificationStandard')}</Label>
                                                    <Input type="select" bssize="sm" name="ByStandard" id="ByStandard"
                                                        value={this.props.searchProperties.selectedStandard.id}
                                                        onChange={(event) => this.onChangeStandard(event)}
                                                        placeholder="">
                                                        <option key={"byStandardId0"} id="byStandardId0" value="0">--{t('all')}--</option>
                                                        {this.props.searchProperties.standards.map((standard, index) => (
                                                            <option key={"byStandardId" + standard.id} id={"byStandardId" + standard.id} value={standard.id}>{standard.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="exampleSelect">{t('WeldingMethod')}</Label>
                                                    <Input type="select" bssize="sm" name="ByWeldingMethod" id="ByWeldingMethod"
                                                        value={this.props.searchProperties.selectedWeldingMethod.id}
                                                        onChange={(event) => this.onChangeWeldingMethod(event)}
                                                        placeholder="">
                                                        <option key={"byStandardId0"} id="byStandardId0" value="0">--{t('all')}--</option>
                                                        {this.props.wpqrWeldingMethodsSearch.map((weldingMethod, index) => (
                                                            <option key={"byWeldingMethodId" + weldingMethod.id} id={"byWeldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.description}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqrJointTypes" size="sm">{t('JointType')} (BW, FW)</Label>
                                                    <Input type="select" bsSize="sm" name="wpqrJointTypes" id="wpqrJointTypes"
                                                        value={this.props.searchProperties.selectedJointType.id}
                                                        onChange={(event) => this.onChangeJointTypes(event)}
                                                        placeholder="">
                                                        <option id="jointTypeId0" value="0">--{t('all')}--</option>
                                                        {this.props.searchProperties.jointTypes.map((jointType, index) => (
                                                            <option key={"jointTypeId" + jointType.id} id={"jointTypeId" + jointType.id} value={jointType.id}>{jointType.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                                <Col xs={6} sm={6}>
                                                    <FormGroup>
                                                        <Label for="productTypes" size="sm">{t('ProductType')}: {t('pipe')} (T), {t('plate')} (P)</Label>
                                                        <Input type="select" bsSize="sm" name="productTypes" id="productTypes"
                                                            value={this.props.searchProperties.selectedProductType.id}
                                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeProductTypes(event)}
                                                            placeholder="">
                                                            <option key="productType2Id0" id="productType2Id0" value="0">--{t('all')}--</option>
                                                            {this.props.searchProperties.productTypes.map((productType, index) => (
                                                                <option key={"productType2Id" + productType.id} id={"productType2Id" + productType.id} value={productType.id}>{productType.name}</option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        <Row form>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="materialThickness">{t('BaseMaterialThickness')} (mm)</Label>
                                                    <Input type="text" name="materialThickness" id="materialThickness"
                                                        value={this.props.searchProperties.materialThickness} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMaterialThickness(event)}
                                                        placeholder="n,d (mm)" />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="outerDiameter">{t('OutsideDiameter')} (mm)</Label>
                                                        <Input type="text" name="outerDiameter" id="outerDiameter"
                                                            value={this.props.searchProperties.outerDiameter} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeouterDiameter(event)}
                                                        placeholder="n,d (mm)" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqrMaterialGroups" size="sm">{t('BaseMaterialGroup')}</Label>
                                                    <Input type="select" bsSize="sm" name="wpqrMaterialGroups" id="wpqrMaterialGroups"
                                                        value={this.props.searchProperties.selectedMaterialGroup.id}
                                                        onChange={(event) => this.onChangeMaterialGroups(event)}
                                                        placeholder="">
                                                        <option key="materialGroupId0" id="materialGroupId0" value="0">--{t('all')}--</option>
                                                        {this.props.searchProperties.materialGroups.map((materialGroup, index) => (
                                                            <option key={"materialGroupId" + materialGroup.id} id={"materialGroupId" + materialGroup.id} value={materialGroup.id}>{materialGroup.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqrRunTypeRangeOfQualifications" size="sm">{t('SingleMultiRun')}</Label>
                                                    <Input type="select" bsSize="sm" name="wpqrRunTypeRangeOfQualifications" id="wpqrRunTypeRangeOfQualifications"
                                                        value={this.props.searchProperties.selectedRunTypeRangeOfQualification.id}
                                                        onChange={(event) => this.onChangeRunTypeRangeOfQualifications(event)}
                                                        placeholder="">
                                                        <option key="runTypeRangeOfQualificationId0" id="runTypeRangeOfQualificationId0" value="0">--{t('all')}--</option>
                                                        {this.props.searchProperties.runTypeRangeOfQualifications.map((runTypeRangeOfQualification, index) => (
                                                            <option key={"runTypeRangeOfQualificationId" + runTypeRangeOfQualification.id} id={"runTypeRangeOfQualificationId" + runTypeRangeOfQualification.id} value={runTypeRangeOfQualification.id}>{runTypeRangeOfQualification.rangeOfQualification}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                        <Col sm={9}>
                            <FormGroup>
                                <Card>
                                    <CardTitle>
                                        <Row form>
                                            <Col sm={4}>
                                            </Col>
                                            <Col sm={4}>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Button className={"dekra2-table-settings-btn"} color="link" size="sm" name={"tabSettingButton"} onClick={(event) => this.onClickTabSettingModalOpen(event)}>
                                                        <i className={"fas fa-cog"}></i>
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Table size="sm" className="cert-table table-striped" responsive={true}>
                                                        <thead>
                                                            {this.renderTableHeaderRow()}
                                                        </thead>
                                                        <tbody>
                                                            <DeletionModal
                                                                onConfirmDeletion={() => this.removeWpqr()}
                                                                show={this.state.showDeletionModal}
                                                                setOpen={(open: boolean) => this.setDeletionModalOpen(open)}
                                                                headerText={t('DeleteWPQR')}
                                                            />
                                                            {this.renderTabSettingModal()}
                                                            {this.rendeWpqrRevisionModal()}
                                                            {this.props.wpqrsSearchResult && this.props.wpqrsSearchResult.slice(
                                                                this.props.wpqrPaginationPageIndex * this.props.wpqrPaginationPageSize,
                                                                this.props.wpqrPaginationPageIndex * this.props.wpqrPaginationPageSize + this.props.wpqrPaginationPageSize
                                                            ).map((wpqr, index) => (
                                                                this.renderTableRow(wpqr,index)
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan={15}>
                                                                    {this.renderPagination()}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                </Card>
                            </FormGroup>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }

    private rendeWpqrRevisionModal() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.wpqrRevision.showChooseWpsRevisionModal}>
                    <ModalHeader>
                        {t('ListOfWPQRRevisions')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                {this.props.wpqrRevision.revisions.map((revision, index) => (
                                    <FormGroup check key={index}>
                                        <Label className="label-sm-form-size-11" check size="sm">
                                            <Input hidden={revision.revisionWpqrAction === RevisionActionType.Revision} type="radio" bssize="sm"
                                                key={"revisions" + revision.id}
                                                id={"revisions" + revision.id}
                                                name="revisions"
                                                value={revision.id}
                                                className="form-check-input-margin-top"
                                                checked={this.props.wpqrRevision.selectedRevision.id === revision.id}
                                                onChange={(event) => this.onChangeSelectedRevision(event, (this.props.wpqrRevision.revisions.length - 1 - index))}
                                                placeholder="" />{'Revision ( ' + (this.props.wpqrRevision.revisions.length - 1 - index) + ' ), ' + revision.createdAt + ', ' + revision.employee.firstName + ' ' + revision.employee.lastName + '    '}
                                            {this.props.wpqrRevision.revisions[0].revisionWpqrAction === RevisionActionType.Edit ? "" :
                                                <Button style={{ textAlign: "right" }} hidden={revision.revisionWpqrAction !== RevisionActionType.Revision} size="sm" color="primary" onClick={(event) => this.onClickCreateRevision(event, revision, (this.props.wpqrRevision.revisions.length))}>{"Skapa revision"}</Button>
                                            }
                                        </Label>

                                    </FormGroup>
                                ))}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickWpsRevisionModalOK(event)} disabled={this.props.wpqrRevision.selectedRevision.id === 0 ? true : false}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickWpsRevisionModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

    private renderPagination() {
        return (
            <React.Fragment key="renderPagination">
                <div className="pagination-wrapper">

                    <Pagination aria-label="Page navigation example">
                        <PaginationItem onClick={event => this.handlePrevPageClick(event)}>
                            <a className={this.props.wpqrPaginationPageIndex === 0 ? "btn disabled" : "btn"} aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                            </a>
                        </PaginationItem>
                       

                        {[...Array(Math.ceil(this.props.wpqrsSearchResult.length / this.props.wpqrPaginationPageSize))].map((page, i) =>
                            <PaginationItem key={i} active={this.props.wpqrPaginationPageIndex === i} onClick={(event) => this.handlePageClick(event, i)}>
                                <a className="btn">{i + 1}</a> 
                            </PaginationItem>
                        )}

                        <PaginationItem  onClick={event => this.handleNextPageClick(event)}>
                            <a className={this.props.wpqrPaginationPageIndex === (Math.ceil(this.props.wpqrsSearchResult.length / this.props.wpqrPaginationPageSize) - 1) ? "btn disabled" : "btn"}  aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </PaginationItem>
                    </Pagination>
                </div>
            </React.Fragment>
        );
    }

    private renderTabSettingModal() {
        return (
            <React.Fragment key="renderTabSettingModal">
                <Modal centered={true} size="lg" isOpen={this.props.showTabSettingModal}>
                    <ModalHeader>
                        {t('TableSettings')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.dekraProcedureNumber}
                                            onChange={(event) => this.onChangeColumnDekraProcedureNumber(event)}
                                            placeholder="" />{' '} DEKRA procedur-nr.
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPQRNumber" id="qwbpColumnmanufacturerWPQRNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.weldingMethod}
                                            onChange={(event) => this.onChangeColumnWeldingMethod(event)}
                                            placeholder="" />{' '} {t('WeldingMethod')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.outsideDiameter}
                                            onChange={(event) => this.onChangeColumnOutsideDiameter(event)}
                                            placeholder="" />{' '} {t('OutsideDiameter')} (mm)
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.manufacturerWPQRNumber}
                                            onChange={(event) => this.onChangeColumnManufacturerWPQRNumber(event)}
                                            placeholder="" />{' '} Tillverkarens WPQR-nr.
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPQRNumber" id="qwbpColumnmanufacturerWPQRNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.jointTypes}
                                            onChange={(event) => this.onChangeColumnJointTypes(event)}
                                            placeholder="" />{' '} {t('JointType')} (BW, FW)
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.materialGroup}
                                            onChange={(event) => this.onChangeColumnMaterialGroup(event)}
                                            placeholder="" />{' '} {t('BaseMaterialGroup')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.pwpsName}
                                            onChange={(event) => this.onChangeColumnPwpsName(event)}
                                            placeholder="" />{' '} pWPS-nr
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPQRNumber" id="qwbpColumnmanufacturerWPQRNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.productType}
                                            onChange={(event) => this.onChangeColumnProductType(event)}
                                            placeholder="" />{' '} {t('ProductyType')}: {t('pipe')} (T), {t('plate')} (P)
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.singleMultiString}
                                            onChange={(event) => this.onChangeColumnSingleMultiString(event)}
                                            placeholder="" />{' '} {t('SingleMultiRun')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.qualificationStandard}
                                            onChange={(event) => this.onChangeColumnQualificationStandard(event)}
                                            placeholder="" />{' '} {t('QualificationStandard')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPQRNumber" id="qwbpColumnmanufacturerWPQRNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.materialThickness}
                                            onChange={(event) => this.onChangeColumnMaterialThickness(event)}
                                            placeholder="" />{' '} {t('BaseMaterialThickness')} (mm)
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrCompleteSettingColumns.makeAdditives}
                                            onChange={(event) => this.onChangeColumnMakeAdditives(event)}
                                            placeholder="" />{' '} {t('MakeAdditives')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="wpqrBaseMaterialGroup" id="wpqrBaseMaterialGroup"
                                            checked={this.props.wpqrCompleteSettingColumns.makeDesignation}
                                            onChange={(event) => this.onChangeColumnMakeDesignation(event)}
                                            placeholder="" />{' '} {t('BaseMaterialGroup')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="wpqrRemarks" id="wpqrRemarks"
                                            checked={this.props.wpqrCompleteSettingColumns.remark}
                                            onChange={(event) => this.onChangeColumnRemark(event)}
                                            placeholder="" />{' '} {t('Remarks')} (mm)
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickTabSettingModalOK(event)}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickTabSettingModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
    private renderTableHeaderRow() {
        return (
            <React.Fragment key={"renderTableHeaderRow"}>
                <tr>
                    <th></th>
                    {this.props.wpqrCompleteSettingColumns.dekraProcedureNumber ? <th>
                       <a>DEKRA procedur-nr </a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.manufacturerWPQRNumber ? <th>
                        <a>Tillverkarens WPQR-nr</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.pwpsName ? <th>
                        <a>pWPS-nr</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.qualificationStandard ? <th>
                        <a> {t('QualificationStandard')}</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.weldingMethod ? <th>
                        <a>{t('WeldingMethod')}</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.jointTypes ? <th>
                        <a> {t('JointType')} (BW, FW)</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.productType ? <th>
                        <a>{t('Product')}: {t('pipe')} (T), {t('plate')} (P)</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.materialThickness ? <th>
                        <a>{t('BaseMaterialThickness')} (mm)</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.outsideDiameter ? <th>
                        <a>{t('OutsideDiameter')} (mm)</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.materialGroup ? <th>
                        <a>{t('BaseMaterialGroup')}</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.singleMultiString ? <th>
                        <a>{t('SingleMultiRun')}</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.makeAdditives ? <th>
                        <a>{t('MakeAdditives')}</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.makeDesignation ? <th>
                        <a>{t('AdditivesDesignation')}</a>
                    </th> : undefined}
                    {this.props.wpqrCompleteSettingColumns.remark ? <th>
                        <a>{t('Remarks')}</a>
                    </th> : undefined}
                </tr>
            </React.Fragment>
        );
    }
    private renderTableRow(wpqr: WPQR, index: number) {
        return (
            <React.Fragment key={"renderTableRow" + index}>
                
                <tr key={wpqr.dekraNumber}>
                    <td>
                        <ButtonGroup vertical className="btn-group-vertical-unset">
                        <Button color="link" size="sm" name={"revisionWeldingParametersRow-" + index} id={"revisionWeldingParametersRow-" + index}
                            onClick={(event) => this.onClickRevisionWpsRow(wpqr.id, event)}>
                            <i className="fas fa-history"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"revisionWeldingParametersRow-" + index}>
                                {t('Revisions')}!
                            </UncontrolledTooltip>
                        <Button color="link" size="sm" name={"selectWpqrButton-" + wpqr.id} id={"selectWpqrButton-" + wpqr.id} onClick={(event) => this.onClickSelectWpqr(event, wpqr)}>
                            <i className="fas fa-clone"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"selectWpqrButton-" + wpqr.id}>
                                {t('Copy')}!
                            </UncontrolledTooltip>
                        <Button color="link" size="sm" name={"selectWpqrViewButton-" + wpqr.id} id={"selectWpqrViewButton-" + wpqr.id} onClick={(event) => this.onClickSelectWpqrView(event, wpqr)}>
                            <i className="fas fa-eye"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"selectWpqrViewButton-" + wpqr.id}>
                                {t('Look')}!
                            </UncontrolledTooltip>
                        <FileUpload wpqrId={wpqr.id} showDownloadPdf={true} selectedFile={wpqr.selectedFile}></FileUpload>
                            <Button color="link" size="sm" name={"deleteRow-" + index} id={"deleteRow-" + index}
                                onClick={(event) => this.onRemoveWpqrPress(wpqr.id, event)}>
                                <i className="fas fa-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"deleteRow-" + index}>
                                {t('Delete')}!
                            </UncontrolledTooltip>
                        </ButtonGroup>
                    </td>
                    {this.props.wpqrCompleteSettingColumns.dekraProcedureNumber ? <td>
                        {wpqr.dekraNumber}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.manufacturerWPQRNumber ? <td>
                        {wpqr.wpqrNumber}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.pwpsName ? <td>
                        {wpqr.pwpsNumber}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.qualificationStandard ? <td>
                        {wpqr.standardName}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.weldingMethod ? <td>
                        {HelpFunctions.putNameAndNumberTogether(wpqr.weldingMethod1Name.split(",", 1)[0], wpqr.weldingMethod2Name.split(",", 1)[0])}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.jointTypes ? <td>
                        {HelpRangeOfQualificationExamination.GetValueRangeOfQualificationJointType(wpqr.jointTypeName)}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.productType ? <td>
                        {HelpFunctions.joinTwoStringIfTheyAreDifferent(wpqr.productType1Name, wpqr.productType2Name)}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.materialThickness ? <td>
                        {wpqr.materialThickness1RangeOfQualification}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.outsideDiameter ? <td>
                        {HelpRangeOfQualificationExamination.GetValueRangeOfQualificationDiameter(wpqr.diameter1Text)}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.materialGroup ? <td>
                        {HelpRangeOfQualificationExamination.GetValueRangeOfQualificationMaterialGroup(wpqr.materialGroupRangeOfQualification)}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.singleMultiString ? <td>
                        {wpqr.runTypeRangeOfQualification}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.makeAdditives ? <td>
                        {wpqr.make1Name} 
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.makeDesignation ? <td>
                        {wpqr.make1DesignationName}
                    </td> : undefined}
                    {this.props.wpqrCompleteSettingColumns.remark ? <td>
                        {wpqr.remarks}
                    </td> : undefined}
                </tr>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.wpqrComplete;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    WPQRCompleteStore.actionCreators // Selects which action creators are merged into the component's props
)(WPQRComplete as any);