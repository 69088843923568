
import * as React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import { t } from '../../i18n';

export default class CommonModals extends React.PureComponent<{ bodyTemplateHtml?: string, openModal?: boolean, toggleShowModal?: () => void}, { isOpenModal: boolean, selectedTypeId: number }> {
    public state = {
        isOpenModal: false,
        selectedTypeId: 0,
    };

    private createMarkup = () => {
        return {
            __html: this.props.bodyTemplateHtml };
    }

    public render() {
        return (
            <React.Fragment key={"modalmessage"}>
            <Modal isOpen={this.props.openModal} size="lg">
                    <ModalHeader>
                    Bekr�ftelse av best�llning av procedurkvalificering.
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                <div dangerouslySetInnerHTML={this.createMarkup()} />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={(event) => this.onClickWpsTypeIdModalCancel(event)}>{t('OK')}</Button>
                    </ModalFooter>
            </Modal>
            </React.Fragment >
        );
    }

    private onClickWpsTypeIdModalCancel = (event: React.MouseEvent) => {
        this.props.toggleShowModal();
    }
}
