﻿

//import { WPS } from './WPS';
import { GetWeldingMethodResponse } from './models/responses/GetWeldingMethodResponse';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { WPSSearchProperties } from './models/WPSSearchProperties';
import { WPSTabSettingColumns } from './models/WPSTabSettingColumns';
import { GetJointTypeResponse } from './models/responses/GetJointTypeResponse';
import { GetMaterialGroupResponse } from './models/responses/GetMaterialGroupResponse';
import { GetRunTypeRangeOfQualificationResponse } from './models/responses/GetRunTypeRangeOfQualificationResponse';
import { GetStandardResponse } from './models/responses/GetStandardResponse';
import { WPS } from './models/WPS';
import { WPSType } from './models/WPSType';
import { GetWpsResponse } from './models/responses/GetWpsResponse';
import { WPSRevision } from './models/WPSRevision';
import * as authHeader from "./models/auth-header";
import EventBus from './eventBus';
import { PDFRequest } from './models/requests/PDFRequest';
import { PDFResponse } from './models/responses/PDFResponse';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WPSTabStoreState {
    wpss: Array<WPS>;
    wpssSearchResult: Array<WPS>;
    searchProperties: WPSSearchProperties;
    showTabSettingModal: boolean;
    wpsTabSettingColumns: WPSTabSettingColumns;
    wpsPaginationPageIndex: number;
    wpsPaginationPageSize: number;
    wpsWeldingMethodsSearch: Array<GetWeldingMethodResponse>;
    wpsType: WPSType;
    wpsRevision: WPSRevision;
    enablePdfLoader: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetWPSsAction {
    type: 'WPS_GET_WPSS';
    wpss: Array<WPS>;
}
interface GetWPSsRequestAction {
    type: 'WPS_GET_WPSS_REQUEST';
}
interface SetWPSsSearchResultAction {
    type: 'WPS_SET_WPSS_SEARCH_RESULT';
    wpssSearchResult: Array<WPS>;
}

interface SetSearchProperties {
    type: 'WPS_SET_SEARCH_PROPERTIES';
    searchProperties: WPSSearchProperties;
}
interface GetWeldingMethondsSearchAction {
    type: 'WPS_SET_WELDING_METHODS_SEARCH';
    wpsWeldingMethodsSearch: Array<GetWeldingMethodResponse>;
}

export interface SetShowTabSettingModalAction {
    type: 'WPS_SET_SHOW_TAB_SETTING_MODAL';
    showTabSettingModal: boolean;
}

export interface SetTabSettingColumnsAction {
    type: 'WPS_SET_TAB_SETTING_COLUMNS';
    wpsTabSettingColumns: WPSTabSettingColumns;
}

export interface SetPaginationPageIndexAction {
    type: 'WPS_SET_PAGINATION_PAGE_INDEX';
    wpsPaginationPageIndex: number;
}

export interface GetWeldingMethondsSearchRequestAction {
    type: 'WPS_GET_WELDING_METHODS_SEARCH_REQUEST';
}
interface GetProductTypesRequestAction {
    type: 'WPS_GET_PRODUCT_TYPES_REQUEST';
}
interface GetJointTypesRequestAction {
    type: 'WPS_GET_JOINT_TYPES_REQUEST';
}

interface GetMaterialGroupRequestAction {
    type: 'WPS_GET_MATERIAL_GROUPS_REQUEST';
}

interface GetRunTypeRangeQualificationAction {
    type: 'WPS_GET_RUN_TYPE_RANGE_QUALIFICATION_REQUEST';
}

interface GetStandardsAction {
    type: 'WPS_GET_STANDARDS_REQUEST';
}

interface SetWpsTypeAction {
    type: 'WPS_SET_WPS_TYPE';
    wpsType: WPSType;
}

interface SetWpsRevisionAction {
    type: 'WPS_SET_REVISION_RESULT';
    wpsRevision: WPSRevision;
}

interface GetWpsRevisionAction {
    type: 'WPS_GET_REVISION_REQUEST';
}

export interface CreatePdfLoaderReceiveAction {
    type: 'CREATE_PDF_LOADER_RECEIVE';
    enablePdfLoader: boolean;
}

export interface CreatePdfLoaderRequestAction {
    type: 'CREATE_PDF_LOADER_REQUEST';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetWPSsAction
    | GetWPSsRequestAction
    | SetSearchProperties
    | SetWPSsSearchResultAction
    | SetShowTabSettingModalAction
    | SetTabSettingColumnsAction
    | SetPaginationPageIndexAction
    | GetWeldingMethondsSearchAction
    | GetWeldingMethondsSearchRequestAction
    | GetProductTypesRequestAction
    | GetJointTypesRequestAction
    | GetMaterialGroupRequestAction
    | GetRunTypeRangeQualificationAction
    | GetStandardsAction
    | SetWpsTypeAction
    | SetWpsRevisionAction
    | GetWpsRevisionAction
    | CreatePdfLoaderReceiveAction
    | CreatePdfLoaderRequestAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    /*getWPSs: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`wpss`)
            .then(response => response.json() as Promise<Array<WPS>>)
            .then(data => {
                dispatch({ type: 'WPS_GET_WPSS', wpss: data });
            });
    },*/
    getWPSs: (customerId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Wpss/GetAllInclude/${customerId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<WPS>;
                console.info(data);
                const appState = getState();
                if (appState && appState.wpsTab) {
                    let wpss: Array<WPS> = { ...appState.wpsTab.wpss };
                    wpss = [...data];
                    dispatch({ type: 'WPS_GET_WPSS', wpss: wpss });

                    let wpssSearchResult: Array<WPS> = { ...appState.wpsTab.wpssSearchResult };
                    wpssSearchResult = [...data];
                    dispatch({ type: 'WPS_SET_WPSS_SEARCH_RESULT', wpssSearchResult: wpssSearchResult });
                }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPS_GET_WPSS_REQUEST' });
    },
    createPdf: (pdfRequest: PDFRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {// Get the WeldingParameters values.
        const appState = getState();
        if (appState && appState.wpsTab) {
            fetch(`api/PDFs/Create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...pdfRequest })
            })
                .then(response => response.json() as Promise<PDFResponse>)
                .then(data => {
                    console.info(data);
                    var pdf = data.data;
                    const linkSource = `data:application/pdf;base64,${pdf}`;
                    const downloadLink = document.createElement("a");
                    const fileName = data.pdfName + ".pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click()
                    dispatch({ type: 'CREATE_PDF_LOADER_RECEIVE', enablePdfLoader: false });
                })
                .catch(error => {
                    console.error(error);
                });
            dispatch({ type: 'CREATE_PDF_LOADER_REQUEST' });
        }
    },
    getWeldingMethodsSearch: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingMethods/GetByDistinctWeldingMethodDescription`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingMethodResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsTab) {
                        let wpsWeldingMethodsSearch: Array<GetWeldingMethodResponse> = { ...appState.wpsTab.wpsWeldingMethodsSearch };
                        wpsWeldingMethodsSearch = [...data];
                        dispatch({ type: 'WPS_SET_WELDING_METHODS_SEARCH', wpsWeldingMethodsSearch: wpsWeldingMethodsSearch });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPS_GET_WELDING_METHODS_SEARCH_REQUEST' });
    },
    getProductTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ProductTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingMethodResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsTab) {
                        let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
                        searchProperties.productTypes = [...data];;
                        dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPS_GET_PRODUCT_TYPES_REQUEST' });
    },
    getJointTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/JointTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetJointTypeResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsTab) {
                        let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
                        searchProperties.jointTypes = [...data];;
                        dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPS_GET_JOINT_TYPES_REQUEST' });
    },
    getMaterialGroupAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/MaterialGroups/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetMaterialGroupResponse>;
                console.info(data);
                const appState = getState();
                if (appState && appState.wpsTab) {
                    let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
                    searchProperties.materialGroups = [...data];;
                    dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPS_GET_MATERIAL_GROUPS_REQUEST' });
    },
    getRuntypeRangeOfQualification: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RunTypeRangeOfQualifications/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response => response.json() as Promise<Array<GetRunTypeRangeOfQualificationResponse>>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.wpsTab) {
                    let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
                    let newRunTypeRangeOfQualifications = []
                    for (let i = 0; i < [...data].length; i++) {
                        //exclude all hardnessTestingOrImpactTesting with false
                        if ([...data][i].hardnessTestingOrImpactTesting) {
                            newRunTypeRangeOfQualifications.push([...data][i])
                        }
                    }
                    searchProperties.runTypeRangeOfQualifications = [...newRunTypeRangeOfQualifications];
                    dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPS_GET_RUN_TYPE_RANGE_QUALIFICATION_REQUEST' });
    },
    getStandardsAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Standards/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetStandardResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsTab) {
                        let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
                        searchProperties.standards = [...data];;
                        dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPS_GET_STANDARDS_REQUEST' });
    },
    getWPSRevisions: (wpsId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Wpss/GetByWpsIdRevisions/${wpsId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWpsResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.wpsTab) {
                        let wpsRevision: WPSRevision = { ...appState.wpsTab.wpsRevision };
                        wpsRevision.revisions = [...data];
                        dispatch({ type: 'WPS_SET_REVISION_RESULT', wpsRevision: wpsRevision });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPS_GET_REVISION_REQUEST' });
    },
    getWPSsSearchResult: (wpssList: Array<WPS>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let newWpqrsList = []
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            let wpss: Array<WPS> = { ...appState.wpsTab.wpss };
            for (let i = 0; i < wpssList.length; i++) {
                if ((searchProperties.freeSearch === "" ? true : ((wpssList[i].dekraNumber.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpssList[i].wpqrNumber.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpssList[i].pwpsNumber.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpssList[i].make1Name.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)
                    || (wpssList[i].make1DesignationName.toLowerCase().search(searchProperties.freeSearch.toLowerCase()) > -1)))
                    && (searchProperties.selectedWeldingMethod.name === "" ? true : (wpssList[i].weldingMethod1Name.search(searchProperties.selectedWeldingMethod.name.split(",", 1)[0]) > -1) || (wpssList[i].weldingMethod2Name.search(searchProperties.selectedWeldingMethod.name.split(",", 1)[0]) > -1))
                    && (searchProperties.selectedProductType.name === "" ? true : (wpssList[i].productType1Name.search(searchProperties.selectedProductType.name.split(",", 1)[0]) > -1) || (wpssList[i].productType2Name.search(searchProperties.selectedProductType.name.split(",", 1)[0]) > -1))
                    && (searchProperties.materialThickness === "" ? true : compareValueBetweenMinAndMax(wpssList[i].materialThickness1RangeOfQualification, searchProperties.materialThickness))
                    && (searchProperties.outerDiameter === "" ? true : compareOuterDiameter(wpssList[i].diameter1Text, searchProperties.outerDiameter))
                    && (searchProperties.selectedJointType.name === "" ? true : compareJointType(wpssList[i].jointTypeName, searchProperties.selectedJointType.name))
                    && (searchProperties.selectedMaterialGroup.name === "" ? true : compareMaterialGroup(wpssList[i].materialGroupRangeOfQualification, searchProperties.selectedMaterialGroup.name))
                    && (searchProperties.selectedRunTypeRangeOfQualification.rangeOfQualification === "" ? true : compareRunTypeRangeOfQualification(wpssList[i].runTypeRangeOfQualification, searchProperties.selectedRunTypeRangeOfQualification.rangeOfQualification))
                    && (searchProperties.selectedStandard.name === "" ? true : compareStandard(wpssList[i].standardName, searchProperties.selectedStandard.name))) {
                    newWpqrsList.push(wpssList[i]);
                }
            }
            wpss = newWpqrsList;

            dispatch({ type: 'WPS_SET_WPSS_SEARCH_RESULT', wpssSearchResult: wpss });
        }

        function compareStandard(standardText: string, selectedStandard: string) {
            let returnValue: Boolean = false;

            if (standardText.indexOf(selectedStandard) > -1) {
                returnValue = true;
            }
            return returnValue;
        }

        function compareRunTypeRangeOfQualification(runTypeRangeOfQualificationText: string, selectedRangeOfQualification: string) {
            let returnValue: Boolean = false;

            if (runTypeRangeOfQualificationText.indexOf(selectedRangeOfQualification) > -1) {
                returnValue = true;
            }
            return returnValue;
        }

        function compareMaterialGroup(materialGroupRangeOfQualification: string, compareMaterialGroupParameter: string) {
            let returnValue: Boolean = false;
            let firtPart = compareMaterialGroupParameter.split(".");

            if (materialGroupRangeOfQualification.indexOf(compareMaterialGroupParameter) > -1) {
                returnValue = true;
            }

            if ((returnValue === false) && ((materialGroupRangeOfQualification.indexOf(" " + firtPart[0] + ",") > -1) || (materialGroupRangeOfQualification.indexOf(firtPart[0] + "*") > -1))) {
                returnValue = true;
            }

            if (returnValue === false) {
                let lastTwo = materialGroupRangeOfQualification.substr(materialGroupRangeOfQualification.length - 2);
                let comma = lastTwo.split(".")
                let chartA = lastTwo.split("a")
                if (comma.length === 1 && chartA.length === 1) {
                    if (lastTwo.indexOf(firtPart[0]) > -1) {
                        returnValue = true
                    }
                }
            }
            return returnValue;
        }

        function compareJointType(jointTypeText: string, selectedJointType: string) {
            let returnValue: Boolean = false;
            if ((selectedJointType.indexOf("(") > -1) && (selectedJointType.indexOf(")") > -1)) {
                let jointType = selectedJointType.substring(selectedJointType.indexOf("(") + 1, selectedJointType.indexOf(")"))
                if (jointTypeText.indexOf(jointType) > -1) {
                    returnValue = true;
                }
            }
            return returnValue;
        }

        function compareOuterDiameter(diameter1Text: string, compareDiameterParameter: string) { //Changes in table [DiameterRangeOfQualification], can make so we need to change here
            let returnValue: Boolean = false;
            let compareValueNumber = !isNaN(parseFloat(compareDiameterParameter.replace(',', '.'))) ? parseFloat(compareDiameterParameter.replace(',', '.')) : 0.0;
            let diameter1TextArray = diameter1Text.split(" ");

            if (diameter1TextArray.length > 1) {
                let compareValue = !isNaN(parseFloat(diameter1TextArray[1].replace(',', '.'))) ? parseFloat(diameter1TextArray[1].replace(',', '.')) : 0.0;
                let conditions = diameter1TextArray[0];
                switch (conditions) {
                    case "≥":
                        if (compareValueNumber >= compareValue) {
                            returnValue = true;
                        }
                        break;
                    case "˃":
                        if (compareValueNumber > compareValue) {
                            returnValue = true;
                        }
                        break;
                    default:
                        break;
                }
            }
            return returnValue;
        }

        function compareValueBetweenMinAndMax(materialThicknessRangeOfQualification: string, compareValue: string) {//Changes in table [MaterialThicknessRangeOfQualification], can make so we need to change here
            let returnValue: Boolean = false;
            let compareValueNumber = !isNaN(parseFloat(compareValue.replace(',', '.'))) ? parseFloat(compareValue.replace(',', '.')) : 0.0;

            let BW = materialThicknessRangeOfQualification.substring(materialThicknessRangeOfQualification.indexOf("BW") + 4, materialThicknessRangeOfQualification.indexOf("FW") - 1).split(" ").join("");
            if (materialThicknessRangeOfQualification.indexOf("BW") > -1) {
                let BWMin = Number(BW.split("-")[0].replace(',', '.'));
                let BWMax = Number(BW.split("-")[1].replace(',', '.'));
                if ((BWMin <= compareValueNumber) && compareValueNumber <= BWMax) {
                    returnValue = true;
                }

            }

            let FW = materialThicknessRangeOfQualification.substring(materialThicknessRangeOfQualification.indexOf("FW") + 4, materialThicknessRangeOfQualification.length).split(" ").join("");
            if (materialThicknessRangeOfQualification.indexOf("FW") > -1 && returnValue === false) {
                let conditions = FW.split("-")[0];
                switch (conditions) {
                    case "≥5":
                        if (compareValueNumber >= 5) {
                            returnValue = true;
                        }
                        break;
                    default:
                        let FWMin = Number(FW.split("-")[0].replace(',', '.'));
                        let FWMax = Number(FW.split("-")[1].replace(',', '.'));
                        if ((FWMin <= compareValueNumber) && (compareValueNumber <= FWMax)) {
                            returnValue = true;
                        }
                        break;
                }

            }
            return returnValue;
        }
    },
    setFreeSearch: (freeSearch: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            searchProperties.freeSearch = freeSearch;
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setMaterialThickness: (materialThickness: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            searchProperties.materialThickness = materialThickness;
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setOuterDiameter: (outerDiameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            searchProperties.outerDiameter = outerDiameter;
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setWeldingMethodForSearch: (weldingMethodId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let weldingMethods: Array<GetWeldingMethodResponse> = [...appState.wpsTab.wpsWeldingMethodsSearch];

            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };

            if (weldingMethodId !== 0) {
                for (let i = 0; i < weldingMethods.length; i++) {
                    if (weldingMethodId === weldingMethods[i].id) {
                        searchProperties.selectedWeldingMethod = { ...weldingMethods[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedWeldingMethod = {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setStandardForSearch: (StandardId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let standars: Array<GetStandardResponse> = [...appState.wpsTab.searchProperties.standards];
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            if (StandardId !== 0) {
                for (let i = 0; i < standars.length; i++) {
                    if (StandardId === standars[i].id) {
                        searchProperties.selectedStandard = { ...standars[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedStandard = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedProductType: (productTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            if (productTypeId !== 0) {
                for (let i = 0; i < searchProperties.productTypes.length; i++) {
                    if (productTypeId === searchProperties.productTypes[i].id) {
                        searchProperties.selectedProductType = { ...searchProperties.productTypes[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedProductType = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedJointType: (jointTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            if (jointTypeId !== 0) {
                for (var i = 0; i < searchProperties.jointTypes.length; i++) {
                    if (jointTypeId === searchProperties.jointTypes[i].id) {
                        searchProperties.selectedJointType = { ...searchProperties.jointTypes[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedJointType = {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedMaterialGroup: (materialGroupId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            if (materialGroupId !== 0) {
                for (var i = 0; i < searchProperties.materialGroups.length; i++) {
                    if (materialGroupId === searchProperties.materialGroups[i].id) {
                        searchProperties.selectedMaterialGroup = { ...searchProperties.materialGroups[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedMaterialGroup = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                }
            }
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    setSelectedRunTypeRangeOfQualification: (runTypeRangeOfQualificationId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            if (runTypeRangeOfQualificationId !== 0) {
                for (var i = 0; i < searchProperties.runTypeRangeOfQualifications.length; i++) {
                    if (runTypeRangeOfQualificationId === searchProperties.runTypeRangeOfQualifications[i].id) {
                        searchProperties.selectedRunTypeRangeOfQualification = { ...searchProperties.runTypeRangeOfQualifications[i] };
                        break;
                    }
                }
            } else {
                searchProperties.selectedRunTypeRangeOfQualification = {
                    id: 0,
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                    rangeOfQualification: '',
                    runType: 1,
                    hardnessTestingOrImpactTesting: false,
                }
            }
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    clearSearchProperties: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let searchProperties: WPSSearchProperties = { ...appState.wpsTab.searchProperties };
            searchProperties = {
                freeSearch: '',
                welder: '',
                selectedWeldingMethod: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                productTypes: [],
                selectedProductType: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                materialThickness: '',
                outerDiameter: '',
                jointTypes: [],
                selectedJointType: {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                materialGroups: [],
                selectedMaterialGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                runTypeRangeOfQualifications: [],
                selectedRunTypeRangeOfQualification: {
                    id: 0,
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                    rangeOfQualification: '',
                    runType: 1,
                    hardnessTestingOrImpactTesting: false,

                },
                standards: [],
                selectedStandard: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
            }
            dispatch({ type: 'WPS_SET_SEARCH_PROPERTIES', searchProperties: searchProperties });
        }
    },
    clearWpsType: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsType: WPSType = { ...appState.wpsTab.wpsType };
            wpsType = {
                showChooseWpsTypeModal: false,
                selectedWpsTypeId: 0,
                selectedWpstypeModalOk: false,
                selectedWpsId: 0
            }
            dispatch({ type: 'WPS_SET_WPS_TYPE', wpsType: wpsType });
        }
    },
    setShowTabSettingModal: (setShowTabSettingModal: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'WPS_SET_SHOW_TAB_SETTING_MODAL', showTabSettingModal: setShowTabSettingModal });
    },
    setTabSettingColumnDekraProcedureNumber: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.dekraProcedureNumber = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnmanufacturerWPSNumber: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            //wpsTabSettingColumns.manufacturerWPSNumber = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnPwpsName: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.pwpsName = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnQualificationStandard: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.qualificationStandard = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnWeldingMethod: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.weldingMethod = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnJointTypes: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.jointTypes = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnProductType: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.productType = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnMaterialThickness: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.materialThickness = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnOutsideDiameter: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.outsideDiameter = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnMaterialGroup: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.materialGroup = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnSingleMultiString: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.singleMultiString = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setTabSettingColumnMakeAdditives: (setTabSettingColumn: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsTabSettingColumns: WPSTabSettingColumns = { ...appState.wpsTab.wpsTabSettingColumns };
            wpsTabSettingColumns.makeAdditives = setTabSettingColumn;
            dispatch({ type: 'WPS_SET_TAB_SETTING_COLUMNS', wpsTabSettingColumns: wpsTabSettingColumns });
        }
    },
    setPaginationPageIndex: (pageIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsPaginationPageIndex = pageIndex
            dispatch({ type: 'WPS_SET_PAGINATION_PAGE_INDEX', wpsPaginationPageIndex: wpsPaginationPageIndex });
        }
    },
    setWpsTypeId: (wpsTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsType: WPSType = { ...appState.wpsTab.wpsType };
            wpsType.selectedWpsTypeId = wpsTypeId;
            dispatch({ type: 'WPS_SET_WPS_TYPE', wpsType: wpsType });
        }
    },
    setselectedWpsId: (selectedWpsId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsType: WPSType = { ...appState.wpsTab.wpsType };
            wpsType.selectedWpsId = selectedWpsId;
            dispatch({ type: 'WPS_SET_WPS_TYPE', wpsType: wpsType });
        }
    },
    onClickModalOK: (isOpenModal: boolean, id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsType: WPSType = { ...appState.wpsTab.wpsType };
            wpsType.selectedWpsTypeId = id;
            wpsType.selectedWpstypeModalOk = true;
            wpsType.showChooseWpsTypeModal = isOpenModal;
            dispatch({ type: 'WPS_SET_WPS_TYPE', wpsType: wpsType });
        }
    },
    closeWpsTypeModal: (showChooseWpsTypeModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsType: WPSType = { ...appState.wpsTab.wpsType };
            if (showChooseWpsTypeModal) {
                wpsType.selectedWpstypeModalOk = true;
            }
            wpsType.showChooseWpsTypeModal = false;
            dispatch({ type: 'WPS_SET_WPS_TYPE', wpsType: wpsType });
        }
    },
    setShowChooseWpsTypeModal: (showChooseWpsTypeModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsType: WPSType = { ...appState.wpsTab.wpsType };
            wpsType.showChooseWpsTypeModal = showChooseWpsTypeModal;
            dispatch({ type: 'WPS_SET_WPS_TYPE', wpsType: wpsType });
        }
    },
    setSelectedRevision: (revisionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsRevision = { ...appState.wpsTab.wpsRevision };
            let selectedRevision: GetWpsResponse = { ...appState.wpsTab.wpsRevision.selectedRevision };
            for (let i = 0; i < wpsRevision.revisions.length; i++) {
                if (wpsRevision.revisions[i].id === revisionId) {
                    selectedRevision = { ...wpsRevision.revisions[i] };
                    break;
                }
            }
            wpsRevision.selectedRevision = selectedRevision;
            dispatch({ type: 'WPS_SET_REVISION_RESULT', wpsRevision: wpsRevision });
        }
    },
    setSelectedRevisionIdTo0: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsRevision = { ...appState.wpsTab.wpsRevision };
            let selectedRevision: GetWpsResponse = { ...appState.wpsTab.wpsRevision.selectedRevision };
            selectedRevision.id = 0
            wpsRevision.selectedRevision = selectedRevision;
            dispatch({ type: 'WPS_SET_REVISION_RESULT', wpsRevision: wpsRevision });
        }
    },
    setShowChooseWpsRevisionModal: (showChooseWpsRevisionModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsRevision: WPSRevision = { ...appState.wpsTab.wpsRevision };
            wpsRevision.showChooseWpsRevisionModal = showChooseWpsRevisionModal;
            dispatch({ type: 'WPS_SET_REVISION_RESULT', wpsRevision: wpsRevision });
        }
    },
    closeWpsRevisionModal: (showChooseWpsRevisionModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsRevision: WPSRevision = { ...appState.wpsTab.wpsRevision };
            if (showChooseWpsRevisionModal) {
                wpsRevision.selectedWpsRevisionModalOk = true;
                wpsRevision.selectedRevisionAction = wpsRevision.selectedRevision.revisionWpsAction;
            }
            wpsRevision.showChooseWpsRevisionModal = false;
            dispatch({ type: 'WPS_SET_REVISION_RESULT', wpsRevision: wpsRevision });
        }
    },
    clearWpsRevisionModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpsTab) {
            let wpsRevision: WPSRevision = { ...appState.wpsTab.wpsRevision };
            wpsRevision = {
                revisions: [],
                selectedRevisionAction: '',
                selectedRevision: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                    wpsName: '',
                    wpsType: '',
                    customerLogo: '',
                    revisionWpsId: undefined,
                    revisionWpsAction: '',
                    employee: {
                        id: 0,
                        firstName: '',
                        lastName: ''
                    }
                },
                showChooseWpsRevisionModal: false,
                selectedWpsRevisionModalOk: false

            }
            dispatch({ type: 'WPS_SET_REVISION_RESULT', wpsRevision: wpsRevision });
        }
    },
    removeWps: (wpsId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.customerList) {
            fetch(`/api/Wpss/DeleteByWpsId/${wpsId}`, {
                method: 'DELETE',
                headers: authHeader.authHeader(),
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    EventBus.dispatch("refreshWpsList", "");
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WPSTabStoreState = {
    wpss: [],
    wpssSearchResult: [],
    searchProperties: {
        freeSearch: '',
        welder: '',
        selectedWeldingMethod: {
            id: 0,
            name: '',
            efficiencyFactor: 0.0,
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        productTypes: [],
        selectedProductType: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        materialThickness: '',
        outerDiameter: '',
        jointTypes: [],
        selectedJointType: {
            id: 0,
            name: '',
            description: '',
            jointShapes: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        materialGroups: [],
        selectedMaterialGroup: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        runTypeRangeOfQualifications: [],
        selectedRunTypeRangeOfQualification: {
            id: 0,
            createdAt: '',
            deletedAt: '',
            updatedAt: '',
            rangeOfQualification: '',
            runType: 1,
            hardnessTestingOrImpactTesting: false,

        },
        standards: [],
        selectedStandard: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
    },
    showTabSettingModal: false,
    wpsTabSettingColumns: {
        dekraProcedureNumber: true,
        manufacturerWPSNumber: true,
        pwpsName: true,
        qualificationStandard: true,
        weldingMethod: true,
        jointTypes: true,
        productType: true,
        materialThickness: true,
        outsideDiameter: true,
        materialGroup: true,
        singleMultiString: true,
        makeAdditives: true,
        manufacturerWPQRNumber: true
    },
    wpsPaginationPageIndex: 0,
    wpsPaginationPageSize: 20,
    wpsWeldingMethodsSearch: [],
    wpsType: {
        showChooseWpsTypeModal: false,
        selectedWpsTypeId: 0,
        selectedWpstypeModalOk: false,
        selectedWpsId: 0
    },
    wpsRevision: {
        revisions: [],
        selectedRevisionAction: '',
        selectedRevision: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: '',
            wpsName: '',
            wpsType: '',
            customerLogo: '',
            revisionWpsId: undefined,
            revisionWpsAction: '',
            employee: {
                id: 0,
                firstName: '',
                lastName: ''
            }
        },
        showChooseWpsRevisionModal: false,
        selectedWpsRevisionModalOk: false

    },
    enablePdfLoader: false
};

export const reducer: Reducer<WPSTabStoreState> = (state: WPSTabStoreState | undefined, incomingAction: Action): WPSTabStoreState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'WPS_GET_WPSS':
            return {
                wpss: action.wpss,                wpsType: state.wpsType,                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_GET_WPSS_REQUEST':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_SET_WPSS_SEARCH_RESULT':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,                wpsRevision: state.wpsRevision,
                wpssSearchResult: action.wpssSearchResult,
                searchProperties: state.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_SET_SEARCH_PROPERTIES':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: action.searchProperties,
                showTabSettingModal: state.showTabSettingModal,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_SET_SHOW_TAB_SETTING_MODAL':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                showTabSettingModal: action.showTabSettingModal,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_SET_TAB_SETTING_COLUMNS':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                wpsTabSettingColumns: action.wpsTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_SET_PAGINATION_PAGE_INDEX':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpsPaginationPageIndex: action.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_SET_WELDING_METHODS_SEARCH':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: action.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_GET_WELDING_METHODS_SEARCH_REQUEST':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_SET_WPS_TYPE':
            return {
                wpss: state.wpss,                wpsType: action.wpsType,
                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'WPS_SET_REVISION_RESULT':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,
                wpsRevision: action.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: state.enablePdfLoader
            };
        case 'CREATE_PDF_LOADER_RECEIVE':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,
                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: action.enablePdfLoader
            };
        case 'CREATE_PDF_LOADER_REQUEST':
            return {
                wpss: state.wpss,                wpsType: state.wpsType,
                wpsRevision: state.wpsRevision,
                wpssSearchResult: state.wpssSearchResult,
                searchProperties: state.searchProperties,
                wpsTabSettingColumns: state.wpsTabSettingColumns,
                showTabSettingModal: state.showTabSettingModal,
                wpsPaginationPageIndex: state.wpsPaginationPageIndex,
                wpsPaginationPageSize: state.wpsPaginationPageSize,
                wpsWeldingMethodsSearch: state.wpsWeldingMethodsSearch,
                enablePdfLoader: true,
            };
        default:
            return state;
    }
};
