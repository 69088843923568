import * as ApplicationsStore from './ApplicationsStore';
import * as ApplicationTabStore from './ApplicationTabStore';
import * as DrawJointDesignStore from './DrawJointDesignStore';
import * as WeldSequenceStore from './WeldSequenceStore';
import * as LoginStore from './LoginStore';
import * as ProjectsTabStore from './ProjectsTabStore';
import * as RangeOfQualificationStore from './RangeOfQualificationStore';
import * as RegisterUserStore from './RegisterUserStore';
import * as TestResultsExaminationStore from './TestResultsExaminationStore';
import * as WPQRDEKRATabStore from './WPQRDEKRATabStore';
import * as WPQRPageHeaderStore from './WPQRPageHeaderStore';
import * as TestingOrderStore from './TestingOrderStore';
import * as WPQRTabStore from './WPQRTabStore';
import * as WPSTabStore from './WPSTabStore';
import * as WPQRCompleteStore from './WPQRCompleteStore';
import * as WPSFormEditStore from './WPSFormEditStore';
import * as MessageStore from './MessageStore';
import * as FileUploadStore from './FileUploadStore';
import * as WParametersStore from './WParametersStore';
import * as LanguageStore from './LanguageStore';
import * as MyPageStore from './MyPageStore';
import * as CustomerListStore from './CustomerListStore';
import * as UserListStore from './UserListStore';
import * as NewsStore from './NewsStore';
import * as WeldingPlanStore from './WeldingPlanStore';

import * as TypeOfBackingStore from './otherStore/TypeOfBackingStore';
import * as HistoryProgressStore from './otherStore/HistoryProgressStore';
import * as GasesStore from './otherStore/GasesStore';
// The top-level state object
export interface ApplicationState {
    applications: ApplicationsStore.ApplicationsState | undefined;
    applicationTab: ApplicationTabStore.ApplicationTabStoreState | undefined;
    customerList: CustomerListStore.CustomerListState | undefined;
    drawJointDesign: DrawJointDesignStore.DrawJointDesignState | undefined;
    weldSequence: WeldSequenceStore.WeldSequenceState | undefined;
    login: LoginStore.LoginState | undefined;
    myPage: MyPageStore.MyPageState | undefined;
    projectsTab: ProjectsTabStore.ProjectsTabStoreState | undefined;
    rangeOfQualification: RangeOfQualificationStore.RangeOfQualificationState | undefined;
    registerUserStore: RegisterUserStore.RegisterUserState | undefined;
    testResultsExamination: TestResultsExaminationStore.TestResultsExaminationState | undefined;
    wpqrDekraTab: WPQRDEKRATabStore.WPQRDEKRATabStoreState | undefined;
    wpqrPageHeader: WPQRPageHeaderStore.WPQRPageHeaderState | undefined;
    testingOrder: TestingOrderStore.TestingOrderState | undefined;
    wParameters: WParametersStore.WParametersState | undefined;
    wpqrTab: WPQRTabStore.WPQRTabStoreState | undefined;
    wpqrComplete: WPQRCompleteStore.WPQRCompleteStoreState | undefined;
    wpsTab: WPSTabStore.WPSTabStoreState | undefined;
    wpsFormEdit: WPSFormEditStore.WPSFormEditStoreState | undefined;
    message: MessageStore.MessageState | undefined;
    language: LanguageStore.LanguageStoreState | undefined;
    userList: UserListStore.UserListState | undefined;
    fileUpload: FileUploadStore.FileUploadState | undefined;
    typeOfBacking: TypeOfBackingStore.TypeOfBackingState | undefined;
    historyProgress: HistoryProgressStore.HistoryProgressState | undefined;
    news: NewsStore.NewsState | undefined;
    gases: GasesStore.GasesState | undefined;
    weldingPlan: WeldingPlanStore.WeldingPlanStoreState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    applications: ApplicationsStore.reducer,
    applicationTab: ApplicationTabStore.reducer,
    customerList: CustomerListStore.reducer,
    drawJointDesign: DrawJointDesignStore.reducer,
    weldSequence: WeldSequenceStore.reducer,
    login: LoginStore.reducer,
    myPage: MyPageStore.reducer,
    projectsTab: ProjectsTabStore.reducer,
    rangeOfQualification: RangeOfQualificationStore.reducer,
    registerUser: RegisterUserStore.reducer,
    testResultsExamination: TestResultsExaminationStore.reducer,
    wpqrDekraTab: WPQRDEKRATabStore.reducer,
    wpqrPageHeader: WPQRPageHeaderStore.reducer,
    testingOrder: TestingOrderStore.reducer,
    wParameters: WParametersStore.reducer,
    wpqrTab: WPQRTabStore.reducer,
    wpsTab: WPSTabStore.reducer,
    wpqrComplete: WPQRCompleteStore.reducer,
    wpsFormEdit: WPSFormEditStore.reducer,
    language: LanguageStore.reducer,
    userList: UserListStore.reducer,
    message: MessageStore.reducer,
    fileUpload: FileUploadStore.reducer,
    typeOfBacking: TypeOfBackingStore.reducer,
    historyProgress: HistoryProgressStore.reducer,
    news: NewsStore.reducer,
    gases: GasesStore.reducer,
    weldingPlan: WeldingPlanStore.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
