﻿import { GetAfterWeldingResponse } from "./GetAfterWeldingResponse";
import { GetFilesUploadResponse } from "./GetFilesUploadResponse";
import { GetJointDesignResponse } from "./GetJointDesignResponse";
import { GetParentMaterialResponse } from "./GetParentMaterialResponse";
import { GetStandardResponse } from "./GetStandardResponse";
import { GetTemperatureResponse } from "./GetTemperatureResponse";
import { GetWeldingDataResponse } from "./GetWeldingDataResponse";
import { GetWeldingParametersResponse } from "./GetWeldingParametersResponse";
import { GetWeldingProcessResponse } from "./GetWeldingProcessResponse";
import { GetWeldSequenceResponse } from "./GetWeldSequenceResponse";

export class GetPWPSResponse {
    afterWelding: GetAfterWeldingResponse;
    byStandard: GetStandardResponse;
    enableStandardText: boolean;
    standardText: string;
    createdAt: string;
    date: string;
    deletedAt: string;
    id: number;
    jointDesign: GetJointDesignResponse;
    weldSequence: GetWeldSequenceResponse;
    parentMaterial: GetParentMaterialResponse;
    projectNumber: string;
    pwpsNumber: string;
    revisionNumber: string;
    temperature: GetTemperatureResponse;
    updatedAt: string;
    weldingData: GetWeldingDataResponse;
    weldingParameters: Array<GetWeldingParametersResponse>;
    filesUpload: Array<GetFilesUploadResponse>;
    weldingProcess: GetWeldingProcessResponse;
    wpqrNumber: string;
    otherInformation: string;
    otherOptionParametersTableId: number;
    manufacturer: string;
    examiner: string;
    confirmedMessage: string;
    public constructor() {
        this.afterWelding = new GetAfterWeldingResponse();
        this.byStandard = new GetStandardResponse();
        this.enableStandardText = false;
        this.standardText = '';
        this.createdAt = '';
        this.date = '';
        this.deletedAt = '';
        this.id = 0;
        this.jointDesign = new GetJointDesignResponse();
        this.weldSequence = new GetWeldSequenceResponse();
        this.parentMaterial = new GetParentMaterialResponse();
        this.projectNumber = '';
        this.pwpsNumber = '';
        this.revisionNumber = '';
        this.temperature = new GetTemperatureResponse();
        this.updatedAt = '';
        this.weldingData = new GetWeldingDataResponse();
        this.weldingParameters = new Array<GetWeldingParametersResponse>();
        this.filesUpload = new Array<GetFilesUploadResponse>();
        this.weldingProcess = new GetWeldingProcessResponse();
        this.wpqrNumber = '';
        this.otherInformation = '';
        this.otherOptionParametersTableId = 0;
        this.manufacturer = '';
        this.examiner = '';
        this.confirmedMessage = '';
    }
}