﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col, Collapse, Container, Form, FormGroup, FormText, Input, InputGroup, Label, ListGroup, ListGroupItem, Nav, NavItem, NavLink, PopoverBody, PopoverHeader, Row, TabContent, Table, TabPane, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import { ApplicationState } from '../store/index';
import { ApplicationStatus } from '../store/models/ApplicationStatus';
import { OtherOptions } from '../store/models/OtherOptions';
import { WpqrRequest } from '../store/models/requests/WpqrRequest';
import { GetBaseMaterialResponse } from '../store/models/responses/GetBaseMaterialResponse';
import { GetMakeResponse } from '../store/models/responses/GetMakeResponse';
import { GetMessageResponse } from '../store/models/responses/GetMessageResponse';
import { GetRootProtectionGasResponse } from '../store/models/responses/GetRootProtectionGasResponse';
import { GetShieldingGasResponse } from '../store/models/responses/GetShieldingGasResponse';
import { GetWeldingMethodResponse } from '../store/models/responses/GetWeldingMethodResponse';
import { RevisionActionType } from '../store/models/RevisionActionType';
import { WPQR } from '../store/WPQR';
import * as WPQRDEKRATabStore from '../store/WPQRDEKRATabStore';
import Applications from './Applications';
import DrawJointDesign from './DrawJointDesign';
import FileUpload from './FileUpload';
import FormelForHeatInput from './FormelForHeatInput';
import Loader from './Loader';
import Message from './Message';
import TypeOfBacking from './otherComponents/TypeOfBacking';
import RangeOfQualification from './RangeOfQualification';
import TestingOrder from './TestingOrder';
import TestResultsExamination from './TestResultsExamination';
import WeldSequence from './WeldSequence';
import WParameters from './WParameters';
import WPQRComplete from './WPQRComplete';
import WPQRPageHeader from './WPQRPageHeader';
import WPSFormEdit from './WPSFormEdit';
import { t } from '../i18n';
import { SendToCustomerRequest } from '../store/models/requests/SendToCustomerRequest';
import HistoryProgress from './otherComponents/HistoryProgress';
import DropdownLists from './otherComponents/DropdownLists';
import Gases from './otherComponents/Gases';
import HelpFunctions from '../store/models/HelpFunctions';
import TestingDetails1Component from './TestingDetails1Component';

// At runtime, Redux will merge together...

type WPQRDEKRATabProps =
    WPQRDEKRATabStore.WPQRDEKRATabStoreState // ... state we've requested from the Redux store
    & typeof WPQRDEKRATabStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>;

const SweToEngJointPreparationCleaning = new Map<string, string>();
const SweToEngJointTypes = new Map<string, string>();
const usersSearchResult = [];
class WPQRDEKRATab extends React.PureComponent<WPQRDEKRATabProps> {

    constructor(props: WPQRDEKRATabProps) {
        super(props);
    }

    private TranslateBoxes = (word: string, dictionary: Map<string, string>) => {
        if (dictionary.has(word)) {
            return dictionary.get(word);
        } else {
            return word;
        }
    }

    public state = {
        wpqrCompleteIsOpen: false,
        updateTestResultsExamination: false,
        updateRangeOfqualification: false,
        updateWPSFormEdit: 0,
        showJointDesignModal: false,
        showJointDesignModalId: 0,
        showWeldSequenceModal: false,
        showWeldSequenceModal1: false,
        hiddenWeldingProtocolTabes: true,
        usersSearchResult,
    };
    private usersSearchResultClear = () => {
        let list = this.state.usersSearchResult;
        list.length = 0;
        this.setState({
            usersSearchResult: list
        });
    }
    private usersSearchResult = (value: any[]) => {
        this.setState({
            usersSearchResult: value
        });
    }
    private setWpqrCompleteIsOpen = (isOpen: boolean) => {
        this.setState({
            wpqrCompleteIsOpen: isOpen
        });
    }
    private setUpdateTestResultsExamination= (update: boolean) => {
        this.setState({
            updateTestResultsExamination: !update
        });
    }
    private setUpdateRangeOfqualification = (update: boolean) => {
        this.setState({
            updateRangeOfqualification: !update
        });
    }
    private setUpdateWPSFormEdit = (update: number) => {
        this.setState({
            updateWPSFormEdit: update
        });
    }
    private toggleShowJointDesignModal = () => {
        this.setState({
            showJointDesignModal: !this.state.showJointDesignModal,
            showJointDesignModalId: 0
        });
    }
    private toggleShowJointDesignModal1 = (value: number) => {
        this.setState({
            showJointDesignModal: !this.state.showJointDesignModal,
            showJointDesignModalId: value
        });
    }
    private toggleWeldSequenceModal = () => {
        this.setState({
            showWeldSequenceModal: !this.state.showWeldSequenceModal
        });
    }
    private toggleWeldSequenceModal1 = () => {
        this.setState({
            showWeldSequenceModal1: !this.state.showWeldSequenceModal1
        });
    }
    private setHiddenWeldingProtocolTabes = (value: boolean) => {
        this.setState({
            hiddenWeldingProtocolTabes: value
        });
    }
    public componentDidMount() {
        this.props.setSelectedWPQRTabId('ongoingWPQR');
        this.props.setSelectedOngoingWPQRTabId('pwps');
        this.props.setSelectedTestResultsTabId('wpqtReport');
        this.props.setSelectedExaminationWPQRTabId('rangeOfQualification');
        this.props.init();
        // Initialize the forms.
        this.props.initWPQTReport();
        this.props.initBendTesting();
        this.props.initTensileTesting();
        this.props.initTransverseTensileTesting();
        this.props.initCruciformTensileTesting();
        this.props.initImpactTesting();
        this.props.initHardnessTesting();
        this.props.initMacroTesting();
        this.props.initMicroTesting();
        this.props.initFerriteTesting();
        this.props.initChemicalTesting();
        this.props.initFractureTesting();
        this.props.initCorrosionTesting();
        this.props.initWeldingTestingDetails1();
        // Get data from db.
        this.props.getApplicationsByApplicationStatus(ApplicationStatus.Confirmed);
        this.props.getJointTypesAll();
        this.props.getMaterialGroupAll();
        this.props.getArcTypeSearchResult();
        this.props.getProductTypesAll();
        this.props.getCurrentTypesAll();
        this.props.getDropletTransitionsAll();
        this.props.getStandardsAll();
        this.props.receiveUserList();
        this.props.getWeldingPositionSearchResult();
        this.props.getRootSupportDetailsSearchResult();
        //this.props.getTackWeldSearchResult();

        SweToEngJointPreparationCleaning.set('Blästring', t('Blasting'));
        SweToEngJointPreparationCleaning.set('Borstning', t('Brushing'));
        SweToEngJointPreparationCleaning.set('Borrning', t('Drilling'));
        SweToEngJointPreparationCleaning.set('Filning', t('Filing'));
        SweToEngJointPreparationCleaning.set('Flamskärning', t('FlameCutting'));
        SweToEngJointPreparationCleaning.set('Försänka', t('CountersinkChamfer'));
        SweToEngJointPreparationCleaning.set('Fräsning', t('Gnawing'));
        SweToEngJointPreparationCleaning.set('Gradning', t('Deburring'));
        SweToEngJointPreparationCleaning.set('Kapning', t('CrossCutting'));
        SweToEngJointPreparationCleaning.set('Klippning', t('Shearing'));
        SweToEngJointPreparationCleaning.set('Laserskuren', t('LaserCut'));
        SweToEngJointPreparationCleaning.set('Luftbågmejsling', t('AirArcGouging'));
        SweToEngJointPreparationCleaning.set('Maskinell bearbetning', t('Machining'));
        SweToEngJointPreparationCleaning.set('Sågning', t('Sawing'));
        SweToEngJointPreparationCleaning.set('Skärning', t('Cutting'));
        SweToEngJointPreparationCleaning.set('Slipning', t('Grinding'));
        SweToEngJointPreparationCleaning.set('Slipning ', t('Grinding'));
        SweToEngJointPreparationCleaning.set('Svarvning', t('TurnyellerLathing'));
        SweToEngJointPreparationCleaning.set('Tvättning', t('Cleaning'));

        SweToEngJointTypes.set('Stumsvets (BW)', t('ButtWeldingBW'));
        SweToEngJointTypes.set('Partiell stumsvets', t('PartialButtWelding'));
        SweToEngJointTypes.set('Kälsvets (FW)', t('KeelWeldingFW'));
        SweToEngJointTypes.set('T-förband (BW)', t('TJointBW'));
        SweToEngJointTypes.set('Rör mot plåt (BW)', t('PipeAgainstPlateBW'));
        SweToEngJointTypes.set('Rör mot plåt (FW)', t('PipeAgainstPlateFW'));
        SweToEngJointTypes.set('Avstickare (BW)', t('StickOutBW'));
        SweToEngJointTypes.set('Avstickare (FW)', t('StickOutFW'));
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.weldingProtocol.weldingProtocolId !== prevProps.weldingProtocol.weldingProtocolId) {
            //this.props.removeApplication(this.props.selectedApplication.id)
            this.props.getApplicationsByApplicationStatus(ApplicationStatus.Confirmed);
        }
    }

    private onChangeBendTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeBendTestingMeasuringInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingMeasuringInstrument(event.currentTarget.value);
    }

    private onChangeBendTestingMeasuringInstrumentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingMeasuringInstrumentSerialNumber(event.currentTarget.value);
    }

    private onChangeBendTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeBendTestingResultsBendingAngle(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsBendingAngle(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeBendTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsComments(event.currentTarget.value);
    }

    private onChangeBendTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeBendTestingResultsDiameterOfRoller(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsDiameterOfRoller(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsDistanceBetweenRollers(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsDistanceBetweenRollers(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsSurfaceCondition(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsSurfaceCondition(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeBendTestingResultsTestSpecimenDimensions(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsTestSpecimenDimensions(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingResultsTestSpecimenType(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingResultsTestSpecimenType(rowNumber, event.currentTarget.value);
    }

    private onChangeBendTestingTestingMachine(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingTestingMachine(event.currentTarget.value);
    }

    private onChangeBendTestingTestingMachineSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingTestingMachineSerialNumber(event.currentTarget.value);
    }

    private onChangeBendTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeBendTestingTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingTestingTemperature(event.currentTarget.value);
    }

    private onChangeBendTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBendTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeChemicalTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeChemcialTestingMeasuringInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemcialTestingMeasuringInstrument(event.currentTarget.value);
    }

    private onChangeChemcialTestingMeasuringInstrumentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemcialTestingMeasuringInstrumentSerialNumber(event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1Grade(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1Grade(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements11(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements11(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements12(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements12(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements13(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements13(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements14(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements14(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements15(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements15(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements16(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements16(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements17(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements17(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements18(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements18(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements19(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements19(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements21(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements21(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements22(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements22(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements23(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements23(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements24(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements24(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements25(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements25(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements26(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements26(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements27(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements27(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements28(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements28(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1MassPercentPartOfElements29(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1MassPercentPartOfElements29(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable1TestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable1TestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2Grade(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2Grade(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements11(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements11(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements12(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements12(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements13(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements13(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements14(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements14(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements15(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements15(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements16(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements16(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements17(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements17(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements18(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements18(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements19(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements19(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements21(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements21(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements22(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements22(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements23(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements23(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements24(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements24(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements25(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements25(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements26(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements26(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements27(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements27(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements28(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements28(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2MassPercentPartOfElements29(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2MassPercentPartOfElements29(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTable2TestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTable2TestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsInterpretationsConclusions1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsInterpretationsConclusions1(event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsInterpretationsConclusions2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsInterpretationsConclusions2(event.currentTarget.value);
    }

    private onChangeChemicalTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeChemicalTestingSpecimensQuantity(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingSpecimensQuantity(event.currentTarget.value);
    }

    private onChangeChemcialTestingTestingEquipment(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemcialTestingTestingEquipment(event.currentTarget.value);
    }

    private onChangeChemcialTestingTestingEquipmentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemcialTestingTestingEquipmentSerialNumber(event.currentTarget.value);
    }

    private onChangeChemicalTestingTestingMethod(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingTestingMethod(event.currentTarget.value);
    }

    private onChangeChemicalTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeChemicalTestingTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingTestingTemperature(event.currentTarget.value);
    }

    private onChangeChemicalTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setChemicalTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeCorrosionTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeCorrosionTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeCorrosionTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeCorrosionTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeCorrosionTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingResultsComments(event.currentTarget.value);
    }

    private onChangeCorrosionTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeCorrosionTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeCorrosionTestingResultsTestingResult(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingResultsTestingResult(rowNumber, event.currentTarget.value);
    }

    private onChangeCorrosionTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeCorrosionTestingTestingCondition(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingTestingCondition(event.currentTarget.value);
    }

    private onChangeCorrosionTestingTestingMethodDescription(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingTestingMethodDescription(event.currentTarget.value);
    }

    private onChangeCorrosionTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeCorrosionTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCorrosionTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingMeasuringInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingMeasuringInstrument(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingMeasuringInstrumentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingMeasuringInstrumentSerialNumber(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsComments(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsFm(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsFm(rowNumber, event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsMeasurementUncertaintyUFm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsMeasurementUncertaintyUFm(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsMeasurementUncertaintyURm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsMeasurementUncertaintyURm(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsMeasurementUncertaintyUWf(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsMeasurementUncertaintyUWf(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsPlaceOfBreak(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsPlaceOfBreak(rowNumber, event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsRm(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsRm(rowNumber, event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsTestSpecimenDimensions(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsTestSpecimenDimensions(rowNumber, event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingResultsWf(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingResultsWf(rowNumber, event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingSampleQuantity(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingSampleQuantity(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingTestingMachine(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingTestingMachine(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingTestingMachineSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingTestingMachineSerialNumber(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingTestingTemperature(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeCruciformTensileTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCruciformTensileTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeFerriteTestingFerriteMeter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingFerriteMeter(event.currentTarget.value);
    }

    private onChangeFerriteTestingFerriteMeterSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingFerriteMeterSerialNumber(event.currentTarget.value);
    }

    private onChangeFerriteTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeFerriteTestingMeasuringInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingMeasuringInstrument(event.currentTarget.value);
    }

    private onChangeFerriteTestingMeasuringInstrumentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingMeasuringInstrumentSerialNumber(event.currentTarget.value);
    }

    private onChangeFerriteTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeFerriteTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeFerriteTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeFerriteTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingResultsComments(event.currentTarget.value);
    }

    private onChangeFerriteTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeFerriteTestingResultsFerriteContent(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingResultsFerriteContent(rowNumber, event.currentTarget.value);
    }

    private onChangeFerriteTestingResultsMeasurementUncertainty(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingResultsMeasurementUncertainty(rowNumber, event.currentTarget.value);
    }

    private onChangeFerriteTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeFerriteTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeFerriteTestingSpecimensQuantity(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingSpecimensQuantity(event.currentTarget.value);
    }

    private onChangeFerriteTestingStandardsSet(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingStandardsSet(event.currentTarget.value);
    }

    private onChangeFerriteTestingStandardsSetSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingStandardsSerialNumber(event.currentTarget.value);
    }

    private onChangeFerriteTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeFerriteTestingTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingTestingTemperature(event.currentTarget.value);
    }

    private onChangeFerriteTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFerriteTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeFractureTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeFractureTestingMeasuringInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingMeasuringInstrument(event.currentTarget.value);
    }

    private onChangeFractureTestingMeasuringInstrumentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingMeasuringInstrumentSerialNumber(event.currentTarget.value);
    }

    private onChangeFractureTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeFractureTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeFractureTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeFractureTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingResultsComments(event.currentTarget.value);
    }

    private onChangeFractureTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeFractureTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeFractureTestingResultsTestResults(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingResultsTestResults(rowNumber, event.currentTarget.value);
    }

    private onChangeFractureTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeFractureTestingTestingMachine(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingTestingMachine(event.currentTarget.value);
    }

    private onChangeFractureTestingTestingMachineSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingTestingMachineSerialNumber(event.currentTarget.value);
    }

    private onChangeFractureTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeFractureTestingTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingTestingTemperature(event.currentTarget.value);
    }

    private onChangeFractureTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeFractureTestingTestSpecimensDescription(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingTestSpecimensDescription(event.currentTarget.value);
    }

    private onChangeFractureTestingTestSpecimensQuantity(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFractureTestingTestSpecimensQuantity(event.currentTarget.value);
    }

    private onChangeHardnessTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeHardnessTestingMeasuringInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingMeasuringInstrument(event.currentTarget.value);
    }

    private onChangeHardnessTestingMeasuringInstrumentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingMeasuringInstrumentSerialNumber(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsAcceptance(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsAcceptance(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsComments(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ4(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ4(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ5(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ5(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ6(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ6(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ10(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ10(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ11(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ11(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1HAZ12(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1HAZ12(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent1(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent1(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent2(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent2(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent3(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent3(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent13(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent13(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent14(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent14(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Parent15(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Parent15(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Weld7(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Weld7(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Weld8(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Weld8(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable1Weld9(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable1Weld9(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ4(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ4(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ5(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ5(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ6(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ6(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ10(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ10(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ11(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ11(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2HAZ12(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2HAZ12(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent1(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent1(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent2(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent2(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent3(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent3(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent13(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent13(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent14(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent14(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Parent15(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Parent15(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Weld7(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Weld7(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Weld8(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Weld8(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTable2Weld9(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTable2Weld9(rowNumber, event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsWayOfMeasurementOfHardness1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsWayOfMeasurementOfHardness1(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsWayOfMeasurementOfHardness2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsWayOfMeasurementOfHardness2(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsMeasurementUncertainty(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsMeasurementUncertainty(event.currentTarget.value);
    }

    private onChangeHardnessTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeHardnessTestingTestingMachine(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingTestingMachine(event.currentTarget.value);
    }

    private onChangeHardnessTestingTestingMachineSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingTestingMachineSerialNumber(event.currentTarget.value);
    }

    private onChangeHardnessTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeHardnessTestingTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingTestingTemperature(event.currentTarget.value);
    }

    private onChangeHardnessTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHardnessTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeImpactTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeImpactTestingMeasuringInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingMeasuringInstrument(event.currentTarget.value);
    }

    private onChangeImpactTestingMeasuringInstrumentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingMeasuringInstrumentSerialNumber(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsComments(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZAbsorbedEnergy(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZAbsorbedEnergy(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZAverageAcceptance(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZAverageAcceptance(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZAverageAbsorbedEnergy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZAverageAbsorbedEnergy(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZAverageLateralExpansion(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZAverageLateralExpansion(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZAverageShear(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZAverageShear(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZLateralExpansion(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZLateralExpansion(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZMeasurementUncertaintyAbsorbedEnergy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZMeasurementUncertaintyAbsorbedEnergy(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZMeasurementUncertaintyAcceptance(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZMeasurementUncertaintyAcceptance(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZMeasurementUncertaintyLateralExpansion(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZMeasurementUncertaintyLateralExpansion(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZMeasurementUncertaintyShear(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZMeasurementUncertaintyShear(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZNominalEnergy(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZNominalEnergy(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZShear(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZShear(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZTestSpecimenDimensions(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZTestSpecimenDimensions(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsHAZTestSpecimenType(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsHAZTestSpecimenType(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAbsorbedEnergy(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAbsorbedEnergy(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAverageAcceptance(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAverageAcceptance(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAverageAbsorbedEnergy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAverageAbsorbedEnergy(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAverageLateralExpansion(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAverageLateralExpansion(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldAverageShear(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldAverageShear(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldLateralExpansion(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldLateralExpansion(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldMeasurementUncertaintyAcceptance(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldMeasurementUncertaintyAcceptance(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldMeasurementUncertaintyAbsorbedEnergy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldMeasurementUncertaintyAbsorbedEnergy(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldMeasurementUncertaintyLateralExpansion(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldMeasurementUncertaintyLateralExpansion(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldMeasurementUncertaintyShear(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldMeasurementUncertaintyShear(event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldNominalEnergy(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldNominalEnergy(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldShear(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldShear(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldTestSpecimenDimensions(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldTestSpecimenDimensions(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingResultsWeldTestSpecimenType(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingResultsWeldTestSpecimenType(rowNumber, event.currentTarget.value);
    }

    private onChangeImpactTestingTestingMachine(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingTestingMachine(event.currentTarget.value);
    }

    private onChangeImpactTestingTestingMachineSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingTestingMachineSerialNumber(event.currentTarget.value);
    }

    private onChangeImpactTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeImpactTestingTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingTestingTemperature(event.currentTarget.value);
    }

    private onChangeImpactTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setImpactTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeMacroTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeMacroTestingExaminationDesignation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingExaminationDesignation(event.currentTarget.value);
    }

    private onChangeMacroTestingExaminationEquipment(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingExaminationEquipment(event.currentTarget.value);
    }

    private onChangeMacroTestingExaminationEquipmentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingExaminationEquipmentSerialNumber(event.currentTarget.value);
    }

    private onChangeMacroTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeMacroTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeMacroTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeMacroTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsComments(event.currentTarget.value);
    }

    private onChangeMacroTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeMacroTestingResultsExaminationResult(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsExaminationResult(rowNumber, event.currentTarget.value);
    }

    private onChangeMacroTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeMacroTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeMacroTestingSpecimensQuantity(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingSpecimensQuantity(event.currentTarget.value);
    }

    private onChangeMacroTestingTestingCondition(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingTestingCondition(event.currentTarget.value);
    }

    private onChangeMacroTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeMacroTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMacroTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeMicroTestingExaminationDesignation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingExaminationDesignation(event.currentTarget.value);
    }

    private onChangeMicroTestingExaminationEquipment(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingExaminationEquipment(event.currentTarget.value);
    }

    private onChangeMicroTestingExaminationEquipmentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingExaminationEquipmentSerialNumber(event.currentTarget.value);
    }

    private onChangeMicroTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeMicroTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeMicroTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeMicroTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeMicroTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsComments(event.currentTarget.value);
    }

    private onChangeMicroTestingResultsCoverageFactor(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsCoverageFactor(event.currentTarget.value);
    }

    private onChangeMicroTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeMicroTestingResultsDistribution(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsDistribution(event.currentTarget.value);
    }

    private onChangeMicroTestingResultsExaminationResult(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsExaminationResult(rowNumber, event.currentTarget.value);
    }

    private onChangeMicroTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeMicroTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeMicroTestingSpecimensQuantity(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingSpecimensQuantity(event.currentTarget.value);
    }

    private onChangeMicroTestingTestingCondition(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingTestingCondition(event.currentTarget.value);
    }

    private onChangeMicroTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeMicroTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMicroTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeTensileTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeTensileTestingMeasuringInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingMeasuringInstrument(event.currentTarget.value);
    }

    private onChangeTensileTestingMeasuringInstrumentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingMeasuringInstrumentSerialNumber(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsA(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsA(rowNumber, event.currentTarget.value);
    }

    private onChangeTensileTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeTensileTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsComments(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsFm(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsFm(rowNumber, event.currentTarget.value);
    }

    private onChangeTensileTestingResultsFractureSurfaceCondition(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsFractureSurfaceCondition(rowNumber, event.currentTarget.value);
    }

    private onChangeTensileTestingResultsMeasurementUncertaintyUA(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsMeasurementUncertaintyUA(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsMeasurementUncertaintyUFm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsMeasurementUncertaintyUFm(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsMeasurementUncertaintyURm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsMeasurementUncertaintyURm(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsMeasurementUncertaintyUR(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsMeasurementUncertaintyUR(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsMeasurementUncertaintyUZ(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsMeasurementUncertaintyUZ(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsR(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsR(rowNumber, event.currentTarget.value);
    }

    private onChangeTensileTestingResultsRm(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsRm(rowNumber, event.currentTarget.value);
    }

    private onChangeTensileTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeTensileTestingResultsTestSpecimenDimensions(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsTestSpecimenDimensions(rowNumber, event.currentTarget.value);
    }

    private onChangeTensileTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeTensileTestingResultsZ(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingResultsZ(rowNumber, event.currentTarget.value);
    }

    private onChangeTensileTestingTestingMachine(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingTestingMachine(event.currentTarget.value);
    }

    private onChangeTensileTestingTestingMachineSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingTestingMachineSerialNumber(event.currentTarget.value);
    }

    private onChangeTensileTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeTensileTestingTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingTestingTemperature(event.currentTarget.value);
    }

    private onChangeTensileTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTensileTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingLocationOfTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingLocationOfTestSpecimens(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingMeasuringInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingMeasuringInstrument(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingMeasuringInstrumentSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingMeasuringInstrumentSerialNumber(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsA(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsA(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsAcceptanceNormsStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsAcceptanceNormsStandard(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsCertificationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsCertificationNumber(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsComments(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsComments(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsDeviationFromStandardsOrAgreement(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsDeviationFromStandardsOrAgreement(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsFm(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsFm(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsFractureSurfaceCondition(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsFractureSurfaceCondition(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsMeasurementUncertaintyU(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsMeasurementUncertaintyU(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsMeasurementUncertaintyUFm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsMeasurementUncertaintyUFm(event.currentTarget.value);
    }

    private onChangeTranverseTensileTestingResultsMeasurementUncertaintyUR(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsMeasurementUncertaintyUR(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsMeasurementUncertaintyURm(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsMeasurementUncertaintyURm(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsPlaceOfBreak(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsPlaceOfBreak(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsR(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsR(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsRm(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsRm(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsTestingPerformedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsTestingPerformedBy(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsTestSpecimenDimensions(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsTestSpecimenDimensions(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingResultsTestSpecimenNumber(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingResultsTestSpecimenNumber(rowNumber, event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingTestingMachine(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingTestingMachine(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingTestingMachineSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingTestingMachineSerialNumber(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingTestingStandardProcedure(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingTestingStandardProcedure(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingTestingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingTestingTemperature(event.currentTarget.value);
    }

    private onChangeTransverseTensileTestingTestSpecimens(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTransverseTensileTestingTestSpecimens(event.currentTarget.value);
    }

    private onChangeWeldingDataLengthTackWelds(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataLengthTackWelds(event.currentTarget.value);
    }

    private onChangeWeldingDataTackWeldSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataTackWeld(event.currentTarget.value);
    }

    private onChangeWeldingProtocolThroatThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolThroatThickness(event.currentTarget.value);
    }

    private onChangeWeldingProtocolBaseMaterial1SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolBaseMaterial1SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getBaseMaterial1SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingProtocolBaseMaterial1SearchResult();
        }
    }

    private onChangeWeldingProtocolBaseMaterial2SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolBaseMaterial2SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getBaseMaterial2SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingProtocolBaseMaterial2SearchResult();
        }
    }

    private onChangeWeldingProtocolJointPreparation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolJointPreparation(event.currentTarget.value);
    }

    private onChangeWeldingProtocolChargeAltHeatNumber1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolChargeAltHeatNumber1(event.currentTarget.value);
    }

    private onChangeWeldingProtocolChargeAltHeatNumber2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolChargeAltHeatNumber2(event.currentTarget.value);
    }

    private onChangeWeldingProtocolCompanyBoxAddress(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolCompanyBoxAddress(event.currentTarget.value);
    }

    private onChangeWeldingProtocolCompanyCity(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolCompanyCity(event.currentTarget.value);
    }

    private onChangeWeldingProtocolCompanyContactPerson(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolCompanyContactPerson(event.currentTarget.value);
    }

    private onChangeWeldingProtocolCompanyContactPersonEmailAddress(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolCompanyContactPersonEmailAddress(event.currentTarget.value);
    }
    private onChangeCommissionNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolAssignmentNumber(event.currentTarget.value);
    }
    private onChangepWPSNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolPWPSNumber(event.currentTarget.value);
    }
    private onChangemarkingOfTheTest(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolMarkingOfTheTest(event.currentTarget.value);
    }
    private onChangeDekraProcedureNumberDisplay(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolDEKRAProcedureNumberDisplay(event.currentTarget.value);
    }

    private onChangeTestingStandards(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTestingStandards(parseInt(event.currentTarget.value));
    }

    private onChangeManufacturerWPQRNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolManufacturerWPQRNumber(event.currentTarget.value);
    }

    private onChangeArcTypeBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcTypeBSearchText(parseInt(event.currentTarget.value));
    }

    private onChangeArcTypeASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcTypeASearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolCompanyName(event.currentTarget.value);
    }

    private onChangeWeldingProtocolCompanyPhoneNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolCompanyPhoneNumber(event.currentTarget.value);
    }

    private onChangeWeldingProtocolCompanyPostcode(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolCompanyPostcode(event.currentTarget.value);
    }

    private onChangeWeldingProtocolCompanyStreetAddress(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolCompanyStreetAddress(event.currentTarget.value);
    }

    private onChangeWeldingProtocolDiameterParentMaterial1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolDiameterParentMaterial1(event.currentTarget.value);
    }

    private onChangeWeldingProtocolDiameterParentMaterial2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolDiameterParentMaterial2(event.currentTarget.value);
    }

    private onChangeWeldingProtocolNozzleDistance(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolNozzleDistance(event.currentTarget.value);
    }

    private onChangeWeldingProtocolEmployeeNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolEmployeeNumber(event.currentTarget.value);
    }

    private onChangeWeldingProtocolGasFlowMethod1RootSide(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolGasFlowMethod1RootSide(event.currentTarget.value);
    }

    private onChangeWeldingProtocolGasFlowMethod1TopSide(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolGasFlowMethod1TopSide(event.currentTarget.value);
    }

    private onChangeWeldingProtocolGasFlowMethod2TopSide(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolGasFlowMethod2TopSide(event.currentTarget.value);
    }

    private onChangeWeldingProtocolGunAngle(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolGunAngle(event.currentTarget.value);
    }

    private onChangeWeldingProtocolHeatingAndCoolingRate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolHeatingAndCoolingRate(event.currentTarget.value);
    }

    private onChangeWeldingProtocolJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolSelectedJointType(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolLotNumberOrHeatNumberA(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolLotNumberOrHeatNumberA(event.currentTarget.value);
    }

    private onChangeWeldingProtocolLotNumberOrHeatNumberB(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolLotNumberOrHeatNumberB(event.currentTarget.value);
    }

    private onChangeWeldingProtocolMaterialThickness1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolMaterialThicknessParentMaterial1(event.currentTarget.value);
    }

    private onChangeWeldingProtocolMaterialThickness2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolMaterialThicknessParentMaterial2(event.currentTarget.value);
    }

    private onChangeWeldingProtocolMaxInterpassTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolMaxInterpassTemperature(event.currentTarget.value);
    }

    private onChangeWeldingProtocolMinElevatedWorkingTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolMinElevatedWorkingTemperature(event.currentTarget.value);
    }

    private onChangeWeldingProtocolNozzleDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolNozzleDiameter(event.currentTarget.value);
    }

    private onChangeWeldingProtocolOscillationMaxRunWidth(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolOscillationMaxRunWidth(event.currentTarget.value);
    }

    private onChangeWeldingProtocolOtherInformation1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolOtherInformation1(event.currentTarget.value);
    }

    private onChangeWeldingProtocolOtherInformation2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolOtherInformation2(event.currentTarget.value);
    }

    private onChangeWeldingProtocolPlaceForMonitoring(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolPlaceForMonitoring(event.currentTarget.value);
    }

    private onChangeWeldingProtocolPowerSourceManufacturer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolPowerSourceManufacturer(event.currentTarget.value);
    }

    private onChangeWeldingProtocolProductTypes1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolSelectedProductType1(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolProductTypes2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolSelectedProductType2(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolRemarks(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolRemarks(event.currentTarget.value);
    }

    private onChangeWeldingProtocolRootSupportDetailsSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolRootSupportDetailsSearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolShieldingGas1SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolShieldingGas1SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWeldingProtocolShieldingGas1SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingProtocolShieldingGas1SearchResult();
        }
    }

    private onChangeWeldingProtocolShieldingGas2SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolShieldingGas2SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWeldingProtocolShieldingGas2SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingProtocolShieldingGas2SearchResult();
        }
    }

    private onChangeWeldingProtocolSignature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolSignature(event.currentTarget.value);
    }

    private onChangeWeldingProtocolPostWeldHeatTreatment(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolPostWeldHeatTreatment(event.currentTarget.value);
    }

    private onChangeWeldingProtocolTechnicalDeliveryConditions1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolSelectedTechnicalDeliveryCondition1(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolTechnicalDeliveryConditions2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolSelectedTechnicalDeliveryCondition2(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolMakeASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolMakeASearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getMakeASearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingProtocolMakeASearchResult();
        }
    }

    private onChangeWeldingProtocolMakeBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolMakeBSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getMakeBSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingProtocolMakeBSearchResult();
        }
    }

    private onChangeWeldingProtocolRootProtectionGasSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolRootProtectionGasSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWeldingProtocolRootProtectionGasSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingProtocolRootProtectionGasSearchResult();
        }
    }

    private onChangeWeldingProtocolTungstenElectrode(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolTungstenElectrode(event.currentTarget.value);
    }

    private onChangeWeldingProtocolWelderDateOfBirth(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWelderDateOfBirth(event.currentTarget.value);
    }

    private onChangeWeldingProtocolWelderFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWelderFirstName(event.currentTarget.value);
    }

    private onChangeWeldingProtocolWelderLastName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWelderLastName(event.currentTarget.value);
    }

    private onChangeWeldingProtocolWelderPlaceOfBirth(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWelderPlaceOfBirth(event.currentTarget.value);
    }

    private onChangeWeldingProtocolWeldingDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingDate(event.currentTarget.value);
    }
    private onChangeWeldingProtocolWeldingPositionSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingPositionSearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolWeldingPosition2SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingPosition2SearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolWeldingMethod1SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingMethod1SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWeldingMethod1SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingProtocolWeldingMethod1SearchResult();
        }
    }

    private onChangeWeldingProtocolWeldingMethod2SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingMethod2SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWeldingMethod2SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingProtocolWeldingMethod2SearchResult();
        }
    }

    private onChangeWeldingTestingDetails1BackingGasFlowRate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1BackingGasFlowRate(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1BackingGasFlux(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1BackingGasFlux(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Examiner(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Examiner(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ExaminerDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ExaminerDate(event.currentTarget.value);
    }
    private onChangeWeldingTestingDetails1CommentInformation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1CommentInformation(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ExaminerName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ExaminerName(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ExaminerSignature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ExaminerSignature(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.usersSearchResultClear(); //Clear
            this.props.weldingTestingDetails1.users.filter(user => user.roles.find(role => role.includes('DEKRA') || role.includes('Admin'))).forEach((item) => {
                var value = item.firstName + " " + item.lastName;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.usersSearchResult.push(value);
                    this.usersSearchResult(this.state.usersSearchResult)
                }
            });
        }
        else {
            // Clear
            this.usersSearchResultClear();
        }
    }

    private onChangeWeldingTestingDetails1ChargeNumberHeatNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ChargeNumberHeatNumber(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ChargeNumberLotNumberSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ChargeNumberLotNumberSerialNumber(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1DetailsOfGougingBacking(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1DetailsOfGougingBacking(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1FillerMaterialDesignation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1FillerMaterialDesignation(event.currentTarget.value);
    }

    //private onChangeWeldingTestingDetails1FillerMaterialMake(event: React.ChangeEvent<HTMLInputElement>) {
    //    event.stopPropagation();
    //    this.props.setWeldingTestingDetails1FillerMaterialMake(event.currentTarget.value);
    //}

    private onChangeWeldingTestingDetails1FillerMaterialTradeName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1FillerMaterialTradeName(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1HeatingAndCoolingRates(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1HeatingAndCoolingRates(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1InterpassTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1InterpassTemperature(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1JointType(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1JointType(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Location(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Location(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ManufacturerOfPowerSource(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ManufacturerOfPowerSource(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ManufacturerPWPSNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ManufacturerPWPSNumber(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer2(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer2Date(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer2Date(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer2Name(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer2Name(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer2Signature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer2Signature(event.currentTarget.value);
    }

    private onClickSelectUser(value: string) {
        this.usersSearchResultClear();//Clear
        this.props.setWeldingTestingDetails1ExaminerSignature(value);
    }

    private onChangeWeldingTestingDetails1MaterialThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1MaterialThickness(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1MethodOfPreparationAndCleaning(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1MethodOfPreparationAndCleaning(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1NozzleDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1NozzleDiameter(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1OtherInformation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1OtherInformation(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1OutsideDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1OutsideDiameter(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ParentMaterialGroup(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ParentMaterialGroup(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ParentMaterialNameOrNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ParentMaterialNameOrNumber(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1PostWeldHeatTreatmentAndOrAgeing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1PostWeldHeatTreatmentAndOrAgeing(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1PreheatTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1PreheatTemperature(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ShieldingGasFlowRate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ShieldingGasFlowRate(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ShieldingGasFlux(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ShieldingGasFlux(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1StandOffDistance(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1StandOffDistance(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1TechnicalDeliveryStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1TechnicalDeliveryStandard(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1TorchAngle(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1TorchAngle(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1TungstenElectrode(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1TungstenElectrode(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Weaving(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Weaving(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1TackWeldText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1TackWeldText(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1LengthTackWelds(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1LengthTackWelds(event.currentTarget.value);
    }
    
    private onChangeWeldingTestingDetails1WelderName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1WelderName(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1WeldingPosition(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1WeldingPosition(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1WeldingPosition2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1WeldingPosition2(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1WeldingProcess(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1WeldingProcess(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ArcType(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ArcType(event.currentTarget.value);
    }

    private onChangeWPQTReportAcceptance(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportAcceptance(rowNumber, event.currentTarget.value);
    }

    private onChangeWPQTReportApplicationNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportApplicationNumber(event.currentTarget.value);
    }

    private onChangeWPQTReportCustomer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportCustomer(event.currentTarget.value);
    }

    private onChangeWPQTReportDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportDate(event.currentTarget.value);
    }

    private onChangeWPQTReportDekraProcedureNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportDekraProcedureNumber(event.currentTarget.value);
    }

    private onChangeWPQTReportDimensions(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportDimensions(event.currentTarget.value);
    }

    private onChangeWPQTReportFillerMaterial(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportFillerMaterial(event.currentTarget.value);
    }

    private onChangeWPQTReportParentMaterial(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportParentMaterial(event.currentTarget.value);
    }

    private onChangeWPQTReportPerformed(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportPerformed(rowNumber, event.currentTarget.checked)
    }

    private onChangeWPQTReportReportApprovedBy(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportReportApprovedBy(event.currentTarget.value)
    }

    private onChangeWPQTReportReportNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportReportNumber(event.currentTarget.value);
    }

    private onChangeWPQTReportTestingLocation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportTestingLocation(event.currentTarget.value);
    }

    private onChangeWPQTReportTestingObject(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportTestingObject(event.currentTarget.value);
    }

    private onChangeWPQTReportTestingPerformedBy(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportTestingPerformedBy(rowNumber, event.currentTarget.value);
    }

    private onChangeWPQTReportTestingResult(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportTestingResult(rowNumber, event.currentTarget.value);
    }

    private onChangeWPQTReportTestingScope(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportTestingScope(event.currentTarget.value);
    }

    private onChangeWPQTReportTestMarking(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportTestMarking(event.currentTarget.value);
    }

    private onChangeWPQTReportTypeOfTest(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportTypeOfTest(rowNumber, event.currentTarget.value);
    }

    private onChangeWPQTReportWeldingAndHeatTreatmentData(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWPQTReportWeldingAndHeatTreatmentData(event.currentTarget.value);
    }

    private onClickExaminationWPQRNavItem(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        this.props.setSelectedExaminationWPQRTabId(event.currentTarget.id);
    }

    private onClickToggleWeldSequenceModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.toggleWeldSequenceModal();
    }

    private onChangeParentMaterialMaterialGroup2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialGroup2Text(event.currentTarget.value);
    }

    private onChangeParentMaterialMaterialGroup1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialGroup1Text(event.currentTarget.value);
    }

    private onChangeEnableBaseMaterial1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableBaseMaterial1Text(event.currentTarget.checked);
    }

    private onChangeEnableBaseMaterial2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableBaseMaterial2Text(event.currentTarget.checked);
    }

    private onChangeParentMaterialBaseMaterial1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial1Text(event.currentTarget.value);
    }

    private onChangeParentMaterialBaseMaterial2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial2Text(event.currentTarget.value);
    }

    private onChangeParentTechnicalDeliveryCondition1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialTechnicalDeliveryCondition1Text(event.currentTarget.value);
    }

    private onChangeParentTechnicalDeliveryCondition2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialTechnicalDeliveryCondition2Text(event.currentTarget.value);
    }

    private onChangeEnableMakeAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMakeAText(event.currentTarget.checked);
    }

    private onChangeEnableMakeBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMakeBText(event.currentTarget.checked);
    }

    private onChangeMakeAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMakeAText(event.currentTarget.value);
    }

    private onChangeDesignationAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDesignationAText(event.currentTarget.value);
    }

    private onChangeMakeBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMakeBText(event.currentTarget.value);
    }

    private onChangeDesignationBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDesignationBText(event.currentTarget.value);
    }

    private onClickNavItem(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        if (event.currentTarget.id === 'examinationWPQR') {
            this.props.initRangeOfQualification();
            this.props.initSelectedApplicationWeldingTestingDetails1();
        }
        if (event.currentTarget.id === 'testingOrder') {
            this.props.initTestingOrder();
        }
        
        this.props.setSelectedOngoingWPQRTabId(event.currentTarget.id);
    }

    private onClickNextButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        (document.getElementById('toggleShowApplications') as HTMLButtonElement).focus();// Scroll to the top
        if (this.props.selectedOngoingWPQRTabId === 'weldingProtocol') {
            this.props.initTestingOrder();
            this.props.setSelectedOngoingWPQRTabId('testingOrder');
        }
        if (this.props.selectedOngoingWPQRTabId === 'testingOrder') {
            this.props.setSelectedOngoingWPQRTabId('testResults');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'testResults') {
            this.props.initRangeOfQualification();
            this.props.initSelectedApplicationWeldingTestingDetails1();
            this.props.setSelectedOngoingWPQRTabId('examinationWPQR');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'examinationWPQR') {
            this.props.setSelectedOngoingWPQRTabId('documentFileUpload');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'documentFileUpload') {
            this.props.setSelectedOngoingWPQRTabId('documentFileUpload');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'pwps') {
            this.props.setSelectedOngoingWPQRTabId('weldingProtocol');
        }
    }

    private onClickNextButton2(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (this.props.selectedOngoingWPQRTabId === 'weldingProtocol') {
            this.props.initTestingOrder();
            this.props.setSelectedOngoingWPQRTabId('testingOrder');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'testingOrder') {
            this.props.initRangeOfQualification();
            this.props.initSelectedApplicationWeldingTestingDetails1();
            this.props.setSelectedOngoingWPQRTabId('examinationWPQR');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'examinationWPQR') {
            this.props.setSelectedOngoingWPQRTabId('documentFileUpload');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'documentFileUpload') {
            this.props.setSelectedOngoingWPQRTabId('documentFileUpload');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'pwps') {
            this.props.setSelectedOngoingWPQRTabId('weldingProtocol');
        }
    }

    private onClickPreviousButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        (document.getElementById('toggleShowApplications') as HTMLButtonElement).focus();// Scroll to the top

        if (this.props.selectedOngoingWPQRTabId === 'pwps') {
            this.props.setSelectedOngoingWPQRTabId('pwps');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'weldingProtocol') {
            this.props.setSelectedOngoingWPQRTabId('pwps');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'testingOrder') {
            this.props.setSelectedOngoingWPQRTabId('weldingProtocol');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'testResults') {
            this.props.setSelectedOngoingWPQRTabId('testingOrder');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'examinationWPQR') {
            this.props.setSelectedOngoingWPQRTabId('testResults');
        }
        else if (this.props.selectedOngoingWPQRTabId === 'documentFileUpload') {
            this.props.setSelectedOngoingWPQRTabId('examinationWPQR');
        }
    }

    // Application with WPQR id
    private initApplicationWithWpqrItem = (applicationId: number, wpsId: number, wpqrId: number) => {
        this.props.initSelectedWpqr(wpqrId);
        this.props.initWeldingProtocol(0, wpqrId, RevisionActionType.Default);
        this.props.addMessageInWeldingProtocolMessages(applicationId);
        this.props.initWeldingProtocolRevisionWpqrAction("");
        this.setUpdateWPSFormEdit(wpsId)
        this.setHiddenWeldingProtocolTabes(false);
    }

    private initWeldingProtocolItem = (applicationId: number, wpsId: number) => {
        this.props.initWeldingProtocol(applicationId, 0, RevisionActionType.Default);
        this.props.addMessageInWeldingProtocolMessages(applicationId);
        this.props.clearPartOfWeldingTestingDetails1();
        this.props.initWeldingProtocolRevisionWpqrAction("");
        this.setUpdateTestResultsExamination(this.state.updateTestResultsExamination);
        this.setUpdateRangeOfqualification(this.state.updateRangeOfqualification);
        this.setUpdateWPSFormEdit(wpsId)
        this.setHiddenWeldingProtocolTabes(false);
    }

    // Clone
    private initWpqrItem = (wpqr: WPQR) => {
        this.props.initWeldingProtocolRevisionWpqrAction(RevisionActionType.Clone);
        this.props.addMessageInWeldingProtocolMessages(0);
        this.props.initSelectedWpqr(wpqr.id);
        this.setWpqrCompleteIsOpen(false);
        this.props.initWeldingProtocol(0, wpqr.id, RevisionActionType.Clone);
        this.setUpdateWPSFormEdit(wpqr.wpsId);
        this.props.setSelectedWPQRTabId("ongoingWPQR");
        this.setHiddenWeldingProtocolTabes(false);

    }

    // View
    private initWpqrItemView = (wpqr: WPQR) => {
        this.props.initWeldingProtocolRevisionWpqrAction(RevisionActionType.View);
        this.props.addMessageInWeldingProtocolMessages(wpqr.applicationId);
        this.props.initSelectedWpqr(wpqr.id);
        this.setWpqrCompleteIsOpen(false);
        this.props.initWeldingProtocol(0, wpqr.id, RevisionActionType.Default);
        this.setUpdateWPSFormEdit(wpqr.wpsId);
        this.props.setSelectedWPQRTabId("ongoingWPQR");
        this.setHiddenWeldingProtocolTabes(false);
    }

    // Revision
    private initWpqrItemRevision = (wpqr: WPQR) => {
        this.props.initWeldingProtocolRevisionWpqrAction(wpqr.revisionWpqrAction === RevisionActionType.CreateRevision ? RevisionActionType.Revision : wpqr.revisionWpqrAction);
        this.props.addMessageInWeldingProtocolMessages(wpqr.applicationId);
        this.props.initSelectedWpqr(wpqr.id);
        this.setWpqrCompleteIsOpen(false);
        this.props.initWeldingProtocol(0, wpqr.id, wpqr.revisionWpqrAction === RevisionActionType.Clone ? RevisionActionType.Clone : wpqr.revisionWpqrAction);
        this.setUpdateWPSFormEdit(wpqr.wpsId)
        this.props.setSelectedWPQRTabId("ongoingWPQR");
        this.setHiddenWeldingProtocolTabes(false);
    }

    private onClickUpdateWpqr(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.createWpqr(true, () => { });//Update is set to true.And this enable to create new row in wpqr Table when SelectedWpqrId == 0
    }

    private onClickAddAndRemoveFilesToFilesUpload = (enable: boolean) => {
        this.props.addAndRemoveFilesToFilesUpload();
        // To Document tab
        this.props.setSelectedOngoingWPQRTabId("documentFileUpload");
    }

    private parentSendToCustomer = () => {
        let sendToCustomerRequest: SendToCustomerRequest = {
            id: 0,
            wpqrId: this.props.weldingProtocol.wpqrId,
            revisionAction: RevisionActionType.Revision,
        };
        this.createWpqr(true, () => { 
        this.props.sendToCustomer(sendToCustomerRequest);
        this.setWpqrCompleteIsOpen(true);
            this.props.setSelectedWPQRTabId("completedWPQR");
        });
    }

    private cloneWPQR = (enable: boolean) => {
        this.createWpqr(false, () => { });
        this.setWpqrCompleteIsOpen(true);
        this.props.setSelectedWPQRTabId("completedWPQR");
    }
    
    private initMessageActive = (messagesList: Array<GetMessageResponse>, messageCodeActive: string) => {
        if (messagesList && messagesList.length > 0) {
            let exist = false;
            for (let i = 0; i < messagesList.length; i++) {
                if (messagesList[i].messageCode === messageCodeActive) {
                    exist = true;
                }
            }
            return exist
        } else {
            return false;
        }
    }

    private createWpqr(enableEdit: boolean, callback: Function) {
        let createWpqrRequest: WpqrRequest = {
            selectedWpqrId: this.props.weldingProtocol.wpqrId,
            wpqrEnableEdit: enableEdit,
            createWeldingProtocolRequest: {
                dekraNumber: this.props.weldingProtocol.dekraProcedureNumber,
                dekraNumberDisplay: this.props.weldingProtocol.dekraProcedureNumberDisplay,
                weldingProtocolId: this.props.weldingProtocol.weldingProtocolId,
                weldingProtocolApplicationId: this.props.selectedApplication.id > 0 ? this.props.selectedApplication.id : this.props.weldingProtocol.weldingProtocolApplicationId, // Save the ApplicationId when create a wpqr
                markingOfTheTest: this.props.weldingProtocol.markingOfTheTest,
                assignmentNumber: this.props.weldingProtocol.assignmentNumber,
                wPQRNumber: this.props.weldingProtocol.manufacturerWPQRNumber,
                pWPSNumber: this.props.weldingProtocol.pWPSNumber,
                employeeDisplayName: "",
                revisionWpqrAction: !enableEdit ? RevisionActionType.Revision : this.props.weldingProtocol.revisionWpqrAction,
                createWeldingProtocolSection1Request: {
                    companyId: this.props.weldingProtocol.weldingProtocolSection1.company.id,
                    welderId: 1,
                    company2: {
                        id: this.props.weldingProtocol.weldingProtocolSection1.company2.id,
                        name: this.props.weldingProtocol.weldingProtocolSection1.company2.name,
                        streetAddress: this.props.weldingProtocol.weldingProtocolSection1.company2.streetAddress,
                        boxAddress: this.props.weldingProtocol.weldingProtocolSection1.company2.boxAddress,
                        postcode: this.props.weldingProtocol.weldingProtocolSection1.company2.postcode,
                        city: this.props.weldingProtocol.weldingProtocolSection1.company2.city,
                        phone: this.props.weldingProtocol.weldingProtocolSection1.company2.phone,
                        contactPerson: this.props.weldingProtocol.weldingProtocolSection1.company2.contactPerson,
                        contactPersonEmail: this.props.weldingProtocol.weldingProtocolSection1.company2.contactPersonEmail,
                        createdAt: this.props.weldingProtocol.weldingProtocolSection1.company2.createdAt,
                        deletedAt: this.props.weldingProtocol.weldingProtocolSection1.company2.deletedAt,
                        updatedAt: this.props.weldingProtocol.weldingProtocolSection1.company2.updatedAt
                    },
                    dateOfBirth: this.props.weldingProtocol.weldingProtocolSection1.welder.dateOfBirth,
                    firstName: this.props.weldingProtocol.weldingProtocolSection1.welder.firstName,
                    lastName: this.props.weldingProtocol.weldingProtocolSection1.welder.lastName,
                    placeOfBirth: this.props.weldingProtocol.weldingProtocolSection1.welder.placeOfBirth
                },
                createWeldingProtocolSection2Request: {
                    heatNumber1: this.props.weldingProtocol.weldingProtocolSection2.lotNumberOrHeatNumberA,
                    heatNumber2: this.props.weldingProtocol.weldingProtocolSection2.lotNumberOrHeatNumberB,
                    JointTypeId: (this.props.weldingProtocol.weldingProtocolSection2.selectedJointType.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection2.selectedJointType.id : undefined,
                    make1Id: (this.props.weldingProtocol.weldingProtocolSection2.selectedMake1.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection2.selectedMake1.id : undefined,
                    make2Id: (this.props.weldingProtocol.weldingProtocolSection2.selectedMake2.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection2.selectedMake2.id : undefined,
                    weldingMethod1Id: (this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingMethod1.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingMethod1.id : undefined,
                    weldingMethod2Id: (this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingMethod2.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingMethod2.id : undefined,
                    weldingPositionId: (this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingPosition.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingPosition.id : undefined,
                    weldingPosition2Id: (this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingPosition2.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingPosition2.id : undefined,
                    arcTypeId1: (this.props.weldingProtocol.weldingProtocolSection2.selectedArcTypeA.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection2.selectedArcTypeA.id : undefined,
                    arcTypeId2: (this.props.weldingProtocol.weldingProtocolSection2.selectedArcTypeB.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection2.selectedArcTypeB.id : undefined,
                    enableMakeAText: this.props.weldingProtocol.weldingProtocolSection2.enableMakeAText,
                    makeAText: this.props.weldingProtocol.weldingProtocolSection2.makeAText,
                    designationAText: this.props.weldingProtocol.weldingProtocolSection2.designationAText,
                    fmGroupAText: this.props.weldingProtocol.weldingProtocolSection2.fmGroupAText,
                    enableMakeBText: this.props.weldingProtocol.weldingProtocolSection2.enableMakeBText,
                    makeBText: this.props.weldingProtocol.weldingProtocolSection2.makeBText,
                    designationBText: this.props.weldingProtocol.weldingProtocolSection2.designationBText,
                    fmGroupBText: this.props.weldingProtocol.weldingProtocolSection2.fmGroupBText,
                },
                createWeldingProtocolSection3Request: {
                    BaseMaterial1Id: (this.props.weldingProtocol.weldingProtocolSection3.selectedBaseMaterial1.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection3.selectedBaseMaterial1.id : undefined,
                    ChargeAltHeatNumber1: this.props.weldingProtocol.weldingProtocolSection3.chargeAltHeatNumber1,
                    TechnicalDeliveryCondition1Id: (this.props.weldingProtocol.weldingProtocolSection3.selectedTechnicalDeliveryCondition1.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection3.selectedTechnicalDeliveryCondition1.id : undefined,
                    ProductType1Id: (this.props.weldingProtocol.weldingProtocolSection3.selectedProductType1.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection3.selectedProductType1.id : undefined,
                    MaterialThickness1: !isNaN(parseFloat(this.props.weldingProtocol.weldingProtocolSection3.materialThickness1.replace(',', '.'))) ? parseFloat(this.props.weldingProtocol.weldingProtocolSection3.materialThickness1.replace(',', '.')) : 0.0,
                    Diameter1: HelpFunctions.getNumberOfString(this.props.weldingProtocol.weldingProtocolSection3.diameter1.replace(',', '.')),
                    OtherInformation1: this.props.weldingProtocol.weldingProtocolSection3.otherInformation1,
                    BaseMaterial2Id: (this.props.weldingProtocol.weldingProtocolSection3.selectedBaseMaterial2.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection3.selectedBaseMaterial2.id : undefined,
                    ChargeAltHeatNumber2: this.props.weldingProtocol.weldingProtocolSection3.chargeAltHeatNumber2,
                    TechnicalDeliveryCondition2Id: (this.props.weldingProtocol.weldingProtocolSection3.selectedTechnicalDeliveryCondition2.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection3.selectedTechnicalDeliveryCondition2.id : undefined,
                    ProductType2Id: (this.props.weldingProtocol.weldingProtocolSection3.selectedProductType2.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection3.selectedProductType2.id : undefined,
                    MaterialThickness2: !isNaN(parseFloat(this.props.weldingProtocol.weldingProtocolSection3.materialThickness2.replace(',', '.'))) ? parseFloat(this.props.weldingProtocol.weldingProtocolSection3.materialThickness2.replace(',', '.')) : 0.0,
                    Diameter2: HelpFunctions.getNumberOfString(this.props.weldingProtocol.weldingProtocolSection3.diameter2.replace(',', '.')),
                    OtherInformation2: this.props.weldingProtocol.weldingProtocolSection3.otherInformation2,
                    enableBaseMaterial1Text: this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial1Text,
                    enableBaseMaterial2Text: this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial2Text,
                    baseMaterial1Text: this.props.weldingProtocol.weldingProtocolSection3.baseMaterial1Text,
                    baseMaterial2Text: this.props.weldingProtocol.weldingProtocolSection3.baseMaterial2Text,
                    materialGroup1Text: this.props.weldingProtocol.weldingProtocolSection3.materialGroup1Text,
                    materialGroup2Text: this.props.weldingProtocol.weldingProtocolSection3.materialGroup2Text,
                    technicalDeliveryCondition1Text: this.props.weldingProtocol.weldingProtocolSection3.technicalDeliveryCondition1Text,
                    technicalDeliveryCondition2Text: this.props.weldingProtocol.weldingProtocolSection3.technicalDeliveryCondition2Text,
                },
                createWeldingProtocolSection4Request: {
                    shieldingGas1Id: (this.props.weldingProtocol.weldingProtocolSection4.selectedShieldingGas1.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection4.selectedShieldingGas1.id : undefined,
                    shieldingGas2Id: (this.props.weldingProtocol.weldingProtocolSection4.selectedShieldingGas2.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection4.selectedShieldingGas2.id : undefined,
                    gasFlow1TopSide: !isNaN(parseFloat(this.props.weldingProtocol.weldingProtocolSection4.gasFlowMethod1TopSide.replace(',', '.'))) ? parseFloat(this.props.weldingProtocol.weldingProtocolSection4.gasFlowMethod1TopSide.replace(',', '.')) : 0.0,
                    gasFlow2TopSide: !isNaN(parseFloat(this.props.weldingProtocol.weldingProtocolSection4.gasFlowMethod2TopSide.replace(',', '.'))) ? parseFloat(this.props.weldingProtocol.weldingProtocolSection4.gasFlowMethod2TopSide.replace(',', '.')) : 0.0,
                    rootProtectionGasId: (this.props.weldingProtocol.weldingProtocolSection4.selectedRootProtectionGas.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection4.selectedRootProtectionGas.id : undefined,
                    plasmaGasId: (this.props.weldingProtocol.weldingProtocolSection4.selectedPlasmaGas.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection4.selectedPlasmaGas.id : undefined,
                    rootSupportDetailsId: (this.props.weldingProtocol.weldingProtocolSection4.selectedRootSupportDetails.id !== 0) ? this.props.weldingProtocol.weldingProtocolSection4.selectedRootSupportDetails.id : undefined,
                    gasFlow1RootSide: !isNaN(parseFloat(this.props.weldingProtocol.weldingProtocolSection4.gasFlowMethod1RootSide.replace(',', '.'))) ? parseFloat(this.props.weldingProtocol.weldingProtocolSection4.gasFlowMethod1RootSide.replace(',', '.')) : 0.0,
                    jointPreparation: this.props.weldingProtocol.weldingProtocolSection4.jointPreparation,
                    nozzleDiameter: this.props.weldingProtocol.weldingProtocolSection4.nozzleDiameter, 
                    nozzleDistance: this.props.weldingProtocol.weldingProtocolSection4.nozzleDistance,
                    gunAngle: this.props.weldingProtocol.weldingProtocolSection4.gunAngle,
                    oscillationMaxRunWidth: this.props.weldingProtocol.weldingProtocolSection4.oscillationMaxRunWidth,
                    tungstenElectrode: this.props.weldingProtocol.weldingProtocolSection4.tungstenElectrode,
                    minElevatedWorkingTemperature: !isNaN(parseFloat(this.props.weldingProtocol.weldingProtocolSection4.minElevatedWorkingTemperature.replace(',', '.'))) ? parseFloat(this.props.weldingProtocol.weldingProtocolSection4.minElevatedWorkingTemperature.replace(',', '.')) : 0.0,
                    maxInterpassTemperature: !isNaN(parseFloat(this.props.weldingProtocol.weldingProtocolSection4.maxInterpassTemperature.replace(',', '.'))) ? parseFloat(this.props.weldingProtocol.weldingProtocolSection4.maxInterpassTemperature.replace(',', '.')) : 0.0,
                    powerSourceManufacturer: this.props.weldingProtocol.weldingProtocolSection4.powerSourceManufacturer,
                    postWeldHeatTreatment: this.props.weldingProtocol.weldingProtocolSection4.postWeldHeatTreatment,
                    heatingAndCoolingRate: this.props.weldingProtocol.weldingProtocolSection4.heatingAndCoolingRate,
                    electrodeTorchAngle111: this.props.weldingProtocol.weldingProtocolSection4.electrodeTorchAngle111,
                    plasmaflowrate: this.props.weldingProtocol.weldingProtocolSection4.plasmaflowrate,
                    typeOfBackingText: "",
                },
                createWeldingProtocolSection6Request: {
                    createWeldingParametersRequests: [],
                },
                createWeldingProtocolSection7_8Request: {
                    tackWeldText: this.props.weldingProtocol.weldingProtocolSection7.tackWeldText,
                    lengthTackWelds: this.props.weldingProtocol.weldingProtocolSection7.lengthTackWelds,
                    throatThickness: this.props.weldingProtocol.weldingProtocolSection7.throatThickness,
                    remarks: this.props.weldingProtocol.weldingProtocolSection7.remarks,
                    placeForMonitoring: this.props.weldingProtocol.placeForMonitoring,
                    weldingDate: this.props.weldingProtocol.weldingDate,
                    signature: this.props.weldingProtocol.signature,
                    employeeNumber: this.props.weldingProtocol.employeeNumber,
                    createJointDesignRequest: {
                        materialThickness1: 0.0,
                        materialThickness2: 0.0,
                        jointTypeId: undefined,
                        jointShapeId: undefined,
                        jointAngleAlpha: 0.0,
                        gapOpening: 0.0,
                        straightEdge: 0.0,
                        phaseAngle1: 0.0,
                        phaseAngle2: 0.0,
                        jointAngleBeta: 0.0,
                        throatThickness: 0.0,
                        rootSupportId: undefined,
                        depthOfPreparation: 0.0,
                        radius: 0.0,
                        note: '',
                        jointDesignHtmlImage: ''
                    },
                    createWeldSequenceRequest: {
                        imageId: 0,
                        imageData: '',
                        sequenceNumber1: '',
                        sequenceNumber2: '',
                        sequenceNumber3: '',
                        sequenceNumber4: '',
                        sequenceNumber5: '',
                        sequenceNumber6: '',
                        sequenceNumber7: '',
                        sequenceNumber8: '',
                        sequenceNumber9: '',
                        sequenceNumber10: '',
                        sequenceNumber11: '',
                        sequenceNumber12: '',
                        sequenceNumber13: '',
                        sequenceNumber14: '',
                        sequenceNumber15: '',
                    }
                },
            },
            createTestResultsExaminationRequest: {} as any,
            createTestingDetails1ExaminationRequest: {} as any,
            createRangeOfQualificationExaminationRequest: {} as any,
            createTestingOrderRequest: {} as any,
            updateFilesUploadRequest: [],
        };
        this.props.createWpqr(createWpqrRequest, () => { callback(); });
    }

    private onClickTestResultsNavItem(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        this.props.setSelectedTestResultsTabId(event.currentTarget.id);
    }
    private onClickToggleApplicationsIsOpen(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.props.setApplicationsIsOpen(!this.props.applicationsIsOpen);
        // Update the applications
        this.props.getApplicationsByApplicationStatus(ApplicationStatus.Confirmed);
    }

    private onClickWeldingProtocolSelectBaseMaterial1(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, baseMaterial: GetBaseMaterialResponse) {
        event.preventDefault();
        this.props.clearWeldingProtocolBaseMaterial1SearchResult();
        this.props.setWeldingProtocolSelectedBaseMaterial1(baseMaterial);
    }

    private onClickWeldingProtocolSelectBaseMaterial2(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, baseMaterial: GetBaseMaterialResponse) {
        event.preventDefault();
        this.props.clearWeldingProtocolBaseMaterial2SearchResult();
        this.props.setWeldingProtocolSelectedBaseMaterial2(baseMaterial);
    }

    private onClickWeldingProtocolSelectMakeA(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, make: GetMakeResponse) {
        event.preventDefault();
        this.props.clearWeldingProtocolMakeASearchResult();
        this.props.setWeldingProtocolSelectedMakeA(make);
    }

    private onClickWeldingProtocolSelectMakeB(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, make: GetMakeResponse) {
        event.preventDefault();
        this.props.clearWeldingProtocolMakeBSearchResult();
        this.props.setWeldingProtocolSelectedMakeB(make);
    }

    private onClickWeldingProtocolSelectRootProtectionGas(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, rootProtectionGas: GetRootProtectionGasResponse) {
        event.preventDefault();
        this.props.clearWeldingProtocolRootProtectionGasSearchResult();
        this.props.setWeldingProtocolSelectedRootProtectionGas(rootProtectionGas);
    }

    private onClickWeldingProtocolSelectShieldingGas1(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, shieldingGas: GetShieldingGasResponse) {
        event.preventDefault();
        this.props.clearWeldingProtocolShieldingGas1SearchResult();
        this.props.setWeldingProtocolSelectedShieldingGas1(shieldingGas);
    }

    private onClickWeldingProtocolSelectShieldingGas2(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, shieldingGas: GetShieldingGasResponse) {
        event.preventDefault();
        this.props.clearWeldingProtocolShieldingGas2SearchResult();
        this.props.setWeldingProtocolSelectedShieldingGas2(shieldingGas);
    }

    private onClickWeldingProtocolSelectWeldingMethodA(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, weldingMethod: GetWeldingMethodResponse) {
        event.preventDefault();
        this.props.clearWeldingProtocolWeldingMethod1SearchResult();
        this.props.setWeldingProtocolSelectedWeldingMethodA(weldingMethod);
    }

    private onClickWeldingProtocolSelectWeldingMethodB(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, weldingMethod: GetWeldingMethodResponse) {
        event.preventDefault();
        this.props.clearWeldingProtocolWeldingMethod2SearchResult();
        this.props.setWeldingProtocolSelectedWeldingMethodB(weldingMethod);
    }

    private onClickWPQRNavItem(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        this.props.setSelectedWPQRTabId(event.currentTarget.id);
        if ("completedWPQR" === event.currentTarget.id) {
            this.setWpqrCompleteIsOpen(true)
        } else {
            this.setHiddenWeldingProtocolTabes(true);
        }
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private handleChange = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            this.props.createFile(event.target.files[i])
        }
    };

    private handleChangeDraJointDesign = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            this.props.createImageDrawJointDesign(event.target.files[i])
        }
    };

    private onChangeWeldingDataPlasmaflowrate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataPlasmaflowrate(event.currentTarget.value);
    }

    private onChangeWeldingDataElectrodeTorchAngle111(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataElectrodeTorchAngle111(event.currentTarget.value);
    }

    public render() {
        return (
            <React.Fragment>
                <div className="dekra-content-wrapper dekra-wrapper" >
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader>
                                    <Nav pills>
                                        <NavItem>
                                            <NavLink active={this.props.selectedWPQRTabId === 'ongoingWPQR'} href="#"
                                                id="ongoingWPQR" onClick={(event) => this.onClickWPQRNavItem(event)}>{t('OngoingWPQR')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={this.props.selectedWPQRTabId === 'completedWPQR'} href="#"
                                                id="completedWPQR" onClick={(event) => this.onClickWPQRNavItem(event)}>{t('CompletedWPQR')}</NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <div hidden={this.props.selectedWPQRTabId !== 'ongoingWPQR'}>
                                        {this.renderOngoingWPQR()}
                                    </div>
                                    <div hidden={this.props.selectedWPQRTabId !== 'completedWPQR'}>
                                        {this.renderCompletedWPQR()}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    private renderBendTesting() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('BendTesting')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingTestingStandardProcedure" id="bendTestingTestingStandardProcedure"
                                                        value={this.props.bendTesting.testingStandardProcedure}
                                                        onChange={(event) => this.onChangeBendTestingTestingStandardProcedure(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingTestingTemperature" id="bendTestingTestingTemperature"
                                                        value={this.props.bendTesting.testingTemperature}
                                                        onChange={(event) => this.onChangeBendTestingTestingTemperature(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingLocationOfTestSpecimens" id="bendTestingLocationOfTestSpecimens"
                                                        value={this.props.bendTesting.locationOfTestSpecimens}
                                                        onChange={(event) => this.onChangeBendTestingLocationOfTestSpecimens(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingTestSpecimens" id="bendTestingTestSpecimens"
                                                        value={this.props.bendTesting.testSpecimens}
                                                        onChange={(event) => this.onChangeBendTestingTestSpecimens(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingTestingMachine" size="sm">{t('TestingMachine')}</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingTestingMachine" id="bendTestingTestingMachine"
                                                        value={this.props.bendTesting.testingMachine}
                                                        onChange={(event) => this.onChangeBendTestingTestingMachine(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingTestingMachineSerialNumber" size="sm">{t('TestingMachine')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingTestingMachineSerialNumber" id="bendTestingTestingMachineSerialNumber"
                                                        value={this.props.bendTesting.testingMachineSerialNumber}
                                                        onChange={(event) => this.onChangeBendTestingTestingMachineSerialNumber(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingMeasuringInstrument" size="sm">{t('MeasuringInstrument')}</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingMeasuringInstrument" id="bendTestingMeasuringInstrument"
                                                        value={this.props.bendTesting.measuringInstrument}
                                                        onChange={(event) => this.onChangeBendTestingMeasuringInstrument(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingMeasuringInstrumentSerialNumber" size="sm">{t('MeasuringInstrument')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingMeasuringInstrumentSerialNumber" id="bendTestingMeasuringInstrumentSerialNumber"
                                                        value={this.props.bendTesting.measuringInstrumentSerialNumber}
                                                        onChange={(event) => this.onChangeBendTestingMeasuringInstrumentSerialNumber(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('BendTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderBendTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="bendTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="bendTestingResultsComments" id="bendTestingResultsComments"
                                                        value={this.props.bendTestingResults.comments} onChange={(event) => this.onChangeBendTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingResultsAcceptanceNormsStandard" id="bendTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.bendTestingResults.acceptanceNormsStandard}
                                                        onChange={(event) => this.onChangeBendTestingResultsAcceptanceNormsStandard(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingResultsDeviationFromStandardsOrAgreement" id="bendTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.bendTestingResults.deviationFromStandardsOrAgreement}
                                                        onChange={(event) => this.onChangeBendTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingResultsTestingPerformedBy" id="bendTestingResultsTestingPerformedBy"
                                                        value={this.props.bendTestingResults.testingPerformedBy}
                                                        onChange={(event) => this.onChangeBendTestingResultsTestingPerformedBy(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="bendTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="bendTestingResultsCertificationNumber" id="bendTestingResultsCertificationNumber"
                                                        value={this.props.bendTestingResults.certificationNumber}
                                                        onChange={(event) => this.onChangeBendTestingResultsCertificationNumber(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderBendTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderBendTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.bendTestingResults.bendTestingResultsTableRows.map((row, index) => (
                            this.renderBendTestingResultsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderBendTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('type')}</th>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('dimensions')}, mm</th>
                    <th className="text-nowrap">{t('DiameterOfRoller')}, mm</th>
                    <th className="text-nowrap">{t('DistanceBetweenRollers')}, mm</th>
                    <th className="text-nowrap">{t('BendingAngle')} </th>
                    <th className="text-nowrap">{t('SurfaceCondition')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderBendTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"bendTestingResultsTestSpecimenNumber-" + rowNumber} id={"bendTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onChange={(event) => this.onChangeBendTestingResultsTestSpecimenNumber(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsTestSpecimenType-" + rowNumber} id={"bendTestingResultsTestSpecimenType-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenType}
                            onChange={(event) => this.onChangeBendTestingResultsTestSpecimenType(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsTestSpecimenDimensions-" + rowNumber} id={"bendTestingResultsTestSpecimenDimensions-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions}
                            onChange={(event) => this.onChangeBendTestingResultsTestSpecimenDimensions(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsDiameterOfRoller-" + rowNumber} id={"bendTestingResultsDiameterOfRoller-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].diameterOfRoller}
                            onChange={(event) => this.onChangeBendTestingResultsDiameterOfRoller(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsDistanceBetweenRollers-" + rowNumber} id={"bendTestingResultsDistanceBetweenRollers-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].distanceBetweenRollers}
                            onChange={(event) => this.onChangeBendTestingResultsDistanceBetweenRollers(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsBendingAngle-" + rowNumber} id={"bendTestingResultsBendingAngle-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].bendingAngle}
                            onChange={(event) => this.onChangeBendTestingResultsBendingAngle(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsSurfaceCondition-" + rowNumber} id={"bendTestingResultsSurfaceCondition-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].surfaceCondition}
                            onChange={(event) => this.onChangeBendTestingResultsSurfaceCondition(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"bendTestingResultsAcceptance-" + rowNumber} id={"bendTestingResultsAcceptance-" + rowNumber}
                            value={this.props.bendTestingResults.bendTestingResultsTableRows[rowNumber - 1].acceptance}
                            onChange={(event) => this.onChangeBendTestingResultsAcceptance(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderChemicalTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap text-center" colSpan={9}>{t('MassPercentPart_')}, %</th>
                    <th className="text-nowrap">{t('Grade')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderChemicalTestingResultsTable1() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderChemicalTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows.map((row, index) => (
                            this.renderChemicalTestingResultsTable1Row(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderChemicalTestingResultsTable1Row(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1TestSpecimenNumber-" + rowNumber} id={"chemicalTestingResultsTable1TestSpecimenNumber-" + rowNumber}
                            value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].testSpecimenNumber}
                            onChange={(event) => this.onChangeChemicalTestingResultsTable1TestSpecimenNumber(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements11-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements11-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements11}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements11(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements21-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements21-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements21}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements21(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements12-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements12-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements12}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements12(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements22-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements22-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements22}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements22(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements13-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements13-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements13}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements13(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements23-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements23-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements23}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements23(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements14-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements14-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements14}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements14(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements24-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements24-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements24}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements24(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements15-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements15-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements15}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements15(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements25-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements25-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements25}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements25(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements16-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements16-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements16}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements16(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements26-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements26-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements26}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements26(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements17-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements17-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements17}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements17(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements27-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements27-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements27}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements27(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements18-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements18-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements18}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements18(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements28-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements28-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements28}
                                    onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements28(rowNumber, event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements19-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements19-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements19}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements19(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1MassPercentPartOfElements29-" + rowNumber} id={"chemicalTestingResultsTable1MassPercentPartOfElements29-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].massPercentPartOfElements29}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable1MassPercentPartOfElements29(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable1Grade-" + rowNumber} id={"chemicalTestingResultsTable1Grade-" + rowNumber}
                            value={this.props.chemicalTestingResults.chemicalTestingResultsTable1Rows[rowNumber - 1].grade}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable1Grade(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderChemicalTestingResultsTable2() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderChemicalTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows.map((row, index) => (
                            this.renderChemicalTestingResultsTable2Row(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderChemicalTestingResultsTable2Row(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2TestSpecimenNumber-" + rowNumber} id={"chemicalTestingResultsTable2TestSpecimenNumber-" + rowNumber}
                            value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2TestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements11-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements11-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements11}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements11(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements21-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements21-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements21}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements21(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements12-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements12-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements12}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements12(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements22-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements22-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements22}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements22(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements13-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements13-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements13}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements13(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements23-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements23-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements23}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements23(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements14-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements14-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements14}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements14(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements24-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements24-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements24}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements24(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements15-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements15-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements15}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements15(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements25-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements25-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements25}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements25(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements16-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements16-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements16}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements16(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements26-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements26-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements26}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements26(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements17-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements17-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements17}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements17(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements27-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements27-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements27}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements27(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements18-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements18-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements18}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements18(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements28-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements28-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements28}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements28(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements19-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements19-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements19}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements19(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2MassPercentPartOfElements29-" + rowNumber} id={"chemicalTestingResultsTable2MassPercentPartOfElements29-" + rowNumber}
                                    value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].massPercentPartOfElements29}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2MassPercentPartOfElements29(rowNumber, event)}
                                    placeholder="" />
                            </Col>
                        </Row>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"chemicalTestingResultsTable2Grade-" + rowNumber} id={"chemicalTestingResultsTable2Grade-" + rowNumber}
                            value={this.props.chemicalTestingResults.chemicalTestingResultsTable2Rows[rowNumber - 1].grade}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTable2Grade(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderCompletedWPQR() {
        return (
            <React.Fragment>
                <Collapse isOpen={this.state.wpqrCompleteIsOpen}>
                    <WPQRComplete pWeldingProtocolId={this.props.weldingProtocol.weldingProtocolId}
                        onSelectWpqr={this.initWpqrItem}
                        onSelectWpqrView={this.initWpqrItemView}
                        onSelectWpqrRevision={this.initWpqrItemRevision} />
                </Collapse>
            </React.Fragment>
        );
    }

    private renderCorrosionTestingResultsInfoText() {
        return (
            <React.Fragment>
                <FormText color="muted">
                    {t('TheReportedExpandedUncertainty_')}
                </FormText>
            </React.Fragment>
        );
    }

    private renderCorrosionTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderCorrosionTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.corrosionTestingResults.corrosionTestingResultsTableRows.map((row, index) => (
                            this.renderCorrosionTestingResultsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderCorrosionTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimenNo')}</th>
                    <th className="text-nowrap">{t('TestResults')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderCorrosionTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"corrosionTestingResultsTestSpecimenNumber-" + rowNumber} id={"corrosionTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.corrosionTestingResults.corrosionTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"corrosionTestingResultsTestingResult-" + rowNumber} id={"corrosionTestingResultsTestingResult-" + rowNumber}
                            value={this.props.corrosionTestingResults.corrosionTestingResultsTableRows[rowNumber - 1].testingResult}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingResultsTestingResult(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"corrosionTestingResultsAcceptance-" + rowNumber} id={"corrosionTestingResultsAcceptance-" + rowNumber}
                            value={this.props.corrosionTestingResults.corrosionTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderCruciformTensileTestingResultsInfoText() {
        return (
            <React.Fragment>
                <FormText color="muted">
                    {t('TheReportedExpandedUncertainty_')}
                </FormText>
            </React.Fragment>
        );
    }

    private renderCruciformTensileTestingResultsMeasurementUncertaintyTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th colSpan={2}></th>
                    <th className="text-nowrap">U Wf, mm</th>
                    <th className="text-nowrap">U Fm, N</th>
                    <th className="text-nowrap">U Rm, MPa</th>
                    <th colSpan={2}></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderCruciformTensileTestingResultsMeasurementUncertaintyTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row" colSpan={2}>
                        <Label size="sm" className="float-right">{t('MeasurementUncertainty')}</Label>
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name="cruciformTensileTestingResultsMeasurementUncertaintyUR" id="cruciformTensileTestingResultsMeasurementUncertaintyUR"
                            value={this.props.cruciformTensileTestingResults.measurementUncertaintyUWf} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsMeasurementUncertaintyUWf(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="cruciformTensileTestingResultsMeasurementUncertaintyUFm" id="cruciformTensileTestingResultsMeasurementUncertaintyUFm"
                            value={this.props.cruciformTensileTestingResults.measurementUncertaintyUFm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsMeasurementUncertaintyUFm(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="cruciformTensileTestingResultsMeasurementUncertaintyURm" id="cruciformTensileTestingResultsMeasurementUncertaintyURm"
                            value={this.props.cruciformTensileTestingResults.measurementUncertaintyURm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsMeasurementUncertaintyURm(event)}
                            placeholder="" />
                    </td>
                    <td colSpan={2}>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderCruciformTensileTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderCruciformTensileTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow.map((row, index) => (
                            this.renderCruciformTensileTestingResultsTableRow(index + 1)
                        ))}
                        {this.renderCruciformTensileTestingResultsMeasurementUncertaintyTableHeaderRow()}
                        {this.renderCruciformTensileTestingResultsMeasurementUncertaintyTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderCruciformTensileTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap">{t('SpecimenDimensions')}</th>
                    <th className="text-nowrap">Wf, mm</th>
                    <th className="text-nowrap">Fm, Kn</th>
                    <th className="text-nowrap">Rm, MPa</th>
                    <th className="text-nowrap">{t('PlaceOfBreak')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderCruciformTensileTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"cruciformTestingResultsTestSpecimenNumber-" + rowNumber} id={"cruciformTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"cruciformTestingResultsTestSpecimenDimensions-" + rowNumber} id={"cruciformTestingResultsTestSpecimenDimensions-" + rowNumber}
                            value={this.props.cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].testSpecimenDimensions}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsTestSpecimenDimensions(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"cruciformTestingResultsWf-" + rowNumber} id={"cruciformTestingResultsWf-" + rowNumber}
                            value={this.props.cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].wf}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsWf(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"cruciformTestingResultsFm-" + rowNumber} id={"cruciformTestingResultsFm-" + rowNumber}
                            value={this.props.cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].fm}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsFm(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"cruciformTestingResultsRm-" + rowNumber} id={"cruciformTestingResultsRm-" + rowNumber}
                            value={this.props.cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].rm}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsRm(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"cruciformTestingResultsPlaceOfBreak-" + rowNumber} id={"cruciformTestingResultsPlaceOfBreak-" + rowNumber}
                            value={this.props.cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].placeOfBreak}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsPlaceOfBreak(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"cruciformTestingResultsAcceptance-" + rowNumber} id={"cruciformTestingResultsAcceptance-" + rowNumber}
                            value={this.props.cruciformTensileTestingResults.cruciformTensileTestingResultsTableRow[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderExaminationWPQR() {
        return (
            <React.Fragment>
                <div>
                    <Nav tabs className="d-examination-nav-tabs">
                        <NavItem>
                            <NavLink active={this.props.selectedExaminationWPQRTabId === 'rangeOfQualification'}
                                href="#"
                                id="rangeOfQualification" onClick={(event) => this.onClickExaminationWPQRNavItem(event)}>{t('ValidationAreas')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={this.props.selectedExaminationWPQRTabId === 'weldingTestingDetails1'} href="#"
                                id="weldingTestingDetails1" onClick={(event) => this.onClickExaminationWPQRNavItem(event)}>{t('WeldingTestDetails')} 1/2</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={this.props.selectedExaminationWPQRTabId === 'weldingTestingDetails2'} href="#"
                                id="weldingTestingDetails2" onClick={(event) => this.onClickExaminationWPQRNavItem(event)}>{t('WeldingTestDetails')} 2/2</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={this.props.selectedExaminationWPQRTabId === 'testResultsExamination'} href="#"
                                id="testResultsExamination" onClick={(event) => this.onClickExaminationWPQRNavItem(event)}>{t('TestResults')}</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.props.selectedExaminationWPQRTabId}>
                        <TabPane tabId="rangeOfQualification">
                            <Row>
                                <Col sm="12">
                                    <RangeOfQualification
                                        jointType={this.props.jointTypeRangeOfQualification}
                                        weldingMethods={this.props.weldingMethodRangeOfQualifications}
                                        materialGroup={this.props.materialGroupRangeOfQualification}
                                        materialThicknesses={this.props.materialThicknessRangeOfQualifications}
                                        weldingMetalThickness={this.props.weldingMetalThicknessRangeOfQualification}
                                        throatThickness={this.props.throatThicknessRangeOfQualification}
                                        runType={this.props.runTypeRangeOfQualification}
                                        diameter={this.props.diameterRangeOfQualification}
                                        designations={this.props.designationRangeOfQualifications}
                                        makes={this.props.makeRangeOfQualifications}
                                        shieldingGases={this.props.shieldingGasRangeOfQualifications}
                                        rootProtectionGas={this.props.rootProtectionGasRangeOfQualification}
                                        currentTypes={this.props.currentTypeRangeOfQualifications}
                                        dropletTransitions={this.props.dropletTransitionRangeOfQualifications}
                                        postWeldHeatTreatment={this.props.postWeldHeatTreatmentRangeOfQualification}
                                        preheatTemperature={this.props.preheatTemperatureRangeOfQualification}
                                        interpassTemperature={this.props.interpassTemperatureRangeOfQualification}
                                        rangeOfQualifications={this.props.rangeOfQualifications}
                                        selectedApplicaitonStandardId={this.props.weldingProtocol.standardId}
                                        weldingDate={this.props.weldingProtocol.weldingDate}
                                        companyAdress={this.props.weldingProtocol.weldingProtocolSection1.company2.streetAddress}
                                        companyPostcode={this.props.weldingProtocol.weldingProtocolSection1.company2.postcode}
                                        companyCity={this.props.weldingProtocol.weldingProtocolSection1.company2.city}
                                        companyName={this.props.weldingProtocol.weldingProtocolSection1.company2.name}
                                        pRangeOfQualificationExaminationResponse={this.props.selectedWpqr.rangeOfQualificationExaminationResponse}
                                        pUpdateRangeOfqualification={this.state.updateRangeOfqualification}
                                        pExaminer={this.props.weldingTestingDetails1.examiner}
                                        setExaminer={this.props.setWeldingTestingDetails1Examiner}
                                        pExaminerName={this.props.weldingTestingDetails1.examinerName}
                                        setExaminerName={this.props.setWeldingTestingDetails1ExaminerName}
                                        pExaminerDate={this.props.weldingTestingDetails1.examinerDate }
                                        setExaminerDate={this.props.setWeldingTestingDetails1ExaminerDate}/>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="weldingTestingDetails1">
                            <Row>
                                <Col sm="12">
                                    <TestingDetails1Component/>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="weldingTestingDetails2">
                            <Row>
                                <Col sm="12">
                                    {this.renderWeldingTestingDetails2()}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="testResultsExamination">
                            <Row>
                                <Col sm="12">
                                    <TestResultsExamination cloneWPQR={this.cloneWPQR} saveWPQR={this.onClickAddAndRemoveFilesToFilesUpload}
                                        pDekraProcedureNumber={this.props.weldingProtocol.dekraProcedureNumber}
                                        pTestResultsExaminationId={this.props.selectedWpqr.testResultsExaminationId}
                                        pTestResultsExaminationResponse={this.props.selectedWpqr.testResultsExaminationResponse}
                                        pRevisionWpqrAction={this.props.weldingProtocol.revisionWpqrAction}
                                        pUpdateTestResultsExamination={this.state.updateTestResultsExamination} />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </React.Fragment>
        );
    }

    private renderFerriteTestingAndChemicalTesting() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('FerriteContentDetermination')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingTestingStandardProcedure" id="ferriteTestingTestingStandardProcedure"
                                                        value={this.props.ferriteTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingTestingTemperature" id="ferriteTestingTestingTemperature"
                                                        value={this.props.ferriteTesting.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingLocationOfTestSpecimens" id="ferriteTestingLocationOfTestSpecimens"
                                                        value={this.props.ferriteTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingTestSpecimens" id="ferriteTestingTestSpecimens"
                                                        value={this.props.ferriteTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingFerriteMeter" size="sm">Ferrite meter</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingFerriteMeter" id="ferriteTestingFerriteMeter"
                                                        value={this.props.ferriteTesting.ferriteMeter} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingFerriteMeter(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingFerriteMeterSerialNumber" size="sm">Ferrite meter S/N</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingFerriteMeterSerialNumber" id="ferriteTestingFerriteMeterSerialNumber"
                                                        value={this.props.ferriteTesting.ferriteMeterSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingFerriteMeterSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingStandardsSet" size="sm">Standards set</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingStandardsSet" id="ferriteTestingStandardsSet"
                                                        value={this.props.ferriteTesting.standardsSet} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingStandardsSet(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingStandardsSetSerialNumber" size="sm">Standards set S/N</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingStandardsSetSerialNumber" id="ferriteTestingStandardsSetSerialNumber"
                                                        value={this.props.ferriteTesting.standardsSetSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingStandardsSetSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingSpecimensQuantity" size="sm">{t('SpecimenQuantity')}</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingSpecimensQuantity" id="ferriteTestingSpecimensQuantity"
                                                        value={this.props.ferriteTesting.specimensQuantity} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingSpecimensQuantity(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingMeasuringInstrument" size="sm">{t('MeasuringInstrument')}</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingMeasuringInstrument" id="ferriteTestingMeasuringInstrument"
                                                        value={this.props.ferriteTesting.measuringInstrument} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingMeasuringInstrument(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingMeasuringInstrumentSerialNumber" size="sm">{t('MeasuringInstrument')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingMeasuringInstrumentSerialNumber" id="ferriteTestingMeasuringInstrumentSerialNumber"
                                                        value={this.props.ferriteTesting.measuringInstrumentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingMeasuringInstrumentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('FerriteContentDeterminationResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderFerriteTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderFerriteTestingResultsInfoText()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="ferriteTestingResultsComments" id="ferriteTestingResultsComments"
                                                        value={this.props.ferriteTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingResultsAcceptanceNormsStandard" id="ferriteTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.ferriteTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingResultsDeviationFromStandardsOrAgreement" id="ferriteTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.ferriteTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingResultsTestingPerformedBy" id="ferriteTestingResultsTestingPerformedBy"
                                                        value={this.props.ferriteTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="ferriteTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="ferriteTestingResultsCertificationNumber" id="ferriteTestingResultsCertificationNumber"
                                                        value={this.props.ferriteTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>Chemical composition determination</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingTestingStandardProcedure" id="chemicalTestingTestingStandardProcedure"
                                                        value={this.props.chemicalTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingTestingTemperature" id="chemicalTestingTestingTemperature"
                                                        value={this.props.chemicalTesting.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingTestingMethod" size="sm">{t('TestingMethod')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingTestingMethod" id="chemicalTestingTestingMethod"
                                                        value={this.props.chemicalTesting.testingMethod} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingTestingMethod(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingSpecimensQuantity" size="sm">{t('SpecimenQuantity')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingSpecimensQuantity" id="chemicalTestingSpecimensQuantity"
                                                        value={this.props.chemicalTesting.specimensQuantity} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingSpecimensQuantity(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingLocationOfTestSpecimens" id="chemicalTestingLocationOfTestSpecimens"
                                                        value={this.props.chemicalTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemciaTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemciaTestingTestSpecimens" id="chemciaTestingTestSpecimens"
                                                        value={this.props.chemicalTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemcialTestingTestingEquipment" size="sm">{t('TestingEquipment')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemcialTestingTestingEquipment" id="chemcialTestingTestingEquipment"
                                                        value={this.props.chemicalTesting.testingEquipment} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemcialTestingTestingEquipment(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemcialTestingTestingEquipmentSerialNumber" size="sm">{t('TestingEquipment')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="chemcialTestingTestingEquipmentSerialNumber" id="chemcialTestingTestingEquipmentSerialNumber"
                                                        value={this.props.chemicalTesting.testingEquipmentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemcialTestingTestingEquipmentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemcialTestingMeasuringInstrument" size="sm">{t('MeasuringInstrument')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemcialTestingMeasuringInstrument" id="chemcialTestingMeasuringInstrument"
                                                        value={this.props.chemicalTesting.measuringInstrument} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemcialTestingMeasuringInstrument(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemcialTestingMeasuringInstrumentSerialNumber" size="sm">{t('MeasuringInstrument')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="chemcialTestingMeasuringInstrumentSerialNumber" id="chemcialTestingMeasuringInstrumentSerialNumber"
                                                        value={this.props.chemicalTesting.measuringInstrumentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemcialTestingMeasuringInstrumentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('ChemicalCompositionDeterminationResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderChemicalTestingResultsTable1()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingResultsInterpretationsConclusions1" size="sm">{t('Interpretations_')}, {t('conclusions')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingResultsInterpretationsConclusions1" id="chemicalTestingResultsInterpretationsConclusions1"
                                                        value={this.props.chemicalTestingResults.interpretationsConclusions1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsInterpretationsConclusions1(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderChemicalTestingResultsTable2()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingResultsInterpretationsConclusions2" size="sm">{t('Interpretations_')}, {t('conclusions')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingResultsInterpretationsConclusions2" id="chemicalTestingResultsInterpretationsConclusions2"
                                                        value={this.props.chemicalTestingResults.interpretationsConclusions2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsInterpretationsConclusions2(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingResultsAcceptanceNormsStandard" id="chemicalTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.chemicalTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingResultsDeviationFromStandardsOrAgreement" id="chemicalTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.chemicalTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingResultsTestingPerformedBy" id="chemicalTestingResultsTestingPerformedBy"
                                                        value={this.props.chemicalTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="chemicalTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="chemicalTestingResultsCertificationNumber" id="chemicalTestingResultsCertificationNumber"
                                                        value={this.props.chemicalTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeChemicalTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderFerriteTestingResultsInfoText() {
        return (
            <React.Fragment>
                <FormText color="muted">
                    {t('TheReportedExpandedUncertainty_')}
                </FormText>
            </React.Fragment>
        );
    }

    private renderFerriteTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderFerriteTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.ferriteTestingResults.ferriteTestingResultsTableRows.map((row, index) => (
                            this.renderFerriteTestingResultsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderFerriteTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap">{t('FerriteContent')}</th>
                    <th className="text-nowrap">{t('MeasurementUncertainty')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderFerriteTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"ferriteTestingResultsTestSpecimenNumber-" + rowNumber} id={"ferriteTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.ferriteTestingResults.ferriteTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"ferriteTestingResultsFerriteContent-" + rowNumber} id={"ferriteTestingResultsFerriteContent-" + rowNumber}
                            value={this.props.ferriteTestingResults.ferriteTestingResultsTableRows[rowNumber - 1].ferriteContent}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingResultsFerriteContent(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"ferriteTestingResultsMeasurementUncertainty-" + rowNumber} id={"ferriteTestingResultsMeasurementUncertainty-" + rowNumber}
                            value={this.props.ferriteTestingResults.ferriteTestingResultsTableRows[rowNumber - 1].measurementUncertainty}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingResultsMeasurementUncertainty(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"ferriteTestingResultsAcceptance-" + rowNumber} id={"ferriteTestingResultsAcceptance-" + rowNumber}
                            value={this.props.ferriteTestingResults.ferriteTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFerriteTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderFractureTestingAndCorrosionTesting() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>Fracture testing</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingTestingStandardProcedure" id="fractureTestingTestingStandardProcedure"
                                                        value={this.props.fractureTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingTestingTemperature" id="fractureTestingTestingTemperature"
                                                        value={this.props.fractureTesting.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingLocationOfTestSpecimens" id="fractureTestingLocationOfTestSpecimens"
                                                        value={this.props.fractureTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingTestSpecimens" id="fractureTestingTestSpecimens"
                                                        value={this.props.fractureTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingTestingMachine" size="sm">{t('TestingMachine')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingTestingMachine" id="fractureTestingTestingMachine"
                                                        value={this.props.fractureTesting.testingMachine} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingTestingMachine(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingTestingMachineSerialNumber" size="sm">{t('TestingMachine')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingTestingMachineSerialNumber" id="fractureTestingTestingMachineSerialNumber"
                                                        value={this.props.fractureTesting.testingMachineSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingTestingMachineSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingMeasuringInstrument" size="sm">{t('MeasuringInstrument')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingMeasuringInstrument" id="fractureTestingMeasuringInstrument"
                                                        value={this.props.fractureTesting.measuringInstrument} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingMeasuringInstrument(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingMeasuringInstrumentSerialNumber" size="sm">{t('MeasuringInstrument')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingMeasuringInstrumentSerialNumber" id="fractureTestingMeasuringInstrumentSerialNumber"
                                                        value={this.props.fractureTesting.measuringInstrumentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingMeasuringInstrumentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingTestSpecimensDescription" size="sm">{t('TestSpecimensDescription')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingTestSpecimensDescription" id="fractureTestingTestSpecimensDescription"
                                                        value={this.props.fractureTesting.testSpecimensDescription} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingTestSpecimensDescription(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingTestSpecimensQuantity" size="sm">{t('TestSpecimensQuantity')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingTestSpecimensQuantity" id="fractureTestingTestSpecimensQuantity"
                                                        value={this.props.fractureTesting.testSpecimensQuantity} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingTestSpecimensQuantity(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>Fracture testing results</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderFractureTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="fractureTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="fractureTestingResultsComments" id="fractureTestingResultsComments"
                                                        value={this.props.fractureTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingResultsAcceptanceNormsStandard" id="fractureTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.fractureTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingResultsDeviationFromStandardsOrAgreement" id="fractureTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.fractureTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingResultsTestingPerformedBy" id="fractureTestingResultsTestingPerformedBy"
                                                        value={this.props.fractureTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="fractureTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="fractureTestingResultsCertificationNumber" id="fractureTestingResultsCertificationNumber"
                                                        value={this.props.fractureTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('CorrosionTesting')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="corrosionTestingTestingStandardProcedure" id="corrosionTestingTestingStandardProcedure"
                                                        value={this.props.corrosionTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="corrosionTestingLocationOfTestSpecimens" id="corrosionTestingLocationOfTestSpecimens"
                                                        value={this.props.corrosionTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="corrosionTestingTestSpecimens" id="corrosionTestingTestSpecimens"
                                                        value={this.props.corrosionTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingTestingMethodDescription" size="sm">Testing method description</Label>
                                                    <Input type="text" bsSize="sm" name="corrosionTestingTestingMethodDescription" id="corrosionTestingTestingMethodDescription"
                                                        value={this.props.corrosionTesting.testingMethodDescription} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingTestingMethodDescription(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingTestingCondition" size="sm">{t('TestingCondition')}</Label>
                                                    <Input type="text" bsSize="sm" name="corrosionTestingTestingCondition" id="corrosionTestingTestingCondition"
                                                        value={this.props.corrosionTesting.testingCondition} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingTestingCondition(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('CorrosionTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderCorrosionTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderCorrosionTestingResultsInfoText()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="corrosionTestingResultsComments" id="corrosionTestingResultsComments"
                                                        value={this.props.corrosionTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="corrosionTestingResultsAcceptanceNormsStandard" id="corrosionTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.corrosionTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="corrosionTestingResultsDeviationFromStandardsOrAgreement" id="corrosionTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.corrosionTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="corrosionTestingResultsTestingPerformedBy" id="corrosionTestingResultsTestingPerformedBy"
                                                        value={this.props.corrosionTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="corrosionTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="corrosionTestingResultsCertificationNumber" id="corrosionTestingResultsCertificationNumber"
                                                        value={this.props.corrosionTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCorrosionTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderFractureTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderFractureTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.fractureTestingResults.fractureTestingResultsTableRows.map((row, index) => (
                            this.renderFractureTestingResultsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderFractureTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap">{t('TestResults')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderFractureTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"fractureTestingResultsTestSpecimenNumber-" + rowNumber} id={"fractureTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.fractureTestingResults.fractureTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"fractureTestingResultsTestResults-" + rowNumber} id={"fractureTestingResultsTestResults-" + rowNumber}
                            value={this.props.fractureTestingResults.fractureTestingResultsTableRows[rowNumber - 1].testResult}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingResultsTestResults(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"fractureTestingResultsAcceptance-" + rowNumber} id={"fractureTestingResultsAcceptance-" + rowNumber}
                            value={this.props.fractureTestingResults.fractureTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFractureTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTesting() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('HardnessTesting')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingTestingStandardProcedure" id="hardnessTestingTestingStandardProcedure"
                                                        value={this.props.hardnessTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingTestingTemperature" id="hardnessTestingTestingTemperature"
                                                        value={this.props.hardnessTesting.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingLocationOfTestSpecimens" id="hardnessTestingLocationOfTestSpecimens"
                                                        value={this.props.hardnessTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingTestSpecimens" id="hardnessTestingTestSpecimens"
                                                        value={this.props.hardnessTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingTestingMachine" size="sm">{t('TestingMachine')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingTestingMachine" id="hardnessTestingTestingMachine"
                                                        value={this.props.hardnessTesting.testingMachine} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingTestingMachine(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingTestingMachineSerialNumber" size="sm">{t('TestingMachine')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingTestingMachineSerialNumber" id="hardnessTestingTestingMachineSerialNumber"
                                                        value={this.props.hardnessTesting.testingMachineSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingTestingMachineSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingMeasuringInstrument" size="sm">{t('MeasuringInstrument')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingMeasuringInstrument" id="hardnessTestingMeasuringInstrument"
                                                        value={this.props.hardnessTesting.measuringInstrument} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingMeasuringInstrument(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingMeasuringInstrumentSerialNumber" size="sm">{t('MeasuringInstrument')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingMeasuringInstrumentSerialNumber" id="hardnessTestingMeasuringInstrumentSerialNumber"
                                                        value={this.props.hardnessTesting.measuringInstrumentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingMeasuringInstrumentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('HardnessOfWelded_')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingResultsWayOfMeasurementOfHardness1" size="sm">{t('WayOfMeasurementOfHardness')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingResultsWayOfMeasurementOfHardness1" id="hardnessTestingResultsWayOfMeasurementOfHardness1"
                                                        value={this.props.hardnessTestingResults.wayOfMeasurementOfHardness1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsWayOfMeasurementOfHardness1(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderHardnessTestingResultsTable1()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingResultsWayOfMeasurementOfHardness2" size="sm">{t('WayOfMeasurementOfHardness')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingResultsWayOfMeasurementOfHardness2" id="hardnessTestingResultsWayOfMeasurementOfHardness2"
                                                        value={this.props.hardnessTestingResults.wayOfMeasurementOfHardness2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsWayOfMeasurementOfHardness2(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderHardnessTestingResultsTable2()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="hardnessTestingResultsComments" id="hardnessTestingResultsComments"
                                                        value={this.props.hardnessTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingResultsMeasurementUncertainty" size="sm">{t('MeasurementUncertainty')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingResultsMeasurementUncertainty" id="hardnessTestingResultsMeasurementUncertainty"
                                                        value={this.props.hardnessTestingResults.measurementUncertainty} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsMeasurementUncertainty(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                {this.renderHardnessTestingResultsInfoText()}
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingResultsAcceptance" size="sm">{t('Acceptance')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingResultsAcceptance" id="hardnessTestingResultsAcceptance"
                                                        value={this.props.hardnessTestingResults.acceptance} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsAcceptance(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingResultsAcceptanceNormsStandard" id="hardnessTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.hardnessTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingResultsDeviationFromStandardsOrAgreement" id="hardnessTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.hardnessTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingResultsTestingPerformedBy" id="hardnessTestingResultsTestingPerformedBy"
                                                        value={this.props.hardnessTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="hardnessTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="hardnessTestingResultsCertificationNumber" id="hardnessTestingResultsCertificationNumber"
                                                        value={this.props.hardnessTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsInfoText() {
        return (
            <React.Fragment>
                <FormText color="muted">
                    {t('TheReportedExpandedUncertainty_')}
                </FormText>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsNumberOfGaugingTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <td className="text-nowrap">{t('NumberOfGauging')}</td>
                    <td className="text-nowrap">1</td>
                    <td className="text-nowrap">2</td>
                    <td className="text-nowrap">3</td>
                    <td className="text-nowrap">4</td>
                    <td className="text-nowrap">5</td>
                    <td className="text-nowrap">6</td>
                    <td className="text-nowrap">7</td>
                    <td className="text-nowrap">8</td>
                    <td className="text-nowrap">9</td>
                    <td className="text-nowrap">10</td>
                    <td className="text-nowrap">11</td>
                    <td className="text-nowrap">12</td>
                    <td className="text-nowrap">13</td>
                    <td className="text-nowrap">14</td>
                    <td className="text-nowrap">15</td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsPlaceOfGaugingTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <td className="text-nowrap">{t('PlaceOfGauging')}</td>
                    <td className="text-nowrap">{t('parent')}</td>
                    <td className="text-nowrap">{t('parent')}</td>
                    <td className="text-nowrap">{t('parent')}</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">{t('fogning')}</td>
                    <td className="text-nowrap">{t('fogning')}</td>
                    <td className="text-nowrap">{t('fogning')}</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">HAZ</td>
                    <td className="text-nowrap">{t('parent')}</td>
                    <td className="text-nowrap">{t('parent')}</td>
                    <td className="text-nowrap">{t('parent')}</td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsValueOfHardnessTable1Row(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <td>{t('ValueOfHardness')}</td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1Parent1" id="hardnessTestingResultsTable1Parent1"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent1(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1Parent2" id="hardnessTestingResultsTable1Parent2"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent2(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1Parent3" id="hardnessTestingResultsTable1Parent3"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent3} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent3(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1HAZ4" id="hardnessTestingResultsTable1HAZ4"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz4} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ4(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1HAZ5" id="hardnessTestingResultsTable1HAZ5"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz5} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ5(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1HAZ6" id="hardnessTestingResultsTable1HAZ6"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz6} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ6(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1Weld7" id="hardnessTestingResultsTable1Weld7"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld7} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Weld7(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1Weld8" id="hardnessTestingResultsTable1Weld8"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld8} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Weld8(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1Weld9" id="hardnessTestingResultsTable1Weld9"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].weld9} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Weld9(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1HAZ10" id="hardnessTestingResultsTable1HAZ10"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz10} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ10(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1HAZ11" id="hardnessTestingResultsTable1HAZ11"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz11} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ11(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1HAZ12" id="hardnessTestingResultsTable1HAZ12"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].haz12} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1HAZ12(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1Parent13" id="hardnessTestingResultsTable1Parent13"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent13} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent13(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1Parent14" id="hardnessTestingResultsTable1Parent14"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent14} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent14(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable1Parent15" id="hardnessTestingResultsTable1Parent15"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows[rowNumber - 1].parent15} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable1Parent15(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsTable1() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <tbody>
                        {this.renderHardnessTestingResultsNumberOfGaugingTableRow()}
                        {this.props.hardnessTestingResults.hardnessTestingResultsTable1Rows.map((row, index) => (
                            this.renderHardnessTestingResultsValueOfHardnessTable1Row(index + 1)
                        ))}
                        {this.renderHardnessTestingResultsPlaceOfGaugingTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsValueOfHardnessTable2Row(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <td>{t('ValueOfHardness')}</td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2Parent1" id="hardnessTestingResultsTable2Parent1"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent1(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2Parent2" id="hardnessTestingResultsTable2Parent2"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent2(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2Parent3" id="hardnessTestingResultsTable2Parent3"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent3} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent3(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2HAZ4" id="hardnessTestingResultsTable2HAZ4"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz4} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ4(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2HAZ5" id="hardnessTestingResultsTable2HAZ5"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz5} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ5(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2HAZ6" id="hardnessTestingResultsTable2HAZ6"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz6} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ6(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2Weld7" id="hardnessTestingResultsTable2Weld7"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld7} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Weld7(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2Weld8" id="hardnessTestingResultsTable2Weld8"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld8} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Weld8(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2Weld9" id="hardnessTestingResultsTable2Weld9"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].weld9} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Weld9(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2HAZ10" id="hardnessTestingResultsTable2HAZ10"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz10} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ10(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2HAZ11" id="hardnessTestingResultsTable2HAZ11"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz11} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ11(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2HAZ12" id="hardnessTestingResultsTable2HAZ12"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].haz12} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2HAZ12(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2Parent13" id="hardnessTestingResultsTable2Parent13"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent13} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent13(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2Parent14" id="hardnessTestingResultsTable2Parent14"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent14} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent14(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="hardnessTestingResultsTable2Parent15" id="hardnessTestingResultsTable2Parent15"
                            value={this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows[rowNumber - 1].parent15} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHardnessTestingResultsTable2Parent15(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderHardnessTestingResultsTable2() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <tbody>
                        {this.renderHardnessTestingResultsNumberOfGaugingTableRow()}
                        {this.props.hardnessTestingResults.hardnessTestingResultsTable2Rows.map((row, index) => (
                            this.renderHardnessTestingResultsValueOfHardnessTable2Row(index + 1)
                        ))}
                        {this.renderHardnessTestingResultsPlaceOfGaugingTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderImpactTesting() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('ImpactTesting')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingTestingStandardProcedure" id="impactTestingTestingStandardProcedure"
                                                        value={this.props.impactTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingTestingTemperature" id="impactTestingTestingTemperature"
                                                        value={this.props.impactTesting.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingLocationOfTestSpecimens" id="impactTestingLocationOfTestSpecimens"
                                                        value={this.props.impactTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingTestSpecimens" id="impactTestingTestSpecimens"
                                                        value={this.props.impactTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingTestingMachine" size="sm">{t('TestingMachine')}</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingTestingMachine" id="impactTestingTestingMachine"
                                                        value={this.props.impactTesting.testingMachine} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingTestingMachine(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingTestingMachineSerialNumber" size="sm">{t('TestingMachine')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingTestingMachineSerialNumber" id="impactTestingTestingMachineSerialNumber"
                                                        value={this.props.impactTesting.testingMachineSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingTestingMachineSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingMeasuringInstrument" size="sm">{t('MeasuringInstrument')}</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingMeasuringInstrument" id="impactTestingMeasuringInstrument"
                                                        value={this.props.impactTesting.measuringInstrument} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingMeasuringInstrument(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingMeasuringInstrumentSerialNumber" size="sm">{t('MeasuringInstrument')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingMeasuringInstrumentSerialNumber" id="impactTestingMeasuringInstrumentSerialNumber"
                                                        value={this.props.impactTesting.measuringInstrumentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingMeasuringInstrumentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('ImpactTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderImpactTestingResultsWeldTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderImpactTestingResultsHAZTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderImpactTestingResultsInfoText()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="impactTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="impactTestingResultsComments" id="impactTestingResultsComments"
                                                        value={this.props.impactTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup check>
                                                    <Label check size="sm">
                                                        <Input type="checkbox" bssize="sm" id="ped" />{' '}
                                                        a) {t('TheSpecimenWasnt_')}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check size="sm">
                                                        <Input type="checkbox" bssize="sm" id="norsok" />{' '}
                                                        b) {t('TheAbsorbedEnergyMeasurement_')}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check size="sm">
                                                        <Input type="checkbox" bssize="sm" id="adMerkplatt" />{' '}
                                                        c) {t('TheAbsorbedEnergyMeasurement_')}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingResultsAcceptanceNormsStandard" id="impactTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.impactTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingResultsDeviationFromStandardsOrAgreement" id="impactTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.impactTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingResultsTestingPerformedBy" id="impactTestingResultsTestingPerformedBy"
                                                        value={this.props.impactTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="impactTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="impactTestingResultsCertificationNumber" id="impactTestingResultsCertificationNumber"
                                                        value={this.props.impactTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsHAZAverageTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row" colSpan={4}>
                        <Label size="sm" className="float-right">{t('Average')}</Label>
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsHAZAverageAbsorbedEnergy" id="impactTestingResultsHAZAverageAbsorbedEnergy"
                            value={this.props.impactTestingResults.hazAverageAbsorbedEnergy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZAverageAbsorbedEnergy(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsHAZAverageLateralExpansion" id="impactTestingResultsHAZAverageLateralExpansion"
                            value={this.props.impactTestingResults.hazAverageLateralExpansion} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZAverageLateralExpansion(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsHAZAverageShear" id="impactTestingResultsHAZAverageShear"
                            value={this.props.impactTestingResults.hazAverageShear} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZAverageShear(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsHAZAverageAcceptance" id="impactTestingResultsHAZAverageAcceptance"
                            value={this.props.impactTestingResults.hazAverageAcceptance} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZAverageAcceptance(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsHAZMeasurementUncertaintyTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row" colSpan={4}>
                        <Label size="sm" className="float-right">{t('MeasurementUncertainty')}</Label>
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsHAZMeasurementUncertaintyAbsorbedEnergy" id="impactTestingResultsHAZMeasurementUncertaintyAbsorbedEnergy"
                            value={this.props.impactTestingResults.hazMeasurementUncertaintyAbsorbedEnergy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZMeasurementUncertaintyAbsorbedEnergy(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsHAZMeasurementUncertaintyLateralExpansion" id="impactTestingResultsHAZMeasurementUncertaintyLateralExpansion"
                            value={this.props.impactTestingResults.hazMeasurementUncertaintyLateralExpansion} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZMeasurementUncertaintyLateralExpansion(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsHAZMeasurementUncertaintyShear" id="impactTestingResultsHAZMeasurementUncertaintyShear"
                            value={this.props.impactTestingResults.hazMeasurementUncertaintyShear} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZMeasurementUncertaintyShear(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsHAZMeasurementUncertaintyAcceptance" id="impactTestingResultsHAZMeasurementUncertaintyAcceptance"
                            value={this.props.impactTestingResults.hazMeasurementUncertaintyAcceptance} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZMeasurementUncertaintyAcceptance(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsHAZTable() {
        return (
            <React.Fragment>
                <h6 className="text-center">Impact test in HAZ</h6>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderImpactTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.impactTestingResults.hazImpactTestingResultsTableRows.map((row, index) => (
                            this.renderImpactTestingResultsHAZTableRow(index + 1)
                        ))}
                        {this.renderImpactTestingResultsHAZAverageTableRow()}
                        {this.renderImpactTestingResultsHAZMeasurementUncertaintyTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsHAZTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"impactTestingResultsHAZTestSpecimenNumber-" + rowNumber} id={"impactTestingResultsHAZTestSpecimenNumber-" + rowNumber}
                            value={this.props.impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsHAZTestSpecimenType-" + rowNumber} id={"impactTestingResultsHAZTestSpecimenType-" + rowNumber}
                            value={this.props.impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].testSpecimenType}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZTestSpecimenType(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsHAZTestSpecimenDimensions-" + rowNumber} id={"impactTestingResultsHAZTestSpecimenDimensions-" + rowNumber}
                            value={this.props.impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZTestSpecimenDimensions(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsHAZNominalEnergy-" + rowNumber} id={"impactTestingResultsHAZNominalEnergy-" + rowNumber}
                            value={this.props.impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].nominalEnergy}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZNominalEnergy(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsHAZAbsorbedEnergy-" + rowNumber} id={"impactTestingResultsHAZAbsorbedEnergy-" + rowNumber}
                            value={this.props.impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZAbsorbedEnergy(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsHAZLateralExpansion-" + rowNumber} id={"impactTestingResultsHAZLateralExpansion-" + rowNumber}
                            value={this.props.impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].lateralExpansion}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZLateralExpansion(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsHAZShear-" + rowNumber} id={"impactTestingResultsHAZShear-" + rowNumber}
                            value={this.props.impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].shear}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZShear(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsHAZAcceptance-" + rowNumber} id={"impactTestingResultsHAZAcceptance-" + rowNumber}
                            value={this.props.impactTestingResults.hazImpactTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsHAZAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsInfoText() {
        return (
            <React.Fragment>
                <FormText color="muted">
                    {t('TheAbsorbedEnergy_')}
                </FormText>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsWeldAverageTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row" colSpan={4}>
                        <Label size="sm" className="float-right">{t('Average')}</Label>
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsWeldAverageAbsorbedEnergy" id="impactTestingResultsWeldAverageAbsorbedEnergy"
                            value={this.props.impactTestingResults.weldAverageAbsorbedEnergy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAverageAbsorbedEnergy(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsWeldAverageLateralExpansion" id="impactTestingResultsWeldAverageLateralExpansion"
                            value={this.props.impactTestingResults.weldAverageLateralExpansion} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAverageLateralExpansion(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsWeldAverageShear" id="impactTestingResultsWeldAverageShear"
                            value={this.props.impactTestingResults.weldAverageShear} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAverageShear(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsWeldAverageAcceptance" id="impactTestingResultsWeldAverageAcceptance"
                            value={this.props.impactTestingResults.weldAverageAcceptance} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAverageAcceptance(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsWeldMeasurementUncertaintyTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row" colSpan={4}>
                        <Label size="sm" className="float-right">{t('MeasurementUncertainty')}</Label>
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsWeldMeasurementUncertaintyAbsorbedEnergy" id="impactTestingResultsWeldMeasurementUncertaintyAbsorbedEnergy"
                            value={this.props.impactTestingResults.weldMeasurementUncertaintyAbsorbedEnergy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldMeasurementUncertaintyAbsorbedEnergy(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsWeldMeasurementUncertaintyLateralExpansion" id="impactTestingResultsWeldMeasurementUncertaintyLateralExpansion"
                            value={this.props.impactTestingResults.weldMeasurementUncertaintyLateralExpansion} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldMeasurementUncertaintyLateralExpansion(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsWeldMeasurementUncertaintyShear" id="impactTestingResultsWeldMeasurementUncertaintyShear"
                            value={this.props.impactTestingResults.weldMeasurementUncertaintyShear} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldMeasurementUncertaintyShear(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="impactTestingResultsWeldMeasurementUncertaintyAcceptance" id="impactTestingResultsWeldMeasurementUncertaintyAcceptance"
                            value={this.props.impactTestingResults.weldMeasurementUncertaintyAcceptance} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldMeasurementUncertaintyAcceptance(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsWeldTable() {
        return (
            <React.Fragment>
                <h6 className="text-center">Impact test in weld</h6>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderImpactTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.impactTestingResults.weldImpactTestingResultsTableRows.map((row, index) => (
                            this.renderImpactTestingResultsWeldTableRow(index + 1)
                        ))}
                        {this.renderImpactTestingResultsWeldAverageTableRow()}
                        {this.renderImpactTestingResultsWeldMeasurementUncertaintyTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsWeldTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"impactTestingResultsWeldTestSpecimenNumber-" + rowNumber} id={"impactTestingResultsWeldTestSpecimenNumber-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsWeldTestSpecimenType-" + rowNumber} id={"impactTestingResultsWeldTestSpecimenType-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenType}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldTestSpecimenType(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsWeldTestSpecimenDimensions-" + rowNumber} id={"impactTestingResultsWeldTestSpecimenDimensions-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldTestSpecimenDimensions(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsWeldNominalEnergy-" + rowNumber} id={"impactTestingResultsWeldNominalEnergy-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].nominalEnergy}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldNominalEnergy(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsWeldAbsorbedEnergy-" + rowNumber} id={"impactTestingResultsWeldAbsorbedEnergy-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].absorbedEnergy}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAbsorbedEnergy(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsWeldLateralExpansion-" + rowNumber} id={"impactTestingResultsWeldLateralExpansion-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].lateralExpansion}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldLateralExpansion(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsWeldShear-" + rowNumber} id={"impactTestingResultsWeldShear-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].shear}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldShear(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"impactTestingResultsWeldAcceptance-" + rowNumber} id={"impactTestingResultsWeldAcceptance-" + rowNumber}
                            value={this.props.impactTestingResults.weldImpactTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeImpactTestingResultsWeldAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderImpactTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap">{t('TestSpecimenType')}</th>
                    <th className="text-nowrap">{t('TestSpecimenDimensions')}, mm</th>
                    <th className="text-nowrap">Nominal energy J</th>
                    <th className="text-nowrap">Absorbed energy KV, J</th>
                    <th className="text-nowrap">Lateral expansion LE, mm</th>
                    <th className="text-nowrap">Shear %</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderMacroTestingAndMicroTesting() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('MacroStructureExamination')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="macroTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingTestingStandardProcedure" id="macroTestingTestingStandardProcedure"
                                                        value={this.props.macroTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingLocationOfTestSpecimens" id="macroTestingLocationOfTestSpecimens"
                                                        value={this.props.macroTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingTestSpecimens" id="macroTestingTestSpecimens"
                                                        value={this.props.macroTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="macroTestingExaminationDesignation" size="sm">{t('ExaminationDesignation')}</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingExaminationDesignation" id="macroTestingExaminationDesignation"
                                                        value={this.props.macroTesting.examinationDesignation} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingExaminationDesignation(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingExaminationEquipment" size="sm">Examination equipment</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingExaminationEquipment" id="macroTestingExaminationEquipment"
                                                        value={this.props.macroTesting.examinationEquipment} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingExaminationEquipment(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingExaminationEquipmentSerialNumber" size="sm">Examination equipment S/N</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingExaminationEquipmentSerialNumber" id="macroTestingExaminationEquipmentSerialNumber"
                                                        value={this.props.macroTesting.examinationEquipmentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingExaminationEquipmentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingTestingCondition" size="sm">{t('TestingCondition')}</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingTestingCondition" id="macroTestingTestingCondition"
                                                        value={this.props.macroTesting.testingCondition} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingTestingCondition(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingSpecimensQuantity" size="sm">{t('SpecimenQuantity')}</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingSpecimensQuantity" id="macroTestingSpecimensQuantity"
                                                        value={this.props.macroTesting.specimensQuantity} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingSpecimensQuantity(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('MacroStructureExaminationResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderMacroTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderMacroTestingResultsInfoText()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="macroTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="macroTestingResultsComments" id="macroTestingResultsComments"
                                                        value={this.props.macroTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingResultsAcceptanceNormsStandard" id="macroTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.macroTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingResultsDeviationFromStandardsOrAgreement" id="macroTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.macroTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingResultsTestingPerformedBy" id="macroTestingResultsTestingPerformedBy"
                                                        value={this.props.macroTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="macroTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="macroTestingResultsCertificationNumber" id="macroTestingResultsCertificationNumber"
                                                        value={this.props.macroTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('MicroStructureExamination')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="microTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingTestingStandardProcedure" id="microTestingTestingStandardProcedure"
                                                        value={this.props.microTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingLocationOfTestSpecimens" id="microTestingLocationOfTestSpecimens"
                                                        value={this.props.microTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingTestSpecimens" id="microTestingTestSpecimens"
                                                        value={this.props.microTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="microTestingExaminationDesignation" size="sm">{t('ExaminationDesignation')}</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingExaminationDesignation" id="microTestingExaminationDesignation"
                                                        value={this.props.microTesting.examinationDesignation} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingExaminationDesignation(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingExaminationEquipment" size="sm">Examination equipment</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingExaminationEquipment" id="microTestingExaminationEquipment"
                                                        value={this.props.microTesting.examinationEquipment} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingExaminationEquipment(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingExaminationEquipmentSerialNumber" size="sm">Examination equipment S/N</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingExaminationEquipmentSerialNumber" id="microTestingExaminationEquipmentSerialNumber"
                                                        value={this.props.microTesting.examinationEquipmentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingExaminationEquipmentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingTestingCondition" size="sm">{t('TestingCondition')}</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingTestingCondition" id="microTestingTestingCondition"
                                                        value={this.props.microTesting.testingCondition} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingTestingCondition(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingSpecimensQuantity" size="sm">{t('SpecimenQuantity')}</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingSpecimensQuantity" id="microTestingSpecimensQuantity"
                                                        value={this.props.microTesting.specimensQuantity} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingSpecimensQuantity(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('MicroStructureExaminationResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderMicroTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderMicroTestingResultsInfoText()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="microTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="microTestingResultsComments" id="microTestingResultsComments"
                                                        value={this.props.microTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingResultsAcceptanceNormsStandard" id="microTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.microTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingResultsDeviationFromStandardsOrAgreement" id="microTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.microTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingResultsTestingPerformedBy" id="microTestingResultsTestingPerformedBy"
                                                        value={this.props.microTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="microTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="microTestingResultsCertificationNumber" id="microTestingResultsCertificationNumber"
                                                        value={this.props.microTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderMacroTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderMacroTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.macroTestingResults.macroTestingResultsTableRows.map((row, index) => (
                            this.renderMacroTestingResultsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderMacroTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap">{t('ExaminationResult')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderMacroTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"macroTestingResultsTestSpecimenNumber-" + rowNumber} id={"macroTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"macroTestingResultsExaminationResult-" + rowNumber} id={"macroTestingResultsExaminationResult-" + rowNumber}
                            value={this.props.macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].examinationResult}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsExaminationResult(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"macroTestingResultsAcceptance-" + rowNumber} id={"macroTestingResultsAcceptance-" + rowNumber}
                            value={this.props.macroTestingResults.macroTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMacroTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderMacroTestingResultsInfoText() {
        return (
            <React.Fragment>
                <FormText color="muted">
                    {t('TheReportedExpandedUncertainty_')}
                </FormText>
            </React.Fragment>
        );
    }

    private renderMicroTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderMicroTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.microTestingResults.microTestingResultsTableRows.map((row, index) => (
                            this.renderMicroTestingResultsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderMicroTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap">{t('ExaminationResult')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderMicroTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"microTestingResultsTestSpecimenNumber-" + rowNumber} id={"microTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.microTestingResults.microTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"microTestingResultsExaminationResult-" + rowNumber} id={"microTestingResultsExaminationResult-" + rowNumber}
                            value={this.props.microTestingResults.microTestingResultsTableRows[rowNumber - 1].examinationResult}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsExaminationResult(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"microTestingResultsAcceptance-" + rowNumber} id={"microTestingResultsAcceptance-" + rowNumber}
                            value={this.props.microTestingResults.microTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderMicroTestingResultsInfoText() {
        return (
            <React.Fragment>
                <Form inline>
                    <FormText color="muted">
                        {t('TheReportedExpanded_')}
                    </FormText>
                    <FormGroup>
                        <Input type="text" bsSize="sm" name="microTestingResultsCoverageFactor" id="microTestingResultsCoverageFactor"
                            value={this.props.microTestingResults.coverageFactor}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsCoverageFactor(event)}
                            placeholder="" />
                    </FormGroup>
                    <FormText color="muted">
                        , {t('_whichForA_')}
                    </FormText>
                    <span>&nbsp;</span>
                    <FormGroup>
                        <Input type="text" bsSize="sm" name="microTestingResultsDistribution" id="microTestingResultsDistribution"
                            value={this.props.microTestingResults.distribution}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMicroTestingResultsDistribution(event)}
                            placeholder="" />
                    </FormGroup>
                    <span>&nbsp;</span>
                    <FormText color="muted">
                        {t('_distribution_')}
                    </FormText>
                </Form>
            </React.Fragment>
        );
    }

    private renderNonDestructiveTesting() {
        return (
            <React.Fragment>
                <p>{t('IndestructiveTesting')}</p>
            </React.Fragment>
        );
    }

    public renderOngoingWPQR() {
        return (
            <React.Fragment>
                <div>
                    <Loader show={this.props.loadingApplication} title="Hämtar WPQR..." />
                    <Loader show={this.props.loadingWpqr} title="Updaterar WPQR..." />
                    <Row>
                        <Col sm={12}>
                            <Button color="primary" size="sm" name="toggleShowApplications" id="toggleShowApplications" onClick={(event) => this.onClickToggleApplicationsIsOpen(event)}>
                                {this.props.applicationsIsOpen &&
                                    <span>{t('HideApplications')}</span>
                                }
                                {!this.props.applicationsIsOpen &&
                                    <span>{t('ShowApplications')}</span>
                                }
                            </Button>
                        </Col>
                    </Row>
                    <Collapse hidden={!this.props.applicationsIsOpen} isOpen={this.props.applicationsIsOpen}>
                        <Row>
                            <Col sm={12}>
                                <Applications applications={this.props.applications}
                                    onSelectApplication={this.initWeldingProtocolItem}
                                    onSelectApplicationWithWpqrId={this.initApplicationWithWpqrItem}
                                    onDeleteApplication={this.props.deleteApplication}
                                    onSetSupervisor={this.props.setApplicationSupervisor}
                                    onSortApplications={this.props.sortApplications} />
                            </Col>
                        </Row>
                    </Collapse>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row hidden={this.state.hiddenWeldingProtocolTabes}>
                        <Col sm={12}>
                            <Card>
                                <CardHeader>
                                    <h6 className="float-right">{t('Application')} {this.props.selectedApplication.dekraNumber}</h6>
                                    <Nav pills className="dekra-breadcrumb">
                                        <NavLink active={this.props.selectedOngoingWPQRTabId === 'pwps'
                                            || this.props.selectedOngoingWPQRTabId === 'testResults'
                                            || this.props.selectedOngoingWPQRTabId === 'testingOrder'
                                            || this.props.selectedOngoingWPQRTabId === 'weldingProtocol'
                                            || this.props.selectedOngoingWPQRTabId === 'examinationWPQR'
                                            || this.props.selectedOngoingWPQRTabId === 'documentFileUpload'} href="#"
                                            id="pwps" onClick={(event) => this.onClickNavItem(event)}>pWPS</NavLink>
                                            <NavLink active={this.props.selectedOngoingWPQRTabId === 'weldingProtocol'
                                                || this.props.selectedOngoingWPQRTabId === 'testResults'
                                                || this.props.selectedOngoingWPQRTabId === 'testingOrder'
                                            || this.props.selectedOngoingWPQRTabId === 'examinationWPQR'
                                            || this.props.selectedOngoingWPQRTabId === 'documentFileUpload'} href="#"
                                            id="weldingProtocol" onClick={(event) => this.onClickNavItem(event)}>Svetsprotokoll</NavLink>
                                        <NavLink active={this.props.selectedOngoingWPQRTabId === 'testingOrder'
                                                || this.props.selectedOngoingWPQRTabId === 'testResults'
                                            || this.props.selectedOngoingWPQRTabId === 'examinationWPQR'
                                            || this.props.selectedOngoingWPQRTabId === 'documentFileUpload'} href="#"
                                            id="testingOrder" onClick={(event) => this.onClickNavItem(event)}>Beställning</NavLink>
                                            <NavLink active={this.props.selectedOngoingWPQRTabId === 'testResults'
                                            || this.props.selectedOngoingWPQRTabId === 'examinationWPQR'
                                            || this.props.selectedOngoingWPQRTabId === 'documentFileUpload'} href="#"
                                            id="testResults" onClick={(event) => this.onClickNavItem(event)}>Provningsresultat</NavLink>
                                        <NavLink active={this.props.selectedOngoingWPQRTabId === 'examinationWPQR'
                                            || this.props.selectedOngoingWPQRTabId === 'documentFileUpload'} href="#"
                                            id="examinationWPQR" onClick={(event) => this.onClickNavItem(event)}>Granskning WPQR</NavLink>
                                        <NavLink active={this.props.selectedOngoingWPQRTabId === 'documentFileUpload'} href="#"
                                            id="documentFileUpload" onClick={(event) => this.onClickNavItem(event)}>Dokument</NavLink>
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <div hidden={this.props.selectedOngoingWPQRTabId !== 'pwps'}>
                                        <WPSFormEdit pUpdateWPSFormEdit={this.state.updateWPSFormEdit} pWpsId={this.props.selectedApplication.wpsId} />
                                    </div>
                                    <div hidden={this.props.selectedOngoingWPQRTabId !== 'weldingProtocol'}>
                                        {this.renderWeldingProtocol()}
                                    </div>
                                    <div hidden={this.props.selectedOngoingWPQRTabId !== 'testResults'}>
                                        {this.renderTestResults()}
                                    </div>
                                    <div hidden={this.props.selectedOngoingWPQRTabId !== 'testingOrder'}>
                                        <TestingOrder pqwbpOrder ={this.props.weldingProtocol.qwbpOrder} />
                                    </div>
                                    <div hidden={this.props.selectedOngoingWPQRTabId !== 'examinationWPQR'}>
                                        {this.renderExaminationWPQR()}
                                    </div>
                                    <div hidden={this.props.selectedOngoingWPQRTabId !== 'documentFileUpload'}>
                                        <FileUpload wpqrId={this.props.weldingProtocol.wpqrId}
                                            pFilesUpload={this.props.weldingProtocol.filesUpload}
                                            showFilesUploadTable={true}
                                            sendToCustomer={this.parentSendToCustomer}
                                            revisionWpqrAction={this.props.weldingProtocol.revisionWpqrAction} />
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    {this.props.weldingProtocol.revisionWpqrAction === RevisionActionType.View || this.props.weldingProtocol.revisionWpqrAction === RevisionActionType.Clone  ? 
                                        <Row>
                                            <Col sm={6}>
                                                <ButtonGroup className={this.props.selectedOngoingWPQRTabId === 'pwps' ? "float-left invisible" : "float-left"}>
                                                    <Button color="primary" onClick={(event) => this.onClickPreviousButton(event)}>{t('Previous')}</Button>
                                                </ButtonGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <ButtonGroup className={this.props.selectedOngoingWPQRTabId === 'documentFileUpload' ? "float-right invisible" : "float-right"}>
                                                    <Button color="primary" onClick={(event) => this.onClickNextButton(event)}>Nästa</Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>:
                                        <Row>
                                            <Col sm={(this.props.selectedOngoingWPQRTabId === 'weldingProtocol' || this.props.selectedOngoingWPQRTabId === 'examinationWPQR') && this.props.selectedWPQRTabId === 'ongoingWPQR' ? 3 : 6}>
                                                <ButtonGroup className={this.props.selectedOngoingWPQRTabId === 'pwps' ? "float-left invisible" : "float-left"}>
                                                    <Button color="primary" onClick={(event) => this.onClickPreviousButton(event)}>{t('Previous')}</Button>
                                                </ButtonGroup>
                                            </Col>
                                            <Col sm={3} hidden={!((this.props.selectedOngoingWPQRTabId === 'weldingProtocol') && (this.props.selectedWPQRTabId === 'ongoingWPQR'))}>
                                                <FileUpload previewFilename={this.props.weldingProtocol.dekraProcedureNumber} wpId={this.props.weldingProtocol.weldingProtocolId} wpqrId={-1} enableOnlyPdfGenerator={true} />
                                            </Col>
                                            <Col sm={3} hidden={!((this.props.selectedOngoingWPQRTabId === 'examinationWPQR') && (this.props.selectedWPQRTabId === 'ongoingWPQR'))}>
                                                <FileUpload previewFilename={this.props.weldingProtocol.dekraProcedureNumber} wpId={-1} wpqrId={this.props.weldingProtocol.wpqrId} enableOnlyPdfGenerator={true} />
                                            </Col>
                                            <Col sm={2} hidden={!((this.props.selectedOngoingWPQRTabId === 'weldingProtocol') && (this.props.weldingProtocol.revisionWpqrAction === ''))}>
                                                <HistoryProgress showToSetProgressId2={true} applicationId={this.props.weldingProtocol.weldingProtocolApplicationId == 0 ? this.props.selectedApplication.id : this.props.weldingProtocol.weldingProtocolApplicationId} />
                                            </Col>
                                            <Col sm={2} hidden={!((this.props.selectedOngoingWPQRTabId === 'documentFileUpload') && (this.props.weldingProtocol.revisionWpqrAction === ''))}>
                                                <HistoryProgress showToSetProgressId3={true} applicationId={this.props.weldingProtocol.weldingProtocolApplicationId == 0 ? this.props.selectedApplication.id : this.props.weldingProtocol.weldingProtocolApplicationId} />
                                            </Col>
                                            <Col sm={(this.props.selectedOngoingWPQRTabId === 'weldingProtocol' || this.props.selectedOngoingWPQRTabId === 'documentFileUpload') && this.props.weldingProtocol.revisionWpqrAction === '' ? 3 : 5}>
                                                <ButtonGroup className={this.props.selectedOngoingWPQRTabId === 'pwps' ? "float-left invisible" : "float-right"}>
                                                    <Button hidden={this.props.selectedWPQRTabId !== 'ongoingWPQR'} type="button" color="primary" id="updateWpqr" name="updateWpqr"
                                                        onClick={(event) => this.onClickUpdateWpqr(event)}>
                                                        Uppdatera pågående WPQR
                                                </Button>
                                                </ButtonGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <ButtonGroup className={this.props.selectedOngoingWPQRTabId === 'documentFileUpload' ? "float-right invisible" : "float-right"}>
                                                    <Button color="primary" onClick={(event) => this.onClickNextButton(event)}>Nästa</Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    }
                                    
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    private renderTensileTesting() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('LongitudinalTensileTesting')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingTestingStandardProcedure" id="tensileTestingTestingStandardProcedure"
                                                        value={this.props.tensileTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingTestingTemperature" id="tensileTestingTestingTemperature"
                                                        value={this.props.tensileTesting.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingLocationOfTestSpecimens" id="tensileTestingLocationOfTestSpecimens"
                                                        value={this.props.tensileTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingTestSpecimens" id="tensileTestingTestSpecimens"
                                                        value={this.props.tensileTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingTestingMachine" size="sm">{t('TestingMachine')}</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingTestingMachine" id="tensileTestingTestingMachine"
                                                        value={this.props.tensileTesting.testingMachine} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingTestingMachine(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingTestingMachineSerialNumber" size="sm">{t('TestingMachine')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingTestingMachineSerialNumber" id="tensileTestingTestingMachineSerialNumber"
                                                        value={this.props.tensileTesting.testingMachineSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingTestingMachineSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingMeasuringInstrument" size="sm">{t('MeasuringInstrument')}</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingMeasuringInstrument" id="tensileTestingMeasuringInstrument"
                                                        value={this.props.tensileTesting.measuringInstrument} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingMeasuringInstrument(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingMeasuringInstrumentSerialNumber" size="sm">{t('MeasuringInstrument')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingMeasuringInstrumentSerialNumber" id="tensileTestingMeasuringInstrumentSerialNumber"
                                                        value={this.props.tensileTesting.measuringInstrumentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingMeasuringInstrumentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('LongitudinalTensileTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderTensileTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                {this.renderTensileTestingResultsInfoText()}
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="tensileTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="tensileTestingResultsComments" id="tensileTestingResultsComments"
                                                        value={this.props.tensileTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingResultsAcceptanceNormsStandard" id="tensileTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.tensileTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingResultsDeviationFromStandardsOrAgreement" id="tensileTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.tensileTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingResultsTestingPerformedBy" id="tensileTestingResultsTestingPerformedBy"
                                                        value={this.props.tensileTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="tensileTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="tensileTestingResultsCertificationNumber" id="tensileTestingResultsCertificationNumber"
                                                        value={this.props.tensileTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('TransverseTensileTesting')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingTestingStandardProcedure" id="transverseTensileTestingTestingStandardProcedure"
                                                        value={this.props.transverseTensileTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingTestingTemperature" id="transverseTensileTestingTestingTemperature"
                                                        value={this.props.transverseTensileTesting.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingLocationOfTestSpecimens" id="transverseTensileTestingLocationOfTestSpecimens"
                                                        value={this.props.transverseTensileTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingTestSpecimens" id="transverseTensileTestingTestSpecimens"
                                                        value={this.props.transverseTensileTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingTestingMachine" size="sm">{t('TestingMachine')}</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingTestingMachine" id="transverseTensileTestingTestingMachine"
                                                        value={this.props.transverseTensileTesting.testingMachine} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingTestingMachine(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingTestingMachineSerialNumber" size="sm">{t('TestingMachine')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingTestingMachineSerialNumber" id="transverseTensileTestingTestingMachineSerialNumber"
                                                        value={this.props.transverseTensileTesting.testingMachineSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingTestingMachineSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingMeasuringInstrument" size="sm">{t('MeasuringInstrument')}</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingMeasuringInstrument" id="transverseTensileTestingMeasuringInstrument"
                                                        value={this.props.transverseTensileTesting.measuringInstrument} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingMeasuringInstrument(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingMeasuringInstrumentSerialNumber" size="sm">{t('MeasuringInstrument')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingMeasuringInstrumentSerialNumber" id="transverseTensileTestingMeasuringInstrumentSerialNumber"
                                                        value={this.props.transverseTensileTesting.measuringInstrumentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingMeasuringInstrumentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('TransverseTensileTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderTransverseTensileTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                {this.renderTransverseTensileTestingResultsInfoText()}
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="transverseTensileTestingResultsComments" id="transverseTensileTestingResultsComments"
                                                        value={this.props.transverseTensileTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingResultsAcceptanceNormsStandard" id="transverseTensileTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.transverseTensileTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingResultsDeviationFromStandardsOrAgreement" id="transverseTensileTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.transverseTensileTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingResultsTestingPerformedBy" id="transverseTensileTestingResultsTestingPerformedBy"
                                                        value={this.props.transverseTensileTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="transverseTensileTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="transverseTensileTestingResultsCertificationNumber" id="transverseTensileTestingResultsCertificationNumber"
                                                        value={this.props.transverseTensileTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('CruciformWeldedJointTensileTesting')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingTestingStandardProcedure" size="sm">{t('TestingStandard')}, {t('procedure')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingTestingStandardProcedure" id="cruciformTensileTestingTestingStandardProcedure"
                                                        value={this.props.cruciformTensileTesting.testingStandardProcedure} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingTestingStandardProcedure(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingTestingTemperature" size="sm">{t('TestingTemperature')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingTestingTemperature" id="cruciformTensileTestingTestingTemperature"
                                                        value={this.props.cruciformTensileTesting.testingTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingTestingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingTestingSampleQuantity" size="sm">{t('SampleQuantity')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingTestingSampleQuantity" id="cruciformTensileTestingTestingSampleQuantity"
                                                        value={this.props.cruciformTensileTesting.sampleQuantity} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingSampleQuantity(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingLocationOfTestSpecimens" size="sm">{t('LocationOfTestSpecimens_')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingLocationOfTestSpecimens" id="cruciformTensileTestingLocationOfTestSpecimens"
                                                        value={this.props.cruciformTensileTesting.locationOfTestSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingLocationOfTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingTestSpecimens" size="sm">{t('TestSpecimensInAccordanceWith')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingTestSpecimens" id="cruciformTensileTestingTestSpecimens"
                                                        value={this.props.cruciformTensileTesting.testSpecimens} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingTestSpecimens(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingTestingMachine" size="sm">{t('TestingMachine')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingTestingMachine" id="cruciformTensileTestingTestingMachine"
                                                        value={this.props.cruciformTensileTesting.testingMachine} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingTestingMachine(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingTestingMachineSerialNumber" size="sm">{t('TestingMachine')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingTestingMachineSerialNumber" id="cruciformTensileTestingTestingMachineSerialNumber"
                                                        value={this.props.cruciformTensileTesting.testingMachineSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingTestingMachineSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingMeasuringInstrument" size="sm">{t('MeasuringInstrument')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingMeasuringInstrument" id="cruciformTensileTestingMeasuringInstrument"
                                                        value={this.props.cruciformTensileTesting.measuringInstrument} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingMeasuringInstrument(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingMeasuringInstrumentSerialNumber" size="sm">{t('MeasuringInstrument')} S/N</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingMeasuringInstrumentSerialNumber" id="cruciformTensileTestingMeasuringInstrumentSerialNumber"
                                                        value={this.props.cruciformTensileTesting.measuringInstrumentSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingMeasuringInstrumentSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('CruciformWeldedJointTensileTestingResults')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderCruciformTensileTestingResultsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                {this.renderCruciformTensileTestingResultsInfoText()}
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingResultsComments" size="sm">{t('Comments')}</Label>
                                                    <Input type="textarea" bsSize="sm" name="cruciformTensileTestingResultsComments" id="cruciformTensileTestingResultsComments"
                                                        value={this.props.cruciformTensileTestingResults.comments} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsComments(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingResultsAcceptanceNormsStandard" size="sm">{t('AcceptanceNorms')}, {t('standard')} ({t('acceptableValues')})</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingResultsAcceptanceNormsStandard" id="cruciformTensileTestingResultsAcceptanceNormsStandard"
                                                        value={this.props.cruciformTensileTestingResults.acceptanceNormsStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsAcceptanceNormsStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingResultsDeviationFromStandardsOrAgreement" size="sm">{t('DeviationFromStandards_')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingResultsDeviationFromStandardsOrAgreement" id="cruciformTensileTestingResultsDeviationFromStandardsOrAgreement"
                                                        value={this.props.cruciformTensileTestingResults.deviationFromStandardsOrAgreement} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsDeviationFromStandardsOrAgreement(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingResultsTestingPerformedBy" size="sm">{t('TestingPerformedBy')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingResultsTestingPerformedBy" id="cruciformTensileTestingResultsTestingPerformedBy"
                                                        value={this.props.cruciformTensileTestingResults.testingPerformedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsTestingPerformedBy(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="cruciformTensileTestingResultsCertificationNumber" size="sm">{t('CertNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="cruciformTensileTestingResultsCertificationNumber" id="cruciformTensileTestingResultsCertificationNumber"
                                                        value={this.props.cruciformTensileTestingResults.certificationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCruciformTensileTestingResultsCertificationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderTensileTestingResultsMeasurementUncertaintyTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th colSpan={2}></th>
                    <th className="text-nowrap">U Fm, N</th>
                    <th className="text-nowrap">U Rm, MPa</th>
                    <th className="text-nowrap">U R, MPa</th>
                    <th className="text-nowrap">U R, %</th>
                    <th className="text-nowrap">U Z, %</th>
                    <th colSpan={2}></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderTensileTestingResultsMeasurementUncertaintyTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row" colSpan={2}>
                        <Label size="sm" className="float-right">{t('MeasurementUncertainty')}</Label>
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name="tensileTestingResultsMeasurementUncertaintyUFm" id="tensileTestingResultsMeasurementUncertaintyUFm"
                            value={this.props.tensileTestingResults.measurementUncertaintyUFm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsMeasurementUncertaintyUFm(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="tensileTestingResultsMeasurementUncertaintyURm" id="tensileTestingResultsMeasurementUncertaintyURm"
                            value={this.props.tensileTestingResults.measurementUncertaintyURm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsMeasurementUncertaintyURm(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="tensileTestingResultsMeasurementUncertaintyUR" id="tensileTestingResultsMeasurementUncertaintyUR"
                            value={this.props.tensileTestingResults.measurementUncertaintyUR} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsMeasurementUncertaintyUR(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="tensileTestingResultsMeasurementUncertaintyUA" id="tensileTestingResultsMeasurementUncertaintyUA"
                            value={this.props.tensileTestingResults.measurementUncertaintyUA} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsMeasurementUncertaintyUA(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="tensileTestingResultsMeasurementUncertaintyUZ" id="tensileTestingResultsMeasurementUncertaintyUZ"
                            value={this.props.tensileTestingResults.measurementUncertaintyUZ} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsMeasurementUncertaintyUZ(event)}
                            placeholder="" />
                    </td>
                    <td colSpan={2}>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderTensileTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderTensileTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.tensileTestingResults.tensileTestingResultsTableRows.map((row, index) => (
                            this.renderTensileTestingResultsTableRow(index + 1)
                        ))}
                        {this.renderTensileTestingResultsMeasurementUncertaintyTableHeaderRow()}
                        {this.renderTensileTestingResultsMeasurementUncertaintyTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderTensileTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap">{t('SpecimenDimensions')}</th>
                    <th className="text-nowrap">Fm, N</th>
                    <th className="text-nowrap">Rm, MPa</th>
                    <th className="text-nowrap">R, MPa</th>
                    <th className="text-nowrap">A, %</th>
                    <th className="text-nowrap">Z, %</th>
                    <th className="text-nowrap">{t('FractureSurfaceCondition')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderTensileTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"tensileTestingResultsTestSpecimenNumber-" + rowNumber} id={"tensileTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"tensileTestingResultsTestSpecimenDimensions-" + rowNumber} id={"tensileTestingResultsTestSpecimenDimensions-" + rowNumber}
                            value={this.props.tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].testSpecimenDimensions}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsTestSpecimenDimensions(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"tensileTestingResultsFm-" + rowNumber} id={"tensileTestingResultsFm-" + rowNumber}
                            value={this.props.tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].fm}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsFm(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"tensileTestingResultsRm-" + rowNumber} id={"tensileTestingResultsRm-" + rowNumber}
                            value={this.props.tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].rm}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsRm(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"tensileTestingResultsR-" + rowNumber} id={"tensileTestingResultsR-" + rowNumber}
                            value={this.props.tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].r}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsR(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"tensileTestingResultsA-" + rowNumber} id={"tensileTestingResultsA-" + rowNumber}
                            value={this.props.tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].a}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsA(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"tensileTestingResultsZ-" + rowNumber} id={"tensileTestingResultsZ-" + rowNumber}
                            value={this.props.tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].z}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsZ(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"tensileTestingResultsFractureSurfaceCondition-" + rowNumber} id={"tensileTestingResultsFractureSurfaceCondition-" + rowNumber}
                            value={this.props.tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].fractureSurfaceCondition}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsFractureSurfaceCondition(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"tensileTestingResultsAcceptance-" + rowNumber} id={"tensileTestingResultsAcceptance-" + rowNumber}
                            value={this.props.tensileTestingResults.tensileTestingResultsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTensileTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderTensileTestingResultsInfoText() {
        return (
            <React.Fragment>
                <FormText color="muted">
                    {t('TheReportedExpandedUncertainty_')}
                </FormText>
            </React.Fragment>
        );
    }

    private renderTestResults() {
        return (
            <React.Fragment>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader>
                                    <Nav pills>
                                        <NavItem>
                                        <NavLink active={this.props.selectedTestResultsTabId === 'wpqtReport'} href="#" id="wpqtReport" onClick={(event) => this.onClickTestResultsNavItem(event)}>{t('WPQTReport')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink active={this.props.selectedTestResultsTabId === 'nonDestructiveTesting'} href="#" id="nonDestructiveTesting" onClick={(event) => this.onClickTestResultsNavItem(event)}>{t('IndestructiveTesting')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink active={this.props.selectedTestResultsTabId === 'tensileTest'} href="#" id="tensileTest" onClick={(event) => this.onClickTestResultsNavItem(event)}>{t('TensileTest')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink active={this.props.selectedTestResultsTabId === 'bendTesting'} href="#" id="bendTesting" onClick={(event) => this.onClickTestResultsNavItem(event)}>{t('BendTest')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink active={this.props.selectedTestResultsTabId === 'impactTesting'} href="#" id="impactTesting" onClick={(event) => this.onClickTestResultsNavItem(event)}>{t('ImpactTesting')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink active={this.props.selectedTestResultsTabId === 'hardnessTesting'} href="#" id="hardnessTesting" onClick={(event) => this.onClickTestResultsNavItem(event)}>{t('HardnessTesting')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink active={this.props.selectedTestResultsTabId === 'macroTestingMicroTesting'} href="#" id="macroTestingMicroTesting" onClick={(event) => this.onClickTestResultsNavItem(event)}>{t('MacroMicroTesting')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink active={this.props.selectedTestResultsTabId === 'ferriteTestingChemicalTesting'} href="#" id="ferriteTestingChemicalTesting" onClick={(event) => this.onClickTestResultsNavItem(event)}>{t('FerriteChemicalTesting')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink active={this.props.selectedTestResultsTabId === 'fractureTestingCorrosionTesting'} href="#" id="fractureTestingCorrosionTesting" onClick={(event) => this.onClickTestResultsNavItem(event)}>{t('FractureCorrosionTesting')}</NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <div hidden={this.props.selectedTestResultsTabId !== 'wpqtReport'}>
                                        {this.renderWPQTReport()}
                                    </div>
                                    <div hidden={this.props.selectedTestResultsTabId !== 'nonDestructiveTesting'}>
                                        {this.renderNonDestructiveTesting()}
                                    </div>
                                    <div hidden={this.props.selectedTestResultsTabId !== 'tensileTest'}>
                                        {this.renderTensileTesting()}
                                    </div>
                                    <div hidden={this.props.selectedTestResultsTabId !== 'bendTesting'}>
                                        {this.renderBendTesting()}
                                    </div>
                                    <div hidden={this.props.selectedTestResultsTabId !== 'impactTesting'}>
                                        {this.renderImpactTesting()}
                                    </div>
                                    <div hidden={this.props.selectedTestResultsTabId !== 'hardnessTesting'}>
                                        {this.renderHardnessTesting()}
                                    </div>
                                    <div hidden={this.props.selectedTestResultsTabId !== 'macroTestingMicroTesting'}>
                                        {this.renderMacroTestingAndMicroTesting()}
                                    </div>
                                    <div hidden={this.props.selectedTestResultsTabId !== 'ferriteTestingChemicalTesting'}>
                                        {this.renderFerriteTestingAndChemicalTesting()}
                                    </div>
                                    <div hidden={this.props.selectedTestResultsTabId !== 'fractureTestingCorrosionTesting'}>
                                        {this.renderFractureTestingAndCorrosionTesting()}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsMeasurementUncertaintyTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th colSpan={2}></th>
                    <th className="text-nowrap">U Fm, N</th>
                    <th className="text-nowrap">U Rm, MPa</th>
                    <th className="text-nowrap">U R, MPa</th>
                    <th className="text-nowrap">U, %</th>
                    <th colSpan={2}></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsMeasurementUncertaintyTableRow() {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row" colSpan={2}>
                        <Label size="sm" className="float-right">{t('MeasurementUncertainty')}</Label>
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name="transverseTensileTestingResultsMeasurementUncertaintyUFm" id="transverseTensileTestingResultsMeasurementUncertaintyUFm"
                            value={this.props.transverseTensileTestingResults.measurementUncertaintyUFm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsMeasurementUncertaintyUFm(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="transverseTensileTestingResultsMeasurementUncertaintyURm" id="transverseTensileTestingResultsMeasurementUncertaintyURm"
                            value={this.props.transverseTensileTestingResults.measurementUncertaintyURm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsMeasurementUncertaintyURm(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="transverseTensileTestingResultsMeasurementUncertaintyUR" id="transverseTensileTestingResultsMeasurementUncertaintyUR"
                            value={this.props.transverseTensileTestingResults.measurementUncertaintyUR} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTranverseTensileTestingResultsMeasurementUncertaintyUR(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name="transverseTensileTestingResultsMeasurementUncertaintyUA" id="transverseTensileTestingResultsMeasurementUncertaintyUA"
                            value={this.props.transverseTensileTestingResults.measurementUncertaintyUA} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsMeasurementUncertaintyU(event)}
                            placeholder="" />
                    </td>
                    <td colSpan={3}>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderTransverseTensileTestingResultsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows.map((row, index) => (
                            this.renderTransverseTensileTestingResultsTableRow(index + 1)
                        ))}
                        {this.renderTransverseTensileTestingResultsMeasurementUncertaintyTableHeaderRow()}
                        {this.renderTransverseTensileTestingResultsMeasurementUncertaintyTableRow()}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TestSpecimen')} {t('NumberShort')}</th>
                    <th className="text-nowrap">{t('TestSpecimenDimensions')}</th>
                    <th className="text-nowrap">Fm, N</th>
                    <th className="text-nowrap">Rm, MPa</th>
                    <th className="text-nowrap">R, MPa</th>
                    <th className="text-nowrap">A, %</th>
                    <th className="text-nowrap">{t('PlaceOfBreak')}</th>
                    <th className="text-nowrap">{t('FractureSurfaceCondition')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <Input type="text" bsSize="sm" name={"transverseTensileTestingResultsTestSpecimenNumber-" + rowNumber} id={"transverseTensileTestingResultsTestSpecimenNumber-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].testSpecimenNumber}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsTestSpecimenNumber(rowNumber, event)}
                            placeholder="" />
                    </th>
                    <td>
                        <Input type="text" bsSize="sm" name={"transverseTensileTestingResultsTestSpecimenDimensions-" + rowNumber} id={"transverseTensileTestingResultsTestSpecimenDimensions-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].testSpecimenDimensions}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsTestSpecimenDimensions(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"transverseTensileTestingResultsFm-" + rowNumber} id={"transverseTensileTestingResultsFm-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].fm}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsFm(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"transverseTensileTestingResultsRm-" + rowNumber} id={"transverseTensileTestingResultsRm-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].rm}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsRm(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"transverseTensileTestingResultsR-" + rowNumber} id={"transverseTensileTestingResultsR-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].r}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsR(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"transverseTensileTestingResultsA-" + rowNumber} id={"transverseTensileTestingResultsA-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].a}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsA(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"transverseTensileTestingResultsPlaceOfBreak-" + rowNumber} id={"transverseTensileTestingResultsPlaceOfBreak-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].placeOfBreak}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsPlaceOfBreak(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"transverseTensileTestingResultsFractureSurfaceCondition-" + rowNumber} id={"transverseTensileTestingResultsFractureSurfaceCondition-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].fractureSurfaceCondition}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsFractureSurfaceCondition(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"transverseTensileTestingResultsAcceptance-" + rowNumber} id={"transverseTensileTestingResultsAcceptance-" + rowNumber}
                            value={this.props.transverseTensileTestingResults.transverseTensileTestingResultsTabelRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeTransverseTensileTestingResultsAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderTransverseTensileTestingResultsInfoText() {
        return (
            <React.Fragment>
                <FormText color="muted">
                    {t('TheReportedExpandedUncertainty_')}
                </FormText>
            </React.Fragment>
        );
    }

    private renderWeldingProtocol() {
        return (
            <React.Fragment>
                <Form>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={1}>
                                                <FormGroup>
                                                    <Label for="wpqrPageHeaderDekraProcedureNumber" size="sm">{t('DekraProcedureNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqrPageHeaderDekraProcedureNumber" id="wpqrPageHeaderDekraProcedureNumber"
                                                        value={this.props.weldingProtocol.dekraProcedureNumberDisplay} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeDekraProcedureNumberDisplay(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="testingStandardId" size="sm">{t('Standard')}</Label>
                                                    <Input type="select" bssize="sm" name="testingStandardId" id="testingStandardId"
                                                        value={this.props.weldingProtocol.standardId}
                                                        onChange={(event) => this.onChangeTestingStandards(event)}
                                                        placeholder="">
                                                        <option key="btestingStandardId0" id="testingStandardId0" value="0"></option>
                                                        {this.props.weldingProtocol.standards.map((standard, index) => (
                                                            <option key={"testingStandardId" + standard.id} id={"testingStandardId" + standard.id} value={standard.id}>{standard.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="wpqrPageHeadermarkingOfTheTest" size="sm">{t('TestMarking')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqrPageHeadermarkingOfTheTest" id="wpqrPageHeadermarkingOfTheTest"
                                                        value={this.props.weldingProtocol.markingOfTheTest} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangemarkingOfTheTest(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="wpqrPageHeaderpWPSNumber" size="sm">{t('pWPSNumber')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqrPageHeaderpWPSNumber" id="wpqrPageHeaderpWPSNumber"
                                                        value={this.props.weldingProtocol.pWPSNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangepWPSNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="wpqrPageHeaderCommissionNumber" size="sm">{t('CommissionNumber')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqrPageHeaderCommissionNumber" id="wpqrPageHeaderCommissionNumber"
                                                        value={this.props.weldingProtocol.assignmentNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCommissionNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="wpqrPageHeaderManufacturerWPQRNumber" size="sm">{t('ManufacturersWPQRNo')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqrPageHeaderManufacturerWPQRNumber" id="wpqrPageHeaderManufacturerWPQRNumber"
                                                        value={this.props.weldingProtocol.manufacturerWPQRNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeManufacturerWPQRNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <FormGroup>
                                                    <Label for="wpqrPageHeaderPageNumber" size="sm">{t('Page')}</Label>
                                                    <Input type="text" readOnly bsSize="sm" name="wpqrPageHeaderPageNumber" id="rangeOfQualificationPageNumber"
                                                        value={"1/"}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolCompanyName" size="sm">{t('Company')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolCompanyName" id="weldingProtocolCompanyName"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.company2.name}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolCompanyName(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolCompanyContactPerson" size="sm">{t('Facilitator')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolCompanyContactPerson" id="weldingProtocolCompanyContactPerson"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.company2.contactPerson}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolCompanyContactPerson(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolCompanyStreetAddress" size="sm">{t('StreetAddress')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolCompanyStreetAddress" id="weldingProtocolCompanyStreetAddress"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.company2.streetAddress}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolCompanyStreetAddress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolCompanyContactPersonEmail" size="sm">{t('FacilitatorEmail')}</Label>
                                                    <Input type="email" bsSize="sm" name="weldingProtocolCompanyContactPersonEmail" id="weldingProtocolCompanyContactPersonEmail"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.company2.contactPersonEmail}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolCompanyContactPersonEmailAddress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolCompanyBoxAddress" size="sm">{t('BoxAddress')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolCompanyBoxAddress" id="weldingProtocolCompanyBoxAddress"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.company2.boxAddress}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolCompanyBoxAddress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolCompanyPhoneNumber" size="sm">{t('PhoneNumber')}</Label>
                                                    <Input type="tel" bsSize="sm" name="weldingProtocolCompanyPhoneNumber" id="weldingProtocolCompanyPhoneNumber"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.company2.phone}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolCompanyPhoneNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolCompanyPostcode" size="sm">{t('Zipcode')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolCompanyPostcode" id="weldingProtocolCompanyPostcode"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.company2.postcode}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolCompanyPostcode(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolCompanyCity" size="sm">{t('PostCity')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolCompanyCity" id="weldingProtocolCompanyCity"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.company2.city}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolCompanyCity(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWelderFirstName" size="sm">{t('FirstName')} {t('Welder')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolWelderFirstName" id="weldingProtocolWelderFirstName"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.welder.firstName}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolWelderFirstName(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWelderLastName" size="sm">{t('LastName')} {t('Welder')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolWelderLastName" id="weldingProtocolWelderLastName"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.welder.lastName}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolWelderLastName(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWelderDateOfBirth" size="sm">{t('DateOfBirth')} {t('Welder')}</Label>
                                                    <Input type="date" max="9999-12-30" bsSize="sm" name="weldingProtocolWelderDateOfBirth" id="weldingProtocolWelderDateOfBirth"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.welder.dateOfBirth}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolWelderDateOfBirth(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWelderPlaceOfBirth" size="sm">{t('PlaceOfBirth')} {t('Welder')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolWelderPlaceOfBirth" id="weldingProtocolWelderPlaceOfBirth"
                                                        value={this.props.weldingProtocol.weldingProtocolSection1.welder.placeOfBirth}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolWelderPlaceOfBirth(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolJointTypes" size="sm">{t('JointType')}</Label><Message modalId={'modalId_1'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolJointTypes')} initMessages={this.props.initMessagesInWeldingProtocolResult}  applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolJointTypes' />
                                                    <Input type="select" bsSize="sm" name="weldingProtocolJointTypes" id="weldingProtocolJointTypes"
                                                        value={this.props.weldingProtocol.weldingProtocolSection2.selectedJointType.id}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolJointTypes(event)}
                                                        placeholder="">
                                                        <option id="jointTypeId0" value="0"></option>
                                                        {this.props.weldingProtocol.weldingProtocolSection2.jointTypes.map((jointType, index) => (
                                                            <option id={"jointTypeId" + jointType.id} value={jointType.id}>{jointType.description}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWeldingMethod1" size="sm">{t('WeldingMethod')} 1</Label><Message modalId={'modalId_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolWeldingMethod')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolWeldingMethod'/>
                                                    <Input autocomplete="off" type="search" bsSize="sm" name="weldingProtocolWeldingMethod1" id="weldingProtocolWeldingMethod1"
                                                        value={this.props.weldingProtocol.weldingProtocolSection2.weldingMethod1SearchText}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolWeldingMethod1SearchText(event)}
                                                        placeholder="" />
                                                    {this.props.weldingProtocol.weldingProtocolSection2.weldingMethod1SearchResult.length !== 0 &&
                                                        <ListGroup className="scroll-vertical">
                                                            {this.props.weldingProtocol.weldingProtocolSection2.weldingMethod1SearchResult.map((weldingMethod, index) => (
                                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProtocolSelectWeldingMethodA(event, weldingMethod)}>
                                                                    {weldingMethod.name}
                                                                </ListGroupItem>
                                                            ))}
                                                        </ListGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col sm={ this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 3 : 2 }>
                                                    <FormGroup>
                                                    <Label for="weldingProtocolMakesA" size="sm">{t('AdditivesPowder')} 1</Label><Message modalId={'modalId_3'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMakes')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMakes' />
                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        <Label size="sm">
                                                            <Input type="checkbox" bssize="sm" name="wpqrDekraEnableMakeAText" id="wpqrDekraEnableMakeAText"
                                                                checked={this.props.weldingProtocol.weldingProtocolSection2.enableMakeAText}
                                                                onChange={(event) => this.onChangeEnableMakeAText(event)}
                                                            placeholder="" />{''} <small>{t('Revise')}</small>
                                                            <UncontrolledTooltip placement="right" target={"wpqrDekraEnableMakeAText"} >
                                                                {t('ReviseOrEnterYourOwnChoice')}!
                                                                </UncontrolledTooltip>
                                                        </Label>
                                                    </FormGroup>
                                                    {this.props.weldingProtocol.weldingProtocolSection2.enableMakeAText ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-make-text"
                                                                name="wpqrDekraBaseMaterial1Text" id="wpqrDekraBaseMaterial1Text"
                                                                value={this.props.weldingProtocol.weldingProtocolSection2.makeAText}
                                                                onChange={(event) => this.onChangeMakeAText(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Input autocomplete="off" type="search" bssize="sm" name="weldingProtocolMakesA" id="weldingProtocolMakesA"
                                                                value={this.props.weldingProtocol.weldingProtocolSection2.make1SearchText}
                                                                onChange={(event) => this.onChangeWeldingProtocolMakeASearchText(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                            {this.props.weldingProtocol.weldingProtocolSection2.make1SearchResult.length !== 0 &&
                                                                <ListGroup className="scroll-vertical">
                                                                    {this.props.weldingProtocol.weldingProtocolSection2.make1SearchResult.map((make, index) => (
                                                                        <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProtocolSelectMakeA(event, make)}>
                                                                            {make.name}
                                                                        </ListGroupItem>
                                                                    ))}
                                                                </ListGroup>
                                                            }
                                                        </FormGroup>
                                                    }
                                            </Col>
                                            <Col sm={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 3 : 2}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolDesignationA" size="sm">{t('AdditivesPowder')}, {t('designation')}</Label>
                                                    {this.props.weldingProtocol.weldingProtocolSection2.enableMakeAText ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-make-text"
                                                                name="applicationDesignationAText" id="applicationDesignationAText"
                                                                value={this.props.weldingProtocol.weldingProtocolSection2.designationAText}
                                                                onChange={(event) => this.onChangeDesignationAText(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Input type="text" readOnly={true} bsSize="sm" name="weldingProtocolDesignationA" id="weldingProtocolDesignationA"
                                                                value={this.props.weldingProtocol.weldingProtocolSection2.selectedDesignation1.name} onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolLotNumberOrHeatNumberA" size="sm">{t('AdditivesPowder')} 1, {t('lotOrHeatNumber')}</Label><Message modalId={'modalId_4'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolLotNumberOrHeatNumber')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolLotNumberOrHeatNumber'/>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolLotNumberOrHeatNumberA" id="weldingProtocolLotNumberOrHeatNumberA"
                                                        value={this.props.weldingProtocol.weldingProtocolSection2.lotNumberOrHeatNumberA}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolLotNumberOrHeatNumberA(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2} hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                                <FormGroup>
                                                    <Label for="weldingProcessArcTypesA" size="sm">{t('ArcType')} (MIG) (MAG)</Label>
                                                    <Input type="select" bsSize="sm" name="weldingProcessArcTypesA" id="weldingProcessArcTypesA"
                                                        value={this.props.weldingProtocol.weldingProtocolSection2.selectedArcTypeA.id}
                                                        onChange={(event) => this.onChangeArcTypeASearchText(event)}
                                                        placeholder="">
                                                        <option key="arcTypeId0" id="arcTypeId0" value="0"></option>
                                                        {this.props.weldingProtocol.weldingProtocolSection2.arcTypeSearchResult.map((arcType) => (
                                                            <option key={"arcTypeId" + arcType.id} id={"arcTypeId" + arcType.id} value={arcType.id}>{arcType.name} </option>
                                                        ))}
                                                    </Input>
                                                    <small className="text-muted">Index A</small>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={1}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWeldingPosition" size="sm">{t('WeldingPosition')} 1</Label><Message modalId={'modalId_26'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolWeldingPosition')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolWeldingPosition'/>
                                                    <Input type="select" bsSize="sm" name="weldingProtocolWeldingPosition" id="weldingProtocolWeldingPosition"
                                                        value={this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingPosition.id}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolWeldingPositionSearchText(event)}
                                                        placeholder="">
                                                        <option key="weldingPositionId0" id="weldingPositionId0" value="0"></option>
                                                        {this.props.weldingProtocol.weldingProtocolSection2.weldingPositionSearchResult.map((weldingPosition) => (
                                                            <option key={"weldingPositionId" + weldingPosition.id} id={"weldingPositionId" + weldingPosition.id} value={weldingPosition.id}>{weldingPosition.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWeldingPosition2" size="sm">{t('WeldingPosition')} 2</Label><Message modalId={'modalId_26_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolWeldingPosition2')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolWeldingPosition2' />
                                                    <Input type="select" bsSize="sm" name="weldingProtocolWeldingPosition2" id="weldingProtocolWeldingPosition2"
                                                        value={this.props.weldingProtocol.weldingProtocolSection2.selectedWeldingPosition2.id}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolWeldingPosition2SearchText(event)}
                                                        placeholder="">
                                                        <option key="weldingPositionId0" id="weldingPositionId0" value="0"></option>
                                                        {this.props.weldingProtocol.weldingProtocolSection2.weldingPositionSearchResult.map((weldingPosition2) => (
                                                            <option key={"weldingPosition2Id" + weldingPosition2.id} id={"weldingPosition2Id" + weldingPosition2.id} value={weldingPosition2.id}>{weldingPosition2.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWeldingMethod2" size="sm">{t('WeldingMethod')} 2</Label><Message modalId={'modalId_27'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolWeldingMethod')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolWeldingMethod' />
                                                    <Input autocomplete="off" type="search" disabled={this.props.selectedApplication && this.props.selectedApplication.otherOptions[0] && this.props.selectedApplication.otherOptions[0].id !== OtherOptions.JointWithMultipleWeldingMethods} bsSize="sm" name="weldingProtocolWeldingMethod2" id="weldingProtocolWeldingMethod2"
                                                        value={this.props.weldingProtocol.weldingProtocolSection2.weldingMethod2SearchText}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolWeldingMethod2SearchText(event)}
                                                        placeholder="" />
                                                    {this.props.weldingProtocol.weldingProtocolSection2.weldingMethod2SearchResult.length !== 0 &&
                                                        <ListGroup className="scroll-vertical">
                                                            {this.props.weldingProtocol.weldingProtocolSection2.weldingMethod2SearchResult.map((weldingMethod, index) => (
                                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProtocolSelectWeldingMethodB(event, weldingMethod)}>
                                                                    {weldingMethod.name}
                                                                </ListGroupItem>
                                                            ))}
                                                        </ListGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col sm={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 3 : 2}>
                                                <FormGroup>
                                                    <FormGroup>
                                                        <Label for="weldingProtocolMakesB" size="sm">{t('AdditivesPowder')} 2</Label><Message modalId={'modalId_28'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMakes')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMakes' />
                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                            <Label size="sm">
                                                                <Input type="checkbox" bssize="sm" name="weldingProtocolEnableMakeBText" id="weldingProtocolEnableMakeBText"
                                                                    checked={this.props.weldingProtocol.weldingProtocolSection2.enableMakeBText}
                                                                onChange={(event) => this.onChangeEnableMakeBText(event)}
                                                                placeholder="" />{''} <small>{t('Revise')}</small>
                                                                <UncontrolledTooltip placement="right" target={"weldingProtocolEnableMakeBText"} >
                                                                            {t('ReviseOrEnterYourOwnChoice')}!
                                                                </UncontrolledTooltip>
                                                            </Label>
                                                    </FormGroup>
                                                    {this.props.weldingProtocol.weldingProtocolSection2.enableMakeBText ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-make-text"
                                                                name="weldingProtocolBaseMaterial1Text" id="weldingProtocolBaseMaterial1Text"
                                                                value={this.props.weldingProtocol.weldingProtocolSection2.makeBText}
                                                                onChange={(event) => this.onChangeMakeBText(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Input autocomplete="off" type="search" bssize="sm" name="weldingProtocolMakesB" id="weldingProtocolMakesB"
                                                                value={this.props.weldingProtocol.weldingProtocolSection2.make2SearchText}
                                                                onChange={(event) => this.onChangeWeldingProtocolMakeBSearchText(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                            {this.props.weldingProtocol.weldingProtocolSection2.make2SearchResult.length !== 0 &&
                                                                <ListGroup className="scroll-vertical">
                                                                    {this.props.weldingProtocol.weldingProtocolSection2.make2SearchResult.map((make, index) => (
                                                                        <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProtocolSelectMakeB(event, make)}>
                                                                            {make.name}
                                                                        </ListGroupItem>
                                                                    ))}
                                                                </ListGroup>
                                                            }
                                                        </FormGroup>
                                                    }
                                                    
                                                </FormGroup>
                                            </Col>
                                            <Col sm={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 3 : 2}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolDesignationB" size="sm">{t('AdditivesPowder')} 2, {t('designation')}</Label>
                                                    {this.props.weldingProtocol.weldingProtocolSection2.enableMakeBText ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-make-text"
                                                                name="weldingProtocolDesignationBText" id="weldingProtocolDesignationBText"
                                                                value={this.props.weldingProtocol.weldingProtocolSection2.designationBText}
                                                                onChange={(event) => this.onChangeDesignationBText(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Input type="text" readOnly={true} bsSize="sm" name="weldingProtocolDesignationB" id="weldingProtocolDesignationB"
                                                                value={this.props.weldingProtocol.weldingProtocolSection2.selectedDesignation2.name} onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup>
                                                    }
                                                    
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolLotNumberOrHeatNumberB" size="sm">{t('AdditivesPowder')} 2, {t('lotOrHeatNumber')}</Label><Message modalId={'modalId_29'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolLotNumberOrHeatNumber')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolLotNumberOrHeatNumber' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolLotNumberOrHeatNumberB" id="weldingProtocolLotNumberOrHeatNumberB"
                                                        value={this.props.weldingProtocol.weldingProtocolSection2.lotNumberOrHeatNumberB}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolLotNumberOrHeatNumberB(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            
                                            <Col sm={2} hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                                <FormGroup>
                                                    <Label for="weldingProcessArcTypesB" size="sm">{t('ArcType')} (MIG, MAG)</Label>
                                                    <Input type="select" bsSize="sm" name="weldingProcessArcTypesB" id="weldingProcessArcTypesB"
                                                        value={this.props.weldingProtocol.weldingProtocolSection2.selectedArcTypeB.id}
                                                        onChange={(event) => this.onChangeArcTypeBSearchText(event)}
                                                        placeholder="">
                                                        <option key="arcTypeBId0" id="arcTypeBId0" value="0"></option>
                                                        {this.props.weldingProtocol.weldingProtocolSection2.arcTypeSearchResult.map((arcType) => (
                                                            <option key={"arcTypeId" + arcType.id} id={"arcTypeId" + arcType.id} value={arcType.id}>{arcType.name} </option>
                                                        ))}
                                                    </Input>
                                                    <small className="text-muted">Index B</small>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <FormGroup>
                                                        <Label for="weldingProtocolBaseMaterials1" size="sm">{t('SpecificationForBaseMaterial')} 1</Label><Message modalId={'modalId_6'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolBaseMaterials')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolBaseMaterials' />
                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        <Label size="sm">
                                                            <Input type="checkbox" bssize="sm" name="applicationEnableBaseMaterial1Text" id="applicationEnableBaseMaterial1Text"
                                                                checked={this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial1Text}
                                                                onChange={(event) => this.onChangeEnableBaseMaterial1Text(event)}
                                                                placeholder="" />{''} <small>{t('Revise')}</small>
                                                            <UncontrolledTooltip placement="right" target={"applicationEnableBaseMaterial1Text"} >
                                                                {t('ReviseOrEnterYourOwnChoice')}!
                                                                </UncontrolledTooltip>
                                                        </Label>
                                                    </FormGroup>
                                                    {this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial1Text ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-base-material-text"
                                                                name="applicationBaseMaterial1Text" id="applicationBaseMaterial1Text"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.baseMaterial1Text}
                                                                onChange={(event) => this.onChangeParentMaterialBaseMaterial1Text(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Input autocomplete="off" type="search" bsSize="sm" name="weldingProtocolBaseMaterials1" id="weldingProtocolBaseMaterials1"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.baseMaterial1SearchText}
                                                                onChange={(event) => this.onChangeWeldingProtocolBaseMaterial1SearchText(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                            {this.props.weldingProtocol.weldingProtocolSection3.baseMaterial1SearchResult.length !== 0 &&
                                                                <ListGroup className="scroll-vertical">
                                                                    {this.props.weldingProtocol.weldingProtocolSection3.baseMaterial1SearchResult.map((baseMaterial, index) => (
                                                                        <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProtocolSelectBaseMaterial1(event, baseMaterial)}>
                                                                            {baseMaterial.name + (baseMaterial.number === "" ? "" : "/" + baseMaterial.number)}
                                                                        </ListGroupItem>
                                                                    ))}
                                                                </ListGroup>
                                                            }
                                                        </FormGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolMaterialGroup1" size="sm">{t('MaterialGroup')} {t('baseMaterial')} 1</Label><Message modalId={'modalId_7'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMaterialGroup')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMaterialGroup' />
                                                    {this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial1Text ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-base-material-text"
                                                                name="applicationMaterialGroup1Text" id="applicationMaterialGroup1Text"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.materialGroup1Text}
                                                                onChange={(event) => this.onChangeParentMaterialMaterialGroup1Text(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Input type="text" readOnly bsSize="sm" name="weldingProtocolMaterialGroup1" id="weldingProtocolMaterialGroup1"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.selectedMaterialGroup1.name}
                                                                placeholder="" />
                                                        </FormGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolChargeAltHeatNumber1" size="sm">{t('ChargeOrHeatNumber')} 1</Label><Message modalId={'modalId_8'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolChargeAltHeatNumber')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolChargeAltHeatNumber' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolChargeAltHeatNumber1" id="weldingProtocolChargeAltHeatNumber1"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.chargeAltHeatNumber1}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolChargeAltHeatNumber1(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolTechnicalDeliveryConditions1" size="sm">{t('TechnicalDeliveryStandard')} 1</Label><Message modalId={'modalId_9'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolTechnicalDeliveryConditions')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolTechnicalDeliveryConditions' />
                                                    
                                                    {this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial1Text ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-base-material-text"
                                                                name="applicationTechnicalDeliveryCondition1Text" id="applicationTechnicalDeliveryCondition1Text"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.technicalDeliveryCondition1Text}
                                                                onChange={(event) => this.onChangeParentTechnicalDeliveryCondition1Text(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Input type="select" bsSize="sm" name="weldingProtocolTechnicalDeliveryConditions1" id="weldingProtocolTechnicalDeliveryConditions1"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.selectedTechnicalDeliveryCondition1.id}
                                                                onChange={(event) => this.onChangeWeldingProtocolTechnicalDeliveryConditions1(event)}
                                                                placeholder="">
                                                                <option id="technicalDeliveryConditionId01" value="0"></option>
                                                                {this.props.weldingProtocol.weldingProtocolSection3.selectedBaseMaterial1.technicalDeliveryConditions.map((technicalDeliveryCondition, index) => (
                                                                    <option id={"technicalDeliveryConditionId" + technicalDeliveryCondition.id + "1"} value={technicalDeliveryCondition.id}>{technicalDeliveryCondition.name}</option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolProductTypes1" size="sm">{t('ProductTypeForBaseMaterial')} 1</Label><Message modalId={'modalId_10'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolProductTypes')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolProductTypes' />
                                                    <Input type="select" bsSize="sm" name="weldingProtocolProductTypes1" id="weldingProtocolProductTypes1"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.selectedProductType1.id}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolProductTypes1(event)}
                                                        placeholder="">
                                                        <option id="productType1Id0" value="0"></option>
                                                        {this.props.weldingProtocol.weldingProtocolSection3.productTypes.map((productType, index) => (
                                                            <option id={"productType1Id" + productType.id} value={productType.id}>{productType.description}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolMaterialThickness1" size="sm">{t('ProductThickness')}, t (mm), {t('baseMaterial')} 1</Label><Message modalId={'modalId_11'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMaterialThickness')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMaterialThickness' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolMaterialThickness1" id="weldingProtocolMaterialThickness1"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.materialThickness1}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolMaterialThickness1(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolDiameterParentMaterial1" size="sm">{t('Diameter')}, D (mm), {t('baseMaterial')} 1</Label><Message modalId={'modalId_12'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolDiameterParentMaterial')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolDiameterParentMaterial' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolDiameterParentMaterial1" id="weldingProtocolDiameterParentMaterial1"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.diameter1}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolDiameterParentMaterial1(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolOtherInformation1" size="sm">{t('OtherRemarks')} 1</Label><Message modalId={'modalId_13'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolOtherInformation')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolOtherInformation' />
                                                    <Input type="textarea" bsSize="sm" name="weldingProtocolOtherInformation1" id="weldingProtocolOtherInformation1"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.otherInformation1}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolOtherInformation1(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <FormGroup>
                                                        <Label for="weldingProtocolBaseMaterials2" size="sm">{t('SpecificationForBaseMaterial')} 2</Label><Message modalId={'modalId_6_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolBaseMaterials')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolBaseMaterials' />
                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        <Label size="sm">
                                                            <Input type="checkbox" bssize="sm" name="applicationEnableBaseMaterial2Text" id="applicationEnableBaseMaterial2Text"
                                                                checked={this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial2Text}
                                                                onChange={(event) => this.onChangeEnableBaseMaterial2Text(event)}
                                                                placeholder="" />{''} <small>{t('Revise')}</small>
                                                            <UncontrolledTooltip placement="right" target={"applicationEnableBaseMaterial2Text"} >
                                                                {t('ReviseOrEnterYourOwnChoice')}!
                                                                </UncontrolledTooltip>
                                                        </Label>
                                                    </FormGroup>
                                                    {this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial2Text ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-base-material-text"
                                                                name="applicationBaseMaterial2Text" id="applicationBaseMaterial2Text"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.baseMaterial2Text}
                                                                onChange={(event) => this.onChangeParentMaterialBaseMaterial2Text(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Input autocomplete="off" type="search" bsSize="sm" name="weldingProtocolBaseMaterials2" id="weldingProtocolBaseMaterials2"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.baseMaterial2SearchText}
                                                                onChange={(event) => this.onChangeWeldingProtocolBaseMaterial2SearchText(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                            {this.props.weldingProtocol.weldingProtocolSection3.baseMaterial2SearchResult.length !== 0 &&
                                                                <ListGroup className="scroll-vertical">
                                                                    {this.props.weldingProtocol.weldingProtocolSection3.baseMaterial2SearchResult.map((baseMaterial, index) => (
                                                                        <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProtocolSelectBaseMaterial2(event, baseMaterial)}>
                                                                            {baseMaterial.name + (baseMaterial.number === "" ? "" : "/" + baseMaterial.number)}
                                                                        </ListGroupItem>
                                                                    ))}
                                                                </ListGroup>
                                                            }
                                                        </FormGroup>
                                                    }
                                                    
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolMaterialGroup2" size="sm">{t('MaterialGroup')} {t('baseMaterial')} 2</Label><Message modalId={'modalId_7_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMaterialGroup')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMaterialGroup' />
                                                    {this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial2Text ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-base-material-text"
                                                                name="applicationMaterialGroup2Text" id="applicationMaterialGroup2Text"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.materialGroup2Text}
                                                                onChange={(event) => this.onChangeParentMaterialMaterialGroup2Text(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <Input type="text" readOnly bsSize="sm" name="weldingProtocolMaterialGroup2" id="weldingProtocolMaterialGroup2"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.selectedMaterialGroup2.name}
                                                                placeholder="" />
                                                        </FormGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolChargeAltHeatNumber2" size="sm">{t('ChargeOrHeatNumber')} 2</Label><Message modalId={'modalId_8_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolChargeAltHeatNumber')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolChargeAltHeatNumber' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolChargeAltHeatNumber2" id="weldingProtocolChargeAltHeatNumber2"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.chargeAltHeatNumber2}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolChargeAltHeatNumber2(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolTechnicalDeliveryConditions2" size="sm">{t('TechnicalDeliveryStandard')} 2</Label><Message modalId={'modalId_9_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolTechnicalDeliveryConditions')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolTechnicalDeliveryConditions' />
                                                    
                                                    {this.props.weldingProtocol.weldingProtocolSection3.enableBaseMaterial2Text ?
                                                        <FormGroup>
                                                            <Input type="text" bsSize="sm"
                                                                className="enable-base-material-text"
                                                                name="applicationTechnicalDeliveryCondition2Text" id="applicationTechnicalDeliveryCondition2Text"
                                                                value={this.props.weldingProtocol.weldingProtocolSection3.technicalDeliveryCondition2Text}
                                                                onChange={(event) => this.onChangeParentTechnicalDeliveryCondition2Text(event)}
                                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                                placeholder="" />
                                                        </FormGroup> :
                                                        <Input type="select" bsSize="sm" name="weldingProtocolTechnicalDeliveryConditions2" id="weldingProtocolTechnicalDeliveryConditions2"
                                                            value={this.props.weldingProtocol.weldingProtocolSection3.selectedTechnicalDeliveryCondition2.id}
                                                            onChange={(event) => this.onChangeWeldingProtocolTechnicalDeliveryConditions2(event)}
                                                            placeholder="">
                                                            <option id="technicalDeliveryConditionId02" value="0"></option>
                                                            {this.props.weldingProtocol.weldingProtocolSection3.selectedBaseMaterial2.technicalDeliveryConditions.map((technicalDeliveryCondition, index) => (
                                                                <option id={"technicalDeliveryConditionId" + technicalDeliveryCondition.id + "2"} value={technicalDeliveryCondition.id}>{technicalDeliveryCondition.name}</option>
                                                            ))}
                                                        </Input>
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolProductTypes2" size="sm">{t('ProductTypeForBaseMaterial')} 2</Label><Message modalId={'modalId_10_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolProductTypes')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolProductTypes' />
                                                    <Input type="select" bsSize="sm" name="weldingProtocolProductTypes2" id="weldingProtocolProductTypes2"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.selectedProductType2.id}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolProductTypes2(event)}
                                                        placeholder="">
                                                        <option id="productType2Id0" value="0"></option>
                                                        {this.props.weldingProtocol.weldingProtocolSection3.productTypes.map((productType, index) => (
                                                            <option id={"productType2Id" + productType.id} value={productType.id}>{productType.description}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolMaterialThickness2" size="sm">{t('ProductThickness')}, t (mm), {t('baseMaterial')} 2</Label><Message modalId={'modalId_11_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMaterialThickness')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMaterialThickness' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolMaterialThickness2" id="weldingProtocolMaterialThickness2"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.materialThickness2}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolMaterialThickness2(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolDiameterParentMaterial2" size="sm">{t('Diameter')}, D (mm), {t('baseMaterial')} 2</Label><Message modalId={'modalId_12_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolDiameterParentMaterial')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolDiameterParentMaterial' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolDiameterParentMaterial2" id="weldingProtocolDiameterParentMaterial2"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.diameter2}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolDiameterParentMaterial2(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolOtherInformation2" size="sm">{t('OtherRemarks')} 2</Label><Message modalId={'modalId_13_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolOtherInformation')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolOtherInformation' />
                                                    <Input type="textarea" bsSize="sm" name="weldingProtocolOtherInformation2" id="weldingProtocolOtherInformation2"
                                                        value={this.props.weldingProtocol.weldingProtocolSection3.otherInformation2}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolOtherInformation2(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={ this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 3 }>
                                                <FormGroup>
                                                    <Label for="weldingProtocolShieldingGases1" size="sm">{this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t("BurnerGasType") : t('ShieldingGas') + " ," + t('method') + " 1," + t('topside')}</Label><Message modalId={'modalId_Gases1'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolShieldingGases')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolShieldingGases' />
                                                    <Input autocomplete="off" type="search" bsSize="sm" name="weldingProtocolShieldingGases1" id="weldingProtocolShieldingGases1"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.shieldingGas1SearchText}
                                                        onChange={(event) => this.onChangeWeldingProtocolShieldingGas1SearchText(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                    {this.props.weldingProtocol.weldingProtocolSection4.shieldingGas1SearchResult.length !== 0 &&
                                                        <ListGroup className="scroll-vertical">
                                                            {this.props.weldingProtocol.weldingProtocolSection4.shieldingGas1SearchResult.map((shieldingGas, index) => (
                                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProtocolSelectShieldingGas1(event, shieldingGas)}>
                                                                    {shieldingGas.name}
                                                                </ListGroupItem>
                                                            ))}
                                                        </ListGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3} hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolGasFlowMethod1TopSide" size="sm">{t('GasFlow')}, {t('method')} 1, {t('topside')} (l/min)</Label><Message modalId={'modalId_GasFlowMethodTopSide1'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolGasFlowMethodTopSide')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolGasFlowMethodTopSide' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolGasFlowMethod1TopSide" id="weldingProtocolGasFlowMethod1TopSide"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.gasFlowMethod1TopSide} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolGasFlowMethod1TopSide(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolRootProtectionGases" size="sm">{t('ShieldingGas')}, {t('method')} 1, {t('rootside')}</Label><Message modalId={'modalId_RootProtectionGases'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolRootProtectionGases')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolRootProtectionGases' />
                                                    <Input autocomplete="off" type="search" bsSize="sm" name="weldingProtocolRootProtectionGases" id="weldingProtocolRootProtectionGases"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.rootProtectionGasSearchText}
                                                        onChange={(event) => this.onChangeWeldingProtocolRootProtectionGasSearchText(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                    {this.props.weldingProtocol.weldingProtocolSection4.rootProtectionGasSearchResult.length !== 0 &&
                                                        <ListGroup className="scroll-vertical">
                                                            {this.props.weldingProtocol.weldingProtocolSection4.rootProtectionGasSearchResult.map((rootProtectionGas, index) => (
                                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProtocolSelectRootProtectionGas(event, rootProtectionGas)}>
                                                                    {rootProtectionGas.name}
                                                                </ListGroupItem>
                                                            ))}
                                                        </ListGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolGasFlowMethod1RootSide" size="sm">{t('GasFlow')}, {t('method')} 1, {t('rootside')} (l/min)</Label><Message modalId={'modalId_GasFlowMethodRootSide'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolGasFlowMethodRootSide')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolGasFlowMethodRootSide' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolGasFlowMethod1RootSide" id="weldingProtocolGasFlowMethod1RootSide"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.gasFlowMethod1RootSide} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolGasFlowMethod1RootSide(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3} hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolShieldingGases2" size="sm">{t('ShieldingGas')}, {t('method')} 2, {t('topside')}</Label><Message modalId={'modalId_Gases2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolShieldingGases')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolShieldingGases' />
                                                    <Input autocomplete="off" type="search" bsSize="sm" name="weldingProtocolShieldingGases2" id="weldingProtocolShieldingGases2"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.shieldingGas2SearchText}
                                                        onChange={(event) => this.onChangeWeldingProtocolShieldingGas2SearchText(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                    {this.props.weldingProtocol.weldingProtocolSection4.shieldingGas2SearchResult.length !== 0 &&
                                                        <ListGroup className="scroll-vertical">
                                                            {this.props.weldingProtocol.weldingProtocolSection4.shieldingGas2SearchResult.map((shieldingGas, index) => (
                                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProtocolSelectShieldingGas2(event, shieldingGas)}>
                                                                    {shieldingGas.name}
                                                                </ListGroupItem>
                                                            ))}
                                                        </ListGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3} hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolGasFlowMethod2TopSide" size="sm">{t('GasFlow')}, {t('method')} 2, {t('topside')} (l/min)</Label><Message modalId={'modalId_GasFlowMethodTopSide2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolGasFlowMethodTopSide')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolGasFlowMethodTopSide' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolGasFlowMethod2TopSide" id="weldingProtocolGasFlowMethod2TopSide"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.gasFlowMethod2TopSide} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolGasFlowMethod2TopSide(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={ this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 3 }>
                                                <FormGroup>
                                                    <Label for="weldingProtocolRootSupportDetails" size="sm">{t('RootSupportDetails')}</Label><Message modalId={'modalId_RootSupportDetails'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolRootSupportDetails')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolRootSupportDetails' />
                                                    <Input type="select" bsSize="sm" name="weldingProtocolRootSupportDetails" id="weldingProtocolRootSupportDetails"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.selectedRootSupportDetails.id}
                                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolRootSupportDetailsSearchText(event)}
                                                        placeholder="">
                                                        <option key="rootSupportDetailId0" id="rootSupportDetailId0" value="0"></option>
                                                        {this.props.weldingProtocol.weldingProtocolSection4.rootSupportDetailsSearchResult.map((rootSupportDetail) => (
                                                            <option key={"rootSupportDetailId" + rootSupportDetail.id} id={"rootSupportDetailId" + rootSupportDetail.id} value={rootSupportDetail.id}>{rootSupportDetail.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolJointPreparation" size="sm">{t('MethodForPreparationAndCleaning')}</Label><Message modalId={'modalId_JointPreparation'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolJointPreparation')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolJointPreparation' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolJointPreparation" id="weldingProtocolJointPreparation"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.jointPreparation}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolJointPreparation(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3} >
                                                <Gases pSelectedPlasmaGas={this.props.weldingProtocol.weldingProtocolSection4.selectedPlasmaGas} setSelectedPlasmaGas={this.props.setWeldingDataSelectedPlasmaGas} />
                                            </Col>
                                            <Col sm={3} >
                                                <FormGroup>
                                                    <Label for="weldingDataPlasmaflowrate" size="sm">{t('Plasmaflowrate')} (l/min)</Label>
                                                    <Input type="text" bssize="sm" name="weldingDataPlasmaflowrate" id="weldingDataPlasmaflowrate"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.plasmaflowrate}
                                                        onChange={(event) => this.onChangeWeldingDataPlasmaflowrate(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingDataElectrodeTorchAngle111" size="sm">{t('ElectrodeTorchAngle111')}</Label>
                                                    <Input type="text" bssize="sm" name="weldingDataElectrodeTorchAngle111" id="weldingDataElectrodeTorchAngle111"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.electrodeTorchAngle111}
                                                        onChange={(event) => this.onChangeWeldingDataElectrodeTorchAngle111(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolNozzleDiameter" size="sm">{this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t('NozzleSize') + " (l/min)[Gas]" : t('NozzleDiameter') + " (mm) [MIG, MAG, TIG, 15X]"}</Label><Message modalId={'modalId_NozzleDiameter'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolNozzleDiameter')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolNozzleDiameter' />
                                                    <InputGroup >
                                                    <Input type="text" bsSize="sm" name="weldingProtocolNozzleDiameter" id="weldingProtocolNozzleDiameter"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.nozzleDiameter} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolNozzleDiameter(event)}
                                                        placeholder="" />
                                                        <DropdownLists listNumber={28} dropDownListsCallback={this.props.setWeldingProtocolNozzleDiameter} rowNumber={0} ></DropdownLists>
                                                    </InputGroup >
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3} hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolNozzleDistance" size="sm">{t('ContactTubeWorkPiece')} (mm) (MIG, MAG, 15X)</Label><Message modalId={'modalId_NozzleDistance'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolNozzleDistance')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolNozzleDistance' />
                                                    <InputGroup >
                                                    <Input type="text" bsSize="sm" name="weldingProtocolNozzleDistance" id="weldingProtocolNozzleDistance"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.nozzleDistance}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolNozzleDistance(event)}
                                                        placeholder="" />
                                                        <DropdownLists listNumber={28} dropDownListsCallback={this.props.setWeldingProtocolNozzleDistance} rowNumber={0} ></DropdownLists>
                                                    </InputGroup >
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3} hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolGunAngle" size="sm">{t('TorchAngle')} (MIG, MAG, {t('mechanical')})</Label><Message modalId={'modalId_GunAngle'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolGunAngle')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolGunAngle' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolGunAngle" id="weldingProtocolGunAngle"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.gunAngle} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolGunAngle(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 3}>
                                                {this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ?
                                                    <TypeOfBacking pTypeOfBackingText={this.props.weldingProtocol.weldingProtocolSection4.typeOfBackingText} enableTypeOfBackingTextView={ false} /> :
                                                    <FormGroup>
                                                        <Label for="weldingProtocolTungstenElectrode" size="sm">{t('TungstenElectrode')}, {t('TypeDimension')} (mm) [14X, 15X]</Label><Message modalId={'modalId_TungstenElectrode'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolTungstenElectrode')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolTungstenElectrode' />
                                                        <InputGroup >
                                                        <Input type="text" bsSize="sm" name="weldingProtocolTungstenElectrode" id="weldingProtocolTungstenElectrode"
                                                            value={this.props.weldingProtocol.weldingProtocolSection4.tungstenElectrode} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolTungstenElectrode(event)}
                                                            placeholder="" />
                                                            <DropdownLists listNumber={22} dropDownListsCallback={this.props.setWeldingProtocolTungstenElectrode} rowNumber={0} ></DropdownLists>
                                                        </InputGroup >
                                                    </FormGroup>
                                                }
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolMinElevatedWorkingTemperature" size="sm">{t('MinPreHeatTemperature')} (°C)</Label><Message modalId={'modalId_MinElevatedWorkingTemperature'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMinElevatedWorkingTemperature')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMinElevatedWorkingTemperature' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolMinElevatedWorkingTemperature" id="weldingProtocolMinElevatedWorkingTemperature"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.minElevatedWorkingTemperature}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolMinElevatedWorkingTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolMaxInterpassTemperature" size="sm">{t('MaxInterpassTemperature')} (°C)</Label><Message modalId={'modalId_MaxInterpassTemperature'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMaxInterpassTemperature')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMaxInterpassTemperature' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolMaxInterpassTemperature" id="weldingProtocolMaxInterpassTemperature"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.maxInterpassTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolMaxInterpassTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolOscillationMaxRunWidth" size="sm">{t('Oscillation')} ({t('MaxWidthOfRun')}) (mm)</Label><Message modalId={'modalId_OscillationMaxRunWidth'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolOscillationMaxRunWidth')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolOscillationMaxRunWidth' />
                                                    <InputGroup>
                                                        <Input type="text" bsSize="sm" name="weldingProtocolOscillationMaxRunWidth" id="weldingProtocolOscillationMaxRunWidth"
                                                            value={this.props.weldingProtocol.weldingProtocolSection4.oscillationMaxRunWidth} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolOscillationMaxRunWidth(event)}
                                                            placeholder="" />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3} hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolPowerSourceManufacturer" size="sm">{t('ManufacturerOfPowerSource')}</Label><Message modalId={'modalId_PowerSourceManufacturer'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolPowerSourceManufacturer')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolPowerSourceManufacturer' />
                                                    <Input type="text" bsSize="sm" name="weldingProtocolPowerSourceManufacturer" id="weldingProtocolPowerSourceManufacturer"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.powerSourceManufacturer}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolPowerSourceManufacturer(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolPostWeldHeatTreatment" size="sm">{t('PostWeldHeatTreatmentAndOrAgeing')}, {t('HoldingTemperature')} (°C), {t('holdTime')} (min)</Label><Message modalId={'modalId_PostWeldHeatTreatment'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolPostWeldHeatTreatment')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolPostWeldHeatTreatment' />
                                                    <InputGroup >
                                                    <Input type="textarea" bsSize="sm" name="weldingProtocolPostWeldHeatTreatment" id="weldingProtocolPostWeldHeatTreatment"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.postWeldHeatTreatment}
                                                        onChange={(event) => this.onChangeWeldingProtocolPostWeldHeatTreatment(event)}
                                                        placeholder="" />
                                                        <DropdownLists listNumber={20} dropDownListsCallback={this.props.setWeldingProtocolPostWeldHeatTreatment} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolHeatingAndCoolingRate" size="sm">{t('HeatingAndCoolingRates')} (ºC/tim)</Label><Message modalId={'modalId_HeatingAndCoolingRate'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolHeatingAndCoolingRate')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolHeatingAndCoolingRate' />
                                                    <InputGroup >
                                                    <Input type="textarea" bsSize="sm" name="weldingProtocolHeatingAndCoolingRate" id="weldingProtocolHeatingAndCoolingRate"
                                                        value={this.props.weldingProtocol.weldingProtocolSection4.heatingAndCoolingRate}
                                                        onChange={(event) => this.onChangeWeldingProtocolHeatingAndCoolingRate(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={21} dropDownListsCallback={this.props.setWeldingProtocolHeatingAndCoolingRate} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                        <Col sm={12}>
                            <FormelForHeatInput weldingPulseParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.PulseWeldingParametersTable}
                                weldingArcParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.ArcWeldingParametersTable} />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={12}>
                            <WParameters pWeldingMethodASearchText={this.props.weldingProtocol.weldingProtocolSection2.weldingMethod1SearchText}
                                pWeldingMethodBSearchText={this.props.weldingProtocol.weldingProtocolSection2.weldingMethod2SearchText}
                                pWeldingMethods={this.props.weldingProtocol.weldingProtocolSection6.weldingMethods}
                                pWeldingParameters={this.props.weldingProtocol.weldingProtocolSection6.weldingParameters}
                                currentTypes={this.props.weldingProtocol.weldingProtocolSection6.currentTypes}
                                showWeldingPulseParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.PulseWeldingParametersTable}
                                showWeldingBaseParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.BaseWeldingParametersTable}
                                showWeldingArcParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.ArcWeldingParametersTable}
                                showWeldingGwParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}
                                componentPositionProtocol={true}
                                setWParametersView={false} />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <Card>
                                                    <CardBody>
                                                        <CardText>
                                                            <Row form>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <Label for="weldingProtocolJointDesign" size="sm">{t('JointDesign')}</Label>
                                                                        <DrawJointDesign imageValidationError={this.props.weldingProtocol.imageValidationError1}
                                                                            imageUpload={this.props.weldingProtocol.imageUpload1}
                                                                            modalId={6} showJointDesignModalId={this.state.showJointDesignModalId}
                                                                            show={this.state.showJointDesignModal}
                                                                            toggleShow={this.toggleShowJointDesignModal}
                                                                            position={"weldingProcess"}
                                                                            jointDesign={this.props.weldingProtocol.weldingProtocolSection7.jointDesign}
                                                                            setDrawJointDesignView={false} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col sm={12}>
                                                                    <br />
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col sm={12} className="text-center">
                                                                    <FormGroup>
                                                                        <Button color="primary" onClick={(event) => this.toggleShowJointDesignModal1(6)}>{t('Draw')}</Button><span>&nbsp;</span>
                                                                        <Button className="btn btn-primary btn-file" >
                                                                            {t('Upload')}<input type="file" onChange={event => this.handleChangeDraJointDesign(event)} />
                                                                        </Button>
                                                                        <Button type="button" color="link" size="sm" id={"UploadInfoJd"} name={"UploadInfoJd"} >
                                                                            <i className="fas fa-info-circle"></i>
                                                                        </Button>
                                                                        <span>&nbsp;</span>
                                                                        <UncontrolledPopover trigger="focus" placement="bottom" target={"UploadInfoJd"}>
                                                                            <PopoverHeader>{t('Upload')}</PopoverHeader>
                                                                            <PopoverBody>{t('UploadInfo')}</PopoverBody>
                                                                        </UncontrolledPopover>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardText>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col sm={6}>
                                                <Card>
                                                    <CardBody>
                                                        <CardText>
                                                                <Row form>
                                                                    <Col sm={12}>
                                                                        <FormGroup>
                                                                        <Label for="weldingProtocolWeldSequence" size="sm">Svetsföljder</Label>
                                                                        <WeldSequence
                                                                            imageValidationError={this.props.weldingProtocol.imageValidationError2}
                                                                            imageUpload={this.props.weldingProtocol.imageUpload2}
                                                                            show={this.state.showWeldSequenceModal}
                                                                            toggleShow={this.toggleWeldSequenceModal}
                                                                            weldSequence={this.props.weldingProtocol.weldingProtocolSection7.weldSequence}
                                                                            pSetWeldSequenceView={false} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row form>
                                                                    <Col sm={12}>
                                                                        <br />
                                                                    </Col>
                                                                </Row>
                                                                <Row form>
                                                                <Col sm={12} className="text-center">
                                                                    <FormGroup>
                                                                        <Button color="primary" onClick={(event) => this.onClickToggleWeldSequenceModal(event)}>{t('Draw')}</Button><span>&nbsp;</span>
                                                                        <Button className="btn btn-primary btn-file" >
                                                                            {t('Upload')}<input type="file" onChange={event => this.handleChange(event)} />
                                                                        </Button>
                                                                        <Button type="button" color="link" size="sm" id={"UploadInfo"} name={"UploadInfo"} >
                                                                            <i className="fas fa-info-circle"></i>
                                                                        </Button>
                                                                        <span>&nbsp;</span>
                                                                        <UncontrolledPopover trigger="focus" placement="bottom" target={"UploadInfo"}>
                                                                            <PopoverHeader>{t('Upload')}</PopoverHeader>
                                                                            <PopoverBody>{t('UploadInfo')}</PopoverBody>
                                                                        </UncontrolledPopover>
                                                                    </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                        </CardText>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingDataTackWelds" size="sm">{t('ProcessTackWelds')}</Label><Message modalId={'modalId_weldingDataTackWeld'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingDataTackWeld')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingDataTackWeld' />
                                                    <InputGroup>
                                                        <Input type="text" bssize="sm" name="weldingDataTackWelds" id="weldingDataTackWelds"
                                                            value={this.props.weldingProtocol.weldingProtocolSection7.tackWeldText}
                                                            onChange={(event) => this.onChangeWeldingDataTackWeldSearchText(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                        <DropdownLists listNumber={27} dropDownListsCallback={this.props.setWeldingDataTackWeld} rowNumber={0} ></DropdownLists>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingDataLengthTackWelds" size="sm">{t('LengthTackWelds')} (mm)</Label><Message modalId={'modalId_weldingDataLengthTackWelds'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingDataLengthTackWelds')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingDataLengthTackWelds' />
                                                    <Input type="text" bsSize="sm" name="weldingDataLengthTackWelds" id="weldingDataLengthTackWelds"
                                                        value={this.props.weldingProtocol.weldingProtocolSection7.lengthTackWelds}
                                                        onChange={(event) => this.onChangeWeldingDataLengthTackWelds(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolThroatThickness" size="sm">{t('ContingentThroatThickness')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolThroatThickness" id="weldingProtocolThroatThickness"
                                                        value={this.props.weldingProtocol.weldingProtocolSection7.throatThickness}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingProtocolThroatThickness(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <Label for="weldingProtocolRemarks" size="sm">{t('OtherInformation')}</Label>
                                                <Input type="textarea" bsSize="sm" name="weldingProtocolRemarks" id="weldingProtocolRemarks"
                                                    value={this.props.weldingProtocol.weldingProtocolSection7.remarks} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolRemarks(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolPlaceForMonitoring" size="sm">{t('PlaceForMonitoring')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolPlaceForMonitoring" id="weldingProtocolPlaceForMonitoring"
                                                        value={this.props.weldingProtocol.placeForMonitoring} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolPlaceForMonitoring(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWeldingDate" size="sm">{t('WeldingDate')}</Label>
                                                    <Input type="date" max="9999-12-30" bsSize="sm" name="weldingProtocolWeldingDate" id="weldingProtocolWeldingDate"
                                                        value={this.props.weldingProtocol.weldingDate} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolWeldingDate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolSignature" size="sm">{t('NameOfSupervisor')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolSignature" id="weldingProtocolSignature"
                                                        value={this.props.weldingProtocol.signature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolSignature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingProtocolEmployeeNumber" size="sm">{t('EmployeeNumber')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingProtocolEmployeeNumber" id="weldingProtocolEmployeeNumber"
                                                        value={this.props.weldingProtocol.employeeNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingProtocolEmployeeNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderWeldingTestingDetails2() {
        return (
            <React.Fragment>
                <Form>
                    <WPQRPageHeader pCommissionNumber=""  pManufacturerWPQRNumber="" pDekraProcedureNumber="" pageNumber={'3/4'} />
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <h4>{t('WeldingTestingDetails')}</h4>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={6}>
                            {this.renderWeldPreparationJointDesign()}
                        </Col>
                        <Col sm={6}>
                            {this.renderWeldingSequences()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Form inline>
                                                        <Label for="testCommentInformation" size="sm">{t('Comments')}</Label>
                                                        <span>&nbsp;</span>
                                                        <DropdownLists listNumber={26} dropDownListsCallback={this.props.setCommentInformation} rowNumber={0} ></DropdownLists>
                                                    </Form>
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.commentInformation.split("\n").length < 4 ? 4 : this.props.weldingTestingDetails1.commentInformation.split("\n").length} bsSize="sm" name="testCommentInformation" id="testCommentInformation"
                                                        value={this.props.weldingTestingDetails1.commentInformation}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1CommentInformation(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <WParameters pWeldingMethodASearchText={this.props.weldingProtocol.weldingProtocolSection2.weldingMethod1SearchText}
                                pWeldingMethodBSearchText={this.props.weldingProtocol.weldingProtocolSection2.weldingMethod2SearchText}
                                pWeldingMethods={this.props.weldingProtocol.weldingProtocolSection6.weldingMethods}
                                pWeldingParameters={this.props.weldingProtocol.weldingProtocolSection6.weldingParameters}
                                currentTypes={this.props.weldingProtocol.weldingProtocolSection6.currentTypes}
                                showWeldingPulseParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.PulseWeldingParametersTable}
                                showWeldingBaseParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.BaseWeldingParametersTable}
                                showWeldingArcParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.ArcWeldingParametersTable}
                                showWeldingGwParameters={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}
                                componentPositionDetails={true} />
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderWeldPreparationJointDesign() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldPreparation')} / {t('JointDesign')}</h5>
                        </CardTitle>
                        <CardText>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <DrawJointDesign modalId={5}
                                            showJointDesignModalId={this.state.showJointDesignModalId}
                                            show={this.state.showJointDesignModal}
                                            toggleShow={this.toggleShowJointDesignModal}
                                            position={"weldingProcess"}
                                            jointDesign={this.props.weldingProtocol.weldingProtocolSection7.jointDesign}
                                            setDrawJointDesignView={false} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardText>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderWeldingSequences() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>Strängplanering <small>Weld sequence</small></h5>
                        </CardTitle>
                        <CardText>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <WeldSequence show={this.state.showWeldSequenceModal1}
                                            toggleShow={this.toggleWeldSequenceModal1}
                                            weldSequence={this.props.weldingProtocol.weldingProtocolSection7.weldSequence}
                                            pSetWeldSequenceView={false} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardText>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderWPQTReportPerformedTestsTableHeaderRow() {
        return (
            <React.Fragment>
                <tr>
                    <th className="text-nowrap">{t('TypeOfTest')}</th>
                    <th className="text-nowrap"></th>
                    <th className="text-nowrap">{t('TestingResult')}</th>
                    <th className="text-nowrap">{t('Acceptance')}</th>
                    <th className="text-nowrap">{t('TestingPerformedBy')}</th>
                </tr>
            </React.Fragment>
        );
    }

    private renderWPQTReportPerformedTestsTableRow(rowNumber: number) {
        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        {rowNumber === this.props.wpqtReport.wpqtReportPerformedTestsTableRows.length &&
                            <Form inline>
                                <Label for="wpqtReportTypeOfTestOther" size="sm">{t('Other')}</Label><span>&nbsp;</span>
                                <Input type="text" bsSize="sm" name="wpqtReportTypeOfTestOther" id="wpqtReportTypeOfTestOther"
                                    value={this.props.wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].typeOfTest}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportTypeOfTest(rowNumber, event)}
                                    placeholder="" />
                            </Form>
                        }
                        {rowNumber !== this.props.wpqtReport.wpqtReportPerformedTestsTableRows.length &&
                            <Input type="text" bsSize="sm" name={"wpqtReportTypeOfTest-" + rowNumber} id={"wpqtReportTypeOfTest-" + rowNumber}
                                value={this.props.wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].typeOfTest}
                                onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportTypeOfTest(rowNumber, event)}
                                placeholder="" />
                        }
                    </th>
                    <td>
                        <FormGroup check>
                            <Label check size="sm">
                                <Input type="checkbox" bssize="sm" name={"wpqtReportPerformed-" + rowNumber} id={"wpqtReportPerformed-" + rowNumber}
                                    checked={this.props.wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].performed}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportPerformed(rowNumber, event)}
                                    placeholder="" />
                            </Label>
                        </FormGroup>
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"wpqtReportTestingResult-" + rowNumber} id={"wpqtReportTestingResult-" + rowNumber}
                            value={this.props.wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].testingResult}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportTestingResult(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"wpqtReportAcceptance-" + rowNumber} id={"wpqtReportAcceptance-" + rowNumber}
                            value={this.props.wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].acceptance}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportAcceptance(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" bsSize="sm" name={"wpqtReportTestingPerformedBy-" + rowNumber} id={"wpqtReportTestingPerformedBy-" + rowNumber}
                            value={this.props.wpqtReport.wpqtReportPerformedTestsTableRows[rowNumber - 1].testingPerformedBy}
                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportTestingPerformedBy(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderWPQTReportPerformedTestsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive>
                    <thead>
                        {this.renderWPQTReportPerformedTestsTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.wpqtReport.wpqtReportPerformedTestsTableRows.map((row, index) => (
                            this.renderWPQTReportPerformedTestsTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderWPQTReport() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="wpqtReportReportNumber" size="sm">{t('ReportNo')}.</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportReportNumber" id="wpqtReportReportNumber"
                                                        value={this.props.wpqtReport.reportNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportReportNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="wpqtReportDate" size="sm">{t('Date')}</Label>
                                                    <Input type="date" max="9999-12-30" bsSize="sm" name="wpqtReportDate" id="wpqtReportDate"
                                                        value={this.props.wpqtReport.date} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportDate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="wpqtReportApplicationNumber" size="sm">{t('ApplicationNo')}.</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportApplicationNumber" id="wpqtReportApplicationNumber"
                                                        value={this.props.wpqtReport.applicationNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportApplicationNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqtReportDekraProcedureNumber" size="sm">{t('DekraProcedureNo')}.</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportDekraProcedureNumber" id="wpqtReportDekraProcedureNumber"
                                                        value={this.props.wpqtReport.dekraProcedureNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportDekraProcedureNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqtReportTestMarking" size="sm">{t('TestMarking')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportTestMarking" id="wpqtReportTestMarking"
                                                        value={this.props.wpqtReport.testMarking} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportTestMarking(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqtReportCustomer" size="sm">{t('Customer')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportCustomer" id="wpqtReportCustomer"
                                                        value={this.props.wpqtReport.customer} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportCustomer(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqtReportTestingScope" size="sm">{t('TestingScope')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportTestingScope" id="wpqtReportTestingScope"
                                                        value={this.props.wpqtReport.testingScope} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportTestingScope(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="wpqtReportTestingLocation" size="sm">{t('TestingLocation')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportTestingLocation" id="wpqtReportTestingLocation"
                                                        value={this.props.wpqtReport.testingLocation} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportTestingLocation(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="wpqtReportTestingObject" size="sm">{t('TestingObject')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportTestingObject" id="wpqtReportTestingObject"
                                                        value={this.props.wpqtReport.testingObject} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportTestingObject(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="wpqtReportDimensions" size="sm">{t('Dimensions')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportDimensions" id="wpqtReportDimensions"
                                                        value={this.props.wpqtReport.dimensions} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportDimensions(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqtReportParentMaterial" size="sm">{t('ParentMaterial')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportParentMaterial" id="wpqtReportParentMaterial"
                                                        value={this.props.wpqtReport.parentMaterial} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportParentMaterial(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqtReportFillerMaterial" size="sm">{t('FillerMaterial')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportFillerMaterial" id="wpqtReportFillerMaterial"
                                                        value={this.props.wpqtReport.fillerMaterial} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportFillerMaterial(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="wpqtReportWeldingAndHeatTreatmentData" size="sm">{t('WeldingAndHeatTreatmentData')}</Label>
                                                    <Input type="text" bsSize="sm" name="wpqtReportWeldingAndHeatTreatmentData" id="wpqtReportWeldingAndHeatTreatmentData"
                                                        value={this.props.wpqtReport.weldingAndHeatTreatmentData} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportWeldingAndHeatTreatmentData(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <h5>{t('ListOfPerformedTests')}</h5>
                                    </CardTitle>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    {this.renderWPQTReportPerformedTestsTable()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label for="wpqtReportReportApprovedBy" size="sm">{t('ReportApprovedBy')}</Label>
                                            <Input type="text" bsSize="sm" name="wpqtReportReportApprovedBy" id="wpqtReportReportApprovedBy"
                                                value={this.props.wpqtReport.reportApprovedBy} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWPQTReportReportApprovedBy(event)}
                                                placeholder="" />
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.wpqrDekraTab, // Selects which state properties are merged into the component's props
    WPQRDEKRATabStore.actionCreators // Selects which action creators are merged into the component's props
)(WPQRDEKRATab as any);