﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as WeldingPlanStore from '../store/WeldingPlanStore';
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, InputGroup, InputGroupText, Input, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledTooltip, ButtonGroup, Collapse} from 'reactstrap';
import { t } from '../i18n';
import WeldingPlanForm from './WeldingPlanForm';
import { GetWeldingPlanResponse } from '../store/models/responses/GetWeldingPlanResponse';
import DeletionModal from './otherComponents/DeletionModal';
import EventBus from '../store/eventBus';
import { PDFRequest } from '../store/models/requests/PDFRequest';

// At runtime, Redux will merge together...
type WeldingPlanTabProps =
    WeldingPlanStore.WeldingPlanStoreState // ... state we've requested from the Redux store
    & typeof WeldingPlanStore.actionCreators // ... plus action creators we've requested


class WeldingPlanTab extends React.PureComponent<WeldingPlanTabProps> {

    constructor(props: WeldingPlanTabProps) {
        super(props);
    }
    public state = {
        isWeldingPlanFormOpen: false,
        selectedWeldingPlanId: 0,
        enableToClone: false,
        showDeletionModal: false,
        currentWeldingPlanId: 0,
    };
    public setDeletionModalOpen(open: boolean) {
        this.setState({ showDeletionModal: open });
    }
    private setIsWeldingPlanFormOpen = (isOpen: boolean) => {
        this.setState({
            isWeldingPlanFormOpen: isOpen
        });
    }
    private setEnableToClone = (clone: boolean) => {
        this.setState({
            enableToClone: clone
        });
    }
    private setSelectedWeldingPlanId = (id: number) => {
        this.setState({
            selectedWeldingPlanId: id
        });
    }
    public componentDidMount() {
        const customerId = JSON.parse(localStorage.getItem('customerId')!);
        this.props.getWeldingPlans(customerId);
        EventBus.on("refreshWeldingPlanList", () => {
            const customerId = JSON.parse(localStorage.getItem('customerId')!);
            this.props.getWeldingPlans(customerId);
        });
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.weldingPlanRevision.selectedWeldingPlanRevisionModalOk !== this.props.weldingPlanRevision.selectedWeldingPlanRevisionModalOk! && this.props.weldingPlanRevision.selectedWeldingPlanRevisionModalOk) {
            this.props.clearState();//Todo
            this.setSelectedWeldingPlanId(this.props.weldingPlanRevision.selectedRevision.id);
            this.setIsWeldingPlanFormOpen(true)
        }
    }
    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
    private onClickRevisionWeldingPlanRow(wPlanId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip
        this.props.getWeldingPlanRevisions(wPlanId);
        this.props.setSelectedRevisionIdTo0();
        this.props.setShowChooseWeldingPlanRevisionModal(true);
        this.setEnableToClone(false);
    }
    private onClickCloneWeldingPlanRow(id: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.setSelectedWeldingPlanId(id);
        this.setIsWeldingPlanFormOpen(true)
        this.setEnableToClone(true);
    }
    private onClickGeneratePdf(weldingPlanId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip
        let pdfRequest: PDFRequest = {
            wpsId: 0,
            wpqrId: 0,
            wpId: 0,
            fileName: "",
            weldingPlanId: weldingPlanId,
        }
        this.props.createPdf(pdfRequest);
    }
    public removeWeldingPlan() {
        this.props.removeWeldingPlan(this.state.currentWeldingPlanId)
    }
    public onRemoveWeldingPlanPress(weldingPlanId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip
        this.setState({ currentWeldingPlanId: weldingPlanId });
        this.setDeletionModalOpen(true);
    }
    private openWeldingPlanFormClick() {
        this.props.clearState();//Todo
        this.props.initWeldingPlan()
        this.setIsWeldingPlanFormOpen(true);
        this.setSelectedWeldingPlanId(0); // Be able to open the same welding plane
        this.setEnableToClone(false);
    }
    private onChangeFreeSearch(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setSearchTerm(event.currentTarget.value);
        this.props.getWeldingPlansSearchResult();
    }
    private onChangeSelectedRevision(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedRevision(parseInt(event.currentTarget.value));
    }
    private onClickWpsRevisionModalOK(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.setSelectedWeldingPlanId(this.props.weldingPlanRevision.selectedRevision.id);
        this.props.closeWeldingPlanRevisionModal(false);
        this.setIsWeldingPlanFormOpen(true);

    }
    private onClickWpsRevisionModalCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setShowChooseWeldingPlanRevisionModal(false);
    }

    private onClickPreviousButton = (refleshWeldingPlanList: boolean) => {
        if (refleshWeldingPlanList) {
            const customerId = JSON.parse(localStorage.getItem('customerId')!);
            this.props.getWeldingPlans(customerId);
        }
        this.setIsWeldingPlanFormOpen(false);
        this.setSelectedWeldingPlanId(0); // Be able to open the same welding plane
    }
    public render() {
        return (
            <React.Fragment key={"renderWpqrTab"}>
                <Form className="dekra-content-wrapper dekra-wrapper">
                    <Collapse isOpen={!this.state.isWeldingPlanFormOpen}>
                    <Row form>
                            <Col sm={12}>
                                <FormGroup>
                                    <Card className="card-border-dekra">
                                        <CardBody>
                                            <CardTitle>
                                            </CardTitle>
                                            <Row form>
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <Button onClick={() => this.openWeldingPlanFormClick()} color="primary">
                                                                {t('Add')}
                                                            </Button>
                                                            <div style={{ display: "inline", marginRight: "10px" }} />
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type="text" bsSize="sm" name="freeSearch" style={{ height: "33px" }}
                                                                value={this.props.searchTerm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFreeSearch(event)}
                                                                placeholder={t('Search')} />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </FormGroup>
                            </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <FormGroup>
                                <Table size="sm" className="cert-table table-striped" responsive={true}>
                                    <thead>
                                        {this.renderTableHeaderRow()}
                                    </thead>
                                        <tbody>
                                            <DeletionModal
                                                onConfirmDeletion={() => this.removeWeldingPlan()}
                                                show={this.state.showDeletionModal}
                                                setOpen={(open: boolean) => this.setDeletionModalOpen(open)}
                                                headerText={t('DeleteWPlan')}
                                            />
                                            {this.rendeWeldingPlanRevisionModal()}
                                        {this.props.searchTerm.length > 0 ?
                                            this.props.searchWPlansResult.map((weldingPlan, index) => (
                                                this.renderTableRow(weldingPlan, index)
                                            ))
                                            :
                                            this.props.wPlansList.map((weldingPlan, index) => (
                                                this.renderTableRow(weldingPlan, index)
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={15}>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </FormGroup>
                        </Col>
                        </Row>
                    </Collapse>
                    <Collapse isOpen={this.state.isWeldingPlanFormOpen}>
                        <Row form>
                            <Col sm={12}>
                                <WeldingPlanForm pEnableClone={this.state.enableToClone}
                                    weldingPlanId={this.state.selectedWeldingPlanId}
                                    previewPage={this.onClickPreviousButton} />
                            </Col>
                        </Row>
                    </Collapse>
                </Form>
            </React.Fragment>
        );
    }

    private renderTableHeaderRow() {
        return (
            <React.Fragment key={"renderTableHeaderRow"}>
                <tr>
                    <th>
                        <a></a>
                    </th>
                    <th>
                        <a>{t('Project')}</a>
                    </th>
                    <th>
                        <a>{t('ProjectDescription')}</a>
                    </th>
                    <th>
                        <a>{t('Regulations')}</a>
                    </th>
                    <th>
                        <a>{t('AcceptanceCriteria')}</a>
                    </th>
                </tr>
            </React.Fragment>
        );
    }
    private rendeWeldingPlanRevisionModal() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.weldingPlanRevision.showChooseWeldingPlanRevisionModal}>
                    <ModalHeader>
                        {t('ListOfWPSRevisions')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                {this.props.weldingPlanRevision.revisions.map((revision, index) => (
                                    <FormGroup check key={index}>
                                        <Label check size="sm">
                                            <Input type="radio" bssize="sm" key={"revisions" + revision.id} id={"revisions" + revision.id} name="revisions"
                                                value={revision.id}
                                                checked={this.props.weldingPlanRevision.selectedRevision.id === revision.id}
                                                onChange={(event) => this.onChangeSelectedRevision(event)}
                                                placeholder="" />{'Revision(' + (this.props.weldingPlanRevision.revisions.length - 1 - index) + ') ' + (revision.weldingPlanDetails.manufacturer.split(",").length > 1 ? revision.weldingPlanDetails.manufacturer.split(",")[1] : revision.weldingPlanDetails.manufacturer) }
                                        </Label>
                                    </FormGroup>
                                ))}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickWpsRevisionModalOK(event)} disabled={this.props.weldingPlanRevision.selectedRevision.id === 0 ? true : false}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickWpsRevisionModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
    private renderTableRow(wPlan: GetWeldingPlanResponse, index: number) {
        return (
            <React.Fragment key={"renderTableRow" + index}>
                <tr key={wPlan.id}>
                    <td>
                        <ButtonGroup vertical>
                            <Button color="link" size="sm" name={"historyWeldingParametersRow-" + index} id={"historyWeldingParametersRow-" + index}
                                onClick={(event) => this.onClickRevisionWeldingPlanRow(wPlan.id, event)}>
                                <i className="fas fa-history"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"historyWeldingParametersRow-" + index} >
                                {t('Revisions')}!
                            </UncontrolledTooltip>
                            <Button color="link" size="sm" name={"cloneWeldingParametersRow-" + index} id={"cloneWeldingParametersRow-" + index}
                                onClick={(event) => this.onClickCloneWeldingPlanRow(wPlan.id, event)}>
                                <i className="fas fa-clone"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"cloneWeldingParametersRow-" + index}>
                                {t('Copy')}!
                            </UncontrolledTooltip>
                            <Button color="link" size="sm" name={"generatePdfRow-" + index} id={"generatePdfRow-" + index}
                                onClick={(event) => this.onClickGeneratePdf(wPlan.id, event)}>
                                <i className="fas fa-file-pdf"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"generatePdfRow-" + index}>
                                PDF!
                            </UncontrolledTooltip>
                            <Button color="link" size="sm" name={"deleteRow-" + index} id={"deleteRow-" + index}
                                onClick={(event) => this.onRemoveWeldingPlanPress(wPlan.id, event)}>
                                <i className="fas fa-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"deleteRow-" + index}>
                                {t('Delete')}!
                            </UncontrolledTooltip>
                        </ButtonGroup>
                    </td>
                    <td>
                        {wPlan.weldingPlanDetails.projectNumber}
                    </td>
                    <td>
                        {wPlan.projectDescription}
                    </td>
                    <td>
                        {wPlan.weldingPlanDetails.byStandard}
                    </td>
                    <td>
                        {wPlan.acceptanceCriteria}
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.weldingPlan, // Selects which state properties are merged into the component's props
    WeldingPlanStore.actionCreators // Selects which action creators are merged into the component's props
)(WeldingPlanTab as any);