﻿

import { WPQR } from './WPQR';
import { GetWeldingMethodResponse } from './models/responses/GetWeldingMethodResponse';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { WPQRSearchProperties } from './models/WPQRSearchProperties';
import { WPQRTabSettingColumns } from './models/WPQRTabSettingColumns';
import { GetStandardResponse } from './models/responses/GetStandardResponse';
import * as authHeader from "./models/auth-header";
import EventBus from './eventBus';
// TODO
import { WeldingPlanHeader } from './WeldingPlanHeader';
import { WeldingPlanRequest } from './models/requests/WeldingPlanRequest';
import HelpFunctions from './models/HelpFunctions';
import { UserListObject } from './UserListObject';
import { GetWeldingPlanResponse } from './models/responses/GetWeldingPlanResponse';
import { WeldingPlanHeadlines } from './models/WeldingPlanHeadlines';
import { WeldingPlanRevision } from './models/WeldingPlanRevision';
import { WeldingPlanResponse } from './models/responses/WeldingPlanResponse';
import { UploadFileResponse } from './models/responses/UploadFileResponse';
import { PDFRequest } from './models/requests/PDFRequest';
import { PDFResponse } from './models/responses/PDFResponse';
import { FilesUpload } from './models/FilesUpload';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WeldingPlanStoreState {
    weldingPlanIdResult: number;
    wPlansList: Array<GetWeldingPlanResponse>;
    searchWPlansResult: Array<GetWeldingPlanResponse>;
    searchTerm: string;
    header: WeldingPlanHeader;
    weldingPlanHeadlines: WeldingPlanHeadlines;
    weldingPlanRevision: WeldingPlanRevision;
    currentlyLoading: boolean;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface SetWeldingPlanLoading {
    type: 'SET_WELDING_PLAN_LOADING';
    currentlyLoading: boolean;
}
interface GetWPlansListAction {
    type: 'WPLAN_GET_WPLANS';
    wPlansList: Array<GetWeldingPlanResponse>;
}
interface GetWPlansListRequestAction {
    type: 'WPLAN_GET_WPLANS_REQUEST';
}
interface SetWPQRsSearchResultAction {
    type: 'SET_WPQRS_SEARCH_RESULT';
    wpqrsSearchResult: Array<WPQR>;
}

interface SetSearchProperties {
    type: 'SET_SEARCH_PROPERTIES';
    searchProperties: WPQRSearchProperties;
}
interface GetWeldingMethondsSearchAction {
    type: 'SET_WELDING_METHODS_SEARCH';
    wpqrWeldingMethodsSearch: Array<GetWeldingMethodResponse>;
}

export interface SetShowTabSettingModalAction {
    type: 'SET_SHOW_TAB_SETTING_MODAL';
    showTabSettingModal: boolean;
}

export interface SetTabSettingColumnsAction {
    type: 'SET_TAB_SETTING_COLUMNS';
    wpqrTabSettingColumns: WPQRTabSettingColumns;
}

export interface SetPaginationPageIndexAction {
    type: 'SET_PAGINATION_PAGE_INDEX';
    wpqrPaginationPageIndex: number;
}

export interface GetWeldingMethondsSearchRequestAction {
    type: 'GET_WELDING_METHODS_SEARCH_REQUEST';
}

export interface SetCreateUpdateWPlanIdReceiveAction {
    type: 'SET_CREATE_UPATE_WPLAN_ID_RECEIVE';
    weldingPlanIdResult: number;
}

interface GetWeldingPlanRequestAction {
    type: 'WPLAN_GET_WELDINGPLAN_REQUEST';
}

export interface SetActiveTabId {
    type: 'SET_ACTIVE_TAB_ID';
    activeTabId: boolean;
}

export interface SetHeaderAction {
    type: 'WPLAN_SET_HEADER';
    header: WeldingPlanHeader;
}

export interface SetHeadlinesAction {
    type: 'WPLAN_SET_HEADLINES';
    weldingPlanHeadlines: WeldingPlanHeadlines;
}

export interface SetSearchTerm {
    type: 'WPLAN_SET_SEARCH_TERM';
    searchTerm: string;
}

export interface GetWeldingPlansSearchResult {
    type: 'WPLAN_GET_WPLANS_SEARCH_RESULT';
    searchWPlansResult: Array<GetWeldingPlanResponse>;
}

export interface GetWeldingPlanRevisionResult {
    type: 'WPLAN_SET_REVISION_RESULT';
    weldingPlanRevision: WeldingPlanRevision;
}

export interface ResetWeldingPlanStateAction {
    type: 'WPLAN_RESET_STATE_ACTION';
}

interface GetWeldingPlanRevisionAction {
    type: 'WPLAN_GET_REVISION_REQUEST';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = SetHeaderAction
    | SetHeadlinesAction
    | GetWPlansListAction
    | GetWPlansListRequestAction
    | SetSearchTerm
    | GetWeldingPlansSearchResult
    | GetWeldingPlanRequestAction
    | ResetWeldingPlanStateAction
    | GetWeldingPlanRevisionResult
    | GetWeldingPlanRevisionAction
    | SetCreateUpdateWPlanIdReceiveAction
    | SetWeldingPlanLoading;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    /*getWPQRs: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`wpqrs`)
            .then(response => response.json() as Promise<Array<WPQR>>)
            .then(data => {
                dispatch({ type: 'GET_WPQRS', wpqrs: data });
            });
    },*/
    clearState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'WPLAN_RESET_STATE_ACTION' });
    },
    getWeldingPlansSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.news) {
            let newWplans = [];
            let searchTerm = appState.weldingPlan!.searchTerm.toLowerCase();
            let weldingPlans: Array<GetWeldingPlanResponse> = appState.weldingPlan!.wPlansList;

            for (let i = 0; i < weldingPlans.length; i++) {
                if (searchTerm.length > -1) {
                    if (
                        weldingPlans[i].acceptanceCriteria?.toLowerCase().search(searchTerm) > -1
                        || weldingPlans[i].weldingPlanDetails.projectNumber?.toLowerCase().search(searchTerm) > -1
                        || weldingPlans[i].weldingPlanDetails.byStandard?.toLowerCase().search(searchTerm) > -1
                        || weldingPlans[i].projectDescription?.toLowerCase().search(searchTerm) > -1
                    ) {
                        newWplans.push(weldingPlans[i]);
                    }
                }
            }

            dispatch({ type: 'WPLAN_GET_WPLANS_SEARCH_RESULT', searchWPlansResult: newWplans });
        }
    },
    setSearchTerm: (searchTerm: string): AppThunkAction<KnownAction> => (dispatch) => {
        const newTerm = searchTerm;
        dispatch({ type: 'WPLAN_SET_SEARCH_TERM', searchTerm: newTerm });
    },
    setAcceptanceCriteria: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.acceptanceCriteria = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setProjectDescription: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.projectDescription = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setObjectsOnDrawings: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.objectsOnDrawings = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setWeldingPlanFigure: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.weldingPlanFigure = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setWeldingPlanWPS: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.weldingPlanWPS = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setFigureText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.figureText = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setTackWelds: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.tackWelds = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setTemporayDevices: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.temporayDevices = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setFillermaterial: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.fillermaterial = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setWeldPosition: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.weldPosition = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setJointPreparation: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.jointPreparation = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setHandlingOfFillerMetal: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.handlingOfFillerMetal = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setBeforeWelding: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.beforeWelding = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setDuringWelding: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.duringWelding = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setAfterWelding: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.afterWelding = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setExtentOfTestingAcceptanskrav: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.extentOfTestingAcceptanskrav = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setOtherInformation: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.otherInformation = value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectExtentOfTestingAcceptanskrav: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.extentOfTestingAcceptanskrav = weldingPlanHeadlines.extentOfTestingAcceptanskrav + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectTackWelds: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.tackWelds = weldingPlanHeadlines.tackWelds + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectAfterWelding: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.afterWelding = weldingPlanHeadlines.afterWelding + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectDuringWelding: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.duringWelding = weldingPlanHeadlines.duringWelding + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectBeforeWelding: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.beforeWelding = weldingPlanHeadlines.beforeWelding + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectHandlingOfFillerMetalv: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.handlingOfFillerMetal = weldingPlanHeadlines.handlingOfFillerMetal + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectJointPreparation: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.jointPreparation = weldingPlanHeadlines.jointPreparation + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectWeldPosition: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.weldPosition = weldingPlanHeadlines.weldPosition + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectTemporayDevices: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.temporayDevices = weldingPlanHeadlines.temporayDevices + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectFillermaterial: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.fillermaterial = weldingPlanHeadlines.fillermaterial + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectWeldingDataSheet: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.weldingPlanWPS = weldingPlanHeadlines.weldingPlanWPS + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectFigureText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.figureText = weldingPlanHeadlines.figureText + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectObjectsOnDrawings: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.objectsOnDrawings = weldingPlanHeadlines.objectsOnDrawings + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectAcceptanceCriteria: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.acceptanceCriteria = weldingPlanHeadlines.acceptanceCriteria + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    setSelectProjectDescription: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
        weldingPlanHeadlines.projectDescription = weldingPlanHeadlines.projectDescription + value;
        dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
    },
    createPdf: (pdfRequest: PDFRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {// Get the WeldingParameters values.
        const appState = getState();
        if (appState && appState.wpsTab) {
            fetch(`api/PDFs/CreateWeldingPlan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...pdfRequest })
            })
                .then(response => response.json() as Promise<PDFResponse>)
                .then(data => {
                    console.info(data);
                    var pdf = data.data;
                    const linkSource = `data:application/pdf;base64,${pdf}`;
                    const downloadLink = document.createElement("a");
                    const fileName = data.pdfName + ".pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click()
                    dispatch({ type: 'SET_WELDING_PLAN_LOADING', currentlyLoading : false });
                })
                .catch(error => {
                    console.error(error);
                });
            dispatch({ type: 'SET_WELDING_PLAN_LOADING', currentlyLoading: true });
        }
    },
    getWeldingPlans: (customerId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingPlans/GetAllInclude/${customerId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetWeldingPlanResponse>;
                console.info(data);
                const appState = getState();
                if (appState && appState.weldingPlan) {
                    let wPlansList: Array<GetWeldingPlanResponse> = { ...appState.weldingPlan.wPlansList };
                    wPlansList = [...data];
                    dispatch({ type: 'WPLAN_GET_WPLANS', wPlansList: wPlansList });
                }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPLAN_GET_WPLANS_REQUEST' });
    },
    getWeldingPlan: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingPlans/GetByIdInclude/${id}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as GetWeldingPlanResponse;
                console.info(data);
                let selectedWeldingPlan: GetWeldingPlanResponse = { ...res.data };
                const appState = getState();
                if (appState && appState.weldingPlan) {
                    let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
                    header.wpqrNumber = selectedWeldingPlan.weldingPlanDetails.wpqrNumber;
                    header.selectedWPlanId = selectedWeldingPlan.id;
                    header.revisionWPlanAction = selectedWeldingPlan.revisionWPlanAction;
                    //header.selectedWeldingPlanId = selectedWeldingPlan.id;
                    header.pwpsNumber = selectedWeldingPlan.weldingPlanDetails.pwpsNumber;
                    header.date = selectedWeldingPlan.weldingPlanDetails.date;
                    header.project = selectedWeldingPlan.weldingPlanDetails.projectNumber;
                    header.revisionNumber = selectedWeldingPlan.weldingPlanDetails.revisionNumber;
                    header.examiner = selectedWeldingPlan.weldingPlanDetails.examiner;
                    header.manufacturer = selectedWeldingPlan.weldingPlanDetails.manufacturer;
                    header.selectedByStandard = selectedWeldingPlan.weldingPlanDetails.byStandard;

                    dispatch({ type: 'WPLAN_SET_HEADER', header: header });
                    let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
                    weldingPlanHeadlines.acceptanceCriteria = selectedWeldingPlan.acceptanceCriteria;
                    weldingPlanHeadlines.objectsOnDrawings = selectedWeldingPlan.objectsOnDrawings;
                    weldingPlanHeadlines.weldingPlanFigure = selectedWeldingPlan.weldingPlanFigure;
                    weldingPlanHeadlines.weldingPlanWPS = selectedWeldingPlan.weldingPlanWPS;
                    weldingPlanHeadlines.jointPreparation = selectedWeldingPlan.jointPreparation
                    weldingPlanHeadlines.outerDiameter = selectedWeldingPlan.outerDiameter;
                    weldingPlanHeadlines.tackWelds = selectedWeldingPlan.tackWelds;
                    weldingPlanHeadlines.temporayDevices = selectedWeldingPlan.temporayDevices;
                    weldingPlanHeadlines.weldPosition = selectedWeldingPlan.weldPosition;
                    weldingPlanHeadlines.handlingOfFillerMetal = selectedWeldingPlan.handlingOfFillerMetal;
                    weldingPlanHeadlines.beforeWelding = selectedWeldingPlan.beforeWelding;
                    weldingPlanHeadlines.duringWelding = selectedWeldingPlan.duringWelding;
                    weldingPlanHeadlines.afterWelding = selectedWeldingPlan.afterWelding;
                    weldingPlanHeadlines.extentOfTestingAcceptanskrav = selectedWeldingPlan.extentOfTestingAcceptanskrav;
                    weldingPlanHeadlines.id = selectedWeldingPlan.id;
                    weldingPlanHeadlines.fillermaterial = selectedWeldingPlan.fillermaterial;
                    weldingPlanHeadlines.projectDescription = selectedWeldingPlan.projectDescription;
                    weldingPlanHeadlines.figureText = selectedWeldingPlan.figureText;
                    weldingPlanHeadlines.otherInformation = selectedWeldingPlan.weldingPlanDetails.otherInformation;

                    // Get images
                    let updateFilesRequest: Array<UploadFileResponse> = new Array<UploadFileResponse>();
                    for (let i = 0; i < selectedWeldingPlan.uploadFileResponse.length; i++) {
                        let getFilesUploadResponse: UploadFileResponse = new UploadFileResponse();
                        getFilesUploadResponse.id = selectedWeldingPlan.uploadFileResponse[i].id;
                        getFilesUploadResponse.fileName = selectedWeldingPlan.uploadFileResponse[i].fileName;
                        getFilesUploadResponse.filePath = selectedWeldingPlan.uploadFileResponse[i].filePath;
                        getFilesUploadResponse.fileUrl = selectedWeldingPlan.uploadFileResponse[i].fileUrl;
                        updateFilesRequest.push(getFilesUploadResponse);
                    }
                    weldingPlanHeadlines.uploadFileResponse = [ ...updateFilesRequest ];

                    dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
                }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPLAN_GET_WELDINGPLAN_REQUEST'  });
    },
    createUpdateWPlan: (weldingPlanRequest: WeldingPlanRequest, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {// Get the WeldingParameters values.
        const appState = getState();
        if (appState && appState.weldingPlan && appState.fileUpload) {
            // UploadImage selected
            let updateFilesRequests: Array<FilesUpload> = new Array<FilesUpload>();
            for (let i = 0; i < appState.fileUpload.selectedFileUpload.length; i++) {
                let fileUploadRequest = new FilesUpload();
                fileUploadRequest.id = appState.fileUpload.selectedFileUpload[i].id;
                fileUploadRequest.fileUrl = appState.fileUpload.selectedFileUpload[i].fileUrl;
                fileUploadRequest.filePath = appState.fileUpload.selectedFileUpload[i].filePath;
                fileUploadRequest.fileName = appState.fileUpload.selectedFileUpload[i].fileName;
                updateFilesRequests.push(fileUploadRequest);
            }
            weldingPlanRequest.fileUploadRequest = updateFilesRequests;

            fetch(`api/WeldingPlans/Create`, {
                method: 'POST',
                headers: authHeader.authHeader(),
                body: JSON.stringify(weldingPlanRequest)
            })
                .then(response => response.json() as Promise<WeldingPlanResponse>)
                .then(data => {
                    console.info(data);
                    dispatch({ type: 'SET_CREATE_UPATE_WPLAN_ID_RECEIVE', weldingPlanIdResult: data.id });
                    dispatch({ type: 'SET_WELDING_PLAN_LOADING', currentlyLoading: false });
                    callback();
                })
                .catch(error => {
                    console.error(error);
                });
            dispatch({ type: 'SET_WELDING_PLAN_LOADING', currentlyLoading: true });
        }
    },
    removeWeldingPlan: (weldingPlanId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.customerList) {
            fetch(`/api/WeldingPlans/DeleteByWeldingPlanId/${weldingPlanId}`, {
                method: 'DELETE',
                headers: authHeader.authHeader(),
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    EventBus.dispatch("refreshWeldingPlanList", "");
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    initWeldingPlan: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan && appState.login) {
            // Initialize WeldingPlanHeader.
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };

            let currentUser: UserListObject = { ...appState.login.currentUser };
            header.manufacturer = currentUser === null ? "" : (currentUser.customer && currentUser.customer.name ? HelpFunctions.joinTwoStringWithComma(currentUser.customer.name, currentUser.displayName) : "");
            header.customerLogo = currentUser === null ? "" : (currentUser.customer && currentUser.customer.customerLogo ? currentUser.customer.customerLogo : "");
            // Initialize WeldingParameters.
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });

            let weldingPlanHeadlines: WeldingPlanHeadlines = { ...appState.weldingPlan.weldingPlanHeadlines };
            weldingPlanHeadlines.uploadFileResponse = [];
            dispatch({ type: 'WPLAN_SET_HEADLINES', weldingPlanHeadlines: weldingPlanHeadlines });
        }
    },
    setHeaderDate: (date: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            header.date = date;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setHeaderLogotypeFileFormData: (logotypeFileFormData: FormData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            //header.logotypeFileFormData = logotypeFileFormData;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setHeaderLogotypeURL: (logotypeURL: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            //header.logotypeURL = logotypeURL;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setHeaderProject: (project: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            header.project = project;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setHeaderPWPSNumber: (pWPSNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            header.pwpsNumber = pWPSNumber;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setHeaderRevisionNumber: (revision: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            header.revisionNumber = revision;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setHeaderSelectedByStandard: (standard: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            header.selectedByStandard = standard;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setHeaderWPQRNumber: (wpqrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            header.wpqrNumber = wpqrNumber;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setHeaderWeldingPlanType: (wpsType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan && appState.login) {
            // Get ContactPerson and CompanyName
            let currentUser: UserListObject = { ...appState.login.currentUser };
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            header.wpsType = wpsType;
            header.manufacturer = currentUser === null ? "" : (currentUser.customer && currentUser.customer.name ? (currentUser.customer.name + ", ") : "") + (currentUser.firstName ? currentUser.firstName + " " : "") + (currentUser.lastName ? currentUser.lastName : "");
            header.examiner = "";
            header.customerLogo = currentUser === null ? "" : (currentUser.customer && currentUser.customer.customerLogo ? currentUser.customer.customerLogo : "");
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setManufacturer: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            header.manufacturer = value;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setExaminer: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let header: WeldingPlanHeader = { ...appState.weldingPlan.header };
            header.examiner = value;
            dispatch({ type: 'WPLAN_SET_HEADER', header: header });
        }
    },
    setSelectedRevision: (revisionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let weldingPlanRevision = { ...appState.weldingPlan.weldingPlanRevision };
            let selectedRevision: GetWeldingPlanResponse = { ...appState.weldingPlan.weldingPlanRevision.selectedRevision };
            for (let i = 0; i < weldingPlanRevision.revisions.length; i++) {
                if (weldingPlanRevision.revisions[i].id === revisionId) {
                    selectedRevision = { ...weldingPlanRevision.revisions[i] };
                    break;
                }
            }
            weldingPlanRevision.selectedRevision = selectedRevision;
            dispatch({ type: 'WPLAN_SET_REVISION_RESULT', weldingPlanRevision: weldingPlanRevision });
        }
    },
    setSelectedRevisionIdTo0: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let weldingPlanRevision = { ...appState.weldingPlan.weldingPlanRevision };
            let selectedRevision: GetWeldingPlanResponse = { ...appState.weldingPlan.weldingPlanRevision.selectedRevision };
            selectedRevision.id = 0
            weldingPlanRevision.selectedRevision = selectedRevision;
            dispatch({ type: 'WPLAN_SET_REVISION_RESULT', weldingPlanRevision: weldingPlanRevision });
        }
    },
    closeWeldingPlanRevisionModal: (showChooseWeldingPlanRevisionModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let weldingPlanRevision: WeldingPlanRevision = { ...appState.weldingPlan.weldingPlanRevision };
            weldingPlanRevision.showChooseWeldingPlanRevisionModal = showChooseWeldingPlanRevisionModal;
            dispatch({ type: 'WPLAN_SET_REVISION_RESULT', weldingPlanRevision: weldingPlanRevision });
        }
    },
    setShowChooseWeldingPlanRevisionModal: (showChooseWeldingPlanRevisionModal: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldingPlan) {
            let weldingPlanRevision: WeldingPlanRevision = { ...appState.weldingPlan.weldingPlanRevision };
            weldingPlanRevision.showChooseWeldingPlanRevisionModal = showChooseWeldingPlanRevisionModal;
            dispatch({ type: 'WPLAN_SET_REVISION_RESULT', weldingPlanRevision: weldingPlanRevision });
        }
    },
    getWeldingPlanRevisions: (weldingPlanId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingPlans/GetByWeldingPlanIdRevisions/${weldingPlanId}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetWeldingPlanResponse>;
                console.info(data);
                const appState = getState();
                if (appState && appState.weldingPlan) {
                    let weldingPlanRevision: WeldingPlanRevision = { ...appState.weldingPlan.weldingPlanRevision };
                    weldingPlanRevision.revisions = [...data];
                    dispatch({ type: 'WPLAN_SET_REVISION_RESULT', weldingPlanRevision: weldingPlanRevision });
                }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'WPLAN_GET_REVISION_REQUEST' });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WeldingPlanStoreState = {
    weldingPlanIdResult: 0,
    wPlansList: [],
    currentlyLoading: false,
    searchWPlansResult: [],
    header: {
        date: '',
        project: '',
        pwpsNumber: '',
        revisionNumber: '',
        revisionWPlanAction: '',
        selectedByStandard: '',
        wpqrNumber: '',
        wpsType: '',
        wpsDekraNumber: '',
        wpsHeatInputText: '',
        wpsArcEnergyText: '',
        manufacturer: '',
        examiner: '',
        confirmedMessage: '',
        // Send Epost
        dekraContactEmail: '',
        bodyTemplateHtml: '',
        selectedWPlanId: 0,
        otherOptions: [],
        showChooseWpsTypeModal: false,
        successStatusCode: false,

        customerLogo: '',

    },
    weldingPlanHeadlines: {
        acceptanceCriteria: '',
        objectsOnDrawings: '',
        weldingPlanFigure: '',
        weldingPlanWPS: '',
        jointPreparation: '',
        outerDiameter: '',
        tackWelds: '',
        temporayDevices: '',
        weldPosition: '',
        handlingOfFillerMetal: '',
        beforeWelding: '',
        duringWelding: '',
        afterWelding: '',
        extentOfTestingAcceptanskrav: '',
        id: 0,
        fillermaterial: '',
        figureText: '',
        projectDescription: '',
        otherInformation: '',
        uploadFileResponse: []
    },
    searchTerm: '',
    weldingPlanRevision: {
        revisions: [],
        selectedRevisionAction: '',
        selectedRevision: {
            id: 0,
            acceptanceCriteria: '',
            objectsOnDrawings: '',
            weldingPlanFigure: '',
            weldingPlanWPS: '',
            jointPreparation: '',
            outerDiameter: '',
            tackWelds: '',
            temporayDevices: '',
            weldPosition: '',
            handlingOfFillerMetal: '',
            beforeWelding: '',
            duringWelding: '',
            afterWelding: '',
            extentOfTestingAcceptanskrav: '',
            figureText: '',
            projectDescription: '',
            weldingPlanDetails: {
                pwpsNumber: '',
                projectNumber: '',
                revisionNumber: '',
                revisionWpsAction: '',
                byStandard: '',
                wpqrNumber: '',
                manufacturer: '',
                examiner: '',
                date: '',
                otherInformation: '',
            },
            customerId: 0,
            revisionWPlanId: 0,
            revisionWPlanAction: '',
            fillermaterial: '',
            uploadFileResponse: []
        },
        selectedWeldingPlanRevisionModalOk: false,
        showChooseWeldingPlanRevisionModal: false,
    }
};

export const reducer: Reducer<WeldingPlanStoreState> = (state: WeldingPlanStoreState | undefined, incomingAction: Action): WeldingPlanStoreState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'WPLAN_SET_HEADER':
            return {
                weldingPlanIdResult: state.weldingPlanIdResult,
                wPlansList: state.wPlansList,
                searchWPlansResult: state.searchWPlansResult,
                header: action.header,
                weldingPlanHeadlines: state.weldingPlanHeadlines,
                searchTerm: state.searchTerm,
                weldingPlanRevision: state.weldingPlanRevision,
                currentlyLoading: state.currentlyLoading,
            };
        case 'WPLAN_SET_HEADLINES':
            return {
                weldingPlanIdResult: state.weldingPlanIdResult,
                wPlansList: state.wPlansList,
                searchWPlansResult: state.searchWPlansResult,
                header: state.header,
                weldingPlanHeadlines: action.weldingPlanHeadlines,
                searchTerm: state.searchTerm,
                weldingPlanRevision: state.weldingPlanRevision,
                currentlyLoading: state.currentlyLoading,
            };
        case 'WPLAN_GET_WPLANS':
            return {
                weldingPlanIdResult: state.weldingPlanIdResult,
                wPlansList: action.wPlansList,
                searchWPlansResult: state.searchWPlansResult,
                header: state.header,
                weldingPlanHeadlines: state.weldingPlanHeadlines,
                searchTerm: state.searchTerm,
                weldingPlanRevision: state.weldingPlanRevision,
                currentlyLoading: state.currentlyLoading,
            };
        case 'WPLAN_SET_SEARCH_TERM':
            return {
                weldingPlanIdResult: state.weldingPlanIdResult,
                wPlansList: state.wPlansList,
                searchWPlansResult: state.searchWPlansResult,
                header: state.header,
                weldingPlanHeadlines: state.weldingPlanHeadlines,
                searchTerm: action.searchTerm,
                weldingPlanRevision: state.weldingPlanRevision,
                currentlyLoading: state.currentlyLoading,
            }
        case 'WPLAN_GET_WPLANS_SEARCH_RESULT':
            return {
                weldingPlanIdResult: state.weldingPlanIdResult,
                wPlansList: state.wPlansList,
                searchWPlansResult: action.searchWPlansResult,
                header: state.header,
                weldingPlanHeadlines: state.weldingPlanHeadlines,
                searchTerm: state.searchTerm,
                weldingPlanRevision: state.weldingPlanRevision,
                currentlyLoading: state.currentlyLoading,
            }
        case 'WPLAN_SET_REVISION_RESULT':
            return {
                weldingPlanIdResult: state.weldingPlanIdResult,
                wPlansList: state.wPlansList,
                searchWPlansResult: state.searchWPlansResult,
                header: state.header,
                weldingPlanHeadlines: state.weldingPlanHeadlines,
                searchTerm: state.searchTerm,
                weldingPlanRevision: action.weldingPlanRevision,
                currentlyLoading: state.currentlyLoading,
            }
        case 'SET_CREATE_UPATE_WPLAN_ID_RECEIVE':
            return {
                weldingPlanIdResult: action.weldingPlanIdResult,
                wPlansList: state.wPlansList,
                searchWPlansResult: state.searchWPlansResult,
                header: state.header,
                weldingPlanHeadlines: state.weldingPlanHeadlines,
                searchTerm: state.searchTerm,
                weldingPlanRevision: state.weldingPlanRevision,
                currentlyLoading: state.currentlyLoading,
            }
        case 'SET_WELDING_PLAN_LOADING':
            return {
                weldingPlanIdResult: state.weldingPlanIdResult,
                wPlansList: state.wPlansList,
                searchWPlansResult: state.searchWPlansResult,
                header: state.header,
                weldingPlanHeadlines: state.weldingPlanHeadlines,
                searchTerm: state.searchTerm,
                weldingPlanRevision: state.weldingPlanRevision,
                currentlyLoading: action.currentlyLoading,
            }
        case 'WPLAN_RESET_STATE_ACTION':
            return unloadedState;
        default:
            return state;
    }
};
