﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
//import * as NewsListStore from '../store/NewsListStore';
import { ApplicationState } from '../store/index';
//import { NewsListObject } from '../store/NewsListObject';
import { t } from '../i18n';
import { CustomerObject } from '../store/CustomerObject';
//import { UpdateNewsRequest } from '../store/models/requests/UpdateNewsRequest';
//import { CreateNewsRequest } from '../store/models/requests/CreateNewsRequest';
import * as NewsStore from '../store/NewsStore';
import { News } from '../store/models/News';

// At runtime, Redux will merge together...
type EditNewsModalProps =
    NewsStore.NewsState // ... state we've requested from the Redux store
    & typeof NewsStore.actionCreators  // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    show: boolean;
    toggleShow: () => void;
    chosenNews: News;
    setChosenNews: any;
    customers: Array<CustomerObject>;
    creatingNewNews: boolean;
}

class EditNewsListModal extends React.PureComponent<EditNewsModalProps> {
    public constructor(props: EditNewsModalProps) {
        super(props);
    }

    public componentDidMount() {

    }

    public state = {
        textAreaValue: '',
    };

    public onRolesSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
    }

    public onCompanySelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
    }

    private onClickToggleShowModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        //this.resetErrors();
        this.props.toggleShow();
    }

    private confirmModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();

        if (this.props.creatingNewNews) {
            const createNewsRequest: News = {
                title: (document.getElementById('newsTitleInput') as HTMLInputElement).value,
                content: (document.getElementById('newsContentInput') as HTMLInputElement).value,
                id: 0,
                language: '',
            }

            this.props.addNewNews(createNewsRequest, () => { this.props.receiveNewsUnsorted() });
        } else {
            const updateNewsRequest: News = {
                title: (document.getElementById('newsTitleInput') as HTMLInputElement).value,
                content: (document.getElementById('newsContentInput') as HTMLInputElement).value,
                id: this.props.chosenNews.id,
                language: this.props.chosenNews.language,
            }

            this.props.updateNews(updateNewsRequest, () => { this.props.receiveNewsUnsorted() });
        }

        this.props.toggleShow();
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
    private onKeyPress1(event: React.KeyboardEvent<HTMLInputElement>) {
        
    }

    private onOpen() {
        if (this.props.creatingNewNews) {
            (document.getElementById('newsTitleInput') as HTMLInputElement).value = "";
            (document.getElementById('newsContentInput') as HTMLInputElement).value = "";
        } 
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.show} onOpened={() => this.onOpen()} size="lg">
                    <ModalHeader>
                        {this.props.creatingNewNews ? t('CreateNewNews') : t('ChangeNewsInformation')}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Row form>
                                            <Col className="field-flex" sm={2}>
                                                <Label className="field-label" for="" size="sm">{t('Title')}{" "}</Label>
                                            </Col>
                                            <Col className="field-flex" sm={10}>
                                                <Input type="text" bssize="sm" id="newsTitleInput" name="modalInput"
                                                    defaultValue={this.props.chosenNews.title}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                       
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Row form>
                                            <Col className="field-flex" sm={2}>
                                                <Label className="field-label" for="" size="sm">{t('Content')}{" "} </Label>
                                            </Col>
                                            <Col className="field-flex" sm={10}>
                                                <Input type="textarea" rows={this.props.chosenNews.content.split("\n").length > 5 ? this.props.chosenNews.content.split("\n").length : 5 } bssize="sm" id="newsContentInput" name="modalInput"
                                                    defaultValue={this.props.chosenNews.content}
                                                    onKeyPress={(event) => this.onKeyPress1(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>

                                    </FormGroup>
                                </Col>

                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col sm={12}>
                                <Button color="primary" onClick={(event) => this.confirmModal(event)}>{t('OK')}</Button>
                                {' '}
                                <Button color="secondary" onClick={(event) => this.onClickToggleShowModal(event)}>{t('Cancel')}</Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.news;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    NewsStore.actionCreators // Selects which action creators are merged into the component's props
)(EditNewsListModal as any);
