﻿import { Action, Reducer } from '../../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './../index';
//import { GasesData } from '../models/GasesData';
//import { GetGasesResponse } from '../models/responses/GetGasesResponse';
import authHeader from '../models/auth-header';
import EventBus from '../eventBus';
import { GetRootProtectionGasResponse } from '../models/responses/GetRootProtectionGasResponse';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface GasesState {
    plasmaGasSearchText: string;
    plasmaGasSearchResult: Array<GetRootProtectionGasResponse>;
    selectedPlasmaGas: GetRootProtectionGasResponse
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

//export interface GetGasesDataReceiveAction {
//    type: 'GET_TYPE_OF_BACKING_DATA_RECEIVE';
//    gasesData: GasesData
//}

export interface SetSelectedPlasmaGasAction {
    type: 'SET_SELECTED_PLASMA_GAS';
    selectedPlasmaGas: GetRootProtectionGasResponse;
}

export interface SetPlasmaGasSearchResultAction {
    type: 'SET_PLASMA_GAS_SEARCH_RESULT';
    plasmaGasSearchResult: Array<GetRootProtectionGasResponse>;
}

export interface SetPlasmaGasSearchTextAction {
    type: 'SET_PLASMA_GAS_SEARCH_TEXT';
    plasmaGasSearchText: string;
}

export interface GetPlasmaGasSearchResultRequestAction {
    type: 'GET_PLASMA_GAS_SEARCH_RESULT_REQUEST';
}

export interface ResetGasesAction {
    type: 'RESET_STATE_GASES_ACTION';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = GetPlasmaGasSearchResultRequestAction
    | SetPlasmaGasSearchResultAction
    | SetPlasmaGasSearchTextAction
    | SetSelectedPlasmaGasAction
    | ResetGasesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_STATE_GASES_ACTION' });
    },
    setWeldingDataPlasmaGasSearchText: (plasmaGasSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'SET_PLASMA_GAS_SEARCH_TEXT', plasmaGasSearchText: plasmaGasSearchText });
        
    },
    getPlasmaGasSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RootProtectionGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetRootProtectionGasResponse>;
                console.info(data);
                const appState = getState();
                if (appState && appState.gases) {
                    let plasmaGasSearchResult: Array<GetRootProtectionGasResponse> = { ...appState.gases.plasmaGasSearchResult };
                    plasmaGasSearchResult = [...data];
                    dispatch({ type: 'SET_PLASMA_GAS_SEARCH_RESULT', plasmaGasSearchResult: plasmaGasSearchResult });
                }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_PLASMA_GAS_SEARCH_RESULT_REQUEST' });
    },
    clearPlasmaGasSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.gases) {
            let plasmaGasSearchResult: Array<GetRootProtectionGasResponse> = { ...appState.gases.plasmaGasSearchResult };
            plasmaGasSearchResult = [];
            dispatch({ type: 'SET_PLASMA_GAS_SEARCH_RESULT', plasmaGasSearchResult: plasmaGasSearchResult });
        }
    },
    setWeldingDataSelectedPlasmaGas: (selectedRootProtectionGas: GetRootProtectionGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.gases) {
            let completeName = selectedRootProtectionGas.content;
            dispatch({ type: 'SET_PLASMA_GAS_SEARCH_TEXT', plasmaGasSearchText: completeName });
            let selectedPlasmaGas: GetRootProtectionGasResponse = { ...appState.gases.selectedPlasmaGas };
            selectedPlasmaGas = { ...selectedRootProtectionGas }
            dispatch({ type: 'SET_SELECTED_PLASMA_GAS', selectedPlasmaGas: selectedPlasmaGas});
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: GasesState = {
    plasmaGasSearchText: '',
    plasmaGasSearchResult: [],
    selectedPlasmaGas: {
        id: 0,
        name: '',
        content: '',
        description: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    }
};

export const reducer: Reducer<GasesState> = (state: GasesState | undefined, incomingAction: Action): GasesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_PLASMA_GAS_SEARCH_TEXT':
            return {
                plasmaGasSearchText: action.plasmaGasSearchText,
                plasmaGasSearchResult: state.plasmaGasSearchResult,
                selectedPlasmaGas: state.selectedPlasmaGas
            };
        case 'SET_PLASMA_GAS_SEARCH_RESULT':
            return {
                plasmaGasSearchText: state.plasmaGasSearchText,
                plasmaGasSearchResult: action.plasmaGasSearchResult,
                selectedPlasmaGas: state.selectedPlasmaGas
            };
        case 'SET_SELECTED_PLASMA_GAS':
            return {
                plasmaGasSearchText: state.plasmaGasSearchText,
                plasmaGasSearchResult: state.plasmaGasSearchResult,
                selectedPlasmaGas: action.selectedPlasmaGas
            };
        case 'RESET_STATE_GASES_ACTION':
            return unloadedState;
        default:
            return state;
    }
};
