﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,} from 'reactstrap';
import { ApplicationState } from '../../store/index';
import * as FileUploadStore from '../../store/FileUploadStore';
import { t } from '../../i18n';
import Loader from '.././Loader';
import { UploadFileResponse } from '../../store/models/responses/UploadFileResponse';
import LogoImage from '../LogoImage';
import { FilesUpload } from '../../store/models/FilesUpload';
// At runtime, Redux will merge together...
type ImageUploadProps =
    FileUploadStore.FileUploadState // ... state we've requested from the Redux store
    & typeof FileUploadStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    selectedImage?: Array<UploadFileResponse>;
}

class ImageUpload extends React.PureComponent<ImageUploadProps> {
    constructor(props: ImageUploadProps) {
        super(props);
    }
    public state = {
        selectedSequenceValue: "",
        selectedSequenceNumber: 0,
        showStandardImagesModal: false,
        selectWeldSequenceImageId: 0,
        createSequenceImage: false
    };

    private toggleStandardImagesModal = () => {
        this.setState({
            showStandardImagesModal: !this.state.showStandardImagesModal
        });
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        // Clear all store
        this.props.clearState();
        this.props.setStandardImages();
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.selectedImage !== this.props.selectedImage) {
            this.props.setImageUpload(this.props.selectedImage);
        }
    }

    private handleChange = (event: any) => {
        event.preventDefault();
        this.props.uploadImage(event.target.files)
    };

    private onClickShowStandardImagesModal = () => {
        this.toggleStandardImagesModal();
    };

    private onClickModalCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.toggleStandardImagesModal();
    }

    private onClickModalOK(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        //this.props.setSequenceNumber(this.state.selectedSequenceValue, this.state.selectedSequenceNumber);
        //this.setSelectedSequenceValueDefault()
        this.toggleStandardImagesModal()
    }

    private selectWplanStandardImage(selectImage: FilesUpload) {
        this.toggleStandardImagesModal()
        this.props.setStandardImageToFileUpload(selectImage);
    }
    private selectCloseImage(rowNumber: number) {
        this.props.setCloseImage(rowNumber);
    }
    
    public render() {
        return (
            <React.Fragment>
                    <Card>
                        <Loader show={this.props.enablePdf} title={t('Working')} />
                        <CardBody>
                        <div>
                            <Row form>
                                {this.props.selectedFileUpload.map((row, index) => (
                                    this.renderSelectedImages(index + 1)
                                ))}
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    { this.renderStandardImagesModal() }
                                    <br />
                                </Col>
                            </Row>
                                <Row form>
                                <Col sm={12} className="text-center">
                                    <FormGroup>
                                        <Button color="primary" onClick={(event) => this.onClickShowStandardImagesModal()}>{t('Images')}</Button><span>&nbsp;</span>
                                            <Button className="btn btn-primary btn-file" >
                                            {t('Upload')}<input type="file" multiple onChange={event => this.handleChange(event)} />
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card> 
            </React.Fragment>
        );
    }
    private renderStandardImage(rowNumber: number) {
        return (
            <React.Fragment key={"StandardImages" + rowNumber}>
                <Col sm={3}>
                    <FormGroup>
                        <Button onClick={() => this.selectWplanStandardImage(this.props.standardImages[rowNumber - 1])}><img className={"image-auto-with-height"} src={this.props.standardImages[rowNumber - 1].fileUrl} alt="image1" /></Button>
                    </FormGroup>
                    <br></br>
                </Col>
            </React.Fragment>
        );
    }
    private renderSelectedImages(rowNumber: number) {
        return (
            <React.Fragment key={"SelectedImages" + rowNumber}>
                <Col sm={6} className="text-center image-auto-with">
                    <div className="img-wrap">
                        <Button className="close" onClick={() => this.selectCloseImage(rowNumber)}>&times;</Button>
                        <FormGroup>
                            <img className="image-weld-sequence" alt="Dekra" src={this.props.selectedFileUpload[rowNumber - 1].fileUrl} />
                        </FormGroup>
                        <br></br>
                    </div>
                </Col>
            </React.Fragment>
        );
    }
    private renderStandardImagesModal() {
        const scrollContainerStyle = { width: "800px", maxHeight: "400px" };
        return (
            <React.Fragment>
                <Modal size="lg" isOpen={this.state.showStandardImagesModal} >
                    <ModalHeader>
                        {t('Images')}!
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12} className="scrollbar" style={scrollContainerStyle}>
                                <Card>
                                    <CardBody>
                                        <CardText>
                                            <Row form>
                                                {this.props.standardImages.map((row, index) => (
                                                    this.renderStandardImage(index + 1)
                                                ))}
                                            </Row>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickModalOK(event)}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.fileUpload;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    FileUploadStore.actionCreators // Selects which action creators are merged into the component's props
)(ImageUpload as any);
