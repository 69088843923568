
import * as React from 'react';
import '../customized.css';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import { t } from '../i18n';

export default class ModalWpsType extends React.PureComponent<{ openModal: boolean, parentCallback: (isOpenModal: boolean, id: number) => void, calcelModalCallback: (isOpenModal: boolean) => void;}, { isOpenModal: boolean, selectedTypeId: number }> {
    public state = {
        isOpenModal: this.props.openModal,
        selectedTypeId: 0,
    };

    public render() {
        return (
            <Modal isOpen={this.props.openModal}>
                    <ModalHeader>
                    {t('ChooseWPSType')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="radio" bssize="sm" id={"wpsTypeId" + "0"} name="wpsTypeId"
                                            value={0}
                                        checked={this.state.selectedTypeId === 0}
                                            onChange={(event) => this.setSelectedTypeId(event)}
                                            placeholder="" />{' '} {'pWPS'}
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="radio" bssize="sm" id={"wpsTypeId" + "-1"} name="wpsTypeId"
                                            value={-1}
                                            checked={this.state.selectedTypeId === -1}
                                            onChange={(event) => this.setSelectedTypeId(event)}
                                            placeholder="" />{' '} {'WPS'}
                                    </Label>
                                    <span>&nbsp;</span>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickWpsTypeIdModalOK(event)}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickWpsTypeIdModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
        );
    }

    private onClickWpsTypeIdModalOK = (event: React.MouseEvent) => {
        this.setState({
            isOpenModal: false
        });
        this.props.parentCallback(this.state.isOpenModal, this.state.selectedTypeId);
    }
    private onClickWpsTypeIdModalCancel = (event: React.MouseEvent) => {
        this.setState({
            isOpenModal: false
        });
        this.props.calcelModalCallback(this.state.isOpenModal);
    }
    private setSelectedTypeId = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selectedTypeId: parseInt(event.currentTarget.value)
        });
    }
}
