﻿export class BendTestingResultsTableRow {
    acceptance: string;
    bendingAngle: string;
    diameterOfRoller: string;
    distanceBetweenRollers: string;
    surfaceCondition: string;
    testSpecimenDimensions: string;
    testSpecimenNumber: string;
    testSpecimenType: string;

    public constructor(testSpecimenNumber: string, testSpecimenType: string, testSpecimenDimensions: string, diameterOfRoller: string, distanceBetweenRollers: string, bendingAngle: string, surfaceCondition: string, acceptance: string) {
        this.testSpecimenNumber = testSpecimenNumber;
        this.testSpecimenType = testSpecimenType;
        this.testSpecimenDimensions = testSpecimenDimensions;
        this.diameterOfRoller = diameterOfRoller;
        this.distanceBetweenRollers = distanceBetweenRollers;
        this.bendingAngle = bendingAngle;
        this.surfaceCondition = surfaceCondition;
        this.acceptance = acceptance;
    }
}