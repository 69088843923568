﻿import { GetBaseMaterialResponse } from "./GetBaseMaterialResponse";
import { GetTechnicalDeliveryConditionResponse } from "./GetTechnicalDeliveryConditionResponse";

export class GetParentMaterialResponse {
    baseMaterial1: GetBaseMaterialResponse;
    baseMaterial2: GetBaseMaterialResponse;
    technicalDeliveryCondition1: GetTechnicalDeliveryConditionResponse;
    technicalDeliveryCondition2: GetTechnicalDeliveryConditionResponse;
    diameter1: number;
    diameter2: number;
    createdAt: string;
    deletedAt: string;
    id: number;
    materialThickness1: number;
    materialThickness2: number;
    enableBaseMaterial1Text: boolean;
    enableBaseMaterial2Text: boolean;
    baseMaterial1Text: string;
    baseMaterial2Text: string;
    materialGroup1Text: string;
    materialGroup2Text: string;
    technicalDeliveryCondition1Text: string;
    technicalDeliveryCondition2Text: string;
    enableMaterialThickness: boolean;
    materialThickness1Text: string;
    materialThickness2Text: string;
    enableDiameter: boolean;
    diameter1Text: string;
    diameter2Text: string;
    updatedAt: string;

    public constructor() {
        this.baseMaterial1 = new GetBaseMaterialResponse();
        this.baseMaterial2 = new GetBaseMaterialResponse();
        this.technicalDeliveryCondition1 = new GetTechnicalDeliveryConditionResponse();
        this.technicalDeliveryCondition2 = new GetTechnicalDeliveryConditionResponse();
        this.diameter1 = 0.0;
        this.diameter2 = 0.0;
        this.createdAt = '';
        this.deletedAt = '';
        this.id = 0;
        this.materialThickness1 = 0.0;
        this.materialThickness2 = 0.0;
        this.enableBaseMaterial1Text = false;
        this.enableBaseMaterial2Text = false;
        this.baseMaterial1Text = '';
        this.baseMaterial2Text = '';
        this.materialGroup1Text = '';
        this.materialGroup2Text = '';
        this.technicalDeliveryCondition1Text = '';
        this.technicalDeliveryCondition2Text = '';
        this.updatedAt = '';
        this.enableMaterialThickness = false;
        this.materialThickness1Text = '';
        this.materialThickness2Text = '';
        this.enableDiameter = false;
        this.diameter1Text = '';
        this.diameter2Text = '';
    }
}