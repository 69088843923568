﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Label, ListGroup, ListGroupItem} from 'reactstrap';
import { t } from '../../i18n';
import { ApplicationState } from '../../store/index';
import { GetRootProtectionGasResponse } from '../../store/models/responses/GetRootProtectionGasResponse';
import * as GasesStore from '../../store/otherStore/GasesStore';
// At runtime, Redux will merge together...
type GasesProps =
    GasesStore.GasesState // ... state we've requested from the Redux store
    & typeof GasesStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    enableGasesTextView?: boolean;
    pSelectedPlasmaGas: GetRootProtectionGasResponse;
    setSelectedPlasmaGas: (selectedPlasmaGas: GetRootProtectionGasResponse) => void;
}

class Gases extends React.PureComponent<GasesProps> {
    constructor(props: GasesProps) {
        super(props);
    }
    public state = {
        dropdownOpen: false,
        isAuth: true
    };
    // This method is called when the component is first added to the document
    public componentDidMount() {
        // Clear all store
        this.props.clearState();
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.pSelectedPlasmaGas !== this.props.pSelectedPlasmaGas) {
            this.props.setWeldingDataSelectedPlasmaGas(this.props.pSelectedPlasmaGas);
        }
    }

    private onChangeWeldingDataPlasmaGasSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataPlasmaGasSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getPlasmaGasSearchResult(event.currentTarget.value);
        }
        else if (event.currentTarget.value.length === 0)
        {
            // Clear
            let clearSelectedPlasmaGas: GetRootProtectionGasResponse = {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            }
            this.props.setSelectedPlasmaGas(clearSelectedPlasmaGas);
        }
        else {
            this.props.clearPlasmaGasSearchResult();
        }
    }

    private onClickWeldingDataSelectPlasmaGas(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, rootProtectionGas: GetRootProtectionGasResponse) {
        event.preventDefault();
        this.props.clearPlasmaGasSearchResult();
        this.props.setWeldingDataSelectedPlasmaGas(rootProtectionGas);
        this.props.setSelectedPlasmaGas(rootProtectionGas);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for="weldingDataPlasmaGases" size="sm">{t('PlasmaGas')}</Label>
                    <Input disabled={this.props.enableGasesTextView} autoComplete="off" type="search" bsSize="sm" name="weldingDataPlasmaGases" id="weldingDataPlasmaGases"
                        value={this.props.plasmaGasSearchText}
                        onChange={(event) => this.onChangeWeldingDataPlasmaGasSearchText(event)}
                        onKeyPress={(event) => this.onKeyPress(event)}
                        placeholder="" />
                    {this.props.plasmaGasSearchResult.length !== 0 &&
                        <ListGroup className="scroll-vertical">
                            {this.props.plasmaGasSearchResult.map((rootProtectionGas, index) => (
                                <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingDataSelectPlasmaGas(event, rootProtectionGas)}>
                                    {rootProtectionGas.name}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    }
                </FormGroup>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.gases;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    GasesStore.actionCreators // Selects which action creators are merged into the component's props
)(Gases as any);