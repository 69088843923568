﻿import { Header } from "../Header";
import { ParentMaterial } from "./ParentMaterial";
import { GetApplicationResponse } from "./responses/GetApplicationResponse";
import { GetPWPSResponse } from "./responses/GetPWPSResponse";
import { WeldingData } from "./WeldingData";
import { WeldingParameters } from "./WeldingParameters";
import { WeldingProcess } from "./WeldingProcess";

export default class HelpFunctions {
    static addRowsAndNumbers(name1: string, name2: string) {

        switch (name1) {
            case null: {
                name1 = '';
                break;
            }
            case "0": {
                name1 = '';
                break;
            }
            default: {
                break;
            }
        }
        switch (name2) {
            case null: {
                name2 = '';
                break;
            }
            case "0": {
                name2 = '';
                break;
            }
            default: {
                break;
            }
        }

        if (name1 !== '' && name2 !== '') {
            if (name1 === name2) {
                return name1;
            } else {
                return "1. " + name1 + "\n2. " + name2;
            }
        }
        else if (name1 === '' && name2 === '') {
            return "";
        }
        else if (name1 === '') {
            return name2;
        }
        else if (name2 === '') {
            return name1;
        }
        else {
            return "";
        }
    };

    static setZeroIfInvinity(numberValue: number) {
        if (numberValue === Infinity) {
            numberValue = 0;
        } else if (isNaN(numberValue)) {
            numberValue = 0;
        }
        return numberValue;
    }


    // Base
    // TensileEnergyAverage (träckenergi medelvärde)
    static baseTensileEnergyAverage(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let currentActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].current.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].current.replace(',', '.')) : 0.0;
        let voltageActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].voltage.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].voltage.replace(',', '.')) : 0.0;
        let efficiencyFactorActive: number = weldingParameters[rowNr - 1].weldingMethod !== null ? weldingParameters[rowNr - 1].weldingMethod.efficiencyFactor : 0;
        let travelSpeedActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].travelSpeed.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].travelSpeed.replace(',', '.')) : 0.0;
        //L15*C15*60*F23/F15/1000
        //math form
        let heatInput = ((currentActive * voltageActive * 60.0) / (travelSpeedActive * 1000)) * efficiencyFactorActive;
        heatInput = this.setZeroIfInvinity(heatInput);
        weldingParameters[rowNr - 1].heatInput = heatInput.toFixed(2).replace('.', ',');
        return weldingParameters;
    }

    // Pulse
    static setPulseParameters(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        
        weldingParameters = HelpFunctions.pulseTime1(weldingParameters, rowNr);
        weldingParameters = HelpFunctions.pulseCurrent1(weldingParameters, rowNr);
        weldingParameters = HelpFunctions.pulseCurrentForm(weldingParameters, rowNr);
        weldingParameters = HelpFunctions.pulseHeatInput1(weldingParameters, rowNr);
        weldingParameters = HelpFunctions.pulseTensileEnergyAverage(weldingParameters, rowNr);
        weldingParameters = HelpFunctions.pulseClean(weldingParameters, rowNr);

        return weldingParameters;
    }

    static pulseClean(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let pulseCurrentActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].timeForBasicCurrent.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].timeForBasicCurrent.replace(',', '.')) : 0.0;
        let frequencyActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].frequency.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].frequency.replace(',', '.')) : 0.0;
        if (pulseCurrentActive === 0.0) {
            weldingParameters[rowNr - 1].current = "";
            weldingParameters[rowNr - 1].heatInput = "";
        }

        if (frequencyActive === 0.0) {
            weldingParameters[rowNr - 1].time1 = "";
            weldingParameters[rowNr - 1].current1 = "";
            weldingParameters[rowNr - 1].heatInput1 = "";
        }

        return weldingParameters;
    }

    static pulseCurrentForm(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let pulseCurrentActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].pulseCurrent.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].pulseCurrent.replace(',', '.')) : 0.0;
        let pulseTimeActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].pulseTime.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].pulseTime.replace(',', '.')) : 0.0;
        let basicCurrentActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].basicCurrent.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].basicCurrent.replace(',', '.')) : 0.0;
        let timeForBasicCurrentActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].timeForBasicCurrent.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].timeForBasicCurrent.replace(',', '.')) : 0.0;
        //(Ip*tp + Ig*tg)/(tp + tg) and tp = tp * 0.001
        //let current = (((pulseCurrentActive * pulseTimeActive * 0.001) + (basicCurrentActive * timeForBasicCurrentActive)) / ((pulseTimeActive * 0.001) + timeForBasicCurrentActive))
        let current = (((pulseCurrentActive * pulseTimeActive) + (basicCurrentActive * timeForBasicCurrentActive)) / (pulseTimeActive + timeForBasicCurrentActive));
        current = this.setZeroIfInvinity(current);
        weldingParameters[rowNr - 1].current = current.toFixed(2).replace('.', ',');

        return weldingParameters;
    }

    // TensileEnergyAverage (träckenergi medelvärde)
    static pulseTensileEnergyAverage(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let currentActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].current.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].current.replace(',', '.')) : 0.0;
        let voltageActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].voltage.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].voltage.replace(',', '.')) : 0.0;
        //let efficiencyFactorActive: number = weldingParameters[rowNr - 1].weldingMethod !== null ? weldingParameters[rowNr - 1].weldingMethod.efficiencyFactor : 0;
        let travelSpeedActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].travelSpeed.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].travelSpeed.replace(',', '.')) : 0.0;
        //L15*C15*60*F23/F15/1000
        //math form
        //let heatInput = ((currentActive * voltageActive * 60.0) / (travelSpeedActive * 1000)) * efficiencyFactorActive;
        let heatInput = (currentActive * voltageActive * 60.0) / (travelSpeedActive * 1000);
        heatInput = this.setZeroIfInvinity(heatInput);
        weldingParameters[rowNr - 1].heatInput = heatInput.toFixed(2).replace('.', ',');
        return weldingParameters;
    }

    static pulseTime1(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let frequencyActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].frequency.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].frequency.replace(',', '.')) : 0.0;
        let pulseTimeActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].pulseTime.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].pulseTime.replace(',', '.')) : 0.0;
        // Math form
        let time1 = ((1 / frequencyActive) - (pulseTimeActive));
        time1 = this.setZeroIfInvinity(time1);

        weldingParameters[rowNr - 1].time1 = time1.toFixed(5).replace('.', ',');
        return weldingParameters;
    }

    static pulseCurrent1(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let pulseCurrentActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].pulseCurrent.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].pulseCurrent.replace(',', '.')) : 0.0;
        let pulseTimeActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].pulseTime.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].pulseTime.replace(',', '.')) : 0.0;
        let basicCurrentActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].basicCurrent.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].basicCurrent.replace(',', '.')) : 0.0;
        let time1Active: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].time1.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].time1.replace(',', '.')) : 0.0;
        //(Ip*tp + Ig*tg)/(tp + tg) 
        //let current1 = ((pulseCurrentActive * pulseTimeActive * 0.001 * 0.001) + (basicCurrentActive * time1Active * 0.001)) / ((pulseTimeActive * 0.001 + time1Active) * 0.001)
        let current1 = ((pulseCurrentActive * pulseTimeActive) + (basicCurrentActive * time1Active)) / ((pulseTimeActive + time1Active))
        current1 = this.setZeroIfInvinity(current1);
        weldingParameters[rowNr - 1].current1 = current1.toFixed(2).replace('.', ',');
        return weldingParameters;
    }

    static pulseHeatInput1(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let current1Active: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].current1.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].current1.replace(',', '.')) : 0.0;
        let voltageActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].voltage.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].voltage.replace(',', '.')) : 0.0;
        //let efficiencyFactorActive: number = weldingParameters[rowNr - 1].weldingMethod !== null ? weldingParameters[rowNr - 1].weldingMethod.efficiencyFactor : 0;
        let travelSpeedActive: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].travelSpeed.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].travelSpeed.replace(',', '.')) : 0.0;

        //let heatInput1 = ((current1Active * voltageActive * 60.0) / (travelSpeedActive * 1000)) * efficiencyFactorActive;
        let heatInput1 = (current1Active * voltageActive * 60.0) / (travelSpeedActive * 1000);
        heatInput1 = this.setZeroIfInvinity(heatInput1);
        weldingParameters[rowNr - 1].heatInput1 = heatInput1.toFixed(2).replace('.', ',');
        return weldingParameters;
    }

    // Arc energy
    static setArcParameters(weldingParameters: Array<WeldingParameters>, rowNr: number) {

        ///weldingParameters = HelpFunctions.arcTravelSpeed(weldingParameters, rowNr);
        weldingParameters = HelpFunctions.arcEnergyIE(weldingParameters, rowNr);
        weldingParameters = HelpFunctions.arcEnergyIP(weldingParameters, rowNr);

        return weldingParameters;
    }

    static arcTravelSpeed(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let runLength: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].runLength.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].runLength.replace(',', '.')) : 0.0;
        let time: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].time.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].time.replace(',', '.')) : 0.0;

        let travelSpeed = (runLength * 60.0) / time;
        travelSpeed = this.setZeroIfInvinity(travelSpeed);

        weldingParameters[rowNr - 1].travelSpeed = travelSpeed.toFixed(2).replace('.', ',');
        return weldingParameters;
    }

    static arcEnergyIE(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let runLength: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].runLength.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].runLength.replace(',', '.')) : 0.0;
        let arcIE: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].arcIE.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].arcIE.replace(',', '.')) : 0.0;

        let arcEnergyIE = ((arcIE) / runLength) * 0.001;
        arcEnergyIE = this.setZeroIfInvinity(arcEnergyIE);

        weldingParameters[rowNr - 1].arcEnergyIE = arcEnergyIE.toFixed(2).replace('.', ',');
        return weldingParameters;
    }

    static arcEnergyIP(weldingParameters: Array<WeldingParameters>, rowNr: number) {
        // If not number set to 0.0
        let travelSpeed: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].travelSpeed.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].travelSpeed.replace(',', '.')) : 0.0;
        let arcIP: number = !isNaN(parseFloat(weldingParameters[rowNr - 1].arcIP.replace(',', '.'))) ? parseFloat(weldingParameters[rowNr - 1].arcIP.replace(',', '.')) : 0.0;

        //let arcEnergyIP = (arcIP / (travelSpeed * 60.0);
        let arcEnergyIP = ((arcIP * 60) / travelSpeed) * 0.001;
        arcEnergyIP = this.setZeroIfInvinity(arcEnergyIP);

        weldingParameters[rowNr - 1].arcEnergyIP = arcEnergyIP.toFixed(2).replace('.', ',');
        return weldingParameters;
    }

    static arrayJoinToString(arraList: Array<string>) {
        let weldingMethodsValues = "";
        for (let i = 0; i < arraList.length; i++) {
            if (arraList.length - 1 === i && weldingMethodsValues !== "") {
                weldingMethodsValues = weldingMethodsValues + "\n"
            }
            weldingMethodsValues = weldingMethodsValues + arraList[i];
        }
        return weldingMethodsValues;
    }

    // Joint two string with " / "
    static JoinTwoString(stringA: string, stringB: string) {
        let addString = " / ";

        const jointTwoString = stringA + addString + stringB;

        let lastTree = jointTwoString.substr(jointTwoString.length - 3);
        let lastPartEmpty = lastTree.split(addString)
        let result = "";

        if (lastPartEmpty.length > 1 && lastPartEmpty[1] === "") {
            result = jointTwoString.replace(addString, '');
        }
        else {
            let firstTree = jointTwoString.substr(0, 3);
            let firstPartEmpty = firstTree.split(addString)
            if (firstPartEmpty.length > 1 && firstPartEmpty[0] === "") {
                result = jointTwoString.replace(addString, '');
            }
            else {
                result = jointTwoString;
            }

        }
        return result;
    }

    // Join diameter and materialThickness
    static JoinDiameterAndMaterialThicknes(diameter: string, materialThickness: string) {
        let result = "";
        if (diameter === "") {
            result = materialThickness;
        } else {
            result = "Ø" + diameter + "x" + materialThickness;
        }
        return result;
    }

    // HeatInputMax and heatInputMin
    static SetHeatInputMaxAndMin(weldingParameter: WeldingParameters) {
        // If not number set to 0.0
        let efficiencyFactorActive: number = weldingParameter.weldingMethod !== null ? weldingParameter.weldingMethod.efficiencyFactor : 0;

        let voltagex: number = !isNaN(parseFloat(weldingParameter.voltageX.replace(',', '.'))) ? parseFloat(weldingParameter.voltageX.replace(',', '.')) : 0.0;
        let voltage: number = !isNaN(parseFloat(weldingParameter.voltage.replace(',', '.'))) ? parseFloat(weldingParameter.voltage.replace(',', '.')) : 0.0;
        let voltagexFactor: number = voltagex === 0 ? 0 : 1;
        weldingParameter.voltageMax = voltagexFactor === 0 ? "" : HelpFunctions.setStringZerosToEmpty(((voltagexFactor + (voltagex / 100)) * voltage).toFixed(1).replace('.', ','));
        weldingParameter.voltageMin = voltagexFactor === 0 ? "" : HelpFunctions.setStringZerosToEmpty(((voltagexFactor - (voltagex / 100)) * voltage).toFixed(1).replace('.', ','));

        let travelSpeedz: number = !isNaN(parseFloat(weldingParameter.travelSpeedZ.replace(',', '.'))) ? parseFloat(weldingParameter.travelSpeedZ.replace(',', '.')) : 0.0;
        let travelSpeed: number = !isNaN(parseFloat(weldingParameter.travelSpeed.replace(',', '.'))) ? parseFloat(weldingParameter.travelSpeed.replace(',', '.')) : 0.0;
        let travelSpeedzFactor: number = travelSpeedz === 0 ? 0 : 1;
        weldingParameter.travelSpeedMax = travelSpeedzFactor === 0 ? "" : HelpFunctions.setStringZerosToEmpty(((travelSpeedzFactor + (travelSpeedz / 100)) * travelSpeed).toFixed(1).replace('.', ','));
        weldingParameter.travelSpeedMin = travelSpeedzFactor === 0 ? "" : HelpFunctions.setStringZerosToEmpty(((travelSpeedzFactor - (travelSpeedz / 100)) * travelSpeed).toFixed(1).replace('.', ','));

        let currenty: number = !isNaN(parseFloat(weldingParameter.currentY.replace(',', '.'))) ? parseFloat(weldingParameter.currentY.replace(',', '.')) : 0.0;
        let current: number = !isNaN(parseFloat(weldingParameter.current.replace(',', '.'))) ? parseFloat(weldingParameter.current.replace(',', '.')) : 0.0;
        let currentyFactor: number = currenty === 0 ? 0 : 1;
        weldingParameter.currentMax = currentyFactor === 0 ? "" : HelpFunctions.setStringZerosToEmpty(((currentyFactor + (currenty / 100)) * current).toFixed(1).replace('.', ','));
        weldingParameter.currentMin = currentyFactor === 0 ? "" : HelpFunctions.setStringZerosToEmpty(((currentyFactor - (currenty / 100)) * current).toFixed(1).replace('.', ','));

        let voltageMax: number = !isNaN(parseFloat(weldingParameter.voltageMax.replace(',', '.'))) ? parseFloat(weldingParameter.voltageMax.replace(',', '.')) : 0.0;
        let currentMax: number = !isNaN(parseFloat(weldingParameter.currentMax.replace(',', '.'))) ? parseFloat(weldingParameter.currentMax.replace(',', '.')) : 0.0;
        let travelSpeedMin: number = !isNaN(parseFloat(weldingParameter.travelSpeedMin.replace(',', '.'))) ? parseFloat(weldingParameter.travelSpeedMin.replace(',', '.')) : 0.0;

        let heatInputMax = ((currentMax * voltageMax * 60.0) / (travelSpeedMin * 1000)) * efficiencyFactorActive;
        heatInputMax = isNaN(heatInputMax) ? 0 : heatInputMax;
        heatInputMax = this.setZeroIfInvinity(heatInputMax);
        weldingParameter.heatInputMax = heatInputMax === 0 ? "" : HelpFunctions.setStringZerosToEmpty(heatInputMax.toFixed(2).replace('.', ','));

        let voltageMin: number = !isNaN(parseFloat(weldingParameter.voltageMin.replace(',', '.'))) ? parseFloat(weldingParameter.voltageMin.replace(',', '.')) : 0.0;
        let currentMin: number = !isNaN(parseFloat(weldingParameter.currentMin.replace(',', '.'))) ? parseFloat(weldingParameter.currentMin.replace(',', '.')) : 0.0;
        let travelSpeedMax: number = !isNaN(parseFloat(weldingParameter.travelSpeedMax.replace(',', '.'))) ? parseFloat(weldingParameter.travelSpeedMax.replace(',', '.')) : 0.0;
        let heatInputMin = ((currentMin * voltageMin * 60.0) / (travelSpeedMax * 1000)) * efficiencyFactorActive;
        heatInputMin = isNaN(heatInputMin) ? 0 : heatInputMin;
        heatInputMin= this.setZeroIfInvinity(heatInputMin);
        weldingParameter.heatInputMin = heatInputMin === 0 ? "" : HelpFunctions.setStringZerosToEmpty(heatInputMin.toFixed(2).replace('.', ','));

        return weldingParameter;
    }
    ///
    static putNameAndNumberTogether(name: string, number: string) {
        if (number === "") {
            return name;
        }
        return name + "/" + number;
    }

    // Validation imageUpload
    static fileValidation(file: any) {
        let errorFile = "";
        // Allowing file type
        var allowedExtensions =
            /(\.jpg|\.jpeg|\.png|\.gif)$/i;

        if (!allowedExtensions.exec(file.name)) {
            errorFile = "type"; // 
            return errorFile
        }
        else if (file.size > 20000) {
            errorFile = "size";
            return errorFile
        } else {
            return errorFile
        }
    }

    // joint two string with " / " only if the strings a different
    static joinTwoStringIfTheyAreDifferent(stringA: string, stringB: string) {
        let result = "";
        // Write only one value
        if (stringA === stringB) {
            stringB = "";
        }

        let addString = " / ";
        if (stringA === "") {
            addString = "";
        }

        result = stringA + ((stringB === "" || stringB === null) ? "" : addString) + stringB;
        return result;
    }

    // joint two string with ", " 
    static joinTwoStringWithComma(stringA: string, stringB: string) {
        let result = "";

        let addString = ", ";
        if (stringA === "" || stringA === null) {
            addString = "";
        }

        result = stringA + ((stringB === "" || stringB === null) ? "" : addString) + stringB;
        return result;
    }

    // get the number of the string
    static getNumberOfString(stringA: string) {
        let result = 0;
        var intRegex = /\d+((.|,)\d+)?/
        var number3 = stringA.match(intRegex);
        result = number3 === null ? 0.0 : parseFloat(number3[0]);
        return result;
    }

    // ZeroToEmpty
    static setZeroToEmpty(nr: number) {

        let nrEmpty = nr.toString().replace('.', ',');
        if (nrEmpty === "0") {
            nrEmpty = "";
        }

        return nrEmpty;
    }

    // StringZerosToEmpty
    static setStringZerosToEmpty(stringnr: string) {

        let nrEmpty = stringnr;
        if (nrEmpty === "0,0") {
            nrEmpty = "";
        }
        else if (nrEmpty === "0,00") {
            nrEmpty = "";
        }
        else if (nrEmpty === "0") {
            nrEmpty = "";
        }

        return nrEmpty;
    }

    static SetHeaderApplication(header: Header, selectedApplicationPwps: GetPWPSResponse) {
        header.date = selectedApplicationPwps.date;
        header.project = selectedApplicationPwps.projectNumber;
        header.revisionNumber = selectedApplicationPwps.revisionNumber
        header.pwpsNumber = selectedApplicationPwps.pwpsNumber
        header.wpqrNumber = selectedApplicationPwps.wpqrNumber;
        header.date = selectedApplicationPwps.date;
        header.manufacturer = selectedApplicationPwps.manufacturer;
        header.examiner = selectedApplicationPwps.examiner;
        header.confirmedMessage = selectedApplicationPwps.confirmedMessage;
        header.selectedByStandard = { ...selectedApplicationPwps.byStandard };
        header.otherOptionParametersTableId = selectedApplicationPwps.otherOptionParametersTableId;
        return header;
    }

    static SetWeldingDataApplication(weldingData: WeldingData, selectedApplication: GetApplicationResponse) {
        weldingData.selectedJointPreparationText = selectedApplication.pwps.weldingData.jointPreparationSearchText;
        // JointType.
        if (selectedApplication.pwps.weldingData.jointType && selectedApplication.pwps.weldingData.jointType.id !== 0) {
            weldingData.selectedJointType = { ...selectedApplication.pwps.weldingData.jointType };
        }
        else {
            weldingData.selectedJointType = {
                id: 0,
                name: '',
                description: '',
                jointShapes: [],
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        weldingData.maxWidthOfRun = selectedApplication.pwps.weldingData.maxWidthOfRun;
        // RootSupportDetails.
        if (selectedApplication.pwps.weldingData.rootSupportDetails && selectedApplication.pwps.weldingData.rootSupportDetails.id !== 0) {
            weldingData.selectedRootSupportDetails = { ...selectedApplication.pwps.weldingData.rootSupportDetails };
        }
        else {
            weldingData.selectedRootSupportDetails = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        weldingData.stickOut = selectedApplication.pwps.weldingData.stickOut;
        weldingData.electrodeTorchAngle = selectedApplication.pwps.weldingData.electrodeTorchAngle;
        weldingData.nozzleDiameter = selectedApplication.pwps.weldingData.nozzleDiameter;
        weldingData.electrodeTorchAngle111 = selectedApplication.pwps.weldingData.electrodeTorchAngle111;
        weldingData.plasmaflowrate = selectedApplication.pwps.weldingData.plasmaflowrate;
        weldingData.tungstenElectrode = selectedApplication.pwps.weldingData.tungstenElectrode;
        weldingData.tackWeldText = selectedApplication.pwps.weldingData.tackWeldText;
        weldingData.lengthTackWelds = selectedApplication.pwps.weldingData.lengthTackWelds;
        // WeldingPosition.
        if (selectedApplication.pwps.weldingData.weldingPosition && selectedApplication.pwps.weldingData.weldingPosition.id !== 0) {
            weldingData.selectedWeldingPosition = { ...selectedApplication.pwps.weldingData.weldingPosition };
        }
        else {
            weldingData.selectedWeldingPosition = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        // WeldingPosition 2.
        if (selectedApplication.pwps.weldingData.weldingPosition2 && selectedApplication.pwps.weldingData.weldingPosition2.id !== 0) {
            weldingData.selectedWeldingPosition2 = { ...selectedApplication.pwps.weldingData.weldingPosition2 };
        }
        else {
            weldingData.selectedWeldingPosition2 = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        // RootProtectionGas.
        if (selectedApplication.pwps.weldingData.rootProtectionGas && selectedApplication.pwps.weldingData.rootProtectionGas.id !== 0) {
            weldingData.rootProtectionGasSearchText = selectedApplication.pwps.weldingData.rootProtectionGas.content;
            weldingData.rootProtectionGasSearchResult = [];
            weldingData.selectedRootProtectionGas = { ...selectedApplication.pwps.weldingData.rootProtectionGas };
        }
        else {
            weldingData.rootProtectionGasSearchText = '';
            weldingData.rootProtectionGasSearchResult = [];
            weldingData.selectedRootProtectionGas = {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        // PlasmaGas.
        if (selectedApplication.pwps.weldingData.plasmaGas && selectedApplication.pwps.weldingData.plasmaGas.id !== 0) {
            weldingData.selectedPlasmaGas = { ...selectedApplication.pwps.weldingData.plasmaGas };
        }
        else {
            weldingData.selectedPlasmaGas = {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        return weldingData;
    }

    static SetWeldingProcessApplication(weldingProcess: WeldingProcess, selectedApplication: GetApplicationResponse) {
        // WeldingMethod1.
        if (selectedApplication.pwps.weldingProcess.weldingMethod1 && selectedApplication.pwps.weldingProcess.weldingMethod1.id !== 0) {
            weldingProcess.weldingMethodASearchText = selectedApplication.pwps.weldingProcess.weldingMethod1.name;
            weldingProcess.weldingMethodASearchResult = [];
            weldingProcess.selectedWeldingMethodA = { ...selectedApplication.pwps.weldingProcess.weldingMethod1 };
        }
        else {
            weldingProcess.weldingMethodASearchText = '';
            weldingProcess.weldingMethodASearchResult = [];
            weldingProcess.selectedWeldingMethodA = {
                id: 0,
                name: '',
                efficiencyFactor: 0.0,
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        // Make1.
        if (selectedApplication.pwps.weldingProcess.make1 && selectedApplication.pwps.weldingProcess.make1.id !== 0) {
            console.log('make1');
            weldingProcess.makeASearchText = selectedApplication.pwps.weldingProcess.make1.name;
            weldingProcess.makeASearchResult = [];
            weldingProcess.selectedMakeA = { ...selectedApplication.pwps.weldingProcess.make1 };
            // Designation1.
            weldingProcess.selectedDesignationA = { ...selectedApplication.pwps.weldingProcess.make1.designation };
            // FmGroup
            weldingProcess.selectedFMGroupA = { ...selectedApplication.pwps.weldingProcess.make1.fmGroup };
        }
        else {
            weldingProcess.makeASearchText = '';
            weldingProcess.makeASearchResult = [];
            weldingProcess.selectedMakeA = {
                id: 0,
                name: '',
                description: '',
                designation: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                fmGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            weldingProcess.selectedDesignationA = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }

        // ShieldingGas1.
        if (selectedApplication.pwps.weldingProcess.shieldingGas1 && selectedApplication.pwps.weldingProcess.shieldingGas1.id !== 0) {
            weldingProcess.shieldingGasASearchText = selectedApplication.pwps.weldingProcess.shieldingGas1.content;
            weldingProcess.shieldingGasASearchResult = [];
            weldingProcess.selectedShieldingGasA = { ...selectedApplication.pwps.weldingProcess.shieldingGas1 };
        }
        else {
            weldingProcess.shieldingGasASearchText = '';
            weldingProcess.shieldingGasASearchResult = [];
            weldingProcess.selectedShieldingGasA = {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }

        // arcType1.
        if (selectedApplication.pwps.weldingProcess.arcType1 && selectedApplication.pwps.weldingProcess.arcType1.id !== 0) {
            weldingProcess.selectedArcTypeA = { ...selectedApplication.pwps.weldingProcess.arcType1 };
        }
        else {
            weldingProcess.selectedArcTypeA = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }

        // WeldingMethod2.
        if (selectedApplication.pwps.weldingProcess.weldingMethod2 && selectedApplication.pwps.weldingProcess.weldingMethod2.id !== 0) {
            console.log('selectedApplication.pwps.weldingProcess.weldingMethod2');
            console.log(selectedApplication.pwps.weldingProcess.weldingMethod2);
            weldingProcess.weldingMethodBSearchText = selectedApplication.pwps.weldingProcess.weldingMethod2.name;
            weldingProcess.weldingMethodBSearchResult = [];
            weldingProcess.selectedWeldingMethodB = { ...selectedApplication.pwps.weldingProcess.weldingMethod2 };
        }
        else {
            weldingProcess.weldingMethodBSearchText = '';
            weldingProcess.weldingMethodBSearchResult = [];
            weldingProcess.selectedWeldingMethodB = {
                id: 0,
                name: '',
                efficiencyFactor: 0.0,
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        // Make2.
        if (selectedApplication.pwps.weldingProcess.make2 && selectedApplication.pwps.weldingProcess.make2.id !== 0) {
            weldingProcess.makeBSearchText = selectedApplication.pwps.weldingProcess.make2.name;
            weldingProcess.makeBSearchResult = [];
            weldingProcess.selectedMakeB = { ...selectedApplication.pwps.weldingProcess.make2 };
            // Designation2.
            weldingProcess.selectedDesignationB = { ...selectedApplication.pwps.weldingProcess.make2.designation };
            // FmGroup
            weldingProcess.selectedFMGroupB = { ...selectedApplication.pwps.weldingProcess.make2.fmGroup };
        }
        else {
            weldingProcess.makeBSearchText = '';
            weldingProcess.makeBSearchResult = [];
            weldingProcess.selectedMakeB = {
                id: 0,
                name: '',
                description: '',
                designation: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                fmGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            weldingProcess.selectedDesignationB = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        // ShieldingGas2.
        if (selectedApplication.pwps.weldingProcess.shieldingGas2 && selectedApplication.pwps.weldingProcess.shieldingGas2.id !== 0) {
            weldingProcess.shieldingGasBSearchText = selectedApplication.pwps.weldingProcess.shieldingGas2.content;
            weldingProcess.shieldingGasBSearchResult = [];
            weldingProcess.selectedShieldingGasB = { ...selectedApplication.pwps.weldingProcess.shieldingGas2 };
        }
        else {
            weldingProcess.shieldingGasBSearchText = '';
            weldingProcess.shieldingGasBSearchResult = [];
            weldingProcess.selectedShieldingGasB = {
                id: 0,
                name: '',
                content: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        // arcType2.
        if (selectedApplication.pwps.weldingProcess.arcType2 && selectedApplication.pwps.weldingProcess.arcType2.id !== 0) {
            weldingProcess.selectedArcTypeB = { ...selectedApplication.pwps.weldingProcess.arcType2 };
        }
        else {
            weldingProcess.selectedArcTypeB = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        weldingProcess.enableMakeAText = selectedApplication.pwps.weldingProcess.enableMakeAText;
        weldingProcess.makeAText = selectedApplication.pwps.weldingProcess.makeAText;
        weldingProcess.designationAText = selectedApplication.pwps.weldingProcess.designationAText;
        weldingProcess.fmGroupAText = selectedApplication.pwps.weldingProcess.fmGroupAText;
        weldingProcess.enableMakeBText = selectedApplication.pwps.weldingProcess.enableMakeBText;
        weldingProcess.makeBText = selectedApplication.pwps.weldingProcess.makeBText;
        weldingProcess.designationBText = selectedApplication.pwps.weldingProcess.designationBText;
        weldingProcess.fmGroupBText = selectedApplication.pwps.weldingProcess.fmGroupBText;

        if (selectedApplication.pwps.weldingProcess.enableMakeAText) {
            weldingProcess.makeASearchText = selectedApplication.pwps.weldingProcess.makeAText;
            weldingProcess.selectedDesignationA.name = selectedApplication.pwps.weldingProcess.designationAText;
        }
        if (selectedApplication.pwps.weldingProcess.enableMakeBText) {
            weldingProcess.makeBSearchText = selectedApplication.pwps.weldingProcess.makeBText;
            weldingProcess.selectedDesignationB.name = selectedApplication.pwps.weldingProcess.designationBText;
        }

        weldingProcess.typeOfBackingText = selectedApplication.pwps.weldingProcess.typeOfBackingText;

        // WeldingMethods
        weldingProcess.weldingMethods = [];
        let weldingMethodEmpty = {
            id: 0,
            name: '',
            efficiencyFactor: 0.0,
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        };
        weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, weldingMethodEmpty);
        weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, selectedApplication.pwps.weldingProcess.weldingMethod1);
        weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, selectedApplication.pwps.weldingProcess.weldingMethod2);
        weldingProcess.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
            if (weldingMethod1.name < weldingMethod2.name) {
                return -1;
            }
            else if (weldingMethod1.name > weldingMethod2.name) {
                return 1;
            }
            return 0;
        });

        return weldingProcess;
    }

    static SetParentMaterialApplication(parentMaterial: ParentMaterial, selectedApplication: GetApplicationResponse) {
        // WeldingMethod1.
        if (selectedApplication.pwps.parentMaterial.baseMaterial1 && selectedApplication.pwps.parentMaterial.baseMaterial1.id !== 0) {
            parentMaterial.baseMaterial1SearchText =
                selectedApplication.pwps.parentMaterial.baseMaterial1.name
                + '/'
                + selectedApplication.pwps.parentMaterial.baseMaterial1.number;
            parentMaterial.baseMaterial1SearchResult = [];
            parentMaterial.selectedBaseMaterial1 = { ...selectedApplication.pwps.parentMaterial.baseMaterial1 };
            // MaterialGroup1.
            if (selectedApplication.pwps.parentMaterial.baseMaterial1.materialGroup && selectedApplication.pwps.parentMaterial.baseMaterial1.materialGroup.id !== 0) {
                parentMaterial.selectedMaterialGroup1 = { ...selectedApplication.pwps.parentMaterial.baseMaterial1.materialGroup };
            }
            else {
                parentMaterial.selectedMaterialGroup1 = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                };
            }
            // TechnicalDeliveryCondition1.
            parentMaterial.selectedTechnicalDeliveryCondition1 = { ...selectedApplication.pwps.parentMaterial.technicalDeliveryCondition1 };
        }
        else {
            parentMaterial.baseMaterial1SearchText = '';
            parentMaterial.baseMaterial1SearchResult = [];
            parentMaterial.selectedBaseMaterial1 = {
                id: 0,
                name: '',
                number: '',
                description: '',
                materialGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                technicalDeliveryConditions: [],
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }
        // MaterialThickness1.
        parentMaterial.materialThickness1 = selectedApplication.pwps.parentMaterial.materialThickness1.zeroToStringEmpty();
        // Diameter1.
        parentMaterial.diameter1 = selectedApplication.pwps.parentMaterial.diameter1.zeroToStringEmpty();
        // BaseMaterial2.
        if (selectedApplication.pwps.parentMaterial.baseMaterial2 && selectedApplication.pwps.parentMaterial.baseMaterial2.id !== 0) {
            parentMaterial.baseMaterial2SearchText =
                selectedApplication.pwps.parentMaterial.baseMaterial2.name
                + '/'
                + selectedApplication.pwps.parentMaterial.baseMaterial2.number;
            parentMaterial.baseMaterial2SearchResult = [];
            parentMaterial.selectedBaseMaterial2 = { ...selectedApplication.pwps.parentMaterial.baseMaterial2 };
            // MaterialGroup2.
            if (selectedApplication.pwps.parentMaterial.baseMaterial2.materialGroup && selectedApplication.pwps.parentMaterial.baseMaterial2.materialGroup.id !== 0) {
                parentMaterial.selectedMaterialGroup2 = { ...selectedApplication.pwps.parentMaterial.baseMaterial2.materialGroup };
            }
            else {
                parentMaterial.selectedMaterialGroup2 = {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                };
            }
            // TechnicalDeliveryCondition2.
            parentMaterial.selectedTechnicalDeliveryCondition2 = { ...selectedApplication.pwps.parentMaterial.technicalDeliveryCondition2 };
        }
        else {
            parentMaterial.baseMaterial2SearchText = '';
            parentMaterial.baseMaterial2SearchResult = [];
            parentMaterial.selectedBaseMaterial2 = {
                id: 0,
                name: '',
                number: '',
                description: '',
                materialGroup: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                technicalDeliveryConditions: [],
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
        }

        parentMaterial.materialThickness2 = selectedApplication.pwps.parentMaterial.materialThickness2.zeroToStringEmpty();
        // Diameter2.
        parentMaterial.diameter2 = selectedApplication.pwps.parentMaterial.diameter2.zeroToStringEmpty();
        parentMaterial.enableBaseMaterial1Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial1Text;
        parentMaterial.enableBaseMaterial2Text = selectedApplication.pwps.parentMaterial.enableBaseMaterial2Text;
        parentMaterial.baseMaterial1Text = selectedApplication.pwps.parentMaterial.baseMaterial1Text;
        parentMaterial.baseMaterial2Text = selectedApplication.pwps.parentMaterial.baseMaterial2Text;
        parentMaterial.materialGroup1Text = selectedApplication.pwps.parentMaterial.materialGroup1Text;
        parentMaterial.materialGroup2Text = selectedApplication.pwps.parentMaterial.materialGroup2Text;
        parentMaterial.technicalDeliveryCondition1Text = selectedApplication.pwps.parentMaterial.technicalDeliveryCondition1Text;
        parentMaterial.technicalDeliveryCondition2Text = selectedApplication.pwps.parentMaterial.technicalDeliveryCondition2Text;

        return parentMaterial;
    }

    static SetWeldingParametersApplication(selectedApplication: GetApplicationResponse) {
        //weldingParameters
        let weldingParametersList = [];
        for (let i = 0; i < selectedApplication.pwps.weldingParameters.length; i++) {
            let weldingParameters: WeldingParameters = new WeldingParameters();
            weldingParameters.id = selectedApplication.pwps.weldingParameters[i].id;
            weldingParameters.runText = selectedApplication.pwps.weldingParameters[i].runText;
            weldingParameters.run = selectedApplication.pwps.weldingParameters[i].run.toString().replace('.', ',');
            weldingParameters.index = { ...selectedApplication.pwps.weldingParameters[i].index };
            weldingParameters.weldingMethod = { ...selectedApplication.pwps.weldingParameters[i].weldingMethod };
            weldingParameters.diameter = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].diameter);
            weldingParameters.current = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].current);
            weldingParameters.currentMax = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].currentMax);
            weldingParameters.currentMin = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].currentMin);
            weldingParameters.voltage = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].voltage);
            weldingParameters.voltageMax = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].voltageMax);
            weldingParameters.voltageMin = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].voltageMin);
            weldingParameters.currentType = { ...selectedApplication.pwps.weldingParameters[i].currentType };
            weldingParameters.runLength = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].runLength);
            weldingParameters.time = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].time);;
            weldingParameters.interpassTemperature = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].interpassTemperature);;
            weldingParameters.wireFeed = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].wireFeed);
            weldingParameters.runWidth = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].runWidth);
            weldingParameters.flowRateRoot = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].flowRateRoot);
            weldingParameters.flowRateTop = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].flowRateTop);
            weldingParameters.travelSpeed = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].travelSpeed);
            weldingParameters.travelSpeedMax = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].travelSpeedMax);
            weldingParameters.travelSpeedMin = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].travelSpeedMin);
            weldingParameters.heatInput = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInput);
            weldingParameters.heatInputMin = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInputMin);
            weldingParameters.heatInputMax = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInputMax);
            weldingParameters.createdAt = selectedApplication.pwps.weldingParameters[i].createdAt;
            weldingParameters.deletedAt = selectedApplication.pwps.weldingParameters[i].deletedAt;
            weldingParameters.updatedAt = selectedApplication.pwps.weldingParameters[i].updatedAt;
            weldingParameters.pulseCurrent = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].pulseCurrent);
            weldingParameters.pulseTime = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].pulseTime);
            weldingParameters.basicCurrent = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].basicCurrent);
            weldingParameters.timeForBasicCurrent = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].timeForBasicCurrent);
            weldingParameters.frequency = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].frequency);
            weldingParameters.current1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].current1)
            weldingParameters.time1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].time1);
            weldingParameters.heatInput1 = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].heatInput1);
            weldingParameters.arcEnergyIE = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcEnergyIE);
            weldingParameters.arcEnergyIP = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcEnergyIP);
            weldingParameters.arcIP = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcIP);
            weldingParameters.arcIE = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].arcIE);
            weldingParameters.additiveMaterial = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].additiveMaterial);
            weldingParameters.voltageX = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].voltageX);
            weldingParameters.currentY = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].currentY);
            weldingParameters.travelSpeedZ = HelpFunctions.setZeroToEmpty(selectedApplication.pwps.weldingParameters[i].travelSpeedZ);
            weldingParameters.weldingTecknique = selectedApplication.pwps.weldingParameters[i].weldingTecknique;
            weldingParameters.fuelGasPressure = selectedApplication.pwps.weldingParameters[i].fuelGasPressure;
            weldingParameters.oxygenPressure = selectedApplication.pwps.weldingParameters[i].oxygenPressure;
            weldingParameters.typeOfFlame = selectedApplication.pwps.weldingParameters[i].typeOfFlame;
            weldingParametersList.splice(weldingParametersList.length, 0, weldingParameters);
        };
        return weldingParametersList
    }
}