﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { GetApplicationResponse } from './models/responses/GetApplicationResponse';
import { GetMessageResponse } from './models/responses/GetMessageResponse';
import { CreateMessageResponse } from './models/responses/CreateMessageResponse';
import { CreateMessageRequest } from './models/requests/CreateMessageRequest';
import { UpdateMessageResponse } from './models/responses/UpdateMessageResponse';
import { UpdateMessageRequest } from './models/requests/UpdateMessageRequest';
import { DeleteMessageResponse } from './models/responses/DeleteMessageResponse';
import { WeldingProtocol } from './models/WeldingProtocol';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MessageState {
    showMessageModal: boolean;
    modalIdActive: string;
    messageText: string;
    message: GetMessageResponse;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetSelectedApplicationAction {
    type: 'SET_SELECTED_APPLICATION';
    selectedApplication: GetApplicationResponse;
}

export interface SetShowMessageModalAction {
    type: 'SET_SHOW_DELETE_APPLICATION_MODAL';
    showMessageModal: boolean;
}

export interface SetmodalIdActiveAction {
    type: 'SET_MODAL_ID';
    modalIdActive: string
}

export interface GetMessageReceiveAction {
    type: 'GET_MESSAGE_RECEIVE';
    message: GetMessageResponse;
}

export interface SetMessage {
    type: 'SET_MESSAGE';
    message: GetMessageResponse;
}

export interface GetMessageRequestAction {
    type: 'GET_MESSAGE_REQUEST';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetSelectedApplicationAction
    | SetShowMessageModalAction
    | SetmodalIdActiveAction
    | GetMessageReceiveAction
    | GetMessageRequestAction
    | SetMessage;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setSelectedApplication: (selectedApplication: GetApplicationResponse): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SELECTED_APPLICATION', selectedApplication: selectedApplication });
    },
    setShowMessageModal: (setShowMessageModal: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SHOW_DELETE_APPLICATION_MODAL', showMessageModal: setShowMessageModal });
    },
    setmodalIdActive: (modalIdActive: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_MODAL_ID', modalIdActive: modalIdActive});
    },
    setMessage: (messageText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.message) {
            let getMessageResponse: GetMessageResponse = { ...appState.message.message};
            getMessageResponse.messageText = messageText;
            dispatch({ type: 'SET_MESSAGE', message: getMessageResponse });
        }
    },
    getMessageById: (name: string): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Messages/GetByName/${name}`)
            .then(response => response.json() as Promise<GetMessageResponse>)
            .then(data => {
                console.info(data);
                dispatch({ type: 'GET_MESSAGE_RECEIVE', message: data });
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MESSAGE_REQUEST' });
    },
    getByApplicationIdMessageCode: (applicationId: number, messageCode: string): AppThunkAction<KnownAction> => (dispatch,getState) => {
        fetch(`api/Messages/GetByApplicationIdAndMessageCode/${applicationId}/${messageCode}`)
            .then(response => response.json() as Promise<GetMessageResponse>)
            .then(data => {
                console.info(data);
                dispatch({ type: 'GET_MESSAGE_RECEIVE', message: data });
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MESSAGE_REQUEST' });
    },
    setByApplicationIdAndMessageCode: (messageCode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrDekraTab && appState.message) {
            let weldingProtocol: WeldingProtocol = { ...appState.wpqrDekraTab.weldingProtocol };
            let getMessageResponse: GetMessageResponse = { ...appState.message.message };
            for (let i = 0; i < weldingProtocol.weldingProtocolMessages.length; i++) {
                if (weldingProtocol.weldingProtocolMessages[i].messageCode === messageCode) {
                    getMessageResponse = { ...weldingProtocol.weldingProtocolMessages[i] };
                    break;
                }
            }
            dispatch({ type: 'GET_MESSAGE_RECEIVE', message: getMessageResponse });
        }
    },
    createMessage: (createMessageRequest: CreateMessageRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
            fetch(`api/Messages/Create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(createMessageRequest)
            })
            .then(response => response.json() as Promise<CreateMessageResponse>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.message) {
                    let getMessageResponse: GetMessageResponse = { ...appState.message.message };
                    getMessageResponse.id = data.id;
                    dispatch({ type: 'SET_MESSAGE', message: getMessageResponse });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MESSAGE_REQUEST' });
    },
    deleteMessageById: (id : number): AppThunkAction<KnownAction> => (dispatch, getState) => {
            fetch(`api/Messages/DeleteById/${id}`)
            .then(response => response.json() as Promise<DeleteMessageResponse>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.message) {
                    let getMessageResponse: GetMessageResponse = { ...appState.message.message };
                    getMessageResponse.id = data.id;
                    dispatch({ type: 'SET_MESSAGE', message: getMessageResponse });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MESSAGE_REQUEST' });
    },
    updateMessage: (updateMessageRequest: UpdateMessageRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Messages/Update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateMessageRequest)
        })
            .then(response => response.json() as Promise<UpdateMessageResponse>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.message) {
                    let getMessageResponse: GetMessageResponse = { ...appState.message.message };
                    getMessageResponse.id = data.id;
                    dispatch({ type: 'SET_MESSAGE', message: getMessageResponse });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MESSAGE_REQUEST' });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MessageState = {
    showMessageModal: false,
    modalIdActive: '',
    messageText: '',
    message: {
        id: 0,
        applicationId: 0,
        messageCode: '',
        messageText: ''
    }
};

export const reducer: Reducer<MessageState> = (state: MessageState | undefined, incomingAction: Action): MessageState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_SELECTED_APPLICATION':
            return {
                showMessageModal: state.showMessageModal,
                modalIdActive: state.modalIdActive,
                messageText: state.messageText,
                message: state.message
            };
        case 'SET_SHOW_DELETE_APPLICATION_MODAL':
            return {
                showMessageModal: action.showMessageModal,
                modalIdActive: state.modalIdActive,
                messageText: state.messageText,
                message: state.message
            };
        case 'SET_MODAL_ID':
            return {
                showMessageModal: state.showMessageModal,
                modalIdActive: action.modalIdActive,
                messageText: state.messageText,
                message: state.message
            };
        case 'GET_MESSAGE_RECEIVE':
            return {
                showMessageModal: state.showMessageModal,
                modalIdActive: state.modalIdActive,
                messageText: state.messageText,
                message: action.message
            };
        case 'SET_MESSAGE':
            return {
                showMessageModal: state.showMessageModal,
                modalIdActive: state.modalIdActive,
                messageText: state.messageText,
                message: action.message
            };
        case 'GET_MESSAGE_REQUEST':
            return {
                showMessageModal: state.showMessageModal,
                modalIdActive: state.modalIdActive,
                messageText: state.messageText,
                message: state.message
            };
        default:
            return state;
    }
};
