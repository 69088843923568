﻿export class FerriteTestingResultsTableRow {
    acceptance: string;
    ferriteContent: string;
    measurementUncertainty: string;
    testSpecimenNumber: string;

    public constructor(testSpecimenNumber: string, ferriteContent: string, measurementUncertainty: string, acceptance: string) {
        this.testSpecimenNumber = testSpecimenNumber;
        this.ferriteContent = ferriteContent;
        this.measurementUncertainty = measurementUncertainty;
        this.acceptance = acceptance;
    }
}