﻿export class WPQTReportPerformedTestsTableRows {
    acceptance: string;
    performed: boolean;
    testingPerformedBy: string;
    testingResult: string;
    typeOfTest: string;

    public constructor(typeOfTest: string, performed: boolean, testingResult: string, acceptance: string, testingPerformedBy: string) {
        this.typeOfTest = typeOfTest;
        this.performed = performed;
        this.testingResult = testingResult;
        this.acceptance = acceptance;
        this.testingPerformedBy = testingPerformedBy;
    }
}