﻿export class UpdateWeldSequenceRequest {
    imageId: number;
    imageData: string;
    sequenceNumber1: string;
    sequenceNumber2: string;
    sequenceNumber3: string;
    sequenceNumber4: string;
    sequenceNumber5: string;
    sequenceNumber6: string;
    sequenceNumber7: string;
    sequenceNumber8: string;
    sequenceNumber9: string;
    sequenceNumber10: string;
    sequenceNumber11: string;
    sequenceNumber12: string;
    sequenceNumber13: string;
    sequenceNumber14: string;
    sequenceNumber15: string;

    public constructor() {
        this.imageId = 0.0;
        this.imageData = '';
        this.sequenceNumber1 = '';
        this.sequenceNumber2 = '';
        this.sequenceNumber3 = '';
        this.sequenceNumber4 = '';
        this.sequenceNumber5 = '';
        this.sequenceNumber6 = '';
        this.sequenceNumber7 = '';
        this.sequenceNumber8 = '';
        this.sequenceNumber9 = '';
        this.sequenceNumber10 = '';
        this.sequenceNumber11 = '';
        this.sequenceNumber12 = '';
        this.sequenceNumber13 = '';
        this.sequenceNumber14 = '';
        this.sequenceNumber15 = '';
    }
}