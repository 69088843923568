﻿import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../store/index';
import * as WeldingPlanStore from '../store/WeldingPlanStore';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form, FormGroup, Input, InputGroup, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Loader from './Loader';
import { RevisionActionType } from '../store/models/RevisionActionType';
import LogoImage from './LogoImage';
import { t } from '../i18n';
import { WeldingPlanRequest } from '../store/models/requests/WeldingPlanRequest';
import ImageUpload from './otherComponents/ImageUpload';
import DropdownLists from './otherComponents/DropdownLists';

// At runtime, Redux will merge together...
type WeldingPlanFormProps =
    WeldingPlanStore.WeldingPlanStoreState // ... state we've requested from the Redux store
    & typeof WeldingPlanStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ applicationId: string, wpsId: string }>
    & OwnProps; // .. plus our own props :-);
const acceptanceCriteriaList = [
    'Empty'];
const acceptanceCriteriaListSearchResult = [];
const objectsOnDrawingsList = [
    'Empty'];
const figurList = [
    'Empty'];
const figurListSearchResult = [];
const weldingDataSheetList = [
    'Empty'];
const temporayDevicesList = [
    'Start- samt stopplåtar ska ha samma dimension & material som övriga konstruktionen / That start and stop plates must have the same dimensions and material as the rest of the construction.',
    'Inga tillfälliga anordningar. / No temporary devices.',];
const weldPositionList = [
    'PA',
    'PB',
    'PC',
    'PD',
    'PE',
    'PF',
    'PH',
    'PG',
    'PJ',
    'PK',
    'H-L045',
    'J-L045',];
const jointPreparationList = [
    'God anliggning mellan detaljer. / Full contact between details.',
    'Enligt WPS och ritning / According to WPS and drawing.'];
const handlingOfFillerMetalList = [
    'Tråd-, rör- och stavelektroder/Wire, tube and rod electrodes\nLagring skall ske i rumstemperatur, platsen skall vara väl skyddad från föroreningar, såsom slipdamm mm. Förvaringsplatserna skall vara tydligt uppmärkta med tillsatsmaterialets beteckning. / Storage must take place at room temperature, the place must be well protected from contaminants, such as grinding dust, etc. The places of storage must be clearly marked with the name of the consumable.',
    'Belagda elektroder, obruten förpackning/Coated electrodes, unopened packaging\nTillsatsmaterial i obrutna förpackningar lagras i rumstemperatur. Platsen skall vara väl skyddad från föroreningar, såsom slipdamm mm. Förvaringsplatserna skall vara tydligt uppmärkta med tillsatsmaterialets beteckning. / Consumables in unopened packages are stored at room temperature. The site must be well protected from contaminants, such as grinding dust, etc. The places of storage must be clearly marked with the name of the consumable.',
    'Belagda elektroder, bruten förpackning/Coated electrodes, opened packaging',
    'Rutilelektroder/Rutile electrodes\nRutilelektroder förvaras i rumstemperatur. Platsen skall vara väl skyddad från föroreningar, såsom slipdamm mm. Förvaringsplatserna skall vara tydligt uppmärkta med tillsatsmaterialets beteckning. / Rutile electrodes are stored at room temperature. The site must be well protected from contaminants, such as grinding dust, etc. The places of storage must be clearly marked with the name of the consumable.',
    'Basiska elektroder/Alkaline electrodes\nBelagda elektroder av basisk typ har ett mycket lågt fuktinnehåll då de lämnar tillverkaren och tar därför mycket lätt åt sig fukt från omgivande atmosfär. För att undvika fuktupptagning skall dessa förvaras i värmeskåp. Temperaturen i värmeskåpet bör vara minst +70ºC. OBS!  I värmeskåpet får endast basiska elektroder förvaras, ej rutila elektroder. Uttag av elektroder till svetsplatsen bör ej vara större än vad som förbrukas inom 4 timmar.På svetsplatsen bör förvaring ske i elektriskt uppvärmd torrhållare, vid en temperatur av min + 50ºC. OBS! Elektroder som skadats eller utsatts för fukt får ej läggas tillbaka i värmeskåpet.Dessa skall kasseras eller omtorkas enligt leverantörens anvisningar. / Coated electrodes of the basic type have a very low moisture content when they leave the manufacturer and therefore very easily absorb moisture from the surrounding atmosphere.To avoid moisture absorption, these must be stored in heating cabinets.The temperature in the heating cabinet should be at least + 70ºC.NOTE!  Only basic electrodes may be stored in the heating cabinet, not rutile electrodes.The extraction of electrodes to the welding site should not be greater than what is consumed within 4 hours. At the welding site, storage should take place in an electrically heated dry holder, at a temperature of min + 50ºC. NB! Electrodes that have been damaged or exposed to moisture must not be put back in the heating cabinet.These must be discarded or re - dried in accordance with the suppliers instructions.',
    'När tillsatsmaterialet inte används ska det förvaras inomhus och skyddas från vatten och fukt. / Welding materials shall be stored inside, away from rain, snow and dew when it is not being used.',
    'Om tillsatsmaterialet har missfärgningar eller korrosion ska denna del av tråden tas bort. / If any discoloration or rust is found prior to usage, please discard the discolored/rusted part of the wire.',
    'Hantering av tillsatsmaterial enligt tillverkarens anvisning. / Handling of filler metal according to the manufacturers instructions.'];
const beforeWeldingList = [
    'Häfta upp detaljerna enligt WPS. / Tack weld the parts according to WPS.',
    'Kontrollera att ingående delar är rena och torra. Ta bort ev. föroreningar som olja och fett. / Check that the included area is clean and dry. / Remove any contaminants such as oil and grease.',
    'Ingen fukt får finnas i samt runt det svetsade området. / No moisture must be present in and around the welded area.',
    'Fogkanter ska slipas metalliskt rena, minst 20 mm utanför fogkant. Kapgrader avlägsnade. / Joint edges must be grinded metallically clean, at least 20 mm outside the joint.Cutting edges removed.',
    'Se till att svetsområdet är skyddat mot vind. / Make sure the welding area is protected from wind.',
    'Förhöjd arbetstemperatur XX ºC. / Preheting temperature XX ºC.',
    'Fogberedning enligt WPS. Slipa bort slagg, skärsår samt tvätta området innan svetsning. / Joint preparation from WPS and grind away slag and cuts and wash the area before welding. ',
    'Identifiera grundmaterialet och tillsatsmaterialet så att det stämmer överens mot WPS:en. / Identity of the parent material and identity welding consumables.',
    'Kontrollera fogberedningen, samt att tillräckligt många häftor har utförts och att fixeringen är korrekt. / check joint preparation, fit-up, jigging and tacking.',
    'Förvärm materialet till + xx°C med Acetylen/Oxygen/Gasol. Låt temperaturen bli jämn i svetsområdet innan mätningen sker. Mät temperaturen med en termometer 75 mm ifrån fogen som ska svetsas. / Preheat material until a temperature of + xx°C has been reached. Preheat with acetylen/oxygen/gasol. Allow the temperature to be even and measure 75 mm away from the joint with a thermometer.',];
const duringWeldingList = [
    'Starta svetsningen med den detaljen som behöver mest utrymme att växa och svetsa ut mot fri ände. / Start welding with the part that needs the most area to grow and weld out towards the free end. ',
    'Minst 1/3 av svetsen skall svetsas upp i en följd. Minimera antalet avsvalningar under svetsens uppbyggnad. / At least 1/3 of the weld must be welded in one sequence. Minimize the number of cooling’s under the weld',
    'Stränga upp svetsen, pendling endast tillåten i roten, max 3 x elektroddiametern. / String up the weld, only weaving allowed in the root, max 3 X electrode diameter.',
    'Ingen pendling. / No weaving.',
    'Slipa samt borsta mellan varje sträng. / Grinding and brush between each weld.',
    'Kontrollera att svetsparametrarna följs. / Check welding parameters being followed.',
    'Kontrollera att den förhöjda arbetstemperaturen upprätthålls innan nästa svetssträng läggs. / Check the preheat temperature before the next run',
    'Placera sista strängarna mitt i fogen. Detta för att materialet inte ska svalna för fort. / Place the last run in the middle of the joint. This is so that the material does not cool down too quickly. ',
    'Slipa elektrodbyten i rotsträngen. / Grinding electrode changes in the root.',
    'Samtliga mellansträngar slipas metalliskt rena. / All intermediate runs grindings metallically clean',
    'Max Elektrod lutning 70-80°grader. / Max electrode gradient 70-80°degrees.',
];
const afterWeldingList = [
    'Säkerställa att all slagg och svetssprut har tagits bort. / Assure all slag and spatter has been removed.',
    'Slipa eventuella ojämnheter. / Grinding any unevenness.',
    'När jämn övergång fodras, bearbeta svetsens fattningskanter till xx°. Vid användning av roterande fil, slipa längst spänningsriktningen. / When a smooth transition is requred, grinding the weld toe to xx°. When using a rotating file, grind along the tension direction. ',
    'Kontrollera att korrekt a-mått enligt ritningen uppfylls. / Assure the correct throat thickness (a) according to the drawing is fulfilled.',
    'Kontrollera den färdiga svetsen visuellt enligt SS-EN ISO 5817. / Examine the finished weld according to ISO 5817.',
    'Kontroll av toleranser enligt gällande väsentliga toleranser och funktionstoleranser. / Verification of tolerances in accordance with applicable essential tolerances and functional tolerances.',
    'För att motverka krypning bör start- samt stopplåtar demonteras direkt efter avslutat svetsning. / Start and stop plates must be dismantled immediately after completion of welding. This is to prevent shrinkage.',];
const extentOfTestingAcceptansList = [
    'Se kontroll- och provningsplan. / See inspection and test plan.',
    '100 % visuell kontroll av eget arbetet. / 100% visual inspection of own work.',
    'Oförstörande kontroll enligt följande. / Non-destructive control as follows.',
    'Innan den kompletterande oförstörande provningen utförs måste det gå minst xx h efter avslutad svetsning.Detta för att undvika vätesprickor. / Before testing, the minimum hold time is xx hours after welding before testing can be conducted.This is to avoid hydrogen cracking',
    '100% visuell provning och xx% magnetpulverprovning. Detta ska fördelas jämnt över alla svetsare och WPS: er under projektets gång. / 100 % visual testing and 25 % Magnetic testing.This shall be divided to cover all the welders and WPS under the period of welding.',
    'Utför yt-kontroll vid borttagning av temporära infästningar. / Perform surface checks when removing temporary fasteners.',];
const tackWeldsList = [
    'Längden på häftorna ska vara minst 50 mm eller 4xt mm. / Length of tack welds must be at least 50 mm or 4xt mm.',
    'Säkerställ att tillräckligt många häftor har använts för att undvika krympning i fogen. / Assure enough tack welds is done to avoid shrinking in gap.',
    'Häftsvetsar måste utföras med förhöjd arbetstemperatur. / Tack welds must be done with preheat temperature.'];
const fillermaterialList = [
    'Empty',];
const projectDescriptionList = [
    'Empty',];
const figureTextList = [
    'Empty',];
export interface OwnProps {
    pEnableClone: boolean;
    weldingPlanId: number;
    previewPage: (refleshWeldingPlanList: boolean) => void;

}

class WeldingPlanForm extends React.PureComponent<WeldingPlanFormProps> {
    constructor(props: WeldingPlanFormProps) {
        super(props);
    }

    public state = {
        tooltipOpen: false,
        tabId: '',
        parametersTableId: false,
        dropdownOpen: false,
        showJointDesignModalId: 0,
        showWeldSequenceModal: false,
        acceptanceCriteriaList,
        enableAcceptanceCriteriaList: false,
        acceptanceCriteriaListSearchResult,
        objectsOnDrawingsList,
        enableObjectsOnDrawingsList: false,
        figurList,
        figurListSearchResult,
        weldingDataSheetList,
        enableWeldingDataSheetList: false,
        temporayDevicesList,
        enableTemporayDevicesList: false,
        weldPositionList,
        enableWeldPositionList: false,
        jointPreparationList,
        enableJointPreparationList: false,
        handlingOfFillerMetalList,
        enableHandlingOfFillerMetalList: false,
        beforeWeldingList,
        enableBeforeWeldingList: false,
        duringWeldingList,
        enableDuringWeldingList: false,
        afterWeldingList,
        enableAfterWeldingList: false,
        extentOfTestingAcceptansList,
        enableExtentOfTestingAcceptansList: false,
        tackWeldsList,
        enableTackWeldsList: false,
        fillermaterialList,
        enableFillermaterialList: false,
        projectDescriptionList,
        enableProjectDescriptionList: false,
        figureTextList,
        enableFigureTextList: false,

    };
    private onChangeAcceptanceCriteria(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setAcceptanceCriteria(event.currentTarget.value);
    }
    private onChangeProjectDescription(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setProjectDescription(event.currentTarget.value);
    }

    private onClickSelectAcceptanceCriteria(value: string) {
        this.setState({
            enableAcceptanceCriteriaList: false,
        });
        this.props.setSelectAcceptanceCriteria(value);
    }
    private onClickSelectProjectDescription(value: string) {
        this.setState({
            enableProjectDescriptionList: false,
        });
        this.props.setSelectProjectDescription(value);
    }
    
    private onChangeObjectsOnDrawings(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setObjectsOnDrawings(event.currentTarget.value);
    }
    private onClickSelectObjectsOnDrawings(value: string) {
        this.setState({
            enableObjectsOnDrawingsList: false,
        });
        this.props.setSelectObjectsOnDrawings(value);
    }

    private onChangeWeldingPlanWPS(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingPlanWPS(event.currentTarget.value);
    }
    private onChangeFigureText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFigureText(event.currentTarget.value);
    }
    
    private onClickSelectWeldingDataSheet(value: string) {
        this.setState({
            enableWeldingDataSheetList: false,
        });
        this.props.setSelectWeldingDataSheet(value);
    }
    private onClickSelectFigureText(value: string) {
        this.setState({
            enableFigureTextList: false,
        });
        this.props.setSelectFigureText(value);
    }
    private onChangeFillermaterial(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setFillermaterial(event.currentTarget.value);
    }
    private onChangeTemporayDevices(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTemporayDevices(event.currentTarget.value);
    }
    private onClickSelectTemporayDevices(value: string) {
        this.setState({
            enableTemporayDevicesList: false,
        });
        this.props.setSelectTemporayDevices(value);
    }
    private onClickSelectFillermaterial(value: string) {
        this.setState({
            enableFillermaterialList: false,
        });
        this.props.setSelectFillermaterial(value);
    }
    private onChangeWeldPosition(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldPosition(event.currentTarget.value);
    }
    private onClickSelectWeldPosition(value: string) {
        this.setState({
            enableWeldPositionList: false,
        });
        this.props.setSelectWeldPosition(value);
    }
    private onChangeJointPreparation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setJointPreparation(event.currentTarget.value);
    }
    private onClickSelectJointPreparation(value: string) {
        this.setState({
            enableJointPreparationList: false,
        });
        this.props.setSelectJointPreparation(value);
    }

    private onChangeHandlingOfFillerMetal(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHandlingOfFillerMetal(event.currentTarget.value);
    }
    private onClickSelectHandlingOfFillerMetal(value: string) {
        this.setState({
            enableHandlingOfFillerMetalList: false,
        });
        this.props.setSelectHandlingOfFillerMetalv(value);
    }
    private onChangeBeforeWelding(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setBeforeWelding(event.currentTarget.value);
    }
    private onClickSelectBeforeWelding(value: string) {
        this.setState({
            enableBeforeWeldingList: false,
        });
        this.props.setSelectBeforeWelding(value);
    }

    private onChangeDuringWelding(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDuringWelding(event.currentTarget.value);
    }
    private onClickSelectDuringWelding(value: string) {
        this.setState({
            enableDuringWeldingList: false,
        });
        this.props.setSelectDuringWelding(value);
    }
    private onChangeAfterWelding(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setAfterWelding(event.currentTarget.value);
    }
    private onClickSelectAfterWelding(value: string) {
        this.setState({
            enableAfterWeldingList: false,
        });
        this.props.setSelectAfterWelding(value);
    }


    private onChangeExtentOfTestingAcceptanskrav(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setExtentOfTestingAcceptanskrav(event.currentTarget.value);
    }

    private onClickSelectExtentOfTestingAcceptans(value: string) {
        this.setState({
            enableExtentOfTestingAcceptansList: false,
        });
        this.props.setSelectExtentOfTestingAcceptanskrav(value);
    }

    private onChangeOtherInformation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherInformation(event.currentTarget.value);
    }

    private onChangeTackWelds(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTackWelds(event.currentTarget.value);
    }
    private onClickSelectTackWelds(value: string) {
        this.setState({
            enableTackWeldsList: false,
        });
        this.props.setSelectTackWelds(value);
    }

    public componentDidMount() {
        // Get data from db.
        this.props.initWeldingPlan()
    }
    componentDidUpdate(prevProps: any) {
        if (this.props.weldingPlanId !== prevProps.weldingPlanId ) {
            if (this.props.weldingPlanId !== 0) {
                this.props.getWeldingPlan(this.props.weldingPlanId);
            } 
        }
    }
    private createNewWPlan() {
        const weldingPlanRequest: WeldingPlanRequest = {
            id: this.props.header.selectedWPlanId,
            acceptanceCriteria: this.props.weldingPlanHeadlines.acceptanceCriteria,
            objectsOnDrawings: this.props.weldingPlanHeadlines.objectsOnDrawings,
            weldingPlanFigure: this.props.weldingPlanHeadlines.weldingPlanFigure,
            weldingPlanWPS: this.props.weldingPlanHeadlines.weldingPlanWPS,
            jointPreparation: this.props.weldingPlanHeadlines.jointPreparation,
            outerDiameter: this.props.weldingPlanHeadlines.outerDiameter,
            tackWelds: this.props.weldingPlanHeadlines.tackWelds,
            temporayDevices: this.props.weldingPlanHeadlines.temporayDevices,
            weldPosition: this.props.weldingPlanHeadlines.weldPosition,
            handlingOfFillerMetal: this.props.weldingPlanHeadlines.handlingOfFillerMetal,
            beforeWelding: this.props.weldingPlanHeadlines.beforeWelding,
            duringWelding: this.props.weldingPlanHeadlines.duringWelding,
            afterWelding: this.props.weldingPlanHeadlines.afterWelding,
            extentOfTestingAcceptanskrav: this.props.weldingPlanHeadlines.extentOfTestingAcceptanskrav,
            fillermaterial: this.props.weldingPlanHeadlines.fillermaterial,
            figureText: this.props.weldingPlanHeadlines.figureText,
            projectDescription: this.props.weldingPlanHeadlines.projectDescription,
            customerId: parseInt(JSON.parse(localStorage.getItem('customerId')!)),
            revisionWPlanAction: this.props.pEnableClone ? "Clone" : this.props.header.revisionWPlanAction,
            weldingPlanDetailsRequest: {
                pwpsNumber: this.props.header.pwpsNumber,
                projectNumber: this.props.header.project,
                revisionNumber: this.props.header.revisionNumber,
                ByStandard: this.props.header.selectedByStandard,
                wpqrNumber: this.props.header.wpqrNumber,
                manufacturer: this.props.header.manufacturer,
                examiner: this.props.header.examiner,
                date: this.props.header.date,
                otherInformation: this.props.weldingPlanHeadlines.otherInformation
            },
            fileUploadRequest: []
        };
        this.props.createUpdateWPlan(weldingPlanRequest, () => { this.props.previewPage(true) });
    }

    private onClickSaveButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        // Update/Save the WeldingPlan to db.
        this.createNewWPlan();
    }
    private onClickWeldingDataSheetListButton() {
        this.setState({
            enableWeldingDataSheetList: !this.state.enableWeldingDataSheetList,
        });
    }
    private onClickFigureTextListButton() {
        this.setState({
            enableFigureTextList: !this.state.enableFigureTextList,
        });
    }

    private onClickAcceptanceCriteriaListButton() {
        this.setState({
            enableAcceptanceCriteriaList: !this.state.enableAcceptanceCriteriaList,
        });
    }
    private onClickProjectDescriptionListButton() {
        this.setState({
            enableProjectDescriptionList: !this.state.enableProjectDescriptionList,
        });
    }
    
    private onClickObjectsOnDrawingsListButton() {
        this.setState({
            enableObjectsOnDrawingsList: !this.state.enableObjectsOnDrawingsList,
        });
    }
    private onClickTemporayDevicesListButton() {
        this.setState({
            enableTemporayDevicesList: !this.state.enableTemporayDevicesList,
        });
    }
    private onClickFillermaterialListButton() {
        this.setState({
            enableFillermaterialList: !this.state.enableFillermaterialList,
        });
    }
    private onClickTackWeldsListButton() {
        this.setState({
            enableTackWeldsList: !this.state.enableTackWeldsList,
        });
    }
    private onClickWeldPositionListButton() {
        this.setState({
            enableWeldPositionList: !this.state.enableWeldPositionList,
        });
    }
    private onClickJointPreparationListButton() {
        this.setState({
            enableJointPreparationList: !this.state.enableJointPreparationList,
        });
    }
    private onClickHandlingOfFillerMetalListButton() {
        this.setState({
            enableHandlingOfFillerMetalList: !this.state.enableHandlingOfFillerMetalList,
        });
    }
    private onClickBeforeWeldingListButton() {
        this.setState({
            enableBeforeWeldingList: !this.state.enableBeforeWeldingList,
        });
    }
    private onClickdDuringWeldingListButton() {
        this.setState({
            enableDuringWeldingList: !this.state.enableDuringWeldingList,
        });
    }
    private onClickExtentOfTestingAcceptansButton() {
        this.setState({
            enableExtentOfTestingAcceptansList: !this.state.enableExtentOfTestingAcceptansList,
        });
    }

    private onClickAfterWeldingListButton() {
        this.setState({
            enableAfterWeldingList: !this.state.enableAfterWeldingList,
        });
    }

    private onChangeHeaderDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderDate(event.currentTarget.value);
    }

    private onChangeHeaderProject(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderProject(event.currentTarget.value);
    }

    private onChangeHeaderByStandards(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderSelectedByStandard(event.currentTarget.value);
    }

    private onChangeHeaderRevisionNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderRevisionNumber(event.currentTarget.value);
    }
    private onChangeManufacturer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setManufacturer(event.currentTarget.value);
    }

    private onChangeExaminer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setExaminer(event.currentTarget.value);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private onClickPreviousButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        this.props.previewPage(true);
    }
    public render() {
        return (
            <React.Fragment key="rendeWpsForm">
                <Loader show={this.props.currentlyLoading} title={t('Working')} />
                <div>
                    <Row><Col sm={12}>
                        <Card>
                            <CardHeader className="card-header-dekra">
                                <Row>
                                    <Col sm={6}>
                                            <h1>{t('WeldingPlan')}</h1>
                                    </Col>
                                    <Col sm={4}>
                                    </Col>
                                    <Col sm={2}>
                                        <div className={"float-left"}>
                                            <Button color="primary" onClick={(event) => this.onClickPreviousButton(event)}>
                                                <i className="fas fa-arrow-left"></i> {t('Previous')}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.renderWeldingPlan()}
                            </CardBody>
                            <CardFooter>
                                    <Row>
                                        <Col sm={12}>
                                            <div className={"float-right"}>
                                                <Button color="primary" onClick={(event) => this.onClickSaveButton(event)}>
                                                    {t('Save')}
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row>
                            </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    private renderWeldingPlan() {
        return (
            <React.Fragment key="rendeWeldingPlan">
                <Form>
                    <Row id="pWPSForm">
                        <Col sm={6}>
                        </Col>
                        <Col sm={3}>
                        </Col>
                        <Col sm={3}>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderHead()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderWeldingPlanHeadlines()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderOtherInformation()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {this.renderManufacturer()}
                        </Col>
                        <Col sm={6}>
                            {this.renderExaminer()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderHead() {
        return (
            <React.Fragment key="rendeWpsFormHeas">
                <Card>
                    <CardBody>
                        <div>
                            <Row form>
                                <Col sm={3}>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerDate" size="sm">{t('Date')}</Label>
                                                <Input type="date" max="9999-12-30" bssize="sm" name="headerDate" id="headerDate"
                                                    value={this.props.header.date} onChange={(event) => this.onChangeHeaderDate(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerProject" size="sm">{t('Project')}</Label>
                                                <Input type="text" bssize="sm" name="headerProject" id="headerProject"
                                                    value={this.props.header.project}
                                                    onChange={(event) => this.onChangeHeaderProject(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-center">
                                                <LogoImage customerLogo={this.props.header.customerLogo} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-center">
                                                    <h2>{t('WeldingPlan')}</h2>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={3}>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerRevisionNumber" size="sm">{t('RevisionNumber')}</Label>
                                                <Input disabled={this.props.header.revisionWPlanAction === RevisionActionType.View} type="text" bssize="sm" name="headerRevisionNumber" id="headerRevisionNumber"
                                                    value={this.props.header.revisionNumber}
                                                    onChange={(event) => this.onChangeHeaderRevisionNumber(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-left">
                                                <Label for="headerByStandards" size="sm">{t('Regulations')}</Label>
                                                <InputGroup >
                                                    <Input type="text" bssize="sm" name="headerByStandards" id="headerByStandards"
                                                        value={this.props.header.selectedByStandard}
                                                        onChange={(event) => this.onChangeHeaderByStandards(event)}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        placeholder="" />
                                                    <DropdownLists listNumber={30} dropDownListsCallback={this.props.setHeaderSelectedByStandard} rowNumber={0} ></DropdownLists>
                                                </InputGroup >
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
    private renderWeldingPlanHeadlines() {
        return (
            <React.Fragment key="rendeWpsFormAfterWelding">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>{t('WeldingPlan')}</h2>
                        </CardTitle>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup >
                                    <label className="col-form-label-md">{t('ProjectDescription')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickProjectDescriptionListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableProjectDescriptionList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.acceptanceCriteriaList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectProjectDescription(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm={8}>
                                <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.projectDescription.split("\n").length} bsSize="sm" name="acceptanceCriteria" id="acceptanceCriteria"
                                        value={this.props.weldingPlanHeadlines.projectDescription}
                                        onChange={(event) => this.onChangeProjectDescription(event)}
                                        placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                            <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('AcceptanceCriteria')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickAcceptanceCriteriaListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableAcceptanceCriteriaList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.acceptanceCriteriaList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectAcceptanceCriteria(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.acceptanceCriteria.split("\n").length} bsSize="sm" name="acceptanceCriteria" id="acceptanceCriteria"
                                            value={this.props.weldingPlanHeadlines.acceptanceCriteria}
                                            onChange={(event) => this.onChangeAcceptanceCriteria(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                            <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('ObjectsOnDrawings')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickObjectsOnDrawingsListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableObjectsOnDrawingsList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.objectsOnDrawingsList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectObjectsOnDrawings(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.objectsOnDrawings.split("\n").length} bsSize="sm" name="objectsOnDrawings" id="objectsOnDrawings"
                                            value={this.props.weldingPlanHeadlines.objectsOnDrawings}
                                            onChange={(event) => this.onChangeObjectsOnDrawings(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                            <Row form>
                                <Col sm={4}>
                                    <FormGroup>
                                    <label className="col-form-label-md">{t('Figur')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    </FormGroup>
                                </Col>
                            <Col sm={8}>
                                <ImageUpload selectedImage={this.props.weldingPlanHeadlines.uploadFileResponse}></ImageUpload>
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('TextToImage')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickFigureTextListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableFigureTextList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.figureTextList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectFigureText(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm={8}>
                                <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.figureText.split("\n").length} bsSize="sm" name="weldingPlanWPS" id="weldingPlanWPS"
                                        value={this.props.weldingPlanHeadlines.figureText}
                                        onChange={(event) => this.onChangeFigureText(event)}
                                        placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                            <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('WeldingDataSheet')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickWeldingDataSheetListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableWeldingDataSheetList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.weldingDataSheetList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectWeldingDataSheet(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.weldingPlanWPS.split("\n").length} bsSize="sm" name="weldingPlanWPS" id="weldingPlanWPS"
                                            value={this.props.weldingPlanHeadlines.weldingPlanWPS}
                                            onChange={(event) => this.onChangeWeldingPlanWPS(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('WeldPreparation')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickJointPreparationListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableJointPreparationList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.jointPreparationList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectJointPreparation(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm={8}>
                                <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.jointPreparation.split("\n").length} bsSize="sm" name="jointPreparation" id="jointPreparation"
                                        value={this.props.weldingPlanHeadlines.jointPreparation}
                                        onChange={(event) => this.onChangeJointPreparation(event)}
                                        placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('TemporayDevices')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickTemporayDevicesListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableTemporayDevicesList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.temporayDevicesList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectTemporayDevices(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm={8}>
                                <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.temporayDevices.split("\n").length} bsSize="sm" name="temporayDevices" id="temporayDevices"
                                        value={this.props.weldingPlanHeadlines.temporayDevices}
                                        onChange={(event) => this.onChangeTemporayDevices(event)}
                                        placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('WeldingAdditives')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickFillermaterialListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableFillermaterialList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.fillermaterialList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectFillermaterial(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm={8}>
                                <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.fillermaterial.split("\n").length} bsSize="sm" name="temporayDevices" id="temporayDevices"
                                        value={this.props.weldingPlanHeadlines.fillermaterial}
                                        onChange={(event) => this.onChangeFillermaterial(event)}
                                        placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                            <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('HandlingOfFillerMetal')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickHandlingOfFillerMetalListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableHandlingOfFillerMetalList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.handlingOfFillerMetalList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectHandlingOfFillerMetal(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.handlingOfFillerMetal.split("\n").length} bsSize="sm" name="handlingOfFillerMetal" id="handlingOfFillerMetal"
                                            value={this.props.weldingPlanHeadlines.handlingOfFillerMetal}
                                            onChange={(event) => this.onChangeHandlingOfFillerMetal(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('TackWelds')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickTackWeldsListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableTackWeldsList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.tackWeldsList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectTackWelds(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm={8}>
                                <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.tackWelds.split("\n").length} bsSize="sm" name="tackWelds" id="tackWelds"
                                        value={this.props.weldingPlanHeadlines.tackWelds}
                                        onChange={(event) => this.onChangeTackWelds(event)}
                                        placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('WeldingPosition')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickWeldPositionListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableWeldPositionList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.weldPositionList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectWeldPosition(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm={8}>
                                <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.weldPosition.split("\n").length} bsSize="sm" name="weldPosition" id="weldPosition"
                                        value={this.props.weldingPlanHeadlines.weldPosition}
                                        onChange={(event) => this.onChangeWeldPosition(event)}
                                        placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                            <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('BeforeWelding')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickBeforeWeldingListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableBeforeWeldingList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.beforeWeldingList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectBeforeWelding(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.beforeWelding.split("\n").length} bsSize="sm" name="beforeWelding" id="beforeWelding"
                                            value={this.props.weldingPlanHeadlines.beforeWelding}
                                            onChange={(event) => this.onChangeBeforeWelding(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                            <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('DuringWelding')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={() => this.onClickdDuringWeldingListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableDuringWeldingList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.duringWeldingList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={() => this.onClickSelectDuringWelding(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.duringWelding.split("\n").length} bsSize="sm" name="duringWelding" id="duringWelding"
                                            value={this.props.weldingPlanHeadlines.duringWelding}
                                            onChange={(event) => this.onChangeDuringWelding(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('AfterWelding')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                    <Button className="float-right" size="sm" color="primary" onClick={(event) => this.onClickAfterWeldingListButton()}>
                                        {t('ChooseText')}
                                    </Button>
                                    {this.state.enableAfterWeldingList ?
                                        <ListGroup className="scroll-vertical ">
                                            {this.state.afterWeldingList.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectAfterWelding(textValue)}>
                                                    {textValue}
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup> : ""
                                    }
                                </FormGroup>
                            </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.afterWelding.split("\n").length} bsSize="sm" name="afterWelding" id="afterWelding"
                                            value={this.props.weldingPlanHeadlines.afterWelding}
                                            onChange={(event) => this.onChangeAfterWelding(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br />
                            </Col>
                        </Row>
                            <Row form>
                                <Col sm={4}>
                                <FormGroup>
                                    <label className="col-form-label-md">{t('ExtentOfTestingAcceptans')}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                        <Button className="float-right" size="sm" color="primary" onClick={(event) => this.onClickExtentOfTestingAcceptansButton()}>
                                            {t('ChooseText')}
                                        </Button>
                                        {this.state.enableExtentOfTestingAcceptansList ?
                                            <ListGroup className="scroll-vertical ">
                                                {this.state.extentOfTestingAcceptansList.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectExtentOfTestingAcceptans(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup> : ""
                                        }
                                    </FormGroup>
                                </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                    <Input type="textarea" rows={this.props.weldingPlanHeadlines.extentOfTestingAcceptanskrav.split("\n").length} bsSize="sm" name="extentOfTestingAcceptanskrav" id="extentOfTestingAcceptanskrav"
                                            value={this.props.weldingPlanHeadlines.extentOfTestingAcceptanskrav}
                                            onChange={(event) => this.onChangeExtentOfTestingAcceptanskrav(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderOtherInformation() {
        return (
            <React.Fragment key="rendeWPlanInformation">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('OtherInformation')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Input type="textarea" bssize="sm" name="otherInformation" id="otherInformation"
                                            value={this.props.weldingPlanHeadlines.otherInformation} onChange={(event) => this.onChangeOtherInformation(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderManufacturer() {
        return (
            <React.Fragment key="rendeWPlanFormManufacturer">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('Manufacturer')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Input type="textarea" bssize="sm" name="manufacturer" id="manufacturer"
                                            value={this.props.header.manufacturer} onChange={(event) => this.onChangeManufacturer(event)}
                                            placeholder={t('Company') + ", " + t('Name')}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderExaminer() {
        return (
            <React.Fragment key="rendeWpsFormExaminer">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('Examiner')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Input type="textarea" bssize="sm" name="examiner" id="examiner"
                                            value={this.props.header.examiner} onChange={(event) => this.onChangeExaminer(event)}
                                            placeholder={t('Name') + ", " + t('Date') + " XXXX-XX-XX"}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.weldingPlan;
}
export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    WeldingPlanStore.actionCreators // Selects which action creators are merged into the component's props
)(WeldingPlanForm as any);