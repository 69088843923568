﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { GetApplicationResponse } from './models/responses/GetApplicationResponse';
import { ApplicationStatus } from './models/ApplicationStatus';
import { SortOrder } from './models/SortOrder';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ApplicationsState {
    selectedApplication: GetApplicationResponse;
    showDeleteApplicationModal: boolean;
    sortOrder: SortOrder;
    sortProperty: string;
    applicationsSearchResult: Array<GetApplicationResponse>;
    searchTerm: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetSelectedApplicationAction {
    type: 'SET_SELECTED_APPLICATION';
    selectedApplication: GetApplicationResponse;
}

export interface SetShowDeleteApplicationModalAction {
    type: 'SET_SHOW_DELETE_APPLICATION_MODAL';
    showDeleteApplicationModal: boolean;
}

export interface SetSortingAction {
    type: 'SET_SORTING';
    sortProperty: string;
    sortOrder: SortOrder;
}

export interface GetApplicationsSearchResult {
    type: 'SET_APPLICATIONS_SEARCH_RESULT';
    applicationsSearchResult: Array<GetApplicationResponse>;
}

export interface SetSearchTerm {
    type: 'SET_SEARCH_TERM_APPLICATIONS';
    searchTerm: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetSelectedApplicationAction
    | SetShowDeleteApplicationModalAction
    | SetSortingAction
    | GetApplicationsSearchResult
    | SetSearchTerm;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setSelectedApplication: (selectedApplication: GetApplicationResponse): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SELECTED_APPLICATION', selectedApplication: selectedApplication });
    },
    setShowDeleteApplicationModal: (setShowDeleteApplicationModal: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SHOW_DELETE_APPLICATION_MODAL', showDeleteApplicationModal: setShowDeleteApplicationModal });
    },
    setSortOrder: (sortProperty: string, sortOrder: SortOrder): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SORTING', sortProperty: sortProperty, sortOrder: sortOrder });
    },
    setSearchTerm: (searchTerm: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SEARCH_TERM_APPLICATIONS', searchTerm: searchTerm });
    },
    GetApplicationsSearchResult: (applicationsSearchResult: Array<GetApplicationResponse>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applications) {
            let newApplicationsList = [];
            let searchTerm = appState.applications!.searchTerm.toLowerCase();
            let applications: Array<GetApplicationResponse> = applicationsSearchResult;

            for (let i = 0; i < applications.length; i++) {
                if (searchTerm.length > -1) {
                    if (
                        (applications[i].supervisor && applications[i].supervisor?.toLowerCase().search(searchTerm) > -1)
                        || (applications[i].examiner && applications[i].examiner?.toLowerCase().search(searchTerm) > -1)
                    ) {
                        newApplicationsList.push(applications[i]);
                    }
                }
            }

            dispatch({ type: 'SET_APPLICATIONS_SEARCH_RESULT', applicationsSearchResult: newApplicationsList});
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ApplicationsState = {
    selectedApplication: {
        id: 0,
        applicationStatus: ApplicationStatus.NA,
        dekraNumber: '',
        wpsArcEnergyText: '',
        wpsHeatInputText: '',
        weldingProtocolId: 0,
        weldingProtocolApplicationId: 0,
        revisionWpqrAction: '',
        wpsId: 0,
        wpqrId: 0,
        weldingProtocolSectionsResponse: {
            heatNumber1: '',
            heatNumber2: '',
            chargeAltHeatNumber1: '',
            chargeAltHeatNumber2: '',
            productType1Id: 0,
            productType2Id: 0,
            otherInformation1: '',
            otherInformation2: '',
            gunAngle: '',
            hydrogenBakeOut: 0,
            oscillationAmplitudeFrequencyHoldingTime: '',
            postWeldHeatTreatment: '',
            powerSourceManufacturer: '',
            plasmaWeldingDetails: '',
            pulseWeldingDetails: '',
            assignmentNumber: '',
            dekraNumberDisplay: '',
            markingOfTheTest: '',
            throatThickness: '',
            remarks: '',
            placeForMonitoring: '',
            weldingDate: '',
            signature: '',
            employeeNumber: '',
            dateOfBirth: '',
            firstName: '',
            lastName: '',
            placeOfBirth: ''

        },
        rangeOfQualificationExaminationResponse : {
            address: '',
            date: '',
            dateOfIssue: '',
            dateOfWelding: '',
            examinerOrExaminingBody: '',
            location: '',
            manufacturer: '',
            name: '',
            testingStandardId: 0,
            enableStandardText: false,
            standardText: '',
            weldingProcess: '',
            jointType: '',
            materialThicknesses: '',
            designations: '',
            shieldingGases: '',
            currentTypes: '',
            dropletTransitions: '',
            makes: '',
            otherInformations: '',
            weldingMetalThicknessText: '',
            enableWeldingMetalThicknessText: false,
            materialGroupText: '',
            enableMaterialGroupText: false,
            enableMaterialThicknessesText: false,
            weldingPositionsText: '',
            enableWeldingProcessText: false,
            enableJointTypeText: false,
            enableThroatThicknessText: false,
            enableRunTypeText: false,
            enableDiameterText: false,
            enableMakesText: false,
            enableShieldingGasesText: false,
            enableRootProtectionGasText: false,
            enableCurrentTypesText: false,
            enableDropletTransitionsText: false,
            enableHeatInputText: false,
            enableArcEnergyText: false,
            enableDesignationsText: false,
            enablePreheatTemperatureText: false,
            enableInterpassTemperatureText: false,
            enablePostWeldHeatTreatmentText: false,
            weldingProcessText: '',
            jointTypeText: '',
            throatThicknessText: '',
            runTypeText: '',
            diameterText: '',
            designationsTemp: '',
            makesTemp: '',
            shieldingGasesTemp: '',
            rootProtectionGasText: '',
            currentTypesTemp: '',
            dropletTransitionsTemp: '',
            heatInputText: '',
            arcEnergyText: '',
            postWeldHeatTreatmentText: '',
            interpassTemperatureText: '',
            preheatTemperatureText: '',

        },
        welders: [],
        company: {
            id: 0,
            name: '',
            streetAddress: '',
            boxAddress: '',
            postcode: '',
            city: '',
            phone: '',
            contactPerson: '',
            contactPersonEmail: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        company2: {
            id: 0,
            name: '',
            streetAddress: '',
            boxAddress: '',
            postcode: '',
            city: '',
            phone: '',
            contactPerson: '',
            contactPersonEmail: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        additionalRequirements: [],
        otherOptions: [],
        standard: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        testingOrderResponse: {
            customer: '',
            contactPerson: '',
            weldingDate: '',
            phoneNumber: '',
            welderBrazerFirstName: '',
            welderBrazerLastName: '',
            pWPSPBPS: '',
            manufacturerWPQRBPARNumber: '',
            weldingBrazingPositions: '',
            weldingProcess: '',
            fillerMaterial: '',
            ndtRemarks: '',
            visual: '',
            mtPT: '',
            rt: '',
            ut: '',
            ndtOther: '',
            conditionsAndDescriptionRemarks: '',
            material1: '',
            materialGroup1: '',
            dimension1: '',
            standardForAcceptance1: '',
            chargeNumber1: '',
            impactTestTemperature1: '',
            directionOfPlateRolling1: '',
            materialSpecifications1Remarks: '',
            material2: '',
            materialGroup2: '',
            dimension2: '',
            standardForAcceptance2: '',
            chargeNumber2: '',
            impactTestTemperature2: '',
            directionOfPlateRolling2: '',
            materialSpecifications2Remarks: '',
            iso156141Edition2004: false,
            iso156141Edition2017: false,
            transverseTensilePlusRp0ReH: false,
            macroPlusPhoto: false,
            hardnessTesting: false,
            impactTesting: false,
            bendTesting: false,
            additionalTesting: false,
            iso134454Testing: false,
            iso134454ImpactTesting: false,
            iso134454MicroTesting: false,
            iso134454FerriteTesting: false,
            iso134454LongitudinalTensileTesting: false,
            iso134454AdditionalTesting: false,
            iso134454ImpactTestingText: '',
            iso134454MicroTestingText: '',
            iso134454FerriteTestingText: '',
            iso134454LongitudinalTensileTestingText: '',
            iso134454AdditionalTestingText: '',
            iso129526LongitudinalTensileTesting: false,
            iso129526ImpactTesting: false,
            iso129526AdditionalTesting: false,
            iso129526Testing: false,
            iso129526LongitudinalTensileTestingText: '',
            iso129526ImpactTestingText: '',
            iso129526AdditionalTestingText: '',
            iso10902: false,
            iso10902AdditionalTesting: false,
            iso10902CruciformTensileTesting: false,
            iso10902ImpactTesting: false,
            iso10902MacroPlusPhoto: false,
            iso10902MicroTesting: false,
            iso10902WeldabilityPrimer: false,
            iso10902AdditionalTestingText: '',
            iso10902CruciformTensileTestingText: '',
            iso10902ImpactTestingText: '',
            iso10902MacroPlusPhotoText: '',
            iso10902MicroTestingText: '',
            iso10902WeldabilityPrimerText: '',
            norsokAdditionalTesting1: false,
            norsokAdditionalTesting2: false,
            norsokAdditionalTesting3: false,
            norsokAdditionalTesting4: false,
            norsokAdditionalTestingText: '',
            norsokAdditionalTesting1Text: '',
            norsokAdditionalTesting2Text: '',
            norsokAdditionalTesting3Text: '',
            norsokAdditionalTesting4Text: '',
            norsokCorrosionTestingText: '',
            norsok: false,
            norsokCorrosionTesting: false,
            norsokAdditionalTesting: false,
            iso14555ApplicationGreaterThan100Degrees: false,
            iso14555ApplicationLessThanOrEqualTo100Degrees: false,
            iso14555BendTesting: false,
            iso14555ArcStudWelding: false,
            iso14555BendTestingTorqueWrench: false,
            iso14555TensileTesting: false,
            iso14555MacroPlusPhoto: false,
            iso14555TorqueTesting: false,
            iso14555AdditionalTesting: false,
            iso14555RTProvning: false,
            iso14555_3834Testing: false,
            iso14555_783Testing: false,
            iso14555_784Testing: false,
            iso14555_785Testing: false,
            iso14555_786Testing: false,
            iso14555_787Testing: false,
            iso14555ApplicationGreaterThan100DegreesText: '',
            iso14555ApplicationLessThanOrEqualTo100DegreesText: '',
            iso14555BendTestingText: '',
            iso14555BendTestingTorqueWrenchText: '',
            iso14555TensileTestingText: '',
            iso14555MacroPlusPhotoText: '',
            iso14555TorqueTestingText: '',
            iso14555AdditionalTestingText: '',
            iso14555RTProvningText: '',
            iso14555_3834TestingText: '',
            iso13134TensileTesting: false,
            iso13134BendTesting: false,
            iso13134HardnessTesting: false,
            iso13134ShearTesting: false,
            iso13134PeelTesting: false,
            iso13134AdditionalTesting: false,
            iso13134BrazinProcedureApproval: false,
            iso13134TensileTestingText: '',
            iso13134BendTestingText: '',
            iso13134HardnessTestingText: '',
            iso13134ShearTestingText: '',
            iso13134PeelTestingText: '',
            iso13134AdditionalTestingText: '',
            other: '',
            ndtApproved: '',
            ndtSignature: '',
            ndtDate: '',
            destructiveTestingApproved: '',
            destructiveTestingSignature: '',
            destructiveTestingDate: '',
            orderedByLevel3: '',
            date: '',
            signature: '',
            iso15614transverseTensilePlusRp0ReHText: '',
            iso15614macroPlusPhotoText: '',
            iso15614hardnessTestingText: '',
            iso15614impactTestingText: '',
            iso15614bendTestingText: '',
            iso15614additionalTestingText: '',
            iso15614TestingText: '',
            pedAdditionalTesting: false,
            dekraProcedureNoTesting: '',
            invoiceReferenceName: '',
            uppdragsnr: '',
            testmarking: '',
            nace: false,
            naceMr0103Testing: false,
            naceMr0103TestingText: '',
            naceMr0175Testing: false,
            naceMr0175TestingText: '',
            naceAdditionalTesting1: false,
            naceAdditionalTesting1Text: '',
            naceAdditionalTesting2: false,
            naceAdditionalTesting2Text: '',
        },
        numberOfWeldingPositions: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        pwps: {
            id: 0,
            date: '',
            projectNumber: '',
            otherInformation: '',
            otherOptionParametersTableId: 0,
            byStandard: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            standardText: '',
            enableStandardText: false,
            revisionNumber: '',
            pwpsNumber: '',
            wpqrNumber: '',
            manufacturer: '',
            examiner: '',
            confirmedMessage: '',
            parentMaterial: {
                id: 0,
                baseMaterial1: {
                    id: 0,
                    name: '',
                    number: '',
                    description: '',
                    materialGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    technicalDeliveryConditions: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                technicalDeliveryCondition1: {
                    id: 0,
                    name: '',
                    deletedAt: '',
                    createdAt: '',
                    updatedAt: '',
                    description: ''
                },
                materialThickness1: 0.0,
                diameter1: 0.0,
                baseMaterial2: {
                    id: 0,
                    name: '',
                    number: '',
                    description: '',
                    materialGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    technicalDeliveryConditions: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                technicalDeliveryCondition2: {
                    id: 0,
                    name: '',
                    deletedAt: '',
                    createdAt: '',
                    updatedAt: '',
                    description: ''
                },
                materialThickness2: 0.0,
                diameter2: 0.0,
                enableBaseMaterial1Text: false,
                enableBaseMaterial2Text: false,
                baseMaterial1Text: '',
                baseMaterial2Text: '',
                materialGroup1Text: '',
                materialGroup2Text: '',
                technicalDeliveryCondition1Text: '',
                technicalDeliveryCondition2Text: '',
                enableMaterialThickness: false,
                materialThickness1Text: '',
                materialThickness2Text: '',
                enableDiameter: false,
                diameter1Text: '',
                diameter2Text: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldingData: {
                id: 0,
                jointType: {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                },
                rootSupportDetails: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                weldingPosition: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                weldingPosition2: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                rootProtectionGas: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                plasmaGas: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                nozzleDiameter: '',
                stickOut: '',
                jointPreparationSearchText: '',
                tungstenElectrode: '',
                electrodeTorchAngle: '',
                maxWidthOfRun: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: '',
                lengthTackWelds: '',
                tackWeldText: '',
                plasmaflowrate: '',
                electrodeTorchAngle111: '',
                enableJointTypeText: false,
                jointTypeText: '',
            },
            weldingProcess: {
                id: 0,
                weldingMethod1: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                make1: {
                    id: 0,
                    name: '',
                    description: '',
                    designation: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    fmGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                shieldingGas1: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                arcType1: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                weldingMethod2: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                make2: {
                    id: 0,
                    name: '',
                    description: '',
                    designation: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    fmGroup: {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    },
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: '',
                },
                shieldingGas2: {
                    id: 0,
                    name: '',
                    content: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                arcType2: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                enableMakeAText: false,
                makeAText: '',
                designationAText: '',
                fmGroupAText: '',
                enableMakeBText: false,
                makeBText: '',
                designationBText: '',
                fmGroupBText: '',
                typeOfBackingText: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldingParameters: [],
            filesUpload: [],
            jointDesign: {
                id: 0,
                materialThickness1: 0.0,
                materialThickness2: 0.0,
                jointType: {
                    id: 0,
                    name: '',
                    description: '',
                    jointShapes: [],
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                jointShape: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                jointAngleAlpha: 0.0,
                gapOpening: 0.0,
                straightEdge: 0.0,
                phaseAngle1: 0.0,
                phaseAngle2: 0.0,
                jointAngleBeta: 0.0,
                throatThickness: 0.0,
                rootSupport: {
                    id: 0,
                    name: '',
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                depthOfPreparation: 0.0,
                radius: 0.0,
                note: '',
                jointDesignHtmlImage: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            weldSequence: {
                createdAt: '',
                deletedAt: '',
                updatedAt: '',
                imageId: 0,
                imageData: '',
                sequenceNumber1: '',
                sequenceNumber2: '',
                sequenceNumber3: '',
                sequenceNumber4: '',
                sequenceNumber5: '',
                sequenceNumber6: '',
                sequenceNumber7: '',
                sequenceNumber8: '',
                sequenceNumber9: '',
                sequenceNumber10: '',
                sequenceNumber11: '',
                sequenceNumber12: '',
                sequenceNumber13: '',
                sequenceNumber14: '',
                sequenceNumber15: ''
            },
            temperature: {
                id: 0,
                preheatTemperature: 0.0,
                maxInterpassTemperature: 0.0,
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            afterWelding: {
                id: 0,
                heatingRate: '',
                coolingRate: '',
                createdAt: '',
                updatedAt: '',
                deletedAt: ''
            },
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        supervisor: '',
        examiner: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: '',
        confirmedAt: ''
    },
    showDeleteApplicationModal: false,
    sortOrder: SortOrder.Descending,
    sortProperty: 'application.confirmedAt',
    applicationsSearchResult: [],
    searchTerm: ''
};

export const reducer: Reducer<ApplicationsState> = (state: ApplicationsState | undefined, incomingAction: Action): ApplicationsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_SELECTED_APPLICATION':
            return {
                selectedApplication: action.selectedApplication,
                showDeleteApplicationModal: state.showDeleteApplicationModal,
                sortOrder: state.sortOrder,
                sortProperty: state.sortProperty,
                applicationsSearchResult: state.applicationsSearchResult,
                searchTerm: state.searchTerm
            };
        case 'SET_SHOW_DELETE_APPLICATION_MODAL':
            return {
                selectedApplication: state.selectedApplication,
                showDeleteApplicationModal: action.showDeleteApplicationModal,
                sortOrder: state.sortOrder,
                sortProperty: state.sortProperty,
                applicationsSearchResult: state.applicationsSearchResult,
                searchTerm: state.searchTerm
            };
        case 'SET_SORTING':
            return {
                selectedApplication: state.selectedApplication,
                showDeleteApplicationModal: state.showDeleteApplicationModal,
                sortOrder: action.sortOrder,
                sortProperty: action.sortProperty,
                applicationsSearchResult: state.applicationsSearchResult,
                searchTerm: state.searchTerm
            };
        case 'SET_APPLICATIONS_SEARCH_RESULT':
            return {
                selectedApplication: state.selectedApplication,
                showDeleteApplicationModal: state.showDeleteApplicationModal,
                sortOrder: state.sortOrder,
                sortProperty: state.sortProperty,
                applicationsSearchResult: action.applicationsSearchResult,
                searchTerm: state.searchTerm
            };
        case 'SET_SEARCH_TERM_APPLICATIONS':
            return {
                selectedApplication: state.selectedApplication,
                showDeleteApplicationModal: state.showDeleteApplicationModal,
                sortOrder: state.sortOrder,
                sortProperty: state.sortProperty,
                applicationsSearchResult: state.applicationsSearchResult,
                searchTerm: action.searchTerm
            };
        default:
            return state;
    }
};
