﻿export class UploadFileResponse {
    id: number;
    filePath: string;
    fileName: string;
    fileBase64: string;
    fileUrl: string;
    public constructor() {
        this.filePath = '';
        this.fileName = '';
        this.fileBase64 = '';
        this.fileUrl = '';
        this.id = 0;
    }
}