
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AdditionalRequirementType } from '../store/models/AdditionalRequirementType';
import { AfterWelding } from './AfterWelding';
import EventBus from './eventBus';
import { Header } from './Header';
import { AppThunkAction } from './index';
import { ApplicationStatus } from './models/ApplicationStatus';
import authHeader from './models/auth-header';
import HelpFunctions from './models/HelpFunctions';
import { OtherOptions } from './models/OtherOptions';
import { ParentMaterial } from './models/ParentMaterial';
import { QWBPOrder } from './models/QWBPOrder';
import { CreateApplicationRequest } from './models/requests/CreateApplicationRequest';
import { SendGridEmailSenderRequest } from './models/requests/SendGridEmailSenderRequest';
import { UpdateApplicationRequest } from './models/requests/UpdateApplicationRequest';
import { UpdateApplicationStatusRequest } from './models/requests/UpdateApplicationStatusRequest';
import { UpdateFilesRequest } from './models/requests/UpdateFilesRequest';
import { UpdateFilesUploadRequest } from './models/requests/UpdateFilesUploadRequest';
import { UpdateWeldingParametersRequest } from './models/requests/UpdateWeldingParametersRequest';
import { CreateApplicationResponse } from './models/responses/CreateApplicationResponse';
import { GetAdditionalRequirementResponse } from './models/responses/GetAdditionalRequirementResponse';
import { GetApplicationResponse } from './models/responses/GetApplicationResponse';
import { GetArcTypeResponse } from './models/responses/GetArcTypeResponse';
import { GetBaseMaterialResponse } from './models/responses/GetBaseMaterialResponse';
import { GetCompanyResponse } from './models/responses/GetCompanyResponse';
import { GetCurrentTypeResponse } from './models/responses/GetCurrentTypeResponse';
import { GetFilesUploadResponse } from './models/responses/GetFilesUploadResponse';
import { GetIndexResponse } from './models/responses/GetIndexResponse';
import { GetJointDesignResponse } from './models/responses/GetJointDesignResponse';
import { GetJointPreparationResponse } from './models/responses/GetJointPreparationResponse';
import { GetJointTypeResponse } from './models/responses/GetJointTypeResponse';
import { GetMakeResponse } from './models/responses/GetMakeResponse';
import { GetNumberOfWeldingPositionsResponse } from './models/responses/GetNumberOfWeldingPositionsResponse';
import { GetOtherOptionResponse } from './models/responses/GetOtherOptionResponse';
import { GetPWPSResponse } from './models/responses/GetPWPSResponse';
import { GetRootProtectionGasResponse } from './models/responses/GetRootProtectionGasResponse';
import { GetRootSupportDetailsResponse } from './models/responses/GetRootSupportDetailsResponse';
import { GetShieldingGasResponse } from './models/responses/GetShieldingGasResponse';
import { GetStandardResponse } from './models/responses/GetStandardResponse';
import { GetWelderResponse } from './models/responses/GetWelderResponse';
import { GetWeldingMethodResponse } from './models/responses/GetWeldingMethodResponse';
import { GetWeldingPositionResponse } from './models/responses/GetWeldingPositionResponse';
import { GetWeldSequenceResponse } from './models/responses/GetWeldSequenceResponse';
import { SendGridEmailSenderResponse } from './models/responses/SendGridEmailSenderResponse';
import { UpdateApplicationResponse } from './models/responses/UpdateApplicationResponse';
import { UpdateApplicationStatusResponse } from './models/responses/UpdateApplicationStatusResponse';
import { UpdateFilesResponse } from './models/responses/UpdateFilesResponse';
import { StandardType } from './models/StandardType';
import { Temperature } from './models/Temperature';
import { WeldingData } from './models/WeldingData';
import { WeldingParameters } from './models/WeldingParameters';
import { WeldingProcess } from './models/WeldingProcess';
import { OtherInformation } from './OtherInformation';
import { UserListObject } from './UserListObject';

// -----------------
// STATE - This defines the type of data maintained in the Redux store

export interface ApplicationTabStoreState {
    applicationId: number;
    additionalRequirements: Array<GetAdditionalRequirementResponse>;
    afterWelding: AfterWelding;
    baseMaterials: Array<GetBaseMaterialResponse>;
    company: GetCompanyResponse;
    currentTypes: Array<GetCurrentTypeResponse>;
    header: Header;
    indexes: Array<GetIndexResponse>;
    makes: Array<GetMakeResponse>;
    numberOfWeldingPositions: Array<GetNumberOfWeldingPositionsResponse>;
    otherInformation: OtherInformation;
    otherOptions: Array<GetOtherOptionResponse>;
    parentMaterial: ParentMaterial;
    qwbpOrder: QWBPOrder;
    selectedNumberOfWeldingPositions: GetNumberOfWeldingPositionsResponse;
    selectedStandard: GetStandardResponse;
    selectedTabId: string;
    selectedWelders: Array<GetWelderResponse>;
    showJointDesignModal: boolean;
    standards: Array<GetStandardResponse>;
    temperature: Temperature;
    updatingApplication: boolean;
    welderSearchResult: Array<GetWelderResponse>;
    welderSearchText: string;
    weldingData: WeldingData;
    weldingParameters: Array<WeldingParameters>;
    weldingProcess: WeldingProcess;
    jointDesign: GetJointDesignResponse;
    weldSequence: GetWeldSequenceResponse;
    filesUpload: Array<GetFilesUploadResponse>;
    currentCustomerId: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface ClearPWPSAction {
    type: 'CLEAR_PWPS';
    header: Header;
}

export interface ClearSelectEmployeeAction {
    type: 'CLEAR_SELECT_EMPLOYEE';
    searchWelderText: string;
    selectedWelders: GetWelderResponse[];
}

export interface GetWelderParametersAction {
    type: 'GET_WELDING_PARAMETERS';
    weldingParameters: WeldingParameters[];
}

export interface ClearStandardAction {
    type: 'CLEAR_STANDARD';
    selectedStandard: GetStandardResponse;
    selectedNumberOfWeldingPositions: GetNumberOfWeldingPositionsResponse;
}

export interface ClearWelderSearchResultAction {
    type: 'CLEAR_WELDER_SEARCH_RESULT';
}

export interface CreateApplicationReceiveAction {
    type: "CREATE_APPLICATION_RECEIVE";
    applicationId: number;
}

export interface CreateApplicationRequestAction {
    type: "CREATE_APPLICATION_REQUEST";
}

export interface GetAdditionalRequirementsReceiveAction {
    type: 'GET_ADDITIONAL_REQUIREMENTS_RECEIVE';
    additionalRequirements: Array<GetAdditionalRequirementResponse>;
}

export interface SetApplicationFilesUploadAction {
    type: 'SET_APPLICATION_FILES_UPLOAD';
    filesUpload: Array<GetFilesUploadResponse>;
}

export interface GetAdditionalRequirementsRequestAction {
    type: 'GET_ADDITIONAL_REQUIREMENTS_REQUEST';
}

export interface GetArcTypeASearchResultRequestAction {
    type: 'GET_ARC_TYPE_A_SEARCH_RESULT_REQUEST';
}

export interface GetArcTypeBSearchResultRequestAction {
    type: 'GET_ARC_TYPE_B_SEARCH_RESULT_REQUEST';
}

export interface GetBaseMaterial1SearchResultRequestAction {
    type: 'GET_BASE_MATERIAL_1_SEARCH_RESULT_REQUEST';
}

export interface GetBaseMaterial2SearchResultRequestAction {
    type: 'GET_BASE_MATERIAL_2_SEARCH_RESULT_REQUEST';
}

export interface GetCompanyReceiveAction {
    type: 'GET_COMPANY_RECEIVE';
    company: GetCompanyResponse;
}

export interface GetCompanyRequestAction {
    type: 'GET_COMPANY_REQUEST';
}

export interface GetCurrentTypeReceiveAction {
    type: 'GET_CURRENT_TYPES_RECEIVE';
    currentTypes: Array<GetCurrentTypeResponse>;
}

export interface GetCurrentTypeRequestAction {
    type: 'GET_CURRENT_TYPES_REQUEST';
}

export interface GetIndexesReceiveAction {
    type: 'GET_INDEXES_RECEIVE';
    indexes: Array<GetIndexResponse>;
}

export interface GetIndexesRequestAction {
    type: 'GET_INDEXES_REQUEST';
}

export interface GetJointTypesAllReceiveAction {
    type: 'GET_JOINT_TYPES_ALL_RECEIVE';
    jointTypes: Array<GetJointTypeResponse>;
}

export interface GetJointTypesRequestAction {
    type: 'GET_JOINT_TYPES_REQUEST';
}

export interface GetJointPreparationsRequestAction {
    type: 'GET_JOINT_PREPARATIONS_REQUEST';
}

export interface GetMakeASearchResultRequestAction {
    type: 'GET_MAKE_A_SEARCH_RESULT_REQUEST';
}

export interface GetMakeBSearchResultRequestAction {
    type: 'GET_MAKE_B_SEARCH_RESULT_REQUEST';
}

export interface GetNumberOfWeldingPositionsReceiveAction {
    type: 'GET_NUMBER_OF_WELDING_POSITIONS_RECEIVE';
    numberOfWeldingPositions: Array<GetNumberOfWeldingPositionsResponse>;
}

export interface GetNumberOfWeldingPositionsRequestAction {
    type: 'GET_NUMBER_OF_WELDING_POSITIONS_REQUEST';
}

export interface GetOtherOptionsReceiveAction {
    type: 'GET_OTHER_OPTIONS_RECEIVE';
    otherOptions: Array<GetOtherOptionResponse>;
}

export interface GetOtherOptionsRequestAction {
    type: 'GET_OTHER_OPTIONS_REQUEST';
}

export interface GetRootProtectionGasSearchResultRequestAction {
    type: 'GET_ROOT_PROTECTION_GAS_SEARCH_RESULT_REQUEST';
}

export interface GetRootSupportDetailsSearchResultRequestAction {
    type: 'GET_ROOT_SUPPORT_DETAILS_SEARCH_RESULT_REQUEST';
}

export interface GetShieldingGasSearchResultRequestAction {
    type: 'GET_SHIELDING_GAS_SEARCH_RESULT_REQUEST';
}

export interface GetStandardsReceiveAction {
    type: 'GET_STANDARDS_RECEIVE';
    standards: Array<GetStandardResponse>;
}

export interface GetStandardsRequestAction {
    type: 'GET_STANDARDS_REQUEST';
}

export interface GetStandardsByMaterialGroupIdAction {
    type: 'GET_STANDARDS_BY_MATERIAL_GROUP_ID_REQUEST';
}

export interface GetWeldingMethodASearchResultRequestAction {
    type: 'GET_WELDING_METHOD_A_SEARCH_RESULT_REQUEST';
}

export interface GetWeldingMethodBSearchResultRequestAction {
    type: 'GET_WELDING_METHOD_B_SEARCH_RESULT_REQUEST';
}

export interface GetWeldingPositionSearchResultRequestAction {
    type: 'GET_WELDING_POSITION_SEARCH_RESULT_REQUEST';
}

export interface GetWelderSearchResultReceiveAction {
    type: 'GET_WELDER_SEARCH_RESULT_RECEIVE';
    welderSearchResult: Array<GetWelderResponse>;
}

export interface GetWelderSearchResultRequestAction {
    type: 'GET_WELDER_SEARCH_RESULT_REQUEST';
}

export interface InitPWPSAction {
    type: 'INIT_PWPS';
    header: Header;
}

export interface InitQWBPOrderAction {
    type: 'INIT_QWBP_ORDER';
    qwbpOrder: QWBPOrder;
}

export interface GetJointDesignAction {
    type: 'GET_JOINT_DESIGN';
    jointDesign: GetJointDesignResponse;
}

export interface GetWeldSequenceAction {
    type: 'GET_WELD_SEQUENCE';
    weldSequence: GetWeldSequenceResponse;
}

export interface SetAdditionalRequirementSelectedAction {
    type: 'SET_ADDITIONAL_REQUIREMENT_SELECTED';
    additionalRequirements: Array<GetAdditionalRequirementResponse>;
}

export interface SetAfterWeldingAction {
    type: 'SET_AFTER_WELDING';
    afterWelding: AfterWelding;
}

export interface SetCompanyAction {
    type: 'SET_COMPANY';
    company: GetCompanyResponse;
}

export interface SetHeaderAction {
    type: 'SET_HEADER';
    header: Header;
}

export interface SetOtherInformationAction {
    type: 'SET_OTHER_INFORMATION';
    otherInformation: OtherInformation;
}

export interface SetOtherOptionSelectedAction {
    type: 'SET_OTHER_OPTION_SELECTED';
    otherOptions: Array<GetOtherOptionResponse>;
}

export interface SetParentMaterialAction {
    type: 'SET_PARENT_MATERIAL';
    parentMaterial: ParentMaterial;
}

export interface SetQWBPOrderAction {
    type: 'SET_QWBP_ORDER';
    qwbpOrder: QWBPOrder;
}

export interface SetSelectedNumberOfWeldingPositionsAction {
    type: 'SET_SELECTED_NUMBER_OF_WELDING_POSITIONS';
    selectedNumberOfWeldingPositions: GetNumberOfWeldingPositionsResponse;
}

export interface SetSelectedStandardAction {
    type: 'SET_SELECTED_STANDARD';
    selectedStandard: GetStandardResponse;
}

export interface SetSelectedTabIdAction {
    type: 'SET_SELECTED_TAB_ID';
    selectedTabId: string;
}

export interface SetSelectedWelders {
    type: 'SET_SELECTED_WELDERS';
    selectedWelders: Array<GetWelderResponse>;
}

export interface SetTemperatureAction {
    type: 'SET_TEMPERATURE';
    temperature: Temperature;
}

export interface SetWelderSearchTextAction {
    type: 'SET_WELDER_SEARCH_TEXT';
    welderSearchText: string;
}

export interface SetWeldingDataAction {
    type: 'SET_WELDING_DATA';
    weldingData: WeldingData;
}

export interface SetWeldingProcessAction {
    type: 'SET_WELDING_PROCESS';
    weldingProcess: WeldingProcess;
}

export interface ToggleJointDesignModalAction {
    type: 'TOGGLE_JOINT_DESIGN_MODAL';
    showJointDesignModal: boolean;
}

export interface UpdateApplicationReceiveAction {
    type: 'UPDATE_APPLICATION_RECEIVE';
    applicationId: number;
}

export interface UpdateApplicationRequestAction {
    type: 'UPDATE_APPLICATION_REQUEST';
}

export interface UploadFileReceiveAction {
    type: 'UPLOAD_FILE_RECEIVE_ACTION';
    path: string;
}

export interface UploadFileRequestAction {
    type: 'UPLOAD_FILE_REQUEST_ACTION';
}

export interface ResetStateAction {
    type: 'RESET_STATE_ACTION';
}

export interface SetCurrentCustomerId {
    type: 'SET_CURRENT_CUSTOMER_ID';
    currentCustomerId: number;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ClearPWPSAction
    | ClearSelectEmployeeAction
    | ClearStandardAction
    | CreateApplicationReceiveAction
    | CreateApplicationRequestAction
    | ClearWelderSearchResultAction
    | GetAdditionalRequirementsReceiveAction
    | GetAdditionalRequirementsRequestAction
    | GetArcTypeASearchResultRequestAction
    | GetArcTypeBSearchResultRequestAction
    | GetBaseMaterial1SearchResultRequestAction
    | GetBaseMaterial2SearchResultRequestAction
    | GetCompanyReceiveAction
    | GetCompanyRequestAction
    | GetCurrentTypeReceiveAction
    | GetCurrentTypeRequestAction
    | GetIndexesReceiveAction
    | GetIndexesRequestAction
    | GetJointTypesAllReceiveAction
    | GetJointTypesRequestAction
    | GetMakeASearchResultRequestAction
    | GetMakeBSearchResultRequestAction
    | GetNumberOfWeldingPositionsReceiveAction
    | GetNumberOfWeldingPositionsRequestAction
    | GetOtherOptionsReceiveAction
    | GetOtherOptionsRequestAction
    | GetRootProtectionGasSearchResultRequestAction
    | GetRootSupportDetailsSearchResultRequestAction
    | GetShieldingGasSearchResultRequestAction
    | GetStandardsReceiveAction
    | GetStandardsRequestAction
    | GetStandardsByMaterialGroupIdAction
    | GetWelderSearchResultReceiveAction
    | GetWelderSearchResultRequestAction
    | GetWeldingMethodASearchResultRequestAction
    | GetWeldingMethodBSearchResultRequestAction
    | GetWeldingPositionSearchResultRequestAction
    | InitPWPSAction
    | InitQWBPOrderAction
    | SetAdditionalRequirementSelectedAction
    | SetAfterWeldingAction
    | SetCompanyAction
    | SetHeaderAction
    | SetOtherInformationAction
    | SetOtherOptionSelectedAction
    | SetParentMaterialAction
    | SetQWBPOrderAction
    | SetWelderSearchTextAction
    | SetSelectedNumberOfWeldingPositionsAction
    | SetSelectedStandardAction
    | SetSelectedTabIdAction
    | SetSelectedWelders
    | SetTemperatureAction
    | SetWeldingDataAction
    | SetWeldingProcessAction
    | ToggleJointDesignModalAction
    | UpdateApplicationReceiveAction
    | UpdateApplicationRequestAction
    | UploadFileReceiveAction
    | UploadFileRequestAction
    | GetJointPreparationsRequestAction
    | ResetStateAction
    | SetCurrentCustomerId
    | GetJointDesignAction
    | GetWeldSequenceAction
    | GetWelderParametersAction
    | SetApplicationFilesUploadAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    addSelectedWelder: (welder: GetWelderResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let selectedWelders: Array<GetWelderResponse> = [...appState.applicationTab.selectedWelders];
            selectedWelders.splice(selectedWelders.length, 0, welder);
            dispatch({ type: 'SET_SELECTED_WELDERS', selectedWelders: selectedWelders });
        }
    },
    clearState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_STATE_ACTION'});
    },
    clearSelectEmployee: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_SELECT_EMPLOYEE', searchWelderText: '', selectedWelders: [] });
    },
    clearWeldingmethods: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.weldingMethods = [];
            let weldingMethodEmpty = {
                id: 0,
                name: '',
                efficiencyFactor: 0.0,
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, weldingMethodEmpty);
            
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearStandard: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedStandard: GetStandardResponse = {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        };
        let selectedNumberOfWeldingPositions: GetNumberOfWeldingPositionsResponse = {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        };
        dispatch({ type: 'CLEAR_STANDARD', selectedStandard: selectedStandard, selectedNumberOfWeldingPositions: selectedNumberOfWeldingPositions });
    },
    clearBaseMaterial1SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.baseMaterial1SearchResult = [];
            // Reset selected Base Material 1.
            parentMaterial.selectedBaseMaterial1.id = 0;
            parentMaterial.selectedBaseMaterial1.name = '';
            parentMaterial.selectedBaseMaterial1.number = '';
            parentMaterial.selectedBaseMaterial1.description = '';
            parentMaterial.selectedBaseMaterial1.materialGroup = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions = [];
            parentMaterial.selectedBaseMaterial1.createdAt = '';
            parentMaterial.selectedBaseMaterial1.deletedAt = '';
            parentMaterial.selectedBaseMaterial1.updatedAt = '';
            // Reset selected Material Group part 1.
            parentMaterial.selectedMaterialGroup1.id = 0;
            parentMaterial.selectedMaterialGroup1.name = '';
            parentMaterial.selectedMaterialGroup1.description = '';
            parentMaterial.selectedMaterialGroup1.createdAt = '';
            parentMaterial.selectedMaterialGroup1.deletedAt = '';
            parentMaterial.selectedMaterialGroup1.updatedAt = '';
            // Reset selected TechnicalDeliveryCondition part 1.
            parentMaterial.selectedTechnicalDeliveryCondition1.id = 0;
            parentMaterial.selectedTechnicalDeliveryCondition1.name = '';
            parentMaterial.selectedTechnicalDeliveryCondition1.description = '';
            parentMaterial.selectedTechnicalDeliveryCondition1.createdAt = '';
            parentMaterial.selectedTechnicalDeliveryCondition1.deletedAt = '';
            parentMaterial.selectedTechnicalDeliveryCondition1.updatedAt = '';
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    clearBaseMaterial2SearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.baseMaterial2SearchResult = [];
            // Reset selected Base Material 2.
            parentMaterial.selectedBaseMaterial2.id = 0;
            parentMaterial.selectedBaseMaterial2.name = '';
            parentMaterial.selectedBaseMaterial2.number = '';
            parentMaterial.selectedBaseMaterial2.description = '';
            parentMaterial.selectedBaseMaterial2.materialGroup = {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            };
            parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions = [];
            parentMaterial.selectedBaseMaterial2.createdAt = '';
            parentMaterial.selectedBaseMaterial2.deletedAt = '';
            parentMaterial.selectedBaseMaterial2.updatedAt = '';
            // Reset selected Material Group part 2.
            parentMaterial.selectedMaterialGroup2.id = 0;
            parentMaterial.selectedMaterialGroup2.name = '';
            parentMaterial.selectedMaterialGroup2.description = '';
            parentMaterial.selectedMaterialGroup2.createdAt = '';
            parentMaterial.selectedMaterialGroup2.deletedAt = '';
            parentMaterial.selectedMaterialGroup2.updatedAt = '';
            // Reset selected TechnicalDeliveryCondition part 2.
            parentMaterial.selectedTechnicalDeliveryCondition2.id = 0;
            parentMaterial.selectedTechnicalDeliveryCondition2.name = '';
            parentMaterial.selectedTechnicalDeliveryCondition2.description = '';
            parentMaterial.selectedTechnicalDeliveryCondition2.createdAt = '';
            parentMaterial.selectedTechnicalDeliveryCondition2.deletedAt = '';
            parentMaterial.selectedTechnicalDeliveryCondition2.updatedAt = '';
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    clearMakeASearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.makeASearchResult = [];
            // Reset selected Designation A.
            weldingProcess.selectedDesignationA.id = 0;
            weldingProcess.selectedDesignationA.name = '';
            weldingProcess.selectedDesignationA.description = '';
            // Reset selected FM Group A.
            weldingProcess.selectedFMGroupA.id = 0;
            weldingProcess.selectedFMGroupA.name = '';
            weldingProcess.selectedFMGroupA.description = '';
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearMakeBSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.makeBSearchResult = [];
            // Reset selected Designation B.
            weldingProcess.selectedDesignationB.id = 0;
            weldingProcess.selectedDesignationB.name = '';
            weldingProcess.selectedDesignationB.description = '';
            // Reset selected FM Group B.
            weldingProcess.selectedFMGroupB.id = 0;
            weldingProcess.selectedFMGroupB.name = '';
            weldingProcess.selectedFMGroupB.description = '';
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearRootProtectionGasSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.rootProtectionGasSearchResult = [];
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    clearShieldingGasASearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.shieldingGasASearchResult = [];
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearShieldingGasBSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.shieldingGasBSearchResult = [];
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearWelderSearchResult: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR_WELDER_SEARCH_RESULT' });
    },
    clearWeldingMethodASearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.weldingMethodASearchResult = [];
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    clearWeldingMethodBSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.weldingMethodBSearchResult = [];
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    createApplication: (createApplicationRequest: CreateApplicationRequest): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Applications/Create`, {
                method: 'POST',
            headers: authHeader.authHeader(),
                body: JSON.stringify(createApplicationRequest)
            })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as CreateApplicationResponse;
                    console.info(data);
                    dispatch({ type: 'CREATE_APPLICATION_RECEIVE', applicationId: data.id });

                }))
            .catch(error => {
                console.error(error);
            });
        
        dispatch({ type: 'CREATE_APPLICATION_REQUEST' });
    },
    getByUserIdAndApplicationStatus: (userId: string, applicationStatus: number, callback: Function): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Applications/GetByUserIdAndApplicationStatus/${userId}/${applicationStatus}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<GetApplicationResponse>)
            .then(data => {
                console.info(data);
                data.id ? callback(data.id) : callback(0)
            })
            .catch(error => {
                console.error(error);
            });
    },
    getAdditionalRequirementsAll: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/AdditionalRequirements/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
         }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetAdditionalRequirementResponse>;
                     console.info(data);
            dispatch({ type: 'GET_ADDITIONAL_REQUIREMENTS_RECEIVE', additionalRequirements: data });
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_ADDITIONAL_REQUIREMENTS_REQUEST' });
    },
    getArcTypeSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ArcTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetArcTypeResponse>;
                    console.info(data);
            const appState = getState();
            if (appState && appState.applicationTab) {
                let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
                weldingProcess.arcTypeSearchResult = [...data];
                dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
            }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_ARC_TYPE_A_SEARCH_RESULT_REQUEST' });
    },
    getBaseMaterial1SearchResult: (nameOrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/BaseMaterials/GetByStartsWithCaseInsensitiveNameOrNumberInclude/${nameOrNumber}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetBaseMaterialResponse>;
                     console.info(data);
            const appState = getState();
            if (appState && appState.applicationTab) {
                let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
                parentMaterial.baseMaterial1SearchResult = [...data];
                if (data.length === 0) {
                    // Reset selected Base Material 1.
                    parentMaterial.selectedBaseMaterial1.id = 0;
                    parentMaterial.selectedBaseMaterial1.name = '';
                    parentMaterial.selectedBaseMaterial1.number = '';
                    parentMaterial.selectedBaseMaterial1.description = '';
                    parentMaterial.selectedBaseMaterial1.materialGroup = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions = [];
                    parentMaterial.selectedBaseMaterial1.createdAt = '';
                    parentMaterial.selectedBaseMaterial1.deletedAt = '';
                    parentMaterial.selectedBaseMaterial1.updatedAt = '';
                    // Reset selected Material Group part 1.
                    parentMaterial.selectedMaterialGroup1.id = 0;
                    parentMaterial.selectedMaterialGroup1.name = '';
                    parentMaterial.selectedMaterialGroup1.description = '';
                    parentMaterial.selectedMaterialGroup1.createdAt = '';
                    parentMaterial.selectedMaterialGroup1.deletedAt = '';
                    parentMaterial.selectedMaterialGroup1.updatedAt = '';
                    // Reset selected TechnicalDeliveryCondition part 1.
                    parentMaterial.selectedTechnicalDeliveryCondition1.id = 0;
                    parentMaterial.selectedTechnicalDeliveryCondition1.name = '';
                    parentMaterial.selectedTechnicalDeliveryCondition1.description = '';
                    parentMaterial.selectedTechnicalDeliveryCondition1.createdAt = '';
                    parentMaterial.selectedTechnicalDeliveryCondition1.deletedAt = '';
                    parentMaterial.selectedTechnicalDeliveryCondition1.updatedAt = '';
                }
                dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
            }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_BASE_MATERIAL_1_SEARCH_RESULT_REQUEST' });
    },
    getBaseMaterial2SearchResult: (nameOrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/BaseMaterials/GetByStartsWithCaseInsensitiveNameOrNumberInclude/${nameOrNumber}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetBaseMaterialResponse>;
                     console.info(data);
            const appState = getState();
            if (appState && appState.applicationTab) {
                let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
                parentMaterial.baseMaterial2SearchResult = [...data];
                if (data.length === 0) {
                    // Reset selected Base Material 2.
                    parentMaterial.selectedBaseMaterial2.id = 0;
                    parentMaterial.selectedBaseMaterial2.name = '';
                    parentMaterial.selectedBaseMaterial2.number = '';
                    parentMaterial.selectedBaseMaterial2.description = '';
                    parentMaterial.selectedBaseMaterial2.materialGroup = {
                        id: 0,
                        name: '',
                        description: '',
                        createdAt: '',
                        deletedAt: '',
                        updatedAt: ''
                    };
                    parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions = [];
                    parentMaterial.selectedBaseMaterial2.createdAt = '';
                    parentMaterial.selectedBaseMaterial2.deletedAt = '';
                    parentMaterial.selectedBaseMaterial2.updatedAt = '';
                    // Reset selected Material Group part 2.
                    parentMaterial.selectedMaterialGroup2.id = 0;
                    parentMaterial.selectedMaterialGroup2.name = '';
                    parentMaterial.selectedMaterialGroup2.description = '';
                    parentMaterial.selectedMaterialGroup2.createdAt = '';
                    parentMaterial.selectedMaterialGroup2.deletedAt = '';
                    parentMaterial.selectedMaterialGroup2.updatedAt = '';
                    // Reset selected TechnicalDeliveryCondition part 2.
                    parentMaterial.selectedTechnicalDeliveryCondition2.id = 0;
                    parentMaterial.selectedTechnicalDeliveryCondition2.name = '';
                    parentMaterial.selectedTechnicalDeliveryCondition2.description = '';
                    parentMaterial.selectedTechnicalDeliveryCondition2.createdAt = '';
                    parentMaterial.selectedTechnicalDeliveryCondition2.deletedAt = '';
                    parentMaterial.selectedTechnicalDeliveryCondition2.updatedAt = '';
                }
                dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
            }
     
         
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_BASE_MATERIAL_2_SEARCH_RESULT_REQUEST' });
    },
    getCompanyById: (id: number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Companies/GetById/${id}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as GetCompanyResponse;
                    console.info(data);
                    dispatch({ type: 'GET_COMPANY_RECEIVE', company: data });

                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_COMPANY_REQUEST' });
    },
    getCustomerById: (id: number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Customer/GetById/${id}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let cust = res.data;
                    let data: GetCompanyResponse = {
                        boxAddress: "",
                        city: cust.customerContact.visitingCity ? cust.customerContact.visitingCity : "",
                        contactPerson: (cust.customerContact.firstName ? cust.customerContact.firstName + " " : "") + (cust.customerContact.lastName ? cust.customerContact.lastName : ""),
                        contactPersonEmail: cust.customerContact.email ? cust.customerContact.email : "",
                        createdAt: "",
                        deletedAt: "",
                        id: 0,
                        name: cust.name ? cust.name : "",
                        phone: cust.customerContact.phoneNumber ? cust.customerContact.phoneNumber : "",
                        postcode: cust.customerContact.visitingZipcode ? cust.customerContact.visitingZipcode : "",
                        streetAddress: cust.customerContact.visitingStreet ? cust.customerContact.visitingStreet : "",
                        updatedAt: ""
                    };
                    console.info(data);
                    dispatch({ type: 'GET_COMPANY_RECEIVE', company: data });
                }))
            .catch(error => {
                console.error(error);
                console.log(error);
            });
        dispatch({ type: 'GET_COMPANY_REQUEST' });
    },
    getCustomerByUserId: (id: string, callback: Function): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`/api/User/GetById/${id}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let user = res.data;
                    console.log("user");
                    console.log(user);
                    let data: GetCompanyResponse = {
                        boxAddress: "",
                        city: user.visitingCity ? user.visitingCity : "",
                        contactPerson: (user.firstName ? user.firstName + " " : "") + (user.lastName ? user.lastName : ""),
                        contactPersonEmail: user.email ? user.email : "",
                        createdAt: "",
                        deletedAt: "",
                        id: user.id,
                        name: user.customer.name ? user.customer.name : "",
                        phone: user.phoneNumber ? user.phoneNumber : "",
                        postcode: user.visitingZipcode ? user.visitingZipcode : "",
                        streetAddress: user.visitingStreet ? user.visitingStreet : "",
                        updatedAt: ""
                    };
                    console.info(data);
                    dispatch({ type: 'GET_COMPANY_RECEIVE', company: data });
                    callback();
                }))
            .catch(error => {
                console.error(error);
                console.log(error);
            });
        dispatch({ type: 'GET_COMPANY_REQUEST' });
    },
    setCurrentCustomerId: (customerId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('setCurrentCustomerId');
        if (appState && appState.applicationTab) {
            dispatch({ type: 'SET_CURRENT_CUSTOMER_ID', currentCustomerId: customerId });
        }
    },
    setDekraContactEmail: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('setDekraContactEmail');
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.dekraContactEmail = JSON.parse(window.localStorage.getItem('user')).dekraContact.email;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    getCurrentTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/CurrentTypes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetCurrentTypeResponse>;
                    let currentTypes = [...data];
                    let currentTypeEmpty = new GetCurrentTypeResponse();
                    currentTypes.splice(currentTypes.length, 0, currentTypeEmpty);
                    currentTypes.sort((a, b) => a.id - b.id);
                    console.info(data);
                    dispatch({ type: 'GET_CURRENT_TYPES_RECEIVE', currentTypes: currentTypes });
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_CURRENT_TYPES_REQUEST' });
    },
    getIndexesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Indexes/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetIndexResponse>;
        console.info(data);
            dispatch({ type: 'GET_INDEXES_RECEIVE', indexes: data });
         
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_INDEXES_REQUEST' });
    },
    getJointTypesAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/JointTypes/GetAllInclude`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetJointTypeResponse>;
       console.info(data);
            const appState = getState();
            if (appState && appState.applicationTab) {
                let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
                weldingData.jointTypes = [...data];
                dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
            }
         
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_JOINT_TYPES_REQUEST' });
    },
    getJointPreparationsAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/JointPreparations/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetJointPreparationResponse>;
       console.info(data);
            const appState = getState();
            if (appState && appState.applicationTab) {
                let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
                weldingData.jointPreparations = [...data];
                dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
            }
         
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_JOINT_PREPARATIONS_REQUEST' });
    },
    getMakeASearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Makes/GetByStartsWithCaseInsensitiveNameInclude/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetMakeResponse>;
                 console.info(data);
            const appState = getState();
            if (appState && appState.applicationTab) {
                let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
                weldingProcess.makeASearchResult = [...data];
                dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
            }
         
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MAKE_A_SEARCH_RESULT_REQUEST' });
    },
    getMakeBSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Makes/GetByStartsWithCaseInsensitiveNameInclude/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetMakeResponse>;
                  console.info(data);
            const appState = getState();
            if (appState && appState.applicationTab) {
                let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
                weldingProcess.makeBSearchResult = [...data];
                dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
            }
         
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_MAKE_B_SEARCH_RESULT_REQUEST' });
    },
    getNumberOfWeldingPositionsAll: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/NumberOfWeldingPositions/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetNumberOfWeldingPositionsResponse>;
            console.info(data);
            dispatch({ type: 'GET_NUMBER_OF_WELDING_POSITIONS_RECEIVE', numberOfWeldingPositions: data });
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_NUMBER_OF_WELDING_POSITIONS_REQUEST' });
    },
    getOtherOptionsAll: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/OtherOptions/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetOtherOptionResponse>;
                    console.info(data);
                    
                    dispatch({ type: 'GET_OTHER_OPTIONS_RECEIVE', otherOptions: data });
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_OTHER_OPTIONS_REQUEST' });
    },
    getRootProtectionGasSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RootProtectionGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetRootProtectionGasResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.applicationTab) {
                        let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
                        weldingData.rootProtectionGasSearchResult = [...data];
                        dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_ROOT_PROTECTION_GAS_SEARCH_RESULT_REQUEST' });
    },
    getRootSupportDetailsSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/RootSupportDetails/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetRootSupportDetailsResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.applicationTab) {
                        let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
                        weldingData.rootSupportDetailsSearchResult = [...data];
                        dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_ROOT_SUPPORT_DETAILS_SEARCH_RESULT_REQUEST' });
    },
    getShieldingGasASearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ShieldingGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetShieldingGasResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.applicationTab) {
                        let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
                        weldingProcess.shieldingGasASearchResult = [...data];
                        dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_SHIELDING_GAS_SEARCH_RESULT_REQUEST' });
    },
    getShieldingGasBSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/ShieldingGases/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetShieldingGasResponse>;
                    const appState = getState();
                    if (appState && appState.applicationTab) {
                        let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
                        weldingProcess.shieldingGasBSearchResult = [...data];
                        dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_SHIELDING_GAS_SEARCH_RESULT_REQUEST' });
    },
    getStandardsAll: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Standards/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetStandardResponse>;
                    console.info(data);
                    dispatch({ type: 'GET_STANDARDS_RECEIVE', standards: data });


                }))
        dispatch({ type: 'GET_STANDARDS_REQUEST' });
    },
    getWeldingMethodASearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingMethods/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    EventBus.dispatch("logout", "");
                }
                let data = res.data as Array<GetWeldingMethodResponse>;
                console.info(data);
                const appState = getState();
                if (appState && appState.applicationTab) {
                    let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
                    weldingProcess.weldingMethodASearchResult = [...data];
                    dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                }
            }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_WELDING_METHOD_A_SEARCH_RESULT_REQUEST' });
    },
    getWeldingMethodBSearchResult: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingMethods/GetByStartsWithCaseInsensitiveName/${name}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingMethodResponse>;
                    console.info(data);
                    const appState = getState();
                    if (appState && appState.applicationTab) {
                        let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
                        weldingProcess.weldingMethodBSearchResult = [...data];
                        dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
                    }

             }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_WELDING_METHOD_B_SEARCH_RESULT_REQUEST' });
    },
    getWeldingPositionSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/WeldingPositions/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWeldingPositionResponse>;
                    const appState = getState();
                    if (appState && appState.applicationTab) {
                        let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
                        weldingData.weldingPositionSearchResult = [];
                        for (let i = 0; i < [...data].length; i++) {
                            if ([...data][i].description === "") {
                                weldingData.weldingPositionSearchResult.push([...data][i]);
                            }
                        }
                        dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_WELDING_POSITION_SEARCH_RESULT_REQUEST' });
    },
    getWelderSearchResult: (firstName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Welders/GetByStartsWithCaseInsensitiveFirstName/${firstName}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetWelderResponse>;
                    dispatch({ type: 'GET_WELDER_SEARCH_RESULT_RECEIVE', welderSearchResult: data });
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_WELDER_SEARCH_RESULT_REQUEST' });
    },
    initPWPS: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab && appState.login) {
            // Initialize Header.
            let header: Header = { ...appState.applicationTab.header };
            let company: GetCompanyResponse = { ...appState.applicationTab.company };
            header.manufacturer = HelpFunctions.joinTwoStringWithComma(company.name, company.contactPerson);
            let currentUser: UserListObject = { ...appState.login.currentUser };
            header.customerLogo = currentUser === null ? "" : (currentUser.customer && currentUser.customer.customerLogo ? currentUser.customer.customerLogo : "");
            dispatch({ type: 'INIT_PWPS', header: header});
        }
    },
    initQWBPOrder: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let qwbpOrder: QWBPOrder = { ...appState.applicationTab.qwbpOrder };
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            qwbpOrder.weldingBrazingPositions = HelpFunctions.JoinTwoString(weldingData.selectedWeldingPosition.name, weldingData.selectedWeldingPosition2.name);
            let header: Header = { ...appState.applicationTab.header };
            qwbpOrder.pWPSPBPS = header.pwpsNumber;
            qwbpOrder.manufacturerWPQRBPARNumber = header.wpqrNumber;
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            qwbpOrder.dimension1 = HelpFunctions.JoinDiameterAndMaterialThicknes(parentMaterial.diameter1, parentMaterial.materialThickness1);
            qwbpOrder.dimension2 = HelpFunctions.JoinDiameterAndMaterialThicknes(parentMaterial.diameter2, parentMaterial.materialThickness2);
            if (parentMaterial.enableBaseMaterial1Text) {
                qwbpOrder.material1 = parentMaterial.baseMaterial1Text;
                qwbpOrder.materialGroup1 = parentMaterial.materialGroup1Text;
                qwbpOrder.standardForAcceptance1 = parentMaterial.technicalDeliveryCondition1Text;
            } else {
                qwbpOrder.material1 = parentMaterial.baseMaterial1SearchText;
                qwbpOrder.materialGroup1 = parentMaterial.selectedMaterialGroup1.name;
                qwbpOrder.standardForAcceptance1 = parentMaterial.selectedTechnicalDeliveryCondition1.name;
            }
            if (parentMaterial.enableBaseMaterial2Text) {
                qwbpOrder.material2 = parentMaterial.baseMaterial2Text;
                qwbpOrder.materialGroup2 = parentMaterial.materialGroup2Text;
                qwbpOrder.standardForAcceptance2 = parentMaterial.technicalDeliveryCondition2Text;
            } else {
                qwbpOrder.material2 = parentMaterial.baseMaterial2SearchText;
                qwbpOrder.materialGroup2 = parentMaterial.selectedMaterialGroup2.name;
                qwbpOrder.standardForAcceptance2 = parentMaterial.selectedTechnicalDeliveryCondition2.name;
            }
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            if (weldingProcess.enableMakeAText && weldingProcess.enableMakeBText) {
                qwbpOrder.fillerMaterial = HelpFunctions.JoinTwoString(weldingProcess.makeAText, weldingProcess.makeBText);
            } else if (weldingProcess.enableMakeAText) {
                qwbpOrder.fillerMaterial = HelpFunctions.JoinTwoString(weldingProcess.makeAText, weldingProcess.makeBSearchText);
            } else if (weldingProcess.enableMakeBText) {
                qwbpOrder.fillerMaterial = HelpFunctions.JoinTwoString(weldingProcess.makeASearchText, weldingProcess.makeBText);
            } else {
                qwbpOrder.fillerMaterial = HelpFunctions.JoinTwoString(weldingProcess.makeASearchText, weldingProcess.makeBSearchText);
            }
            qwbpOrder.weldingProcess = HelpFunctions.JoinTwoString(weldingProcess.weldingMethodASearchText, weldingProcess.weldingMethodBSearchText);
            let company: GetCompanyResponse = { ...appState.applicationTab.company };
            qwbpOrder.customer = company.name;
            qwbpOrder.phoneNumber = company.phone;
            qwbpOrder.contactPerson = company.contactPerson; 
            dispatch({ type: 'INIT_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setParentMaterialAutoFilling: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.selectedBaseMaterial2 = { ...parentMaterial.selectedBaseMaterial1 };
            parentMaterial.baseMaterial2SearchText = parentMaterial.baseMaterial1SearchText;
            parentMaterial.selectedMaterialGroup2 = { ...parentMaterial.selectedMaterialGroup1 };
            parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions = [...parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions];
            parentMaterial.selectedTechnicalDeliveryCondition2 = { ...parentMaterial.selectedTechnicalDeliveryCondition1 }
            parentMaterial.materialThickness2 = parentMaterial.materialThickness1;
            parentMaterial.diameter2 = parentMaterial.diameter1;
            parentMaterial.enableBaseMaterial2Text = parentMaterial.enableBaseMaterial1Text;
            parentMaterial.baseMaterial2Text = parentMaterial.baseMaterial1Text;
            parentMaterial.materialGroup2Text = parentMaterial.materialGroup1Text;
            parentMaterial.technicalDeliveryCondition2Text = parentMaterial.technicalDeliveryCondition1Text;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    removeWelder: (welderId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let selectedWelders: Array<GetWelderResponse> = [...appState.applicationTab.selectedWelders];
            for (let i = 0; i < selectedWelders.length; i++) {
                if (welderId === selectedWelders[i].id) {
                    selectedWelders.splice(i, 1);
                    break;
                }
            }
            dispatch({ type: 'SET_SELECTED_WELDERS', selectedWelders: selectedWelders });
        }
    },
    setAdditionalRequirementSelected: (additionalRequirementId: number, selected: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let additionalRequirements = [...appState.applicationTab.additionalRequirements];
            for (let i = 0; i < additionalRequirements.length; i++) {
                if (additionalRequirements[i].id === additionalRequirementId) {
                    additionalRequirements[i].selected = selected;
                    break;
                }
            }
            dispatch({ type: 'SET_ADDITIONAL_REQUIREMENT_SELECTED', additionalRequirements: additionalRequirements });
        }
    },
    setAdditionalRequirementInTestingOrderSelected: (additionalRequirementId: number, selected: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let additionalRequirements = [...appState.applicationTab.additionalRequirements];
            let additionalRequirement = new GetAdditionalRequirementResponse()
            for (let i = 0; i < additionalRequirements.length; i++) {
                if (additionalRequirements[i].id === additionalRequirementId) {
                    additionalRequirement = { ...additionalRequirements[i] }
                    break;
                }
            }

            let qwbpOrder: QWBPOrder = { ...appState.applicationTab.qwbpOrder };
            
            switch (additionalRequirement.name) {
                case AdditionalRequirementType.Asme:
                    qwbpOrder.conditionsAndDescriptionRemarks = additionalRequirement.name;
                    break;
                case AdditionalRequirementType.SS_EN_12952:
                    qwbpOrder.iso129526Testing = selected;
                    break;
                case AdditionalRequirementType.Norsok:
                    qwbpOrder.norsok = selected;
                    break;
                case AdditionalRequirementType.Ped:
                    qwbpOrder.pedAdditionalTesting = selected;
                    break;
                case AdditionalRequirementType.SS_EN_1090_2:
                    qwbpOrder.iso10902 = selected;
                    break;
                case AdditionalRequirementType.SS_EN_1090_3:
                    qwbpOrder.conditionsAndDescriptionRemarks = additionalRequirement.name;
                    break;
                default:
            }
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setAfterWeldingCoolingRate: (coolingRate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let afterWelding: AfterWelding = { ...appState.applicationTab.afterWelding };
            afterWelding.coolingRate = coolingRate;
            dispatch({ type: 'SET_AFTER_WELDING', afterWelding: afterWelding });
        }
    },
    setAfterWeldingHeatingRate: (heatingRate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let afterWelding: AfterWelding = { ...appState.applicationTab.afterWelding };
            afterWelding.heatingRate = heatingRate;
            dispatch({ type: 'SET_AFTER_WELDING', afterWelding: afterWelding });
        }
    },
    setCompanyCity: (city: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let company: GetCompanyResponse = { ...appState.applicationTab.company };
            company.city = city;
            dispatch({ type: 'SET_COMPANY', company: company });
        }
    },
    setCompanyContactPerson: (contactPerson: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let company: GetCompanyResponse = { ...appState.applicationTab.company };
            company.contactPerson = contactPerson;
            dispatch({ type: 'SET_COMPANY', company: company });
        }
    },
    setCompanyContactPersonEmail: (contactPersonEmail: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let company: GetCompanyResponse = { ...appState.applicationTab.company };
            company.contactPersonEmail = contactPersonEmail;
            dispatch({ type: 'SET_COMPANY', company: company });
        }
    },
    setCompanyName: (name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let company: GetCompanyResponse = { ...appState.applicationTab.company };
            company.name = name;
            dispatch({ type: 'SET_COMPANY', company: company });
        }
    },
    setCompanyPhone: (phone: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let company: GetCompanyResponse = { ...appState.applicationTab.company };
            company.phone = phone;
            dispatch({ type: 'SET_COMPANY', company: company });
        }
    },
    setCompanyPostcode: (postcode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let company: GetCompanyResponse = { ...appState.applicationTab.company };
            company.postcode = postcode;
            dispatch({ type: 'SET_COMPANY', company: company });
        }
    },
    setCompanyStreetAddress: (streetAddress: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let company: GetCompanyResponse = { ...appState.applicationTab.company };
            company.streetAddress = streetAddress;
            dispatch({ type: 'SET_COMPANY', company: company });
        }
    },
    setHeaderDate: (date: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.date = date;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setHeaderProject: (project: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.project = project;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setHeaderPWPSNumber: (pWPSNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.pwpsNumber = pWPSNumber;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setHeaderRevisionNumber: (revision: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.revisionNumber = revision;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setManufacturer: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.manufacturer = value;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setExaminer: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.examiner = value;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setConfirmedMessage: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.confirmedMessage = value;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setHeaderSelectedByStandard: (standardId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let standards: Array<GetStandardResponse> = [...appState.applicationTab.standards];
            let header: Header = { ...appState.applicationTab.header };
            let selectedStandard: GetStandardResponse = new GetStandardResponse();
            for (let i = 0; i < standards.length; i++) {
                if (standardId === standards[i].id) {
                    selectedStandard = { ...standards[i] };
                    break;
                }
            }
            header.selectedByStandard = { ...selectedStandard }
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setHeaderWPQRNumber: (wpqrNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.wpqrNumber = wpqrNumber;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setHeaderOtherOptionParametersTableId: (otherOptionParametersTableId: number, selected: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            let otherOptions: Array<GetOtherOptionResponse> = [...appState.applicationTab.otherOptions];
            if (!selected) {
                otherOptionParametersTableId = 0;
                // See if the Pulse, Arc or Gw is true 
                for (let i = 0; i < otherOptions.length; i++) {
                    if (otherOptions[i].id === OtherOptions.PulseWeldingParametersTable && otherOptions[i].selected) {
                        otherOptionParametersTableId = otherOptions[i].id;
                        break;
                    }
                    if (otherOptions[i].id === OtherOptions.ArcWeldingParametersTable && otherOptions[i].selected) {
                        otherOptionParametersTableId = otherOptions[i].id;
                        break;
                    }
                    if (otherOptions[i].id === OtherOptions.GwParametersTable && otherOptions[i].selected) {
                        otherOptionParametersTableId = otherOptions[i].id;
                        break;
                    }
                }
            }
            header.otherOptionParametersTableId = otherOptionParametersTableId;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setOtherInformationOtherInformation: (otherInformation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let otherInformation_: OtherInformation = { ...appState.applicationTab.otherInformation };
            otherInformation_.otherInformation = otherInformation;
            dispatch({ type: 'SET_OTHER_INFORMATION', otherInformation: otherInformation_ });
        }
    },
    setOtherOptionSelected: (otherOptionId: number, selected: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let otherOptions: Array<GetOtherOptionResponse> = [...appState.applicationTab.otherOptions];
            for (let i = 0; i < otherOptions.length; i++) {
                if (otherOptions[i].id === otherOptionId) {
                    otherOptions[i].selected = selected;
                    break;
                }
            }
            dispatch({ type: 'SET_OTHER_OPTION_SELECTED', otherOptions: otherOptions });
        }
    },
    setSelectedWeldingPosition2: (otherOptionId: number, selected: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.selectedWeldingPosition2 = selected;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setParentMaterialBaseMaterial1SearchText: (baseMaterial1SearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.baseMaterial1SearchText = baseMaterial1SearchText;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialBaseMaterial2SearchText: (baseMaterial2SearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.baseMaterial2SearchText = baseMaterial2SearchText;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialDiameter1: (diameter1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.diameter1 = diameter1;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialDiameter2: (diameter2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.diameter2 = diameter2;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialMaterialThickness1: (materialThickness1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.materialThickness1 = materialThickness1;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialBaseMaterial1Text: (baseMaterial1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.baseMaterial1Text = baseMaterial1Text;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialBaseMaterial2Text: (baseMaterial2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.baseMaterial2Text = baseMaterial2Text;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialMaterialGroup1Text: (materialGroup1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.materialGroup1Text = materialGroup1Text;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialMaterialGroup2Text: (materialGroup2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.materialGroup2Text = materialGroup2Text;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialTechnicalDeliveryCondition1Text: (technicalDeliveryCondition1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.technicalDeliveryCondition1Text = technicalDeliveryCondition1Text;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialTechnicalDeliveryCondition2Text: (technicalDeliveryCondition2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.technicalDeliveryCondition2Text = technicalDeliveryCondition2Text;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setEnableBaseMaterial1Text: (enableBaseMaterial1Text: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.enableBaseMaterial1Text = enableBaseMaterial1Text;
            parentMaterial.baseMaterial1Text = parentMaterial.baseMaterial1SearchText;
            parentMaterial.materialGroup1Text = parentMaterial.selectedMaterialGroup1.name;
            parentMaterial.technicalDeliveryCondition1Text = parentMaterial.selectedTechnicalDeliveryCondition1.name;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setEnableBaseMaterial2Text: (enableBaseMaterial2Text: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.enableBaseMaterial2Text = enableBaseMaterial2Text;
            parentMaterial.baseMaterial2Text = parentMaterial.baseMaterial2SearchText;
            parentMaterial.materialGroup2Text = parentMaterial.selectedMaterialGroup2.name;
            parentMaterial.technicalDeliveryCondition2Text = parentMaterial.selectedTechnicalDeliveryCondition2.name;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialMaterialThickness2: (materialThickness1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.materialThickness2 = materialThickness1;
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialSelectedBaseMaterial1: (baseMaterial: GetBaseMaterialResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.baseMaterial1SearchText = HelpFunctions.putNameAndNumberTogether(baseMaterial.name, baseMaterial.number);
            parentMaterial.selectedBaseMaterial1 = { ...baseMaterial };
            parentMaterial.selectedMaterialGroup1 = { ...baseMaterial.materialGroup };
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialSelectedBaseMaterial2: (baseMaterial: GetBaseMaterialResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            parentMaterial.baseMaterial2SearchText = HelpFunctions.putNameAndNumberTogether(baseMaterial.name, baseMaterial.number);
            parentMaterial.selectedBaseMaterial2 = { ...baseMaterial };
            parentMaterial.selectedMaterialGroup2 = { ...baseMaterial.materialGroup };
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialSelectedTechnicalDeliveryCondition1: (technicalDeliveryConditionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            for (let i = 0; i < parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions.length; i++) {
                if (technicalDeliveryConditionId === parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions[i].id) {
                    parentMaterial.selectedTechnicalDeliveryCondition1 = { ...parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    setParentMaterialSelectedTechnicalDeliveryCondition2: (technicalDeliveryConditionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
            for (let i = 0; i < parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions.length; i++) {
                if (technicalDeliveryConditionId === parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions[i].id) {
                    parentMaterial.selectedTechnicalDeliveryCondition2 = { ...parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });
        }
    },
    
    setSelectedNumberOfWeldingPositions: (numberOfWeldingPositionsId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let numberOfWeldingPositions = [...appState.applicationTab.numberOfWeldingPositions];
            let selectedNumberOfWeldingPositions: GetNumberOfWeldingPositionsResponse = { ...appState.applicationTab.selectedNumberOfWeldingPositions };
            for (let i = 0; i < numberOfWeldingPositions.length; i++) {
                if (numberOfWeldingPositions[i].id === numberOfWeldingPositionsId) {
                    selectedNumberOfWeldingPositions = { ...numberOfWeldingPositions[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_SELECTED_NUMBER_OF_WELDING_POSITIONS', selectedNumberOfWeldingPositions: selectedNumberOfWeldingPositions });
        }
    },
    setSelectedStandard: (standardId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let standards = [...appState.applicationTab.standards];
            let selectedStandard: GetStandardResponse = { ...appState.applicationTab.selectedStandard };
            for (let i = 0; i < standards.length; i++) {
                if (standards[i].id === standardId) {
                    selectedStandard = { ...standards[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_SELECTED_STANDARD', selectedStandard });

            let header: Header = { ...appState.applicationTab.header };
            header.selectedByStandard = selectedStandard
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    setSelectedStandardInTestingOrderSelected: (standardId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let standards = [...appState.applicationTab.standards];
            let selectedStandard: GetStandardResponse = { ...appState.applicationTab.selectedStandard };
            for (let i = 0; i < standards.length; i++) {
                if (standards[i].id === standardId) {
                    selectedStandard = { ...standards[i] };
                    break;
                }
            }
            let qwbpOrder: QWBPOrder = { ...appState.applicationTab.qwbpOrder };
            switch (selectedStandard.name) {
                case StandardType.ISO_15614_1_2004:
                    qwbpOrder.iso156141Edition2004 = true;
                    qwbpOrder.iso156141Edition2017 = false;
                    break;
                case StandardType.ISO_15614_1_2017:
                    qwbpOrder.iso156141Edition2004 = false;
                    qwbpOrder.iso156141Edition2017 = true;
                    break;
                default:
            }
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setSelectedTabId: (selectedTabId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SELECTED_TAB_ID', selectedTabId: selectedTabId });
    },
    setTemperatureMaxInterpassTemperature: (maxInterpassTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let temperature: Temperature = { ...appState.applicationTab.temperature };
            temperature.maxInterpassTemperature = maxInterpassTemperature;
            dispatch({ type: 'SET_TEMPERATURE', temperature: temperature });
        }
    },
    setTemperaturePreheatTemperature: (preheatTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let temperature: Temperature = { ...appState.applicationTab.temperature };
            temperature.preheatTemperature = preheatTemperature;
            dispatch({ type: 'SET_TEMPERATURE', temperature: temperature });
        }
    },
    setWelderSearchText: (welderSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_WELDER_SEARCH_TEXT', welderSearchText: welderSearchText });
    },
    setWeldingDataLengthTackWelds: (lengthTackWelds: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.lengthTackWelds = lengthTackWelds;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataPlasmaflowrate: (plasmaflowrate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.plasmaflowrate = plasmaflowrate;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataElectrodeTorchAngle111: (electrodeTorchAngle111: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.electrodeTorchAngle111 = electrodeTorchAngle111;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataMaxWidthOfRun: (maxWidthOfRun: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.maxWidthOfRun = maxWidthOfRun;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataNozzleDiameter: (nozzleDiameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.nozzleDiameter = nozzleDiameter;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setOnchangeJointPreparationText: (SelectJointPreparation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.selectedJointPreparationText = SelectJointPreparation
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    clearJointPreparationText: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.selectedJointPreparationText = ""
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setJointPreparationText: (SelectJointPreparation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.selectedJointPreparationText = weldingData.selectedJointPreparationText === "" ? SelectJointPreparation : weldingData.selectedJointPreparationText + ", " + SelectJointPreparation;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataRootProtectionGasSearchText: (rootProtectionGasSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.rootProtectionGasSearchText = rootProtectionGasSearchText;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedJointType: (jointTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            let selectedJointType: GetJointTypeResponse = new GetJointTypeResponse();
            for (var i = 0; i < weldingData.jointTypes.length; i++) {
                if (jointTypeId === weldingData.jointTypes[i].id) {
                    selectedJointType = { ...weldingData.jointTypes[i] };
                    break;
                }
            }
            weldingData.selectedJointType = { ...selectedJointType }
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedRootProtectionGas: (selectedRootProtectionGas: GetRootProtectionGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.rootProtectionGasSearchText = selectedRootProtectionGas.name;
            weldingData.selectedRootProtectionGas = { ...selectedRootProtectionGas };
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedPlasmaGas: (selectedPlasmasGas: GetRootProtectionGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.selectedPlasmaGas = { ...selectedPlasmasGas };
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedRootSupportDetails: (selectedRootSupportDetailId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            for (var i = 0; i < weldingData.rootSupportDetailsSearchResult.length; i++) {
                if (selectedRootSupportDetailId === weldingData.rootSupportDetailsSearchResult[i].id) {
                    weldingData.selectedRootSupportDetails = { ...weldingData.rootSupportDetailsSearchResult[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataSelectedTackWeld: (selectedTackWeldText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.tackWeldText = selectedTackWeldText;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataStickOut: (stickOut: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.stickOut = stickOut;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataTungstenElectrode: (tungstenElectrode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.tungstenElectrode = tungstenElectrode;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataElectrodeTorchAngle: (electrodeTorchAngle: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            weldingData.electrodeTorchAngle = electrodeTorchAngle;
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataWeldingPositionSearchText: (weldPositionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            for (var i = 0; i < weldingData.weldingPositionSearchResult.length; i++) {
                if (weldPositionId === weldingData.weldingPositionSearchResult[i].id) {
                    weldingData.selectedWeldingPosition = { ...weldingData.weldingPositionSearchResult[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingDataWeldingPosition2SearchText: (weldPositionId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
            let selectedWeldingPosition2: GetWeldingPositionResponse = new GetWeldingPositionResponse();
            for (var i = 0; i < weldingData.weldingPositionSearchResult.length; i++) {
                if (weldPositionId === weldingData.weldingPositionSearchResult[i].id) {
                    selectedWeldingPosition2 = { ...weldingData.weldingPositionSearchResult[i] };
                    break;
                }
            }
            weldingData.selectedWeldingPosition2 = { ...selectedWeldingPosition2 };
            dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });
        }
    },
    setWeldingProcessArcTypeASearchText: (arcTypeAId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            let selectedArcTypeA: GetArcTypeResponse = new GetArcTypeResponse();
            for (var i = 0; i < weldingProcess.arcTypeSearchResult.length; i++) {
                if (arcTypeAId === weldingProcess.arcTypeSearchResult[i].id) {
                    selectedArcTypeA = { ...weldingProcess.arcTypeSearchResult[i] };
                    break;
                }
            }
            weldingProcess.selectedArcTypeA = { ...selectedArcTypeA }
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessArcTypeBSearchText: (arcTypeBId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            let selectedArcTypeB: GetArcTypeResponse = new GetArcTypeResponse();
            for (var i = 0; i < weldingProcess.arcTypeSearchResult.length; i++) {
                if (arcTypeBId === weldingProcess.arcTypeSearchResult[i].id) {
                    selectedArcTypeB = { ...weldingProcess.arcTypeSearchResult[i] };
                    break;
                }
            }
            weldingProcess.selectedArcTypeB = { ...selectedArcTypeB };
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessMakeASearchText: (makeASearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.makeASearchText = makeASearchText;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessMakeBSearchText: (makeBSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.makeBSearchText = makeBSearchText;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedMakeA: (selectedMakeA: GetMakeResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.makeASearchText = selectedMakeA.name;
            weldingProcess.selectedMakeA = { ...selectedMakeA };
            weldingProcess.selectedDesignationA = { ...weldingProcess.selectedMakeA.designation };
            weldingProcess.selectedFMGroupA = { ...weldingProcess.selectedMakeA.fmGroup };
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedMakeB: (selectedMakeB: GetMakeResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.makeBSearchText = selectedMakeB.name;
            weldingProcess.selectedMakeB = { ...selectedMakeB };
            weldingProcess.selectedDesignationB = { ...weldingProcess.selectedMakeB.designation };
            weldingProcess.selectedFMGroupB = { ...weldingProcess.selectedMakeB.fmGroup };
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedShieldingGasA: (shieldingGas: GetShieldingGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.shieldingGasASearchText = shieldingGas.name;
            weldingProcess.selectedShieldingGasA = { ...shieldingGas };
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedShieldingGasB: (shieldingGas: GetShieldingGasResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.shieldingGasBSearchText = shieldingGas.name;
            weldingProcess.selectedShieldingGasB = { ...shieldingGas };
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedWeldingMethodA: (weldingMethod: GetWeldingMethodResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            // Remove the old selected WeldingMethod and add the new selected WeldingMethod in the Dropdown list.
            let removeIndex = weldingProcess.weldingMethods.findIndex(weldingMethod1 => weldingMethod1.id !== 0 && weldingMethod1.id === weldingProcess.selectedWeldingMethodA.id);
            if (removeIndex !== -1) {
                weldingProcess.weldingMethods.splice(removeIndex, 1);
            }
            weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, weldingMethod);
            weldingProcess.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
                if (weldingMethod1.name < weldingMethod2.name) {
                    return -1;
                }
                else if (weldingMethod1.name > weldingMethod2.name) {
                    return 1;
                }
                return 0;
            });
            weldingProcess.weldingMethodASearchText = weldingMethod.name;
            weldingProcess.selectedWeldingMethodA = { ...weldingMethod };
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessSelectedWeldingMethodB: (weldingMethod: GetWeldingMethodResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            //Remove the old selected WeldingMethod and add the new selected WeldingMethod in the Dropdown list.
            let removeIndex = weldingProcess.weldingMethods.findIndex(weldingMethod2 => weldingMethod2.id !== 0 && weldingMethod2.id === weldingProcess.selectedWeldingMethodB.id);
            if (removeIndex !== -1) {
                weldingProcess.weldingMethods.splice(removeIndex, 1);
            }
            weldingProcess.weldingMethods.splice(weldingProcess.weldingMethods.length, 0, weldingMethod);
            weldingProcess.weldingMethods.sort((weldingMethod1, weldingMethod2) => {
                if (weldingMethod1.name < weldingMethod2.name) {
                    return -1;
                }
                else if (weldingMethod1.name > weldingMethod2.name) {
                    return 1;
                }
                return 0;
            });
            weldingProcess.weldingMethodBSearchText = weldingMethod.name;
            weldingProcess.selectedWeldingMethodB = { ...weldingMethod };
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessShieldingGasASearchText: (shieldingGasASearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.shieldingGasASearchText = shieldingGasASearchText;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessShieldingGasBSearchText: (shieldingGasBSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.shieldingGasBSearchText = shieldingGasBSearchText;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessWeldingMethodASearchText: (weldingMethodASearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.weldingMethodASearchText = weldingMethodASearchText;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setWeldingProcessWeldingMethodBSearchText: (weldingMethodBSearchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.weldingMethodBSearchText = weldingMethodBSearchText;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    toggleShowJointDesignModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let showJointDesignModal = appState.applicationTab.showJointDesignModal;
            dispatch({ type: 'TOGGLE_JOINT_DESIGN_MODAL', showJointDesignModal: !showJointDesignModal });
        }
    },
    setEnableMakeAText: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.enableMakeAText = value;
            weldingProcess.makeAText = weldingProcess.makeASearchText;
            weldingProcess.designationAText = weldingProcess.selectedDesignationA.name;
            weldingProcess.fmGroupAText = weldingProcess.selectedFMGroupA.name;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setEnableMakeBText: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.enableMakeBText = value;
            weldingProcess.makeBText = weldingProcess.makeBSearchText;
            weldingProcess.designationBText = weldingProcess.selectedDesignationB.name;
            weldingProcess.fmGroupBText = weldingProcess.selectedFMGroupB.name;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setMakeAText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.makeAText = value;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setDesignationAText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.designationAText = value;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setfmGroupAText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.fmGroupAText = value;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setMakeBText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.makeBText = value;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setDesignationBText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.designationBText = value;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    setfmGroupBText: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
            weldingProcess.fmGroupBText = value;
            dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });
        }
    },
    updateApplication: (updateApplicationRequest: UpdateApplicationRequest, confirmed: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.drawJointDesign && appState.wParameters && appState.fileUpload && appState.weldSequence && appState.typeOfBacking) {
            // Get the TypeOfBackings values.
            updateApplicationRequest.updatePWPSRequest.updateWeldingProcessRequest.typeOfBackingText = appState.typeOfBacking.typeOfBackingData.typeOfBackingText;

            // Get the WeldingParameters values.
            let updateWeldingParametersRequests: Array<UpdateWeldingParametersRequest> = new Array<UpdateWeldingParametersRequest>();
            for (let i = 0; i < appState.wParameters.weldingParameters.length; i++) {
                let updateWeldingParametersRequest: UpdateWeldingParametersRequest = {
                    id: 0,
                    run: !isNaN(parseInt(appState.wParameters.weldingParameters[i].run)) ? parseInt(appState.wParameters.weldingParameters[i].run) : 0,
                    runText: appState.wParameters.weldingParameters[i].runText,
                    indexId: (appState.wParameters.weldingParameters[i].index.id !== 0) ? appState.wParameters.weldingParameters[i].index.id : undefined,
                    diameter: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].diameter.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].diameter.replace(',', '.')) : 0.0,
                    voltage: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltage.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltage.replace(',', '.')) : 0.0,
                    voltageMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageMin.replace(',', '.')) : 0.0,
                    voltageMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageMax.replace(',', '.')) : 0.0,
                    current: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].current.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].current.replace(',', '.')) : 0.0,
                    currentMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentMin.replace(',', '.')) : 0.0,
                    currentMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentMax.replace(',', '.')) : 0.0,
                    travelSpeed: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeed.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeed.replace(',', '.')) : 0.0,
                    travelSpeedMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMin.replace(',', '.')) : 0.0,
                    travelSpeedMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedMax.replace(',', '.')) : 0.0,
                    heatInput: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInput.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInput.replace(',', '.')) : 0.0,
                    heatInputMin: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInputMin.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInputMin.replace(',', '.')) : 0.0,
                    heatInputMax: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInputMax.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInputMax.replace(',', '.')) : 0.0,
                    flowRateTop: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].flowRateTop.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].flowRateTop.replace(',', '.')) : 0.0,
                    flowRateRoot: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].flowRateRoot.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].flowRateRoot.replace(',', '.')) : 0.0,
                    wireFeed: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].wireFeed.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].wireFeed.replace(',', '.')) : 0.0,
                    currentTypeId: (appState.wParameters.weldingParameters[i].currentType.id !== 0) ? appState.wParameters.weldingParameters[i].currentType.id : undefined,
                    time: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].time.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].time.replace(',', '.')) : 0.0,
                    runLength: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].runLength.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].runLength.replace(',', '.')) : 0.0,
                    pulseCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].pulseCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].pulseCurrent.replace(',', '.')) : 0.0,
                    pulseTime: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].pulseTime.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].pulseTime.replace(',', '.')) : 0.0,
                    basicCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].basicCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].basicCurrent.replace(',', '.')) : 0.0,
                    timeForBasicCurrent: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].timeForBasicCurrent.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].timeForBasicCurrent.replace(',', '.')) : 0.0,
                    frequency: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].frequency.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].frequency.replace(',', '.')) : 0.0,
                    current1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].current1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].current1.replace(',', '.')) : 0.0,
                    time1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].time1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].time1.replace(',', '.')) : 0.0,
                    heatInput1: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].heatInput1.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].heatInput1.replace(',', '.')) : 0.0,
                    weldingMethodId: (appState.wParameters.weldingParameters[i].weldingMethod.id !== 0) ? appState.wParameters.weldingParameters[i].weldingMethod.id : undefined,
                    arcEnergyIE: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIE.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIE.replace(',', '.')) : 0.0,
                    arcEnergyIP: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIP.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcEnergyIP.replace(',', '.')) : 0.0,
                    arcIP: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcIP.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcIP.replace(',', '.')) : 0.0,
                    arcIE: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].arcIE.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].arcIE.replace(',', '.')) : 0.0,
                    additiveMaterial: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].additiveMaterial.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].additiveMaterial.replace(',', '.')) : 0.0,
                    currentY: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].currentY.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].currentY.replace(',', '.')) : 0.0,
                    voltageX: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].voltageX.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].voltageX.replace(',', '.')) : 0.0,
                    travelSpeedZ: !isNaN(parseFloat(appState.wParameters.weldingParameters[i].travelSpeedZ.replace(',', '.'))) ? parseFloat(appState.wParameters.weldingParameters[i].travelSpeedZ.replace(',', '.')) : 0.0,
                    weldingTecknique: appState.wParameters.weldingParameters[i].weldingTecknique,
                    fuelGasPressure: appState.wParameters.weldingParameters[i].fuelGasPressure,
                    oxygenPressure: appState.wParameters.weldingParameters[i].oxygenPressure,
                    typeOfFlame: appState.wParameters.weldingParameters[i].typeOfFlame
                };
                updateWeldingParametersRequests.push(updateWeldingParametersRequest);
            }
            updateApplicationRequest.updatePWPSRequest.updateWeldingParametersRequests = updateWeldingParametersRequests;

            // Get the drawJointDesign values.
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.materialThickness1 = !isNaN(parseFloat(appState.drawJointDesign.materialThickness1.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.materialThickness1.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.materialThickness2 = !isNaN(parseFloat(appState.drawJointDesign.materialThickness2.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.materialThickness2.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.jointTypeId = (appState.drawJointDesign.selectedJointType.id !== 0) ? appState.drawJointDesign.selectedJointType.id : undefined;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.jointShapeId = (appState.drawJointDesign.selectedJointShape.id !== 0) ? appState.drawJointDesign.selectedJointShape.id : undefined;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.jointAngleAlpha = !isNaN(parseFloat(appState.drawJointDesign.jointAngleAlpha.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.jointAngleAlpha.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.gapOpening = !isNaN(parseFloat(appState.drawJointDesign.gapOpening.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.gapOpening.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.straightEdge = !isNaN(parseFloat(appState.drawJointDesign.straightEdge.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.straightEdge.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.phaseAngle1 = !isNaN(parseFloat(appState.drawJointDesign.phaseAngle1.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.phaseAngle1.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.phaseAngle2 = !isNaN(parseFloat(appState.drawJointDesign.phaseAngle2.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.phaseAngle2.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.jointAngleBeta = !isNaN(parseFloat(appState.drawJointDesign.jointAngleBeta.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.jointAngleBeta.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.throatThickness = !isNaN(parseFloat(appState.drawJointDesign.throatThickness.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.throatThickness.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.rootSupportId = (appState.drawJointDesign.selectedRootSupport.id !== 0) ? appState.drawJointDesign.selectedRootSupport.id : undefined;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.depthOfPreparation = !isNaN(parseFloat(appState.drawJointDesign.depthOfPreparation.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.depthOfPreparation.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.radius = !isNaN(parseFloat(appState.drawJointDesign.radius.toString().replace(',', '.'))) ? parseFloat(appState.drawJointDesign.radius.toString().replace(',', '.')) : 0.0;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.note = appState.drawJointDesign.note;
            updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest.jointDesignHtmlImage = appState.drawJointDesign.jointDesignHtmlImage;

            // Get the FilesUpload values.
            let updateFilesUploadRequest: Array<UpdateFilesUploadRequest> = new Array<UpdateFilesUploadRequest>();
            for (let i = 0; i < appState.fileUpload.filesUpload.length; i++) {
                let updateFileUploadRequest: UpdateFilesUploadRequest = {
                    fileName: appState.fileUpload.filesUpload[i].fileName,
                    fileBase64: appState.fileUpload.filesUpload[i].fileBase64,
                    filePath: appState.fileUpload.filesUpload[i].filePath,
                    fileDescription: '',
                    fileGuid: '',
                    selectedFile: appState.fileUpload.filesUpload[i].selectedFile,
                };
                updateFilesUploadRequest.push(updateFileUploadRequest);
            }
            updateApplicationRequest.updatePWPSRequest.updateFilesUploadRequest = updateFilesUploadRequest;

            console.log('updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest');
            console.log(updateApplicationRequest.updatePWPSRequest.updateJointDesignRequest);
            console.log('JSON.stringify(updateApplicationRequest)');
            console.log(JSON.stringify(updateApplicationRequest));

            // Save Files and WeldSequence
            let updateFilesRequest: UpdateFilesRequest = {
                applicationId: updateApplicationRequest.id,
                weldingProtocolId: 0,
                wpsId: 0,
                enableCreateWps: false,
                wpqrEnableEdit: false,
                jointDesignHtmlImage: "",
                updateWeldSequenceRequest: {
                    imageId: appState.weldSequence.sequences.imageId,
                    imageData: appState.weldSequence.sequences.imageData,
                    sequenceNumber1: appState.weldSequence.sequences.sequenceNumber1,
                    sequenceNumber2: appState.weldSequence.sequences.sequenceNumber2,
                    sequenceNumber3: appState.weldSequence.sequences.sequenceNumber3,
                    sequenceNumber4: appState.weldSequence.sequences.sequenceNumber4,
                    sequenceNumber5: appState.weldSequence.sequences.sequenceNumber5,
                    sequenceNumber6: appState.weldSequence.sequences.sequenceNumber6,
                    sequenceNumber7: appState.weldSequence.sequences.sequenceNumber7,
                    sequenceNumber8: appState.weldSequence.sequences.sequenceNumber8,
                    sequenceNumber9: appState.weldSequence.sequences.sequenceNumber9,
                    sequenceNumber10: appState.weldSequence.sequences.sequenceNumber10,
                    sequenceNumber11: appState.weldSequence.sequences.sequenceNumber11,
                    sequenceNumber12: appState.weldSequence.sequences.sequenceNumber12,
                    sequenceNumber13: appState.weldSequence.sequences.sequenceNumber13,
                    sequenceNumber14: appState.weldSequence.sequences.sequenceNumber14,
                    sequenceNumber15: appState.weldSequence.sequences.sequenceNumber15,
                },
                updateFilesUploadRequest: []
            };

            // Send Email
            let sendGridEmailSenderRequest: SendGridEmailSenderRequest = {
                id: 0,
                applicationId: updateApplicationRequest.id,
                currentEmail: '',
                customerId: 0,
                standardName: '',
                pwpsNumber: '',
                userId: localStorage.getItem('userId'),
            };
            
            // WeldSequenceUpdate
            fetch(`api/File/Update`, {
                    method: 'PUT',
                headers: authHeader.authHeader(),
                body: JSON.stringify(updateFilesRequest)
                })
                .then(response => response.json() as Promise<UpdateFilesResponse>)
                .then(data => {
                    console.info(data);
                })
                .catch(error => {
                    console.error(error);
                });

            // Applications Update
            fetch(`api/Applications/Update`, {
                method: 'PUT',
                headers: authHeader.authHeader(),
                body: JSON.stringify(updateApplicationRequest)
            })
            .then(response => response.json() as Promise<UpdateApplicationResponse>)
                .then(data => {
                    console.log('UPDATE_APPLICATION_RECEIVE');
                    console.info(data);
                    if (confirmed) {
                        // Update status and create a wps
                        let updateApplicatinStatusRequest: UpdateApplicationStatusRequest = {
                            id: data.id,
                            applicationStatus: ApplicationStatus.Confirmed
                        };
                            fetch(`api/Applications/UpdateApplicationStatus`, {
                                method: 'PUT',
                                headers: authHeader.authHeader(),
                                body: JSON.stringify(updateApplicatinStatusRequest)
                            })
                            .then(response => response.json() as Promise<UpdateApplicationStatusResponse>)
                            .then(data => {
                                console.info(data);
                                let saveApplicationId = data.id;
                                    // Send Email
                                    fetch(`api/SendGridEmails/SendEmailData`, {
                                        method: 'POST',
                                        headers: authHeader.authHeader(),
                                        body: JSON.stringify(sendGridEmailSenderRequest)
                                    })
                                    .then(response => response.json() as Promise<SendGridEmailSenderResponse>)
                                    .then(data => {
                                        console.info(data);
                                        dispatch({ type: 'UPDATE_APPLICATION_RECEIVE', applicationId: saveApplicationId });
                                        let header: Header = { ...appState.applicationTab.header };
                                        header.successStatusCode = data.successStatusCode;
                                        header.bodyTemplateHtml = data.bodyTemplateHtml;
                                            dispatch({ type: 'SET_HEADER', header: header });
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                            })
                            .catch(error => {
                                console.error(error);
                            });

                    } else {
                        dispatch({ type: 'UPDATE_APPLICATION_RECEIVE', applicationId: data.id });
                    }
            })
            .catch(error => {
                console.log(error);
                console.error(error);
            });
            dispatch({ type: 'UPDATE_APPLICATION_REQUEST' });
        }
    },
    setHeaderSuccessStatusCode: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let header: Header = { ...appState.applicationTab.header };
            header.successStatusCode = false;
            dispatch({ type: 'SET_HEADER', header: header });
        }
    },
    createFile: (createFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileValidationResult = HelpFunctions.fileValidation(createFile);
        if (fileValidationResult == "") {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    const appState = getState();
                    if (appState && appState.applicationTab) {
                        let header: Header = { ...appState.applicationTab.header };
                        header.imageValidationError2 = "";
                        header.imageUpload2 = reader.result.toString();
                        dispatch({ type: 'SET_HEADER', header: header });
                    }
                }
            };
            reader.readAsDataURL(createFile);
        }
        else {
            const appState = getState();
            if (appState && appState.applicationTab) {
                let header: Header = { ...appState.applicationTab.header };
                header.imageValidationError2 = fileValidationResult;
                dispatch({ type: 'SET_HEADER', header: header });
            }
        }
    },
    createImageDrawJointDesign: (createFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileValidationResult = HelpFunctions.fileValidation(createFile);
        if (fileValidationResult == "") {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    const appState = getState();
                    if (appState && appState.applicationTab) {
                        let header: Header = { ...appState.applicationTab.header };
                        header.imageValidationError1 = "";
                        header.imageUpload1 = reader.result.toString();;
                        dispatch({ type: 'SET_HEADER', header: header });
                    }
                }
            };
            reader.readAsDataURL(createFile);
        }
        else {
            const appState = getState();
            if (appState && appState.applicationTab) {
                let header: Header = { ...appState.applicationTab.header };
                header.imageValidationError1 = fileValidationResult;
                dispatch({ type: 'SET_HEADER', header: header });
            }
        }
    },
    initSelectedPwps: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Wpss/GetByIdInclude/${id}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    };
                    let data = res.data as GetApplicationResponse;
                    console.info(data);
                    let selectedApplication: GetApplicationResponse = { ...data };
                    const appState = getState();
                    if (appState && appState.wpsFormEdit) {

                        let header: Header = { ...appState.applicationTab.header };
                        let selectedApplicationPwps = { ...selectedApplication.pwps };
                        header = HelpFunctions.SetHeaderApplication(header, selectedApplicationPwps);
                        dispatch({ type: 'SET_HEADER', header: header });
                        // WeldingData
                        let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
                        weldingData = HelpFunctions.SetWeldingDataApplication(weldingData, selectedApplication);
                        dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });

                        // WeldingProcess
                        let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
                        weldingProcess = HelpFunctions.SetWeldingProcessApplication(weldingProcess, selectedApplication)
                        dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });

                        let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
                        parentMaterial = HelpFunctions.SetParentMaterialApplication(parentMaterial, selectedApplication)
                        dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });

                        //weldingParameters
                        let weldingParametersList = HelpFunctions.SetWeldingParametersApplication(selectedApplication);
                        dispatch({ type: 'GET_WELDING_PARAMETERS', weldingParameters: weldingParametersList });

                        //JointDesign
                        let jointDesign = { ...selectedApplication.pwps.jointDesign }
                        dispatch({ type: 'GET_JOINT_DESIGN', jointDesign: jointDesign });

                        let weldSequence = { ...selectedApplication.pwps.weldSequence }
                        dispatch({ type: 'GET_WELD_SEQUENCE', weldSequence: weldSequence });

                        let temperature: Temperature = { ...appState.applicationTab.temperature };
                        temperature.maxInterpassTemperature = selectedApplication.pwps.temperature.maxInterpassTemperature.zeroToStringEmpty();
                        temperature.preheatTemperature = selectedApplication.pwps.temperature.preheatTemperature.zeroToStringEmpty();
                        dispatch({ type: 'SET_TEMPERATURE', temperature: temperature });

                        let afterWelding: AfterWelding = { ...appState.applicationTab.afterWelding };
                        afterWelding.coolingRate = selectedApplication.pwps.afterWelding.coolingRate;
                        afterWelding.heatingRate = selectedApplication.pwps.afterWelding.heatingRate
                        dispatch({ type: 'SET_AFTER_WELDING', afterWelding: afterWelding });

                        let otherInformation: OtherInformation = { ...appState.applicationTab.otherInformation };
                        otherInformation.otherInformation = selectedApplication.pwps.otherInformation;
                        dispatch({ type: 'SET_OTHER_INFORMATION', otherInformation: otherInformation });
                    }
                }))
            .catch(error => {
                console.error(error);
            });
        /*dispatch({ type: 'WPSFORMEDIT_GET_WELDING_METHOD_A_SEARCH_RESULT_REQUEST' });*/
    },
    getApplication: (applicationId: number, wpqrId: number, actionType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Applications/GetByIdInclude/${applicationId}/${wpqrId}/${actionType}`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        })
            .then(response => response.json() as Promise<GetApplicationResponse>)
            .then(data => {
                let selectedApplication: GetApplicationResponse = { ...data };
                const appState = getState();
                if (appState && appState.wpqrDekraTab && appState.login) {

                    dispatch({ type: 'CREATE_APPLICATION_RECEIVE', applicationId: selectedApplication.id });

                    // Company.
                    let company: GetCompanyResponse = { ...appState.applicationTab.company };
                    company = { ...selectedApplication.company2 };
                    dispatch({ type: 'SET_COMPANY', company: company });

                    let selectedStandard: GetStandardResponse = { ...appState.applicationTab.selectedStandard };
                    selectedStandard = { ...selectedApplication.standard };
                    dispatch({ type: 'SET_SELECTED_STANDARD', selectedStandard });

                    let additionalRequirements = [...appState.applicationTab.additionalRequirements];
                    let selectedAdditionalRequirements = [...selectedApplication.additionalRequirements]
                    for (let i = 0; i < additionalRequirements.length; i++) {
                        for (let j = 0; j < selectedAdditionalRequirements.length; j++) {
                            if (additionalRequirements[i].id === selectedAdditionalRequirements[j].id) {
                                additionalRequirements[i].selected = true;
                                break;
                            }
                        }
                    }
                    dispatch({ type: 'SET_ADDITIONAL_REQUIREMENT_SELECTED', additionalRequirements: additionalRequirements });

                    let otherOptions = [...appState.applicationTab.otherOptions];
                    let selectedOtherOptions = [...selectedApplication.otherOptions];
                    for (let i = 0; i < otherOptions.length; i++) {
                        for (let j = 0; j < selectedOtherOptions.length; j++) {
                            if (otherOptions[i].id === selectedOtherOptions[j].id) {
                                otherOptions[i].selected = true;
                                break;
                            }
                        }
                    }
                    dispatch({ type: 'SET_OTHER_OPTION_SELECTED', otherOptions: otherOptions });
                    
                    let selectedNumberOfWeldingPositions = Object.assign({}, appState.applicationTab.selectedNumberOfWeldingPositions);
                    let numberOfWeldingPosition = Object.assign({}, selectedApplication.numberOfWeldingPositions);
                    selectedNumberOfWeldingPositions = numberOfWeldingPosition
                    dispatch({ type: 'SET_SELECTED_NUMBER_OF_WELDING_POSITIONS', selectedNumberOfWeldingPositions: selectedNumberOfWeldingPositions });

                    let header: Header = { ...appState.applicationTab.header };
                    let selectedApplicationPwps: GetPWPSResponse = { ...selectedApplication.pwps };
                    header = HelpFunctions.SetHeaderApplication(header, selectedApplicationPwps);
                    header.otherOptionParametersTableId = selectedApplicationPwps.otherOptionParametersTableId;
                    dispatch({ type: 'SET_HEADER', header: header });

                    // WeldingData
                    let weldingData: WeldingData = { ...appState.applicationTab.weldingData };
                    weldingData = HelpFunctions.SetWeldingDataApplication(weldingData, selectedApplication);
                    dispatch({ type: 'SET_WELDING_DATA', weldingData: weldingData });

                    // WeldingProcess
                    let weldingProcess: WeldingProcess = { ...appState.applicationTab.weldingProcess };
                    weldingProcess = HelpFunctions.SetWeldingProcessApplication(weldingProcess, selectedApplication)
                    dispatch({ type: 'SET_WELDING_PROCESS', weldingProcess: weldingProcess });

                    let parentMaterial: ParentMaterial = { ...appState.applicationTab.parentMaterial };
                    parentMaterial = HelpFunctions.SetParentMaterialApplication(parentMaterial, selectedApplication)
                    dispatch({ type: 'SET_PARENT_MATERIAL', parentMaterial: parentMaterial });

                    //weldingParameters
                    let weldingParametersList = HelpFunctions.SetWeldingParametersApplication(selectedApplication);
                    dispatch({ type: 'GET_WELDING_PARAMETERS', weldingParameters: weldingParametersList });

                    //JointDesign
                    let jointDesign = { ...selectedApplication.pwps.jointDesign }
                    dispatch({ type: 'GET_JOINT_DESIGN', jointDesign: jointDesign });

                    let weldSequence = { ...selectedApplication.pwps.weldSequence }
                    dispatch({ type: 'GET_WELD_SEQUENCE', weldSequence: weldSequence });
                    
                    let temperature: Temperature = { ...appState.applicationTab.temperature };
                    temperature.maxInterpassTemperature = selectedApplication.pwps.temperature.maxInterpassTemperature.zeroToStringEmpty();
                    temperature.preheatTemperature = selectedApplication.pwps.temperature.preheatTemperature.zeroToStringEmpty();
                    dispatch({ type: 'SET_TEMPERATURE', temperature: temperature });
                    
                    let afterWelding: AfterWelding = { ...appState.applicationTab.afterWelding };
                    afterWelding.coolingRate = selectedApplication.pwps.afterWelding.coolingRate;
                    afterWelding.heatingRate = selectedApplication.pwps.afterWelding.heatingRate
                    dispatch({ type: 'SET_AFTER_WELDING', afterWelding: afterWelding });

                    let otherInformation: OtherInformation = { ...appState.applicationTab.otherInformation };
                    otherInformation.otherInformation = selectedApplication.pwps.otherInformation;
                    dispatch({ type: 'SET_OTHER_INFORMATION', otherInformation: otherInformation });

                    // Initialize TestingOrder.
                    /////////////////////////////////////////////////////////////////////////////////////
                    let qwbpOrder: QWBPOrder = { ...appState.applicationTab.qwbpOrder };
                    qwbpOrder = {
                        additionalTesting: selectedApplication.testingOrderResponse.additionalTesting,
                        bendTesting: selectedApplication.testingOrderResponse.bendTesting,
                        chargeNumber1: selectedApplication.testingOrderResponse.chargeNumber1,
                        chargeNumber2: selectedApplication.testingOrderResponse.chargeNumber2,
                        conditionsAndDescriptionRemarks: selectedApplication.testingOrderResponse.conditionsAndDescriptionRemarks,
                        contactPerson: selectedApplication.testingOrderResponse.contactPerson,
                        customer: selectedApplication.testingOrderResponse.customer,
                        date: selectedApplication.testingOrderResponse.date,
                        destructiveTestingApproved: selectedApplication.testingOrderResponse.destructiveTestingApproved,
                        destructiveTestingDate: selectedApplication.testingOrderResponse.destructiveTestingDate,
                        destructiveTestingSignature: selectedApplication.testingOrderResponse.destructiveTestingSignature,
                        dimension1: selectedApplication.testingOrderResponse.dimension1,
                        dimension2: selectedApplication.testingOrderResponse.dimension2,
                        directionOfPlateRolling1: selectedApplication.testingOrderResponse.directionOfPlateRolling1,
                        directionOfPlateRolling2: selectedApplication.testingOrderResponse.directionOfPlateRolling2,
                        fillerMaterial: selectedApplication.testingOrderResponse.fillerMaterial,
                        hardnessTesting: selectedApplication.testingOrderResponse.hardnessTesting,
                        impactTesting: selectedApplication.testingOrderResponse.impactTesting,
                        impactTestTemperature1: selectedApplication.testingOrderResponse.impactTestTemperature1,
                        impactTestTemperature2: selectedApplication.testingOrderResponse.impactTestTemperature2,
                        iso10902: selectedApplication.testingOrderResponse.iso10902,
                        iso10902AdditionalTesting: selectedApplication.testingOrderResponse.iso10902AdditionalTesting,
                        iso10902CruciformTensileTesting: selectedApplication.testingOrderResponse.iso10902CruciformTensileTesting,
                        iso10902ImpactTesting: selectedApplication.testingOrderResponse.iso10902ImpactTesting,
                        iso10902MacroPlusPhoto: selectedApplication.testingOrderResponse.iso10902MacroPlusPhoto,
                        iso10902MicroTesting: selectedApplication.testingOrderResponse.iso10902MicroTesting,
                        iso10902WeldabilityPrimer: selectedApplication.testingOrderResponse.iso10902WeldabilityPrimer,
                        iso10902AdditionalTestingText: selectedApplication.testingOrderResponse.iso10902AdditionalTestingText,
                        iso10902CruciformTensileTestingText: selectedApplication.testingOrderResponse.iso10902CruciformTensileTestingText,
                        iso10902ImpactTestingText: selectedApplication.testingOrderResponse.iso10902ImpactTestingText,
                        iso10902MacroPlusPhotoText: selectedApplication.testingOrderResponse.iso10902MacroPlusPhotoText,
                        iso10902MicroTestingText: selectedApplication.testingOrderResponse.iso10902MicroTestingText,
                        iso10902WeldabilityPrimerText: selectedApplication.testingOrderResponse.iso10902WeldabilityPrimerText,
                        iso129526AdditionalTesting: selectedApplication.testingOrderResponse.iso129526AdditionalTesting,
                        iso129526ImpactTesting: selectedApplication.testingOrderResponse.iso129526ImpactTesting,
                        iso129526LongitudinalTensileTesting: selectedApplication.testingOrderResponse.iso129526LongitudinalTensileTesting,
                        iso129526AdditionalTestingText: selectedApplication.testingOrderResponse.iso129526AdditionalTestingText,
                        iso129526ImpactTestingText: selectedApplication.testingOrderResponse.iso129526ImpactTestingText,
                        iso129526LongitudinalTensileTestingText: selectedApplication.testingOrderResponse.iso129526LongitudinalTensileTestingText,
                        iso129526Testing: selectedApplication.testingOrderResponse.iso129526Testing,
                        iso13134AdditionalTesting: selectedApplication.testingOrderResponse.iso13134AdditionalTesting,
                        iso13134BendTesting: selectedApplication.testingOrderResponse.iso13134BendTesting,
                        iso13134HardnessTesting: selectedApplication.testingOrderResponse.iso13134HardnessTesting,
                        iso13134PeelTesting: selectedApplication.testingOrderResponse.iso13134PeelTesting,
                        iso13134ShearTesting: selectedApplication.testingOrderResponse.iso13134ShearTesting,
                        iso13134TensileTesting: selectedApplication.testingOrderResponse.iso13134TensileTesting,
                        iso13134BrazinProcedureApproval: selectedApplication.testingOrderResponse.iso13134BrazinProcedureApproval,
                        iso13134AdditionalTestingText: selectedApplication.testingOrderResponse.iso13134AdditionalTestingText,
                        iso13134BendTestingText: selectedApplication.testingOrderResponse.iso13134BendTestingText,
                        iso13134HardnessTestingText: selectedApplication.testingOrderResponse.iso13134HardnessTestingText,
                        iso13134PeelTestingText: selectedApplication.testingOrderResponse.iso13134PeelTestingText,
                        iso13134ShearTestingText: selectedApplication.testingOrderResponse.iso13134ShearTestingText,
                        iso13134TensileTestingText: selectedApplication.testingOrderResponse.iso13134TensileTestingText,
                        iso134454Testing: selectedApplication.testingOrderResponse.iso134454Testing,
                        iso134454AdditionalTesting: selectedApplication.testingOrderResponse.iso134454AdditionalTesting,
                        iso134454FerriteTesting: selectedApplication.testingOrderResponse.iso134454FerriteTesting,
                        iso134454ImpactTesting: selectedApplication.testingOrderResponse.iso134454ImpactTesting,
                        iso134454LongitudinalTensileTesting: selectedApplication.testingOrderResponse.iso134454LongitudinalTensileTesting,
                        iso134454MicroTesting: selectedApplication.testingOrderResponse.iso134454MicroTesting,
                        iso134454AdditionalTestingText: selectedApplication.testingOrderResponse.iso134454AdditionalTestingText,
                        iso134454FerriteTestingText: selectedApplication.testingOrderResponse.iso134454FerriteTestingText,
                        iso134454ImpactTestingText: selectedApplication.testingOrderResponse.iso134454ImpactTestingText,
                        iso134454LongitudinalTensileTestingText: selectedApplication.testingOrderResponse.iso134454LongitudinalTensileTestingText,
                        iso134454MicroTestingText: selectedApplication.testingOrderResponse.iso134454MicroTestingText,
                        iso14555AdditionalTesting: selectedApplication.testingOrderResponse.iso14555AdditionalTesting,
                        iso14555ApplicationGreaterThan100Degrees: selectedApplication.testingOrderResponse.iso14555ApplicationGreaterThan100Degrees,
                        iso14555ApplicationLessThanOrEqualTo100Degrees: selectedApplication.testingOrderResponse.iso14555ApplicationLessThanOrEqualTo100Degrees,
                        iso14555BendTesting: selectedApplication.testingOrderResponse.iso14555BendTesting,
                        iso14555ArcStudWelding: selectedApplication.testingOrderResponse.iso14555ArcStudWelding,
                        iso14555_3834Testing: selectedApplication.testingOrderResponse.iso14555_3834Testing,
                        iso14555_783Testing: selectedApplication.testingOrderResponse.iso14555_783Testing,
                        iso14555_784Testing: selectedApplication.testingOrderResponse.iso14555_784Testing,
                        iso14555_785Testing: selectedApplication.testingOrderResponse.iso14555_785Testing,
                        iso14555_786Testing: selectedApplication.testingOrderResponse.iso14555_786Testing,
                        iso14555_787Testing: selectedApplication.testingOrderResponse.iso14555_787Testing,
                        iso14555BendTestingTorqueWrench: selectedApplication.testingOrderResponse.iso14555BendTestingTorqueWrench,
                        iso14555MacroPlusPhoto: selectedApplication.testingOrderResponse.iso14555MacroPlusPhoto,
                        iso14555TensileTesting: selectedApplication.testingOrderResponse.iso14555TensileTesting,
                        iso14555TorqueTesting: selectedApplication.testingOrderResponse.iso14555TorqueTesting,
                        iso14555RTProvning: selectedApplication.testingOrderResponse.iso14555RTProvning,
                        iso14555AdditionalTestingText: selectedApplication.testingOrderResponse.iso14555AdditionalTestingText,
                        iso14555ApplicationGreaterThan100DegreesText: selectedApplication.testingOrderResponse.iso14555ApplicationGreaterThan100DegreesText,
                        iso14555ApplicationLessThanOrEqualTo100DegreesText: selectedApplication.testingOrderResponse.iso14555ApplicationLessThanOrEqualTo100DegreesText,
                        iso14555BendTestingText: selectedApplication.testingOrderResponse.iso14555BendTestingText,
                        iso14555BendTestingTorqueWrenchText: selectedApplication.testingOrderResponse.iso14555BendTestingTorqueWrenchText,
                        iso14555MacroPlusPhotoText: selectedApplication.testingOrderResponse.iso14555MacroPlusPhotoText,
                        iso14555TensileTestingText: selectedApplication.testingOrderResponse.iso14555TensileTestingText,
                        iso14555TorqueTestingText: selectedApplication.testingOrderResponse.iso14555TorqueTestingText,
                        iso14555RTProvningText: selectedApplication.testingOrderResponse.iso14555RTProvningText,
                        iso14555_3834TestingText: selectedApplication.testingOrderResponse.iso14555_3834TestingText,
                        iso156141Edition2004: selectedApplication.testingOrderResponse.iso156141Edition2004,
                        iso156141Edition2017: selectedApplication.testingOrderResponse.iso156141Edition2017,
                        macroPlusPhoto: selectedApplication.testingOrderResponse.macroPlusPhoto,
                        manufacturerWPQRBPARNumber: selectedApplication.testingOrderResponse.manufacturerWPQRBPARNumber,
                        material1: selectedApplication.testingOrderResponse.material1,
                        material2: selectedApplication.testingOrderResponse.material2,
                        materialGroup1: selectedApplication.testingOrderResponse.materialGroup1,
                        materialGroup2: selectedApplication.testingOrderResponse.materialGroup2,
                        materialSpecifications1Remarks: selectedApplication.testingOrderResponse.materialSpecifications1Remarks,
                        materialSpecifications2Remarks: selectedApplication.testingOrderResponse.materialSpecifications2Remarks,
                        mtPT: selectedApplication.testingOrderResponse.mtPT,
                        ndtApproved: selectedApplication.testingOrderResponse.ndtApproved,
                        ndtDate: selectedApplication.testingOrderResponse.ndtDate,
                        ndtSignature: selectedApplication.testingOrderResponse.ndtSignature,
                        ndtOther: selectedApplication.testingOrderResponse.ndtOther,
                        norsokAdditionalTesting: selectedApplication.testingOrderResponse.norsokAdditionalTesting,
                        norsokAdditionalTesting1: selectedApplication.testingOrderResponse.norsokAdditionalTesting1,
                        norsokAdditionalTesting2: selectedApplication.testingOrderResponse.norsokAdditionalTesting2,
                        norsokAdditionalTesting3: selectedApplication.testingOrderResponse.norsokAdditionalTesting3,
                        norsokAdditionalTesting4: selectedApplication.testingOrderResponse.norsokAdditionalTesting4,
                        norsokAdditionalTestingText: selectedApplication.testingOrderResponse.norsokAdditionalTestingText,
                        norsokAdditionalTesting1Text: selectedApplication.testingOrderResponse.norsokAdditionalTesting1Text,
                        norsokAdditionalTesting2Text: selectedApplication.testingOrderResponse.norsokAdditionalTesting2Text,
                        norsokAdditionalTesting3Text: selectedApplication.testingOrderResponse.norsokAdditionalTesting3Text,
                        norsokAdditionalTesting4Text: selectedApplication.testingOrderResponse.norsokAdditionalTesting4Text,
                        norsokCorrosionTestingText: selectedApplication.testingOrderResponse.norsokCorrosionTestingText,
                        norsokCorrosionTesting: selectedApplication.testingOrderResponse.norsokCorrosionTesting,
                        norsok: selectedApplication.testingOrderResponse.norsok,
                        phoneNumber: selectedApplication.testingOrderResponse.phoneNumber,
                        pWPSPBPS: selectedApplication.testingOrderResponse.pWPSPBPS,
                        orderedByLevel3: selectedApplication.testingOrderResponse.orderedByLevel3,
                        other: selectedApplication.testingOrderResponse.other,
                        ndtRemarks: selectedApplication.testingOrderResponse.ndtRemarks,
                        rt: selectedApplication.testingOrderResponse.rt,
                        signature: selectedApplication.testingOrderResponse.signature,
                        standardForAcceptance1: selectedApplication.testingOrderResponse.standardForAcceptance1,
                        standardForAcceptance2: selectedApplication.testingOrderResponse.standardForAcceptance2,
                        transverseTensilePlusRp0ReH: selectedApplication.testingOrderResponse.transverseTensilePlusRp0ReH,
                        ut: selectedApplication.testingOrderResponse.ut,
                        visual: selectedApplication.testingOrderResponse.visual,
                        weldingDate: selectedApplication.testingOrderResponse.weldingDate,
                        weldingBrazingPositions: selectedApplication.testingOrderResponse.weldingBrazingPositions,
                        weldingProcess: selectedApplication.testingOrderResponse.weldingProcess,
                        welderBrazerFirstName: selectedApplication.testingOrderResponse.welderBrazerFirstName,
                        welderBrazerLastName: selectedApplication.testingOrderResponse.welderBrazerLastName,
                        iso15614transverseTensilePlusRp0ReHText: selectedApplication.testingOrderResponse.iso15614transverseTensilePlusRp0ReHText,
                        iso15614macroPlusPhotoText: selectedApplication.testingOrderResponse.iso15614macroPlusPhotoText,
                        iso15614hardnessTestingText: selectedApplication.testingOrderResponse.iso15614hardnessTestingText,
                        iso15614impactTestingText: selectedApplication.testingOrderResponse.iso15614impactTestingText,
                        iso15614bendTestingText: selectedApplication.testingOrderResponse.iso15614bendTestingText,
                        iso15614additionalTestingText: selectedApplication.testingOrderResponse.iso15614additionalTestingText,
                        pedAdditionalTesting: selectedApplication.testingOrderResponse.pedAdditionalTesting,
                        iso15614TestingText: selectedApplication.testingOrderResponse.iso15614TestingText,
                        dekraProcedureNoTesting: selectedApplication.testingOrderResponse.dekraProcedureNoTesting,
                        invoiceReferenceName: selectedApplication.testingOrderResponse.invoiceReferenceName,
                        uppdragsnr: selectedApplication.testingOrderResponse.uppdragsnr,
                        testmarking: selectedApplication.testingOrderResponse.testmarking,
                        nace: selectedApplication.testingOrderResponse.nace,
                        naceMr0103Testing: selectedApplication.testingOrderResponse.naceMr0103Testing,
                        naceMr0103TestingText: selectedApplication.testingOrderResponse.naceMr0103TestingText,
                        naceMr0175Testing: selectedApplication.testingOrderResponse.naceMr0175Testing,
                        naceMr0175TestingText: selectedApplication.testingOrderResponse.naceMr0175TestingText,
                        naceAdditionalTesting1: selectedApplication.testingOrderResponse.naceAdditionalTesting1,
                        naceAdditionalTesting1Text: selectedApplication.testingOrderResponse.naceAdditionalTesting1Text,
                        naceAdditionalTesting2: selectedApplication.testingOrderResponse.naceAdditionalTesting2,
                        naceAdditionalTesting2Text: selectedApplication.testingOrderResponse.naceAdditionalTesting2Text,
                    }
                    dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });

                    // Initialize FilesUpload.
                    /////////////////////////////////////////////////////////////////////////////////////
                    let filesUpload: Array<GetFilesUploadResponse> = { ...appState.applicationTab.filesUpload };
                    filesUpload = [...selectedApplication.pwps.filesUpload];
                    dispatch({ type: 'SET_APPLICATION_FILES_UPLOAD', filesUpload: filesUpload });
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ApplicationTabStoreState = {
    currentCustomerId: 0,
    selectedTabId: '',
    applicationId: 0,
    updatingApplication: false,
    welderSearchResult: [],
    welderSearchText: '',
    selectedWelders: [],
    company: {
        id: 0,
        name: '',
        streetAddress: '',
        boxAddress: '',
        postcode: '',
        city: '',
        contactPerson: '',
        contactPersonEmail: '',
        phone: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    standards: [],
    selectedNumberOfWeldingPositions: {
        id: 0,
        name: '',
        description: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    selectedStandard: {
        id: 0,
        name: '',
        description: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    additionalRequirements: [],
    otherOptions: [],
    numberOfWeldingPositions: [],
    header: {
        date: '',
        project: '',
        selectedByStandard: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        revisionNumber: '',
        pwpsNumber: '',
        wpqrNumber: '',
        wpsType: '',
        wpsDekraNumber: '',
        wpsArcEnergyText: '',
        wpsHeatInputText: '',
        revisionWpsAction: '',
        selectedWpsId: 0,
        showChooseWpsTypeModal: false,
        otherOptions: [],
        otherOptionParametersTableId: 0,
        manufacturer: '',
        examiner: '',
        confirmedMessage: '',
        successStatusCode: false,
        dekraContactEmail: '',
        bodyTemplateHtml: '',
        imageUpload1: '',
        imageUpload2: '',
        imageValidationError1: '',
        imageValidationError2: '',
        selectedWeldingPosition2: false,
        customerLogo: '',
        employeeDisplayName: ''
    },
    baseMaterials: [],
    parentMaterial: {
        baseMaterial1SearchText: '',
        baseMaterial1SearchResult: [],
        selectedBaseMaterial1: {
            id: 0,
            name: '',
            number: '',
            description: '',
            materialGroup: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            technicalDeliveryConditions: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        baseMaterial2SearchText: '',
        baseMaterial2SearchResult: [],
        selectedBaseMaterial2: {
            id: 0,
            name: '',
            number: '',
            description: '',
            materialGroup: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            technicalDeliveryConditions: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedMaterialGroup1: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedMaterialGroup2: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedTechnicalDeliveryCondition1: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedTechnicalDeliveryCondition2: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        diameter1: '',
        diameter2: '',
        materialThickness1: '',
        materialThickness2: '',
        enableBaseMaterial1Text: false,
        enableBaseMaterial2Text: false,
        baseMaterial1Text: '',
        baseMaterial2Text: '',
        materialGroup1Text: '',
        materialGroup2Text: '',
        technicalDeliveryCondition1Text: '',
        technicalDeliveryCondition2Text: '',
        enableMaterialThickness: false,
        materialThickness1Text: '',
        materialThickness2Text: '',
        enableDiameter: false,
        diameter1Text: '',
        diameter2Text: '',
    },
    weldingData: {
        jointTypes: [],
        selectedJointType: {
            id: 0,
            name: '',
            description: '',
            jointShapes: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        maxWidthOfRun: '',
        rootSupportDetailsSearchResult: [],
        selectedRootSupportDetails: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        weldingPositionSearchResult: [],
        selectedWeldingPosition: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedWeldingPosition2: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        lengthTackWelds: '',
        tungstenElectrode: '',
        electrodeTorchAngle: '',
        nozzleDiameter: '',
        rootProtectionGasSearchText: '',
        rootProtectionGasSearchResult: [],
        selectedRootProtectionGas: {
            id: 0,
            name: '',
            content: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        stickOut: '',
        jointPreparations: [],
        selectedJointPreparationText: '',
        tackWeldText: '',
        electrodeTorchAngle111: '',
        selectedPlasmaGas: {
            id: 0,
            name: '',
            content: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        plasmaflowrate: '',
        enableJointTypeText: false,
        jointTypeText: '',
    },
    makes: [],
    weldingProcess: {
        weldingMethods: [],
        weldingMethodASearchText: '',
        weldingMethodASearchResult: [],
        selectedWeldingMethodA: {
            id: 0,
            name: '',
            efficiencyFactor: 0.0,
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        makeASearchText: '',
        makeASearchResult: [],
        selectedMakeA: {
            id: 0,
            name: '',
            description: '',
            designation: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            fmGroup: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedDesignationA: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedFMGroupA: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        shieldingGasASearchText: '',
        shieldingGasASearchResult: [],
        selectedShieldingGasA: {
            id: 0,
            name: '',
            content: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        arcTypeSearchResult: [],
        selectedArcTypeA: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        weldingMethodBSearchText: '',
        weldingMethodBSearchResult: [],
        selectedWeldingMethodB: {
            id: 0,
            name: '',
            efficiencyFactor: 0.0,
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        makeBSearchText: '',
        makeBSearchResult: [],
        selectedMakeB: {
            id: 0,
            name: '',
            description: '',
            designation: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            fmGroup: {
                id: 0,
                name: '',
                description: '',
                createdAt: '',
                deletedAt: '',
                updatedAt: ''
            },
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedDesignationB: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedFMGroupB: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        shieldingGasBSearchText: '',
        shieldingGasBSearchResult: [],
        selectedShieldingGasB: {
            id: 0,
            name: '',
            content: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        selectedArcTypeB: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        enableMakeAText: false,
        makeAText: '',
        designationAText: '',
        fmGroupAText: '',
        enableMakeBText: false,
        makeBText: '',
        designationBText: '',
        fmGroupBText: '',
        typeOfBackingText: '',
    },
    showJointDesignModal: false,
    weldingParameters: [],
    filesUpload: [],
    indexes: [],
    currentTypes: [],
    temperature: {
        preheatTemperature: '',
        maxInterpassTemperature: ''
    },
    afterWelding: {
        heatingRate: '',
        coolingRate: ''
    },
    otherInformation: {
        otherInformation: ''
    },
    qwbpOrder: {
        customer: '',
        contactPerson: '',
        weldingDate: '',
        phoneNumber: '',
        welderBrazerFirstName: '',
        welderBrazerLastName: '',
        pWPSPBPS: '',
        manufacturerWPQRBPARNumber: '',
        weldingBrazingPositions: '',
        weldingProcess: '',
        fillerMaterial: '',
        ndtRemarks: '',
        visual: '',
        mtPT: '',
        rt: '',
        ut: '',
        ndtOther: '',
        conditionsAndDescriptionRemarks: '',
        material1: '',
        materialGroup1: '',
        dimension1: '',
        standardForAcceptance1: '',
        chargeNumber1: '',
        impactTestTemperature1: '',
        directionOfPlateRolling1: '',
        materialSpecifications1Remarks: '',
        material2: '',
        materialGroup2: '',
        dimension2: '',
        standardForAcceptance2: '',
        chargeNumber2: '',
        impactTestTemperature2: '',
        directionOfPlateRolling2: '',
        materialSpecifications2Remarks: '',
        iso156141Edition2004: false,
        iso156141Edition2017: false,
        transverseTensilePlusRp0ReH: false,
        macroPlusPhoto: false,
        hardnessTesting: false,
        impactTesting: false,
        bendTesting: false,
        additionalTesting: false,
        iso134454Testing: false,
        iso134454ImpactTesting: false,
        iso134454MicroTesting: false,
        iso134454FerriteTesting: false,
        iso134454LongitudinalTensileTesting: false,
        iso134454AdditionalTesting: false,
        iso134454ImpactTestingText: '',
        iso134454MicroTestingText: '',
        iso134454FerriteTestingText: '',
        iso134454LongitudinalTensileTestingText: '',
        iso134454AdditionalTestingText: '',
        iso129526LongitudinalTensileTesting: false,
        iso129526ImpactTesting: false,
        iso129526AdditionalTesting: false,
        iso129526Testing: false,
        iso129526LongitudinalTensileTestingText: '',
        iso129526ImpactTestingText: '',
        iso129526AdditionalTestingText: '',
        iso10902: false,
        iso10902AdditionalTesting: false,
        iso10902CruciformTensileTesting: false,
        iso10902ImpactTesting: false,
        iso10902MacroPlusPhoto: false,
        iso10902MicroTesting: false,
        iso10902WeldabilityPrimer: false,
        iso10902AdditionalTestingText: '',
        iso10902CruciformTensileTestingText: '',
        iso10902ImpactTestingText: '',
        iso10902MacroPlusPhotoText: '',
        iso10902MicroTestingText: '',
        iso10902WeldabilityPrimerText: '',
        norsokAdditionalTesting1: false,
        norsokAdditionalTesting2: false,
        norsokAdditionalTesting3: false,
        norsokAdditionalTesting4: false,
        norsokAdditionalTestingText: '',
        norsokAdditionalTesting1Text: '',
        norsokAdditionalTesting2Text: '',
        norsokAdditionalTesting3Text: '',
        norsokAdditionalTesting4Text: '',
        norsokCorrosionTestingText: '',
        norsok: false,
        norsokCorrosionTesting: false,
        norsokAdditionalTesting: false,
        iso14555ApplicationGreaterThan100Degrees: false,
        iso14555ApplicationLessThanOrEqualTo100Degrees: false,
        iso14555BendTesting: false,
        iso14555ArcStudWelding: false,
        iso14555BendTestingTorqueWrench: false,
        iso14555TensileTesting: false,
        iso14555MacroPlusPhoto: false,
        iso14555TorqueTesting: false,
        iso14555AdditionalTesting: false,
        iso14555RTProvning: false,
        iso14555_3834Testing: false,
        iso14555_783Testing: false,
        iso14555_784Testing: false,
        iso14555_785Testing: false,
        iso14555_786Testing: false,
        iso14555_787Testing: false,
        iso14555ApplicationGreaterThan100DegreesText: '',
        iso14555ApplicationLessThanOrEqualTo100DegreesText: '',
        iso14555BendTestingText: '',
        iso14555BendTestingTorqueWrenchText: '',
        iso14555TensileTestingText: '',
        iso14555MacroPlusPhotoText: '',
        iso14555TorqueTestingText: '',
        iso14555AdditionalTestingText: '',
        iso14555RTProvningText: '',
        iso14555_3834TestingText: '',
        iso13134TensileTesting: false,
        iso13134BendTesting: false,
        iso13134HardnessTesting: false,
        iso13134ShearTesting: false,
        iso13134PeelTesting: false,
        iso13134AdditionalTesting: false,
        iso13134BrazinProcedureApproval: false,
        iso13134TensileTestingText: '',
        iso13134BendTestingText: '',
        iso13134HardnessTestingText: '',
        iso13134ShearTestingText: '',
        iso13134PeelTestingText: '',
        iso13134AdditionalTestingText: '',
        other: '',
        ndtApproved: '',
        ndtSignature: '',
        ndtDate: '',
        destructiveTestingApproved: '',
        destructiveTestingSignature: '',
        destructiveTestingDate: '',
        orderedByLevel3: '',
        date: '',
        signature: '',
        iso15614transverseTensilePlusRp0ReHText: '',
        iso15614macroPlusPhotoText: '',
        iso15614hardnessTestingText: '',
        iso15614impactTestingText: '',
        iso15614bendTestingText: '',
        iso15614additionalTestingText: '',
        iso15614TestingText: '',
        pedAdditionalTesting: false,
        dekraProcedureNoTesting: '',
        invoiceReferenceName: '',
        uppdragsnr: '',
        testmarking: '',
        nace: false,
        naceMr0103Testing: false,
        naceMr0103TestingText: '',
        naceMr0175Testing: false,
        naceMr0175TestingText: '',
        naceAdditionalTesting1: false,
        naceAdditionalTesting1Text: '',
        naceAdditionalTesting2: false,
        naceAdditionalTesting2Text: '',
    },
    jointDesign: {
        id: 0,
        materialThickness1: 0.0,
        materialThickness2: 0.0,
        jointType: {
            id: 0,
            name: '',
            description: '',
            jointShapes: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        jointShape: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        jointAngleAlpha: 0.0,
        gapOpening: 0.0,
        straightEdge: 0.0,
        phaseAngle1: 0.0,
        phaseAngle2: 0.0,
        jointAngleBeta: 0.0,
        throatThickness: 0.0,
        rootSupport: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        depthOfPreparation: 0.0,
        radius: 0.0,
        note: '',
        jointDesignHtmlImage: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    weldSequence: {
        createdAt: '',
        deletedAt: '',
        updatedAt: '',
        imageId: 0,
        imageData: '',
        sequenceNumber1: '',
        sequenceNumber2: '',
        sequenceNumber3: '',
        sequenceNumber4: '',
        sequenceNumber5: '',
        sequenceNumber6: '',
        sequenceNumber7: '',
        sequenceNumber8: '',
        sequenceNumber9: '',
        sequenceNumber10: '',
        sequenceNumber11: '',
        sequenceNumber12: '',
        sequenceNumber13: '',
        sequenceNumber14: '',
        sequenceNumber15: '',
    },
};

export const reducer: Reducer<ApplicationTabStoreState> = (state: ApplicationTabStoreState | undefined, incomingAction: Action): ApplicationTabStoreState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CLEAR_PWPS':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: action.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'CLEAR_SELECT_EMPLOYEE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: action.searchWelderText,
                selectedWelders: action.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'CLEAR_STANDARD':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: action.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: action.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'CREATE_APPLICATION_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: action.applicationId,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                afterWelding: state.afterWelding,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'CREATE_APPLICATION_REQUEST':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                afterWelding: state.afterWelding,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'CLEAR_WELDER_SEARCH_RESULT':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: [],
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_ADDITIONAL_REQUIREMENTS_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: action.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_BASE_MATERIAL_1_SEARCH_RESULT_REQUEST':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_COMPANY_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: action.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_COMPANY_REQUEST':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_CURRENT_TYPES_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: action.currentTypes,
                jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_INDEXES_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: action.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_JOINT_TYPES_ALL_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_NUMBER_OF_WELDING_POSITIONS_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: action.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_OTHER_OPTIONS_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: action.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_ROOT_PROTECTION_GAS_SEARCH_RESULT_REQUEST':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_SHIELDING_GAS_SEARCH_RESULT_REQUEST':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_STANDARDS_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: action.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_STANDARDS_REQUEST':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_STANDARDS_BY_MATERIAL_GROUP_ID_REQUEST':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_WELDER_SEARCH_RESULT_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: action.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'INIT_PWPS':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: action.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'INIT_QWBP_ORDER':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: action.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_ADDITIONAL_REQUIREMENT_SELECTED':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: action.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_AFTER_WELDING':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: action.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_COMPANY':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: action.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_HEADER':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: action.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_OTHER_INFORMATION':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: action.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_OTHER_OPTION_SELECTED':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: action.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_PARENT_MATERIAL':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: action.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_QWBP_ORDER':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: action.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_SELECTED_NUMBER_OF_WELDING_POSITIONS':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: action.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_SELECTED_STANDARD':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: action.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_SELECTED_TAB_ID':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: action.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_SELECTED_WELDERS':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: action.selectedWelders,
                afterWelding: state.afterWelding,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_TEMPERATURE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: action.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_WELDER_SEARCH_TEXT':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: action.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_WELDING_DATA':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: action.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_WELDING_PROCESS':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: action.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'TOGGLE_JOINT_DESIGN_MODAL':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: action.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'UPDATE_APPLICATION_RECEIVE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: action.applicationId,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                afterWelding: state.afterWelding,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: false
            };
        case 'UPDATE_APPLICATION_REQUEST':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                afterWelding: state.afterWelding,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: true
            };
        case 'UPLOAD_FILE_REQUEST_ACTION':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
	 	 	 	filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
	 	 	 	jointDesign: state.jointDesign,
	 	 	 	weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_CURRENT_CUSTOMER_ID':
            return {
                currentCustomerId: action.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
                filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                jointDesign: state.jointDesign,
                weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_JOINT_DESIGN':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
                filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                jointDesign: action.jointDesign,
                weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_WELD_SEQUENCE':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
                filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                jointDesign: state.jointDesign,
                weldSequence: action.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'GET_WELDING_PARAMETERS':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: action.weldingParameters,
                filesUpload: state.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                jointDesign: state.jointDesign,
                weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'SET_APPLICATION_FILES_UPLOAD':
            return {
                currentCustomerId: state.currentCustomerId,
                applicationId: state.applicationId,
                afterWelding: state.afterWelding,
                welderSearchResult: state.welderSearchResult,
                welderSearchText: state.welderSearchText,
                selectedWelders: state.selectedWelders,
                company: state.company,
                standards: state.standards,
                selectedStandard: state.selectedStandard,
                additionalRequirements: state.additionalRequirements,
                otherOptions: state.otherOptions,
                numberOfWeldingPositions: state.numberOfWeldingPositions,
                selectedNumberOfWeldingPositions: state.selectedNumberOfWeldingPositions,
                header: state.header,
                otherInformation: state.otherInformation,
                baseMaterials: state.baseMaterials,
                parentMaterial: state.parentMaterial,
                qwbpOrder: state.qwbpOrder,
                selectedTabId: state.selectedTabId,
                temperature: state.temperature,
                weldingData: state.weldingData,
                weldingParameters: state.weldingParameters,
                filesUpload: action.filesUpload,
                makes: state.makes,
                weldingProcess: state.weldingProcess,
                showJointDesignModal: state.showJointDesignModal,
                indexes: state.indexes,
                currentTypes: state.currentTypes,
                jointDesign: state.jointDesign,
                weldSequence: state.weldSequence,
                updatingApplication: state.updatingApplication
            };
        case 'RESET_STATE_ACTION':
            return unloadedState;
        default:
            return state;
    }
};
