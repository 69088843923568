﻿import { AppThunkAction } from './index';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface RegisterUserState {
    firstName: string;
    lastName: string;
    password: string;
    repeatPassword: string;
    userName: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface RegisterFailureResponseAction {
    type: 'REGISTER_FAILURE_RESPONSE';
}

export interface RegisterRequestAction {
    type: 'REGISTER_REQUEST';
}

export interface RegisterSuccessResponseAction {
    type: 'REGISTER_SUCCESS_RESPONSE';
}

export interface SetPasswordAction {
    type: 'SET_PASSWORD';
    password: string;
}

export interface SetUserNameAction {
    type: 'SET_USERNAME';
    userName: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RegisterFailureResponseAction
    | RegisterRequestAction
    | RegisterSuccessResponseAction
    | SetPasswordAction
    | SetUserNameAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setPassword: (password: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_PASSWORD', password: password });
    },
    setUserName: (userName: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_USERNAME', userName: userName });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: RegisterUserState = {
    firstName: '',
    lastName: '',
    userName: '',
    password: '',
    repeatPassword: ''
};

export const reducer: Reducer<RegisterUserState> = (state: RegisterUserState | undefined, incomingAction: Action): RegisterUserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REGISTER_FAILURE_RESPONSE':
            return {
                firstName: '',
                lastName: '',
                userName: '',
                password: '',
                repeatPassword: ''
            }
        case 'REGISTER_REQUEST':
            return {
                firstName: '',
                lastName: '',
                userName: '',
                password: '',
                repeatPassword: ''
            }
        case 'REGISTER_SUCCESS_RESPONSE':
            return {
                firstName: '',
                lastName: '',
                userName: '',
                password: '',
                repeatPassword: ''
            }
        case 'SET_PASSWORD':
            return {
                firstName: '',
                lastName: '',
                userName: '',
                password: '',
                repeatPassword: ''
            };
        case 'SET_USERNAME':
            return {
                firstName: '',
                lastName: '',
                userName: '',
                password: '',
                repeatPassword: ''
            };
        default:
            return state;
    }
};
