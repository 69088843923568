﻿import * as React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardText, CardTitle, Col, Form, FormGroup, Input, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { ApplicationState } from '../store/index';
import { GetCurrentTypeResponse } from '../store/models/responses/GetCurrentTypeResponse';
import { GetIndexResponse } from '../store/models/responses/GetIndexResponse';
import { GetWeldingMethodResponse } from '../store/models/responses/GetWeldingMethodResponse';
import { WeldingParameters } from '../store/models/WeldingParameters';
import * as WParametersStore from '../store/WParametersStore';
import { t } from '../i18n';
import DropdownLists from './otherComponents/DropdownLists';

// At runtime, Redux will merge together...
type WParametersProps =
    WParametersStore.WParametersState // ... state we've requested from the Redux store
    & typeof WParametersStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

const SweToEngCurrentType = new Map<string, string>();

export interface OwnProps {
    pWeldingMethodASearchText: string;
    pWeldingMethodBSearchText: string;
    pWeldingParameters: Array<WeldingParameters>;
    currentTypes: Array<GetCurrentTypeResponse>;
    pWeldingMethods: Array<GetWeldingMethodResponse>;
    // Tree different parameters (Base,Pulse,XXX) 
    showWeldingBaseParameters?: boolean;
    showWeldingPulseParameters?: boolean;
    showWeldingArcParameters?: boolean;
    showWeldingGwParameters?: boolean;
    // Set's when place the component in the code
    componentPositionApplication?: boolean;
    componentPositionProtocol?: boolean;
    componentPositionDetails?: boolean;

    setWParametersView?: boolean;
    enableSettingModal?: boolean;
    pWpsHeatInputText?: string;
    pWpsArcEnergyText?: string;
}

class WParameters extends React.PureComponent<WParametersProps> {
    constructor(props: WParametersProps) {
        super(props);
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.props.clearWeldingMethods();
        this.props.initWParameters();
        SweToEngCurrentType.set('DC +, pulsad ström', 'DC+ Puls/Pulse');
        SweToEngCurrentType.set('DC -, pulsad ström', 'DC- Puls/Pulse');
    }

    private TranslateBoxes = (word: string, dictionary: Map<string, string>) => {
        if (dictionary.has(word)) {
            return dictionary.get(word);
        } else {
            return word;
        }
    }

    componentDidUpdate(prevProps: any) {

        if (prevProps.pWeldingParameters !== this.props.pWeldingParameters) {

            if (this.props.setWParametersView) {
                //TODO this is only to wiew.
                this.props.setWeldingParametersView(this.props.pWeldingParameters);
                this.props.setWeldingParametersPropertiesView(this.props.pWeldingMethods);
            } else {
                this.props.setWeldingParameters(this.props.pWeldingParameters);
            }
        }
        if (prevProps.pWeldingMethods !== this.props.pWeldingMethods) {
            if (this.props.setWParametersView) {
                //this.props.clearWeldingMethodsView();
                //this.props.setWeldingParametersPropertiesView(this.props.pWeldingMethods);
            } else {
                this.props.setWeldingParametersProperties(this.props.pWeldingMethods);
            }
            
        }
        if (prevProps.pWeldingMethodASearchText !== this.props.pWeldingMethodASearchText || prevProps.pWeldingMethodBSearchText !== this.props.pWeldingMethodBSearchText) {
            if (this.props.setWParametersView) {
            } else {
                this.props.setWeldingParametersProperties(this.props.pWeldingMethods);
            }

        }
    }

    private onChangeWeldingParametersCurrent(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersCurrent(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersCurrentTypes(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersCurrentTypeId(rowNumber, parseInt(event.currentTarget.value), this.props.currentTypes);
    }

    private onChangeWeldingParametersDiameter(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersDiameter(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersHeatInput(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersHeatInput(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersFlowRateTop(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersFlowRateTop(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersFlowRateRoot(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersFlowRateRoot(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersRunText(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersRunText(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersSettingsSaveShowCurrent(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowCurrent(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowDiamter(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowFlowRateRoot(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowFlowRateRoot(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowFlowRateTop(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowFlowRateTop(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowHeatInput(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowHeatInput(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowIndex(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowIndex(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowRun(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowRun(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowTravelSpeed(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowTravelSpeed(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowTypeOfCurrent(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowTypeOfCurrent(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowVoltage(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowVoltage(event.currentTarget.checked);
    }

    private onChangeWeldingParametersSettingsSaveShowWireFeed(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersSettingsSaveShowWireFeed(event.currentTarget.checked);
    }

    private onChangeWeldingParametersTravelSpeed(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersTravelSpeed(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersTravelSpeedPulse(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersTravelSpeedPulse(rowNumber, event.currentTarget.value);
    }

    private onChangeArcWeldingParametersTravelSpeed(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcWeldingParametersTravelSpeed(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersPulseCurrent(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersPulseCurrent(rowNumber, event.currentTarget.value);
    }
    private onChangeWeldingParametersPulseTime(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersPulseTime(rowNumber, event.currentTarget.value);
    }
    private onChangeWeldingParametersBasicCurrent(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersBasicCurrent(rowNumber, event.currentTarget.value);
    }
    
    private onChangeWeldingParametersTimeForBasicCurrent(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersTimeForBasicCurrent(rowNumber, event.currentTarget.value);
    }
    private onChangeWeldingParametersFrequency(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersFrequency(rowNumber, event.currentTarget.value);
    }
    private onChangeWeldingParametersTime1(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersTime1(rowNumber, event.currentTarget.value);
    }
    private onChangeWeldingParametersCurrent1(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersCurrent1(rowNumber, event.currentTarget.value);
    }
    private onChangeWeldingParametersHeatInput1(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersHeatInput1(rowNumber, event.currentTarget.value);
    }
    private onChangeWeldingParametersVoltage(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersVoltage(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersVoltagePulse(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersVoltagePulse(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingProtocolWeldingParametersTimePulse(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingParametersTimePulse(rowNumber, event.currentTarget.value);
    }

    private onChangeArcWeldingParametersVoltage(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersVoltagePulse(rowNumber, event.currentTarget.value);
    }

    private onChangeArcWeldingParametersArcIP(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcWeldingParametersArcIP(rowNumber, event.currentTarget.value);

    }

    private onChangeArcWeldingParametersArcIE(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcWeldingParametersArcIE(rowNumber, event.currentTarget.value);
    }

    private onChangeArcWeldingParametersAdditiveMaterial(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcWeldingParametersAdditiveMaterial(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersWireFeed(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersWireFeed(rowNumber, event.currentTarget.value);
    }

    private onClickRemoveWeldingParametersRow(rowNumber: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.removeWeldingParametersRow(rowNumber);
    }

    private onClickCloneWeldingParametersRow(rowNumber: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.cloneWeldingParametersRow(rowNumber);
    }

    private onClickAddWeldingParametersRow(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.stopPropagation();
        this.props.addWeldingParametersRow('', '', '', '', '', '', '', '');
    }

    private onClickOpenWeldingParametersSettingsModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.openWeldingParametersSettingsModal();
    }

    private onClickWeldingParametersSettingsModalOK(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.closeWeldingParametersSettingsModal(true);
    }

    private onClickWeldingParametersSettingsModalCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.closeWeldingParametersSettingsModal(false);
    }

    private onChangeWeldingProtocolWeldingParametersWeldingMethods(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingParametersWeldingMethodId(rowNumber, parseInt(event.currentTarget.value));
    }

    private onChangeWeldingParametersWeldingMethods(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersWeldingMethodId(rowNumber, parseInt(event.currentTarget.value));
    }

    private onChangeWeldingParametersVoltageX(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersWeldingVoltageX(event.currentTarget.value);
    }

    private onChangeWeldingParametersCurrentY(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersCurrentY(event.currentTarget.value);
    }

    private onChangeWeldingParametersTravelSpeedZ(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersTravelSpeedZ(event.currentTarget.value);
    }

    private onChangeArcWeldingParametersWeldingMethods(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcWeldingParametersWeldingMethodId(rowNumber, parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProtocolWeldingParametersRunLength(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingParametersRunLength(rowNumber, event.currentTarget.value);
    }
    private onChangeWeldingProtocolWeldingParametersRunLengthPulse(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingParametersRunLengthPulse(rowNumber, event.currentTarget.value);
    }

    private onChangeArcWeldingParametersRunLength(rowNumber: number,enableTravelSpeed: boolean, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcWeldingParametersRunLength(rowNumber, enableTravelSpeed, event.currentTarget.value);
    }

    private onChangeWeldingProtocolWeldingParametersTime(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProtocolWeldingParametersTime(rowNumber, event.currentTarget.value);
    }

    private onChangeArcWeldingParametersTime(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcWeldingParametersTime(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersInterpassTemperature(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersInterpassTemperature(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersRunWidth(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersRunWidth(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersWeldingTecknique(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersTecknique(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersFuelGasPressure(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersFuelGasPressure(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersOxygenPressure(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersOxygenPressure(rowNumber, event.currentTarget.value);
    }

    private onChangeWeldingParametersTypeOfFlame(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingParametersTypeOfFlame(rowNumber, event.currentTarget.value);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private onClickSetText = (event: string) => {
    }

    public render() {
        return (
            <React.Fragment key="Wparameters1">
                {this.props.showWeldingPulseParameters ?
                    <Row form>
                        <Col sm={12}>
                            {this.props.setWParametersView === true ? this.renderWeldingPulseParametersView() : this.renderWeldingPulseParameters()}
                        </Col>
                    </Row> : <Row></Row>}
                {this.props.showWeldingBaseParameters ?
                    <Row form>
                        <Col sm={12}>
                            {this.renderWeldingBaseParameters()}
                        </Col>
                    </Row> : <Row></Row>}
                {this.props.showWeldingArcParameters ?
                    <Row form>
                        <Col sm={12}>
                            {this.props.setWParametersView === true ? this.renderWeldingArcParametersView() : this.renderWeldingArcParameters()}
                        </Col>
                    </Row> : <Row></Row>}
                {this.props.showWeldingGwParameters ?
                    <Row form>
                        <Col sm={12}>
                            {this.props.setWParametersView === true ? this.renderWeldingGwParametersView() : this.renderWeldingGwParameters()}
                        </Col>
                    </Row> : <Row></Row>}
                {this.props.enableSettingModal ? this.renderWeldingParametersSettingsModal() : ""}
            </React.Fragment>
        );
    }
    private renderWeldingPulseParameters() {
        return (
            <React.Fragment key="Wparameters2">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row form >
                                <Col sm={2}>
                                    <h5>{t('WeldingParameters')}<span>&nbsp;</span><Button type="button" color="link" size="sm" id="weldingParametersSettings" onClick={(event) => this.onClickOpenWeldingParametersSettingsModal(event)}><i className="fas fa-cog"></i></Button></h5>
                                </Col>
                                <Col sm={this.props.pWpsArcEnergyText !== null && this.props.pWpsArcEnergyText !== "" ? 6 : 8}>
                                    <FormGroup>
                                        <Label for="weldingProtocolWeldingProcessEfficiencyK" size="sm">{t('PulseWelding')} ({t('SquareWave')})</Label>
                                        <p className="font-italic">
                                            * {t('AverageCurrentCalculatedAs')} I({t('Average')}) = (Ip*tp + Ig*tg)/(tp + tg) med Ip, tp, Ig, tg {t('readFromDisplay')}<br />
                                            ** {t('AverageCurrentCalculatedAs')} I({t('Average')}) = (Ip*tp + Ig*tg)/(tp + tg) med Ip, tg, Ig {t('andFrequencyUsing')} (tp + tg) * f = 1 {t('second')} (1 ms = 1 * 0,001 ({t('sec')}))<br />
                                    </p>
                                    </FormGroup>
                                </Col>
                                <Col sm={4} hidden={!(this.props.pWpsArcEnergyText !== null && this.props.pWpsArcEnergyText !== "")}>
                                    <Row form>
                                        <Col sm={12}>
                                            <Form inline>
                                                <FormGroup>
                                                    <Label for="pWpsHeatInputText" size="sm">{t('ValidityOfTheWPQRForStrainEnergy')}:</Label>
                                                </FormGroup>
                                                <span>&nbsp;</span>
                                                    {this.props.pWpsHeatInputText}
                                            </Form>
                                            <Form inline>
                                                <FormGroup>
                                                    <Label for="weldingProtocolWeldingProcessEfficiencyK" size="sm">{t('ValidityOfTheWPQRForArcEnergy')}:</Label>
                                                </FormGroup>
                                                <span>&nbsp;</span>
                                                    {this.props.pWpsArcEnergyText}
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardTitle>
                        <div>
                            <Row form >
                                <Col sm={12}>
                                    {this.renderWeldingPulseParametersTable1()}
                                </Col>
                            </Row>
                            <br />
                            <Row form >
                                <Col sm={12}>
                                    {this.renderWeldingPulseParametersTable2()}
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Button color="link" size="sm" onClick={(event) => this.onClickAddWeldingParametersRow(event)}>+ {t('AddNewRow')}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
    private renderWeldingPulseParametersView() {
        return (
            <React.Fragment key="Wparameters3">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row form >
                                <Col sm={4}>
                                    <h5>{t('WeldingParameters')}<span>&nbsp;</span><Button type="button" color="link" size="sm" id="weldingParametersSettings" onClick={(event) => this.onClickOpenWeldingParametersSettingsModal(event)}><i className="fas fa-cog"></i></Button></h5>
                                </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                        <Label for="weldingProtocolWeldingProcessEfficiencyK" size="sm">{t('PulseWelding')} ({t('SquareWave')})</Label>
                                        <p className="font-italic">
                                            * {t('AverageCurrentCalculatedAs')} I({t('Average')}) = (Ip*tp + Ig*tg)/(tp + tg) med Ip, tp, Ig, tg {t('readFromDisplay')}<br />
                                            ** {t('AverageCurrentCalculatedAs')} I({t('Average')}) = (Ip*tp + Ig*tg)/(tp + tg) med Ip, tg, Ig {t('andFrequencyUsing')} (tp + tg) * f = 1 {t('second')} (1 ms = 1 * 0,001 ({t('sec')}))<br />
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardTitle>
                        <div>
                            <Row form >
                                <Col sm={12}>
                                    {this.renderWeldingPulseParametersTable1View()}
                                </Col>
                            </Row>
                            <br />
                            <Row form >
                                <Col sm={12}>
                                    {this.renderWeldingPulseParametersTable2View()}
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderWeldingBaseParameters() {
        return (
            <React.Fragment key="Wparameters4">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row form >
                                <Col sm={4}>
                                    <h5>{t('WeldingParameters')} <span>&nbsp;</span><Button type="button" color="link" size="sm" id="weldingParametersSettings" onClick={(event) => this.onClickOpenWeldingParametersSettingsModal(event)}><i className="fas fa-cog"></i></Button></h5>
                                </Col>
                                <Col sm={8}>
                                    {this.props.pWpsArcEnergyText !== null && this.props.pWpsArcEnergyText !== "" ?
                                        <Row form>
                                            <Col sm={12}>
                                                <Form inline>
                                                    <FormGroup>
                                                        <Label for="pWpsHeatInputText" size="sm">{t('ValidityOfTheWPQRForStrainEnergy')}:</Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                        {this.props.pWpsHeatInputText}
                                                </Form>
                                                <Form inline>
                                                    <FormGroup>
                                                        <Label for="weldingProtocolWeldingProcessEfficiencyK" size="sm">{t('ValidityOfTheWPQRForArcEnergy')}:</Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                        {this.props.pWpsArcEnergyText}
                                                </Form>
                                            </Col>
                                        </Row> : <Row></Row>}
                                </Col>
                            </Row>
                        </CardTitle>
                        <div>
                            {this.props.componentPositionApplication ?
                                <Row form>
                                    <Col sm={12}>
                                        {this.props.setWParametersView === true ? this.renderWeldingParametersTableView() : this.renderWeldingParametersTable()}
                                    </Col>
                                </Row> : <Row></Row>}
                            {this.props.componentPositionProtocol ?
                                <Row form>
                                    <Col sm={12}>
                                        {this.renderWeldingBaseParametersTable()}
                                    </Col>
                                </Row> : <Row></Row>}
                            {this.props.componentPositionDetails ?
                                <Row form>
                                    <Col sm={12}>
                                        {this.renderWeldingBaseParametersTableDetails()}
                                    </Col>
                                </Row> : <Row></Row>}
                            <Row form hidden={this.props.setWParametersView}>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Button color="link" size="sm" onClick={(event) => this.onClickAddWeldingParametersRow(event)}>+ {t('AddNewRow')}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderWeldingArcParameters() {
        return (
            <React.Fragment key="Wparameters5">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row form >
                                <Col sm={4}>
                                    <h5>{t('WeldingParameters')} <span>&nbsp;</span><Button type="button" color="link" size="sm" id="weldingParametersSettings" onClick={(event) => this.onClickOpenWeldingParametersSettingsModal(event)}><i className="fas fa-cog"></i></Button></h5>
                                </Col>
                                <Col sm={8}>
                                    {this.props.pWpsArcEnergyText !== null && this.props.pWpsArcEnergyText !== "" ?
                                        <Row form>
                                            <Col sm={12}>
                                                <Form inline>
                                                    <FormGroup>
                                                        <Label for="pWpsHeatInputText" size="sm">{t('ValidityOfTheWPQRForStrainEnergy')}:</Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                        {this.props.pWpsHeatInputText}
                                                </Form>
                                                <Form inline>
                                                    <FormGroup>
                                                        <Label for="weldingProtocolWeldingProcessEfficiencyK" size="sm">{t('ValidityOfTheWPQRForArcEnergy')}:</Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                        {this.props.pWpsArcEnergyText}
                                                </Form>
                                            </Col>
                                        </Row> : <Row></Row>}
                                </Col>
                            </Row>
                        </CardTitle>
                        <div>
                            <Row>
                                <Col sm={12}>
                                    {this.renderWeldingArcParametersTable()}
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Button color="link" size="sm" onClick={(event) => this.onClickAddWeldingParametersRow(event)}>+ {t('AddNewRow')}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
    private renderWeldingArcParametersView() {
        return (
            <React.Fragment key="WparametersView5">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row form >
                                <Col sm={4}>
                                    <h5>{t('WeldingParameters')} <span>&nbsp;</span><Button type="button" color="link" size="sm" id="weldingParametersSettings" onClick={(event) => this.onClickOpenWeldingParametersSettingsModal(event)}><i className="fas fa-cog"></i></Button></h5>
                                </Col>
                            </Row>
                        </CardTitle>
                        <div>
                            <Row>
                                <Col sm={12}>
                                    {this.renderWeldingArcParametersTableView()}
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
    private renderWeldingGwParameters() {
        return (
            <React.Fragment key="Wparameters5">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row form >
                                <Col sm={4}>
                                    <h5>{t('WeldingParameters')} <span>&nbsp;</span><Button type="button" color="link" size="sm" id="weldingParametersSettings" onClick={(event) => this.onClickOpenWeldingParametersSettingsModal(event)}><i className="fas fa-cog"></i></Button></h5>
                                </Col>
                                <Col sm={8}>
                                    {this.props.pWpsArcEnergyText !== null && this.props.pWpsArcEnergyText !== "" ?
                                        <Row form>
                                            <Col sm={12}>
                                                <Form inline>
                                                    <FormGroup>
                                                        <Label for="pWpsHeatInputText" size="sm">{t('ValidityOfTheWPQRForStrainEnergy')}:</Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                        {this.props.pWpsHeatInputText}
                                                </Form>
                                                <Form inline>
                                                    <FormGroup>
                                                        <Label for="weldingProtocolWeldingProcessEfficiencyK" size="sm">{t('ValidityOfTheWPQRForArcEnergy')}:</Label>
                                                    </FormGroup>
                                                    <span>&nbsp;</span>
                                                        {this.props.pWpsArcEnergyText}
                                                </Form>
                                            </Col>
                                        </Row> : <Row></Row>}
                                </Col>
                            </Row>
                        </CardTitle>
                        <div>
                            <Row>
                                <Col sm={12}>
                                    {this.renderWeldinGwParametersTable()}
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Button color="link" size="sm" onClick={(event) => this.onClickAddWeldingParametersRow(event)}>+ {t('AddNewRow')}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
    private renderWeldingGwParametersView() {
        return (
            <React.Fragment key="WparametersView5">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldingParameters')}<span>&nbsp;</span><Button type="button" color="link" size="sm" id="weldingParametersSettings" onClick={(event) => this.onClickOpenWeldingParametersSettingsModal(event)}><i className="fas fa-cog"></i></Button></h5>
                        </CardTitle>
                        <div>
                            <Row>
                                <Col sm={12}>
                                    {this.renderWeldingGwParametersTableView()}
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
    private renderWeldingParametersSettingsModal() {
        return (
            <React.Fragment key="Wparameters6">
                <Modal centered={true} isOpen={(this.props.showWeldingParametersSettingsModal && this.props.componentPositionDetails) || (this.props.showWeldingParametersSettingsModal && this.props.componentPositionApplication)}>
                    <ModalHeader>
                        {t('WeldingParameters')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowRun" id="weldingParametersSettingsShowRun"
                                            checked={this.props.weldingParametersSettingsSave.showRun}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowRun(event)}
                                            placeholder="" />{' '} {t('String')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowIndex" id="weldingParametersSettingsShowIndex"
                                            checked={this.props.weldingParametersSettingsSave.showIndex}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowIndex(event)}
                                            placeholder="" />{' '} {t('Index')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowDiameter" id="weldingParametersSettingsShowDiameter"
                                            checked={this.props.weldingParametersSettingsSave.showDiameter}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowDiameter(event)}
                                            placeholder="" />{' '} {t('Diameter')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowVoltage" id="weldingParametersSettingsShowVoltage"
                                            checked={this.props.weldingParametersSettingsSave .showVoltage}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowVoltage(event)}
                                            placeholder="" />{' '} {t('Voltage')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowCurrent" id="weldingParametersSettingsShowCurrent"
                                            checked={this.props.weldingParametersSettingsSave.showCurrent}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowCurrent(event)}
                                            placeholder="" />{' '} {t('Current')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowWireFeed" id="weldingParametersSettingsShowWireFeed"
                                            checked={this.props.weldingParametersSettingsSave.showWireFeed}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowWireFeed(event)}
                                            placeholder="" />{' '} {t('WireFeed')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowFlowRateTop" id="weldingParametersSettingsShowFlowRateTop"
                                            checked={this.props.weldingParametersSettingsSave.showFlowRateTop}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowFlowRateTop(event)}
                                            placeholder="" />{' '} {t('GasFlowTop')}{t('side')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowFlowRateRoot" id="weldingParametersSettingsShowFlowRateRoot"
                                            checked={this.props.weldingParametersSettingsSave.showFlowRateRoot}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowFlowRateRoot(event)}
                                            placeholder="" />{' '}{t('GasFlowRoot')}{t('side')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowTypeOfCurrent" id="weldingParametersSettingsShowTypeOfCurrent"
                                            checked={this.props.weldingParametersSettingsSave.showCurrentType}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowTypeOfCurrent(event)}
                                            placeholder="" />{' '} {t('CurrentType')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowTravelSpeed" id="weldingParametersSettingsShowTravelSpeed"
                                            checked={this.props.weldingParametersSettingsSave.showTravelSpeed}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowTravelSpeed(event)}
                                            placeholder="" />{' '} {t('Speed')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" name="weldingParametersSettingsShowHeatInput" id="weldingParametersSettingsShowHeatInput"
                                            checked={this.props.weldingParametersSettingsSave.showHeatInput}
                                            onChange={(event) => this.onChangeWeldingParametersSettingsSaveShowHeatInput(event)}
                                            placeholder="" />{' '} {t('HeatInput')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickWeldingParametersSettingsModalOK(event)}>OK</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickWeldingParametersSettingsModalCancel(event)}>Avbryt</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
    private renderWeldingParametersTable() {
        return (
            <React.Fragment key="Wparameters7">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingParametersTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.weldingParameters.map((row, index) => (
                            this.renderWeldingParametersTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderWeldingParametersTableView() {
        return (
            <React.Fragment key="WparametersView7">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingParametersTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.weldingParametersView.map((row, index) => (
                            this.renderWeldingParametersTableRowView(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderWeldingPulseParametersTable1() {
        return (
            <React.Fragment key="Wparameters8">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingPulseParametersTableHeaderRow1()}
                    </thead>
                    <tbody>
                        {this.props.weldingParameters.map((row, index) => (
                            this.renderWeldingPulseParametersTable1Row(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderWeldingPulseParametersTable1View() {
        return (
            <React.Fragment key="Wparameters9">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingPulseParametersTableHeaderRow1()}
                    </thead>
                    <tbody>
                        {this.props.weldingParametersView.map((row, index) => (
                            this.renderWeldingPulseParametersTable1RowView(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderWeldingPulseParametersTable2() {
        return (
            <React.Fragment key="Wparameters10">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingPulseParametersTableHeaderRow2()}
                    </thead>
                    <tbody>
                        {this.props.weldingParameters.map((row, index) => (
                            this.renderWeldingPulseParametersTable2Row(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderWeldingPulseParametersTable2View() {
        return (
            <React.Fragment key="Wparameters11">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingPulseParametersTableHeaderRow2()}
                    </thead>
                    <tbody>
                        {this.props.weldingParametersView.map((row, index) => (
                            this.renderWeldingPulseParametersTable2RowView(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderWeldingBaseParametersTableDetails() {
        return (
            <React.Fragment key="Wparameters12">
                <Table size="sm" responsive className="cert-table table-padding" >
                    <thead>
                        {this.renderWeldingParametersTableHeaderRowDetails()}
                    </thead>
                    <tbody>
                        {this.props.weldingParameters.map((row, index) => (
                            this.renderWeldingParametersTableRowDetails(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderWeldingParametersTableHeaderRow() {
        return (
            <React.Fragment key="Wparameters13">
                <tr>
                    <th rowSpan={2} hidden={this.props.setWParametersView}></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showRun}><a>Sträng</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showIndex}><a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Svetsmetod&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showDiameter}><a>Elektrod Ø (mm) </a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showWireFeed}><a>Trådmatning (m/min)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showFlowRateTop}><a>Gasflöde topp (l/min)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showFlowRateRoot}><a>Gasflöde rot (l/min)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showCurrentType}><a>Strömtyp</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showVoltage}><a>Spänning (V)</a></th>
                    <th colSpan={2} hidden={!this.props.weldingParametersSettings.showVoltage}>
                        {this.props.setWParametersView ?
                            <Input readOnly type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersVoltageX"} id={"weldingParametersVoltageX"}
                            value={this.props.weldingParametersSettings.voltageXView}
                            placeholder="(%)" /> :
                            <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersVoltageX"} id={"weldingParametersVoltageX"}
                            value={this.props.weldingParametersSettings.voltageX}
                            onChange={(event) => this.onChangeWeldingParametersVoltageX(event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="(%)" />
                        }
                    </th>
                    <th rowSpan={2} hidden={(!this.props.weldingParametersSettings.showCurrent)}><a>Ström (A)</a></th>
                    <th colSpan={2} hidden={(!this.props.weldingParametersSettings.showCurrent)}>
                        {this.props.setWParametersView ?
                            <Input readOnly type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersCurrentY"} id={"weldingParametersCurrentY"}
                                value={this.props.weldingParametersSettings.currentYView}
                                placeholder="(%)" /> :
                            <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersCurrentY"} id={"weldingParametersCurrentY"}
                                value={this.props.weldingParametersSettings.currentY}
                                onChange={(event) => this.onChangeWeldingParametersCurrentY(event)}
                                onKeyPress={(event) => this.onKeyPress(event)}
                                placeholder="(%)" />
                        }
                    </th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showTravelSpeed}><a>Framförings-</a><br></br><a>hastighet (mm/min)</a></th>
                    <th colSpan={2} hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        {this.props.setWParametersView ?
                            <Input readOnly type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersTravelSpeedZ}"} id={"weldingParametersTravelSpeedZ"}
                                value={this.props.weldingParametersSettings.travelSpeedZView}
                                placeholder="(%)" /> :
                            <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersTravelSpeedZ}"} id={"weldingParametersTravelSpeedZ"}
                                value={this.props.weldingParametersSettings.travelSpeedZ}
                                onChange={(event) => this.onChangeWeldingParametersTravelSpeedZ(event)}
                                onKeyPress={(event) => this.onKeyPress(event)}
                                placeholder="(%)" />
                        }
                    </th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showHeatInput}><a>Värmetillförsel (kJ/mm)</a></th>
                    <th colSpan={2} hidden={!this.props.weldingParametersSettings.showHeatInput}><a>Värmetillförsel (kJ/mm)</a></th>
                </tr>
                <tr>
                    <th hidden={!this.props.weldingParametersSettings.showVoltage}><a>Min</a></th>
                    <th hidden={!this.props.weldingParametersSettings.showVoltage}><a>Max</a></th>
                    <th hidden={!this.props.weldingParametersSettings.showCurrent}><a>Min</a></th>
                    <th hidden={!this.props.weldingParametersSettings.showCurrent}><a>Max</a></th>
                    <th hidden={!this.props.weldingParametersSettings.showTravelSpeed}><a>Min</a></th>
                    <th hidden={!this.props.weldingParametersSettings.showTravelSpeed}><a>Max</a></th>
                    <th hidden={!this.props.weldingParametersSettings.showHeatInput}><a>Min</a></th>
                    <th hidden={!this.props.weldingParametersSettings.showHeatInput}><a>Max</a></th>

                </tr>
            </React.Fragment>
        );
    }

    private renderWeldingPulseParametersTableHeaderRow1() {
        return (
            <React.Fragment key="Wparameters14">
                <tr>
                    <th colSpan={2} hidden={this.props.setWParametersView}></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showRun}><a>{t('String')}</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showIndex}><a>{t('WeldingMethod')}</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showDiameter}><a>{t('Electrode')} Ø (mm)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showWireFeed}><a>{t('WireFeed')} (m/min)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showFlowRateTop || this.props.componentPositionProtocol || this.props.componentPositionDetails}><a>{t('GasFlowTop')} (l/min)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showFlowRateRoot || this.props.componentPositionProtocol || this.props.componentPositionDetails}><a>{t('GasFlowRoot')} (l/min)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showCurrentType}><a>{t('CurrentType')}</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showVoltage}><a>{t('Voltage')} (V)</a></th>
                    <th rowSpan={2} hidden={this.props.componentPositionApplication}><a>{t('WeldingBead')} (mm)</a></th>
                    <th rowSpan={2} hidden={this.props.componentPositionApplication}><a>{t('Time')} (s)</a></th>
                    <th rowSpan={2}><a>{t('PerformanceSpeed')} (mm/min)</a></th>
                </tr>
                <tr>
                    <th hidden={this.props.setWParametersView}></th>
                    <th hidden={this.props.setWParametersView}></th>

                </tr>
            </React.Fragment>
        );
    }

    private renderWeldingPulseParametersTableHeaderRow2() {
        return (
            <React.Fragment key="Wparameters15">
                <tr>
                    <th rowSpan={2}><a>{t('PulseCurrent')} Ip (A)</a></th>
                    <th rowSpan={2}><a>{t('PulseDuration')} tp ({t('sec')})</a></th>
                    <th rowSpan={2}><a>{t('BasicCurrent')} Ig (A)</a></th>
                    <th className="pulse-td-dark-green" rowSpan={2}><a>{t('BasicCurrentDuration')} tg * ({t('sec')})</a></th>
                    <th className="pulse-td-light-green" rowSpan={2}><a>{t('Frequency')} ** (Hz)</a></th>
                    <th className="pulse-td-dark-green" rowSpan={2}><a>{t('AverageCurrent')} * (A)</a></th>
                    <th className="pulse-td-dark-green" rowSpan={2}><a>{t('HeatSupply')} {t('average')} * (kJ/mm)</a></th>
                    <th className="pulse-td-light-green" rowSpan={2}><a>{t('CalculatedDurationForBasicCurrent')} tg ** ({t('sec')})</a></th>
                    <th className="pulse-td-light-green" rowSpan={2}><a>{t('AverageCurrent')} ** (A)</a></th>
                    <th className="pulse-td-light-green" rowSpan={2}><a>{t('HeatSupply')} {t('average')} ** (kJ/mm)</a></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderWeldingArcParametersTableHeaderRow() {
        return (
            <React.Fragment key="Wparameters16">
                <tr>
                    <th colSpan={2} hidden={this.props.setWParametersView}></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showRun}><a>{t('String')}</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showIndex}><a>{t('WeldingMethod')}</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showDiameter}><a>{t('Electrode')} Ø (mm)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showWireFeed}><a>{t('WireFeed')} (m/min)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showFlowRateTop || this.props.componentPositionProtocol || this.props.componentPositionDetails}><a>{t('GasFlowTop')} (l/min)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showFlowRateRoot || this.props.componentPositionProtocol || this.props.componentPositionDetails}><a>{t('GasFlowRoot')} (l/min)</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showCurrentType}><a>{t('CurrentType')}</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showVoltage}><a>{t('Voltage')} (V)</a></th>
                    <th className="pulse-td-light-green" rowSpan={2}><a>IE (J)</a></th>
                    <th className="pulse-td-dark-green" rowSpan={2}><a>IP (J/s)</a></th>
                    <th className="pulse-td-light-green" rowSpan={2} ><a>{t('WeldLength')} (mm)</a></th>
                    <th rowSpan={2} hidden={this.props.componentPositionApplication}><a>{t('WeldTime')} ({t('sec')})</a></th>
                    <th className="pulse-td-dark-green" rowSpan={2}><a>{t('PerformanceSpeed')} (mm/min)</a></th>
                    <th className="pulse-td-light-green" rowSpan={2}><a>{t('ArcEnergy')} E=IE/L (kJ/mm)</a></th>
                    <th className="pulse-td-dark-green" rowSpan={2}><a>{t('ArcEnergy')} E=IP/v (kJ/mm)</a></th>
                </tr>
                <tr>
                    <th hidden={this.props.setWParametersView}></th>
                    <th hidden={this.props.setWParametersView}></th>

                </tr>
            </React.Fragment>
        );
    }

    private renderWeldingGwParametersTableHeaderRow() {
        return (
            <React.Fragment key="Wparameters16Gw">
                <tr>
                    <th colSpan={2} hidden={this.props.setWParametersView}></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showRun}><a>{t('String')}</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showIndex}><a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('WeldingMethod')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></th>
                    <th rowSpan={2} hidden={!this.props.weldingParametersSettings.showDiameter}><a>{t('Electrode')} Ø (mm)</a></th>
                    <th rowSpan={2} ><a>{t('WeldingTechnology')} </a></th>
                    <th rowSpan={2} ><a>{t('FuelPressure')} (bar)</a></th>
                    <th rowSpan={2} ><a>{t('OxygenPressure')} (bar)</a></th>
                    <th rowSpan={2} ><a>{t('LowType')}</a></th>
                    <th rowSpan={2} hidden={this.props.componentPositionApplication}><a>{t('InterpassTemperature')} °C</a></th>
                    <th rowSpan={2} hidden={this.props.componentPositionApplication}><a>{t('WidthOfRun')} (mm)</a></th>
                </tr>
                <tr>
                    <th hidden={this.props.setWParametersView}></th>
                    <th hidden={this.props.setWParametersView}></th>

                </tr>
            </React.Fragment>
        );
    }

    private renderWeldingParametersTableRow(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters17" + rowNumber}>
                <tr>
                    <td>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <Button color="link" size="sm" name={"removeWeldingParametersRow-" + rowNumber} id={"removeWeldingParametersRow-" + rowNumber}
                                onClick={(event) => this.onClickRemoveWeldingParametersRow(rowNumber, event)}>
                                <i className="fas fa-trash"></i>
                            </Button>
                            <Button color="link" size="sm" name={"cloneWeldingParametersRow-" + rowNumber} id={"cloneWeldingParametersRow-" + rowNumber}
                                onClick={(event) => this.onClickCloneWeldingParametersRow(rowNumber, event)}>
                                <i className="fas fa-clone"></i>
                            </Button>
                        </div>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersRun-" + rowNumber} id={"weldingParametersRun-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runText}
                            onChange={(event) => this.onChangeWeldingParametersRunText(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input type="select" className="padding-form-control-select" bsSize="sm" name={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber} id={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].weldingMethod.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersWeldingMethods(rowNumber, event)}
                            placeholder="">
                            {this.props.weldingParametersProperties.weldingMethods.map((weldingMethod, index) => (
                                <option key={"weldingMethodId" + weldingMethod.id + rowNumber} id={"weldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showDiameter}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersDiameter-" + rowNumber} id={"weldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].diameter}
                            onChange={(event) => this.onChangeWeldingParametersDiameter(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showWireFeed}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersWireFeed-" + rowNumber} id={"weldingParametersWireFeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].wireFeed}
                            onChange={(event) => this.onChangeWeldingParametersWireFeed(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateTop}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersGasFlowRateTop-" + rowNumber} id={"weldingParametersGasFlowRateTop-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].flowRateTop}
                            onChange={(event) => this.onChangeWeldingParametersFlowRateTop(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateRoot}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersGasFlowRateRoot-" + rowNumber} id={"weldingParametersGasFlowRateRoot-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].flowRateRoot}
                            onChange={(event) => this.onChangeWeldingParametersFlowRateRoot(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrentType}>
                        <Input type="select" className="padding-form-control-select" bsSize="sm" name={"weldingParametersCurrentTypes-" + rowNumber} id={"weldingParametersCurrentTypes-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].currentType.id}
                            onChange={(event) => this.onChangeWeldingParametersCurrentTypes(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="">
                            {this.props.currentTypes.map((currentType, index) => (
                                <option key={"currentTypeId" + currentType.id + rowNumber} id={"currentTypeId" + currentType.id} value={currentType.id}>{this.TranslateBoxes(currentType.name, SweToEngCurrentType)}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersVoltage-" + rowNumber} id={"weldingParametersVoltage-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].voltage}
                            onChange={(event) => this.onChangeWeldingParametersVoltage(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersVoltageMin-" + rowNumber} id={"weldingParametersVoltageMin-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].voltageMin}
                            onChange={(event) => this.onChangeWeldingParametersVoltage(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" /></td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersVoltageMax-" + rowNumber} id={"weldingParametersVoltageMax-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].voltageMax}
                            onChange={(event) => this.onChangeWeldingParametersVoltage(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" /></td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrent}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersCurrent-" + rowNumber} id={"weldingParametersCurrent-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].current}
                            onChange={(event) => this.onChangeWeldingParametersCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrent}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersCurrentMin-" + rowNumber} id={"weldingParametersCurrentMin-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].currentMin}
                            onChange={(event) => this.onChangeWeldingParametersCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" /></td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrent}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersCurrentMax-" + rowNumber} id={"weldingParametersCurrentMax-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].currentMax}
                            onChange={(event) => this.onChangeWeldingParametersCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" /></td>
                    <td hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersTravelSpeed-" + rowNumber} id={"weldingParametersTravelSpeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].travelSpeed}
                            onChange={(event) => this.onChangeWeldingParametersTravelSpeed(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input type="text" className="padding-form-control text-center" bsSize="sm" name={"weldingParametersTravelSpeedMin-" + rowNumber} id={"weldingParametersTravelSpeedMin-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].travelSpeedMin}
                            onChange={(event) => this.onChangeWeldingParametersTravelSpeed(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersTravelSpeedMax-" + rowNumber} id={"weldingParametersTravelSpeedMax-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].travelSpeedMax}
                            onChange={(event) => this.onChangeWeldingParametersTravelSpeed(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" /></td>
                    <td hidden={!this.props.weldingParametersSettings.showHeatInput}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersHeatInput-" + rowNumber} id={"weldingParametersHeatInput-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].heatInput}
                            onChange={(event) => this.onChangeWeldingParametersHeatInput(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showHeatInput}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersHeatInputMin-" + rowNumber} id={"weldingParametersHeatInputMin-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].heatInputMin}
                            onChange={(event) => this.onChangeWeldingParametersHeatInput(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" /></td>
                    <td hidden={!this.props.weldingParametersSettings.showHeatInput}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersHeatInputMax-" + rowNumber} id={"weldingParametersHeatInputMax-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].heatInputMax}
                            onChange={(event) => this.onChangeWeldingParametersHeatInput(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" /></td>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldingParametersTableRowView(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters17" + rowNumber}>
                <tr>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"   name={"weldingParametersRun-" + rowNumber} id={"weldingParametersRun-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].runText}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input disabled type="select" className="padding-form-control-select" bsSize="sm"   name={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber} id={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].weldingMethod.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersWeldingMethods(rowNumber, event)}
                            placeholder="">
                            {this.props.weldingParametersPropertiesView.weldingMethods.map((weldingMethod, index) => (
                                <option key={"weldingMethodBaseViewId" + weldingMethod.id + rowNumber} id={"weldingMethodBaseViewId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showDiameter}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersDiameter-" + rowNumber} id={"weldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].diameter}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showWireFeed}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersWireFeed-" + rowNumber} id={"weldingParametersWireFeed-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].wireFeed}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateTop}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateTop-" + rowNumber} id={"weldingParametersGasFlowRateTop-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].flowRateTop}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateRoot}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateRoot-" + rowNumber} id={"weldingParametersGasFlowRateRoot-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].flowRateRoot}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrentType}>
                        <Input disabled type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingParametersCurrentTypes-" + rowNumber} id={"weldingParametersCurrentTypes-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].currentType.id}
                            onChange={(event) => this.onChangeWeldingParametersCurrentTypes(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="">
                            {this.props.currentTypes.map((currentType, index) => (
                                <option key={"currentTypeBaseViewId" + currentType.id + rowNumber} id={"currentTypeId" + currentType.id} value={currentType.id}>{currentType.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersVoltage-" + rowNumber} id={"weldingParametersVoltage-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].voltage}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersVoltageMin-" + rowNumber} id={"weldingParametersVoltageMin-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].voltageMin}/></td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersVoltageMax-" + rowNumber} id={"weldingParametersVoltageMax-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].voltageMax}/></td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrent}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersCurrent-" + rowNumber} id={"weldingParametersCurrent-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].current} />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrent}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersCurrentMin-" + rowNumber} id={"weldingParametersCurrentMin-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].currentMin}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrent}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersCurrentMax-" + rowNumber} id={"weldingParametersCurrentMax-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].currentMax}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTravelSpeed-" + rowNumber} id={"weldingParametersTravelSpeed-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].travelSpeed}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTravelSpeedMin-" + rowNumber} id={"weldingParametersTravelSpeedMin-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].travelSpeedMin}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTravelSpeedMax-" + rowNumber} id={"weldingParametersTravelSpeedMax-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].travelSpeedMax}/></td>
                    <td hidden={!this.props.weldingParametersSettings.showHeatInput}>
                        <Input disabled  type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersHeatInput-" + rowNumber} id={"weldingParametersHeatInput-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].heatInput}/>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showHeatInput}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersHeatInputMin-" + rowNumber} id={"weldingParametersHeatInputMin-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].heatInputMin}/></td>
                    <td hidden={!this.props.weldingParametersSettings.showHeatInput}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersHeatInputMax-" + rowNumber} id={"weldingParametersHeatInputMax-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].heatInputMax}/></td>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldingArcParametersTableRowView(rowNumber: number) {
        return (
            <React.Fragment key={"WparametersView18" + rowNumber}>
                <tr>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersRun-" + rowNumber} id={"weldingParametersRun-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].runText}
                            onChange={(event) => this.onChangeWeldingParametersRunText(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input disabled type="select" className="padding-form-control-select" bsSize="sm"  name={"arcWeldingParametersWeldingMethods-" + rowNumber} id={"arcWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].weldingMethod.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeArcWeldingParametersWeldingMethods(rowNumber, event)}
                            placeholder="">
                            {this.props.weldingParametersPropertiesView.weldingMethods.map((weldingMethod, index) => (
                                <option key={"weldingMethodIda" + weldingMethod.id + rowNumber} id={"weldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showDiameter}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersDiameter-" + rowNumber} id={"weldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].diameter}
                            onChange={(event) => this.onChangeWeldingParametersDiameter(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showWireFeed}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersWireFeed-" + rowNumber} id={"weldingParametersWireFeed-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].wireFeed}
                            onChange={(event) => this.onChangeWeldingParametersWireFeed(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateTop || this.props.componentPositionProtocol || this.props.componentPositionDetails}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateTop-" + rowNumber} id={"weldingParametersGasFlowRateTop-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].flowRateTop}
                            onChange={(event) => this.onChangeWeldingParametersFlowRateTop(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateRoot || this.props.componentPositionProtocol || this.props.componentPositionDetails}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateRoot-" + rowNumber} id={"weldingParametersGasFlowRateRoot-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].flowRateRoot}
                            onChange={(event) => this.onChangeWeldingParametersFlowRateRoot(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrentType}>
                        <Input disabled type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingParametersCurrentTypes-" + rowNumber} id={"weldingParametersCurrentTypes-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].currentType.id}
                            onChange={(event) => this.onChangeWeldingParametersCurrentTypes(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="">
                            {this.props.currentTypes.map((currentType, index) => (
                                <option key={"currentTypeIda" + currentType.id + rowNumber} id={"currentTypeId" + currentType.id} value={currentType.id}>{currentType.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersVoltage-" + rowNumber} id={"weldingParametersVoltage-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].voltage}
                            onChange={(event) => this.onChangeArcWeldingParametersVoltage(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green">
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersArcIE-" + rowNumber} id={"arcWeldingParametersArcIE-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].arcIE}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeArcWeldingParametersArcIE(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green">
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersArcIP-" + rowNumber} id={"arcWeldingParametersArcIP-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].arcIP}/>
                    </td>
                    <td className="pulse-td-light-green">
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersRunLength-" + rowNumber} id={"arcWeldingParametersRunLength-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].runLength}/>
                    </td>
                    <td hidden={this.props.componentPositionApplication}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersTime-" + rowNumber} id={"weldingProtocolWeldingParametersTime-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].time}/>
                    </td>
                    <td className="pulse-td-dark-green" hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTravelSpeed-" + rowNumber} id={"weldingParametersTravelSpeed-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].travelSpeed} />
                    </td>
                    <td className="pulse-td-light-green">
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersArcEnegyIE-" + rowNumber} id={"arcWeldingParametersArcEnegyIE-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].arcEnergyIE}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green">
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersArcEnegyIP-" + rowNumber} id={"arcWeldingParametersArcEnegyIP-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].arcEnergyIP}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldingArcParametersTableRow(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters18" + rowNumber}>
                <tr>
                    <td scope="row">
                        <Button color="link" size="sm" name={"removeWeldingParametersRow-" + rowNumber} id={"removeWeldingParametersRow-" + rowNumber}
                            onClick={(event) => this.onClickRemoveWeldingParametersRow(rowNumber, event)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                    <td>
                        <Button color="link" size="sm" name={"cloneWeldingParametersRow-" + rowNumber} id={"cloneWeldingParametersRow-" + rowNumber}
                            onClick={(event) => this.onClickCloneWeldingParametersRow(rowNumber, event)}>
                            <i className="fas fa-clone"></i>
                        </Button>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersRun-" + rowNumber} id={"weldingParametersRun-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runText}
                            onChange={(event) => this.onChangeWeldingParametersRunText(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="select" className="padding-form-control-select" bsSize="sm"  name={"arcWeldingParametersWeldingMethods-" + rowNumber} id={"arcWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].weldingMethod.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeArcWeldingParametersWeldingMethods(rowNumber, event)}
                            placeholder="">
                            {this.props.weldingParametersProperties.weldingMethods.map((weldingMethod, index) => (
                                <option key={"weldingMethodIda" + weldingMethod.id + rowNumber} id={"weldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showDiameter}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersDiameter-" + rowNumber} id={"weldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].diameter}
                            onChange={(event) => this.onChangeWeldingParametersDiameter(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showWireFeed}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersWireFeed-" + rowNumber} id={"weldingParametersWireFeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].wireFeed}
                            onChange={(event) => this.onChangeWeldingParametersWireFeed(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateTop || this.props.componentPositionProtocol || this.props.componentPositionDetails}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateTop-" + rowNumber} id={"weldingParametersGasFlowRateTop-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].flowRateTop}
                            onChange={(event) => this.onChangeWeldingParametersFlowRateTop(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateRoot || this.props.componentPositionProtocol || this.props.componentPositionDetails}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateRoot-" + rowNumber} id={"weldingParametersGasFlowRateRoot-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].flowRateRoot}
                            onChange={(event) => this.onChangeWeldingParametersFlowRateRoot(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrentType}>
                        <Input type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingParametersCurrentTypes-" + rowNumber} id={"weldingParametersCurrentTypes-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].currentType.id}
                            onChange={(event) => this.onChangeWeldingParametersCurrentTypes(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="">
                            {this.props.currentTypes.map((currentType, index) => (
                                <option key={"currentTypeIda" + currentType.id + rowNumber} id={"currentTypeId" + currentType.id} value={currentType.id}>{currentType.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersVoltage-" + rowNumber} id={"weldingParametersVoltage-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].voltage}
                            onChange={(event) => this.onChangeArcWeldingParametersVoltage(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green">
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersArcIE-" + rowNumber} id={"arcWeldingParametersArcIE-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].arcIE}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeArcWeldingParametersArcIE(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green">
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersArcIP-" + rowNumber} id={"arcWeldingParametersArcIP-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].arcIP}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeArcWeldingParametersArcIP(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green">
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersRunLength-" + rowNumber} id={"arcWeldingParametersRunLength-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runLength}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeArcWeldingParametersRunLength(rowNumber, this.props.componentPositionApplication, event)}
                            placeholder="" />
                    </td>
                    <td hidden={this.props.componentPositionApplication}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersTime-" + rowNumber} id={"weldingProtocolWeldingParametersTime-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].time}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeArcWeldingParametersTime(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green" hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTravelSpeed-" + rowNumber} id={"weldingParametersTravelSpeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].travelSpeed}
                            onChange={(event) => this.onChangeArcWeldingParametersTravelSpeed(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green">
                        <Input readOnly  type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersArcEnegyIE-" + rowNumber} id={"arcWeldingParametersArcEnegyIE-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].arcEnergyIE}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green">
                        <Input readOnly  type="text" className="padding-form-control" bsSize="sm"  name={"arcWeldingParametersArcEnegyIP-" + rowNumber} id={"arcWeldingParametersArcEnegyIP-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].arcEnergyIP}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderWeldingGwParametersTableRow(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters18Gw" + rowNumber}>
                <tr>
                    <td scope="row">
                        <Button color="link" size="sm" name={"removeWeldingParametersRow-" + rowNumber} id={"removeWeldingParametersRow-" + rowNumber}
                            onClick={(event) => this.onClickRemoveWeldingParametersRow(rowNumber, event)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                    <td>
                        <Button color="link" size="sm" name={"cloneWeldingParametersRow-" + rowNumber} id={"cloneWeldingParametersRow-" + rowNumber}
                            onClick={(event) => this.onClickCloneWeldingParametersRow(rowNumber, event)}>
                            <i className="fas fa-clone"></i>
                        </Button>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersRun-" + rowNumber} id={"weldingParametersRun-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runText}
                            onChange={(event) => this.onChangeWeldingParametersRunText(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="select" className="padding-form-control-select" bsSize="sm" name={"arcWeldingParametersWeldingMethods-" + rowNumber} id={"arcWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].weldingMethod.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeArcWeldingParametersWeldingMethods(rowNumber, event)}
                            placeholder="">
                            {this.props.weldingParametersProperties.weldingMethods.map((weldingMethod, index) => (
                                <option key={"weldingMethodIda" + weldingMethod.id + rowNumber} id={"weldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showDiameter}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersDiameter-" + rowNumber} id={"weldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].diameter}
                            onChange={(event) => this.onChangeWeldingParametersDiameter(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                       <DropdownLists value={this.props.weldingParameters[rowNumber - 1].weldingTecknique} listNumber={17} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setWeldingParametersTecknique} rowNumber={rowNumber} ></DropdownLists>
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersFuelGasPressure-" + rowNumber} id={"weldingParametersFuelGasPressure-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].fuelGasPressure}
                            onChange={(event) => this.onChangeWeldingParametersFuelGasPressure(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersOxygenPressure-" + rowNumber} id={"weldingParametersOxygenPressure-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].oxygenPressure}
                            onChange={(event) => this.onChangeWeldingParametersOxygenPressure(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <DropdownLists value={this.props.weldingParameters[rowNumber - 1].typeOfFlame} listNumber={18} dropDownListsCallback={this.onClickSetText} dropDownListsCallbackRow={this.props.setWeldingParametersTypeOfFlame} rowNumber={rowNumber} ></DropdownLists>
                    </td>
                    <td hidden={this.props.componentPositionApplication}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersInterpassTemperature-" + rowNumber} id={"weldingParametersInterpassTemperature-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].interpassTemperature}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersInterpassTemperature(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td hidden={this.props.componentPositionApplication}>
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersRunWidth-" + rowNumber} id={"weldingParametersRunWidth-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runWidth}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersRunWidth(rowNumber, event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldingGwParametersTableRowView(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters18Gw" + rowNumber}>
                <tr>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersRun-" + rowNumber} id={"weldingParametersRun-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].runText}
                            placeholder="" />
                    </td>
                    <td>
                        <Input disabled type="select" className="padding-form-control-select" bsSize="sm" name={"arcWeldingParametersWeldingMethods-" + rowNumber} id={"arcWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].weldingMethod.id}
                            placeholder="">
                            {this.props.weldingParametersProperties.weldingMethods.map((weldingMethod, index) => (
                                <option key={"weldingMethodIda" + weldingMethod.id + rowNumber} id={"weldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showDiameter}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersDiameter-" + rowNumber} id={"weldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].diameter}
                            placeholder="" />
                    </td>
                    <td>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersWeldingTecknique-" + rowNumber} id={"weldingParametersWeldingTecknique-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].weldingTecknique}
                            placeholder="" />
                    </td>
                    <td>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersFuelGasPressure-" + rowNumber} id={"weldingParametersFuelGasPressure-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].fuelGasPressure}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateRoot || this.props.componentPositionProtocol || this.props.componentPositionDetails}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersOxygenPressure-" + rowNumber} id={"weldingParametersOxygenPressure-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].oxygenPressure}
                            placeholder="" />
                    </td>
                    <td>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersTypeOfFlam-" + rowNumber} id={"weldingParametersTypeOfFlam-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].typeOfFlame}
                            placeholder="">
                        </Input>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldingPulseParametersTable1Row(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters19" + rowNumber}>
                <tr>
                    <td scope="row">
                        <Button color="link" size="sm" name={"removeWeldingParametersRow-" + rowNumber} id={"removeWeldingParametersRow-" + rowNumber}
                            onClick={(event) => this.onClickRemoveWeldingParametersRow(rowNumber, event)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                    <td>
                        <Button color="link" size="sm" name={"cloneWeldingParametersRow-" + rowNumber} id={"cloneWeldingParametersRow-" + rowNumber}
                            onClick={(event) => this.onClickCloneWeldingParametersRow(rowNumber, event)}>
                            <i className="fas fa-clone"></i>
                        </Button>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersRun-" + rowNumber} id={"weldingParametersRun-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runText}
                            onChange={(event) => this.onChangeWeldingParametersRunText(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber} id={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].weldingMethod.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingProtocolWeldingParametersWeldingMethods(rowNumber, event)}
                            placeholder="">
                            {this.props.weldingParametersProperties.weldingMethods.map((weldingMethod, index) => (
                                <option key={"weldingMethodIdp" + weldingMethod.id + rowNumber}  id={"weldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showDiameter}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersDiameter-" + rowNumber} id={"weldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].diameter}
                            onChange={(event) => this.onChangeWeldingParametersDiameter(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showWireFeed}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersWireFeed-" + rowNumber} id={"weldingParametersWireFeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].wireFeed}
                            onChange={(event) => this.onChangeWeldingParametersWireFeed(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateTop || this.props.componentPositionProtocol || this.props.componentPositionDetails}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateTop-" + rowNumber} id={"weldingParametersGasFlowRateTop-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].flowRateTop}
                            onChange={(event) => this.onChangeWeldingParametersFlowRateTop(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateRoot || this.props.componentPositionProtocol || this.props.componentPositionDetails}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateRoot-" + rowNumber} id={"weldingParametersGasFlowRateRoot-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].flowRateRoot}
                            onChange={(event) => this.onChangeWeldingParametersFlowRateRoot(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrentType}>
                        <Input type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingParametersCurrentTypes-" + rowNumber} id={"weldingParametersCurrentTypes-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].currentType.id}
                            onChange={(event) => this.onChangeWeldingParametersCurrentTypes(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="">
                            {this.props.currentTypes.map((currentType, index) => (
                                <option key={"currentTypeIdp" + currentType.id + rowNumber}  id={"currentTypeId" + currentType.id} value={currentType.id}>{currentType.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersVoltage-" + rowNumber} id={"weldingParametersVoltage-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].voltage}
                            onChange={(event) => this.onChangeWeldingParametersVoltagePulse(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td hidden={this.props.componentPositionApplication}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersRunLength-" + rowNumber} id={"weldingProtocolWeldingParametersRunLength-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runLength}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingProtocolWeldingParametersRunLengthPulse(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td hidden={this.props.componentPositionApplication}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersTime-" + rowNumber} id={"weldingProtocolWeldingParametersTime-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].time}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingProtocolWeldingParametersTimePulse(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTravelSpeed-" + rowNumber} id={"weldingParametersTravelSpeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].travelSpeed}
                            onChange={(event) => this.onChangeWeldingParametersTravelSpeedPulse(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldingPulseParametersTable1RowView(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters20" + rowNumber}>
                <tr>
                    <td hidden={!this.props.weldingParametersSettings.showRun}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersRun-" + rowNumber} id={"weldingParametersRun-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].runText}
                            placeholder="" />
                    </td>
                    <td>
                        <Input disabled type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber} id={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].weldingMethod.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingProtocolWeldingParametersWeldingMethods(rowNumber, event)}
                            placeholder="">
                            {this.props.weldingParametersPropertiesView.weldingMethods.map((weldingMethod, index) => (
                                <option key={"viewWeldingMethodIdpw" + weldingMethod.id + rowNumber} id={"viewWeldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showDiameter}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersDiameter-" + rowNumber} id={"weldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].diameter}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showWireFeed}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersWireFeed-" + rowNumber} id={"weldingParametersWireFeed-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].wireFeed}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateTop || this.props.componentPositionProtocol || this.props.componentPositionDetails}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateTop-" + rowNumber} id={"weldingParametersGasFlowRateTop-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].flowRateTop}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showFlowRateRoot || this.props.componentPositionProtocol || this.props.componentPositionDetails}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersGasFlowRateRoot-" + rowNumber} id={"weldingParametersGasFlowRateRoot-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].flowRateRoot}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showCurrentType}>
                        <Input disabled type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingParametersCurrentTypes-" + rowNumber} id={"weldingParametersCurrentTypes-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].currentType.id}
                            onChange={(event) => this.onChangeWeldingParametersCurrentTypes(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="">
                            {this.props.currentTypes.map((currentType, index) => (
                                <option key={"currentTypeIdpw" + currentType.id + rowNumber} id={"currentTypeId" + currentType.id} value={currentType.id}>{this.TranslateBoxes(currentType.name, SweToEngCurrentType)}</option>
                            ))}
                        </Input>
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showVoltage}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersVoltage-" + rowNumber} id={"weldingParametersVoltage-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].voltage}
                            placeholder="" />
                    </td>
                    <td hidden={this.props.componentPositionApplication}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersRunLength-" + rowNumber} id={"weldingProtocolWeldingParametersRunLength-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].runLength}
                            placeholder="" />
                    </td>
                    <td hidden={this.props.componentPositionApplication}>
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersTime-" + rowNumber} id={"weldingProtocolWeldingParametersTime-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].time}
                            placeholder="" />
                    </td>
                    <td hidden={!this.props.weldingParametersSettings.showTravelSpeed}>
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTravelSpeed-" + rowNumber} id={"weldingParametersTravelSpeed-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].travelSpeed}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldingPulseParametersTable2Row(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters21" + rowNumber}>
                <tr>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersPulseCurrent-" + rowNumber} id={"weldingParametersPulseCurrent-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].pulseCurrent}
                            onChange={(event) => this.onChangeWeldingParametersPulseCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersPulseTime-" + rowNumber} id={"weldingParametersPulseTime-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].pulseTime}
                            onChange={(event) => this.onChangeWeldingParametersPulseTime(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersBasicCurrent-" + rowNumber} id={"weldingParametersBasicCurrent-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].basicCurrent}
                            onChange={(event) => this.onChangeWeldingParametersBasicCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green">
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTimeForBasicCurrent-" + rowNumber} id={"weldingParametersTimeForBasicCurrent-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].timeForBasicCurrent}
                            onChange={(event) => this.onChangeWeldingParametersTimeForBasicCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green">
                        <Input type="text" className="padding-form-control" bsSize="sm" name={"weldingParametersFrequency-" + rowNumber} id={"weldingParametersFrequency-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].frequency}
                            onChange={(event) => this.onChangeWeldingParametersFrequency(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green">
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersCurrent-" + rowNumber} id={"weldingParametersCurrent-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].current}
                            onChange={(event) => this.onChangeWeldingParametersCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green">
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersHeatInput-" + rowNumber} id={"weldingParametersHeatInput-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].heatInput}
                            onChange={(event) => this.onChangeWeldingParametersHeatInput(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green">
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTime1-" + rowNumber} id={"weldingParametersTime1-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].time1}
                            onChange={(event) => this.onChangeWeldingParametersTime1(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green">
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersCurrent1-" + rowNumber} id={"weldingParametersCurrent1-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].current1}
                            onChange={(event) => this.onChangeWeldingParametersCurrent1(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green">
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersHeatInput-" + rowNumber} id={"weldingParametersHeatInput1-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].heatInput1}
                            onChange={(event) => this.onChangeWeldingParametersHeatInput1(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderWeldingPulseParametersTable2RowView(rowNumber: number) {
        return (
            <React.Fragment key={"WparametersView22" + rowNumber}>
                <tr>
                    <td >
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersPulseCurrent-" + rowNumber} id={"weldingParametersPulseCurrent-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].pulseCurrent}
                            onChange={(event) => this.onChangeWeldingParametersPulseCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td >
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersPulseTime-" + rowNumber} id={"weldingParametersPulseTime-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].pulseTime}
                            onChange={(event) => this.onChangeWeldingParametersPulseTime(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td >
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersBasicCurrent-" + rowNumber} id={"weldingParametersBasicCurrent-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].basicCurrent}
                            onChange={(event) => this.onChangeWeldingParametersBasicCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green" >
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTimeForBasicCurrent-" + rowNumber} id={"weldingParametersTimeForBasicCurrent-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].timeForBasicCurrent}
                            onChange={(event) => this.onChangeWeldingParametersTimeForBasicCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green" >
                        <Input disabled type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTravelSpeed-" + rowNumber} id={"weldingParametersTravelSpeed-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].frequency}
                            onChange={(event) => this.onChangeWeldingParametersFrequency(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green" >
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersCurrent-" + rowNumber} id={"weldingParametersCurrent-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].current}
                            onChange={(event) => this.onChangeWeldingParametersCurrent(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-dark-green" >
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersHeatInput-" + rowNumber} id={"weldingParametersHeatInput-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].heatInput}
                            onChange={(event) => this.onChangeWeldingParametersHeatInput(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green" >
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersTime1-" + rowNumber} id={"weldingParametersTime1-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].time1}
                            onChange={(event) => this.onChangeWeldingParametersTime1(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green" >
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersCurrent1-" + rowNumber} id={"weldingParametersCurrent1-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].current1}
                            onChange={(event) => this.onChangeWeldingParametersCurrent1(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td className="pulse-td-light-green" >
                        <Input readOnly type="text" className="padding-form-control" bsSize="sm"  name={"weldingParametersHeatInput-" + rowNumber} id={"weldingParametersHeatInput1-" + rowNumber}
                            value={this.props.weldingParametersView[rowNumber - 1].heatInput1}
                            onChange={(event) => this.onChangeWeldingParametersHeatInput1(rowNumber, event)}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private renderWeldingParametersTableHeaderRowDetails() {
        return (
            <React.Fragment key="Wparameters23">
                <tr>
                    <th className="text-nowrap"><a>{t('String')}</a></th>
                    <th className="text-nowrap"><a>{t('WeldingMethod')}</a></th>
                    <th className="text-nowrap"><a>{t('Electrode')} Ø (mm)</a></th>
                    <th className="text-nowrap"><a>{t('WireFeed')} (m/min)</a></th>
                    <th className="text-nowrap"><a>{t('CurrentType')}</a></th>
                    <th className="text-nowrap"><a>{t('Current')} (A)</a></th>
                    <th className="text-nowrap"><a>{t('Voltage')} (V)</a></th>
                    <th className="text-nowrap"><a>{t('Speed')} (mm/min)</a></th>
                    <th className="text-nowrap"><a>{t('HeatSupply')} (kJ/mm)</a></th>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldingParametersTableRowDetails(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters24" + rowNumber}>
                <tr>
                    <td scope="row">
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersRun-" + rowNumber} id={"weldingProtocolWeldingParametersRun-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runText}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersRunText(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber} id={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].weldingMethod.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingProtocolWeldingParametersWeldingMethods(rowNumber, event)}
                            placeholder="">
                            {this.props.weldingParametersProperties.weldingMethods.map((weldingMethod, index) => (
                                <option key={"weldingMethodId" + weldingMethod.id + rowNumber}  id={"weldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersDiameter-" + rowNumber} id={"weldingProtocolWeldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].diameter}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersDiameter(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersWireFeed-" + rowNumber} id={"weldingProtocolWeldingParametersWireFeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].wireFeed}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersWireFeed(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingProtocolWeldingParametersCurrentTypes-" + rowNumber} id={"weldingProtocolWeldingParametersCurrentTypes-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].currentType.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersCurrentTypes(rowNumber, event)}
                            placeholder="">
                            {this.props.currentTypes.map((currentType, index) => (
                                <option key={"currentTypeIdl" + currentType.id + rowNumber} id={"currentTypeId" + currentType.id} value={currentType.id}>{currentType.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersCurrent-" + rowNumber} id={"weldingProtocolWeldingParametersCurrent-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].current}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersCurrent(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersVoltage-" + rowNumber} id={"weldingProtocolWeldingParametersVoltage-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].voltage}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersVoltage(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" readOnly className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersTravelSpeed-" + rowNumber} id={"weldingProtocolWeldingParametersTravelSpeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].travelSpeed}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" readOnly className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersHeatInput-" + rowNumber} id={"weldingProtocolWeldingParametersHeatInput-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].heatInput}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldingBaseParametersTable() {
        return (
            <React.Fragment key="Wparameters5">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingProtocolBaseWeldingParametersTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.weldingParameters.map((row, index) => (
                            this.renderWeldingProtocolBaseWeldingParametersTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderWeldingArcParametersTable() {
        return (
            <React.Fragment key="Wparameters26">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingArcParametersTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.weldingParameters.map((row, index) => (
                            this.renderWeldingArcParametersTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderWeldingArcParametersTableView() {
        return (
            <React.Fragment key="WparametersView26">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingArcParametersTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.weldingParametersView.map((row, index) => (
                            this.renderWeldingArcParametersTableRowView(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderWeldinGwParametersTable() {
        return (
            <React.Fragment key="Wparameters26Gw">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingGwParametersTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.weldingParameters.map((row, index) => (
                            this.renderWeldingGwParametersTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderWeldingGwParametersTableView() {
        return (
            <React.Fragment key="WparametersView26">
                <Table size="sm" responsive className="cert-table table-padding">
                    <thead>
                        {this.renderWeldingGwParametersTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.weldingParametersView.map((row, index) => (
                            this.renderWeldingGwParametersTableRowView(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderWeldingProtocolBaseWeldingParametersTableHeaderRow() {
        return (
            <React.Fragment key="Wparameters27">
                <tr>
                    <th colSpan={2}></th>
                    <th><a>{t('String')}</a></th>
                    <th><a>{t('WeldingMethod')}</a></th>
                    <th><a>{t('ElectrodeDiameter')} Ø (mm)</a></th>
                    <th><a>{t('WireFeed')} (m/min)</a></th>
                    <th><a>{t('CurrentType')}</a></th>
                    <th><a>Strömstyrka (A)</a></th>
                    <th><a>{t('Voltage')} (V)</a></th>
                    <th><a>Stränglängd (mm)</a></th>
                    <th><a>Tid (s)</a></th>
                    <th><a>Framförings-</a><br></br><a>hastighet (mm/min)</a></th>
                    <th><a>Mellansträngstemp °C</a></th>
                    <th><a>Strängbredd (mm)</a></th>
                    <th><a>{t('HeatInput')} (kJ/mm)</a></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderWeldingProtocolBaseWeldingParametersTableRow(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters28" + rowNumber}>
                <tr>
                    <td scope="row">
                    <Button color="link" size="sm" name={"removeWeldingParametersRow-" + rowNumber} id={"removeWeldingParametersRow-" + rowNumber}
                        onClick={(event) => this.onClickRemoveWeldingParametersRow(rowNumber, event)}>
                        <i className="fas fa-trash"></i>
                    </Button>
                    </td>
                    <td>
                        <Button color="link" size="sm" name={"cloneWeldingParametersRow-" + rowNumber} id={"cloneWeldingParametersRow-" + rowNumber}
                            onClick={(event) => this.onClickCloneWeldingParametersRow(rowNumber, event)}>
                            <i className="fas fa-clone"></i>
                        </Button>
                    </td>
                    <td scope="row">
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersRun-" + rowNumber} id={"weldingProtocolWeldingParametersRun-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runText}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersRunText(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber} id={"weldingProtocolWeldingParametersWeldingMethods-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].weldingMethod.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersWeldingMethods(rowNumber, event)}
                            placeholder="">
                            {this.props.weldingParametersProperties.weldingMethods.map((weldingMethod, index) => (
                                <option key={"weldingMethodId" + weldingMethod.id + rowNumber} value={weldingMethod.id}>{weldingMethod.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersDiameter-" + rowNumber} id={"weldingProtocolWeldingParametersDiameter-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].diameter}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersDiameter(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersWireFeed-" + rowNumber} id={"weldingProtocolWeldingParametersWireFeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].wireFeed}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersWireFeed(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="select" className="padding-form-control-select" bsSize="sm"  name={"weldingProtocolWeldingParametersCurrentTypes-" + rowNumber} id={"weldingProtocolWeldingParametersCurrentTypes-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].currentType.id}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersCurrentTypes(rowNumber, event)}
                            placeholder="">
                            {this.props.currentTypes.map((currentType, index) => (
                                <option key={"currentTypeIdb" + currentType.id + rowNumber} value={currentType.id}>{currentType.name}</option>
                            ))}
                        </Input>
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersCurrent-" + rowNumber} id={"weldingProtocolWeldingParametersCurrent-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].current}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersCurrent(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersVoltage-" + rowNumber} id={"weldingProtocolWeldingParametersVoltage-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].voltage}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersVoltage(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersRunLength-" + rowNumber} id={"weldingProtocolWeldingParametersRunLength-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runLength}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingProtocolWeldingParametersRunLength(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersTime-" + rowNumber} id={"weldingProtocolWeldingParametersTime-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].time}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingProtocolWeldingParametersTime(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" readOnly className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersTravelSpeed-" + rowNumber} id={"weldingProtocolWeldingParametersTravelSpeed-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].travelSpeed}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersInterpassTemperature-" + rowNumber} id={"weldingProtocolWeldingParametersInterpassTemperature-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].interpassTemperature}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersInterpassTemperature(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersRunWidth-" + rowNumber} id={"weldingProtocolWeldingParametersRunWidth-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].runWidth}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            onChange={(event) => this.onChangeWeldingParametersRunWidth(rowNumber, event)}
                            placeholder="" />
                    </td>
                    <td>
                        <Input type="text" readOnly className="padding-form-control" bsSize="sm"  name={"weldingProtocolWeldingParametersHeatInput-" + rowNumber} id={"weldingProtocolWeldingParametersHeatInput-" + rowNumber}
                            value={this.props.weldingParameters[rowNumber - 1].heatInput}
                            onKeyPress={(event) => this.onKeyPress(event)}
                            placeholder="" />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.wParameters;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    WParametersStore.actionCreators // Selects which action creators are merged into the component's props
)(WParameters as any);
