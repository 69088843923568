﻿
import * as React from 'react';
import { Connect, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, Col, Collapse, Row, FormGroup, CardTitle, InputGroup, InputGroupAddon, Input, InputGroupText, Table } from 'reactstrap';
import { ApplicationState } from '../store/index';
import { t } from '../i18n';
import Loader from './Loader';
import * as NewsStore from '../store/NewsStore';
import { News } from '../store/models/News';
import EditNewsListModal from './EditNewsListModal';

// At runtime, Redux will merge together...
type NewsListProps =
    NewsStore.NewsState // ... state we've requested from the Redux store
    & typeof NewsStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>

class NewsList extends React.PureComponent<NewsListProps> {
    constructor(props: NewsListProps) {
        super(props);
    }

    public state = {
        wpqrCompleteIsOpen: true,
        updateTestResultsExamination: false,
        updateRangeOfqualification: false,
        updateWPSFormEdit: 0,
        revisionNumber: '',
        customers: [],
        showDeletionModal: false,
        createNewNews: false
    };

    public componentDidMount() {
        this.props.setSelectedLanguage(localStorage.getItem('lang'));
        this.props.receiveNews(localStorage.getItem('lang'));
    }

    componentDidUpdate(prevProps: any) {
    }

    private onChangeFreeSearch(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setSearchTerm(event.currentTarget.value);
        this.props.GetNewsSearchResult();
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public setDeletionModalOpen(open: boolean) {
        this.setState({ showDeletionModal: open });
    }

    private addModalClick() {
        this.setState({ createNewNews: true });
        this.props.toggleEditModal();
    }

    private onRemoveNewsPress(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newsId: number) {
        event.stopPropagation();
        this.props.removeNews(newsId, () => { this.props.receiveNewsUnsorted() });
    }

    private removeNews() {
        this.props.removeNews(this.props.chosenNews.id, () => { this.props.receiveNewsUnsorted() });
    }

    private onEditNewsPress(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newsId: number) {
        event.stopPropagation();
        this.setState({ createNewNews: false, });
        this.props.setChosenNews(this.props.newsList.find(news => news.id === newsId));
        this.props.toggleEditModal();
    }

    private renderTableHeaderRow() {
        return (
            <React.Fragment key={"renderTableHeaderRow"}>
                <tr>
                    <th className="thinCol"><a></a></th>
                    <th>
                        <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('Title')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                    </th>
                    <th>
                        <a>{t('Content')}</a>
                    </th>
                    <th className="thinCol"><a></a></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderTableRow(news: News, index: number) {
        return (
            <React.Fragment key={"renderTableRow" + index}>
                <tr key={news.id}>
                    <td>
                        <Button color="link" size="sm" name={"newsEditBtn-" + index} id={"newsEditBtn-" + index}
                            onClick={(event) => this.onEditNewsPress(event, news.id)}>
                            <i className="fas fa-pen"></i>
                        </Button>
                    </td>
                    <td>
                        {news.title}
                    </td>
                    <td>
                        {news.content}
                    </td>
                    <td>
                        <Button color="link" size="sm" name={"userRmBtn-" + index} id={"userRmBtn-" + index}
                            onClick={(event) => this.onRemoveNewsPress(event, news.id)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
    public render() {
        return (
            <React.Fragment>
                <div className="dekra-content-wrapper dekra-wrapper" >
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <Collapse isOpen={this.state.wpqrCompleteIsOpen}>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Card className="card-border-dekra">
                                                        <CardBody>
                                                            <CardTitle>
                                                            </CardTitle>
                                                            <Row form>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <InputGroup>
                                                                            <Button onClick={() => this.addModalClick()} color="primary">
                                                                                {t('Add')}
                                                                            </Button>
                                                                            <div style={{ display: "inline", marginRight: "10px" }} />
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input type="text" bsSize="sm" name="freeSearch" style={{ height: "33px" }}
                                                                                value={this.props.searchTerm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFreeSearch(event)}
                                                                                placeholder={t('Search')} />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Card>
                                                        <CardTitle>
                                                            <Row form>
                                                                <Col sm={4}>
                                                                </Col>
                                                                <Col sm={4}>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <FormGroup>
                                                                        {/*<Button className={"dekra2-table-settings-btn"} color="link" size="sm" name={"tabSettingButton"} onClick={(event) => this.onClickTabSettingModalOpen(event)}>*/}
                                                                        {/*    <i className={"fas fa-cog"}></i>*/}
                                                                        {/*</Button>*/}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardTitle>
                                                        <Row form>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <Table size="sm" className="cert-table table-striped" responsive={true}>
                                                                        <thead>
                                                                            {this.renderTableHeaderRow()}
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.props.searchTerm.length > 0 ?
                                                                                    this.props.searchResult.map((news, index) => (
                                                                                        this.renderTableRow(news, index)
                                                                                    ))
                                                                                    :
                                                                                    this.props.newsList.map((news, index) => (
                                                                                        this.renderTableRow(news, index)
                                                                                    ))
                                                                            }
                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr>
                                                                                <td colSpan={14}>
                                                                                    {/*this.renderPagination()*/}
                                                                                </td>
                                                                            </tr>
                                                                        </tfoot>
                                                                    </Table>
                                                                    <EditNewsListModal
                                                                        show={this.props.editModalOpen}
                                                                        toggleShow={this.props.toggleEditModal}
                                                                        chosenNews={this.props.chosenNews}
                                                                        setChosenNews={this.props.setChosenNews}
                                                                        customers={this.state.customers}
                                                                        creatingNewNews={this.state.createNewNews}
                                                                    />
                                                                    <Loader show={this.props.currentlyLoading} title={t('FetchingNews')} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Collapse>
                                    <Row>
                                        <Col sm={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.news, // Selects which state properties are merged into the component's props
    NewsStore.actionCreators // Selects which action creators are merged into the component's props
)(NewsList as any);