﻿export class RangeOfQualifications {
    arcEnergy: string;
    fillerMaterialDesignation: string;
    fillerMaterialMake: string;
    heatInput: string;
    interpassTemperature: string;
    jointType: string;
    modeOfMetalTransfer: string;
    numberOfRuns: string;
    otherInformation: string;
    outsidePipeDiameter: string; 
    parentMaterialGroup: string;
    parentMaterialThickness: string;
    postHeating: string;
    postWeldHeatTreatmentAndOrAgeing: string;
    preheatTemperature: string;
    rootProtectionGasDesignation: string;
    shieldingGasDesignation: string;
    throatThickness: string;
    typeOfWeldingCurrentAndPolarity: string;
    weldingMetalThickness: string;
    weldingMethods: Array<string>;
    weldingPositions: string;
    
    public constructor() {
        this.arcEnergy = '';
        this.fillerMaterialDesignation = '';
        this.fillerMaterialMake = '';
        this.heatInput = '';
        this.interpassTemperature = '';
        this.jointType = '';
        this.modeOfMetalTransfer = '';
        this.numberOfRuns = '';
        this.otherInformation = '';
        this.outsidePipeDiameter = '';
        this.parentMaterialGroup = '';
        this.parentMaterialThickness = '';
        this.postHeating = '';
        this.postWeldHeatTreatmentAndOrAgeing = '';
        this.preheatTemperature = '';
        this.rootProtectionGasDesignation = '';
        this.shieldingGasDesignation = '';
        this.throatThickness = '';
        this.typeOfWeldingCurrentAndPolarity = '';
        this.weldingMetalThickness = '';
        this.weldingMethods = new Array<string>();
        this.weldingPositions = '';
    }
}