﻿export class GetTemperatureResponse {
    createdAt: string;
    deletedAt: string;
    id: number;
    maxInterpassTemperature: number;
    preheatTemperature: number;
    updatedAt: string;

    public constructor() {
        this.createdAt = '';
        this.deletedAt = '';
        this.id = 0;
        this.maxInterpassTemperature = 0.0;
        this.preheatTemperature = 0.0;
        this.updatedAt = '';
    }
}