﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { UserListObject } from './UserListObject';
import authHeader from './models/auth-header';
import { UpdateUserRequest } from './models/requests/UpdateUserRequest';
import { CreateUserRequest } from './models/requests/CreateUserRequest';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserListState {
    users: Array<UserListObject>;
    searchResult: Array<UserListObject>;
    chosenUser: UserListObject;
    searchTerm: string;
    paginationPageIndex: number;
    paginationPageSize: number;
    editModalOpen: boolean;
    currentlyLoading: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetLoading {
    type: 'SET_LOADING';
    currentlyLoading: boolean;
}

export interface SetSettings {
    type: 'SET_SETTINGS';
    name: string;
}

export interface SetUserList {
    type: 'SET_USER_LIST';
    userList: Array<UserListObject>
}

export interface ToggleEditModal {
    type: 'TOGGLE_USER_EDIT_MODAL_OPEN';
    editModalOpen: boolean;
}

export interface SetPaginationPageIndexAction {
    type: 'SET_PAGINATION_PAGE_INDEX';
    paginationPageIndex: number;
}

export interface SetSearchTerm {
    type: 'SET_SEARCH_TERM';
    searchTerm: string;
}

export interface SetChosenUser {
    type: 'SET_CHOSEN_USER';
    chosenUser: UserListObject;
}

export interface GetUserSearchResult {
    type: 'SET_USER_SEARCH_RESULT';
    searchResult: Array<UserListObject>;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    SetSettings
    | SetPaginationPageIndexAction
    | SetSearchTerm
    | GetUserSearchResult
    | SetUserList
    | ToggleEditModal
    | SetChosenUser
    | SetLoading

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    initForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {

        }
    },
    currentUser: (): AppThunkAction<KnownAction> => (dispatch) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/current/`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('user');
                        localStorage.removeItem('userId');
                        localStorage.removeItem('customerId');

                        //return dispatch({ type: 'LOGOUT' });
                    }

                    //return dispatch({ type: 'CHECK_TOKEN_ACTION' });
                }))
                .catch(error => {

                });
        }
        else {
            //return dispatch({ type: 'LOGOUT' });
        }
    },
    GetUserSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userList) {
            let newUserList = [];
            let searchTerm = appState.userList!.searchTerm.toLowerCase();
            let users: Array<UserListObject> = appState.userList!.users;

            for (let i = 0; i < users.length; i++) {
                if (searchTerm.length > -1) {
                    if (
                        users[i].firstName?.toLowerCase().search(searchTerm) > -1
                        || users[i].lastName?.toLowerCase().search(searchTerm) > -1
                        || users[i].email?.toLowerCase().search(searchTerm) > -1
                        || users[i].userName?.toLowerCase().search(searchTerm) > -1
                        || users[i].customer?.name.toLowerCase().search(searchTerm) > -1
                        || users[i].roles[0]?.toLowerCase().search(searchTerm) > -1
                        || users[i].mobilePhoneNumber?.toLowerCase().split(" ").join("").search(searchTerm) > -1
                        || users[i].phoneNumber?.toLowerCase().split(" ").join("").search(searchTerm) > -1
                        || users[i].title?.toLowerCase().search(searchTerm) > -1
                    ) {
                        newUserList.push(users[i]);
                    }
                }
            }

            dispatch({ type: 'SET_USER_SEARCH_RESULT', searchResult: newUserList });
        }
    },
    removeUser: (userId: string, callback: Function): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.userList) {
            fetch(`/api/User/DeleteById/${userId}`, {
                method: 'DELETE',
                headers: authHeader.authHeader(),
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (callback !== null) {
                        callback();
                    }
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    resetSearchResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userList) {
            let users: Array<UserListObject> = appState.userList!.users;

            dispatch({ type: 'SET_USER_SEARCH_RESULT', searchResult: users });
        }
    },
    setChosenUser: (user: UserListObject): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_CHOSEN_USER', chosenUser: user });
    },
    setSearchTerm: (searchTerm: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const newTerm = searchTerm;
        dispatch({ type: 'SET_SEARCH_TERM', searchTerm: newTerm });
    },
    setSettings: (name: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SETTINGS', name: name });
    },
    toggleEditModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.applicationTab) {
            let editModalOpen = appState.userList?.editModalOpen;
            dispatch({ type: 'TOGGLE_USER_EDIT_MODAL_OPEN', editModalOpen: !editModalOpen });
        }
    },
    setUserInUserList: (newUser: UserListObject): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const users = appState.userList!.users;
        let userToUpdate = users.findIndex(user => user.userName == newUser.userName)
        users[userToUpdate] = newUser;

        dispatch({
            type: 'SET_USER_LIST',
            userList: users
        });
    },
    receiveUserList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }
        dispatch({ type: 'SET_LOADING', currentlyLoading: true });
        if (tokenStr) {
            fetch(`/api/User/GetAllOrderBy/firstname`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    dispatch({ type: 'SET_USER_LIST', userList: res.data });
                    dispatch({ type: 'SET_LOADING', currentlyLoading: false });
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    receiveUserListUnsorted: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }
        dispatch({ type: 'SET_LOADING', currentlyLoading: true });
        if (tokenStr) {
            fetch(`/api/User/GetAll/`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    dispatch({ type: 'SET_USER_LIST', userList: res.data });
                    dispatch({ type: 'SET_LOADING', currentlyLoading: false });
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    updateUser: (incomingUser: UpdateUserRequest, callback: Function): AppThunkAction<KnownAction> => () => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/Update/`, {
                method: 'PUT',
                headers: authHeader.authHeader(),
                body: JSON.stringify(incomingUser)
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (callback !== null) {
                        callback();
                    }
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    addNewUser: (incomingUser: CreateUserRequest, callback: Function): AppThunkAction<KnownAction> => () => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/Create/`, {
                method: 'POST',
                headers: authHeader.authHeader(),
                body: JSON.stringify(incomingUser)
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (callback !== null) {
                        callback();
                    }
                }))
                .catch(error => {
                    console.log('error');
                    console.log(error);
                });
        }
    },
    setPaginationPageIndex: (pageIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wpqrComplete) {
            let paginationPageIndex = pageIndex
            dispatch({ type: 'SET_PAGINATION_PAGE_INDEX', paginationPageIndex: paginationPageIndex });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

let value = localStorage.getItem('token');

const unloadedState: UserListState =
{
    users: [],
    paginationPageIndex: 0,
    paginationPageSize: 0,
    searchResult: [],
    searchTerm: '',
    editModalOpen: false,
    chosenUser: {
        userName: '',
        firstName: '',
        lastName: '',
        displayName: '',

        dekraContact: null,
        dekraContactId: '',

        title: '',
        email: '',

        phoneNumber: '',
        mobilePhoneNumber: '',
        phoneExchangeNumber: '',
        roles: [],

        visitingCity: '',
        visitingZipcode: '',
        visitingStreet: '',

        postCity: '',
        postZipcode: '',
        postStreet: '',

        customer: null,
        customerId: '',
        website: '',
        id: '',
        signatureLogo: '',
    },
    currentlyLoading: false
};

export const reducer: Reducer<UserListState> = (state: UserListState | undefined, incomingAction: Action): UserListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_LOADING':
            return {
                users: state.users,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                chosenUser: state.chosenUser,
                currentlyLoading: action.currentlyLoading
            }
        case 'SET_SETTINGS':
            return {
                users: state.users,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                chosenUser: state.chosenUser,
                currentlyLoading: state.currentlyLoading
            }
        case 'TOGGLE_USER_EDIT_MODAL_OPEN':
            return {
                users: state.users,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: action.editModalOpen,
                chosenUser: state.chosenUser,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_USER_LIST':
            return {
                users: action.userList,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                chosenUser: state.chosenUser,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_PAGINATION_PAGE_INDEX':
            return {
                users: state.users,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: action.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                chosenUser: state.chosenUser,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_SEARCH_TERM':
            return {
                users: state.users,
                searchResult: state.searchResult,
                searchTerm: action.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                chosenUser: state.chosenUser,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_USER_SEARCH_RESULT':
            return {
                users: state.users,
                searchResult: action.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                chosenUser: state.chosenUser,
                currentlyLoading: state.currentlyLoading
            }
        case 'SET_CHOSEN_USER':
            return {
                users: state.users,
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                paginationPageIndex: state.paginationPageIndex,
                paginationPageSize: state.paginationPageSize,
                editModalOpen: state.editModalOpen,
                chosenUser: action.chosenUser,
                currentlyLoading: state.currentlyLoading
            }
        default:
            return state;
    }
};
