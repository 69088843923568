﻿import { WpsTypeEnums } from './WpsTypeEnums';

export default class HelpRangeOfQualificationExamination {

    static GetValueRangeOfQualification(rangeOfQualification: string) {

        let rangeOfQualificationSplit = rangeOfQualification.split("\n")
        let result1 = "";
        let result2 = "";

        if (rangeOfQualificationSplit.length === 2) {
            let oneLine = rangeOfQualificationSplit[0];
            var index = oneLine.indexOf(": ");
            if (index > -1) {
                result1 = oneLine.substring(index + 2);
            }

            let twoLine = rangeOfQualificationSplit[1]
            var index = twoLine.indexOf(": ");
            if (index > -1) {
                result2 = twoLine.substring(index + 2);
            }
        } else {
            let oneLine = rangeOfQualificationSplit[0];
            result1 = oneLine
        }
        return { result1, result2 }
    }

    static GetValueRangeOfQualificationMaterialThickness(materialThickness: string) {

        let materialThicknessSplit = materialThickness.split("\n")
        let result1 = "";
        let result2 = "";
        let conditionToSplit: string[] = ['BW:'];

        if (materialThicknessSplit.length === 2) {
            let oneLine = materialThicknessSplit[0];
            var index = oneLine.indexOf(conditionToSplit[0]);
            if (index > -1) {
                result1 = oneLine.substring(index);
            } else {
                result1 = oneLine;
            }

            let twoLine = materialThicknessSplit[1]
            var index = twoLine.indexOf(conditionToSplit[0]);
            if (index > -1) {
                result2 = twoLine.substring(index);
            }
            else {
                result2 = oneLine;
            }
        } else {
            let oneLine = materialThicknessSplit[0];
            var index = oneLine.indexOf(conditionToSplit[0]);
            if (index > -1) {
                result1 = oneLine.substring(index);
            } else {
                result1 = oneLine;
            }

        }
        return { result1, result2};
    }

    static GetValueRangeOfQualificationMaterialGroup(rangeOfQualificationMaterialGroup: string) {

        let resultmg = rangeOfQualificationMaterialGroup;
        if (rangeOfQualificationMaterialGroup.indexOf("group") != -1) {
            resultmg = rangeOfQualificationMaterialGroup.substring(rangeOfQualificationMaterialGroup.indexOf("group") + 6)
        }
        return resultmg;
    }

    static GetValueRangeOfQualificationJointType(rangeOfQualificationJointType: string) {
        let conditionToSplit: string[] = [', s'];
        let resultJT = rangeOfQualificationJointType;
        if (rangeOfQualificationJointType.indexOf(conditionToSplit[0]) != -1) {
            resultJT = rangeOfQualificationJointType.substring(0, rangeOfQualificationJointType.indexOf(conditionToSplit[0]))
        }
        return resultJT;
    }

    static SetWpsType(type: string) {
        let resultType = type;
        resultType = WpsTypeEnums.PWPS === type ? "pWPS" : "WPS"
        
        return resultType;
    }

    static GetValueRangeOfQualificationDiameter(diameterText: string) {
        let conditionToSplit: string[] = [' och p', ', ', ' för'];
        let resultDiameter = diameterText;
        if (diameterText.indexOf(conditionToSplit[0]) != -1) {
            resultDiameter = diameterText.substring(0, diameterText.indexOf(conditionToSplit[0]))
        }
        else if (diameterText.indexOf(conditionToSplit[1]) != -1) {
            resultDiameter = diameterText.substring(0, diameterText.indexOf(conditionToSplit[1]))
        }
        else if (diameterText.indexOf(conditionToSplit[2]) != -1) {
            resultDiameter = diameterText.substring(0, diameterText.indexOf(conditionToSplit[2]))
        }
        return resultDiameter;
    }

    static setNullToEmpty(value: string) {
        let result = value;
        if (value === null) {
            result = "";
        }

        return result;
    }

}
