﻿
import * as React from 'react';
import {
    Route,
    Redirect,
    RouteProps,
    RouteComponentProps
} from "react-router-dom";
import { connect } from 'react-redux';
import * as LoginStore from '../store/LoginStore';

type LoginProps =
    LoginStore.LoginState // ... state we've requested from the Redux store
    & typeof LoginStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>;

export interface ProtectedRouteProps extends RouteProps {
    loggedIn: boolean;
    allowedPermissions: Array<string>;
    currentRoles: Array<string>;
}

export class ProtectedRoute extends Route<ProtectedRouteProps> {
    private checkPermissions(): Boolean {
        const allowedP = this.props.allowedPermissions;
        if (allowedP.length == 0) {
            return true;
        }

        const userP = this.props.currentRoles;

        for (var i = 0; i < userP.length; i++) {
            for (var j = 0; j < allowedP.length; j++) {
                if (userP[i] === allowedP[j]) {
                    return true;
                }
            }
        }

        return false;
    }

    public render() {
        let redirectPath: string = '';

        if (!this.props.loggedIn || !this.checkPermissions()) {
        //if (!this.props.loggedIn) {
            //TODO: Create new page for insufficient permissions and redirect there?
            redirectPath = "/login";
        }

        if (redirectPath) {
            const renderComponent = () => (<Redirect to={{ pathname: redirectPath }} />);
            return <Route {...this.props} component={renderComponent} render={undefined} />;
        } else {
            return <Route {...this.props} />;
        }
    }
}
const mapStateToProps = (state: any) => ({
    loggedIn: state.login.loggedIn,
    currentRoles: state.login.currentRoles
})

export default connect(mapStateToProps)(ProtectedRoute)