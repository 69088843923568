﻿import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as WPQRTabStore from '../store/WPQRTabStore';
import { TabPane, Button, Card, CardBody, Pagination, PaginationItem, CardTitle, Col, Form, FormGroup, InputGroup, InputGroupText, Input, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledTooltip, ButtonGroup, Nav, NavItem, NavLink, TabContent, CardText, Collapse, CardHeader, CardFooter, UncontrolledPopover, PopoverHeader, PopoverBody, FormText} from 'reactstrap';
import { WPQR } from '../store/WPQR';
import HelpFunctions from '../store/models/HelpFunctions';
import { Link } from 'react-router-dom';
import { t } from '../i18n';
import FileUpload from './FileUpload';
import HelpRangeOfQualificationExamination from '../store/models/HelpRangeOfQualificationExamination';
import RangeOfQualification from './RangeOfQualification';
import { RangeOfQualifications } from '../store/models/RangeOfQualifications';
import TestingDetails1Component from './TestingDetails1Component';
import WPQRPageHeader from './WPQRPageHeader';
import { ExistingWpqrRequest } from '../store/models/requests/ExistingWpqrRequest';
import DrawJointDesign from './DrawJointDesign';
import WeldSequence from './WeldSequence';
import WParameters from './WParameters';
import DropdownLists from './otherComponents/DropdownLists';
import { CreateRangeOfQualificationExaminationRequest } from '../store/models/requests/CreateRangeOfQualificationExaminationRequest';
import { UpdateJointDesignRequest } from '../store/models/requests/UpdateJointDesignRequest';
import { UpdateWeldSequenceRequest } from '../store/models/requests/UpdateWeldSequenceRequest';
import { CreateTestingDetails1ExaminationRequest } from '../store/models/requests/CreateTestingDetails1ExaminationRequest';
import Loader from './Loader';
//import { ExistingWpqrStatus } from '../store/models/ExistingWpqrStatus';


import { RevisionActionType } from '../store/models/RevisionActionType';
import { PDFRequest } from '../store/models/requests/PDFRequest';
import DeletionModal from './otherComponents/DeletionModal';
import EventBus from '../store/eventBus';
// At runtime, Redux will merge together...
type WPQRTabProps =
    WPQRTabStore.WPQRTabStoreState // ... state we've requested from the Redux store
    & typeof WPQRTabStore.actionCreators // ... plus action creators we've requested

const SweToEngWeldingMethods = new Map<string, string>();
const SweToEngJointTypes = new Map<string, string>();
const SweToEngRuns = new Map<string, string>();
const SweToEngPipeOrPlate = new Map<string, string>();

class WPQRTab extends React.PureComponent<WPQRTabProps> {
    public componentDidMount() {
        const customerId = JSON.parse(localStorage.getItem('customerId')!);
        this.props.getWPQRs(customerId, () => {});
        this.props.getWeldingMethodsSearch();
        this.props.getProductTypesAll();
        this.props.getJointTypesAll();
        this.props.getMaterialGroupAll();
        this.props.getRuntypeRangeOfQualification();
        this.props.getStandardsAll();
        this.props.getCurrentTypesAll();
        this.props.clearSearchProperties();
        this.props.initExistingWpqr();
        this.setState({ selectedExaminationWPQRTabId: "rangeOfQualification" }); // Begin with this TabId

        EventBus.on("refreshExistingWpqrList", () => {
            const customerId = JSON.parse(localStorage.getItem('customerId')!);
            this.props.getWPQRs(customerId, () => { this.props.setCurrentlyLoading(false); });
        });

        SweToEngWeldingMethods.set('111 Metallbågsvetsning med belagd elektrod', t('111'));
        SweToEngWeldingMethods.set('114 Metallbågsvetsning med rörelektrod utan gasskydd', t('114'));
        SweToEngWeldingMethods.set('121, helmekaniserad', t('121'));
        SweToEngWeldingMethods.set('122 Pulverbågsvetsning med bandelektrod', t('122'));
        SweToEngWeldingMethods.set('124 Pulverbågsvetsning med metallpulver', t('124'));
        SweToEngWeldingMethods.set('125 Pulverbågsvetsning med rörelektrod', t('125'));
        SweToEngWeldingMethods.set('126 Pulverbågsvetsning med rörformad bandelektrod', t('126'));
        SweToEngWeldingMethods.set('131 MIG-svetsning med trådelektrod (Inert gas)', t('131'));
        SweToEngWeldingMethods.set('132 MIG-svetsning med slaggande rörelektrod', t('132'));
        SweToEngWeldingMethods.set('133 MIG-svetsning med metallpulverfylld rörelektrod', t('133'));
        SweToEngWeldingMethods.set('135 MAG-svetsning med trådelektrod', t('135'));
        SweToEngWeldingMethods.set('136 MAG-svetsning, rörelektrod fluxfylld', t('136'));
        SweToEngWeldingMethods.set('138 MAG-svetsning, metallpulverfylld rörelektrod (M)', t('138'));
        SweToEngWeldingMethods.set('141 TIG-svetsning med trådelektrod', t('141'));
        SweToEngWeldingMethods.set('142 TIG-svetsning utan tillsatsmaterial', t('142'));
        SweToEngWeldingMethods.set('143 TIG-svetsning med rörelektrod', t('143'));
        SweToEngWeldingMethods.set('145 TIG-svetsning med reducerande gas och tråd', t('145'));
        SweToEngWeldingMethods.set('146 TIG-svetsning med reducerande gas och pulverfylld tråd/stav', t('146'));
        SweToEngWeldingMethods.set('147 Gasvolframbågsvetsning med aktiv gas (TAGsvetsning)', t('147'));
        SweToEngWeldingMethods.set('151 Plasma-MIG-svetsning', t('151'));
        SweToEngWeldingMethods.set('152 Plasmapulversvetsning', t('152'));
        SweToEngWeldingMethods.set('153 Plasmasvetsning med överförd ljusbåge (PTA)', t('153'));
        SweToEngWeldingMethods.set('154 Plasmasvetsning med ej överförd ljusbåge', t('154'));
        SweToEngWeldingMethods.set('155 Plasmasvetsning med delvis överförd ljusbåge', t('155'));
        SweToEngWeldingMethods.set('311 Oxy-acetylensvetsning', t('311'));
        SweToEngWeldingMethods.set('312 Oxy-propansvetsning', t('312'));
        SweToEngWeldingMethods.set('313 Oxy-hydrogensvetsning', t('313'));

        SweToEngJointTypes.set('Stumsvets (BW)', t('ButtWeldingBW'));
        SweToEngJointTypes.set('Partiell stumsvets', t('PartialButtWelding'));
        SweToEngJointTypes.set('Kälsvets (FW)', t('KeelWeldingFW'));
        SweToEngJointTypes.set('T-förband (BW)', t('TJointBW'));
        SweToEngJointTypes.set('Rör mot plåt (BW)', t('PipeAgainstPlateBW'));
        SweToEngJointTypes.set('Rör mot plåt (FW)', t('PipeAgainstPlateFW'));
        SweToEngJointTypes.set('Avstickare (BW)', t('StickOutBW'));
        SweToEngJointTypes.set('Avstickare (FW)', t('StickOutFW'));

        SweToEngRuns.set('Enkelsträng / Single run', t('SingleRun'));
        SweToEngRuns.set('Flersträng / Multi run', t('MultiRun'));

        SweToEngPipeOrPlate.set('Plåt', t('Plate'));
        SweToEngPipeOrPlate.set('Rör', t('Pipe'));
    }
    componentDidUpdate(prevProps: any) {
        //if (prevProps.existingWpqrRevision.selectedExistingWpqrRevisionModalOk !== this.props.existingWpqrRevision.selectedExistingWpqrRevisionModalOk! && this.props.existingWpqrRevision.selectedExistingWpqrRevisionModalOk) {
        //    //this.props.clearState();//Todo

        //}

        //if (prevProps.existingWpqrRevision.activeSelectedExistingWpqrId !== this.props.existingWpqrRevision.activeSelectedExistingWpqrId) {
        //    if (this.props.existingWpqrRevision.activeSelectedExistingWpqrId !== 0) {
        //        this.props.initExistingWpqr();
        //        this.props.getExistingWpqr(this.props.existingWpqrRevision.activeSelectedExistingWpqrId)
        //        this.setSelectedExistingWpqrId(this.props.existingWpqrRevision.activeSelectedExistingWpqrId);
        //        this.setIsExistingWpqrFormOpen(true)
        //    } 
        //}
        
    }

    private TranslateBoxes = (word: string, dictionary: Map<string, string>) => {
        if (dictionary.has(word)) {
            return dictionary.get(word);
        } else {
            return word;
        }
    }

    public state = {
        selectedExaminationWPQRTabId: 'rangeOfQualification',
        isExistingWpqrFormOpen: false,
        showJointDesignModal: false,
        showWeldSequenceModal: false,
        enableToClone: false,
        currentExistingWpqrId: 0,
        showDeletionModal: false,
    };

    public setDeletionModalOpen(open: boolean) {
        this.setState({ showDeletionModal: open });
    }

    public removeExistingWpqr() {
        this.props.removeExistingWpqr(this.state.currentExistingWpqrId)
    }

    private setIsExistingWpqrFormOpen = (isOpen: boolean) => {
        this.setState({
            isExistingWpqrFormOpen: isOpen
        });
    }
    private setToggleShowJointDesignModal = () => {
        this.setState({
            showJointDesignModal: !this.state.showJointDesignModal
        });
    }
    private setToggleWeldSequenceModal = () => {
        this.setState({
            showWeldSequenceModal: !this.state.showWeldSequenceModal
        });
    }
    
    private setEnableToClone = (clone: boolean) => {
        this.setState({
            enableToClone: clone
        });
    }
    private openExistingWpqrFormClick() {
        //this.props.clearState();//Todo 
        const userId = localStorage.getItem('userId')!;
        this.props.getByUserIdAndExistingWpqrStatus(userId, 1,
            (savedExistingWpqrId: number) => {
                if (savedExistingWpqrId > 0) {
                    this.clearAndGetExistingWpqrById(savedExistingWpqrId);
                } else {
                    this.props.setActiveSelectedExistingWpqrId(0);// To create existingWpqr
                    this.props.initExistingWpqr(); // Clean
                    this.setIsExistingWpqrFormOpen(true);; // Create a new existingWpqr in db
                }
            })
        

    }
    private clearAndGetExistingWpqrById(id: number) {
        this.props.enableExistingWpqrRevisionModal(false);
        this.setIsExistingWpqrFormOpen(true);
        this.props.setActiveSelectedExistingWpqrId(0); // To create existingWpqr
        this.props.initExistingWpqr(); // Clean
        this.props.getExistingWpqr(id) // Get 
    }

    private clearMarkClick() {
        
        let existingWpqrRequest: ExistingWpqrRequest = {
            existingWpqrStatus: 2,
            userId: localStorage.getItem('userId')!,
            customerId: JSON.parse(localStorage.getItem('customerId')!),
            revisionEWpqrAction: RevisionActionType.Revision,
            revisionEWpqrId: this.props.existingWpqrData.revisionEWpqrId > 0 ? this.props.existingWpqrData.revisionEWpqrId : this.props.existingWpqrData.existingWpqrId,
            id: this.props.existingWpqrData.existingWpqrId,
            existingWpqrDataRequest: {
                createWeldingParametersRequests: [],
                createRangeOfQualificationExaminationRequest: new CreateRangeOfQualificationExaminationRequest(),
                createTestingDetails1ExaminationRequest: new CreateTestingDetails1ExaminationRequest(),
                updateJointDesignRequest: new UpdateJointDesignRequest(),
                updateWeldSequenceRequest: new UpdateWeldSequenceRequest(),
                updateFilesUploadRequest: [],
                commissionNumber: '',
                dekraProcedureNumber: '',
                manufacturerWPQRNumber: '',
                details1CommentInformation: this.props.existingWpqrData.details1CommentInformation,
            }
        };
        let enableCreateExistingWpqr: boolean = false;
        this.props.createUpdateExistingWpqr(existingWpqrRequest, enableCreateExistingWpqr, () => {
            this.props.getWPQRs(existingWpqrRequest.customerId, () => {
                this.props.setCurrentlyLoading(false); 
                this.setIsExistingWpqrFormOpen(false);
                (document.getElementById('addExistingWpqr') as HTMLButtonElement).focus();// Scroll to the top
            });
        });
    }

    private cloneClick() {
        
        let existingWpqrRequest: ExistingWpqrRequest = {
            existingWpqrStatus: 2,
            userId: localStorage.getItem('userId')!,
            customerId: JSON.parse(localStorage.getItem('customerId')!),
            revisionEWpqrAction: RevisionActionType.Clone,
            revisionEWpqrId: this.props.existingWpqrData.existingWpqrId,
            id: this.props.existingWpqrData.existingWpqrId,
            existingWpqrDataRequest: {
                createWeldingParametersRequests: [],
                createRangeOfQualificationExaminationRequest: new CreateRangeOfQualificationExaminationRequest(),
                createTestingDetails1ExaminationRequest: new CreateTestingDetails1ExaminationRequest(),
                updateJointDesignRequest: new UpdateJointDesignRequest(),
                updateWeldSequenceRequest: new UpdateWeldSequenceRequest(),
                updateFilesUploadRequest: [],
                commissionNumber: '',
                dekraProcedureNumber: '',
                manufacturerWPQRNumber: '',
                details1CommentInformation: this.props.existingWpqrData.details1CommentInformation,
            }
        };
        let enableCreateExistingWpqr: boolean = true;
        this.props.createUpdateExistingWpqr(existingWpqrRequest, enableCreateExistingWpqr, () => {
            this.props.getWPQRs(existingWpqrRequest.customerId, () => {
                this.props.setCurrentlyLoading(false);
                this.setIsExistingWpqrFormOpen(false);
                this.setEnableToClone(false);
                (document.getElementById('addExistingWpqr') as HTMLButtonElement).focus();// Scroll to the top
            });
        });
    }


    private onChangeFreeSearch(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setFreeSearch(event.currentTarget.value);
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeMaterialThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setMaterialThickness(event.currentTarget.value);
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeouterDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setOuterDiameter(event.currentTarget.value);
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onClickTabSettingModalCancel(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowTabSettingModal(false);
    }
    private onClickTabSettingModalOK(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowTabSettingModal(false);
    }

    private onClickTabSettingModalOpen(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setShowTabSettingModal(true);
    }
    // Redirect
    private handleLinkClick = (event, id: string) => {
        console.log('Link clicked');
        this.props.setActiveTabId(id);
        // 👇️ refers to the link element
        console.log(event.currentTarget);
    };
    private onChangeColumnDekraProcedureNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnDekraProcedureNumber(event.currentTarget.checked);
    }

    private onChangeColumnManufacturerWPQRNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnmanufacturerWPQRNumber(event.currentTarget.checked);
    }

    private onChangeColumnPwpsName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnPwpsName(event.currentTarget.checked);
    }

    private onChangeColumnQualificationStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnQualificationStandard(event.currentTarget.checked);
    }

    private onChangeColumnWeldingMethod(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnWeldingMethod(event.currentTarget.checked);
    }

    private onChangeColumnJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnJointTypes(event.currentTarget.checked);
    }

    private onChangeColumnProductType(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnProductType(event.currentTarget.checked);
    }

    private onChangeColumnMaterialThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMaterialThickness(event.currentTarget.checked);
    }

    private onChangeColumnOutsideDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnOutsideDiameter(event.currentTarget.checked);
    }

    private onChangeColumnMaterialGroup(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMaterialGroup(event.currentTarget.checked);
    }

    private onChangeColumnSingleMultiString(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnSingleMultiString(event.currentTarget.checked);
    }

    private onChangeColumnMakeAdditives(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTabSettingColumnMakeAdditives(event.currentTarget.checked);
    }
    //Pagination
    private handlePrevPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.wpqrPaginationPageIndex - 1);
    }

    private handleNextPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.wpqrPaginationPageIndex + 1);
    }

    private handlePageClick(event: React.MouseEvent<HTMLElement, MouseEvent>, i: number ) {
        event.preventDefault();
        this.props.setPaginationPageIndex(i);
    }
    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
    private onChangeWeldingMethod(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingMethodForSearch(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setStandardForSearch(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeProductTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedProductType(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedJointType(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeMaterialGroups(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedMaterialGroup(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private onChangeRunTypeRangeOfQualifications(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedRunTypeRangeOfQualification(parseInt(event.currentTarget.value));
        this.props.getWPQRsSearchResult(this.props.wpqrs);
    }
    private setExaminer = (value: string) => {
        /*alert(applicationId);*/
        this.props.setExaminer(value);
    }
    private onClickSaveExistingWPQRButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        // Update/Save the existingWpqr to db.
        this.createNewExistingWpqr();
    }
    private createNewExistingWpqr() {
        let existingWpqrRequest: ExistingWpqrRequest = {
            existingWpqrStatus: this.props.existingWpqrData.existingWpqrStatus > 1 ? this.props.existingWpqrData.existingWpqrStatus : 1,
            userId: localStorage.getItem('userId')!,
            customerId: JSON.parse(localStorage.getItem('customerId')!),
            revisionEWpqrAction: this.props.existingWpqrData.revisionEWpqrAction === RevisionActionType.Revision ? RevisionActionType.Edit : this.props.existingWpqrData.revisionEWpqrAction,
            revisionEWpqrId: this.props.existingWpqrData.revisionEWpqrId > 0 ? this.props.existingWpqrData.revisionEWpqrId : this.props.existingWpqrData.existingWpqrId,
            id: this.props.existingWpqrData.existingWpqrId,
            existingWpqrDataRequest: {
                createWeldingParametersRequests: [],
                createRangeOfQualificationExaminationRequest: new CreateRangeOfQualificationExaminationRequest(),
                createTestingDetails1ExaminationRequest: new CreateTestingDetails1ExaminationRequest( ),
                updateJointDesignRequest: new UpdateJointDesignRequest(),
                updateWeldSequenceRequest: new UpdateWeldSequenceRequest(),
                updateFilesUploadRequest: [],
                commissionNumber: '',
                dekraProcedureNumber: '',
                manufacturerWPQRNumber: '',
                details1CommentInformation: this.props.existingWpqrData.details1CommentInformation,
            }
        };
        
        let enableCreateExistingWpqr: boolean = false; // When true Create a ExistingWpqr
        if (this.props.existingWpqrData.revisionEWpqrAction === RevisionActionType.Edit) {
            enableCreateExistingWpqr = false;
        }
        else if (this.props.existingWpqrData.revisionEWpqrAction === RevisionActionType.Revision && this.props.existingWpqrRevision.activeSelectedExistingWpqrId === 0) {
            enableCreateExistingWpqr = true;
        }
        else if (this.props.existingWpqrData.revisionEWpqrAction === RevisionActionType.Revision && this.props.existingWpqrRevision.activeSelectedExistingWpqrId > 0) {
            enableCreateExistingWpqr = false;
        }

        
        //enableCreateExistingWpqr = this.props.existingWpqrRevision.activeSelectedExistingWpqrId == 0 && (this.props.existingWpqrData.revisionEWpqrAction === RevisionActionType.Revision || this.props.existingWpqrData.revisionEWpqrAction === RevisionActionType.Edit);
        this.props.createUpdateExistingWpqr(existingWpqrRequest, enableCreateExistingWpqr, () => { this.props.setCurrentlyLoading(false); });
    }
    private onClickShowWpqrTab() {
        this.setIsExistingWpqrFormOpen(false);
        this.setEnableToClone(false);
    }

    private handleChangeDraJointDesign = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            this.props.createImageDrawJointDesign(event.target.files[i])
        }
    };

    private handleChangeWeldSequence = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            this.props.createImageWeldSequence(event.target.files[i])
        }
    };

    private onChangeWeldingTestingDetails1CommentInformation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1CommentInformation(event.currentTarget.value);
    }

    private onClickRevisionExistingWpqrRow(rowNumber: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip

        this.props.getExistingWpqrRevisions(rowNumber);
        this.props.setSelectedRevisionIdTo0();
        this.props.enableExistingWpqrRevisionModal(true);
    }

    private onClickCloneExistingWpqrRow(id: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        //this.setSelectedExistingWpqrId(id);
        this.setIsExistingWpqrFormOpen(true)
        this.setEnableToClone(true);
        //this.props.setActiveSelectedExistingWpqrId(rowNumber);
        this.props.initExistingWpqr(); // Clean
        this.props.getExistingWpqr(id); // Get 
    }

    private onClickGeneratePdf(wpsId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.getElementById('tabSettingButton') as HTMLButtonElement).focus();// To close UncontrolledTooltip
        let pdfRequest: PDFRequest = {
            wpsId: wpsId,
            wpqrId: 0,
            wpId: 0,
            fileName: "",
            weldingPlanId: 0,
        }
        //this.props.createPdf(pdfRequest);
    }

    public onRemoveWpqrPress(existingWpqrId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip
        this.setState({ currentExistingWpqrId: existingWpqrId });
        this.setDeletionModalOpen(true);
    }

    private onChangeSelectedRevision(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedRevision(parseInt(event.currentTarget.value));
    }

    private onClickExistingWpqrRevisionModalOK(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.clearAndGetExistingWpqrById(this.props.existingWpqrRevision.selectedRevision.id);
        //this.props.enableExistingWpqrRevisionModal(false);
        //this.setIsExistingWpqrFormOpen(true);
        //this.props.setActiveSelectedExistingWpqrId(0);// To create existingWpqr
        //this.props.initExistingWpqr(); // Clean
        //this.props.getExistingWpqr(this.props.existingWpqrRevision.selectedRevision.id) // Get 
    }

    private onClickCreateRevision(event: React.MouseEvent<HTMLInputElement, MouseEvent>, id: number) {
        event.stopPropagation();
        this.clearAndGetExistingWpqrById(id);
        //this.props.enableExistingWpqrRevisionModal(false);
        //this.setIsExistingWpqrFormOpen(true);
        //this.props.setActiveSelectedExistingWpqrId(0); // To create existingWpqr
        //this.props.initExistingWpqr(); // Clean
        //this.props.getExistingWpqr(id) // Get 
    }

    private onClickExistingWpqrRevisionModalCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.enableExistingWpqrRevisionModal(false);
    }

    public render() {
        return (
            <React.Fragment key={"renderWpqrTab"}>
                <Loader show={this.props.currentlyLoading} title={t('Working')} />
                <Form className="dekra-content-wrapper dekra-wrapper">
                    <Collapse isOpen={!this.state.isExistingWpqrFormOpen}>
                    <Row form>
                        <Col sm={3}>
                            <FormGroup>
                                <Card className="card-border-dekra">
                                    <CardBody>
                                        <CardTitle>
                                        </CardTitle>
                                            <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type="text" bsSize="sm" name="freeSearch"
                                                            value={this.props.searchProperties.freeSearch} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFreeSearch(event)}
                                                            placeholder={t('Search')} />
                                                    </InputGroup>
                                                </FormGroup>
                                                </Col>
                                            </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="exampleSelect">{t('QualificationStandard')}</Label>
                                                    <Input type="select" bssize="sm" name="ByStandard" id="ByStandard"
                                                        value={this.props.searchProperties.selectedStandard.id}
                                                        onChange={(event) => this.onChangeStandard(event)}
                                                        placeholder="">
                                                        <option key={"byStandardId0"} id="byStandardId0" value="0">--{t('alla')}--</option>
                                                        {this.props.searchProperties.standards.map((standard, index) => (
                                                            <option key={"byStandardId" + standard.id} id={"byStandardId" + standard.id} value={standard.id}>{standard.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="exampleSelect">{t('WeldingMethod')}</Label>
                                                    <Input type="select" bssize="sm" name="ByWeldingMethod" id="ByWeldingMethod"
                                                        value={this.props.searchProperties.selectedWeldingMethod.id}
                                                        onChange={(event) => this.onChangeWeldingMethod(event)}
                                                        placeholder="">
                                                        <option key={"byStandardId0"} id="byStandardId0" value="0">--{t('all')}--</option>
                                                        {this.props.wpqrWeldingMethodsSearch.map((weldingMethod, index) => (
                                                            <option key={"byWeldingMethodId" + weldingMethod.id} id={"byWeldingMethodId" + weldingMethod.id} value={weldingMethod.id}>{this.TranslateBoxes(weldingMethod.description, SweToEngWeldingMethods)}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqrJointTypes" size="sm">{t('JointTypes')} (BW, FW)</Label>
                                                    <Input type="select" bsSize="sm" name="wpqrJointTypes" id="wpqrJointTypes"
                                                        value={this.props.searchProperties.selectedJointType.id}
                                                        onChange={(event) => this.onChangeJointTypes(event)}
                                                        placeholder="">
                                                        <option id="jointTypeId0" value="0">--{t('all')}--</option>
                                                        {this.props.searchProperties.jointTypes.map((jointType, index) => (
                                                            <option key={"jointTypeId" + jointType.id} id={"jointTypeId" + jointType.id} value={jointType.id}>{this.TranslateBoxes(jointType.name, SweToEngJointTypes)}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                                <Col xs={6} sm={6}>
                                                    <FormGroup>
                                                        <Label for="productTypes" size="sm">{t('ProductType')}: {t('pipe')} (T), {t('plate')} (P)</Label>
                                                        <Input type="select" bsSize="sm" name="productTypes" id="productTypes"
                                                            value={this.props.searchProperties.selectedProductType.id}
                                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeProductTypes(event)}
                                                            placeholder="">
                                                            <option key="productType2Id0" id="productType2Id0" value="0">--{t('all')}--</option>
                                                        {this.props.searchProperties.productTypes.map((productType, index) => (
                                                            <option key={"productType2Id" + productType.id} id={"productType2Id" + productType.id} value={productType.id}>{this.TranslateBoxes(productType.name, SweToEngPipeOrPlate)}</option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        <Row form>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="materialThickness">{t('BaseMaterialThickness')} (mm)</Label>
                                                    <Input type="text" name="materialThickness" id="materialThickness"
                                                        value={this.props.searchProperties.materialThickness} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMaterialThickness(event)}
                                                        placeholder="n,d (mm)" />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="outerDiameter">{t('OutsideDiameter')} (mm)</Label>
                                                        <Input type="text" name="outerDiameter" id="outerDiameter"
                                                            value={this.props.searchProperties.outerDiameter} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeouterDiameter(event)}
                                                        placeholder="n,d (mm)" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqrMaterialGroups" size="sm">{t('BaseMaterialGroup')}</Label>
                                                    <Input type="select" bsSize="sm" name="wpqrMaterialGroups" id="wpqrMaterialGroups"
                                                        value={this.props.searchProperties.selectedMaterialGroup.id}
                                                        onChange={(event) => this.onChangeMaterialGroups(event)}
                                                        placeholder="">
                                                        <option key="materialGroupId0" id="materialGroupId0" value="0">--{t('all')}--</option>
                                                        {this.props.searchProperties.materialGroups.map((materialGroup, index) => (
                                                            <option key={"materialGroupId" + materialGroup.id} id={"materialGroupId" + materialGroup.id} value={materialGroup.id}>{materialGroup.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={6} sm={6}>
                                                <FormGroup>
                                                    <Label for="wpqrRunTypeRangeOfQualifications" size="sm">{t('SingleMultiRun')}</Label>
                                                    <Input type="select" bsSize="sm" name="wpqrRunTypeRangeOfQualifications" id="wpqrRunTypeRangeOfQualifications"
                                                        value={this.props.searchProperties.selectedRunTypeRangeOfQualification.id}
                                                        onChange={(event) => this.onChangeRunTypeRangeOfQualifications(event)}
                                                        placeholder="">
                                                        <option key="runTypeRangeOfQualificationId0" id="runTypeRangeOfQualificationId0" value="0">--{t('all')}--</option>
                                                        {this.props.searchProperties.runTypeRangeOfQualifications.map((runTypeRangeOfQualification, index) => (
                                                            <option key={"runTypeRangeOfQualificationId" + runTypeRangeOfQualification.id} id={"runTypeRangeOfQualificationId" + runTypeRangeOfQualification.id} value={runTypeRangeOfQualification.id}>{this.TranslateBoxes(runTypeRangeOfQualification.rangeOfQualification, SweToEngRuns)}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            </Row>
                                            <Row form>
                                                <Col sm={12}>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row form>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Button id="addExistingWpqr" name="addExistingWpqr" onClick={() => this.openExistingWpqrFormClick()} color="primary">
                                                            {t('AddExistingWpqr')}
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                        <Col sm={9}>
                            <FormGroup>
                                <Card>
                                    <CardTitle>
                                        <Row form>
                                            <Col sm={4}>
                                            </Col>
                                            <Col sm={4}>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                        <Button className={"dekra2-table-settings-btn"} color="link" size="sm" id={"tabSettingButton"} name={"tabSettingButton"} onClick={(event) => this.onClickTabSettingModalOpen(event)}>
                                                        <i className={"fas fa-cog"}></i>
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Table size="sm" className="cert-table" responsive={true}>
                                                        <thead>
                                                            {this.renderTableHeaderRow()}
                                                        </thead>
                                                        <tbody>
                                                            <DeletionModal
                                                                onConfirmDeletion={() => this.removeExistingWpqr()}
                                                                show={this.state.showDeletionModal}
                                                                setOpen={(open: boolean) => this.setDeletionModalOpen(open)}
                                                                headerText={t('DeleteExistingWpqr')}
                                                            />
                                                            {this.rendeExistingWpqrRevisionModal()}
                                                            {this.renderTabSettingModal()}
                                                            {this.props.wpqrsSearchResult && this.props.wpqrsSearchResult.slice(
                                                                this.props.wpqrPaginationPageIndex * this.props.wpqrPaginationPageSize,
                                                                this.props.wpqrPaginationPageIndex * this.props.wpqrPaginationPageSize + this.props.wpqrPaginationPageSize
                                                            ).map((wpqr, index) => (
                                                                this.renderTableRow(wpqr,index)
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan={15}>
                                                                    {this.renderPagination()}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                </Card>
                            </FormGroup>
                        </Col>
                        </Row>
                    </Collapse>
                    <Collapse isOpen={this.state.isExistingWpqrFormOpen}>
                    <Row form>
                        <Col sm={12}>
                            {this.renderExaminationWPQR()}
                        </Col>
                        </Row>
                    </Collapse>
                </Form>
            </React.Fragment>
        );
    }
    private rendeExistingWpqrRevisionModal() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.existingWpqrRevision.showChooseExistingWpqrRevisionModal}>
                    <ModalHeader>
                        {t('ListOfExistingWPQRRevisions')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                {this.props.existingWpqrRevision.revisions.map((revision, index) => (
                                    <FormGroup check key={index}>
                                        <Label check size="sm">
                                            <Input hidden={revision.revisionEWpqrAction === RevisionActionType.Revision} type="radio" bssize="sm"
                                                key={"revisions" + revision.id}
                                                id={"revisions" + revision.id}
                                                name={"revisions" + revision.id}
                                                value={revision.id}
                                                checked={this.props.existingWpqrRevision.selectedRevision.id === revision.id}
                                                onChange={(event) => this.onChangeSelectedRevision(event)}
                                                placeholder="" />{'Revision(' + (this.props.existingWpqrRevision.revisions.length - 1 - index) + ') ' + (revision.existingWpqrData.testingDetails1ExaminationResponse.manufacturer2Name.split(",").length > 1 ? revision.existingWpqrData.testingDetails1ExaminationResponse.manufacturer2Name.split(",")[1] : revision.existingWpqrData.testingDetails1ExaminationResponse.manufacturer2Name) + '    '}
                                            {this.props.existingWpqrRevision.revisions[0].revisionEWpqrAction === RevisionActionType.Edit ? "" :
                                                <Button style={{ textAlign: "right" }} hidden={revision.revisionEWpqrAction !== RevisionActionType.Revision} size="sm" color="primary" onClick={(event) => this.onClickCreateRevision(event, revision.id)}>{t('CreateAudit')}</Button>
                                            }
                                        </Label>
                                    </FormGroup>
                                ))}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickExistingWpqrRevisionModalOK(event)} disabled={this.props.existingWpqrRevision.selectedRevision.id === 0 ? true : false}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickExistingWpqrRevisionModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

    private renderPagination() {
        return (
            <React.Fragment key="renderPagination">
                <div className="pagination-wrapper">

                    <Pagination aria-label="Page navigation example">
                        <PaginationItem onClick={event => this.handlePrevPageClick(event)}>
                            <a className={this.props.wpqrPaginationPageIndex === 0 ? "btn disabled" : "btn"} aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                            </a>
                        </PaginationItem>
                       

                        {[...Array(Math.ceil(this.props.wpqrsSearchResult.length / this.props.wpqrPaginationPageSize))].map((page, i) =>
                            <PaginationItem key={i} active={this.props.wpqrPaginationPageIndex === i} onClick={(event) => this.handlePageClick(event, i)}>
                                <a className="btn">{i + 1}</a> 
                            </PaginationItem>
                        )}

                        <PaginationItem  onClick={event => this.handleNextPageClick(event)}>
                            <a className={this.props.wpqrPaginationPageIndex === (Math.ceil(this.props.wpqrsSearchResult.length / this.props.wpqrPaginationPageSize) - 1) ? "btn disabled" : "btn"}  aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </PaginationItem>
                    </Pagination>
                </div>
            </React.Fragment>
        );
    }

    private renderTabSettingModal() {
        return (
            <React.Fragment key="renderTabSettingModal">
                <Modal centered={true} size="lg" isOpen={this.props.showTabSettingModal}>
                    <ModalHeader>
                        {t('TableSettings')}
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrTabSettingColumns.dekraProcedureNumber}
                                            onChange={(event) => this.onChangeColumnDekraProcedureNumber(event)}
                                            placeholder="" />{' '} DEKRA procedur-nr.
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPQRNumber" id="qwbpColumnmanufacturerWPQRNumber"
                                            checked={this.props.wpqrTabSettingColumns.weldingMethod}
                                            onChange={(event) => this.onChangeColumnWeldingMethod(event)}
                                            placeholder="" />{' '} {t('WeldingMethod')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrTabSettingColumns.outsideDiameter}
                                            onChange={(event) => this.onChangeColumnOutsideDiameter(event)}
                                            placeholder="" />{' '} {t('OutsideDiameter')} (mm)
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrTabSettingColumns.manufacturerWPQRNumber}
                                            onChange={(event) => this.onChangeColumnManufacturerWPQRNumber(event)}
                                            placeholder="" />{' '} Tillverkarens WPQR-nr.
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPQRNumber" id="qwbpColumnmanufacturerWPQRNumber"
                                            checked={this.props.wpqrTabSettingColumns.jointTypes}
                                            onChange={(event) => this.onChangeColumnJointTypes(event)}
                                            placeholder="" />{' '} {t('JointTypes')} (BW, FW)
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrTabSettingColumns.materialGroup}
                                            onChange={(event) => this.onChangeColumnMaterialGroup(event)}
                                            placeholder="" />{' '} {t('BaseMaterialGroup')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrTabSettingColumns.pwpsName}
                                            onChange={(event) => this.onChangeColumnPwpsName(event)}
                                            placeholder="" />{' '} pWPS-nr
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPQRNumber" id="qwbpColumnmanufacturerWPQRNumber"
                                            checked={this.props.wpqrTabSettingColumns.productType}
                                            onChange={(event) => this.onChangeColumnProductType(event)}
                                            placeholder="" />{' '} {t('ProductType')}: {t('pipe')} (T), {t('plate')} (P)
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrTabSettingColumns.singleMultiString}
                                            onChange={(event) => this.onChangeColumnSingleMultiString(event)}
                                            placeholder="" />{' '} {t('SingleMultiRun')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrTabSettingColumns.qualificationStandard}
                                            onChange={(event) => this.onChangeColumnQualificationStandard(event)}
                                            placeholder="" />{' '} {t('QualificationStandard')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbpColumnmanufacturerWPQRNumber" id="qwbpColumnmanufacturerWPQRNumber"
                                            checked={this.props.wpqrTabSettingColumns.materialThickness}
                                            onChange={(event) => this.onChangeColumnMaterialThickness(event)}
                                            placeholder="" />{' '} {t('BaseMaterialThickness')} (mm)
                                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup check>
                                    <Label check size="sm">
                                        <Input type="checkbox" bssize="sm" name="qwbColumnDekraProcedureNumber" id="qwbColumnDekraProcedureNumber"
                                            checked={this.props.wpqrTabSettingColumns.makeAdditives}
                                            onChange={(event) => this.onChangeColumnMakeAdditives(event)}
                                            placeholder="" />{' '} {t('MakeAdditives')}
                                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickTabSettingModalOK(event)}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickTabSettingModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
    private renderTableHeaderRow() {
        return (
            <React.Fragment key={"renderTableHeaderRow"}>
                <tr>
                    <th>
                    </th>
                    {this.props.wpqrTabSettingColumns.dekraProcedureNumber ? <th>
                       <a>DEKRA procedur-nr </a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.manufacturerWPQRNumber ? <th>
                        <a>Tillverkarens WPQR-nr</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.pwpsName ? <th>
                        <a>pWPS-nr</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.qualificationStandard ? <th>
                        <a> {t('QualificationStandard')}</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.weldingMethod ? <th>
                        <a>Svetsmetod</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.jointTypes ? <th>
                        <a> {t('JointTypes')} (BW, FW)</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.productType ? <th>
                        <a>{t('ProductType')}: {t('pipe')} (T), {t('plate')} (P)</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.materialThickness ? <th>
                        <a>{t('BaseMaterialThickness')} (mm)</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.outsideDiameter ? <th>
                        <a>{t('OutsideDiameter')} (mm)</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.materialGroup ? <th>
                        <a>{t('BaseMaterialGroup')}</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.singleMultiString ? <th>
                        <a>{t('SingleMultiRun')}</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.makeAdditives ? <th>
                        <a>{t('MakeAdditives')}</a>
                    </th> : undefined}
                    {this.props.wpqrTabSettingColumns.makeAdditives ? <th>
                        <a>{t('AdditivesDesignation')}</a>
                    </th> : undefined}
                    <th>
                        WPS
                    </th>
                </tr>
            </React.Fragment>
        );
    }
    private renderTableRow(wpqr: WPQR, index: number) {
        return (
            <React.Fragment key={"renderTableRow" + index}>
                <tr key={wpqr.dekraNumber} className={wpqr.revisionWpqrAction === RevisionActionType.Revision ? "striped" : ""}>
                    {wpqr.revisionWpqrAction === RevisionActionType.Revision ? <td>
                        <ButtonGroup vertical>
                            <Button color="link" size="sm" name={"historyWeldingParametersRow-" + index} id={"historyWeldingParametersRow-" + index}
                                onClick={(event) => this.onClickRevisionExistingWpqrRow(wpqr.id, event)}>
                                <i className="fas fa-history"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"historyWeldingParametersRow-" + index} >
                                {t('Revisions')}!
                            </UncontrolledTooltip>
                            <Button color="link" size="sm" name={"cloneWeldingParametersRow-" + index} id={"cloneWeldingParametersRow-" + index}
                                onClick={(event) => this.onClickCloneExistingWpqrRow(wpqr.id, event)}>
                                <i className="fas fa-clone"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"cloneWeldingParametersRow-" + index}>
                                {t('Copy')}!
                            </UncontrolledTooltip>
                            <FileUpload showOnly1Loader={index} wpqrId={wpqr.id} showDownloadPdf={true} selectedFile={wpqr.selectedFile}></FileUpload>
                            <Button color="link" size="sm" name={"deleteRow-" + index} id={"deleteRow-" + index}
                                onClick={(event) => this.onRemoveWpqrPress(wpqr.id, event)}>
                                <i className="fas fa-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"deleteRow-" + index}>
                                {t('Delete')}!
                            </UncontrolledTooltip>
                        </ButtonGroup>
                    </td> : <td>
                        <ButtonGroup vertical>
                            <FileUpload showOnly1Loader={index} wpqrId={wpqr.id} showDownloadPdf={true} selectedFile={wpqr.selectedFile}></FileUpload>
                        </ButtonGroup>
                    </td> }
                    {this.props.wpqrTabSettingColumns.dekraProcedureNumber ? <td>
                        {wpqr.dekraNumber}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.manufacturerWPQRNumber ? <td>
                        {wpqr.wpqrNumber}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.pwpsName ? <td>
                        {wpqr.pwpsNumber}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.qualificationStandard ? <td>
                        {wpqr.standardName}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.weldingMethod ? <td>
                        {HelpFunctions.putNameAndNumberTogether(wpqr.weldingMethod1Name.split(",", 1)[0], wpqr.weldingMethod2Name.split(",", 1)[0])}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.jointTypes ? <td>
                        {HelpRangeOfQualificationExamination.GetValueRangeOfQualificationJointType(wpqr.jointTypeName)}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.productType ? <td>
                        {HelpFunctions.joinTwoStringIfTheyAreDifferent(wpqr.productType1Name, wpqr.productType2Name)}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.materialThickness ? <td>
                        {wpqr.materialThickness1RangeOfQualification}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.outsideDiameter ? <td>
                        {HelpRangeOfQualificationExamination.GetValueRangeOfQualificationDiameter(wpqr.diameter1Text)}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.materialGroup ? <td>
                        {HelpRangeOfQualificationExamination.GetValueRangeOfQualificationMaterialGroup(wpqr.materialGroupRangeOfQualification)}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.singleMultiString ? <td>
                        {wpqr.runTypeRangeOfQualification}
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.makeAdditives ? <td>
                        {wpqr.make1Name} 
                    </td> : undefined}
                    {this.props.wpqrTabSettingColumns.makeAdditives ? <td>
                        {wpqr.make1DesignationName}
                    </td> : undefined}
                    <td>
                        {wpqr.revisionWpqrAction === RevisionActionType.Revision ?
                            <ButtonGroup vertical>
                                <Link onClick={(event) => this.handleLinkClick(event, wpqr.id.toString())} to={"/wpsformedit/-2/" + wpqr.id.toString()} id={"wpsformEdit" + index} className="btn btn-link">
                                    <i className="fas fa-check"></i>
                                </Link>
                                <UncontrolledTooltip placement="right" target={"wpsformEdit" + index}>
                                    {t('CreateAWPS')}
                                </UncontrolledTooltip>
                            </ButtonGroup> :
                            <ButtonGroup vertical>
                                <Link onClick={(event) => this.handleLinkClick(event, wpqr.id.toString())} to={"/wpsformedit/" + wpqr.id.toString()} id={"wpsformEdit" + index} className="btn btn-link">
                                    <i className="fas fa-check"></i>
                                </Link>
                                <UncontrolledTooltip placement="right" target={"wpsformEdit" + index}>
                                    {t('CreateAWPS')}
                                </UncontrolledTooltip>
                            </ButtonGroup>
                        }
                    </td>
                </tr>
            </React.Fragment>
        );
    }
    private renderWeldPreparationJointDesign() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldPreparation')} / {t('JointDesign')}</h5>
                        </CardTitle>
                        <CardText>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <DrawJointDesign
                                            imageValidationError={this.props.existingWpqrData.imageValidationError1}
                                            imageUpload={this.props.existingWpqrData.imageUpload1}
                                            show={this.state.showJointDesignModal}
                                            toggleShow={this.setToggleShowJointDesignModal}
                                            position={"existingWpqr"}
                                            jointDesign={this.props.existingWpqrData.jointDesign} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12} className="text-center">
                                    <FormGroup>
                                        <Button color="primary" onClick={() => this.setToggleShowJointDesignModal()}>{t('Draw')}</Button><span>&nbsp;</span>
                                        <Button className="btn btn-primary btn-file" >
                                            {t('Upload')}<input type="file" onChange={event => this.handleChangeDraJointDesign(event)} />
                                        </Button>
                                        <Button type="button" color="link" size="sm" id={"UploadInfoJd"} name={"UploadInfoJd"} >
                                            <i className="fas fa-info-circle"></i>
                                        </Button>
                                        <span>&nbsp;</span>
                                        <UncontrolledPopover trigger="focus" placement="bottom" target={"UploadInfoJd"}>
                                            <PopoverHeader>{t('Upload')}</PopoverHeader>
                                            <PopoverBody>{t('UploadInfo')}</PopoverBody>
                                        </UncontrolledPopover>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardText>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
    private renderWeldingSequences() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldSequence')}</h5>
                        </CardTitle>
                        <CardText>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <WeldSequence
                                            imageValidationError={this.props.existingWpqrData.imageValidationError2}
                                            imageUpload={this.props.existingWpqrData.imageUpload2}
                                            show={this.state.showWeldSequenceModal}
                                            toggleShow={this.setToggleWeldSequenceModal}
                                            weldSequence={this.props.existingWpqrData.weldSequence} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12} className="text-center">
                                    <FormGroup>
                                        <Button color="primary" onClick={() => this.setToggleWeldSequenceModal()}>{t('Draw')}</Button><span>&nbsp;</span>
                                        <Button className="btn btn-primary btn-file" >
                                            {t('Upload')}<input type="file" onChange={event => this.handleChangeWeldSequence(event)} />
                                        </Button>
                                        <Button type="button" color="link" size="sm" id={"UploadInfo"} name={"UploadInfo"} >
                                            <i className="fas fa-info-circle"></i>
                                        </Button>
                                        <span>&nbsp;</span>
                                        <UncontrolledPopover trigger="focus" placement="bottom" target={"UploadInfo"}>
                                            <PopoverHeader>{t('Upload')}</PopoverHeader>
                                            <PopoverBody>{t('UploadInfo')}</PopoverBody>
                                        </UncontrolledPopover>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardText>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderWeldingTestingDetails2() {
        return (
            <React.Fragment>
                <Form>
                    <WPQRPageHeader pCommissionNumber={this.props.existingWpqrData.commissionNumber} pManufacturerWPQRNumber={this.props.existingWpqrData.manufacturerWPQRNumber} pDekraProcedureNumber={this.props.existingWpqrData.dekraProcedureNumber} pageNumber={'3/4'} />
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <h4>{t('WeldingTestingDetails')}</h4>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={6}>
                            {this.renderWeldPreparationJointDesign()}
                        </Col>
                        <Col sm={6}>
                            {this.renderWeldingSequences()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <WParameters pWeldingMethodASearchText={""}
                                pWeldingMethodBSearchText={""}
                                pWeldingMethods={[]}
                                pWeldingParameters={this.props.existingWpqrData.weldingParameters}
                                currentTypes={this.props.currentTypes}
                                showWeldingBaseParameters={true}
                                componentPositionProtocol={true} // 
                                pWpsArcEnergyText={""} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={6}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Form inline>
                                                        <Label for="testCommentInformation" size="sm">{t('Comments')}</Label>
                                                        <span>&nbsp;</span>
                                                        <DropdownLists listNumber={26} dropDownListsCallback={this.props.setCommentInformation} rowNumber={0} ></DropdownLists>
                                                    </Form>
                                                    <Input type="textarea" rows={this.props.existingWpqrData.details1CommentInformation.split("\n").length < 4 ? 4 : this.props.existingWpqrData.details1CommentInformation.split("\n").length} bsSize="sm" name="testCommentInformation" id="testCommentInformation"
                                                        value={this.props.existingWpqrData.details1CommentInformation}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1CommentInformation(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <FormText color="muted">
                                    <FileUpload pFilesUpload={this.props.existingWpqrData.filesUpload} showFilesUploadTable={true} hiddenSendButtonToCustomer={true}></FileUpload>
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
    private renderExaminationWPQR() {
        return (
            <React.Fragment>
                <Card>
                    <CardHeader className="card-header-dekra">
                        <Row>
                            <Col sm={6}>
                                <Nav tabs className="d-examination-nav-tabs">
                                    <NavItem>
                                        <NavLink active={this.state.selectedExaminationWPQRTabId === 'rangeOfQualification'}
                                            href="#"
                                            id="rangeOfQualification" onClick={(event) => this.setState({ selectedExaminationWPQRTabId: event.currentTarget.id })}>{t('ValidationAreas')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={this.state.selectedExaminationWPQRTabId === 'weldingTestingDetails1'} href="#"
                                            id="weldingTestingDetails1" onClick={(event) => this.setState({ selectedExaminationWPQRTabId: event.currentTarget.id })}>{t('WeldingTestDetails')} 1/2</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={this.state.selectedExaminationWPQRTabId === 'weldingTestingDetails2'} href="#"
                                            id="weldingTestingDetails2" onClick={(event) => this.setState({ selectedExaminationWPQRTabId: event.currentTarget.id })}>{t('WeldingTestDetails')} 2/2</NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col sm={6} className="text-center">
                                <Button color="primary" id="previous" name="previous" onClick={(event) => this.onClickShowWpqrTab()}>{t('Previous')}{' '}
                                    <i className="fas fa-arrow-left"></i>
                                </Button>
                            </Col>
                        </Row>
                        </CardHeader>
                    <CardBody>
                    <TabContent activeTab={this.state.selectedExaminationWPQRTabId}>
                        <TabPane tabId="rangeOfQualification">
                            <Row>
                                <Col sm="12">
                                        <RangeOfQualification
                                            rangeOfQualifications={new RangeOfQualifications()}
                                            updateRangeOfQualificationInfo={this.props.existingWpqrData.rangeOfQualificationInfo}
                                            pExaminer={this.props.existingWpqrData.existingWpqrDataExtra.examinerOrExaminingBody}
                                            pExaminerName={this.props.existingWpqrData.existingWpqrDataExtra.rangeOfName}
                                            pExaminerDate={this.props.existingWpqrData.existingWpqrDataExtra.rangeOfDate}
                                            setExaminer={this.props.setExaminer}
                                            setExaminerName={this.props.setExaminerName}
                                            setExaminerDate={this.props.setExaminerDate}
                                            hiddenToShowExistingWpqrForm={true} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="weldingTestingDetails1">
                            <Row>
                                <Col sm="12">
                                        <TestingDetails1Component
                                            hiddenMessageExistingWpqr={true}
                                            setWeldingTestingDetails1={this.props.existingWpqrData.weldingTestingDetails1}
                                            examinerOrExaminingBody={this.props.existingWpqrData.existingWpqrDataExtra.examinerOrExaminingBody}
                                            rangeOfName={this.props.existingWpqrData.existingWpqrDataExtra.rangeOfName}
                                            rangeOfDate={this.props.existingWpqrData.existingWpqrDataExtra.rangeOfDate} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="weldingTestingDetails2">
                            <Row>
                                <Col sm="12">
                                    {this.renderWeldingTestingDetails2()}
                                </Col>
                            </Row>
                        </TabPane>
                            </TabContent>
                        </CardBody>
                    <CardFooter>
                        <Row hidden={this.state.enableToClone || this.props.existingWpqrData.revisionEWpqrAction === RevisionActionType.Clone}>
                            <Col sm={6}>
                                <div className={"float-right"}>
                                    <Button disabled={this.props.existingWpqrData.revisionEWpqrAction === RevisionActionType.Revision && this.props.existingWpqrRevision.activeSelectedExistingWpqrId === 0} onClick={() => this.clearMarkClick()} color="primary">
                                        {t('ReadyExistingWpqr')}
                                    </Button>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className={"float-right"}>
                                    <Button color="primary" onClick={(event) => this.onClickSaveExistingWPQRButton(event)}>
                                        {t('Save')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row hidden={!(this.state.enableToClone || this.props.existingWpqrData.revisionEWpqrAction === RevisionActionType.Clone)}>
                            <Col sm={6}>
                                <div className={"float-right"}>
                                    <Button type="button" color="primary" size="lg" id="existingWpqrclone" name="existingWpqrclone"
                                        onClick={() => this.cloneClick()}>
                                        <i className="fas fa-clone"></i>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        </CardFooter>
                    </Card>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.wpqrTab, // Selects which state properties are merged into the component's props
    WPQRTabStore.actionCreators // Selects which action creators are merged into the component's props
)(WPQRTab as any);