﻿import { GetArcTypeResponse } from "./responses/GetArcTypeResponse";
import { GetCurrentTypeResponse } from "./responses/GetCurrentTypeResponse";
import { GetIndexResponse } from "./responses/GetIndexResponse";
import { GetWeldingMethodResponse } from "./responses/GetWeldingMethodResponse";

export class WeldingParameters {
    createdAt: string;
    current: string;
    currentMin: string;
    currentMax: string;
    currentY: string;
    currentType: GetCurrentTypeResponse;
    deletedAt: string;
    diameter: string;
    heatInput: string;
    flowRateTop: string;
    flowRateRoot: string;
    id: number;
    index: GetIndexResponse;
    interpassTemperature: string;
    run: string;
    runText: string;
    runLength: string;
    runWidth: string;
    time: string;
    travelSpeed: string;
    updatedAt: string;
    voltage: string;
    voltageMin: string;
    voltageMax: string;
    voltageX: string;
    weldingMethod: GetWeldingMethodResponse;
    wireFeed: string;
    heatInputMin: string;
    heatInputMax: string;
    travelSpeedMin: string;
    travelSpeedMax: string;
    travelSpeedZ: string;
    pulseCurrent: string;
    pulseTime: string;
    basicCurrent: string;
    timeForBasicCurrent: string;
    frequency: string;
    current1: string;
    time1: string;
    heatInput1: string;
    arcEnergyIE: string;
    arcEnergyIP: string;
    arcIP: string;
    arcIE: string;
    additiveMaterial: string;
    weldingTecknique: string;
    fuelGasPressure: string;
    oxygenPressure: string;
    typeOfFlame: string;

    public constructor() {
        this.createdAt = '';
        this.current = '';
        this.currentMin = '';
        this.currentMax = '';
        this.currentY = '';
        this.currentType = new GetCurrentTypeResponse();
        this.deletedAt = '';
        this.diameter = '';
        this.heatInput = '';
        this.flowRateTop = '';
        this.flowRateRoot = '';
        this.id = 0;
        this.index = new GetIndexResponse();
        this.interpassTemperature = '';
        this.run = '';
        this.runText = '';
        this.runLength = '';
        this.runWidth = '';
        this.time = '';
        this.travelSpeed = '';
        this.updatedAt = '';
        this.voltage = '';
        this.voltageMin = '';
        this.voltageMax = '';
        this.voltageX = '';
        this.weldingMethod = new GetWeldingMethodResponse();
        this.wireFeed = '';
        this.heatInput = '';
        this.heatInputMin = '';
        this.heatInputMax = '';
        this.travelSpeed = '';
        this.travelSpeedMin = '';
        this.travelSpeedMax = '';
        this.travelSpeedZ = '';
        this.pulseCurrent = '';
        this.pulseTime = '';
        this.basicCurrent = '';
        this.timeForBasicCurrent = '';
        this.frequency = '';
        this.current1 = '';
        this.time1 = '';
        this.heatInput1 = '';
        this.arcEnergyIE = '';
        this.arcEnergyIP = '';
        this.arcIP = '';
        this.arcIE = '';
        this.additiveMaterial = '';
        this.weldingTecknique = '';
        this.fuelGasPressure = '';
        this.oxygenPressure = '';
        this.typeOfFlame = '';
    }
}