﻿export class GetWeldingProtocolSectionsResponse {
    heatNumber1: string;
    heatNumber2: string;
    chargeAltHeatNumber1: string;
    chargeAltHeatNumber2: string;
    productType1Id: number;
    productType2Id: number;
    otherInformation1: string;
    otherInformation2: string;
    gunAngle: string;
    hydrogenBakeOut: number;
    oscillationAmplitudeFrequencyHoldingTime: string;
    postWeldHeatTreatment: string;
    powerSourceManufacturer: string;
    plasmaWeldingDetails: string;
    pulseWeldingDetails: string;
    assignmentNumber: string;
    dekraNumberDisplay: string;
    markingOfTheTest: string;
    throatThickness: string;
    remarks: string;
    placeForMonitoring: string;
    weldingDate: string;
    signature: string | null | undefined;
    employeeNumber: string | null | undefined;
    dateOfBirth: string;
    firstName: string;
    lastName: string;
    placeOfBirth: string;

    public constructor() {
        this.heatNumber1 = '';
        this.heatNumber2 = '';
        this.chargeAltHeatNumber1 = '';
        this.chargeAltHeatNumber2 = '';
        this.productType1Id = 0;
        this.productType2Id = 0;
        this.otherInformation1 = '';
        this.otherInformation2 = '';
        this.gunAngle = '';
        this.hydrogenBakeOut = 0.0;
        this.oscillationAmplitudeFrequencyHoldingTime = '';
        this.postWeldHeatTreatment = '';
        this.powerSourceManufacturer = '';
        this.plasmaWeldingDetails = '';
        this.pulseWeldingDetails = '';
        this.assignmentNumber = '';
        this.dekraNumberDisplay = '';
        this.markingOfTheTest = '';
        this.throatThickness = '';
        this.remarks = '';
        this.placeForMonitoring = '';
        this.weldingDate = '';
        this.signature = '';
        this.employeeNumber = '';
        this.dateOfBirth = '';
        this.firstName = '';
        this.lastName = '';
        this.placeOfBirth = '';
    }
}