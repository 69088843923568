﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WPQRPageHeaderState {
    commissionNumber: string;
    dekraProcedureNumber: string;
    manufacturerWPQRNumber: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetCommissionNumberAction {
    type: 'SET_COMMISSION_NUMBER';
    commissionNumber: string;
}

export interface SetDekraProcedureNumberAction {
    type: 'SET_DEKRA_PROCEDURE_NUMBER';
    dekraProcedureNumber: string;
}

export interface SetManufacturerWPQRNumberAction {
    type: 'SET_MANUFACTURER_WPQR_NUMBER';
    manufacturerWPQRNumber: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

export type KnownAction = SetCommissionNumberAction
    | SetDekraProcedureNumberAction
    | SetManufacturerWPQRNumberAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setCommissionNumber: (commissionNumber: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_COMMISSION_NUMBER', commissionNumber: commissionNumber });
    },
    setDekraProcedureNumber: (dekraProcedureNumber: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_DEKRA_PROCEDURE_NUMBER', dekraProcedureNumber: dekraProcedureNumber });
    },
    setManufacturerWPQRNumber: (manufacturerWPQRNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_MANUFACTURER_WPQR_NUMBER', manufacturerWPQRNumber: manufacturerWPQRNumber });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WPQRPageHeaderState = {
    dekraProcedureNumber: '',
    commissionNumber: '',
    manufacturerWPQRNumber: ''
};

export const reducer: Reducer<WPQRPageHeaderState> = (state: WPQRPageHeaderState | undefined, incomingAction: Action): WPQRPageHeaderState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_COMMISSION_NUMBER':
            return {
                commissionNumber: action.commissionNumber,
                dekraProcedureNumber: state.dekraProcedureNumber,
                manufacturerWPQRNumber: state.manufacturerWPQRNumber
            }
        case 'SET_DEKRA_PROCEDURE_NUMBER':
            return {
                commissionNumber: state.commissionNumber,
                dekraProcedureNumber: action.dekraProcedureNumber,
                manufacturerWPQRNumber: state.manufacturerWPQRNumber
            }
        case 'SET_MANUFACTURER_WPQR_NUMBER':
            return {
                commissionNumber: state.commissionNumber,
                dekraProcedureNumber: state.dekraProcedureNumber,
                manufacturerWPQRNumber: action.manufacturerWPQRNumber
            }
        default:
            return state;
    }
};
