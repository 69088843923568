﻿
import * as React from 'react';
import '../../customized.css';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroupButtonDropdown, UncontrolledDropdown} from 'reactstrap';
import { t } from '../../i18n';
const list3 = ['Godkänd/Approved', 'Underkänd/Failed', '-'];
const list4 = ['+XX ºC'];
const list5 = ['-', '≥ XXX', 'XXX - XXX'];
const list6 = ['Grundmaterial/Parent metal', 'Svets/Weld', 'HAZ', '-'];
const list7 = ['XX mm', '4 x t', '-'];
const list8 = ['180º'];
const list9 = ['Topp/Face', 'Rot/Root', 'SBB', 'N/A'];
const list10 = ['≥ X J'];
const list11 = ['VWT0/1', 'VHT1/1', 'VHT2/1', 'VHT5/1', 'N/A'];
const list12 = ['10x10x55', '7,5x10x55', '5x10x55', '2,5x10x55'];
const list13 = ['300J', '600J'];
const list14 = ['+XX ºC', '-XX ºC'];
const list15 = ['Halvprovstav / Sub sized specimen', '3/4-provstav / Sub sized specimen', '1/4-provstav / Sub sized specimen', 'Samples were not completely broken'];
const list16 = ['External surface (HV 10)', 'External surface (HV 5)', 'Root (HV 10)', 'Root (HV 5)', 'External surface 2 (HV 10)', 'External surface 2 (HV 5)', 'Middle (HV 10)', 'Middle (HV 5)', 'Area between 1st and 2nd welding process (HV 10)', 'Area between 1st and 2nd welding process (HV 5)'];
const list17 = ['Frånsvets (lw)', 'Motsvets (rw)'];
const list18 = ['Normal låga', 'Kolande låga', 'Sotande låga', 'Svagreducerande låga', 'Oxiderande låga'];

const list20 = ['PWHT i ugn/med värmefilt/med eluppvärmning i ugn. XXX min hålltid vid XXXX °C./PWHT in oven/with hot blankets/with electric heating in oven. XXX min holding time at XXXX °C',
    'Släckglödgning. Eluppvärmning i ugn. XXX min hålltid vid XXX°C./Quench annealing. Electric heated in oven. XXX min holding time at XXXX °C./Släckning XX sek från ugn till vatten./Qenching XX sec from oven to water.',
    'Obehandlat tillstånd efter svetsning/As welded',
    'Naturlig åldring (kallåldring)/Natural ageing',
    'Konstgjord åldring (varmåldring)/Artificial ageing'];
const list21 = ['Uppvärmningshastighet XX°C/h mellan XXX och XXX°C. Avsvalningshastighet XX°C/h mellan XXX och XXX°C./Heating XX°C/h between XXX and XXX°C. Cooling XX°C/h between XXX and XXX°C.'];
const list22 = ['-', 'WTh 10, ØXX mm', 'WTh 20, ØXX mm', 'WTh 30, ØXX mm', 'WLa 10, ØXX mm', 'WLa 15, ØXX mm', 'WLa 20, ØXX mm', 'WZr 3, ØXX mm', 'WZr 8, ØXX mm', 'WWP, ØXX mm', 'Metod 1: WTh XX, ØXX mm, Metod 2: WTh XX, ØXX mm'];
const list23 = ['-',
    'Ferrithaltsmätning/Ferrite content measurement, HAZ: XX,X ±X,X %, HAZ HT: XX,X ±X,X %, WM: XX,X ±X,X %, enligt krav, se rapport/acc. to req, see report DT-XXXX.',
    'Korrosionsprovning enligt/Corrosion testing acc. to NORSOK M-601, se rapport/see report DT-XXXX.',
    'Längdsvetsdragprov vid XX°C enligt/Longitudinal weld tensile test at XX°C acc. to SS-EN 13445-4:2021, 8.3 a), se rapport /see report DT-XXXX.',
    'Längdsvetsdragprov vid XX°C enligt/Longitudinal weld tensile test at XX°C acc. to SS-EN 12952-6, 6.2.2.2, se rapport/see report DT-XXXX.',
    'Dragprovning vid förhöjd temperatur, XXX°C/Tensile test at elevated temperature, XXX°C, se rapport/ see report DT-XXXX.',
    '*Makroprovning inklusive bestämning av minsta inträngningsdjupet (X,X mm) utfört enligt SS-EN 1090-2, 7.4.1.2./*Macro examination including determination of min. depth of penetration (1,9 mm) performed acc. to EN 1090-2, 7.4.1.2. ',
    '**Mikroprovning utförd enligt SS-EN 1090-2, 7.4.1.2 b)./**Micro examination performed acc. to SS-EN 1090-2, 7.4.1.2 b). ',
    'Korsdragprov utförda enligt SS-EN 1090-2, 7.4.1.2./Cruciform welded joint tensile test performed acc. to SS-EN 1090-2, 7.4.1.2.',
    'Makroprovning samt hårdhetsprovning utförda på XX mm långa häftsvetsar enligt SS-EN 1090-2, 7.5.7./Macro examination and hardness test of of tack welds of XX mm length performed acc. to EN 1090-2, 7.5.7.',
    'Makroprovning samt hårdhetsprovning utförda även på häfta./Macro examination and hardness test also performed on tack weld.',
    'En extra rad med hårdhetsintryck utförd enligt SS-EN ISO 15614-1:2017./An additional row of hardness indentations performed acc. to SS-EN ISO 15614-1:2017.',
    'Hårdhetskrav enligt NORSOK M-601:2016: 350 HV 10./Requirement for hardness acc. to NORSOK M-601:2016: 350 HV 10.',
    'Drag-, bock- och slagprovning utförd enligt ASME BPVC.IX-2021./Tensile, bend and impact test performed acc. to ASME BPVC.IX-2021.',
    'Makro-, drag-, bock- och slagprovning utförd enligt AWS D1.1:2020./Macro, tensile, bend and impact test performed acc. to AWS D1.1:2020.',
    'Makro- och slagprovning utförd enligt NORSOK M-101:2011./Macro and impact test performed acc. to NORSOK M-101:2011.',
    "Protokoll XXXXXX: Anmärkningen, bindfelet/katetavvikelsen/poran, är inte relaterad till proceduren, utan beror på otillräcklig färdighet hos svetsaren, och beaktas därför inte./Report XXXXXX, The remark, lack of fusion/the excessive asymmetry/the pore, is not procedure-related, but due to insufficient welder's skill, and is therefore not taken into account."];
const list24 = ['-',
    'Hårdhetsprov utan utvärdering./Hardness test without evaluation.',
    'Resultatet av slagprovningen i svetsen är inte godkänt om man tar hänsyn till mätosäkerheten./The result of the impact test in the weld is unacceptable if taking measurement uncertainty into account.',
    'Hårdhetsprovning och slagprovning har endast gjorts i ett svetsläge (läge för värmetillförsel)./Impact and hardness testing have been performed at only one heat input position.',
    'Provstycke för komplettering med nytt tillsatsmaterial./Test piece for new filler material.',
    'Svetsmetoderna är inte slagprovade/hårdhetsprovade individuellt./The welding processes are not impact tested/hardness tested individually.'];
const list25 = ['-',
    'DEKRA Industrial AB, Safe Control Materialteknik AB',
    'DEKRA Industrial AB, UAB DEKRA Industrial',
    'DEKRA Industrial AB, DEKRA CZ a.s.',
    'DEKRA Industrial AB, ZVU STROJÍRNY',
    'DEKRA Industrial AB, OSTP Lab'];
const list26 = ['-',
    'Nedanstående värden är medelvärden för provstycke nr X och Y, som användes vid den förstörande provningen.',
    'Provstycke X användes vid den förstörande provningen./Test piece X was/were used for destructive testing.',
    'Provstycke X användes vid hårdhetsprovningen. Detta ger den nedre giltighetsgränsen för sträckenergi/bågenergi./Test piece X was used for hardness testing. This gives the lower limit of range of qualification of heat input/arc energy.',
    'Provstycke X användes vid slagprovningen. Detta ger den övre giltighetsgränsen för sträckenergi/bågenergi./Test piece X was used for impact testing. This gives the higher limit of range of qualification of heat input/arc energy.',
    'För 111, Qmedel (ØX,X mm): X,XX kJ/mm, Qmedel (ØY,Y mm): Y,YY kJ/mm',
    '*Se Bilaga 1 och sid 4(5) för beräkning med IE/IP. Se pWPS och svetsprotokollet för ställvärden.',
    'Om pulsning (fyrkantsvåg):',
    'Se Bilaga för pulssvetsningsdetaljer.'];
const list27 = ['111',
    '114',
    '121',
    '122',
    '124',
    '125',
    '126',
    '131',
    '132',
    '133',
    '135',
    '136',
    '138',
    '141',
    '142',
    '143',
    '145',
    '146',
    '147',
    '151',
    '152',
    '153',
    '154',
    '155',
    '311',
    '312',
    '313'];
const list28 = ['Metod 1: XX mm, Metod 2: XX mm'];
const list29 = ['SS-EN ISO 15614-1:2004 + A1:2008 + A2:2012',
    'SS-EN ISO 15613:2004, SS-EN ISO 15614-1:2004 + A1:2008 + A2:2012, 2014/68/EU (PED)',
    'SS-EN ISO 15614-2:2005',
    'SS-EN ISO 15614-1:2004 + A1:2008 + A2:2012, 2014/68/EU (PED)',
    'SS-EN ISO 15614-1:2017 + A1:2019, Level 2, 2014/68/EU (PED)',
    'SS-EN ISO 15613:2004, SS-EN ISO 15614-1:2004 + A1:2008+A2:2012',
    'SS-EN ISO 15614-2:2005, 2014/68/EU (PED)',
    'SS-EN ISO 15613:2004, SS-EN ISO 15614-1:2017 + A1:2019, Level 2, 2014/68/EU (PED)',
    'SS-EN ISO 15613:2004, SS-EN ISO 15614-1:2017 + A1:2019, Level 2'
];
const list30 = ['SS-EN 13445 (Tryckkärl, ej eldberörd)',
    'SS-EN 13480 (Rörledning)',
    'SS-EN 12952 (Vattenrörspanna)',
    'SS-EN 12953 (Eldrörspanna)',
    'SS-EN 13941 (Fjärrvärmerörsystem)',
    'SS-EN 1090-2 (Utförande av stålkonstruktioner) ',
    'SS-EN 1090-3 (Utförande av aluminiumkonstruktioner)'
];
export default class DropdownLists extends React.PureComponent<{ value?: string, listNumber: number, rowNumber: number, dropDownListsCallback: (stringValue: string) => void, dropDownListsCallbackRow?: (rowN: number, stringValue: string) => void, dropdownDisabled?: boolean },
    { dropdownOpen: boolean }> {
    public state = {
        dropdownOpen: false,
        list3,
        list4,
        list5,
        list6,
        list7,
        list8,
        list9,
        list10,
        list11,
        list12,
        list13,
        list14,
        list15,
        list16,
        list17,
        list18,
        list20,
        list21,
        list22,
        list23,
        list24,
        list25,
        list26,
        list27,
        list28,
        list29,
        list30,
        value: "",
    };
    public componentDidMount() {
    }
    private toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    
    public render() {
        return (
            <React.Fragment>
                {this.props.listNumber === 18 && this.props.rowNumber > 0 ?
                    <Input type="select" className="padding-form-control" bsSize="sm" name={"weldingParametersTypeOfFlam-" + this.props.rowNumber} id={"weldingParametersTypeOfFlam-" + this.props.rowNumber}
                        value={this.props.value}
                        onChange={(event) => this.onClickSetTextWithRow(event.currentTarget.value, this.props.rowNumber)}
                        placeholder="">
                        <option key={"typeOfFlamId0" + this.props.rowNumber} id="typeOfFlamId0" value="0"></option>
                        {this.state.list18.map((item) => (
                            <option key={"typeOfFlamIda" + item + this.props.rowNumber} id={"typeOfFlamId" + item} value={item}>{item}</option>
                        ))}
                    </Input> : ""
                }
                {this.props.listNumber === 17 && this.props.rowNumber > 0 ?
                    <Input type="select" className="padding-form-control" bsSize="sm" name={"weldingParametersWeldingTecknique-" + this.props.rowNumber} id={"weldingParametersWeldingTecknique-" + this.props.rowNumber}
                        value={this.props.value}
                        onChange={(event) => this.onClickSetTextWithRow(event.currentTarget.value, this.props.rowNumber)}
                        placeholder="">
                        <option key={"weldingMethodId0" + this.props.rowNumber} id="weldingMethodId0" value="0"></option>
                        {this.state.list17.map((item) => (
                            <option key={"weldingMethodIda" + item + this.props.rowNumber} id={"weldingMethodId" + item} value={item}>{item}</option>
                        ))}
                    </Input>: ""
                }
                {this.props.listNumber < 17 ? 
                <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                        <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-background">
                        </DropdownToggle>
                        {this.props.listNumber === 3 && this.props.rowNumber === 0 ? < DropdownMenu >
                            {this.state.list3.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 3 && this.props.rowNumber > 0 ? < DropdownMenu >
                            {this.state.list3.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetTextWithRow(item, this.props.rowNumber)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 4 ? < DropdownMenu >
                            {this.state.list4.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 5 && this.props.rowNumber === 0 ? < DropdownMenu >
                            {this.state.list5.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 5 && this.props.rowNumber > 0 ? < DropdownMenu >
                            {this.state.list5.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetTextWithRow(item, this.props.rowNumber)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 6 && this.props.rowNumber > 0 ? < DropdownMenu >
                            {this.state.list6.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetTextWithRow(item, this.props.rowNumber)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 7 ? < DropdownMenu >
                            {this.state.list7.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 8 ? < DropdownMenu >
                            {this.state.list8.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetTextWithRow(item, this.props.rowNumber)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 9 ? < DropdownMenu >
                            {this.state.list9.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetTextWithRow(item, this.props.rowNumber)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 10 ? < DropdownMenu >
                            {this.state.list10.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 11 ? < DropdownMenu >
                            {this.state.list11.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetTextWithRow(item, this.props.rowNumber)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 12 ? < DropdownMenu >
                            {this.state.list12.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetTextWithRow(item, this.props.rowNumber)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 13 && this.props.rowNumber > 0 ? < DropdownMenu >
                            {this.state.list13.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetTextWithRow(item, this.props.rowNumber)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 13 && this.props.rowNumber === 0 ? < DropdownMenu >
                            {this.state.list13.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 14 ? < DropdownMenu >
                            {this.state.list14.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 15 && this.props.rowNumber > 0 ? < DropdownMenu >
                            {this.state.list15.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetTextWithRow(item, this.props.rowNumber)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 15 && this.props.rowNumber === 0 ? < DropdownMenu >
                            {this.state.list15.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        {this.props.listNumber === 16 ? < DropdownMenu >
                            {this.state.list16.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </InputGroupButtonDropdown> : ""
                }
                {this.props.listNumber === 20 ?
                    <InputGroupButtonDropdown disabled={this.props.dropdownDisabled} addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                        <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-background">
                        </DropdownToggle>
                        {this.props.listNumber === 20 ? < DropdownMenu >
                            {this.state.list20.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </InputGroupButtonDropdown> : ""
                }
                {this.props.listNumber === 21 ?
                    <InputGroupButtonDropdown disabled={this.props.dropdownDisabled} addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                        <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-background">
                        </DropdownToggle>
                        {this.props.listNumber === 21 ? < DropdownMenu >
                            {this.state.list21.map((item) => (
                                <div className="btn text-left" key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><div style={{ width: "100%" }}><a>{item}</a></div></div>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </InputGroupButtonDropdown> : ""
                }
                {this.props.listNumber === 22 ?
                    <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                        <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-background">
                        </DropdownToggle>
                        {this.props.listNumber === 22 ? < DropdownMenu >
                            {this.state.list22.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </InputGroupButtonDropdown> : ""
                }
                {this.props.listNumber === 23 ?
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown} direction={"left"}>
                        <DropdownToggle style={{ padding: '0px' }} caret size="sm" className="btn btn-primary">{t('ChooseText')}{ }
                        </DropdownToggle>
                        {this.props.listNumber === 23 ? < DropdownMenu >
                            {this.state.list23.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </Dropdown> : ""
                }
                {this.props.listNumber === 24 ?
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown} direction={"left"}>
                        <DropdownToggle style={{ padding: '0px' }} caret size="sm" className="btn btn-primary">{t('ChooseText')}{ }
                        </DropdownToggle>
                        {this.props.listNumber === 24 ? < DropdownMenu >
                            {this.state.list24.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                                
                            ))}
                        </DropdownMenu> : ""
                        }
                    </Dropdown> : ""
                }
                {this.props.listNumber === 25 ?
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown} direction={"left"}>
                        <DropdownToggle style={{ padding: '0px' }} caret size="sm" className="btn btn-primary">{t('ChooseText')}{ }
                        </DropdownToggle>
                        {this.props.listNumber === 25 ? < DropdownMenu >
                            {this.state.list25.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                        </Dropdown>: ""
                }
                {this.props.listNumber === 26 ?
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown} direction={"left"}>
                        <DropdownToggle style={{ padding: '0px' }} caret size="sm" className="btn btn-primary">{t('ChooseText')}{ }
                        </DropdownToggle>
                        {this.props.listNumber === 26 ? < DropdownMenu >
                            {this.state.list26.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </Dropdown> : ""
                }
                {this.props.listNumber === 27 ?
                    < InputGroupButtonDropdown disabled={this.props.dropdownDisabled}  addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                        <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-background">
                        </DropdownToggle>
                        {this.props.listNumber === 27 ? < DropdownMenu className="scroll-vertical">
                            {this.state.list27.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </InputGroupButtonDropdown> : ""
                }
                {this.props.listNumber === 28 ?
                    <InputGroupButtonDropdown disabled={this.props.dropdownDisabled} addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                        <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-background">
                        </DropdownToggle>
                        {this.props.listNumber === 28 ? < DropdownMenu >
                            {this.state.list28.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </InputGroupButtonDropdown> : ""
                }
                {this.props.listNumber === 29 ?
                    <InputGroupButtonDropdown disabled={this.props.dropdownDisabled} addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                        <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-background">
                        </DropdownToggle>
                        {this.props.listNumber === 29 ? < DropdownMenu size="sm">
                            {this.state.list29.map((item) => (
                                <div className="btn text-left" key={"itemId" + item}
                                                        id={"itemId" + item}
                                                        onClick={(event) => this.onClickSetText(item)}><div style={{ width: "100%" }}><a>{item}</a></div></div>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </InputGroupButtonDropdown> : ""
                }
                {this.props.listNumber === 30 ?
                    <InputGroupButtonDropdown disabled={this.props.dropdownDisabled} addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                        <DropdownToggle caret size="sm" className="btn btn-primary btn-primary-background">
                        </DropdownToggle>
                        {this.props.listNumber === 30 ? < DropdownMenu >
                            {this.state.list30.map((item) => (
                                <DropdownItem key={"itemId" + item} id={"itemId" + item} onClick={(event) => this.onClickSetText(item)}><a>{item}</a></DropdownItem>
                            ))}
                        </DropdownMenu> : ""
                        }
                    </InputGroupButtonDropdown> : ""
                }
            </React.Fragment>
                );
    }
    private onClickSetText = (event: string) => {
        this.toggleDropDown()
        this.props.dropDownListsCallback(event);
    }
    private onClickSetTextWithRow = (event: string, rowN: number) => {
        this.toggleDropDown()
        this.props.dropDownListsCallbackRow(rowN, event);
    }
}
