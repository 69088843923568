﻿export default class NumberExtendions {

    static zeroToStringEmpty(str: Number) {
        return str === 0 ? "" : str.toString().replace('.', ',');
    };

}
declare global {
    interface Number {
        zeroToStringEmpty(): string;
    }
}

Number.prototype.zeroToStringEmpty = function (): string {
    return NumberExtendions.zeroToStringEmpty(this);
};
//declare global {
//    interface Number {
//        thousandsSeperator(): string;
//    }
//}
//Number.prototype.thousandsSeperator = function (): string {
//    return this === 0 ? "" : this.toString().replace('.', ',');
//    //return Number(this).toString().replace("0", ',oooo');
//}
//export { }; 