﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardText, CardTitle, Col, Form, FormGroup, Input, Label, Row, Table, Button, Container, CardSubtitle, FormText } from 'reactstrap';
import { ApplicationState } from '../store/index';
import * as WPQRPageHeaderStore from '../store/WPQRPageHeaderStore';
import { t } from '../i18n';

// At runtime, Redux will merge together...
type WPQRPageHeaderProps =
    WPQRPageHeaderStore.WPQRPageHeaderState // ... state we've requested from the Redux store
    & typeof WPQRPageHeaderStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    pageNumber: string;
    pDekraProcedureNumber: string;
    pManufacturerWPQRNumber: string;
    pCommissionNumber: string;
}

class WPQRPageHeader extends React.PureComponent<WPQRPageHeaderProps> {
    constructor(props: WPQRPageHeaderProps) {
        super(props);
        this.props.setDekraProcedureNumber(this.props.pDekraProcedureNumber);
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.pDekraProcedureNumber !== this.props.pDekraProcedureNumber) {
            this.props.setDekraProcedureNumber(this.props.pDekraProcedureNumber);
        }

        if (prevProps.pManufacturerWPQRNumber !== this.props.pManufacturerWPQRNumber) {
            this.props.setManufacturerWPQRNumber(this.props.pManufacturerWPQRNumber);
        }

        if (prevProps.pCommissionNumber !== this.props.pCommissionNumber) {
            this.props.setCommissionNumber(this.props.pCommissionNumber);
        }
    }
    private onChangeCommissionNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCommissionNumber(event.currentTarget.value);
    }

    private onChangeDekraProcedureNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDekraProcedureNumber(event.currentTarget.value);
    }

    private onChangeManufacturerWPQRNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setManufacturerWPQRNumber(event.currentTarget.value);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div>
                    <Row form>
                        <Col sm={3}>
                            <FormGroup>
                                <Label for="wpqrPageHeaderDekraProcedureNumber" size="sm">{t('ProcedureNo')}</Label>
                                <Input type="text" bsSize="sm" name="wpqrPageHeaderDekraProcedureNumber" id="wpqrPageHeaderDekraProcedureNumber"
                                    value={this.props.dekraProcedureNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeDekraProcedureNumber(event)}
                                    placeholder="" />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label for="wpqrPageHeaderCommissionNumber" size="sm">{t('CommissionNumber')}</Label>
                                <Input type="text" bsSize="sm" name="wpqrPageHeaderCommissionNumber" id="wpqrPageHeaderCommissionNumber"
                                    value={this.props.commissionNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCommissionNumber(event)}
                                    placeholder="" />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label for="wpqrPageHeaderManufacturerWPQRNumber" size="sm">{t('ManufacturersWPQRNo')}.</Label>
                                <Input type="text" bsSize="sm" name="wpqrPageHeaderManufacturerWPQRNumber" id="wpqrPageHeaderManufacturerWPQRNumber"
                                    value={this.props.manufacturerWPQRNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeManufacturerWPQRNumber(event)}
                                    placeholder="" />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label for="wpqrPageHeaderPageNumber" size="sm">{t('Page')}</Label>
                                <Input type="text" readOnly bsSize="sm" name="wpqrPageHeaderPageNumber" id="rangeOfQualificationPageNumber"
                                    value={this.props.pageNumber}
                                    placeholder="" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.wpqrPageHeader;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    WPQRPageHeaderStore.actionCreators // Selects which action creators are merged into the component's props
)(WPQRPageHeader as any);
