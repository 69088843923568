﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Card, CardBody, Col, FormGroup, Input, Label, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { ApplicationState } from '../store/index';
import * as FileUploadStore from '../store/FileUploadStore';
import { GetFilesUploadResponse } from '../store/models/responses/GetFilesUploadResponse';
import { PDFRequest } from '../store/models/requests/PDFRequest';
import { t } from '../i18n';
import Loader from './Loader';
import { RevisionActionType } from '../store/models/RevisionActionType';
// At runtime, Redux will merge together...
type FileUploadProps =
    FileUploadStore.FileUploadState // ... state we've requested from the Redux store
    & typeof FileUploadStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    pFilesUpload?: Array<GetFilesUploadResponse>;
    enableOnlyPdfGenerator?: boolean;
    wpId?: number;
    wpqrId?: number;
    previewFilename?: string;
    hiddenSelectedFileRow?: boolean;
    hiddenSendButtonToCustomer?: boolean
    showFilesUploadTable?: boolean;
    sendToCustomer?: () => void;
    showDownloadPdf?: boolean;
    selectedFile?: GetFilesUploadResponse;
    showOnly1Loader?: number;
    revisionWpqrAction?: string;
}

class FileUpload extends React.PureComponent<FileUploadProps> {
    constructor(props: FileUploadProps) {
        super(props);
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        // Clear all store
        this.props.clearState();
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.pFilesUpload !== this.props.pFilesUpload) {
            this.props.setFilesUpload(this.props.pFilesUpload);
        }
    }

    private handleChange = (event: any) => {
       event.preventDefault();
       for (var i = 0; i < event.target.files.length; ++i) {
           this.props.createFile(event.target.files[i])
       }
    };

    private handleChangeSendToCustomer = (event: any) => {
        event.preventDefault();
        this.props.sendToCustomer();
    };

    private onClickDownloadFile(fileName: string, filePath: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setDownload(fileName, filePath);
    }

    private onClickDeleteFile(rowNumber: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.deleteFileRow(rowNumber);
    }

    private onClickGeneratePdf(pathId: string, fileDescription: string, fileName: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        let pdfRequest: PDFRequest = {
            wpsId: 0,
            wpqrId: 0,
            wpId: 0,
            fileName: fileName,
            weldingPlanId: 0,
        }
        if (fileDescription === "wps") {
            pdfRequest.wpsId = parseInt(pathId);
            this.props.createPdf(pdfRequest);
        } else if (fileDescription === "wp") {
            pdfRequest.wpId = parseInt(pathId);
            this.props.createWeldingProtocolPdf(pdfRequest);
        } else {
            pdfRequest.wpqrId = parseInt(pathId);
            this.props.createWpqrPdf(pdfRequest);
        }
    }

    private onClickGeneratePdfWp(id: number, previewName: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        let pdfRequest: PDFRequest = {
            wpsId: 0,
            wpqrId: 0,
            wpId: id,
            fileName: previewName + "_WP", 
            weldingPlanId: 0,
        }
        this.props.createWeldingProtocolPdf(pdfRequest);
    }

    private onClickGeneratePdfWpqr(id: number, previewName: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        let pdfRequest: PDFRequest = {
            wpsId: 0,
            wpqrId: id,
            wpId: 0,
            fileName: previewName + "_WPQR.pdf",
            weldingPlanId: 0,
        }
        this.props.createWpqrPdf(pdfRequest);
    }

    private onClickDownloadPdf(selectedFile: GetFilesUploadResponse, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        (document.activeElement as HTMLButtonElement).blur();// To close UncontrolledTooltip
        let pdfRequest: PDFRequest = {
            wpsId: 0,
            wpqrId: 0,
            wpId: 0,
            fileName: selectedFile.fileName,
            weldingPlanId: 0,
        }
        // wps, wp and Wpqr are for create pdf
        if (selectedFile.fileDescription === "wps") {
            pdfRequest.wpsId = parseInt(selectedFile.filePath);
            this.props.createPdf(pdfRequest);
        } else if (selectedFile.fileDescription  === "wp") {
            pdfRequest.wpId = parseInt(selectedFile.filePath);
            this.props.createWeldingProtocolPdf(pdfRequest);
        } else if (selectedFile.fileDescription  === "Wpqr") {
            pdfRequest.wpqrId = parseInt(selectedFile.filePath);
            this.props.createWpqrPdf(pdfRequest);
        } else{
            this.props.setDownload(selectedFile.fileName, selectedFile.filePath);
        }
    }

    private onChangeSelectedFile(rowNumber: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedFile(rowNumber, event.currentTarget.checked);
    }
    
    public render() {
        return (
            <React.Fragment>
                {this.props.enableOnlyPdfGenerator && this.props.wpId >= 0 ?
                    <FormGroup className="float-right">
                        <Button disabled={this.props.wpId === 0} type="button" color="primary" id="generatePdf" name="generatePdf"
                            onClick={(event) => this.onClickGeneratePdfWp(this.props.wpId, this.props.previewFilename, event)}>
                            {t('Preview')}{"  "}<i className="fas fa-file-pdf"></i>
                                </Button>
                            </FormGroup> : ""
                }
                {this.props.enableOnlyPdfGenerator && this.props.wpqrId >= 0 ?
                    <FormGroup className="float-right">
                        <Button disabled={this.props.wpqrId === 0} type="button" color="primary" id="generatePdf" name="generatePdf"
                            onClick={(event) => this.onClickGeneratePdfWpqr(this.props.wpqrId, this.props.previewFilename, event)}>
                            {t('Preview')}{"  "}<i className="fas fa-file-pdf"></i>
                        </Button>
                    </FormGroup> : ""
                }
                {this.props.showDownloadPdf ?
                    <div>
                        {this.props.showOnly1Loader === 1 ? <Loader show={this.props.enablePdf} title={t('Downloading')} /> : ""}
                        <Button disabled={this.props.selectedFile === null} color="link" size="sm" name={"downloadPdfNow"} id={"downloadPdfNow"}
                            onClick={(event) => this.onClickDownloadPdf(this.props.selectedFile, event)}>
                                <i className="fas fa-file"></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"downloadPdfNow"}>
                                File!
                            </UncontrolledTooltip>
                    </div> : ""
                }
                {(this.props.showFilesUploadTable) ?
                    <Card>
                        <Loader show={this.props.enablePdf} title={t('Downloading')} />
                        <CardBody>
                            <div>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <span className="btn btn-primary btn-file" >
                                                {t('SelectFilesForUpload')}<input type="file" onChange={event => this.handleChange(event)} />
                                            </span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br />
                                <Row form>
                                    <Col sm={12}>
                                        {this.renderFilesUploadTable()}
                                    </Col>
                                </Row>
                                <Row form hidden={this.props.hiddenSendButtonToCustomer}>
                                    <Col sm={12}>
                                        <br />
                                    </Col>
                                </Row>
                                <Row form hidden={this.props.hiddenSendButtonToCustomer}>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Button disabled={this.props.wpqrId === 0 || this.props.revisionWpqrAction === RevisionActionType.View} type="button" color="primary" id="toCustomer" name="toCustomer"
                                                onClick={(event) => this.handleChangeSendToCustomer(event)}>
                                                {t('SendToCustomer')}{"  "}
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br />
                            </div>
                        </CardBody>
                    </Card> : ""
                }
            </React.Fragment>
        );
    }

    private renderFilesUploadTable() {
        return (
            <React.Fragment key="FileUpload">
                <Table size="sm" responsive className="cert-table">
                    <thead hidden={this.props.hiddenSelectedFileRow}>
                        {this.renderFilesUploadTableHeaderRow()}
                    </thead>
                    <tbody>
                        {this.props.filesUpload.map((row, index) => (
                            this.renderFilesUploadTableRow(index + 1)
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
    private renderFilesUploadTableHeaderRow() {
        return (
            <React.Fragment key="Wparameters141">
                <tr>
                    <th style={{ textAlign: "left" }}><a>Välj endast en fil</a></th>
                    <th style={{ textAlign: "left" }}><a>{t('Name')}</a></th>
                    <th></th>
                    <th></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderFilesUploadTableRow(rowNumber: number) {
        return (
            <React.Fragment key={"Wparameters17" + rowNumber}>
                <tr>
                    <td style={{ textAlign: "left" }} hidden={this.props.hiddenSelectedFileRow}>
                        <FormGroup check>
                            <Label check size="sm">
                                <Input type="checkbox" bssize="sm" id={"selectedFile" + rowNumber} name={"selectedFile" + rowNumber}
                                    checked={this.props.filesUpload[rowNumber - 1].selectedFile}
                                    onChange={(event) => this.onChangeSelectedFile(rowNumber, event)}
                                    placeholder="" />{' '}  {""}
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </Label>
                        </FormGroup>
                    </td>
                    <td style={{ textAlign: "left" }}>
                        <Label for={"fileUpload" + rowNumber} size="sm">{this.props.filesUpload[rowNumber - 1].fileName}</Label>
                    </td>
                    <td>
                        <ButtonGroup vertical>
                            {this.props.filesUpload[rowNumber - 1].fileDescription === "" ?
                                <Button color="link" size="sm" id={"downloadFile" + rowNumber} name={"downloadFile" + rowNumber}
                                    onClick={(event) => this.onClickDownloadFile(this.props.filesUpload[rowNumber - 1].fileName, this.props.filesUpload[rowNumber - 1].filePath, event)}>
                                    <i className="fas fa-download"></i>
                                </Button> :
                                <Button color="link" size="sm" name={"generatePdfRow-" + rowNumber} id={"generatePdfRow-" + rowNumber}
                                    onClick={(event) => this.onClickGeneratePdf(this.props.filesUpload[rowNumber - 1].filePath, this.props.filesUpload[rowNumber - 1].fileDescription, this.props.filesUpload[rowNumber - 1].fileName , event)}>
                                    <i className="fas fa-download"></i>
                                </Button>
                            }
                        </ButtonGroup>
                    </td>
                    <td>
                        <ButtonGroup vertical>
                            <Button color="link" size="sm" id="deleteFile" name="deleteFile"
                                onClick={(event) => this.onClickDeleteFile(rowNumber, event)}>
                                <i className="fas fa-trash"></i>
                            </Button>
                        </ButtonGroup>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.fileUpload;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    FileUploadStore.actionCreators // Selects which action creators are merged into the component's props
)(FileUpload as any);
