﻿
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LanguageStoreState {
    currentLanguage: String;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SetCurrentLanguage {
    type: 'SET_CURRENT_LANG';
    newCurrentLanguage: String;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = SetCurrentLanguage;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    /*getWPQRs: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`wpqrs`)
            .then(response => response.json() as Promise<Array<WPQR>>)
            .then(data => {
                dispatch({ type: 'GET_WPQRS', wpqrs: data });
            });
    },*/
    setNewLang: (newCurrentLanguage: String): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: 'SET_CURRENT_LANG', newCurrentLanguage: newCurrentLanguage });
    }
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: LanguageStoreState = {
    currentLanguage: "se"
};

export const reducer: Reducer<LanguageStoreState> = (state: LanguageStoreState | undefined, incomingAction: Action): LanguageStoreState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_CURRENT_LANG':
            return {
                currentLanguage: action.newCurrentLanguage,
            }; 
        
        default:
            return state;
    }
};
