﻿import { ExistingWpqrDataExtra } from "./ExistingWpqrDataExtra";
import { RangeOfQualificationInfo } from "./RangeOfQualificationInfo";
import { WeldingParameters } from "./WeldingParameters";
import { WeldingTestingDetails1 } from "./WeldingTestingDetails1";
import { GetFilesUploadResponse } from "./responses/GetFilesUploadResponse";
import { GetJointDesignResponse } from "./responses/GetJointDesignResponse";
import { GetWeldSequenceResponse } from "./responses/GetWeldSequenceResponse";

export class ExistingWpqrData {
    details1CommentInformation: string;
    jointDesign: GetJointDesignResponse;
    weldSequence: GetWeldSequenceResponse;
    weldingParameters: Array<WeldingParameters>;
    filesUpload: Array<GetFilesUploadResponse>;
    weldingTestingDetails1: WeldingTestingDetails1;
    rangeOfQualificationInfo: RangeOfQualificationInfo;
    existingWpqrDataExtra: ExistingWpqrDataExtra;
    // ImagesUpload
    imageUpload1: string;
    imageUpload2: string;
    imageValidationError1: string;
    imageValidationError2: string;
    // ExitingWpqr
    existingWpqrId: number;
    existingWpqrStatus: number;
    userId: string;
    customerId: number;
    revisionEWpqrId: number;
    revisionEWpqrAction: string;
    // ExistingWpqrData
    commissionNumber: string;
    dekraProcedureNumber: string;
    manufacturerWPQRNumber: string;
    public constructor() {
        this.existingWpqrId = 0;
        this.details1CommentInformation = '';
        this.jointDesign = new GetJointDesignResponse();
        this.weldSequence = new GetWeldSequenceResponse();
        this.weldingParameters = new Array<WeldingParameters>();
        this.filesUpload = new Array<GetFilesUploadResponse>();
        this.weldingTestingDetails1 = new WeldingTestingDetails1();
        this.rangeOfQualificationInfo = new RangeOfQualificationInfo();
        this.existingWpqrDataExtra = new ExistingWpqrDataExtra();
        // ImagesUpload
        this.imageUpload1 = '';
        this.imageUpload2 = '';
        this.imageValidationError1 = '';
        this.imageValidationError2 = '';
        // ExistingWpqr
        this.existingWpqrId = 0;
        this.existingWpqrStatus = 0;
        this.userId = '';
        this.customerId = 0;
        this.revisionEWpqrId = 0;
        this.revisionEWpqrAction = '';
        // ExistingWpqrData
        this.commissionNumber = '';
        this.dekraProcedureNumber = '';
        this.manufacturerWPQRNumber = '';
    }
}