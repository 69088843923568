﻿export class GetCompanyResponse {
    boxAddress: string;
    city: string;
    contactPerson: string;
    contactPersonEmail: string;
    createdAt: string;
    deletedAt: string;
    id: number;
    name: string;
    phone: string;
    postcode: string;
    streetAddress: string;
    updatedAt: string;

    public constructor() {
        this.boxAddress = '';
        this.city = '';
        this.contactPerson = '';
        this.contactPersonEmail = '';
        this.createdAt = '';
        this.deletedAt = '';
        this.id = 0;
        this.name = '';
        this.phone = '';
        this.postcode = '';
        this.streetAddress = '';
        this.updatedAt = '';
    }
}