﻿import i18next from 'i18next';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Input } from 'reactstrap';
import { t } from '../i18n';

export class LanguageSelect extends React.PureComponent<{ setTabId: any, setChangingLanguage: any, changingLanguage: boolean }, {}> {

    private languageChange = (): void => {
        const confirmText = t('YourChangesWillNotBeSaved_') + '\n' + t('IfYouHaveAnApplication_');
        /*eslint-disable no-restricted-globals*/
        if (confirm(confirmText)) {
            let langSelect = document.getElementById('LanguageSelect') as HTMLInputElement | null;
            localStorage.setItem('lang', langSelect?.value!);
            //let logo = document.getElementsByClassName('nav-link')[0] as HTMLBodyElement;
            i18next.changeLanguage(localStorage.getItem('lang')!);
            //this.props.setChangingLanguage(true);

            this.props.setTabId("");
            this.props.setChangingLanguage(true);
        }        
    }

    public render() {
        return (
        <React.Fragment >
                {
                    this.props.changingLanguage == true &&
                    <Redirect to={{ pathname: "/" }} />
            }
            <Input
                style={{ width: "100%" }}
                type="select" bssize="sm" name="LanguageSelect" id="LanguageSelect"
                onChange={this.languageChange}
                placeholder="">
                <option key={"byStandardId0"} id="byStandardId0" value="0">{t('ChooseLanguage')}</option>
                <option value="en">English</option>
                <option value="se">Svenska</option>
                </Input>
            </React.Fragment>
        );
    }
}