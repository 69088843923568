﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledAlert } from 'reactstrap';
const welDBaseLogo = require('../images/DEKRAwelDBase-scale-alpha.png');
const VJointAsymmetricHalfTJoint15Against11 = require('../images/drawJointDesign/Asymetrisk Halv V-fog 15 mm mot 11 mm.png');
const VJointAsymmetricHalfTJoint15Against15 = require('../images/drawJointDesign/Asymetrisk Halv V-fog 15 mm mot 15 mm.png');
const VJointAsymmetricHalfTJoint15Against7 = require('../images/drawJointDesign/Asymetrisk Halv V-fog 15 mm mot 7 mm.png');
const KJointAsymmetric15Against11 = require('../images/drawJointDesign/Asymetrisk K-fog 15 mm mot 11 mm.png');
const XJointAsymmetric11 = require('../images/drawJointDesign/Asymetrisk X-fog 11 mm.png');
const XJointAsymmetric15 = require('../images/drawJointDesign/Asymetrisk X-fog 15 mm.png');
const XJointAsymmetric7 = require('../images/drawJointDesign/Asymetrisk X-fog 7 mm.png');
const VJointHalf11 = require('../images/drawJointDesign/Halv V-fog 11 mm.png');
const VJointHalf15 = require('../images/drawJointDesign/Halv V-fog 15 mm.png');
const VJointHalf7 = require('../images/drawJointDesign/Halv V-fog 7 mm.png');
const VJointHalfTJoint11 = require('../images/drawJointDesign/Halv V-fog, T-förband 11 mm.png');
const VJointHalfTJoint15 = require('../images/drawJointDesign/Halv V-fog, T-förband 15 mm.png');
const VJointHalfTJoint7 = require('../images/drawJointDesign/Halv V-fog, T-förband 7 mm.png');
const IJoint7 = require('../images/drawJointDesign/I-fog 7 mm.png');
const JJoint15 = require('../images/drawJointDesign/J-fog 15 mm.png');
const KJoint11 = require('../images/drawJointDesign/K-fog 11 mm.png');
const KJoint15 = require('../images/drawJointDesign/K-fog 15 mm.png');
const KJointTJoint11 = require('../images/drawJointDesign/K-fog, T-förband 11 mm.png');
const KJointTJoint15 = require('../images/drawJointDesign/K-fog, T-förband 15 mm.png');
const UJoint11 = require('../images/drawJointDesign/U-fog 11 mm.png');
const UJoint15 = require('../images/drawJointDesign/U-fog 15 mm.png');
const VJoint11 = require('../images/drawJointDesign/V-fog 11 mm.png');
const VJoint15 = require('../images/drawJointDesign/V-fog 15 mm.png');
const VJoint7 = require('../images/drawJointDesign/V-fog 7 mm.png');
const VJointHighStraightEdge11 = require('../images/drawJointDesign/V-fog med hög rätkant 11 mm.png');
const VJointHighStraightEdge15 = require('../images/drawJointDesign/V-fog med hög rätkant 15 mm.png');
const XJoint11 = require('../images/drawJointDesign/X-fog 11 mm.png');
const XJoint15 = require('../images/drawJointDesign/X-fog 15 mm.png');
const XJoint7 = require('../images/drawJointDesign/X-fog 7 mm.png');
const YJoint11 = require('../images/drawJointDesign/Y-fog 11 mm.png');
const YJoint15 = require('../images/drawJointDesign/Y-fog 15 mm.png');
const YJoint7 = require('../images/drawJointDesign/Y-fog 7 mm.png');
// Kälsvets hörn
const KweldingCorner7 = require('../images/drawJointDesign/Kälsvets hörn, 7 mm mot 7 mm.png');
const KweldingCorner11 = require('../images/drawJointDesign/Kälsvets hörn, 11 mm mot 11 mm.png');
const KweldingCorner15 = require('../images/drawJointDesign/Kälsvets hörn, 15 mm mot 15 mm.png');
// Kälsvets(T-förband)
const KweldingT7 = require('../images/drawJointDesign/Kälsvets, T-förband,  7 mm mot 7 mm.png');
const KweldingT11 = require('../images/drawJointDesign/Kälsvets, T-förband,  11 mm mot 11 mm.png');
const KweldingT15 = require('../images/drawJointDesign/Kälsvets, T-förband,  15 mm mot 15 mm.png');
// Kälsvets hörn(Asymmetrisk)
const KweldingCornerAsymmetric3 = require('../images/drawJointDesign/Kälsvets hörn, 15 mm mot 3 mm.png');
const KweldingCornerAsymmetric7 = require('../images/drawJointDesign/Kälsvets hörn, 15 mm mot 7 mm.png');
// Kälsvets hörn(T-förband Asymmetrisk)
const KweldingTAsymmetric3 = require('../images/drawJointDesign/Kälsvets, T-förband 15 mm mot 3 mm.png');
const KweldingTAsymmetric7 = require('../images/drawJointDesign/Kälsvets, T-förband 15 mm mot 7 mm.png');

import * as DrawJointDesignStore from '../store/DrawJointDesignStore';
import { ApplicationState } from '../store/index';
import { GetJointDesignResponse } from '../store/models/responses/GetJointDesignResponse';
import * as htmlToImage from 'html-to-image';
import { t } from '../i18n';

// At runtime, Redux will merge together...
type DrawJointDesignProps =
    DrawJointDesignStore.DrawJointDesignState // ... state we've requested from the Redux store
    & typeof DrawJointDesignStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    show: boolean;
    showJointDesignModalId?: number; // Open only one modal
    modalId?: number;
    toggleShow: () => void;
    jointDesign: GetJointDesignResponse;
    position: string;
    setDrawJointDesignView?: boolean; // Only to View
    imageUpload?: string;
    imageValidationError?: string;
}

class DrawJointDesign extends React.PureComponent<DrawJointDesignProps> {
    public constructor(props: DrawJointDesignProps) {
        super(props);
    }

    public componentDidMount() {
        // Clear all store
        this.props.clearState();
        this.props.getJointTypesAll();
        this.props.getRootSupportsAll();
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.jointDesign !== this.props.jointDesign) {
            if (this.props.setDrawJointDesignView) {
                //TODO this is only to wiew.
                this.props.setJointDesignView(this.props.jointDesign);
            } else {
                this.props.setJointDesign(this.props.jointDesign);
            }
            
        }
        if (prevProps.imageUpload !== this.props.imageUpload) {
            this.props.setHtmlImage(this.props.imageUpload);
        }
    }

    private disableDepthOfPreparation() {
        if (this.props.selectedJointShape.name === 'I-fog'
            || this.props.selectedJointShape.name === 'J-fog'
            || this.props.selectedJointShape.name === 'K-fog'
            || this.props.selectedJointShape.name === 'K-fog (T-förband)'
            || this.props.selectedJointShape.name === 'K-fog (T-förband asymetrisk)'
            || this.props.selectedJointShape.name === 'U-fog'
            || this.props.selectedJointShape.name === 'V-fog'
            || this.props.selectedJointShape.name === 'V-fog (hög rätkant)'
            || this.props.selectedJointShape.name === 'Halv V-fog'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband)'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband asymetrisk)'
            || this.props.selectedJointShape.name === 'Y-fog') {
            this.props.setDepthOfPreparation('');
            return true;
        }
        return false;
    }

    private disableGapOpening() {
        return (false);
    }

    private disableJointAngleAlpha() {
        if (this.props.selectedJointShape.name === 'I-fog'
            || this.props.selectedJointShape.name === 'J-fog'
            || this.props.selectedJointShape.name === 'K-fog'
            || this.props.selectedJointShape.name === 'K-fog (T-förband)'
            || this.props.selectedJointShape.name === 'K-fog (T-förband asymetrisk)'
            || this.props.selectedJointShape.name === 'U-fog'
            || this.props.selectedJointShape.name === 'Halv V-fog'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband)'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband asymetrisk)') {
            this.props.setJointAngleAlpha('');
            return true;
        }
        return false;
    }

    private disableJointAngleBeta() {
        if (this.props.selectedJointShape.name === 'I-fog'
            || this.props.selectedJointShape.name === 'J-fog'
            || this.props.selectedJointShape.name === 'K-fog'
            || this.props.selectedJointShape.name === 'K-fog (T-förband)'
            || this.props.selectedJointShape.name === 'K-fog (T-förband asymetrisk)'
            || this.props.selectedJointShape.name === 'U-fog'
            || this.props.selectedJointShape.name === 'V-fog'
            || this.props.selectedJointShape.name === 'V-fog (hög rätkant)'
            || this.props.selectedJointShape.name === 'Halv V-fog'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband)'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband asymetrisk)'
            || this.props.selectedJointShape.name === 'Y-fog') {
            this.props.setJointAngleBeta('');
            return true;
        }
        return false;
    }

    private disableMaterialThickness1() {
        return (false);
    }

    private disableMaterialThickness2() {
        if (this.props.selectedJointShape.name === 'I-fog'
            || this.props.selectedJointShape.name === 'J-fog'
            || this.props.selectedJointShape.name === 'K-fog'
            || this.props.selectedJointShape.name === 'K-fog (T-förband)'
            || this.props.selectedJointShape.name === 'U-fog'
            || this.props.selectedJointShape.name === 'V-fog'
            || this.props.selectedJointShape.name === 'V-fog (hög rätkant)'
            || this.props.selectedJointShape.name === 'Halv V-fog'
            || this.props.selectedJointShape.name === 'X-fog'
            || this.props.selectedJointShape.name === 'X-fog (asymetrisk)'
            || this.props.selectedJointShape.name === 'Y-fog') {
            this.props.setMaterialThickness2('');
            return true;
        }
        return false;
    }

    private disablePhaseAngle1() {
        if (this.props.selectedJointShape.name === 'I-fog'
            || this.props.selectedJointShape.name === 'V-fog'
            || this.props.selectedJointShape.name === 'V-fog (hög rätkant)'
            || this.props.selectedJointShape.name === 'X-fog'
            || this.props.selectedJointShape.name === 'X-fog (asymetrisk)'
            || this.props.selectedJointShape.name === 'Y-fog') {
            this.props.setPhaseAngle1('');
            return true;
        }
        return false;
    }

    private disablePhaseAngle2() {
        if (this.props.selectedJointShape.name === 'I-fog'
            || this.props.selectedJointShape.name === 'J-fog'
            || this.props.selectedJointShape.name === 'K-fog'
            || this.props.selectedJointShape.name === 'K-fog (T-förband)'
            || this.props.selectedJointShape.name === 'K-fog (T-förband asymetrisk)'
            || this.props.selectedJointShape.name === 'U-fog'
            || this.props.selectedJointShape.name === 'V-fog'
            || this.props.selectedJointShape.name === 'V-fog (hög rätkant)'
            || this.props.selectedJointShape.name === 'Halv V-fog'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband)'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband asymetrisk)'
            || this.props.selectedJointShape.name === 'X-fog'
            || this.props.selectedJointShape.name === 'X-fog (asymetrisk)'
            || this.props.selectedJointShape.name === 'Y-fog') {
            this.props.setPhaseAngle2('');
            return true;
        }
        return false;
    }

    private disableRadius() {
        if (this.props.selectedJointShape.name === 'I-fog'
            || this.props.selectedJointShape.name === 'J-fog'
            || this.props.selectedJointShape.name === 'K-fog'
            || this.props.selectedJointShape.name === 'K-fog (T-förband)'
            || this.props.selectedJointShape.name === 'K-fog (T-förband asymetrisk)'
            || this.props.selectedJointShape.name === 'U-fog'
            || this.props.selectedJointShape.name === 'V-fog'
            || this.props.selectedJointShape.name === 'V-fog (hög rätkant)'
            || this.props.selectedJointShape.name === 'Halv V-fog'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband)'
            || this.props.selectedJointShape.name === 'Halv V-fog (T-förband asymetrisk)'
            || this.props.selectedJointShape.name === 'X-fog'
            || this.props.selectedJointShape.name === 'X-fog (asymetrisk)'
            || this.props.selectedJointShape.name === 'Y-fog') {
            this.props.setRadius('');
            return true;
        }
        return false;
    }

    private disableStraightEdge() {
        if (this.props.selectedJointShape.name === 'I-fog') {
            this.props.setStraightEdge('');
            return true;
        }
        return false;
    }

    private disableThroatThickness() {
        return (false);
        //if (this.props.selectedJointShape.name === 'I-fog'
        //    || this.props.selectedJointShape.name === 'J-fog'
        //    || this.props.selectedJointShape.name === 'K-fog'
        //    || this.props.selectedJointShape.name === 'K-fog (T-förband)'
        //    || this.props.selectedJointShape.name === 'K-fog (T-förband asymetrisk)'
        //    || this.props.selectedJointShape.name === 'U-fog'
        //    || this.props.selectedJointShape.name === 'V-fog'
        //    || this.props.selectedJointShape.name === 'V-fog (hög rätkant)'
        //    || this.props.selectedJointShape.name === 'Halv V-fog'
        //    || this.props.selectedJointShape.name === 'Halv V-fog (T-förband)'
        //    || this.props.selectedJointShape.name === 'Halv V-fog (T-förband asymetrisk)'
        //    || this.props.selectedJointShape.name === 'X-fog'
        //    || this.props.selectedJointShape.name === 'X-fog (asymetrisk)'
        //    || this.props.selectedJointShape.name === 'Y-fog') {
        //    this.props.setThroatThickness('');
        //    return true;
        //}
        //return false;
    }

    private onChangeDepthOfPreparation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDepthOfPreparation(event.currentTarget.value);
    }

    private onChangeGapOpening(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setGapOpening(event.currentTarget.value);
    }

    private onChangeJointAngleAlpha(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setJointAngleAlpha(event.currentTarget.value);
    }

    private onChangeJointAngleBeta(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setJointAngleBeta(event.currentTarget.value);
    }

    private onChangeJointShapes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedJointShape(parseInt(event.currentTarget.value));
    }

    private onChangeJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedJointType(parseInt(event.currentTarget.value));
    }

    private onChangeMaterialThickness1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMaterialThickness1(event.currentTarget.value);
    }

    private onChangeMaterialThickness2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMaterialThickness2(event.currentTarget.value);
    }

    private onChangeNote(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setNote(event.currentTarget.value);
    }

    private onChangePhaseAngle1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setPhaseAngle1(event.currentTarget.value);
    }

    private onChangePhaseAngle2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setPhaseAngle2(event.currentTarget.value);
    }

    private onChangeRadius(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setRadius(event.currentTarget.value);
    }

    private onChangeRootSupports(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedRootSupport(parseInt(event.currentTarget.value));
    }

    private onChangeStraightEdge(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setStraightEdge(event.currentTarget.value);
    }

    private onChangeThroatThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setThroatThickness(event.currentTarget.value);
    }

    private onClickToggleShowModalOk(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        var canvas = (document.getElementById('canvas') as HTMLCanvasElement);
        var ctx = canvas.getContext("2d");
        // Html to Image
        const node = document.getElementById("htmlToImage")!;
        var dataurl = "";
        (async () => {
            await htmlToImage.toPng(node)
                .then(function (dataUrl) {
                    var img = new Image();
                    img.src = dataUrl;
                    dataurl = dataUrl;
                    //document.body.appendChild(img);
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
            const htmlImage = new Image();
            htmlImage.src = dataurl;

            var jointShapes = "X-fog (asymetrisk), X-fog, I-fog"; // The width is to big, resize
            var heightMaxLimit = 250;
            var cutToWidth = 500;

            if (jointShapes.indexOf(this.props.selectedJointShape.name) !== -1) {
                const aspectRatio = htmlImage.width / htmlImage.height;
                let newWidth = cutToWidth;
                let newHeight = newWidth / aspectRatio;
                canvas.width = newWidth;
                canvas.height = newHeight;
                ctx.drawImage(htmlImage, 0, 0, newWidth, newHeight);
            }
            else if (htmlImage.height > heightMaxLimit) {
                const aspectRatio = htmlImage.height / htmlImage.width;
                let newWidth = htmlImage.height / aspectRatio;
                let newHeight = 210; // 
                canvas.width = newWidth;
                canvas.height = newHeight;
                ctx.drawImage(htmlImage, 0, 0, newWidth, newHeight);
                var startX = newWidth / 2 - cutToWidth / 2;
                const imageData = ctx.getImageData(startX, 0, cutToWidth, newHeight);
                canvas.width = cutToWidth;
                ctx.putImageData(imageData, 0, 0);
            }
            else {
                canvas.width = cutToWidth;
                canvas.height = htmlImage.height;
                var startXX = htmlImage.width / 2 - cutToWidth / 2;
                ctx.drawImage(htmlImage, startXX, 0, cutToWidth, htmlImage.height, 0, 0, cutToWidth, htmlImage.height);
            }
            this.props.setHtmlImage((document.getElementById('canvas') as HTMLCanvasElement).toDataURL("image/png", 1));
            this.props.toggleShow();
        })();

        //if (this.props.setDrawJointDesignView) {
        //    this.props.setJointDesign(this.props.jointDesignSaved);
        //}
    }
    private onClickToggleShowModalCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.props.toggleShow();
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <canvas hidden={true} id="canvas"></canvas>
                <FormGroup className="text-center image-auto-with">
                    {this.props.setDrawJointDesignView ?
                        <img className={this.props.jointDesignView.jointDesignHtmlImage !== "" ? "image-draw-joint-design" : ""} src={this.props.jointDesignView.jointDesignHtmlImage !== "" ? this.props.jointDesignView.jointDesignHtmlImage : welDBaseLogo} alt="DEKRA" /> :
                        <img className={this.props.jointDesignHtmlImage !== "" ? "image-draw-joint-design" : ""} src={this.props.jointDesignHtmlImage !== "" ? this.props.jointDesignHtmlImage : welDBaseLogo} alt="DEKRA" />
                    }
                </FormGroup>
                {this.props.imageValidationError === "type" ?
                    <Row form>
                        <Col sm={12}>
                            <UncontrolledAlert color="danger">
                                {t('TheImageIsOfTheWrongType')}
                            </UncontrolledAlert>
                        </Col>
                    </Row> : null
                }
                {this.props.imageValidationError === "size" ?
                    <Row form>
                        <Col sm={12}>
                            <UncontrolledAlert color="danger">
                                {t('TheImageIsLargeThan')}
                            </UncontrolledAlert>
                        </Col>
                    </Row> : null
                }
                <Modal isOpen={this.props.show && this.props.modalId === this.props.showJointDesignModalId} onOpened={() => this.props.initForm(this.props.position, this.props.setDrawJointDesignView)} size="lg">
                        <ModalHeader>
                        {t('DrawJointDesign')}
                    </ModalHeader>
                        <ModalBody>
                            <Form>
                                <Row form>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <CardText>
                                                    <Row form>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignMaterialThickness1" size="sm">{t('ProductThickness')} (mm)</Label>
                                                                <Input type="text" disabled={this.disableMaterialThickness1()} bsSize="sm" name="drawJointDesignMaterialThickness1" id="drawJointDesignMaterialThickness1"
                                                                    value={this.props.materialThickness1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMaterialThickness1(event)}
                                                                    placeholder="" />
                                                                <small className="text-muted">Del 1</small>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignMaterialThickness2" size="sm">{t('ProductThickness')} (mm)</Label>
                                                                <Input type="text" disabled={this.disableMaterialThickness2()} bsSize="sm" name="drawJointDesignMaterialThickness2" id="drawJointDesignMaterialThickness2"
                                                                    value={this.props.materialThickness2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMaterialThickness2(event)}
                                                                    placeholder="" />
                                                                <small className="text-muted">Del 2</small>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignJointTypes" size="sm">{t('JointType')} BW/FW</Label>
                                                                <Input type="select" bsSize="sm" name="drawJointDesignJointTypes" id="drawJointDesignJointTypes"
                                                                    value={this.props.selectedJointType.id}
                                                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeJointTypes(event)}
                                                                    placeholder="">
                                                                    <option key="jointTypeId0" id="jointTypeId0" value="0"></option>
                                                                    {this.props.jointTypes.map((jointType, index) => (
                                                                        <option key={"jointTypeId" + jointType.id} id={"jointTypeId" + jointType.id} value={jointType.id}>{jointType.name}</option>
                                                                    ))}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignJointShapes" size="sm">{t('JointShape')} (ISO 9692)</Label>
                                                                <Input type="select" bsSize="sm" name="drawJointDesignJointShapes" id="drawJointDesignJointShapes"
                                                                    value={this.props.selectedJointShape.id}
                                                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeJointShapes(event)}
                                                                    placeholder="">
                                                                    <option key="jointShapeId0" id="jointShapeId0" value="0"></option>
                                                                    {this.props.selectedJointType.jointShapes.map((jointShape, index) => (
                                                                        <option key={"jointShapeId" + jointShape.id} id={"jointShapeId" + jointShape.id} value={jointShape.id}>{jointShape.name}</option>
                                                                    ))}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignJointAngleAlpha" size="sm">{t('JointAngle')} 1 (α) (°)</Label>
                                                                <Input type="text" disabled={this.disableJointAngleAlpha()} bsSize="sm" name="drawJointDesignJointAngleAlpha" id="drawJointDesignJointAngleAlpha"
                                                                    value={this.props.jointAngleAlpha} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeJointAngleAlpha(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignGapOpening" size="sm">{t('GapOpening')} (mm)</Label>
                                                                <Input type="text" disabled={this.disableGapOpening()} bsSize="sm" name="drawJointDesignGapOpening" id="drawJointDesignGapOpening"
                                                                    value={this.props.gapOpening} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeGapOpening(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignStraightEdge" size="sm">{t('StraightEdge')} (mm)</Label>
                                                                <Input type="text" disabled={this.disableStraightEdge()} bsSize="sm" name="drawJointDesignStraightEdge" id="drawJointDesignStraightEdge"
                                                                    value={this.props.straightEdge} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeStraightEdge(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignPhaseAngle1" size="sm">{t('PhaseAngle')} 1 (β) (°)</Label>
                                                                <Input type="text" disabled={this.disablePhaseAngle1()} bsSize="sm" name="drawJointDesignPhaseAngle1" id="drawJointDesignPhaseAngle1"
                                                                    value={this.props.phaseAngle1} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangePhaseAngle1(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignPhaseAngle2" size="sm">{t('PhaseAngle')} 2 (β) (°)</Label>
                                                                <Input type="text" disabled={this.disablePhaseAngle2()} bsSize="sm" name="drawJointDesignPhaseAngle2" id="drawJointDesignPhaseAngle2"
                                                                    value={this.props.phaseAngle2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangePhaseAngle2(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignJointAngleBeta" size="sm">{t('JointAngle')} 2 (α) (°)</Label>
                                                                <Input type="text" disabled={this.disableJointAngleBeta()} bsSize="sm" name="drawJointDesignJointAngleBeta" id="drawJointDesignJointAngleBeta"
                                                                    value={this.props.jointAngleBeta} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeJointAngleBeta(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="drawJointDesignThroatThickness" size="sm">a-mått (mm)</Label>
                                                                <Input type="text" disabled={this.disableThroatThickness()} bsSize="sm" name="drawJointDesignThroatThickness" id="drawJointDesignThroatThickness"
                                                                    value={this.props.throatThickness} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeThroatThickness(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignRootSupports" size="sm">{t('RootSupports')}</Label>
                                                                <Input type="select" bsSize="sm" name="drawJointDesignRootSupports" id="drawJointDesignRootSupports"
                                                                    value={this.props.selectedRootSupport.id}
                                                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeRootSupports(event)}
                                                                    placeholder="">
                                                                    <option key="rootSupportId0" id="rootSupportId0" value="0"></option>
                                                                    {this.props.rootSupports.map((rootSupport, index) => (
                                                                        <option key={"rootSupportId" + rootSupport.id} id={"rootSupportId" + rootSupport.id} value={rootSupport.id}>{rootSupport.name}</option>
                                                                    ))}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignDepthOfPreparation" size="sm">{t('DepthOfPreparation')} (h) (mm)</Label>
                                                                <Input type="text" disabled={this.disableDepthOfPreparation()} bsSize="sm" name="drawJointDesignDepthOfPreparation" id="drawJointDesignDepthOfPreparation"
                                                                    value={this.props.depthOfPreparation} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeDepthOfPreparation(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignRadius" size="sm">{t('Radius')} (R) (mm)</Label>
                                                                <Input type="text" disabled={this.disableRadius()} bsSize="sm" name="drawJointDesignRadius" id="drawJointDesignRadius"
                                                                    value={this.props.radius} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeRadius(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                            <Label for="drawJointDesignNote" size="sm">{t('Note')}</Label>
                                                                <Input type="textarea" bsSize="sm" name="drawJointDesignNote" id="drawJointDesignNote"
                                                                    value={this.props.note} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeNote(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                    <br />
                                    </Col>
                                </Row>
                            <Row form>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <CardText>
                                                <Row form>
                                                        <Col sm={12}>
                                                        <FormGroup className="text-center">
                                                            <div id="htmlToImage" className="html-to-image">
                                                                {this.selectIJoint7() &&
                                                                    this.renderIJoint7()
                                                                }
                                                                {this.selectJJoint15() &&
                                                                    this.renderJJoint15()
                                                                }
                                                                {this.selectKJoint11() &&
                                                                    this.renderKJoint11()
                                                                }
                                                                {this.selectKJoint15() &&
                                                                    this.renderKJoint15()
                                                                }
                                                                {this.selectKJointAsymmetric15Against11() &&
                                                                    this.renderKJointAsymmtric15Against11()
                                                                }
                                                                {this.selectKJointTJoint11() &&
                                                                    this.renderKJointTJoint11()
                                                                }
                                                                {this.selectKJointTJoint15() &&
                                                                    this.renderKJointTJoint15()
                                                                }
                                                                {this.selectUJoint11() &&
                                                                    this.renderUJoint11()
                                                                }
                                                                {this.selectUJoint15() &&
                                                                    this.renderUJoint15()
                                                                }
                                                                {this.selectVJoint7() &&
                                                                    this.renderVJoint7()
                                                                }
                                                                {this.selectVJoint11() &&
                                                                    this.renderVJoint11()
                                                                }
                                                                {this.selectVJoint15() &&
                                                                    this.renderVJoint15()
                                                                }
                                                                {this.selectVJointHalf7() &&
                                                                    this.renderVJointHalf7()
                                                                }
                                                                {this.selectVJointHalf11() &&
                                                                    this.renderVJointHalf11()
                                                                }
                                                                {this.selectVJointHalf15() &&
                                                                    this.renderVJointHalf15()
                                                                }
                                                                {this.selectVJointHalfTJoint7() &&
                                                                    this.renderVJointHalfTJoint7()
                                                                }
                                                                {this.selectVJointHalfTJoint11() &&
                                                                    this.renderVJointHalfTJoint11()
                                                                }
                                                                {this.selectVJointHalfTJoint15() &&
                                                                    this.renderVJointHalfTJoint15()
                                                                }
                                                                {this.selectVJointHalfTJoint15Against7() &&
                                                                    this.renderVJointAsymmetricHalfTJoint15Against7()
                                                                }
                                                                {this.selectVJointHalfTJoint15Against11() &&
                                                                    this.renderVJointAsymmetricHalfTJoint15Against11()
                                                                }
                                                                {this.selectVJointHalfTJoint15Against15() &&
                                                                    this.renderVJointAsymmetricHalfTJoint15Against15()
                                                                }
                                                                {this.selectVJointHighStraightEdge11() &&
                                                                    this.renderVJointHighStraightEdge11()
                                                                }
                                                                {this.selectVJointHighStraightEdge15() &&
                                                                    this.renderVJointHighStraightEdge15()
                                                                }
                                                                {this.selectXJoint7() &&
                                                                    this.renderXJoint7()
                                                                }
                                                                {this.selectXJoint11() &&
                                                                    this.renderXJoint11()
                                                                }
                                                                {this.selectXJoint15() &&
                                                                    this.renderXJoint15()
                                                                }
                                                                {this.selectXJointAsymmetric7() &&
                                                                    this.renderXJointAsymetrisk7()
                                                                }
                                                                {this.selectXJointAsymmetric11() &&
                                                                    this.renderXJointAsymetrisk11()
                                                                }
                                                                {this.selectXJointAsymmetric15() &&
                                                                    this.renderXJointAsymetrisk15()
                                                                }
                                                                {this.selectWelDBaseLogo() &&
                                                                    this.renderWelDBaseLogo()
                                                                }
                                                                {this.selectYJoint7() &&
                                                                    this.renderYJoint7()
                                                                }
                                                                {this.selectYJoint11() &&
                                                                    this.renderYJoint11()
                                                                }
                                                                {this.selectYJoint15() &&
                                                                    this.renderYJoint15()
                                                                }
                                                                {this.selectKweldingCorner7() &&
                                                                    this.renderKweldingCorner7()
                                                                }
                                                                {this.selectKweldingCorner11() &&
                                                                    this.renderKweldingCorner11()
                                                                }
                                                                {this.selectKweldingCorner15() &&
                                                                    this.renderKweldingCorner15()
                                                                }
                                                                {this.selectKweldingT7() &&
                                                                    this.renderKweldingT7()
                                                                }
                                                                {this.selectKweldingT11() &&
                                                                    this.renderKweldingT11()
                                                                }
                                                                {this.selectKweldingT15() &&
                                                                    this.renderKweldingT15()
                                                                }
                                                                {this.selectKweldingCornerAsymmetric3() &&
                                                                    this.renderKweldingCornerAsymmetric3()
                                                                }
                                                                {this.selectKweldingCornerAsymmetric7() &&
                                                                    this.renderKweldingCornerAsymmetric7()
                                                                }
                                                                {this.selectKweldingTAsymmetric3() &&
                                                                    this.renderKweldingTAsymmetric3()
                                                                }
                                                                {this.selectKweldingTAsymmetric7() &&
                                                                    this.renderKweldingTAsymmetric7()
                                                                }
                                                                
                                                            </div>
                                                            </FormGroup>
                                                    </Col>
                                                    </Row>
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    <ModalFooter justify-content-between>
                    <Button color="primary" onClick={(event) => this.onClickToggleShowModalOk(event)}>OK</Button>
                        {' '}
                    <Button color="secondary" onClick={(event) => this.onClickToggleShowModalCancel(event)}>Avbryt</Button>
                    </ModalFooter>
                    </Modal> 
            </React.Fragment>
        );
    }

    private renderWelDBaseLogo() {
        return (
            <React.Fragment>
                <img src={welDBaseLogo} alt="welDBase" />
            </React.Fragment>
        );
    }

    private renderIJoint7() {
        return (
            <React.Fragment>
                <img src={IJoint7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="i-joint-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="i-joint-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderJJoint15() {
        return (
            <React.Fragment>
                <img src={JJoint15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="j-joint-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="j-joint-15-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="j-joint-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="j-joint-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKJoint11() {
        return (
            <React.Fragment>
                <img src={KJoint11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-joint-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-11-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="k-joint-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-11-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKJoint15() {
        return (
            <React.Fragment>
                <img src={KJoint15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-joint-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-15-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="k-joint-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKJointAsymmtric15Against11() {
        return (
            <React.Fragment>
                <img src={KJointAsymmetric15Against11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-joint-asymmetric-15-against-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-asymmetric-15-against-11-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-asymmetric-15-against-11-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="k-joint-asymmetric-15-against-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-asymmetric-15-against-11-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKJointTJoint11() {
        return (
            <React.Fragment>
                <img src={KJointTJoint11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-joint-t-joint-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-t-joint-11-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="k-joint-t-joint-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-t-joint-11-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKJointTJoint15() {
        return (
            <React.Fragment>
                <img src={KJointTJoint15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-joint-t-joint-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-t-joint-15-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="k-joint-t-joint-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-joint-t-joint-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderUJoint11() {
        return (
            <React.Fragment>
                <img src={UJoint11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="u-joint-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="u-joint-11-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="u-joint-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="u-joint-11-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderUJoint15() {
        return (
            <React.Fragment>
                <img src={UJoint15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="u-joint-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="u-joint-15-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="u-joint-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="u-joint-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJoint7() {
        return (
            <React.Fragment>
                <img src={VJoint7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-7-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-7-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJoint11() {
        return (
            <React.Fragment>
                <img src={VJoint11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-11-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-11-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJoint15() {
        return (
            <React.Fragment>
                <img src={VJoint15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-15-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
    private renderVJointHalf7() {
        return (
            <React.Fragment>
                <img src={VJointHalf7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-half-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-7-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-half-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-7-straight-edge">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointHalf11() {
        return (
            <React.Fragment>
                <img src={VJointHalf11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-half-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-11-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-half-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-11-straight-edge">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointHalf15() {
        return (
            <React.Fragment>
                <img src={VJointHalf15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-half-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-15-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-half-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointHalfTJoint7() {
        return (
            <React.Fragment>
                <img src={VJointHalfTJoint7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-asymmetric-half-t-joint-15-against-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-7-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-7-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-7-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointHalfTJoint11() {
        return (
            <React.Fragment>
                <img src={VJointHalfTJoint11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-asymmetric-half-t-joint-15-against-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-11-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-11-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-11-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointHalfTJoint15() {
        return (
            <React.Fragment>
                <img src={VJointHalfTJoint15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-asymmetric-half-t-joint-15-against-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-15-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-15-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-asymmetric-half-t-joint-15-against-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointAsymmetricHalfTJoint15Against7() {
        return (
            <React.Fragment>
                <img src={VJointAsymmetricHalfTJoint15Against7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-half-t-joint-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-t-joint-7-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-half-t-joint-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-t-joint-7-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointAsymmetricHalfTJoint15Against11() {
        return (
            <React.Fragment>
                <img src={VJointAsymmetricHalfTJoint15Against11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-half-t-joint-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-t-joint-11-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-half-t-joint-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-t-joint-11-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointAsymmetricHalfTJoint15Against15() {
        return (
            <React.Fragment>
                <img src={VJointAsymmetricHalfTJoint15Against15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-half-t-joint-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-t-joint-15-phase-angle-1">
                        {this.props.phaseAngle1}
                        {this.props.phaseAngle1 &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-half-t-joint-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-half-t-joint-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointHighStraightEdge11() {
        return (
            <React.Fragment>
                <img src={VJointHighStraightEdge11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-high-straight-edge-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-high-straight-edge-11-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-high-straight-edge-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-high-straight-edge-11-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderVJointHighStraightEdge15() {
        return (
            <React.Fragment>
                <img src={VJointHighStraightEdge15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="v-joint-high-straight-edge-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-high-straight-edge-15-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="v-joint-high-straight-edge-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="v-joint-high-straight-edge-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderXJoint7() {
        return (
            <React.Fragment >
                <img src={XJoint7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="x-fog-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-7-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-7-joint-angle-beta">
                        {this.props.jointAngleBeta}
                        {this.props.jointAngleBeta &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-7-depth-of-preparation">
                        {this.props.depthOfPreparation}
                        {this.props.depthOfPreparation &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderXJoint11() {
        return (
            <React.Fragment>
                <img src={XJoint11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="x-fog-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-11-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-11-joint-angle-beta">
                        {this.props.jointAngleBeta}
                        {this.props.jointAngleBeta &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-11-depth-of-preparation">
                        {this.props.depthOfPreparation}
                        {this.props.depthOfPreparation &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderXJoint15() {
        return (
            <React.Fragment>
                <img src={XJoint15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="x-fog-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-15-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-15-joint-angle-beta">
                        {this.props.jointAngleBeta}
                        {this.props.jointAngleBeta &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-15-depth-of-preparation">
                        {this.props.depthOfPreparation}
                        {this.props.depthOfPreparation &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderXJointAsymetrisk7() {
        return (
            <React.Fragment>
                <img src={XJointAsymmetric7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="x-fog-asymetrisk-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-7-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-7-joint-angle-beta">
                        {this.props.jointAngleBeta}
                        {this.props.jointAngleBeta &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-7-depth-of-preparation">
                        {this.props.depthOfPreparation}
                        {this.props.depthOfPreparation &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderXJointAsymetrisk11() {
        return (
            <React.Fragment>
                <img src={XJointAsymmetric11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="x-fog-asymetrisk-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-11-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-11-joint-angle-beta">
                        {this.props.jointAngleBeta}
                        {this.props.jointAngleBeta &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-11-depth-of-preparation">
                        {this.props.depthOfPreparation}
                        {this.props.depthOfPreparation &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderXJointAsymetrisk15() {
        return (
            <React.Fragment>
                <img src={XJointAsymmetric15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="x-fog-asymetrisk-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-15-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-15-joint-angle-beta">
                        {this.props.jointAngleBeta}
                        {this.props.jointAngleBeta &&
                            <span> °</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="x-fog-asymetrisk-15-depth-of-preparation">
                        {this.props.depthOfPreparation}
                        {this.props.depthOfPreparation &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderYJoint7() {
        return (
            <React.Fragment>
                <img src={YJoint7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="y-joint-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="y-joint-7-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="y-joint-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="y-joint-7-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderYJoint11() {
        return (
            <React.Fragment>
                <img src={YJoint11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="y-joint-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="y-joint-11-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="y-joint-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="y-joint-11-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderYJoint15() {
        return (
            <React.Fragment>
                <img src={YJoint15} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="y-joint-15-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="y-joint-15-joint-angle-alpha">
                        {this.props.jointAngleAlpha}
                        {this.props.jointAngleAlpha &&
                            <span> °</span>
                        }
                    </div>
                    <div className="y-joint-15-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="y-joint-15-straight-edge">
                        {this.props.straightEdge}
                        {this.props.straightEdge &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKweldingCorner7() {
        return (
            <React.Fragment>
                <img src={KweldingCorner7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-welding-corner-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-corner-7-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-corner-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKweldingCorner11() {
        return (
            <React.Fragment>
                <img src={KweldingCorner11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-welding-corner-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-corner-11-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-corner-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKweldingCorner15() {
        return (
            <React.Fragment>
                <img src={KweldingCorner15} alt="DEKRA" />
                <div className="k-welding-corner-15-material-thickness1">
                    {this.props.materialThickness1}
                    {this.props.materialThickness1 &&
                        <span> mm</span>
                    }
                </div>
                <div className="k-welding-corner-15-material-thickness2">
                    {this.props.materialThickness2}
                    {this.props.materialThickness2 &&
                        <span> mm</span>
                    }
                </div>
                <div className="k-welding-corner-15-gap-opening">
                    {this.props.gapOpening}
                    {this.props.gapOpening &&
                        <span> mm</span>
                    }
                </div>
            </React.Fragment>
        );
    }

    private renderKweldingT7() {
        return (
            <React.Fragment>
                <img src={KweldingT7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-welding-t-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-t-7-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-t-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKweldingT11() {
        return (
            <React.Fragment>
                <img src={KweldingT11} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-welding-t-11-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-t-11-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-t-11-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKweldingT15() {
        return (
            <React.Fragment>
                <img src={KweldingT15} alt="DEKRA" />
                <div className="k-welding-t-15-material-thickness1">
                    {this.props.materialThickness1}
                    {this.props.materialThickness1 &&
                        <span> mm</span>
                    }
                </div>
                <div className="k-welding-t-15-material-thickness2">
                    {this.props.materialThickness2}
                    {this.props.materialThickness2 &&
                        <span> mm</span>
                    }
                </div>
                <div className="k-welding-t-15-gap-opening">
                    {this.props.gapOpening}
                    {this.props.gapOpening &&
                        <span> mm</span>
                    }
                </div>
            </React.Fragment>
        );
    }

    private renderKweldingCornerAsymmetric3() {
        return (
            <React.Fragment>
                <img src={KweldingCornerAsymmetric3} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-welding-corner-asymmetric-3-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-corner-asymmetric-3-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-corner-asymmetric-3-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKweldingCornerAsymmetric7() {
        return (
            <React.Fragment>
                <img src={KweldingCornerAsymmetric7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-welding-corner-asymmetric-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-corner-asymmetric-7-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-corner-asymmetric-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
    private renderKweldingTAsymmetric3() {
        return (
            <React.Fragment>
                <img src={KweldingTAsymmetric3} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-welding-t-asymmetric-3-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-t-asymmetric-3-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-t-asymmetric-3-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderKweldingTAsymmetric7() {
        return (
            <React.Fragment>
                <img src={KweldingTAsymmetric7} alt="DEKRA" />
                <div className="draw-joint-design-container">
                    <div className="k-welding-t-asymmetric-7-material-thickness1">
                        {this.props.materialThickness1}
                        {this.props.materialThickness1 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-t-asymmetric-7-material-thickness2">
                        {this.props.materialThickness2}
                        {this.props.materialThickness2 &&
                            <span> mm</span>
                        }
                    </div>
                    <div className="k-welding-t-asymmetric-7-gap-opening">
                        {this.props.gapOpening}
                        {this.props.gapOpening &&
                            <span> mm</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private selectIJoint7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'I-fog' && materialThickness1 > 0);
    }

    private selectJJoint15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'J-fog' && materialThickness1 > 0);
    }

    private selectKJoint11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'K-fog' && materialThickness1 > 0 && materialThickness1 <= 11);
    }

    private selectKJoint15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'K-fog' && materialThickness1 > 11);
    }

    private selectKJointAsymmetric15Against11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        const materialThickness2: number = this.checkIfIsNaN(this.props.materialThickness2);
        return (this.props.selectedJointShape.name === 'K-fog (T-förband asymetrisk)' && materialThickness1 > 0 && materialThickness2 > 0);
    }

    private selectKJointTJoint11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'K-fog (T-förband)' && materialThickness1 > 0 && materialThickness1 <= 11);
    }

    private selectKJointTJoint15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'K-fog (T-förband)' && materialThickness1 > 11);
    }

    private selectUJoint11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'U-fog' && materialThickness1 > 0 && materialThickness1 <= 11);
    }

    private selectUJoint15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'U-fog' && materialThickness1 > 11);
    }

    private selectVJoint7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'V-fog' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectVJoint11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'V-fog' && materialThickness1 > 7 && materialThickness1 <= 11);
    }

    private selectVJoint15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'V-fog' && materialThickness1 > 11);
    }

    private selectVJointHalf7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Halv V-fog' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectVJointHalf11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Halv V-fog' && materialThickness1 > 7 && materialThickness1 <= 11);
    }

    private selectVJointHalf15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Halv V-fog' && materialThickness1 > 11);
    }

    private selectVJointHalfTJoint7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Halv V-fog (T-förband)' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectVJointHalfTJoint11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Halv V-fog (T-förband)' && materialThickness1 > 7 && materialThickness1 <= 11);
    }

    private selectVJointHalfTJoint15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Halv V-fog (T-förband)' && materialThickness1 > 11);
    }

    private selectVJointHalfTJoint15Against7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Halv V-fog (T-förband asymetrisk)' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectVJointHalfTJoint15Against11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Halv V-fog (T-förband asymetrisk)' && materialThickness1 > 7 && materialThickness1 <= 11);
    }

    private selectVJointHalfTJoint15Against15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Halv V-fog (T-förband asymetrisk)' && materialThickness1 > 11);
    }

    private selectVJointHighStraightEdge11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'V-fog (hög rätkant)' && materialThickness1 > 0 && materialThickness1 <= 11);
    }

    private selectVJointHighStraightEdge15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'V-fog (hög rätkant)' && materialThickness1 > 11);
    }

    private selectWelDBaseLogo() {
        return (this.props.selectedJointShape.id === 0);
    }

    private selectXJoint7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'X-fog' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectXJoint11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'X-fog' && materialThickness1 > 7 && materialThickness1 <= 11);
    }

    private selectXJoint15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'X-fog' && materialThickness1 > 11);
    }

    private selectXJointAsymmetric7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'X-fog (asymetrisk)' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectXJointAsymmetric11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'X-fog (asymetrisk)' && materialThickness1 > 7 && materialThickness1 <= 11);
    }

    private selectXJointAsymmetric15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'X-fog (asymetrisk)' && materialThickness1 > 11);
    }

    private selectYJoint7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Y-fog' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectYJoint11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Y-fog' && materialThickness1 > 7 && materialThickness1 <= 11);
    }

    private selectYJoint15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Y-fog' && materialThickness1 > 11);
    }

    private selectKweldingCorner7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (hörn)' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectKweldingCorner11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (hörn)' && materialThickness1 > 7 && materialThickness1 <= 11);
    }

    private selectKweldingCorner15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (hörn)' && materialThickness1 > 11);
    }

    //private selectKweldingT3() {
    //    const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
    //    return (this.props.selectedJointShape.name === 'Kälsvets (T-förband)' && materialThickness1 > 0 && materialThickness1 <= 3);
    //}

    private selectKweldingT7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (T-förband)' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectKweldingT11() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (T-förband)' && materialThickness1 > 7 && materialThickness1 <= 11);
    }

    private selectKweldingT15() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (T-förband)' && materialThickness1 > 11);
    }

    private selectKweldingCornerAsymmetric3() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (hörn asymetrisk)' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectKweldingCornerAsymmetric7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (hörn asymetrisk)' && materialThickness1 > 7);
    }

    private selectKweldingTAsymmetric3() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (T-förband asymetrisk)' && materialThickness1 > 0 && materialThickness1 <= 7);
    }

    private selectKweldingTAsymmetric7() {
        const materialThickness1: number = this.checkIfIsNaN(this.props.materialThickness1);
        return (this.props.selectedJointShape.name === 'Kälsvets (T-förband asymetrisk)' && materialThickness1 > 7);
    }

    private checkIfIsNaN(value: string) {
        const materialThickness: number = isNaN(parseInt(value)) ? 10 : parseInt(value);
        return (materialThickness);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.drawJointDesign;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    DrawJointDesignStore.actionCreators // Selects which action creators are merged into the component's props
)(DrawJointDesign as any);
