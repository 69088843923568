﻿import { UserListObject } from '../UserListObject';
import { WeldingParameters } from "./WeldingParameters";

export class WeldingTestingDetails1 {
    backingGasFlowRate: string;
    backingGasFlux: string;
    chargeNumberHeatNumber: string;
    chargeNumberLotNumberSerialNumber: string;
    detailsOfGougingBacking: string;
    examiner: string;
    examinerSignature: string;
    examinerName: string;
    examinerDate: string;
    fillerMaterialDesignation: string;
    fillerMaterialMake: string;
    fillerMaterialTradeName: string;
    heatingAndCoolingRates: string;
    interpassTemperature: string;
    jointType: string;
    location: string;
    manufacturer: string;
    manufacturerOfPowerSource: string;
    manufacturerPWPSNumber: string;
    materialThickness: string;
    methodOfPreparationAndCleaning: string;
    nozzleDiameter: string;
    otherInformation: string;
    outsideDiameter: string;
    parentMaterialGroup: string;
    parentMaterialNameOrNumber: string;
    postWeldHeatTreatmentAndOrAgeing: string;
    preheatTemperature: string;
    shieldingGasFlowRate: string;
    shieldingGasFlux: string;
    standOffDistance: string;
    technicalDeliveryStandard: string;
    torchAngle: string;
    tungstenElectrode: string;
    weaving: string;
    welderName: string;
    weldingPosition: string;
    weldingPosition2: string;
    weldingProcess: string;
    manufacturer2: string;
    manufacturer2Signature: string;
    manufacturer2Name: string;
    manufacturer2Date: string;
    tackWeldText: string;
    lengthTackWelds: string;
    arcType: string;
    commentInformation: string | null | undefined;
    electrodeTorchAngle111: string | null | undefined;
    plasmaflowrate: string | null | undefined;
    plasmaGasName: string | null | undefined;
    users: Array<UserListObject>;

    public constructor() {
        this.backingGasFlowRate = '';
        this.backingGasFlux = '';
        this.chargeNumberHeatNumber = '';
        this.chargeNumberLotNumberSerialNumber = '';
        this.detailsOfGougingBacking = '';
        this.examiner = '';
        this.examinerSignature = '';
        this.examinerName = '';
        this.examinerDate = '';
        this.fillerMaterialDesignation = '';
        this.fillerMaterialMake = '';
        this.fillerMaterialTradeName = '';
        this.heatingAndCoolingRates = '';
        this.interpassTemperature = '';
        this.jointType = '';
        this.location = '';
        this.manufacturer = '';
        this.manufacturerOfPowerSource = '';
        this.manufacturerPWPSNumber = '';
        this.manufacturer2 = '';
        this.manufacturer2Signature = '';
        this.manufacturer2Name = '';
        this.manufacturer2Date = '';
        this.materialThickness = '';
        this.methodOfPreparationAndCleaning = '';
        this.nozzleDiameter = '';
        this.otherInformation = '';
        this.outsideDiameter = '';
        this.parentMaterialGroup = '';
        this.parentMaterialNameOrNumber = '';
        this.postWeldHeatTreatmentAndOrAgeing = '';
        this.preheatTemperature = '';
        this.shieldingGasFlowRate = '';
        this.shieldingGasFlux = '';
        this.standOffDistance = '';
        this.technicalDeliveryStandard = '';
        this.torchAngle = '';
        this.tungstenElectrode = '';
        this.weaving = '';
        this.welderName = '';
        this.weldingPosition = '';
        this.weldingPosition2 = '';
        this.weldingProcess = '';
        this.tackWeldText = '';
        this.lengthTackWelds = '';
        this.arcType = '';
        this.commentInformation = '';
        this.electrodeTorchAngle111 = '';
        this.plasmaflowrate = '';
        this.plasmaGasName = '';
        this.users = new Array<UserListObject>();
    }
}