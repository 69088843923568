﻿export class RangeOfQualificationInfo {
    address: string;
    dateOfIssue: string;
    dateOfWelding: string;
    location: string;
    manufacturer: string;
    testingStandardId: number;
    weldingProcess: string;
    jointType: string;
    materialThicknesses: string;
    designations: string;
    shieldingGases: string;
    currentTypes: string;
    dropletTransitions: string;
    makes: string;
    otherInformations: string;
    weldingMetalThicknessText: string;
    enableWeldingMetalThicknessText: boolean;
    materialGroupText: string;
    enableMaterialGroupText: boolean;
    enableMaterialThicknessesText: boolean;
    weldingPositionsText: string;
    enableWeldingProcessText: boolean;
    enableJointTypeText: boolean;
    enableThroatThicknessText: boolean;
    enableRunTypeText: boolean;
    enableDiameterText: boolean;
    enableMakesText: boolean;
    enableShieldingGasesText: boolean;
    enableRootProtectionGasText: boolean;
    enableCurrentTypesText: boolean;
    enableDropletTransitionsText: boolean;
    enableHeatInputText: boolean;
    enableArcEnergyText: boolean;
    enableDesignationsText: boolean;
    enablePreheatTemperatureText: boolean;
    enableInterpassTemperatureText: boolean;
    enablePostWeldHeatTreatmentText: boolean;
    enableStandardText: boolean;
    throatThicknessText: string;
    runTypeText: string;
    diameterText: string;
    rootProtectionGasText: string;
    heatInputText: string;
    arcEnergyText: string;
    postWeldHeatTreatmentText: string;
    interpassTemperatureText: string;
    preheatTemperatureText: string;
    /*maxValueForRangeOfQualificationBw: string*/
    standardText: string;
    public constructor() {
        this.address = '';
        //this.date = '';
        this.dateOfIssue = '';
        this.dateOfWelding = '';
        //this.examinerOrExaminingBody = '';
        this.location = '';
        this.manufacturer = '';
        //this.name = '';
        this.testingStandardId = 0;
        this.weldingProcess = '';
        this.jointType = '';
        this.materialThicknesses = '';
        this.designations = '';
        this.shieldingGases = '';
        this.currentTypes = '';
        this.dropletTransitions = '';
        this.makes = '';
        this.otherInformations = '';
        this.weldingMetalThicknessText = '';
        this.enableWeldingMetalThicknessText = false;
        this.materialGroupText = '';
        this.enableMaterialGroupText = false;
        this.enableMaterialThicknessesText = false;
        this.weldingPositionsText = '';
        this.enableWeldingProcessText = false;
        this.enableJointTypeText = false;
        this.enableThroatThicknessText = false;
        this.enableRunTypeText = false;
        this.enableDiameterText = false;
        this.enableMakesText = false;
        this.enableShieldingGasesText = false;
        this.enableRootProtectionGasText = false;
        this.enableCurrentTypesText = false;
        this.enableDropletTransitionsText = false;
        this.enableHeatInputText = false;
        this.enableArcEnergyText = false;
        this.enableDesignationsText = false;
        this.enablePreheatTemperatureText = false;
        this.enableInterpassTemperatureText = false;
        this.enablePostWeldHeatTreatmentText = false;
        this.enableStandardText = false;
        this.throatThicknessText = '';
        this.runTypeText = '';
        this.diameterText = '';
        this.rootProtectionGasText = '';
        this.heatInputText = '';
        this.arcEnergyText = '';
        this.postWeldHeatTreatmentText = '';
        this.interpassTemperatureText = '';
        this.preheatTemperatureText = '';
        /*this.maxValueForRangeOfQualificationBw = '';*/
        this.standardText = '';
    }
}