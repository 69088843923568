﻿import { GetAdditionalRequirementResponse } from "./GetAdditionalRequirementResponse";
import { GetCompanyResponse } from "./GetCompanyResponse";
import { GetNumberOfWeldingPositionsResponse } from "./GetNumberOfWeldingPositionsResponse";
import { GetOtherOptionsResponse } from "./GetOtherOptionsResponse";
import { GetStandardResponse } from "./GetStandardResponse";
import { GetWelderResponse } from "./GetWelderResponse";
import { GetPWPSResponse } from "./GetPWPSResponse";
import { ApplicationStatus } from "../ApplicationStatus";
import { GetWeldingProtocolSectionsResponse } from "./GetWeldingProtocolSectionsResponse";
import { TestingOrderResponse } from "./TestingOrderResponse";
import { GetRangeOfQualificationExaminationResponse } from './GetRangeOfQualificationExaminationResponse';

export class GetApplicationResponse {
    additionalRequirements: Array<GetAdditionalRequirementResponse>;
    applicationStatus: ApplicationStatus;
    company: GetCompanyResponse;
    company2: GetCompanyResponse;
    confirmedAt: string;
    createdAt: string;
    dekraNumber: string;
    wpsHeatInputText: string;
    wpsArcEnergyText: string;
    revisionWpqrAction: string;
    deletedAt: string;
    id: number;
    numberOfWeldingPositions: GetNumberOfWeldingPositionsResponse;
    otherOptions: Array<GetOtherOptionsResponse>;
    pwps: GetPWPSResponse;
    standard: GetStandardResponse;
    supervisor: string;
    examiner: string;
    updatedAt: string;
    welders: Array<GetWelderResponse>;
    weldingProtocolId: number;
    weldingProtocolApplicationId: number;
    weldingProtocolSectionsResponse: GetWeldingProtocolSectionsResponse;
    rangeOfQualificationExaminationResponse: GetRangeOfQualificationExaminationResponse;
    testingOrderResponse: TestingOrderResponse;
    wpsId: number;
    wpqrId: number;
    public constructor() {
        this.additionalRequirements = new Array<GetAdditionalRequirementResponse>();
        this.applicationStatus = ApplicationStatus.NA;
        this.company = new GetCompanyResponse();
        this.company2 = new GetCompanyResponse();
        this.confirmedAt = '';
        this.createdAt = '';
        this.dekraNumber = '';
        this.wpsHeatInputText = '';
        this.wpsArcEnergyText = '';
        this.revisionWpqrAction = '';
        this.deletedAt = '';
        this.id = 0;
        this.numberOfWeldingPositions = new GetNumberOfWeldingPositionsResponse();
        this.otherOptions = new Array<GetOtherOptionsResponse>();
        this.pwps = new GetPWPSResponse();
        this.standard = new GetStandardResponse();
        this.supervisor = '';
        this.examiner = '';
        this.updatedAt = '';
        this.welders = new Array<GetWelderResponse>();
        this.weldingProtocolId = 0;
        this.weldingProtocolApplicationId = 0;
        this.weldingProtocolSectionsResponse = new GetWeldingProtocolSectionsResponse();
        this.rangeOfQualificationExaminationResponse = new GetRangeOfQualificationExaminationResponse();
        this.testingOrderResponse = new TestingOrderResponse();
        this.wpsId = 0;
        this.wpqrId = 0;
    }
}