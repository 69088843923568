
import * as React from 'react';
import { CSSProperties } from "react";

const divFotter: CSSProperties = {
    borderBottom: '1px solid #D1D1D1',
    borderLeft: '10px solid #007D40',
    padding: '0 0 0 0',
    margin: '0 0 0 0'
}

export default class Footer extends React.PureComponent<{}, { isOpen: boolean, tabId: String }> {
    public state = {
        isOpen: false,
        tabId: '',
    };

    public render() {
        return (
            <footer className="container-fluid">
                <div ng-show="isLoggedIn" className="top-menu-wrapper" style={divFotter}>
                     <hr className="dekra-line-break"></hr>
                    <div style={{ padding: '25px 0 10px 25px' }}>
                        <p className="text-muted">&copy; DEKRA 2021</p>
                    </div>
                </div>
            </footer>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    private setTabId = (p: String) => {
        this.setState({
            tabId: p
        });
    }
}
