﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, Col, Collapse, Row, Pagination, PaginationItem, FormGroup, CardTitle, InputGroup, InputGroupAddon, Input, InputGroupText, Table } from 'reactstrap';
import { ApplicationState } from '../store/index';
import * as CustomerListStore from '../store/CustomerListStore';
import { CustomerObject } from '../store/CustomerObject';
import { t } from '../i18n';
import EditCustomerListModal from './EditCustomerListModal';
import DeletionConfirmationModal from '../components/DeletionConfirmationModal';
import Loader from './Loader';

// At runtime, Redux will merge together...
type CustomerListProps =
    CustomerListStore.CustomerListState // ... state we've requested from the Redux store
    & typeof CustomerListStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>
    & OwnProps;

export interface OwnProps {
    customerList: any;
}

class CustomerList extends React.PureComponent<CustomerListProps> {
    constructor(props: CustomerListProps) {
        super(props);
    }

    public state = {
        wpqrCompleteIsOpen: true,
        currentCustomer: -1,
        showDeletionModal: false,
        currentActiveSortChoice: 0,
    };

    public componentDidMount() {
        this.props.receiveUserList();
        this.props.receiveCustomerList();
    }

    public setDeletionModalOpen(open: boolean) {
        this.setState({ showDeletionModal: open });
    }

    private onChangeFreeSearch(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setSearchTerm(event.currentTarget.value);
        this.props.GetUserSearchResult();
    }

    private addButtonClick() {
        const emptyCustomer: CustomerObject = {
            customerContactId: '',
            name: '',
            number: '',
            customerLogo: '',
            customerContact: null,
            id: null
        }

        this.props.setNewCustomer(emptyCustomer);
        this.setState({ currentCustomer: -1 });
        this.props.toggleEditModal();
    }

    public onSelectActiveCustomerSort(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        console.log(event.target);
    }

    //Pagination
    private handlePrevPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.paginationPageIndex - 1);
    }

    private handleNextPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.paginationPageIndex + 1);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public clearEmptyCustomers() {
        this.props.clearEmptyCustomersList();
        this.props.clearEmptyCustomersSearch();
    }

    public onRemoveCustomerPress(rowNumber: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.setState({ currentCustomer: rowNumber });
        this.setDeletionModalOpen(true);
    }

    public removeCustomer() {
        if (this.props.customerList.searchTerm.length > 0) {
            this.props.removeCustomer(this.props.customerList.searchResult[this.state.currentCustomer].id, () => { this.props.receiveCustomerList() });
        } else {
            this.props.removeCustomer(this.props.customerList.customers[this.state.currentCustomer].id, () => { this.props.receiveCustomerList() });
        }
    }

    private onEditUserPress(rowNumber: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.setState({ currentCustomer: rowNumber });
        if (this.props.customerList.searchTerm.length > 0) {
            this.props.setNewCustomer(this.props.customerList.searchResult[rowNumber]);
        } else {
            console.log('this.props.customerList.customers[rowNumber]');
            console.log(this.props.customerList.customers[rowNumber]);
            this.props.setNewCustomer(this.props.customerList.customers[rowNumber]);
        }

        this.props.toggleEditModal();
    }

    private onKeyTokenPress(username: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.getTokenFromUsername(username);
    }

    private renderPagination() {
        return (
            <React.Fragment key="renderPagination">
                <div className="pagination-wrapper">

                    <Pagination aria-label="Page navigation example">
                        <PaginationItem onClick={event => this.handlePrevPageClick(event)}>
                            <a className={this.props.paginationPageIndex === 0 ? "btn disabled" : "btn"} aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </PaginationItem>

                        {/*[...Array(Math.ceil(this.props.wpqrsSearchResult.length / this.props.paginationPageSize))].map((page, i) =>
                            <PaginationItem key={i} active={this.props.paginationPageIndex === i} onClick={(event) => this.handlePageClick(event, i)}>
                                <a className="btn">{i + 1}</a> 
                            </PaginationItem>
                        )*/}

                        <PaginationItem onClick={event => this.handleNextPageClick(event)}>
                            {/*<a className={this.props.paginationPageIndex === (Math.ceil(this.props.wpqrsSearchResult.length / this.props.paginationPageSize) - 1) ? "btn disabled" : "btn"}  aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>*/}
                        </PaginationItem>
                    </Pagination>
                </div>
            </React.Fragment>
        );
    }

    private renderTableHeaderRow() {
        return (
            <React.Fragment key={"renderTableHeaderRow"}>
                <tr>
                    <th className="thinCol"><a></a></th>
                    <th>
                        <a>{t('CustomerNumber')}</a>
                    </th>
                    <th>
                        <a>{t('Company')}</a>
                    </th>
                    <th>
                        <a>{t('City')}</a>
                    </th>
                    <th>
                        <a>{t('Email')}</a>
                    </th>
                    <th className="thinCol"><a></a></th>
                    <th className="thinCol"><a></a></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderKey(customer: CustomerObject, index: number) {
        if (customer.id === 1) {
            return null;
        } else {
            if (customer.customerContact && customer.customerContact?.dekraContactId) {
                return (
                    <Button color="link" size="sm" name={"customerEditBtn-" + index} id={"customerEditBtn-" + index}
                        onClick={(event) => this.onKeyTokenPress(customer.customerContact?.userName, event)}>
                        <i className="fas fa-key"></i>
                    </Button>
                )
            } else if (customer.customerContact && !customer.customerContact?.dekraContactId) {
                return (
                    <span style={{ fontWeight: "bold" }}>DEKRA-kontakt saknas</span>
                )
            } else if (!customer.customerContact && customer.customerContact?.dekraContactId) {
                return (
                    <span style={{ fontWeight: "bold" }}>Kundkontakt saknas</span>
                )
            } else {
                return (
                    <span style={{ fontWeight: "bold" }}>Kontakter saknas</span>
                )
            }
        }
    }

    private renderTableRow(customer: CustomerObject, index: number) {
        return (
            <React.Fragment key={"renderTableRow" + index}>
                <tr key={customer.id}>
                    <td>
                        <Button color="link" size="sm" name={"customerEditBtn-" + index} id={"customerEditBtn-" + index}
                            onClick={(event) => this.onEditUserPress(index, event)}>
                            <i className="fas fa-pen"></i>
                        </Button>
                    </td>
                    <td>
                        {customer.number ? customer.number : null}
                    </td>
                    <td>
                        {customer.name}
                    </td>
                    <td>
                        {customer.customerContact ? customer.customerContact.visitingCity : null}
                    </td>
                    <td>
                        {customer.customerContact ? customer.customerContact.email : null}
                    </td>
                    <td>
                        {this.renderKey(customer, index)}
                    </td>
                    <td>
                        <Button color="link" size="sm" name={"customerRmBtn-" + index} id={"customerRmBtn-" + index}
                            onClick={(event) => this.onRemoveCustomerPress(index, event)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    public render() {
        return (
            <React.Fragment>
                <div className="dekra-content-wrapper dekra-wrapper" >
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <Collapse isOpen={this.state.wpqrCompleteIsOpen}>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Card className="card-border-dekra">
                                                        <CardBody>
                                                            <CardTitle>
                                                            </CardTitle>
                                                            <Row form>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <InputGroup>
                                                                            <Button onClick={() => this.addButtonClick()} color="primary">
                                                                                {t('Add')}
                                                                            </Button>
                                                                            <div style={{ display: "inline", marginRight: "10px" }} />
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input type="text" bsSize="sm" name="freeSearch"
                                                                                id="freeSearchField"
                                                                                style={{ height: "33px" }}
                                                                                value={this.props.customerList.searchTerm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFreeSearch(event)}
                                                                                placeholder={t('Search')} />
                                                                        </InputGroup>
                                                                        {/*<FormGroup>*/}
                                                                        {/*    <Input type="select" bsSize="sm" name="parentMaterialTechnicalDeliveryConditions1" id="parentMaterialTechnicalDeliveryConditions1"*/}
                                                                        {/*        value={this.state.currentActiveSortChoice}*/}
                                                                        {/*        onChange={(event) => this.onSelectActiveCustomerSort(event)}*/}
                                                                        {/*        placeholder="">*/}
                                                                        {/*        <option id={"activeFilterNoFilter"} value={0}>{t('NoFilter')}</option>*/}
                                                                        {/*        <option id={"activeFilterActives"} value={1}>{t('Active')}</option>*/}
                                                                        {/*        <option id={"activeFilterInactives"} value={2}>{t('Inactive')}</option>*/}
                                                                        {/*    </Input>*/}
                                                                        {/*</FormGroup>*/}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Card>
                                                        <CardTitle>
                                                            <Row form>
                                                                <Col sm={4}>
                                                                </Col>
                                                                <Col sm={4}>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <FormGroup>
                                                                        {/*<Button className={"dekra2-table-settings-btn"} color="link" size="sm" name={"tabSettingButton"} onClick={(event) => this.onClickTabSettingModalOpen(event)}>*/}
                                                                        {/*    <i className={"fas fa-cog"}></i>*/}
                                                                        {/*</Button>*/}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardTitle>
                                                        <Row form>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <Table size="sm" className="cert-table table-striped" responsive={true}>
                                                                        <thead>
                                                                            {this.renderTableHeaderRow()}
                                                                        </thead>
                                                                        <tbody>
                                                                            {/*this.renderTabSettingModal()*/}
                                                                            {/*this.rendeWpqrRevisionModal()*/}
                                                                            {/*this.props.searchResult && this.props.searchResult.slice(
                                                                this.props.paginationPageIndex * this.props.paginationPageSize,
                                                                this.props.paginationPageIndex * this.props.paginationPageSize + this.props.paginationPageSize
                                                            ).map((customer, index) => (
                                                                this.renderTableRow(customer,index)
                                                            ))*/}
                                                                            {
                                                                                this.props.customerList.searchTerm.length > 0 ?
                                                                                    this.props.customerList.searchResult.map((user, index) => (
                                                                                        this.renderTableRow(user, index)
                                                                                    ))
                                                                                    :
                                                                                    this.props.customerList.customers.map((user, index) => (
                                                                                        this.renderTableRow(user, index)
                                                                                    ))
                                                                            }
                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr>
                                                                                <td colSpan={14}>
                                                                                    {/*this.renderPagination()*/}
                                                                                </td>
                                                                            </tr>
                                                                        </tfoot>
                                                                    </Table>
                                                                    <EditCustomerListModal
                                                                        show={this.props.customerList.editModalOpen}
                                                                        toggleShow={this.props.toggleEditModal}
                                                                        currentCustomer={this.state.currentCustomer}
                                                                        customerList={this.props.customerList}
                                                                        clearEmptyCustomers={this.clearEmptyCustomers}
                                                                    />
                                                                    <DeletionConfirmationModal
                                                                        onConfirmDeletion={() => this.removeCustomer()}
                                                                        show={this.state.showDeletionModal}
                                                                        setOpen={(open: boolean) => this.setDeletionModalOpen(open)}
                                                                        useUserNotCust={false}
                                                                    />
                                                                    <Loader show={this.props.customerList.currentlyLoading} title={t('FetchingCustomers')} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Collapse>
                                    <Row>
                                        <Col sm={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
    userList: state.userList,
    customerList: state.customerList
})

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    CustomerListStore.actionCreators // Selects which action creators are merged into the component's props
)(CustomerList as any);