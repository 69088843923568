﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, PopoverBody, PopoverHeader, Row, UncontrolledPopover } from 'reactstrap';
import * as CustomerListStore from '../store/CustomerListStore';
import { ApplicationState } from '../store/index';
import { CustomerObject } from '../store/CustomerObject';
import { t } from '../i18n';
import { UserListObject } from '../store/UserListObject';
import { UpdateCustomerRequest } from '../store/models/requests/UpdateCustomerRequest';
import { CreateCustomerRequest } from '../store/models/requests/CreateCustomerRequest';
//@ts-ignore
const logo = require('./../images/logo.png');

// At runtime, Redux will merge together...
type EditUserObjectModalProps =
    CustomerListStore.CustomerListState // ... state we've requested from the Redux store
    & typeof CustomerListStore.actionCreators  // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)
    & connectedProps

export interface OwnProps {
    show: boolean;
    toggleShow: () => void;
    currentCustomer: number;
    customerList: any;
    clearEmptyCustomers: any;
}

export interface connectedProps {
    currentFacilitator: UserListObject;
}

export interface DropdownProps {
    currentUser: UserListObject;
}

interface FilteredFacsProps {
    users: Array<UserListObject>,
    currentCustomerObj: CustomerObject
}

const GetFilteredFacilitatorsOptions: React.FunctionComponent<FilteredFacsProps> = ({ users, currentCustomerObj }) => {
    const [filteredFacs, setFilteredFacs] = React.useState<Array<UserListObject>>([]);

    React.useEffect(() => {
        setFilteredFacs(users.filter(fac => { return fac.customer?.id == currentCustomerObj?.id }))
    }, [currentCustomerObj, users])

    return (
        <React.Fragment>
            {filteredFacs.map((contact, index) => (
                <option key={contact.id} value={contact.userName}>{contact.firstName} {contact.lastName} {contact.customer ? '(' + contact.customer.name + ')' : null}</option>
            ))}
        </React.Fragment>);
};

class EditCustomerListModal extends React.PureComponent<EditUserObjectModalProps> {
    public constructor(props: EditUserObjectModalProps) {
        super(props);
    }

    public state = {
        errorList: new Map<string, boolean>([
            ['customerNumberMissing', false],
            ['customerNumberTaken', false],
            ['customerNameMissing', false],
            ['userNameMissing', false],
            ['usernameTaken', false],
            ['companyMissing', false]
        ])
    };

    public onLoad() {
        const facInput = (document.getElementById('facilitatorInput') as HTMLSelectElement);

        if (this.props.currentCustomer == -1) {
            (document.getElementById('chooseFacilitatorDiv') as HTMLSelectElement).style.display = "none";
            facInput.value = t('newFacilitator');
            facInput.setAttribute('disabled', '');
        } else {
            (document.getElementById('chooseFacilitatorDiv') as HTMLSelectElement).style.display = "unset";
            facInput.removeAttribute('disabled');
            facInput.value = this.props.customerList.newCustomer.customerContact?.userName;
        }
    }

    public componentDidMount() {

    }

    private errorsExist() {
        let currentErrorsArray = Array.from<boolean>(this.state.errorList.values());

        if (currentErrorsArray.find(errorBoolean => errorBoolean) !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    public onSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
    }

    private onCancelModalClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        this.resetErrors();
        this.onClickToggleShowModal(event);
    }

    private onClickToggleShowModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.resetErrors();
        this.props.toggleShow();
        //this.getDekraFacilitators();

        //this.populateFacilitator(this.getFacilitators()[0]);
        //this.populateDekraFacilitator(this.getDekraFacilitators()[0]);
    }

    private populateFacilitator(popUser: UserListObject | null) {
        //Empty modal if new user
        if (!popUser) {
            (document.getElementById('userNameInput') as HTMLInputElement).value = "";
            (document.getElementById('firstNameInput') as HTMLInputElement).value = "";
            (document.getElementById('lastNameInput') as HTMLInputElement).value = "";
            (document.getElementById('titleInput') as HTMLInputElement).value = "";
            (document.getElementById('phoneInput') as HTMLInputElement).value = "";
            (document.getElementById('mobilePhoneInput') as HTMLInputElement).value = "";
            (document.getElementById('emailInput') as HTMLInputElement).value = "";
            (document.getElementById('cityInput') as HTMLInputElement).value = "";
            (document.getElementById('zipcodeInput') as HTMLInputElement).value = "";
            (document.getElementById('streetInput') as HTMLInputElement).value = "";
            (document.getElementById('postCityInput') as HTMLInputElement).value = "";
            (document.getElementById('postZipcodeInput') as HTMLInputElement).value = "";
            (document.getElementById('postStreetInput') as HTMLInputElement).value = "";
        } else {
            (document.getElementById('userNameInput') as HTMLInputElement).value = popUser.userName;
            (document.getElementById('firstNameInput') as HTMLInputElement).value = popUser.firstName;
            (document.getElementById('lastNameInput') as HTMLInputElement).value = popUser.lastName;
            (document.getElementById('titleInput') as HTMLInputElement).value = popUser.title;
            (document.getElementById('phoneInput') as HTMLInputElement).value = popUser.phoneNumber;
            (document.getElementById('mobilePhoneInput') as HTMLInputElement).value = popUser.mobilePhoneNumber;
            (document.getElementById('emailInput') as HTMLInputElement).value = popUser.email;
            (document.getElementById('cityInput') as HTMLInputElement).value = popUser.visitingCity;
            (document.getElementById('zipcodeInput') as HTMLInputElement).value = popUser.visitingZipcode;
            (document.getElementById('streetInput') as HTMLInputElement).value = popUser.visitingStreet;
            (document.getElementById('postCityInput') as HTMLInputElement).value = popUser.postCity;
            (document.getElementById('postZipcodeInput') as HTMLInputElement).value = popUser.postZipcode;
            (document.getElementById('postStreetInput') as HTMLInputElement).value = popUser.postStreet;
        }
    }

    private getFacilitators(): Array<UserListObject> {
        const users = this.props.customerList.listOfUsers;
        console.log('users');
        console.log(users);
        const currentCustomerId = parseInt(localStorage.getItem('customerId'));
        console.log(['currentCustomerId', currentCustomerId]);
        //Return only users with the 'Customer' role and that matches the currently logged-in user's customer id.
        let facilitators = users.filter((user: UserListObject) => {
            return user.roles.find(role => role.includes('Customer'))
        });

        return facilitators;
    }

    private confirmModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        //event.preventDefault();
        this.resetErrors();
        if (this.props.currentCustomer === -1) {
            this.finalizeCreateCustomer();
        } else {
            this.finalizeUpdateCustomer();
        }
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        //this.setNewCustomerSettings();
    }

    public onSelectFacilitatorChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value) {
            let newFacUser = this.props.customerList.listOfUsers.find(user => user.userName == event.target.value)

            if (newFacUser) {
                this.populateFacilitator(newFacUser);
            }
            //if (event.target.value == "newFacilitator") {
            //    this.enableFacilitatorInputs();
            //    this.populateFacilitator(null);
            //} else {
            //    this.populateFacilitator(this.props.users.find(user => user.userName == event.target.value));
            //    this.disableFacilitatorInputs();
            //}

        } else {
            //Empty field if blank is selected
            this.populateFacilitator(null);
        }

        /*const foundContact = this.props.userList.users.find((user) => user.userName === event.target.value);

        this.setState({
            currentFacilitator: foundContact
        });*/
    }

    private setError(errorName: string, showError: boolean) {
        let currentErrors = this.state.errorList;

        if (currentErrors.has(errorName)) {
            currentErrors.set(errorName, showError)
        }

        this.setState({ currentErrors: currentErrors });
    }

    private resetErrors() {
        let currentErrors = this.state.errorList;

        currentErrors.forEach((isOn, error) => {
            currentErrors.set(error, false);
        });
        this.setState({ currentErrors: currentErrors });
    }

    private finalizeCreateCustomer() {
        //const facInput = (document.getElementById('facilitatorInput') as HTMLInputElement).value;
        const custNumber = (document.getElementById('customerNumberInput') as HTMLInputElement).value;
        const custName = (document.getElementById('companyInput') as HTMLInputElement).value;
        const customerLogo = (document.getElementById('customerLogo') as HTMLInputElement).src;

        if (!custNumber) {
            this.setError('customerNumberMissing', true);
        }

        if (!custName) {
            this.setError('customerNameMissing', true);
        }

        if (!(document.getElementById('userNameInput') as HTMLInputElement).value) {
            this.setError('userNameMissing', true);
        }

        if (this.errorsExist()) {
            let fadeInElements = document.getElementsByClassName('fadeInFromLeftClass');

            //Reset animation
            for (var i = 0; i < fadeInElements.length; i++) {
                let el = (fadeInElements[i] as HTMLElement);

                el.style.animationName = "fadeInFromLeft";
                el.onanimationend = (event) => { el.style.animationName = null }
            }

            return;
        }

        let customerRequest: CreateCustomerRequest = {
            customerContact: null,
            customerContactId: null,
            name: custName,
            number: custNumber,
            customerLogo: customerLogo,
        };

        //Handle customer contact

        customerRequest.customerContact = { //If contactId is null and contact isn't, get info from page
            displayName: null,
            userName: (document.getElementById('userNameInput') as HTMLInputElement).value,
            email: (document.getElementById('emailInput') as HTMLInputElement).value,
            firstName: (document.getElementById('firstNameInput') as HTMLInputElement).value,
            lastName: (document.getElementById('lastNameInput') as HTMLInputElement).value,
            mobilePhoneNumber: (document.getElementById('mobilePhoneInput') as HTMLInputElement).value,
            phoneExchangeNumber: null,
            phoneNumber: (document.getElementById('phoneInput') as HTMLInputElement).value,
            postCity: (document.getElementById('postCityInput') as HTMLInputElement).value,
            postStreet: (document.getElementById('postStreetInput') as HTMLInputElement).value,
            postZipcode: (document.getElementById('postZipcodeInput') as HTMLInputElement).value,
            roles: ["Customer"],
            title: (document.getElementById('titleInput') as HTMLInputElement).value,
            visitingCity: (document.getElementById('cityInput') as HTMLInputElement).value,
            visitingStreet: (document.getElementById('streetInput') as HTMLInputElement).value,
            visitingZipcode: (document.getElementById('zipcodeInput') as HTMLInputElement).value,
            customerId: parseInt((document.getElementById('customerNumberInput') as HTMLInputElement).value),
            website: null
        }

        customerRequest.customerContactId = null;

        this.props.addNewCustomer(customerRequest, () => {
            this.props.receiveCustomerList();
            this.props.receiveUserList();
        });

        this.props.toggleShow();
    }

    private finalizeUpdateCustomer() {
        const newCustFac = this.props.customerList.listOfUsers.find(user => user.userName == (document.getElementById('facilitatorInput') as HTMLInputElement).value);

        const custNumber = (document.getElementById('customerNumberInput') as HTMLInputElement).value;
        const custName = (document.getElementById('companyInput') as HTMLInputElement).value;
        const customerLogo = (document.getElementById('customerLogo') as HTMLInputElement).src;
        

        const cFirstName = (document.getElementById('firstNameInput') as HTMLInputElement).value;
        const cLastName = (document.getElementById('lastNameInput') as HTMLInputElement).value;
        const cUserName = (document.getElementById('userNameInput') as HTMLInputElement).value;
        const cTitle = (document.getElementById('titleInput') as HTMLInputElement).value;
        const cPhone = (document.getElementById('phoneInput') as HTMLInputElement).value;
        const cMobilePhone = (document.getElementById('mobilePhoneInput') as HTMLInputElement).value;
        const cEmail = (document.getElementById('emailInput') as HTMLInputElement).value;
        const cVisitStreet = (document.getElementById('streetInput') as HTMLInputElement).value;
        const cVisitCity = (document.getElementById('cityInput') as HTMLInputElement).value;
        const cVisitZipcode = (document.getElementById('zipcodeInput') as HTMLInputElement).value;
        const cPostStreet = (document.getElementById('postStreetInput') as HTMLInputElement).value;
        const cPostCity = (document.getElementById('postCityInput') as HTMLInputElement).value;
        const cPostZipcode = (document.getElementById('postZipcodeInput') as HTMLInputElement).value;

        const newCustomer: UpdateCustomerRequest = {
            number: custNumber,
            name: custName,
            customerLogo: customerLogo,
            customerContactId: newCustFac ? newCustFac.id : null,
            id: this.props.customerList.newCustomer.id,
            customerContact: {
                userName: cUserName,
                email: cEmail,
                displayName: cFirstName + " " + cLastName,
                phoneNumber: cPhone,
                firstName: cFirstName,
                lastName: cLastName,
                title: cTitle,
                mobilePhoneNumber: cMobilePhone,
                phoneExchangeNumber: "",
                website: "",
                postCity: cPostCity,
                postZipcode: cPostZipcode,
                postStreet: cPostStreet,
                visitingCity: cVisitCity,
                visitingZipcode: cVisitZipcode,
                visitingStreet: cVisitStreet,
                roles: [
                    "Customer"
                ],
                customerId: this.props.customerList.newCustomer.id,
                dekraContactId: null,
                dekraContact: null
            }
        };

        const newCustomerObject: CustomerObject = {
            number: custNumber,
            name: custName,
            customerLogo: customerLogo,
            customerContactId: newCustFac ? newCustFac.id : null,
            id: this.props.customerList.newCustomer.id,
            customerContact: {
                userName: cUserName,
                email: cEmail,
                displayName: cFirstName + " " + cLastName,
                phoneNumber: cPhone,
                firstName: cFirstName,
                lastName: cLastName,
                title: cTitle,
                mobilePhoneNumber: cMobilePhone,
                phoneExchangeNumber: "",
                website: "",
                postCity: cPostCity,
                postZipcode: cPostZipcode,
                postStreet: cPostStreet,
                visitingCity: cVisitCity,
                visitingZipcode: cVisitZipcode,
                visitingStreet: cVisitStreet,
                roles: [
                    "Customer"
                ],
                customerId: this.props.customerList.newCustomer.id,
                dekraContactId: null,
                dekraContact: null,
                id: this.props.customerList.newCustomer.customerContact.id,
                customer: null,
                signatureLogo: "",
            }
        };

        this.props.updateCustomer(newCustomer, newCustomerObject, () => {
            console.log("updateCustomer");
            this.props.receiveCustomerList();
            this.props.receiveUserList();
        });
        //this.props.setCustomerList(newCustomerObject, this.props.currentCustomer);
        this.props.toggleShow();
    }

    private handleChangeUploadLogo = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result != null) {
                    (document.getElementById('customerLogo') as HTMLInputElement).src = reader.result.toString();
                }
            };
            reader.readAsDataURL(event.target.files[i]);
        }
    };

    private enableFacilitatorInputs() {
        const facInputs = document.getElementsByClassName('modalInput');

        for (var i = 0; i < facInputs.length; i++) {
            facInputs[i].removeAttribute('disabled')
        }
    }

    private disableFacilitatorInputs() {
        const facInputs = document.getElementsByClassName('modalInput');

        for (var i = 0; i < facInputs.length; i++) {
            facInputs[i].setAttribute('disabled', '');
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.show} onOpened={() => this.onLoad()} size="lg">
                    <ModalHeader>
                        {this.props.currentCustomer > -1 ? t('ChangeCustomerInformation') : t('CreateCustomer')}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <h5 style={{ color: "#007D40" }}>{t('Customer')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('CustomerNumber')}</Label>
                                                <Input type="text" bssize="sm" id="customerNumberInput" name="commonModalInput" className="commonModalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.number ? this.props.customerList.newCustomer.number : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    onBlur={(event) => this.onFieldChange(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        {this.state.errorList.get('customerNumberMissing') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="customerNumberMissing" style={{ color: "red" }} for="" size="sm">{t('CustomerNumberMissing_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        {this.state.errorList.get('customerNumberTaken') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="customerNumberTaken" style={{ color: "red" }} for="" size="sm">{t('customerNumberTaken_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Company')}</Label>
                                                <Input type="text" bssize="sm" id="companyInput" name="commonModalInput" className="commonModalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.name ? this.props.customerList.newCustomer.name : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    onBlur={(event) => this.onFieldChange(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        {this.state.errorList.get('customerNameMissing') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="customerNameMissing" style={{ color: "red" }} for="" size="sm">{t('CustomerNameMissing_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        {this.state.errorList.get('customerNameTaken') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="customerNameTaken" style={{ color: "red" }} for="" size="sm">{t('CustomerNameTaken_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        <div className="modal-distancer" />
                                        <h5 style={{ color: "#007D40" }}>{t('ContactInformationToCustomer')}</h5>
                                        <div id="chooseFacilitatorDiv">
                                            <Row form>
                                                <Col className="field-flex" sm={12}>
                                                    <Label className="field-label" for="" size="sm">{t('ChooseFacilitator')}</Label>
                                                    <Input type="select" bssize="sm" id="facilitatorInput" name="commonModalInput" className="commonModalInput field-input"
                                                        /*defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.userName : null}*/
                                                        defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.userName : "noContact"}
                                                        onChange={(event) => this.onSelectFacilitatorChange(event)}
                                                        onBlur={(event) => this.onFieldChange(event)}
                                                        placeholder="" >
                                                        {/*<option key={"emptyFacilitator"} value={'noContact'}>{t('NoContactChosen')}</option>*/}
                                                        {/*<option key={"newFacilitator"} value={"newFacilitator"}>{t('CreateNewFacilitator')}</option>*/}
                                                        {/*<option key={"Arne"} value={"Arne"}>Arne Arnesson</option>*/}
                                                        <GetFilteredFacilitatorsOptions
                                                            users={this.props.customerList.listOfUsers}
                                                            currentCustomerObj={this.props.customerList.newCustomer}
                                                        />
                                                    </Input>
                                                </Col>
                                            </Row>
                                            <div className="modal-distancer" />
                                        </div>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('FirstName')}</Label>
                                                <Input type="text" bssize="sm" id="firstNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.firstName : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('LastName')}</Label>
                                                <Input type="text" bssize="sm" id="lastNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.lastName : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Username')}</Label>
                                                <Input type="text" bssize="sm" id="userNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.userName : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        {this.state.errorList.get('userNameMissing') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="userNameMissing" style={{ color: "red" }} for="" size="sm">{t('UserNameMissing_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        {this.state.errorList.get('userNameTaken') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="userNameTaken" style={{ color: "red" }} for="" size="sm">{t('UserNameTaken_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Title')}</Label>
                                                <Input type="text" bssize="sm" id="titleInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.title : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Phone')}</Label>
                                                <Input type="text" bssize="sm" id="phoneInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.phoneNumber : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('MobilePhone')}</Label>
                                                <Input type="text" bssize="sm" id="mobilePhoneInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.mobilePhoneNumber : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Email')}</Label>
                                                <Input type="text" bssize="sm" id="emailInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.email : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <div className="modal-distancer" />
                                        <h5 style={{ color: "#007D40" }}>{t('VisitingAddress')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Street')}</Label>
                                                <Input type="text" bssize="sm" id="streetInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.visitingStreet : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="zipcodeInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.visitingZipcode : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('City')}</Label>
                                                <Input type="text" bssize="sm" id="cityInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.visitingCity : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <h5 style={{ color: "#007D40" }}>{t('PostAddress')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('DeliveryAddress')}</Label>
                                                <Input type="text" bssize="sm" id="postStreetInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.postStreet : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="postZipcodeInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.postZipcode : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('PostCity')}</Label>
                                                <Input type="text" bssize="sm" id="postCityInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.customerList.newCustomer.customerContact ? this.props.customerList.newCustomer.customerContact.postCity : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                    </FormGroup>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                            <Button type="button" id="UploadLogo" name="UploadLogo" className="btn btn-sm btn-primary btn-file" >
                                                    {t('Upload')} {t('Logo')}<input type="file" onChange={event => this.handleChangeUploadLogo(event)} />
                                                </Button>
                                                <Button type="button" color="link" size="sm" id={"LogoInfo"} name={"LogoInfo"} >
                                                    <i className="fas fa-info-circle"></i>
                                                </Button>
                                                <span>&nbsp;</span>
                                            <UncontrolledPopover trigger="focus" placement="bottom" target={"LogoInfo"}>
                                                    <PopoverHeader>{t('Logo')}</PopoverHeader>
                                                    <PopoverBody>{t('InfoLogo')}</PopoverBody>
                                                </UncontrolledPopover>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="modal-distancer" />
                                    <Row form>
                                        <Col sm={12}>
                                            <div className="text-center customer-logo-border">
                                                <img id="customerLogo" className="customer-logo" src={this.props.customerList.newCustomer.customerLogo === "" || this.props.customerList.newCustomer.customerLogo === null ? logo : this.props.customerList.newCustomer.customerLogo} alt="DEKRA" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Row >
                            {this.errorsExist() ?
                                <Col className="no-padding" sm={12}>
                                    <Label className="fadeInFromLeftClass error-text" for="" size="sm">{t('ThereWereErrors_')}</Label>
                                </Col>
                                : null}
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Button color="primary" onClick={(event) => this.confirmModal(event)}>{t('OK')}</Button>
                                {' '}
                                <Button color="secondary" onClick={(event) => this.onCancelModalClick(event)}>{t('Cancel')}</Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any, ownProps: OwnProps) => ({

})

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    CustomerListStore.actionCreators // Selects which action creators are merged into the component's props
)(EditCustomerListModal as any);
