﻿import { GetDesignationResponse } from "./GetDesignationResponse";
import { GetFMGroupResponse } from "./GetFMGroupResponse";

export class GetMakeResponse {
    createdAt: string;
    deletedAt: string;
    description: string;
    designation: GetDesignationResponse;
    fmGroup: GetFMGroupResponse;
    id: number;
    name: string;
    updatedAt: string;

    public constructor() {
        this.createdAt = '';
        this.deletedAt = '';
        this.description = '';
        this.designation = new GetDesignationResponse();
        this.fmGroup = new GetFMGroupResponse();
        this.id = 0;
        this.name = '';
        this.updatedAt = '';
    }
}