﻿export class FractureTestingResultsTableRow {
    acceptance: string;
    testResult: string;
    testSpecimenNumber: string;

    public constructor(testSpecimenNumber: string, testResult: string, acceptance: string) {
        this.testSpecimenNumber = testSpecimenNumber;
        this.testResult = testResult;
        this.acceptance = acceptance;
    }
}