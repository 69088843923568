﻿import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';

interface LoaderProps {
    show: boolean;
    title: string;
}

const Loader = (props: LoaderProps) => (
    <React.Fragment>
            <Modal centered={true} isOpen={props.show} size="sm">
                <ModalHeader>{props.title}</ModalHeader>
                <ModalBody>
                    <div className="text-center">
                        <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </ModalBody>
            </Modal>
    </React.Fragment>
);

export default connect()(Loader);
