﻿export class FilesUpload {
    id: number;
    fileName: string;
    fileDescription: string;
    fileBase64: string;
    filePath: string;
    fileUrl: string;
    selectedFile: boolean;

    public constructor() {
        this.id = 0;
        this.fileName = '';
        this.fileBase64 = '';
        this.filePath = '';
        this.fileUrl = '';
        this.fileDescription = '';
        this.selectedFile = false;
    }
}