﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import authHeader from './models/auth-header';
import EventBus from '../store/eventBus';
import { UserListObject } from './UserListObject';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LoginState {
    userName: string;
    loggedIn: boolean;
    loggingIn: boolean;
    accessToken: string;
    roleList: Array<string>;
    currentRoles: Array<string>;
    currentUser: UserListObject;
    activeTabId: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoginFailureResponseAction {
    type: 'LOGIN_FAILURE_RESPONSE';
}

export interface LoginRequestAction {
    type: 'LOGIN_REQUEST';
}

export interface LoginSuccessResponseAction {
    type: 'LOGIN_SUCCESS_RESPONSE';
    accessToken: string;
}

export interface LogoutAction {
    type: 'LOGOUT';
}

export interface CheckTokenAction {
    type: 'CHECK_TOKEN_ACTION';
}

export interface SetRoleList {
    type: 'SET_ROLE_LIST';
    roleList: Array<string>;
}



export interface SetUserNameAction {
    type: 'SET_USERNAME';
    userName: string;
}

export interface ReceiveCurrentRoles {
    type: 'RECEIVE_ROLES';
    currentRoles: Array<string>;
}

export interface ReceiveCurrentUser {
    type: 'RECEIVE_CURRENT_USER';
    currentUser: UserListObject;
}

export interface SetActiveTabId {
    type: 'SET_ACTIVE_TAB_ID';
    activeTabId: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = LoginFailureResponseAction
    | LoginRequestAction
    | LoginSuccessResponseAction
    | LogoutAction
    | SetUserNameAction
    | CheckTokenAction
    | SetRoleList
    | ReceiveCurrentRoles
    | ReceiveCurrentUser
    | SetActiveTabId;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    login: (userName: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'LOGIN_REQUEST' });

        fetch('', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userName })
        })
            .then(response => response.json())
            .then(data => {
                // localStorage.setItem('user', JSON.stringify(data));
                //  dispatch({ type: 'LOGIN_SUCCESS_RESPONSE', user: data });
            })
            .catch(error => {
                dispatch({ type: 'LOGIN_FAILURE_RESPONSE' });
            });
    },
    currentUser: (): AppThunkAction<KnownAction> => (dispatch) => {
        let tokenStr = null;

        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }        

        if (tokenStr) {
            fetch(`/api/User/current/`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('user');
                        localStorage.removeItem('userId');
                        localStorage.removeItem('customerId');
                        
                        return dispatch({ type: 'LOGOUT' });
                    }

                    //return dispatch({ type: 'CHECK_TOKEN_ACTION' });
                }))
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            //return dispatch({ type: 'LOGOUT' });
        }
    },
    adlogin: (token: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'LOGIN_REQUEST' });

        const myHeaders = new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });

        fetch(`/api/User/current/`, {
            method: 'GET',
            headers: myHeaders,

        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                    return dispatch({ type: 'LOGIN_FAILURE_RESPONSE' });
                }
                else if (res.status >= 400) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('user');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('customerId');

                    return dispatch({ type: 'LOGOUT' });
                }

                //localStorage.setItem('accessToken', token);
                EventBus.dispatch("tokenSet", "");
                return dispatch({ type: 'LOGIN_SUCCESS_RESPONSE', accessToken: token })
            }))
            .catch(error => {
                console.log(error);
            });
    },
    receiveCurrentUser: (): AppThunkAction<KnownAction> => (dispatch) => {
        let tokenStr = null;
     
        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/current`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    let hasCustomer = true;
                    let hasPermission = true;

                    if (res.data.customer == null) {
                        localStorage.setItem('customerId', "");
                        localStorage.setItem('userId', "");
                        localStorage.setItem('user', "");
                        hasCustomer = false;
                    } else {
                        localStorage.setItem('customerId', res.data.customer.id);
                        localStorage.setItem('userId', res.data.id);
                        localStorage.setItem('user', JSON.stringify(res.data));
                    }

                    if (res.data.roles.length == 0) {
                        hasPermission = false;
                    }

                    if (!hasCustomer && !hasPermission) {
                        EventBus.dispatch('showPermissionWarning', 'noCustNoPerm');
                    } else if (!hasCustomer && hasPermission) {
                        if (res.data.roles.includes('Customer')) {
                            EventBus.dispatch('showPermissionWarning', 'noCustHasPerm');
                        }                        
                    } else if (hasCustomer && !hasPermission) {
                        EventBus.dispatch('showPermissionWarning', 'hasCustNoPerm');
                    }

                    return dispatch({ type: 'RECEIVE_CURRENT_USER', currentUser: res.data });
                }))
                .catch(error => {
                    console.log(error);
                });
        }
    },
    logout: (): AppThunkAction<KnownAction> => (dispatch) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        localStorage.removeItem('userId');
        localStorage.removeItem('customerId');

        return dispatch({ type: 'LOGOUT' });
    },
    receiveRoles: (): AppThunkAction<KnownAction> => (dispatch) => {
        let tokenStr = null;
     
        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/Role/GetAll`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    return dispatch({ type: 'SET_ROLE_LIST', roleList: res.data });
                }))
                .catch(error => {
                    console.log(error);
                });
        }
    },
    receiveCurrentRoles: (): AppThunkAction<KnownAction> => (dispatch) => {
        let tokenStr = null;
 
        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/current`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    return dispatch({ type: 'RECEIVE_ROLES', currentRoles: res.data.roles });
                }))
                .catch(error => {
                    console.log(error);
                });
        }
    },
    CheckToken: (): AppThunkAction<KnownAction> => (dispatch) => {
        let tokenStr = null;
    
        if (sessionStorage.getItem('savedToken')) {
            tokenStr = sessionStorage.getItem('savedToken');
        } else {
            tokenStr = localStorage.getItem("accessToken");
        }

        if (tokenStr) {
            fetch(`/api/User/current/`, {
                method: 'GET',
                headers: authHeader.authHeader(),

            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('user');
                        localStorage.removeItem('userId');
                        localStorage.removeItem('customerId');

                        return dispatch({ type: 'LOGOUT' });
                    }

                    //return dispatch({ type: 'CHECK_TOKEN_ACTION' });
                }))
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            return dispatch({ type: 'LOGOUT' });
        }
    },
    setUserName: (userName: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_USERNAME', userName: userName });
    },
    setRoleList: (incomingRoleList: Array<string>): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_ROLE_LIST', roleList: incomingRoleList });
    },
     setActiveTabId: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_ACTIVE_TAB_ID', activeTabId: false });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

let value = localStorage.getItem('');

const unloadedState: LoginState = (value) ?
    {
        userName: '',
        loggedIn: false,
        loggingIn: false,
        accessToken: '',
        roleList: [],
        currentRoles: [],
        currentUser: null,
        activeTabId: false
    } : {
        userName: '',
        loggedIn: false,
        loggingIn: false,
        accessToken: '',
        roleList: [],
        currentRoles: [],
        currentUser: null,
        activeTabId: false
    };

export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOGIN_FAILURE_RESPONSE':
            return {
                userName: state.userName,
                loggedIn: false,
                loggingIn: false,
                accessToken: '',
                roleList: state.roleList,
                currentRoles: state.currentRoles,
                currentUser: state.currentUser,
                activeTabId: state.activeTabId,
            }
        case 'SET_ROLE_LIST':
            return {
                userName: state.userName,
                loggedIn: state.loggedIn,
                loggingIn: state.loggingIn,
                accessToken: state.accessToken,
                roleList: action.roleList,
                currentRoles: state.currentRoles,
                currentUser: state.currentUser,
                activeTabId: state.activeTabId,
            }
        case 'LOGIN_REQUEST':
            return {
                userName: state.userName,
                loggedIn: false,
                loggingIn: true,
                accessToken: '',
                roleList: state.roleList,
                currentRoles: state.currentRoles,
                currentUser: state.currentUser,
                activeTabId: state.activeTabId,
            }
        case 'LOGOUT':
            return {
                userName: state.userName,
                loggedIn: false,
                loggingIn: false,
                accessToken: '',
                roleList: state.roleList,
                currentRoles: state.currentRoles,
                currentUser: state.currentUser,
                activeTabId: state.activeTabId,
            }
        case 'LOGIN_SUCCESS_RESPONSE':
            return {
                userName: state.userName,
                loggedIn: true,
                loggingIn: false,
                accessToken: action.accessToken,
                roleList: state.roleList,
                currentRoles: state.currentRoles,
                currentUser: state.currentUser,
                activeTabId: state.activeTabId,
            }

        case 'CHECK_TOKEN_ACTION':
            return {
                userName: state.userName,
                loggedIn: state.loggedIn,
                loggingIn: state.loggingIn,
                accessToken: state.accessToken,
                roleList: state.roleList,
                currentRoles: state.currentRoles,
                currentUser: state.currentUser,
                activeTabId: state.activeTabId,
            };
        case 'SET_USERNAME':
            return {
                userName: action.userName,
                loggedIn: state.loggedIn,
                loggingIn: state.loggingIn,
                accessToken: state.accessToken,
                roleList: state.roleList,
                currentRoles: state.currentRoles,
                currentUser: state.currentUser,
                activeTabId: state.activeTabId,
            };
        case 'RECEIVE_ROLES':
            return {
                userName: state.userName,
                loggedIn: state.loggedIn,
                loggingIn: state.loggingIn,
                accessToken: state.accessToken,
                roleList: state.roleList,
                currentRoles: action.currentRoles,
                currentUser: state.currentUser,
                activeTabId: state.activeTabId,
            };
        case 'RECEIVE_CURRENT_USER':
            return {
                userName: state.userName,
                loggedIn: state.loggedIn,
                loggingIn: state.loggingIn,
                accessToken: state.accessToken,
                roleList: state.roleList,
                currentRoles: state.currentRoles,
                currentUser: action.currentUser,
                activeTabId: state.activeTabId,
            };
        case 'SET_ACTIVE_TAB_ID':
            return {
                userName: state.userName,
                loggedIn: state.loggedIn,
                loggingIn: state.loggingIn,
                accessToken: state.accessToken,
                roleList: state.roleList,
                currentRoles: state.currentRoles,
                currentUser: state.currentUser,
                activeTabId: action.activeTabId,
            };
        default:
            return state;
    }
};
