﻿export class GetTechnicalDeliveryConditionResponse {
    createdAt: string;
    deletedAt: string;
    description: string;
    id: number;
    name: string;
    updatedAt: string;

    public constructor() {
        this.createdAt = '';
        this.deletedAt = '';
        this.description = '';
        this.id = 0;
        this.name = '';
        this.updatedAt = '';
    }
}