﻿import { AppThunkAction } from './index';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { GetJointDesignResponse } from './models/responses/GetJointDesignResponse';
import { GetJointShapeResponse } from './models/responses/GetJointShapeResponse';
import { GetJointTypeResponse } from './models/responses/GetJointTypeResponse';
import { GetRootSupportResponse } from './models/responses/GetRootSupportResponse';
import '../store/models/NumberExtensions';
import authHeader from './models/auth-header';
import EventBus from './eventBus';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DrawJointDesignState {
    depthOfPreparation: string;
    gapOpening: string;
    jointAngleAlpha: string;
    jointAngleBeta: string;
    jointTypes: Array<GetJointTypeResponse>;
    materialThickness1: string;
    materialThickness2: string;
    note: string;
    phaseAngle1: string;
    phaseAngle2: string;
    radius: string;
    rootSupports: Array<GetRootSupportResponse>;
    selectedJointShape: GetJointShapeResponse;
    selectedJointType: GetJointTypeResponse;
    selectedRootSupport: GetRootSupportResponse;
    straightEdge: string;
    throatThickness: string;
    jointDesignView: GetJointDesignResponse;
    jointDesignHtmlImage: string
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface GetJointTypesReceiveAction {
    type: 'GET_JOINT_TYPES_RECEIVE';
    jointTypes: Array<GetJointTypeResponse>;
}

interface GetJointTypesRequestAction {
    type: 'GET_JOINT_TYPES_REQUEST';
}

interface GetRootSupportsReceiveAction {
    type: 'GET_ROOT_SUPPORTS_RECEIVE';
    rootSupports: Array<GetRootSupportResponse>;
}

interface GetRootSupportsRequestAction {
    type: 'GET_ROOT_SUPPORTS_REQUEST';
}

interface InitAction {
    type: 'INIT';
    materialThickness1: string;
    materialThickness2: string;
    selectedJointType: GetJointTypeResponse;
}

interface SetDepthOfPreparation {
    type: 'SET_DEPTH_OF_PREPARATION';
    depthOfPreparation: string;
}

interface SetGapOpeningAction {
    type: 'SET_GAP_OPENING';
    gapOpening: string;
}

interface SetJointAngleAlphaAction {
    type: 'SET_JOINT_ANGLE_ALPHA';
    jointAngleAlpha: string;
}

interface SetJointAngleBetaAction {
    type: 'SET_JOINT_ANGLE_BETA';
    jointAngleBeta: string;
}

interface SetMaterialThickness1Action {
    type: 'SET_MATERIAL_THICKNESS_1';
    materialThickness1: string;
}

interface SetMaterialThickness2Action {
    type: 'SET_MATERIAL_THICKNESS_2';
    materialThickness2: string;
}

interface SetJointDesignHtmlImageAction {
    type: 'SET_JOINT_DESIGN_HTML_IMAGE';
    jointDesignHtmlImage: string;
}
interface SetNoteAction {
    type: 'SET_NOTE';
    note: string;
}

interface SetPhaseAngle1Action {
    type: 'SET_PHASE_ANGLE_1';
    phaseAngle1: string;
}

interface SetPhaseAngle2Action {
    type: 'SET_PHASE_ANGLE_2';
    phaseAngle2: string;
}

interface SetRadiusAction {
    type: 'SET_RADIUS';
    radius: string;
}

interface SetSelectedJointShapeAction {
    type: 'SET_SELECTED_JOINT_SHAPE';
    selectedJointShape: GetJointShapeResponse;
}

interface SetSelectedJointTypeAction {
    type: 'SET_SELECTED_JOINT_TYPE';
    selectedJointType: GetJointTypeResponse;
}

export interface SetSelectedRootSupportAction {
    type: 'SET_SELECTED_ROOT_SUPPORT';
    selectedRootSupport: GetRootSupportResponse;
}

export interface SetStraightEdgeAction {
    type: 'SET_STRAIGHT_EDGE';
    straightEdge: string;
}

export interface SetThroatThicknessAction {
    type: 'SET_THROAT_THICKNESS';
    throatThickness: string;
}

export interface SetJointDesignViewAction {
    type: 'SET_JOINT_DESIGN_VIEW';
    jointDesignView: GetJointDesignResponse;
}

export interface ResetStateDrawJointDesignAction {
    type: 'RESET_STATE_DRAW_JOINT_DESIGN_ACTION';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    GetJointTypesReceiveAction
    | GetJointTypesRequestAction
    | GetRootSupportsReceiveAction
    | GetRootSupportsRequestAction
    | InitAction
    | SetDepthOfPreparation
    | SetGapOpeningAction
    | SetJointAngleAlphaAction
    | SetJointAngleBetaAction
    | SetMaterialThickness1Action
    | SetMaterialThickness2Action
    | SetNoteAction
    | SetPhaseAngle1Action
    | SetPhaseAngle2Action
    | SetRadiusAction
    | SetSelectedJointShapeAction
    | SetSelectedJointTypeAction
    | SetSelectedRootSupportAction
    | SetStraightEdgeAction
    | SetThroatThicknessAction
    | SetJointDesignViewAction
    | ResetStateDrawJointDesignAction
    | SetJointDesignHtmlImageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_STATE_DRAW_JOINT_DESIGN_ACTION' });
    },
    getJointTypesAll: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/JointTypes/GetAllInclude`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response => response.json() as Promise<Array<GetJointTypeResponse>>)
            .then(data => {
                console.info(data);
                // This is temporary, until we get pictures for joint types. Todo
                let jointTypesTemp: Array<GetJointTypeResponse> = new Array<GetJointTypeResponse>();
                for (let i = 0; i < [...data].length; i++) {
                    if ([...data][i].id === 1 || [...data][i].id === 3) {
                        jointTypesTemp.push([...data][i]);
                    }
                }
                dispatch({ type: 'GET_JOINT_TYPES_RECEIVE', jointTypes: jointTypesTemp });
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_JOINT_TYPES_REQUEST' });
    },
    getRootSupportsAll: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/RootSupports/GetAll`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    if (res.status === 401) {
                        EventBus.dispatch("logout", "");
                    }
                    let data = res.data as Array<GetRootSupportResponse>;
                    console.info(data);
                    dispatch({ type: 'GET_ROOT_SUPPORTS_RECEIVE', rootSupports: data });
                }))
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_ROOT_SUPPORTS_REQUEST' });
    },
    setJointDesign: (jointDesign: GetJointDesignResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({ type: 'SET_DEPTH_OF_PREPARATION', depthOfPreparation: jointDesign.depthOfPreparation.zeroToStringEmpty() });
        dispatch({ type: 'SET_GAP_OPENING', gapOpening: jointDesign.gapOpening.zeroToStringEmpty() });
        dispatch({ type: 'SET_JOINT_ANGLE_ALPHA', jointAngleAlpha: jointDesign.jointAngleAlpha.zeroToStringEmpty() });
        dispatch({ type: 'SET_JOINT_ANGLE_BETA', jointAngleBeta: jointDesign.jointAngleBeta.zeroToStringEmpty() });
        dispatch({ type: 'SET_MATERIAL_THICKNESS_1', materialThickness1: jointDesign.materialThickness1.zeroToStringEmpty() });
        dispatch({ type: 'SET_MATERIAL_THICKNESS_2', materialThickness2: jointDesign.materialThickness2.zeroToStringEmpty() });
        dispatch({ type: 'SET_NOTE', note: jointDesign.note});
        dispatch({ type: 'SET_JOINT_DESIGN_HTML_IMAGE', jointDesignHtmlImage: jointDesign.jointDesignHtmlImage});
        dispatch({ type: 'SET_PHASE_ANGLE_1', phaseAngle1: jointDesign.phaseAngle1.zeroToStringEmpty() });
        dispatch({ type: 'SET_PHASE_ANGLE_2', phaseAngle2: jointDesign.phaseAngle2.zeroToStringEmpty() });
        dispatch({ type: 'SET_RADIUS', radius: jointDesign.radius.toString() });
        dispatch({ type: 'SET_STRAIGHT_EDGE', straightEdge: jointDesign.straightEdge.zeroToStringEmpty() });
        dispatch({ type: 'SET_THROAT_THICKNESS', throatThickness: jointDesign.throatThickness.zeroToStringEmpty() });
        dispatch({ type: 'SET_SELECTED_JOINT_SHAPE', selectedJointShape: jointDesign.jointShape });
        dispatch({ type: 'SET_SELECTED_ROOT_SUPPORT', selectedRootSupport: jointDesign.rootSupport });

        if (appState && appState.drawJointDesign) {
            let jointTypes: Array<GetJointTypeResponse> = [...appState.drawJointDesign.jointTypes];
            let selectedJointType: GetJointTypeResponse = new GetJointTypeResponse();
            for (var i = 0; i < jointTypes.length; i++) {
                if (jointDesign.jointType.id === jointTypes[i].id) {
                    selectedJointType = { ...jointTypes[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_SELECTED_JOINT_TYPE', selectedJointType: selectedJointType });
        }
    },
    setHtmlImage: (htmlImage: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_JOINT_DESIGN_HTML_IMAGE', jointDesignHtmlImage: htmlImage});
    },
    setJointDesignView: (jointDesign: GetJointDesignResponse): AppThunkAction<KnownAction> => (dispatch) => {
            dispatch({ type: 'SET_JOINT_DESIGN_VIEW', jointDesignView: jointDesign });
    },
    initForm: (position: string, setDrawJointDesignView: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        switch (position) {
            case 'application':
                if (appState && appState.applicationTab && appState.drawJointDesign) {
                    let materialThickness1 = appState.applicationTab.parentMaterial.materialThickness1;
                    let materialThickness2 = appState.applicationTab.parentMaterial.materialThickness2;
                    let selectedJointType: GetJointTypeResponse = new GetJointTypeResponse();
                    for (let i = 0; i < appState.drawJointDesign.jointTypes.length; i++) {
                        if (appState.drawJointDesign.jointTypes[i].id === appState.applicationTab.weldingData.selectedJointType.id) {
                            selectedJointType = { ...appState.drawJointDesign.jointTypes[i] };
                            break;
                        }
                    }
                    dispatch({ type: 'INIT', materialThickness1: materialThickness1, materialThickness2: materialThickness2, selectedJointType: selectedJointType });
                };
                break;
            case 'weldingProcess':
                if (appState && appState.wpqrDekraTab && appState.drawJointDesign) {
                    let materialThickness1 = appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3.materialThickness1;
                    let materialThickness2 = appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection3.materialThickness2;
                    let selectedJointType: GetJointTypeResponse = new GetJointTypeResponse();
                    for (let i = 0; i < appState.drawJointDesign.jointTypes.length; i++) {
                        if (appState.drawJointDesign.jointTypes[i].id === appState.wpqrDekraTab.weldingProtocol.weldingProtocolSection2.selectedJointType.id) {
                            selectedJointType = { ...appState.drawJointDesign.jointTypes[i] };
                            break;
                        }
                    }
                    dispatch({ type: 'INIT', materialThickness1: materialThickness1, materialThickness2: materialThickness2, selectedJointType: selectedJointType });
                }
                break;
            case 'wpsForm':
                if (appState && appState.wpsFormEdit && appState.drawJointDesign) {
                    let materialThickness1 = appState.wpsFormEdit.parentMaterial.materialThickness1;
                    let materialThickness2 = appState.wpsFormEdit.parentMaterial.materialThickness2;
                    let selectedJointType: GetJointTypeResponse = new GetJointTypeResponse();
                    for (let i = 0; i < appState.drawJointDesign.jointTypes.length; i++) {
                        if (appState.drawJointDesign.jointTypes[i].id === appState.wpsFormEdit.weldingData.selectedJointType.id) {
                            selectedJointType = { ...appState.drawJointDesign.jointTypes[i] };
                            break;
                        }
                    }
                    dispatch({ type: 'INIT', materialThickness1: materialThickness1, materialThickness2: materialThickness2, selectedJointType: selectedJointType });
                }
                break;
        }
    },
    setDepthOfPreparation: (depthOfPreparation: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_DEPTH_OF_PREPARATION', depthOfPreparation: depthOfPreparation });
    },
    setGapOpening: (gapOpening: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_GAP_OPENING', gapOpening: gapOpening });
    },
    setJointAngleAlpha: (jointAngleAlpha: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_JOINT_ANGLE_ALPHA', jointAngleAlpha: jointAngleAlpha });
    },
    setJointAngleBeta: (jointAngleBeta: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_JOINT_ANGLE_BETA', jointAngleBeta: jointAngleBeta });
    },
    setMaterialThickness1: (materialThickness1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_MATERIAL_THICKNESS_1', materialThickness1: materialThickness1 });
    },
    setMaterialThickness2: (materialThickness2: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_MATERIAL_THICKNESS_2', materialThickness2: materialThickness2 });
    },
    setNote: (note: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_NOTE', note: note });
    },
    setPhaseAngle1: (phaseAngle1: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_PHASE_ANGLE_1', phaseAngle1: phaseAngle1 });
    },
    setPhaseAngle2: (phaseAngle2: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_PHASE_ANGLE_2', phaseAngle2: phaseAngle2 });
    },
    setRadius: (radius: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_RADIUS', radius: radius });
    },
    setSelectedJointShape: (jointShapeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.drawJointDesign) {
            let jointShapes: Array<GetJointShapeResponse> = [...appState.drawJointDesign.selectedJointType.jointShapes];
            let selectedJointShape: GetJointShapeResponse = new GetJointShapeResponse();
            for (var i = 0; i < jointShapes.length; i++) {
                if (jointShapeId === jointShapes[i].id) {
                    selectedJointShape = { ...jointShapes[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_SELECTED_JOINT_SHAPE', selectedJointShape: selectedJointShape });
        }
    },
    setSelectedJointType: (jointTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.drawJointDesign) {
            let jointTypes: Array<GetJointTypeResponse> = [...appState.drawJointDesign.jointTypes];
            let selectedJointType: GetJointTypeResponse = new GetJointTypeResponse();
            for (var i = 0; i < jointTypes.length; i++) {
                if (jointTypeId === jointTypes[i].id) {
                    selectedJointType = { ...jointTypes[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_SELECTED_JOINT_TYPE', selectedJointType: selectedJointType });
        }
    },
    setSelectedRootSupport: (rootSupportId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.drawJointDesign) {
            let rootSupports: Array<GetRootSupportResponse> = [...appState.drawJointDesign.rootSupports];
            let selectedRootSupport: GetRootSupportResponse = new GetRootSupportResponse();
            for (var i = 0; i < rootSupports.length; i++) {
                if (rootSupportId === rootSupports[i].id) {
                    selectedRootSupport = { ...rootSupports[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_SELECTED_ROOT_SUPPORT', selectedRootSupport: selectedRootSupport });
        }
    },
    setStraightEdge: (straightEdge: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_STRAIGHT_EDGE', straightEdge: straightEdge });
    },
    setThroatThickness: (throatThickness: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_THROAT_THICKNESS', throatThickness: throatThickness });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: DrawJointDesignState = {
    materialThickness1: '',
    materialThickness2: '',
    jointTypes: [],
    selectedJointType: {
        id: 0,
        name: '',
        description: '',
        jointShapes: [],
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    selectedJointShape: {
        id: 0,
        name: '',
        description: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    jointAngleAlpha: '',
    gapOpening: '',
    straightEdge: '',
    phaseAngle1: '',
    phaseAngle2: '',
    jointAngleBeta: '',
    throatThickness: '',
    rootSupports: [],
    selectedRootSupport: {
        id: 0,
        name: '',
        description: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    depthOfPreparation: '',
    radius: '',
    note: '',
    jointDesignView: {
        id: 0,
        materialThickness1: 0.0,
        materialThickness2: 0.0,
        jointType: {
            id: 0,
            name: '',
            description: '',
            jointShapes: [],
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        jointShape: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        jointAngleAlpha: 0.0,
        gapOpening: 0.0,
        straightEdge: 0.0,
        phaseAngle1: 0.0,
        phaseAngle2: 0.0,
        jointAngleBeta: 0.0,
        throatThickness: 0.0,
        rootSupport: {
            id: 0,
            name: '',
            description: '',
            createdAt: '',
            deletedAt: '',
            updatedAt: ''
        },
        depthOfPreparation: 0.0,
        radius: 0.0,
        note: '',
        jointDesignHtmlImage: '',
        createdAt: '',
        deletedAt: '',
        updatedAt: ''
    },
    jointDesignHtmlImage: ''
};

export const reducer: Reducer<DrawJointDesignState> = (state: DrawJointDesignState | undefined, incomingAction: Action): DrawJointDesignState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_JOINT_TYPES_RECEIVE':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: action.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,
                jointDesignView: state.jointDesignView,
            };
        case 'GET_JOINT_TYPES_REQUEST':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'GET_ROOT_SUPPORTS_RECEIVE':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: action.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'GET_ROOT_SUPPORTS_REQUEST':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'INIT':
            return {
                materialThickness1: action.materialThickness1,
                materialThickness2: action.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: action.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_DEPTH_OF_PREPARATION':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: action.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_GAP_OPENING':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: action.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_JOINT_ANGLE_ALPHA':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: action.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_JOINT_ANGLE_BETA':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: action.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_MATERIAL_THICKNESS_1':
            return {
                materialThickness1: action.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_MATERIAL_THICKNESS_2':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: action.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_NOTE':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: action.note,
                jointDesignHtmlImage: state.jointDesignHtmlImage,
                jointDesignView: state.jointDesignView
            };
        case 'SET_PHASE_ANGLE_1':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: action.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_PHASE_ANGLE_2':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: action.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_RADIUS':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: action.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_SELECTED_JOINT_SHAPE':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: action.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_SELECTED_JOINT_TYPE':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: action.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_SELECTED_ROOT_SUPPORT':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: action.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_STRAIGHT_EDGE':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: action.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_THROAT_THICKNESS':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: action.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,	 	 	 	jointDesignView: state.jointDesignView
            };
        case 'SET_JOINT_DESIGN_VIEW':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,	 	 	 	jointDesignHtmlImage: state.jointDesignHtmlImage,                jointDesignView: action.jointDesignView
            };
        case 'SET_JOINT_DESIGN_HTML_IMAGE':
            return {
                materialThickness1: state.materialThickness1,
                materialThickness2: state.materialThickness2,
                jointTypes: state.jointTypes,
                selectedJointType: state.selectedJointType,
                selectedJointShape: state.selectedJointShape,
                jointAngleAlpha: state.jointAngleAlpha,
                gapOpening: state.gapOpening,
                straightEdge: state.straightEdge,
                jointAngleBeta: state.jointAngleBeta,
                phaseAngle1: state.phaseAngle1,
                phaseAngle2: state.phaseAngle2,
                throatThickness: state.throatThickness,
                rootSupports: state.rootSupports,
                selectedRootSupport: state.selectedRootSupport,
                depthOfPreparation: state.depthOfPreparation,
                radius: state.radius,
                note: state.note,                jointDesignHtmlImage: action.jointDesignHtmlImage,                jointDesignView: state.jointDesignView
            };
        case 'RESET_STATE_DRAW_JOINT_DESIGN_ACTION':
            return unloadedState;
        default:
            return state;
    }
};
