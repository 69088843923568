﻿import { GetWeldingPositionResponse } from "./GetWeldingPositionResponse";
import { GetRootSupportDetailsResponse } from "./GetRootSupportDetailsResponse";
import { GetJointTypeResponse } from "./GetJointTypeResponse";
import { GetRootProtectionGasResponse } from "./GetRootProtectionGasResponse";
import { GetTackWeldResponse } from "./GetTackWeldResponse";

export class GetWeldingDataResponse {
    createdAt: string;
    deletedAt: string;
    id: number;
    jointType: GetJointTypeResponse;
    rootProtectionGas: GetRootProtectionGasResponse;
    rootSupportDetails: GetRootSupportDetailsResponse;
    updatedAt: string;
    weldingPosition: GetWeldingPositionResponse;
    weldingPosition2: GetWeldingPositionResponse;
    nozzleDiameter: string;
    stickOut: string;
    jointPreparationSearchText: string;
    lengthTackWelds: string;
    tungstenElectrode: string;
    maxWidthOfRun: string;
    electrodeTorchAngle: string;
    tackWeldText: string;
    electrodeTorchAngle111: string | null | undefined;
    plasmaGas: GetRootProtectionGasResponse;
    plasmaflowrate: string | null | undefined;
    enableJointTypeText: boolean;
    jointTypeText: string;
    public constructor() {
        this.createdAt = '';
        this.deletedAt = '';
        this.id = 0;
        this.jointType = new GetJointTypeResponse();
        this.rootProtectionGas = new GetRootProtectionGasResponse();
        this.rootSupportDetails = new GetRootSupportDetailsResponse();
        this.updatedAt = '';
        this.weldingPosition = new GetWeldingPositionResponse();
        this.weldingPosition2 = new GetWeldingPositionResponse();
        this.nozzleDiameter = '';
        this.stickOut = '';
        this.jointPreparationSearchText = '';
        this.tungstenElectrode = '';
        this.maxWidthOfRun = '';
        this.lengthTackWelds = '';
        this.electrodeTorchAngle = '';
        this.tackWeldText = '';
        this.electrodeTorchAngle111 = '';
        this.plasmaGas = new GetRootProtectionGasResponse();
        this.plasmaflowrate = '';
        this.enableJointTypeText = false;
        this.jointTypeText = '';
    }
}