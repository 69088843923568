﻿import { GetJointShapeResponse } from "./GetJointShapeResponse";
import { GetJointTypeResponse } from "./GetJointTypeResponse";
import { GetRootSupportResponse } from "./GetRootSupportResponse";

export class GetJointDesignResponse {
    createdAt: string;
    deletedAt: string;
    depthOfPreparation: number;
    gapOpening: number;
    id: number;
    jointAngleAlpha: number;
    jointAngleBeta: number;
    jointShape: GetJointShapeResponse;
    jointType: GetJointTypeResponse;
    materialThickness1: number;
    materialThickness2: number;
    note: string;
    jointDesignHtmlImage: string;
    phaseAngle1: number;
    phaseAngle2: number;
    radius: number;
    rootSupport: GetRootSupportResponse;
    straightEdge: number;
    throatThickness: number;
    updatedAt: string;

    public constructor() {
        this.createdAt = '';
        this.deletedAt = '';
        this.depthOfPreparation = 0.0;
        this.gapOpening = 0.0;
        this.id = 0;
        this.jointAngleAlpha = 0.0;
        this.jointAngleBeta = 0.0;
        this.jointShape = new GetJointShapeResponse();
        this.jointType = new GetJointTypeResponse();
        this.materialThickness1 = 0.0;
        this.materialThickness2 = 0.0;
        this.note = '';
        this.jointDesignHtmlImage = '';
        this.phaseAngle1 = 0.0;
        this.phaseAngle2 = 0.0;
        this.radius = 0.0;
        this.rootSupport = new GetRootSupportResponse();
        this.straightEdge = 0.0;
        this.throatThickness = 0.0;
        this.updatedAt = '';
    }
}