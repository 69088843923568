﻿export class ExistingWpqrDataExtra {
    rangeOfDate: string;
    examinerOrExaminingBody: string;
    rangeOfName: string;

    public constructor() {
        this.rangeOfDate = '';
        this.examinerOrExaminingBody = '';
        this.rangeOfName = '';
    }
}