import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import { t } from '../i18n';
import * as NewsStore from '../store/NewsStore';
import { CardHeader, Input, Row, Col, FormGroup, Button } from 'reactstrap';
import HistoryProgress from './otherComponents/HistoryProgress';
// At runtime, Redux will merge together...
type HomeProps =
    NewsStore.NewsState// ... state we've requested from the Redux store
    & typeof NewsStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-);

export interface OwnProps {
}

class Home extends React.PureComponent<HomeProps> {
    constructor(props: HomeProps) {
        super(props);
    }

    public componentDidMount() {
        this.props.setSelectedLanguage(localStorage.getItem('lang'));
        this.props.receiveNews(localStorage.getItem('lang'));
        this.props.setSelectedCustomerId(JSON.parse(localStorage.getItem('customerId')));
    }
    componentDidUpdate(prevProps: any) {
        if (localStorage.getItem('lang') !== this.props.selectedLanguage) {
            this.props.setSelectedLanguage(localStorage.getItem('lang'));
            this.props.receiveNews(localStorage.getItem('lang'));
        }
        if (JSON.parse(localStorage.getItem('customerId')) !== this.props.selectedCustomerId) {
            this.props.setSelectedCustomerId(JSON.parse(localStorage.getItem('customerId')));
        }
    }

    public state = {
        wpqrCompleteIsOpen: true,
        currentWpsId: 0,
        showDeletionModal: false,
        showAllNews: false
    };

    private setShowAllNews= (update: boolean) => {
        this.setState({
            showAllNews: !update
        });
    }

    private renderNewsList() {
        return (
            <React.Fragment key="NewsList">
                {this.props.newsList.slice(0, this.state.showAllNews ? this.props.newsList.length : 3).map((row, index) => (
                    <div>
                        <br></br>
                        <div className="card">
                            <div className="card-body">
                                        <h1>{this.props.newsList[index].title}</h1>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Input type="textarea" className="textarea-delete-borders" readonly rows={this.props.newsList[index].content.split("\n").length} bssize="sm" id={"news" + index} name={"news" + index}
                                            value={this.props.newsList[index].content}
                                            placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {this.props.newsList.length > 3 ?
                    <div>
                        <br></br>
                        < FormGroup className="float-right">
                            <Button color="primary" textleft onClick={(event) => this.setShowAllNews(this.state.showAllNews)}><i className={this.state.showAllNews ? "fas fa-angles-up" : "fas fa-angles-down"}></i>{this.state.showAllNews ? t('ShowLess') : t('ShowMore')} </Button>
                        </FormGroup>
                    </div> : null
                }
            </React.Fragment>
        );
    };

    public render() {
        return (
            <React.Fragment key="homepage">
                <form className="dekra-content-wrapper dekra-wrapper">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card">
                                    <CardHeader className="card-header-dekra">
                                        <Row>
                                            <Col sm={12}>
                                                <h1>{t('News')}</h1>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </div>
                                {this.renderNewsList()}
                            </div>
                            <div className="col-sm-6">
                            <div className="card">
                                <CardHeader className="card-header-dekra">
                                    <Row>
                                        <Col sm={12}>
                                            <h1>{t('History')}</h1>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <div className="card-body home-contact-font-size">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <HistoryProgress customerId={this.props.selectedCustomerId} showHistoriesTable={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="card">
                                <CardHeader className="card-header-dekra">
                                    <Row>
                                        <Col sm={6}>
                                            <h1>{t('Contact')}</h1>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <div className="card-body home-contact-font-size">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h6>{t('WeldingTechnicalManager')}</h6>
                                            <p>
                                                Ulla Zetterberg Anehorn<br />
                                            Tel: +46 (0)10 455 065<br />
                                            ulla.anehorn@dekra.com
                                        </p>
                                        </div>
                                        <div className="col-sm-6">
                                            <h6>{t('ResponsibleForTheWeldingProcedureQvalificationArea')}</h6>
                                            <p>
                                                Malin Ekroth<br />
                                            Tel: +46 (0)0 455 1044<br />
                                            malin.ekroth@dekra.com
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                </form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.news;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    NewsStore.actionCreators // Selects which action creators are merged into the component's props
)(Home as any);
