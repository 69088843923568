﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import * as MyPageStore from '../store/MyPageStore';
import { ApplicationState } from '../store/index';
import { t } from '../i18n';
import { UserListObject } from '../store/UserListObject';
import { UpdateMyPageRequest } from '../store/models/requests/UpdateMyPageRequest';

// At runtime, Redux will merge together...
type EditMyPageModalProps =
    MyPageStore.MyPageState // ... state we've requested from the Redux store
    & typeof MyPageStore.actionCreators  // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    show: boolean;
    toggleShow: () => void;
}

class EditMyPageModal extends React.PureComponent<EditMyPageModalProps> {
    public constructor(props: EditMyPageModalProps) {
        super(props);
    }

    public componentDidMount() {

    }

    private onClickToggleShowModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.props.toggleShow();
    }

    private confirmModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();

        const newUser: UpdateMyPageRequest = {
            userName: this.props.information.userName,
            firstName: (document.getElementById('firstNameInput') as HTMLInputElement).value,
            lastName: (document.getElementById('lastNameInput') as HTMLInputElement).value,
            displayName: this.props.information.displayName,

            //assignedCompany: (document.getElementById('companyInput') as HTMLInputElement).value,
            title: (document.getElementById('titleInput') as HTMLInputElement).value,
            email: (document.getElementById('emailInput') as HTMLInputElement).value,

            phoneNumber: (document.getElementById('phoneInput') as HTMLInputElement).value,
            mobilePhoneNumber: (document.getElementById('mobilePhoneInput') as HTMLInputElement).value,
            phoneExchangeNumber: this.props.information.phoneExchangeNumber,
            //roles: this.props.information.roles,

            visitingCity: (document.getElementById('cityInput') as HTMLInputElement).value,
            visitingZipcode: (document.getElementById('zipcodeInput') as HTMLInputElement).value,
            visitingStreet: (document.getElementById('streetInput') as HTMLInputElement).value,

            postCity: (document.getElementById('postCityInput') as HTMLInputElement).value,
            postZipcode: (document.getElementById('postZipcodeInput') as HTMLInputElement).value,
            postStreet: (document.getElementById('postStreetInput') as HTMLInputElement).value,

            //customer: this.props.information.customer,
            //customerId: this.props.information.customerId,
            website: this.props.information.website,
            //id: this.props.information.id,
        };

        this.props.updateUserInformation(newUser, () => { this.props.recieveUserInformation() });

        //this.props.setUserInformation(newUser);
        //this.props.setUserInformation(this.props.contactInformationForDekra);
        this.props.toggleShow();
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.show} onOpened={() => this.props.initForm()} size="lg">
                    <ModalHeader>
                        Ändra mina uppgifter
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <h5 style={{ color: "#007D40" }}>{t('MyInformation')}</h5>
                                        {/*this.props.information.customer ?
                                            <React.Fragment> <Row form>
                                                <Col className="field-flex" sm={12}>
                                                    <Label className="field-label" for="" size="sm">{t('Company')}</Label>
                                                </Col>
                                            </Row>
                                                <Row form>
                                                    <Col className="field-flex" sm={12}>
                                                        <Input type="text" bssize="sm" id="companyInput" name="modalInput" className="modalInput field-input"
                                                            defaultValue={this.props.information.customer.name}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </Col>
                                                </Row>
                                                <div className="modal-distancer" />
                                            </React.Fragment> : null*/}

                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('FirstName')}</Label>
                                                <Input type="text" bssize="sm" id="firstNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.firstName}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('LastName')}</Label>
                                                <Input type="text" bssize="sm" id="lastNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.lastName}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        {/*<div className="modal-distancer" />*/}
                                        {/*<Row form>*/}
                                        {/*    <Col className="field-flex" sm={12}>*/}
                                        {/*        <Label className="field-label" for="" size="sm">{t('Username')}</Label>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<Row form>*/}
                                        {/*    <Col className="field-flex" sm={12}>*/}
                                        {/*        <Input type="text" bssize="sm" name="modalInput" className="modalInput field-input"*/}
                                        {/*            defaultValue="A404464"*/}
                                        {/*            onKeyPress={(event) => this.onKeyPress(event)}*/}
                                        {/*            placeholder="" />*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Title')}</Label>
                                                <Input type="text" bssize="sm" id="titleInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.title}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Email')}</Label>
                                                <Input type="text" bssize="sm" id="emailInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.email}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Phone')}</Label>
                                                <Input type="text" bssize="sm" id="phoneInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.phoneNumber}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('MobilePhone')}</Label>
                                                <Input type="text" bssize="sm" id="mobilePhoneInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.mobilePhoneNumber}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <h5 style={{ color: "#007D40" }}>{t('Address')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Street')}</Label>
                                                <Input type="text" bssize="sm" id="streetInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.visitingStreet}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('City')}</Label>
                                                <Input type="text" bssize="sm" id="cityInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.visitingCity}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="zipcodeInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.visitingZipcode}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        {/*<h5 style={{ color: "#007D40", marginTop: 44 }}>{t('PostAddress')}</h5>*/}
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('DeliveryAddress')}</Label>
                                                <Input type="text" bssize="sm" id="postStreetInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.postStreet}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('PostCity')}</Label>
                                                <Input type="text" bssize="sm" id="postCityInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.postCity}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="postZipcodeInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.information.postZipcode}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                {/*<Col sm={6}>*/}
                                {/*    <FormGroup>*/}
                                {/*        <h5 style={{ color: "#007D40" }}>{t('SupportDekraIndustrialAB')}</h5>*/}
                                {/*        <Row form>*/}
                                {/*            <Col className="field-flex" sm={12}>*/}
                                {/*                <Label className="field-label" for="" size="sm">{t('PhoneExchange')}</Label>*/}
                                {/*            </Col>*/}
                                {/*        </Row>*/}
                                {/*        <Row form>*/}
                                {/*            <Col className="field-flex" sm={12}>*/}
                                {/*                <Input type="text" bssize="sm" name="modalInput" className="modalInput field-input"*/}
                                {/*                    defaultValue={this.props.contactInformationForDekra.phoneExchange}*/}
                                {/*                    onKeyPress={(event) => this.onKeyPress(event)}*/}
                                {/*                    placeholder="" />*/}
                                {/*            </Col>*/}
                                {/*        </Row>*/}
                                {/*        <div className="modal-distancer" />*/}
                                {/*        <Row form>*/}
                                {/*            <Col className="field-flex" sm={12}>*/}
                                {/*                <Label className="field-label" for="" size="sm">{t('Email')}</Label>*/}
                                {/*            </Col>*/}
                                {/*        </Row>*/}
                                {/*        <Row form>*/}
                                {/*            <Col className="field-flex" sm={12}>*/}
                                {/*                <Input type="text" bssize="sm" name="modalInput" className="modalInput field-input"*/}
                                {/*                    defaultValue={this.props.contactInformationForDekra.email}*/}
                                {/*                    onKeyPress={(event) => this.onKeyPress(event)}*/}
                                {/*                    placeholder="" />*/}
                                {/*            </Col>*/}
                                {/*        </Row>*/}
                                {/*    </FormGroup>*/}
                                {/*</Col>*/}
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col sm={12}>
                                <Button color="primary" onClick={(event) => this.confirmModal(event)}>{t('OK')}</Button>
                                {' '}
                                <Button color="secondary" onClick={(event) => this.onClickToggleShowModal(event)}>{t('Cancel')}</Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.myPage;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    MyPageStore.actionCreators // Selects which action creators are merged into the component's props
)(EditMyPageModal as any);
