//Encoding problem

import * as React from 'react';
import { Route, Redirect } from 'react-router';
import Applications from './components/Applications';
import ApplicationTab from './components/ApplicationTab';
import Home from './components/Home';
import Layout from './components/Layout';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import RegisterUser from './components/RegisterUser';
import TestResultsExamination from './components/TestResultsExamination';
import WPQRDEKRATab from './components/WPQRDEKRATab';
import WPQRTab from './components/WPQRTab';
import WPSTab from './components/WPSTab';
import WeldingPlanTab from './components/WeldingPlanTab';
import WPSFormEdit from './components/WPSFormEdit';
import AuthVerify from './components/AuthVerify';
import MyPage from './components/MyPage';
import CustomerList from './components/CustomerList';
import UserList from './components/UserList';
import DekraList from './components/DekraList';
import './custom.css';
import NewsList from './components/NewsList';
//Roles: Customer, Dekra, Admin
export default () =>
    (
       
    <Layout>
        <Route path='/applications/:applicationId?' component={Applications} />

        <ProtectedRoute
            exact path='/'
            component={Home}
            allowedPermissions={[]}
        />

        <ProtectedRoute
            path='/application/:applicationId?'
            component={ApplicationTab}
            allowedPermissions={["Admin", "Customer"]}
        />
      
        <ProtectedRoute
            path='/wpqr'
            component={WPQRTab}
            allowedPermissions={["Admin", "Customer"]}
        />
            <Route path='/testresultsexamination' component={TestResultsExamination} />

        <ProtectedRoute
            path='/wps'
            component={WPSTab}
            allowedPermissions={["Admin", "Customer"]}
        />
        <Route path='/wpsformedit/:applicationId?/:wpsId?' component={WPSFormEdit} />

        <ProtectedRoute
            path='/weldingplan'
            component={WeldingPlanTab}
            allowedPermissions={["Admin", "Customer"]}
        />

        <ProtectedRoute
            path='/dashboardh'
            component={ApplicationTab}
            allowedPermissions={["Admin", "Customer"]}
        />
    
        <ProtectedRoute 
            path='/wpqrdekra'
            component={WPQRDEKRATab}
            allowedPermissions={["Admin", "Dekra"]}
        />

        <ProtectedRoute
            path='/customerlist'
            component={CustomerList}
            allowedPermissions={["Admin"]}
        />

        <ProtectedRoute
            path='/dashboard'
            component={WPSTab}
            allowedPermissions={["Admin", "Customer"]}
        />
        <ProtectedRoute
            path='/mypage'
            component={MyPage}
            allowedPermissions={[]}
        />

        <ProtectedRoute
            path='/userlist'
            component={UserList}
            allowedPermissions={["Admin"]}
        />

        <ProtectedRoute
            path='/dekralist'
            component={DekraList}
            allowedPermissions={["Admin"]}
        />

        <ProtectedRoute
            path='/newslist'
            component={NewsList}
            allowedPermissions={["Admin"]}
        />
     
        <Route path='/login' component={Login} />
            <Route path='/registeruser' component={RegisterUser} />
            <AuthVerify  /> 
    </Layout>
);



