﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { ApplicationState } from '../store/index';
import * as LoginStore from '../store/LoginStore';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col, Container, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Nav, NavItem, NavLink, Row, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { t } from '../i18n';

interface ILoginInfoProps extends RouteComponentProps<{ token: string }> {

}

// At runtime, Redux will merge together...
type LoginProps =
    LoginStore.LoginState // ... state we've requested from the Redux store
    & typeof LoginStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ token: string }>;

class Login extends React.PureComponent<LoginProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        let params = this.queryStringParse(this.props.location.search);
        const accessToken = localStorage.getItem('accessToken');
        const tempToken = sessionStorage.getItem('savedToken');
        console.log('tempToken');
        console.log(tempToken);

        console.log('accessToken');
        console.log(accessToken);

        if (params["Token"] !== undefined) {
            console.log(params["Token"]);
            console.log('params[Token]');
            localStorage.setItem('accessToken', params['Token']);
            this.props.adlogin(params["Token"]);
        } else {
            if (tempToken) {
                console.log('tempToken login');
                this.props.adlogin(tempToken);
            } else {
                if (accessToken) {
                    console.log('adlogin(accessToken)');
                    //this.props.CheckToken();
                    this.props.adlogin(accessToken);
                } else {
                    this.redirectToLoginSystem();
                }
            }
        }
    }

    public queryStringParse(url: string) {
        const arr = url.slice(1).split(/&|=/); // remove the "?", "&" and "="
        let queryStringparams: { [key: string]: string } = {
        }
        for (let i = 0; i < arr.length; i += 2) {
            const key = arr[i], value = arr[i + 1];
            queryStringparams[key] = value; // build the object = { limit: "10", page:"1", status:"APPROVED" }
        }

        return queryStringparams;
    }

    private onChangeUserName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setUserName(event.currentTarget.value);
    }

    private onClickLoginButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.login(this.props.userName);
    }

    private onClickLogoutButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.logout();
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private redirectToLoginSystem() {
        const currentURL = window.location.href;
        if (currentURL.indexOf("dekra-industrial.se") >= 0) {
            window.location.replace("https://weldbase2-test.dekra-industrial.se:444/");
        }
        else {
            window.location.replace("https://localhost:44314/");
        }
    }

    public render() {
        let params = this.queryStringParse(this.props.location.search);
        //if (this.props.loggedIn)
        //{


        //    if (params["Token"] !== undefined)
        //    {
        //       /// window.location.href = '/';
        //    }
        //}

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <Card style={{ display: 'none' }}>
                                <CardBody>
                                    <Row>
                                        <Col sm={3}>
                                        </Col>
                                        <Col sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm={12}>
                                                            {/* 


                                                            <FormGroup>
                                                                <Label for="userName" size="sm">Användarnamn</Label>
                                                               <Input type="text" bsSize="sm" name="userName" id="userName"
                                                                    value={this.props.userName} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeUserName(event)}
                                                                    placeholder="" />
                                                            </FormGroup>
                                                            */}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            {/*
                                                            <FormGroup>
                                                                <Label for="password" size="sm">Lösenord</Label>
                                                                <Input type="text" bsSize="sm" name="password" id="password"
                                                                    value={this.props.password} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangePassword(event)}
                                                                    placeholder="" /> 
                                                            </FormGroup>
                                                             */}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            {/*
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={(event) => this.onClickLoginButton(event)}>Logga in AD</Button>
                                                            </ButtonGroup>
                                                              */}
                                                            {this.props.loggedIn == false &&
                                                                <Col sm={12}>
                                                                    <h1 className="card-title">{t('ClickBelow_')}</h1>
                                                                    <ButtonGroup className="float-left">
                                                                    <a href="https://weldbase2-test.dekra-industrial.se:444/">
                                                                            <Button color="primary">{t('LogIn')}</Button>
                                                                        </a>
                                                                    </ButtonGroup>
                                                                </Col>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {this.props.loggedIn == true && (params["Token"] || localStorage.getItem('accessToken')) !== undefined &&
                                                            <Redirect to={{ pathname: "/" }} />
                                                        }
                                                        {this.props.loggedIn == true &&
                                                            <Col sm={12}>
                                                                <h1 className="card-title">{t('ClickBelowToLogOut')}</h1>
                                                                <ButtonGroup className="float-left">
                                                                    <Button onClick={(event) => this.onClickLogoutButton(event)} color="primary">{t('LogOut')}</Button>
                                                                </ButtonGroup>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    loggedIn: state.login.loggedIn
})

export default connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    LoginStore.actionCreators // Selects which action creators are merged into the component's props
)(Login as any);