﻿export class UpdateJointDesignRequest {
    depthOfPreparation: number;
    gapOpening: number;
    id: number;
    jointAngleAlpha: number;
    jointAngleBeta: number;
    jointShapeId?: number;
    jointTypeId?: number;
    materialThickness1: number;
    materialThickness2: number;
    note: string;
    jointDesignHtmlImage: string;
    phaseAngle1: number;
    phaseAngle2: number;
    radius: number;
    rootSupportId?: number;
    straightEdge: number;
    throatThickness: number;

    public constructor() {
        this.depthOfPreparation = 0.0;
        this.gapOpening = 0.0;
        this.id = 0;
        this.jointAngleAlpha = 0.0;
        this.jointAngleBeta = 0.0;
        this.jointShapeId = undefined;
        this.jointTypeId = undefined;
        this.materialThickness1 = 0.0;
        this.materialThickness2 = 0.0;
        this.note = '';
        this.jointDesignHtmlImage = '';
        this.phaseAngle1 = 0.0;
        this.phaseAngle2 = 0.0;
        this.radius = 0.0;
        this.rootSupportId = undefined;
        this.straightEdge = 0.0;
        this.throatThickness = 0.0;
    }
}