﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardText, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import * as WPQRDEKRATabStore from '../store/WPQRDEKRATabStore';
import { ApplicationState } from '../store/index';
import { t } from '../i18n';
import WPQRPageHeader from './WPQRPageHeader';
import Message from './Message';
import { GetMessageResponse } from '../store/models/responses/GetMessageResponse';
import { OtherOptions } from '../store/models/OtherOptions';
import { WeldingTestingDetails1 } from '../store/models/WeldingTestingDetails1';
import { ExistingWpqrDataExtra } from '../store/models/ExistingWpqrDataExtra';

// At runtime, Redux will merge together...
type TestingDetails1ComponentProps =
    WPQRDEKRATabStore.WPQRDEKRATabStoreState // ... state we've requested from the Redux store
    & typeof WPQRDEKRATabStore.actionCreators  // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    hiddenMessageExistingWpqr?: boolean;
    setWeldingTestingDetails1?: WeldingTestingDetails1;
    // Extra
    examinerOrExaminingBody?: string; 
    rangeOfName?: string;
    rangeOfDate?: string;

}

class TestingDetails1Component extends React.PureComponent<TestingDetails1ComponentProps> {
    public constructor(props: TestingDetails1ComponentProps) {
        super(props);
    }

    public componentDidMount() {
    }

    componentDidUpdate(prevProps: any) {

        if (prevProps.setWeldingTestingDetails1 !== this.props.setWeldingTestingDetails1) {
            this.props.setTestingDetail1Component(this.props.setWeldingTestingDetails1);
        }

        if (prevProps.examinerOrExaminingBody !== this.props.examinerOrExaminingBody) {
            this.props.setWeldingTestingDetails1Examiner(this.props.examinerOrExaminingBody);
        }

        if (prevProps.rangeOfName !== this.props.rangeOfName) {
            this.props.setWeldingTestingDetails1ExaminerName(this.props.rangeOfName);
        }

        if (prevProps.rangeOfDate !== this.props.rangeOfDate) {
            this.props.setWeldingTestingDetails1ExaminerDate(this.props.rangeOfDate);
        }
    }

    public state = {
        errorList: new Map<string, boolean>([
            ['userNameMissing', false],
            ['usernameTaken', false]
        ]),
        usersSearchResult: [],
    };

    private usersSearchResultClear = () => {
        let list = this.state.usersSearchResult;
        list.length = 0;
        this.setState({
            usersSearchResult: list
        });
    }
    private usersSearchResult = (value: any[]) => {
        this.setState({
            usersSearchResult: value
        });
    }

    public onRolesSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
    }

    public onCompanySelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
    }

    private onClickToggleShowModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.resetErrors();
        //this.props.toggleShow();
    }

    private setError(errorName: string, showError: boolean) {
        let currentErrors = this.state.errorList;

        if (currentErrors.has(errorName)) {
            currentErrors.set(errorName, showError)
        }

        //(document.getElementById(errorName) as HTMLElement).classList.add('fadeInFromLeftClass');

        this.setState({ currentErrors: currentErrors });
    }

    private errorsExist() {
        let currentErrorsArray = Array.from<boolean>(this.state.errorList.values());

        if (currentErrorsArray.find(errorBoolean => errorBoolean) !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    private resetErrors() {
        let currentErrors = this.state.errorList;

        currentErrors.forEach((isOn, error) => {
            currentErrors.set(error, false);
        });

        this.setState({ currentErrors: currentErrors });
    }
    
    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private onChangeWeldingTestingDetails1BackingGasFlowRate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1BackingGasFlowRate(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1BackingGasFlux(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1BackingGasFlux(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Examiner(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Examiner(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ExaminerDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ExaminerDate(event.currentTarget.value);
    }
    private onChangeWeldingTestingDetails1CommentInformation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1CommentInformation(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ExaminerName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ExaminerName(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ExaminerSignature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ExaminerSignature(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.usersSearchResultClear(); //Clear
            this.props.weldingTestingDetails1.users.filter(user => user.roles.find(role => role.includes('DEKRA') || role.includes('Admin'))).forEach((item) => {
                var value = item.firstName + " " + item.lastName;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.usersSearchResult.push(value);
                    this.usersSearchResult(this.state.usersSearchResult)
                }
            });
        }
        else {
            // Clear
            this.usersSearchResultClear();
        }
    }

    private onChangeWeldingTestingDetails1ChargeNumberHeatNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ChargeNumberHeatNumber(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ChargeNumberLotNumberSerialNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ChargeNumberLotNumberSerialNumber(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1DetailsOfGougingBacking(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1DetailsOfGougingBacking(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1FillerMaterialDesignation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1FillerMaterialDesignation(event.currentTarget.value);
    }

    //private onChangeWeldingTestingDetails1FillerMaterialMake(event: React.ChangeEvent<HTMLInputElement>) {
    //    event.stopPropagation();
    //    this.props.setWeldingTestingDetails1FillerMaterialMake(event.currentTarget.value);
    //}

    private onChangeWeldingTestingDetails1FillerMaterialTradeName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1FillerMaterialTradeName(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1HeatingAndCoolingRates(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1HeatingAndCoolingRates(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1InterpassTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1InterpassTemperature(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1JointType(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1JointType(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Location(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Location(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ManufacturerOfPowerSource(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ManufacturerOfPowerSource(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ManufacturerPWPSNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ManufacturerPWPSNumber(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer2(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer2Date(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer2Date(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer2Name(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer2Name(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Manufacturer2Signature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Manufacturer2Signature(event.currentTarget.value);
    }

    private onClickSelectUser(value: string) {
        this.usersSearchResultClear();//Clear
        this.props.setWeldingTestingDetails1ExaminerSignature(value);
    }

    private onChangeWeldingTestingDetails1MaterialThickness(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1MaterialThickness(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1MethodOfPreparationAndCleaning(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1MethodOfPreparationAndCleaning(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1NozzleDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1NozzleDiameter(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1OtherInformation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1OtherInformation(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1OutsideDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1OutsideDiameter(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ParentMaterialGroup(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ParentMaterialGroup(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ParentMaterialNameOrNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ParentMaterialNameOrNumber(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1PostWeldHeatTreatmentAndOrAgeing(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1PostWeldHeatTreatmentAndOrAgeing(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1PreheatTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1PreheatTemperature(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ShieldingGasFlowRate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ShieldingGasFlowRate(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ShieldingGasFlux(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ShieldingGasFlux(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1StandOffDistance(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1StandOffDistance(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1TechnicalDeliveryStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1TechnicalDeliveryStandard(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1TorchAngle(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1TorchAngle(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1TungstenElectrode(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1TungstenElectrode(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ElectrodeTorchAngle111(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ElectrodeTorchAngle111(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Plasmaflowrate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Plasmaflowrate(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1PlasmaGasName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1PlasmaGasNam(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1Weaving(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1Weaving(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1TackWeldText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1TackWeldText(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1LengthTackWelds(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1LengthTackWelds(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1WelderName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1WelderName(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1WeldingPosition(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1WeldingPosition(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1WeldingPosition2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1WeldingPosition2(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1WeldingProcess(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1WeldingProcess(event.currentTarget.value);
    }

    private onChangeWeldingTestingDetails1ArcType(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingTestingDetails1ArcType(event.currentTarget.value);
    }
    private initMessageActive = (messagesList: Array<GetMessageResponse>, messageCodeActive: string) => {
        if (messagesList && messagesList.length > 0) {
            let exist = false;
            for (let i = 0; i < messagesList.length; i++) {
                if (messagesList[i].messageCode === messageCodeActive) {
                    exist = true;
                }
            }
            return exist
        } else {
            return false;
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Row form>
                    <Col sm={12}>
                        {this.renderWeldingTestingDetails1()}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    private renderWeldingTestingDetails1() {
        return (
            <React.Fragment>
                <Form>
                    <WPQRPageHeader pCommissionNumber={this.props.weldingProtocol.assignmentNumber}
                        pManufacturerWPQRNumber={this.props.weldingProtocol.manufacturerWPQRNumber}
                        pDekraProcedureNumber={this.props.weldingProtocol.dekraProcedureNumberDisplay}
                        pageNumber={'2/4'} />
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <h4>{t('WeldingTestingDetails')}</h4>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1Manufacturer" size="sm">{t('Manufacturer')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1Manufacturer" id="weldingTestingDetails1Manufacturer"
                                                        value={this.props.weldingTestingDetails1.manufacturer} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1Manufacturer(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1Location" size="sm">{t('City')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1Location" id="weldingTestingDetails1Location"
                                                        value={this.props.weldingTestingDetails1.location} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1Location(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1WelderName" size="sm">{t('WeldersName')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1WelderName" id="weldingTestingDetails1WelderName"
                                                        value={this.props.weldingTestingDetails1.welderName} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1WelderName(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ManufacturerPWPSNumber" size="sm">{t('ManufacturersPWPSNo')}.</Label>
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1ManufacturerPWPSNumber" id="weldingTestingDetails1ManufacturerPWPSNumber"
                                                        value={this.props.weldingTestingDetails1.manufacturerPWPSNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ManufacturerPWPSNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 3}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1WeldingProcess" size="sm">{t('WeldingMethod')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_14'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolWeldingMethod')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolWeldingMethod' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.weldingProcess.split("\n").length} bsSize="sm" name="weldingTestingDetails1WeldingProcess" id="weldingTestingDetails1WeldingProcess"
                                                        value={this.props.weldingTestingDetails1.weldingProcess} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1WeldingProcess(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3} hidden={this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ActType" size="sm">{t('ArcType')} (MIG, MAG)</Label>
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.arcType.split("\n").length} bsSize="sm" name="weldingTestingDetails1ArcType" id="weldingTestingDetails1ArcType"
                                                        value={this.props.weldingTestingDetails1.arcType} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ArcType(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1MethodOfPreparationAndCleaning" size="sm">{t('MethodForPreparationAndCleaning')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_JointPreparation_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolJointPreparation')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolJointPreparation' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1MethodOfPreparationAndCleaning" id="weldingTestingDetails1MethodOfPreparationAndCleaning"
                                                        value={this.props.weldingTestingDetails1.methodOfPreparationAndCleaning} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1MethodOfPreparationAndCleaning(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1JointType" size="sm">{t('JointType')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_15'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolJointTypes')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolJointTypes' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1JointType" id="weldingTestingDetails1JointType"
                                                        value={this.props.weldingTestingDetails1.jointType} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1JointType(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1DetailsOfGougingBacking" size="sm">{t('RootSupportDetails')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_RootSupportDetails_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolRootSupportDetails')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolRootSupportDetails' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1DetailsOfGougingBacking" id="weldingTestingDetails1DetailsOfGougingBacking"
                                                        value={this.props.weldingTestingDetails1.detailsOfGougingBacking} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1DetailsOfGougingBacking(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1WeldingPosition" size="sm">{t('WeldingPosition')} 1</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_16'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolWeldingPosition')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolWeldingPosition' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1WeldingPosition" id="weldingTestingDetails1WeldingPosition"
                                                        value={this.props.weldingTestingDetails1.weldingPosition} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1WeldingPosition(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1WeldingPosition" size="sm">{t('WeldingPosition')} 2</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_16_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolWeldingPosition2')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolWeldingPosition2' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1WeldingPosition2" id="weldingTestingDetails1WeldingPosition2"
                                                        value={this.props.weldingTestingDetails1.weldingPosition2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1WeldingPosition2(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ParentMaterialNameOrNumber" size="sm">{t('BaseMaterial')}, {t('nameOrNumber')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_17'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolBaseMaterials')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolBaseMaterials' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.parentMaterialNameOrNumber.split("\n").length} bsSize="sm" name="weldingTestingDetails1ParentMaterialNameOrNumber" id="weldingTestingDetails1ParentMaterialNameOrNumber"
                                                        value={this.props.weldingTestingDetails1.parentMaterialNameOrNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ParentMaterialNameOrNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1TechnicalDeliveryStandard" size="sm">{t('TechnicalDeliveryStandard')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_18'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolTechnicalDeliveryConditions')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolTechnicalDeliveryConditions' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.technicalDeliveryStandard.split("\n").length} bsSize="sm" name="weldingTestingDetails1TechnicalDeliveryStandard" id="weldingTestingDetails1TechnicalDeliveryStandard"
                                                        value={this.props.weldingTestingDetails1.technicalDeliveryStandard} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1TechnicalDeliveryStandard(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ParentMaterialGroup" size="sm">{t('BaseMaterialGroup')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_19'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMaterialGroup')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMaterialGroup' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.parentMaterialGroup.split("\n").length} bsSize="sm" name="weldingTestingDetails1ParentMaterialGroup" id="weldingTestingDetails1ParentMaterialGroup"
                                                        value={this.props.weldingTestingDetails1.parentMaterialGroup} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ParentMaterialGroup(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ChargeNumberHeatNumber" size="sm">{t('ChargeNumberHeatNumber')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_20'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolChargeAltHeatNumber')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolChargeAltHeatNumber' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.chargeNumberHeatNumber.split("\n").length} bsSize="sm" name="weldingTestingDetails1ChargeNumberHeatNumber" id="weldingTestingDetails1ChargeNumberHeatNumber"
                                                        value={this.props.weldingTestingDetails1.chargeNumberHeatNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ChargeNumberHeatNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1MaterialThickness" size="sm">{t('MaterialThickness')} (mm)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_21'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMaterialThickness')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMaterialThickness' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.materialThickness.split("\n").length} bsSize="sm" name="weldingTestingDetails1MaterialThickness" id="weldingTestingDetails1MaterialThickness"
                                                        value={this.props.weldingTestingDetails1.materialThickness} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1MaterialThickness(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1OutsideDiameter" size="sm">{t('OutsideDiameter')} (mm)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_22'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolDiameterParentMaterial')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolDiameterParentMaterial' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.outsideDiameter.split("\n").length} bsSize="sm" name="weldingTestingDetails1OutsideDiameter" id="weldingTestingDetails1OutsideDiameter"
                                                        value={this.props.weldingTestingDetails1.outsideDiameter} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1OutsideDiameter(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1FillerMaterialTradeName" size="sm">{t('AdditivesPowder')}, {t('tradeName')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_23'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMakes')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMakes' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.fillerMaterialTradeName.split("\n").length} bsSize="sm" name="weldingTestingDetails1FillerMaterialTradeName" id="weldingTestingDetails1FillerMaterialTradeName"
                                                        value={this.props.weldingTestingDetails1.fillerMaterialTradeName} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1FillerMaterialTradeName(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1FillerMaterialDesignation" size="sm">{t('AdditivesPowder')}, {t('designation')}</Label>
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.fillerMaterialDesignation.split("\n").length} bsSize="sm" name="weldingTestingDetails1FillerMaterialDesignation" id="weldingTestingDetails1FillerMaterialDesignation"
                                                        value={this.props.weldingTestingDetails1.fillerMaterialDesignation} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1FillerMaterialDesignation(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ChargeNumberLotNumberSerialNumber" size="sm">{t('ChargeLotSerialNumber')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_24'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolLotNumberOrHeatNumber')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolLotNumberOrHeatNumber' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.chargeNumberLotNumberSerialNumber.split("\n").length} bsSize="sm" name="weldingTestingDetails1ChargeNumberLotNumberSerialNumber" id="weldingTestingDetails1ChargeNumberLotNumberSerialNumber"
                                                        value={this.props.weldingTestingDetails1.chargeNumberLotNumberSerialNumber} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ChargeNumberLotNumberSerialNumber(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1TackWeldText" size="sm">{t('ProcessTackWelds')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_weldingDataTackWeld_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingDataTackWeld')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingDataTackWeld' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1TackWeldText" id="weldingTestingDetails1TackWeldText"
                                                        value={this.props.weldingTestingDetails1.tackWeldText} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1TackWeldText(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1LengthTackWelds" size="sm">{t('LengthTackWelds')} (mm)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_weldingDataLengthTackWelds_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingDataLengthTackWelds')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingDataLengthTackWelds' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1LengthTackWelds" id="weldingTestingDetails1LengthTackWelds"
                                                        value={this.props.weldingTestingDetails1.lengthTackWelds} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1LengthTackWelds(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1Weaving" size="sm">{t('Oscillation')} ({t('MaxWidthOfRun')}) (mm)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_OscillationMaxRunWidth_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolOscillationMaxRunWidth')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolOscillationMaxRunWidth' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1Weaving" id="weldingTestingDetails1Weaving"
                                                        value={this.props.weldingTestingDetails1.weaving} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1Weaving(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ShieldingGasFlux" size="sm">{this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t("BurnerGasType") : t('ShieldingGas')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_Gases_1_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolShieldingGases')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolShieldingGases' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.shieldingGasFlux.split("\n").length} bsSize="sm" name="weldingTestingDetails1ShieldingGasFlux" id="weldingTestingDetails1ShieldingGasFlux"
                                                        value={this.props.weldingTestingDetails1.shieldingGasFlux} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ShieldingGasFlux(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ShieldingGasFlowRate" size="sm">{t('ShieldingGas')}, {t('flow')} (l/min)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_GasFlowMethodTopSide'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolGasFlowMethodTopSide')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolGasFlowMethodTopSide' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.shieldingGasFlowRate.split("\n").length} bsSize="sm" name="weldingTestingDetails1ShieldingGasFlowRate" id="weldingTestingDetails1ShieldingGasFlowRate"
                                                        value={this.props.weldingTestingDetails1.shieldingGasFlowRate} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ShieldingGasFlowRate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1BackingGasFlux" size="sm">{t('RootProtectionGas')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_RootProtectionGases_1'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolRootProtectionGases')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolRootProtectionGases' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1BackingGasFlux" id="weldingTestingDetails1BackingGasFlux"
                                                        value={this.props.weldingTestingDetails1.backingGasFlux} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1BackingGasFlux(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1BackingGasFlowRate" size="sm">{t('RootProtectionGas')}, {t('flow')} (l/min)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_GasFlowMethodRootSide_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolGasFlowMethodRootSide')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolGasFlowMethodRootSide' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1BackingGasFlowRate" id="weldingTestingDetails1BackingGasFlowRate"
                                                        value={this.props.weldingTestingDetails1.backingGasFlowRate}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1BackingGasFlowRate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingDataPlasmaGases" size="sm">{t('PlasmaGas')}</Label>
                                                    <Input type="text" bssize="sm" name="weldingDataPlasmaGases" id="weldingDataPlasmaGases"
                                                        value={this.props.weldingTestingDetails1.plasmaGasName}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1PlasmaGasName(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingDataPlasmaflowrate" size="sm">{t('Plasmaflowrate')} (l/min)</Label>
                                                    <Input type="text" bssize="sm" name="weldingDataPlasmaflowrate" id="weldingDataPlasmaflowrate"
                                                        value={this.props.weldingTestingDetails1.plasmaflowrate}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1Plasmaflowrate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1NozzleDiameter" size="sm">{this.props.selectedApplication.pwps.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t('NozzleSize') + " (l/min)[Gas]" : t('NozzleDiameter') + " (mm) [MIG, MAG, TIG, 15X]"}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_NozzleDiameter_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolNozzleDiameter')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolNozzleDiameter' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1NozzleDiameter" id="weldingTestingDetails1NozzleDiameter"
                                                        value={this.props.weldingTestingDetails1.nozzleDiameter}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1NozzleDiameter(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1StandOffDistance" size="sm">{t('ContactTubeNozzleDistance')} (mm) (MIG, MAG, 15X)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_NozzleDistance_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolNozzleDistance')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolNozzleDistance' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1StandOffDistance" id="weldingTestingDetails1StandOffDistance"
                                                        value={this.props.weldingTestingDetails1.standOffDistance}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1StandOffDistance(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1TorchAngle" size="sm">{t('TorchAngle')} (MIG, MAG, {t('mechanical')})</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_GunAngle_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolGunAngle')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolGunAngle' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1TorchAngle" id="weldingTestingDetails1TorchAngle"
                                                        value={this.props.weldingTestingDetails1.torchAngle}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1TorchAngle(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1TungstenElectrode" size="sm">{t('TungstenElectrode')}, {t('typedimension')} (mm) [14X, 15X]</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_TungstenElectrode_2'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolTungstenElectrode')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolTungstenElectrode' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1TungstenElectrode" id="weldingTestingDetails1TungstenElectrode"
                                                        value={this.props.weldingTestingDetails1.tungstenElectrode}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1TungstenElectrode(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingDataElectrodeTorchAngle111" size="sm">{t('ElectrodeTorchAngle111')}</Label>
                                                    <Input type="text" bssize="sm" name="weldingDataElectrodeTorchAngle111" id="weldingDataElectrodeTorchAngle111"
                                                        value={this.props.weldingTestingDetails1.electrodeTorchAngle111}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1ElectrodeTorchAngle111(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ManufacturerOfPowerSource" size="sm">{t('ManufacturerOfPowerSource')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_PowerSourceManufacturer_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolPowerSourceManufacturer')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolPowerSourceManufacturer' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1ManufacturerOfPowerSource" id="weldingTestingDetails1ManufacturerOfPowerSource"
                                                        value={this.props.weldingTestingDetails1.manufacturerOfPowerSource}
                                                        onKeyPress={(event) => this.onKeyPress(event)}
                                                        onChange={(event) => this.onChangeWeldingTestingDetails1ManufacturerOfPowerSource(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1PreheatTemperature" size="sm">{t('PreheatTemperature')} (ºC)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_MinElevatedWorkingTemperature'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMinElevatedWorkingTemperature_d')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMinElevatedWorkingTemperature' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1PreheatTemperature" id="weldingTestingDetails1PreheatTemperature"
                                                        value={this.props.weldingTestingDetails1.preheatTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1PreheatTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1InterpassTemperature" size="sm">{t('InterpassTemperature')} (ºC)</Label> <Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_MaxInterpassTemperature'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolMaxInterpassTemperature_d')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolMaxInterpassTemperature' />
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1InterpassTemperature" id="weldingTestingDetails1InterpassTemperature"
                                                        value={this.props.weldingTestingDetails1.interpassTemperature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1InterpassTemperature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1PostWeldHeatTreatmentAndOrAgeing" size="sm">{t('PostWeldHeatTreatmentAndOrAgeing')}, {t('HoldingTemperature')} (°C), {t('holdTime')} (min)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_PostWeldHeatTreatment_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolPostWeldHeatTreatment')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolPostWeldHeatTreatment' />
                                                    <Input type="textarea" bsSize="sm" name="weldingTestingDetails1PostWeldHeatTreatmentAndOrAgeing" id="weldingTestingDetails1PostWeldHeatTreatmentAndOrAgeing"
                                                        value={this.props.weldingTestingDetails1.postWeldHeatTreatmentAndOrAgeing} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1PostWeldHeatTreatmentAndOrAgeing(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1HeatingAndCoolingRates" size="sm">{t('HeatingAndCoolingRates')} (ºC/tim)</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_HeatingAndCoolingRate_d'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolHeatingAndCoolingRate')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolHeatingAndCoolingRate' />
                                                    <Input type="textarea" bsSize="sm" name="weldingTestingDetails1HeatingAndCoolingRates" id="weldingTestingDetails1HeatingAndCoolingRates"
                                                        value={this.props.weldingTestingDetails1.heatingAndCoolingRates} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1HeatingAndCoolingRates(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1OtherInformation" size="sm">{t('OtherRemarks')}</Label><Message hiddenMessage={this.props.hiddenMessageExistingWpqr} modalId={'modalId_25'} selectMessage={this.initMessageActive(this.props.weldingProtocol.weldingProtocolMessages, 'weldingProtocolOtherInformation')} initMessages={this.props.initMessagesInWeldingProtocolResult} applicationIdStart={this.props.selectedApplication.id} messageCodeStart='weldingProtocolOtherInformation' />
                                                    <Input type="textarea" rows={this.props.weldingTestingDetails1.otherInformation.split("\n").length} bsSize="sm" name="weldingTestingDetails1OtherInformation" id="weldingTestingDetails1OtherInformation"
                                                        value={this.props.weldingTestingDetails1.otherInformation} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1OtherInformation(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1Manufacturer2" size="sm">{t('Manufacturer')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1Manufacturer2" id="weldingTestingDetails1Manufacturer2"
                                                        value={this.props.weldingTestingDetails1.manufacturer2} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1Manufacturer2(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1Manufacturer2Signature" size="sm">{t('ManufacturersSignature')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1Manufacturer2Signature" id="weldingTestingDetails1Manufacturer2Signature"
                                                        value={this.props.weldingTestingDetails1.manufacturer2Signature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1Manufacturer2Signature(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1Manufacturer2Name" size="sm">{t('Name')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1Manufacturer2Name" id="weldingTestingDetails1Manufacturer2Name"
                                                        value={this.props.weldingTestingDetails1.manufacturer2Name} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1Manufacturer2Name(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1Manufacturer2Date" size="sm">{t('Date')}</Label>
                                                    <Input type="date" max="9999-12-30" bsSize="sm" name="weldingTestingDetails1Manufacturer2Date" id="weldingTestingDetails1Manufacturer2Date"
                                                        value={this.props.weldingTestingDetails1.manufacturer2Date} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1Manufacturer2Date(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1Examiner" size="sm">{t('ExaminerOrExaminingGroup')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1Examiner" id="weldingTestingDetails1Examiner"
                                                        value={this.props.weldingTestingDetails1.examiner} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1Examiner(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ExaminerSignature" size="sm">{t('ExaminerSignature')}</Label>
                                                    <Input autocomplete="off" type="search" bsSize="sm" name="weldingTestingDetails1ExaminerSignature" id="weldingTestingDetails1ExaminerSignature"
                                                        value={this.props.weldingTestingDetails1.examinerSignature} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ExaminerSignature(event)}
                                                        placeholder="" />
                                                    {this.state.usersSearchResult.length !== 0 &&
                                                        <ListGroup className="scroll-vertical">
                                                            {this.state.usersSearchResult.map((user, index) => (
                                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectUser(user)}>
                                                                    {user}
                                                                </ListGroupItem>
                                                            ))}
                                                        </ListGroup>
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ExaminerName" size="sm">{t('Name')}</Label>
                                                    <Input type="text" bsSize="sm" name="weldingTestingDetails1ExaminerName" id="weldingTestingDetails1ExaminerName"
                                                        value={this.props.weldingTestingDetails1.examinerName} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ExaminerName(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="weldingTestingDetails1ExaminerDate" size="sm">{t('Date')}</Label>
                                                    <Input type="date" max="9999-12-30" bsSize="sm" name="weldingTestingDetails1ExaminerDate" id="weldingTestingDetails1ExaminerDate"
                                                        value={this.props.weldingTestingDetails1.examinerDate} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingTestingDetails1ExaminerDate(event)}
                                                        placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.wpqrDekraTab;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    WPQRDEKRATabStore.actionCreators // Selects which action creators are merged into the component's props
)(TestingDetails1Component as any);
