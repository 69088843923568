﻿
import * as React from 'react';
import { Connect, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, Col, Collapse, Row, Pagination, PaginationItem, FormGroup, CardTitle, InputGroup, InputGroupAddon, Input, InputGroupText, Table } from 'reactstrap';
import { ApplicationState } from '../store/index';
import * as UserListStore from '../store/UserListStore';
import { UserListObject } from '../store/UserListObject';
import { t } from '../i18n';
import EditUserListModal from './EditUserListModal';
import authHeader from '../store/models/auth-header';
import EventBus from '../store/eventBus';
import DeletionConfirmationModal from '../components/DeletionConfirmationModal';
import Loader from './Loader';

// At runtime, Redux will merge together...
type UserListProps =
    UserListStore.UserListState // ... state we've requested from the Redux store
    & typeof UserListStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>

const SweToEngRoles = new Map<string, string>();

class UserList extends React.PureComponent<UserListProps> {
    constructor(props: UserListProps) {
        super(props);
    }

    public state = {
        wpqrCompleteIsOpen: true,
        updateTestResultsExamination: false,
        updateRangeOfqualification: false,
        updateWPSFormEdit: 0,
        revisionNumber: '',
        customers: [],
        showDeletionModal: false,
        createNewUser: false
    };

    public componentDidMount() {
        this.props.receiveUserListUnsorted();

        fetch(`/api/Customer/GetAll/`, {
            method: 'GET',
            headers: authHeader.authHeader(),

        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                this.setState({ customers: res.data });
            }))
            .catch(error => {
                console.log('error');
                console.log(error);
            });

        SweToEngRoles.set('Admin', t('Admin'));
        SweToEngRoles.set('DEKRA', t('DEKRA'));
        SweToEngRoles.set('Customer', t('Customer'));
    }

    componentDidUpdate(prevProps: any) {

    }

    private onChangeFreeSearch(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        this.props.setSearchTerm(event.currentTarget.value);
        this.props.GetUserSearchResult();
    }

    private TranslateBoxes = (word: string, dictionary: Map<string, string>) => {
        if (dictionary.has(word)) {
            return dictionary.get(word);
        } else {
            return word;
        }
    }

    //Pagination
    private handlePrevPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.paginationPageIndex - 1);
    }

    private handleNextPageClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setPaginationPageIndex(this.props.paginationPageIndex + 1);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public setDeletionModalOpen(open: boolean) {
        this.setState({ showDeletionModal: open });
    }

    private addButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, userId: string) {
        event.stopPropagation();
        this.setState({ createNewUser: true });
        this.props.setChosenUser(this.props.users.find(user => user.id === userId));
        this.props.toggleEditModal();
    }

    private onRemoveUserPress(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, userId: string) {
        event.stopPropagation();
        this.props.setChosenUser(this.props.users.find(user => user.id === userId));
        this.setDeletionModalOpen(true);
    }

    private removeUser() {
        this.props.removeUser(this.props.chosenUser.id, () => { this.props.receiveUserListUnsorted() });
    }

    private onEditUserPress(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, userId: string) {
        event.stopPropagation();
        this.setState({ createNewUser: false, });
        this.props.setChosenUser(this.props.users.find(user => user.id === userId));
        this.props.toggleEditModal();
    }

    private renderPagination() {
        return (
            <React.Fragment key="renderPagination">
                <div className="pagination-wrapper">

                    <Pagination aria-label="Page navigation example">
                        <PaginationItem onClick={event => this.handlePrevPageClick(event)}>
                            <a className={this.props.paginationPageIndex === 0 ? "btn disabled" : "btn"} aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </PaginationItem>

                        {/*[...Array(Math.ceil(this.props.wpqrsSearchResult.length / this.props.paginationPageSize))].map((page, i) =>
                            <PaginationItem key={i} active={this.props.paginationPageIndex === i} onClick={(event) => this.handlePageClick(event, i)}>
                                <a className="btn">{i + 1}</a> 
                            </PaginationItem>
                        )*/}

                        <PaginationItem onClick={event => this.handleNextPageClick(event)}>
                            {/*<a className={this.props.paginationPageIndex === (Math.ceil(this.props.wpqrsSearchResult.length / this.props.paginationPageSize) - 1) ? "btn disabled" : "btn"}  aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>*/}
                        </PaginationItem>
                    </Pagination>
                </div>
            </React.Fragment>
        );
    }

    private getDekraContact(user: UserListObject) {
        let contactString = "";
        if (user.dekraContact == null) {
            return contactString;
        } else {
            if (user.dekraContact.firstName != null) {
                contactString += user.dekraContact.firstName + " ";
            }

            if (user.dekraContact.lastName != null) {
                contactString += user.dekraContact.lastName + " ";
            }

            if (user.dekraContact.userName != null) {
                contactString += "(" + user.dekraContact.userName + ")";
            }

            return contactString;
        }
    }

    private renderTableHeaderRow() {
        return (
            <React.Fragment key={"renderTableHeaderRow"}>
                <tr>
                    <th className="thinCol"><a></a></th>
                    <th className="thinCol"><a></a></th>
                    <th>
                        <a>{t('Permissions')}</a>
                    </th>
                    <th>
                        <a>{t('AssignedCompany')}</a>
                    </th>
                    <th>
                        <a>{t('Username')} </a>
                    </th>
                    <th>
                        <a>{t('FirstName')}</a>
                    </th>
                    <th>
                        <a>{t('LastName')}</a>
                    </th>
                    <th>
                        <a>{t('Title')}</a>
                    </th>
                    <th>
                        <a>{t('Email')}</a>
                    </th>
                    <th>
                        <a>{t('Phone')}</a>
                    </th>
                    <th>
                        <a>{t('MobilePhone')}</a>
                    </th>
                    <th>
                        <a>{t('CustomerContactAtDekra')}</a>
                    </th>
                    <th className="thinCol"><a></a></th>
                </tr>
            </React.Fragment>
        );
    }

    private renderTableRow(user: UserListObject, index: number) {
        return (
            <React.Fragment key={"renderTableRow" + index}>
                <tr key={user.id}>
                    <td>
                        <Button color="link" size="sm" name={"userEditBtn-" + index} id={"userEditBtn-" + index}
                            onClick={(event) => this.onEditUserPress(event, user.id)}>
                            <i className="fas fa-pen"></i>
                        </Button>
                    </td>
                    <td>
                        <Button color="link" size="sm" name={"userEditBtn-" + index} id={"userEditBtn-" + index}
                            onClick={(event) => this.addButtonClick(event, user.id)}>
                            <i className="fas fa-user-plus"></i>
                        </Button>
                    </td>
                    <td>
                        {user.roles.find(role => role == 'Admin') ? <span style={{ fontWeight: 'bold' }}>{this.TranslateBoxes(user.roles[0], SweToEngRoles)}</span> : this.TranslateBoxes(user.roles[0], SweToEngRoles)}
                    </td>
                    <td>
                        {user.customer ? user.roles.find(role => role == 'Admin') ? <span style={{ fontWeight: 'bold' }}>{user.customer.name}</span> : user.customer.name : null}
                    </td>
                    <td>
                        {user.roles.find(role => role == 'Admin') ? <span style={{ fontWeight: 'bold' }}>{user.userName}</span> : user.userName}
                    </td>
                    <td>
                        {user.firstName}
                    </td>
                    <td>
                        {user.lastName}
                    </td>
                    <td>
                        {user.title}
                    </td>
                    <td>
                        {user.email}
                    </td>
                    <td>
                        {user.phoneNumber}
                    </td>
                    <td>
                        {user.mobilePhoneNumber}
                    </td>
                    <td>{this.getDekraContact(user)}
                    </td>
                    {/*<td>
                        {customer.dekraContact ?
                            (customer.dekraContact.firstName == null ? '' : customer.dekraContact.firstName + " ") +
                            (customer.dekraContact.lastName == null ? '' : customer.dekraContact.lastName + " ") +
                            " (" + customer.dekraContact.userName + ")"
                            : null}
                    </td>*/}
                    <td>
                        <Button color="link" size="sm" name={"userRmBtn-" + index} id={"userRmBtn-" + index}
                            onClick={(event) => this.onRemoveUserPress(event, user.id)}>
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    public render() {
        return (
            <React.Fragment>
                <div className="dekra-content-wrapper dekra-wrapper" >
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <Collapse isOpen={this.state.wpqrCompleteIsOpen}>
                                        <Row form>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Card className="card-border-dekra">
                                                        <CardBody>
                                                            <CardTitle>
                                                            </CardTitle>
                                                            <Row form>
                                                                <Col sm={12}>
                                                                    <FormGroup>
                                                                        <InputGroup>
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input type="text" bsSize="sm" name="freeSearch" style={{ height: "33px" }}
                                                                                value={this.props.searchTerm} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeFreeSearch(event)}
                                                                                placeholder={t('Search')} />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Card>
                                                        <CardTitle>
                                                            <Row form>
                                                                <Col sm={4}>
                                                                </Col>
                                                                <Col sm={4}>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <FormGroup>
                                                                        {/*<Button className={"dekra2-table-settings-btn"} color="link" size="sm" name={"tabSettingButton"} onClick={(event) => this.onClickTabSettingModalOpen(event)}>*/}
                                                                        {/*    <i className={"fas fa-cog"}></i>*/}
                                                                        {/*</Button>*/}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardTitle>
                                                        <Row form>
                                                            <Col sm={12}>
                                                                <FormGroup>
                                                                    <Table size="sm" className="cert-table table-striped" responsive={true}>
                                                                        <thead>
                                                                            {this.renderTableHeaderRow()}
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.props.searchTerm.length > 0 ?
                                                                                    this.props.searchResult.filter(user => user.roles.find(role => role.includes('Customer'))).map((user, index) => (
                                                                                        this.renderTableRow(user, index)
                                                                                    ))
                                                                                    :
                                                                                    this.props.users.filter(user => user.roles.find(role => role.includes('Customer'))).map((user, index) => (
                                                                                        this.renderTableRow(user, index)
                                                                                    ))
                                                                            }
                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr>
                                                                                <td colSpan={14}>
                                                                                    {/*this.renderPagination()*/}
                                                                                </td>
                                                                            </tr>
                                                                        </tfoot>
                                                                    </Table>
                                                                    <EditUserListModal
                                                                        show={this.props.editModalOpen}
                                                                        toggleShow={this.props.toggleEditModal}
                                                                        chosenUser={this.props.chosenUser}
                                                                        setChosenUser={this.props.setChosenUser}
                                                                        customers={this.state.customers}
                                                                        creatingNewUser={this.state.createNewUser}
                                                                    />
                                                                    <DeletionConfirmationModal
                                                                        onConfirmDeletion={() => this.removeUser()}
                                                                        show={this.state.showDeletionModal}
                                                                        setOpen={(open: boolean) => this.setDeletionModalOpen(open)}
                                                                        useUserNotCust={true}
                                                                    />
                                                                    <Loader show={this.props.currentlyLoading} title={t('FetchingUsers')} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Collapse>
                                    <Row>
                                        <Col sm={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.userList, // Selects which state properties are merged into the component's props
    UserListStore.actionCreators // Selects which action creators are merged into the component's props
)(UserList as any);