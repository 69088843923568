﻿
import { GetCurrentTypeResponse } from './models/responses/GetCurrentTypeResponse';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { GetIndexResponse } from './models/responses/GetIndexResponse';
import { WeldingParameters } from './models/WeldingParameters';
import { WeldingParametersSettings } from './models/WeldingParametersSettings';
import { WeldingParametersProperties } from './models/WeldingParametersProperties';
import { GetWeldingMethodResponse } from './models/responses/GetWeldingMethodResponse';
import HelpFunctions from './models/HelpFunctions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WParametersState {
    weldingParameters: Array<WeldingParameters>;
    weldingParametersView: Array<WeldingParameters>;
    weldingParametersSettings: WeldingParametersSettings;
    weldingParametersSettingsSave: WeldingParametersSettings;
    showWeldingParametersSettingsModal: boolean;
    weldingParametersProperties: WeldingParametersProperties;
    weldingParametersPropertiesView: WeldingParametersProperties;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetWeldingParametersAction {
    type: 'SET_WELDING_PARAMETERS';
    weldingParameters: Array<WeldingParameters>;
}

export interface SetWeldingParametersSettingsAction {
    type: 'SET_WELDING_PARAMETERS_SETTINGS';
    weldingParametersSettings: WeldingParametersSettings;
}

export interface SetWeldingParametersSettingsSaveAction {
    type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE';
    weldingParametersSettingsSave: WeldingParametersSettings;
}

export interface OpenWeldingParametersSettingsModalAction {
    type: 'OPEN_WELDING_PARAMETERS_SETTINGS_MODAL';
    showWeldingParametersSettingsModal: boolean;
    weldingParametersSettingsSave: WeldingParametersSettings;
}

export interface CloseWeldingParametersSettingsModalAction {
    type: 'CLOSE_WELDING_PARAMETERS_SETTINGS_MODAL';
    showWeldingParametersSettingsModal: boolean;
    weldingParametersSettings: WeldingParametersSettings;
}

export interface SetWeldingParametersPropertiesAction {
    type: 'SET_WELDING_PARAMETERS_PROPERTIES';
    weldingParametersProperties: WeldingParametersProperties;
}

export interface SetWeldingParametersViewAction {
    type: 'SET_WELDING_PARAMETERS_VIEW';
    weldingParametersView: Array<WeldingParameters>;
}

export interface SetWeldingParametersPropertiesViewAction {
    type: 'SET_WELDING_PARAMETERS_PROPERTIES_VIEW';
    weldingParametersPropertiesView: WeldingParametersProperties;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

export type KnownAction = SetWeldingParametersAction
    | SetWeldingParametersSettingsAction
    | SetWeldingParametersSettingsSaveAction
    | OpenWeldingParametersSettingsModalAction
    | CloseWeldingParametersSettingsModalAction
    | SetWeldingParametersPropertiesAction
    | SetWeldingParametersPropertiesViewAction
    | SetWeldingParametersViewAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    initWParameters: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters = [];
            let weldingParametersRow = new WeldingParameters();
            weldingParametersRow.run = '1';
            weldingParametersRow.runText = '1';
            weldingParameters.splice(weldingParameters.length, 0, weldingParametersRow);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
            // Clear weldingParametersSettings
            let weldingParametersSettings: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettings };
            weldingParametersSettings.currentY = "";
            weldingParametersSettings.voltageX = "";
            weldingParametersSettings.travelSpeedZ = "";
            weldingParametersSettings.currentYView = "";
            weldingParametersSettings.voltageXView = "";
            weldingParametersSettings.travelSpeedZView = "";
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS', weldingParametersSettings: weldingParametersSettings });
        }
    },
    clearWeldingMethods: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersP: WeldingParametersProperties = { ...appState.wParameters.weldingParametersProperties };
            weldingParametersP.weldingMethods = [];
            dispatch({ type: 'SET_WELDING_PARAMETERS_PROPERTIES', weldingParametersProperties: weldingParametersP });
        }
    },
    clearWeldingMethodsView: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersView: WeldingParametersProperties = { ...appState.wParameters.weldingParametersPropertiesView };
            weldingParametersView.weldingMethods = [];
            dispatch({ type: 'SET_WELDING_PARAMETERS_PROPERTIES_VIEW', weldingParametersPropertiesView: weldingParametersView });
        }
    },
    setWeldingParameters: (selectedWeldingParameters: Array<WeldingParameters>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            // Initialize WeldingParameters.
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters = selectedWeldingParameters
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
            // Percentage
            if (selectedWeldingParameters.length > 0) {
                let weldingParametersSettings: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettings };
                weldingParametersSettings.currentY = selectedWeldingParameters[0].currentY;
                weldingParametersSettings.voltageX = selectedWeldingParameters[0].voltageX;
                weldingParametersSettings.travelSpeedZ = selectedWeldingParameters[0].travelSpeedZ;
                dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS', weldingParametersSettings: weldingParametersSettings });
            }
        }
    },
    setWeldingParametersView: (selectedWeldingParameters: Array<WeldingParameters>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersView: Array<WeldingParameters> = [...appState.wParameters.weldingParametersView];
            weldingParametersView = selectedWeldingParameters
            dispatch({ type: 'SET_WELDING_PARAMETERS_VIEW', weldingParametersView: weldingParametersView });
            // Percentage
            if (selectedWeldingParameters.length > 0) {
                let weldingParametersSettings: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettings };
                weldingParametersSettings.currentYView = selectedWeldingParameters[0].currentY;
                weldingParametersSettings.voltageXView = selectedWeldingParameters[0].voltageX;
                weldingParametersSettings.travelSpeedZView = selectedWeldingParameters[0].travelSpeedZ;
                dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS', weldingParametersSettings: weldingParametersSettings });
            }
        }
    },
    setWeldingParametersProperties: (weldingMethods:Array<GetWeldingMethodResponse>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersP: WeldingParametersProperties = { ...appState.wParameters.weldingParametersProperties };
            weldingParametersP.weldingMethods = weldingMethods;
            dispatch({ type: 'SET_WELDING_PARAMETERS_PROPERTIES', weldingParametersProperties: weldingParametersP });
        }
    },
    setWeldingParametersPropertiesView: (weldingMethods: Array<GetWeldingMethodResponse>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersView: WeldingParametersProperties = { ...appState.wParameters.weldingParametersPropertiesView };
            weldingParametersView.weldingMethods = weldingMethods;
            dispatch({ type: 'SET_WELDING_PARAMETERS_PROPERTIES_VIEW', weldingParametersPropertiesView: weldingParametersView });
        }
    },
    setWeldingParametersCurrent: (rowNumber: number, current: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters && appState.applicationTab) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].current = current;
            weldingParameters[rowNumber - 1] = { ...HelpFunctions.SetHeatInputMaxAndMin(weldingParameters[rowNumber - 1]) };
            weldingParameters = HelpFunctions.pulseTensileEnergyAverage(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersPulseCurrent: (rowNumber: number, pulseCurrent: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].pulseCurrent = pulseCurrent;
            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersPulseTime: (rowNumber: number, pulseTime: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].pulseTime = pulseTime;
            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersBasicCurrent: (rowNumber: number, basicCurrent: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].basicCurrent = basicCurrent;
            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersTimeForBasicCurrent: (rowNumber: number, timeForBasicCurrent: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].timeForBasicCurrent = timeForBasicCurrent;
            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersFrequency: (rowNumber: number, frequency: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].frequency = frequency;
            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersTime1: (rowNumber: number, time1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].time1 = time1;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersCurrent1: (rowNumber: number, current1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].current1 = current1;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersHeatInput1: (rowNumber: number, heatInput1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].heatInput1 = heatInput1;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersCurrentTypeId: (rowNumber: number, currentTypeId: number, currentTypesActives: Array<GetCurrentTypeResponse>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            let currentTypes: Array<GetCurrentTypeResponse> = currentTypesActives;
            for (let i = 0; i < currentTypes.length; i++) {
                if (currentTypeId === currentTypes[i].id) {
                    weldingParameters[rowNumber - 1].currentType = { ...currentTypes[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersDiameter: (rowNumber: number, diameter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].diameter = diameter;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersHeatInput: (rowNumber: number, heatInput: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].heatInput = heatInput;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersInterpassTemperature: (rowNumber: number, interpassTemperature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        //TODO setWeldingProtocolWeldingParametersInterpassTemperature Send value to parent
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].interpassTemperature = interpassTemperature;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersFlowRateRoot: (rowNumber: number, flowRateRoot: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].flowRateRoot = flowRateRoot;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersFlowRateTop: (rowNumber: number, flowRateTop: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].flowRateTop = flowRateTop;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersRunText: (rowNumber: number, runText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].runText = runText;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersSettingsSaveShowCurrent: (showCurrent: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showCurrent = showCurrent;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowDiamter: (showDiamter: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showDiameter = showDiamter;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowFlowRateRoot: (showFlowRateRoot: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showFlowRateRoot = showFlowRateRoot;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowFlowRateTop: (showFlowRateTop: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showFlowRateTop = showFlowRateTop;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowHeatInput: (showHeatInput: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showHeatInput = showHeatInput;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowIndex: (showIndex: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showIndex = showIndex;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowRun: (showRun: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showRun = showRun;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowTravelSpeed: (showTravelSpeed: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showTravelSpeed = showTravelSpeed;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowTypeOfCurrent: (showTypeOfCurrent: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showCurrentType = showTypeOfCurrent;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowVoltage: (showVoltage: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showVoltage = showVoltage;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersSettingsSaveShowWireFeed: (showWireFeed: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettingsSave: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettingsSave };
            weldingParametersSettingsSave.showWireFeed = showWireFeed;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS_SAVE', weldingParametersSettingsSave: weldingParametersSettingsSave });
        }
    },
    setWeldingParametersTravelSpeed: (rowNumber: number, travelSpeed: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters && appState.applicationTab) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].travelSpeed = travelSpeed;
            weldingParameters[rowNumber - 1] = { ...HelpFunctions.SetHeatInputMaxAndMin(weldingParameters[rowNumber - 1]) };
            weldingParameters = HelpFunctions.pulseTensileEnergyAverage(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersTravelSpeedPulse: (rowNumber: number, travelSpeed: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters && appState.applicationTab) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].travelSpeed = travelSpeed;
            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setArcWeldingParametersTravelSpeed: (rowNumber: number, travelSpeed: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters && appState.applicationTab) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].travelSpeed = travelSpeed;
            weldingParameters = HelpFunctions.setArcParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersVoltage: (rowNumber: number, voltage: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters && appState.applicationTab) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].voltage = voltage;
            weldingParameters[rowNumber - 1] = { ...HelpFunctions.SetHeatInputMaxAndMin(weldingParameters[rowNumber - 1]) };
            weldingParameters = HelpFunctions.pulseTensileEnergyAverage(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersVoltagePulse: (rowNumber: number, voltage: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters && appState.applicationTab) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].voltage = voltage;
            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersWireFeed: (rowNumber: number, wireFeed: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].wireFeed = wireFeed;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setArcWeldingParametersArcIE: (rowNumber: number, arcIE: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].arcIE = arcIE;
            weldingParameters = HelpFunctions.setArcParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setArcWeldingParametersArcIP: (rowNumber: number, arcIP: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].arcIP = arcIP;
            weldingParameters = HelpFunctions.setArcParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setArcWeldingParametersAdditiveMaterial: (rowNumber: number, additiveMaterial: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].additiveMaterial = additiveMaterial;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    removeWeldingParametersRow: (rowNumber: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters.splice(rowNumber - 1, 1);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    cloneWeldingParametersRow: (rowNumber: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            let nextRun: number = weldingParameters.length === 0 ? 1 : parseInt(weldingParameters[weldingParameters.length - 1].run) + 1;
            let nextRunText: string = !isNaN(parseInt(weldingParameters[rowNumber - 1].runText)) ? (parseInt(weldingParameters[weldingParameters.length - 1].runText) + 1).toString() : weldingParameters[rowNumber - 1].runText;
            let weldingParametersRow: WeldingParameters = {
                id: 0,
                run: nextRun.toString(),
                runText: nextRunText,
                index: { ...weldingParameters[rowNumber - 1].index },
                diameter: weldingParameters[rowNumber - 1].diameter,
                voltage: weldingParameters[rowNumber - 1].voltage,
                voltageMin: weldingParameters[rowNumber - 1].voltageMin,
                voltageMax: weldingParameters[rowNumber - 1].voltageMax,
                current: weldingParameters[rowNumber - 1].current,
                currentMin: weldingParameters[rowNumber - 1].currentMin,
                currentMax: weldingParameters[rowNumber - 1].currentMax,
                wireFeed: weldingParameters[rowNumber - 1].wireFeed,
                flowRateTop: weldingParameters[rowNumber - 1].flowRateTop,
                flowRateRoot: weldingParameters[rowNumber - 1].flowRateRoot,
                currentType: { ...weldingParameters[rowNumber - 1].currentType },
                travelSpeed: weldingParameters[rowNumber - 1].travelSpeed,
                travelSpeedMin: weldingParameters[rowNumber - 1].travelSpeedMin,
                travelSpeedMax: weldingParameters[rowNumber - 1].travelSpeedMax,
                heatInput: weldingParameters[rowNumber - 1].heatInput,
                heatInputMin: weldingParameters[rowNumber - 1].heatInputMin,
                heatInputMax: weldingParameters[rowNumber - 1].heatInputMax,
                interpassTemperature: weldingParameters[rowNumber - 1].interpassTemperature,
                runLength: weldingParameters[rowNumber - 1].runLength,
                runWidth: weldingParameters[rowNumber - 1].runWidth,
                time: weldingParameters[rowNumber - 1].time,
                weldingMethod: weldingParameters[rowNumber - 1].weldingMethod,
                createdAt: '',
                deletedAt: '',
                updatedAt: '',
                pulseCurrent: weldingParameters[rowNumber - 1].pulseCurrent,
                pulseTime: weldingParameters[rowNumber - 1].pulseTime,
                basicCurrent: weldingParameters[rowNumber - 1].basicCurrent,
                timeForBasicCurrent: weldingParameters[rowNumber - 1].timeForBasicCurrent,
                frequency: weldingParameters[rowNumber - 1].frequency,
                current1: weldingParameters[rowNumber - 1].current1,
                time1: weldingParameters[rowNumber - 1].time1,
                heatInput1: weldingParameters[rowNumber - 1].heatInput1,
                arcEnergyIE: weldingParameters[rowNumber - 1].arcEnergyIE,
                arcEnergyIP: weldingParameters[rowNumber - 1].arcEnergyIP,
                arcIP: weldingParameters[rowNumber - 1].arcIP,
                arcIE: weldingParameters[rowNumber - 1].arcIE,
                currentY: weldingParameters[rowNumber - 1].currentY,
                voltageX: weldingParameters[rowNumber - 1].voltageX,
                travelSpeedZ: weldingParameters[rowNumber - 1].currentY,
                additiveMaterial: weldingParameters[rowNumber - 1].travelSpeedZ,
                weldingTecknique: weldingParameters[rowNumber - 1].weldingTecknique,
                fuelGasPressure: weldingParameters[rowNumber - 1].fuelGasPressure,
                oxygenPressure: weldingParameters[rowNumber - 1].oxygenPressure,
                typeOfFlame: weldingParameters[rowNumber - 1].typeOfFlame,
            };
            weldingParameters.splice(weldingParameters.length, 0, weldingParametersRow);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    addWeldingParametersRow: (diameter: string, voltage: string, current: string, wireFeed: string, flowRateTop: string, flowRateRoot: string, travelSpeed: string, heatInput: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            let nextRun: number = weldingParameters.length === 0 ? 1 : parseInt(weldingParameters[weldingParameters.length - 1].run) + 1;
            let nextRunText: string = "1";
            let currentTempY: string = "";
            let voltageTempX: string = "";
            let travelSpeedTempZ: string = "";
            if (weldingParameters.length > 0) {
                nextRunText = !isNaN(parseInt(weldingParameters[weldingParameters.length - 1].runText)) ? (parseInt(weldingParameters[weldingParameters.length - 1].runText) + 1).toString() : '';
                currentTempY = weldingParameters[weldingParameters.length - 1].currentY;
                voltageTempX = weldingParameters[weldingParameters.length - 1].voltageX;
                travelSpeedTempZ = weldingParameters[weldingParameters.length - 1].travelSpeedZ;
            }
            let weldingParametersRow: WeldingParameters = {
                id: 0,
                run: nextRun.toString(),
                runText: nextRunText,
                index: new GetIndexResponse(),
                diameter: diameter,
                voltage: voltage,
                voltageMin: '',
                voltageMax: '',
                current: current,
                currentMin: '',
                currentMax: '',
                wireFeed: wireFeed,
                flowRateTop: flowRateTop,
                flowRateRoot: flowRateRoot,
                currentType: new GetCurrentTypeResponse(),
                travelSpeed: travelSpeed,
                travelSpeedMin: '',
                travelSpeedMax: '',
                heatInput: heatInput,
                heatInputMin: '',
                heatInputMax: '',
                interpassTemperature: '',
                runLength: '',
                runWidth: '',
                time: '',
                weldingMethod: {
                    id: 0,
                    name: '',
                    efficiencyFactor: 0.0,
                    description: '',
                    createdAt: '',
                    deletedAt: '',
                    updatedAt: ''
                },
                createdAt: '',
                deletedAt: '',
                updatedAt: '',
                pulseCurrent: '',
                pulseTime: '',
                basicCurrent: '',
                timeForBasicCurrent: '',
                frequency: '',
                current1: '',
                time1: '',
                heatInput1: '',
                arcEnergyIE: '',
                arcEnergyIP: '',
                arcIP: '',
                arcIE: '',
                additiveMaterial: '',
                currentY: currentTempY,
                voltageX: voltageTempX,
                travelSpeedZ: travelSpeedTempZ,
                weldingTecknique: '',
                fuelGasPressure: '',
                oxygenPressure: '',
                typeOfFlame: '',
            };
            weldingParameters.splice(weldingParameters.length, 0, weldingParametersRow);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    openWeldingParametersSettingsModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettings: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettings };
            dispatch({ type: 'OPEN_WELDING_PARAMETERS_SETTINGS_MODAL', showWeldingParametersSettingsModal: true, weldingParametersSettingsSave: weldingParametersSettings });
        }
    },
    closeWeldingParametersSettingsModal: (saveWeldingParameterSettings: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettings: WeldingParametersSettings = saveWeldingParameterSettings ?
                { ...appState.wParameters.weldingParametersSettingsSave } :
                { ...appState.wParameters.weldingParametersSettings };
            dispatch({ type: 'CLOSE_WELDING_PARAMETERS_SETTINGS_MODAL', showWeldingParametersSettingsModal: false, weldingParametersSettings: weldingParametersSettings });
        }
    },
    setWeldingParametersWeldingMethodId: (rowNumber: number, weldingMethodId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            let weldingParametersP: WeldingParametersProperties = { ...appState.wParameters.weldingParametersProperties };
            for (let i = 0; i < weldingParametersP.weldingMethods.length; i++) {
                if (weldingMethodId === weldingParametersP.weldingMethods[i].id) {
                    weldingParameters[rowNumber - 1].weldingMethod = { ...weldingParametersP.weldingMethods[i] };
                    break;
                }
            }
            weldingParameters[rowNumber - 1] = { ...HelpFunctions.SetHeatInputMaxAndMin(weldingParameters[rowNumber - 1]) };
            weldingParameters = HelpFunctions.pulseTensileEnergyAverage(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersWeldingVoltageX: (voltageX: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettings: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettings };
            weldingParametersSettings.voltageX = voltageX;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS', weldingParametersSettings: weldingParametersSettings });

            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            for (let i = 0; i < weldingParameters.length; i++) {
                weldingParameters[i].voltageX = voltageX.toString();
                weldingParameters[i] = { ...HelpFunctions.SetHeatInputMaxAndMin(weldingParameters[i]) };
            }
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersTravelSpeedZ: (travelSpeedZ: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettings: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettings };
            weldingParametersSettings.travelSpeedZ = travelSpeedZ;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS', weldingParametersSettings: weldingParametersSettings });

            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            for (let i = 0; i < weldingParameters.length; i++) {
                weldingParameters[i].travelSpeedZ = travelSpeedZ.toString();
                weldingParameters[i] = { ...HelpFunctions.SetHeatInputMaxAndMin(weldingParameters[i]) };
            }
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersCurrentY: (currentY: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParametersSettings: WeldingParametersSettings = { ...appState.wParameters.weldingParametersSettings };
            weldingParametersSettings.currentY = currentY;
            dispatch({ type: 'SET_WELDING_PARAMETERS_SETTINGS', weldingParametersSettings: weldingParametersSettings });

            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            for (let i = 0; i < weldingParameters.length; i++) {
                weldingParameters[i].currentY = currentY.toString();
                weldingParameters[i] = { ...HelpFunctions.SetHeatInputMaxAndMin(weldingParameters[i]) };
            }
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setArcWeldingParametersWeldingMethodId: (rowNumber: number, weldingMethodId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            let weldingParametersP: WeldingParametersProperties = { ...appState.wParameters.weldingParametersProperties };
            for (let i = 0; i < weldingParametersP.weldingMethods.length; i++) {
                if (weldingMethodId === weldingParametersP.weldingMethods[i].id) {
                    weldingParameters[rowNumber - 1].weldingMethod = { ...weldingParametersP.weldingMethods[i] };
                    break;
                }
            }
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingProtocolWeldingParametersWeldingMethodId: (rowNumber: number, weldingMethodId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            let weldingParametersP: WeldingParametersProperties = { ...appState.wParameters.weldingParametersProperties };
            for (let i = 0; i < weldingParametersP.weldingMethods.length; i++) {
                if (weldingMethodId === weldingParametersP.weldingMethods[i].id) {
                    weldingParameters[rowNumber - 1].weldingMethod = { ...weldingParametersP.weldingMethods[i] };
                    break;
                }
            }
            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersRunWidth: (rowNumber: number, runWidth: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].runWidth = runWidth;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingProtocolWeldingParametersRunLength: (rowNumber: number, runLength: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].runLength = runLength;
            let runLength1: number = parseFloat(weldingParameters[rowNumber - 1].runLength.replace(',', '.'));
            let time1: number = parseFloat(weldingParameters[rowNumber - 1].time.replace(',', '.'));
            let travelSpeed1: number = NaN;
            if (!isNaN(runLength1) && !isNaN(time1)) {
                travelSpeed1 = (runLength1 * 60.0) / time1;
                weldingParameters[rowNumber - 1].travelSpeed = travelSpeed1.toFixed(2).toString().replace('.', ',');
            }
            else {
                weldingParameters[rowNumber - 1].travelSpeed = '';
            }
            weldingParameters = HelpFunctions.baseTensileEnergyAverage(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingProtocolWeldingParametersRunLengthPulse: (rowNumber: number, runLength: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].runLength = runLength;
            let runLength1: number = parseFloat(weldingParameters[rowNumber - 1].runLength.replace(',', '.'));
            let time1: number = parseFloat(weldingParameters[rowNumber - 1].time.replace(',', '.'));
            let travelSpeed1: number = NaN;
            if (!isNaN(runLength1) && !isNaN(time1)) {
                travelSpeed1 = (runLength1 * 60.0) / time1;
                weldingParameters[rowNumber - 1].travelSpeed = travelSpeed1.toFixed(2).toString().replace('.', ',');
            }
            else {
                weldingParameters[rowNumber - 1].travelSpeed = '';
            }

            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingProtocolWeldingParametersTime: (rowNumber: number, time: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].time = time;
            let runLength1: number = parseFloat(weldingParameters[rowNumber - 1].runLength.replace(',', '.'));
            let time1: number = parseFloat(weldingParameters[rowNumber - 1].time.replace(',', '.'));
            let travelSpeed1: number = NaN;
            if (!isNaN(runLength1) && !isNaN(time1)) {
                travelSpeed1 = (runLength1 * 60.0) / time1;
                weldingParameters[rowNumber - 1].travelSpeed = travelSpeed1.toFixed(2).toString().replace('.', ',');
            }
            else {
                weldingParameters[rowNumber - 1].travelSpeed = '';
            }
            weldingParameters = HelpFunctions.baseTensileEnergyAverage(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingProtocolWeldingParametersTimePulse: (rowNumber: number, time: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].time = time;
            let runLength1: number = parseFloat(weldingParameters[rowNumber - 1].runLength.replace(',', '.'));
            let time1: number = parseFloat(weldingParameters[rowNumber - 1].time.replace(',', '.'));
            let travelSpeed1: number = NaN;
            if (!isNaN(runLength1) && !isNaN(time1)) {
                travelSpeed1 = (runLength1 * 60.0) / time1;
                weldingParameters[rowNumber - 1].travelSpeed = travelSpeed1.toFixed(2).toString().replace('.', ',');
            }
            else {
                weldingParameters[rowNumber - 1].travelSpeed = '';
            }
            weldingParameters = HelpFunctions.setPulseParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setArcWeldingParametersTime: (rowNumber: number, time: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].time = time;
            weldingParameters = HelpFunctions.arcTravelSpeed(weldingParameters, rowNumber);
            weldingParameters = HelpFunctions.setArcParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setArcWeldingParametersRunLength: (rowNumber: number,enableTravelSpeed: boolean, runLength: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].runLength = runLength;
            if (!enableTravelSpeed)
            {
                weldingParameters = HelpFunctions.arcTravelSpeed(weldingParameters, rowNumber);
            }
            weldingParameters = HelpFunctions.setArcParameters(weldingParameters, rowNumber);
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersTecknique: (rowNumber: number, weldingTecknique: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].weldingTecknique = weldingTecknique;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersFuelGasPressure: (rowNumber: number, fuelGasPressure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].fuelGasPressure = fuelGasPressure;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersOxygenPressure: (rowNumber: number, oxygenPressure: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].oxygenPressure = oxygenPressure;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
    setWeldingParametersTypeOfFlame: (rowNumber: number, typeOfFlame: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.wParameters) {
            let weldingParameters: Array<WeldingParameters> = [...appState.wParameters.weldingParameters];
            weldingParameters[rowNumber - 1].typeOfFlame = typeOfFlame;
            dispatch({ type: 'SET_WELDING_PARAMETERS', weldingParameters: weldingParameters });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WParametersState = {
    weldingParameters: [],
    weldingParametersView: [],
    weldingParametersSettings: {
        showRun: true,
        showIndex: true,
        showDiameter: true,
        showVoltage: true,
        showCurrent: true,
        showWireFeed: true,
        showFlowRateTop: true,
        showFlowRateRoot: true,
        showCurrentType: true,
        showTravelSpeed: true,
        showHeatInput: true,
        currentY: '',
        voltageX: '',
        travelSpeedZ: '',
        currentYView: '',
        voltageXView: '',
        travelSpeedZView: ''
    },
    weldingParametersSettingsSave: {
        showRun: true,
        showIndex: true,
        showDiameter: true,
        showVoltage: true,
        showCurrent: true,
        showWireFeed: true,
        showFlowRateTop: true,
        showFlowRateRoot: true,
        showCurrentType: true,
        showTravelSpeed: true,
        showHeatInput: true,
        currentY: '',
        voltageX: '',
        travelSpeedZ: '',
        currentYView: '',
        voltageXView: '',
        travelSpeedZView: ''
    },
    showWeldingParametersSettingsModal: false,
    weldingParametersProperties: {
        weldingMethods: [],
    },
    weldingParametersPropertiesView: {
        weldingMethods: [],
    }
};

export const reducer: Reducer<WParametersState> = (state: WParametersState | undefined, incomingAction: Action): WParametersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_WELDING_PARAMETERS':
            return {
                weldingParameters: action.weldingParameters,
                weldingParametersView: state.weldingParametersView,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                weldingParametersProperties: state.weldingParametersProperties,
                weldingParametersPropertiesView: state.weldingParametersPropertiesView,

            };
        case 'SET_WELDING_PARAMETERS_VIEW':
            return {
                weldingParameters: state.weldingParameters,
                weldingParametersView: action.weldingParametersView,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                weldingParametersProperties: state.weldingParametersProperties,
                weldingParametersPropertiesView: state.weldingParametersPropertiesView,
            };
        case 'SET_WELDING_PARAMETERS_SETTINGS':
            return {
                weldingParameters: state.weldingParameters,
                weldingParametersView: state.weldingParametersView,
                weldingParametersSettings: action.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                weldingParametersProperties: state.weldingParametersProperties,
                weldingParametersPropertiesView: state.weldingParametersPropertiesView,
            };
        case 'SET_WELDING_PARAMETERS_SETTINGS_SAVE':
            return {
                weldingParameters: state.weldingParameters,
                weldingParametersView: state.weldingParametersView,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: action.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                weldingParametersProperties: state.weldingParametersProperties,
                weldingParametersPropertiesView: state.weldingParametersPropertiesView,
            };
        case 'OPEN_WELDING_PARAMETERS_SETTINGS_MODAL':
            return {
                weldingParameters: state.weldingParameters,
                weldingParametersView: state.weldingParametersView,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: action.showWeldingParametersSettingsModal,
                weldingParametersProperties: state.weldingParametersProperties,
                weldingParametersPropertiesView: state.weldingParametersPropertiesView,
            };
        case 'CLOSE_WELDING_PARAMETERS_SETTINGS_MODAL':
            return {
                weldingParameters: state.weldingParameters,
                weldingParametersView: state.weldingParametersView,
                weldingParametersSettings: action.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: action.showWeldingParametersSettingsModal,
                weldingParametersProperties: state.weldingParametersProperties,
                weldingParametersPropertiesView: state.weldingParametersPropertiesView,
            };
        case 'SET_WELDING_PARAMETERS_PROPERTIES':
            return {
                weldingParameters: state.weldingParameters,
                weldingParametersView: state.weldingParametersView,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                weldingParametersProperties: action.weldingParametersProperties,
                weldingParametersPropertiesView: state.weldingParametersPropertiesView,
            };
        case 'SET_WELDING_PARAMETERS_PROPERTIES_VIEW':
            return {
                weldingParameters: state.weldingParameters,
                weldingParametersView: state.weldingParametersView,
                weldingParametersSettings: state.weldingParametersSettings,
                weldingParametersSettingsSave: state.weldingParametersSettingsSave,
                showWeldingParametersSettingsModal: state.showWeldingParametersSettingsModal,
                weldingParametersProperties: state.weldingParametersProperties,
                weldingParametersPropertiesView: action.weldingParametersPropertiesView,
            };
        default:
            return state;
    }
};
