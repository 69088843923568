﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardText, Col, FormGroup, Label, Row } from 'reactstrap';
import { t } from '../i18n';

class FormelForHeatInput extends React.PureComponent<{ weldingPulseParameters?: boolean, weldingArcParameters?: boolean}> {
    constructor(props: any) {
        super(props);
    }
    public render() {
        return (<React.Fragment key="rendeWpsFormHeas">
            <Card>
                <CardBody>
                    {this.props.weldingArcParameters == false ?
                        <Row form>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="weldingProtocolHeatInput" size="sm">{t('HeatInput')} (SS-EN 1011-1)</Label>
                                    <p className="font-italic">
                                        Q = (U ∙ I ∙ t) / (1000 ∙ l) ∙ k  [kJ/mm]<br />
                                    Q = (U ∙ I ∙ 60) / 1000 . hastigheten i mm/min. (kJ/mm)<br />
                                    </p>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <p className="font-italic">
                                        Q = {t('heatinput')} [kJ/mm]<br />
                                        U = {t('arctension')} [volt]<br />
                                        I = {t('weldingcurrent')} [ampere]<br />
                                        l = {t('weldingbead')} [mm]<br />
                                        t = {t('time')}<br />
                                        k = {t('efficiency')}<br />
                                    </p>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="weldingProtocolHeatInput" size="sm">{t('ThermalEfficiencyOfWeldingMethods')} (k)</Label>
                                    <p className="font-italic">
                                        {t('SubmergedArcWelding')} k=1.0<br />
                                        {t('GasMetalArcWelding')} k=0,8<br />
                                        {t('ShieldedMetalArcWelding')} k=0,8<br />
                                        {t('GasTungstenArcWelding')} k=0,6<br />
                                        {t('PlasmaArcWelding')} k=0,6<br />
                                    </p>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="weldingProtocolWeldingProcessEfficiencyK" size="sm">{t('WeldingProcess')}, {t('Efficiency')}, k</Label>
                                    <p className="font-italic">
                                        121: k = 1,0<br />
                                        111, 114, 131, 135, 136, 137, 138: k = 0,8<br />
                                        141, 15: k = 0,6
                                    </p>
                                </FormGroup>
                            </Col>
                        </Row> :
                        <Row form>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="weldingProtocolHeatInput" size="sm">{t('ArcEnergy')} (SIS-ISO/TR 18491)</Label>
                                    <p className="font-italic">
                                        {t('FormulaProvidesTheArcEnergy')}<br />
                                        E = (U ∙ I) / v ∙ 0,001<br />
                                        {t('FormulaGivesThe')}<br />
                                        E = (IE) / L ∙ 0,001<br />
                                        {t('FormulaGivesTheArcEnergy')}<br />
                                        E = (IP) / v ∙ 0,001<br />
                                    </p>
                                </FormGroup>
                            </Col>
                            <Col sm={6} hidden={this.props.weldingPulseParameters || this.props.weldingArcParameters ? false : true}>
                                <FormGroup>
                                    <p className="font-italic">
                                        I = {t('weldingcurrent')} [A]<br />
                                        L = {t('weldingBead')} [mm]<br />
                                        U = {t('arctension')} [V]<br />
                                        v = {t('weldingSpeed')} [mm/s]<br />
                                        E = {t('arcEnergy')} [kJ/mm]<br />
                                        IE = {t('instantaneousEnergy')} [J]<br />
                                        IP = {t('instantaneousPower')} [J/s]<br />
                                    </p>
                                </FormGroup>
                            </Col>
                        </Row> 
                    }
                </CardBody>
            </Card>
        </React.Fragment>);
    }
};

export default connect()(FormelForHeatInput);
