﻿export class GetAfterWeldingResponse {
    coolingRate: string;
    createdAt: string;
    deletedAt: string;
    heatingRate: string;
    id: number;
    updatedAt: string;

    public constructor() {
        this.coolingRate = '';
        this.createdAt = '';
        this.deletedAt = '';
        this.heatingRate = '';
        this.id = 0.0;
        this.updatedAt = '';
    }
}