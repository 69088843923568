﻿export class TransverseTensileTestingResultsTableRow {
    a: string;
    acceptance: string;
    fm: string;
    fractureSurfaceCondition: string;
    placeOfBreak: string;
    r: string;
    rm: string;
    rp?: string;
    testSpecimenDimensions: string;
    testSpecimenNumber: string;

    public constructor(testSpecimenNumber: string, testSpecimenDimensions: string, fm: string, rm: string, r: string, a: string, placeOfBreak: string, fractureSurfaceCondition: string, acceptance: string) {
        this.testSpecimenNumber = testSpecimenNumber;
        this.testSpecimenDimensions = testSpecimenDimensions;
        this.fm = fm;
        this.rm = rm;
        this.r = r;
        this.a = a;
        this.placeOfBreak = placeOfBreak;
        this.fractureSurfaceCondition = fractureSurfaceCondition;
        this.acceptance = acceptance;
        this.rp = '';
    }
}