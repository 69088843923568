﻿import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, FormText, Input, InputGroup, InputGroupButtonDropdown, Label, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Nav, NavLink, PopoverBody, PopoverHeader, Row, Tooltip, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import { t } from '../i18n';
import * as ApplicationTabStore from '../store/ApplicationTabStore';
import { ApplicationState } from '../store/index';
import { ApplicationStatus } from '../store/models/ApplicationStatus';
import HelpFunctions from '../store/models/HelpFunctions';
import { OtherOptions } from '../store/models/OtherOptions';
import { CreateApplicationRequest } from '../store/models/requests/CreateApplicationRequest';
import { UpdateApplicationRequest } from '../store/models/requests/UpdateApplicationRequest';
import { GetBaseMaterialResponse } from '../store/models/responses/GetBaseMaterialResponse';
import { GetMakeResponse } from '../store/models/responses/GetMakeResponse';
import { GetRootProtectionGasResponse } from '../store/models/responses/GetRootProtectionGasResponse';
import { GetShieldingGasResponse } from '../store/models/responses/GetShieldingGasResponse';
import { GetWelderResponse } from '../store/models/responses/GetWelderResponse';
import { GetWeldingMethodResponse } from '../store/models/responses/GetWeldingMethodResponse';
//@ts-ignore
import DrawJointDesign from './DrawJointDesign';
import FileUpload from './FileUpload';
import FormelForHeatInput from './FormelForHeatInput';
import Loader from './Loader';
import LogoImage from './LogoImage';
import CommonModals from './otherComponents/CommonModals';
import DropdownLists from './otherComponents/DropdownLists';
import Gases from './otherComponents/Gases';
import HistoryProgress from './otherComponents/HistoryProgress';
import PwpsListModal from './otherComponents/PwpsListModal';
import TypeOfBacking from './otherComponents/TypeOfBacking';
import TestingOrder from './TestingOrder';
import WeldSequence from './WeldSequence';
import WParameters from './WParameters';

// At runtime, Redux will merge together...
type ApplicationTabProps =
    ApplicationTabStore.ApplicationTabStoreState // ... state we've requested from the Redux store
    & typeof ApplicationTabStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ applicationId: string }> // ... plus incoming routing parameters
    & ownProps; // ... plus incoming routing parameters

export interface ownProps {
    SetCurrentCustomerId: any;
}

const SweToEngOtherChoices = new Map<string, string>();
const SweToEngWeldingModeRequestsNames = new Map<string, string>();
const SweToEngWeldingModeRequestsDescriptions = new Map<string, string>();
const SweToEngRootSupport = new Map<string, string>();
const SweToEngArcType = new Map<string, string>();
const SweToEngJointTypes = new Map<string, string>();
const SweToEngTackWelds = new Map<string, string>();

class ApplicationTab extends React.PureComponent<ApplicationTabProps> {
    constructor(props: ApplicationTabProps) {
        super(props);
    }
    public state = {
        tooltipOpen: false,
        tabId: '',
        dropdownOpen: false,
        showWeldSequenceModal: false,
        userId: 0
    };

    private toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    };

    private toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    private TranslateBoxes = (word: string, dictionary: Map<string, string>) => {
        if (dictionary.has(word)) {
            return dictionary.get(word);
        } else {
            return word;
        }
    }

    private toggleWeldSequenceModal = () => {
        this.setState({
            showWeldSequenceModal: !this.state.showWeldSequenceModal
        });
    }

    public componentDidMount() {
        // Clear all store
        this.props.clearState();
        // Start tab
        this.props.setSelectedTabId('selectEmployee');
        // Clear selected fields.
        this.props.clearSelectEmployee();
        this.props.clearStandard();
        this.props.clearWeldingmethods();
        // Initialize forms.
        //this.props.initQWBPOrder();
        // Get data from db.
        //this.props.getCompanyById(1);
        const customerId = JSON.parse(localStorage.getItem('customerId')!);
        const userId = localStorage.getItem('userId')!;

        //const customerId = parseInt(localStorage === null ? "0" : localStorage.getItem('customerId'));
        this.props.setCurrentCustomerId(customerId);
        this.props.getCustomerByUserId(userId, () => {
            this.props.initPWPS();
        });
       // this.props.getCustomerById(customerId);
        this.props.setDekraContactEmail();
        this.props.getStandardsAll();
        this.props.getAdditionalRequirementsAll();
        this.props.getOtherOptionsAll();
        this.props.getNumberOfWeldingPositionsAll();
        this.props.getJointTypesAll();
        this.props.getIndexesAll();
        this.props.getCurrentTypesAll();
        this.props.getRootSupportDetailsSearchResult();
        this.props.getWeldingPositionSearchResult();
        this.props.getArcTypeSearchResult();
        this.props.getJointPreparationsAll();
        this.props.getByUserIdAndApplicationStatus(userId, ApplicationStatus.InProgress,
            (savedApplicationId: number) => {
                if (savedApplicationId > 0) {
                    this.props.getApplication(savedApplicationId, 0, "null") // Get saved application
                } else {
                    this.createNewApplication(); // Create a new application in db
                }
            })

        SweToEngOtherChoices.set('Rör mot plåt', t('PipeAgainstPlate'));
        SweToEngOtherChoices.set('Förband med flera svetsmetoder', t('JointWithMultipleWeldingMethods'));
        SweToEngOtherChoices.set('Pulssvetsning (fyrkantsvåg)', t('PulseWelding'));
        SweToEngOtherChoices.set('Vågformsstyrd process (Bågenergi IE alt. IP)', t('WaveformControlledProcess'));

        SweToEngWeldingModeRequestsNames.set('Ett svetsläge', t('SingleMode'));
        SweToEngWeldingModeRequestsNames.set('Två eller flera svetslägen', t('TwoOrMoreModes'));
        SweToEngWeldingModeRequestsDescriptions.set('Giltigheten blir bara för det valda svetsläget.', t('ValidityWillOnlyBeForTheChosenWeldingMode'));
        SweToEngWeldingModeRequestsDescriptions.set('Ett varmt svetsläge och kallt svetsläge ger en giltighet/möjlighet för att svetsa i de flesta svetslägena.', t('OneHotModeAnd_'));

        SweToEngRootSupport.set('bs utan mejsling', t('BSWithoutChiselling'));
        SweToEngRootSupport.set('bs med mejsling', t('BSWithChiselling'));

        SweToEngArcType.set('D Kortbåge', t('DShortArc'));
        SweToEngArcType.set('S Spraybåge', t('SSprayArc'));
        SweToEngArcType.set('P Pulsbåge', t('PPulseArc'));
        SweToEngArcType.set('Globulär droppövergång', t('GlobularTransfer'));

        SweToEngJointTypes.set('Stumsvets (BW)', t('ButtWeldingBW'));
        SweToEngJointTypes.set('Partiell stumsvets', t('PartialButtWelding'));
        SweToEngJointTypes.set('Kälsvets (FW)', t('KeelWeldingFW'));
        SweToEngJointTypes.set('T-förband (BW)', t('TJointBW'));
        SweToEngJointTypes.set('Rör mot plåt (BW)', t('PipeAgainstPlateBW'));
        SweToEngJointTypes.set('Rör mot plåt (FW)', t('PipeAgainstPlateFW'));
        SweToEngJointTypes.set('Avstickare (BW)', t('StickOutBW'));
        SweToEngJointTypes.set('Avstickare (FW)', t('StickOutFW'));

        SweToEngTackWelds.set('Häftor slipas bort', t('TacksGetGrindedAway'));
        SweToEngTackWelds.set('Kvarvarande häftor', t('RemainingTacks'));
    }

    componentDidUpdate(prevProps: any) {
    }

    private createNewApplication() {
        let createApplicationRequest: CreateApplicationRequest = {
            applicationStatus: ApplicationStatus.InProgress,
            userId: localStorage.getItem('userId')!,
            customerId: parseInt(JSON.parse(localStorage.getItem('customerId')!)),
            standardId: undefined,
            numberOfWeldingPositionsId: (this.props.selectedNumberOfWeldingPositions.id !== 0) ? this.props.selectedNumberOfWeldingPositions.id : undefined,
            createPWPSRequest: {
                date: '',
                projectNumber: '',
                byStandardId: undefined,
                revisionNumber: '',
                pWPSNumber: '',
                wpqrNumber: '',
                createParentMaterialRequest: {
                    baseMaterialId1: undefined,
                    materialThickness1: 0.0,
                    diameter1: 0.0,
                    baseMaterialId2: undefined,
                    materialThickness2: 0.0,
                    diameter2: 0.0,
                    technicalDeliveryConditionId1: undefined
                },
                createWeldingDataRequest: {
                    jointTypeId: undefined,
                    rootSupportDetailsId: undefined,
                    tackWeldId: undefined,
                    weldingPositionId: undefined,
                    rootProtectionGasId: undefined,
                    nozzleDiameter: '',
                    stickOut: '',
                    lengthTackWelds: '',
                    jointPreparationSearchText: '',
                    tungstenElectrode: '',
                    maxWidthOfRun: '',
                },
                createWeldingProcessRequest: {
                    weldingMethodId1: undefined,
                    makeId1: undefined,
                    shieldingGasId1: undefined,
                    arcTypeId1: undefined,
                    weldingMethodId2: undefined,
                    makeId2: undefined,
                    shieldingGasId2: undefined,
                    arcTypeId2: undefined
                },
                createJointDesignRequest: {
                    materialThickness1: 0.0,
                    materialThickness2: 0.0,
                    jointTypeId: undefined,
                    jointShapeId: undefined,
                    jointAngleAlpha: 0.0,
                    gapOpening: 0.0,
                    straightEdge: 0.0,
                    phaseAngle1: 0.0,
                    phaseAngle2: 0.0,
                    jointAngleBeta: 0.0,
                    throatThickness: 0.0,
                    rootSupportId: undefined,
                    depthOfPreparation: 0.0,
                    radius: 0.0,
                    note: '',
                    jointDesignHtmlImage: ''
                },
                createWeldingParametersRequests: [],
                createTemperatureRequest: {
                    preheatTemperature: 0.0,
                    maxInterpassTemperature: 0.0
                },
                createAfterWeldingRequest: {
                    heatingRate: '',
                    time: '',
                    temperature: '',
                    coolingRate: ''
                },
                otherInformation: ''
            }
        };
        this.props.createApplication(createApplicationRequest);
    }

    private onChangeAdditionalRequirementSelected(additionalRequirementId: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setAdditionalRequirementSelected(additionalRequirementId, event.currentTarget.checked);
        this.props.setAdditionalRequirementInTestingOrderSelected(additionalRequirementId, event.currentTarget.checked);
    }

    private onChangeAfterWeldingCoolingRate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setAfterWeldingCoolingRate(event.currentTarget.value);
    }

    private onChangeAfterWeldingHeatingRate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setAfterWeldingHeatingRate(event.currentTarget.value);
    }

    private onChangeCompanyCity(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCompanyCity(event.currentTarget.value);
    }

    private onChangeCompanyContactPerson(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCompanyContactPerson(event.currentTarget.value);
    }

    private onChangeCompanyContactPersonEmail(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCompanyContactPersonEmail(event.currentTarget.value);
    }

    private onChangeCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCompanyName(event.currentTarget.value);
    }

    private onChangeCompanyPhone(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCompanyPhone(event.currentTarget.value);
    }

    private onChangeCompanyPostcode(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCompanyPostcode(event.currentTarget.value);
    }

    private onChangeCompanyStreetAddress(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCompanyStreetAddress(event.currentTarget.value);
    }

    private onChangeHeaderByStandards(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderSelectedByStandard(parseInt(event.currentTarget.value));
    }

    private onChangeHeaderDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderDate(event.currentTarget.value);
    }
    private onChangeHeaderProject(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderProject(event.currentTarget.value);
    }

    private onChangeHeaderPWPSNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderPWPSNumber(event.currentTarget.value);
    }

    private onChangeHeaderRevisionNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderRevisionNumber(event.currentTarget.value);
    }

    private onChangeHeaderWPQRNumber(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeaderWPQRNumber(event.currentTarget.value);
    }

    private onChangeEnableBaseMaterial1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableBaseMaterial1Text(event.currentTarget.checked);
    }

    private onChangeEnableBaseMaterial2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableBaseMaterial2Text(event.currentTarget.checked);
    }

    private onChangeOtherOptionSelected(otherOptionId: number, event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherOptionSelected(otherOptionId, event.currentTarget.checked);
        // Show different parameterstable. TODO Better
        if (otherOptionId === OtherOptions.PulseWeldingParametersTable || otherOptionId === OtherOptions.ArcWeldingParametersTable || otherOptionId === OtherOptions.GwParametersTable) {
            this.props.setHeaderOtherOptionParametersTableId(otherOptionId, event.currentTarget.checked)
        }
        // JointWithMultipleWeldingMethods
        if (otherOptionId === OtherOptions.JointWithMultipleWeldingMethods ) {
            this.props.setSelectedWeldingPosition2(otherOptionId, event.currentTarget.checked)
        }
    }

    private onChangeParentMaterialBaseMaterial1SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial1SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getBaseMaterial1SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearBaseMaterial1SearchResult();
        }
    }

    private onChangeOtherInformationOtherInformation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOtherInformationOtherInformation(event.currentTarget.value);
    }

    private onChangeParentMaterialBaseMaterial2SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial2SearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getBaseMaterial2SearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearBaseMaterial2SearchResult();
        }
    }

    private onChangeParentMaterialDiameter1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialDiameter1(event.currentTarget.value);
    }

    private onChangeParentMaterialDiameter2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialDiameter2(event.currentTarget.value);
    }

    private onChangeParentMaterialMaterialThickness1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialThickness1(event.currentTarget.value);
    }

    private onChangeParentMaterialBaseMaterial1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial1Text(event.currentTarget.value);
    }

    private onChangeParentMaterialBaseMaterial2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialBaseMaterial2Text(event.currentTarget.value);
    }

    private onChangeParentMaterialMaterialGroup2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialGroup2Text(event.currentTarget.value);
    }

    private onChangeParentMaterialMaterialGroup1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialGroup1Text(event.currentTarget.value);
    }

    private onChangeParentTechnicalDeliveryCondition1Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialTechnicalDeliveryCondition1Text(event.currentTarget.value);
    }

    private onChangeParentTechnicalDeliveryCondition2Text(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialTechnicalDeliveryCondition2Text(event.currentTarget.value);
    }
    
    private onChangeParentMaterialMaterialThickness2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialMaterialThickness2(event.currentTarget.value);
    }

    private onChangeParentMaterialTechnicalDeliveryConditions1(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialSelectedTechnicalDeliveryCondition1(parseInt(event.currentTarget.value));
    }

    private onChangeParentMaterialTechnicalDeliveryConditions2(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setParentMaterialSelectedTechnicalDeliveryCondition2(parseInt(event.currentTarget.value));
    }

    private onChangeSelectedNumberOfWeldingPositions(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedNumberOfWeldingPositions(parseInt(event.currentTarget.value));
    }

    private onChangeSelectedStandard(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setSelectedStandard(parseInt(event.currentTarget.value));
        this.props.setSelectedStandardInTestingOrderSelected(parseInt(event.currentTarget.value));

    }

    private onChangeTemperatureMaxInterpassTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTemperatureMaxInterpassTemperature(event.currentTarget.value);
    }

    private onChangeTemperaturePreheatTemperature(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTemperaturePreheatTemperature(event.currentTarget.value);
    }

    private onChangeWelderSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWelderSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWelderSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWelderSearchResult();
        }
    }

    private onChangeWeldingDataJointTypes(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataSelectedJointType(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingDataLengthTackWelds(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataLengthTackWelds(event.currentTarget.value);
    }

    private onChangeWeldingDataPlasmaflowrate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataPlasmaflowrate(event.currentTarget.value);
    }

    private onChangeWeldingDataElectrodeTorchAngle111(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataElectrodeTorchAngle111(event.currentTarget.value);
    }

    private onChangeWeldingDataMaxWidthOfRun(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataMaxWidthOfRun(event.currentTarget.value);
    }

    private onChangeWeldingDataNozzleDiameter(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataNozzleDiameter(event.currentTarget.value);
    }

    private onChangeWeldingDataRootProtectionGasSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataRootProtectionGasSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getRootProtectionGasSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearRootProtectionGasSearchResult();
        }
    }

    private onChangeWeldingDataRootSupportDetails(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataSelectedRootSupportDetails(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingDataStickOut(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataStickOut(event.currentTarget.value);
    }

    private onChangeWeldingDataTungstenElectrode(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataTungstenElectrode(event.currentTarget.value);
    }

    private onChangeWeldingDataElectrodeTorchAngle(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataElectrodeTorchAngle(event.currentTarget.value);
    }

    private onChangeWeldingDataWeldingPositionSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataWeldingPositionSearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingDataWeldingPosition2SearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataWeldingPosition2SearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingDataTackWeldSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingDataSelectedTackWeld(event.currentTarget.value);
    }

    private onChangeWeldingProcessArcTypeASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessArcTypeASearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProcessArcTypeBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessArcTypeBSearchText(parseInt(event.currentTarget.value));
    }

    private onChangeWeldingProcessMakeASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessMakeASearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getMakeASearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearMakeASearchResult();
        }
    }

    private onChangeWeldingProcessMakeBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessMakeBSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getMakeBSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearMakeBSearchResult();
        }
    }

    private onChangeWeldingProcessShieldingGasASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessShieldingGasASearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getShieldingGasASearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearShieldingGasASearchResult();
        }
    }

    private onChangeWeldingProcessShieldingGasBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessShieldingGasBSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getShieldingGasBSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearShieldingGasBSearchResult();
        }
    }

    private onChangeWeldingProcessWeldingMethodASearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessWeldingMethodASearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWeldingMethodASearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingMethodASearchResult();
        }
    }

    private onChangeWeldingProcessWeldingMethodBSearchText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessWeldingMethodBSearchText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.props.getWeldingMethodBSearchResult(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingMethodBSearchResult();
        }
    }

    private onClickConfirm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.updateApplication(true);
    }

    private onClickToSave(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.updateApplication(false);
    }

    private onClickNavItem(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        if (this.props.applicationId === 0) {
            return;
        }
        // Only update the application to db if we step backward or forward.
        if (event.currentTarget.id !== this.props.selectedTabId) {
            this.updateApplication(false);
        }
        // Initialize/Update the QWBPOrder when the testingorder(provning) tab is clicked.
        if (event.currentTarget.id === 'testing') {
            this.props.initQWBPOrder();
        }
        this.props.setSelectedTabId(event.currentTarget.id);
    }

    private onClickNextButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        (document.getElementById('history') as HTMLButtonElement).focus();// Scroll to the top, the element is in component HistoryProgress 

        if (this.props.applicationId === 0) {
            return;
        }
        if (this.props.selectedTabId === 'selectEmployee') {
            this.props.setSelectedTabId('standard');
        }
        else if (this.props.selectedTabId === 'standard') {
            this.props.setSelectedTabId('pWPS');
        }
        else if (this.props.selectedTabId === 'pWPS') {
            this.props.initQWBPOrder();
            this.props.setSelectedTabId('testing');
        }
        else if (this.props.selectedTabId === 'testing') {
            this.props.setSelectedTabId('confirm');
        }
        else if (this.props.selectedTabId === 'confirm') {
            this.props.setSelectedTabId('confirm');
        }
        // Update/Save the application to db.
        this.updateApplication(false);
    }
    private onClickPreviousButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        (document.getElementById('history') as HTMLButtonElement).focus();// Scroll to the top, the element is in component HistoryProgress 

        if (this.props.applicationId === 0) {
            return;
        }
        if (this.props.selectedTabId === 'selectEmployee') {
            this.props.setSelectedTabId('selectEmployee');
        }
        else if (this.props.selectedTabId === 'standard') {
            this.props.setSelectedTabId('selectEmployee');
        }
        else if (this.props.selectedTabId === 'pWPS') {
            this.props.setSelectedTabId('standard');
        }
        else if (this.props.selectedTabId === 'testing') {
            this.props.setSelectedTabId('pWPS');
        }
        else if (this.props.selectedTabId === 'confirm') {
            this.props.setSelectedTabId('testing');
        }
        // Update/Save the application to db.
        this.updateApplication(false);
    }

    private onClickParentMaterialSelectBaseMaterial1(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, baseMaterial: GetBaseMaterialResponse) {
        event.preventDefault();
        this.props.clearBaseMaterial1SearchResult();
        this.props.setParentMaterialSelectedBaseMaterial1(baseMaterial);
    }

    private onClickParentMaterialSelectBaseMaterial2(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, baseMaterial: GetBaseMaterialResponse) {
        event.preventDefault();
        this.props.clearBaseMaterial2SearchResult();
        this.props.setParentMaterialSelectedBaseMaterial2(baseMaterial);
    }

    private onClickRemoveWelder(welderId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.props.removeWelder(welderId);
    }

    private onClickSelectWelder(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, welder: GetWelderResponse) {
        event.preventDefault();
        this.props.clearWelderSearchResult();
        this.props.addSelectedWelder(welder);
    }

    private onClickToggleJointDesignModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.toggleShowJointDesignModal();
    }

    private onClickToggleWeldSequenceModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.toggleWeldSequenceModal();
    }

    private onClickWeldingDataSelectRootProtectionGas(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, rootProtectionGas: GetRootProtectionGasResponse) {
        event.preventDefault();
        this.props.clearRootProtectionGasSearchResult();
        this.props.setWeldingDataSelectedRootProtectionGas(rootProtectionGas);
    }

    private onClickWeldingProcessSelectMakeA(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, make: GetMakeResponse) {
        event.preventDefault();
        this.props.clearMakeASearchResult();
        this.props.setWeldingProcessSelectedMakeA(make);
    }

    private onClickWeldingProcessSelectMakeB(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, make: GetMakeResponse) {
        event.preventDefault();
        this.props.clearMakeBSearchResult();
        this.props.setWeldingProcessSelectedMakeB(make);
    }

    private onClickWeldingProcessSelectShieldingGasA(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, shieldingGas: GetShieldingGasResponse) {
        event.preventDefault();
        this.props.clearShieldingGasASearchResult();
        this.props.setWeldingProcessSelectedShieldingGasA(shieldingGas);
    }

    private onClickWeldingProcessSelectShieldingGasB(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, shieldingGas: GetShieldingGasResponse) {
        event.preventDefault();
        this.props.clearShieldingGasBSearchResult();
        this.props.setWeldingProcessSelectedShieldingGasB(shieldingGas);
    }

    private onClickWeldingProcessSelectWeldingMethodA(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, weldingMethod: GetWeldingMethodResponse) {
        event.preventDefault();
        this.props.clearWeldingMethodASearchResult();
        this.props.setWeldingProcessSelectedWeldingMethodA(weldingMethod);
    }

    private onClickWeldingProcessSelectWeldingMethodB(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, weldingMethod: GetWeldingMethodResponse) {
        event.preventDefault();
        this.props.clearWeldingMethodBSearchResult();
        this.props.setWeldingProcessSelectedWeldingMethodB(weldingMethod);
    }

    private onClickAutoFilling(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        this.props.setParentMaterialAutoFilling();
    }

    private onChangeWeldingDataSetJointPreparationText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        if (event.currentTarget.value.length > 0) {
            this.props.setOnchangeJointPreparationText(event.currentTarget.value);
        }
        else {
            this.props.clearJointPreparationText();
        }
    }

    private onClickWeldingDataSetJointPreparationText(SelectJointPreparation: string) {
        this.props.setJointPreparationText(SelectJointPreparation);
    }

    private onChangeManufacturer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setManufacturer(event.currentTarget.value);
    }

    private onChangeExaminer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setExaminer(event.currentTarget.value);
    }

    private onChangeConfirmedMessage(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setConfirmedMessage(event.currentTarget.value);
    }

    private onChangeEnableMakeAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMakeAText(event.currentTarget.checked);
    }

    private onChangeEnableMakeBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMakeBText(event.currentTarget.checked);
    }

    private onChangeMakeAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMakeAText(event.currentTarget.value);
    }

    private onChangeDesignationAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDesignationAText(event.currentTarget.value);
    }

    private onChangefmGroupAText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setfmGroupAText(event.currentTarget.value);
    }

    private onChangeMakeBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMakeBText(event.currentTarget.value);
    }

    private onChangeDesignationBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDesignationBText(event.currentTarget.value);
    }

    private onChangefmGroupBText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setfmGroupBText(event.currentTarget.value);
    }

    private handleChange = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            this.props.createFile(event.target.files[i]);
        }
    };

    private handleChangeDraJointDesign = (event: any) => {
        event.preventDefault();
        for (var i = 0; i < event.target.files.length; ++i) {
            this.props.createImageDrawJointDesign(event.target.files[i])
        }
    };

    private setHeaderSuccessStatusCode = () => {
        this.props.setHeaderSuccessStatusCode()
        this.props.history.push('/');
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className="dekra-content-wrapper dekra-wrapper">
                    <Loader show={this.props.applicationId === 0} title={t('CreatingApplication')} />
                    <Loader show={this.props.updatingApplication} title={t('SavingApplication')} />
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader className="card-header-dekra">
                                    <Row>
                                        <Col sm={12}>
                                            <h1>{t('Application')} {t('OnWeldingProcedureQualification')}</h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={5}>
                                            <Nav pills className="dekra-breadcrumb">
                                            <NavLink active={this.props.selectedTabId === 'selectEmployee'
                                                || this.props.selectedTabId === 'standard'
                                                || this.props.selectedTabId === 'pWPS'
                                                || this.props.selectedTabId === 'testing'
                                                || this.props.selectedTabId === 'confirm'} href="#" id="selectEmployee" onClick={(event) => this.onClickNavItem(event)}>{t('ChooseEmployee')}</NavLink>
              
                                            <NavLink active={this.props.selectedTabId === 'standard'
                                                || this.props.selectedTabId === 'pWPS'
                                                || this.props.selectedTabId === 'testing'
                                                || this.props.selectedTabId === 'confirm'} href="#" id="standard" onClick={(event) => this.onClickNavItem(event)}>{t('Standard')}</NavLink>
                                       
                                            <NavLink active={this.props.selectedTabId === 'pWPS'
                                                || this.props.selectedTabId === 'testing'
                                                || this.props.selectedTabId === 'confirm'} href="#" id="pWPS" onClick={(event) => this.onClickNavItem(event)}>pWPS</NavLink>
                                        
                                            <NavLink active={this.props.selectedTabId === 'testing'
                                                || this.props.selectedTabId === 'confirm'} href="#" id="testing" onClick={(event) => this.onClickNavItem(event)}>{t('Testing')}</NavLink>
                                       
                                            <NavLink active={this.props.selectedTabId === 'confirm'} href="#" id="confirm" onClick={(event) => this.onClickNavItem(event)}>{t('Verification')}</NavLink>
                                            </Nav>
                                        </Col>
                                        <Col sm={2} className="text-center">
                                            <Button color="primary" onClick={(event) => this.onClickToSave(event)}>{t('Save')}{' '}
                                                <i className="fas fa-save"></i>
                                            </Button>
                                        </Col>
                                        <Col sm={5}>
                                            <PwpsListModal showPwpsList={this.props.selectedTabId === 'pWPS'} initSelectedPwps={this.props.initSelectedPwps } />
                                            <HistoryProgress showHistoriesProgressList={true} />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div hidden={this.props.selectedTabId !== 'selectEmployee'}>
                                        {this.renderSelectEmployee()}
                                    </div>
                                    <div hidden={this.props.selectedTabId !== 'standard'}>
                                        {this.renderStandard()}
                                    </div>
                                    <div hidden={this.props.selectedTabId !== 'pWPS'}>
                                        {this.renderPWPS()}
                                    </div>
                                    <div hidden={this.props.selectedTabId !== 'testing'}>
                                            <TestingOrder pqwbpOrder={this.props.qwbpOrder} />
                                    </div>
                                    <div hidden={this.props.selectedTabId !== 'confirm'}>
                                        {this.renderConfirm()}
                                        {this.renderCommonModals()}
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col sm={6}>
                                            <div className={this.props.selectedTabId === 'selectEmployee' ? "float-left invisible" : "float-left"}>
                                                <Button color="primary" onClick={(event) => this.onClickPreviousButton(event)}>
                                                    {t('Previous')}
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className={this.props.selectedTabId === 'confirm' ? "float-right invisible" : "float-right"}>
                                                <Button color="primary" onClick={(event) => this.onClickNextButton(event)}>
                                                    {t('Next')}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    private renderCommonModals() {
        return (
            <React.Fragment>
                <CommonModals bodyTemplateHtml={this.props.header.bodyTemplateHtml} openModal={this.props.header.successStatusCode} toggleShowModal={this.setHeaderSuccessStatusCode } ></CommonModals>
            </React.Fragment>
        );
    }

    private renderAfterWelding() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('FollowUpHeatTreatmentAndOrAging')} (PWHT)</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="afterWeldingHeatingRate" size="sm">{t('MinAndMaxTemperature')} (°C), {t('HoldTime')} ({t('min')})</Label>
                                        <InputGroup >
                                            <Input type="textarea" bsSize="sm" name="weldingProtocolPostWeldHeatTreatment" id="weldingProtocolPostWeldHeatTreatment"
                                                value={this.props.afterWelding.heatingRate}
                                                onChange={(event) => this.onChangeAfterWeldingHeatingRate(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={20} dropDownListsCallback={this.props.setAfterWeldingHeatingRate} rowNumber={0} ></DropdownLists>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="afterWeldingCoolingRate" size="sm">{t('HeatingAndCoolingSpeed')} (°C/{t('hour')})</Label>
                                        <InputGroup >
                                            <Input type="textarea" bsSize="sm" name="weldingProtocolHeatingAndCoolingRate" id="weldingProtocolHeatingAndCoolingRate"
                                                value={this.props.afterWelding.coolingRate}
                                                onChange={(event) => this.onChangeAfterWeldingCoolingRate(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={21} dropDownListsCallback={this.props.setAfterWeldingCoolingRate} rowNumber={0} ></DropdownLists>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderConfirm() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={6}>
                        <React.Fragment>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                            <h1 className="card-title">Sammanfattning och godkännande av beställning</h1>
                                    </CardTitle>
                                    <div>
                                        <Row form>
                                        <Col sm={12}>
                                                <p>
                                                        <Label size="sm">Kvalificering av svetsprocedur är beställd enligt nedan:</Label><br />
                                                        {this.props.header.pwpsNumber}<br />
                                                        <Label size="sm">Information om bekräftelsemail skickas till</Label><br />
                                                    Svetsansvarige, {this.props.header.dekraContactEmail}<br />
                                                        <Label size="sm">Ladda upp pWPS samt materialcertifikat för grundmaterialet/-en och tillsatsmaterialet/-en.</Label><br />
                                                    Om ni inte önskar bifoga materialcertifikat i databasen måste de lämnas till svetsövervakaren vid övervakningstillfället.<br />
                                                </p>
                                        </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                    <FormGroup>
                                                        <Label for="confirmedMessage" size="sm">Meddelande</Label>
                                                            <Input type="textarea" rows={3} bsSize="sm" name="confirmedMessage" id="confirmedMessage"
                                                                value={this.props.header.confirmedMessage}
                                                            onChange={(event) => this.onChangeConfirmedMessage(event)}
                                                            onKeyPress={(event) => this.onKeyPress(event)}
                                                            placeholder="" />
                                                    </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col sm={12} className="text-center">
                                                    <Button color="primary" size="lg" onClick={(event) => this.onClickConfirm(event)}>{t('Confirm')}</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </React.Fragment>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <FormText color="muted">
                                    <FileUpload pFilesUpload={this.props.filesUpload} showFilesUploadTable={true} hiddenSelectedFileRow={true}></FileUpload>
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderExaminer() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('Examiner')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Input type="textarea" bssize="sm" name="examiner" id="examiner"
                                            value={this.props.header.examiner} onChange={(event) => this.onChangeExaminer(event)}
                                            placeholder={t('Name') + ", " + t('Date') + " XXXX-XX-XX"}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderHead() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div>
                            <Row form>
                                <Col sm={3}>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerDate" size="sm">{t('Date')}</Label>
                                                <Input type="date" max="9999-12-30" bssize="sm" name="headerDate" id="headerDate"
                                                    value={this.props.header.date} onChange={(event) => this.onChangeHeaderDate(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerProject" size="sm">{t('Project')}</Label>
                                                <Input type="text" bssize="sm" name="headerProject" id="headerProject"
                                                    value={this.props.header.project}
                                                    onChange={(event) => this.onChangeHeaderProject(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-left">
                                                <Label for="headerByStandards" size="sm">{t('ByStandard')}</Label>
                                                <Input type="select" bssize="sm" name="headerByStandards" id="headerByStandards"
                                                    value={this.props.header.selectedByStandard.id}
                                                    onChange={(event) => this.onChangeHeaderByStandards(event)}
                                                    placeholder="">
                                                    <option id="byStandardId0" value="0"></option>
                                                    {this.props.standards.map((standard, index) => (
                                                        <option id={"byStandardId" + standard.id} value={standard.id}>{standard.name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-center">
                                                <LogoImage customerLogo={this.props.header.customerLogo} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-center">
                                                <h2>{t('PreliminaryWeldingProcedureSpecification')}</h2>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup className="text-center">
                                                <p className="font-weight-normal lead">
                                                    (pWPS) ISO 15609-1
                                                </p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={3}>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerRevisionNumber" size="sm">{t('RevisionNumber')}</Label>
                                                <Input type="text" bssize="sm" name="headerRevisionNumber" id="headerRevisionNumber"
                                                    value={this.props.header.revisionNumber}
                                                    onChange={(event) => this.onChangeHeaderRevisionNumber(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerPWPSNumber" size="sm">{t('pWPSNumber')}</Label>
                                                <Input type="text" bssize="sm" name="headerPWPSNumber" id="headerPWPSNumber"
                                                    value={this.props.header.pwpsNumber}
                                                    onChange={(event) => this.onChangeHeaderPWPSNumber(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="headerWPQRNumber" size="sm">{t('PrelWPQRNumber')}</Label>
                                                <Input type="text" bssize="sm" name="headerWPQRNumber" id="headerWPQRNumber"
                                                    value={this.props.header.wpqrNumber}
                                                    onChange={(event) => this.onChangeHeaderWPQRNumber(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderJointDesign() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('JointDesign')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <DrawJointDesign imageValidationError={this.props.header.imageValidationError1}
                                        imageUpload={this.props.header.imageUpload1}
                                        modalId={1} showJointDesignModalId={1} show={this.props.showJointDesignModal}
                                        toggleShow={this.props.toggleShowJointDesignModal}
                                        jointDesign={this.props.jointDesign}
                                        position={"application"}
                                        setDrawJointDesignView={false} />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12} className="text-center">
                                    <FormGroup>
                                        <Button color="primary" onClick={(event) => this.onClickToggleJointDesignModal(event)}>{t('Draw')}</Button><span>&nbsp;</span>
                                        <Button id={"UploadInfoDj"} name={"UploadInfoDj"} className="btn btn-primary btn-file" >
                                            {t('Upload')}<input type="file" onChange={event => this.handleChangeDraJointDesign(event)} />
                                        </Button>
                                        <Button type="button" color="link" size="sm" id={"UploadInfoDj"} name={"UploadInfoDj"} >
                                            <i className="fas fa-info-circle"></i>
                                        </Button>
                                        <span>&nbsp;</span>
                                        <UncontrolledPopover trigger="focus" placement="bottom" target={"UploadInfoDj"}>
                                            <PopoverHeader>{t('Upload')}</PopoverHeader>
                                            <PopoverBody>{t('UploadInfo')}</PopoverBody>
                                        </UncontrolledPopover>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderManufacturer() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('Manufacturer')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Input type="textarea" bssize="sm" name="manufacturer" id="manufacturer"
                                            value={this.props.header.manufacturer} onChange={(event) => this.onChangeManufacturer(event)}
                                            placeholder={t('Company') + ", " + t('Name')} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderOtherInformation() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('OtherInformation')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Input type="textarea" bssize="sm" name="otherInformation" id="otherInformation"
                                            value={this.props.otherInformation.otherInformation} onChange={(event) => this.onChangeOtherInformationOtherInformation(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderParentMaterial() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('BaseMaterial')}<span>&nbsp;</span><Button className="btn-sm-padding-right-left" type="button" color="link" size="sm" id="parentMaterialAutoFilling" onClick={(event) => this.onClickAutoFilling(event)}><i className="fas fa-clone"></i></Button><Label check size="sm">{t('CopyPart1ToPart2')}</Label></h5>
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="parentMaterialAutoFilling" toggle={this.toggle}>
                                {t('CopyPart1ToPart2')}
                            </Tooltip>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm>
                                    <FormGroup>
                                        <Form inline>
                                        <Label for="parentMaterialBaseMaterials1" size="sm">Grundmaterial namn/nummer</Label>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <FormGroup check>
                                            <Label check size="sm">
                                                <Input type="checkbox" bssize="sm" name="applicationEnableBaseMaterial1Text" id="applicationEnableBaseMaterial1Text"
                                                    checked={this.props.parentMaterial.enableBaseMaterial1Text}
                                                    onChange={(event) => this.onChangeEnableBaseMaterial1Text(event)}
                                                    placeholder="" />{''} {t('Revise')}
                                                <UncontrolledTooltip placement="right" target={"applicationEnableBaseMaterial1Text"} >
                                                    {t('ReviseOrEnterYourOwnChoice')}!
                                                    </UncontrolledTooltip>
                                            </Label>
                                        </FormGroup>
                                        </Form>
                                        {this.props.parentMaterial.enableBaseMaterial1Text ?
                                            <FormGroup>
                                                <Input className="enable-base-material-text" type="text" bsSize="sm"
                                                    name="applicationBaseMaterial1Text" id="applicationBaseMaterial1Text"
                                                    value={this.props.parentMaterial.baseMaterial1Text}
                                                    onChange={(event) => this.onChangeParentMaterialBaseMaterial1Text(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input autoComplete="off" type="search" bsSize="sm" name="parentMaterialBaseMaterials1" id="parentMaterialBaseMaterials1"
                                                    value={this.props.parentMaterial.baseMaterial1SearchText}
                                                    onChange={(event) => this.onChangeParentMaterialBaseMaterial1SearchText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                                {this.props.parentMaterial.baseMaterial1SearchResult.length !== 0 &&
                                                    <ListGroup className="scroll-vertical">
                                                        {this.props.parentMaterial.baseMaterial1SearchResult.map((baseMaterial, index) => (
                                                            <ListGroupItem tag="button" action onClick={(event) => this.onClickParentMaterialSelectBaseMaterial1(event, baseMaterial)}>
                                                                {baseMaterial.name + (baseMaterial.number === "" ? "" : "/" + baseMaterial.number)}
                                                            </ListGroupItem>
                                                        ))}
                                                    </ListGroup>
                                                }
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Part')} 1</small>
                                    </FormGroup>
                                </Col>
                                <Col sm>
                                    <FormGroup>
                                        <Label for="parentMaterialMaterialGroup1" size="sm">{t('MaterialGroup')} (ISO/TR 15608)</Label>
                                        {this.props.parentMaterial.enableBaseMaterial1Text ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-base-material-text"
                                                    name="applicationMaterialGroup1Text" id="applicationMaterialGroup1Text"
                                                    value={this.props.parentMaterial.materialGroup1Text}
                                                    onChange={(event) => this.onChangeParentMaterialMaterialGroup1Text(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input type="text" readOnly bsSize="sm" name="parentMaterialMaterialGroup1" id="parentMaterialMaterialGroup1"
                                                    value={this.props.parentMaterial.selectedMaterialGroup1.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Part')} 1</small>
                                    </FormGroup>
                                </Col>
                                <Col sm>
                                    <FormGroup>
                                        <Label for="parentMaterialTechnicalDeliveryConditions1" size="sm">Teknisk leveransstandard</Label>
                                        {this.props.parentMaterial.enableBaseMaterial1Text ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-base-material-text"
                                                    name="applicationTechnicalDeliveryCondition1Text" id="applicationTechnicalDeliveryCondition1Text"
                                                    value={this.props.parentMaterial.technicalDeliveryCondition1Text}
                                                    onChange={(event) => this.onChangeParentTechnicalDeliveryCondition1Text(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input type="select" bsSize="sm" name="parentMaterialTechnicalDeliveryConditions1" id="parentMaterialTechnicalDeliveryConditions1"
                                                    value={this.props.parentMaterial.selectedTechnicalDeliveryCondition1.id}
                                                    onChange={(event) => this.onChangeParentMaterialTechnicalDeliveryConditions1(event)}
                                                    placeholder="">
                                                    <option id="technicalDeliveryConditionId01" value="0"></option>
                                                    {this.props.parentMaterial.selectedBaseMaterial1.technicalDeliveryConditions.map((technicalDeliveryCondition, index) => (
                                                        <option id={"technicalDeliveryConditionId" + technicalDeliveryCondition.id + "1"} value={technicalDeliveryCondition.id}>{technicalDeliveryCondition.name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Part')} 1</small>
                                    </FormGroup>
                                </Col>
                                <Col sm>
                                    <FormGroup>
                                        <Label for="parentMaterialMaterialThickness1" size="sm">{t('ProductThickness')} (mm)</Label>
                                        <Input type="text" bsSize="sm" name="parentMaterialMaterialThickness1" id="parentMaterialMaterialThickness1"
                                            value={this.props.parentMaterial.materialThickness1}
                                            onChange={(event) => this.onChangeParentMaterialMaterialThickness1(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        <small className="text-muted">{t('Part')} 1</small>
                                    </FormGroup>
                                </Col>
                                <Col sm>
                                    <FormGroup>
                                        <Label for="parentMaterialDiameter1" size="sm">{t('Diameter')} (mm)</Label>
                                        <Input type="text" bsSize="sm" name="parentMaterialDiameter1" id="parentMaterialDiameter1"
                                            value={this.props.parentMaterial.diameter1}
                                            onChange={(event) => this.onChangeParentMaterialDiameter1(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        <small className="text-muted">{t('Part')} 1</small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm>
                                    <FormGroup>
                                        <Form inline>
                                            <Label for="parentMaterialBaseMaterials2" size="sm">Grundmaterial namn/nummer</Label>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <FormGroup check>
                                                <Label check size="sm">
                                                    <Input type="checkbox" bssize="sm" name="applicationEnableBaseMaterial2Text" id="applicationEnableBaseMaterial2Text"
                                                        checked={this.props.parentMaterial.enableBaseMaterial2Text}
                                                        onChange={(event) => this.onChangeEnableBaseMaterial2Text(event)}
                                                        placeholder="" />{''} {t('Revise')}
                                                    <UncontrolledTooltip placement="right" target={"applicationEnableBaseMaterial2Text"} >
                                                        {t('ReviseOrEnterYourOwnChoice')}!
                                                    </UncontrolledTooltip>
                                                </Label>
                                            </FormGroup>
                                        </Form>
                                        {this.props.parentMaterial.enableBaseMaterial2Text ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm" name="applicationBaseMaterial2Text" id="applicationBaseMaterial2Text"
                                                    className="enable-base-material-text"
                                                    value={this.props.parentMaterial.baseMaterial2Text}
                                                    onChange={(event) => this.onChangeParentMaterialBaseMaterial2Text(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input autoComplete="off" type="search" bsSize="sm" name="parentMaterialBaseMaterials2" id="parentMaterialBaseMaterials2"
                                                    value={this.props.parentMaterial.baseMaterial2SearchText}
                                                    onChange={(event) => this.onChangeParentMaterialBaseMaterial2SearchText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                                {this.props.parentMaterial.baseMaterial2SearchResult.length !== 0 &&
                                                    <ListGroup className="scroll-vertical">
                                                        {this.props.parentMaterial.baseMaterial2SearchResult.map((baseMaterial, index) => (
                                                            <ListGroupItem tag="button" action onClick={(event) => this.onClickParentMaterialSelectBaseMaterial2(event, baseMaterial)}>
                                                                {baseMaterial.name + (baseMaterial.number === "" ? "" : "/" + baseMaterial.number)}
                                                            </ListGroupItem>
                                                        ))}
                                                    </ListGroup>
                                                }
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Part')} 2</small>
                                    </FormGroup>
                                </Col>
                                <Col sm>
                                    <FormGroup>
                                        <Label for="parentMaterialMaterialGroup2" size="sm">{t('MaterialGroup')} (ISO/TR 15608)</Label>
                                        {this.props.parentMaterial.enableBaseMaterial2Text ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-base-material-text"
                                                    name="applicationMaterialGroup2Text" id="applicationMaterialGroup2Text"
                                                    value={this.props.parentMaterial.materialGroup2Text}
                                                    onChange={(event) => this.onChangeParentMaterialMaterialGroup2Text(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input type="text" readOnly bsSize="sm" name="parentMaterialMaterialGroup2" id="parentMaterialMaterialGroup2"
                                                    value={this.props.parentMaterial.selectedMaterialGroup2.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Part')} 2</small>
                                    </FormGroup>
                                </Col>
                                <Col sm>
                                    <FormGroup>
                                        <Label for="parentMaterialTechnicalDeliveryConditions2" size="sm">Teknisk leveransstandard</Label>
                                        {this.props.parentMaterial.enableBaseMaterial2Text ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-base-material-text"
                                                    name="applicationTechnicalDeliveryCondition2Text" id="applicationTechnicalDeliveryCondition2Text"
                                                    value={this.props.parentMaterial.technicalDeliveryCondition2Text}
                                                    onChange={(event) => this.onChangeParentTechnicalDeliveryCondition2Text(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input type="select" bsSize="sm" name="parentMaterialTechnicalDeliveryConditions2" id="parentMaterialTechnicalDeliveryConditions2"
                                                    value={this.props.parentMaterial.selectedTechnicalDeliveryCondition2.id}
                                                    onChange={(event) => this.onChangeParentMaterialTechnicalDeliveryConditions2(event)}
                                                    placeholder="">
                                                    <option id="technicalDeliveryConditionId02" value="0"></option>
                                                    {this.props.parentMaterial.selectedBaseMaterial2.technicalDeliveryConditions.map((technicalDeliveryCondition, index) => (
                                                        <option id={"technicalDeliveryConditionId" + technicalDeliveryCondition.id + "2"} value={technicalDeliveryCondition.id}>{technicalDeliveryCondition.name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Part')} 2</small>
                                    </FormGroup>
                                </Col>
                                <Col sm>
                                    <FormGroup>
                                        <Label for="parentMaterialMaterialThickness2" size="sm">{t('ProductThickness')} (mm)</Label>
                                        <Input type="text" bsSize="sm" name="parentMaterialMaterialThickness2" id="parentMaterialMaterialThickness2"
                                            value={this.props.parentMaterial.materialThickness2}
                                            onChange={(event) => this.onChangeParentMaterialMaterialThickness2(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        <small className="text-muted">{t('Part')} 2</small>
                                    </FormGroup>
                                </Col>
                                <Col sm>
                                    <FormGroup>
                                        <Label for="parentMaterialDiameter2" size="sm">{t('Diameter')} (mm)</Label>
                                        <Input type="text" bsSize="sm" name="parentMaterialDiameter2" id="parentMaterialDiameter2"
                                            value={this.props.parentMaterial.diameter2}
                                            onChange={(event) => this.onChangeParentMaterialDiameter2(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        <small className="text-muted">{t('Part')} 2</small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderPWPS() {
        return (
            <React.Fragment>
                <Form>
                    <Row>
                        <Col sm={12}>
                            {this.renderHead()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderParentMaterial()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderWeldingData()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderWeldingProcess()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {this.renderJointDesign()}
                        </Col>
                        <Col sm={6}>
                            {this.renderWeldSequence()}
                        </Col>
                    </Row>
                    <Row hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                        <Col sm={12}>
                            <FormelForHeatInput weldingPulseParameters={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.PulseWeldingParametersTable}
                                weldingArcParameters={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.ArcWeldingParametersTable} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <WParameters pWeldingMethodASearchText={this.props.weldingProcess.weldingMethodASearchText}
                                pWeldingMethodBSearchText={this.props.weldingProcess.weldingMethodBSearchText}
                                pWeldingMethods={this.props.weldingProcess.weldingMethods}
                                pWeldingParameters={this.props.weldingParameters}
                                currentTypes={this.props.currentTypes}
                                showWeldingPulseParameters={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.PulseWeldingParametersTable}
                                showWeldingBaseParameters={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.BaseWeldingParametersTable}
                                showWeldingArcParameters={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.ArcWeldingParametersTable}
                                showWeldingGwParameters={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}
                                componentPositionApplication={true}
                                setWParametersView={false}
                                enableSettingModal={true} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {this.renderTemperature()}
                        </Col>
                        <Col sm={6}>
                            {this.renderAfterWelding()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.renderOtherInformation()}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {this.renderManufacturer()}
                        </Col>
                        <Col sm={6}>
                            {this.renderExaminer()}
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderSelectEmployee() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={6}>
                            <h1>{t('ChooseWeldersToBeCertified')}</h1>
                        </Col>
                        <Col sm={6}>
                            <h1>{t('CompanyInformation')}</h1>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="searchWelderText" size="sm">{t('Search')}</Label>
                                <Input type="search" bssize="sm" name="searchWelderText" id="searchWelderText"
                                    value={this.props.welderSearchText}
                                    onChange={(event) => this.onChangeWelderSearchText(event)}
                                    onKeyPress={(event) => this.onKeyPress(event)}
                                    placeholder="" />
                                {this.props.welderSearchResult.length !== 0 &&
                                    <ListGroup className="scroll-vertical">
                                        {this.props.welderSearchResult.map((welder, index) => (
                                            <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectWelder(event, welder)}>
                                                {welder.firstName + ' ' + welder.lastName}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                }
                            </FormGroup>
                            <FormGroup>
                                <ListGroup>
                                    {this.props.selectedWelders.map((welder, index) => (
                                        <ListGroupItem>
                                            <ListGroupItemHeading>
                                                <Row>
                                                    <Col sm={10}>
                                                        {welder.firstName} {welder.lastName}
                                                    </Col>
                                                    <Col sm={2}>
                                                        <Button className="float-right" color="link" size="sm" name="removeWelder" id="removeWelder" onClick={(event) => this.onClickRemoveWelder(welder.id, event)}>
                                                            <i className="fas fa-trash"></i>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </ListGroupItemHeading>
                                            <ListGroupItemText>
                                                {welder.dateOfBirth} {welder.placeOfBirth}
                                            </ListGroupItemText>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label for="companyName" size="sm">{t('CompanyName')}</Label>
                                            <Input type="text" bssize="sm" name="companyName" id="companyName"
                                                value={this.props.company.name}
                                                onChange={(event) => this.onChangeCompanyName(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label for="companyStreetAddress" size="sm">{t('CompanyAddress')}</Label>
                                            <Input type="text" bssize="sm" name="companyStreetAddress" id="companyStreetAddress"
                                                value={this.props.company.streetAddress}
                                                onChange={(event) => this.onChangeCompanyStreetAddress(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label for="companyPostcode" size="sm">{t('Zipcode')}</Label>
                                            <Input type="text" bssize="sm" name="companyPostcode" id="companyPostcode"
                                                value={this.props.company.postcode}
                                                onChange={(event) => this.onChangeCompanyPostcode(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label for="companyCity" size="sm">{t('City')}</Label>
                                            <Input type="text" bssize="sm" name="companyCity" id="companyCity"
                                                value={this.props.company.city}
                                                onChange={(event) => this.onChangeCompanyCity(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label for="companyPhone" size="sm">{t('Telephone')}</Label>
                                            <Input type="tel" bssize="sm" name="companyPhone" id="companyPhone"
                                                value={this.props.company.phone}
                                                onChange={(event) => this.onChangeCompanyPhone(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label for="companyContactPerson" size="sm">{t('Facilitator')}</Label>
                                            <Input type="text" bssize="sm" name="companyContactPerson" id="companyContactPerson"
                                                value={this.props.company.contactPerson}
                                                onChange={(event) => this.onChangeCompanyContactPerson(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label for="companyOrdernumber" size="sm">{t('OrderNumber')}</Label>
                                            <Input type="text" bssize="sm" name="companyOrdernumber" id="companyOrdernumber" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label for="companyContactPersonEmail" size="sm">{t('FacilitatorEmail')}</Label>
                                            <Input type="email" bssize="sm" name="companyContactPersonEmail" id="companyContactPersonEmail"
                                                value={this.props.company.contactPersonEmail}
                                                onChange={(event) => this.onChangeCompanyContactPersonEmail(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderStandard() {
        return (
            <React.Fragment>
                <Form>
                    <Row form>
                        <Col sm={12}>
                            <h1>Standard</h1>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={3}>
                            <FormGroup>
                                <Card>
                                    <CardBody className="card-body-dekra">
                                        <CardTitle className="card-title-dekra">
                                            {t('ChoiceOfStandard')}
                                        </CardTitle>
                                        <div className="card-text-dekra">
                                            {this.props.standards.map((standard, index) => (
                                                <FormGroup check>
                                                    <Label check size="sm">
                                                        <Input type="radio" bssize="sm" id={"standard" + standard.id} name="standards"
                                                            value={standard.id}
                                                            checked={this.props.selectedStandard.id === standard.id}
                                                            onChange={(event) => this.onChangeSelectedStandard(event)}
                                                            placeholder="" />{' '} {standard.name}
                                                    </Label>
                                                    <span>&nbsp;</span>
                                                    <Button type="button" hidden={!standard.description} color="link" size="sm" id={"standardInfo" + standard.id} name={"standardInfo" + standard.id}>
                                                        <i className="fas fa-info-circle"></i>
                                                    </Button>
                                                    <UncontrolledPopover trigger="focus" placement="bottom" target={"standardInfo" + standard.id}>
                                                        <PopoverHeader>{standard.name}</PopoverHeader>
                                                        <PopoverBody>{standard.description}</PopoverBody>
                                                    </UncontrolledPopover>
                                                </FormGroup>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Card>
                                    <CardBody className="card-body-dekra">
                                        <CardTitle className="card-title-dekra">
                                            {t('AdditionalRequirements')}
                                        </CardTitle>
                                        <div className="card-text-dekra">
                                            {this.props.additionalRequirements.map((additionalRequirement, index) => (
                                                <FormGroup check>
                                                    <Label check size="sm">
                                                        <Input type="checkbox" bssize="sm" id={"additionalRequirement" + additionalRequirement.id} name={"additionalRequirement" + additionalRequirement.id}
                                                            checked={additionalRequirement.selected}
                                                            onChange={(event) => this.onChangeAdditionalRequirementSelected(additionalRequirement.id, event)}
                                                            placeholder="" />{' '} {additionalRequirement.name}
                                                    </Label>
                                                    <span>&nbsp;</span>
                                                    <Button type="button" hidden={!additionalRequirement.description} color="link" size="sm" id={"additionalRequirementInfo" + additionalRequirement.id} name={"additionalRequirementInfo" + additionalRequirement.id}>
                                                        <i className="fas fa-info-circle"></i>
                                                    </Button>
                                                    <UncontrolledPopover trigger="focus" placement="bottom" target={"additionalRequirementInfo" + additionalRequirement.id}>
                                                        <PopoverHeader>{additionalRequirement.name}</PopoverHeader>
                                                        <PopoverBody>{additionalRequirement.description}</PopoverBody>
                                                    </UncontrolledPopover>
                                                </FormGroup>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>     
                                <Card>
                                    <CardBody className="card-body-dekra">
                                        <CardTitle className="card-title-dekra">
                                            {t('OtherChoices')}
                                        </CardTitle>
                                        <div className="card-text-dekra">
                                            {this.props.otherOptions.map((otherOption, index) => (
                                                <FormGroup check>
                                                    <Label check size="sm">
                                                        <Input type="checkbox" bssize="sm" id={"otherOption" + otherOption.id} name={"otherOption" + otherOption.id}
                                                            checked={otherOption.selected}
                                                            onChange={(event) => this.onChangeOtherOptionSelected(otherOption.id, event)}
                                                            placeholder="" />{' '} {this.TranslateBoxes(otherOption.name, SweToEngOtherChoices)}
                                                    </Label>
                                                    <span>&nbsp;</span>
                                                    <Button type="button" hidden={!otherOption.description} color="link" size="sm" id={"otherOptionInfo" + otherOption.id} name={"otherOptionInfo" + otherOption.id}>
                                                        <i className="fas fa-info-circle"></i>
                                                    </Button>
                                                    <UncontrolledPopover trigger="focus" placement="bottom" target={"otherOptionInfo" + otherOption.id}>
                                                        <PopoverHeader>{this.TranslateBoxes(otherOption.name, SweToEngOtherChoices)}</PopoverHeader>
                                                        <PopoverBody>{otherOption.description}</PopoverBody>
                                                    </UncontrolledPopover>
                                                </FormGroup>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Card>
                                    <CardBody className="card-body-dekra">
                                        <CardTitle className="card-title-dekra">
                                            {t('WeldingModeRequests')}<span>&nbsp;</span><Button type="button" color="link" className="btn-link-color-dekra" size="sm" id="hej"><i className="fas fa-info-circle"></i></Button>
                                            <UncontrolledPopover trigger="focus" placement="bottom" target="hej">
                                                <PopoverHeader>{t('WeldingModeRequests')}</PopoverHeader>
                                                <PopoverBody>{t('ValidityIsApplied')}</PopoverBody>
                                            </UncontrolledPopover>
                                        </CardTitle>
                                        <div className="card-text-dekra">
                                            {this.props.numberOfWeldingPositions.map((numberOfWeldingPositions, index) => (
                                                <FormGroup check>
                                                    <Label check size="sm">
                                                        <Input type="radio" bssize="sm" id={"numberOfWeldingPositions" + numberOfWeldingPositions.id} name="numberOfWeldingPositions"
                                                            value={numberOfWeldingPositions.id}
                                                            checked={this.props.selectedNumberOfWeldingPositions.id === numberOfWeldingPositions.id}
                                                            onChange={(event) => this.onChangeSelectedNumberOfWeldingPositions(event)}
                                                            placeholder="" />{' '} {this.TranslateBoxes(numberOfWeldingPositions.name, SweToEngWeldingModeRequestsNames)}
                                                    </Label>
                                                    <span>&nbsp;</span>
                                                    <Button type="button" hidden={!numberOfWeldingPositions.description} color="link" size="sm" id={"numberOfWeldingPositionsInfo" + numberOfWeldingPositions.id} name={"numberOfWeldingPositionsInfo" + numberOfWeldingPositions.id}>
                                                        <i className="fas fa-info-circle"></i>
                                                    </Button>
                                                    <UncontrolledPopover trigger="focus" placement="bottom" target={"numberOfWeldingPositionsInfo" + numberOfWeldingPositions.id}>
                                                        <PopoverHeader>{this.TranslateBoxes(numberOfWeldingPositions.name, SweToEngWeldingModeRequestsNames)}</PopoverHeader>
                                                        <PopoverBody>{this.TranslateBoxes(numberOfWeldingPositions.description, SweToEngWeldingModeRequestsDescriptions)}</PopoverBody>
                                                    </UncontrolledPopover>
                                                </FormGroup>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderTemperature() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('Temperature')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="temperaturePreheatTemperature" size="sm">{t('MinPreHeatTemperature')} (°C)</Label>
                                        <Input type="text" bssize="sm" name="temperaturePreheatTemperature" id="temperaturePreheatTemperature"
                                            value={this.props.temperature.preheatTemperature}
                                            onChange={(event) => this.onChangeTemperaturePreheatTemperature(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="temperatureMaxInterpassTemperature" size="sm">{t('MaxInterpassTemperature')} (°C)</Label>
                                        <Input type="text" bssize="sm" name="temperatureMaxInterpassTemperature" id="temperatureMaxInterpassTemperature"
                                            value={this.props.temperature.maxInterpassTemperature}
                                            onChange={(event) => this.onChangeTemperatureMaxInterpassTemperature(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderWeldingData() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldingData')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="weldingDataJointPreparationAndCleaning" size="sm">{t('MethodForPreparationAndCleaning')}</Label>
                                        <InputGroup>
                                            <Input autoComplete="off" type="search" bssize="sm" name="weldingDataTungstenElectrode" id="weldingDataTungstenElectrode"
                                                value={this.props.weldingData.selectedJointPreparationText}
                                                onChange={(event) => this.onChangeWeldingDataSetJointPreparationText(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                                <DropdownToggle caret size="sm" className="btn btn-primary">
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {this.props.weldingData.jointPreparations.map((jointPreparation) => (
                                                        <DropdownItem key={"jointPreparationId" + jointPreparation.id} id={"jointPreparationId" + jointPreparation.id} onClick={(event) => this.onClickWeldingDataSetJointPreparationText(jointPreparation.description)}><a>{jointPreparation.description}</a></DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="weldingDataJointTypes" size="sm">{t('JointTypes')}</Label>
                                        <Input type="select" bsSize="sm" name="weldingDataJointTypes" id="weldingDataJointTypes"
                                            value={this.props.weldingData.selectedJointType.id}
                                            onChange={(event) => this.onChangeWeldingDataJointTypes(event)}
                                            placeholder="">
                                            <option id="jointTypeId0" value="0"></option>
                                            {this.props.weldingData.jointTypes.map((jointType, index) => (
                                                <option id={"jointTypeId" + jointType.id} value={jointType.id}>{jointType.description}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="weldingDataMaxWidthOfRun" size="sm">{t('Oscillation')} ({t('MaxWidthOfRun')}) (mm)</Label>
                                        <InputGroup>
                                            <Input type="text" bsSize="sm" name="oscillationMaxRunWidth" id="oscillationMaxRunWidth"
                                                value={this.props.weldingData.maxWidthOfRun} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingDataMaxWidthOfRun(event)}
                                                placeholder="" />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="weldingDataRootSupportDetails" size="sm">{t('RootSupportDetails')}</Label>
                                        <Input type="select" bsSize="sm" name="weldingDataRootSupportDetails" id="weldingDataRootSupportDetails"
                                            value={this.props.weldingData.selectedRootSupportDetails.id}
                                            onChange={(event) => this.onChangeWeldingDataRootSupportDetails(event)}
                                            placeholder="">
                                            <option id="rootSupportDetailId0" value="0"></option>
                                            {this.props.weldingData.rootSupportDetailsSearchResult.map((rootSupportDetails, index) => (
                                                <option key={"rootSupportDetailId" + rootSupportDetails.id} id={"rootSupportDetailId" + rootSupportDetails.id} value={rootSupportDetails.id}>{this.TranslateBoxes(rootSupportDetails.name, SweToEngRootSupport)} </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="weldingDataNozzleDiameter" size="sm">{this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t('NozzleSize') + " (l/min)" : t('NozzleDiameter') + " (mm) [MIG, MAG, TIG, 15X]"}</Label>
                                        <InputGroup >
                                            <Input type="text" bssize="sm" name="weldingDataNozzleDiameter" id="weldingDataNozzleDiameter"
                                                value={this.props.weldingData.nozzleDiameter}
                                                onChange={(event) => this.onChangeWeldingDataNozzleDiameter(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={28} dropDownListsCallback={this.props.setWeldingDataNozzleDiameter} rowNumber={0} ></DropdownLists>
                                        </InputGroup >
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    {this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ?
                                        <TypeOfBacking pTypeOfBackingText={this.props.weldingProcess.typeOfBackingText} enableTypeOfBackingTextView={false} /> :
                                        <FormGroup>
                                            <Label for="weldingDataStickOut" size="sm">{t('StickOutLengthMuzzleDistance')} (mm) (MIG, MAG, 15X)</Label>
                                            <InputGroup >
                                                <Input type="text" bssize="sm" name="weldingDataStickOut" id="weldingDataStickOut"
                                                    value={this.props.weldingData.stickOut}
                                                    onChange={(event) => this.onChangeWeldingDataStickOut(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                                <DropdownLists listNumber={28} dropDownListsCallback={this.props.setWeldingDataStickOut} rowNumber={0} ></DropdownLists>
                                            </InputGroup >
                                        </FormGroup>
                                    }
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 2}>
                                    <FormGroup>
                                        <Label for="weldingDataWeldingPosition" size="sm">{t('WeldingPosition')} 1</Label>
                                        <Input type="select" bsSize="sm" name="weldingDataWeldingPosition" id="weldingDataWeldingPosition"
                                            value={this.props.weldingData.selectedWeldingPosition.id}
                                            onChange={(event) => this.onChangeWeldingDataWeldingPositionSearchText(event)}
                                            placeholder="">
                                            <option id="weldingPositionId0" value="0"></option>
                                            {this.props.weldingData.weldingPositionSearchResult.map((weldingPosition) => (
                                                <option key={"weldingPositionId" + weldingPosition.id} id={"weldingPositionId" + weldingPosition.id} value={weldingPosition.id}>{weldingPosition.name} </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 6 : 2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataWeldingPosition2" size="sm">{t('WeldingPosition')} 2</Label>
                                        <Input type="select" bsSize="sm" name="weldingDataWeldingPosition2" id="weldingDataWeldingPosition2"
                                            value={this.props.weldingData.selectedWeldingPosition2.id}
                                            onChange={(event) => this.onChangeWeldingDataWeldingPosition2SearchText(event)}
                                            placeholder="">
                                            <option id="weldingPositionId0" value="0"></option>
                                            {this.props.weldingData.weldingPositionSearchResult.map((weldingPosition) => (
                                                <option key={"weldingPosition2Id" + weldingPosition.id} id={"weldingPosition2Id" + weldingPosition.id} value={weldingPosition.id}>{weldingPosition.name} </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataTungstenElectrode" size="sm">{t('TungstenElectrode')}, {t('TypeDimension')} (mm) [14X, 15X]</Label>
                                        <InputGroup>
                                            <Input type="text" bssize="sm" name="weldingDataTungstenElectrode" id="weldingDataTungstenElectrode"
                                                value={this.props.weldingData.tungstenElectrode}
                                                onChange={(event) => this.onChangeWeldingDataTungstenElectrode(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={22} dropDownListsCallback={this.props.setWeldingDataTungstenElectrode} rowNumber={0} ></DropdownLists>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataElectrodeTorchAngle111" size="sm">{t('ElectrodeTorchAngle111')}</Label>
                                        <Input type="text" bssize="sm" name="weldingDataElectrodeTorchAngle111" id="weldingDataElectrodeTorchAngle111"
                                            value={this.props.weldingData.electrodeTorchAngle111}
                                            onChange={(event) => this.onChangeWeldingDataElectrodeTorchAngle111(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataElectrodeTorchAngle" size="sm">{t('TorchAngle')} (MIG, MAG, {t('mechanical')})</Label>
                                        <Input type="text" bssize="sm" name="weldingDataElectrodeTorchAngle" id="weldingDataElectrodeTorchAngle"
                                            value={this.props.weldingData.electrodeTorchAngle}
                                            onChange={(event) => this.onChangeWeldingDataElectrodeTorchAngle(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingDataTackWelds" size="sm">{t('ProcessTackWelds')}</Label>
                                        <InputGroup>
                                            <Input type="text" bssize="sm" name="weldingDataTackWelds" id="weldingDataTackWelds"
                                                value={this.props.weldingData.tackWeldText}
                                                onChange={(event) => this.onChangeWeldingDataTackWeldSearchText(event)}
                                                onKeyPress={(event) => this.onKeyPress(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={27} dropDownListsCallback={this.props.setWeldingDataSelectedTackWeld} rowNumber={0} ></DropdownLists>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingDataLengthTackWelds" size="sm">{t('Length')} {t('TackWelds')} (mm)</Label>
                                        <Input type="text" bssize="sm" name="weldingDataLengthTackWelds" id="weldingDataLengthTackWelds"
                                            value={this.props.weldingData.lengthTackWelds}
                                            onChange={(event) => this.onChangeWeldingDataLengthTackWelds(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="weldingDataRootProtectionGases" size="sm">{t('RootProtectionGas')}</Label>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="weldingDataRootProtectionGases" id="weldingDataRootProtectionGases"
                                            value={this.props.weldingData.rootProtectionGasSearchText}
                                            onChange={(event) => this.onChangeWeldingDataRootProtectionGasSearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingData.rootProtectionGasSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingData.rootProtectionGasSearchResult.map((rootProtectionGas, index) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingDataSelectRootProtectionGas(event, rootProtectionGas)}>
                                                        {rootProtectionGas.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col sm={3} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <Gases enableGasesTextView={false} pSelectedPlasmaGas={this.props.weldingData.selectedPlasmaGas} setSelectedPlasmaGas={this.props.setWeldingDataSelectedPlasmaGas} />
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingDataPlasmaflowrate" size="sm">{t('Plasmaflowrate')} (l/min)</Label>
                                        <Input type="text" bssize="sm" name="weldingDataPlasmaflowrate" id="weldingDataPlasmaflowrate"
                                            value={this.props.weldingData.plasmaflowrate}
                                            onChange={(event) => this.onChangeWeldingDataPlasmaflowrate(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderWeldingProcess() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldingAdditives')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                < Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 3 : 2}>
                                    <FormGroup>
                                        <Label for="weldingProcessWeldingMethodA" size="sm">{t('WeldingMethod')}</Label>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="weldingProcessWeldingMethodA" id="weldingProcessWeldingMethodA"
                                            value={this.props.weldingProcess.weldingMethodASearchText}
                                            onChange={(event) => this.onChangeWeldingProcessWeldingMethodASearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingProcess.weldingMethodASearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingProcess.weldingMethodASearchResult.map((weldingMethod, index) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProcessSelectWeldingMethodA(event, weldingMethod)}>
                                                        {weldingMethod.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                        <small className="text-muted">{t('Index')} A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <FormGroup check>
                                            <Input type="checkbox" bssize="sm" name="applicationEnableMakeAText" id="applicationEnableMakeAText"
                                                checked={this.props.weldingProcess.enableMakeAText}
                                                onChange={(event) => this.onChangeEnableMakeAText(event)}
                                                placeholder="" />
                                            <UncontrolledTooltip placement="right" target={"applicationEnableMakeAText"} >
                                                Revidera eller ange eget val!
                                            </UncontrolledTooltip>
                                            <Label for="weldingProcessMakesA" size="sm">Tillsatsmaterial/pulver (handelsnamn)</Label>
                                        </FormGroup>
                                        {this.props.weldingProcess.enableMakeAText ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="applicationBaseMaterial1Text" id="applicationBaseMaterial1Text"
                                                    value={this.props.weldingProcess.makeAText}
                                                    onChange={(event) => this.onChangeMakeAText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input autoComplete="off" type="search" bssize="sm" name="weldingProcessMakesA" id="weldingProcessMakesA"
                                                    value={this.props.weldingProcess.makeASearchText}
                                                    onChange={(event) => this.onChangeWeldingProcessMakeASearchText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                                {this.props.weldingProcess.makeASearchResult.length !== 0 &&
                                                    <ListGroup className="scroll-vertical">
                                                        {this.props.weldingProcess.makeASearchResult.map((make, index) => (
                                                            <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProcessSelectMakeA(event, make)}>
                                                                {make.name}
                                                            </ListGroupItem>
                                                        ))}
                                                    </ListGroup>
                                                }
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Index')} A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm = {2}>
                                    <FormGroup>
                                        <Label for="weldingProcessDesignationA" size="sm">Beteckning</Label>
                                        {this.props.weldingProcess.enableMakeAText ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="applicationDesignationAText" id="applicationDesignationAText"
                                                    value={this.props.weldingProcess.designationAText}
                                                    onChange={(event) => this.onChangeDesignationAText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input type="text" readOnly bssize="sm" name="weldingProcessDesignationA" id="weldingProcessDesignationA"
                                                    value={this.props.weldingProcess.selectedDesignationA.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Index')} A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 2 : 1}>
                                    <FormGroup>
                                        <Label for="weldingProcessFmGroupA" size="sm">FM-Grupp</Label>
                                        {this.props.weldingProcess.enableMakeAText ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="applicationfmGroupAText" id="applicationfmGroupAText"
                                                    value={this.props.weldingProcess.fmGroupAText}
                                                    onChange={(event) => this.onChangefmGroupAText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input type="text" readOnly bssize="sm" name="weldingProcessFmGroupA" id="weldingProcessFmGroupA"
                                                    value={this.props.weldingProcess.selectedFMGroupA.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Index')} A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingProcessShieldingGasesA" size="sm">{this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t('BurnerGasType') : t('ShieldingGas') + ' (' + t('TradeName') + ')'}</Label>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="weldingProcessShieldingGasesA" id="weldingProcessShieldingGasesA"
                                            value={this.props.weldingProcess.shieldingGasASearchText}
                                            onChange={(event) => this.onChangeWeldingProcessShieldingGasASearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingProcess.shieldingGasASearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingProcess.shieldingGasASearchResult.map((shieldingGas, index) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProcessSelectShieldingGasA(event, shieldingGas)}>
                                                        {shieldingGas.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                        <small className="text-muted">{t('Index')} A</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingProcessArcTypesA" size="sm">{t('ArcType')} (MIG, MAG)</Label>
                                        <Input type="select" bsSize="sm" name="weldingProcessArcTypesA" id="weldingProcessArcTypesA"
                                            value={this.props.weldingProcess.selectedArcTypeA.id}
                                            onChange={(event) => this.onChangeWeldingProcessArcTypeASearchText(event)}
                                            placeholder="">
                                            <option key="arcTypeId0" id="arcTypeId0" value="0"></option>
                                            {this.props.weldingProcess.arcTypeSearchResult.map((arcType) => (
                                                <option key={"arcTypeId" + arcType.id} id={"arcTypeId" + arcType.id} value={arcType.id}>{this.TranslateBoxes(arcType.name, SweToEngArcType)} </option>
                                            ))}
                                        </Input>
                                        <small className="text-muted">{t('Index')} A</small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form hidden={this.props.otherOptions[1] && !this.props.otherOptions[1].selected}>
                                <Col sm={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 3 : 2}>
                                    <FormGroup>
                                        <Label for="weldingProcessWeldingMethodB" size="sm">{t('WeldingMethod')}</Label>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="weldingProcessWeldingMethodB" id="weldingProcessWeldingMethodB"
                                            value={this.props.weldingProcess.weldingMethodBSearchText}
                                            onChange={(event) => this.onChangeWeldingProcessWeldingMethodBSearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingProcess.weldingMethodBSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingProcess.weldingMethodBSearchResult.map((weldingMethod, index) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProcessSelectWeldingMethodB(event, weldingMethod)}>
                                                        {weldingMethod.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                        <small className="text-muted">{t('Index')} B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <FormGroup check>
                                            <Input type="checkbox" bssize="sm" name="applicationEnableMakeBText" id="applicationEnableMakeBText"
                                                checked={this.props.weldingProcess.enableMakeBText}
                                                onChange={(event) => this.onChangeEnableMakeBText(event)}
                                                placeholder="" />
                                            <UncontrolledTooltip placement="right" target={"applicationEnableMakeBText"} >
                                                Revidera eller ange eget val!
                                            </UncontrolledTooltip>
                                            <Label for="weldingProcessMakesB" size="sm">Tillsatsmaterial/pulver (handelsnamn)</Label>
                                        </FormGroup>
                                        {this.props.weldingProcess.enableMakeBText ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="applicationBaseMaterial1Text" id="applicationBaseMaterial1Text"
                                                    value={this.props.weldingProcess.makeBText}
                                                    onChange={(event) => this.onChangeMakeBText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input autoComplete="off" type="search" bssize="sm" name="weldingProcessMakesB" id="weldingProcessMakesB"
                                                    value={this.props.weldingProcess.makeBSearchText}
                                                    onChange={(event) => this.onChangeWeldingProcessMakeBSearchText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                                {this.props.weldingProcess.makeBSearchResult.length !== 0 &&
                                                    <ListGroup className="scroll-vertical">
                                                        {this.props.weldingProcess.makeBSearchResult.map((make, index) => (
                                                            <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProcessSelectMakeB(event, make)}>
                                                                {make.name}
                                                            </ListGroupItem>
                                                        ))}
                                                    </ListGroup>
                                                }
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Index')} B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingProcessDesignationB" size="sm">Beteckning</Label>
                                        {this.props.weldingProcess.enableMakeBText ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="applicationDesignationBText" id="applicationDesignationBText"
                                                    value={this.props.weldingProcess.designationBText}
                                                    onChange={(event) => this.onChangeDesignationBText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input type="text" readOnly bssize="sm" name="weldingProcessDesignationB" id="weldingProcessDesignationB"
                                                    value={this.props.weldingProcess.selectedDesignationB.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Index')} B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm = { this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? 2 : 1 }>
                                    <FormGroup>
                                        <Label for="weldingProcessFmGroupB" size="sm">FM-Grupp</Label>
                                        {this.props.weldingProcess.enableMakeBText ?
                                            <FormGroup>
                                                <Input type="text" bsSize="sm"
                                                    className="enable-make-text"
                                                    name="applicationfmGroupBText" id="applicationfmGroupBText"
                                                    value={this.props.weldingProcess.fmGroupBText}
                                                    onChange={(event) => this.onChangefmGroupBText(event)}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Input type="text" readOnly bssize="sm" name="weldingProcessFmGroupB" id="weldingProcessFmGroupB"
                                                    value={this.props.weldingProcess.selectedFMGroupB.name}
                                                    placeholder="" />
                                            </FormGroup>
                                        }
                                        <small className="text-muted">{t('Index')} B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label for="weldingProcessShieldingGasesB" size="sm">{this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable ? t('BurnerGasType') : t('ShieldingGas') + ' (' + t('TradeName') + ')'}</Label>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="weldingProcessShieldingGasesB" id="weldingProcessShieldingGasesB"
                                            value={this.props.weldingProcess.shieldingGasBSearchText}
                                            onChange={(event) => this.onChangeWeldingProcessShieldingGasBSearchText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        {this.props.weldingProcess.shieldingGasBSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical">
                                                {this.props.weldingProcess.shieldingGasBSearchResult.map((shieldingGas, index) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickWeldingProcessSelectShieldingGasB(event, shieldingGas)}>
                                                        {shieldingGas.name}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                        <small className="text-muted">{t('Index')} B</small>
                                    </FormGroup>
                                </Col>
                                <Col sm={2} hidden={this.props.header && this.props.header.otherOptionParametersTableId === OtherOptions.GwParametersTable}>
                                    <FormGroup>
                                        <Label for="weldingProcessArcTypesB" size="sm">{t('ArcType')} (MIG, MAG)</Label>
                                        <Input type="select" bsSize="sm" name="weldingProcessArcTypesB" id="weldingProcessArcTypesB"
                                            value={this.props.weldingProcess.selectedArcTypeB.id}
                                            onChange={(event) => this.onChangeWeldingProcessArcTypeBSearchText(event)}
                                            placeholder="">
                                            <option key="arcTypeBId0" id="arcTypeBId0" value="0"></option>
                                            {this.props.weldingProcess.arcTypeSearchResult.map((arcType) => (
                                                <option key={"arcTypeId" + arcType.id} id={"arcTypeId" + arcType.id} value={arcType.id}>{this.TranslateBoxes(arcType.name, SweToEngArcType)} </option>
                                            ))}
                                        </Input>
                                        <small className="text-muted">{t('Index')} B</small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private renderWeldSequence() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>{t('WeldSequence')}</h5>
                        </CardTitle>
                        <div>
                            <Row form>
                                <Col sm={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12}>
                                    <WeldSequence imageValidationError={this.props.header.imageValidationError2}
                                        imageUpload={this.props.header.imageUpload2}
                                        show={this.state.showWeldSequenceModal}
                                        toggleShow={this.toggleWeldSequenceModal}
                                        pSetWeldSequenceView={false}
                                        weldSequence={this.props.weldSequence} />
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={12} className="text-center">
                                    <FormGroup>
                                        <Button color="primary" onClick={(event) => this.onClickToggleWeldSequenceModal(event)}>{t('Draw')}</Button><span>&nbsp;</span>
                                        <Button id={"UploadInfo"} name={"UploadInfo"}  className="btn btn-primary btn-file" >
                                            {t('Upload')}<input type="file" onChange={event => this.handleChange(event)} />
                                        </Button>
                                        <Button type="button" color="link" size="sm" id={"UploadInfo"} name={"UploadInfo"} >
                                            <i className="fas fa-info-circle"></i>
                                        </Button>
                                        <span>&nbsp;</span>
                                        <UncontrolledPopover trigger="focus" placement="bottom" target={"UploadInfo"}>
                                            <PopoverHeader>{t('Upload')}</PopoverHeader>
                                            <PopoverBody>{t('UploadInfo')}</PopoverBody>
                                        </UncontrolledPopover>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private updateApplication(confirmed: boolean) {
        // Get the WelderId values.
        let welderIds: Array<number> = new Array<number>();
        for (let i = 0; i < this.props.selectedWelders.length; i++) {
            let welder = this.props.selectedWelders[i];
            welderIds.push(welder.id);
        }
        // Get the AdditionalRequirementId values.
        let additionalRequirementIds: Array<number> = new Array<number>();
        for (let i = 0; i < this.props.additionalRequirements.length; i++) {
            let additionalRequirement = this.props.additionalRequirements[i];
            if (additionalRequirement.selected) {
                additionalRequirementIds.push(additionalRequirement.id);
            }
        }
        // Get the OtherOptionId values. and set otherOptionParametersTableId
        let otherOptionIds: Array<number> = new Array<number>();
        for (let i = 0; i < this.props.otherOptions.length; i++) {
            let otherOption = this.props.otherOptions[i];
            if (otherOption.selected) {
                otherOptionIds.push(otherOption.id);
            }
        }
        // Update application
        let updateApplicationRequest: UpdateApplicationRequest = {
            id: this.props.applicationId,
            applicationStatus: ApplicationStatus.InProgress,
            welderIds: welderIds,
            companyId: 1,
            company: {
                id: 0,
                name: this.props.company.name,
                streetAddress: this.props.company.streetAddress,
                boxAddress: this.props.company.boxAddress,
                postcode: this.props.company.postcode,
                city: this.props.company.city,
                phone: this.props.company.phone,
                contactPerson: this.props.company.contactPerson,
                contactPersonEmail: this.props.company.contactPersonEmail,
                createdAt: this.props.company.createdAt,
                deletedAt: this.props.company.deletedAt,
                updatedAt: this.props.company.updatedAt
            },
            standardId: (this.props.selectedStandard.id !== 0) ? this.props.selectedStandard.id : undefined,
            additionalRequirementIds: additionalRequirementIds,
            otherOptionIds: otherOptionIds,
            numberOfWeldingPositionsId: (this.props.selectedNumberOfWeldingPositions.id !== 0) ? this.props.selectedNumberOfWeldingPositions.id : undefined,
            updatePWPSRequest: {
                id: 0,
                date: this.props.header.date,
                projectNumber: this.props.header.project,
                byStandardId: (this.props.header.selectedByStandard.id !== 0) ? this.props.header.selectedByStandard.id : undefined,
                standardText: '',
                enableStandardText: false,
                revisionNumber: this.props.header.revisionNumber,
                pwpsNumber: this.props.header.pwpsNumber,
                wpqrNumber: this.props.header.wpqrNumber,
                wpsType: '',
                wpsDekraNumber: '',
                wpsArcEnergyText: '',
                wpsHeatInputText: '',
                revisionWpsAction: '',
                customerId: 0,
                manufacturer: this.props.header.manufacturer,
                examiner: this.props.header.examiner,
                confirmedMessage: this.props.header.confirmedMessage,
                otherOptionParametersTableId: this.props.header.otherOptionParametersTableId,
                updateParentMaterialRequest: {
                    id: 0,
                    baseMaterialId1: (this.props.parentMaterial.selectedBaseMaterial1.id !== 0) ? this.props.parentMaterial.selectedBaseMaterial1.id : undefined,
                    materialThickness1: !isNaN(parseFloat(this.props.parentMaterial.materialThickness1.replace(',', '.'))) ? parseFloat(this.props.parentMaterial.materialThickness1.replace(',', '.')) : 0.0,
                    diameter1: HelpFunctions.getNumberOfString(this.props.parentMaterial.diameter1.replace(',', '.')),
                    baseMaterialId2: (this.props.parentMaterial.selectedBaseMaterial2.id !== 0) ? this.props.parentMaterial.selectedBaseMaterial2.id : undefined,
                    materialThickness2: !isNaN(parseFloat(this.props.parentMaterial.materialThickness2.replace(',', '.'))) ? parseFloat(this.props.parentMaterial.materialThickness2.replace(',', '.')) : 0.0,
                    diameter2: HelpFunctions.getNumberOfString(this.props.parentMaterial.diameter2.replace(',', '.')),
                    technicalDeliveryConditionId1: (this.props.parentMaterial.selectedTechnicalDeliveryCondition1.id !== 0) ? this.props.parentMaterial.selectedTechnicalDeliveryCondition1.id : undefined,
                    technicalDeliveryConditionId2: (this.props.parentMaterial.selectedTechnicalDeliveryCondition2.id !== 0) ? this.props.parentMaterial.selectedTechnicalDeliveryCondition2.id : undefined,
                    enableBaseMaterial1Text: this.props.parentMaterial.enableBaseMaterial1Text,
                    enableBaseMaterial2Text: this.props.parentMaterial.enableBaseMaterial2Text,
                    baseMaterial1Text: this.props.parentMaterial.baseMaterial1Text,
                    baseMaterial2Text: this.props.parentMaterial.baseMaterial2Text,
                    materialGroup1Text: this.props.parentMaterial.materialGroup1Text,
                    materialGroup2Text: this.props.parentMaterial.materialGroup2Text,
                    technicalDeliveryCondition1Text: this.props.parentMaterial.technicalDeliveryCondition1Text,
                    technicalDeliveryCondition2Text: this.props.parentMaterial.technicalDeliveryCondition2Text,
                    enableMaterialThickness: this.props.parentMaterial.enableMaterialThickness,
                    materialThickness1Text: this.props.parentMaterial.materialThickness1,
                    materialThickness2Text: this.props.parentMaterial.materialThickness2,
                    enableDiameter: this.props.parentMaterial.enableDiameter,
                    diameter1Text: this.props.parentMaterial.diameter1,
                    diameter2Text: this.props.parentMaterial.diameter2,
                },
                updateWeldingDataRequest: {
                    id: 0,
                    jointTypeId: (this.props.weldingData.selectedJointType.id !== 0) ? this.props.weldingData.selectedJointType.id : undefined,
                    enableJointTypeText: this.props.weldingData.enableJointTypeText,
                    jointTypeText: this.props.weldingData.jointTypeText,
                    rootSupportDetailsId: (this.props.weldingData.selectedRootSupportDetails.id !== 0) ? this.props.weldingData.selectedRootSupportDetails.id : undefined,
                    tackWeldText: this.props.weldingData.tackWeldText,
                    weldingPositionId: (this.props.weldingData.selectedWeldingPosition.id !== 0) ? this.props.weldingData.selectedWeldingPosition.id : undefined,
                    weldingPosition2Id: (this.props.weldingData.selectedWeldingPosition2.id !== 0) ? this.props.weldingData.selectedWeldingPosition2.id : undefined,
                    rootProtectionGasId: (this.props.weldingData.selectedRootProtectionGas.id !== 0) ? this.props.weldingData.selectedRootProtectionGas.id : undefined,
                    nozzleDiameter: this.props.weldingData.nozzleDiameter,
                    stickOut: this.props.weldingData.stickOut,
                    lengthTackWelds: this.props.weldingData.lengthTackWelds,
                    electrodeTorchAngle111: this.props.weldingData.electrodeTorchAngle111,
                    jointPreparationSearchText: this.props.weldingData.selectedJointPreparationText,
                    tungstenElectrode: this.props.weldingData.tungstenElectrode,
                    electrodeTorchAngle: this.props.weldingData.electrodeTorchAngle,
                    maxWidthOfRun: this.props.weldingData.maxWidthOfRun,
                    plasmaflowrate: this.props.weldingData.plasmaflowrate,
                    plasmaGasId: (this.props.weldingData.selectedPlasmaGas.id !== 0) ? this.props.weldingData.selectedPlasmaGas.id : undefined,
                },
                updateWeldingProcessRequest: {
                    id: 0,
                    weldingMethodId1: (this.props.weldingProcess.selectedWeldingMethodA.id !== 0) ? this.props.weldingProcess.selectedWeldingMethodA.id : undefined,
                    makeId1: (this.props.weldingProcess.selectedMakeA.id !== 0) ? this.props.weldingProcess.selectedMakeA.id : undefined,
                    shieldingGasId1: (this.props.weldingProcess.selectedShieldingGasA.id !== 0) ? this.props.weldingProcess.selectedShieldingGasA.id : undefined,
                    arcTypeId1: (this.props.weldingProcess.selectedArcTypeA.id !== 0) ? this.props.weldingProcess.selectedArcTypeA.id : undefined,
                    weldingMethodId2: (this.props.weldingProcess.selectedWeldingMethodB.id !== 0) ? this.props.weldingProcess.selectedWeldingMethodB.id : undefined,
                    makeId2: (this.props.weldingProcess.selectedMakeB.id !== 0) ? this.props.weldingProcess.selectedMakeB.id : undefined,
                    shieldingGasId2: (this.props.weldingProcess.selectedShieldingGasB.id !== 0) ? this.props.weldingProcess.selectedShieldingGasB.id : undefined,
                    arcTypeId2: (this.props.weldingProcess.selectedArcTypeB.id !== 0) ? this.props.weldingProcess.selectedArcTypeB.id : undefined,
                    enableMakeAText: this.props.weldingProcess.enableMakeAText,
                    makeAText: this.props.weldingProcess.makeAText,
                    designationAText: this.props.weldingProcess.designationAText,
                    fmGroupAText: this.props.weldingProcess.fmGroupAText,
                    enableMakeBText: this.props.weldingProcess.enableMakeBText,
                    makeBText: this.props.weldingProcess.makeBText,
                    designationBText: this.props.weldingProcess.designationBText,
                    fmGroupBText: this.props.weldingProcess.fmGroupBText,
                    typeOfBackingText: '' // It's set in ApplicationTabStore
                },
                updateJointDesignRequest: {
                    id: 0,
                    materialThickness1: 0.0,
                    materialThickness2: 0.0,
                    jointTypeId: undefined,
                    jointShapeId: undefined,
                    jointAngleAlpha: 0.0,
                    gapOpening: 0.0,
                    straightEdge: 0.0,
                    phaseAngle1: 0.0,
                    phaseAngle2: 0.0,
                    jointAngleBeta: 0.0,
                    throatThickness: 0.0,
                    rootSupportId: undefined,
                    depthOfPreparation: 0.0,
                    radius: 0.0,
                    note: '',
                    jointDesignHtmlImage: ''
                },
                updateWeldSequenceRequest: {
                    imageId: 0,
                    imageData: '',
                    sequenceNumber1: '',
                    sequenceNumber2: '',
                    sequenceNumber3: '',
                    sequenceNumber4: '',
                    sequenceNumber5: '',
                    sequenceNumber6: '',
                    sequenceNumber7: '',
                    sequenceNumber8: '',
                    sequenceNumber9: '',
                    sequenceNumber10: '',
                    sequenceNumber11: '',
                    sequenceNumber12: '',
                    sequenceNumber13: '',
                    sequenceNumber14: '',
                    sequenceNumber15: ''
                    
                },
                updateWeldingParametersRequests: [],
                updateFilesUploadRequest: [],
                updateTemperatureRequest: {
                    id: 0,
                    preheatTemperature: !isNaN(parseFloat(this.props.temperature.preheatTemperature.replace(',', '.'))) ? parseFloat(this.props.temperature.preheatTemperature.replace(',', '.')) : 0.0,
                    maxInterpassTemperature: !isNaN(parseFloat(this.props.temperature.maxInterpassTemperature.replace(',', '.'))) ? parseFloat(this.props.temperature.maxInterpassTemperature.replace(',', '.')) : 0.0
                },
                updateAfterWeldingRequest: {
                    id: 0,
                    heatingRate: this.props.afterWelding.heatingRate,
                    coolingRate: this.props.afterWelding.coolingRate
                },
                otherInformation: this.props.otherInformation.otherInformation
            },
            testingOrderRequest: {
                additionalTesting: this.props.qwbpOrder.additionalTesting,
                bendTesting: this.props.qwbpOrder.bendTesting,
                chargeNumber1: this.props.qwbpOrder.chargeNumber1,
                chargeNumber2: this.props.qwbpOrder.chargeNumber2,
                conditionsAndDescriptionRemarks: this.props.qwbpOrder.conditionsAndDescriptionRemarks,
                contactPerson: this.props.qwbpOrder.contactPerson,
                customer: this.props.qwbpOrder.customer,
                date: this.props.qwbpOrder.date,
                destructiveTestingApproved: this.props.qwbpOrder.destructiveTestingApproved,
                destructiveTestingDate: this.props.qwbpOrder.destructiveTestingDate,
                destructiveTestingSignature: this.props.qwbpOrder.destructiveTestingSignature,
                dimension1: this.props.qwbpOrder.dimension1,
                dimension2: this.props.qwbpOrder.dimension2,
                directionOfPlateRolling1: this.props.qwbpOrder.directionOfPlateRolling1,
                directionOfPlateRolling2: this.props.qwbpOrder.directionOfPlateRolling2,
                fillerMaterial: this.props.qwbpOrder.fillerMaterial,
                hardnessTesting: this.props.qwbpOrder.hardnessTesting,
                impactTesting: this.props.qwbpOrder.impactTesting,
                impactTestTemperature1: this.props.qwbpOrder.impactTestTemperature1,
                impactTestTemperature2: this.props.qwbpOrder.impactTestTemperature2,
                iso10902: this.props.qwbpOrder.iso10902,
                iso10902AdditionalTesting: this.props.qwbpOrder.iso10902AdditionalTesting,
                iso10902CruciformTensileTesting: this.props.qwbpOrder.iso10902CruciformTensileTesting,
                iso10902ImpactTesting: this.props.qwbpOrder.iso10902ImpactTesting,
                iso10902MacroPlusPhoto: this.props.qwbpOrder.iso10902MacroPlusPhoto,
                iso10902MicroTesting: this.props.qwbpOrder.iso10902MicroTesting,
                iso10902WeldabilityPrimer: this.props.qwbpOrder.iso10902WeldabilityPrimer,
                iso10902AdditionalTestingText: this.props.qwbpOrder.iso10902AdditionalTestingText,
                iso10902CruciformTensileTestingText: this.props.qwbpOrder.iso10902CruciformTensileTestingText,
                iso10902ImpactTestingText: this.props.qwbpOrder.iso10902ImpactTestingText,
                iso10902MacroPlusPhotoText: this.props.qwbpOrder.iso10902MacroPlusPhotoText,
                iso10902MicroTestingText: this.props.qwbpOrder.iso10902MicroTestingText,
                iso10902WeldabilityPrimerText: this.props.qwbpOrder.iso10902WeldabilityPrimerText,
                iso129526AdditionalTesting: this.props.qwbpOrder.iso129526AdditionalTesting,
                iso129526ImpactTesting: this.props.qwbpOrder.iso129526ImpactTesting,
                iso129526LongitudinalTensileTesting: this.props.qwbpOrder.iso129526LongitudinalTensileTesting,
                iso129526AdditionalTestingText: this.props.qwbpOrder.iso129526AdditionalTestingText,
                iso129526ImpactTestingText: this.props.qwbpOrder.iso129526ImpactTestingText,
                iso129526LongitudinalTensileTestingText: this.props.qwbpOrder.iso129526LongitudinalTensileTestingText,
                iso129526Testing: this.props.qwbpOrder.iso129526Testing,
                iso13134AdditionalTesting: this.props.qwbpOrder.iso13134AdditionalTesting,
                iso13134BendTesting: this.props.qwbpOrder.iso13134BendTesting,
                iso13134HardnessTesting: this.props.qwbpOrder.iso13134HardnessTesting,
                iso13134PeelTesting: this.props.qwbpOrder.iso13134PeelTesting,
                iso13134ShearTesting: this.props.qwbpOrder.iso13134ShearTesting,
                iso13134TensileTesting: this.props.qwbpOrder.iso13134TensileTesting,
                iso13134BrazinProcedureApproval: this.props.qwbpOrder.iso13134BrazinProcedureApproval,
                iso13134AdditionalTestingText: this.props.qwbpOrder.iso13134AdditionalTestingText,
                iso13134BendTestingText: this.props.qwbpOrder.iso13134BendTestingText,
                iso13134HardnessTestingText: this.props.qwbpOrder.iso13134HardnessTestingText,
                iso13134PeelTestingText: this.props.qwbpOrder.iso13134PeelTestingText,
                iso13134ShearTestingText: this.props.qwbpOrder.iso13134ShearTestingText,
                iso13134TensileTestingText: this.props.qwbpOrder.iso13134TensileTestingText,
                iso134454Testing: this.props.qwbpOrder.iso134454Testing,
                iso134454AdditionalTesting: this.props.qwbpOrder.iso134454AdditionalTesting,
                iso134454FerriteTesting: this.props.qwbpOrder.iso134454FerriteTesting,
                iso134454ImpactTesting: this.props.qwbpOrder.iso134454ImpactTesting,
                iso134454LongitudinalTensileTesting: this.props.qwbpOrder.iso134454LongitudinalTensileTesting,
                iso134454MicroTesting: this.props.qwbpOrder.iso134454MicroTesting,
                iso134454AdditionalTestingText: this.props.qwbpOrder.iso134454AdditionalTestingText,
                iso134454FerriteTestingText: this.props.qwbpOrder.iso134454FerriteTestingText,
                iso134454ImpactTestingText: this.props.qwbpOrder.iso134454ImpactTestingText,
                iso134454LongitudinalTensileTestingText: this.props.qwbpOrder.iso134454LongitudinalTensileTestingText,
                iso134454MicroTestingText: this.props.qwbpOrder.iso134454MicroTestingText,
                iso14555AdditionalTesting: this.props.qwbpOrder.iso14555AdditionalTesting,
                iso14555ApplicationGreaterThan100Degrees: this.props.qwbpOrder.iso14555ApplicationGreaterThan100Degrees,
                iso14555ApplicationLessThanOrEqualTo100Degrees: this.props.qwbpOrder.iso14555ApplicationLessThanOrEqualTo100Degrees,
                iso14555BendTesting: this.props.qwbpOrder.iso14555BendTesting,
                iso14555ArcStudWelding: this.props.qwbpOrder.iso14555ArcStudWelding,
                iso14555_3834Testing: this.props.qwbpOrder.iso14555_3834Testing,
                iso14555_783Testing: this.props.qwbpOrder.iso14555_783Testing,
                iso14555_784Testing: this.props.qwbpOrder.iso14555_784Testing,
                iso14555_785Testing: this.props.qwbpOrder.iso14555_785Testing,
                iso14555_786Testing: this.props.qwbpOrder.iso14555_786Testing,
                iso14555_787Testing: this.props.qwbpOrder.iso14555_787Testing,
                iso14555BendTestingTorqueWrench: this.props.qwbpOrder.iso14555BendTestingTorqueWrench,
                iso14555MacroPlusPhoto: this.props.qwbpOrder.iso14555MacroPlusPhoto,
                iso14555TensileTesting: this.props.qwbpOrder.iso14555TensileTesting,
                iso14555TorqueTesting: this.props.qwbpOrder.iso14555TorqueTesting,
                iso156141Edition2004: this.props.qwbpOrder.iso156141Edition2004,
                iso14555RTProvning: this.props.qwbpOrder.iso14555RTProvning,
                iso14555AdditionalTestingText: this.props.qwbpOrder.iso14555AdditionalTestingText,
                iso14555ApplicationGreaterThan100DegreesText: this.props.qwbpOrder.iso14555ApplicationGreaterThan100DegreesText,
                iso14555ApplicationLessThanOrEqualTo100DegreesText: this.props.qwbpOrder.iso14555ApplicationLessThanOrEqualTo100DegreesText,
                iso14555BendTestingText: this.props.qwbpOrder.iso14555BendTestingText,
                iso14555BendTestingTorqueWrenchText: this.props.qwbpOrder.iso14555BendTestingTorqueWrenchText,
                iso14555MacroPlusPhotoText: this.props.qwbpOrder.iso14555MacroPlusPhotoText,
                iso14555TensileTestingText: this.props.qwbpOrder.iso14555TensileTestingText,
                iso14555TorqueTestingText: this.props.qwbpOrder.iso14555TorqueTestingText,
                iso14555RTProvningText: this.props.qwbpOrder.iso14555RTProvningText,
                iso14555_3834TestingText: this.props.qwbpOrder.iso14555_3834TestingText,
                iso156141Edition2017: this.props.qwbpOrder.iso156141Edition2017,
                macroPlusPhoto: this.props.qwbpOrder.macroPlusPhoto,
                manufacturerWPQRBPARNumber: this.props.qwbpOrder.manufacturerWPQRBPARNumber,
                material1: this.props.qwbpOrder.material1,
                material2: this.props.qwbpOrder.material2,
                materialGroup1: this.props.qwbpOrder.materialGroup1,
                materialGroup2: this.props.qwbpOrder.materialGroup2,
                materialSpecifications1Remarks: this.props.qwbpOrder.materialSpecifications1Remarks,
                materialSpecifications2Remarks: this.props.qwbpOrder.materialSpecifications2Remarks,
                mtPT: this.props.qwbpOrder.mtPT,
                ndtApproved: this.props.qwbpOrder.ndtApproved,
                ndtDate: this.props.qwbpOrder.ndtDate,
                ndtRemarks: this.props.qwbpOrder.ndtRemarks,
                ndtSignature: this.props.qwbpOrder.ndtSignature,
                ndtOther: this.props.qwbpOrder.ndtOther,
                norsokAdditionalTesting: this.props.qwbpOrder.norsokAdditionalTesting,
                norsokAdditionalTesting1: this.props.qwbpOrder.norsokAdditionalTesting1,
                norsokAdditionalTesting2: this.props.qwbpOrder.norsokAdditionalTesting2,
                norsokAdditionalTesting3: this.props.qwbpOrder.norsokAdditionalTesting3,
                norsokAdditionalTesting4: this.props.qwbpOrder.norsokAdditionalTesting4,
                norsokAdditionalTestingText: this.props.qwbpOrder.norsokAdditionalTestingText,
                norsokAdditionalTesting1Text: this.props.qwbpOrder.norsokAdditionalTesting1Text,
                norsokAdditionalTesting2Text: this.props.qwbpOrder.norsokAdditionalTesting2Text,
                norsokAdditionalTesting3Text: this.props.qwbpOrder.norsokAdditionalTesting3Text,
                norsokAdditionalTesting4Text: this.props.qwbpOrder.norsokAdditionalTesting4Text,
                norsokCorrosionTestingText: this.props.qwbpOrder.norsokCorrosionTestingText,
                norsokCorrosionTesting: this.props.qwbpOrder.norsokCorrosionTesting,
                norsok: this.props.qwbpOrder.norsok,
                phoneNumber: this.props.qwbpOrder.phoneNumber,
                pWPSPBPS: this.props.qwbpOrder.pWPSPBPS,
                orderedByLevel3: this.props.qwbpOrder.orderedByLevel3,
                other: this.props.qwbpOrder.other,
                rt: this.props.qwbpOrder.rt,
                signature: this.props.qwbpOrder.signature,
                standardForAcceptance1: this.props.qwbpOrder.standardForAcceptance1,
                standardForAcceptance2: this.props.qwbpOrder.standardForAcceptance2,
                transverseTensilePlusRp0ReH: this.props.qwbpOrder.transverseTensilePlusRp0ReH,
                ut: this.props.qwbpOrder.ut,
                visual: this.props.qwbpOrder.visual,
                weldingDate: this.props.qwbpOrder.weldingDate,
                weldingBrazingPositions: this.props.qwbpOrder.weldingBrazingPositions,
                weldingProcess: this.props.qwbpOrder.weldingProcess,
                welderBrazerFirstName: this.props.qwbpOrder.welderBrazerFirstName,
                welderBrazerLastName: this.props.qwbpOrder.welderBrazerLastName,
                iso15614transverseTensilePlusRp0ReHText: this.props.qwbpOrder.iso15614transverseTensilePlusRp0ReHText,
                iso15614macroPlusPhotoText: this.props.qwbpOrder.iso15614macroPlusPhotoText,
                iso15614hardnessTestingText: this.props.qwbpOrder.iso15614hardnessTestingText,
                iso15614impactTestingText: this.props.qwbpOrder.iso15614impactTestingText,
                iso15614bendTestingText: this.props.qwbpOrder.iso15614bendTestingText,
                iso15614additionalTestingText: this.props.qwbpOrder.iso15614additionalTestingText,
                pedAdditionalTesting: this.props.qwbpOrder.pedAdditionalTesting,
                iso15614TestingText: this.props.qwbpOrder.iso15614TestingText,
                dekraProcedureNoTesting: this.props.qwbpOrder.dekraProcedureNoTesting,
                invoiceReferenceName: this.props.qwbpOrder.invoiceReferenceName,
                uppdragsnr: this.props.qwbpOrder.uppdragsnr,
                testmarking: this.props.qwbpOrder.testmarking,
                nace: this.props.qwbpOrder.nace,
                naceMr0103Testing: this.props.qwbpOrder.naceMr0103Testing,
                naceMr0103TestingText: this.props.qwbpOrder.naceMr0103TestingText,
                naceMr0175Testing: this.props.qwbpOrder.naceMr0175Testing,
                naceMr0175TestingText: this.props.qwbpOrder.naceMr0175TestingText,
                naceAdditionalTesting1: this.props.qwbpOrder.naceAdditionalTesting1,
                naceAdditionalTesting1Text: this.props.qwbpOrder.naceAdditionalTesting1Text,
                naceAdditionalTesting2: this.props.qwbpOrder.naceAdditionalTesting2,
                naceAdditionalTesting2Text: this.props.qwbpOrder.naceAdditionalTesting2Text,
            }
        };
        this.props.updateApplication(updateApplicationRequest, confirmed);
    }
}

export default connect(
    (state: ApplicationState) => state.applicationTab, // Selects which state properties are merged into the component's props
    ApplicationTabStore.actionCreators // Selects which action creators are merged into the component's props
)(ApplicationTab as any);