﻿import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { QWBPOrder } from './models/QWBPOrder';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TestingOrderState {
    commissionNumber: string;
    dekraProcedureNumber: string;
    manufacturerWPQRNumber: string;
    qwbpOrder: QWBPOrder;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetCommissionNumberAction {
    type: 'SET_COMMISSION_NUMBER';
    commissionNumber: string;
}

export interface SetDekraProcedureNumberAction {
    type: 'SET_DEKRA_PROCEDURE_NUMBER';
    dekraProcedureNumber: string;
}

export interface SetManufacturerWPQRNumberAction {
    type: 'SET_MANUFACTURER_WPQR_NUMBER';
    manufacturerWPQRNumber: string;
}

export interface SetQWBPOrderAction {
    type: 'SET_QWBP_ORDER';
    qwbpOrder: QWBPOrder;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

export type KnownAction = SetCommissionNumberAction
    | SetDekraProcedureNumberAction
    | SetManufacturerWPQRNumberAction
    | SetQWBPOrderAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setCommissionNumber: (commissionNumber: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_COMMISSION_NUMBER', commissionNumber: commissionNumber });
    },
    setDekraProcedureNumber: (dekraProcedureNumber: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_DEKRA_PROCEDURE_NUMBER', dekraProcedureNumber: dekraProcedureNumber });
    },
    setManufacturerWPQRNumber: (manufacturerWPQRNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_MANUFACTURER_WPQR_NUMBER', manufacturerWPQRNumber: manufacturerWPQRNumber });
    },
    setTestingOrder: (qwbpOrderTemp: QWBPOrder): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder = qwbpOrderTemp;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderChargeNumber1: (chargeNumber1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.chargeNumber1 = chargeNumber1;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderChargeNumber2: (chargeNumber2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.chargeNumber2 = chargeNumber2;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderConditionsAndDescriptionRemarks: (conditionsAndDescriptionRemarks: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.conditionsAndDescriptionRemarks = conditionsAndDescriptionRemarks;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderContactPerson: (contactPerson: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.contactPerson = contactPerson;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderCustomer: (customer: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.customer = customer;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderDate: (date: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.date = date;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderDestructiveTestingApproved: (destructiveTestingApproved: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.destructiveTestingApproved = destructiveTestingApproved;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderDestructiveTestingDate: (destructiveTestingDate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.destructiveTestingDate = destructiveTestingDate;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderDestructiveTestingSignature: (destructiveTestingSignature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.destructiveTestingSignature = destructiveTestingSignature;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderDimension1: (thickness1: string, dimension1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            //qwbpOrder.dimension1 = dimension1;
            qwbpOrder.dimension1 = thickness1 + "/" + dimension1;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderDimension2: (dimension2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.dimension2 = dimension2;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderDirectionOfPlateRolling1: (directionOfPlateRolling1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.directionOfPlateRolling1 = directionOfPlateRolling1;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderDirectionOfPlateRolling2: (directionOfPlateRolling2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.directionOfPlateRolling2 = directionOfPlateRolling2;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderImpactTestTemperature1: (impactTestTemperature1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.impactTestTemperature1 = impactTestTemperature1;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderImpactTestTemperature2: (impactTestTemperature2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.impactTestTemperature2 = impactTestTemperature2;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902: (iso10902: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902 = iso10902;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902AdditionalTesting: (iso10902AdditionalTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902AdditionalTesting = iso10902AdditionalTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902CruciformTensileTesting: (iso10902CruciformTensileTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902CruciformTensileTesting = iso10902CruciformTensileTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902ImpactTesting: (iso10902ImpactTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902ImpactTesting = iso10902ImpactTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902MacroPlusPhoto: (iso10902MacroPlusPhoto: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902MacroPlusPhoto = iso10902MacroPlusPhoto;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902MicroTesting: (iso10902MicroTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902MicroTesting = iso10902MicroTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902WeldabilityPrimer: (iso10902WeldabilityPrimer: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902WeldabilityPrimer = iso10902WeldabilityPrimer;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },

    setQWBPOrderISO10902AdditionalTestingText: (iso10902AdditionalTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902AdditionalTestingText = iso10902AdditionalTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902CruciformTensileTestingText: (iso10902CruciformTensileTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902CruciformTensileTestingText = iso10902CruciformTensileTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902ImpactTestingText: (iso10902ImpactTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902ImpactTestingText = iso10902ImpactTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902MacroPlusPhotoText: (iso10902MacroPlusPhotoText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902MacroPlusPhotoText = iso10902MacroPlusPhotoText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902MicroTestingText: (iso10902MicroTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902MicroTestingText = iso10902MicroTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO10902WeldabilityPrimerText: (iso10902WeldabilityPrimerText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso10902WeldabilityPrimerText = iso10902WeldabilityPrimerText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },

    setQWBPOrderISO129526AdditionalTesting: (iso129526AdditionalTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso129526AdditionalTesting = iso129526AdditionalTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO129526ImpactTesting: (iso129526ImpactTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso129526ImpactTesting = iso129526ImpactTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO129526LongitudinalTensileTesting: (iso129526LongitudinalTensileTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso129526LongitudinalTensileTesting = iso129526LongitudinalTensileTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO129526Testing: (iso129526Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso129526Testing = iso129526Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO129526AdditionalTestingText: (iso129526AdditionalTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso129526AdditionalTestingText = iso129526AdditionalTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO129526ImpactTestingText: (iso129526ImpactTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso129526ImpactTestingText = iso129526ImpactTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO129526LongitudinalTensileTestingText: (iso129526LongitudinalTensileTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso129526LongitudinalTensileTestingText = iso129526LongitudinalTensileTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134AdditionalTesting: (iso13134AdditionalTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134AdditionalTesting = iso13134AdditionalTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134AdditionalTestingText: (iso13134AdditionalTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134AdditionalTestingText = iso13134AdditionalTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134BendTesting: (iso13134BendTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134BendTesting = iso13134BendTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134BendTestingText: (iso13134BendTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134BendTestingText = iso13134BendTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134HardnessTesting: (iso13134HardnessTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134HardnessTesting = iso13134HardnessTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134HardnessTestingText: (iso13134HardnessTesting: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134HardnessTestingText = iso13134HardnessTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134PeelTesting: (iso13134PeelTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134PeelTesting = iso13134PeelTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134PeelTestingText: (iso13134PeelTesting: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134PeelTestingText = iso13134PeelTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134ShearTesting: (iso13134ShearTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134ShearTesting = iso13134ShearTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134ShearTestingText: (iso13134ShearTesting: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134ShearTestingText = iso13134ShearTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134TensileTesting: (iso13134TensileTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134TensileTesting = iso13134TensileTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134BrazinProcedureApproval: (iso13134BrazinProcedureApproval: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134BrazinProcedureApproval = iso13134BrazinProcedureApproval;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO13134TensileTestingText: (iso13134TensileTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso13134TensileTestingText = iso13134TensileTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454Testing: (iso134454Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454Testing = iso134454Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454AdditionalTesting: (iso134454AdditionalTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454AdditionalTesting = iso134454AdditionalTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454FerriteTesting: (iso134454FerriteTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454FerriteTesting = iso134454FerriteTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454ImpactTesting: (iso134454ImpactTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454ImpactTesting = iso134454ImpactTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454LongitudinalTensileTesting: (iso134454LongitudinalTensileTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454LongitudinalTensileTesting = iso134454LongitudinalTensileTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454MicroTesting: (iso134454MicroTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454MicroTesting = iso134454MicroTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },

    setQWBPOrderISO134454AdditionalTestingText: (iso134454AdditionalTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454AdditionalTestingText = iso134454AdditionalTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454FerriteTestingText: (iso134454FerriteTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454FerriteTestingText = iso134454FerriteTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454ImpactTestingText: (iso134454ImpactTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454ImpactTestingText = iso134454ImpactTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454LongitudinalTensileTestingText: (iso134454LongitudinalTensileTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454LongitudinalTensileTestingText = iso134454LongitudinalTensileTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO134454MicroTestingText: (iso134454MicroTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso134454MicroTestingText = iso134454MicroTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },


    setQWBPOrderISO14555AdditionalTesting: (iso14555AdditionalTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555AdditionalTesting = iso14555AdditionalTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555ApplicationGreaterThan100Degrees: (iso14555ApplicationGreaterThan100Degrees: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555ApplicationGreaterThan100Degrees = iso14555ApplicationGreaterThan100Degrees;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555ApplicationLessThanOrEqualTo100Degrees: (iso14555ApplicationLessThanOrEqualTo100Degrees: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555ApplicationLessThanOrEqualTo100Degrees = iso14555ApplicationLessThanOrEqualTo100Degrees;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555BendTesting: (iso14555BendTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555BendTesting = iso14555BendTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555ArcStudWelding: (iso14555ArcStudWelding: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555ArcStudWelding = iso14555ArcStudWelding;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555_3834Testing: (iso14555_3834Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555_3834Testing = iso14555_3834Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555_783Testing: (iso14555_783Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555_783Testing = iso14555_783Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555_784Testing: (iso14555_784Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555_784Testing = iso14555_784Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555_785Testing: (iso14555_785Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555_785Testing = iso14555_785Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555_786Testing: (iso14555_786Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555_786Testing = iso14555_786Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555_787Testing: (iso14555_787Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555_787Testing = iso14555_787Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555_3834TestingText: (iso14555_3834TestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555_3834TestingText = iso14555_3834TestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555BendTestingTorqueWrench: (iso14555BendTestingTorqueWrench: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555BendTestingTorqueWrench = iso14555BendTestingTorqueWrench;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555MacroPlusPhoto: (iso14555MacroPlusPhoto: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555MacroPlusPhoto = iso14555MacroPlusPhoto;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555RTProvning: (iso14555RTProvning: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555RTProvning = iso14555RTProvning;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555TensileTesting: (iso14555TensileTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555TensileTesting = iso14555TensileTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555TorqueTesting: (iso14555TorqueTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555TorqueTesting = iso14555TorqueTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },

    setQWBPOrderISO14555AdditionalTestingText: (iso14555AdditionalTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555AdditionalTestingText = iso14555AdditionalTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555ApplicationGreaterThan100DegreesText: (iso14555ApplicationGreaterThan100DegreesText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555ApplicationGreaterThan100DegreesText = iso14555ApplicationGreaterThan100DegreesText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555ApplicationLessThanOrEqualTo100DegreesText: (iso14555ApplicationLessThanOrEqualTo100DegreesText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555ApplicationLessThanOrEqualTo100DegreesText = iso14555ApplicationLessThanOrEqualTo100DegreesText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555BendTestingText: (iso14555BendTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555BendTestingText = iso14555BendTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555BendTestingTorqueWrenchText: (iso14555BendTestingTorqueWrenchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555BendTestingTorqueWrenchText = iso14555BendTestingTorqueWrenchText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555MacroPlusPhotoText: (iso14555MacroPlusPhotoText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555MacroPlusPhotoText = iso14555MacroPlusPhotoText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555TensileTestingText: (iso14555TensileTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555TensileTestingText = iso14555TensileTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555TorqueTestingText: (iso14555TorqueTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555TorqueTestingText = iso14555TorqueTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO14555RTProvningText: (iso14555RTProvningText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso14555RTProvningText = iso14555RTProvningText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO156141Edition2004: (iso156141Edition2004: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso156141Edition2004 = iso156141Edition2004;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderISO156141Edition2017: (iso156141Edition2017: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso156141Edition2017 = iso156141Edition2017;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderManufacturerWPQRBPARNumber: (manufacturerWPQRBPARNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.manufacturerWPQRBPARNumber = manufacturerWPQRBPARNumber;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderMaterial1: (material1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.material1 = material1;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderMaterial2: (material2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.material2 = material2;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderMaterialGroup1: (materialGroup1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.materialGroup1 = materialGroup1;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderMaterialGroup2: (materialGroup2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.materialGroup2 = materialGroup2;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderMTPT: (mtPT: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.mtPT = mtPT;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNDTApproved: (ndtApproved: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.ndtApproved = ndtApproved;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNDTDate: (ndtDate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.ndtDate = ndtDate;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNDTOther: (ndtOther: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.ndtOther = ndtOther;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNDTRemarks: (ndtRemarks: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.ndtRemarks = ndtRemarks;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNDTSignature: (ndtSignature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.ndtSignature = ndtSignature;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOKAdditionalTesting: (norsokAdditionalTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTesting = norsokAdditionalTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOKAdditionalTestingText: (norsokAdditionalTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTestingText = norsokAdditionalTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOKAdditionalTesting1Text: (norsokAdditionalTesting1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTesting1Text = norsokAdditionalTesting1Text;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOKAdditionalTesting2Text: (norsokAdditionalTesting2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTesting2Text = norsokAdditionalTesting2Text;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOKAdditionalTesting3Text: (norsokAdditionalTesting3Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTesting3Text = norsokAdditionalTesting3Text;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOKAdditionalTesting4Text: (norsokAdditionalTesting4Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTesting4Text = norsokAdditionalTesting4Text;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOKAdditionalTesting1: (norsokAdditionalTesting1: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTesting1 = norsokAdditionalTesting1;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOKAdditionalTesting2: (norsokAdditionalTesting2: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTesting2 = norsokAdditionalTesting2;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    }, setQWBPOrderNORSOKAdditionalTesting3: (norsokAdditionalTesting3: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTesting3 = norsokAdditionalTesting3;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    }, setQWBPOrderNORSOKAdditionalTesting4: (norsokAdditionalTesting4: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokAdditionalTesting4 = norsokAdditionalTesting4;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },

    setQWBPOrderNORSOKCorrosionTesting: (norsokCorrosionTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokCorrosionTesting = norsokCorrosionTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOKCorrosionTestingText: (norsokCorrosionTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsokCorrosionTestingText = norsokCorrosionTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNORSOK: (norsok: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.norsok = norsok;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderOrderedByLevel3: (orderedByLevel3: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.orderedByLevel3 = orderedByLevel3;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderOther: (other: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.other = other;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderPhoneNumber: (phoneNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.phoneNumber = phoneNumber;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderPWPSPBPS: (pWPSPBPS: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.pWPSPBPS = pWPSPBPS;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderMaterialSpecifications1Remarks: (materialSpecifications1Remarks: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.materialSpecifications1Remarks = materialSpecifications1Remarks;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderMaterialSpecifications2Remarks: (materialSpecifications2Remarks: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.materialSpecifications2Remarks = materialSpecifications2Remarks;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderRT: (rt: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.rt = rt;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderSignature: (signature: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.signature = signature;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderStandardForAcceptance1: (standardForAcceptance1: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.standardForAcceptance1 = standardForAcceptance1;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderStandardForAcceptance2: (standardForAcceptance2: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.standardForAcceptance2 = standardForAcceptance2;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614TransverseTensilePlusRp0ReH: (transverseTensilePlusRp0ReH: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.transverseTensilePlusRp0ReH = transverseTensilePlusRp0ReH;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderPEDAdditionalTesting: (pedAdditionalTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.pedAdditionalTesting = pedAdditionalTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614MacroPlusPhoto: (macroPlusPhoto: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.macroPlusPhoto = macroPlusPhoto;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614HardnessTesting: (hardnessTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.hardnessTesting = hardnessTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614ImpactTesting: (impactTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.impactTesting = impactTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614BendTesting: (bendTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.bendTesting = bendTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614AdditionalTesting: (additionalTesting: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.additionalTesting = additionalTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614TestingText: (iso15614TestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso15614TestingText = iso15614TestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614TransverseTensilePlusRp0ReHText: (transverseTensilePlusRp0ReHText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso15614transverseTensilePlusRp0ReHText = transverseTensilePlusRp0ReHText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614MacroPlusPhotoText: (macroPlusPhotoText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso15614macroPlusPhotoText = macroPlusPhotoText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614HardnessTestingText: (hardnessTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso15614hardnessTestingText = hardnessTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614ImpactTestingText: (impactTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso15614impactTestingText = impactTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614BendTestingText: (bendTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso15614bendTestingText = bendTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderIso15614AdditionalTestingText: (additionalTestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.iso15614additionalTestingText = additionalTestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },


    setQWBPOrderUT: (ut: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.ut = ut;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderVisual: (visual: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.visual = visual;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderWelderBrazerFirstName: (welderBrazer: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.welderBrazerFirstName = welderBrazer;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderWelderBrazerLastName: (welderBrazer: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.welderBrazerLastName = welderBrazer;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderWeldingBrazingPositions: (weldingBrazingPositions: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.weldingBrazingPositions = weldingBrazingPositions;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderWeldingDate: (weldingDate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.weldingDate = weldingDate;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderWeldingProcess: (weldingProcessA: string, weldingProcessB: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            //qwbpOrder.weldingProcess = weldingProcess;
            qwbpOrder.weldingProcess = weldingProcessA + ", " + weldingProcessB;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setDekraProcedureNoTesting: (dekraProcedureNoTesting: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.dekraProcedureNoTesting = dekraProcedureNoTesting;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setInvoiceReferenceName: (invoiceReferenceName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.invoiceReferenceName = invoiceReferenceName;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setUppdragsnr: (uppdragsnr: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.uppdragsnr = uppdragsnr;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setTestmarking: (Testmarking: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.testmarking = Testmarking;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNACE: (nace: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.nace = nace;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrdernaceMr0103Testing: (naceMr0103Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.naceMr0103Testing = naceMr0103Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNaceMr0175Testing: (naceMr0175Testing: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.naceMr0175Testing = naceMr0175Testing;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNACEAdditionalTesting1: (naceAdditionalTesting1: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.naceAdditionalTesting1 = naceAdditionalTesting1;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNACEAdditionalTesting2: (naceAdditionalTesting2: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.naceAdditionalTesting2 = naceAdditionalTesting2;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNaceMr0103TestingText: (naceMr0103TestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.naceMr0103TestingText = naceMr0103TestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNaceMr0175TestingText: (naceMr0175TestingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.naceMr0175TestingText = naceMr0175TestingText;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNACEAdditionalTesting1Text: (naceAdditionalTesting1Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.naceAdditionalTesting1Text = naceAdditionalTesting1Text;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
    setQWBPOrderNACEAdditionalTesting2Text: (naceAdditionalTesting2Text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.testingOrder) {
            let qwbpOrder: QWBPOrder = { ...appState.testingOrder.qwbpOrder };
            qwbpOrder.naceAdditionalTesting2Text = naceAdditionalTesting2Text;
            dispatch({ type: 'SET_QWBP_ORDER', qwbpOrder: qwbpOrder });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TestingOrderState = {
    dekraProcedureNumber: '',
    commissionNumber: '',
    manufacturerWPQRNumber: '',
    qwbpOrder: {
        customer: '',
        contactPerson: '',
        weldingDate: '',
        phoneNumber: '',
        welderBrazerFirstName: '',
        welderBrazerLastName: '',
        pWPSPBPS: '',
        manufacturerWPQRBPARNumber: '',
        weldingBrazingPositions: '',
        weldingProcess: '',
        fillerMaterial: '',
        ndtRemarks: '',
        visual: '',
        mtPT: '',
        rt: '',
        ut: '',
        ndtOther: '',
        conditionsAndDescriptionRemarks: '',
        material1: '',
        materialGroup1: '',
        dimension1: '',
        standardForAcceptance1: '',
        chargeNumber1: '',
        impactTestTemperature1: '',
        directionOfPlateRolling1: '',
        materialSpecifications1Remarks: '',
        material2: '',
        materialGroup2: '',
        dimension2: '',
        standardForAcceptance2: '',
        chargeNumber2: '',
        impactTestTemperature2: '',
        directionOfPlateRolling2: '',
        materialSpecifications2Remarks: '',
        iso156141Edition2004: false,
        iso156141Edition2017: false,
        transverseTensilePlusRp0ReH: false,
        macroPlusPhoto: false,
        hardnessTesting: false,
        impactTesting: false,
        bendTesting: false,
        additionalTesting: false,
        iso134454Testing: false,
        iso134454ImpactTesting: false,
        iso134454MicroTesting: false,
        iso134454FerriteTesting: false,
        iso134454LongitudinalTensileTesting: false,
        iso134454AdditionalTesting: false,
        iso134454ImpactTestingText: '',
        iso134454MicroTestingText: '',
        iso134454FerriteTestingText: '',
        iso134454LongitudinalTensileTestingText: '',
        iso134454AdditionalTestingText: '',
        iso129526LongitudinalTensileTesting: false,
        iso129526ImpactTesting: false,
        iso129526AdditionalTesting: false,
        iso129526Testing: false,
        iso129526LongitudinalTensileTestingText: '',
        iso129526ImpactTestingText: '',
        iso129526AdditionalTestingText: '',
        iso10902: false,
        iso10902AdditionalTesting: false,
        iso10902CruciformTensileTesting: false,
        iso10902ImpactTesting: false,
        iso10902MacroPlusPhoto: false,
        iso10902MicroTesting: false,
        iso10902WeldabilityPrimer: false,
        iso10902AdditionalTestingText: '',
        iso10902CruciformTensileTestingText: '',
        iso10902ImpactTestingText: '',
        iso10902MacroPlusPhotoText: '',
        iso10902MicroTestingText: '',
        iso10902WeldabilityPrimerText: '',
        norsokAdditionalTesting1: false,
        norsokAdditionalTesting2: false,
        norsokAdditionalTesting3: false,
        norsokAdditionalTesting4: false,
        norsokAdditionalTestingText: '',
        norsokAdditionalTesting1Text: '',
        norsokAdditionalTesting2Text: '',
        norsokAdditionalTesting3Text: '',
        norsokAdditionalTesting4Text: '',
        norsokCorrosionTestingText: '',
        norsok: false,
        norsokCorrosionTesting: false,
        norsokAdditionalTesting: false,
        iso14555ApplicationGreaterThan100Degrees: false,
        iso14555ApplicationLessThanOrEqualTo100Degrees: false,
        iso14555BendTesting: false,
        iso14555ArcStudWelding: false,
        iso14555BendTestingTorqueWrench: false,
        iso14555TensileTesting: false,
        iso14555MacroPlusPhoto: false,
        iso14555TorqueTesting: false,
        iso14555AdditionalTesting: false,
        iso14555RTProvning: false,
        iso14555_3834Testing: false,
        iso14555_783Testing: false,
        iso14555_784Testing: false,
        iso14555_785Testing: false,
        iso14555_786Testing: false,
        iso14555_787Testing: false,
        iso14555ApplicationGreaterThan100DegreesText: '',
        iso14555ApplicationLessThanOrEqualTo100DegreesText: '',
        iso14555BendTestingText: '',
        iso14555BendTestingTorqueWrenchText: '',
        iso14555TensileTestingText: '',
        iso14555MacroPlusPhotoText: '',
        iso14555TorqueTestingText: '',
        iso14555AdditionalTestingText: '',
        iso14555RTProvningText: '',
        iso14555_3834TestingText: '',
        iso13134TensileTesting: false,
        iso13134BendTesting: false,
        iso13134HardnessTesting: false,
        iso13134ShearTesting: false,
        iso13134PeelTesting: false,
        iso13134AdditionalTesting: false,
        iso13134BrazinProcedureApproval: false,
        iso13134TensileTestingText: '',
        iso13134BendTestingText: '',
        iso13134HardnessTestingText: '',
        iso13134ShearTestingText: '',
        iso13134PeelTestingText: '',
        iso13134AdditionalTestingText: '',
        other: '',
        ndtApproved: '',
        ndtSignature: '',
        ndtDate: '',
        destructiveTestingApproved: '',
        destructiveTestingSignature: '',
        destructiveTestingDate: '',
        orderedByLevel3: '',
        date: '',
        signature: '',
        iso15614transverseTensilePlusRp0ReHText: '',
        iso15614macroPlusPhotoText: '',
        iso15614hardnessTestingText: '',
        iso15614impactTestingText: '',
        iso15614bendTestingText: '',
        iso15614additionalTestingText: '',
        iso15614TestingText: '',
        pedAdditionalTesting: false,
        dekraProcedureNoTesting: '',
        invoiceReferenceName: '',
        uppdragsnr: '',
        testmarking: '',
        nace: false,
        naceMr0103Testing: false,
        naceMr0103TestingText: '',
        naceMr0175Testing: false,
        naceMr0175TestingText: '',
        naceAdditionalTesting1: false,
        naceAdditionalTesting1Text: '',
        naceAdditionalTesting2: false,
        naceAdditionalTesting2Text: '',
    }

};

export const reducer: Reducer<TestingOrderState> = (state: TestingOrderState | undefined, incomingAction: Action): TestingOrderState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_COMMISSION_NUMBER':
            return {
                commissionNumber: action.commissionNumber,
                dekraProcedureNumber: state.dekraProcedureNumber,
                manufacturerWPQRNumber: state.manufacturerWPQRNumber,
                qwbpOrder: state.qwbpOrder
            }
        case 'SET_DEKRA_PROCEDURE_NUMBER':
            return {
                commissionNumber: state.commissionNumber,
                dekraProcedureNumber: action.dekraProcedureNumber,
                manufacturerWPQRNumber: state.manufacturerWPQRNumber,
                qwbpOrder: state.qwbpOrder
            }
        case 'SET_MANUFACTURER_WPQR_NUMBER':
            return {
                commissionNumber: state.commissionNumber,
                dekraProcedureNumber: state.dekraProcedureNumber,
                manufacturerWPQRNumber: action.manufacturerWPQRNumber,
                qwbpOrder: state.qwbpOrder
            }
        case 'SET_QWBP_ORDER':
            return {
                commissionNumber: state.commissionNumber,
                dekraProcedureNumber: state.dekraProcedureNumber,
                manufacturerWPQRNumber: state.manufacturerWPQRNumber,
                qwbpOrder: action.qwbpOrder
            };
        default:
            return state;
    }
};
