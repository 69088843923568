﻿
import * as React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as LoginStore from '../store/LoginStore';
import EventBus from '../store/eventBus';


interface ILoginInfoProps extends RouteComponentProps {
    loggedIn: boolean;
}

type LoginProps =
    LoginStore.LoginState // ... state we've requested from the Redux store
    & typeof LoginStore.actionCreators // ... plus action creators we've requested
    & ILoginInfoProps; 


class AuthVerify extends React.PureComponent<LoginProps, {}>
{

    public componentDidMount()
    {
        this.props.CheckToken();

        EventBus.remove("logout", () => this.logOutEvent());
        EventBus.on("logout", () => this.logOutEvent());
    }

    private logOutEvent() {
        this.props.logout();
    }

    public render() {
      
        /*this.props.history.listen(() => {
            this.props.CheckToken();
        });*/

        return (
            <div></div>
        );
    }


}


const mapStateToProps = (state: any) => ({
    loggedIn: state.login.loggedIn
})

export default withRouter(connect(mapStateToProps, LoginStore.actionCreators)(AuthVerify as any));