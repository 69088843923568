﻿export class GetAdditionalRequirementResponse {
    createdAt: string;
    deletedAt: string;
    description: string;
    id: number;
    name: string;
    selected: boolean;
    updatedAt: string;
   
    public constructor() {
        this.createdAt = '';
        this.deletedAt = '';
        this.description = '';
        this.id = 0;
        this.name = '';
        this.selected = false;
        this.updatedAt = '';
    }
}