﻿export class HardnessTestingResultsTableRow {
    parent1: string;
    parent2: string;
    parent3: string;
    haz4: string;
    haz5: string;
    haz6: string;
    weld7: string;
    weld8: string;
    weld9: string;
    haz10: string;
    haz11: string;
    haz12: string;
    parent13: string;
    parent14: string;
    parent15: string;
    haz16: string;
    haz17: string;
    haz18: string;
    haz19: string;

    public constructor(parent1: string, parent2: string, parent3: string, haz4: string, haz5: string, haz6: string, weld7: string, weld8: string, weld9: string, haz10: string, haz11: string, haz12: string, parent13: string, parent14: string, parent15: string, haz16: string, haz17: string, haz18: string, haz19: string) {
        this.parent1 = parent1;
        this.parent2 = parent2;
        this.parent3 = parent3;
        this.haz4 = haz4;
        this.haz5 = haz5;
        this.haz6 = haz6;
        this.weld7 = weld7;
        this.weld8 = weld8;
        this.weld9 = weld9;
        this.haz10 = haz10;
        this.haz11 = haz11;
        this.haz12 = haz12;
        this.parent13 = parent13;
        this.parent14 = parent14;
        this.parent15 = parent15;
        this.haz16 = haz16;
        this.haz17 = haz17;
        this.haz18 = haz18;
        this.haz19 = haz19;
    }
}