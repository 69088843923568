﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Form, FormGroup, FormText, Input, InputGroup, InputGroupButtonDropdown, Label, ListGroup, ListGroupItem, Row, Table } from 'reactstrap';
import { ApplicationState } from '../store/index';
import { RangeOfQualifications } from '../store/models/RangeOfQualifications';
import { GetRangeOfQualificationExaminationResponse } from '../store/models/responses/GetRangeOfQualificationExaminationResponse';
import * as RangeOfQualificationStore from '../store/RangeOfQualificationStore';
import WPQRPageHeader from './WPQRPageHeader';
import { t } from '../i18n';
import DropdownLists from './otherComponents/DropdownLists';
import { RangeOfQualificationInfo } from '../store/models/RangeOfQualificationInfo';

// At runtime, Redux will merge together...
type RangeOfQualificationProps =
    RangeOfQualificationStore.RangeOfQualificationState // ... state we've requested from the Redux store
    & typeof RangeOfQualificationStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)
    
const SweToEngWeldingPositions = new Map<string, string>();
const SweToEngOtherInformation = new Map<string, string>();
const postWeldHeatTreatmentTextList = [
    'PWHT, XXXºC ± 20ºC',
    'Obehandlat tillstånd efter svetsning / As welded',
    'Naturlig åldring (kallåldring) / Natural ageing',
    'Konstgjord åldring (varmåldring) / Artificial ageing'];
const interpassTemperatureTextList = [
    'Max +XX',
    'Max +XX (an increase of 50°C is permitted)',
    'Enligt rekommendationer för det aktuella grundmaterialet / According to recommendations for the parent material used'];
const preheatTemperatureTextList = [
    'Min +XX',
    'Min +15',
    'Min +XX (a decrease of 50°C is permitted if e.g. ISO/TR 17671-2 is fulfilled)'];
const arcEnergyTextList = [
    '≥ X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1 - n',
    '≤ X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1 - n',
    'X,XX – X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1 - n',
    '≥ X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1',
    '≤ X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1',
    'X,XX – X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1',
    'Bågenergin vid procedurkvalificeringen var Xmin-Xmax kJ/mm / The arc energy at the procedure qualification was Xmin-Xmax kJ/mm',
    'XXX: X,XX – X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1\nYYY: X,XX – XX,X kJ/mm, se sida 3 för sträng / see page 3 for run 2 - n'];
const heatInputTextList = [
    '≥ X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1 - n',
    '≤ X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1 - n',
    'X,XX – X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1 - n',
    '≥ X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1',
    '≤ X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1',
    'X,XX – X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1',
    'XXX: X,XX – X,XX kJ/mm, se sida 3 för sträng / see page 3 for run 1\nYYY: X,XX – XX,X kJ/mm, se sida 3 för sträng / see page 3 for run 2 - n',
    'Ø2,5 mm: X,XX – X,XX kJ/mm, se sida 3 / see page 3\nØ3,2 mm: X,XX – X,XX kJ/mm, se sida 3 / see page 3',
    'Värmetillförseln vid procedurkvalificeringen var Xmin-Xmax kJ/mm / The heat input at the procedure qualification was Xmin-Xmax kJ/mm',];
const dropletTransitionsList = [
    'Spray- eller pulsbåge eller globulär droppövergång / Spray, pulse or globular transfer',
    'Kortbåge / Short circuiting transfer',
    'XXX: Kortbåge / Short circuiting transfer (XXX, YYY = svetsmetoder om flera metoder)\nYYY: Spray- eller pulsbåge eller globulär droppövergång / Spray, pulse or globular transfer',
    'Rot/root: Kortbåge / Short circuiting transfer\nFyllnad/filling: Spray- eller pulsbåge eller globulär droppövergång / Spray, pulse or globular transfer'];
const weldingProcessList = [
    '111, manuell / manual',
    '141, manuell / manual',
    '311, manuell / manual',
    '121, delmekaniserad / partly mechanized',
    '131, delmekaniserad / partly mechanized',
    '132, delmekaniserad / partly mechanized',
    '133, delmekaniserad / partly mechanized',
    '135, delmekaniserad / partly mechanized',
    '136, delmekaniserad / partly mechanized',
    '138, delmekaniserad / partly mechanized',
    '141, delmekaniserad / partly mechanized',
    '151, delmekaniserad / partly mechanized',
    '152, delmekaniserad / partly mechanized',
    '153, delmekaniserad / partly mechanized',
    '154, delmekaniserad / partly mechanized',
    '155, delmekaniserad / partly mechanized',
    '121, mekaniserad / mechanized',
    '131, mekaniserad / mechanized',
    '132, mekaniserad / mechanized',
    '133, mekaniserad / mechanized',
    '135, mekaniserad / mechanized',
    '136, mekaniserad / mechanized',
    '138, mekaniserad / mechanized',
    '141, mekaniserad / mechanized',
    '151, mekaniserad / mechanized',
    '152, mekaniserad / mechanized',
    '153, mekaniserad / mechanized',
    '154, mekaniserad / mechanized',
    '155, mekaniserad / mechanized',
    '121, automatisk / automatic',
    '151, automatisk / automatic',
    '152, automatisk / automatic',
    '153, automatisk / automatic',
    '154, automatisk / automatic',
    '155, automatisk / automatic',
    '131, automatisk / automatic',
    '132, automatisk / automatic',
    '133, automatisk / automatic',
    '135, automatisk / automatic',
    '136, automatisk / automatic',
    '138, automatisk / automatic',
    'Rot/root: XXX, manuell / manual\nFyllnad / filling: YYY, manuell / manual',
    'Rot/root: XXX, manuell / manual\nFyllnad / filling: YYY, delmekaniserad / partly mechanized',
    'Rot/root: XXX, delmekaniserad / partly mechanized\nFyllnad/filling: YYY, delmekaniserad / partly mechanized',
    'Rot/root: XXX, delmekaniserad / partly mechanized\nFyllnad/filling: YYY, manuell / manual',
    'Rot och fyllnad/root and filling: XXX, manuell / manual\nTopp/face: YYY, manuell / manual'];
const jointTypeList = [
    'BW/FW, ss-nb, ss-mb, bs, see 8.4.3 in SS-EN ISO 15614-1',
    'BW/FW, 138: ss-nb, ss-mb, bs, 136: ss-mb, bs, se 8.4.3 i/see 8.4.3 in SS-EN ISO 15614-1',
    'FW, see 8.4.3 in SS-EN ISO 15614-1',
    'BW/FW, ss-mb, bs with and without gouging, see 8.4.3 in SS-EN ISO 15614-1',
    'BW/FW, ss-mb, bs with gouging, see 8.4.3 in SS-EN ISO 15614-1',
    'T-förband/T-joint, BW/FW, see 8.4.3 in SS-EN ISO 15614-1',
    'Avstickare / Branch connection α ≥ XX°, BW, FW, see 8.4.3 in SS-EN ISO 15614-1',
    'SS-EN ISO 15613:',
    'BW, se kap. 8 i/see chap. 8 in SS-EN ISO 15613',
    'Partiell/partial BW, se kap. 8 i/see chap. 8 in SS-EN ISO 15613',
    'FW, se kap. 8 i/see chap. 8 in SS-EN ISO 15613',
    'T-förband/T-joint, BW, se kap. 8 i/see chap. 8 in SS-EN ISO 15613',
    'T-förband/T-joint, FW, se kap. 8 i/see chap. 8 in SS-EN ISO 15613',
    'T-förband, hörnfog/T-joint, corner weld, see 8 in SS-EN ISO 15613'];
const materialGroupTextList = [
    'CEN ISO/TR 15608:2017 grupp/group 1 - 1, ReH ≤ XXX MPa',
    'CEN ISO/TR 15608:2017 grupp/group 2-2, 1-1, 2-1,  ReH ≤ XXX MPa',
    'CEN ISO/TR 15608:2017 grupp/group 3-3, 1-1, 2-1, 2-2, 3-1, 3-2,  ReH ≤ XXX MPa',
    'CEN ISO/TR 15608:2017 grupp/group 5.1 och/and 5.2 mot/to 1, 2, 5.1, 5.2',
    'CEN ISO/TR 15608:2017 grupp/group 7.1',
    'CEN ISO/TR 15608:2017 grupp/group 7.2',
    'CEN ISO/TR 15608:2017 grupp/group 7.3',
    'CEN ISO/TR 15608:2017 grupp/group 1* mot/to 8.1  *ReH ≤ XXX MPa',
    'CEN ISO/TR 15608:2017 grupp/group 8.1 - 8.1',
    'CEN ISO/TR 15608:2017 grupp/group 8.1, 8.2 - 8.1, 8.2',
    'CEN ISO/TR 15608:2017 grupp/group 8 mot/to 8',
    'CEN ISO/TR 15608:2017 grupp/group 10.1',
    'CEN ISO/TR 15608:2017 grupp/group 10.2',
    'CEN ISO/TR 15608:2017 grupp/group 10.3',
    'CEN ISO/TR 15608:2017 grupp/group 10.1 - 8.1',
    'CEN ISO/TR 15608:2017 grupp/group 43* –  43, 45* – 45, 47* - 47',
    'CEN ISO/TR 15608:2017 grupp/group 23.1, 22.1, 22.2, 22.3, 22.4 och blandförband mellan/and dissimilar joints between 22.1, 22.2, 22.3, 22.4',
    'CEN ISO/TR 15608:2017 grupp/group 23.1-23.1, 22.1-22.1',
    'CEN ISO/TR 15608:2017 grupp/group 24.2, 24.1, 23.1 och blandförband/and dissimilar joints 24.2-24.1, 24.2-23.1',
    'CEN ISO/TR 15608:2017 grupp/group 26, 24.1*, 24.2*, 25* och blandförband/and dissimilar joints 26-24.1*, 26-24.2*, 26-25*, skriv under Annan upplysning: *endast för gjutgods / only for castings'];
const materialThicknessesList = [
    'BW: XX,X - XX,X    FW: XX,X - XX,X',
    'BW: XX,X - XX,X**    FW: XX,X - XX,X',
    'Rör/Pipe 1, gr 1.2: BW: XX,X - XX,X   FW: XX,X - XX,X\nRör/Pipe 2, gr 8.1: BW: XX,X - XX,X   FW: XX,X - XX,X ',
    'Rör/Pipe: BW: XX,X - XX,X   FW: XX,X - XX,X\nPlåt/Plate: BW: XX,X - XX,X   FW: XX,X - XX,X ',
    'Plåt/Plate 1: BW: XX,X - XX,X   FW: XX,X - XX,X\nPlåt/Plate 2: BW: XX,X - XX,X   FW: XX,X - XX,X ',
    'Plåt/Plate: ≥ 5\nStång/Bar: ≥ 5 ',
    'BW: XX,X - XX,X   FW: XX,X - XX,X   (SS-EN 13445-2: BW: 3,0 – 5,0)**'];
const weldingMetalThicknessTextList = [
    '≤ XX,X',
    'XXX: ≤ XX,X    YYY: ≤ XX,X  (XXX, YYY = svetsmetoder om flera metoder)',
    'Minsta inträngningsdjup enligt SS-EN 1090-2, 7.4.1.2, X,X mm / Min. depth of penetration acc. to SS-EN 1090-2, 7.4.1.2, X,X mm'];
const throatThicknessTextList = [
    'Ingen begränsning / No restriction',
    'XX,X - XX,X'];
const diameterTextList = [
    '≥ XXX och plåt/and plate',
    '˃500, ˃150 för/for PC samt vridbara svetslägena/and rotated welding positions PA och/and PF',
    '˃500',
    '˃150 för/for PC',
    '˃150 för vridbara svetsläget PA / for rotated welding position PA',
    '˃150 för vridbara svetsläget PF / for rotated welding position PF'];
const runTypeTextList = [
    'Enkelsträng / Single run',
    'Flersträng / Multi run',
    'Enkelsträng och Flersträng / Single run and Multi run',];
const designationsList = ['-'];
const shieldingGasesList = [
    'EN ISO 14175-I1-Ar, se även/see also 8.5.3.1',
    'SS-EN ISO 14175-Z-ArC+NO-2/0,03, 1,60-1,92% CO2, se även/see also 8.5.2.1',
    'EN ISO 14175-M21-ArC-18, 14,4-21,6% CO2, se även/see also 8.5.2.1',
    'EN ISO 14175-Z-ArC+NO-18/0,03, 14,4-21,6% CO2, se även/see also 8.5.2.1',
    'EN ISO 14175-Z-ArC+NO-25/0,03, 20,0-30,0% CO2, se även/see also 8.5.2.1',
    'SS-EN ISO 14175-N5-NH-10',
    '15: Plasmagas / Plasma gas: EN ISO 14175-I1-Ar\nSkyddsgas / Shielding gas: EN ISO 14175-I1-Ar',
    '141: SS-EN ISO 14175-I1-Ar\n135: SS-EN ISO 14175-N5-NH-10'];
const rootProtectionGasTextList = [
    'EN ISO 14175-I1-Ar',
    'EN ISO 14175-N5-NH-10',
    'Utan eller med EN ISO 14175-I, -N1, -N2, -N3 / With or without EN ISO 14175-I, -N1, -N2, -N3',
    'EN ISO 14175-I, N1, N2, N3, se även/see also 8.5.6',
    'EN ISO 14175-I, N, R, se även/see also 8.5.6',];
const makesList = ['-'];
const currentTypesList = [
    'DC+',
    'DC-',
    'AC',
    '111: AC, DC-, DC+',
    'DC+, pulsad ström / pulsed current',
    'DC-, pulsad ström / pulsed current',
    'XXX: DC-, YYY: DC+  (XXX, YYY = svetsmetoder om flera metoder)',];
const postWeldHeatTreatmentTextListSearchResult = [];
const interpassTemperatureTextListSearchResult = [];
const preheatTemperatureTextListSearchResult = [];
const arcEnergyTextListSearchResult = [];
const heatInputTextListSearchResult = [];
const dropletTransitionsListSearchResult = [];
const weldingProcessListSearchResult = [];
const jointTypeListSearchResult = [];
const materialGroupTextListSearchResult = [];
const materialThicknessesListSearchResult = [];
const weldingMetalThicknessTextListSearchResult = [];
const throatThicknessTextListSearchResult = [];
const diameterTextListSearchResult = [];
const runTypeTextListSearchResult = [];
const designationsListSearchResult = [];
const shieldingGasesListSearchResult = [];
const rootProtectionGasTextListSearchResult = [];
const makesListSearchResult = [];
const currentTypesListSearchResult = [];

export interface OwnProps {
    jointType?: string;
    materialGroup?: string;
    materialThicknesses?: Array<string>;
    weldingMethods?: Array<string>;
    weldingMetalThickness?: string;
    throatThickness?: string;
    runType?: string;
    diameter?: string;
    designations?: Array<string>;
    makes?: Array<string>;
    shieldingGases?: Array<string>;
    rootProtectionGas?: string;
    currentTypes?: Array<string>;
    dropletTransitions?: Array<string>;
    postWeldHeatTreatment?: string;
    preheatTemperature?: string;
    interpassTemperature?: string;
    rangeOfQualifications?: RangeOfQualifications;
    selectedApplicaitonStandardId?: number;
    weldingDate?: string;
    companyAdress?: string;
    companyPostcode?: string;
    companyCity?: string;
    companyName?: string;
    pRangeOfQualificationExaminationResponse?: GetRangeOfQualificationExaminationResponse;
    pUpdateRangeOfqualification?: boolean;
    pExaminer?: string;
    setExaminer: (value: string) => void;
    pExaminerName?: string;
    setExaminerName: (value: string) => void;
    pExaminerDate?: string;
    setExaminerDate: (value: string) => void;
    hiddenToShowExistingWpqrForm?: boolean
    updateRangeOfQualificationInfo?: RangeOfQualificationInfo;

}

class RangeOfQualification extends React.PureComponent<RangeOfQualificationProps> {
    public state = {
        dropdownOpen: false,
        dropdownOpen1: false,
        postWeldHeatTreatmentTextList,
        postWeldHeatTreatmentTextListSearchResult,
        interpassTemperatureTextList,
        interpassTemperatureTextListSearchResult,
        preheatTemperatureTextList,
        preheatTemperatureTextListSearchResult,
        arcEnergyTextList,
        arcEnergyTextListSearchResult,
        heatInputTextList,
        heatInputTextListSearchResult,
        dropletTransitionsList,
        dropletTransitionsListSearchResult,
        weldingProcessList,
        weldingProcessListSearchResult,
        jointTypeList,
        jointTypeListSearchResult,
        materialGroupTextList,
        materialGroupTextListSearchResult,
        materialThicknessesList,
        materialThicknessesListSearchResult,
        weldingMetalThicknessTextList,
        weldingMetalThicknessTextListSearchResult,
        throatThicknessTextList,
        throatThicknessTextListSearchResult,
        diameterTextList,
        diameterTextListSearchResult,
        runTypeTextList,
        runTypeTextListSearchResult,
        designationsList,
        designationsListSearchResult,
        shieldingGasesList,
        shieldingGasesListSearchResult,
        rootProtectionGasTextList,
        rootProtectionGasTextListSearchResult,
        makesList,
        makesListSearchResult,
        currentTypesList,
        currentTypesListSearchResult,
    };
    private currentTypesListSearchResultClear = () => {
        let list = this.state.currentTypesListSearchResult;
        list.length = 0;
        this.setState({
            currentTypesListSearchResult: list
        });
    }
    private currentTypesListSearchResult = (value: any[]) => {
        this.setState({
            currentTypesListSearchResult: value
        });
    }
    private makesListSearchResultClear = () => {
        let list = this.state.makesListSearchResult;
        list.length = 0;
        this.setState({
            makesListSearchResult: list
        });
    }
    private makesListSearchResult = (value: any[]) => {
        this.setState({
            makesListSearchResult: value
        });
    }
    private rootProtectionGasTextListSearchResultClear = () => {
        let list = this.state.rootProtectionGasTextListSearchResult;
        list.length = 0;
        this.setState({
            rootProtectionGasTextListSearchResult: list
        });
    }
    private rootProtectionGasTextListSearchResult = (value: any[]) => {
        this.setState({
            rootProtectionGasTextListSearchResult: value
        });
    }
    private shieldingGasesListSearchResultClear = () => {
        let list = this.state.shieldingGasesListSearchResult;
        list.length = 0;
        this.setState({
            shieldingGasesListSearchResult: list
        });
    }
    private shieldingGasesListSearchResult = (value: any[]) => {
        this.setState({
            shieldingGasesListSearchResult: value
        });
    }
    private designationsListSearchResultClear = () => {
        let list = this.state.designationsListSearchResult;
        list.length = 0;
        this.setState({
            designationsListSearchResult: list
        });
    }
    private designationsListSearchResult = (value: any[]) => {
        this.setState({
            designationsListSearchResult: value
        });
    }
    private runTypeTextListSearchResultClear = () => {
        let list = this.state.runTypeTextListSearchResult;
        list.length = 0;
        this.setState({
            runTypeTextListSearchResult: list
        });
    }
    private runTypeTextListSearchResult = (value: any[]) => {
        this.setState({
            runTypeTextListSearchResult: value
        });
    }
    private diameterTextListSearchResultClear = () => {
        let list = this.state.diameterTextListSearchResult;
        list.length = 0;
        this.setState({
            diameterTextListSearchResult: list
        });
    }
    private diameterTextListSearchResult = (value: any[]) => {
        this.setState({
            diameterTextListSearchResult: value
        });
    }

    private throatThicknessTextListSearchResultClear = () => {
        let list = this.state.throatThicknessTextListSearchResult;
        list.length = 0;
        this.setState({
            throatThicknessTextListSearchResult: list
        });
    }
    private throatThicknessTextListSearchResult = (value: any[]) => {
        this.setState({
            throatThicknessTextListSearchResult: value
        });
    }
    private weldingMetalThicknessTextListSearchResultClear = () => {
        let list = this.state.weldingMetalThicknessTextListSearchResult;
        list.length = 0;
        this.setState({
            weldingMetalThicknessTextListSearchResult: list
        });
    }
    private weldingMetalThicknessTextListSearchResult = (value: any[]) => {
        this.setState({
            weldingMetalThicknessTextListSearchResult: value
        });
    }
    private materialThicknessesListSearchResultClear = () => {
        let list = this.state.materialThicknessesListSearchResult;
        list.length = 0;
        this.setState({
            materialThicknessesListSearchResult: list
        });
    }
    private materialThicknessesListSearchResult = (value: any[]) => {
        this.setState({
            materialThicknessesListSearchResult: value
        });
    }
    private materialGroupTextListSearchResultClear = () => {
        let list = this.state.materialGroupTextListSearchResult;
        list.length = 0;
        this.setState({
            materialGroupTextListSearchResult: list
        });
    }
    private materialGroupTextListSearchResult = (value: any[]) => {
        this.setState({
            materialGroupTextListSearchResult: value
        });
    }
    private jointTypeListSearchResultClear = () => {
        let list = this.state.jointTypeListSearchResult;
            list.length = 0;
        this.setState({
            jointTypeListSearchResult: list
        });
    }
    private jointTypeListSearchResult = (value: any[]) => {
        this.setState({
            jointTypeListSearchResult: value
        });
    }
    private weldingProcessListSearchResultClear = () => {
        let list = this.state.weldingProcessListSearchResult;
            list.length = 0;
        this.setState({
            weldingProcessListSearchResult: list
        });
    }
    private weldingProcessListSearchResult = (value: any[]) => {
        this.setState({
            weldingProcessListSearchResult: value
        });
    }
    private dropletTransitionsListSearchResultClear = () => {
        let list = this.state.dropletTransitionsListSearchResult;
            list.length = 0;
        this.setState({
            dropletTransitionsListSearchResult: list
        });
    }
    private dropletTransitionsListSearchResult = (value: any[]) => {
        this.setState({
            dropletTransitionsListSearchResult: value
        });
    }
    private heatInputTextListSearchResultClear = () => {
        let list = this.state.heatInputTextListSearchResult;
        list.length = 0;
        this.setState({
            heatInputTextListSearchResult: list
        });
    }
    private heatInputTextListSearchResult = (value: any[]) => {
        this.setState({
            heatInputTextListSearchResult: value
        });
    }
    private arcEnergyTextListSearchResultClear = () => {
        let list = this.state.arcEnergyTextListSearchResult;
        list.length = 0;
        this.setState({
            arcEnergyTextListSearchResult: list
        });
    }
    private arcEnergyTextListSearchResult = (value: any[]) => {
        this.setState({
            arcEnergyTextListSearchResult: value
        });
    }
    private preheatTemperatureTextListSearchResultClear = () => {
        let list = this.state.preheatTemperatureTextListSearchResult;
        list.length = 0;
        this.setState({
            preheatTemperatureTextListSearchResult: list
        });
    }
    private preheatTemperatureTextListSearchResult = (value: any[]) => {
        this.setState({
            preheatTemperatureTextListSearchResult: value
        });
    }
    private interpassTemperatureTextListSearchResultClear = () => {
        let list = this.state.interpassTemperatureTextListSearchResult;
        list.length = 0;
        this.setState({
            interpassTemperatureTextListSearchResult: list
        });
    }
    private interpassTemperatureTextListSearchResult = (value: any[]) => {
        this.setState({
            interpassTemperatureTextListSearchResult: value
        });
    }
    private postWeldHeatTreatmentTextListSearchResultClear = () => {
        let list = this.state.postWeldHeatTreatmentTextListSearchResult;
        list.length = 0;
        this.setState({
            postWeldHeatTreatmentTextListSearchResult: list
        });
    }
    private postWeldHeatTreatmentTextListSearchResult = (value: any[]) => {
        this.setState({
            postWeldHeatTreatmentTextListSearchResult: value
        });
    }
    private toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    private toggleDropDown1 = () => {
        this.setState({
            dropdownOpen1: !this.state.dropdownOpen1
        });
    }

    private TranslateBoxes = (word: string, dictionary: Map<string, string>) => {
        if (dictionary.has(word)) {
            return dictionary.get(word);
        } else {
            return word;
        }
    }

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.props.getOtherInformationRangeOfQualificationsAll();
        this.props.getWeldingPositionResult();
        
        SweToEngWeldingPositions.set('Alla utom fallande / All except down hill', t('AllExceptDownhill'));
        SweToEngWeldingPositions.set('Alla, inom giltighetsområdet för Q / All, within the range of qualification for Q', t('AllWithin_'));
        SweToEngWeldingPositions.set('Endast fallande / Only down hill', t('OnlyDownhill'));
        SweToEngWeldingPositions.set('Endast fallande, inom giltighetsområdet för Q / Only down hill, within the range of qualification for Q', t('OnlyDownhillWithin_'));
        SweToEngWeldingPositions.set('Svetslägen möjliga att svetsa inom giltighetsområdet för Q (utom fallande) / Welding positions possible to weld within the range of qualification for Q (except down-hill)', t('WeldingPositionsPossible_'));

        SweToEngOtherInformation.set('Provningen uppfyller 2014/68/EU (PED) / The testing fulfills 2014/68/EU (PED)', t('TheTestingFulfills_'));
        SweToEngOtherInformation.set('Material för tillverkning måste uppfylla Bilaga I, 4.2 b) i 2014/68/EU / Material for manufacture must fulfil Annex I, 4.2 b) in 2014/68/EU', t('MaterialForManufacture_'));
        SweToEngOtherInformation.set('Provningen uppfyller krav enligt SS-EN 1090-2:2018, X.X.X.X. / The testing fulfills requirements acc. to SS-EN 1090-2:2018, X.X.X.X.', t('TheTestingFulfillsReq_'));
        SweToEngOtherInformation.set('Slagprovning har utförts på ett extra provstycke för tillsatsmaterial XXXXX, enligt SS-EN ISO 15641-1, avsnitt 8.4.5.', t('ImpactTestingHasBeen_'));
        SweToEngOtherInformation.set('**Slagprovning ej utförd. Observera att det finns en begränsning i harmoniserade produktstandarder. Se t ex SS-EN 13445-2.', t('ImpactTestingNot_'));
        SweToEngOtherInformation.set('Provningen ger ingen information om förbandets mekaniska egenskaper / The tests do not provide info on the mechanical properties of the joint.', t('TheTestsDoNotProvide_'));
        SweToEngOtherInformation.set('Proceduren är svetsad med undermatchande tillsatsmaterial. Dragprovningsresultatet uppfyller inte grundmaterialets minsta specificerade brottgräns. / Welding performed with undermatching filler material. / The value for tensile strength does not fulfill the specified minimum value for the parent material.', t('WeldingPerformedWith_'));
        SweToEngOtherInformation.set('Ny bedömning mot ovanstående standarder och föreskrifter/ Rejudged against above standards and codesTidigare procedur / Former procedure XXXX.', t('RejudgedAgainstAbove_'));
        SweToEngOtherInformation.set('(Gäller nickel): *covers all solid solution or precipitation hardenable alloys respectively in the same group.', t('RegardingNickel_'));
        SweToEngOtherInformation.set('Info about power source manufacturer is missing in former WPQR.', t('InfoAboutPower_'));
        SweToEngOtherInformation.set('Reviderad 2020-XX-XX m.a.p. / Revised 2020-XX-XX regarding.', t('Revised_Regarding'));
        SweToEngOtherInformation.set('Reviderad 2020-XX-XX m.a.p. företagsnamn / Revised 2020-XX-XX regarding company name.', t('Revised_RegardingCompanyName'));
        // Get data from db.
        this.props.getStandardsAll();

    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.weldingMethods !== this.props.weldingMethods) {
            this.props.setWeldingProcesses(this.props.weldingMethods);
        }
        if (prevProps.materialThicknesses !== this.props.materialThicknesses) {
            this.props.setMaterialThicknessesTemp(this.props.materialThicknesses);
            // Update also, weldingDate, companyBoxAdress, companyName

            this.props.setInitForRangeOfQualification(this.props.weldingDate, this.props.companyAdress, this.props.companyPostcode, this.props.companyCity, this.props.companyName)
        }
        if (prevProps.designations !== this.props.designations) {
            this.props.setDesignations(this.props.designations);
        }
        if (prevProps.shieldingGases !== this.props.shieldingGases) {
            this.props.setShieldingGases(this.props.shieldingGases);
        }
        if (prevProps.currentTypes !== this.props.currentTypes) {
            this.props.setCurrentTypes(this.props.currentTypes);
        }
        if (prevProps.dropletTransitions !== this.props.dropletTransitions) {
            this.props.setDropletTransitions(this.props.dropletTransitions);
        }
        if (prevProps.makes !== this.props.makes) {
            this.props.setMakes(this.props.makes);
        }
        if (prevProps.jointType !== this.props.jointType) {
            this.props.setJointType(this.props.jointType);
        }
        if (prevProps.materialGroup !== this.props.materialGroup) {
            this.props.setMaterialGroup(this.props.materialGroup);
        }
        if (prevProps.weldingMetalThickness !== this.props.weldingMetalThickness) {
            this.props.setMetalThickness(this.props.weldingMetalThickness);
        }
        if (prevProps.throatThickness !== this.props.throatThickness) {
            this.props.setThroatThickness(this.props.throatThickness);
        }
        if (prevProps.runType !== this.props.runType) {
            this.props.setRunType(this.props.runType);
        }
        if (prevProps.diameter !== this.props.diameter) {
            this.props.setDiameter(this.props.diameter);
        }
        if (prevProps.rootProtectionGas !== this.props.rootProtectionGas) {
            this.props.setRootProtectionGas(this.props.rootProtectionGas);
        }
        if (prevProps.preheatTemperature !== this.props.preheatTemperature) {
            this.props.setPreheatTemperature(this.props.preheatTemperature);
        }
        if (prevProps.interpassTemperature !== this.props.interpassTemperature) {
            this.props.setInterpassTemperature(this.props.interpassTemperature);
        }
        if (prevProps.postWeldHeatTreatment !== this.props.postWeldHeatTreatment) {
            this.props.setPostWeldHeatTreatment(this.props.postWeldHeatTreatment);
        }
        if (prevProps.pRangeOfQualificationExaminationResponse !== this.props.pRangeOfQualificationExaminationResponse) {
            this.props.setRangeOfQualificationExaminationResponse(this.props.pRangeOfQualificationExaminationResponse);
        }
        if (prevProps.pUpdateRangeOfqualification !== this.props.pUpdateRangeOfqualification) {
            this.props.clearPartOfRangeOfQualificationExamination();
        }
        if (prevProps.selectedApplicaitonStandardId !== this.props.selectedApplicaitonStandardId) {
            this.props.setStandardId(this.props.selectedApplicaitonStandardId);
        }  
        if (prevProps.updateRangeOfQualificationInfo !== this.props.updateRangeOfQualificationInfo) { // Updated from WPQRTab component
            this.props.setRangeOfQualificationInfo(this.props.updateRangeOfQualificationInfo);
        }  
    }
    private onChangeAddress(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setAddress(event.currentTarget.value);
    }
    
    private onChangeDate(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setExaminerDate(event.currentTarget.value);
    }

    private onChangeDateOfIssue(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDateOfIssue(event.currentTarget.value);
    }

    private onChangeDateOfWelding(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDateOfWelding(event.currentTarget.value);
    }

    private onChangeSetStandardText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setStandardText(event.currentTarget.value);
    }
    

    private onChangeExaminerOrExaminingBody(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setExaminer(event.currentTarget.value);
    }

    private onChangeLocation(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setLocation(event.currentTarget.value);
    }

    private onChangeManufacturer(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setManufacturer(event.currentTarget.value);
    }

    private onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setExaminerName(event.currentTarget.value);
    }

    private onChangeTestingStandards(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setTestingStandards(parseInt(event.currentTarget.value));
    }

    private onChangeEnableWeldingMetalThicknessText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableWeldingMetalThicknessText(event.currentTarget.checked, this.props.weldingMetalThickness);
    }

    private onChangeWeldingMetalThicknessText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingMetalThicknessText(event.currentTarget.value);
        if (event.currentTarget.value.length >= 2) {
            this.weldingMetalThicknessTextListSearchResultClear(); //Clear
            this.state.weldingMetalThicknessTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.weldingMetalThicknessTextListSearchResult.push(item);
                    this.weldingMetalThicknessTextListSearchResult(this.state.weldingMetalThicknessTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.weldingMetalThicknessTextList.forEach((item) => {
                this.state.weldingMetalThicknessTextListSearchResult.push(item);
                this.weldingMetalThicknessTextListSearchResult(this.state.weldingMetalThicknessTextListSearchResult)
            });
        }
    }

    private onClickSelectWeldingMetalThicknessText(value: string) {
        this.weldingMetalThicknessTextListSearchResultClear();//Clear
        this.props.setWeldingMetalThicknessText(value);
    }
    
    private onChangeEnableWeldingProcessText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableWeldingProcessText(event.currentTarget.checked, this.props.weldingMethods);
    }

    private onChangeEnableJointTypeText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableJointTypeText(event.currentTarget.checked, this.props.jointType);
    }

    private onChangeEnableStandardText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableStandardText(event.currentTarget.checked, this.props.rangeOfQualificationInfo.testingStandardId);
    }
    
    private onChangeEnableThroatThicknessText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableThroatThicknessText(event.currentTarget.checked, this.props.throatThickness);
    }

    private onChangeEnableRunTypeText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableRunTypeText(event.currentTarget.checked, this.props.runType);
    }

    private onChangeEnableDiameterText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableDiameterText(event.currentTarget.checked, this.props.diameter);
    }

    private onChangeEnableMakesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMakesText(event.currentTarget.checked, this.props.makes);
    }

    private onChangeEnableShieldingGasesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableShieldingGasesText(event.currentTarget.checked, this.props.shieldingGases);
    }
    private onChangeEnableRootProtectionGasText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableRootProtectionGasText(event.currentTarget.checked, this.props.rootProtectionGas);
    }

    private onChangeEnableCurrentTypesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableCurrentTypesText(event.currentTarget.checked, this.props.currentTypes);
    }

    private onChangeEnableDropletTransitionsText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableDropletTransitionsText(event.currentTarget.checked, this.props.dropletTransitions);
    }

    private onChangeEnableHeatInputText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableHeatInputText(event.currentTarget.checked, this.props.rangeOfQualifications.heatInput);
    }

    private onChangeEnableArcEnergyText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableArcEnergyText(event.currentTarget.checked, this.props.rangeOfQualifications.arcEnergy);
    }

    private onChangeEnablePreheatTemperatureText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnablePreheatTemperatureText(event.currentTarget.checked, this.props.preheatTemperature);
    }
    private onChangeEnableInterpassTemperatureText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableInterpassTemperatureText(event.currentTarget.checked, this.props.interpassTemperature)
    }
    private onChangeEnablePostWeldHeatTreatmentText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnablePostWeldHeatTreatmentText(event.currentTarget.checked, this.props.postWeldHeatTreatment);
    }

    private onChangeEnableDesignationsText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableDesignationsText(event.currentTarget.checked, this.props.designations);
    }

    private onChangeEnableMaterialGroupText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMaterialGroupText(event.currentTarget.checked, this.props.materialGroup);
    }

    private onChangeEnableMaterialThicknessesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setEnableMaterialThicknessesText(event.currentTarget.checked, this.props.materialThicknesses);
    }

    private onChangeMaterialThicknessesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMaterialThicknessesText(event.currentTarget.value);
        this.materialThicknessesListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.materialThicknessesList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.materialThicknessesListSearchResult.push(item);
                    this.materialThicknessesListSearchResult(this.state.materialThicknessesListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.materialThicknessesList.forEach((item) => {
                this.state.materialThicknessesListSearchResult.push(item);
                this.materialThicknessesListSearchResult(this.state.materialThicknessesListSearchResult)
            });
        }
    }

    private onClickSelectMaterialThicknesses(value: string) {
        this.materialThicknessesListSearchResultClear();//Clear
        this.props.setMaterialThicknessesText(value);
    }

    private onChangeWeldingMaterialGroupText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMaterialGroupText(event.currentTarget.value);
        this.materialGroupTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.materialGroupTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.materialGroupTextListSearchResult.push(item);
                    this.materialGroupTextListSearchResult(this.state.materialGroupTextListSearchResult);
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.materialGroupTextList.forEach((item) => {
                this.state.materialGroupTextListSearchResult.push(item);
                this.materialGroupTextListSearchResult(this.state.materialGroupTextListSearchResult);
            });
        }
    }

    private onClickSelectmaterialGroupText(value: string) {
        this.materialGroupTextListSearchResultClear();//Clear
        this.props.setMaterialGroupText(value);
    }

    private onChangeWeldingProcessText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setWeldingProcessText(event.currentTarget.value);
        this.weldingProcessListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.weldingProcessList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.weldingProcessListSearchResult.push(item)
                    this.weldingProcessListSearchResult(this.state.weldingProcessListSearchResult);
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.weldingProcessList.forEach((item) => {
                this.state.weldingProcessListSearchResult.push(item)
                this.weldingProcessListSearchResult(this.state.weldingProcessListSearchResult);
            });
        }
    }

    private onClickSelectWeldingProcess(value: string) {
        this.weldingProcessListSearchResultClear();//Clear
        this.props.setWeldingProcessText(value);
    }

    private onChangeJointTypeText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setJointTypeText(event.currentTarget.value);
        this.jointTypeListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.jointTypeList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.jointTypeListSearchResult.push(item);
                    this.jointTypeListSearchResult(this.state.jointTypeListSearchResult);
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.jointTypeList.forEach((item) => {
                this.state.jointTypeListSearchResult.push(item);
                this.jointTypeListSearchResult(this.state.jointTypeListSearchResult);
            });
        }
    }
    private onClickSelectJointType(value: string) {
        this.jointTypeListSearchResultClear();//Clear
        this.props.setJointTypeText(value);
    }

    private onChangeThroatThicknessText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setThroatThicknessText(event.currentTarget.value);
        this.throatThicknessTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.throatThicknessTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.throatThicknessTextListSearchResult.push(item);
                    this.throatThicknessTextListSearchResult(this.state.throatThicknessTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.throatThicknessTextList.forEach((item) => {
                    this.state.throatThicknessTextListSearchResult.push(item);
                    this.throatThicknessTextListSearchResult(this.state.throatThicknessTextListSearchResult)
            });
        }
    }

    private onClickSelectThroatThicknessText(value: string) {
        this.throatThicknessTextListSearchResultClear();//Clear
        this.props.setThroatThicknessText(value);
    }

    private onChangeRunTypesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setRunTypesText(event.currentTarget.value);
        this.runTypeTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.runTypeTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.runTypeTextListSearchResult.push(item);
                    this.runTypeTextListSearchResult(this.state.runTypeTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.runTypeTextList.forEach((item) => {
                this.state.runTypeTextListSearchResult.push(item);
                this.runTypeTextListSearchResult(this.state.runTypeTextListSearchResult)
            });
        }
    }

    private onClickSelectRunTypeText(value: string) {
        this.runTypeTextListSearchResultClear();//Clear
        this.props.setRunTypesText(value);
    }

    private onChangeDiameterText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDiameterText(event.currentTarget.value);
        this.diameterTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.diameterTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.diameterTextListSearchResult.push(item);
                    this.diameterTextListSearchResult(this.state.diameterTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.diameterTextList.forEach((item) => {
                    this.state.diameterTextListSearchResult.push(item);
                    this.diameterTextListSearchResult(this.state.diameterTextListSearchResult)
            });
        }
    }

    private onClickSelectDiameterText(value: string) {
        this.diameterTextListSearchResultClear();//Clear
        this.props.setDiameterText(value);
    }

    private onChangeDesignationsText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDesignationsText(event.currentTarget.value);
        this.designationsListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.designationsList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.designationsListSearchResult.push(item);
                    this.designationsListSearchResult(this.state.designationsListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.designationsList.forEach((item) => {
                this.state.designationsListSearchResult.push(item);
                this.designationsListSearchResult(this.state.designationsListSearchResult)
            });
        }
    }

    private onClickSelectDesignations(value: string) {
        this.designationsListSearchResultClear();//Clear
        this.props.setDesignationsText(value);
    }

    private onChangeMakesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setMakesText(event.currentTarget.value);
        this.makesListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.makesList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.makesListSearchResult.push(item);
                    this.makesListSearchResult(this.state.makesListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.makesList.forEach((item) => {
                this.state.makesListSearchResult.push(item);
                this.makesListSearchResult(this.state.makesListSearchResult)
            });
        }
    }

    private onClickSelectMakes(value: string) {
        this.makesListSearchResultClear();//Clear
        this.props.setMakesText(value);
    }

    private onChangeShieldingGasesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setShieldingGasesText(event.currentTarget.value);
        this.shieldingGasesListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.shieldingGasesList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.shieldingGasesListSearchResult.push(item);
                    this.shieldingGasesListSearchResult(this.state.shieldingGasesListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.shieldingGasesList.forEach((item) => {
                this.state.shieldingGasesListSearchResult.push(item);
                this.shieldingGasesListSearchResult(this.state.shieldingGasesListSearchResult)
            });
        }
    }

    private onClickSelectShieldingGases(value: string) {
        this.shieldingGasesListSearchResultClear();//Clear
        this.props.setShieldingGasesText(value);
    }

    private onChangeRootProtectionGasText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setRootProtectionGasText(event.currentTarget.value);
        this.rootProtectionGasTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.rootProtectionGasTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.rootProtectionGasTextListSearchResult.push(item);
                    this.rootProtectionGasTextListSearchResult(this.state.rootProtectionGasTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.rootProtectionGasTextList.forEach((item) => {
                this.state.rootProtectionGasTextListSearchResult.push(item);
                this.rootProtectionGasTextListSearchResult(this.state.rootProtectionGasTextListSearchResult)
            });
        }
    }

    private onClickSelectRootProtectionGasText(value: string) {
        this.rootProtectionGasTextListSearchResultClear();//Clear
        this.props.setRootProtectionGasText(value);
    }

    private onChangeDropletTransitionsText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setDropletTransitionsText(event.currentTarget.value); 
        this.dropletTransitionsListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.dropletTransitionsList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.dropletTransitionsListSearchResult.push(item);
                    this.dropletTransitionsListSearchResult(this.state.dropletTransitionsListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.dropletTransitionsList.forEach((item) => {
                this.state.dropletTransitionsListSearchResult.push(item);
                this.dropletTransitionsListSearchResult(this.state.dropletTransitionsListSearchResult)
            });
        }
    }

    private onClickSelectDropletTransitionsText(value: string) {
        this.dropletTransitionsListSearchResultClear();//Clear
        this.props.setDropletTransitionsText(value);
    }

    private onChangeHeatInputText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setHeatInputText(event.currentTarget.value);
        this.heatInputTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.heatInputTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.heatInputTextListSearchResult.push(item);
                    this.heatInputTextListSearchResult(this.state.heatInputTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.heatInputTextList.forEach((item) => {
                this.state.heatInputTextListSearchResult.push(item);
                this.heatInputTextListSearchResult(this.state.heatInputTextListSearchResult)
            });
        }
    }

    private onClickSelectHeatInputText(value: string) {
        this.heatInputTextListSearchResultClear();//Clear
        this.props.setHeatInputText(value);
    }

    private onChangeArcEnergyText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setArcEnergyText(event.currentTarget.value);
        this.arcEnergyTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.arcEnergyTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.arcEnergyTextListSearchResult.push(item);
                    this.arcEnergyTextListSearchResult(this.state.arcEnergyTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.arcEnergyTextList.forEach((item) => {
                this.state.arcEnergyTextListSearchResult.push(item);
                this.arcEnergyTextListSearchResult(this.state.arcEnergyTextListSearchResult)
            });
        }
    }

    private onClickSelectArcEnergyText(value: string) {
        this.arcEnergyTextListSearchResultClear();//Clear
        this.props.setArcEnergyText(value);
    }

    private onChangeInterpassTemperatureText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setInterpassTemperatureText(event.currentTarget.value);
        this.interpassTemperatureTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.interpassTemperatureTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.interpassTemperatureTextListSearchResult.push(item);
                    this.interpassTemperatureTextListSearchResult(this.state.interpassTemperatureTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.interpassTemperatureTextList.forEach((item) => {
                this.state.interpassTemperatureTextListSearchResult.push(item);
                this.interpassTemperatureTextListSearchResult(this.state.interpassTemperatureTextListSearchResult)
            });
        }
    }

    private onClickSelectInterpassTemperatureText(value: string) {
        this.interpassTemperatureTextListSearchResultClear();//Clear
        this.props.setInterpassTemperatureText(value);
    }

    private onChangePreheatTemperatureText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setPreheatTemperatureText(event.currentTarget.value);
        this.preheatTemperatureTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.preheatTemperatureTextList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.preheatTemperatureTextListSearchResult.push(item);
                    this.preheatTemperatureTextListSearchResult(this.state.preheatTemperatureTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.preheatTemperatureTextList.forEach((item) => {
                this.state.preheatTemperatureTextListSearchResult.push(item);
                this.preheatTemperatureTextListSearchResult(this.state.preheatTemperatureTextListSearchResult)
            });
        }
    }
    private onClickSelectPreheatTemperatureText(value: string) {
        this.preheatTemperatureTextListSearchResultClear();//Clear
        this.props.setPreheatTemperatureText(value);
    }

    private onChangePostWeldHeatTreatmentText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setPostWeldHeatTreatmentText(event.currentTarget.value);
        this.postWeldHeatTreatmentTextListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.postWeldHeatTreatmentTextList.forEach((item) => {
                var value = item; 
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.postWeldHeatTreatmentTextListSearchResult.push(item);
                    this.postWeldHeatTreatmentTextListSearchResult(this.state.postWeldHeatTreatmentTextListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.postWeldHeatTreatmentTextList.forEach((item) => {
                this.state.postWeldHeatTreatmentTextListSearchResult.push(item);
                this.postWeldHeatTreatmentTextListSearchResult(this.state.postWeldHeatTreatmentTextListSearchResult)
            });
        }
    }

    private onClickSelectPostWeldHeatTreatmentTex(value: string) {
        this.postWeldHeatTreatmentTextListSearchResultClear();//Clear
        this.props.setPostWeldHeatTreatmentText(value);
    }

    private onChangeCurrentTypesText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setCurrentTypesText(event.currentTarget.value);
        this.currentTypesListSearchResultClear(); //Clear
        if (event.currentTarget.value.length >= 2) {
            this.state.currentTypesList.forEach((item) => {
                var value = item;
                if (value.toLowerCase().includes(event.currentTarget.value.toLowerCase())) {
                    this.state.currentTypesListSearchResult.push(item);
                    this.currentTypesListSearchResult(this.state.currentTypesListSearchResult)
                }
            });
        }
        else if (event.currentTarget.value.length === 1) {
            this.state.currentTypesList.forEach((item) => {
                this.state.currentTypesListSearchResult.push(item);
                this.currentTypesListSearchResult(this.state.currentTypesListSearchResult)
            });
        }
    }
    private onClickSelectCurrentTypes(value: string) {
        this.currentTypesListSearchResultClear(); //Clear
        this.props.setCurrentTypesText(value);
    }
    
    private onChangeWeldingPositionText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        if (event.currentTarget.value.length > 0) {
            this.props.setOnChangeWeldingPositionText(event.currentTarget.value);
        }
        else {
            this.props.clearWeldingPositionText();
        }
    }
    private onChangeOtherInformationsText(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.props.setOnChangeOtherInformationsText(event.currentTarget.value);
    }

    private onClickWeldingPosition(weldingPositionText: string) {
        this.props.setWeldingPositionText(weldingPositionText);
    }

    private onClickOtherInformations(text: string) {
        this.toggleDropDown1();
        this.props.setOtherInformations(text);
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Form>
                    <WPQRPageHeader pCommissionNumber=""  pManufacturerWPQRNumber="" pDekraProcedureNumber="" pageNumber={'1/4'} />
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={6}>
                            <h5>{t('WELDINGPROCEDURE_')}</h5>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            {this.renderRangeOfQualificationInfoTable()}
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={6}>
                            <h5>{t('RangeOfQualification')}</h5>
                        </Col>
                        <Col sm={6}>
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            {this.renderRangeOfQualificationsTable()}
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            {this.renderInfoText()}
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm={6}>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="rangeOfQualificationLocation" size="sm">Plats <small>Location</small></Label>
                                        <Input type="text" bsSize="sm" name="rangeOfQualificationLocation" id="rangeOfQualificationLocation"
                                            value={this.props.rangeOfQualificationInfo.location}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeLocation(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="rangeOfQualificationDateOfIssue" size="sm">Utskriftsdatum <small>Date of issue</small></Label>
                                        <Input type="date" max="9999-12-30" bsSize="sm" name="rangeOfQualificationDateOfIssue" id="rangeOfQualificationDateOfIssue"
                                            value={this.props.rangeOfQualificationInfo.dateOfIssue}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeDateOfIssue(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6}>
                            <Row form>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label for="rangeOfQualificationExaminerOrExaminingBody" size="sm">{t('ExaminerOrExaminingGroup')}</Label>
                                        <Input type="text" bsSize="sm" name="rangeOfQualificationExaminerOrExaminingBody" id="rangeOfQualificationExaminerOrExaminingBody"
                                            value={this.props.pExaminer}
                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeExaminerOrExaminingBody(event)}
                                            placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="rangeOfQualificationName" size="sm">Namn <small>Name</small></Label>
                                        <Input type="text" bsSize="sm" name="rangeOfQualificationName" id="rangeOfQualificationName"
                                            value={this.props.pExaminerName}
                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeName(event)}
                                        placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="rangeOfQualificationDate" size="sm">Datum <small>Date</small></Label>
                                    <Input type="date" max="9999-12-30" bsSize="sm" name="rangeOfQualificationDate" id="rangeOfQualificationDate"
                                        value={this.props.pExaminerDate}
                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeDate(event)}
                                    placeholder="" />
                                </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

    private renderInfoText() {
        return (
            <React.Fragment>
                <FormText color="dark">
                    <h6>Härmed intygas att provsvetsar bereddes, svetsades och provades med tillfredsställande resultat enligt fordringarna i ovan angivna standarder. <small>It is hereby certified that test welds were prepared, welded and tested satisfactorily in accordance with the requirements of the standards indicated above.</small></h6>
                </FormText>
            </React.Fragment>
        );
    }

    private renderRangeOfQualificationInfoTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive borderless>
                    <tbody>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('Manufacturer')}</h6>
                            </td>
                            <td style={{ width: '5%' }}  hidden={this.props.hiddenToShowExistingWpqrForm}>
                            </td>
                            <td style={{ width: '55%' }}>
                                <Input type="text" bsSize="sm" name="rangeOfQualificationManufacturer" id="rangeOfQualificationManufacturer"
                                    value={this.props.rangeOfQualificationInfo.manufacturer}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeManufacturer(event)}
                                    placeholder="" />
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('Address')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                            </td>
                            <td style={{ width: '55%' }}>
                                <Input type="text" bsSize="sm" name="rangeOfQualificationAddress" id="rangeOfQualificationAddress"
                                    value={this.props.rangeOfQualificationInfo.address}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeAddress(event)}
                                    placeholder="" />
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('StandardForQualification')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Label check size="sm">
                                            <Input type="checkbox" bssize="sm" name="qwbpEnableStandardText" id="qwbpEnableStandardText"
                                                checked={this.props.rangeOfQualificationInfo.enableStandardText}
                                                onChange={(event) => this.onChangeEnableStandardText(event)}
                                                placeholder="" />{' '} {t('Revise')}
                                        </Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableStandardText) ?
                                    <FormGroup>
                                        <InputGroup>
                                            <Input type="text" bsSize="sm" name="qwbpStandardText" id="qwbpStandardText"
                                                value={this.props.rangeOfQualificationInfo.standardText} onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeSetStandardText(event)}
                                                placeholder="" />
                                            <DropdownLists listNumber={29} dropDownListsCallback={this.props.setStandardText} rowNumber={0} ></DropdownLists>
                                        </InputGroup>
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input type="select" bssize="sm" name="testingStandardId" id="testingStandardId"
                                            value={this.props.rangeOfQualificationInfo.testingStandardId}
                                            onChange={(event) => this.onChangeTestingStandards(event)}
                                            placeholder="">
                                            <option key="btestingStandardId0" id="testingStandardId0" value="0"></option>
                                            {this.props.standards.map((standard, index) => (
                                                <option key={"testingStandardId" + standard.id} id={"testingStandardId" + standard.id} value={standard.id}>{standard.name}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                }
                                
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('DateOfWelding')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                            </td>
                            <td style={{ width: '55%' }}>
                                <Input type="date" max="9999-12-30" bsSize="sm" name="rangeOfQualificationDateOfWelding" id="rangeOfQualificationDateOfWelding"
                                    value={this.props.rangeOfQualificationInfo.dateOfWelding}
                                    onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeDateOfWelding(event)}
                                    placeholder="" />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    private renderRangeOfQualificationsTable() {
        return (
            <React.Fragment>
                <Table size="sm" responsive borderless>
                    <tbody>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('WeldingProcess')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Label check size="sm">
                                            <Input type="checkbox" bssize="sm" name="qwbpEnableWeldingProcess" id="qwbpEnableWeldingProcess"
                                                checked={this.props.rangeOfQualificationInfo.enableWeldingProcessText}
                                                onChange={(event) => this.onChangeEnableWeldingProcessText(event)}
                                                placeholder="" />{' '} {t('Revise')}
                                            </Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableWeldingProcessText) ?
                                    <FormGroup>
                                        <Input type="textarea" readOnly bssize="sm" rows={this.props.rangeOfQualificationInfo.weldingProcess.split("\n").length} bsSize="sm" name="rangeOfQualificationWeldingProcess" id="rangeOfQualificationWeldingProcess"
                                            value={this.props.rangeOfQualificationInfo.weldingProcess}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input type="textarea" bssize="sm" rows={this.props.rangeOfQualificationInfo.weldingProcess.split("\n").length} bsSize="sm" name="rangeOfQualificationWeldingProcessText" id="rangeOfQualificationWeldingProcessText"
                                            value={this.props.rangeOfQualificationInfo.weldingProcess} onChange={(event) => this.onChangeWeldingProcessText(event)}
                                            placeholder="" />
                                        {this.state.weldingProcessListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.weldingProcessListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectWeldingProcess(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('TypeOfJointAndWeld')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpEnableJointType" id="qwbpEnableJointType"
                                            checked={this.props.rangeOfQualificationInfo.enableJointTypeText}
                                            onChange={(event) => this.onChangeEnableJointTypeText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || (this.props.hiddenToShowExistingWpqrForm ||this.props.rangeOfQualificationInfo.enableJointTypeText)) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationJointType" id="rangeOfQualificationJointType"
                                            value={this.props.rangeOfQualificationInfo.jointType}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationtext" id="rangeOfQualificationText"
                                            value={this.props.rangeOfQualificationInfo.jointType}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeJointTypeText(event)}
                                            placeholder="" />
                                        {this.state.jointTypeListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.jointTypeListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectJointType(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('ParentMaterialGroups')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpEnableMaterialGroupTex" id="qwbpEnableMaterialGroupTex"
                                            checked={this.props.rangeOfQualificationInfo.enableMaterialGroupText}
                                            onChange={(event) => this.onChangeEnableMaterialGroupText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableMaterialGroupText) ?
                                    <FormGroup>
                                        <Input readOnly type="text" bsSize="sm" name="rangeOfQualificationMaterialGroup" id="rangeOfQualificationMaterialGroup"
                                            value={this.props.rangeOfQualificationInfo.materialGroupText}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationMaterialGroupText" id="rangeOfQualificationMaterialGroupText"
                                            value={this.props.rangeOfQualificationInfo.materialGroupText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingMaterialGroupText(event)}
                                            placeholder="" />
                                        {this.state.materialGroupTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.materialGroupTextListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectmaterialGroupText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('ParentMaterialThickness')} (mm)</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpEnableMaterialThicknessesText" id="qwbpEnableMaterialThicknessesText"
                                            checked={this.props.rangeOfQualificationInfo.enableMaterialThicknessesText}
                                            onChange={(event) => this.onChangeEnableMaterialThicknessesText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableMaterialThicknessesText) ?
                                    <FormGroup>
                                        <Input type="textarea" readOnly bssize="sm" rows={this.props.rangeOfQualificationInfo.materialThicknesses.split("\n").length} bsSize="sm"
                                            name="rangeOfQualificationMaterialThicknessesTemp" id="rangeOfQualificationMaterialThicknessesTemp"
                                            value={this.props.rangeOfQualificationInfo.materialThicknesses}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input type="textarea" bssize="sm" rows={this.props.rangeOfQualificationInfo.materialThicknesses.split("\n").length} bsSize="sm"
                                            name="rangeOfQualificationMaterialThicknesses" id="rangeOfQualificationMaterialThicknesses"
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMaterialThicknessesText(event)}
                                            value={this.props.rangeOfQualificationInfo.materialThicknesses}
                                            placeholder="" />
                                        {this.state.materialThicknessesListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.materialThicknessesListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectMaterialThicknesses(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('WeldMetalThickness')} (mm)</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpEnableWeldingMetalThicknessText" id="qwbpEnableWeldingMetalThicknessText"
                                            checked={this.props.rangeOfQualificationInfo.enableWeldingMetalThicknessText}
                                            onChange={(event) => this.onChangeEnableWeldingMetalThicknessText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableWeldingMetalThicknessText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationTestingWeldingMetalThickness" id="rangeOfQualificationTestingWeldingMetalThickness"
                                            value={this.props.rangeOfQualificationInfo.weldingMetalThicknessText}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationTestingWeldingMetalThicknessText" id="rangeOfQualificationTestingWeldingMetalThicknessText"
                                            value={this.props.rangeOfQualificationInfo.weldingMetalThicknessText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeWeldingMetalThicknessText(event)}
                                            placeholder="" />
                                        {this.state.weldingMetalThicknessTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.weldingMetalThicknessTextListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectWeldingMetalThicknessText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('ThroatThickness')} (mm)</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableThroatThicknessTextg" id="qwbpenableThroatThicknessText"
                                            checked={this.props.rangeOfQualificationInfo.enableThroatThicknessText}
                                            onChange={(event) => this.onChangeEnableThroatThicknessText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableThroatThicknessText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationTestingThroatThickness" id="rangeOfQualificationTestingThroatThickness"
                                            value={this.props.throatThickness}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationThroatThicknessText" id="rangeOfQualificationThroatThicknessText"
                                            value={this.props.rangeOfQualificationInfo.throatThicknessText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeThroatThicknessText(event)}
                                            placeholder="" />
                                        {this.state.throatThicknessTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.throatThicknessTextListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectThroatThicknessText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('SingleRunMultiRun')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableRunTypeText" id="qwbpenableRunTypeText"
                                            checked={this.props.rangeOfQualificationInfo.enableRunTypeText}
                                            onChange={(event) => this.onChangeEnableRunTypeText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableRunTypeText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationRunType" id="rangeOfQualificationRunType"
                                            value={this.props.rangeOfQualificationInfo.runTypeText}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationRunTypeText" id="rangeOfQualificationRunTypeText"
                                            value={this.props.rangeOfQualificationInfo.runTypeText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeRunTypesText(event)}
                                            placeholder="" />
                                        {this.state.runTypeTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.runTypeTextListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectRunTypeText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('OutsidePipeDiameter')} (mm)</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableDiameterText" id="qwbpenableDiameterText"
                                            checked={this.props.rangeOfQualificationInfo.enableDiameterText}
                                            onChange={(event) => this.onChangeEnableDiameterText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableDiameterText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationDiameter" id="rangeOfQualificationDiameter"
                                            value={this.props.rangeOfQualificationInfo.diameterText}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationDiameterText" id="rangeOfQualificationDiameterText"
                                            value={this.props.rangeOfQualificationInfo.diameterText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeDiameterText(event)}
                                            placeholder="" />
                                        {this.state.diameterTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.diameterTextListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectDiameterText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('FillerMaterialMake')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableDesignationsText" id="qwbpenableDesignationsText"
                                            checked={this.props.rangeOfQualificationInfo.enableDesignationsText}
                                            onChange={(event) => this.onChangeEnableDesignationsText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableDesignationsText) ?
                                    <FormGroup>
                                        <Input type="textarea" readOnly rows={this.props.rangeOfQualificationInfo.designations.split("\n").length} bsSize="sm" name="rangeOfQualificationDesignationsTemp" id="rangeOfQualificationDesignationsTemp"
                                            value={this.props.rangeOfQualificationInfo.designations}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input type="textarea" bsSize="sm" rows={this.props.rangeOfQualificationInfo.designations.split("\n").length} name="rangeOfQualificationDesignationsText" id="rangeOfQualificationDesignationsText"
                                            value={this.props.rangeOfQualificationInfo.designations}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeDesignationsText(event)}
                                            placeholder="" />
                                        {this.state.designationsListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.designationsListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectDesignations(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('MakeAdditivesPowder')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableMakesText" id="qwbpenableMakesTextt"
                                            checked={this.props.rangeOfQualificationInfo.enableMakesText}
                                            onChange={(event) => this.onChangeEnableMakesText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableMakesText) ?
                                    <FormGroup>
                                        <Input type="textarea" readOnly rows={this.props.rangeOfQualificationInfo.makes.split("\n").length} bsSize="sm" name="rangeOfQualificationMakes" id="rangeOfQualificationMakes"
                                            value={this.props.rangeOfQualificationInfo.makes}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input type="textarea" bsSize="sm" rows={this.props.rangeOfQualificationInfo.makes.split("\n").length} name="rangeOfQualificationMakes" id="rangeOfQualificationMakes"
                                            value={this.props.rangeOfQualificationInfo.makes}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeMakesText(event)}
                                            placeholder="" />
                                        {this.state.makesListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.makesListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectMakes(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('ShieldingGasFluxDesignation')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableGasesText" id="qwbpenableGasesTextt"
                                            checked={this.props.rangeOfQualificationInfo.enableShieldingGasesText}
                                            onChange={(event) => this.onChangeEnableShieldingGasesText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableShieldingGasesText) ?
                                    <FormGroup>
                                        <Input type="textarea" readOnly rows={this.props.rangeOfQualificationInfo.shieldingGases.split("\n").length} bsSize="sm"
                                            name="rangeOfQualificationShieldingGases" id="rangeOfQualificationShieldingGases"
                                            value={this.props.rangeOfQualificationInfo.shieldingGases}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input type="textarea" bsSize="sm" rows={this.props.rangeOfQualificationInfo.shieldingGases.split("\n").length} name="rangeOfQualificationShieldingGases" id="rangeOfQualificationShieldingGases"
                                            value={this.props.rangeOfQualificationInfo.shieldingGases}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeShieldingGasesText(event)}
                                            placeholder="" />
                                        {this.state.shieldingGasesListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.shieldingGasesListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectShieldingGases(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('BackingGasDesignation')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableRootProtectionGasText" id="qwbpenableRootProtectionGasText"
                                            checked={this.props.rangeOfQualificationInfo.enableRootProtectionGasText}
                                            onChange={(event) => this.onChangeEnableRootProtectionGasText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableRootProtectionGasText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationRootProtectionGas" id="rangeOfQualificationRootProtectionGas"
                                            value={this.props.rangeOfQualificationInfo.rootProtectionGasText}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationRootProtectionGasText" id="rangeOfQualificationRootProtectionGasText"
                                            value={this.props.rangeOfQualificationInfo.rootProtectionGasText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeRootProtectionGasText(event)}
                                            placeholder="" />
                                        {this.state.rootProtectionGasTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.rootProtectionGasTextListSearchResult.map((textValue) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectRootProtectionGasText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('TypeOfWeldingCurrentAndPolarity')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableCurrentTypesTextt" id="qwbpenableCurrentTypesText"
                                            checked={this.props.rangeOfQualificationInfo.enableCurrentTypesText}
                                            onChange={(event) => this.onChangeEnableCurrentTypesText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableCurrentTypesText) ?
                                    <FormGroup>
                                        <Input type="textarea" readOnly rows={this.props.rangeOfQualificationInfo.currentTypes.split("\n").length} bsSize="sm"
                                            name="rangeOfQualificationCurrentTypesTemp" id="rangeOfQualificationCurrentTypesTemp"
                                            value={this.props.rangeOfQualificationInfo.currentTypes}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input type="textarea" rows={this.props.rangeOfQualificationInfo.currentTypes.split("\n").length} bsSize="sm"
                                            name="rangeOfQualificationCurrentTypes" id="rangeOfQualificationCurrentTypes"
                                            value={this.props.rangeOfQualificationInfo.currentTypes}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeCurrentTypesText(event)}
                                            placeholder="" />
                                        {this.state.currentTypesListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.currentTypesListSearchResult.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectCurrentTypes(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('ModeOfMetalTransfer')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableDropletTransitionsText" id="qwbpenableDropletTransitionsText"
                                            checked={this.props.rangeOfQualificationInfo.enableDropletTransitionsText}
                                            onChange={(event) => this.onChangeEnableDropletTransitionsText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableDropletTransitionsText) ?
                                    <FormGroup>
                                        <Input readOnly type="textarea" rows={this.props.rangeOfQualificationInfo.dropletTransitions.split("\n").length} bsSize="sm"
                                            name="rangeOfQualificationDropletTransitionTemp" id="rangeOfQualificationDropletTransitionTemp"
                                            value={this.props.rangeOfQualificationInfo.dropletTransitions}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input type="textarea" rows={this.props.rangeOfQualificationInfo.dropletTransitions.split("\n").length} bsSize="sm"
                                            name="rangeOfQualificationDropletTransition" id="rangeOfQualificationDropletTransition"
                                            value={this.props.rangeOfQualificationInfo.dropletTransitions}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeDropletTransitionsText(event)}
                                            placeholder="" />
                                        {this.state.dropletTransitionsListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.dropletTransitionsListSearchResult.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectDropletTransitionsText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('HeatInput')}, Q</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Input type="checkbox" bssize="sm" name="qwbpenableHeatInputText" id="qwbpenableHeatInputText"
                                            checked={this.props.rangeOfQualificationInfo.enableHeatInputText}
                                            onChange={(event) => this.onChangeEnableHeatInputText(event)}
                                            placeholder="" />
                                        <Label check size="sm">{' '} {t('Revise')}</Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableHeatInputText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationHeatInput" id="rangeOfQualificationHeatInput"
                                            value={this.props.rangeOfQualifications.heatInput}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input type="textarea" rows={this.props.rangeOfQualificationInfo.heatInputText.split("\n").length} bsSize="sm" name="rangeOfQualificationHeatInput" id="rangeOfQualificationHeatInput"
                                            value={this.props.rangeOfQualificationInfo.heatInputText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeHeatInputText(event)}
                                            placeholder="" />
                                        {this.state.heatInputTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.heatInputTextListSearchResult.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectHeatInputText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('ArcEnergy')}, E</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Label check size="sm">
                                            <Input type="checkbox" bssize="sm" name="qwbpEnableArcEnergyText" id="qwbpEnableArcEnergyText"
                                                checked={this.props.rangeOfQualificationInfo.enableArcEnergyText}
                                                onChange={(event) => this.onChangeEnableArcEnergyText(event)}
                                                placeholder="" />{' '} {t('Revise')}
                                            </Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableArcEnergyText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationArcEnergyTemp" id="rangeOfQualificationArcEnergyTemp"
                                            value={this.props.rangeOfQualifications.arcEnergy}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input tautoComplete="off" type="textarea" rows={this.props.rangeOfQualificationInfo.arcEnergyText.split("\n").length} bsSize="sm" name="rangeOfQualificationArcEnergy" id="rangeOfQualificationArcEnergy"
                                            value={this.props.rangeOfQualificationInfo.arcEnergyText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeArcEnergyText(event)}
                                            placeholder="" />
                                        {this.state.arcEnergyTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.arcEnergyTextListSearchResult.map((textValue) => (
                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectArcEnergyText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('WeldingPositions')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                            </td>
                            <td style={{ width: '55%' }}>
                                <FormGroup>
                                    <InputGroup>
                                        <Input autoComplete="off" type="search" bssize="sm" name="weldingDataTungstenElectrode" id="weldingDataTungstenElectrode"
                                            value={this.props.rangeOfQualificationInfo.weldingPositionsText}
                                            onChange={(event) => this.onChangeWeldingPositionText(event)}
                                            onKeyPress={(event) => this.onKeyPress(event)}
                                            placeholder="" />
                                        <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                            <DropdownToggle caret size="sm" className="btn btn-primary">
                                            </DropdownToggle>
                                            <DropdownMenu size="sm">
                                                {this.props.weldingPositionResult.map((weldingPosition) => (
                                                    <div className="btn text-left" key={"weldingPositionId" + weldingPosition.id}
                                                        id={"weldingPositionId" + weldingPosition.id}
                                                        onClick={(event) => this.onClickWeldingPosition(weldingPosition.name)}><div style={{ width: "100%" }}><a>{this.TranslateBoxes(weldingPosition.name, SweToEngWeldingPositions)}</a></div></div>
                                                ))}
                                            </DropdownMenu>
                                        </InputGroupButtonDropdown>
                                    </InputGroup>
                                </FormGroup>
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('PreheatTemperature')} (ºC)</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Label check size="sm">
                                            <Input type="checkbox" bssize="sm" name="qwbpEnablePreheatTemperatureText" id="qwbpEnablePreheatTemperatureText"
                                                checked={this.props.rangeOfQualificationInfo.enablePreheatTemperatureText}
                                                onChange={(event) => this.onChangeEnablePreheatTemperatureText(event)}
                                                placeholder="" />{' '} {t('Revise')}
                                            </Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enablePreheatTemperatureText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationPreheatTemperature" id="rangeOfQualificationPreheatTemperature"
                                            value={this.props.rangeOfQualificationInfo.preheatTemperatureText}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationPreheatTemperatureText" id="rangeOfQualificationPreheatTemperatureText"
                                            value={this.props.rangeOfQualificationInfo.preheatTemperatureText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangePreheatTemperatureText(event)}
                                            placeholder="" />
                                        {this.state.preheatTemperatureTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                            {this.state.preheatTemperatureTextListSearchResult.map((textValue, index) => (
                                                <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectPreheatTemperatureText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('InterpassTemperature')} (ºC)</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Label check size="sm">
                                            <Input type="checkbox" bssize="sm" name="qwbpEnableInterpassTemperatureText" id="qwbpEnableInterpassTemperatureText"
                                                checked={this.props.rangeOfQualificationInfo.enableInterpassTemperatureText}
                                                onChange={(event) => this.onChangeEnableInterpassTemperatureText(event)}
                                                placeholder="" />{' '} {t('Revise')}
                                            </Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enableInterpassTemperatureText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationInterpassTemperature" id="rangeOfQualificationInterpassTemperature"
                                            value={this.props.rangeOfQualificationInfo.interpassTemperatureText}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationInterpassTemperatureText" id="rangeOfQualificationInterpassTemperatureText"
                                            value={this.props.rangeOfQualificationInfo.interpassTemperatureText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangeInterpassTemperatureText(event)}
                                            placeholder="" />
                                        {this.state.interpassTemperatureTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                                {this.state.interpassTemperatureTextListSearchResult.map((textValue, index) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectInterpassTemperatureText(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('PostWeldHeatTreatmentAgeing')} (ºC)</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                                <Form inline>
                                    <FormGroup check>
                                        <Label check size="sm">
                                            <Input type="checkbox" bssize="sm" name="qwbpEnablePostWeldHeatTreatmentText" id="qwbpEnablePostWeldHeatTreatmentText"
                                                checked={this.props.rangeOfQualificationInfo.enablePostWeldHeatTreatmentText}
                                                onChange={(event) => this.onChangeEnablePostWeldHeatTreatmentText(event)}
                                                placeholder="" />{' '} {t('Revise')}
                                            </Label>
                                    </FormGroup>
                                </Form>
                            </td>
                            <td style={{ width: '55%' }}>
                                {!(this.props.hiddenToShowExistingWpqrForm || this.props.rangeOfQualificationInfo.enablePostWeldHeatTreatmentText) ?
                                    <FormGroup>
                                        <Input type="text" readOnly bsSize="sm" name="rangeOfQualificationPostWeldHeatTreatmentAndOrAgeing" id="rangeOfQualificationPostWeldHeatTreatmentAndOrAgeing"
                                            value={this.props.rangeOfQualificationInfo.postWeldHeatTreatmentText}
                                            placeholder="" />
                                    </FormGroup> :
                                    <FormGroup>
                                        <Input autoComplete="off" type="search" bsSize="sm" name="rangeOfQualificationPostWeldHeatTreatmentAndOrAgeingText" id="rangeOfQualificationPostWeldHeatTreatmentAndOrAgeingText"
                                            value={this.props.rangeOfQualificationInfo.postWeldHeatTreatmentText}
                                            onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.onChangePostWeldHeatTreatmentText(event)}
                                            placeholder="" />
                                        {this.state.postWeldHeatTreatmentTextListSearchResult.length !== 0 &&
                                            <ListGroup className="scroll-vertical-width">
                                                {this.state.postWeldHeatTreatmentTextListSearchResult.map((textValue, index) => (
                                                    <ListGroupItem tag="button" action onClick={(event) => this.onClickSelectPostWeldHeatTreatmentTex(textValue)}>
                                                        {textValue}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        }
                                    </FormGroup>
                                }
                            </td>
                        </tr>
                        <tr className="td-vertical-align">
                            <td style={{ width: '40%' }}>
                                <h6>{t('OtherInformation')}</h6>
                            </td>
                            <td style={{ width: '5%' }} hidden={this.props.hiddenToShowExistingWpqrForm}>
                            </td>
                            <td style={{ width: '55%' }}>
                                <FormGroup>
                                    <Dropdown addonType="append" isOpen={this.state.dropdownOpen1} toggle={this.toggleDropDown1}>
                                        <DropdownToggle caret size="sm" className="btn btn-primary">{t('ChooseText')}
                                        </DropdownToggle>
                                        <DropdownMenu size="sm">
                                            {this.props.otherInformations.map((otherInformation) => (
                                                <div className="btn text-left" key={"otherInformationId" + otherInformation.id} id={"otherInformationd" + otherInformation.id} onClick={(event) => this.onClickOtherInformations(otherInformation.rangeOfQualification)}><div style={{ width: "100%" }}><a>{otherInformation.rangeOfQualification}</a></div></div>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Input type="textarea" rows={this.props.rangeOfQualificationInfo.otherInformations.split("\n").length} bssize="sm" name="rangeOfQualificationOtherInformations" id="rangeOfQualificationOtherInformations"
                                        value={this.props.rangeOfQualificationInfo.otherInformations}
                                        onChange={(event) => this.onChangeOtherInformationsText(event)}
                                    />
                                </FormGroup>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.rangeOfQualification;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    RangeOfQualificationStore.actionCreators // Selects which action creators are merged into the component's props
)(RangeOfQualification as any);
