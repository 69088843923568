﻿import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledAlert } from 'reactstrap';
const welDBaseLogo = require('../images/DEKRAwelDBase-scale-alpha.png');
const sequenceImage1 = require('../images/weldSequence/sequenceImage1.png');
const sequenceImage2 = require('../images/weldSequence/sequenceImage2.png');
const sequenceImage3 = require('../images/weldSequence/sequenceImage3.png');
const sequenceImage4 = require('../images/weldSequence/sequenceImage4.png');
const sequenceImage5 = require('../images/weldSequence/sequenceImage5.png');
const sequenceImage6 = require('../images/weldSequence/sequenceImage6.png');
const sequenceImage7 = require('../images/weldSequence/sequenceImage7.png');
const sequenceImage8 = require('../images/weldSequence/sequenceImage8.png');
const sequenceImage9 = require('../images/weldSequence/sequenceImage9.png');
const sequenceImage10 = require('../images/weldSequence/sequenceImage10.png');
const sequenceImage11 = require('../images/weldSequence/sequenceImage11.png');
const sequenceImage12 = require('../images/weldSequence/sequenceImage12.png');
const sequenceImage13 = require('../images/weldSequence/sequenceImage13.png');
const sequenceImage14 = require('../images/weldSequence/sequenceImage14.png');
const sequenceImage15 = require('../images/weldSequence/sequenceImage15.png');
const sequenceImage16 = require('../images/weldSequence/sequenceImage16.png');
const sequenceImage17 = require('../images/weldSequence/sequenceImage17.png');
const sequenceImage18 = require('../images/weldSequence/sequenceImage18.png');
const sequenceImage19 = require('../images/weldSequence/sequenceImage19.png');
const sequenceImage20 = require('../images/weldSequence/sequenceImage20.png');
const sequenceImage21 = require('../images/weldSequence/sequenceImage21.png');
const sequenceImage22 = require('../images/weldSequence/sequenceImage22.png');
const sequenceImage23 = require('../images/weldSequence/sequenceImage23.png');
const sequenceImage24 = require('../images/weldSequence/sequenceImage24.png');
const sequenceImage25 = require('../images/weldSequence/sequenceImage25.png');
const sequenceImage26 = require('../images/weldSequence/sequenceImage26.png');
const sequenceImage27 = require('../images/weldSequence/sequenceImage27.png');
const sequenceImage28 = require('../images/weldSequence/sequenceImage28.png');
const sequenceImage29 = require('../images/weldSequence/sequenceImage29.png');
const sequenceImage30 = require('../images/weldSequence/sequenceImage30.png');
const sequenceImage31 = require('../images/weldSequence/sequenceImage31.png');
const sequenceImage32 = require('../images/weldSequence/sequenceImage32.png');
const sequenceImage33 = require('../images/weldSequence/sequenceImage33.png');
const sequenceImage34 = require('../images/weldSequence/sequenceImage34.png');
const sequenceImage35 = require('../images/weldSequence/sequenceImage35.png');
const sequenceImage36 = require('../images/weldSequence/sequenceImage36.png');
const sequenceImage37 = require('../images/weldSequence/sequenceImage37.png');
const sequenceImage38 = require('../images/weldSequence/sequenceImage38.png');
const sequenceImage39 = require('../images/weldSequence/sequenceImage39.png');
const sequenceImage40 = require('../images/weldSequence/sequenceImage40.png');
const sequenceImage41 = require('../images/weldSequence/sequenceImage41.png');
const sequenceImage42 = require('../images/weldSequence/sequenceImage42.png');
const sequenceImage43 = require('../images/weldSequence/sequenceImage43.png');
const sequenceImage44 = require('../images/weldSequence/sequenceImage44.png');
const sequenceImage45 = require('../images/weldSequence/sequenceImage45.png');
const sequenceImage46 = require('../images/weldSequence/sequenceImage46.png');
const sequenceImage47 = require('../images/weldSequence/sequenceImage47.png');
const sequenceImage48 = require('../images/weldSequence/sequenceImage48.png');
const sequenceImage49 = require('../images/weldSequence/sequenceImage49.png');
const sequenceImage50 = require('../images/weldSequence/sequenceImage50.png');
const sequenceImage51 = require('../images/weldSequence/sequenceImage51.png');
const sequenceImage52 = require('../images/weldSequence/sequenceImage52.png');
const sequenceImage53 = require('../images/weldSequence/sequenceImage53.png');
const sequenceImage54 = require('../images/weldSequence/sequenceImage54.png');
const sequenceImage55 = require('../images/weldSequence/sequenceImage55.png');
const sequenceImage56 = require('../images/weldSequence/sequenceImage56.png');
const sequenceImage57 = require('../images/weldSequence/sequenceImage57.png');
const sequenceImage58 = require('../images/weldSequence/sequenceImage58.png');
const sequenceImage59 = require('../images/weldSequence/sequenceImage59.png');
const sequenceImage60 = require('../images/weldSequence/sequenceImage60.png');
const sequenceImage61 = require('../images/weldSequence/sequenceImage61.png');
const sequenceImage62 = require('../images/weldSequence/sequenceImage62.png');
const sequenceImage63 = require('../images/weldSequence/sequenceImage63.png');
const sequenceImage64 = require('../images/weldSequence/sequenceImage64.png');
const sequenceImage65 = require('../images/weldSequence/sequenceImage65.png');
const sequenceImage66 = require('../images/weldSequence/sequenceImage66.png');
const sequenceImage67 = require('../images/weldSequence/sequenceImage67.png');
const sequenceImage68 = require('../images/weldSequence/sequenceImage68.png');
const sequenceImage69 = require('../images/weldSequence/sequenceImage69.png');
const sequenceImage70 = require('../images/weldSequence/sequenceImage70.png');
const sequenceImage71 = require('../images/weldSequence/sequenceImage71.png');
const sequenceImage72 = require('../images/weldSequence/sequenceImage72.png');
const sequenceImage73 = require('../images/weldSequence/sequenceImage73.png');
const sequenceImage74 = require('../images/weldSequence/sequenceImage74.png');
const sequenceImage75 = require('../images/weldSequence/sequenceImage75.png');
const sequenceImage76 = require('../images/weldSequence/sequenceImage76.png');
const sequenceImage77 = require('../images/weldSequence/sequenceImage77.png');
const sequenceImage78 = require('../images/weldSequence/sequenceImage78.png');
const sequenceImage79 = require('../images/weldSequence/sequenceImage79.png');
const sequenceImage80 = require('../images/weldSequence/sequenceImage80.png');
const sequenceImage81 = require('../images/weldSequence/sequenceImage81.png');
const sequenceImage82 = require('../images/weldSequence/sequenceImage82.png');
const sequenceImage83 = require('../images/weldSequence/sequenceImage83.png');
const sequenceImage84 = require('../images/weldSequence/sequenceImage84.png');
const sequenceImage85 = require('../images/weldSequence/sequenceImage85.png');
const sequenceImage86 = require('../images/weldSequence/sequenceImage86.png');
const sequenceImage87 = require('../images/weldSequence/sequenceImage87.png');
const sequenceImage88 = require('../images/weldSequence/sequenceImage88.png');
const sequenceImage89 = require('../images/weldSequence/sequenceImage89.png');
const sequenceImage90 = require('../images/weldSequence/sequenceImage90.png');
const sequenceImage91 = require('../images/weldSequence/sequenceImage91.png');
const sequenceImage92 = require('../images/weldSequence/sequenceImage92.png');
const sequenceImage93 = require('../images/weldSequence/sequenceImage93.png');
const sequenceImage94 = require('../images/weldSequence/sequenceImage94.png');
const sequenceImage95 = require('../images/weldSequence/sequenceImage95.png');
const sequenceImage96 = require('../images/weldSequence/sequenceImage96.png');
const sequenceImage97 = require('../images/weldSequence/sequenceImage97.png');
const sequenceImage98 = require('../images/weldSequence/sequenceImage98.png');
const sequenceImage99 = require('../images/weldSequence/sequenceImage99.png');
const sequenceImage100 = require('../images/weldSequence/sequenceImage100.png');
const sequenceImage101 = require('../images/weldSequence/sequenceImage101.png');
const sequenceImage102 = require('../images/weldSequence/sequenceImage102.png');
const sequenceImage103 = require('../images/weldSequence/sequenceImage103.png');
const sequenceImage104 = require('../images/weldSequence/sequenceImage104.png');
const sequenceImage105 = require('../images/weldSequence/sequenceImage105.png');
const sequenceImage106 = require('../images/weldSequence/sequenceImage106.png');
const sequenceImage107 = require('../images/weldSequence/sequenceImage107.png');
const sequenceImage108 = require('../images/weldSequence/sequenceImage108.png');
const sequenceImage109 = require('../images/weldSequence/sequenceImage109.png');
const sequenceImage110 = require('../images/weldSequence/sequenceImage110.png');

import * as WeldSequenceStore from '../store/WeldSequenceStore';
import { ApplicationState } from '../store/index';
import * as htmlToImage from 'html-to-image';
import { GetWeldSequenceResponse } from '../store/models/responses/GetWeldSequenceResponse';
import Loader from './Loader';
import '../weld-sequence.css';
import { t } from '../i18n';
// At runtime, Redux will merge together...
type WeldSequenceProps =
    WeldSequenceStore.WeldSequenceState // ... state we've requested from the Redux store
    & typeof WeldSequenceStore.actionCreators // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    show: boolean;
    toggleShow: () => void;
    weldSequence: GetWeldSequenceResponse;
    pSetWeldSequenceView?: boolean; // Only to View
    imageUpload?: string;
    imageValidationError?: string;
}

class WeldSequence extends React.PureComponent<WeldSequenceProps> {
    public constructor(props: WeldSequenceProps) {
        super(props);
        }

    public state = {
        selectedSequenceValue: "",
        selectedSequenceNumber: 0,
        showSequenceModal: false,
        selectWeldSequenceImageId: 0,
        createSequenceImage: false
    };

    private toggleSequenceModal = () => {
        this.setState({
            showSequenceModal: !this.state.showSequenceModal
        });
    }

    private setSelectedSequenceNumber= (selectedSequenceNumber: number) => {
        this.setState({
            selectedSequenceNumber: selectedSequenceNumber
        });
    }

    private setSelectWeldSequenceImageId = (selectWeldSequenceImageId: number) => {
        this.setState({
            selectWeldSequenceImageId: selectWeldSequenceImageId
        });
    }

    private handleChange(event: any) {
        this.setState({
            selectedSequenceValue: event.target.value
        });
    }

    private setSelectedSequenceValueDefault() {
        this.setState({
            selectedSequenceValue: ""
        });
    }
    private setCreateSequenceImage = () => {
        this.setState({
            createSequenceImage: !this.state.createSequenceImage
        });
    }

    public componentDidMount() {
        // Clear all store
        this.props.clearState();
        //this.props.getJointTypesAll();
        //this.props.getRootSupportsAll();
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.weldSequence !== this.props.weldSequence) {
                if (this.props.pSetWeldSequenceView) {
                    //TODO this is only to wiew.
                    this.props.setWeldSequenceView(this.props.weldSequence);
                } else {
                    this.props.setWeldSequence(this.props.weldSequence);
                    this.setSelectWeldSequenceImageId(this.props.weldSequence.imageId);
                }
        }
        if (prevProps.imageUpload !== this.props.imageUpload) {
            this.props.setHtmlImage(this.props.imageUpload);
        }
    }

    private selectWeldSequenceImage(selectImage: number) {
        this.setSelectWeldSequenceImageId(selectImage);
        this.props.setSequenceImageId(selectImage);
    }

    private selectWeldSequence1(selectSequence: number) {
        this.setSelectedSequenceNumber(selectSequence)
        this.toggleSequenceModal()
    }
 
    private onClickToggleShowModalOk(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.setCreateSequenceImage();
        var canvas = (document.getElementById('canvas') as HTMLCanvasElement);
        var ctx = canvas.getContext("2d");
        // Html to Image
        const node = document.getElementById("htmlToImageWeldSequence")!;
        var dataurl = "";
        (async () => {
            await htmlToImage.toPng(node)
                .then(function (dataUrl) {
                    var img = new Image();
                    img.src = dataUrl;
                    dataurl = dataUrl;
                    //document.body.appendChild(img);
                })
                .catch(function (error) {
                    console.error('something went wrong WeldSequence!', error);
                });
            // Cut image
            const htmlImage = new Image();
            htmlImage.src = dataurl;

            var cutToWidth = 500;
                canvas.width = cutToWidth;
                canvas.height = htmlImage.height;

                var realImageWithTo = htmlImage.width / 2 - cutToWidth / 2;
                ctx.drawImage(htmlImage, realImageWithTo, 0, cutToWidth, htmlImage.height, 0, 0, cutToWidth, htmlImage.height);

            this.props.setHtmlImage((document.getElementById('canvas') as HTMLCanvasElement).toDataURL("image/png", 1));
            this.props.toggleShow();
            this.setCreateSequenceImage();
        })();
    }

    private onClickToggleShowModalCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.props.toggleShow();
    }
    private onClickMessageModalOK(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.props.setSequenceNumber(this.state.selectedSequenceValue, this.state.selectedSequenceNumber);
        this.setSelectedSequenceValueDefault()
        this.toggleSequenceModal()
    }

    private onClickMessageModalCancel(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        event.preventDefault();
        this.toggleSequenceModal();
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
    private getClass = (classN: string, elementLength: number) => {
        var resultLeft = 0;
        switch (classN) {
            case "sequenseImage1-sequense1":
                resultLeft = 362 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage2-sequense1":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage2-sequense2":
                resultLeft = 362 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage3-sequense1":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage3-sequense2":
                resultLeft = 381 - ((elementLength) * 2);
                break;
            case "sequenseImage3-sequense3":
                resultLeft = 342 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage4-sequense1":
                resultLeft = 366 - ((elementLength) * 2);
                break;
            case "sequenseImage4-sequense2":
                resultLeft = 389 - ((elementLength) * 2);
                break;
            case "sequenseImage4-sequense3":
                resultLeft = 350 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage5-sequense1":
                resultLeft = 370 - ((elementLength) * 2);
                break;
            case "sequenseImage5-sequense2":
                resultLeft = 370 - ((elementLength) * 2);
                break;
            case "sequenseImage5-sequense3":
                resultLeft = 370 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage6-sequense1":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage6-sequense2":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage6-sequense3":
                resultLeft = 362 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage7-sequense1":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage7-sequense2":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage7-sequense3":
                resultLeft = 385 - ((elementLength) * 2);
                break;
            case "sequenseImage7-sequense4":
                resultLeft = 337 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage8-sequense1":
                resultLeft = 367 - ((elementLength) * 2);
                break;
            case "sequenseImage8-sequense2":
                resultLeft = 368 - ((elementLength) * 2);
                break;
            case "sequenseImage8-sequense3":
                resultLeft = 397 - ((elementLength) * 2);
                break;
            case "sequenseImage8-sequense4":
                resultLeft = 339 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage9-sequense1":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage9-sequense2":
                resultLeft = 396 - ((elementLength) * 2);
                break;
            case "sequenseImage9-sequense3":
                resultLeft = 327 - ((elementLength) * 2);
                break;
            case "sequenseImage9-sequense4":
                resultLeft = 362 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage10-sequense1":
                resultLeft = 372 - ((elementLength) * 2);
                break;
            case "sequenseImage10-sequense2":
                resultLeft = 406 - ((elementLength) * 2);
                break;
            case "sequenseImage10-sequense3":
                resultLeft = 345 - ((elementLength) * 2);
                break;
            case "sequenseImage10-sequense4":
                resultLeft = 372 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage11-sequense1":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage11-sequense2":
                resultLeft = 390 - ((elementLength) * 2);
                break;
            case "sequenseImage11-sequense3":
                resultLeft = 329 - ((elementLength) * 2);
                break;
            case "sequenseImage11-sequense4":
                resultLeft = 392 - ((elementLength) * 2);
                break;
            case "sequenseImage11-sequense5":
                resultLeft = 329 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage12-sequense1":
                resultLeft = 360 - ((elementLength) * 2);
                break;
            case "sequenseImage12-sequense2":
                resultLeft = 400 - ((elementLength) * 2);
                break;
            case "sequenseImage12-sequense3":
                resultLeft = 333 - ((elementLength) * 2);
                break;
            case "sequenseImage12-sequense4":
                resultLeft = 400 - ((elementLength) * 2);
                break;
            case "sequenseImage12-sequense5":
                resultLeft = 333 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage13-sequense1":
                resultLeft = 390 - ((elementLength) * 2);
                break;
            case "sequenseImage13-sequense2":
                resultLeft = 333 - ((elementLength) * 2);
                break;
            case "sequenseImage13-sequense3":
                resultLeft = 365 - ((elementLength) * 2);
                break;
            case "sequenseImage13-sequense4":
                resultLeft = 388 - ((elementLength) * 2);
                break;
            case "sequenseImage13-sequense5":
                resultLeft = 333 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage14-sequense1":
                resultLeft = 404 - ((elementLength) * 2);
                break;
            case "sequenseImage14-sequense2":
                resultLeft = 350 - ((elementLength) * 2);
                break;
            case "sequenseImage14-sequense3":
                resultLeft = 375 - ((elementLength) * 2);
                break;
            case "sequenseImage14-sequense4":
                resultLeft = 404 - ((elementLength) * 2);
                break;
            case "sequenseImage14-sequense5":
                resultLeft = 350 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage15-sequense1":
                resultLeft = 360 - ((elementLength) * 2);
                break;
            case "sequenseImage15-sequense2":
                resultLeft = 385 - ((elementLength) * 2);
                break;
            case "sequenseImage15-sequense3":
                resultLeft = 335 - ((elementLength) * 2);
                break;
            case "sequenseImage15-sequense4":
                resultLeft = 406 - ((elementLength) * 2);
                break;
            case "sequenseImage15-sequense5":
                resultLeft = 358 - ((elementLength) * 2);
                break;
            case "sequenseImage15-sequense6":
                resultLeft = 310 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage16-sequense1":
                resultLeft = 358 - ((elementLength) * 2);
                break;
            case "sequenseImage16-sequense2":
                resultLeft = 385 - ((elementLength) * 2);
                break;
            case "sequenseImage16-sequense3":
                resultLeft = 332 - ((elementLength) * 2);
                break;
            case "sequenseImage16-sequense4":
                resultLeft = 405 - ((elementLength) * 2);
                break;
            case "sequenseImage16-sequense5":
                resultLeft = 355 - ((elementLength) * 2);
                break;
            case "sequenseImage16-sequense6":
                resultLeft = 308 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage17-sequense1":
                resultLeft = 352 - ((elementLength) * 2);
                break;
            case "sequenseImage17-sequense2":
                resultLeft = 380 - ((elementLength) * 2);
                break;
            case "sequenseImage17-sequense3":
                resultLeft = 315 - ((elementLength) * 2);
                break;
            case "sequenseImage17-sequense4":
                resultLeft = 400 - ((elementLength) * 2);
                break;
            case "sequenseImage17-sequense5":
                resultLeft = 348 - ((elementLength) * 2);
                break;
            case "sequenseImage17-sequense6":
                resultLeft = 300 - ((elementLength) * 2);
                break// New sequense
            case "sequenseImage18-sequense1":
                resultLeft = 384 - ((elementLength) * 2);
                break;
            case "sequenseImage18-sequense2":
                resultLeft = 340 - ((elementLength) * 2);
                break;
            case "sequenseImage18-sequense3":
                resultLeft = 363 - ((elementLength) * 2);
                break;
            case "sequenseImage18-sequense4":
                resultLeft = 363 - ((elementLength) * 2);
                break;
            case "sequenseImage18-sequense5":
                resultLeft = 385 - ((elementLength) * 2);
                break;
            case "sequenseImage18-sequense6":
                resultLeft = 340 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage19-sequense1":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage19-sequense2":
                resultLeft = 388 - ((elementLength) * 2);
                break;
            case "sequenseImage19-sequense3":
                resultLeft = 343 - ((elementLength) * 2);
                break;
            case "sequenseImage19-sequense4":
                resultLeft = 384 - ((elementLength) * 2);
                break;
            case "sequenseImage19-sequense5":
                resultLeft = 340 - ((elementLength) * 2);
                break;
            case "sequenseImage19-sequense6":
                resultLeft = 404 - ((elementLength) * 2);
                break;
            case "sequenseImage19-sequense7":
                resultLeft = 365 - ((elementLength) * 2);
                break;
            case "sequenseImage19-sequense8":
                resultLeft = 321 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage20-sequense1":
                resultLeft = 372 - ((elementLength) * 2);
                break;
            case "sequenseImage20-sequense2":
                resultLeft = 396 - ((elementLength) * 2);
                break;
            case "sequenseImage20-sequense3":
                resultLeft = 353 - ((elementLength) * 2);
                break;
            case "sequenseImage20-sequense4":
                resultLeft = 404 - ((elementLength) * 2);
                break;
            case "sequenseImage20-sequense5":
                resultLeft = 368 - ((elementLength) * 2);
                break;
            case "sequenseImage20-sequense6":
                resultLeft = 324 - ((elementLength) * 2);
                break;
            case "sequenseImage20-sequense7":
                resultLeft = 412 - ((elementLength) * 2);
                break;
            case "sequenseImage20-sequense8":
                resultLeft = 370 - ((elementLength) * 2);
                break;
            case "sequenseImage20-sequense9":
                resultLeft = 325 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage21-sequense1":
                resultLeft = 360 - ((elementLength) * 2);
                break;
            case "sequenseImage21-sequense2":
                resultLeft = 382 - ((elementLength) * 2);
                break;
            case "sequenseImage21-sequense3":
                resultLeft = 338 - ((elementLength) * 2);
                break;
            case "sequenseImage21-sequense4":
                resultLeft = 396 - ((elementLength) * 2);
                break;
            case "sequenseImage21-sequense5":
                resultLeft = 348 - ((elementLength) * 2);
                break;
            case "sequenseImage21-sequense6":
                resultLeft = 310 - ((elementLength) * 2);
                break;
            case "sequenseImage21-sequense7":
                resultLeft = 418 - ((elementLength) * 2);
                break;
            case "sequenseImage21-sequense8":
                resultLeft = 370 - ((elementLength) * 2);
                break;
            case "sequenseImage21-sequense9":
                resultLeft = 325 - ((elementLength) * 2);
                break;
            case "sequenseImage21-sequense10":
                resultLeft = 280 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage22-sequense1":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense2":
                resultLeft = 386 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense3":
                resultLeft = 350 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense4":
                resultLeft = 386 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense5":
                resultLeft = 344 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense6":
                resultLeft = 406 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense7":
                resultLeft = 364 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense8":
                resultLeft = 330 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense9":
                resultLeft = 415 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense10":
                resultLeft = 376 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense11":
                resultLeft = 348 - ((elementLength) * 2);
                break;
            case "sequenseImage22-sequense12":
                resultLeft = 312 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage23-sequense1":
                resultLeft = 368 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense2":
                resultLeft = 388 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense3":
                resultLeft = 346 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense4":
                resultLeft = 404 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense5":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense6":
                resultLeft = 314 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense7":
                resultLeft = 412 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense8":
                resultLeft = 372 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense9":
                resultLeft = 330 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense10":
                resultLeft = 292 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense11":
                resultLeft = 425 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense12":
                resultLeft = 385 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense13":
                resultLeft = 348 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense14":
                resultLeft = 315 - ((elementLength) * 2);
                break;
            case "sequenseImage23-sequense15":
                resultLeft = 275 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage24-sequense1":
                resultLeft = 382 - ((elementLength) * 2);
                break;
            case "sequenseImage24-sequense2":
                resultLeft = 340 - ((elementLength) * 2);
                break;
            case "sequenseImage24-sequense3":
                resultLeft = 361 - ((elementLength) * 2);
                break;
            case "sequenseImage24-sequense4":
                resultLeft = 361 - ((elementLength) * 2);
                break;
            case "sequenseImage24-sequense5":
                resultLeft = 361 - ((elementLength) * 2);
                break;
            case "sequenseImage24-sequense6":
                resultLeft = 382 - ((elementLength) * 2);
                break;
            case "sequenseImage24-sequense7":
                resultLeft = 342 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage25-sequense1":
                resultLeft = 387 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense2":
                resultLeft = 352 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense3":
                resultLeft = 316 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense4":
                resultLeft = 372 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense5":
                resultLeft = 334 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense6":
                resultLeft = 350 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense7":
                resultLeft = 370 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense8":
                resultLeft = 332 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense9":
                resultLeft = 390 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense10":
                resultLeft = 348 - ((elementLength) * 2);
                break;
            case "sequenseImage25-sequense11":
                resultLeft = 314 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage26-sequense1":
                resultLeft = 410 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense2":
                resultLeft = 382 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense3":
                resultLeft = 350 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense4":
                resultLeft = 316 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense5":
                resultLeft = 364 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense6":
                resultLeft = 364 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense7":
                resultLeft = 364 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense8":
                resultLeft = 411 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense9":
                resultLeft = 389 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense10":
                resultLeft = 355 - ((elementLength) * 2);
                break;
            case "sequenseImage26-sequense11":
                resultLeft = 318 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage27-sequense1":
                resultLeft = 400 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense2":
                resultLeft = 368 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense3":
                resultLeft = 336 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense4":
                resultLeft = 306 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense5":
                resultLeft = 384 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense6":
                resultLeft = 342 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense7":
                resultLeft = 360 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense8":
                resultLeft = 374 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense9":
                resultLeft = 332 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense10":
                resultLeft = 405 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense11":
                resultLeft = 370 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense12":
                resultLeft = 338 - ((elementLength) * 2);
                break;
            case "sequenseImage27-sequense13":
                resultLeft = 305 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage28-sequense1":
                resultLeft = 382 - ((elementLength) * 2);
                break;
            case "sequenseImage28-sequense2":
                resultLeft = 346 - ((elementLength) * 2);
                break;
            case "sequenseImage28-sequense3":
                resultLeft = 356 - ((elementLength) * 2);
                break;
            case "sequenseImage28-sequense4":
                resultLeft = 382 - ((elementLength) * 2);
                break;
            case "sequenseImage28-sequense5":
                resultLeft = 345 - ((elementLength) * 2);
                break;;// New sequense
            case "sequenseImage29-sequense1":
                resultLeft = 412 - ((elementLength) * 2);
                break;
            case "sequenseImage29-sequense2":
                resultLeft = 380 - ((elementLength) * 2);
                break;
            case "sequenseImage29-sequense3":
                resultLeft = 350 - ((elementLength) * 2);
                break;
            case "sequenseImage29-sequense4":
                resultLeft = 356 - ((elementLength) * 2);
                break;
            case "sequenseImage29-sequense5":
                resultLeft = 358 - ((elementLength) * 2);
                break;
            case "sequenseImage29-sequense6":
                resultLeft = 418 - ((elementLength) * 2);
                break;
            case "sequenseImage29-sequense7":
                resultLeft = 380 - ((elementLength) * 2);
                break;
            case "sequenseImage29-sequense8":
                resultLeft = 350 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage30-sequense1":
                resultLeft = 390 - ((elementLength) * 2);
                break;
            case "sequenseImage30-sequense2":
                resultLeft = 340 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage31-sequense1":
                resultLeft = 398 - ((elementLength) * 2);
                break;
            case "sequenseImage31-sequense2":
                resultLeft = 370 - ((elementLength) * 2);
                break;
            case "sequenseImage31-sequense3":
                resultLeft = 340 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage32-sequense1":
                resultLeft = 394 - ((elementLength) * 2);
                break;
            case "sequenseImage32-sequense2":
                resultLeft = 366 - ((elementLength) * 2);
                break;
            case "sequenseImage32-sequense3":
                resultLeft = 340 - ((elementLength) * 2);
                break;
            case "sequenseImage32-sequense4":
                resultLeft = 386 - ((elementLength) * 2);
                break;
            case "sequenseImage32-sequense5":
                resultLeft = 340 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage33-sequense1":
                resultLeft = 386 - ((elementLength) * 2);
                break;
            case "sequenseImage33-sequense2":
                resultLeft = 352 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage34-sequense1":
                resultLeft = 400 - ((elementLength) * 2);
                break;
            case "sequenseImage34-sequense2":
                resultLeft = 360 - ((elementLength) * 2);
                break;
            case "sequenseImage34-sequense3":
                resultLeft = 388 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage35-sequense1":
                resultLeft = 400 - ((elementLength) * 2);
                break;
            case "sequenseImage35-sequense2":
                resultLeft = 366 - ((elementLength) * 2);
                break;
            case "sequenseImage35-sequense3":
                resultLeft = 340 - ((elementLength) * 2);
                break;
            case "sequenseImage35-sequense4":
                resultLeft = 396 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage36-sequense1":
                resultLeft = 342 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage37-sequense1":
                resultLeft = 348 - ((elementLength) * 2);
                break;
            case "sequenseImage37-sequense2":
                resultLeft = 356 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage38-sequense1":
                resultLeft = 336 - ((elementLength) * 2);
                break;
            case "sequenseImage38-sequense2":
                resultLeft = 339 - ((elementLength) * 2);
                break;
            case "sequenseImage38-sequense3":
                resultLeft = 346 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage39-sequense1":
                resultLeft = 340 - ((elementLength) * 2);
                break;
            case "sequenseImage39-sequense2":
                resultLeft = 344 - ((elementLength) * 2);
                break;
            case "sequenseImage39-sequense3":
                resultLeft = 370 - ((elementLength) * 2);
                break;
            case "sequenseImage39-sequense4":
                resultLeft = 336 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage40-sequense1":
                resultLeft = 336 - ((elementLength) * 2);
                break;
            case "sequenseImage40-sequense2":
                resultLeft = 356 - ((elementLength) * 2);
                break;
            case "sequenseImage40-sequense3":
                resultLeft = 324 - ((elementLength) * 2);
                break;
            case "sequenseImage40-sequense4":
                resultLeft = 362 - ((elementLength) * 2);
                break;
            case "sequenseImage40-sequense5":
                resultLeft = 330 - ((elementLength) * 2);
                break;// New sequense
            case "sequenseImage41-sequense1":
                resultLeft = 340 - ((elementLength) * 2);
                break;
            case "sequenseImage41-sequense2":
                resultLeft = 366 - ((elementLength) * 2);
                break;
            case "sequenseImage41-sequense3":
                resultLeft = 336 - ((elementLength) * 2);
                break;
            case "sequenseImage41-sequense4":
                resultLeft = 396 - ((elementLength) * 2);
                break;
            case "sequenseImage41-sequense5":
                resultLeft = 364 - ((elementLength) * 2);
                break;
            case "sequenseImage41-sequense6":
                resultLeft = 334 - ((elementLength) * 2);
                break;
            default:
        }
        return resultLeft + 'px';
    }
    public render() {
        const scrollContainerStyle = { width: "800px", maxHeight: "400px" };
        return (
            <React.Fragment>
                <canvas hidden={true} id="canvas"></canvas>
                <Loader show={this.state.createSequenceImage} title="Create Image..." />
                <FormGroup className="text-center image-auto-with">
                    {this.props.pSetWeldSequenceView ?
                        <img className={this.props.sequencesView.imageData !== "" ? "image-weld-sequence" : ""} src={this.props.sequencesView.imageData !== "" ? this.props.sequencesView.imageData : welDBaseLogo} alt="DEKRA" /> :
                        <img className={this.props.sequences.imageData !== "" ? "image-weld-sequence" : ""} src={this.props.sequences.imageData !== "" ? this.props.sequences.imageData : welDBaseLogo} alt="DEKRA" />
                    }
                </FormGroup>
                {this.props.imageValidationError === "type" ?
                    <Row form>
                        <Col sm={12}>
                            <UncontrolledAlert color="danger">
                                {t('TheImageIsOfTheWrongType')}
                            </UncontrolledAlert>
                        </Col>
                    </Row> : null
                }
                {this.props.imageValidationError === "size" ?
                    <Row form>
                        <Col sm={12}>
                            <UncontrolledAlert color="danger">
                                {t('TheImageIsLargeThan')}
                            </UncontrolledAlert>
                        </Col>
                    </Row> : null
                }
                <Modal isOpen={this.props.show} size="lg">
                        <ModalHeader>
                        {t('WeldingStrings')}
                    </ModalHeader>
                        <ModalBody>
                            <Form>
                                <Row form>
                                    <Col sm={12} className="scrollbar" style={scrollContainerStyle}>
                                        <Card>
                                            <CardBody>
                                                <CardText>
                                                    <Row form>
                                                        <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 1 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(1)}><img className={"image-auto-with-height"} src={sequenceImage1} alt="image1" /></Button>
                                                        </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 2 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(2)}><img className={"image-auto-with-height"} src={sequenceImage2} alt="image2" /></Button>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 3 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(3)}><img className={"image-auto-with-height"} src={sequenceImage3} alt="image3" /></Button>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 4 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(4)}><img className={"image-auto-with-height"} src={sequenceImage4} alt="image4" /></Button>
                                                            </FormGroup>
                                                        </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 5 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(5)}><img className={"image-auto-with-height"} src={sequenceImage5} alt="image5" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 6 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(6)}><img className={"image-auto-with-height"} src={sequenceImage6} alt="image6" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 7 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(7)}><img className={"image-auto-with-height"} src={sequenceImage7} alt="image1" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 8 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(8)}><img className={"image-auto-with-height"} src={sequenceImage8} alt="image1" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 9 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(9)}><img className={"image-auto-with-height"} src={sequenceImage9} alt="image1" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 10 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(10)}><img className={"image-auto-with-height"} src={sequenceImage10} alt="image10" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 11 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(11)}><img className={"image-auto-with-height"} src={sequenceImage11} alt="image11" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 12 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(12)}><img className={"image-auto-with-height"} src={sequenceImage12} alt="image12" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 13 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(13)}><img className={"image-auto-with-height"} src={sequenceImage13} alt="image13" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 14 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(14)}><img className={"image-auto-with-height"} src={sequenceImage14} alt="image14" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 15 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(15)}><img className={"image-auto-with-height"} src={sequenceImage15} alt="image15" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 16 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(16)}><img className={"image-auto-with-height"} src={sequenceImage16} alt="image16" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 17 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(17)}><img className={"image-auto-with-height"} src={sequenceImage17} alt="image17" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 18 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(18)}><img className={"image-auto-with-height"} src={sequenceImage18} alt="image18" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 19 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(19)}><img className={"image-auto-with-height"} src={sequenceImage19} alt="image19" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 20 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(20)}><img className={"image-auto-with-height"} src={sequenceImage20} alt="image20" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 21 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(21)}><img className={"image-auto-with-height"} src={sequenceImage21} alt="image21" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 22 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(22)}><img className={"image-auto-with-height"} src={sequenceImage22} alt="image22" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 23 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(23)}><img className={"image-auto-with-height"} src={sequenceImage23} alt="image23" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 24 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(24)}><img className={"image-auto-with-height"} src={sequenceImage24} alt="image24" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 25 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(25)}><img className={"image-auto-with-height"} src={sequenceImage25} alt="image25" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 26 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(26)}><img className={"image-auto-with-height"} src={sequenceImage26} alt="image26" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 27 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(27)}><img className={"image-auto-with-height"} src={sequenceImage27} alt="image27" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 28 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(28)}><img className={"image-auto-with-height"} src={sequenceImage28} alt="image28" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 29 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(29)}><img className={"image-auto-with-height"} src={sequenceImage29} alt="image29" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 30 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(30)}><img className={"image-auto-with-height"} src={sequenceImage30} alt="image30" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 31 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(31)}><img className={"image-auto-with-height"} src={sequenceImage31} alt="image31" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 32 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(32)}><img className={"image-auto-with-height"} src={sequenceImage32} alt="image32" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 33 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(33)}><img className={"image-auto-with-height"} src={sequenceImage33} alt="image33" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 34 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(34)}><img className={"image-auto-with-height"} src={sequenceImage34} alt="image34" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 35 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(35)}><img className={"image-auto-with-height"} src={sequenceImage35} alt="image35" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 36 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(36)}><img className={"image-auto-with-height"} src={sequenceImage36} alt="image36" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 37 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(37)}><img className={"image-auto-with-height"} src={sequenceImage37} alt="image37" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 38 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(38)}><img className={"image-auto-with-height"} src={sequenceImage38} alt="image38" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 39 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(39)}><img className={"image-auto-with-height"} src={sequenceImage39} alt="image39" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 40 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(40)}><img className={"image-auto-with-height"} src={sequenceImage40} alt="image40" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 41 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(41)}><img className={"image-auto-with-height"} src={sequenceImage41} alt="image41" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 42 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(42)}><img className={"image-auto-with-height"} src={sequenceImage42} alt="image42" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 43 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(43)}><img className={"image-auto-with-height"} src={sequenceImage43} alt="image43" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 44 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(44)}><img className={"image-auto-with-height"} src={sequenceImage44} alt="image44" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 45 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(45)}><img className={"image-auto-with-height"} src={sequenceImage45} alt="image45" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 46 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(46)}><img className={"image-auto-with-height"} src={sequenceImage46} alt="image46" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 47 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(47)}><img className={"image-auto-with-height"} src={sequenceImage47} alt="image47" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 48 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(48)}><img className={"image-auto-with-height"} src={sequenceImage48} alt="image48" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 49 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(49)}><img className={"image-auto-with-height"} src={sequenceImage49} alt="image49" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 50 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(50)}><img className={"image-auto-with-height"} src={sequenceImage50} alt="image50" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 51 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(51)}><img className={"image-auto-with-height"} src={sequenceImage51} alt="image51" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 52 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(52)}><img className={"image-auto-with-height"} src={sequenceImage52} alt="image52" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 53 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(53)}><img className={"image-auto-with-height"} src={sequenceImage53} alt="image53" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 54 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(54)}><img className={"image-auto-with-height"} src={sequenceImage54} alt="image54" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 55 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(55)}><img className={"image-auto-with-height"} src={sequenceImage55} alt="image55" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 56 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(56)}><img className={"image-auto-with-height"} src={sequenceImage56} alt="image56" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 57 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(57)}><img className={"image-auto-with-height"} src={sequenceImage57} alt="image57" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 58 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(58)}><img className={"image-auto-with-height"} src={sequenceImage58} alt="image58" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 59 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(59)}><img className={"image-auto-with-height"} src={sequenceImage59} alt="image59" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 60 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(60)}><img className={"image-auto-with-height"} src={sequenceImage60} alt="image60" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 61 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(61)}><img className={"image-auto-with-height"} src={sequenceImage61} alt="image61" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 62 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(62)}><img className={"image-auto-with-height"} src={sequenceImage62} alt="image62" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 63 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(63)}><img className={"image-auto-with-height"} src={sequenceImage63} alt="image63" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 64 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(64)}><img className={"image-auto-with-height"} src={sequenceImage64} alt="image64" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 65 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(65)}><img className={"image-auto-with-height"} src={sequenceImage65} alt="image65" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 66 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(66)}><img className={"image-auto-with-height"} src={sequenceImage66} alt="image66" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 67 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(67)}><img className={"image-auto-with-height"} src={sequenceImage67} alt="image67" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 68 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(68)}><img className={"image-auto-with-height"} src={sequenceImage68} alt="image68" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 69 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(69)}><img className={"image-auto-with-height"} src={sequenceImage69} alt="image69" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 70 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(70)}><img className={"image-auto-with-height"} src={sequenceImage70} alt="image70" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 71 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(71)}><img className={"image-auto-with-height"} src={sequenceImage71} alt="image71" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 72 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(72)}><img className={"image-auto-with-height"} src={sequenceImage72} alt="image72" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 73 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(73)}><img className={"image-auto-with-height"} src={sequenceImage73} alt="image73" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 74 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(74)}><img className={"image-auto-with-height"} src={sequenceImage74} alt="image74" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 75 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(75)}><img className={"image-auto-with-height"} src={sequenceImage75} alt="image75" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 76 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(76)}><img className={"image-auto-with-height"} src={sequenceImage76} alt="image76" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 77 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(77)}><img className={"image-auto-with-height"} src={sequenceImage77} alt="image77" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 78 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(78)}><img className={"image-auto-with-height"} src={sequenceImage78} alt="image78" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 79 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(79)}><img className={"image-auto-with-height"} src={sequenceImage79} alt="image79" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 80 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(80)}><img className={"image-auto-with-height"} src={sequenceImage80} alt="image80" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 81 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(81)}><img className={"image-auto-with-height"} src={sequenceImage81} alt="image81" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 82 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(82)}><img className={"image-auto-with-height"} src={sequenceImage82} alt="image82" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 83 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(83)}><img className={"image-auto-with-height"} src={sequenceImage83} alt="image83" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 84 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(84)}><img className={"image-auto-with-height"} src={sequenceImage84} alt="image84" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 85 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(85)}><img className={"image-auto-with-height"} src={sequenceImage85} alt="image85" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 86 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(86)}><img className={"image-auto-with-height"} src={sequenceImage86} alt="image86" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 87 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(87)}><img className={"image-auto-with-height"} src={sequenceImage87} alt="image87" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 88 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(88)}><img className={"image-auto-with-height"} src={sequenceImage88} alt="image88" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 89 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(89)}><img className={"image-auto-with-height"} src={sequenceImage89} alt="image89" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 90 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(90)}><img className={"image-auto-with-height"} src={sequenceImage90} alt="image90" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 91 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(91)}><img className={"image-auto-with-height"} src={sequenceImage91} alt="image91" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 92 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(92)}><img className={"image-auto-with-height"} src={sequenceImage92} alt="image92" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 93 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(93)}><img className={"image-auto-with-height"} src={sequenceImage93} alt="image93" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 94 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(94)}><img className={"image-auto-with-height"} src={sequenceImage94} alt="image94" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 95 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(95)}><img className={"image-auto-with-height"} src={sequenceImage95} alt="image95" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 96 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(96)}><img className={"image-auto-with-height"} src={sequenceImage96} alt="image96" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 97 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(97)}><img className={"image-auto-with-height"} src={sequenceImage97} alt="image97" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 98 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(98)}><img className={"image-auto-with-height"} src={sequenceImage98} alt="image98" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 99 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(99)}><img className={"image-auto-with-height"} src={sequenceImage99} alt="image99" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 100 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(100)}><img className={"image-auto-with-height"} src={sequenceImage100} alt="image100" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 101 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(101)}><img className={"image-auto-with-height"} src={sequenceImage101} alt="image101" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 102 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(102)}><img className={"image-auto-with-height"} src={sequenceImage102} alt="image102" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 103 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(103)}><img className={"image-auto-with-height"} src={sequenceImage103} alt="image103" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 104 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(104)}><img className={"image-auto-with-height"} src={sequenceImage104} alt="image104" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 105 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(105)}><img className={"image-auto-with-height"} src={sequenceImage105} alt="image105" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 106 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(106)}><img className={"image-auto-with-height"} src={sequenceImage106} alt="image106" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 107 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(107)}><img className={"image-auto-with-height"} src={sequenceImage107} alt="image107" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 108 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(108)}><img className={"image-auto-with-height"} src={sequenceImage108} alt="image108" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 109 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(109)}><img className={"image-auto-with-height"} src={sequenceImage109} alt="image109" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Button className={this.props.sequences.imageId === 110 ? "btn-primary" : "btn-secondary"} onClick={() => this.selectWeldSequenceImage(110)}><img className={"image-auto-with-height"} src={sequenceImage110} alt="image110" /></Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                    <br />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <CardText>
                                                <Row form>
                                                        <Col sm={12}>
                                                        <FormGroup className="text-center">
                                                            <div id="htmlToImageWeldSequence" className="html-to-image">
                                                                {this.selectWelDBaseLogo() &&
                                                                    this.renderWelDBaseLogo()
                                                                }
                                                                {this.selectImage1() &&
                                                                    this.renderImage1()
                                                                }
                                                                {this.selectImage2() &&
                                                                    this.renderImage2()
                                                                }
                                                                {this.selectImage3() &&
                                                                    this.renderImage3()
                                                                }
                                                                {this.selectImage4() &&
                                                                    this.renderImage4()
                                                                }
                                                                {this.selectImage5() &&
                                                                    this.renderImage5()
                                                                }
                                                                {this.selectImage6() &&
                                                                    this.renderImage6()
                                                                }
                                                                {this.selectImage7() &&
                                                                    this.renderImage7()
                                                                }
                                                                {this.selectImage8() &&
                                                                    this.renderImage8()
                                                                }
                                                                {this.selectImage9() &&
                                                                    this.renderImage9()
                                                                }
                                                                {this.selectImage10() &&
                                                                    this.renderImage10()
                                                                }
                                                                {this.selectImage11() &&
                                                                    this.renderImage11()
                                                                }
                                                                {this.selectImage12() &&
                                                                    this.renderImage12()
                                                                }
                                                                {this.selectImage13() &&
                                                                    this.renderImage13()
                                                                }
                                                                {this.selectImage14() &&
                                                                    this.renderImage14()
                                                                }
                                                                {this.selectImage15() &&
                                                                    this.renderImage15()
                                                                }
                                                                {this.selectImage16() &&
                                                                    this.renderImage16()
                                                                }
                                                                {this.selectImage17() &&
                                                                    this.renderImage17()
                                                                }
                                                                {this.selectImage18() &&
                                                                    this.renderImage18()
                                                                }
                                                                {this.selectImage19() &&
                                                                    this.renderImage19()
                                                                }
                                                                {this.selectImage20() &&
                                                                    this.renderImage20()
                                                                }
                                                                {this.selectImage21() &&
                                                                    this.renderImage21()
                                                                }
                                                                {this.selectImage22() &&
                                                                    this.renderImage22()
                                                                }
                                                                {this.selectImage23() &&
                                                                    this.renderImage23()
                                                                }
                                                                {this.selectImage24() &&
                                                                    this.renderImage24()
                                                                }
                                                                {this.selectImage25() &&
                                                                    this.renderImage25()
                                                                }
                                                                {this.selectImage26() &&
                                                                    this.renderImage26()
                                                                }
                                                                {this.selectImage27() &&
                                                                    this.renderImage27()
                                                                }
                                                                {this.selectImage28() &&
                                                                    this.renderImage28()
                                                                }
                                                                {this.selectImage29() &&
                                                                    this.renderImage29()
                                                                }
                                                                {this.selectImage30() &&
                                                                    this.renderImage30()
                                                                }
                                                                {this.selectImage31() &&
                                                                    this.renderImage31()
                                                                }
                                                                {this.selectImage32() &&
                                                                    this.renderImage32()
                                                                }
                                                                {this.selectImage33() &&
                                                                    this.renderImage33()
                                                                }
                                                                {this.selectImage34() &&
                                                                    this.renderImage34()
                                                                }
                                                                {this.selectImage35() &&
                                                                    this.renderImage35()
                                                                }
                                                                {this.selectImage36() &&
                                                                    this.renderImage36()
                                                                }
                                                                {this.selectImage37() &&
                                                                    this.renderImage37()
                                                                }
                                                                {this.selectImage38() &&
                                                                    this.renderImage38()
                                                                }
                                                                {this.selectImage39() &&
                                                                    this.renderImage39()
                                                                }
                                                                {this.selectImage40() &&
                                                                    this.renderImage40()
                                                                }
                                                                {this.selectImage41() &&
                                                                    this.renderImage41()
                                                                }
                                                                {this.selectImage42() &&
                                                                    this.renderImage42()
                                                                }
                                                                {this.selectImage43() &&
                                                                    this.renderImage43()
                                                                }
                                                                {this.selectImage44() &&
                                                                    this.renderImage44()
                                                                }
                                                                {this.selectImage45() &&
                                                                    this.renderImage45()
                                                                }
                                                                {this.selectImage46() &&
                                                                    this.renderImage46()
                                                                }
                                                                {this.selectImage47() &&
                                                                    this.renderImage47()
                                                                }
                                                                {this.selectImage48() &&
                                                                    this.renderImage48()
                                                                }
                                                                {this.selectImage49() &&
                                                                    this.renderImage49()
                                                                }
                                                                {this.selectImage50() &&
                                                                    this.renderImage50()
                                                                }
                                                                {this.selectImage51() &&
                                                                    this.renderImage51()
                                                                }
                                                                {this.selectImage52() &&
                                                                    this.renderImage52()
                                                                }
                                                                {this.selectImage53() &&
                                                                    this.renderImage53()
                                                                }
                                                                {this.selectImage54() &&
                                                                    this.renderImage54()
                                                                }
                                                                {this.selectImage55() &&
                                                                    this.renderImage55()
                                                                }
                                                                {this.selectImage56() &&
                                                                    this.renderImage56()
                                                                }
                                                                {this.selectImage57() &&
                                                                    this.renderImage57()
                                                                }
                                                                {this.selectImage58() &&
                                                                    this.renderImage58()
                                                                }
                                                                {this.selectImage59() &&
                                                                    this.renderImage59()
                                                                }
                                                                {this.selectImage60() &&
                                                                    this.renderImage60()
                                                                }
                                                                {this.selectImage61() &&
                                                                    this.renderImage61()
                                                                }
                                                                {this.selectImage62() &&
                                                                    this.renderImage62()
                                                                }
                                                                {this.selectImage63() &&
                                                                    this.renderImage63()
                                                                }
                                                                {this.selectImage64() &&
                                                                    this.renderImage64()
                                                                }
                                                                {this.selectImage65() &&
                                                                    this.renderImage65()
                                                                }
                                                                {this.selectImage66() &&
                                                                    this.renderImage66()
                                                                }
                                                                {this.selectImage67() &&
                                                                    this.renderImage67()
                                                                }
                                                                {this.selectImage68() &&
                                                                    this.renderImage68()
                                                                }
                                                                {this.selectImage69() &&
                                                                    this.renderImage69()
                                                                }
                                                                {this.selectImage70() &&
                                                                    this.renderImage70()
                                                                }
                                                                {this.selectImage71() &&
                                                                    this.renderImage71()
                                                                }
                                                                {this.selectImage72() &&
                                                                    this.renderImage72()
                                                                }
                                                                {this.selectImage73() &&
                                                                    this.renderImage73()
                                                                }
                                                                {this.selectImage74() &&
                                                                    this.renderImage74()
                                                                }
                                                                {this.selectImage75() &&
                                                                    this.renderImage75()
                                                                }
                                                                {this.selectImage76() &&
                                                                    this.renderImage76()
                                                                }
                                                                {this.selectImage77() &&
                                                                    this.renderImage77()
                                                                }
                                                                {this.selectImage78() &&
                                                                    this.renderImage78()
                                                                }
                                                                {this.selectImage79() &&
                                                                    this.renderImage79()
                                                                }
                                                                {this.selectImage80() &&
                                                                    this.renderImage80()
                                                                }
                                                                {this.selectImage81() &&
                                                                    this.renderImage81()
                                                                }
                                                                {this.selectImage82() &&
                                                                    this.renderImage82()
                                                                }
                                                                {this.selectImage83() &&
                                                                    this.renderImage83()
                                                                }
                                                                {this.selectImage84() &&
                                                                    this.renderImage84()
                                                                }
                                                                {this.selectImage85() &&
                                                                    this.renderImage85()
                                                                }
                                                                {this.selectImage86() &&
                                                                    this.renderImage86()
                                                                }
                                                                {this.selectImage87() &&
                                                                    this.renderImage87()
                                                                }
                                                                {this.selectImage88() &&
                                                                    this.renderImage88()
                                                                }
                                                                {this.selectImage89() &&
                                                                    this.renderImage89()
                                                                }
                                                                {this.selectImage90() &&
                                                                    this.renderImage90()
                                                                }
                                                                {this.selectImage91() &&
                                                                    this.renderImage91()
                                                                }
                                                                {this.selectImage92() &&
                                                                    this.renderImage92()
                                                                }
                                                                {this.selectImage93() &&
                                                                    this.renderImage93()
                                                                }
                                                                {this.selectImage94() &&
                                                                    this.renderImage94()
                                                                }
                                                                {this.selectImage95() &&
                                                                    this.renderImage95()
                                                                }
                                                                {this.selectImage96() &&
                                                                    this.renderImage96()
                                                                }
                                                                {this.selectImage97() &&
                                                                    this.renderImage97()
                                                                }
                                                                {this.selectImage98() &&
                                                                    this.renderImage98()
                                                                }
                                                                {this.selectImage99() &&
                                                                    this.renderImage99()
                                                                }
                                                                {this.selectImage100() &&
                                                                    this.renderImage100()
                                                                }
                                                                {this.selectImage101() &&
                                                                    this.renderImage101()
                                                                }
                                                                {this.selectImage102() &&
                                                                    this.renderImage102()
                                                                }
                                                                {this.selectImage103() &&
                                                                    this.renderImage103()
                                                                }
                                                                {this.selectImage104() &&
                                                                    this.renderImage104()
                                                                }
                                                                {this.selectImage105() &&
                                                                    this.renderImage105()
                                                                }
                                                                {this.selectImage106() &&
                                                                    this.renderImage106()
                                                                }
                                                                {this.selectImage107() &&
                                                                    this.renderImage107()
                                                                }
                                                                {this.selectImage108() &&
                                                                    this.renderImage108()
                                                                }
                                                                {this.selectImage109() &&
                                                                    this.renderImage109()
                                                                }
                                                                {this.selectImage110() &&
                                                                    this.renderImage110()
                                                                }
                                                            </div>
                                                            </FormGroup>
                                                    </Col>
                                                    </Row>
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                        <ModalFooter justify-content-between>
                            <div className="mr-auto">
                            <h5>{t('ClickOnTheImageToInsertStringnumbering')}</h5>
                            </div>
                            <div>
                            <Button color="primary" onClick={(event) => this.onClickToggleShowModalOk(event)}>{t('OK')}</Button>
                                {' '}
                            <Button color="secondary" onClick={(event) => this.onClickToggleShowModalCancel(event)}>{t('Cancel')}</Button>
                            </div>
                        </ModalFooter>
                </Modal>
                <Modal centered={true} size="sm" isOpen={this.state.showSequenceModal} >
                    <ModalHeader>
                        {t('String')} {t('Number')}!
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label for="messageLabel" size="sm">{t('Number')}</Label>
                                    <Input type="text" bsSize="sm" name="messageText"
                                        value={this.state.selectedSequenceValue}
                                        onKeyPress={(event) => this.onKeyPress(event)} onChange={(event) => this.handleChange(event)}
                                        placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => this.onClickMessageModalOK(event)}>{t('OK')}</Button>{' '}
                        <Button color="secondary" onClick={(event) => this.onClickMessageModalCancel(event)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>

            </React.Fragment>
        );
    }

    private renderWelDBaseLogo() {
        return (
            <React.Fragment>
                <img src={welDBaseLogo} alt="welDBase" />
            </React.Fragment>
        );
    }

     private renderImage1() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage1} useMap="#image-map" />
                    <div className="sequenseImage1-sequense1" style={{ left: this.getClass("sequenseImage1-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="166,42,154,50,146,61,144,77,149,96,161,108,177,105,190,82,190,61,180,47" shape="poly" />
                </map>

            </React.Fragment>
        );
    }

    private renderImage2() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage2} useMap="#image-map" />
                    <div className="sequenseImage2-sequense1" style={{ left: this.getClass("sequenseImage2-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage2-sequense2" style={{ left: this.getClass("sequenseImage2-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="157,27,140,38,138,49,148,61,162,66,179,59,184,44,175,32" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="158,68,144,63,138,74,147,87,157,91,172,91,185,77,183,66,179,62" shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage3() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage3} useMap="#image-map" />
                    <div className="sequenseImage3-sequense1" style={{ left: this.getClass("sequenseImage3-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage3-sequense2" style={{ left: this.getClass("sequenseImage3-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage3-sequense3" style={{ left: this.getClass("sequenseImage3-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="178,31,161,37,156,46,160,56,171,62,187,64,203,59,213,47,208,37,195,30" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="138,61,151,63,165,61,156,50,158,38,159,30,146,31,134,35,126,40,123,49,127,55" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="149,65,145,77,152,87,165,94,182,90,192,80,192,70,187,64" shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage4() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage4} useMap="#image-map" />
                    <div className="sequenseImage4-sequense1" style={{ left: this.getClass("sequenseImage4-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage4-sequense2" style={{ left: this.getClass("sequenseImage4-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage4-sequense3" style={{ left: this.getClass("sequenseImage4-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="147,28,128,33,123,42,128,52,139,57,150,58,160,58,171,53,176,41,169,32,160,28" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="179,42,171,57,181,58,195,58,207,51,209,39,199,31,186,27,178,27,169,28" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="146,60,143,70,149,79,162,86,176,83,187,78,189,68,188,60" shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage5() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage5} useMap="#image-map" />
                    <div className="sequenseImage5-sequense1" style={{ left: this.getClass("sequenseImage5-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage5-sequense2" style={{ left: this.getClass("sequenseImage5-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage5-sequense3" style={{ left: this.getClass("sequenseImage5-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="176,22,160,24,146,28,139,40,147,50,164,53,178,55,194,53,208,46,213,39,205,28" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="174,57,153,53,147,59,148,68,158,73,171,76,182,76,192,73,201,69,204,59,202,53" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="175,80,154,75,153,86,160,96,173,102,187,99,196,92,199,82,196,74" shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage6() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage6} useMap="#image-map" />
                    <div className={"sequenseImage6-sequense1"} style={{ left: this.getClass("sequenseImage6-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className={"sequenseImage6-sequense2"} style={{ left: this.getClass("sequenseImage6-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage6-sequense3" style={{ left: this.getClass("sequenseImage6-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="160,13,140,17,133,33,143,42,160,46,182,42,188,32,182,18" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="160,47,145,45,138,51,140,59,147,64,160,62,177,63,184,55,185,48,180,42" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="150,67,136,77,140,92,159,97,176,94,191,85,188,73,177,65"  shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage7() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage7} useMap="#image-map" />
                    <div className="sequenseImage7-sequense1" style={{ left: this.getClass("sequenseImage7-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage7-sequense2" style={{ left: this.getClass("sequenseImage7-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage7-sequense3" style={{ left: this.getClass("sequenseImage7-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage7-sequense4" style={{ left: this.getClass("sequenseImage7-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="150,35,165,16,146,15,126,19,116,29,113,42,121,55,135,61,151,63,167,58" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="191,13,165,22,154,36,156,49,168,58,193,63,212,61,223,48,224,33,212,18" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="170,62,138,65,135,78,135,86,142,92,159,97,175,99,195,93,207,81,204,69,199,65" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="170,102,141,94,141,109,148,121,168,130,189,122,197,107,197,95" shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage8() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage8} useMap="#image-map" />
                    <div className="sequenseImage8-sequense1" style={{ left: this.getClass("sequenseImage8-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage8-sequense2" style={{ left: this.getClass("sequenseImage8-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage8-sequense3" style={{ left: this.getClass("sequenseImage8-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage8-sequense4" style={{ left: this.getClass("sequenseImage8-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="139,12,112,20,105,37,111,55,129,65,151,64,169,55,176,39,172,26,160,17" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="181,39,167,61,183,64,204,63,223,53,227,34,211,16,193,12,167,18" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="165,62,134,67,131,78,136,89,149,97,162,101,182,99,196,93,206,83,203,67" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="168,105,140,95,138,111,145,121,161,133,181,129,196,114,199,95"  shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage9() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage9} useMap="#image-map" />
                    <div className="sequenseImage9-sequense1" style={{ left: this.getClass("sequenseImage9-sequense1", this.props.sequences.sequenceNumber1.length) }}  onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage9-sequense2" style={{ left: this.getClass("sequenseImage9-sequense2", this.props.sequences.sequenceNumber2.length) }}  onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage9-sequense3" style={{ left: this.getClass("sequenseImage9-sequense3", this.props.sequences.sequenceNumber3.length) }}  onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage9-sequense4" style={{ left: this.getClass("sequenseImage9-sequense4", this.props.sequences.sequenceNumber4.length) }}  onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="171,16,134,21,109,39,122,54,158,64,207,61,231,45,226,26,206,19" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="139,64,119,55,103,75,111,92,123,101,144,104,163,100,167,93,163,75,168,67" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="196,65,172,67,168,80,170,95,182,104,206,110,236,99,241,73,232,62,219,57" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="168,99,131,106,137,128,160,141,190,136,204,123,208,112" shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage10() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage10} useMap="#image-map" />
                    <div className="sequenseImage10-sequense1" style={{ left: this.getClass("sequenseImage10-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage10-sequense2" style={{ left: this.getClass("sequenseImage10-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage10-sequense3" style={{ left: this.getClass("sequenseImage10-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage10-sequense4" style={{ left: this.getClass("sequenseImage10-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="169,11,137,17,118,35,128,47,154,54,180,57,208,50,229,44,234,31,205,16" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="148,53,116,48,110,68,116,82,126,90,136,92,148,92,166,87,174,83,182,66,181,58" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="185,57,177,83,190,90,215,94,234,82,242,67,234,54,225,46" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="174,86,138,95,138,112,150,121,169,129,198,121,203,108,205,97" shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage11() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage11} useMap="#image-map" />
                    <div className="sequenseImage11-sequense1" style={{ left: this.getClass("sequenseImage11-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage11-sequense2" style={{ left: this.getClass("sequenseImage11-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage11-sequense3" style={{ left: this.getClass("sequenseImage11-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage11-sequense4" style={{ left: this.getClass("sequenseImage11-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage11-sequense5" style={{ left: this.getClass("sequenseImage11-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="153,30,161,14,129,9,103,19,97,38,106,49,121,55,139,58,164,54" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="193,9,168,15,157,28,161,45,173,52,189,55,213,56,230,46,233,32,220,14" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="135,59,107,51,99,69,105,81,115,88,124,90,147,92,161,89,154,71,159,56" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="172,53,156,64,155,81,165,91,181,95,202,95,222,90,234,75,221,58" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="163,91,136,97,137,112,146,124,158,132,176,132,196,119,201,99" shape="poly" />
                </map>
            </React.Fragment>
        );
    }

    private renderImage12() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage12} useMap="#image-map" />
                    <div className="sequenseImage12-sequense1" style={{ left: this.getClass("sequenseImage12-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage12-sequense2" style={{ left: this.getClass("sequenseImage12-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage12-sequense3" style={{ left: this.getClass("sequenseImage12-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage12-sequense4" style={{ left: this.getClass("sequenseImage12-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage12-sequense5" style={{ left: this.getClass("sequenseImage12-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="125,10,97,18,85,33,98,51,119,54,140,56,157,50,171,39,162,19,146,12" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="170,32,161,16,193,12,218,16,231,34,221,44,207,55,186,57,161,51" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="129,59,96,51,87,66,95,80,109,91,129,91,151,87,166,79,170,66,161,53" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="160,84,193,91,223,83,231,65,218,53,195,59,164,55,174,71" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="161,86,122,94,127,111,144,121,164,123,184,113,192,95" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage13() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage13} useMap="#image-map" />
                    <div className="sequenseImage13-sequense1" style={{ left: this.getClass("sequenseImage13-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage13-sequense2" style={{ left: this.getClass("sequenseImage13-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage13-sequense3" style={{ left: this.getClass("sequenseImage13-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage13-sequense4" style={{ left: this.getClass("sequenseImage13-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage13-sequense5" style={{ left: this.getClass("sequenseImage13-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="156,40,162,23,143,17,110,23,101,40,103,54,114,62,129,67,143,66,162,60" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="192,16,164,25,157,44,169,62,187,67,208,68,222,56,231,42,219,24" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="163,62,134,70,131,83,136,88,149,88,162,91,174,90,197,88,202,78,194,71" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="156,111,165,93,144,90,126,94,114,103,107,118,117,134,133,141,154,143,168,137" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="191,89,161,100,158,125,179,140,207,141,227,124,225,102,213,91" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage14() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage14} useMap="#image-map" />
                    <div className="sequenseImage14-sequense1" style={{ left: this.getClass("sequenseImage14-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage14-sequense2" style={{ left: this.getClass("sequenseImage14-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage14-sequense3" style={{ left: this.getClass("sequenseImage14-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage14-sequense4" style={{ left: this.getClass("sequenseImage14-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage14-sequense5" style={{ left: this.getClass("sequenseImage14-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="147,14,120,30,119,52,136,63,168,60,179,60,187,48,182,24,166,15" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="189,38,179,19,214,19,231,27,234,45,227,57,218,66,201,67,181,61" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="177,62,140,68,139,81,140,88,162,91,171,93,186,90,203,87,204,70" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="146,88,117,102,114,121,126,139,147,144,171,138,180,118,173,100,163,92" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="184,115,178,95,206,88,228,101,230,122,217,139,196,142,175,135" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage15() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage15} useMap="#image-map" />
                    <div className="sequenseImage15-sequense1" style={{ left: this.getClass("sequenseImage15-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage15-sequense2" style={{ left: this.getClass("sequenseImage15-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage15-sequense3" style={{ left: this.getClass("sequenseImage15-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage15-sequense4" style={{ left: this.getClass("sequenseImage15-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage15-sequense5" style={{ left: this.getClass("sequenseImage15-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage15-sequense6" style={{ left: this.getClass("sequenseImage15-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="125,38,135,17,103,16,88,25,84,39,86,53,98,61,108,64,137,59" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="155,14,132,25,127,38,137,56,146,62,160,64,181,57,192,45,187,23,172,14" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="192,40,183,59,203,63,222,63,234,46,229,26,212,16,195,14,186,17" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="137,61,109,67,109,89,118,101,131,104,148,100,163,94,154,66" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="158,68,155,83,169,97,189,101,210,95,218,80,215,67,180,62" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="163,97,131,106,137,126,157,137,182,132,196,115,193,104" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage16() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage16} useMap="#image-map" />
                    <div className="sequenseImage16-sequense1" style={{ left: this.getClass("sequenseImage16-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage16-sequense2" style={{ left: this.getClass("sequenseImage16-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage16-sequense3" style={{ left: this.getClass("sequenseImage16-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage16-sequense4" style={{ left: this.getClass("sequenseImage16-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage16-sequense5" style={{ left: this.getClass("sequenseImage16-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage16-sequense6" style={{ left: this.getClass("sequenseImage16-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="117,38,128,18,102,18,83,25,77,41,83,54,96,62,114,61,128,57" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="149,16,126,25,118,40,125,53,141,58,160,61,177,52,185,37,170,18" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="185,39,177,21,194,18,218,25,227,37,221,51,211,61,190,58,173,57" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="131,60,99,65,99,80,108,93,118,96,132,95,156,90,158,68,157,61" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="170,58,161,64,158,90,169,93,183,97,200,91,210,82,210,63" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="154,91,121,99,127,117,146,127,168,126,185,108,187,99" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage17() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage17} useMap="#image-map" />
                    <div className="sequenseImage17-sequense1" style={{ left: this.getClass("sequenseImage17-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage17-sequense2" style={{ left: this.getClass("sequenseImage17-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage17-sequense3" style={{ left: this.getClass("sequenseImage17-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage17-sequense4" style={{ left: this.getClass("sequenseImage17-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage17-sequense5" style={{ left: this.getClass("sequenseImage17-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage17-sequense6" style={{ left: this.getClass("sequenseImage17-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="116,36,126,14,100,12,81,21,73,36,76,54,87,65,98,71,127,66" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="145,10,125,20,118,35,126,62,142,71,162,66,176,60,183,39,174,17" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="184,38,174,64,196,71,215,70,226,59,227,41,218,20,202,15,187,13,177,17" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="128,68,145,73,141,84,126,95,119,108,104,101,96,86,96,72" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="170,66,149,72,149,81,164,83,173,88,182,101,189,110,206,103,213,88,207,77" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="149,83,127,97,121,121,138,144,162,148,179,136,188,117,179,95,167,85" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage18() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage18} useMap="#image-map" />
                    <div className="sequenseImage18-sequense1" style={{ left: this.getClass("sequenseImage18-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage18-sequense2" style={{ left: this.getClass("sequenseImage18-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage18-sequense3" style={{ left: this.getClass("sequenseImage18-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage18-sequense4" style={{ left: this.getClass("sequenseImage18-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage18-sequense5" style={{ left: this.getClass("sequenseImage18-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage18-sequense6" style={{ left: this.getClass("sequenseImage18-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="149,32,160,21,143,15,122,19,112,29,112,39,121,46,129,51,142,49,154,45" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="182,17,164,19,154,28,153,39,162,45,177,50,194,49,205,44,209,30,198,20" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="159,46,137,53,137,63,143,72,159,65,167,65,179,70,184,62,182,53" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="159,67,142,75,139,88,156,95,164,98,176,97,189,90,187,76,174,67" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="153,111,160,98,132,94,117,98,110,108,113,118,129,127,149,128,158,127" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="183,96,158,103,154,114,162,126,176,131,202,129,210,119,209,104,196,95" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage19() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage19} useMap="#image-map" />
                    <div className="sequenseImage19-sequense1" style={{ left: this.getClass("sequenseImage19-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage19-sequense2" style={{ left: this.getClass("sequenseImage19-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage19-sequense3" style={{ left: this.getClass("sequenseImage19-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage19-sequense4" style={{ left: this.getClass("sequenseImage19-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage19-sequense5" style={{ left: this.getClass("sequenseImage19-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage19-sequense6" style={{ left: this.getClass("sequenseImage19-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage19-sequense7" style={{ left: this.getClass("sequenseImage19-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                    <div className="sequenseImage19-sequense8" style={{ left: this.getClass("sequenseImage19-sequense8", this.props.sequences.sequenceNumber8.length) }} onClick={() => this.selectWeldSequence1(8)}>{this.props.sequences.sequenceNumber8}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(8)} coords="141,33,144,21,125,14,103,23,94,36,101,51,114,57,145,48" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(7)} coords="182,35,186,20,168,14,150,18,143,27,144,42,147,49,161,57,187,48,179,54" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="212,14,193,19,183,33,186,47,202,54,217,58,233,49,236,32,227,20" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="144,50,162,59,162,82,150,90,139,92,119,86,113,69,119,57" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="188,51,165,59,163,72,166,86,182,86,206,85,212,77,211,58" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="142,94,164,86,166,99,165,110,152,116,135,122,125,116,117,102,121,87" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="168,87,205,88,211,97,209,111,200,120,189,122,173,114,167,110" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="165,114,136,124,139,139,149,146,165,148,183,141,188,123" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage20() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage20} useMap="#image-map" />
                    <div className="sequenseImage20-sequense1" style={{ left: this.getClass("sequenseImage20-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage20-sequense2" style={{ left: this.getClass("sequenseImage20-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage20-sequense3" style={{ left: this.getClass("sequenseImage20-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage20-sequense4" style={{ left: this.getClass("sequenseImage20-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage20-sequense5" style={{ left: this.getClass("sequenseImage20-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage20-sequense6" style={{ left: this.getClass("sequenseImage20-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage20-sequense7" style={{ left: this.getClass("sequenseImage20-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                    <div className="sequenseImage20-sequense8" style={{ left: this.getClass("sequenseImage20-sequense8", this.props.sequences.sequenceNumber8.length) }} onClick={() => this.selectWeldSequence1(8)}>{this.props.sequences.sequenceNumber8}</div>
                    <div className="sequenseImage20-sequense9" style={{ left: this.getClass("sequenseImage20-sequense9", this.props.sequences.sequenceNumber9.length) }} onClick={() => this.selectWeldSequence1(9)}>{this.props.sequences.sequenceNumber9}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(9)} coords="102,26,104,43,109,49,120,49,142,48,148,43,142,26,145,14,120,11" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(8)} coords="148,44,177,50,185,38,182,24,187,15,168,8,155,10,145,25" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(7)} coords="199,9,184,24,186,38,197,47,204,50,227,48,232,38,229,19,220,11" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="143,67,150,80,133,85,117,81,106,64,113,52,129,52,148,48" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="175,52,150,45,145,56,146,70,151,77,170,78,177,79,187,74,178,60" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="187,42,178,53,185,73,207,81,228,69,232,48,202,52" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="149,79,172,80,175,88,170,98,157,103,143,106,132,96,128,83" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="180,82,188,74,214,80,212,94,201,105,187,104,173,99" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="171,100,147,108,148,122,163,130,186,127,197,109" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage21() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage21} useMap="#image-map" />
                    <div className="sequenseImage21-sequense1" style={{ left: this.getClass("sequenseImage21-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage21-sequense2" style={{ left: this.getClass("sequenseImage21-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage21-sequense3" style={{ left: this.getClass("sequenseImage21-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage21-sequense4" style={{ left: this.getClass("sequenseImage21-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage21-sequense5" style={{ left: this.getClass("sequenseImage21-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage21-sequense6" style={{ left: this.getClass("sequenseImage21-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage21-sequense7" style={{ left: this.getClass("sequenseImage21-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                    <div className="sequenseImage21-sequense8" style={{ left: this.getClass("sequenseImage21-sequense8", this.props.sequences.sequenceNumber8.length) }} onClick={() => this.selectWeldSequence1(8)}>{this.props.sequences.sequenceNumber8}</div>
                    <div className="sequenseImage21-sequense9" style={{ left: this.getClass("sequenseImage21-sequense9", this.props.sequences.sequenceNumber9.length) }} onClick={() => this.selectWeldSequence1(9)}>{this.props.sequences.sequenceNumber9}</div>
                    <div className="sequenseImage21-sequense10" style={{ left: this.getClass("sequenseImage21-sequense10", this.props.sequences.sequenceNumber10.length) }} onClick={() => this.selectWeldSequence1(10)}>{this.props.sequences.sequenceNumber10}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(10)} coords="85,7,64,13,58,32,64,47,75,53,88,52,106,45,100,27,108,15" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(9)} coords="125,9,113,14,102,25,107,42,116,46,125,53,139,50,148,45,140,27,148,13" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(8)} coords="167,8,150,16,141,31,146,46,166,50,177,52,188,45,188,32,191,14" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(7)} coords="210,7,195,17,189,28,192,41,201,48,212,53,228,53,244,40,241,22,232,11" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="108,47,85,55,81,75,90,81,102,87,115,86,125,80,123,62,122,50" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="151,48,124,55,123,70,128,79,138,84,159,86,173,77,167,62,167,53" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="192,46,170,56,168,71,177,79,191,84,205,85,219,77,223,56,208,54" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="128,83,109,89,109,106,123,115,133,118,154,113,162,102,161,88,152,883" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="201,108,207,87,185,86,174,78,169,83,164,86,165,97,160,112,184,116" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="157,113,130,121,133,134,148,144,166,143,181,134,185,117,172,115" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage22() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage22} useMap="#image-map" />
                    <div className="sequenseImage22-sequense1" style={{ left: this.getClass("sequenseImage22-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage22-sequense2" style={{ left: this.getClass("sequenseImage22-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage22-sequense3" style={{ left: this.getClass("sequenseImage22-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage22-sequense4" style={{ left: this.getClass("sequenseImage22-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage22-sequense5" style={{ left: this.getClass("sequenseImage22-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage22-sequense6" style={{ left: this.getClass("sequenseImage22-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage22-sequense7" style={{ left: this.getClass("sequenseImage22-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                    <div className="sequenseImage22-sequense8" style={{ left: this.getClass("sequenseImage22-sequense8", this.props.sequences.sequenceNumber8.length) }} onClick={() => this.selectWeldSequence1(8)}>{this.props.sequences.sequenceNumber8}</div>
                    <div className="sequenseImage22-sequense9" style={{ left: this.getClass("sequenseImage22-sequense9", this.props.sequences.sequenceNumber9.length) }} onClick={() => this.selectWeldSequence1(9)}>{this.props.sequences.sequenceNumber9}</div>
                    <div className="sequenseImage22-sequense10" style={{ left: this.getClass("sequenseImage22-sequense10", this.props.sequences.sequenceNumber10.length) }} onClick={() => this.selectWeldSequence1(10)}>{this.props.sequences.sequenceNumber10}</div>
                    <div className="sequenseImage22-sequense11" style={{ left: this.getClass("sequenseImage22-sequense11", this.props.sequences.sequenceNumber11.length) }} onClick={() => this.selectWeldSequence1(11)}>{this.props.sequences.sequenceNumber11}</div>
                    <div className="sequenseImage22-sequense12" style={{ left: this.getClass("sequenseImage22-sequense12", this.props.sequences.sequenceNumber12.length) }} onClick={() => this.selectWeldSequence1(12)}>{this.props.sequences.sequenceNumber12}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(12)} coords="108,11,86,22,85,34,94,46,102,48,122,45,132,30,123,19" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(11)} coords="132,31,126,17,137,12,153,18,161,27,157,41,148,46,135,49,125,44" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(10)} coords="163,29,157,17,170,13,181,17,190,24,192,33,190,43,182,48,173,52,166,48,157,45" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(9)} coords="194,30,188,19,206,12,223,18,227,30,226,41,219,48,206,48,194,46,189,42" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(8)} coords="122,45,102,51,101,65,108,75,122,75,135,71,133,61,133,50" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(7)} coords="155,47,135,52,134,66,142,74,158,75,168,72,176,66,173,53" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="187,46,175,52,176,66,181,71,191,76,204,77,214,70,220,52,203,50" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="138,72,114,78,110,92,119,104,140,105,148,98,157,93,156,76" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="194,100,201,88,196,78,185,75,175,69,169,74,159,78,155,87,160,99,174,104,185,105" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="137,105,119,103,117,112,122,120,131,128,143,124,156,120,163,108,159,99,155,95" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="190,118,195,101,183,105,172,105,162,101,164,112,157,121,167,127,182,126" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="156,123,130,127,136,138,147,144,160,143,171,137,180,128" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage23() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage23} useMap="#image-map" />
                    <div className="sequenseImage23-sequense1" style={{ left: this.getClass("sequenseImage23-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage23-sequense2" style={{ left: this.getClass("sequenseImage23-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage23-sequense3" style={{ left: this.getClass("sequenseImage23-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage23-sequense4" style={{ left: this.getClass("sequenseImage23-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage23-sequense5" style={{ left: this.getClass("sequenseImage23-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage23-sequense6" style={{ left: this.getClass("sequenseImage23-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage23-sequense7" style={{ left: this.getClass("sequenseImage23-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                    <div className="sequenseImage23-sequense8" style={{ left: this.getClass("sequenseImage23-sequense8", this.props.sequences.sequenceNumber8.length) }} onClick={() => this.selectWeldSequence1(8)}>{this.props.sequences.sequenceNumber8}</div>
                    <div className="sequenseImage23-sequense9" style={{ left: this.getClass("sequenseImage23-sequense9", this.props.sequences.sequenceNumber9.length) }} onClick={() => this.selectWeldSequence1(9)}>{this.props.sequences.sequenceNumber9}</div>
                    <div className="sequenseImage23-sequense10" style={{ left: this.getClass("sequenseImage23-sequense10", this.props.sequences.sequenceNumber10.length) }} onClick={() => this.selectWeldSequence1(10)}>{this.props.sequences.sequenceNumber10}</div>
                    <div className="sequenseImage23-sequense11" style={{ left: this.getClass("sequenseImage23-sequense11", this.props.sequences.sequenceNumber11.length) }} onClick={() => this.selectWeldSequence1(11)}>{this.props.sequences.sequenceNumber11}</div>
                    <div className="sequenseImage23-sequense12" style={{ left: this.getClass("sequenseImage23-sequense12", this.props.sequences.sequenceNumber12.length) }} onClick={() => this.selectWeldSequence1(12)}>{this.props.sequences.sequenceNumber12}</div>
                    <div className="sequenseImage23-sequense13" style={{ left: this.getClass("sequenseImage23-sequense13", this.props.sequences.sequenceNumber13.length) }} onClick={() => this.selectWeldSequence1(13)}>{this.props.sequences.sequenceNumber13}</div>
                    <div className="sequenseImage23-sequense14" style={{ left: this.getClass("sequenseImage23-sequense14", this.props.sequences.sequenceNumber14.length) }} onClick={() => this.selectWeldSequence1(14)}>{this.props.sequences.sequenceNumber14}</div>
                    <div className="sequenseImage23-sequense15" style={{ left: this.getClass("sequenseImage23-sequense15", this.props.sequences.sequenceNumber15.length) }} onClick={() => this.selectWeldSequence1(15)}>{this.props.sequences.sequenceNumber15}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(15)} coords="79,9,61,14,56,33,63,44,72,48,83,49,98,43,107,30,97,11" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(14)} coords="106,27,98,13,115,10,129,13,135,24,135,33,132,44,120,47,112,48,100,43" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(13)} coords="138,27,133,13,157,9,168,19,170,28,168,39,160,45,148,50,140,46,132,43" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(12)} coords="172,25,167,13,189,8,201,13,209,25,207,35,199,43,190,48,176,44,170,41" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(11)} coords="211,27,205,40,214,47,226,48,243,47,250,30,243,16,229,11,215,10,209,17" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(10)} coords="97,45,77,52,75,66,79,75,86,81,94,82,105,78,114,74,110,60,113,49" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(9)} coords="131,45,112,51,112,66,117,73,130,75,140,81,152,71,146,50" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(8)} coords="167,40,148,52,149,65,158,74,171,79,183,78,194,69,197,57,190,48" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(7)} coords="205,41,194,47,198,56,192,67,203,72,211,73,220,70,228,66,236,52,218,50" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="116,75,97,83,96,97,107,108,116,109,125,109,139,98,137,89,136,78" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="154,73,136,82,141,95,153,101,169,104,183,95,183,79,170,80" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="205,102,186,99,184,91,186,77,194,70,203,75,213,73,221,72,223,83,218,92" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="142,99,168,105,165,119,154,125,143,132,128,125,118,113" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="170,105,166,122,178,128,194,128,207,117,208,100,194,102,182,96" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="164,123,140,132,145,145,161,151,176,148,186,143,189,131" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage24() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage24} useMap="#image-map" />
                    <div className="sequenseImage24-sequense1" style={{ left: this.getClass("sequenseImage24-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage24-sequense2" style={{ left: this.getClass("sequenseImage24-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage24-sequense3" style={{ left: this.getClass("sequenseImage24-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage24-sequense4" style={{ left: this.getClass("sequenseImage24-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage24-sequense5" style={{ left: this.getClass("sequenseImage24-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage24-sequense6" style={{ left: this.getClass("sequenseImage24-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage24-sequense7" style={{ left: this.getClass("sequenseImage24-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                </div>
                <map name="image-map">
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(7)} coords="138,6,121,13,116,32,128,43,144,45,158,36,160,24,161,15,153,8" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(6)} coords="186,7,166,12,159,29,165,42,186,47,200,40,206,24,200,11" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(5)} coords="162,37,138,46,142,59,153,64,166,64,179,59,183,47" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(4)} coords="144,60,140,73,140,83,151,80,161,80,169,82,181,88,189,78,180,61,161,66" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(3)} coords="143,83,138,94,154,99,158,103,169,97,181,94,174,84,160,79" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(2)} coords="155,111,157,101,138,97,125,101,115,112,119,124,125,132,142,136,160,126" shape="poly" />
                    <area target="" className="btn" alt="" title="" onClick={() => this.selectWeldSequence1(1)} coords="181,96,160,102,155,113,160,125,172,135,186,136,196,130,203,114,197,101" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage25() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage25} useMap="#image-map" />
                    <div className="sequenseImage25-sequense1" style={{ left: this.getClass("sequenseImage25-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage25-sequense2" style={{ left: this.getClass("sequenseImage25-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage25-sequense3" style={{ left: this.getClass("sequenseImage25-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage25-sequense4" style={{ left: this.getClass("sequenseImage25-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage25-sequense5" style={{ left: this.getClass("sequenseImage25-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage25-sequense6" style={{ left: this.getClass("sequenseImage25-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage25-sequense7" style={{ left: this.getClass("sequenseImage25-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                    <div className="sequenseImage25-sequense8" style={{ left: this.getClass("sequenseImage25-sequense8", this.props.sequences.sequenceNumber8.length) }} onClick={() => this.selectWeldSequence1(8)}>{this.props.sequences.sequenceNumber8}</div>
                    <div className="sequenseImage25-sequense9" style={{ left: this.getClass("sequenseImage25-sequense9", this.props.sequences.sequenceNumber9.length) }} onClick={() => this.selectWeldSequence1(9)}>{this.props.sequences.sequenceNumber9}</div>
                    <div className="sequenseImage25-sequense10" style={{ left: this.getClass("sequenseImage25-sequense10", this.props.sequences.sequenceNumber10.length) }} onClick={() => this.selectWeldSequence1(10)}>{this.props.sequences.sequenceNumber10}</div>
                    <div className="sequenseImage25-sequense11" style={{ left: this.getClass("sequenseImage25-sequense11", this.props.sequences.sequenceNumber11.length) }} onClick={() => this.selectWeldSequence1(11)}>{this.props.sequences.sequenceNumber11}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="111,115,96,130,100,146,118,152,134,149,145,134,137,119,123,115" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="147,133,138,119,158,113,169,119,177,125,177,137,166,147,152,151,140,146" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="179,130,172,119,185,113,205,117,209,127,207,139,198,148,186,150,173,146" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="170,115,152,108,157,90,182,86,197,94,199,105,197,111" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="138,117,115,111,115,93,132,85,145,86,153,91,151,105,153,112" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="153,65,136,73,134,83,149,88,156,90,168,87,179,83,176,73" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(7)} coords="175,40,159,48,158,64,169,70,181,72,189,69,198,51,194,45" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(8)} coords="140,42,157,48,159,61,140,69,125,69,113,61,110,49" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(11)} coords="131,24,138,11,121,9,103,18,98,32,108,39,118,44,131,44,140,40" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(10)} coords="165,23,169,11,148,9,134,19,132,30,136,37,144,41,153,46,162,45,172,39" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(9)} coords="193,6,173,12,167,27,179,44,197,43,211,39,215,20,208,11" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage26() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage26} useMap="#image-map" />
                    <div className="sequenseImage26-sequense1" style={{ left: this.getClass("sequenseImage26-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage26-sequense2" style={{ left: this.getClass("sequenseImage26-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage26-sequense3" style={{ left: this.getClass("sequenseImage26-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage26-sequense4" style={{ left: this.getClass("sequenseImage26-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage26-sequense5" style={{ left: this.getClass("sequenseImage26-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage26-sequense6" style={{ left: this.getClass("sequenseImage26-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage26-sequense7" style={{ left: this.getClass("sequenseImage26-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                    <div className="sequenseImage26-sequense8" style={{ left: this.getClass("sequenseImage26-sequense8", this.props.sequences.sequenceNumber8.length) }} onClick={() => this.selectWeldSequence1(8)}>{this.props.sequences.sequenceNumber8}</div>
                    <div className="sequenseImage26-sequense9" style={{ left: this.getClass("sequenseImage26-sequense9", this.props.sequences.sequenceNumber9.length) }} onClick={() => this.selectWeldSequence1(9)}>{this.props.sequences.sequenceNumber9}</div>
                    <div className="sequenseImage26-sequense10" style={{ left: this.getClass("sequenseImage26-sequense10", this.props.sequences.sequenceNumber10.length) }} onClick={() => this.selectWeldSequence1(10)}>{this.props.sequences.sequenceNumber10}</div>
                    <div className="sequenseImage26-sequense11" style={{ left: this.getClass("sequenseImage26-sequense11", this.props.sequences.sequenceNumber11.length) }} onClick={() => this.selectWeldSequence1(11)}>{this.props.sequences.sequenceNumber11}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="198,124,189,108,208,105,222,117,222,130,212,137,197,144,191,139" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="171,123,162,107,179,106,189,110,194,122,193,130,187,138,174,142,163,139" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="168,114,156,107,142,107,131,113,125,125,131,136,143,143,156,143,166,131" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="122,124,135,107,117,105,103,112,98,126,102,136,112,143,124,146,133,142" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="160,105,127,106,137,90,157,83,178,87,188,90,196,100,194,106" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="159,65,142,61,140,72,142,86,155,84,166,82,180,84,186,72,180,62" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(7)} coords="140,42,128,47,137,62,159,65,175,63,187,54,188,47,172,41,158,48" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(8)} coords="203,24,193,9,210,9,223,21,220,36,212,42,200,45,193,44" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(9)} coords="179,24,172,10,186,10,195,15,201,22,198,30,192,39,189,46,173,42" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(10)} coords="134,25,149,6,171,12,180,26,170,37,165,43,156,46,140,40" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(11)} coords="132,23,139,12,118,6,102,19,102,34,111,43,122,47,136,41" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage27() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage27} useMap="#image-map" />
                    <div className="sequenseImage27-sequense1" style={{ left: this.getClass("sequenseImage27-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage27-sequense2" style={{ left: this.getClass("sequenseImage27-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage27-sequense3" style={{ left: this.getClass("sequenseImage27-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage27-sequense4" style={{ left: this.getClass("sequenseImage27-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage27-sequense5" style={{ left: this.getClass("sequenseImage27-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage27-sequense6" style={{ left: this.getClass("sequenseImage27-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage27-sequense7" style={{ left: this.getClass("sequenseImage27-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                    <div className="sequenseImage27-sequense8" style={{ left: this.getClass("sequenseImage27-sequense8", this.props.sequences.sequenceNumber8.length) }} onClick={() => this.selectWeldSequence1(8)}>{this.props.sequences.sequenceNumber8}</div>
                    <div className="sequenseImage27-sequense9" style={{ left: this.getClass("sequenseImage27-sequense9", this.props.sequences.sequenceNumber9.length) }} onClick={() => this.selectWeldSequence1(9)}>{this.props.sequences.sequenceNumber9}</div>
                    <div className="sequenseImage27-sequense10" style={{ left: this.getClass("sequenseImage27-sequense10", this.props.sequences.sequenceNumber10.length) }} onClick={() => this.selectWeldSequence1(10)}>{this.props.sequences.sequenceNumber10}</div>
                    <div className="sequenseImage27-sequense11" style={{ left: this.getClass("sequenseImage27-sequense11", this.props.sequences.sequenceNumber11.length) }} onClick={() => this.selectWeldSequence1(11)}>{this.props.sequences.sequenceNumber11}</div>
                    <div className="sequenseImage27-sequense12" style={{ left: this.getClass("sequenseImage27-sequense12", this.props.sequences.sequenceNumber12.length) }} onClick={() => this.selectWeldSequence1(11)}>{this.props.sequences.sequenceNumber12}</div>
                    <div className="sequenseImage27-sequense13" style={{ left: this.getClass("sequenseImage27-sequense13", this.props.sequences.sequenceNumber13.length) }} onClick={() => this.selectWeldSequence1(11)}>{this.props.sequences.sequenceNumber13}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="183,121,197,114,216,113,231,127,222,143,207,151,186,145" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="180,129,187,114,173,111,155,118,151,135,159,146,170,150,181,150,187,145" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="149,128,157,113,135,111,124,124,122,134,127,143,140,150,157,148" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="120,129,127,114,105,110,94,119,90,132,96,144,105,150,114,151,126,148" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="182,85,204,93,207,105,198,111,189,113,177,109,160,111,167,94" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="124,113,126,98,136,88,149,87,161,87,165,93,162,105,161,112" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(7)} coords="159,66,138,75,142,81,151,85,159,88,167,91,179,86,184,75" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(8)} coords="175,48,163,46,158,59,167,69,177,72,190,74,205,60,203,51,191,43,187,49" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(9)} coords="157,53,148,49,135,46,123,49,116,58,120,66,129,73,139,73,159,66" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(10)} coords="187,19,204,11,221,12,231,25,231,36,223,45,213,48,201,47,187,37" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(11)} coords="184,26,194,13,181,10,169,13,157,21,157,33,163,43,173,47,186,47,191,44,189,36" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(12)} coords="156,24,161,13,143,8,133,14,126,25,126,38,138,45,151,48,161,43" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(13)} coords="122,25,129,12,108,12,99,17,95,30,100,41,110,48,124,47,130,42" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage28() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage28} useMap="#image-map" />
                    <div className="sequenseImage28-sequense1" style={{ left: this.getClass("sequenseImage28-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage28-sequense2" style={{ left: this.getClass("sequenseImage28-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage28-sequense3" style={{ left: this.getClass("sequenseImage28-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage28-sequense4" style={{ left: this.getClass("sequenseImage28-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage28-sequense5" style={{ left: this.getClass("sequenseImage28-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="196,118,206,104,199,85,184,77,162,84,155,100,160,114,175,125" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="152,97,164,80,143,79,128,89,126,96,127,110,135,118,142,123,159,125,165,119" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="137,56,132,67,137,76,149,77,161,76,169,77,177,65,165,57" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="206,31,193,14,175,13,165,20,156,28,158,46,166,55,177,58,187,59,197,54" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="161,56,134,54,127,40,128,30,136,19,153,15,162,14,153,36" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage29() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage29} useMap="#image-map" />
                    <div className="sequenseImage29-sequense1" style={{ left: this.getClass("sequenseImage29-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage29-sequense2" style={{ left: this.getClass("sequenseImage29-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage29-sequense3" style={{ left: this.getClass("sequenseImage29-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage29-sequense4" style={{ left: this.getClass("sequenseImage29-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage29-sequense5" style={{ left: this.getClass("sequenseImage29-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage29-sequense6" style={{ left: this.getClass("sequenseImage29-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage29-sequense7" style={{ left: this.getClass("sequenseImage29-sequense7", this.props.sequences.sequenceNumber7.length) }} onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber7}</div>
                    <div className="sequenseImage29-sequense8" style={{ left: this.getClass("sequenseImage29-sequense8", this.props.sequences.sequenceNumber8.length) }} onClick={() => this.selectWeldSequence1(8)}>{this.props.sequences.sequenceNumber8}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="240,114,228,97,211,94,197,103,193,117,197,130,205,138,222,142,237,130" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="190,115,204,95,191,92,173,96,165,109,162,123,168,134,179,137,192,137,198,135" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="163,111,172,95,156,93,146,95,134,103,133,113,134,123,144,132,153,136,167,136" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="154,78,143,69,138,84,144,92,160,93,172,92,186,88,184,73,168,81" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="161,56,145,50,142,64,152,74,162,78,177,74,185,66,175,58" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="245,37,239,17,214,12,200,24,200,38,202,49,216,57,235,53" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(7)} coords="196,30,201,14,183,14,168,26,164,39,168,52,186,61,201,51,198,45" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(8)} coords="163,30,179,11,149,14,140,27,138,36,141,45,151,51,166,56" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage30() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage30} useMap="#image-map" />
                    <div className="sequenseImage30-sequense1" style={{ left: this.getClass("sequenseImage30-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage30-sequense2" style={{ left: this.getClass("sequenseImage30-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="184,77,171,54,151,52,136,61,132,81,141,96,153,101,166,105,186,92" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="131,73,137,56,119,52,100,56,87,69,88,84,93,96,101,101,112,104,125,102,137,95" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage31() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage31} useMap="#image-map" />
                    <div className="sequenseImage31-sequense1" style={{ left: this.getClass("sequenseImage31-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage31-sequense2" style={{ left: this.getClass("sequenseImage31-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage31-sequense3" style={{ left: this.getClass("sequenseImage31-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)}  coords="155,95,142,74,124,71,115,78,119,91,123,102,139,107,147,107" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)}  coords="114,74,101,75,99,86,93,97,93,104,99,107,109,109,125,104,116,93" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)}  coords="96,68,78,70,70,78,64,89,64,98,69,103,77,105,91,98,98,85" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage32() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage32} useMap="#image-map" />
                    <div className="sequenseImage32-sequense1" style={{ left: this.getClass("sequenseImage32-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage32-sequense2" style={{ left: this.getClass("sequenseImage32-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage32-sequense3" style={{ left: this.getClass("sequenseImage32-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage32-sequense4" style={{ left: this.getClass("sequenseImage32-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage32-sequense5" style={{ left: this.getClass("sequenseImage32-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="138,85,127,73,106,71,95,83,99,99,114,106,132,103" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="97,76,86,68,77,80,78,95,84,97,94,99" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="56,82,45,77,37,85,41,98,50,104,55,107,67,104,75,96,76,79" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="87,62,97,74,110,70,126,66,121,53,109,44,96,48,89,52" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="83,61,74,49,58,47,45,55,42,64,45,72,53,79,68,80,82,77" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage33() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage33} useMap="#image-map" />
                    <div className="sequenseImage33-sequense1" style={{ left: this.getClass("sequenseImage33-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage33-sequense2" style={{ left: this.getClass("sequenseImage33-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="141,73,122,62,102,70,97,87,98,96,105,103,115,107,132,103,140,92" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="96,84,98,72,84,69,70,75,64,84,65,92,73,103,79,106,86,108,99,103" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage34() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage34} useMap="#image-map" />
                    <div className="sequenseImage34-sequense1" style={{ left: this.getClass("sequenseImage34-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage34-sequense2" style={{ left: this.getClass("sequenseImage34-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage34-sequense3" style={{ left: this.getClass("sequenseImage34-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="169,90,166,76,157,71,143,78,125,80,125,96,134,104,156,105" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="124,77,115,70,101,73,91,80,89,91,95,102,106,107,120,106,126,100" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="161,56,147,44,128,44,114,55,116,69,125,77,144,79,158,70" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage35() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage35} useMap="#image-map" />
                    <div className="sequenseImage35-sequense1" style={{ left: this.getClass("sequenseImage35-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage35-sequense2" style={{ left: this.getClass("sequenseImage35-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage35-sequense3" style={{ left: this.getClass("sequenseImage35-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage35-sequense4" style={{ left: this.getClass("sequenseImage35-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="194,104,185,86,169,79,152,85,147,99,152,117,167,122,178,122" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="148,89,141,73,129,78,120,93,120,104,124,112,131,117,140,119,149,121" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="118,101,119,86,109,92,101,98,101,112,106,118,119,122,130,121" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="149,85,144,70,153,57,179,57,189,63,190,76,187,82,168,77" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage36() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img id="image36" alt="eq" src={sequenceImage36}  useMap="#image-map" />
                    <div className="sequenseImage36-sequense1" style={{ left: this.getClass("sequenseImage36-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="129,57,124,28,102,15,86,21,71,32,64,57,65,82,79,93,98,97,115,88,125,77" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage37() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage37} useMap="#image-map" />
                    <div className="sequenseImage37-sequense1" style={{ left: this.getClass("sequenseImage37-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage37-sequense2" style={{ left: this.getClass("sequenseImage37-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="142,74,124,59,102,60,88,66,87,86,96,96,115,102,132,99" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="116,57,95,53,90,34,100,19,110,15,127,16,143,22,144,31,147,44,138,53" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage38() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage38} useMap="#image-map" />
                    <div className="sequenseImage38-sequense1" style={{ left: this.getClass("sequenseImage38-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage38-sequense2" style={{ left: this.getClass("sequenseImage38-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage38-sequense3" style={{ left: this.getClass("sequenseImage38-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="94,73,84,68,76,80,80,93,89,100,104,97,115,94,121,83,117,72" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="102,49,88,43,80,53,83,64,90,70,98,71,105,70,117,69,126,60,125,48" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="108,7,89,11,81,23,86,38,97,44,112,46,125,43,134,25,125,11" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage39() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage39} useMap="#image-map" />
                    <div className="sequenseImage39-sequense1" style={{ left: this.getClass("sequenseImage39-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage39-sequense2" style={{ left: this.getClass("sequenseImage39-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage39-sequense3" style={{ left: this.getClass("sequenseImage39-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage39-sequense4" style={{ left: this.getClass("sequenseImage39-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="95,74,80,68,71,78,74,88,86,93,101,95,112,88,116,78,112,70" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="106,46,118,52,114,66,101,71,90,71,78,64,73,46,90,51" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="144,32,136,16,118,12,104,18,101,30,104,40,114,48,126,48,133,47" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="98,28,103,15,84,14,71,22,67,32,73,42,80,47,90,49,103,46" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage40() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage40} useMap="#image-map" />
                    <div className="sequenseImage40-sequense1" style={{ left: this.getClass("sequenseImage40-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage40-sequense2" style={{ left: this.getClass("sequenseImage40-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage40-sequense3" style={{ left: this.getClass("sequenseImage40-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage40-sequense4" style={{ left: this.getClass("sequenseImage40-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage40-sequense5" style={{ left: this.getClass("sequenseImage40-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="94,79,74,76,68,88,75,101,89,106,101,105,109,98,110,82" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="100,49,90,54,87,68,98,77,109,80,123,78,128,69,130,54,114,52" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="87,52,73,49,66,57,65,71,74,76,93,78,88,65" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="115,18,99,21,93,35,98,46,109,50,126,52,132,46,136,31,129,22" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="92,33,100,18,89,18,73,18,69,29,67,38,73,47,87,51,100,48" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage41() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage41} useMap="#image-map" />
                    <div className="sequenseImage41-sequense1" style={{ left: this.getClass("sequenseImage41-sequense1", this.props.sequences.sequenceNumber1.length) }} onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage41-sequense2" style={{ left: this.getClass("sequenseImage41-sequense2", this.props.sequences.sequenceNumber2.length) }} onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage41-sequense3" style={{ left: this.getClass("sequenseImage41-sequense3", this.props.sequences.sequenceNumber3.length) }} onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage41-sequense4" style={{ left: this.getClass("sequenseImage41-sequense4", this.props.sequences.sequenceNumber4.length) }} onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage41-sequense5" style={{ left: this.getClass("sequenseImage41-sequense5", this.props.sequences.sequenceNumber5.length) }} onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage41-sequense6" style={{ left: this.getClass("sequenseImage41-sequense6", this.props.sequences.sequenceNumber6.length) }} onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)}  coords="94,75,80,71,75,83,79,93,90,100,103,101,116,93,119,82,112,73" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)}  coords="120,52,106,47,99,55,102,65,108,70,118,73,127,75,139,63,142,54,134,48" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)}  coords="106,71,91,75,80,72,74,60,78,52,82,48,89,51,97,52" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)}  coords="147,17,135,21,130,35,132,43,139,49,150,51,162,49,170,43,173,28,159,18" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)}  coords="128,32,135,17,123,18,113,18,105,23,101,37,106,44,113,48,124,50,132,47" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)}  coords="99,29,108,15,90,16,78,23,74,31,75,41,81,45,87,48,96,52,106,44" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage42() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage42} useMap="#image-map" />
                    <div className="sequenseImage42-sequense1"  onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage42-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="98,66,83,51,78,65,85,78,92,85,100,89,116,88" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="84,79,78,69,72,80,77,91,88,99,104,92,98,90,97,92" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage43() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage43} useMap="#image-map" />
                    <div className="sequenseImage43-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage43-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage43-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage43-sequense4"  onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="113,58,110,70,96,66,89,50,92,40,96,36" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="146,89,131,99,115,85,111,73,111,64,116,60" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="109,71,89,62,96,78,103,86,109,89,119,90" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="98,84,91,69,85,81,88,89,95,96,105,97,112,92" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage44() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage44} useMap="#image-map" />
                    <div className="sequenseImage44-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage44-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage44-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage44-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage44-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="95,43,81,28,70,40,74,53,82,58" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="94,47,114,68,103,76,91,71,87,64,86,55" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="114,70,101,76,101,85,114,94,123,92,129,87" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="89,70,82,58,77,59,77,71,86,86,101,90,99,80" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="81,81,75,68,70,83,78,90,84,94,95,92" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage45() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage45} useMap="#image-map" />
                    <div className="sequenseImage45-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage45-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage45-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="86,57,78,46,71,61,77,71,89,77,95,72,98,67" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="100,69,91,77,94,84,103,89,113,82" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="87,77,72,67,71,78,75,87,85,90,97,88" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage46() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage46} useMap="#image-map" />
                    <div className="sequenseImage46-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage46-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage46-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage46-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage46-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="88,48,74,33,67,41,69,56,77,64,92,67,104,61" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="105,63,93,68,95,81,104,86,115,89,122,82" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="79,66,69,59,69,77,84,83,88,75,91,67" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="97,83,91,71,85,83,90,93,101,100,111,96,115,88" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="83,84,70,78,70,90,78,95,91,95" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage47() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage47} useMap="#image-map" />
                    <div className="sequenseImage47-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage47-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage47-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage47-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage47-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage47-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="87,41,80,33,73,40,75,55,87,58,93,51" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="105,60,94,50,87,63,95,75,101,76,117,76" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="120,77,107,78,105,90,117,96,125,95,132,92" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="86,60,75,56,72,69,77,76,84,80,90,71" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="103,78,90,72,86,82,90,90,97,95,106,94" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="85,81,74,74,73,85,75,92,82,95,94,97" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage48() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage48} useMap="#image-map" />
                    <div className="sequenseImage48-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="122,108,113,96,103,104,110,123,123,126,132,121" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage49() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage49} useMap="#image-map" />
                    <div className="sequenseImage49-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="109,96,95,82,85,98,96,108,109,114,118,104" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage50() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage50} useMap="#image-map" />
                    <div className="sequenseImage50-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage50-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="108,78,94,66,92,80,98,91,105,95,117,98,121,95" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="95,89,92,80,87,89,92,103,103,105,110,98" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage51() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage51} useMap="#image-map" />
                    <div className="sequenseImage51-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage51-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage51-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage51-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="129,77,121,65,114,81,118,92,127,101,135,103,139,90" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="156,106,141,92,136,105,141,119,154,132,168,130,174,120" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="133,104,116,92,120,110,128,120,138,125,146,127" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="120,114,114,102,108,118,114,126,122,133,136,134,141,126" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage52() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage52} useMap="#image-map" />
                    <div className="sequenseImage52-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage52-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage52-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage52-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage52-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="114,113,109,100,105,113,111,124,118,129,127,129,132,125" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="132,107,142,126,123,119,114,112,110,99,110,90,118,91" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="152,102,136,108,139,118,148,128,156,125,163,122,166,115" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="139,85,130,74,122,84,121,99,129,103,141,106,151,100" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="129,72,117,88,106,82,104,66,111,57,115,51" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage53() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage53} useMap="#image-map" />
                    <div className="sequenseImage53-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage53-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage53-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="127,110,113,100,106,112,112,124,124,128,134,128,138,124" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="145,106,133,92,130,111,139,123,149,127,159,119" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="117,108,129,109,133,93,124,82,119,76,110,92" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage54() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage54} useMap="#image-map" />
                    <div className="sequenseImage54-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage54-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage54-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage54-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage54-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="133,119,138,133,121,135,114,121,115,105" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="151,104,140,104,134,121,139,130,151,140,167,135,155,124" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="133,102,116,91,117,104,121,111,125,116,134,118,139,106" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="161,100,153,104,153,115,159,125,173,132,185,123" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="142,78,123,56,114,73,118,91,138,104,152,104,159,97" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage55() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage55} useMap="#image-map" />
                    <div className="sequenseImage55-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage55-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage55-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage55-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage55-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage55-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="129,110,115,104,112,117,118,126,128,131,138,129" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="130,84,117,79,112,93,119,107,129,110,133,97" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="147,112,135,102,132,114,136,127,146,130,156,133" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="167,107,150,111,150,121,161,134,172,130,181,123,176,120" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="154,93,139,73,133,85,134,97,145,110,152,109,167,107" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="131,60,123,51,116,60,113,69,117,78,129,83,140,75" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage56() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage56} useMap="#image-map" />
                    <div className="sequenseImage56-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="52,70,39,62,29,78,40,92,57,96,67,79" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage57() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage57} useMap="#image-map" />
                    <div className="sequenseImage57-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage57-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="45,84,35,75,31,84,36,98,47,99,57,99,65,92" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="60,65,37,49,27,61,39,78,50,87,73,92,85,84" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage58() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage58} useMap="#image-map" />
                    <div className="sequenseImage58-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage58-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage58-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage58-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage58-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="41,84,29,74,28,92,38,100,50,101,60,96" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="63,77,75,92,62,95,43,85,33,74,31,63,44,62" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="84,67,71,77,75,92,92,92,103,89,105,79" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="66,54,49,42,44,53,49,67,59,76,74,75,83,66" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="46,40,34,22,25,35,27,48,34,60,45,61" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage59() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage59} useMap="#image-map" />
                    <div className="sequenseImage59-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage59-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage59-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage59-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="39,78,30,66,26,83,33,91,45,97,53,94,59,87" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="51,60,28,57,31,66,38,76,51,85,58,81" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="76,62,61,54,54,63,55,81,64,86,77,91,92,84,96,75" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="46,36,32,20,20,31,19,43,26,55,39,60,52,59,61,52" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage60() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage60} useMap="#image-map" />
                    <div className="sequenseImage60-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage60-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage60-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="35,79,24,70,22,85,27,92,36,99,50,97" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="58,71,37,81,46,91,60,97,75,90,81,79" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="43,56,30,43,23,59,28,72,36,77,46,79,59,73" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage61() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage61} useMap="#image-map" />
                    <div className="sequenseImage61-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage61-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage61-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage61-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage61-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="37,75,20,69,18,80,25,93,35,98,44,97,55,94,47,89" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="59,69,39,77,47,86,55,90,62,92,74,89,63,78" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="40,61,26,47,18,59,25,70,36,73,48,76,58,68,61,58" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="81,62,66,54,60,66,64,80,75,88,86,88,97,81,101,76" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="49,34,30,16,24,41,30,51,43,60,58,59,65,51" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage62() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage62} useMap="#image-map" />
                    <div className="sequenseImage62-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage62-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage62-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage62-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage62-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage62-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="41,77,25,68,23,78,27,89,37,97,49,95" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="65,76,53,73,43,77,47,88,61,95,74,94,77,91" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="41,55,27,44,23,57,27,68,34,74,42,77,49,71" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="91,70,104,76,93,88,81,90,70,82,68,76,78,68" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="61,54,49,44,43,55,46,65,54,72,67,75,78,65" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="43,33,37,19,26,27,26,38,30,48,40,54,48,42" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage63() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage63} useMap="#image-map" />
                    <div className="sequenseImage63-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="149,90,137,70,115,82,114,98,120,109,138,116,150,119,161,109" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage64() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage64} useMap="#image-map" />
                    <div className="sequenseImage64-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage64-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="126,106,118,79,100,89,98,105,110,119,123,125,136,124,145,120" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="153,83,133,57,124,72,122,87,127,102,138,111,147,117,161,117,168,108" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage65() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage65} useMap="#image-map" />
                    <div className="sequenseImage65-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage65-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage65-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage65-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="112,97,111,75,97,86,94,105,102,116,115,121,132,120,121,112" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="126,80,117,67,112,79,112,94,116,102,124,114,138,117,148,116,141,89" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="155,79,145,85,143,95,147,109,157,117,168,122,176,112" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="141,55,133,41,120,44,111,58,122,72,132,82,148,82,155,78" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage66() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage66} useMap="#image-map" />
                    <div className="sequenseImage66-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage66-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage66-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage66-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage66-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="116,100,114,79,100,92,102,106,109,114,122,116,130,115" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="134,92,116,76,115,93,117,102,129,112,139,114,139,100" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="158,100,140,101,143,115,149,122,158,123,168,120,177,112" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="149,81,139,63,132,71,128,81,134,92,142,100,153,100,157,98" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="138,62,135,50,122,56,118,66,116,72,119,79,126,87" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage67() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage67} useMap="#image-map" />
                    <div className="sequenseImage67-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage67-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage67-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="128,100,121,89,112,94,105,106,113,122,126,132,142,125,129,115" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="146,108,131,101,128,108,135,119,144,123,154,122,164,116,170,110,158,104" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="148,85,138,66,124,74,119,86,128,98,141,105,151,106,156,100" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage68() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage68} useMap="#image-map" />
                    <div className="sequenseImage68-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage68-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage68-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage68-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage68-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="122,109,112,97,105,110,106,125,113,134,133,136,123,125" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="141,112,123,110,124,124,133,131,145,135,159,128,148,121" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="128,90,118,74,111,84,113,94,116,100,121,105,132,112,138,109,136,97" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="156,91,139,98,141,109,148,119,155,124,165,124,171,120,173,107" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="145,64,136,42,125,49,119,64,122,77,125,85,134,94,145,94,153,89" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage69() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage69} useMap="#image-map" />
                    <div className="sequenseImage69-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage69-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage69-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage69-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage69-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage69-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="124,102,110,94,108,106,110,117,117,121,128,122,134,117" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="146,95,133,86,125,96,128,103,134,118,152,119,145,106" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="127,80,108,72,106,87,109,92,114,97,118,101,125,99,131,87" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="162,94,148,96,145,105,149,114,158,117,166,120,172,117,179,115" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="136,52,131,37,118,36,109,43,104,51,103,62,107,68,115,78,124,78" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="150,73,136,56,128,69,130,86,143,93,155,93,162,93" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage70() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage70} useMap="#image-map" />
                    <div className="sequenseImage70-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage70-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage70-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage70-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage70-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="110,69,128,66,114,55,98,57,93,63,88,73,91,87" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="126,80,131,69,117,68,102,74,98,82,96,91,96,99,106,95" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="104,112,105,97,95,100,90,110,89,119,90,126,95,133,103,141,108,127" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="140,101,154,87,138,82,122,82,119,88,109,93,104,104,105,114,108,121,114,123" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="156,88,173,78,167,66,159,62,149,62,138,64,126,80,145,80" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage71() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage71} useMap="#image-map" />
                    <div className="sequenseImage71-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage71-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage71-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="116,81,131,67,122,58,101,66,96,76,93,87,95,94,106,84" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="146,93,156,81,148,69,133,66,121,77,114,89,119,101,125,107,139,96" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="103,89,116,85,117,97,120,107,116,111,104,127,95,122,93,100" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage72() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage72} useMap="#image-map" />
                    <div className="sequenseImage72-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage72-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage72-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage72-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage72-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="107,80,122,63,101,63,92,70,88,82,90,98" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="111,99,105,84,97,89,92,97,89,109,95,122,101,109" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="129,77,139,68,124,62,114,69,108,84,108,97,122,99,124,89" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="156,90,171,80,160,66,138,70,131,77,126,88,125,95,137,103" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="129,118,139,104,125,98,113,101,104,106,100,113,97,127,97,136,102,149" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage73() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage73} useMap="#image-map" />
                    <div className="sequenseImage73-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage73-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage73-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage73-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage73-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage73-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="118,86,122,73,111,71,103,76,100,84,99,90,99,96" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="144,83,147,72,138,68,129,71,123,74,120,81,122,88,134,89" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="120,103,129,90,118,85,112,89,104,94,101,99,96,108" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="165,91,182,77,172,68,159,65,153,68,148,74,143,82" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="149,106,163,92,148,85,137,85,128,91,123,97,121,103,120,116,125,125" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="116,136,124,125,118,112,115,105,107,106,98,113,96,121,93,130,98,141,103,149" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage74() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage74} useMap="#image-map" />
                    <div className="sequenseImage74-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="124,39,133,24,119,15,109,21,103,29,105,40,107,48,115,52" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage75() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage75} useMap="#image-map" />
                    <div className="sequenseImage75-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage75-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="117,25,130,17,114,13,105,16,98,25,99,35,105,42" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="134,41,145,25,129,19,121,24,113,30,107,38,110,48,110,56,115,62" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage76() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage76} useMap="#image-map" />
                    <div className="sequenseImage76-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage76-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage76-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage76-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="112,22,129,15,111,9,105,13,97,22,98,30,100,38,106,42" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="127,34,131,18,120,18,115,21,111,28,109,35,110,41" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="157,30,170,19,153,10,137,14,131,21,127,31,132,37,140,41" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="126,60,137,42,127,37,109,41,105,48,103,56,102,65,108,75,113,79" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage77() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage77} useMap="#image-map" />
                    <div className="sequenseImage77-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage77-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage77-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage77-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage77-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage77-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                    <div className="sequenseImage77-sequense7" onClick={() => this.selectWeldSequence1(7)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="116,18,128,13,111,7,102,11,99,18,94,26,98,33,103,41,107,25" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="127,26,131,16,121,14,115,19,110,25,108,31,107,38,116,37,128,38" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="152,19,159,15,146,10,136,12,132,18,129,24,128,31,130,37,141,34,146,35" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="121,46,128,39,112,38,104,44,101,51,101,62,105,67,118,56" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="176,30,188,19,172,14,156,14,150,21,147,31,153,38,161,44" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(6)} coords="149,56,161,44,149,37,135,35,126,39,121,47,120,54,123,64,132,79" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(7)} coords="106,67,118,58,125,70,130,78,122,86,116,96,107,99,99,82" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage78() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage78} useMap="#image-map" />
                    <div className="sequenseImage78-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage78-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage78-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="120,29,132,22,116,16,103,21,99,26,96,33,101,41,105,45,116,40" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="143,37,152,30,141,21,127,24,122,28,119,34,120,40,133,44" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="123,58,131,44,119,40,105,45,103,53,103,63,108,70,114,73" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage79() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage79} useMap="#image-map" />
                    <div className="sequenseImage79-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage79-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage79-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage79-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage79-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="120,26,126,16,115,13,103,14,98,23,95,32,102,44,108,37,117,34" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="142,24,148,18,136,14,126,17,121,24,120,32,133,36" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="115,53,109,63,101,50,108,42,116,33,125,35,131,37,132,44" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="165,35,177,24,161,16,147,19,139,28,134,36,135,42,149,48" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="130,70,150,51,140,46,119,47,114,52,110,62,106,71,108,82,112,89" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage80() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage80} useMap="#image-map" />
                    <div className="sequenseImage80-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage80-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage80-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage80-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage80-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage80-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="121,34,132,19,119,18,107,21,103,26,102,30,103,43" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="125,43,133,38,119,36,108,42,102,50,102,56,104,62,119,54" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="145,33,150,22,138,17,128,23,124,34,134,36,141,37" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="124,66,119,57,109,61,103,70,103,79,108,87,113,92,128,74" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="143,57,158,44,145,37,134,40,126,44,123,52,123,61,129,76" shape="poly" />
                    <area alt=""  className="btn" onClick={() => this.selectWeldSequence1(6)} coords="161,44,146,34,150,23,159,18,166,17,175,21,183,26" shape="poly" />

                </map>
            </React.Fragment>
        );
    }
    private renderImage81() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage81} useMap="#image-map" />
                    <div className="sequenseImage81-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="124,93,144,79,131,62,122,58,105,56,96,62,86,71,88,82,106,94" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage82() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage82} useMap="#image-map" />
                    <div className="sequenseImage82-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage82-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="119,81,137,69,105,61,93,71,92,81,97,87,103,92,111,97" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="142,96,151,81,140,75,130,74,124,76,119,84,114,91,112,101,118,109,130,114" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage83() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage83} useMap="#image-map" />
                    <div className="sequenseImage83-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage83-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage83-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage83-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">

                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="114,77,136,68,105,63,96,67,90,73,87,83,97,89,106,96" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="135,81,142,73,125,69,116,78,114,84,112,95,114,101,131,97" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="139,107,132,100,123,100,112,106,109,116,110,128,115,136,123,141" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="162,85,171,77,155,67,147,71,139,76,133,87,136,98,141,104" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage84() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage84} useMap="#image-map" />
                    <div className="sequenseImage84-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage84-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage84-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage84-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage84-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="109,73,128,65,100,60,89,65,83,73,89,87,98,92,103,95" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="125,81,131,65,120,65,109,74,107,89,109,96,123,91" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="121,107,121,93,108,99,104,109,105,118,114,128,122,133,130,118" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="147,95,156,83,137,81,130,85,123,90,121,99,125,108,129,115" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="147,78,125,84,131,68,141,64,152,64,163,68,167,77,158,84" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage85() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage85} useMap="#image-map" />
                    <div className="sequenseImage85-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage85-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage85-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage85-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage85-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="108,80,114,63,95,64,86,71,82,78,85,87,95,95" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="117,94,111,83,103,86,98,93,93,106,97,112,104,119,109,104" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="132,72,149,64,136,53,119,61,114,68,112,81,119,95,125,80" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="135,122,150,104,131,94,116,97,111,105,106,117,110,130,116,139,125,141" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="160,85,170,75,156,65,145,66,138,70,132,74,124,87,131,92,148,104" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage86() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage86} useMap="#image-map" />
                    <div className="sequenseImage86-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage86-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage86-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="121,75,131,64,118,61,105,61,99,64,93,76,101,83" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="122,116,131,103,120,94,120,81,111,79,101,84,95,95,97,106,110,115" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="147,90,165,76,146,63,133,66,125,72,121,81,123,92,132,103" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage87() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage87} useMap="#image-map" />
                    <div className="sequenseImage87-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage87-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage87-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage87-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage87-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage87-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="109,84,111,70,97,70,91,78,88,84,88,92,93,103" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="122,102,132,90,108,85,102,90,99,97,96,105,103,114,106,123,117,116" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="134,86,139,69,128,66,120,69,113,71,109,83" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="109,127,119,113,125,125,130,131,127,143,118,147,112,138" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="140,111,150,89,137,87,129,94,123,102,120,117,133,129" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(6)} coords="153,91,164,77,153,70,144,70,140,74,134,86" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage88() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage88} useMap="#image-map" />
                    <div className="sequenseImage88-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="98,53,108,38,99,24,78,27,67,34,68,48,74,61,86,69" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage89() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage89} useMap="#image-map" />
                    <div className="sequenseImage89-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage89-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="79,41,75,62,64,48,72,27,83,23,96,23,98,29" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="105,52,120,35,103,28,92,32,84,43,78,54,79,62,88,70" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage90() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage90} useMap="#image-map" />
                    <div className="sequenseImage90-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage90-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage90-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage90-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage90-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="84,37,100,27,81,20,67,29,63,40,68,52,75,63" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="101,46,109,42,108,31,98,31,89,36,83,43,78,53,78,63,92,62" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="102,81,94,61,82,63,73,70,66,81,68,93,73,101,88,107" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="117,62,127,47,116,44,109,44,103,46,96,56,96,65,101,80" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="128,46,108,40,114,22,129,19,143,20,150,25,151,32" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage91() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage91} useMap="#image-map" />
                    <div className="sequenseImage91-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage91-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage91-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="94,42,98,22,82,18,71,25,70,32,69,40,69,52,72,56" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="100,71,110,51,93,44,81,47,75,59,77,74,82,82,92,90" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="113,52,97,42,97,27,109,21,121,20,131,25,135,30" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage92() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage92} useMap="#image-map" />
                    <div className="sequenseImage92-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage92-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage92-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage92-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage92-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage92-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="92,44,92,25,79,20,70,28,67,37,69,55,78,47" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="87,69,91,50,82,49,76,53,72,63,70,71,71,79" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="111,34,120,23,103,18,100,23,95,28,92,36,93,43" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="99,81,88,66,78,74,72,82,72,98,76,105,86,112" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="117,60,132,43,116,38,105,39,94,45,90,57,89,72,99,78" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(6)} coords="112,34,133,42,153,34,143,25,132,23,120,24" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage93() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage93} useMap="#image-map" />
                    <div className="sequenseImage93-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage93-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage93-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage93-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage93-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="94,75,80,71,75,83,79,93,90,100,103,101,116,93,119,82,112,73" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="120,52,106,47,99,55,102,65,108,70,118,73,127,75,139,63,142,54,134,48" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(3)} coords="106,71,91,75,80,72,74,60,78,52,82,48,89,51,97,52" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(4)} coords="147,17,135,21,130,35,132,43,139,49,150,51,162,49,170,43,173,28,159,18" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(5)} coords="128,32,135,17,123,18,113,18,105,23,101,37,106,44,113,48,124,50,132,47" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage94() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage94} useMap="#image-map" />
                    <div className="sequenseImage94-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="119,98,130,69,108,52,88,51,74,55,67,63,66,84,76,100,86,108,101,112,115,115" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage95() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage95} useMap="#image-map" />
                    <div className="sequenseImage95-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage95-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(1)} coords="86,74,106,62,90,51,66,61,60,75,62,86,69,96,79,102,80,87" shape="poly" />
                    <area alt="" className="btn" onClick={() => this.selectWeldSequence1(2)} coords="124,93,133,68,108,59,92,73,85,86,83,100,88,109,99,115,116,115" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage96() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage96} useMap="#image-map" />
                    <div className="sequenseImage96-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage96-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage96-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage96-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="75,71,87,59,72,55,58,61,55,71,60,88,77,93,74,82" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="99,81,100,61,92,59,85,63,80,67,77,73,77,87,89,96" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="121,101,126,87,109,80,99,84,91,94,92,105,100,111,116,115" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="133,74,134,63,122,55,113,57,108,61,101,68,101,76,114,79,126,85" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage97() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage97} useMap="#image-map" />
                    <div className="sequenseImage97-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage97-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage97-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage97-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage97-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="76,74,82,61,66,61,60,67,55,73,56,83,60,90,77,96,75,84,74,77" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="97,83,96,65,87,63,81,67,77,73,74,81,79,90,87,95" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="100,97,97,83,87,90,88,101,90,114,109,115,118,110" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="123,90,130,74,117,71,104,74,103,81,101,92,105,100,115,105" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="109,72,99,80,96,67,105,60,112,58,122,58,127,63,131,71" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage98() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage98} useMap="#image-map" />
                    <div className="sequenseImage98-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage98-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage98-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="89,77,94,58,73,56,62,65,58,81,65,90,77,96,85,99" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="115,99,120,84,109,77,96,79,89,86,88,100,94,110,112,113" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="111,73,90,80,94,64,108,55,126,57,133,67,129,76,122,82,116,76" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage99() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage99} useMap="#image-map" />
                    <div className="sequenseImage99-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage99-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage99-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage99-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage99-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="83,75,86,56,75,56,61,62,58,75,63,84,69,92,74,85" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="90,87,102,79,96,72,88,75,82,78,77,81,74,96,79,101,88,106" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="101,68,114,59,107,54,95,54,89,58,86,65,84,73,96,72" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="118,99,122,86,109,79,97,79,91,87,90,97,92,108,101,113,117,112" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="123,84,133,67,123,58,114,60,104,65,102,75,109,78" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage100() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage100} useMap="#image-map" />
                    <div className="sequenseImage100-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage100-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage100-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage100-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage100-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage100-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="77,77,80,60,71,61,62,64,54,72,54,84,61,91" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="84,90,101,84,86,77,73,79,66,90,66,99,70,108,82,114" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="104,81,79,74,81,62,89,56,101,55,112,59" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="101,99,99,83,90,87,84,94,84,108,87,118,103,122,114,114" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="122,93,129,76,115,73,107,77,103,89,104,104,115,110" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(6)} coords="129,76,135,65,120,60,109,63,107,76" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage101() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage101} useMap="#image-map" />
                    <div className="sequenseImage101-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="114,101,140,82,144,59,140,42,129,28,113,27,90,24,76,27,61,47,60,68,72,88,90,97" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage102() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage102} useMap="#image-map" />
                    <div className="sequenseImage102-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage102-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="108,76,128,62,133,47,129,29,111,25,88,20,78,24,69,41,72,59,86,69,96,73" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="109,75,129,61,131,83,122,93,110,100,96,101,79,95,67,88,61,76,60,65,69,50,87,72" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage103() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage103} useMap="#image-map" />
                    <div className="sequenseImage103-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage103-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage103-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage103-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="96,23,77,9,68,17,63,30,69,43,75,50,95,51,92,34" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="136,46,134,29,116,23,103,22,95,23,94,32,95,46,103,55,119,58" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="107,71,120,59,105,55,96,50,86,52,78,52,69,49,72,60,82,69,92,76" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="95,76,113,68,114,81,106,90,95,96,81,94,69,84,64,75,69,60,80,67" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage104() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage104} useMap="#image-map" />
                    <div className="sequenseImage104-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage104-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage104-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage104-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage104-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="92,72,111,76,105,93,86,94,78,84,77,70,78,61" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="108,49,132,59,117,74,101,72,90,71,80,63,75,51,78,42,94,49" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="130,25,153,27,153,43,139,54,125,56,114,48,127,39" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="95,16,130,25,126,36,121,41,108,45,97,39,93,29" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="89,49,102,46,95,39,89,29,92,18,89,11,82,5,75,13,73,24,79,37" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage105() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage105} useMap="#image-map" />
                    <div className="sequenseImage105-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage105-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage105-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="92,41,107,63,91,72,70,54,68,40,66,24,74,11,81,8,92,12,100,22" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="97,69,108,64,124,64,119,76,115,83,108,88,98,91,90,91,77,85,72,75,73,62" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="120,23,144,26,145,46,134,59,121,62,109,61,95,46,96,31,102,20,112,22" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage106() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage106} useMap="#image-map" />
                    <div className="sequenseImage106-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage106-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage106-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage106-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage106-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="93,48,107,45,102,18,90,12,81,8,74,13,71,25,76,36,80,41" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="91,48,69,31,65,40,63,51,67,61,73,68,77,74,90,75,90,63" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="95,76,69,69,69,81,77,95,84,99,97,100,110,100,117,84" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="121,58,107,47,92,50,90,62,95,75,105,80,117,81,133,74,141,66,142,52" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="123,22,149,26,146,44,139,51,127,54,116,54,107,43,104,29,104,18" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage107() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage107} useMap="#image-map" />
                    <div className="sequenseImage107-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage107-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage107-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage107-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                    <div className="sequenseImage107-sequense5" onClick={() => this.selectWeldSequence1(5)}>{this.props.sequences.sequenceNumber5}</div>
                    <div className="sequenseImage107-sequense6" onClick={() => this.selectWeldSequence1(6)}>{this.props.sequences.sequenceNumber6}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="88,29,99,42,82,52,66,42,69,32,73,21,79,13,91,17" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="83,62,96,76,80,80,67,66,68,53,73,47,86,53" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="96,79,109,80,108,90,100,99,88,100,74,96,71,88,71,76" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="103,44,87,52,86,64,94,73,105,78,116,75,127,64,108,54" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(5)} coords="120,43,104,46,113,54,124,58,136,56,147,46,155,33,152,24,134,27" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(6)} coords="114,27,132,26,121,39,112,43,101,41,94,31,93,19" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage108() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage108} useMap="#image-map" />
                    <div className="sequenseImage108-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="123,97,141,82,125,73,108,72,91,78,91,92,96,107,99,112" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage109() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage109} useMap="#image-map" />
                    <div className="sequenseImage109-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage109-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="115,73,132,72,125,58,104,61,96,68,94,77,94,90,97,102,102,84" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="125,102,146,81,132,71,120,71,110,76,106,82,102,91,99,104,105,118" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
    private renderImage110() {
        return (
            <React.Fragment>
                <div className="container-sequense">
                    <img alt="eq" src={sequenceImage110} useMap="#image-map" />
                    <div className="sequenseImage110-sequense1" onClick={() => this.selectWeldSequence1(1)}>{this.props.sequences.sequenceNumber1}</div>
                    <div className="sequenseImage110-sequense2" onClick={() => this.selectWeldSequence1(2)}>{this.props.sequences.sequenceNumber2}</div>
                    <div className="sequenseImage110-sequense3" onClick={() => this.selectWeldSequence1(3)}>{this.props.sequences.sequenceNumber3}</div>
                    <div className="sequenseImage110-sequense4" onClick={() => this.selectWeldSequence1(4)}>{this.props.sequences.sequenceNumber4}</div>
                </div>
                <map name="image-map">
                    <area className="btn" onClick={() => this.selectWeldSequence1(1)} coords="109,76,133,66,118,60,109,60,101,65,95,74,91,88,95,98,102,83" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(2)} coords="121,93,133,73,119,71,112,75,101,81,100,88,97,97,97,105" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(3)} coords="116,119,126,103,122,92,106,100,100,104,96,112,94,122,99,134,106,132" shape="poly" />
                    <area className="btn" onClick={() => this.selectWeldSequence1(4)} coords="154,89,174,81,165,67,156,65,142,65,135,69,130,75,125,89,126,101,130,106" shape="poly" />
                </map>
            </React.Fragment>
        );
    }
   

    private selectWelDBaseLogo() {
        return (this.props.sequences.imageId === 0);
    }
    private selectImage1() {
        return (this.props.sequences.imageId === 1);
    }
    private selectImage2() {
        return (this.props.sequences.imageId === 2);
    }
    private selectImage3() {
        return (this.props.sequences.imageId === 3);
    }
    private selectImage4() {
        return (this.props.sequences.imageId === 4);
    }
    private selectImage5() {
        return (this.props.sequences.imageId === 5);
    }
    private selectImage6() {
        return (this.props.sequences.imageId === 6);
    }
    private selectImage7() {
        return (this.props.sequences.imageId === 7);
    }
    private selectImage8() {
        return (this.props.sequences.imageId === 8);
    }
    private selectImage9() {
        return (this.props.sequences.imageId === 9);
    }
    private selectImage10() {
        return (this.props.sequences.imageId === 10);
    }
    private selectImage11() {
        return (this.props.sequences.imageId === 11);
    }
    private selectImage12() {
        return (this.props.sequences.imageId === 12);
    }
    private selectImage13() {
        return (this.props.sequences.imageId === 13);
    }
    private selectImage14() {
        return (this.props.sequences.imageId === 14);
    }
    private selectImage15() {
        return (this.props.sequences.imageId === 15);
    }
    private selectImage16() {
        return (this.props.sequences.imageId === 16);
    }
    private selectImage17() {
        return (this.props.sequences.imageId === 17);
    }
    private selectImage18() {
        return (this.props.sequences.imageId === 18);
    }
    private selectImage19() {
        return (this.props.sequences.imageId === 19);
    }
    private selectImage20() {
        return (this.props.sequences.imageId === 20);
    }
    private selectImage21() {
        return (this.props.sequences.imageId === 21);
    }
    private selectImage22() {
        return (this.props.sequences.imageId === 22);
    }
    private selectImage23() {
        return (this.props.sequences.imageId === 23);
    }
    private selectImage24() {
        return (this.props.sequences.imageId === 24);
    }
    private selectImage25() {
        return (this.props.sequences.imageId === 25);
    }
    private selectImage26() {
        return (this.props.sequences.imageId === 26);
    }
    private selectImage27() {
        return (this.props.sequences.imageId === 27);
    }
    private selectImage28() {
        return (this.props.sequences.imageId === 28);
    }
    private selectImage29() {
        return (this.props.sequences.imageId === 29);
    }
    private selectImage30() {
        return (this.props.sequences.imageId === 30);
    }
    private selectImage31() {
        return (this.props.sequences.imageId === 31);
    }
    private selectImage32() {
        return (this.props.sequences.imageId === 32);
    }
    private selectImage33() {
        return (this.props.sequences.imageId === 33);
    }
    private selectImage34() {
        return (this.props.sequences.imageId === 34);
    }
    private selectImage35() {
        return (this.props.sequences.imageId === 35);
    }
    private selectImage36() {
        return (this.props.sequences.imageId === 36);
    }
    private selectImage37() {
        return (this.props.sequences.imageId === 37);
    }
    private selectImage38() {
        return (this.props.sequences.imageId === 38);
    }
    private selectImage39() {
        return (this.props.sequences.imageId === 39);
    }
    private selectImage40() {
        return (this.props.sequences.imageId === 40);
    }
    private selectImage41() {
        return (this.props.sequences.imageId === 41);
    }
    private selectImage42() {
        return (this.props.sequences.imageId === 42);
    }
    private selectImage43() {
        return (this.props.sequences.imageId === 43);
    }
    private selectImage44() {
        return (this.props.sequences.imageId === 44);
    }
    private selectImage45() {
        return (this.props.sequences.imageId === 45);
    }
    private selectImage46() {
        return (this.props.sequences.imageId === 46);
    }
    private selectImage47() {
        return (this.props.sequences.imageId === 47);
    }
    private selectImage48() {
        return (this.props.sequences.imageId === 48);
    }
    private selectImage49() {
        return (this.props.sequences.imageId === 49);
    }
    private selectImage50() {
        return (this.props.sequences.imageId === 50);
    }
    private selectImage51() {
        return (this.props.sequences.imageId === 51);
    }
    private selectImage52() {
        return (this.props.sequences.imageId === 52);
    }
    private selectImage53() {
        return (this.props.sequences.imageId === 53);
    }
    private selectImage54() {
        return (this.props.sequences.imageId === 54);
    }
    private selectImage55() {
        return (this.props.sequences.imageId === 55);
    }
    private selectImage56() {
        return (this.props.sequences.imageId === 56);
    }
    private selectImage57() {
        return (this.props.sequences.imageId === 57);
    }
    private selectImage58() {
        return (this.props.sequences.imageId === 58);
    }
    private selectImage59() {
        return (this.props.sequences.imageId === 59);
    }
    private selectImage60() {
        return (this.props.sequences.imageId === 60);
    }
    private selectImage61() {
        return (this.props.sequences.imageId === 61);
    }
    private selectImage62() {
        return (this.props.sequences.imageId === 62);
    }
    private selectImage63() {
        return (this.props.sequences.imageId === 63);
    }
    private selectImage64() {
        return (this.props.sequences.imageId === 64);
    }
    private selectImage65() {
        return (this.props.sequences.imageId === 65);
    }
    private selectImage66() {
        return (this.props.sequences.imageId === 66);
    }
    private selectImage67() {
        return (this.props.sequences.imageId === 67);
    }
    private selectImage68() {
        return (this.props.sequences.imageId === 68);
    }
    private selectImage69() {
        return (this.props.sequences.imageId === 69);
    }
    private selectImage70() {
        return (this.props.sequences.imageId === 70);
    }
    private selectImage71() {
        return (this.props.sequences.imageId === 71);
    }
    private selectImage72() {
        return (this.props.sequences.imageId === 72);
    }
    private selectImage73() {
        return (this.props.sequences.imageId === 73);
    }
    private selectImage74() {
        return (this.props.sequences.imageId === 74);
    }
    private selectImage75() {
        return (this.props.sequences.imageId === 75);
    }
    private selectImage76() {
        return (this.props.sequences.imageId === 76);
    }
    private selectImage77() {
        return (this.props.sequences.imageId === 77);
    }
    private selectImage78() {
        return (this.props.sequences.imageId === 78);
    }
    private selectImage79() {
        return (this.props.sequences.imageId === 79);
    }
    private selectImage80() {
        return (this.props.sequences.imageId === 80);
    }
    private selectImage81() {
        return (this.props.sequences.imageId === 81);
    }
    private selectImage82() {
        return (this.props.sequences.imageId === 82);
    }
    private selectImage83() {
        return (this.props.sequences.imageId === 83);
    }
    private selectImage84() {
        return (this.props.sequences.imageId === 84);
    }
    private selectImage85() {
        return (this.props.sequences.imageId === 85);
    }
    private selectImage86() {
        return (this.props.sequences.imageId === 86);
    }
    private selectImage87() {
        return (this.props.sequences.imageId === 87);
    }
    private selectImage88() {
        return (this.props.sequences.imageId === 88);
    }
    private selectImage89() {
        return (this.props.sequences.imageId === 89);
    }
    private selectImage90() {
        return (this.props.sequences.imageId === 90);
    }
    private selectImage91() {
        return (this.props.sequences.imageId === 91);
    }
    private selectImage92() {
        return (this.props.sequences.imageId === 92);
    }
    private selectImage93() {
        return (this.props.sequences.imageId === 93);
    }
    private selectImage94() {
        return (this.props.sequences.imageId === 94);
    }
    private selectImage95() {
        return (this.props.sequences.imageId === 95);
    }
    private selectImage96() {
        return (this.props.sequences.imageId === 96);
    }
    private selectImage97() {
        return (this.props.sequences.imageId === 97);
    }
    private selectImage98() {
        return (this.props.sequences.imageId === 98);
    }
    private selectImage99() {
        return (this.props.sequences.imageId === 99);
    }
    private selectImage100() {
        return (this.props.sequences.imageId === 100);
    }
    private selectImage101() {
        return (this.props.sequences.imageId === 101);
    }
    private selectImage102() {
        return (this.props.sequences.imageId === 102);
    }
    private selectImage103() {
        return (this.props.sequences.imageId === 103);
    }
    private selectImage104() {
        return (this.props.sequences.imageId === 104);
    }
    private selectImage105() {
        return (this.props.sequences.imageId === 105);
    }
    private selectImage106() {
        return (this.props.sequences.imageId === 106);
    }
    private selectImage107() {
        return (this.props.sequences.imageId === 107);
    }
    private selectImage108() {
        return (this.props.sequences.imageId === 108);
    }
    private selectImage109() {
        return (this.props.sequences.imageId === 109);
    }
    private selectImage110() {
        return (this.props.sequences.imageId === 110);
    }
     
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.weldSequence;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    WeldSequenceStore.actionCreators // Selects which action creators are merged into the component's props
)(WeldSequence as any);
