﻿
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import * as UserListStore from '../store/UserListStore';
import { ApplicationState } from '../store/index';
import { UserListObject } from '../store/UserListObject';
import { t } from '../i18n';
import { CustomerObject } from '../store/CustomerObject';
import { UpdateUserRequest } from '../store/models/requests/UpdateUserRequest';
import { CreateUserRequest } from '../store/models/requests/CreateUserRequest';

// At runtime, Redux will merge together...
type EditUserObjectModalProps =
    UserListStore.UserListState // ... state we've requested from the Redux store
    & typeof UserListStore.actionCreators  // ... plus action creators we've requested
    & OwnProps // .. plus our own props :-)

export interface OwnProps {
    show: boolean;
    toggleShow: () => void;
    chosenUser: UserListObject;
    setChosenUser: any;
    customers: Array<CustomerObject>;
    creatingNewUser: boolean;
}

class EditUserListModal extends React.PureComponent<EditUserObjectModalProps> {
    public constructor(props: EditUserObjectModalProps) {
        super(props);
    }

    public componentDidMount() {

    }

    public state = {
        errorList: new Map<string, boolean>([
            ['userNameMissing', false],
            ['usernameTaken', false]
        ])
    };

    public onRolesSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
    }

    public onCompanySelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
    }

    private onClickToggleShowModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.resetErrors();
        this.props.toggleShow();
    }

    private setError(errorName: string, showError: boolean) {
        let currentErrors = this.state.errorList;

        if (currentErrors.has(errorName)) {
            currentErrors.set(errorName, showError)
        }

        //(document.getElementById(errorName) as HTMLElement).classList.add('fadeInFromLeftClass');

        this.setState({ currentErrors: currentErrors });
    }

    private errorsExist() {
        let currentErrorsArray = Array.from<boolean>(this.state.errorList.values());

        if (currentErrorsArray.find(errorBoolean => errorBoolean) !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    private resetErrors() {
        let currentErrors = this.state.errorList;

        currentErrors.forEach((isOn, error) => {
            currentErrors.set(error, false);
        });

        this.setState({ currentErrors: currentErrors });
    }

    private confirmModal(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.resetErrors();

        if (!(document.getElementById('userNameInput') as HTMLInputElement).value) {
            this.setError('userNameMissing', true);
        }

        if (this.errorsExist()) {
            let fadeInElements = document.getElementsByClassName('fadeInFromLeftClass');

            //Reset animation
            for (var i = 0; i < fadeInElements.length; i++) {
                let el = (fadeInElements[i] as HTMLElement);

                el.style.animationName = "fadeInFromLeft";
                el.onanimationend = (event) => { el.style.animationName = null }
            }

            return;
        }

        //const customer = this.props.customers.find(cust => cust.number?.toString() == (document.getElementById('assignedCompanyInput') as HTMLInputElement).value);
        const dekraContact = this.props.users.find(user => user.userName == (document.getElementById('dekraFacilitatorInput') as HTMLInputElement).value);
        const createNewDekra = (document.getElementById('dekraFacilitatorInput') as HTMLInputElement).value == 'newDekraFacilitator';

        if (this.props.creatingNewUser) {
            const createUserRequest: CreateUserRequest = {
                email: (document.getElementById('emailInput') as HTMLInputElement).value,
                userName: (document.getElementById('userNameInput') as HTMLInputElement).value,
                phoneNumber: (document.getElementById('phoneInput') as HTMLInputElement).value,
                firstName: (document.getElementById('firstNameInput') as HTMLInputElement).value,
                lastName: (document.getElementById('lastNameInput') as HTMLInputElement).value,
                title: (document.getElementById('titleInput') as HTMLInputElement).value,
                mobilePhoneNumber: (document.getElementById('mobilePhoneInput') as HTMLInputElement).value,
                phoneExchangeNumber: "",
                website: "",
                dekraContactId: createNewDekra || !dekraContact ? null : dekraContact.id,
                //website: (document.getElementById('websiteInput') as HTMLInputElement).value,
                //phoneExchangeNumber: (document.getElementById('phoneExchangeInput') as HTMLInputElement).value,
                //website: (document.getElementById('websiteInput') as HTMLInputElement).value,
                postCity: (document.getElementById('postCityInput') as HTMLInputElement).value,
                postZipcode: (document.getElementById('postZipcodeInput') as HTMLInputElement).value,
                postStreet: (document.getElementById('postStreetInput') as HTMLInputElement).value,
                visitingCity: (document.getElementById('cityInput') as HTMLInputElement).value,
                visitingZipcode: (document.getElementById('zipcodeInput') as HTMLInputElement).value,
                visitingStreet: (document.getElementById('streetInput') as HTMLInputElement).value,
                roles: (document.getElementById('permissionsInput') as HTMLInputElement).value == '0' ?
                    [] : [(document.getElementById('permissionsInput') as HTMLInputElement).value],
                customerId: this.props.chosenUser.customerId ? parseInt(this.props.chosenUser.customerId) : null,
                //id: this.props.chosenUser.id
                signatureLogo: "",
            }

            this.props.addNewUser(createUserRequest, () => { this.props.receiveUserListUnsorted() });
        } else {
            const updateUserRequest: UpdateUserRequest = {
                email: (document.getElementById('emailInput') as HTMLInputElement).value,
                userName: (document.getElementById('userNameInput') as HTMLInputElement).value,
                phoneNumber: (document.getElementById('phoneInput') as HTMLInputElement).value,
                firstName: (document.getElementById('firstNameInput') as HTMLInputElement).value,
                lastName: (document.getElementById('lastNameInput') as HTMLInputElement).value,
                title: (document.getElementById('titleInput') as HTMLInputElement).value,
                mobilePhoneNumber: (document.getElementById('mobilePhoneInput') as HTMLInputElement).value,
                phoneExchangeNumber: "",
                website: "",
                //website: (document.getElementById('websiteInput') as HTMLInputElement).value,
                //phoneExchangeNumber: (document.getElementById('phoneExchangeInput') as HTMLInputElement).value,
                //website: (document.getElementById('websiteInput') as HTMLInputElement).value,
                postCity: (document.getElementById('postCityInput') as HTMLInputElement).value,
                postZipcode: (document.getElementById('postZipcodeInput') as HTMLInputElement).value,
                postStreet: (document.getElementById('postStreetInput') as HTMLInputElement).value,
                visitingCity: (document.getElementById('cityInput') as HTMLInputElement).value,
                visitingZipcode: (document.getElementById('zipcodeInput') as HTMLInputElement).value,
                visitingStreet: (document.getElementById('streetInput') as HTMLInputElement).value,
                roles: (document.getElementById('permissionsInput') as HTMLInputElement).value == '0' ?
                    [] : [(document.getElementById('permissionsInput') as HTMLInputElement).value],
                customerId: this.props.chosenUser.customerId ? parseInt(this.props.chosenUser.customerId) : null,
                id: this.props.chosenUser.id,
                dekraContact: dekraContact ? dekraContact : null,
                dekraContactId: dekraContact ? dekraContact.id : null,
                signatureLogo: ""
            }

            //this.props.setUserInUserList(newUser);

            this.props.updateUser(updateUserRequest, () => { this.props.receiveUserListUnsorted() });
        }

        this.props.toggleShow();
    }

    private populateDekraFacilitator(popUser: UserListObject | null) {
        if (!popUser) {
            (document.getElementById('dekraEmploymentNumberInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraFirstNameInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraLastNameInput') as HTMLInputElement).value = "";

            (document.getElementById('dekraTitleInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraEmailInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraPhoneInput') as HTMLInputElement).value = "";
            //(document.getElementById('dekraMobilePhoneInput') as HTMLInputElement).value = "";

            (document.getElementById('dekraStreetInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraCityInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraZipcodeInput') as HTMLInputElement).value = "";

            (document.getElementById('dekraPostStreetInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraPostCityInput') as HTMLInputElement).value = "";
            (document.getElementById('dekraPostZipcodeInput') as HTMLInputElement).value = "";
        } else {
            (document.getElementById('dekraEmploymentNumberInput') as HTMLInputElement).value = popUser.userName;
            (document.getElementById('dekraFirstNameInput') as HTMLInputElement).value = popUser.firstName;
            (document.getElementById('dekraLastNameInput') as HTMLInputElement).value = popUser.lastName;

            (document.getElementById('dekraTitleInput') as HTMLInputElement).value = popUser.title;
            (document.getElementById('dekraEmailInput') as HTMLInputElement).value = popUser.email;
            (document.getElementById('dekraPhoneInput') as HTMLInputElement).value = popUser.phoneNumber;
            //(document.getElementById('dekraMobilePhoneInput') as HTMLInputElement).value = popUser.mobilePhoneNumber;

            (document.getElementById('dekraStreetInput') as HTMLInputElement).value = popUser.visitingStreet;
            (document.getElementById('dekraCityInput') as HTMLInputElement).value = popUser.visitingCity;
            (document.getElementById('dekraZipcodeInput') as HTMLInputElement).value = popUser.visitingZipcode;

            (document.getElementById('dekraPostStreetInput') as HTMLInputElement).value = popUser.postStreet;
            (document.getElementById('dekraPostCityInput') as HTMLInputElement).value = popUser.postCity;
            (document.getElementById('dekraPostZipcodeInput') as HTMLInputElement).value = popUser.postZipcode;
        }
    }

    public onSelectDekraFacilitatorChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        if (event.target.value) {
            if (event.target.value == "newDekraFacilitator") {
                this.populateDekraFacilitator(null);
                this.enableDekraFacilitatorInputs();
            } else {
                this.populateDekraFacilitator(this.props.users.find(user => user.userName == event.target.value));
                this.disableDekraFacilitatorInputs();
            }
        } else {
            this.populateDekraFacilitator(null);
        }
    }

    private enableDekraFacilitatorInputs() {
        const facInputs = document.getElementsByClassName('dekraModalInput');

        for (var i = 0; i < facInputs.length; i++) {
            facInputs[i].removeAttribute('disabled')
        }
    }

    private disableDekraFacilitatorInputs() {
        const facInputs = document.getElementsByClassName('dekraModalInput');

        for (var i = 0; i < facInputs.length; i++) {
            facInputs[i].setAttribute('disabled', '');
        }
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private onOpen() {
        if (this.props.creatingNewUser) {
            (document.getElementById('userNameInput') as HTMLInputElement).removeAttribute('disabled');
            (document.getElementById('userNameInput') as HTMLInputElement).value = "";
            (document.getElementById('firstNameInput') as HTMLInputElement).value = "";
            (document.getElementById('lastNameInput') as HTMLInputElement).value = "";
            (document.getElementById('titleInput') as HTMLInputElement).value = "";
            (document.getElementById('emailInput') as HTMLInputElement).value = "";
            (document.getElementById('phoneInput') as HTMLInputElement).value = "";
            (document.getElementById('mobilePhoneInput') as HTMLInputElement).value = "";
        } else {
            (document.getElementById('userNameInput') as HTMLInputElement).setAttribute('disabled', '');
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.show} onOpened={() => this.onOpen()} size="lg">
                    <ModalHeader>
                        {this.props.creatingNewUser ? t('CreateNewUser') : t('ChangeUserInformation')}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row form>
                                <Col sm={6}>
                                    <FormGroup>
                                        <h5 style={{ color: "#007D40" }}>{t('User')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Permissions')}</Label>
                                                <Input type="select" bssize="sm" id="permissionsInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.roles[0]}
                                                    onChange={(event) => this.onRolesSelectChange(event)}
                                                    placeholder=""
                                                    disabled
                                                >
                                                    <option key={"permission0"} id="permission0" value={0}>{t('NoRolesChosen')}</option>
                                                    <option key={"permission1"} id="permission1" value="Customer">{t('Customer')}</option>
                                                    <option key={"permission2"} id="permission2" value="Dekra">{t('DekraContact')}</option>
                                                    <option key={"permission3"} id="permission3" value="Admin">{t('Administrator')}</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Username')}</Label>
                                                <Input type="text" bssize="sm" id="userNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.userName}
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        {this.state.errorList.get('userNameMissing') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="userNameMissing" style={{ color: "red" }} for="" size="sm">{t('UserNameMissing_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        {this.state.errorList.get('userNameTaken') ?
                                            <Row form>
                                                <Col className="no-padding" sm={12}>
                                                    <Label id="userNameTaken" style={{ color: "red" }} for="" size="sm">{t('UserNameTaken_')}</Label>
                                                </Col>
                                            </Row>
                                            : null}
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('FirstName')}</Label>
                                                <Input type="text" bssize="sm" id="firstNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.firstName}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('LastName')}</Label>
                                                <Input type="text" bssize="sm" id="lastNameInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.lastName}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Title')}</Label>
                                                <Input type="text" bssize="sm" id="titleInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.title}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Email')}</Label>
                                                <Input type="text" bssize="sm" id="emailInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.email}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Phone')}</Label>
                                                <Input type="text" bssize="sm" id="phoneInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.phoneNumber}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('MobilePhone')}</Label>
                                                <Input type="text" bssize="sm" id="mobilePhoneInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.mobilePhoneNumber}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <h5 style={{ color: "#007D40" }}>{t('VisitingAddress')} {t('forTheUser')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Street')}</Label>
                                                <Input type="text" bssize="sm" id="streetInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.visitingStreet}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="zipcodeInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.visitingZipcode}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('City')}</Label>
                                                <Input type="text" bssize="sm" id="cityInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.visitingCity}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <h5 style={{ color: "#007D40" }}>{t('PostAddress')} {t('forTheUser')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('DeliveryAddress')}</Label>
                                                <Input type="text" bssize="sm" id="postStreetInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.postStreet}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="postZipcodeInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.postZipcode}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('PostCity')}</Label>
                                                <Input type="text" bssize="sm" id="postCityInput" name="modalInput" className="modalInput field-input"
                                                    defaultValue={this.props.chosenUser.postCity}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>

                                <Col sm={6}>
                                    <FormGroup>
                                        <h5 style={{ color: "#007D40" }}>Dekra</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('ChooseDekraFacilitator')}</Label>
                                                <Input type="select" bssize="sm" id="dekraFacilitatorInput" name="commonModalInput" className="commonModalInput field-input"
                                                    /*defaultValue={this.props.customerList.newCustomer.dekraContact ? this.props.customerList.newCustomer.dekraContact.userName : null}*/
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.userName : "noContact"}
                                                    onChange={(event) => this.onSelectDekraFacilitatorChange(event)}
                                                    placeholder="" >
                                                    <option key={"emptyDekraFacilitator"} value={"noContact"}>{t('NoContactChosen')}</option>
                                                    {/*<option key={"newDekraFacilitator"} value={"newDekraFacilitator"}>{t('CreateNewDekraFacilitator')}</option>*/}
                                                    {this.props.users.filter(user => user.roles.find(role => role == 'DEKRA' || role == 'Admin') != undefined).map((facilitator, index) => (
                                                        <option key={facilitator.email} value={facilitator.userName}>{facilitator.firstName} {facilitator.lastName} ({facilitator.userName})</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('EmploymentNumber')}</Label>
                                                <Input type="text" bssize="sm" id="dekraEmploymentNumberInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.userName : null}
                                                    /*defaultValue="A23563"*/
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <h5 style={{ color: "#007D40" }}>{t('ContactInformationForDekra')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('FirstName')}</Label>
                                                <Input type="text" bssize="sm" id="dekraFirstNameInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.firstName : null}
                                                    /*defaultValue="Ulla"*/
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('LastName')}</Label>
                                                <Input type="text" bssize="sm" id="dekraLastNameInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.lastName : null}
                                                    /*defaultValue="Anehorn"*/
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Title')}</Label>
                                                <Input type="text" bssize="sm" id="dekraTitleInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.title : null}
                                                    /*defaultValue="Svetsansvarig"*/
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        {/*                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Company')}</Label>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Input type="text" bssize="sm" id="dekraCompanyInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    defaultValue="Dekra Industrial AB"
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />*/}
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('PhoneDirect')}</Label>
                                                <Input type="text" bssize="sm" id="dekraPhoneInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    /*defaultValue="046-534243"*/
                                                    disabled
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.phoneNumber : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Email')}</Label>
                                                <Input type="text" bssize="sm" id="dekraEmailInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    /*defaultValue="ulla.anehorn@dekra.se"*/
                                                    disabled
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.email : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        {/*<div style={{ height: "96px" }} />*/}
                                        <h5 style={{ color: "#007D40" }}>{t('VisitingAddress')} {t('forDekra')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Street')}</Label>
                                                <Input type="text" bssize="sm" id="dekraStreetInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.visitingStreet : null}
                                                    /*defaultValue="Agatan 31"*/
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="dekraZipcodeInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    /*defaultValue="32 435"*/
                                                    disabled
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.visitingZipcode : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('City')}</Label>
                                                <Input type="text" bssize="sm" id="dekraCityInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.visitingCity : null}
                                                    /*defaultValue="Malmö"*/
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        {/*<div style={{ height: "26.25px" }} />*/}
                                        <h5 style={{ color: "#007D40" }}>{t('PostAddress')} {t('forDekra')}</h5>
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('DeliveryAddress')}</Label>
                                                <Input type="text" bssize="sm" id="dekraPostStreetInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.postStreet : null}
                                                    /*defaultValue="Begatan 14"*/
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('Zipcode')}</Label>
                                                <Input type="text" bssize="sm" id="dekraPostZipcodeInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.postZipcode : null}
                                                    /*defaultValue="12 563"*/
                                                    disabled
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                        <div className="modal-distancer" />
                                        <Row form>
                                            <Col className="field-flex" sm={12}>
                                                <Label className="field-label" for="" size="sm">{t('PostCity')}</Label>
                                                <Input type="text" bssize="sm" id="dekraPostCityInput" name="dekraModalInput" className="dekraModalInput field-input"
                                                    /*defaultValue="Lund"*/
                                                    disabled
                                                    defaultValue={this.props.chosenUser.dekraContact ? this.props.chosenUser.dekraContact.postCity : null}
                                                    onKeyPress={(event) => this.onKeyPress(event)}
                                                    placeholder="" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Row >
                            {this.errorsExist() ?
                                <Col className="no-padding" sm={12}>
                                    <Label className="fadeInFromLeftClass error-text" for="" size="sm">{t('ThereWereErrors_')}</Label>
                                </Col>
                                : null}
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Button color="primary" onClick={(event) => this.confirmModal(event)}>{t('OK')}</Button>
                                {' '}
                                <Button color="secondary" onClick={(event) => this.onClickToggleShowModal(event)}>{t('Cancel')}</Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return state.userList;
}

export default connect(
    (state: ApplicationState, ownProps: OwnProps) => mapStateToProps(state, ownProps), // Selects which state properties are merged into the component's props
    UserListStore.actionCreators // Selects which action creators are merged into the component's props
)(EditUserListModal as any);
