﻿import { AppThunkAction } from './index';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { GetWeldSequenceResponse } from './models/responses/GetWeldSequenceResponse';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WeldSequenceState {
    sequences: GetWeldSequenceResponse;
    sequencesView: GetWeldSequenceResponse;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface ResetStateWeldSequenceAction {
    type: 'RESET_WELD_SEQUENCE_ACTION';
}

export interface SetSequencesAction {
    type: 'SET_SEQUENCES_ACTION';
    sequences: GetWeldSequenceResponse;
}

export interface SetSequencesViewAction {
    type: 'SET_SEQUENCES_VIEW_ACTION';
    sequencesView: GetWeldSequenceResponse;
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ResetStateWeldSequenceAction
    | SetSequencesAction
    | SetSequencesViewAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_WELD_SEQUENCE_ACTION' });
    },
    setWeldSequence: (weldSequence: GetWeldSequenceResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldSequence) {
            let weldSequences: GetWeldSequenceResponse = { ...appState.weldSequence.sequences };
            weldSequences = weldSequence;
            dispatch({ type: 'SET_SEQUENCES_ACTION', sequences: weldSequences });
        }
    },
    setWeldSequenceView: (weldSequenceView: GetWeldSequenceResponse): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldSequence) {
            let weldSequencesView: GetWeldSequenceResponse = { ...appState.weldSequence.sequencesView };
            weldSequencesView = weldSequenceView;
            dispatch({ type: 'SET_SEQUENCES_VIEW_ACTION', sequencesView: weldSequencesView });
        }
    },
    setSequenceNumber: (sequenceValue: string, sequenceNumber: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldSequence) {
            let weldSequences: GetWeldSequenceResponse = { ...appState.weldSequence.sequences };
            switch (sequenceNumber) {
                case 1:
                    weldSequences.sequenceNumber1 = sequenceValue;
                    break;
                case 2:
                    weldSequences.sequenceNumber2 = sequenceValue;
                    break;
                case 3:
                    weldSequences.sequenceNumber3 = sequenceValue;
                    break;
                case 4:
                    weldSequences.sequenceNumber4 = sequenceValue;
                    break;
                case 5:
                    weldSequences.sequenceNumber5 = sequenceValue;
                    break;
                case 6:
                    weldSequences.sequenceNumber6 = sequenceValue;
                    break;
                case 7:
                    weldSequences.sequenceNumber7 = sequenceValue;
                    break;
                case 8:
                    weldSequences.sequenceNumber8 = sequenceValue;
                    break;
                case 9:
                    weldSequences.sequenceNumber9 = sequenceValue;
                    break;
                case 10:
                    weldSequences.sequenceNumber10 = sequenceValue;
                    break;
                case 11:
                    weldSequences.sequenceNumber11 = sequenceValue;
                    break;
                case 12:
                    weldSequences.sequenceNumber12 = sequenceValue;
                    break;
                case 13:
                    weldSequences.sequenceNumber13 = sequenceValue;
                    break;
                case 14:
                    weldSequences.sequenceNumber14 = sequenceValue;
                    break;
                case 15:
                    weldSequences.sequenceNumber15 = sequenceValue;
                    break;
                default:
            }
            dispatch({ type: 'SET_SEQUENCES_ACTION', sequences: weldSequences });
        }
    },
    setHtmlImage: (htmlImage: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.weldSequence) {
            let weldSequences: GetWeldSequenceResponse = { ...appState.weldSequence.sequences };
            weldSequences.imageData = htmlImage;
            dispatch({ type: 'SET_SEQUENCES_ACTION', sequences: weldSequences });
        }
    },
    setSequenceImageId: (imageId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.weldSequence) {
            let weldSequences: GetWeldSequenceResponse = { ...appState.weldSequence.sequences };
            weldSequences.imageId = imageId;
            dispatch({ type: 'SET_SEQUENCES_ACTION', sequences: weldSequences });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WeldSequenceState = {
    sequences: {
        createdAt: '',
        deletedAt: '',
        updatedAt: '',
        imageId: 0,
        imageData: '',
        sequenceNumber1: '',
        sequenceNumber2: '',
        sequenceNumber3: '',
        sequenceNumber4: '',
        sequenceNumber5: '',
        sequenceNumber6: '',
        sequenceNumber7: '',
        sequenceNumber8: '',
        sequenceNumber9: '',
        sequenceNumber10: '',
        sequenceNumber11: '',
        sequenceNumber12: '',
        sequenceNumber13: '',
        sequenceNumber14: '',
        sequenceNumber15: ''
    },
    sequencesView: {
        createdAt: '',
        deletedAt: '',
        updatedAt: '',
        imageId: 0,
        imageData: '',
        sequenceNumber1: '',
        sequenceNumber2: '',
        sequenceNumber3: '',
        sequenceNumber4: '',
        sequenceNumber5: '',
        sequenceNumber6: '',
        sequenceNumber7: '',
        sequenceNumber8: '',
        sequenceNumber9: '',
        sequenceNumber10: '',
        sequenceNumber11: '',
        sequenceNumber12: '',
        sequenceNumber13: '',
        sequenceNumber14: '',
        sequenceNumber15: ''
    },
};

export const reducer: Reducer<WeldSequenceState> = (state: WeldSequenceState | undefined, incomingAction: Action): WeldSequenceState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_SEQUENCES_ACTION':
            return {
                sequences: action.sequences,
                sequencesView: state.sequencesView
            };
        case 'SET_SEQUENCES_VIEW_ACTION':
            return {
                sequences: state.sequences,
                sequencesView: action.sequencesView
            };
        case 'RESET_WELD_SEQUENCE_ACTION':
            return unloadedState;
        default:
            return state;
    }
};
