﻿import { Action, Reducer } from '../../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './../index';
import { TypeOfBackingData } from '../models/TypeOfBackingData';
import { GetTypeOfBackingResponse } from '../models/responses/GetTypeOfBackingResponse';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TypeOfBackingState {
    typeOfBackingData: TypeOfBackingData
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface GetTypeOfBackingDataReceiveAction {
    type: 'GET_TYPE_OF_BACKING_DATA_RECEIVE';
    typeOfBackingData: TypeOfBackingData
}

export interface GetTypeOfBackingDataRequestAction {
    type: 'GET_TYPE_OF_BACKING_DATA_REQUEST';
}

export interface ResetTypeOfBackingAction {
    type: 'RESET_STATE_TYPE_OF_BACKING_ACTION';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = GetTypeOfBackingDataReceiveAction
    | GetTypeOfBackingDataRequestAction
    | ResetTypeOfBackingAction
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_STATE_TYPE_OF_BACKING_ACTION' });
    },
    getTypeOfBackingsAll: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/TypeOfBackings/GetAll`)
            .then(response => response.json() as Promise<Array<GetTypeOfBackingResponse>>)
            .then(data => {
                console.info(data);
                const appState = getState();
                if (appState && appState.typeOfBacking) {
                    let typeOfBackingData: TypeOfBackingData = { ...appState.typeOfBacking.typeOfBackingData };
                    typeOfBackingData.typeOfBackings = [...data];
                    dispatch({ type: 'GET_TYPE_OF_BACKING_DATA_RECEIVE', typeOfBackingData: typeOfBackingData });
                }
            })
            .catch(error => {
                console.error(error);
            });
        dispatch({ type: 'GET_TYPE_OF_BACKING_DATA_REQUEST' });
    },
    setTypeOfBackingText: (typeOfBackingText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.typeOfBacking) {
            let typeOfBackingData: TypeOfBackingData = { ...appState.typeOfBacking.typeOfBackingData };
            typeOfBackingData.typeOfBackingText = typeOfBackingText;
            dispatch({ type: 'GET_TYPE_OF_BACKING_DATA_RECEIVE', typeOfBackingData: typeOfBackingData });
        }
    },
    setTypeOfBackingTextView: (typeOfBackingTextView: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.typeOfBacking) {
            let typeOfBackingData: TypeOfBackingData = { ...appState.typeOfBacking.typeOfBackingData };
            typeOfBackingData.typeOfBackingTextView = typeOfBackingTextView;
            dispatch({ type: 'GET_TYPE_OF_BACKING_DATA_RECEIVE', typeOfBackingData: typeOfBackingData });
        }
    },
    clearTypeOfBackingText: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.typeOfBacking) {
            let typeOfBackingData: TypeOfBackingData = { ...appState.typeOfBacking.typeOfBackingData };
            typeOfBackingData.typeOfBackingText = '';
            dispatch({ type: 'GET_TYPE_OF_BACKING_DATA_RECEIVE', typeOfBackingData: typeOfBackingData });
        }
    }

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TypeOfBackingState = {
    typeOfBackingData: {
        typeOfBackingText: '',
        typeOfBackingTextView: '',
        typeOfBackings: [],
    }
};

export const reducer: Reducer<TypeOfBackingState> = (state: TypeOfBackingState | undefined, incomingAction: Action): TypeOfBackingState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_TYPE_OF_BACKING_DATA_RECEIVE':
            return {
                typeOfBackingData: action.typeOfBackingData,
            };
        case 'RESET_STATE_TYPE_OF_BACKING_ACTION':
            return unloadedState;
        default:
            return state;
    }
};
