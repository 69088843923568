﻿
import { FilesUpload } from './models/FilesUpload';
import { Action, Reducer } from '../../../ClientApp/node_modules/redux/index';
import { AppThunkAction } from './index';
import { GetFilesUploadResponse } from './models/responses/GetFilesUploadResponse';
import { UploadFileResponse } from './models/responses/UploadFileResponse';
import { PDFRequest } from './models/requests/PDFRequest';
import { PDFResponse } from './models/responses/PDFResponse';
import authHeader from './models/auth-header';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface FileUploadState {
    filesUpload: Array<FilesUpload>; 
    enablePdf: boolean;
    selectedFileUpload: Array<FilesUpload>;
    standardImages: Array<FilesUpload>; 
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetFileUpload {
    type: 'SET_FILES_UPLOAD';
    filesUpload: Array<FilesUpload>;
}
export interface SetStandardImages {
    type: 'SET_STANDARD_IMAGES';
    standardImages: Array<FilesUpload>;
}
// Upload File
export interface SetFileUploadAction {
    type: 'SET_FILE_UPLOAD_ACTION';
    selectedFileUpload: Array<FilesUpload>;
}
export interface ResetStatefileUploadAction {
    type: 'RESET_STATE_FILE_UPLOAD_ACTION';
}
export interface CreatePdfReceiveAction {
    type: 'CREATE_PDF_RECEIVE';
    enablePdf: boolean;
}

export interface CreatePdfRequestAction {
    type: 'CREATE_PDF_REQUEST';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetFileUpload
    | ResetStatefileUploadAction
    | CreatePdfReceiveAction
    | CreatePdfRequestAction
    | SetFileUploadAction
    | SetStandardImages;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearState: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'RESET_STATE_FILE_UPLOAD_ACTION' });
    },
    setDownload: (fileName: string, filePath: string ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileUpload = new FilesUpload();
        fileUpload.fileName = fileName;
        fileUpload.filePath = filePath;
                fetch(`api/File/Download`, {
                    method: 'POST',
                    headers: authHeader.authHeader(),
                    body: JSON.stringify(fileUpload)
                    })
                    .then(response => response.json() as Promise<UploadFileResponse>)
                    .then(data => {
                        console.log('Download');
                        console.info(data);
                        const linkSource = `data:application/pdf;base64,${data.fileBase64}`;
                        const downloadLink = document.createElement("a");
                        const fileName = data.fileName;
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click()
                    })
                    .catch(error => {
                        console.error(error);
                    });
    },
    uploadImage: (uploadImages: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const formData = new FormData();
        for (let i = 0; i < uploadImages.length; i++) {
            formData.append('images', uploadImages[i]);
        }
            fetch(`api/File/Upload`, {
                method: 'POST',
                headers: authHeader.authHeaderAddAccept(),
                body: formData
            })
                .then(response => response.json() as Promise<Array<FilesUpload>>)
                .then(data => {
                    console.log('UploadFiles');
                    console.info(data);
                    if (appState && appState.fileUpload) {
                        let selectedFileUpload: Array<FilesUpload> = [...appState.fileUpload.selectedFileUpload];
                        for (let i = 0; i < data.length; i++) {
                            let fileUpload: FilesUpload = new FilesUpload();
                            fileUpload.id = data[i].id;
                            fileUpload.fileName = data[i].fileName;
                            fileUpload.fileBase64 = data[i].fileBase64;
                            fileUpload.filePath = data[i].filePath;
                            fileUpload.fileUrl = data[i].fileUrl;
                            selectedFileUpload.splice(selectedFileUpload.length, 0, fileUpload);
                        }
                        dispatch({ type: 'SET_FILE_UPLOAD_ACTION', selectedFileUpload: selectedFileUpload });
                        dispatch({ type: 'CREATE_PDF_RECEIVE', enablePdf: false });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        dispatch({ type: 'CREATE_PDF_REQUEST' });
    },
    setStandardImageToFileUpload: (uploadImage: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.fileUpload) {
            let selectedFileUpload: Array<FilesUpload> = [...appState.fileUpload.selectedFileUpload ];
            let fileUpload: FilesUpload = new FilesUpload();
            fileUpload.id = uploadImage.id;
            fileUpload.fileUrl = uploadImage.fileUrl;
            fileUpload.fileName = uploadImage.fileName;
            fileUpload.fileBase64 = uploadImage.fileBase64;
            fileUpload.filePath = uploadImage.filePath;
            selectedFileUpload.splice(selectedFileUpload.length, 0, fileUpload);
            dispatch({ type: 'SET_FILE_UPLOAD_ACTION', selectedFileUpload: selectedFileUpload });
        }
    },
    setCloseImage: (rowNumber: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.fileUpload) {
            let selectedFileUpload: Array<FilesUpload> = [...appState.fileUpload.selectedFileUpload];
            selectedFileUpload.splice(rowNumber - 1, 1);
            dispatch({ type: 'SET_FILE_UPLOAD_ACTION', selectedFileUpload: selectedFileUpload });
        }
    },
    createFile: (createFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let reader = new FileReader();
            reader.onloadend = () => {
                    let fileUpload = new FilesUpload();
                    if (reader.result != null) {
                        fileUpload.fileBase64 = reader.result.toString();
                    }
                    fileUpload.fileName = createFile.name;
                    fetch(`api/File/UploadFiles`, {
                        method: 'POST',
                        headers: authHeader.authHeader(),
                        body: JSON.stringify(fileUpload)
                        })
                        .then(response => response.json() as Promise<UploadFileResponse>)
                        .then(data => {
                            console.log('UploadFiles');
                            console.info(data);
                            const appState = getState();
                            if (appState && appState.fileUpload) {
                            let filesUploadResponse: Array<FilesUpload> = [...appState.fileUpload.filesUpload];
                            let fileUploadResponse = new FilesUpload();
                            fileUploadResponse.fileBase64 = "";
                            fileUploadResponse.fileName = data.fileName;
                            fileUploadResponse.filePath = data.filePath;
                            filesUploadResponse.splice(filesUploadResponse.length, 0, fileUploadResponse);
                                dispatch({ type: 'SET_FILES_UPLOAD', filesUpload: filesUploadResponse });
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
            };
            reader.readAsDataURL(createFile);
    },
    deleteFileRow: (rowNumber: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.fileUpload) {
            let filesUploadResponse: Array<FilesUpload> = [ ...appState.fileUpload.filesUpload ];
            filesUploadResponse.splice(rowNumber - 1, 1);
            dispatch({ type: 'SET_FILES_UPLOAD', filesUpload: filesUploadResponse });
        }
    },
    setFilesUpload: (selectedFilesUpload: Array<GetFilesUploadResponse>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.fileUpload) {
            let filesUpload: Array<FilesUpload> = [...appState.fileUpload.filesUpload];
            filesUpload = [];
            for (let i = 0; i < selectedFilesUpload.length; i++) {
                let fileUpload: FilesUpload = new FilesUpload();
                fileUpload.fileName = selectedFilesUpload[i].fileName;
                fileUpload.fileDescription = selectedFilesUpload[i].fileDescription;
                fileUpload.fileBase64 = selectedFilesUpload[i].fileBase64;
                fileUpload.filePath = selectedFilesUpload[i].filePath;
                fileUpload.selectedFile = selectedFilesUpload[i].selectedFile;
                filesUpload.splice(filesUpload.length, 0, fileUpload);
            }
            dispatch({ type: 'SET_FILES_UPLOAD', filesUpload: filesUpload });
        }
    },
    setStandardImages: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/File/GetAllStandardImages`, {
            method: 'GET',
            headers: authHeader.authHeader(),
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                if (res.status === 401) {
                }
                let data = res.data as Array<FilesUpload>;
                console.info(data);
                dispatch({ type: 'SET_STANDARD_IMAGES', standardImages: data });
            }))
            .catch(error => {
                console.error(error);
            });
    },
    createPdf: (pdfRequest: PDFRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {// Get the WeldingParameters values.
        const appState = getState();
        if (appState && appState.wpsTab) {
            fetch(`api/PDFs/Create`, {
                method: 'POST',
                headers: authHeader.authHeader(),
                body: JSON.stringify({ ...pdfRequest })
            })
                .then(response => response.json() as Promise<PDFResponse>)
                .then(data => {
                    console.info(data);
                    var pdf = data.data;
                    const linkSource = `data:application/pdf;base64,${pdf}`;
                    const downloadLink = document.createElement("a");
                    const fileName = pdfRequest.fileName == "" ? data.pdfName : pdfRequest.fileName + ".pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click()
                    dispatch({ type: 'CREATE_PDF_RECEIVE', enablePdf: false });
                })
                .catch(error => {
                    console.error(error);
                });
            dispatch({ type: 'CREATE_PDF_REQUEST' });
        }
    },
    createWpqrPdf: (pdfRequest: PDFRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {// Get the WeldingParameters values.
        const appState = getState();
        if (appState && appState.wpsTab) {
            fetch(`api/PDFs/CreateWpqr`, {
                method: 'POST',
                headers: authHeader.authHeader(),
                body: JSON.stringify({ ...pdfRequest })
            })
                .then(response => response.json() as Promise<PDFResponse>)
                .then(data => {
                    console.info(data);
                    var file = data.data;
                    var linkSource = "";
                    var fileName = "";

                    if (pdfRequest.fileName.indexOf(".docx") > -1) {
                        linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${file}`;
                        fileName = pdfRequest.fileName ;
                    } else {
                        linkSource = `data:application/pdf;base64,${file}`;
                        fileName = pdfRequest.fileName == "" ? data.pdfName : pdfRequest.fileName;
                    }

                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click()
                    dispatch({ type: 'CREATE_PDF_RECEIVE', enablePdf: false });
                })
                .catch(error => {
                    console.error(error);
                });
            dispatch({ type: 'CREATE_PDF_REQUEST' });
        }
    },
    createWeldingProtocolPdf: (pdfRequest: PDFRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {// Get the WeldingParameters values.
        const appState = getState();
        if (appState && appState.wpsTab) {
            fetch(`api/PDFs/CreateWeldingProtocol`, {
                method: 'POST',
                headers: authHeader.authHeader(),
                body: JSON.stringify({ ...pdfRequest })
            })
                .then(response => response.json() as Promise<PDFResponse>)
                .then(data => {
                    console.info(data);
                    var pdf = data.data;
                    const linkSource = `data:application/pdf;base64,${pdf}`;
                    const downloadLink = document.createElement("a");
                    const fileName = pdfRequest.fileName == "" ? data.pdfName : pdfRequest.fileName + ".pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click()
                    dispatch({ type: 'CREATE_PDF_RECEIVE', enablePdf: false });
                })
                .catch(error => {
                    console.error(error);
                });
            dispatch({ type: 'CREATE_PDF_REQUEST' });
        }
    },
    setSelectedFile: (rowNumber: number, selected: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.fileUpload) {
            let filesUpload: Array<FilesUpload> = [...appState.fileUpload.filesUpload];
            for (let i = 0; i < filesUpload.length; i++) {
                if (rowNumber - 1 === i) {
                    filesUpload[rowNumber - 1].selectedFile = selected;
                } else
                {
                    filesUpload[i].selectedFile = false;
                }
            }
            dispatch({ type: 'SET_FILES_UPLOAD', filesUpload: filesUpload });
        }
    },
    setImageUpload: (uploadFileResponse: Array<UploadFileResponse>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.fileUpload) {
            let filesUpload: Array<FilesUpload> = [...appState.fileUpload.filesUpload];
            filesUpload = [];
            for (let i = 0; i < uploadFileResponse.length; i++) {
                let fileUpload: FilesUpload = new FilesUpload();
                fileUpload.id = uploadFileResponse[i].id;
                fileUpload.fileName = uploadFileResponse[i].fileName;
                fileUpload.fileBase64 = uploadFileResponse[i].fileBase64;
                fileUpload.filePath = uploadFileResponse[i].filePath;
                fileUpload.fileUrl = uploadFileResponse[i].fileUrl;
                filesUpload.splice(filesUpload.length, 0, fileUpload);
            }
            dispatch({ type: 'SET_FILE_UPLOAD_ACTION', selectedFileUpload: filesUpload });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: FileUploadState = {
    filesUpload: [],
    enablePdf: false,
    selectedFileUpload:[],
    standardImages: [],
    
};

export const reducer: Reducer<FileUploadState> = (state: FileUploadState | undefined, incomingAction: Action): FileUploadState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_FILES_UPLOAD':
            return {
                filesUpload: action.filesUpload,
                enablePdf: state.enablePdf,
                selectedFileUpload: state.selectedFileUpload,
                standardImages: state.standardImages,
            };
        case 'CREATE_PDF_RECEIVE':
            return {
                filesUpload: state.filesUpload,
                enablePdf: action.enablePdf,
                selectedFileUpload: state.selectedFileUpload,
                standardImages: state.standardImages,
            };
        case 'SET_FILE_UPLOAD_ACTION':
            return {
                filesUpload: state.filesUpload,
                enablePdf: state.enablePdf,
                selectedFileUpload: action.selectedFileUpload,
                standardImages: state.standardImages,
            };
        case 'CREATE_PDF_REQUEST':
            return {
                filesUpload: state.filesUpload,
                enablePdf: true,
                selectedFileUpload: state.selectedFileUpload,
                standardImages: state.standardImages,
            };
        case 'SET_STANDARD_IMAGES':
            return {
                filesUpload: state.filesUpload,
                enablePdf: state.enablePdf,
                selectedFileUpload: state.selectedFileUpload,
                standardImages: action.standardImages,
            };
        case 'RESET_STATE_FILE_UPLOAD_ACTION':
            return unloadedState;
        default:
            return state;
    }
};
